import type { MouseEvent } from 'react';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import c from 'classnames';
import { getFieldWorkedArea } from 'fields/Field';
import type { FieldUsageWithFields } from 'fields/fields-selectors';
import { asBadge, getUsageName } from 'fields/FieldUsage';
import type { Set } from 'immutable';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';
import SizeBubble from 'view/molecules/size-bubble/SizeBubble';
import FormattedArea from 'view/units/FormattedArea';

interface FieldSelectorGroupProps {
    year: number;
    group: FieldUsageWithFields;
    selected: Set<string>;
    setSelected: (fields: Set<string>) => void;
    isOpen: boolean;
    toggleOpen: VoidFunction;
}

export default ({
    year,
    group,
    selected,
    setSelected,
    isOpen,
    toggleOpen,
}: FieldSelectorGroupProps) => {
    const allUsageSelected =
        group.fields.size > 0 && group.fields.map((f) => f.uuid).isSubset(selected);

    const toggleSelected = () => {
        const uuids = group.fields.map((f) => f.uuid);
        if (allUsageSelected) {
            setSelected(selected.subtract(uuids));
        } else {
            setSelected(selected.concat(uuids));
        }
    };

    const handleToggleOpen = (e?: MouseEvent) => {
        e?.preventDefault();
        toggleOpen();
    };

    const totalSize = group.fields.reduce(
        (total, field) => total + getFieldWorkedArea(field, year),
        0
    );

    return (
        <div className="FieldSelectorGroup flex items-center relative bordered-b px-5 py-4">
            <div className="flex-grow truncate mr-2">
                <Checkbox
                    id={group.fieldUsage.uuid}
                    checked={allUsageSelected}
                    onChange={toggleSelected}
                    label={<BadgeItem item={asBadge(group.fieldUsage)} className="font-bold" />}
                />
            </div>
            <SizeBubble>
                <strong>
                    <FormattedArea areaSqm={totalSize} withUnit />
                </strong>
            </SizeBubble>
            <button className="bare-btn ml-2 focus" onClick={handleToggleOpen}>
                <span className="sr-only">
                    Toggle showing {getUsageName(group.fieldUsage)} fields
                </span>
                <i
                    className={c('open-toggle', isOpen ? 'ion-arrow-down-b' : 'ion-arrow-right-b')}
                />
            </button>
        </div>
    );
};
