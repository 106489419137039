import { useTranslation } from 'react-i18next';
import { isComplete } from 'activity/activity-helper';
import { SidebarCard } from 'components/sidebar-card';
import { useOperationDetailsActions } from 'hooks/actions';
import DueDateWords from 'notes/sidebar/list/DueDateWords';
import type FullOperation from 'operations/FullOperation';
import {
    selectOperationFieldCompleteCount,
    selectOperationFieldCompletedHa,
} from 'operations/FullOperation';
import FormattedArea from 'view/units/FormattedArea';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import MarkAllComplete from './MarkAllComplete';
import OperationCompletionCircle from './OperationCompletionCircle';

import './OperatonStatusView.css';

interface OperationStatusViewProps {
    fullOperation: FullOperation;
    completedFields: number;
    totalArea: number;
    completedArea: number;
    percentageComplete: number;
}

type ParentProps = Pick<OperationStatusViewProps, 'fullOperation'>;

const OperationStatusViewBase = ({
    fullOperation,
    completedFields,
    totalArea,
    completedArea,
    percentageComplete,
}: OperationStatusViewProps) => {
    const { t } = useTranslation();
    const { handleToggleEditingFields: toggleEditingFields } = useOperationDetailsActions();

    return (
        <SidebarCard.Root>
            {fullOperation.fields !== null && (
                <div className="OperationCompletionStatus">
                    <OperationCompletionCircle percentageComplete={percentageComplete} />
                    <div>
                        <strong>
                            {completedFields}/{fullOperation.fields.size}
                        </strong>{' '}
                        {t('fields')}
                    </div>
                    <div className="ml-auto">
                        <strong>
                            <FormattedArea areaSqm={completedArea} />
                        </strong>{' '}
                        <ShortAreaUnit /> /{' '}
                        <strong>
                            <FormattedArea areaSqm={totalArea} />
                        </strong>{' '}
                        <ShortAreaUnit />
                    </div>
                </div>
            )}
            <div className="flex items-center justify-between">
                <MarkAllComplete
                    fullOperation={fullOperation}
                    onEditFields={toggleEditingFields}
                    checked={percentageComplete === 100}
                />
                {!isComplete(fullOperation) && fullOperation.summary.dueDate && (
                    <DueDateWords date={fullOperation.summary.dueDate} />
                )}
            </div>
        </SidebarCard.Root>
    );
};

function OperationStatus(props: ParentProps) {
    const totalArea =
        props.fullOperation.fields === null
            ? 0
            : props.fullOperation.fields.reduce((acc, field) => acc + field.areaSqm, 0);

    const completedFields = selectOperationFieldCompleteCount(props.fullOperation.fields);

    const preparedProps = {
        ...props,
        completedFields,
        totalArea: totalArea,
        completedArea: selectOperationFieldCompletedHa(props.fullOperation.fields),
        percentageComplete:
            props.fullOperation.fields === null
                ? 0
                : (completedFields / props.fullOperation.fields.size) * 100,
    };

    return <OperationStatusViewBase {...preparedProps} />;
}

export default OperationStatus;
