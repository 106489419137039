import type Field from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import { getUsageName } from 'fields/FieldUsage';
import type { List } from 'immutable';
import { compareName, compareString } from 'lib/util/text';

export const textMatches = (matcher: string, text: string) => {
    return text.toLowerCase().indexOf(matcher.toLowerCase()) !== -1;
};

export const sortFieldsByUsageFirst = (
    fields: List<{ field: Field; previousUsages?: List<FieldUsage> }>
) =>
    fields
        .map((i) =>
            i.previousUsages ? { ...i, previousUsages: i.previousUsages.sort(compareName) } : i
        )
        .sort((a, b) => {
            if (a.previousUsages && a.previousUsages.size > 0) {
                const aFirstUsage = a.previousUsages.get(0) as FieldUsage;

                if (b.previousUsages && b.previousUsages.size > 0) {
                    const bFirstUsage = b.previousUsages.get(0) as FieldUsage;

                    if (aFirstUsage.uuid === 'none' && bFirstUsage.uuid !== 'none') {
                        return -1;
                    }

                    if (aFirstUsage.uuid === bFirstUsage.uuid) {
                        return compareString(a.field.name, b.field.name);
                    }
                    if (bFirstUsage.uuid === 'none') {
                        return 1;
                    }

                    return compareString(getUsageName(aFirstUsage), getUsageName(bFirstUsage));
                }
                return 1;
            }

            return b.previousUsages ? -1 : compareString(a.field.name, b.field.name);
        });
