import { connect } from 'react-redux';
import type { ChatNotification } from 'chat/chat-state';
import { getDisplayName } from 'lib/util/names';
import type { AppState } from 'system/store';
import { selectDisplayableFarmUsers } from 'team/farm-users-state';

interface NewMessageNotificationProps {
    // From parent
    notification: ChatNotification;
    // From redux
    user: string;
}

const NewMessageNotification = ({ user, notification }: NewMessageNotificationProps) => (
    <div>
        <p className="mb-0">
            <strong>{user}:</strong>
        </p>
        <p className="truncate">
            {notification.payload.type === 'text' ? (
                notification.payload.text
            ) : notification.payload.type === 'image' ? (
                <em>Sent an image</em>
            ) : (
                <em>Sent a file</em>
            )}
        </p>
    </div>
);

export default connect(
    (state: AppState, ownProps: Pick<NewMessageNotificationProps, 'notification'>) => {
        return {
            user: getDisplayName(
                selectDisplayableFarmUsers(state),
                ownProps.notification.payload.createdByUserId
            ),
        };
    }
)(NewMessageNotification);
