import { connect } from 'react-redux';
import type Field from 'fields/Field';
import { getYearFieldUsageUuidOrNone } from 'fields/Field';
import { selectFieldUsagesWithNotSet } from 'fields/field-usage-selectors';
import { selectMaybeVisibleMaybeArchivedFieldsWithParentsGroupedByArchived } from 'fields/fields-selectors';
import type FieldUsage from 'fields/FieldUsage';
import type { List } from 'immutable';
import { DEFAULT_COLOUR } from 'lib/colours';
import type { AppState } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

import BulkFieldEditArchivedHeader from './BulkFieldEditArchivedHeader';
import FieldEditRow from './FieldEditRow';

interface FieldEditAlphabeticalProps {
    // From redux
    fields: {
        active: List<Field>;
        archived: List<Field>;
    };
    year: number;
    fieldUsages: List<FieldUsage>;
}

const FieldEditAlphabetical = ({ fields, year, fieldUsages }: FieldEditAlphabeticalProps) => {
    return (
        <>
            {fields.active.map((field) => (
                <FieldEditRow key={field.uuid} field={field}>
                    <div
                        className="w-2 self-stretch"
                        style={{
                            backgroundColor:
                                fieldUsages.find(
                                    (fu) => fu.uuid === getYearFieldUsageUuidOrNone(year, field)
                                )?.colour ?? DEFAULT_COLOUR,
                        }}
                    />
                </FieldEditRow>
            ))}
            {fields.archived.size > 0 && <BulkFieldEditArchivedHeader />}
            {fields.archived.map((field) => (
                <FieldEditRow key={field.uuid} field={field}>
                    <div
                        className="w-2 self-stretch"
                        style={{
                            backgroundColor:
                                fieldUsages.find(
                                    (fu) => fu.uuid === getYearFieldUsageUuidOrNone(year, field)
                                )?.colour ?? DEFAULT_COLOUR,
                        }}
                    />
                </FieldEditRow>
            ))}
        </>
    );
};

export default connect((state: AppState) => ({
    fields: selectMaybeVisibleMaybeArchivedFieldsWithParentsGroupedByArchived(state),
    year: selectCurrentYear(state),
    fieldUsages: selectFieldUsagesWithNotSet(state),
}))(FieldEditAlphabetical);
