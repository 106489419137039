import type { MouseEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import icon from 'components/icons/icon';
import type { List } from 'immutable';
import { Form } from 'informed';
import { useOutOfDatePromise } from 'lib/hooks';
import type { BadgeItemWithIdLike } from 'view/molecules/badge-item/BadgeItem';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';

import EditableActions from '../common/EditableActions';
import EditButton from '../common/EditButton';
import SidebarModule from '../SidebarModule';

import BadgeRadioGroup from './BadgeRadioGroup';
import ForceFieldValueOnUpdate from './ForceFieldValueOnUpdate';

import './Badge.scss';

interface FormValues {
    badge: any;
}

interface EditableBadgeProps {
    label: string;
    value: any;
    options: List<BadgeItemWithIdLike>;
    allowNull: boolean;
    onSave: (value: any) => Promise<any>;
    editingDisabled?: boolean;
    className?: string;

    forceSelectedId: any;

    manageLabel?: string;
    onManage?: VoidFunction;

    onCreateClick?: VoidFunction;
    initialEditing?: boolean;
}

const EditableBadge = ({
    label,
    value,
    options,
    allowNull,
    onSave,
    editingDisabled = false,
    className,
    forceSelectedId,
    manageLabel,
    onManage,
    onCreateClick,
    initialEditing,
}: EditableBadgeProps) => {
    const { t } = useTranslation();
    const [editing, setEditing] = useState(initialEditing || false);
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(() => setEditing(false));

    const handleSubmit = (values: FormValues) => {
        setPromise(onSave(values.badge));
    };

    const handleManage = (e: MouseEvent) => {
        e.preventDefault();
        onManage && onManage();
    };

    if (editing) {
        return (
            <SidebarModule className="EditableBadge" editing={true}>
                <h4>
                    {label}{' '}
                    {onCreateClick && (
                        <Button variant="outline" small onClick={onCreateClick}>
                            {t('create_new')}
                        </Button>
                    )}
                </h4>
                <Form initialValues={{ badge: value }} onSubmit={handleSubmit}>
                    <BadgeRadioGroup field="badge" options={options} allowNull={allowNull} />
                    {manageLabel && onManage && (
                        <a href="" className="add-link" onClick={handleManage}>
                            {icon('add')}
                            {manageLabel}
                        </a>
                    )}
                    <EditableActions
                        disabled={pending}
                        error={error}
                        outOfDate={outOfDate}
                        setEditing={setEditing}
                    />
                    <ForceFieldValueOnUpdate field="badge" value={forceSelectedId} />
                </Form>
            </SidebarModule>
        );
    }

    const badge = options.find((o) => o.id === value);
    return (
        <SidebarModule className={c('EditableBadge', className)}>
            <EditButton setEditing={setEditing} disabled={editingDisabled} />
            <h4>{label}</h4>
            {badge && <BadgeItem item={badge} />}
            {!badge && <div className="not-set">{t('not_set')}</div>}
        </SidebarModule>
    );
};

export default EditableBadge;
