import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { usePromise, useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import LoadableContent from '@fieldmargin/webapp-styling/components/loadable-content';
import icon from 'components/icons/icon';
import type Field from 'fields/Field';
import { selectFieldsWithFullNames } from 'fields/fields-selectors';
import { useCurrentYearSelector } from 'hooks/selectors';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import type { List } from 'immutable';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type OutputReportSummary from 'outputs/OutputReportSummary';
import { bindActionCreators } from 'redux';
import ExportReportButton from 'reporting/ExportReportButton';
import { getCsvDownloadLink, getYearOuputSummary } from 'reporting/reporting-api';
import ReportStatusFilter from 'reporting/ReportStatusFilter';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import ContactSupportLink from 'view/ContactSupportLink';
import ErrorMessage from 'view/ErrorMessage';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';
import FeatureLockedModal from 'view/modal/FeatureLockedModal';

import editCostsSvg from '../edit-costs.svg';
import reportingEmpty from '../reporting-empty.png';

import type { YeldReportingOuletSchema } from './useYeldReportingContext';
import { normalizeOutputReportSummaries } from './yield-reporting-utils';

interface YieldReportingProps {
    fields: List<Field>;
    areaMeasurementUnit: MeasurementUnit;
    changeModal: typeof changeModal;
}

const YieldReporting = ({ fields, areaMeasurementUnit, changeModal }: YieldReportingProps) => {
    const { t } = useTranslation();
    const { currentYear } = useCurrentYearSelector();
    const { currentFarm } = useCurrentFarm();
    const { state } = useLocation();
    const isTable = state?.isTable ?? true;
    const [showUpgradeModal, toggleUpgradeModal] = useToggle(false);
    const [statusFilter, setStatusFilter] = useState('all');
    const [reportData, setReportData] = useState<List<OutputReportSummary>>();
    const { pending, error, setPromise } = usePromise<List<OutputReportSummary>>(
        (outputReportSummaries) =>
            setReportData(normalizeOutputReportSummaries(outputReportSummaries, fields))
    );

    useEffect(() => {
        if (currentFarm.plan.reportingLevel !== 'NO_REPORTING') {
            setPromise(getYearOuputSummary(currentFarm.uuid, currentYear, areaMeasurementUnit));
        }
    }, [areaMeasurementUnit, currentFarm, currentYear]);

    const handleFilterChange = (nextFilter: string) => {
        setPromise(
            getYearOuputSummary(
                currentFarm.uuid,
                currentYear,
                areaMeasurementUnit,
                nextFilter === 'all' ? undefined : nextFilter.toUpperCase()
            )
        );
        setStatusFilter(nextFilter);
    };

    const handleEditPricesClick = () => {
        if (currentFarm.plan.reportingLevel !== 'ALL_REPORTING') {
            if (!showUpgradeModal) {
                toggleUpgradeModal();
            }
            return;
        }

        changeModal({
            name: 'output-cost',
            props: {
                onSave: () => {
                    setPromise(
                        getYearOuputSummary(
                            currentFarm.uuid,
                            currentYear,
                            areaMeasurementUnit,
                            statusFilter === 'all' ? undefined : statusFilter
                        )
                    );
                },
            },
        });
    };

    let content: ReactNode = null;
    if (!pending && error) {
        content = (
            <ErrorMessage>
                <MarkdownNoPara components={{ a: ContactSupportLink }}>
                    {t('something_went_wrong_or_contact')}
                </MarkdownNoPara>
            </ErrorMessage>
        );
    } else if (reportData && reportData.size === 0 && statusFilter !== 'all') {
        content = (
            <p>
                {t(
                    statusFilter === 'todo'
                        ? 'yield_reporting_empty_incomplete'
                        : 'yield_reporting_empty_complete'
                )}
            </p>
        );
    } else if (reportData && reportData.size === 0) {
        content = (
            <div className="mt-8 text-xl text-center">
                <p className="text-fm-blue mb-5">{t('yield_reporting_empty1')}</p>
                <p className="text-fm-blue mb-5">
                    <MarkdownNoPara>{t('yield_reporting_empty2')}</MarkdownNoPara>
                </p>
                <p className="mt-8 mb-5 mx-auto w-1/4">
                    <Button
                        as={Link}
                        to={{ pathname: `/farms/${currentFarm.uuid}/operations/new` }}
                        state={{ operationType: 'HARVEST' }}
                        className="flex items-center justify-center"
                    >
                        {icon('field-job', 'white', 'mr-2')}
                        {t('add_a_field_job')}
                    </Button>
                </p>
                <img
                    className="mx-auto"
                    src={reportingEmpty}
                    alt="Screenshot of reporting in fieldmargin"
                />
            </div>
        );
    } else if (reportData) {
        content = (
            <Outlet
                context={
                    {
                        reportData,
                        areaMeasurementUnit,
                    } satisfies YeldReportingOuletSchema
                }
            />
        );
    }

    return (
        <div className="relative">
            {showUpgradeModal && <FeatureLockedModal onClose={toggleUpgradeModal} />}
            <Button
                as={Link}
                to={`/farms/${currentFarm.uuid}/reporting`}
                className="absolute top-0 left-0 flex items-center gap-2"
                variant="subtle"
            >
                {icon('back')} {t('back')}
            </Button>
            <h1 className="uppercase text-center">
                {currentYear} {t('yield_report_title')}
            </h1>
            {((reportData && reportData.size > 0) || statusFilter !== 'all') && (
                <div className="flex items-center mb-5">
                    <Button
                        onClick={handleEditPricesClick}
                        variant="outline"
                        className="flex items-center mr-4"
                        small
                    >
                        <img
                            src={editCostsSvg}
                            className="w-5 mr-2"
                            alt="Icon showing money bag and coins"
                        />
                        {t('yield_reporting_set_prices')}
                    </Button>
                    <Button
                        as={Link}
                        to={{
                            pathname: `/farms/${currentFarm.uuid}/operations/new`,
                        }}
                        state={{ operationType: 'HARVEST' }}
                        variant="outline"
                        small
                    >
                        {t('yield_reporting_add_job')}
                    </Button>
                    <ReportStatusFilter
                        filter={statusFilter}
                        setFilter={handleFilterChange}
                        disabled={pending}
                        className="ml-auto"
                    />
                    <div className="flex items-center">
                        <ExportReportButton
                            filename="yield-report"
                            downloadLink={getCsvDownloadLink(
                                currentFarm.uuid,
                                currentYear,
                                statusFilter,
                                'outputsSummary',
                                areaMeasurementUnit
                            )}
                        />
                        <Link
                            to={`/farms/${currentFarm.uuid}/reporting/yield`}
                            state={{
                                isTable: true,
                            }}
                            className="block ml-5"
                        >
                            {icon('table', isTable ? 'blue' : 'dark-blue')}
                        </Link>
                        <Link
                            to={`/farms/${currentFarm.uuid}/reporting/yield/graph`}
                            state={{
                                isTable: false,
                            }}
                            className="block ml-3"
                        >
                            {icon('graph', !isTable ? 'blue' : 'dark-blue')}
                        </Link>
                    </div>
                </div>
            )}
            <LoadableContent
                loading={!error && (reportData === undefined || pending)}
                delay={300}
                fallback={
                    <p>
                        <em>{t('reporting_loading')}</em>
                    </p>
                }
            >
                {content}
            </LoadableContent>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        fields: selectFieldsWithFullNames(state),
        areaMeasurementUnit: selectUserAreaMeasurementUnit(state),
    }),
    (dispatch) => bindActionCreators({ changeModal }, dispatch)
)(YieldReporting);
