import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import Modal from 'modal/Modal';
import type FullOperation from 'operations/FullOperation';
import type { FullOperationField } from 'operations/OperationField';
import { fullOperationFieldToOperationField } from 'operations/OperationField';
import { saveOperationField } from 'operations/operations-save-helper';
import { setOperation } from 'operations/operations-state';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { useAppSelector } from 'system/store';
import {
    selectUserId,
    selectUserLengthMeasurementUnit,
    selectUserTemperatureUnit,
} from 'users/user-state';

import type { MarkCompleteData } from './mark-complete-utils';
import { getMarkCompleteInitialValues, setOperationFieldComplete } from './mark-complete-utils';
import MarkCompleteForm from './MarkCompleteForm';

interface MarkCompleteModalProps {
    // from parents
    farm: Farm;
    fullOperation: FullOperation;
    operationField: FullOperationField;
    onClose: VoidFunction;
    onEditFields: VoidFunction;

    // from redux
    lengthMeasurementUnit: MeasurementUnit;
    temperatureUnit: string;
    setOperation: typeof setOperation;
}

const MarkCompleteModal = ({
    farm,
    fullOperation,
    operationField,
    onClose,
    onEditFields,
    lengthMeasurementUnit,
    temperatureUnit,
    setOperation,
}: MarkCompleteModalProps) => {
    const { t } = useTranslation();
    const userId = useAppSelector(selectUserId);

    const handleSubmit = async (values: MarkCompleteData) => {
        const savedOperation = await saveOperationField(
            farm.uuid,
            fullOperation,
            setOperationFieldComplete(fullOperationFieldToOperationField(operationField), values)
        );
        setOperation(savedOperation);
    };

    const handleEditFields = () => {
        onClose();
        onEditFields();
    };

    const initialValues: ComponentProps<typeof MarkCompleteForm>['initialValues'] =
        getMarkCompleteInitialValues(
            operationField,
            fullOperation,
            userId,
            lengthMeasurementUnit,
            temperatureUnit
        );

    return (
        <Modal onClose={onClose}>
            <div className="bg-white text-center p-6" style={{ width: 750 }}>
                <h2>{t('dialog_complete_title_web')}</h2>
                <p className="text-base">
                    <button
                        className="bare-btn underline text-fm-sky text-base"
                        onClick={handleEditFields}
                    >
                        {t('dialog_complete_desc_web')}
                    </button>
                </p>
                <MarkCompleteForm
                    onClose={onClose}
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                />
            </div>
        </Modal>
    );
};

export default connect(
    (state: AppState) => ({
        lengthMeasurementUnit: selectUserLengthMeasurementUnit(state),
        temperatureUnit: selectUserTemperatureUnit(state),
    }),
    (dispatch) => bindActionCreators({ setOperation }, dispatch)
)(MarkCompleteModal);
