import { usePageParams } from 'hooks/usePageParamas';
import { selectMapGroup } from 'maps/farm/maps-state';
import { useAppSelector } from 'system/store';

const usePageMapGroup = () => {
    const { mapGroupUuid } = usePageParams();
    const mapGroup = useAppSelector((state) => selectMapGroup(state, mapGroupUuid ?? ''));

    return { mapGroup };
};

export { usePageMapGroup };
