import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { usePromise } from '@fieldmargin/webapp-state';
import { stopEditing } from 'farm-editing/farm-editing-state';
import { bindActionCreators } from 'redux';
import EditableActions from 'sidebar/modules/common/EditableActions';
import type { AppState } from 'system/store';
import type { UnaryPromiseFunction } from 'system/types';

import FieldBoundaryContainer from './FieldBoundaryContainer';

interface FieldBoundaryFormProps {
    // From parent
    onStopEditing: VoidFunction;
    onSave: UnaryPromiseFunction<GeoFeatureCollection>;
    // From redux
    editingGeoFeatureCollection: GeoFeatureCollection | null;
    stopEditing: typeof stopEditing;
}

const FieldBoundaryForm = ({
    onStopEditing,
    onSave,
    editingGeoFeatureCollection,
    stopEditing,
}: FieldBoundaryFormProps) => {
    const { pending, error, setPromise } = usePromise(onStopEditing);
    const methods = useForm<{ shapes: { shapes?: GeoFeatureCollection; fields?: string[] } }>();

    useEffect(() => {
        return () => {
            stopEditing();
        };
    }, [stopEditing]);

    const handleShapesSave = () => {
        if (editingGeoFeatureCollection === null) {
            return;
        }
        setPromise(onSave(editingGeoFeatureCollection));
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleShapesSave)}>
                <FieldBoundaryContainer editingGeoFeatureCollection={editingGeoFeatureCollection} />
                <EditableActions
                    disabled={pending}
                    error={error}
                    setEditing={onStopEditing}
                    className="px-5 pb-5"
                />
            </form>
        </FormProvider>
    );
};

export default connect(
    (state: AppState) => ({
        editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,
    }),
    (dispatch) => bindActionCreators({ stopEditing }, dispatch)
)(FieldBoundaryForm);
