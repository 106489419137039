import { prefersImperialUnits } from 'lib/MeasurementUnit';
import { useAppSelector } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import { converter, hectaresRateToAcresRate, sqmToHectares } from 'utils/conversion';

import FormattedRate from './FormattedRate';

interface FormattedTotalProps {
    rate: number;
    areaSqm: number;
}

const FormattedTotal = ({ rate, areaSqm }: FormattedTotalProps) => {
    const areaUnit = useAppSelector(selectUserAreaMeasurementUnit);
    const convertedRate = prefersImperialUnits(areaUnit) ? hectaresRateToAcresRate(rate) : rate;
    const area = prefersImperialUnits(areaUnit)
        ? converter.convertSquareMetresToAcres(areaSqm)
        : sqmToHectares(areaSqm);
    return <FormattedRate rate={convertedRate * area} noConversion />;
};

export default FormattedTotal;
