import type { MouseEvent, ReactNode } from 'react';
import { Input, NumberField } from 'react-aria-components';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import { clsx } from 'clsx';
import { useField, useFormState } from 'informed';
import ErrorMessage from 'view/ErrorMessage';

import type { InputAndRecordingFormValues } from './NewInputAndRecordingForm';

interface NutrientFormFieldsProps {
    className?: string;
    alwaysShowMicronutrients?: boolean;
    initiallyShowMicronutrients?: boolean;
}

const NutrientFormFields = ({
    className,
    alwaysShowMicronutrients = false,
    initiallyShowMicronutrients = false,
}: NutrientFormFieldsProps) => {
    const { t } = useTranslation();
    const [showMicronutrients, toggleMicronutrients] = useToggle(initiallyShowMicronutrients);
    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        toggleMicronutrients();
    };
    const formState = useFormState<InputAndRecordingFormValues>();
    const hasNutrientErrors =
        formState.errors.nutrients !== undefined || formState.errors.micronutrients !== undefined;
    return (
        <div className={className}>
            {!alwaysShowMicronutrients && (
                <button className="bare-btn float-right text-gray-400 focus" onClick={handleClick}>
                    {!showMicronutrients && <em className="mr-2">{t('show_micronutrients')}</em>}{' '}
                    <i
                        className={clsx(
                            'text-black',
                            showMicronutrients ? 'ion-arrow-up-b' : 'ion-arrow-down-b'
                        )}
                    />
                </button>
            )}
            <h3 className="uppercase text-sm">{t('nutrients')}</h3>
            {hasNutrientErrors && <ErrorMessage>{t('nutrient_validation')}</ErrorMessage>}
            <div className="flex gap-3">
                <ChemicalFormField label="N %" field="nutrients.nitrogen" />
                <ChemicalFormField
                    label={
                        <>
                            P - P<sub>2</sub>O<sub>5</sub> %
                        </>
                    }
                    field="nutrients.phosphorus"
                />
                <ChemicalFormField
                    label={
                        <>
                            K - K<sub>2</sub>O %
                        </>
                    }
                    field="nutrients.potassium"
                />
                <ChemicalFormField
                    label={
                        <>
                            Na - Na<sub>2</sub>O %
                        </>
                    }
                    field="nutrients.sodium"
                />
                <ChemicalFormField label={<>MgO %</>} field="nutrients.magnesium" />
                <ChemicalFormField
                    label={
                        <>
                            S - SO<sub>3</sub> %
                        </>
                    }
                    field="nutrients.sulphur"
                />
            </div>
            <div
                className={clsx('flex flex-wrap gap-3 mt-3', {
                    hidden: !alwaysShowMicronutrients && !showMicronutrients,
                })}
            >
                <ChemicalFormField label="Ca %" field="micronutrients.calcium" />
                <ChemicalFormField label="Zn %" field="micronutrients.zinc" />
                <ChemicalFormField label="Mn %" field="micronutrients.manganese" />
                <ChemicalFormField label="Cu %" field="micronutrients.copper" />
                <ChemicalFormField label="B %" field="micronutrients.boron" />
                <ChemicalFormField label="Fe %" field="micronutrients.iron" />
                <ChemicalFormField label="Mo %" field="micronutrients.molybdenum" />
                <ChemicalFormField label="Se %" field="micronutrients.selenium" />
            </div>
        </div>
    );
};

const ChemicalFormField = ({ label, field }: { label: ReactNode; field: string }) => {
    const { fieldApi, fieldState } = useField<number>({ field });
    const handleChange = (value: number) => {
        fieldApi.setValue(value);
    };
    return (
        <div>
            <label htmlFor={field} className="cursor-pointer font-bold text-xs">
                {label}
            </label>
            <NumberField id={field} defaultValue={fieldState.value} onChange={handleChange}>
                <Input
                    autoComplete="off"
                    className={clsx('simple-text-input', 'w-20', { 'has-error': fieldState.error })}
                />
            </NumberField>
        </div>
    );
};

export default NutrientFormFields;
