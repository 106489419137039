import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { createGeoFeatureWithId } from '@fieldmargin/webapp-geo';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import { EditingType } from 'farm-editing/farm-editing-state';
import { List } from 'immutable';
import { logReselect } from 'lib/util/reselect-util';
import { createSelector } from 'reselect';
import { selectManualSensor } from 'sensors/manual/manual-sensors-state';
import type ManualSensor from 'sensors/manual/ManualSensor';
import type SensorSummary from 'sensors/SensorSummary';
import type { AppState } from 'system/store';
import type { ActiveSection } from 'system/url-util';

/**
 * - Sensors will only be shown when the active section is 'sensors'
 * - Sensors without a location will not be shown
 * - If a sensor is being edited other sensors will not be shown
 */
export const selectSensors = createSelector(
    (_state: AppState, activeSection: ActiveSection) => activeSection.main === 'data',
    (state: AppState) => state.sensorsState.sensorsByIntegration,
    (state: AppState) => state.manualSensorsState.sensors,
    (state: AppState, _activeSection, { selectedManualSensorId }) =>
        selectManualSensor(state, selectedManualSensorId),
    (state: AppState) => state.farmEditingState.editingType,
    (state: AppState) => state.farmEditingState.editingData,
    (state: AppState) => state.farmEditingState.editingGeoFeatureCollection,
    (
        isActiveSection,
        sensorsByIntegration,
        manualSensors,
        selectedManualSensor,
        editingType,
        editingData,
        editingGeoFeatureCollection
    ): GeoFeature[] => {
        logReselect('selectSensors');
        if (!isActiveSection) {
            return [];
        }

        if (editingType === EditingType.SENSOR && editingData.id && editingGeoFeatureCollection) {
            const features = editingGeoFeatureCollection.features.map((geoFeature) => {
                return geoFeature.set(
                    'properties',
                    OLFeatureProperties({
                        editable: true,
                    })
                );
            });
            return features.toArray();
        }

        if (editingType === EditingType.MANUAL_SENSOR && editingGeoFeatureCollection) {
            // Manual sensor being created or edited
            return editingGeoFeatureCollection.features
                .map((geoFeature) => {
                    return geoFeature.set(
                        'properties',
                        OLFeatureProperties({
                            editable: true,
                        })
                    );
                })
                .toArray();
        }

        if (!sensorsByIntegration && !manualSensors) {
            return [];
        }

        let features = List();

        if (selectedManualSensor !== undefined) {
            features = features.push(sensorToGeoFeature(selectedManualSensor));
            return features.toArray();
        }

        if (sensorsByIntegration) {
            features = features.concat(
                sensorsByIntegration
                    .filter((i) => i.sensors && i.sensors.size)
                    .toList()
                    .flatMap((farmIntegrationSensors) => {
                        return (farmIntegrationSensors.sensors as List<SensorSummary>)
                            .filter((sensor) => sensor.getLocation())
                            .map((sensor) => sensorToGeoFeature(sensor));
                    })
            );
        }

        if (manualSensors) {
            features = features.concat(
                manualSensors.map((manualSensor) => sensorToGeoFeature(manualSensor))
            );
        }
        return features.toArray();
    }
);

const sensorToGeoFeature = (sensor: SensorSummary | ManualSensor) => {
    return createGeoFeatureWithId(
        sensor.getLocation(),
        OLFeatureProperties({
            type: 'sensor',
            label: sensor.getName(),
            pointScale: sensor.active ? 10 : 4,
            colour: sensor.active ? '#4da4da' : '#c1c1c1',
        })
    );
};
