import { useMatch } from 'react-router-dom';
import { geojson } from '@fieldmargin/webapp-geo';
import type { FarmMapSensorProps } from 'farm-map/types';
import type { Feature, Geometry } from 'geojson';
import { useActiveSection } from 'hooks/useActiveSection';
import { notNil } from 'lib/fp-helpers';
import type ManualSensor from 'sensors/manual/ManualSensor';
import type SensorSummary from 'sensors/SensorSummary';
import { useAppSelector } from 'system/store';

export type FarmMapSensorFeature = Feature<Geometry, FarmMapSensorProps>;

/**
 * - Sensors will only be shown when the active section is 'sensors'
 * - Sensors without a location will not be shown
 * - If a sensor is viewed other sensors will not be shown
 * - If a sensor is being edited it will not be shown
 */
export const useFarmMapSensors = () => {
    const activeSection = useActiveSection();
    const sensorsByIntegration = useAppSelector((state) =>
        state.sensorsState.sensorsByIntegration
            ?.toList()
            .toArray()
            .flatMap((i) => i.sensors?.toArray())
            .filter(notNil)
    );
    const manualSensors = useAppSelector((state) => state.manualSensorsState.sensors?.toArray());
    const sensorId = useMatch('/farms/:farmUuid/data/:sensorId')?.params.sensorId;
    const editingId = useAppSelector((state) => state.farmEditingState.editingData.id);

    return getFarmMapSensors(manualSensors ?? [], sensorsByIntegration ?? [], {
        displaySensors: activeSection.main === 'data',
        ignoreUuids: [editingId ?? ''],
        sensorId,
    });
};

export const getFarmMapSensors = (
    manualSensors: ManualSensor[],
    integrationSensors: SensorSummary[],
    opts: { displaySensors: boolean; ignoreUuids: string[]; sensorId?: string }
) => {
    if (!opts.displaySensors) {
        return [];
    }

    const filteredManual = manualSensors.filter(
        (sensor) =>
            sensor.id !== null && !opts.ignoreUuids.includes(sensor.id) && sensor.location !== null
    );
    const filteredIntegration = integrationSensors.filter(
        (sensor) => !opts.ignoreUuids.includes(sensor.id) && sensor.getLocation() !== null
    );

    const features = filteredManual
        .map(manualSensorToFeature)
        .concat(filteredIntegration.map(integrationSensorToFeature));

    if (opts.sensorId !== undefined) {
        return features.filter((feature) => feature.properties.uuid === opts.sensorId);
    }

    return features;
};

const manualSensorToFeature = (sensor: ManualSensor) =>
    geojson.createFeatureWithId({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [sensor.location.coordinates.x, sensor.location.coordinates.y],
        },
        properties: {
            type: 'SENSOR',
            uuid: sensor.id as string,
            name: sensor.getName(),
            active: sensor.active,
        },
    }) as FarmMapSensorFeature;

const integrationSensorToFeature = (sensor: SensorSummary) =>
    geojson.createFeatureWithId({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [sensor.getLocation().coordinates.x, sensor.getLocation().coordinates.y],
        },
        properties: {
            type: 'SENSOR',
            uuid: sensor.id,
            name: sensor.getName(),
            active: sensor.active,
        },
    }) as FarmMapSensorFeature;
