export const unitDisplayNames = {
    GRAMS: {
        short: 'g',
        long: 'grams',
    },
    KILOGRAMS: {
        short: 'kg',
        long: 'kilograms',
    },
    METRIC_TONNES: {
        short: 't',
        long: 'metric tonnes',
    },
    MILLILITRES: {
        short: 'ml',
        long: 'millilitres',
    },
    LITRES: {
        short: 'L',
        long: 'litres',
    },
    OUNCES: {
        short: 'oz',
        long: 'ounces',
    },
    POUNDS: {
        short: 'lb',
        long: 'pounds',
    },
    IMPERIAL_TONS: {
        short: 'ton',
        long: 'US tons',
    },
    FLUID_OUNCES: {
        short: 'fl oz',
        long: 'fluid ounces',
    },
    GALLONS: {
        short: 'gal',
        long: 'US gallons',
    },
    COUNT: {
        short: '#',
        long: 'count',
    },
    CUBIC_METER: {
        short: 'm3',
        long: 'cubic Meter',
    },
    PINT: {
        short: 'pt',
        long: 'pint',
    },
    QUART: {
        short: 'qt',
        long: 'quart',
    },
    MEGALITRE: {
        short: 'ML',
        long: 'megalitre',
    },
    HUNDRED_WEIGHT: {
        short: 'cwt',
        long: 'hundredweight',
    },
    BUSHEL: {
        short: 'bsh',
        long: 'bushel',
    },
};

export const getUnitShortName = (unit: string) =>
    unitDisplayNames[unit] ? unitDisplayNames[unit].short : '';
export const getUnitLongName = (unit: string) =>
    unitDisplayNames[unit] ? unitDisplayNames[unit].long : '';
