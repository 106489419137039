import { createIconGenerator } from 'lib/util/icon-generator';

export type VisibilityIcon = 'border' | 'eye-on' | 'eye-off';

import borderIcon from './icons/border.svg?raw';
import eyeOffIcon from './icons/eye-off.svg?raw';
import eyeOnIcon from './icons/eye-on.svg?raw';

const src = {
    border: borderIcon,
    'eye-on': eyeOnIcon,
    'eye-off': eyeOffIcon,
};

export default createIconGenerator(src, 'icon');
