import { useDispatch, useSelector } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import type { List } from 'immutable';
import { Set } from 'immutable';
import type Input from 'inputs/Input';
import type { InputUnit } from 'inputs/Input';
import { propsToInput } from 'inputs/Input';
import { useGetOrCreateInput } from 'inputs/input-utils';
import type Nutrients from 'inputs/Nutrients';
import type { MicronutrientFormValues, Micronutrients, NutrientFormValues } from 'inputs/Nutrients';
import { defaultToZero, notNil } from 'lib/fp-helpers';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { renderMeasurementUnitShort } from 'lib/MeasurementUnit';
import { getUnitShortName } from 'lib/unit';
import { roundToDp, roundToSf } from 'lib/util/number';
import { capitaliseWord, numberToStringOrEmpty } from 'lib/util/text';
import { flow } from 'lodash';
import type FullOperation from 'operations/FullOperation';
import type Operation from 'operations/Operation';
import { saveOperationRecordingApi } from 'operations/operations-api';
import { setOperationRecordings } from 'operations/operations-state';
import type Recording from 'operations/Recording';
import { createNewRecording } from 'operations/Recording';
import { dissocPath, divide, multiply, useWith as ramdaUseWith, when } from 'ramda';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import { trackEvent } from 'utils/trackEvent';

export interface OperationRecording {
    input: Input;
    recording: Recording;
}

export const getRecordingInfo = (
    recordings: Set<Recording>,
    inputs: List<Input>
): List<OperationRecording> => {
    return recordings
        .filter((recording) => !recording.archived)
        .map((recording) => {
            return {
                recording,
                input: inputs.find((input) => input.uuid === recording.inputUuid),
            };
        })
        .filter(({ input }) => input !== undefined)
        .toList() as List<OperationRecording>;
};

export const formatRate = (rate: number) => {
    if (rate >= 10000 || roundToSf(rate, 5) >= 10000) {
        return `${roundToSf(rate / 1000, 4)}k`;
    }
    if (rate < 1) {
        return roundToDp(rate, 4).toString();
    }
    return roundToSf(rate, 5).toString();
};

export const calculateTotalFromRateAndArea = (area: number, rate: number) => area * rate;

export const calculateRateFromAreaAndTotal = (area: number, total: number) =>
    area === 0 ? 0 : total / area;

/**
 * Given an area, possible nutrients and micronutrients, returns a function that can be called
 * with a rate that will calculate the overall total and rates and totals for nutrients and
 * micronutrients.
 */
export const calculateRecordingFormValuesFromRate =
    (area: number, nutrients?: Nutrients, micronutrients?: Micronutrients) => (rate: number) => {
        const rateNum = defaultToZero(rate);
        return {
            total: numberToStringOrEmpty(calculateTotalFromRateAndArea(area, rateNum)),
            ...calculateNutrientRatesAndTotalsFromRate(area, nutrients, rateNum),
            ...calculateMicronutrientRatesAndTotalsFromRate(area, micronutrients, rateNum),
        };
    };

/**
 * Given an area, possible nutrients and micronutrients, returns a function that can be called
 * with a total that will calculate the overall rate and rates and totals for nutrients and
 * micronutrients.
 */
export const calculateRecordingFormValuesFromTotal =
    (area: number, nutrients?: Nutrients, micronutrients?: Micronutrients) => (total: number) => {
        const rateNum = calculateRateFromAreaAndTotal(area, defaultToZero(total));
        return {
            rate: numberToStringOrEmpty(rateNum),
            ...calculateNutrientRatesAndTotalsFromRate(area, nutrients, rateNum),
            ...calculateMicronutrientRatesAndTotalsFromRate(area, micronutrients, rateNum),
        };
    };

/**
 * Given a percentage rate for a nutrient or micronutrient & the rate set for that nutrient,
 * returns the overall rate that should be set.
 */
const calculateOverallRateFromNutrient = ramdaUseWith<number, number, number, number, number>(
    multiply,
    [divide(100), defaultToZero]
);

/**
 * Given an area, possible nutrients and micronutrients, returns a function that can be called
 * with a nutrient and the rate for that nutrient that will calculate:
 * - overall rate
 * - overall total
 * - total for the nutrient
 * - rates and totals for micronutrients
 *
 * If there are no nutrients provided the function will return undefined.
 */
export const calculateRecordingFormValuesFromNutrientRate =
    (area: number, nutrients?: Nutrients, micronutrients?: Micronutrients) =>
    (nutrient: string, nutrientRate: number): {} | undefined =>
        when(
            (n) => notNil(n?.get(nutrient, 0)),
            (nutrients: Nutrients) => {
                const overallRate = calculateOverallRateFromNutrient(
                    nutrients.get(nutrient, 0),
                    nutrientRate
                );
                return {
                    rate: numberToStringOrEmpty(overallRate),
                    total: numberToStringOrEmpty(calculateTotalFromRateAndArea(area, overallRate)),
                    ...dissocPath<{
                        nutrientRates?: NutrientFormValues;
                        nutrientTotals?: NutrientFormValues;
                    }>(
                        ['nutrientRates', nutrient],
                        calculateNutrientRatesAndTotalsFromRate(area, nutrients, overallRate)
                    ),
                    ...calculateMicronutrientRatesAndTotalsFromRate(
                        area,
                        micronutrients,
                        overallRate
                    ),
                };
            },
            nutrients
        );

/**
 * Given an area, possible nutrients and micronutrients, returns a function that can be called
 * with a nutrient and the total for that nutrient that will calculate:
 * - overall rate
 * - overall total
 * - rate for the nutrient
 * - rates and totals for micronutrients
 *
 * If there are no nutrients provided the function will return undefined.
 */
export const calculateRecordingFormValuesFromNutrientTotal =
    (area: number, nutrients?: Nutrients, micronutrients?: Micronutrients) =>
    (nutrient: string, nutrientTotal: number): {} | undefined =>
        when(
            (v) => notNil(v?.get(nutrient, 0)),
            (nutrients: Nutrients) => {
                const overallRate = calculateOverallRateFromNutrient(
                    nutrients.get(nutrient, 0),
                    calculateRateFromAreaAndTotal(area, defaultToZero(nutrientTotal))
                );
                return {
                    rate: numberToStringOrEmpty(overallRate),
                    total: numberToStringOrEmpty(calculateTotalFromRateAndArea(area, overallRate)),
                    ...dissocPath<{
                        nutrientRates?: NutrientFormValues;
                        nutrientTotals?: NutrientFormValues;
                    }>(
                        ['nutrientTotals', nutrient],
                        calculateNutrientRatesAndTotalsFromRate(area, nutrients, overallRate)
                    ),
                    ...calculateMicronutrientRatesAndTotalsFromRate(
                        area,
                        micronutrients,
                        overallRate
                    ),
                };
            },
            nutrients
        );

/**
 * Given an area, possible nutrients and micronutrients, returns a function that can be called
 * with a micronutrient and the rate for that micronutrient that will calculate:
 * - overall rate
 * - overall total
 * - total for the micronutrient
 * - rates and totals for nutrients
 *
 * If there are no nutrients provided the function will return undefined.
 */
export const calculateRecordingFormValuesFromMicronutrientRate =
    (area: number, nutrients?: Nutrients, micronutrients?: Micronutrients) =>
    (micronutrient: string, micronutrientRate: number): {} | undefined =>
        when(
            (n) => notNil(n?.get(micronutrient, 0)),
            (micronutrients: Micronutrients) => {
                const overallRate = calculateOverallRateFromNutrient(
                    micronutrients.get(micronutrient, 0),
                    micronutrientRate
                );
                return {
                    rate: numberToStringOrEmpty(overallRate),
                    total: numberToStringOrEmpty(calculateTotalFromRateAndArea(area, overallRate)),
                    ...dissocPath<{
                        micronutrientRates?: MicronutrientFormValues;
                        micronutrientTotals?: MicronutrientFormValues;
                    }>(
                        ['micronutrientRates', micronutrient],
                        calculateMicronutrientRatesAndTotalsFromRate(
                            area,
                            micronutrients,
                            overallRate
                        )
                    ),
                    ...calculateNutrientRatesAndTotalsFromRate(area, nutrients, overallRate),
                };
            },
            micronutrients
        );

/**
 * Given an area, possible nutrients and micronutrients, returns a function that can be called
 * with a micronutrient and the total for that micronutrient that will calculate:
 * - overall rate
 * - overall total
 * - rate for the micronutrient
 * - rates and totals for nutrients
 *
 * If there are no micronutrients provided the function will return undefined.
 */
export const calculateRecordingFormValuesFromMicronutrientTotal =
    (area: number, nutrients?: Nutrients, micronutrients?: Micronutrients) =>
    (micronutrient: string, micronutrientTotal: number): {} | undefined =>
        when(
            (v) => notNil(v?.get(micronutrient, 0)),
            (micronutrients: Micronutrients) => {
                const overallRate = calculateOverallRateFromNutrient(
                    micronutrients.get(micronutrient, 0),
                    calculateRateFromAreaAndTotal(area, defaultToZero(micronutrientTotal))
                );
                return {
                    rate: numberToStringOrEmpty(overallRate),
                    total: numberToStringOrEmpty(calculateTotalFromRateAndArea(area, overallRate)),
                    ...dissocPath<{
                        micronutrientRates?: MicronutrientFormValues;
                        micronutrientTotals?: MicronutrientFormValues;
                    }>(
                        ['micronutrientTotals', micronutrient],
                        calculateMicronutrientRatesAndTotalsFromRate(
                            area,
                            micronutrients,
                            overallRate
                        )
                    ),
                    ...calculateNutrientRatesAndTotalsFromRate(area, nutrients, overallRate),
                };
            },
            micronutrients
        );

export const calculateNutrientRate = (rate: number, nutrientPercentage: number) =>
    (rate / 100) * nutrientPercentage;

/**
 * Given an area, possible nutrients and an overall rate, this calculates the rates and totals for
 * each nutrient, or returns and empty object if there are no nutrients.
 */
export const calculateNutrientRatesAndTotalsFromRate = (
    totalArea: number,
    nutrients?: Nutrients,
    rate: number = 0
): { nutrientRates?: NutrientFormValues; nutrientTotals?: NutrientFormValues } => {
    return nutrients === undefined
        ? {}
        : Object.keys(nutrients.toObject()).reduce(
              (values, nutrient) => {
                  const nutrientRate = calculateNutrientRate(rate, nutrients.get(nutrient, 0));
                  values.nutrientRates[nutrient] = numberToStringOrEmpty(nutrientRate);
                  values.nutrientTotals[nutrient] = numberToStringOrEmpty(
                      calculateTotalFromRateAndArea(totalArea, nutrientRate)
                  );
                  return values;
              },
              {
                  nutrientRates: {} as NutrientFormValues,
                  nutrientTotals: {} as NutrientFormValues,
              }
          );
};

/**
 * Given an area, possible micronutrients and an overall rate, this calculates the rates and totals
 * for each micronutrient, or returns and empty object if there are no micronutrients.
 */
export const calculateMicronutrientRatesAndTotalsFromRate = (
    totalArea: number,
    micronutrients?: Micronutrients,
    rate: number = 0
): {
    micronutrientRates?: MicronutrientFormValues;
    micronutrientTotals?: MicronutrientFormValues;
} => {
    return micronutrients === undefined
        ? {}
        : Object.keys(micronutrients.toObject()).reduce(
              (values, micronutrient) => {
                  const micronutrientRate = calculateNutrientRate(
                      rate,
                      micronutrients.get(micronutrient, 0)
                  );
                  values.micronutrientRates[micronutrient] =
                      numberToStringOrEmpty(micronutrientRate);
                  values.micronutrientTotals[micronutrient] = numberToStringOrEmpty(
                      calculateTotalFromRateAndArea(totalArea, micronutrientRate)
                  );
                  return values;
              },
              {
                  micronutrientRates: {} as MicronutrientFormValues,
                  micronutrientTotals: {} as MicronutrientFormValues,
              }
          );
};

export const getRecordingAddedToastMessage = (
    name: string,
    type: string,
    unit: InputUnit,
    rate: number,
    measurementUnit: MeasurementUnit
) =>
    `${name} ${capitaliseWord(type)} added at ${rate}${getUnitShortName(
        unit
    )} / ${renderMeasurementUnitShort(measurementUnit)}`;

export const useSaveRecording = (fullOperation: FullOperation) => {
    const dispatch = useDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);
    const areaMeasurementUnit = useSelector<AppState, MeasurementUnit>(
        selectUserAreaMeasurementUnit
    );
    const getOrCreateInput = useGetOrCreateInput();

    const handleSaveRecording = (selectedInput: Input, rate: number) =>
        saveOperationRecording(
            fullOperation,
            rate,
            selectedInput,
            areaMeasurementUnit,
            flow(setOperationRecordings, dispatch)
        );

    const handleSaveNewInputAndRecording = (inputValues: Partial<Input>, rate: number) => {
        const newInput = propsToInput({ ...inputValues, farmUuid: farm.uuid });
        return getOrCreateInput(newInput).then((input) =>
            saveOperationRecording(
                fullOperation,
                rate,
                input,
                areaMeasurementUnit,
                flow(setOperationRecordings, dispatch)
            )
        );
    };

    return { handleSaveRecording, handleSaveNewInputAndRecording };
};

export const saveOperationRecording = async (
    fullOperation: FullOperation,
    rate: number,
    input: Input,
    userMeasurementUnit: MeasurementUnit,
    setOperationRecordings: (opts: { operation: Operation; newRecordings: Set<Recording> }) => void
) => {
    const recording = createNewRecording(rate, fullOperation.uuid, input.uuid, userMeasurementUnit);
    const savedRecording = await saveOperationRecordingApi(fullOperation.summary, recording);
    setOperationRecordings({
        operation: fullOperation.summary,
        newRecordings: fullOperation.recordings
            ? fullOperation.recordings.add(savedRecording)
            : Set.of(savedRecording),
    });
    trackEvent('Input added', {
        farmUuid: fullOperation.summary.farmUuid,
        jobUuid: fullOperation.uuid,
        inputUuid: recording.inputUuid,
    });
    return savedRecording;
};
