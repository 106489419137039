import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePromise } from '@fieldmargin/webapp-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { isUuid } from 'lib/util/text';
import { pipe } from 'ramda';
import type { AppState } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

import type Note from './Note';
import { setNote } from './note-actions';
import { getNote } from './notes-api_new';
import { selectNote } from './notes-selectors';

/**
 * A HOC that will load the full note, update it in redux and return the given component
 * with the full note and possible error as props.
 */
const withPageNote =
    <Props,>(Component: React.ComponentType<Props>) =>
    (props: Props) => {
        const navigate = useNavigate();
        const location = useLocation();
        const dispatch = useDispatch();
        const farm = useSelector(selectCurrentFarm);
        const year = useSelector(selectCurrentYear);
        const noteUuid = useParams<{ noteUuid: string }>().noteUuid;
        const note =
            noteUuid !== undefined
                ? useSelector<AppState, Note | undefined>((state) => selectNote(state, noteUuid))
                : undefined;

        const { error, setPromise } = usePromise<Note>(pipe(setNote, dispatch));

        useEffect(() => {
            if (noteUuid === undefined || !isUuid(noteUuid)) {
                navigate(`/farms/${farm.uuid}/notes`, { replace: true });
                return;
            }

            if (isUuid(noteUuid) && (note === undefined || location.state?.newNote !== true)) {
                setPromise(getNote(farm.uuid, year, noteUuid));
            }
        }, [farm, noteUuid]);

        return <Component {...props} note={note} error={error} />;
    };

export default withPageNote;
