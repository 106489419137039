import { useState } from 'react';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { extentFromGeo } from '@fieldmargin/webapp-geo';
import { usePromise } from '@fieldmargin/webapp-state';
import { AttachmentType } from 'farm-editing/attachments';
import {
    replaceEditingGeoFeatureCollection,
    setEditingAttachmentsByType,
    startAutoBoundary,
} from 'farm-editing/farm-editing-state';
import { fetchAutoBoundaries } from 'fields/sidebar/auto-boundary/auto-boundary-api';
import { Set } from 'immutable';
import { useAppDispatch } from 'system/store';

import { useEditingGeoFeatureCollection } from './useEditingGeoFeatureCollection';
import { usePageParams } from './usePageParamas';

const useSearchAutoBoundaryArea = (onAreaSelectedCallback?: () => void) => {
    const dispatch = useAppDispatch();
    const { farmUuid } = usePageParams();
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();
    const [noBoundaries, setNoBoundaries] = useState(false);

    const handleSelectAreaDone = (geoFeatureCollection: GeoFeatureCollection) => {
        dispatch(replaceEditingGeoFeatureCollection(geoFeatureCollection));
        dispatch(setEditingAttachmentsByType(AttachmentType.FIELD)(Set()));

        if (onAreaSelectedCallback) {
            onAreaSelectedCallback();
        }
    };

    const { pending, setPromise } = usePromise<GeoFeatureCollection>((featureCollection) => {
        if (featureCollection.features.size === 0) {
            setNoBoundaries(true);
            dispatch(startAutoBoundary());
        } else {
            handleSelectAreaDone(featureCollection);
        }
    });

    const onSearchAutoBoundaryArea = () => {
        if (editingGeoFeatureCollection === null) return;

        const extent = extentFromGeo(editingGeoFeatureCollection);

        if (extent !== null && farmUuid) {
            setPromise(fetchAutoBoundaries(farmUuid, extent));
        }
    };

    const isDisabled = editingGeoFeatureCollection === null || pending;

    return {
        noBoundaries,
        isDisabled,
        onSearchAutoBoundaryArea,
        isPending: pending,
    };
};

export { useSearchAutoBoundaryArea };
