import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { always, omit } from 'ramda';

import type { Organisation, OrganisationUser } from './organisation-state';

export const fetchOrganisation = (organisationUuid: string) =>
    authorizedRequest<Organisation>({
        url: `/farms-api/organisations/${organisationUuid}`,
    });

export const fetchOrganisationUsers = (organisationUuid: string) =>
    authorizedRequest<OrganisationUser[]>({
        url: `/farms-api/organisations/${organisationUuid}/users`,
    });

interface OrganisationInviteDTO {
    uuid: string;
    email: string;
    farms: string[];
    inviterId: number;
    organisationUUID: string;
}

export interface OrganisationInvite extends Omit<OrganisationInviteDTO, 'organisationUUID'> {
    organisation: Organisation;
}

export const fetchUserPendingOrganisationInvites = (): Promise<OrganisationInvite[]> =>
    authorizedRequest<OrganisationInviteDTO[]>({
        url: '/farms-api/organisations/pendingInvitesForUser',
    }).then((invites) =>
        Promise.all(
            invites.map((invite) =>
                fetchOrganisation(invite.organisationUUID).then((organisation) => ({
                    ...omit(['organisationUUID'], invite),
                    organisation,
                }))
            )
        )
    );

export const acceptOrganisationInvite = (organisationUuid: string, inviteUuid: string) =>
    authorizedRequest<void>({
        method: 'POST',
        url: `/farms-api/organisations/${organisationUuid}/users/acceptInvite`,
        params: {
            uuid: inviteUuid,
        },
    }).then(always(inviteUuid));

export const declineOrganisationInvite = (organisationUuid: string, inviteUuid: string) =>
    authorizedRequest<void>({
        method: 'POST',
        url: `/farms-api/organisations/${organisationUuid}/users/declineInvite`,
        params: {
            uuid: inviteUuid,
        },
    }).then(always(inviteUuid));
