import { Form } from 'informed';
import { useOutOfDatePromise } from 'lib/hooks';

import EditableActions from '../common/EditableActions';

import TitleText from './TitleText';

interface TitleFormProps {
    label: string;
    value: string;
    onSave: (newTitle: string) => Promise<any>;
    setEditing: (editing: boolean) => void;
}

interface FormValues {
    title: string;
}

const TitleForm = ({ label, value, onSave, setEditing }: TitleFormProps) => {
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(() => setEditing(false));

    const handleSubmit = (values: FormValues) => {
        setPromise(onSave(values.title));
    };

    return (
        <Form<FormValues> initialValues={{ title: value }} onSubmit={handleSubmit}>
            <h4>{label}</h4>
            <TitleText field="title" />
            <EditableActions
                disabled={pending}
                error={error}
                outOfDate={outOfDate}
                setEditing={setEditing}
            />
        </Form>
    );
};

export default TitleForm;
