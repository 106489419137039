import MapLayerIndex from 'components/maps/model/MapLayerIndex';
import type { AttachmentItem } from 'farm-editing/attachments';
import type { FarmMapFeature } from 'farm-map/types';
import { prop } from 'ramda';
import { useAppSelector } from 'system/store';

/**
 * Attachments are items like fields, or features that are attached to a note, operation or herd.
 */
export const useFarmMapEditingAttachments = (features: FarmMapFeature[]): FarmMapFeature[] => {
    const attachments = useAppSelector((state) =>
        state.farmEditingState.editingAttachments.toArray()
    );

    return getFarmMapEditingAttachments(features, attachments);
};

export const getFarmMapEditingAttachments = (
    features: FarmMapFeature[],
    attachments: AttachmentItem[]
): FarmMapFeature[] => {
    const attachmentIds = attachments.map(prop('id'));
    return features
        .filter(
            (feature) =>
                attachmentIds.includes(feature.properties.uuid) && feature.geometry !== null
        )
        .map(styleProps);
};

const styleProps = (feature: FarmMapFeature): FarmMapFeature => ({
    ...feature,
    properties: {
        ...feature.properties,
        colour: '#ffffff',
        pointScale: 6,
        strokeWeight: 2,
        strokeOpacity: 1,
        fillOpacity: 0.4,
        zIndex: MapLayerIndex.NOTE_FIELD_POLYGON,
        // @ts-ignore
        label: feature.properties.name,
    },
});
