import { createAction, handleActions } from '@fieldmargin/webapp-state';
import type FarmInvite from 'farms/invites/FarmInvite';
import type { Dispatch } from 'redux';
import { getFarmInvitesApi } from 'users/users-api';

export const fetchFarmInvites = (farmInviteUuid?: string) => (dispatch: Dispatch<any>) => {
    dispatch(setFetchFarmInvitesPending());
    getFarmInvitesApi(farmInviteUuid).then((invites) => dispatch(setInvites(invites)));
};

const setFetchFarmInvitesPending = createAction<FarmInviteState, never>(
    'Set fetch farm invites pending',
    (state) => ({ ...state, fetchFarmInvitesPending: true })
);
const setInvites = createAction<FarmInviteState, FarmInvite[]>('Set invites', (state, invites) => ({
    ...state,
    invites,
    fetchFarmInvitesPending: false,
}));

export const removeInvite = createAction<FarmInviteState, FarmInvite>(
    'Remove invite',
    (state, payload) => ({
        ...state,
        invites: state.invites?.filter((i) => i.farm.uuid !== payload.farm.uuid),
    })
);

export interface FarmInviteState {
    invites?: FarmInvite[];
    fetchFarmInvitesPending: boolean;
}

export const farmInvitesReducer = handleActions<FarmInviteState>(
    {
        fetchFarmInvitesPending: false,
    },
    [removeInvite, setFetchFarmInvitesPending, setInvites]
);
