import c from 'classnames';

export interface BadgeItemLike {
    colour: string;
    name: string;
    archived: boolean;
}

export interface BadgeItemWithIdLike extends BadgeItemLike {
    id: any;
}

interface BadgeItemProps {
    item: BadgeItemLike;
    className?: string;
    truncate?: boolean;
}

const BadgeItem = ({ item, className, truncate = true }: BadgeItemProps) => (
    <div className={c('flex flex-row items-center cursor-pointer', className)}>
        <div
            className="h-5 w-5 rounded mr-3 print:border-solid print:border-8 print:h-0 print:w-0"
            style={{
                borderColor: item.colour,
                backgroundColor: item.colour,
                flex: '0 0 auto',
            }}
        />
        <div className={c({ truncate })}>{item.name}</div>
    </div>
);

export default BadgeItem;
