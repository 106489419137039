import { startOfDay } from 'date-fns';
import type { Discussion } from 'discussions/Discussion';
import { List } from 'immutable';
import type Comment from 'lib/model/Comment';
import { splitList } from 'lib/util/list';
import type Media from 'media/Media';
import type FullOperation from 'operations/FullOperation';
import type { SingleParamVoidFunction } from 'system/types';

import type { ChatItem } from './chat-models';
import {
    ChatComment,
    ChatFile,
    ChatImage,
    ChatLog,
    DayChatGroup,
    UserChatGroup,
} from './chat-models';
import type ChatMessage from './ChatMessage';
import { isValidChatMessage } from './ChatMessage';

/**
 * Converts a list of comments, files and images into a ChatLog.
 */
const sortAndGroup = (items: List<ChatItem>) => {
    items = items.sortBy((item) => item.date.valueOf());

    // Items split by day in local time, List<List<ChatItem>>
    const itemsByDay = splitList(items, (item) => startOfDay(item.date).valueOf());

    const dayChatGroups = itemsByDay.map((dayItems) => {
        // Items on this day grouped by user, List<List<ChatItem>>
        const itemsByDayAndUser = splitList(dayItems, (item) => item.userId);
        const userChatGroups = itemsByDayAndUser.map(
            (items) =>
                new UserChatGroup({
                    userId: (items.get(0) as ChatItem).userId,
                    items,
                })
        );

        return new DayChatGroup({
            date: startOfDay((dayItems.get(0) as ChatItem).date),
            userChatGroups,
        });
    });

    return new ChatLog({
        dayChatGroups,
    });
};

/**
 * Creates the farm chat log, containing comments, files and images.
 * @return {ChatLog} log broken down by day and user
 */
export const createFarmChatLog = (
    messages: List<ChatMessage>,
    getImageUrl: (message: ChatMessage, thumb?: boolean) => string,
    getFileUrl: (message: ChatMessage) => string,
    chatMediaOpen: SingleParamVoidFunction<string>
) => {
    const imageMapper = messageToChatImage(getImageUrl, chatMediaOpen);
    const fileMapper = messageToChatFile(getFileUrl);
    return sortAndGroup(
        messages
            .filter(isValidChatMessage)
            .map((message) =>
                message.type === 'text'
                    ? messageToChatComment(message)
                    : message.type === 'image'
                      ? imageMapper(message)
                      : fileMapper(message)
            )
    );
};

/**
 * Creates a chat log for displaying on a note, containing comments, files and images.
 * @return {ChatLog} log broken down by day and user
 */
export const createNoteChatLog = (
    media: Media[],
    discussions: Discussion[],
    getMediaThumbUrl: (media: Media) => string,
    noteMediaOpen?: SingleParamVoidFunction<number>
) => {
    const mediaMapper = noteMediaToChatItem(getMediaThumbUrl, noteMediaOpen);
    return sortAndGroup(List(discussions.map(discussionToChatItem)).concat(media.map(mediaMapper)));
};

/**
 * Creates an chat log for displaying on an operation, containing comments, files and images.
 * @return {ChatLog} log broken down by day and user
 */
export const createOperationChatLog = (
    operation: FullOperation,
    getThumbUrl: (mediaId: string) => string,
    onClick: SingleParamVoidFunction<number>
) => {
    const mediaMapper = operationMediaToChatItem(getThumbUrl, onClick);
    const comments = operation.comments ?? List<Comment>();
    const media = operation.media ?? List<Media>();
    return sortAndGroup(comments.map(commentToChatItem).concat(media.map(mediaMapper)));
};

const discussionToChatItem = (discussion: Discussion) =>
    new ChatComment({
        id: discussion.id.toString(),
        date: discussion.createdDate,
        userId: discussion.createdByUserId,
        comment: discussion.comment,
    });

const commentToChatItem = (comment: Comment) =>
    new ChatComment({
        id: comment.id.toString(),
        date: comment.createdDate,
        userId: comment.createdByUserId,
        comment: comment.comment,
    });

const noteMediaToChatItem =
    (getThumbUrl: (media: Media) => string, onClick?: SingleParamVoidFunction<number>) =>
    (media: Media, mediaIndex: number) =>
        media.mediaType === 'image'
            ? new ChatImage({
                  id: media.id.toString(),
                  date: media.createdDate as Date,
                  userId: media.createdByUserId,
                  thumbUrl: getThumbUrl(media),
                  onClick: onClick
                      ? () => {
                            onClick(mediaIndex);
                        }
                      : undefined,
              })
            : new ChatFile({
                  id: media.id.toString(),
                  date: media.createdDate as Date,
                  userId: media.createdByUserId,
                  url: media.url,
                  mediaType: media.mediaType,
                  mediaSize: media.size,
                  fileName: media.fileName,
              });

const operationMediaToChatItem =
    (getThumbUrl: (mediaId: string) => string, onClick?: SingleParamVoidFunction<number>) =>
    (media: Media, mediaIndex: number) =>
        media.mediaType === 'image'
            ? new ChatImage({
                  id: media.id.toString(),
                  date: media.createdDate as Date,
                  userId: media.createdByUserId,
                  thumbUrl: getThumbUrl(media.id),
                  onClick: onClick
                      ? () => {
                            onClick(mediaIndex);
                        }
                      : undefined,
              })
            : new ChatFile({
                  id: media.id.toString(),
                  date: media.createdDate as Date,
                  userId: media.createdByUserId,
                  url: media.url,
                  mediaType: media.mediaType,
                  mediaSize: media.size,
                  fileName: media.fileName,
              });

const messageToChatComment = (message: ChatMessage) =>
    new ChatComment({
        id: message.uuid,
        date: message.createdDate,
        userId: message.createdByUserId,
        comment: message.text,
    });

const messageToChatImage =
    (
        getChatImageUrl: (message: ChatMessage, thumb?: boolean) => string,
        onClick?: SingleParamVoidFunction<string>
    ) =>
    (message: ChatMessage) =>
        new ChatImage({
            id: message.uuid,
            date: message.createdDate,
            userId: message.createdByUserId,
            thumbUrl: getChatImageUrl(message, true),
            onClick: onClick
                ? () => {
                      onClick(getChatImageUrl(message));
                  }
                : undefined,
            isFarmChat: true,
        });

const messageToChatFile =
    (getChatFileUrl: (message: ChatMessage) => string) => (message: ChatMessage) =>
        new ChatFile({
            id: message.uuid,
            date: message.createdDate,
            userId: message.createdByUserId,
            url: getChatFileUrl(message),
            mediaType: message.type,
            mediaSize: message.fileSize,
            fileName: message.fileName,
            isFarmChat: true,
        });
