import { createLogger } from '@fieldmargin/webapp-reporting';
import { getMediaTypeFromMimeType } from 'media/Media';

import { defaultToUndefined } from './fp-helpers';

const logger = createLogger('lib.LastActivity');

export type ActivityType =
    | 'created'
    | 'comment'
    | 'image'
    | 'file'
    | 'todo'
    | 'completed'
    | 'operation_field';

export interface LastActivity {
    date: Date;
    type: ActivityType;
    userId: number;
    metadata?: string; // e.g. a comment or the field name
}

type NoteEventTypes =
    | 'NOTE_CREATED'
    | 'NOTE_CREATED_TASK'
    | 'NOTE_COMPLETED_TASK'
    | 'COMMENT_ADDED';

export type LastActivityDTO =
    | {
          eventType: 'MEDIA_UPLOADED';
          timestamp: number;
          userId: number;
          metadata: { MIME_TYPE: string };
      }
    | {
          eventType: NoteEventTypes;
          timestamp: number;
          userId: number;
          metadata: null;
      };

export const deserializeLastActivity = (json: LastActivityDTO): LastActivity | null => {
    let type: ActivityType | undefined = undefined;
    let metadata: string | undefined = undefined;

    switch (json.eventType) {
        case 'NOTE_CREATED':
            type = 'created';
            break;
        case 'NOTE_CREATED_TASK':
            type = 'todo';
            break;
        case 'NOTE_COMPLETED_TASK':
            type = 'completed';
            break;
        case 'MEDIA_UPLOADED': {
            const mediaType = getMediaTypeFromMimeType(json.metadata.MIME_TYPE);
            type = mediaType === 'image' ? 'image' : 'file';
            break;
        }
        case 'COMMENT_ADDED':
            type = 'comment';
            metadata = json.metadata ?? undefined;
            break;
        default:
    }

    if (!type) {
        logger.warn('Unable to parse legacy last activity', json);
        return null;
    }

    return {
        type,
        date: new Date(json.timestamp),
        userId: json.userId,
        metadata,
    };
};

/**
 * @deprecated
 */
export interface LegacyLastActivityDTO {
    date: number;
    category:
        | 'note'
        | 'note_discussion'
        | 'note_media'
        | 'operation'
        | 'operation_discussion'
        | 'operation_media'
        | 'operation_field';
    action: 'new' | 'todo' | 'completed';
    metadata: string | null;
    userId: number;
}

/**
 * @deprecated
 */
export const deserializeLegacyLastActivity = (json: LegacyLastActivityDTO): LastActivity | null => {
    const { category, action } = json;
    let type = null as ActivityType | null;
    if (category === 'note' || category === 'operation') {
        if (action === 'new') {
            type = 'created';
        } else if (action === 'todo') {
            type = 'todo';
        } else if (action === 'completed') {
            type = 'completed';
        }
    } else if (category === 'note_discussion' || category === 'operation_discussion') {
        type = 'comment';
    } else if (category === 'note_media' || category === 'operation_media') {
        if (json.metadata === 'file') {
            type = 'file';
        } else if (json.metadata === 'image') {
            type = 'image';
        }
    } else if (category === 'operation_field') {
        type = 'operation_field';
    }

    if (!type) {
        logger.warn('Unable to parse legacy last activity', json);
        return null;
    }

    return {
        type,
        date: new Date(json.date),
        userId: json.userId,
        metadata: defaultToUndefined(json.metadata),
    };
};
