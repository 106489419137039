import type Input from 'inputs/Input';

import ShortAreaUnit from './ShortAreaUnit';
import ShortUnit from './ShortUnit';

interface ShortInputRateProps {
    input: Input;
}

const ShortInputRate = ({ input }: ShortInputRateProps) => {
    return (
        <>
            <ShortUnit unit={input.unit} />/<ShortAreaUnit />
        </>
    );
};

export default ShortInputRate;
