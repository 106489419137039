import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { defaultTo } from 'ramda';

import type { Discussion, DiscussionDTO, DiscussionItemType } from './Discussion';
import { deserializeDiscussion } from './Discussion';

/**
 * Fetch dicussions for an item from the server.
 * Resolves with a list of discussions.
 */
export const getItemDiscussions = (
    farmUuid: string,
    itemUuid: string,
    itemType: DiscussionItemType
) =>
    authorizedRequest<{ comments: DiscussionDTO[] } | null>({
        method: 'get',
        url: `/notes-api/farms/${farmUuid}/discussions/${itemType}/${itemUuid}/comments`,
    }).then((discussions) =>
        defaultTo(<Discussion[]>[], discussions?.comments?.map(deserializeDiscussion))
    );

/**
 * Save a discussion against an item on the server.
 * Resolves with the ID of the new discussion item.
 */
export const createDiscussion = (
    farmUuid: string,
    itemUuid: string,
    itemType: DiscussionItemType,
    discussion: string
) =>
    authorizedRequest<string>({
        method: 'post',
        url: `/notes-api/farms/${farmUuid}/discussions/${itemType}/${itemUuid}/comments`,
        data: { comment: discussion },
    });
