import { getCreatedDate } from 'activity/activity-helper';
import type Field from 'fields/Field';
import type {
    HerdArchiveEvent,
    HerdEvent,
    HerdLocation,
    HerdMergeEvent,
    HerdNewEvent,
    HerdNewFromSplitEvent,
    HerdUnarchiveEvent,
    HerdUpdateEvent,
} from 'herd/Herd';
import type Herd from 'herd/Herd';
import { HerdEventAction, HerdEventCategory, herdLocationAsRemove } from 'herd/Herd';
import { fetchHerdHistory, fetchHerdNotes } from 'herd/herd-api';
import type { List } from 'immutable';
import { sortByDate } from 'lib/fp-helpers';
import type Note from 'notes/Note';
import { prop, sortBy } from 'ramda';

export type HerdHistoryNewFromSplit = {
    type: 'new-from-split';
    item: { name: string; uuid: string };
    date: Date;
};
export type HerdHistorySizeUpdate = {
    type: 'size-update';
    item: { difference: number; userId: number };
    date: Date;
};
export type HerdHistoryArchive = {
    type: 'archive';
    item: { userId: number };
    date: Date;
};
export type HerdHistoryUnarchive = {
    type: 'unarchive';
    item: { userId: number };
    date: Date;
};
export type HerdHistoryMerge = {
    type: 'merge';
    item: { name: string; uuid: string }[];
    date: Date;
};
export type HerdHistoryNote = {
    type: 'note';
    item: Note;
    date: Date;
};
export type HerdHistoryMove = {
    type: 'move';
    item: { herdLocation: HerdLocation; fieldName?: string };
    date: Date;
};
export type HerdHistoryRemove = {
    type: 'remove';
    item: { herdLocation: HerdLocation; fieldName?: string };
    date: Date;
};

export type HerdHistoryItem =
    | HerdHistoryNewFromSplit
    | HerdHistorySizeUpdate
    | HerdHistoryArchive
    | HerdHistoryUnarchive
    | HerdHistoryMerge
    | HerdHistoryNote
    | HerdHistoryMove
    | HerdHistoryRemove;

// Utility functions for checking the type of a herd event - this allows typescript to infer
// types when using array methods.
const herdEventIsNewFromSplit = (event: HerdEvent): event is HerdNewFromSplitEvent =>
    event.action === HerdEventAction.NEW_FROM_SPLIT;
const herdEventIsNewOrUpdateAndHerdCategory = (
    event: HerdEvent
): event is HerdNewEvent | HerdUpdateEvent =>
    event.category === HerdEventCategory.HERD &&
    (event.action === HerdEventAction.NEW || event.action === HerdEventAction.UPDATE);
const herdEventIsArchiveOrUnarchive = (
    event: HerdEvent
): event is HerdArchiveEvent | HerdUnarchiveEvent =>
    event.action === HerdEventAction.ARCHIVE || event.action === HerdEventAction.UNARCHIVE;
const herdEventIsMerge = (event: HerdEvent): event is HerdMergeEvent =>
    event.action === HerdEventAction.MERGE;

/**
 * Load herd history from the server and also load any associated notes since these may not
 * have been loaded when a herd is viewed.
 */
export const loadHerdHistory = async (farmUuid: string, herd: Herd) => {
    const { herdHistories, herdLocations } = await fetchHerdHistory(herd);
    const notes = await fetchHerdNotes(farmUuid, herd.uuid);
    return {
        herdHistories,
        herdLocations,
        notes,
    };
};

/**
 * Generate history for a single herd. The items will be returned sorted by date descending.
 */
export const generateHerdHistory = (
    herdEvents: HerdEvent[],
    herdLocations: HerdLocation[],
    fields: List<Field>,
    notes: Note[]
): HerdHistoryItem[] => {
    if (herdEvents.length === 0 && herdLocations.length === 0 && notes.length === 0) {
        return [];
    }

    const newFromSplitItems = generateNewFromSplitItem(herdEvents);
    const sizeItems = generateHerdSizeItems(herdEvents);
    const archiveItems = generateHerdArchiveItems(herdEvents);
    const locationItems = generateHerdMoveItems(herdLocations, fields);
    const mergeItems = generateHerdMergeItems(herdEvents);
    const noteItems = generateHerdNoteItems(notes);
    return sortByDate([
        ...newFromSplitItems,
        ...sizeItems,
        ...archiveItems,
        ...locationItems,
        ...mergeItems,
        ...noteItems,
    ]).reverse();
};

const generateNewFromSplitItem = (herdEvents: HerdEvent[]): HerdHistoryNewFromSplit[] =>
    sortByDate(herdEvents.filter<HerdNewFromSplitEvent>(herdEventIsNewFromSplit)).map((event) => ({
        type: 'new-from-split',
        item: event.data,
        date: event.date,
    }));

/**
 * Take the new and update events and converts them into size-update history items
 * ordered by date ascending.
 */
const generateHerdSizeItems = (herdEvents: HerdEvent[]): HerdHistorySizeUpdate[] =>
    sortByDate(
        herdEvents.filter<HerdUpdateEvent | HerdNewEvent>(herdEventIsNewOrUpdateAndHerdCategory)
    ).reduce((items, event, key, events) => {
        // First item has nothing to compare against so move on...
        if (key === 0) {
            return items;
        }
        // If there is a previous event, compare the size against it to see if we need to add an item
        const previousEvent = events[key - 1];
        if (
            event.action === HerdEventAction.UPDATE &&
            previousEvent !== undefined &&
            previousEvent.data.size !== event.data.size
        ) {
            return [
                ...items,
                {
                    type: 'size-update',
                    item: {
                        difference: event.data.size - previousEvent.data.size,
                        userId: event.data.lastModifiedByUserId,
                    },
                    date: event.date,
                },
            ];
        }
        return items;
    }, []);

/**
 * Generate archived and unarchived history items.
 */
const generateHerdArchiveItems = (
    herdEvents: HerdEvent[]
): (HerdHistoryArchive | HerdHistoryUnarchive)[] =>
    herdEvents
        .filter<HerdArchiveEvent | HerdUnarchiveEvent>(herdEventIsArchiveOrUnarchive)
        .map((event) => ({
            type: event.action === HerdEventAction.ARCHIVE ? 'archive' : 'unarchive',
            item: { userId: event.data.userId },
            date: event.date,
        }));

/**
 * Take the herd locations and convert them into `move` or `remove` history items orderd
 * by date ascending.
 */
const generateHerdMoveItems = (
    herdLocations: HerdLocation[],
    fields: List<Field>
): (HerdHistoryMove | HerdHistoryRemove)[] =>
    sortBy(prop('moveDate'), herdLocations).reduce((items, herdLocation, key, herdLocations) => {
        const field = fields.find((field) => field.uuid === herdLocation.fieldUuid);

        // Every location that has a field UUID has a move item, even if it's not the latest.
        // This is because it would have been the latest at some point and we want to
        // show a move for that.
        let nextItems: (HerdHistoryMove | HerdHistoryRemove)[] = [
            ...items,
            herdLocationToMoveItem(herdLocation, field),
        ];

        const nextMove = herdLocations[key + 1];
        // If the location is not the latest, it also has a remove item, provided
        // there is a next item in the list
        if (!herdLocation.latest && herdLocation.durationHours !== undefined && nextMove) {
            nextItems = [
                ...nextItems,
                herdLocationToRemove(herdLocationAsRemove(herdLocation, nextMove), field),
            ];
        }
        return nextItems;
    }, []);

const herdLocationToMoveItem = (herdLocation: HerdLocation, field?: Field): HerdHistoryMove => ({
    type: 'move',
    item: { herdLocation, fieldName: field ? field.name : undefined },
    date: herdLocation.moveDate,
});
const herdLocationToRemove = (herdLocation: HerdLocation, field?: Field): HerdHistoryRemove => ({
    type: 'remove',
    item: {
        herdLocation,
        fieldName: field ? field.name : undefined,
    },
    date: herdLocation.moveDate,
});

const generateHerdNoteItems = (notes: Note[]): HerdHistoryNote[] => {
    return notes.map((note) => ({
        type: 'note',
        item: note,
        date: getCreatedDate(note) ?? new Date(),
    }));
};

const generateHerdMergeItems = (herdEvents: HerdEvent[]): HerdHistoryMerge[] =>
    herdEvents.filter(herdEventIsMerge).map((event) => ({
        type: 'merge',
        item: event.data,
        date: event.date,
    }));
