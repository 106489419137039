import OnboardingTooltip from 'onboarding/OnboardingTooltip';

interface OperationOnboardingProps {
    onSkip: VoidFunction;
}

const OperationOnboarding = ({ onSkip }: OperationOnboardingProps) => (
    <OnboardingTooltip
        style={{ top: 468, left: 320 }}
        className="OperationOnboarding"
        onSkip={onSkip}
        link="http://support.fieldmargin.com/en/articles/3448718-how-to-make-a-field-job"
    >
        <p>
            Add a Field Job
            <br />
            Record inputs such as seed, spray or fertilizer
        </p>
    </OnboardingTooltip>
);

export default OperationOnboarding;
