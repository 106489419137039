import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import Button from '@fieldmargin/webapp-styling/components/button';
import LoadableContent from '@fieldmargin/webapp-styling/components/loadable-content';
import icon from 'components/icons/icon';
import FieldHistoryDay from 'fields/history/FieldHistoryDay';
import EmptyState from 'sidebar/modules/EmptyState';

import { useFieldTabsContext } from '../useFieldTabsContext';

import fieldHistoryEmpty from './field-history-empty.png';
import { useFieldHistory } from './field-history-generator';

const FieldHistory = () => {
    const { t } = useTranslation();
    const { field, herdLocations, farm } = useFieldTabsContext();
    const historyByDay = useFieldHistory(field, herdLocations);

    return (
        <div className="FieldHistory pb-5 flex flex-col">
            {farm.plan.domainPrinting && herdLocations && (
                <Button
                    as={Link}
                    small
                    className="mx-5 mt-5 inline-flex items-center self-end"
                    to={`/farms/${farm.uuid}/fields/${field.uuid}/print`}
                >
                    {icon('print', 'white', 'mr-3')}
                    {t('field_history_print')}
                </Button>
            )}
            <LoadableContent
                loading={herdLocations === undefined}
                delay={300}
                fallback={
                    <p className="p-5">
                        <em className="text-gray-550">{t('loading')}</em>
                    </p>
                }
            >
                {herdLocations !== undefined &&
                    historyByDay &&
                    (historyByDay.size === 0 ? (
                        <EmptyState
                            header={t('field_history_empty_web_title')}
                            image={fieldHistoryEmpty}
                        >
                            <Markdown>{t('field_history_empty_web_desc')}</Markdown>
                        </EmptyState>
                    ) : (
                        <div className="field-history-list">
                            {historyByDay.map((history, index) => (
                                <FieldHistoryDay field={field} history={history} key={index} />
                            ))}
                        </div>
                    ))}
            </LoadableContent>
        </div>
    );
};

export default FieldHistory;
