import type { Activity } from 'activity/activity-helper';
import { isNote } from 'activity/activity-helper';
import type { List } from 'immutable';

export const filterActivitiesByNotes = (
    isNotesActive: boolean,
    activities: List<Activity>
): List<Activity> => {
    return isNotesActive ? activities.filter((activity) => isNote(activity)) : activities;
};
