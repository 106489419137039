import { envConfig } from 'config/env-config';

interface FeaturesSchema {
    features: {
        adminTools: boolean;
        maintenanceMode: boolean;
        premiumMaps: boolean;
        thirtyMhz: boolean;
        printingOveride: boolean;
        newFarmMap: boolean;
    };
}

interface FarmFeaturesSchema {
    farmFeatures?: Record<
        string,
        Partial<{
            adminTools: boolean;
            maintenanceMode: boolean;
            premiumMaps: boolean;
            thirtyMhz: boolean;
            printingOveride: boolean;
            newFarmMap: boolean;
        }>
    >;
}

type FeaturesFlagsSchema = FeaturesSchema & FarmFeaturesSchema;

const mode = envConfig.mode;

const featureFlagsConfig: FeaturesFlagsSchema =
    mode === 'production' || mode === 'staging'
        ? {
              features: {
                  adminTools: false,
                  maintenanceMode: false,
                  premiumMaps: true,
                  thirtyMhz: false,
                  printingOveride: false,
                  newFarmMap: false,
              },
              farmFeatures: {
                  // Camilla's farms - these are on a free trial instead of paying
                  '59b3a9b8-1238-8725-826a-0ed602617dfb': {
                      printingOveride: true,
                  },
                  '648388ea-0ae9-42ec-6444-fe66c00a74c6': {
                      printingOveride: true,
                  },
                  '1020d7e2-cfd6-59b7-cc94-fc98e3bb80f0': {
                      printingOveride: true,
                  },
                  'd4ece5dc-e000-0f02-c440-87e48cc07891': {
                      printingOveride: true,
                  },
                  // Mark's farms - these are on a free trial instead of paying
                  '7db079c8-b650-b5a0-b50b-ec1e70f02938': {
                      printingOveride: true,
                  },
                  'a92fc5a4-ce44-47c1-a41a-8d26852b3619': {
                      printingOveride: true,
                  },
                  '52beee14-3abf-4efc-a896-755ba2be3724': {
                      printingOveride: true,
                  },
                  '6b48bc9c-9d9e-457c-bf22-33cab2c0636b': {
                      printingOveride: true,
                  },
              },
          }
        : {
              features: {
                  adminTools: false,
                  maintenanceMode: false,
                  premiumMaps: false,
                  thirtyMhz: false,
                  printingOveride: false,
                  newFarmMap: false,
              },
              farmFeatures: {},
          };

export { featureFlagsConfig };
