import { connect } from 'react-redux';
import type { Map } from 'immutable';
import type Input from 'inputs/Input';
import { selectInputMap } from 'inputs/inputs-state';
import type FullOperation from 'operations/FullOperation';
import type Recording from 'operations/Recording';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import FormattedRate from 'view/units/FormattedRate';
import ShortInputRate from 'view/units/ShortInputRate';

interface FieldHistoryOperationRecordingsProps {
    fullOperation: FullOperation;
    inputMap: Map<string, Input>;
}

const FieldHistoryOperationRecordings = ({
    fullOperation,
    inputMap,
}: FieldHistoryOperationRecordingsProps) => {
    if (fullOperation.recordings === null) {
        return null;
    }

    return (
        <div className="FieldHistoryOperationRecordings">
            {fullOperation.recordings
                .filter((recording) => !recording.archived)
                .map((recording) => (
                    <FieldHistoryOperationRecording
                        key={recording.uuid}
                        recording={recording}
                        inputMap={inputMap}
                    />
                ))}
        </div>
    );
};

const FieldHistoryOperationRecording = ({
    recording,
    inputMap,
}: {
    recording: Recording;
    inputMap: Map<string, Input>;
}) => {
    const input = inputMap.get(recording.inputUuid);
    if (!input) {
        return null;
    }
    return (
        <span className="block">
            {input.name} @ <FormattedRate rate={recording.rate} />
            <ShortInputRate input={input} />
        </span>
    );
};

export default connect((state: AppState) => ({
    inputMap: selectInputMap(state),
    areaMeasurementUnit: selectUserAreaMeasurementUnit(state),
}))(FieldHistoryOperationRecordings);
