interface VegetationIconProps {
    fill?: string;
    className?: string;
}

const VegetationIcon = ({ fill = '#4da4da', className }: VegetationIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 55.31 59.26"
            className={className}
        >
            <path
                style={{ fill }}
                d="M48.56,12.22A12.91,12.91,0,0,0,39,8a12.05,12.05,0,0,0-7.54,2.61,15.41,15.41,0,0,0-3.05,3.18,15.41,15.41,0,0,0-3.05-3.18A12.05,12.05,0,0,0,17.78,8a12.91,12.91,0,0,0-9.6,4.18A15,15,0,0,0,4.37,22.47c0,4.06,1.51,7.78,4.76,11.69,2.91,3.51,7.08,7.07,11.92,11.19h0c1.65,1.4,3.52,3,5.45,4.69a2.82,2.82,0,0,0,3.72,0c1.94-1.7,3.81-3.29,5.46-4.7,4.84-4.12,9-7.68,11.92-11.19,3.25-3.91,4.76-7.63,4.76-11.69A15,15,0,0,0,48.56,12.22ZM26.81,31H25.24a8.15,8.15,0,0,1-8-6.89H19A8,8,0,0,1,23,25.21,8.17,8.17,0,0,1,26,28.13L27.65,31h-.84Zm18.64,1.38c-2.75,3.32-6.84,6.8-11.58,10.84l-3.94,3.37V33.81H31.5a11,11,0,0,0,11-11,1.57,1.57,0,0,0-1.57-1.57H37.76a10.93,10.93,0,0,0-9.39,5.4A10.93,10.93,0,0,0,19,21.29H15.86a1.57,1.57,0,0,0-1.57,1.57,11,11,0,0,0,11,11h1.57V46.58c-1.39-1.2-2.72-2.33-3.93-3.36h0c-4.73-4-8.82-7.52-11.57-10.84s-4.12-6.49-4.12-9.9a12.17,12.17,0,0,1,3.07-8.34,10.11,10.11,0,0,1,7.53-3.27,9.31,9.31,0,0,1,5.82,2,13.61,13.61,0,0,1,3.25,3.76,1.75,1.75,0,0,0,1.52.86,1.78,1.78,0,0,0,1.53-.86,13.38,13.38,0,0,1,3.24-3.76,9.31,9.31,0,0,1,5.82-2,10.15,10.15,0,0,1,7.54,3.27,12.21,12.21,0,0,1,3.06,8.34C49.56,25.88,48.29,28.94,45.45,32.37ZM29.09,31l1.7-2.86a8.25,8.25,0,0,1,2.92-2.92,8,8,0,0,1,4-1.11h1.78a8.15,8.15,0,0,1-8,6.89H29.09Z"
            />
        </svg>
    );
};

export default VegetationIcon;
