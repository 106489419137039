import type { ReactNode } from 'react';
import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import { createFarmChatLog } from 'chat/chat-generator';
import { getHistoryPage, setHistoryScrollPos, toggleHistory } from 'chat/chat-state';
import type ChatMessage from 'chat/ChatMessage';
import { getChatFileUrl, getChatImageUrl } from 'chat/ChatMessage';
import { DiscussionList } from 'components/discussion-list';
import { selectCurrentFarm } from 'farms/farms-state';
import type { TFunction } from 'i18next';
import { List } from 'immutable';
import configService from 'lib/config';
import { curry } from 'lodash';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';

import './FarmChatPanel.scss';

interface FarmChatHistoryProps {
    farm: Farm;
    messages: List<ChatMessage>;
    toggleHistory: typeof toggleHistory;
    history: List<ChatMessage>;
    historyFinished: boolean;
    getHistoryPage: typeof getHistoryPage;
    getHistoryPagePending: boolean;
    getHistoryPageError: boolean;
    changeModal: typeof changeModal;
    setHistoryScrollPos: typeof setHistoryScrollPos;
    historyScrollPos?: number;
    t: TFunction;
}

class FarmChatHistory extends PureComponent<FarmChatHistoryProps> {
    private scrollingPane: HTMLDivElement | null;
    private oldHeight: number;

    constructor(props: FarmChatHistoryProps) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleToggleHistory = this.handleToggleHistory.bind(this);
    }

    render() {
        const {
            farm,
            history,
            historyFinished,
            getHistoryPagePending,
            changeModal,
            setHistoryScrollPos,
            t,
        } = this.props;

        const chatLog = createFarmChatLog(
            history,
            curry(getChatImageUrl)(configService)(farm),
            curry(getChatFileUrl)(configService)(farm),
            (mediaUrl) => {
                changeModal({
                    name: 'chat-media',
                    props: { chatMediaUrl: mediaUrl },
                });
                this.scrollingPane && setHistoryScrollPos(this.scrollingPane.scrollTop);
            }
        );

        let historyMessage: ReactNode = null;
        if (getHistoryPagePending) {
            historyMessage = t('farm_chat_loading', { defaultValue: 'Loading more messages...' });
        } else if (historyFinished) {
            historyMessage = t('farm_chat_no_more_messages', {
                defaultValue: 'No more messages found',
            });
        }

        return (
            <div className="farm-chat-panel scrollable">
                <div className="top-arrow" />
                <div className="non-scrolling chat-header">
                    <h3>{t('field_details_history')}</h3>
                </div>
                <div
                    className="scrolling"
                    ref={(pane) => {
                        this.scrollingPane = pane;
                    }}
                    onScroll={this.handleScroll}
                >
                    <div className="loading-history-container">
                        <div className="message">{historyMessage}</div>
                    </div>
                    <DiscussionList chatLog={chatLog} />
                </div>
                <div className="non-scrolling">
                    <div className="close-history-container flex items-center justify-center">
                        <Button variant="outline" onClick={this.handleToggleHistory}>
                            {t('farm_chat_back_to_live', { defaultValue: 'Back to live chat' })}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const { farm, history, getHistoryPage, historyScrollPos, setHistoryScrollPos } = this.props;
        if (this.scrollingPane) this.oldHeight = this.scrollingPane.scrollHeight;

        if (historyScrollPos) {
            if (this.scrollingPane) this.scrollingPane.scrollTop = historyScrollPos;
            if (historyScrollPos !== null) {
                setHistoryScrollPos(null);
            }
        } else {
            getHistoryPage({ farmUuid: farm.uuid, topMessage: history.first() });
        }
    }

    componentDidUpdate() {
        // Height changes when more history is loaded, adjust the scroll position so that the
        // new items appear above without the contents moving for the user
        if (this.scrollingPane) {
            const newHeight = this.scrollingPane.scrollHeight;
            const currentTop = this.scrollingPane.scrollTop;
            if (newHeight > this.oldHeight) {
                this.scrollingPane.scrollTop = newHeight - this.oldHeight + currentTop;
            }
            this.oldHeight = newHeight;
        }
    }

    handleScroll() {
        // When the user scrolls to the top of the history, load the next segment
        const { farm, history, getHistoryPage, getHistoryPagePending, historyFinished } =
            this.props;
        if (
            this.scrollingPane &&
            this.scrollingPane.scrollTop < 20 &&
            !getHistoryPagePending &&
            !historyFinished
        ) {
            getHistoryPage({ farmUuid: farm.uuid, topMessage: history.first() });
        }
    }

    handleToggleHistory() {
        const { toggleHistory } = this.props;
        toggleHistory();
    }
}

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        messages: List(state.chatState.messages),
        history: List(state.chatState.history),
        historyFinished: state.chatState.historyFinished,
        getHistoryPagePending: state.chatState.getHistoryPagePending,
        getHistoryPageError: state.chatState.getHistoryPageError,
        historyScrollPos: state.chatState.historyScrollPos,
    }),
    (dispatch) =>
        bindActionCreators(
            { getHistoryPage, toggleHistory, changeModal, setHistoryScrollPos },
            dispatch
        )
)(withTranslation()(FarmChatHistory));
