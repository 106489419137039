import { useSelector } from 'react-redux';
import { getCreatedDate } from 'activity/activity-helper';
import { startOfDay } from 'date-fns';
import type Feature from 'features/Feature';
import { selectFeatureHistoryNotes } from 'features/features-selectors';
import type { List } from 'immutable';
import { splitList } from 'lib/util/list';
import type Note from 'notes/Note';
import type { AppState } from 'system/store';

const getNoteDate = (note: Note) =>
    note.task && note.completedDate !== null ? note.completedDate : getCreatedDate(note);

export interface FeatureHistoryItem {
    uuid: string;
    date: Date;
    type: 'note';
    item: Note;
}

/**
 * Returns a list of notes & completed tasks attached to a given feature, sorted by the date they were created
 * or completed and grouped into days.
 */
export const useFeatureHistory = (feature: Feature) => {
    const featureNotes = useSelector<AppState, List<Note>>((state) =>
        selectFeatureHistoryNotes(state, feature.uuid)
    );

    const items: List<FeatureHistoryItem> = featureNotes
        .sortBy(getNoteDate)
        .reverse()
        .map((note) => ({
            uuid: note.uuid,
            date: getNoteDate(note),
            type: 'note',
            item: note,
        }));

    return splitList<FeatureHistoryItem, number>(items, (item) => startOfDay(item.date).getTime());
};
