import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import type { List } from 'immutable';
import { Option, Select, useFieldState } from 'informed';
import EditButton from 'sidebar/modules/common/EditButton';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';

interface NewOperationYearProps {
    // From parent
    field: string;
    // From redux
    years: List<number>;
}

const NewOperationYear = ({ field, years }: NewOperationYearProps) => {
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const fieldState = useFieldState<number>(field);

    return (
        <div className="NewOperationYear">
            <SidebarModule noBorder>
                {!editing && <EditButton setEditing={setEditing} />}
                <h4 className="mb-0">{t('activity_log_year')}</h4>
            </SidebarModule>
            <SidebarModule className={c({ 'pt-0': !editing })} editing={editing}>
                {!editing && <div className="value">{fieldState.value}</div>}
                <div
                    className="select-form-field mb-4"
                    style={{ display: editing ? 'block' : 'none' }}
                >
                    <Select field={field}>
                        {years.map((year) => (
                            <Option key={year} value={year}>
                                {year}
                            </Option>
                        ))}
                    </Select>
                </div>
                {editing && (
                    <div className="EditableActions">
                        <div className="actions">
                            <Button onClick={() => setEditing(false)}>{t('other_done')}</Button>
                        </div>
                    </div>
                )}
            </SidebarModule>
        </div>
    );
};

export default connect((state: AppState) => ({
    years: state.yearsState.years,
}))(NewOperationYear);
