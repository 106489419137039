import { Record } from 'immutable';

const HistoryReading = Record({
    timestamp: new Date(),
    value: null as number | string | null,
});
interface HistoryReading extends ReturnType<typeof HistoryReading> {}

export interface HistoryReadingDTO {
    timestamp: number;
    value: string;
}

export const deserializeHistoryReading = (json: HistoryReadingDTO) => {
    return HistoryReading({
        timestamp: new Date(json.timestamp),
        value: parseFloat(json.value),
    });
};

export default HistoryReading;
