import styles from './Spinner.module.css';

const bars = Array(12).fill(0);

interface CustomCSSProperties extends React.CSSProperties {
    '--spinner-size'?: string;
    '--spinner-color': string;
}
export function Spinner({ color, size = 20 }) {
    const spinnerStyle: CustomCSSProperties = {
        ['--spinner-size']: `${size}px`,
        ['--spinner-color']: color,
    };

    return (
        <div className={styles.wrapper} style={spinnerStyle}>
            <div className={styles.spinner}>
                {bars.map((_, i) => (
                    <div className={styles.bar} key={`spinner-bar-${i}`} />
                ))}
            </div>
        </div>
    );
}
