import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { getOnboardingClassName } from 'onboarding/onboarding-state';
import SidebarModule from 'sidebar/modules/SidebarModule';
import Fog from 'view/Fog';
import TextInputField from 'view/form/hook/TextInputField';
import { required } from 'view/form/validations';

import '../Field.css';

interface FieldNewTitleProps {
    fog: boolean;
    activeStep?: string;
    onChange: VoidFunction;
}

const FieldNewTitle = ({ fog, activeStep, onChange }: FieldNewTitleProps) => {
    const { t } = useTranslation();
    const className = getOnboardingClassName('fieldName', activeStep);
    const { register } = useFormContext();
    return (
        <SidebarModule className="FieldTitle" editing>
            {fog && <Fog />}
            <div className={c('Title', className)}>
                <h4>{t('hint_create_field_name')}</h4>
                <TextInputField
                    field="name"
                    onChange={onChange}
                    validate={required(t('register_required'))}
                />
            </div>
            <div className={c('Title FieldId', className)}>
                <h4>{t('field_id')}</h4>
                <input
                    type="text"
                    {...register('id')}
                    onChange={onChange}
                    className="simple-text-input"
                    autoComplete="off"
                />
            </div>
        </SidebarModule>
    );
};

export default FieldNewTitle;
