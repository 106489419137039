import type Field from 'fields/Field';
import { getFieldArea, getYearFieldUsageUuidOrNone } from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import { asBadgeWithYear } from 'fields/FieldUsage';
import type { List } from 'immutable';
import {
    type MeasurementUnit,
    prefersImperialUnits,
    renderMeasurementUnitShort,
} from 'lib/MeasurementUnit';
import { converter, sqmToHectares } from 'utils/conversion';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';
import Num from 'view/Num';

import './FieldTooltip.scss';

interface FieldTooltipProps {
    field: Field;
    areaMeasurementUnit: MeasurementUnit;
    fieldUsages: List<FieldUsage>;
    previousUsages: List<FieldUsage>;
    currentYear: number;
}

const FieldTooltip = ({
    field,
    areaMeasurementUnit,
    fieldUsages,
    previousUsages,
    currentYear,
}: FieldTooltipProps) => {
    const currentUsageUuid = getYearFieldUsageUuidOrNone(currentYear, field);
    const currentUsage = fieldUsages.find(
        (fieldUsage) => fieldUsage.uuid === currentUsageUuid
    ) as FieldUsage;

    const fieldArea = sqmToHectares(getFieldArea(field));
    const formattedArea = prefersImperialUnits(areaMeasurementUnit)
        ? converter.convertHectaresToAcres(fieldArea)
        : fieldArea;

    return (
        <div className="FieldTooltip">
            <h3>{field.name}</h3>
            <p className="area">
                <strong>
                    <Num value={formattedArea} sigFigs={4} />{' '}
                    {renderMeasurementUnitShort(areaMeasurementUnit)}
                </strong>
            </p>
            <div className="usage">
                <BadgeItem item={asBadgeWithYear(currentUsage, currentYear)} />
            </div>
            {previousUsages.map((fieldUsage) => (
                <div key={fieldUsage.uuid} className="usage">
                    <BadgeItem item={asBadgeWithYear(fieldUsage, currentYear - 1)} />
                </div>
            ))}
        </div>
    );
};

export default FieldTooltip;
