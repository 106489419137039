import type { List } from 'immutable';
import LocalStorageHelper from 'lib/storage/LocalStorageHelper';

export const getDefaultYear = (farmUuid: string, years: List<number>): number => {
    let year = new Date().getFullYear();
    const yearString = LocalStorageHelper.getItem(`farm-${farmUuid}-year`);
    if (yearString) {
        year = +yearString;
    }
    if (years.includes(year)) {
        return year;
    }
    return years.get(0) as number;
};
