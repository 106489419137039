import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import SidebarModule from 'sidebar/modules/SidebarModule';
import { useAppSelector } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';

interface SubFieldsStartFreshProps {
    handleStartFresh: SingleParamVoidFunction<MouseEvent>;
}

const SubFieldsStartFresh = ({ handleStartFresh }: SubFieldsStartFreshProps) => {
    const { t } = useTranslation();
    const disabled = useAppSelector(
        (state) =>
            state.subFieldsState.history.size === 0 && state.subFieldsState.subFields.size === 1
    );

    return (
        <SidebarModule editing>
            <h4 className="mb-2 text-gray-450 text-sm font-medium">
                {t('sub_fields_start_fresh_desc')}
            </h4>
            <Button small variant="outline" onClick={handleStartFresh} disabled={disabled}>
                {t('sub_fields_start_fresh_confirm')}
            </Button>
        </SidebarModule>
    );
};

export default SubFieldsStartFresh;
