import { geojson, serialize } from '@fieldmargin/webapp-geo';
import { selectFarmUsersAndLocations } from 'farm-map/selectors/selectFarmUsersAndLocations';
import type { FarmMapFeature, FarmMapInitialProps, FarmUserAndLocation } from 'farm-map/types';
import type { Feature, Geometry, Point } from 'geojson';
import { useActiveSection } from 'hooks/useActiveSection';
import { getInitials } from 'lib/util/names';
import { useAppSelector } from 'system/store';
import type { ActiveSection } from 'system/url-util';
import type Vehicle from 'vehicle-tracking/Vehicle';

type FarmMapInitialFeature = Feature<Geometry, FarmMapInitialProps>;

export const useFarmMapInitials = () => {
    const activeSecion = useActiveSection();
    const activeVehicles =
        useAppSelector((state) => state.vehicleTrackingState.activeVehicles?.toArray()) ?? [];
    const inactiveVehicles =
        useAppSelector((state) => state.vehicleTrackingState.inactiveVehicles?.toArray()) ?? [];
    const farmUsersAndLocations = useAppSelector(selectFarmUsersAndLocations);

    return getFarmMapInitials(
        activeSecion,
        activeVehicles,
        inactiveVehicles,
        farmUsersAndLocations
    );
};

export const getFarmMapInitials = (
    activeSection: ActiveSection,
    activeVehicles: Vehicle[],
    inactiveVehicles: Vehicle[],
    farmUsersAndLocations: FarmUserAndLocation[]
): FarmMapFeature[] => {
    if (activeSection.main === 'vehicles') {
        return activeVehicles.map(vehicleToFeature).concat(inactiveVehicles.map(vehicleToFeature));
    } else if (activeSection.main === 'team') {
        return farmUsersAndLocations.map(farmUserAndLocationToFeature);
    } else {
        return [];
    }
};

const vehicleToFeature = (vehicle: Vehicle) =>
    geojson.createFeatureWithId({
        type: 'Feature',
        geometry: serialize(vehicle.location) as Point,
        properties: {
            type: 'INITIAL',
            name: vehicle.displayName,
            uuid: vehicle.id,
        },
    }) as FarmMapInitialFeature;

export const farmUserAndLocationToFeature = ({ farmUser, location }: FarmUserAndLocation) =>
    geojson.createFeatureWithId({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [location.position.x, location.position.y],
        },
        properties: {
            type: 'INITIAL',
            uuid: farmUser.id.toString(),
            name: getInitials(farmUser),
        },
    }) as FarmMapInitialFeature;
