import type { ButtonHTMLAttributes } from 'react';
import c from 'classnames';

const TopNavItem = ({ className, children, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
        <button
            className={c(
                'bare-btn focus ring-inset w-14 flex items-center justify-center border-l border-solid border-top-nav-border',
                className
            )}
            {...props}
        >
            {children}
        </button>
    );
};

export default TopNavItem;
