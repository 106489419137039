import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import { AttachmentType } from 'farm-editing/attachments';
import {
    removeEditingAttachmentByType,
    startSelectingMultipleAttachments,
} from 'farm-editing/farm-editing-state';
import type Feature from 'features/Feature';
import type { List } from 'immutable';
import { preventDefaultAnd } from 'lib/dom';
import { bindActionCreators } from 'redux';
import { selectEditingFeatures } from 'sidebar/modules/shapes/shapes-util';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';

import NoteFeaturesBubbles from './details/NoteFeaturesBubbles';
import featuresSvg from './features.svg';

interface NoteFeaturesEditProps {
    noteFeatures: List<Feature>;
    startSelectingMultipleAttachments: typeof startSelectingMultipleAttachments;
    removeEditingFeatureUuid: SingleParamVoidFunction<string>;
}

const NoteFeaturesEdit = ({
    noteFeatures,
    startSelectingMultipleAttachments,
    removeEditingFeatureUuid,
}: NoteFeaturesEditProps) => {
    const { t } = useTranslation();
    const handleButtonClick = preventDefaultAnd(() => {
        startSelectingMultipleAttachments(AttachmentType.FEATURE);
    });

    const attachBtn = (
        <Button
            onClick={handleButtonClick}
            className={c('flex items-center', { 'mt-2': noteFeatures.size === 0 })}
        >
            <img src={featuresSvg} className="h-5 mr-3" /> {t('create_note_attach_feature')}
        </Button>
    );

    return noteFeatures.size === 0 ? (
        attachBtn
    ) : (
        <div className="mt-3">
            <NoteFeaturesBubbles features={noteFeatures} onRemove={removeEditingFeatureUuid}>
                {attachBtn}
            </NoteFeaturesBubbles>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        noteFeatures: selectEditingFeatures(state),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                startSelectingMultipleAttachments,
                removeEditingFeatureUuid: removeEditingAttachmentByType(AttachmentType.FEATURE),
            },
            dispatch
        )
)(NoteFeaturesEdit);
