import c from 'classnames';
import { useField } from 'informed';
import DateAndTimePicker from 'sidebar/modules/date/DateAndTimePicker';
import type { SingleParamVoidFunction } from 'system/types';

interface DateAndTimeFieldProps {
    field: string;
    validate?: any;
    openDirection: 'top' | 'bottom';
    allowDelete?: boolean;
    initialValue?: Date | null;
    maxDate?: Date;
    className?: string;
    onChange?: SingleParamVoidFunction<Date>;
    classWithDate?: string;
    withoutIcon?: boolean;
    dateFormat?: string;
}

const DateAndTimeField = ({
    field,
    validate,
    openDirection,
    allowDelete = false,
    initialValue = null,
    maxDate,
    className,
    onChange,
    classWithDate,
    withoutIcon = false,
    dateFormat,
}: DateAndTimeFieldProps) => {
    const { fieldState, fieldApi } = useField<Date | null>({
        field,
        validate,
        keepState: true,
        initialValue,
    });

    const handleChange = (dateTime: Date) => {
        fieldApi.setTouched(true);
        fieldApi.setValue(dateTime);
        onChange && onChange(dateTime);
    };

    return (
        <div className={c(className)}>
            <DateAndTimePicker
                value={fieldState.value}
                allowDelete={allowDelete}
                onChange={handleChange}
                openDirection={openDirection || 'top'}
                maxDate={maxDate}
                classWithDate={classWithDate}
                withoutIcon={withoutIcon}
                dateFormat={dateFormat}
            />
            {fieldState.error && <div className="field-error">{fieldState.error}</div>}
        </div>
    );
};

export default DateAndTimeField;
