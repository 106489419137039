import type { ComponentProps } from 'react';

function DownloadIcon({ fill = 'currentColor', ...props }: ComponentProps<'svg'>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700" {...props}>
            <path
                fill={fill}
                d="M572.32 381.08v98.422c0 1.96-.281 3.781-.7 5.46-1.96 9.661-10.5 16.942-20.577 16.942l-402.22-.004c-10.781 0-19.738-8.262-20.86-18.762-.28-1.262-.28-2.379-.28-3.781v-98.422c0-11.621 9.519-21 21-21 5.738 0 11.059 2.379 14.84 6.16s6.16 9.102 6.16 14.84v78.82h360.78v-78.82c0-11.621 9.52-21 21-21 5.738 0 11.059 2.379 14.84 6.16 3.64 3.926 6.02 9.246 6.02 14.984z"
            />
            <path
                fill={fill}
                d="M471.1 282.52 367.92 385.7c-.14.281-.422.422-.559.559-3.78 3.78-8.68 6.16-13.578 6.859-.421 0-.84.14-1.261.14-.84.141-1.68.141-2.52.141l-2.379-.14c-.422 0-.84-.141-1.261-.141-5.04-.7-9.801-3.078-13.578-6.86l-.559-.558-103.18-103.18c-4.762-4.762-7.14-11.059-7.14-17.359 0-6.3 2.378-12.602 7.14-17.359 9.52-9.52 25.059-9.52 34.719 0l62.02 62.02-.004-227.22c0-13.441 11.06-24.5 24.5-24.5 6.719 0 12.88 2.8 17.36 7.14 4.48 4.481 7.14 10.5 7.14 17.36v227.22l62.02-62.02c9.52-9.52 25.059-9.52 34.719 0 9.101 9.66 9.101 25.199-.418 34.719z"
            />
        </svg>
    );
}
export { DownloadIcon };
