import type { List } from 'immutable';
import { join, map, pipe, prop, useWith as ramdaUseWith } from 'ramda';

import { isNumeric } from './number';

export const pluralise = (count: number, singular: string, plural: string) => {
    return count === 1 ? singular : plural;
};

export const joinNicely = (items: List<string>, separator = ', ') =>
    items.size < 2 ? items.get(0, '') : `${items.skipLast(1).join(separator)} and ${items.last()}`;

export const joinItemNames = pipe<[{ name: string }[]], string[], string>(
    map(prop('name')),
    join(', ')
);

export const shortenText = (length: number, content: string) => {
    return content.length > length ? content.substr(0, length).trim() + '...' : content;
};

/**
 * Returns the first capital letter that doesn't appear in the provided list.
 */
export const nextCapitalLetter = (existing: List<string>) => {
    for (let i = 0; i < 26; i++) {
        const char = String.fromCharCode(65 + i);
        const used = !!existing.contains(char);
        if (!used) {
            return char;
        }
    }
    return 'A';
};

export const capitaliseWord = (word: string) => word[0].toUpperCase() + word.slice(1).toLowerCase();

export const compareString = (a: string, b: string) => {
    const aIsNumber = isNumeric(a);
    const bIsNumber = isNumeric(b);

    if (aIsNumber && !bIsNumber) {
        return -1;
    } else if (!aIsNumber && bIsNumber) {
        return 1;
    }
    const aValue = aIsNumber ? parseFloat(a) : a.toLowerCase();
    const bValue = bIsNumber ? parseFloat(b) : b.toLowerCase();
    return aValue < bValue ? -1 : 1;
};

export const compareName = <T extends { name: string }>(a: T, b: T) =>
    compareString(a.name, b.name);

export const comparePropNameProp = <T extends { [key in keyof T]: { name: string } | any }>(
    propName: keyof T
) =>
    ramdaUseWith<T, { name: string } | undefined, T, { name: string } | undefined, -1 | 1>(
        compareName,
        [prop(propName), prop(propName)]
    );

export const toKebabCase = (str: string) => str.toLowerCase().replace(/ /g, '-');

export const numberToStringOrEmpty = (num: number | null | undefined) =>
    num === null || num === undefined || num === 0 ? '' : num.toString();

export const stringContains = (str: string, search: string) =>
    str.toLowerCase().includes(search.toLowerCase());

const v4UuidPattern = new RegExp(
    /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
);
export const isUuid = (str: string) => v4UuidPattern.test(str);

/**
 * This should be used when you want to use a string as part of a regular expression.
 */
const REGEXP_SPECIAL_CHAR = /[!#$%^&*()+=[\]{};:'"\\|~`<>._-]/g;
export const escapeForRegex = (str: string) => str.replace(REGEXP_SPECIAL_CHAR, '\\$&');
