import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoPosition } from '@fieldmargin/webapp-geo';
import { apiSlice } from 'api/base.api';
import type { Point } from 'geojson';

interface Args {
    farmName: string;
    farmLocation: GeoPosition;
    externalId?: number | null;
    externalIdSource?: 'AGREENA' | null;
}

interface Request {
    name: string;
    geoJsonPoint: Point;
    externalId?: number | null;
    externalIdSource?: 'AGREENA' | null;
}

interface Response extends Farm {
    externalId?: string;
    externalIdSource?: string;
}

export const createFarmApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createFarm: builder.mutation<Response, Args>({
            query: ({ farmName, farmLocation, externalId, externalIdSource }) => {
                const extraProps =
                    externalId && externalIdSource
                        ? {
                              externalId,
                              externalIdSource,
                          }
                        : {};

                const body: Request = {
                    name: farmName,
                    geoJsonPoint: {
                        type: 'Point',
                        coordinates: [farmLocation.x, farmLocation.y],
                    },
                    ...extraProps,
                };

                return {
                    url: '/farms-api/v2/farms/',
                    method: 'POST',
                    body,
                };
            },
        }),
    }),
});

export const { useCreateFarmMutation } = createFarmApi;
