import { useTranslation } from 'react-i18next';
import type Input from 'inputs/Input';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';

import type { PesticideCheckMaxTreatments } from './PesticideCheck';
import { PesticideCheckStatus } from './PesticideCheck';
import PesticideCheckResultIcon from './PesticideCheckResultIcon';

interface MaxTreatmentsResultProps {
    input: Input;
    check: PesticideCheckMaxTreatments;
}

const MaxTreatmentsResult = ({ check }: MaxTreatmentsResultProps) => {
    const { t } = useTranslation();
    return (
        <tr>
            <PesticideCheckResultIcon status={check.status} />
            <td>
                {check.status === PesticideCheckStatus.FAIL ? (
                    <MarkdownNoPara>
                        {t('pesticide_check_max_treatments_fail', {
                            sprintf: [check.values.treatments, check.values.maxTreatments],
                        })}
                    </MarkdownNoPara>
                ) : check.status === PesticideCheckStatus.UNKNOWN ? (
                    t('pesticide_check_max_treatments_no_data')
                ) : (
                    t('pesticide_check_max_treatments_pass', {
                        sprintf: [check.values.treatments, check.values.maxTreatments],
                    })
                )}
            </td>
        </tr>
    );
};

export default MaxTreatmentsResult;
