import { useDispatch, useSelector } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import { setFieldUsage } from 'fields/field-usage-state';
import {
    createFieldUsage,
    toggleFieldUsageArchived,
    updateFieldUsage,
} from 'fields/field-usages-api';
import type { WriteFieldUsageDTO } from 'fields/FieldUsage';
import type FieldUsage from 'fields/FieldUsage';
import { trackFieldUsageChange } from 'fields/FieldUsage';
import { dispatchAndReturn } from 'lib/fp-helpers';
import type { AppState } from 'system/store';

export const useCreateFieldUsage = () => {
    const dispatch = useDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);

    return (name: string, cropCode: string, description: string, colour: string) =>
        createFieldUsage(farm.uuid, { name, cropCode, description, colour })
            .then(trackFieldUsageChange('saved'))
            .then(dispatchAndReturn(dispatch, setFieldUsage));
};

export const useUpdateFieldUsage = (fieldUsage: FieldUsage) => {
    const dispatch = useDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);

    return (data: Partial<WriteFieldUsageDTO>) =>
        updateFieldUsage(farm.uuid, fieldUsage.uuid, data)
            .then(trackFieldUsageChange('saved'))
            .then((data) => ({
                ...fieldUsage,
                ...data,
                cropCode: data.cropCode,
            }))
            .then(dispatchAndReturn(dispatch, setFieldUsage));
};

export const useToggleFieldUsageArchived = () => {
    const dispatch = useDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);

    return (fieldUsage: FieldUsage) =>
        toggleFieldUsageArchived(farm.uuid, fieldUsage).then(
            dispatchAndReturn(dispatch, setFieldUsage)
        );
};
