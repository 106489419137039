import { useTranslation } from 'react-i18next';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import icon from 'components/icons/icon';
import { useDateFormat } from 'hooks/useDateFormat';
import { getDisplayName } from 'lib/util/names';
import FarmRememberingLink from 'view/FarmRememberingLink';

import type { HerdHistoryNote } from './herd-history-generator';

interface HerdHistoryNoteItemProps {
    farmUsers: FarmUser[];
    historyItem: HerdHistoryNote;
}

const HerdHistoryNoteItem = ({ farmUsers, historyItem }: HerdHistoryNoteItemProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    return (
        <li className="p-0 pb-5">
            <FarmRememberingLink to={`/notes/${historyItem.item.uuid}`} className="no-underline">
                <strong className="mb-2 block text-xxs">
                    {format(historyItem.date, 'do MMMM yyyy')}
                </strong>
                <span className="flex">
                    {icon(historyItem.item.task ? 'task' : 'note', 'blue')}
                    <span className="ml-3">
                        <strong className="block">{historyItem.item.name}</strong>
                        <span className="block text-xxs">
                            {t('created_by')}{' '}
                            {getDisplayName(farmUsers, historyItem.item.createdByUserId)}
                        </span>
                    </span>
                </span>
            </FarmRememberingLink>
        </li>
    );
};

export default HerdHistoryNoteItem;
