import { List, Map, Record } from 'immutable';
import { InputType } from 'inputs/InputType';
import { compareString } from 'lib/util/text';

interface InputCostDTO {
    inputType: InputType;
    cost: number;
    totalCost: number;
}

interface FieldInputCostsDTO {
    fieldName: string;
    fieldUsage: string;
    status: null | string;
    costs: InputCostDTO[];
}

export interface InputCostsDTO {
    fields: FieldInputCostsDTO[];
    totals: InputCostDTO[];
}

export class InputCost extends Record({ inputType: InputType.OTHER, cost: 0, totalCost: 0 }) {}

export class FieldInputCosts extends Record({
    fieldName: '',
    fieldUsage: '',
    costs: Map<InputType, InputCost>(),
}) {}

export class InputCosts extends Record({
    fields: List<FieldInputCosts>(),
    totals: Map<InputType, InputCost>(),
}) {}

export const deserializeInputCosts = (dto: InputCostsDTO) =>
    new InputCosts({
        fields: List(
            dto.fields
                .map(deserializeFieldInputCost)
                .sort((a, b) => compareString(a.fieldName, b.fieldName))
        ),
        totals: dto.totals.reduce(
            (map, total) => map.set(total.inputType, deserializeInputCost(total)),
            Map<InputType, InputCost>()
        ),
    });

const deserializeFieldInputCost = ({ fieldName, fieldUsage, costs }: FieldInputCostsDTO) =>
    new FieldInputCosts({
        fieldName,
        fieldUsage,
        costs: costs.reduce(
            (map, total) => map.set(total.inputType, deserializeInputCost(total)),
            Map<InputType, InputCost>()
        ),
    });
const deserializeInputCost = (dto: InputCostDTO) => new InputCost({ ...dto });
