import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { Form } from 'informed';
import type Input from 'inputs/Input';
import { InputUnit } from 'inputs/Input';
import { InputType } from 'inputs/InputType';
import type { PesticideData } from 'inputs/Pesticide';
import { getPesticideData } from 'inputs/pesticides-api';
import PesticideUnitField from 'inputs/PesticideUnitField';
import { defaultToZero } from 'lib/fp-helpers';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import type { CodedItem } from 'system/types';
import ErrorMessage from 'view/ErrorMessage';
import VerifiedShield from 'view/shield/VerifiedShield';

import RecordingFormFields from './RecordingFormFields';
import RecordingPesticideData from './RecordingPesticideData';

interface NewPesticideRecordingFormProps {
    farm: Farm;
    totalArea: number;
    pesticide: CodedItem;
    onCancel: VoidFunction;
    onAddAnother: (name: string, type: string, unit: InputUnit, rate: number) => void;
    onDone: VoidFunction;
    onSave: (inputValues: Partial<Input>, rate: number) => PromiseLike<void>;
    measurementUnit: MeasurementUnit;
}

interface FormValues {
    rate: number;
    total: number;
    unit: InputUnit;
}

const NewPesticideRecordingForm = ({
    farm,
    totalArea,
    pesticide,
    onCancel,
    onSave,
    onAddAnother,
    onDone,
    measurementUnit,
}: NewPesticideRecordingFormProps) => {
    const { t } = useTranslation();
    const { pending: savePending, error: saveError, setPromise: setSavePromise } = usePromise();
    const [pesticideData, setPesticideData] = useState<PesticideData>();
    const { pending: dataPending, setPromise: setDataPromise } =
        usePromise<PesticideData>(setPesticideData);

    useEffect(() => {
        setDataPromise(getPesticideData(farm.uuid, pesticide.code));
    }, [pesticide]);

    const handleSubmit = (values: FormValues, setAnother: boolean) => {
        if (values.rate >= 0 && pesticideData) {
            const rate = defaultToZero(values.rate);
            const unit =
                pesticideData.doseUnits.length === 1 ? pesticideData.doseUnits[0] : values.unit;

            const next = setAnother
                ? onAddAnother.bind(
                      null,
                      pesticide.name,
                      pesticideData.productCategory.name,
                      unit,
                      rate
                  )
                : onDone();
            setSavePromise(
                onSave(
                    {
                        name: pesticide.name,
                        type: InputType.SPRAY,
                        unit: InputUnit[unit],
                        pesticideData,
                    },
                    rate
                ).then(next)
            );
        }
    };

    const pending = savePending || dataPending;

    return (
        <Form<FormValues> className="h-content-full">
            {({ formState, formApi }) => (
                <>
                    <SidebarHeader className="px-5">
                        <Button onClick={onCancel} variant="danger-outline" small>
                            {t('cancel')}
                        </Button>
                    </SidebarHeader>
                    <h4 className="p-5 pb-0 flex items-center">
                        {pesticide.name} <VerifiedShield className="ml-2" />
                    </h4>
                    <RecordingPesticideData
                        pending={dataPending}
                        pesticideData={pesticideData}
                        unit={<PesticideUnitField pesticideData={pesticideData} />}
                    />
                    <RecordingFormFields
                        inputUnit={formApi.getValue('unit') ?? ''}
                        inputType={formApi.getValue('type') ?? ''}
                        totalArea={totalArea}
                        measurementUnit={measurementUnit}
                    />
                    {saveError && (
                        <ErrorMessage className="px-5 pt-5">
                            {t('something_went_wrong')}
                        </ErrorMessage>
                    )}
                    <div className="p-5">
                        <SubmitButton
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                e.stopPropagation();
                                formApi.validate();
                                if (!formState.invalid) {
                                    handleSubmit(formState.values, false);
                                }
                            }}
                            disabled={pending || dataPending}
                            className="mr-4"
                        >
                            {t('input_rate_set')}
                        </SubmitButton>
                        <SubmitButton
                            variant="outline"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                e.stopPropagation();
                                formApi.validate();
                                if (!formState.invalid) {
                                    handleSubmit(formState.values, true);
                                }
                            }}
                            disabled={pending || dataPending}
                        >
                            {t('input_rate_set_add')}
                        </SubmitButton>
                    </div>
                </>
            )}
        </Form>
    );
};

export default NewPesticideRecordingForm;
