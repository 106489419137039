import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeature, GeoFeatureCollection, GeoPoint } from '@fieldmargin/webapp-geo';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import { stopEditing } from 'farm-editing/farm-editing-state';
import { startEditingIntegrationSensor } from 'farm-editing/start-editing-reducer';
import { selectCurrentFarm } from 'farms/farms-state';
import { List } from 'immutable';
import type { SaveSensorOverridesResponse } from 'integrations/integrations-data-api';
import { setSensorOverridesApi } from 'integrations/integrations-data-api';
import { bindActionCreators } from 'redux';
import { setSensor } from 'sensors/sensors-state';
import withPageSensor from 'sensors/withPageSensor';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import EditableShapeInformation from 'sidebar/modules/shapes/components/EditableShapeInformation';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';

import type SensorSummary from '../SensorSummary';

interface SensorEditProps {
    farm: Farm;
    sensor?: SensorSummary;
    editingGeoFeatureCollection: GeoFeatureCollection | null;

    setSensor: typeof setSensor;
    startEditingIntegrationSensor: typeof startEditingIntegrationSensor;
    stopEditing: typeof stopEditing;
}

const SensorEdit = ({
    farm,
    sensor,
    setSensor,
    editingGeoFeatureCollection,
    startEditingIntegrationSensor,
    stopEditing,
}: SensorEditProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        startEditingIntegrationSensor(sensor);

        return () => {
            stopEditing();
        };
    }, []);

    const { pending, error, setPromise } = usePromise<SaveSensorOverridesResponse>((response) => {
        setSensor(response);
        navigate(`/farms/${farm.uuid}/data`);
    });

    if (!sensor) {
        // Likely a page refresh, redirect to sensors list
        return <Navigate to={`/farms/${farm.uuid}/data`} />;
    }

    const handleSave = () => {
        if (editingGeoFeatureCollection && editingGeoFeatureCollection.features.size > 0) {
            const location = (editingGeoFeatureCollection.features.get(0) as GeoFeature)
                .geometry as GeoPoint;
            setPromise(
                setSensorOverridesApi(
                    farm.uuid,
                    sensor.farmIntegrationUuid,
                    sensor.id,
                    sensor.getName(),
                    location
                )
            );
        }
    };

    return (
        <div className="sensor-edit scrollable">
            <div className="non-scrolling">
                <SidebarHeader className="px-5">
                    <Button
                        as={Link}
                        to={`/farms/${farm.uuid}/data`}
                        variant="danger-outline"
                        small
                    >
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleSave} disabled={pending} small>
                        {t(pending ? 'saving' : 'save')}
                    </Button>
                </SidebarHeader>
            </div>
            <div className="scrolling">
                <SidebarModule editing>
                    <h3 className="mb-0">{sensor.getName()}</h3>
                </SidebarModule>
                <SidebarModule editing>
                    <EditableShapeInformation
                        label={t('sensors_set_location')}
                        tools={List(['point' as DrawingTool])}
                        maxShapes={1}
                    />
                </SidebarModule>
            </div>
            {error && (
                <div className="non-scrolling">
                    <SidebarError title={t('failed_to_save')} message={t('something_went_wrong')} />
                </div>
            )}
        </div>
    );
};

export default connect(
    (state: AppState) => {
        return {
            farm: selectCurrentFarm(state),
            editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,
        };
    },
    (dispatch) =>
        bindActionCreators(
            {
                startEditingIntegrationSensor,
                stopEditing,
                setSensor,
            },
            dispatch
        )
)(withPageSensor(SensorEdit));
