import { useTranslation } from 'react-i18next';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import type Herd from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import { useDateFormat } from 'hooks/useDateFormat';
import { getDisplayName } from 'lib/util/names';

import type { HerdHistoryNewFromSplit } from './herd-history-generator';

interface HerdHistoryNewFromSplitItemProps {
    herd: Herd;
    historyItem: HerdHistoryNewFromSplit;
    farmUsers: FarmUser[];
}

const HerdHistoryNewFromSplitItem = ({
    herd,
    historyItem,
    farmUsers,
}: HerdHistoryNewFromSplitItemProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    return (
        <li className="p-0 pb-5">
            <strong className="mb-2 block text-xxs text-gray-500">
                {format(historyItem.date, 'do MMMM yyyy')}
            </strong>
            <span className="flex">
                {herdIcon(herd.type, 'blue')}
                <span className="ml-3">
                    <strong className="block">
                        {t('herd_history_split', { sprintf: [historyItem.item.name] })}
                    </strong>
                    <span className="block text-xxs text-gray-500">
                        {t('created_by')} {getDisplayName(farmUsers, herd.createdByUserId)}
                    </span>
                </span>
            </span>
        </li>
    );
};

export default HerdHistoryNewFromSplitItem;
