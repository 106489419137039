import { useOutletContext } from 'react-router-dom';
import type { ChatLog } from 'chat/chat-models';
import type { List } from 'immutable';
import type FullOperation from 'operations/FullOperation';
import type { FullOperationField } from 'operations/OperationField';

export interface EditableOperationDetailsSchema {
    fullOperation: FullOperation;
    chatLog: ChatLog;
    isHarvesting: boolean;
    operationFields: List<FullOperationField>;
    totalFieldsArea: number;
    handleSaveOperation: (newFullOperation: FullOperation) => Promise<void>;
}

const useEditableOperationDetailsContext = () => {
    return useOutletContext<EditableOperationDetailsSchema>();
};

export { useEditableOperationDetailsContext };
