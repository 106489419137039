import { createAction, handleActions } from '@fieldmargin/webapp-state';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess, loadingFarm } from 'farms/farm-loading-state';
import type Feature from 'features/Feature';
import type FeatureType from 'features/FeatureType';
import { List, Record } from 'immutable';
import { replaceOrAdd } from 'lib/immutil';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

export const setFeatureSearchString = createAction<FeaturesState, string>(
    'Set feature search string',
    (state, payload) => state.set('featureSearchString', payload)
);
export const setFilterFeaturesByScreen = createAction<FeaturesState, boolean>(
    'Set filter features by screen',
    (state, payload) => state.set('filterFeaturesByScreen', payload)
);

export const setFeatureType = createAction<FeaturesState, FeatureType>(
    'Set feature type',
    (state, payload) =>
        state.update('featureTypes', (featureTypes: List<FeatureType>) =>
            replaceOrAdd(featureTypes, payload, (ft) => ft.uuid === payload.uuid)
        )
);

export const setFeature = createAction<FeaturesState, Feature>('Set feature', (state, payload) =>
    state.set(
        'features',
        replaceOrAdd(
            state.features as List<Feature>,
            payload,
            (feature) => feature.uuid === payload.uuid
        )
    )
);
export const removeFeature = createAction<FeaturesState, Feature>(
    'Remove feature',
    (state, payload) =>
        state.features
            ? state.update('features', (features: List<Feature>) =>
                  features.filter((feature) => feature.uuid !== payload.uuid)
              )
            : state
);

export const FeaturesState = Record({
    features: null as null | List<Feature>,

    featureTypes: null as null | List<FeatureType>,

    featureSearchString: '',
    filterFeaturesByScreen: false,
});
export interface FeaturesState extends ReturnType<typeof FeaturesState> {}

const featureTypeSortBy = (featureType: FeatureType) => featureType.name.toLowerCase();

export const featuresReducer = handleActions<FeaturesState>(
    FeaturesState(),
    [setFeatureSearchString, setFilterFeaturesByScreen, setFeatureType, setFeature, removeFeature],
    {
        [loadingFarm.toString()]: () => FeaturesState(),
        [loadedFarmSuccess.toString()]: (state: FeaturesState, payload: LoadedFarmSuccessPayload) =>
            state
                .set('features', payload.features)
                .set('featureTypes', payload.featureTypes.sortBy(featureTypeSortBy)),
    }
);

export const selectFeaturesTypesSortedByArchived = createSelector(
    (state: AppState) => state.featuresState.featureTypes || List<FeatureType>(),
    (featureTypes) => {
        const map = featureTypes.reduce(
            (map, featureType) =>
                featureType.archived
                    ? { ...map, archived: map.archived.push(featureType) }
                    : { ...map, active: map.active.push(featureType) },
            { active: List<FeatureType>(), archived: List<FeatureType>() }
        );

        return {
            active: map.active.sortBy((ft) => ft.name.toLowerCase()),
            archived: map.archived.sortBy((ft) => ft.name.toLowerCase()),
        };
    }
);

export const selectDisplayableFeatureTypes = createSelector(
    (state: AppState) => state.featuresState.featureTypes,
    (featureTypes) => featureTypes?.filter((ft) => !ft.archived) ?? List<FeatureType>()
);
