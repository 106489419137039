import type { ComponentProps } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import type Herd from 'herd/Herd';
import withPageHerd from 'herd/withPageHerd';
import CreateHeader from 'sidebar/header/CreateHeader';
import PlusButton from 'sidebar/modules/PlusButton';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import type { CanBeUndefined } from 'types';
import ErrorMessage from 'view/ErrorMessage';
import ErrorMessageBox from 'view/ErrorMessageBox';

import { useHerdSplitForm } from './herd-split-hooks';
import HerdSplitFormFields from './HerdSplitFormFields';

interface HerdSplitBaseProps {
    farm: Farm;
    herd: Herd;
}

type HerdSplitProps = CanBeUndefined<ComponentProps<typeof HerdSplitBase>, 'herd'>;

const HerdSplitBase = ({ farm, herd }: HerdSplitBaseProps) => {
    const { t } = useTranslation();

    const { methods, fields, append, remove, handleSubmit, pending, error, groupTotal, canSave } =
        useHerdSplitForm(herd);

    return (
        <FormProvider {...methods}>
            <form className="scrollable" onSubmit={methods.handleSubmit(handleSubmit)}>
                <div className="non-scrolling">
                    <CreateHeader
                        backPath={`/farms/${farm.uuid}/livestock/${herd.uuid}`}
                        isSaving={pending}
                        canSave={canSave}
                    />
                    {error && (
                        <SidebarError
                            title={t('failed_to_save')}
                            message={t('something_went_wrong')}
                        />
                    )}
                </div>
                <div className="scrolling">
                    <SidebarModule editing noBorder className="pb-0">
                        <h2 className="mb-1">{t('split_herd')}</h2>
                        <p>
                            <em>{t('livestock_split_desc')}</em>
                        </p>
                        {groupTotal > herd.size && (
                            <ErrorMessage>
                                {t('livestock_split_error', { sprintf: [herd.size] })}
                            </ErrorMessage>
                        )}
                    </SidebarModule>
                    {fields.map((field, index) => (
                        <HerdSplitFormFields
                            key={field.id}
                            field={`groups.${index}`}
                            alternate={index % 2 !== 0}
                            remove={index > 1 ? () => remove(index) : undefined}
                        />
                    ))}
                    <SidebarModule className="py-2" editing>
                        <strong>{t('total_animals_colon')}</strong> {groupTotal}/{herd.size}
                    </SidebarModule>
                    <SidebarModule editing>
                        <PlusButton
                            onClick={() => append({ name: '', number: '', colour: herd.colour })}
                        >
                            {t('add_more')}
                        </PlusButton>
                    </SidebarModule>
                </div>
            </form>
        </FormProvider>
    );
};

function HerdSplit({ herd, ...props }: HerdSplitProps) {
    return !herd ? (
        <Navigate to={`/farms/${props.farm.uuid}/livestock`} />
    ) : (
        <HerdSplitBase {...props} herd={herd} />
    );
}

const HerdSplitWithErrorBoundary = withErrorBoundary(HerdSplit, {
    fallback: (
        <ErrorMessageBox className="p-5">
            Something went wrong when splitting this herd.
        </ErrorMessageBox>
    ),
});

const HerdSplitWithPageHerd = withPageHerd(HerdSplitWithErrorBoundary);

export default connect((state: AppState, props) => ({
    ...props,
    farm: selectCurrentFarm(state),
}))(HerdSplitWithPageHerd);
