import type { RouteObject } from 'react-router-dom';
import { johnDeereRoutes } from '@notes-web/components';
import { FieldmarginFarmLoader } from '@notes-web/fieldmargin';
import { Onboarding } from '@notes-web/fieldmargin/src/components/Onboarding';
import { APP_ROUTES } from '@notes-web/types';
import FarmOverview from 'view/FarmOverview';

import { AddFields } from '../components/AddFieles';

import { billingRoutes } from './billing.routes';
import { dataRoutes } from './data.routes';
import { featuresRoutes } from './features.routes';
import { fieldRoutes } from './fields.routes';
import { livestockRoutes } from './livestock.routes';
import { mapsRoutes } from './maps.routes';
import { notesRoutes } from './notes.routes';
import { operationsRoutes } from './operations.routes';
import { printRoutes } from './print.routes';
import { reportingRoutes } from './reporting.routes';
import { teamRoutes } from './team.routes';
import { vehiclesRoutes } from './vehicles.routes';

const farmsRoutes: RouteObject[] = [
    {
        path: APP_ROUTES.Farms.Farm,
        element: <FieldmarginFarmLoader />,

        children: [
            // REPORTING
            reportingRoutes,

            // PRINT
            printRoutes,

            // BILLING
            billingRoutes,

            //JOHN DEERE ROUTES
            johnDeereRoutes,

            {
                element: <FarmOverview />,

                children: [
                    {
                        path: 'onboarding',
                        element: <Onboarding />,
                    },
                    {
                        path: 'add-fields',
                        element: <AddFields />,
                    },

                    // OPERATIONS
                    operationsRoutes,
                    // NOTES
                    notesRoutes,
                    // FIELD
                    fieldRoutes,
                    // FEATURES
                    featuresRoutes,
                    // MAPS
                    mapsRoutes,
                    // TEAM
                    teamRoutes,
                    // VEHICLES
                    vehiclesRoutes,
                    // DATA
                    dataRoutes,
                    //LIVESTOCK
                    livestockRoutes,
                ],
            },
        ],
    },
];

export { farmsRoutes };
