import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import { includes } from 'lodash';
import CardCheckboxList from 'view/card-checkbox-list/CardCheckboxList';
import CardCheckboxListItem from 'view/card-checkbox-list/CardCheckboxListItem';
import AreaIcon from 'view/svg/AreaIcon';
import DataIcon from 'view/svg/DataIcon';
import FoldedMapIcon from 'view/svg/FoldedMapIcon';
import HerdIcon from 'view/svg/HerdIcon';
import NoteIcon from 'view/svg/NoteIcon';
import PlanningIcon from 'view/svg/PlanningIcon';
import SprayIcon from 'view/svg/SprayIcon';
import TeamIcon from 'view/svg/TeamIcon';

import { OnboardingTutorial } from './onboarding-api';

interface OnboardingEndChoicesProps {
    onSkip: VoidFunction;
    onNext: () => Promise<any>;
    selectedTutorials: OnboardingTutorial[];
    toggleTutorial: (value: OnboardingTutorial) => VoidFunction;
}

const OnboardingEndChoices = ({
    onSkip,
    onNext,
    selectedTutorials,
    toggleTutorial,
}: OnboardingEndChoicesProps) => {
    const { pending, setPromise } = usePromise();
    const handleNext = () => setPromise(onNext());

    return (
        <>
            <h2>
                Well done you have made your first field and job.
                <br />
                What else would you like to use fieldmargin for?
            </h2>
            <p>(You can choose more than one)</p>

            <CardCheckboxList>
                <CardCheckboxListItem
                    value="mapping"
                    selected={includes(selectedTutorials, OnboardingTutorial.MAPPING)}
                    onChange={toggleTutorial(OnboardingTutorial.MAPPING)}
                >
                    <AreaIcon
                        className="w-12"
                        fill={
                            includes(selectedTutorials, OnboardingTutorial.MAPPING)
                                ? 'white'
                                : undefined
                        }
                    />
                    Mapping my farm
                </CardCheckboxListItem>
                <CardCheckboxListItem
                    value="planning"
                    selected={includes(selectedTutorials, OnboardingTutorial.PLANNING)}
                    onChange={toggleTutorial(OnboardingTutorial.PLANNING)}
                >
                    <PlanningIcon
                        className="w-10"
                        fill={
                            includes(selectedTutorials, OnboardingTutorial.PLANNING)
                                ? 'white'
                                : undefined
                        }
                    />
                    Planning and tracking work
                </CardCheckboxListItem>
                <CardCheckboxListItem
                    value="inputs"
                    selected={includes(selectedTutorials, OnboardingTutorial.INPUTS)}
                    onChange={toggleTutorial(OnboardingTutorial.INPUTS)}
                >
                    <SprayIcon
                        className="w-11"
                        fill={
                            includes(selectedTutorials, OnboardingTutorial.INPUTS)
                                ? 'white'
                                : undefined
                        }
                    />
                    Keeping records of inputs
                </CardCheckboxListItem>
                <CardCheckboxListItem
                    value="ndvi"
                    selected={includes(selectedTutorials, OnboardingTutorial.SATELLITE)}
                    onChange={toggleTutorial(OnboardingTutorial.SATELLITE)}
                >
                    <FoldedMapIcon
                        className="w-10"
                        fill={
                            includes(selectedTutorials, OnboardingTutorial.SATELLITE)
                                ? 'white'
                                : undefined
                        }
                    />
                    Satellite imagery
                </CardCheckboxListItem>
                <CardCheckboxListItem
                    value="herds"
                    selected={includes(selectedTutorials, OnboardingTutorial.LIVESTOCK)}
                    onChange={toggleTutorial(OnboardingTutorial.LIVESTOCK)}
                >
                    <HerdIcon
                        className="w-10"
                        fill={
                            includes(selectedTutorials, OnboardingTutorial.LIVESTOCK)
                                ? 'white'
                                : undefined
                        }
                    />
                    Keeping livestock records
                </CardCheckboxListItem>
                <CardCheckboxListItem
                    value="notes"
                    selected={includes(selectedTutorials, OnboardingTutorial.SCOUTING)}
                    onChange={toggleTutorial(OnboardingTutorial.SCOUTING)}
                >
                    <NoteIcon
                        className="w-10"
                        fill={
                            includes(selectedTutorials, OnboardingTutorial.SCOUTING)
                                ? 'white'
                                : undefined
                        }
                    />
                    Scouting and noting issues
                </CardCheckboxListItem>
                <CardCheckboxListItem
                    value="team"
                    selected={includes(selectedTutorials, OnboardingTutorial.COMMUNICATION)}
                    onChange={toggleTutorial(OnboardingTutorial.COMMUNICATION)}
                >
                    <TeamIcon
                        className="w-10"
                        fill={
                            includes(selectedTutorials, OnboardingTutorial.COMMUNICATION)
                                ? 'white'
                                : undefined
                        }
                    />
                    Communicating with my team
                </CardCheckboxListItem>
                <CardCheckboxListItem
                    value="data"
                    selected={includes(selectedTutorials, OnboardingTutorial.LOGGING)}
                    onChange={toggleTutorial(OnboardingTutorial.LOGGING)}
                >
                    <DataIcon
                        className="w-10"
                        fill={
                            includes(selectedTutorials, OnboardingTutorial.LOGGING)
                                ? 'white'
                                : undefined
                        }
                    />
                    Logging data & measurements
                </CardCheckboxListItem>
            </CardCheckboxList>

            <div className="flex items-center justify-center">
                <Button variant="outline" onClick={onSkip} className="mr-4">
                    Skip
                </Button>
                <Button disabled={pending} onClick={handleNext}>
                    Next
                </Button>
            </div>
        </>
    );
};

export default OnboardingEndChoices;
