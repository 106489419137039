import { useTranslation } from 'react-i18next';
import { herdIcon } from 'herd/herd-icons';
import ItemHistoryItem from 'sidebar/modules/item-history/ItemHistoryItem';
import { useFarmUserDisplayName } from 'team/farm-users-hooks';

import type { FieldHistoryHerdItem, FieldHistoryItem } from './field-history-generator';

interface FieldHistoryHerdRemoveItemProps {
    // From parent
    historyItem: FieldHistoryItem;
}

const FieldHistoryHerdRemoveItem = ({ historyItem }: FieldHistoryHerdRemoveItemProps) => {
    const { t } = useTranslation();
    const getDisplayName = useFarmUserDisplayName();
    const item = historyItem.item as FieldHistoryHerdItem;

    return (
        <ItemHistoryItem>
            {herdIcon(item.herdType, 'gray')}
            <div className="flex flex-col">
                <strong>
                    {t('field_details_history_action_herd_removed', {
                        sprintf: [item.herdLocation.size, item.herdName],
                    })}
                </strong>
                <span className="text-gray-500">
                    {t('livestock_details_history_grazed', {
                        sprintf: [Math.round((item.herdLocation.durationHours as number) / 24)],
                    })}
                </span>
                <span className="ItemHistorySubText">
                    {t('created_by')} {getDisplayName(item.herdLocation.createdByUserId)}
                </span>
            </div>
        </ItemHistoryItem>
    );
};

export default FieldHistoryHerdRemoveItem;
