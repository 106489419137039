import { selectCurrentFarm } from 'farms/farms-state';
import { useAppSelector } from 'system/store';

export const useCurrentFarm = () => {
    const currentFarm = useAppSelector(selectCurrentFarm);

    return {
        currentFarm,
    };
};
