import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import type { SingleParamVoidFunction } from 'system/types';
import Steps, { Step } from 'view/Steps';

interface SubFieldsUsePreviousUsageChoiceProps {
    onBack: VoidFunction;
    onChoose: SingleParamVoidFunction<boolean>;
}

const SubFieldsUsePreviousUsageChoice = ({
    onBack,
    onChoose,
}: SubFieldsUsePreviousUsageChoiceProps) => {
    const { t } = useTranslation();
    return (
        <>
            <Button variant="outline" onClick={onBack} className="absolute top-8 left-8">
                {t('back')}
            </Button>
            <h2>{t('sub_fields_use_previous_title')}</h2>
            <Steps>
                <Step done>1</Step>
                <Step selected>2</Step>
            </Steps>
            <p className="text-xl pt-40">{t('sub_fields_use_previous_usages_desc')}</p>
            <div className="flex justify-center mt-auto">
                <Button onClick={() => onChoose(false)} variant="danger-outline" className="mr-4">
                    {t('no')}
                </Button>
                <Button onClick={() => onChoose(true)}>{t('yes')}</Button>
            </div>
        </>
    );
};

export default SubFieldsUsePreviousUsageChoice;
