import type {
    GeoFeature,
    GeoFeatureCollection,
    GeoLineString,
    GeoPolygon,
    GeoPosition,
} from '@fieldmargin/webapp-geo';
import { GeoPoint } from '@fieldmargin/webapp-geo';
import { noteIsTodo } from 'activity/activity-helper';
import { getZIndex } from 'components/maps/model/MapLayerIndex';
import type MapPosition from 'components/maps/model/MapPosition';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import { List } from 'immutable';
import { notNil } from 'lib/fp-helpers';
import { logReselect } from 'lib/util/reselect-util';
import type Note from 'notes/Note';
import { selectFilteredNoteSummaries } from 'notes/notes-filter';
import { selectNote } from 'notes/notes-selectors';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import type { ActiveSection } from 'system/url-util';

/**
 * - Notes are only shown when the active section is 'notes'
 * - No notes are shown if the user has turned off visibility
 * - Completed tasks are never shown
 * - If a note is selected or being edited, no other notes are shown
 */
export const selectNotes = createSelector(
    (_state: AppState, activeSection: ActiveSection) => activeSection.main === 'notes',
    selectFilteredNoteSummaries,
    (state: AppState, _activeSection: ActiveSection, selectedNoteUuid?: string) =>
        selectedNoteUuid !== undefined ? selectNote(state, selectedNoteUuid) : undefined,
    (state: AppState) => state.farmEditingState.editingType,
    (state: AppState) => state.farmEditingState.editingData,
    (state: AppState) => state.farmEditingState.editingGeoFeatureCollection,
    (state: AppState) => state.farmEditingState.layerVisibility.displayNotes,
    (state: AppState) => state.farmEditingState.highlightedGeoFeatureId,
    (state: AppState) => state.farmEditingState.lastMapPosition,
    (
        isActiveSection,
        notes: List<Note>,
        selectedNote,
        editingType,
        editingData,
        editingGeoFeatureCollection,
        displayNotes,
        highlightedGeoFeatureId,
        lastMapPosition
    ): List<GeoFeature> => {
        logReselect('selectNotes');
        if (editingType === 'note' && editingGeoFeatureCollection) {
            return noteToGeoFeatures(
                isActiveSection,
                highlightedGeoFeatureId,
                selectedNote?.uuid,
                lastMapPosition,
                editingData.id ? notes.find((note) => note.uuid === editingData.id) || null : null,
                editingGeoFeatureCollection,
                true
            );
        } else if (selectedNote !== undefined) {
            return noteToGeoFeatures(
                isActiveSection,
                highlightedGeoFeatureId,
                selectedNote.uuid,
                lastMapPosition,
                selectedNote,
                selectedNote.getGeoFeatureCollection(),
                false
            );
        } else if (isActiveSection && displayNotes) {
            let geoFeatures = List();
            notes
                .filter((note) => !note.archived)
                .filter((note) => !note.task || noteIsTodo(note))
                .forEach((note) => {
                    geoFeatures = geoFeatures.concat(
                        noteToGeoFeatures(
                            isActiveSection,
                            highlightedGeoFeatureId,
                            undefined,
                            lastMapPosition,
                            note,
                            note.getGeoFeatureCollection(),
                            false
                        )
                    );
                });
            return geoFeatures;
        }
        return List();
    }
);

export const selectNoteMarkers = createSelector(
    (
        state: AppState,
        activeSection: ActiveSection,
        { selectedNoteUuid }: { selectedNoteUuid?: string }
    ) => selectNotes(state, activeSection, selectedNoteUuid),
    (noteGeoFeatures) => {
        logReselect('selectNoteMarkers');

        return noteGeoFeatures
            .toArray()
            .filter((geoFeature) => geoFeature.properties.get('noteMarker'))
            .map((geoFeature) => {
                // Turn the shapes to into points
                let geoPosition = null as GeoPosition | null;
                if (geoFeature.geometry.type === 'Point') {
                    geoPosition = (geoFeature.geometry as GeoPoint).coordinates;
                } else if (geoFeature.geometry.type === 'LineString') {
                    geoPosition = (geoFeature.geometry as GeoLineString).coordinates.get(
                        0
                    ) as GeoPosition;
                } else if (geoFeature.geometry.type === 'Polygon') {
                    geoPosition = (geoFeature.geometry as GeoPolygon).getIn([
                        'coordinates',
                        0,
                        0,
                    ]) as GeoPosition;
                }

                if (geoPosition) {
                    return geoFeature.set(
                        'geometry',
                        GeoPoint({
                            coordinates: geoPosition,
                        })
                    );
                }
                return null;
            })
            .filter(notNil);
    }
);

const noteToGeoFeatures = (
    isActiveSection: boolean,
    highlightedGeoFeatureId: string | null,
    selectedNoteUuid: string | undefined,
    lastMapPosition: MapPosition | null,
    note: Note | null,
    geoFeatureCollection: GeoFeatureCollection,
    isEditing: boolean
) => {
    const showMapShapes =
        isEditing ||
        selectedNoteUuid ||
        !lastMapPosition ||
        !lastMapPosition.zoom ||
        lastMapPosition.zoom >= 15;

    return geoFeatureCollection.features.map((geoFeature, i) => {
        const isHighlighted = geoFeature.id === highlightedGeoFeatureId;
        let noteMarker = null as string | null;
        if (!isEditing && i === 0) {
            // Show note marker on the first shape of notes, except when editing
            if ((note as Note).task) {
                noteMarker = 'task';
            } else if ((note as Note).archived) {
                noteMarker = 'archived';
            } else {
                noteMarker = 'text';
            }
        }
        return geoFeature.set(
            'properties',
            OLFeatureProperties({
                type: 'note',
                editable: isEditing,
                colour: '#fff',
                pointScale: isHighlighted ? 9 : 6,
                pointOpacity: 0.6,
                strokeWeight: isHighlighted ? 5 : 3,
                zIndex: getZIndex(isActiveSection, 'note', geoFeature.geometry.type),
                lineEndCircles: true,
                noteMarker,
                hideShapes: !showMapShapes,
            })
        );
    });
};
