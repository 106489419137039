import { useTranslation } from 'react-i18next';
import c from 'classnames';
import type Field from 'fields/Field';
import { isSubField } from 'fields/Field';
import { Form, Text } from 'informed';
import { useOutOfDatePromise } from 'lib/hooks';
import EditableActions from 'sidebar/modules/common/EditableActions';
import TitleText from 'sidebar/modules/title/TitleText';
import type { SingleParamVoidFunction } from 'system/types';

interface EditableTitleFormProps {
    field: Field;
    setEditing: SingleParamVoidFunction<boolean>;
    onSave: (newTitle: string, newId?: string) => Promise<any>;
}

interface FormValues {
    name: string;
    fieldId?: string;
}

const EditableTitleForm = ({ field, setEditing, onSave }: EditableTitleFormProps) => {
    const { t } = useTranslation();
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(() => setEditing(false));
    const handleSubmit = (values: FormValues) => {
        setPromise(onSave(values.name, values.fieldId));
    };

    return (
        <Form<FormValues>
            initialValues={{ name: field.name, fieldId: field.fieldId }}
            onSubmit={handleSubmit}
        >
            <div className={c('FieldTitle', { FieldTitleOnly: isSubField(field) })}>
                <div className="Title">
                    <h4>{t('hint_create_field_name')}</h4>
                    <TitleText field="name" />
                </div>
                {!isSubField(field) && (
                    <div className="Title FieldId">
                        <h4>{t('field_id')}</h4>
                        <Text
                            field="fieldId"
                            type="text"
                            className="simple-text-input"
                            autoComplete="off"
                            keepState={true}
                        />
                    </div>
                )}
            </div>
            <EditableActions
                disabled={pending}
                error={error}
                outOfDate={outOfDate}
                setEditing={setEditing}
            />
        </Form>
    );
};

export default EditableTitleForm;
