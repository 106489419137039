import c from 'classnames';
import icon from 'components/icons/icon';
import { preventDefaultAnd } from 'lib/dom';
import Notice from 'view/Notice';

export interface ToastState {
    message: string;
    level: string;
}

interface ToastProps {
    level: string;
    message: string;
    onClose: VoidFunction;
}

const Toast = ({ level, message, onClose }: ToastProps) => (
    <Notice className={c('absolute z-10 top-0 left-0 w-full text-base font-bold')} variant={level}>
        {message}
        <button className="bare-btn absolute top-1 right-1" onClick={preventDefaultAnd(onClose)}>
            {icon('close', 'dark-blue')}
        </button>
    </Notice>
);

export default Toast;
