import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import type { List } from 'immutable';
import { preventDefaultAnd } from 'lib/dom';
import type { SingleParamVoidFunction } from 'system/types';
import BadgeRadioGroup from 'view/form/hook/BadgeRadioGroup';
import type { BadgeItemWithIdLike } from 'view/molecules/badge-item/BadgeItem';

import SidebarModule from '../SidebarModule';

import './Badge.scss';

interface NewBadgeProps {
    field: string;
    label: string;
    options: List<BadgeItemWithIdLike>;

    allowNull?: boolean;

    onCreate?: VoidFunction;
    emptyState?: ReactNode;
    onChange?: SingleParamVoidFunction<BadgeItemWithIdLike>;
}

const NewBadge = ({
    field,
    label,
    options,
    allowNull = false,
    onCreate,
    emptyState,
    onChange,
}: NewBadgeProps) => {
    const { t } = useTranslation();
    const handleCreate = preventDefaultAnd(() => onCreate && onCreate());
    return (
        <SidebarModule className="NewBadge" editing={true}>
            <h4>
                {label}{' '}
                {onCreate && (
                    <Button variant="outline" small onClick={handleCreate}>
                        {t('create_new')}
                    </Button>
                )}
            </h4>
            <BadgeRadioGroup
                field={field}
                options={options.toArray()}
                allowNull={allowNull}
                onChange={onChange}
            />
            {options.size === 0 && emptyState}
        </SidebarModule>
    );
};

export default NewBadge;
