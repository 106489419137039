import { Navigate, useNavigate } from 'react-router-dom';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { useCurrentManualSensor } from 'hooks/selectors/src/useCurrentManualSensor.selector';
import { useIsFarmOwner } from 'hooks/selectors/src/useIsFarmOwner';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { t } from 'i18next';
import { deleteManualSensorApi } from 'sensors/manual/manual-sensors-api';
import { removeManualSensor } from 'sensors/manual/manual-sensors-state';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import { useAppDispatch } from 'system/store';

const ManualSensorDeletion = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { currentFarm: farm } = useCurrentFarm();
    const { currentManualSensor: manualSensor } = useCurrentManualSensor();
    const { isFarmOwner: canDelete } = useIsFarmOwner();
    const { pending, error, setPromise } = usePromise<string>((manualSensorId) => {
        dispatch(removeManualSensor(manualSensorId));
        navigate(`/farms/${farm.uuid}/data`);
    });

    if (manualSensor === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/data`} />;
    }

    const handleDelete = () => {
        setPromise(deleteManualSensorApi(farm.uuid, manualSensor.id as string));
    };

    const renderContent = () => {
        if (canDelete) {
            return (
                <SidebarModule editing>
                    <p className="text-lg">{t('sensors_delete_manual_prompt')}</p>
                    <Button variant="danger" onClick={handleDelete} disabled={pending}>
                        {t(pending ? 'label_deleting' : 'label_delete')}
                    </Button>
                </SidebarModule>
            );
        } else {
            return (
                <SidebarModule editing>
                    <p className="text-lg text-error-text">
                        {t('insufficient_permissions_monitoring_site')}
                    </p>
                </SidebarModule>
            );
        }
    };
    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader>
                    <SidebarHeaderLink to={`/farms/${farm.uuid}/data/${manualSensor.id}`}>
                        {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                    </SidebarHeaderLink>
                </SidebarHeader>
                {error && (
                    <SidebarError
                        title={t('failed_to_delete')}
                        message={t('something_went_wrong')}
                    />
                )}
            </div>
            <div className="scrolling">
                <SidebarModule editing>
                    <h2 className="mb-0">{manualSensor.name}</h2>
                </SidebarModule>
                {renderContent()}
            </div>
        </div>
    );
};

export default ManualSensorDeletion;
