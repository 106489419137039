import { useState } from 'react';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import c from 'classnames';
import type Field from 'fields/Field';
import { useStartEditingField } from 'hooks/useStartEditingField';
import EditButton from 'sidebar/modules/common/EditButton';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { UnaryPromiseFunction } from 'system/types';

import FieldBoundaryContainer from '../boundary/FieldBoundaryContainer';
import FieldBoundaryForm from '../boundary/FieldBoundaryForm';

interface EditableBoundaryProps {
    // From parent
    field: Field;
    onSave: UnaryPromiseFunction<GeoFeatureCollection>;
}

const EditableBoundary = ({ field, onSave }: EditableBoundaryProps) => {
    const { startEditingField } = useStartEditingField();
    const [editing, setEditing] = useState(false);

    if (!field.geoJson) {
        return null;
    }

    const handleStartEditing = () => {
        startEditingField(field);
        setEditing(true);
    };

    const handleStopEditing = () => {
        setEditing(false);
    };

    return (
        <SidebarModule editing={editing} className={c('p-0', { archived: field.archived })}>
            {!editing ? (
                <>
                    <EditButton
                        setEditing={handleStartEditing}
                        className="mt-5 mr-5"
                        disabled={field.archived}
                    />
                    <FieldBoundaryContainer
                        editingGeoFeatureCollection={field.geoJson}
                        editing={false}
                    />
                </>
            ) : (
                <FieldBoundaryForm onStopEditing={handleStopEditing} onSave={onSave} />
            )}
        </SidebarModule>
    );
};

export default EditableBoundary;
