import type { MouseEvent } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import Button from '@fieldmargin/webapp-styling/components/button';
import { clsx } from 'clsx';
import icon from 'components/icons/icon';
import {
    removeEditingGeoFeatureById,
    selectDrawingTool,
    startMeasuring,
} from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { trackEvent } from 'utils/trackEvent';
import DropdownButton from 'view/dropdown-button/DropdownButton';
import { DropdownButtonItem } from 'view/dropdown-button/DropdownButtonItem';
import ShapeBubbles from 'view/shapes/ShapeBubbles';
import ShapeControl from 'view/shapes/ShapeControl';

import styles from './MeasureTool.module.css';

interface MeasureToolProps {
    // From parent
    toggleMeasureTool: VoidFunction;

    // From redux
    farm: Farm;
    drawingTool: DrawingTool | null;
    selectDrawingTool: typeof selectDrawingTool;
    editingGeoFeatureCollection: GeoFeatureCollection | null;
    removeEditingGeoFeatureById: typeof removeEditingGeoFeatureById;
    startMeasuring: typeof startMeasuring;
}

const MeasureTool = ({
    farm,
    toggleMeasureTool,
    drawingTool,
    selectDrawingTool,
    editingGeoFeatureCollection,
    removeEditingGeoFeatureById,
    startMeasuring,
}: MeasureToolProps) => {
    const { t } = useTranslation();
    useEffect(() => {
        startMeasuring();
    }, [startMeasuring]);

    const handleCloseClick = (e?: MouseEvent) => {
        e?.preventDefault();
        toggleMeasureTool();
    };

    const handleRemoveGeoFeature = (id: string | number) => {
        removeEditingGeoFeatureById({ id, nextDrawingTool: drawingTool });
    };

    const handleSelectDrawingTool = (drawingTool: DrawingTool) => {
        selectDrawingTool(drawingTool);
    };

    const noFeatures =
        !editingGeoFeatureCollection || editingGeoFeatureCollection.features.size === 0;

    const fieldAllowed =
        editingGeoFeatureCollection &&
        editingGeoFeatureCollection.features.size === 1 &&
        (editingGeoFeatureCollection.features.get(0) as GeoFeature).geometry.type === 'Polygon';

    const trackSaveAsForFarm = trackSaveAs(farm.uuid);

    return (
        <div className={clsx(styles.measureTool, 'no-print forefront-abs')}>
            <h2>
                {t('measure_tool_title', { defaultValue: 'Measure Tool' })}
                <a href="#" onClick={handleCloseClick}>
                    {icon('close', 'dark-blue')}
                </a>
            </h2>
            <p>{t('measure_tool_desc', { defaultValue: 'Select a shape to measure' })}</p>
            <div className="flex flex-wrap mb-3">
                <ShapeControl
                    tool="area"
                    selected={drawingTool === 'area'}
                    onClick={() => handleSelectDrawingTool('area')}
                    className="mr-2"
                />
                <ShapeControl
                    tool="line"
                    selected={drawingTool === 'line'}
                    onClick={() => handleSelectDrawingTool('line')}
                    className="mr-2"
                />
                <ShapeControl
                    tool="circle"
                    selected={drawingTool === 'circle'}
                    onClick={() => handleSelectDrawingTool('circle')}
                />
            </div>
            <div className="mb-3">
                <ShapeBubbles
                    geoFeatureCollection={editingGeoFeatureCollection || GeoFeatureCollection()}
                    onRemove={handleRemoveGeoFeature}
                    emptyMessage=""
                />
            </div>
            <div className="flex">
                <DropdownButton
                    text={
                        <>
                            {icon('add', 'white', 'mr-3')}{' '}
                            {t('label_save_as', { defaultValue: 'Save as' })}
                        </>
                    }
                    disabled={noFeatures}
                    className="mr-4"
                >
                    <DropdownButtonItem.Root>
                        <DropdownButtonItem.LinkItem
                            to={`/farms/${farm.uuid}/fields/new`}
                            disabled={!fieldAllowed}
                            onClick={(e) => {
                                if (!fieldAllowed) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                                trackSaveAsForFarm('Measure tool save as field clicked');
                            }}
                        >
                            {t('field')}
                        </DropdownButtonItem.LinkItem>
                    </DropdownButtonItem.Root>
                    <DropdownButtonItem.Root>
                        <DropdownButtonItem.LinkItem
                            to={`/farms/${farm.uuid}/features/new`}
                            onClick={() => {
                                trackSaveAsForFarm('Measure tool save as feature clicked');
                            }}
                        >
                            {t('feature_details_title')}
                        </DropdownButtonItem.LinkItem>
                    </DropdownButtonItem.Root>
                    <DropdownButtonItem.Root>
                        <DropdownButtonItem.LinkItem
                            to={`/farms/${farm.uuid}/notes/new`}
                            onClick={() => {
                                trackSaveAsForFarm('Measure tool save as note clicked');
                            }}
                        >
                            {t('note')}
                        </DropdownButtonItem.LinkItem>
                    </DropdownButtonItem.Root>
                </DropdownButton>
                <Button onClick={handleCloseClick} variant="danger-outline" disabled={noFeatures}>
                    {t('discard')}
                </Button>
            </div>
        </div>
    );
};

const trackSaveAs = (farmUuid: string) => (msg: string) => trackEvent(msg, { farmUuid });

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        drawingTool: state.farmEditingState.drawingTool,
        editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,
    }),
    (dispatch) =>
        bindActionCreators(
            { selectDrawingTool, startMeasuring, removeEditingGeoFeatureById },
            dispatch
        )
)(MeasureTool);
