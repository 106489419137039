import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import { analytics } from '@notes-web/analytics';
import { useIsFarmOwner } from 'hooks/selectors/src/useIsFarmOwner';
import { useFarmAction } from 'hooks/useFarmAction';
import Modal from 'modal/Modal';
import UpgradeButton from 'upgrade/UpgradeButton';
import ErrorMessage from 'view/ErrorMessage';
import PadlockIcon from 'view/svg/PadlockIcon';

interface FarmBlockedModal {
    farm: Farm;
}

// Usage in your component

const FarmBlockedModal = ({ farm }: FarmBlockedModal) => {
    const { t } = useTranslation();
    const { isFarmOwner } = useIsFarmOwner();
    const { pending, error, handleClick } = useFarmAction();

    analytics.track('Farm locked modal seen', {
        farmUuid: farm.uuid,
    });

    return (
        <Modal allowClose={false}>
            <div className="bg-white rounded text-center text-lg flex flex-col items-center gap-6 py-10 px-20">
                <PadlockIcon fill="#ce1d18" className="w-12" />
                <h2 className="text-xxl">{t('upgrade_needed')}</h2>
                <p className="mb-0">{t('farm_limit_reached')}</p>
                <p className="mb-0">{t('please_upgrade')}</p>
                <UpgradeButton className="w-40" />
                <Button
                    variant="danger-outline"
                    onClick={handleClick}
                    className="w-40"
                    disabled={pending}
                >
                    {isFarmOwner ? t('delete_farm') : t('label_settings_farm_leave')}
                </Button>
                {error && <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>}
            </div>
        </Modal>
    );
};

export default FarmBlockedModal;
