import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { Farm } from '@fieldmargin/webapp-farms';
import { findFarm } from '@fieldmargin/webapp-farms';
import type { Extent } from '@fieldmargin/webapp-geo';
import { deserializeLngLat, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { createLogger } from '@fieldmargin/webapp-reporting';
import type { FeatureCollection, MultiPolygon } from '@turf/helpers';
import { multiPolygonFeatureToPolygonFeature } from 'lib/geo/geometry';

const logger = createLogger('fields.sidebar.auto-boundary.auto-boundary-api');

export enum AutoBoundaryCoverage {
    COMPLETE = 'COMPLETE',
    PARTIAL = 'PARTIAL',
    SOME = 'SOME',
    LITTLE = 'LITTLE',
    NONE = 'NONE',
}

/**
 * Fetch the coverage level for the farm.
 * If this request fails NONE will be returned instead because this is not a critical request.
 */
export const fetchAutoBoundaryCoverage = (farm: Farm) =>
    // First we need to know if the farm has a country. When a farm is created the country
    // is set asynchronously on the server. If the country is not set yet we can't do the
    // auto-detect request so we'll need to fetch the farm again.
    new Promise<Farm>((resolve) =>
        farm.country !== '' ? resolve(farm) : resolve(findFarm(farm.uuid))
    ).then((farm) =>
        // It's still possible the farm has no country due to reverse geo coding lookup failing
        farm.country === ''
            ? AutoBoundaryCoverage.NONE
            : authorizedRequest<AutoBoundaryCoverage>({
                  url: `/boundary-uploader-api/boundary-uploader/v1/countryCoverage/${farm.country}`,
              }).catch(() => AutoBoundaryCoverage.NONE)
    );

/**
 * Fetch the auto-detected boundaries for the given bounding box.
 */
export const fetchAutoBoundaries = (farmUuid: string, extent: Extent) =>
    authorizedRequest<FeatureCollection<MultiPolygon>>({
        url: `/boundary-uploader-api/boundary-uploader/v1/${farmUuid}/fields`,
        params: {
            minLongitude: extent.minX,
            maxLongitude: extent.maxX,
            minLatitude: extent.minY,
            maxLatitude: extent.maxY,
        },
    })
        .then((featureCollection) => ({
            ...featureCollection,
            features: featureCollection.features.map((feature) =>
                feature.geometry.type === 'MultiPolygon'
                    ? multiPolygonFeatureToPolygonFeature(feature)
                    : feature
            ),
        }))
        .then((featureCollection) => deserializeLngLat(featureCollection) as GeoFeatureCollection)
        .catch((e) => {
            logger.handleRequestError('Unable to fetch auto boundaries')(e);
            return GeoFeatureCollection();
        });
