import c from 'classnames';

const ZoomIcon = ({ className }: { className?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 28 28">
            <path
                className={c('fill-fm-blue', className)}
                d="M2367.98,357.548a12.434,12.434,0,1,0,12.43,12.434A12.451,12.451,0,0,0,2367.98,357.548Zm0.99,22.731v-5.334h-1.99v5.334a10.361,10.361,0,0,1-9.3-9.3h5.33v-1.99h-5.33a10.36,10.36,0,0,1,9.3-9.3v5.333h1.99v-5.334a10.361,10.361,0,0,1,9.3,9.3h-5.33v1.99h5.33A10.361,10.361,0,0,1,2368.97,380.279Z"
                transform="translate(-2354 -356)"
            />
        </svg>
    );
};

export default ZoomIcon;
