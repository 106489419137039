import type { MouseEvent, ReactNode } from 'react';
import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { ChatLog, DayChatGroup, UserChatGroup } from 'chat/chat-models';
import type { ChatComment, ChatFile, ChatImage } from 'chat/chat-models';
import DiscussionImage from 'chat/chatlog/DiscussionImage';
import { clsx } from 'clsx';
import { useDateFormat } from 'hooks/useDateFormat';
import { useUserId } from 'hooks/useUserId';
import { bytesToSize } from 'lib/util/number';
import { useFarmUserDisplayName } from 'team/farm-users-hooks';

import fileIcon from './file-icon.svg';
import pdfIcon from './pdf-icon.svg';

import styles from './DiscussionList.module.css';

const Comment = ({ text }: { text: string }) => {
    const out: ReactNode[] = [];
    const paragraphs = text.split('\n\n');
    paragraphs.forEach((paragraph, i) => {
        if (i > 0) {
            out.push(<br key={`break1-${i}`} />);
            out.push(<div key={`spacer-${i}`} className={styles.spacer} />);
        }
        const lines = paragraph.split('\n');
        lines.forEach((line, j) => {
            if (j > 0) {
                out.push(<br key={`break2-${j}`} />);
            }
            out.push(line.trim());
        });
    });
    return out;
};

const Time = ({ date }: { date: Date }) => {
    const { format } = useDateFormat();
    return <span className={styles.time}>{format(date, 'k:mm')}</span>;
};

const FileTime = ({ date }: { date: Date }) => {
    const { format } = useDateFormat();
    return <span className={clsx(styles.time, styles.fileTime)}>{format(date, 'k:mm')}</span>;
};

const FileIcon = ({ mediaType }: { mediaType: string }) => {
    let src = fileIcon;
    if (mediaType === 'pdf') {
        src = pdfIcon;
    }
    return <img className={styles.fileIcon} src={src} alt="" />;
};

const File = ({ file, className }: { file: ChatFile; className: string }) => {
    const handleClick = (e: MouseEvent) => {
        if (file.isFarmChat) {
            // For farm chat files we need to fetch the link first and then open it in a new tab.
            e.preventDefault();
            authorizedRequest<string>({ url: file.url }).then((link) => {
                const a = document.createElement('a');
                a.href = link;
                a.target = '_blank';
                a.rel = 'noopener noreferrer';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
        }
    };
    return (
        <a
            target="_blank"
            rel="noopener noreferrer"
            href={file.url}
            onClick={handleClick}
            className={className}
        >
            <FileIcon mediaType={file.mediaType} />
            <div className={styles.fileBubbleBody}>
                <p className={styles.fileTitle}>{file.fileName}</p>
                <FileTime date={file.date} />
                <p className={styles.fileSize}>{bytesToSize(file.mediaSize)}</p>
            </div>
        </a>
    );
};

const OtherBubble = ({ comment, withStalk }: { comment: ChatComment; withStalk: boolean }) => {
    return (
        <div className={styles.container}>
            <div className={clsx(styles.bubbleLeft, { [styles.withStalk]: withStalk })}>
                {withStalk && (
                    <div className={styles.stalk}>
                        <div className={styles.stalkInner} />
                    </div>
                )}
                <Comment text={comment.comment} />
                <Time date={comment.date} />
            </div>
        </div>
    );
};

const FileBubble = ({ file, myFile }: { file: ChatFile; myFile: boolean }) => {
    const classes = clsx({
        [styles.fileRight]: myFile,
        [styles.fileLeft]: !myFile,
    });

    return (
        <div className={styles.container}>
            <File file={file} className={classes} />
        </div>
    );
};

const ImageBubble = ({ image, myImage }: { image: ChatImage; myImage: boolean }) => {
    return (
        <div>
            <div className={myImage ? styles.imageRight : styles.imageLeft}>
                <DiscussionImage image={image} />
                <Time date={image.date} />
            </div>
            <div className={styles.clear} />
        </div>
    );
};

const MyBubble = ({ comment, withStalk }: { comment: ChatComment; withStalk: boolean }) => {
    return (
        <div className={styles.container}>
            <div className={clsx(styles.bubbleRight, { [styles.withStalk]: withStalk })}>
                <Comment text={comment.comment} />
                {withStalk && (
                    <div className={styles.stalk}>
                        <div className={styles.stalkInner} />
                    </div>
                )}
                <Time date={comment.date} />
            </div>
        </div>
    );
};

const ChatGroup = ({ userChatGroup }: { userChatGroup: UserChatGroup }) => {
    const { userId } = useUserId();
    const getDisplayName = useFarmUserDisplayName();

    const bubbles = userChatGroup.items.map((item, i) => {
        let bubble: ReactNode;
        if (item.type === 'comment') {
            if (item.userId === userId) {
                bubble = (
                    <MyBubble
                        comment={item as ChatComment}
                        withStalk={i === userChatGroup.items.size - 1}
                    />
                );
            } else {
                bubble = (
                    <OtherBubble
                        comment={item as ChatComment}
                        withStalk={i === userChatGroup.items.size - 1}
                    />
                );
            }
            return <div key={item.id}>{bubble}</div>;
        } else if (item.type === 'image') {
            bubble = <ImageBubble image={item as ChatImage} myImage={item.userId === userId} />;
        } else if (item.type === 'file') {
            bubble = <FileBubble file={item as ChatFile} myFile={item.userId === userId} />;
        }

        if (bubble) {
            return <div key={item.id}>{bubble}</div>;
        }
        return null;
    });
    const classes = clsx(styles.container, {
        [styles.right]: userChatGroup.userId === userId,
    });
    return (
        <div className={classes}>
            <div className={styles.nameLabel}>{getDisplayName(userChatGroup.userId)}</div>
            {bubbles}
        </div>
    );
};

const DayChatGroupView = ({ dayChatGroup }: { dayChatGroup: DayChatGroup }) => {
    const { format } = useDateFormat();
    const items = dayChatGroup.userChatGroups.map((userChatGroup, i) => {
        return (
            <div className={styles.chat} key={i}>
                <ChatGroup userChatGroup={userChatGroup} />
            </div>
        );
    });

    return (
        <div key={dayChatGroup.date.valueOf()} className={styles.discussionListWrapper}>
            <div className={styles.dayLabel}>{format(dayChatGroup.date, 'do MMMM yyyy')}</div>
            {items}
        </div>
    );
};

const DiscussionList = ({ chatLog }: { chatLog: ChatLog }) => {
    if (chatLog.dayChatGroups.size === 0) {
        return null;
    }

    const days = chatLog.dayChatGroups.map((dayChatGroup, i) => (
        <DayChatGroupView key={i} dayChatGroup={dayChatGroup} />
    ));
    return <div className={styles.discussionList}>{days}</div>;
};

export { DiscussionList };
