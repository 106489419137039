import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';

interface ChoiceModalProps {
    clearModal: VoidFunction;
    content: ReactNode;
    onConfirm?: VoidFunction;
    onCancel?: VoidFunction;
    cancelText?: string;
    confirmText?: string;
}

const ChoiceModal = ({
    clearModal,
    content,
    onConfirm,
    onCancel,
    cancelText,
    confirmText,
}: ChoiceModalProps) => {
    const { t } = useTranslation();
    const handleConfirm = () => {
        onConfirm !== undefined && onConfirm();
        clearModal();
    };
    return (
        <div className="bg-white py-8 px-12 text-center">
            {content}
            <div className="flex items-center">
                <Button
                    variant="danger-outline"
                    onClick={onCancel ? onCancel : clearModal}
                    className="flex-grow mr-4"
                >
                    {cancelText ?? t('cancel')}
                </Button>
                <Button onClick={handleConfirm} className="flex-grow">
                    {confirmText ?? t('ok')}
                </Button>
            </div>
        </div>
    );
};

export default ChoiceModal;
