/**
 * The interfaces defined here are temporary, they represent models that are not yet defined in
 * Typescript. They should be removed gradually as the codebase is updated.
 */

import type { ChangeEvent } from 'react';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import type { AxiosRequestConfig } from 'axios';
import type { FieldDTO } from 'fields/Field';
import type Field from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import type { PaymentWebConfig } from 'lib/config';
import type { Organisation } from 'organisation/organisation-state';

import type { LoadTimeState } from './load-time-state';

declare global {
    interface Window {
        ga: (a: string, b: string, c: string) => void;
        Intercom: Function;
        NOTES_WEB_VERSION: string;
        NOTES_WEB_ENV: string;

        FMPayment:
            | {
                  init: ({
                      data,
                      configSpec,
                      hostEl,
                      requester,
                      events,
                      loadTimeState,
                  }: {
                      data: any;
                      configSpec: PaymentWebConfig;
                      hostEl: Element;
                      requester: <T>(config: AxiosRequestConfig) => Promise<T>;
                      events: {
                          onMembershipUpdate: (
                              finishAt: Date | null,
                              trialAvailable: boolean
                          ) => void;
                      };
                      loadTimeState: LoadTimeState;
                  }) => Promise<VoidFunction>;
                  unmount: VoidFunction;
              }
            | undefined;

        FMBoundaryUploader:
            | {
                  init: ({
                      hostEl,
                      farm,
                      onCreateFields,
                      requester,
                  }: {
                      hostEl: Element;
                      farm: Farm;
                      azureMapKey: string;
                      onCreateFields: (fieldDtos: FieldDTO[]) => void;
                      requester?: <T>(config: AxiosRequestConfig) => Promise<T>;
                  }) => Promise<VoidFunction>;
                  unmount: VoidFunction;
              }
            | undefined;

        FMOrganisationManagement:
            | {
                  init: ({
                      hostEl,
                      requester,
                      organisation,
                      onBack,
                  }: {
                      hostEl: Element;
                      requester: <T>(config: AxiosRequestConfig) => Promise<T>;
                      organisation: Organisation;
                      onBack?: VoidFunction;
                  }) => Promise<VoidFunction>;
                  unmount: VoidFunction;
              }
            | undefined;

        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }

    const ENVIRONMENT_NAME: string;
}

export type Nullable<T> = T | null;

export interface Item {
    createdByUserId: number;
}

export interface GeoJsonItem {
    uuid: string;
    geoJson: GeoFeatureCollection;
}
export interface GeoJsonItemWithName extends GeoJsonItem {
    name: string;
}

export enum VisibilityOptions {
    ON = 'on',
    BORDER = 'border',
    HALF = 'half',
    OFF = 'off',
}

export interface CodedItem {
    code: string;
    name: string;
}

export interface FieldAndCurrentUsage {
    field: Field;
    currentUsage: FieldUsage;
}

export type ChangeEventVoidFunction<Element extends HTMLElement> = (
    event: ChangeEvent<Element>
) => void;

export type SingleParamVoidFunction<Param> = (p: Param) => void;
export type UnaryBooleanFunction<Param> = (a: Param) => boolean;

export type UnaryPromiseFunction<Param, Result = any> = (a: Param) => Promise<Result>;
