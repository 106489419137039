import type { PropsWithChildren } from 'react';
import { Children, Fragment } from 'react';
import c from 'classnames';

const Steps = ({ children }: PropsWithChildren) => {
    const childArray = Children.toArray(children).filter((child) => child !== null);
    const totalChildren = childArray.length;
    return (
        <div className="flex items-center justify-center w-full mx-auto mb-10">
            {childArray.map((child, i) =>
                i + 1 !== totalChildren ? (
                    <Fragment key={i}>
                        {child}
                        <div className="h-1 bg-fm-sky w-28" />
                    </Fragment>
                ) : (
                    <Fragment key={i}>{child}</Fragment>
                )
            )}
        </div>
    );
};

export default Steps;

export const Step = ({
    children,
    selected = false,
    done = false,
}: PropsWithChildren<{ selected?: boolean; done?: boolean }>) => {
    return (
        <div
            className={c(
                'text-lg font-bold rounded-full border-solid h-7 w-7 leading-7 box-content',
                {
                    'bg-fm-sky text-white border-transparent ': selected,
                    'bg-gray-300 text-white border-transparent ': done,
                    'bg-white text-fm-blue border-fm-sky': !selected && !done,
                }
            )}
        >
            {children}
        </div>
    );
};
