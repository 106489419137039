import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import Modal from 'modal/Modal';

interface BulkOutputModalProps {
    onClose: VoidFunction;
    onCreate: VoidFunction;
}

const BulkOutputModal = ({ onClose, onCreate }: BulkOutputModalProps) => {
    const { t } = useTranslation();
    const handleCreate = () => {
        onCreate();
        onClose();
    };
    return (
        <Modal onClose={onClose}>
            <div className="bg-white p-6 w-96 text-center">
                <h2 className="uppercase">
                    {t('do_you_want_to_set_up_all_your_outputs_what_you_are_harvesting')}
                </h2>
                <div className="flex justify-center gap-4">
                    <Button onClick={onClose} variant="danger-outline">
                        {t('not_now')}
                    </Button>
                    <Button onClick={handleCreate}>{t('yes')}</Button>
                </div>
            </div>
        </Modal>
    );
};

export default BulkOutputModal;
