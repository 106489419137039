import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import { Promise } from 'bluebird';
import type CropType from 'fields/CropType';
import i18next from 'i18next';
import { useFormApi, useFormState } from 'informed';
import { searchCropTypes } from 'inputs/pesticides-api';
import { useDebouncedFunc } from 'lib/hooks';
import { path } from 'ramda';
import Combobox from 'view/combobox/Combobox';
import ComboboxItem from 'view/combobox/ComboboxItem';
import HiddenFormField from 'view/form/HiddenFormField';

interface CropTypeFormFieldProps {
    farm: Farm;
    field: string;
    initialValue?: string;
    validate: (v: any) => any;
    label?: ReactNode;
    allowOther?: boolean;
}

const getDefaultSearchResults = (): CropType[] => [{ code: 'other', name: i18next.t('other') }];

const CropTypeFormField = ({
    farm,
    field,
    initialValue = '',
    validate,
    label,
    allowOther = true,
}: CropTypeFormFieldProps) => {
    const { t } = useTranslation();
    const formState = useFormState();
    const formApi = useFormApi();
    const codeField = `${field}Code`;
    const nameField = `${field}Name`;
    const formError = path<string | undefined>(['errors'].concat(codeField.split('.')), formState);

    const [searchTerm, setSearchTerm] = useState<string>(initialValue);
    const [searchResults, setSearchResults] = useState<CropType[]>(
        allowOther ? getDefaultSearchResults() : []
    );
    const { setPromise } = usePromise<{
        searchResults: CropType[];
        clearForm?: boolean;
    }>(({ searchResults, clearForm = true }) => {
        setSearchResults(
            allowOther ? searchResults.concat(getDefaultSearchResults()) : searchResults
        );
        clearForm && setFormValues();
    });

    const setFormValues = (type?: string, name?: string) => {
        formApi.setValue(codeField, type);
        formApi.setValue(nameField, name);
    };

    const debouncedSearch = useDebouncedFunc((value: string, clearForm: boolean = true) => {
        setPromise(
            Promise.props({
                searchResults: searchCropTypes(farm.uuid, value),
                clearForm,
            })
        );
    }, 300);

    useEffect(() => {
        initialValue && debouncedSearch(initialValue, false);
    }, []);

    const handleSearch = (value: string) => {
        setSearchTerm(value);
        if (value === '') {
            debouncedSearch.cancel();
            allowOther && setSearchResults(getDefaultSearchResults());
            setFormValues();
            formApi.validate();
        } else {
            debouncedSearch(value);
        }
    };

    const handleItemSelected = ({ code, name }: CropType) => {
        setSearchTerm(name);
        setFormValues(code, name);
        formError && formApi.validate();
    };

    return (
        <div className="form-field">
            <label htmlFor="crop-type-search">
                {label !== undefined ? (
                    label
                ) : (
                    <>
                        <span className="block mb-1">{t('field_usages_crop_type')}</span>
                        <span className="block text-sm text-gray-550">
                            {t('field_usages_create_label')}
                        </span>
                    </>
                )}
            </label>
            <HiddenFormField field={codeField} validate={validate} />
            <HiddenFormField field={nameField} />
            <Combobox
                id="crop-type-search"
                search={searchTerm}
                onSearch={handleSearch}
                error={formError !== undefined}
                placeholder={t('start_typing_to_search_crops')}
            >
                {searchResults.length > 0 ? (
                    searchResults.map((result, key) => (
                        <ComboboxItem
                            key={key}
                            item={result.name}
                            onSelect={() => handleItemSelected(result)}
                        />
                    ))
                ) : allowOther ? (
                    <ComboboxItem item={t('no_results')} onSelect={() => {}} />
                ) : null}
            </Combobox>
            {formError && <div className="field-error">{formError}</div>}
        </div>
    );
};

export default CropTypeFormField;
