import type { FieldProps } from 'informed';
import { TextArea, useFieldState } from 'informed';

import './TextareaFormField.css';

interface TextareaFormFieldProps extends FieldProps<any, any> {
    error?: string;
}

const TextareaFormField = ({ error, ...props }: TextareaFormFieldProps) => {
    const fieldState = useFieldState(props.field);

    return (
        <div className="TextareaFormField form-field">
            <div className="TextareaWrapper" data-replicated-value={fieldState.value}>
                <TextArea {...props} />
            </div>
            {error && <div className="field-error">{error}</div>}
        </div>
    );
};

export default TextareaFormField;
