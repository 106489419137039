import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Sidebar } from '@notes-web/components';

import styles from './AddFieldsBase.module.css';

interface OptionSchema {
    path: string;
    title: string;
    description: string;
    logo: string;
    disabled: boolean;
}

interface AddFieldsBaseProps {
    options: Array<OptionSchema>;
}

function AddFieldsBase({ options }: AddFieldsBaseProps) {
    const { t } = useTranslation();

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Header>
                    <Sidebar.Heading>{t('addFields.heading')}</Sidebar.Heading>
                    <Sidebar.Description>{t('addFields.description')}</Sidebar.Description>
                </Sidebar.Header>

                <Sidebar.Card>
                    <div className={styles.linksHeading}>{t('addFields.optionsTitle')}</div>
                    <div className={styles.linksWrapper}>
                        {options.map((item) => (
                            <Link
                                key={item.path}
                                to={item.path}
                                className={styles.link}
                                aria-disabled={item.disabled}
                            >
                                <div className={styles.linkImageContainer}>
                                    <img
                                        className={styles.linkImage}
                                        src={item.logo}
                                        alt={t(item.title)}
                                    />
                                </div>

                                <div className={styles.linkText}>
                                    <Sidebar.Title>{t(item.title)}</Sidebar.Title>
                                    <Sidebar.Description>{t(item.description)}</Sidebar.Description>
                                </div>
                            </Link>
                        ))}
                    </div>
                </Sidebar.Card>
            </Sidebar.Body>
        </Sidebar.Root>
    );
}

export { AddFieldsBase };
