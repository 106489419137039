import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import { selectCurrentFarm } from 'farms/farms-state';
import { selectHasSensorsConnected } from 'integrations/integrations-state';
import { bindActionCreators } from 'redux';
import DemoMessage from 'sidebar/demo/DemoMessage';
import SidebarTitle from 'sidebar/modules/SidebarTitle';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';

import { fetchManualSensors } from '../../manual/manual-sensors-state';
import { fetchIntegrationSensors } from '../../sensors-state';

import IntegrationSensorsList from './IntegrationSensorsList';
import ManualSensorsList from './ManualSensorsList';

interface SensorsListProps {
    farm: Farm;
    hasAnySensors: boolean;
    fetchSensorsPending: boolean;
    hasSensorsConnected: boolean;
    fetchIntegrationSensors: typeof fetchIntegrationSensors;
    fetchManualSensors: typeof fetchManualSensors;
    changeModal: typeof changeModal;
}

const SensorsList = ({
    farm,
    hasAnySensors,
    fetchSensorsPending,
    hasSensorsConnected,
    fetchIntegrationSensors,
    fetchManualSensors,
}: SensorsListProps) => {
    const { t } = useTranslation();

    const handleRefresh = () => {
        if (hasSensorsConnected) {
            fetchIntegrationSensors(farm.uuid);
        }
        fetchManualSensors(farm.uuid);
    };

    return (
        <div className="sensors-list scrollable">
            <div className="sidebar-top non-scrolling">
                <SidebarTitle title={t('menu_data')}>
                    {hasAnySensors && (
                        <Button
                            disabled={fetchSensorsPending}
                            onClick={handleRefresh}
                            className="ml-3"
                        >
                            {t('label_refresh')}
                        </Button>
                    )}
                </SidebarTitle>
                {farm.demo && <DemoMessage />}
            </div>
            <div className="scrolling">
                <div className="items">
                    <ManualSensorsList />
                    {hasSensorsConnected && <IntegrationSensorsList />}
                </div>
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => {
        const sensorsByIntegration = state.sensorsState.sensorsByIntegration;
        const manualSensors = state.manualSensorsState.sensors;
        return {
            farm: selectCurrentFarm(state),
            hasAnySensors: !!(
                (sensorsByIntegration && sensorsByIntegration.size > 0) ||
                (manualSensors && manualSensors.size > 0)
            ),
            fetchSensorsPending:
                state.sensorsState.fetchIntegrationSensorsPending ||
                state.manualSensorsState.fetchManualSensorsPending,
            hasSensorsConnected: selectHasSensorsConnected(state),
        };
    },
    (dispatch) =>
        bindActionCreators(
            {
                fetchIntegrationSensors,
                fetchManualSensors,
                changeModal,
            },
            dispatch
        )
)(SensorsList);
