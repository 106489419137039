import { Record } from 'immutable';

import type WmsTiles from '../common/WmsTiles';
import type { XyzTilesData } from '../common/XyzTiles';
import XyzTiles, { deserializeXyzTiles } from '../common/XyzTiles';

class MapLayer extends Record({
    uuid: '',
    name: '',
    timestamp: new Date(),
    tiles: XyzTiles() as XyzTiles | WmsTiles,
    fieldUuids: [] as string[],
    farmIntegrationUuid: '',
}) {}

export interface FieldMapDTO {
    id: string;
    farmUUID: string;
    fieldUUID: string;
    farmIntegrationUUID: string;
    name: string;
    timestamp: number;
    tiles: XyzTilesData;
}

export const deserializeFieldMap = (json: FieldMapDTO) =>
    new MapLayer({
        uuid: json.id,
        name: json.name,
        timestamp: new Date(json.timestamp),
        tiles: deserializeXyzTiles(json.tiles),
        fieldUuids: json.fieldUUID ? [json.fieldUUID] : [],
        farmIntegrationUuid: json.farmIntegrationUUID,
    });

export default MapLayer;
