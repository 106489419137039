import type { Extent, GeoFeature, GeoPolygon } from '@fieldmargin/webapp-geo';
import {
    createGeoFeatureWithId,
    deserializeLngLat,
    extentIntersectsGeo,
    GeoFeatureCollection,
    serialize,
} from '@fieldmargin/webapp-geo';
import type { Feature, Polygon, Properties } from '@turf/helpers';
import { List, Map as ImmutableMap, Record } from 'immutable';
import { mapDelete, mapGetOr, mapSet, notNil } from 'lib/fp-helpers';
import { getFirstFeatureGeometryFromCollection } from 'lib/geo/geometry';
import { getSizesOfGeometry } from 'lib/geo/shape-size-cacher';
import {
    type MeasurementUnit,
    prefersImperialUnits,
    renderMeasurementUnitShort,
} from 'lib/MeasurementUnit';
import { defaultTo, isNil, omit, pick, unless, when } from 'ramda';
import type { Nullable } from 'system/types';
import { converter, sqmToHectares } from 'utils/conversion';
import { trackEvent } from 'utils/trackEvent';

class Field extends Record({
    uuid: '',
    name: '',
    fieldId: '',
    description: '',
    geoJson: null as GeoFeatureCollection | null,
    yearFieldUsages: new Map<string, string>(),
    yearWorkedArea: new Map<string, number>(),
    createdDate: new Date(),
    version: null as number | null,
    createdByUserId: -1,
    archived: false,

    // Sub field related properties. These will be set for sub fields and will be undefined
    // for parent fields.
    originalName: undefined as string | undefined,
    parentUuid: undefined as string | undefined,
    year: undefined as number | undefined,

    // This property is not stored in the server. It's calculated when fields are loaded.
    locked: false,
}) {}

// This interface is the FieldDTO received from the server
export interface FieldDTO {
    uuid: string;
    name: string;
    fieldId: Nullable<string>;
    description?: string;
    geoJsonPolygon: Nullable<Polygon>;
    yearFieldUsage: Nullable<globalThis.Record<string, string>>;
    yearWorkedArea: Nullable<globalThis.Record<string, string>>;
    createdByUserId: number;
    createdDate: number;
    version: number;
    archived: boolean;

    // Only sub fields will have these set
    parentFieldUuid: Nullable<string>;
    year: Nullable<number>;
}

/**
 * This is data that is used when updating a field.
 * It's used by the update hook and should not be sent to the server.
 */
export interface WriteFieldProps {
    name: string;
    fieldId: string;
    description?: string;
    geoJson: GeoFeatureCollection;
    yearFieldUsage?: {
        year: number;
        fieldUsageUuid?: string;
    };
    yearWorkedArea?: {
        year: number;
        workedArea: number;
    };
}

/**
 * This is the data that is sent to the server when updating a field.
 */
export interface WriteFieldDTO {
    name: string;
    fieldId?: string;
    description?: string;
    geoJsonPolygon: Polygon | null;
    setUsage?: { year: number; fieldUsageUUID: string };
    yearWorkedArea?: { year: number; areaSqm: number };
}

/**
 * This is data that is used when updating a sub field.
 * It's used by the update hook and should not be sent to the server.
 */
export type WriteSubFieldProps = {
    name: string;
    description?: string;
    geoJson: GeoFeatureCollection;
    year: number;
    fieldUsage?: { uuid?: string };
    workedArea?: number;
    parentUuid: string;
};
/**
 * This is the data that is sent to the server when updating a sub field.
 */
export interface WriteSubFieldDTO {
    name: string;
    description?: string;
    geoJsonPolygon: Polygon;
    year: number;
    parentFieldUuid: string;
    fieldUsageUuid?: string;
    workedArea?: number;
}

export const deserializeField = (json: FieldDTO) =>
    new Field({
        ...json,
        fieldId: json.fieldId ?? '',
        geoJson: unless(
            isNil,
            (geoJsonPolygon) =>
                GeoFeatureCollection({
                    features: List.of(
                        createGeoFeatureWithId(deserializeLngLat(geoJsonPolygon) as GeoPolygon)
                    ),
                }),
            json.geoJsonPolygon
        ),
        createdDate: new Date(json.createdDate),
        yearFieldUsages: new Map(Object.entries(defaultTo({}, json.yearFieldUsage))),
        yearWorkedArea: new Map(
            Object.entries(defaultTo({}, json.yearWorkedArea)).map(([k, v]) => [k, parseFloat(v)])
        ),
        parentUuid: json.parentFieldUuid ?? undefined,
        year: json.year ?? undefined,
    });

const omitWriteFieldPropsForDTO = omit(['geoJson', 'yearFieldUsage', 'yearWorkedArea']);

/**
 * Convert props for creating a field into the DTO that should be sent to the server.
 */
export const serializeWriteFieldProps = (
    props: Partial<WriteFieldProps>
): Partial<WriteFieldDTO> => ({
    ...omitWriteFieldPropsForDTO(props),
    geoJsonPolygon: when(
        notNil,
        (polygon: GeoPolygon) => serialize(polygon, { precision: 6 }),
        props.geoJson !== undefined
            ? getFirstFeatureGeometryFromCollection<GeoPolygon>(props.geoJson)
            : undefined
    ),
    setUsage:
        props.yearFieldUsage !== undefined
            ? {
                  year: props.yearFieldUsage.year,
                  fieldUsageUUID: defaultTo('', props.yearFieldUsage.fieldUsageUuid),
              }
            : undefined,
    yearWorkedArea:
        props.yearWorkedArea !== undefined
            ? {
                  year: props.yearWorkedArea.year,
                  areaSqm: props.yearWorkedArea.workedArea,
              }
            : undefined,
});

/**
 * Used after creating a new field to convert the response from the server into a Field.
 */
export const convertWriteFieldPropsToField = (
    uuid: string,
    { name, fieldId, description, geoJson, yearFieldUsage }: WriteFieldProps
) =>
    new Field({
        uuid,
        name,
        fieldId,
        description,
        geoJson,
        yearFieldUsages:
            yearFieldUsage?.fieldUsageUuid !== undefined
                ? new Map<string, string>([
                      [yearFieldUsage.year.toString(), yearFieldUsage.fieldUsageUuid],
                  ])
                : new Map<string, string>(),
    });

/**
 * Used after updating a field to merge the new data into the existing field.
 */
export const mergeFieldAndWriteProps = (field: Field, update: Partial<WriteFieldProps>) => {
    // Omit yearFieldUsage, yearWorkedArea and geoJson as these are handled separately.
    let updated = field.merge({ ...omit(['yearFieldUsage', 'yearWorkedArea', 'geoJson'], update) });
    if (update.yearFieldUsage !== undefined) {
        const { fieldUsageUuid, year } = update.yearFieldUsage;
        updated =
            fieldUsageUuid === undefined
                ? removeYearFieldUsage(updated, year)
                : setYearFieldUsage(updated, year, fieldUsageUuid);
    }
    if (update.yearWorkedArea !== undefined) {
        updated = updated.set(
            'yearWorkedArea',
            mapSet(
                updated.yearWorkedArea,
                update.yearWorkedArea.year.toString(),
                update.yearWorkedArea.workedArea
            )
        );
    }
    if (notNil(update.geoJson)) {
        updated = modifyFieldBoundaryFeature(
            updated,
            createGeoFeatureWithId(
                getFirstFeatureGeometryFromCollection<GeoPolygon>(
                    update.geoJson as GeoFeatureCollection
                ) as GeoPolygon
            )
        );
    }
    return updated;
};

/**
 * Convert props for creating a field into the DTO that should be sent to the server.
 */
export const serializeWriteSubFieldProps = (
    props: Partial<WriteSubFieldProps>
): Partial<WriteSubFieldDTO> => ({
    // No need to omit year here as we want to set this for sub fields.
    ...omit(['fieldUsage', 'parentUuid', 'geoJson'], props),
    parentFieldUuid: props.parentUuid,
    fieldUsageUuid:
        props.year !== undefined && props.workedArea === undefined
            ? defaultTo('', props.fieldUsage?.uuid)
            : undefined,
    geoJsonPolygon: when(
        notNil,
        (polygon: GeoPolygon) => serialize(polygon),
        props.geoJson !== undefined
            ? getFirstFeatureGeometryFromCollection<GeoPolygon>(props.geoJson)
            : undefined
    ),
});

/**
 * Used after updating a sub field to merge the new data into the existing field.
 */
export const mergeSubFieldAndWriteProps = (field: Field, update: Partial<WriteSubFieldProps>) => {
    const writeFieldProps: Partial<WriteFieldProps> = {
        ...pick(['name', 'description', 'geoJson'], update),
        yearFieldUsage:
            update.year !== undefined && update.fieldUsage !== undefined
                ? { year: update.year, fieldUsageUuid: update.fieldUsage.uuid }
                : undefined,
        yearWorkedArea:
            update.year !== undefined && update.workedArea !== undefined
                ? { year: update.year, workedArea: update.workedArea }
                : undefined,
    };
    return mergeFieldAndWriteProps(field, writeFieldProps);
};

export const getFieldName = (field: Field, parentField?: Field) =>
    parentField ? `${parentField.name} - ${field.name}` : field.name;

export const getFieldNameWithArea = (
    field: Field,
    year: number,
    measurementUnit: MeasurementUnit
) => {
    const area = sqmToHectares(getFieldWorkedArea(field, year));
    const convertedArea = prefersImperialUnits(measurementUnit)
        ? converter.convertHectaresToAcres(area)
        : area;
    const formatter = new Intl.NumberFormat(window.navigator.language, {
        maximumSignificantDigits: 4,
    });
    return area > 0
        ? `${field.name}\n${formatter.format(convertedArea)} ${renderMeasurementUnitShort(measurementUnit)}`
        : field.name;
};

export const getFieldArea = (field: Field) => {
    if (field.geoJson === null) {
        return 0;
    }
    const sizes = getSizesOfGeometry(field.uuid, field.geoJson);
    return sizes.area > 0 ? sizes.area : 0;
};

/**
 * Returns the field worked area for the given year if a value is set, otherwise
 * returns the area of the field.
 */
export const getFieldWorkedArea = (field: Field, year: number) =>
    mapGetOr(field.yearWorkedArea, year.toString(), getFieldArea(field));

export const getFieldGeoFeature = (field: Field) => {
    if (field.geoJson === null || field.geoJson.features.size === 0) {
        throw new Error('Attempting to get geoFeature for a field that has no boundary');
    }
    return field.geoJson.features.first<GeoFeature>();
};

export const getFieldGeoFeatureGeoJson = (field: Field): Feature<Polygon, Properties> =>
    serialize(getFieldGeoFeature(field));

/**
 * Modify the GeoFeature within the given fields boundary FeatureCollection.
 */
export const modifyFieldBoundaryFeature = (field: Field, feature: GeoFeature) =>
    field.geoJson !== null
        ? field.setIn(['geoJson', 'features', 0], feature)
        : field.set('geoJson', GeoFeatureCollection({ features: List.of(feature) }));

export const getYearFieldUsageUuidOrNone = (year: number, field: Field) =>
    defaultTo('none', getYearFieldUsageUuid(year, field));

export const getYearFieldUsageUuid = (year: number, field: Field) =>
    field.yearFieldUsages.get(year.toString());

export const setYearFieldUsage = (field: Field, year: number, fieldUsageUuid: string): Field =>
    field.set('yearFieldUsages', mapSet(field.yearFieldUsages, year.toString(), fieldUsageUuid));

export const removeYearFieldUsage = (field: Field, year: number) =>
    field.set('yearFieldUsages', mapDelete(field.yearFieldUsages, year.toString()));

export const fieldIntersectsExtent = (field: Field, extent: Extent) =>
    field.geoJson !== null && extentIntersectsGeo(extent, field.geoJson);

export const trackFieldChange = (msg: string) => (field: Field) => {
    trackEvent(`Field ${msg}`, {
        fieldUuid: field.uuid,
    });
    return field;
};

export const groupFieldsByUsage = (fields: List<Field>, year: number) =>
    ImmutableMap(
        fields.groupBy((field) => getYearFieldUsageUuidOrNone(year, field)).map((f) => f.toList())
    );

export const isSubField = (field: Field) => field.parentUuid !== undefined;

export default Field;
