import c from 'classnames';
import type Herd from 'herd/Herd';

interface HerdTypeColourBarProps {
    herd: Herd;
    className?: string;
}

const HerdTypeColourBar = ({ herd, className }: HerdTypeColourBarProps) => (
    <div className={c('w-2 mr-4', className)} style={{ backgroundColor: herd.colour }} />
);

export default HerdTypeColourBar;
