import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SingleParamVoidFunction } from 'system/types';

interface WeatherNoteFormFieldProps {
    field: string;
    onChange?: SingleParamVoidFunction<string>;
}

const WeatherNoteFormField = ({ field, onChange }: WeatherNoteFormFieldProps) => {
    const { t } = useTranslation();
    const { register } = useFormContext();
    return (
        <select
            {...register(field, {
                onChange: (e) => onChange && onChange(e.target.value),
            })}
            className="bordered p-2 rounded-sm h-10 focus"
        >
            <option value={undefined}></option>
            <option value="Sunny">☀️ {t('sunnry')}</option>
            <option value="Rainy">🌧 {t('rainy')}</option>
            <option value="Cloudy">⛅️ {t('cloudy')}</option>
            <option value="Snow">🌨 {t('snow')}</option>
            <option value="Icy">❄️ {t('icy')}</option>
        </select>
    );
};

export default WeatherNoteFormField;
