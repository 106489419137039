import { useAppSelector } from 'system/store';
import { selectUserAreaMeasurementUnit, selectUserLengthMeasurementUnit } from 'users/user-state';

const useMesurementUnitSelector = () => {
    const areaMeasurementUnit = useAppSelector(selectUserAreaMeasurementUnit);
    const lengthMeasurementUnit = useAppSelector(selectUserLengthMeasurementUnit);

    return { areaMeasurementUnit, lengthMeasurementUnit };
};

export { useMesurementUnitSelector };
