import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { connect } from 'react-redux';
import Button from '@fieldmargin/webapp-styling/components/button';
import { Box } from 'components/box';
import type Field from 'fields/Field';
import { selectShouldShowFieldAreaCount } from 'fields/fields-selectors';
import type { FieldsSortBy } from 'fields/fields-state';
import TotalFieldArea from 'fields/TotalFieldArea';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { useFieldListTracking } from 'hooks/useSegmentTracking';
import type { List } from 'immutable';
import { bindActionCreators } from 'redux';
import DemoMessage from 'sidebar/demo/DemoMessage';
import EmptyState from 'sidebar/modules/EmptyState';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarTitle from 'sidebar/modules/SidebarTitle';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import FarmLink from 'view/FarmLink';

import FieldAreaLimitMessage from './list/FieldAreaLimitMessage';
import FieldListAlphabetical from './list/FieldListAlphabetical';
import FieldListFieldsByUsage from './list/FieldListFieldsByUsage';
import FieldListVisibility from './FieldListVisibility';
import fieldsEmpty from './fields-empty.png';
import FieldSearchBar from './FieldSearchBar';
import FieldSortAndFilter from './FieldSortAndFilter';
import NewFieldMenu from './NewFieldMenu';

interface FieldListProps {
    allFields: List<Field>;

    sortBy: FieldsSortBy;

    changeModal: typeof changeModal;
    showFieldAreaLimitMsg: boolean;
}

const FieldList = ({ allFields, sortBy, changeModal, showFieldAreaLimitMsg }: FieldListProps) => {
    const { t } = useTranslation();
    const { currentFarm } = useCurrentFarm();

    useFieldListTracking();

    return (
        <div className="scrollable">
            <div className="non-scrolling relative">
                <SidebarTitle title={t('fields')}>
                    <NewFieldMenu farm={currentFarm} />
                </SidebarTitle>

                <SidebarModule editing className="flex justify-between items-center">
                    <FieldSearchBar />
                    <FieldListVisibility />
                </SidebarModule>

                {currentFarm.demo && <DemoMessage />}

                {showFieldAreaLimitMsg && <FieldAreaLimitMessage farm={currentFarm} />}
            </div>
            <div className="scrolling">
                {allFields.size > 0 ? (
                    <Fragment>
                        <Box>
                            <div>
                                <TotalFieldArea className="mb-2" />
                                <FarmLink
                                    to="/fields/edit"
                                    className="bare-btn focus uppercase text-fm-sky"
                                >
                                    {t('edit_fields')}
                                </FarmLink>
                            </div>
                            <FieldSortAndFilter />
                        </Box>
                    </Fragment>
                ) : (
                    <EmptyState header={t('field_list_empty_title_web')} image={fieldsEmpty}>
                        <Markdown>{t('field_list_empty_web')}</Markdown>
                        <Markdown>{t('field_list_empty_import_web')}</Markdown>
                        <ul>
                            <li>{t('import_from_computer')}</li>
                            <li>{t('john_deere_header')}</li>
                            <li>{t('import_natural_england')}</li>
                            <li>{t('rpa_header')}</li>
                        </ul>
                        <p>
                            <Button
                                onClick={() => {
                                    changeModal({
                                        name: 'connections',
                                        props: {
                                            initialSection: 'fields',
                                        },
                                    });
                                }}
                            >
                                {t('field_list_empty_start_importing_web')}
                            </Button>
                        </p>
                    </EmptyState>
                )}
                {sortBy === 'usage' && <FieldListFieldsByUsage />}
                {sortBy === 'alphabetically' && <FieldListAlphabetical />}
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        allFields: state.fieldsState.fields,
        sortBy: state.fieldsState.sortBy,
        showFieldAreaLimitMsg: selectShouldShowFieldAreaCount(state),
    }),
    (dispatch) => bindActionCreators({ changeModal }, dispatch)
)(FieldList);
