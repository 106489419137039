import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import icon from 'components/icons/icon';
import type { InputReportSummary } from 'inputs/InputReportSummary';
import UnsavedHookFormChangesChecker from 'unsaved-changes/UnsavedHookFormChangesChecker';
import ErrorMessage from 'view/ErrorMessage';

import InputSummaryTable from '../InputSummaryTable';

import { getDefaultEditCostFormValues } from './edit-costs-utils';

export interface EditInputCostsFormValues {
    // Input UUID -> values
    [k: string]: {
        unit: number;
        total: number;
    };
}

interface EditInputCostsFormProps {
    farm: Farm;
    includeAll: boolean;
    toggleIncludeAll: VoidFunction;
    reportData: InputReportSummary[];
    costs: Record<string, string>;
    onSubmit: (values: EditInputCostsFormValues) => Promise<any>;
    postSave: VoidFunction;
    returnTo: string;
}

const EditInputCostsForm = ({
    farm,
    includeAll,
    toggleIncludeAll,
    reportData,
    costs,
    onSubmit,
    postSave,
    returnTo,
}: EditInputCostsFormProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise(postSave);
    const methods = useForm<EditInputCostsFormValues>({
        mode: 'onChange',
        defaultValues: getDefaultEditCostFormValues(reportData, costs),
    });

    const handleSave = (values: EditInputCostsFormValues) => {
        setPromise(onSubmit(values));
    };
    return (
        <form className="relative" onSubmit={methods.handleSubmit(handleSave)}>
            <FormProvider {...methods}>
                <UnsavedHookFormChangesChecker
                    copy={[t('edit_costs_unsaved_warning'), t('confirm_continue')]}
                >
                    <div className="flex justify-between items-center">
                        <Button
                            as={Link}
                            to={returnTo}
                            variant="subtle"
                            className="flex items-center gap-2"
                        >
                            {icon('back')} {t('back')}
                        </Button>
                        <h1 className="uppercase text-center text-2xl">{t('label_edit_costs')}</h1>
                        <SubmitButton disabled={pending}>{t('save')}</SubmitButton>
                    </div>
                    {error && <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>}
                    {!methods.formState.isValid && (
                        <ErrorMessage className="text-right">
                            {t('edit_costs_validation')}
                        </ErrorMessage>
                    )}
                    <Checkbox
                        checked={includeAll}
                        onChange={toggleIncludeAll}
                        id="edit-costs-include-all"
                        label={t('edit_costs_unused_filter')}
                        className="mb-4"
                    />
                    <InputSummaryTable farm={farm} inputReportSummaries={reportData} editingCosts />
                </UnsavedHookFormChangesChecker>
            </FormProvider>
        </form>
    );
};

export default EditInputCostsForm;
