import type { Farm } from '@fieldmargin/webapp-farms';
import { useDateFormat } from 'hooks/useDateFormat';

interface DemoExpiryMessageProps {
    farm: Farm;
}

const DemoExpiryMessage = ({ farm }: DemoExpiryMessageProps) => {
    const { formatDistanceToNow } = useDateFormat();
    return (
        <span className="text-white border-solid border-2 border-fm-wheat rounded py-1 px-2">
            This is a demo farm
            {farm.demoExpiryDate &&
                `, it will be deleted in ${formatDistanceToNow(farm.demoExpiryDate)}`}
        </span>
    );
};

export default DemoExpiryMessage;
