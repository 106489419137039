import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import Button from '@fieldmargin/webapp-styling/components/button';
import type FieldUsage from 'fields/FieldUsage';
import { asBadge } from 'fields/FieldUsage';
import type { List } from 'immutable';
import { preventDefaultAnd } from 'lib/dom';
import BadgeRadioField from 'view/form/badge-radio/BadgeRadioField';

interface FieldUsageSelectionProps {
    // From parent
    fieldUsages: List<FieldUsage>;
    selectedFieldUsageUuid: string;
    setSelectedFieldUsage: (fieldUsageUuid: string) => void;
    toggleAddingUsage: VoidFunction;
}

const FieldUsageSelection = ({
    fieldUsages,
    selectedFieldUsageUuid,
    setSelectedFieldUsage,
    toggleAddingUsage,
}: FieldUsageSelectionProps) => {
    const { t } = useTranslation();
    return (
        <div className="FieldUsageSelection bordered-b p-5 bg-white">
            <h4 className="flex justify-between items-center">
                {t('field_usage_title')}
                <Button variant="outline" small onClick={toggleAddingUsage}>
                    {t('label_add_field_usage')}
                </Button>
            </h4>
            <BadgeRadioField
                items={fieldUsages.map(asBadge)}
                selected={selectedFieldUsageUuid}
                onChange={setSelectedFieldUsage}
            />
            {fieldUsages.size === 1 && (
                <>
                    <h3 className="mt-6">{t('title_empty_field_usages_list')}</h3>
                    <p className="mb-1">{t('message_empty_field_usages_list')}</p>
                    <Markdown
                        components={{
                            a: ({ children }) => (
                                <a
                                    href="#"
                                    className="text-fm-sky"
                                    onClick={preventDefaultAnd(toggleAddingUsage)}
                                >
                                    {children}
                                </a>
                            ),
                        }}
                    >
                        {t('message_empty_field_usages_list_create_new')}
                    </Markdown>
                </>
            )}
        </div>
    );
};

export default FieldUsageSelection;
