import { geojson } from '@fieldmargin/webapp-ol-map';
import type Feature from 'ol/Feature';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

class SensorsController extends geojson.FeaturesController {
    constructor(zIndex: number) {
        super(zIndex, {});
    }

    protected styleFeature(feature: Feature) {
        const props = feature.getProperties();
        const pointScale = props.active ? 10 : 4;
        let textStyle: Text | undefined = undefined;
        if (feature.get('name')) {
            textStyle = new Text({
                font: `bold 12px sans-serif`,
                fill: new Fill({ color: '#333' }),
                stroke: new Stroke({ color: '#fff', width: 3 }),
                text: feature.get('name'),
                overflow: true,
                offsetY: pointScale + 10,
            });
        }

        const image = new Circle({
            radius: pointScale,
            stroke: new Stroke({ color: 'white', width: 2 }),
            fill: new Fill({ color: props.active ? '#4da4da' : '#c1c1c1' }),
        });
        return new Style({
            image: image,
            text: textStyle,
        });
    }
}

export default SensorsController;
