import { useTranslation } from 'react-i18next';

import marginImg1 from './images/margin1.png';
import marginImg2 from './images/margin2.png';
import marginImg3 from './images/margin3.png';
import marginImg4 from './images/margin4.png';
import SubFieldsHelpDiagram from './SubFieldsHelpDiagram';

const SubFieldsHelpMargin = () => {
    const { t } = useTranslation();
    return (
        <div style={{ width: 700 }}>
            <h2 className="text-center text-sm">{t('sub_fields_help_margin_title')}</h2>
            <div className="flex text-center">
                <SubFieldsHelpDiagram src={marginImg1} alt="Image showing a selected sub-field">
                    {t('sub_fields_help_margin1')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram src={marginImg2} alt="Image showing the Margin tool selected">
                    {t('sub_fields_help_margin2')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram src={marginImg3} alt="Image showing a margin width set to 6m">
                    {t('sub_fields_help_margin3')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram
                    src={marginImg4}
                    alt="Image showing two sub-fields named 1 and 2"
                >
                    {t('sub_fields_help_margin4')}
                </SubFieldsHelpDiagram>
            </div>
        </div>
    );
};

export default SubFieldsHelpMargin;
