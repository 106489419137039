import type { List } from 'immutable';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type Vehicle from 'vehicle-tracking/Vehicle';

import VehicleItem from './VehicleItem';

interface VehicleListProps {
    vehicles: List<Vehicle>;
    active?: boolean;
    header: string;
}

const VehicleList = ({ vehicles, header, active = false }: VehicleListProps) => {
    return (
        <>
            <SidebarModule editing>
                <h4 className="mb-0">{header}</h4>
            </SidebarModule>
            <div key="active" className="active">
                {vehicles.map((vehicle) => (
                    <VehicleItem key={vehicle.id} vehicle={vehicle} active={active} />
                ))}
            </div>
        </>
    );
};

export default VehicleList;
