import { useEffect } from 'react';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { useOnMount } from '@fieldmargin/webapp-state';
import { useClearEditingGeoFeatureCollection } from 'hooks/useClearEditingGeoFeatureCollection';
import { useAppSelector } from 'system/store';

/**
 * A hook that clears the editingGeoFeatureCollection on first render so that
 * the component will operate on a fresh version of it.
 * After the first render this hook will call the given function on changes to the given
 * inject value or editingGeoFeatureCollection.
 */
export const useFreshEditingGeoFeatureCollection = <T>(
    fn: (egfc: GeoFeatureCollection | null, value?: T) => void,
    inject?: T
) => {
    const { clearEditingGeoFeatureCollection } = useClearEditingGeoFeatureCollection();
    const isFirstRender = useOnMount();
    const editingGeoFeatureCollection = useAppSelector(
        (state) => state.farmEditingState.editingGeoFeatureCollection
    );

    useEffect(() => {
        if (isFirstRender && editingGeoFeatureCollection !== null) {
            clearEditingGeoFeatureCollection();
        }

        if (!isFirstRender) {
            fn(editingGeoFeatureCollection, inject);
        }
    }, [inject, editingGeoFeatureCollection]);
};
