import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface AgreenaTokenSchema {
    aud: 'agreena-web-client';
    email: string;
    exp: number;
    farmId: number;
    farmName: string;
    firstName: string;
    iat: number;
    iss: string;
    lastName: number;
    locale: string;
    redirectUrl: string;
}

interface InitState {
    userInfo: AgreenaTokenSchema | null;
}

const initialState: InitState = {
    userInfo: null,
};

const agreenaTokenSlice = createSlice({
    name: 'agreena',
    initialState,
    reducers: {
        updateAgreenaToken: (state, action: PayloadAction<unknown>) => {
            state.userInfo = action.payload as AgreenaTokenSchema;
        },
    },
});

export const agreenaReducer = agreenaTokenSlice.reducer;
export const { updateAgreenaToken } = agreenaTokenSlice.actions;
