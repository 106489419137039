import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { analytics } from '@notes-web/analytics';

function TrackPage() {
    const location = useLocation();

    useEffect(() => {
        analytics.page();
    }, [location.pathname]);
    return <Outlet />;
}

export { TrackPage };
