import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromise } from '@fieldmargin/webapp-state';
import { getCurrencySymbol } from '@fieldmargin/webapp-styling/components/pricing/pricing-utils';
import { getInputTypeI18nKey } from 'inputs/InputType';
import { capitaliseWord, compareName } from 'lib/util/text';
import { sort } from 'ramda';
import ExportReportButton from 'reporting/ExportReportButton';
import { getCsvDownloadLink, getYearGrossMarginByField } from 'reporting/reporting-api';
import ReportStatusFilter from 'reporting/ReportStatusFilter';
import YearFieldFilter from 'reporting/YearFieldFilter';
import ContactSupportLink from 'view/ContactSupportLink';
import ErrorMessage from 'view/ErrorMessage';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';
import Num from 'view/Num';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import type { GrossMargin } from './GrossMargin';
import GrossMarginFieldGraph from './GrossMarginFieldGraph';
import { useGrossMarginContext } from './useGrossMarginContext';

const GrossMarginByFieldReport = () => {
    const { t } = useTranslation();
    const { farm, measurementUnit, year } = useGrossMarginContext();
    const [selectedFieldUuids, setSelectedFieldUuids] = useState<string[]>([]);
    const [status, setStatus] = useState('all');
    const [reportData, setReportData] = useState<GrossMargin[]>([]);
    const { pending, error, setPromise } = usePromise<GrossMargin[]>((data) =>
        setReportData(sort(compareName, data))
    );

    useEffect(() => {
        setReportData([]);
        setPromise(
            getYearGrossMarginByField(farm.uuid, year, measurementUnit, status, selectedFieldUuids)
        );
    }, [farm, selectedFieldUuids, status, measurementUnit]);

    return (
        <>
            <div className="flex items-end mb-6">
                <YearFieldFilter selected={selectedFieldUuids} onChange={setSelectedFieldUuids} />
                <ReportStatusFilter filter={status} setFilter={setStatus} className="ml-4" />
                <ExportReportButton
                    className="ml-auto"
                    filename="gross-margin-by-field"
                    downloadLink={getCsvDownloadLink(
                        farm.uuid,
                        year,
                        status,
                        'grossMarginByField',
                        measurementUnit
                    )}
                />
            </div>
            {pending && (
                <p>
                    <em>{t('reporting_loading')}</em>
                </p>
            )}
            {error && (
                <ErrorMessage>
                    <MarkdownNoPara components={{ a: ContactSupportLink }}>
                        {t('something_went_wrong_or_contact')}
                    </MarkdownNoPara>
                </ErrorMessage>
            )}
            {reportData.map((datum, i) => (
                <div className="mb-6" key={i}>
                    <h2 className="text-base mb-2">{datum.name}</h2>
                    <div className="flex justify-between">
                        <table className="ReportingTable w-5/12 mr-6">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>{t('total')}</th>
                                    <th>
                                        {capitaliseWord(t('per'))} <ShortAreaUnit /> (
                                        {getCurrencySymbol(farm.costingsCurrency || 'GBP')})
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={3}>
                                        <strong>{t('outputs_title')}</strong>
                                    </td>
                                </tr>
                                {datum.outputs?.map((output) => (
                                    <tr key={output.type}>
                                        <td>{output.type}</td>
                                        <td>
                                            <Num value={output.totalCost} />
                                        </td>
                                        <td>
                                            <Num value={output.cost} />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={3}>
                                        <strong>{t('inputs_title')}</strong>
                                    </td>
                                </tr>
                                {datum.inputs?.map((input) => (
                                    <tr key={input.type}>
                                        <td>{t(getInputTypeI18nKey(input.type))}</td>
                                        <td>
                                            <Num value={input.totalCost} />
                                        </td>
                                        <td>
                                            <Num value={input.cost} />
                                        </td>
                                    </tr>
                                ))}
                                {datum.margin && (
                                    <tr>
                                        <td>
                                            <strong>{t('gross_margin_title')}</strong>
                                        </td>
                                        <td>
                                            <strong>
                                                <Num value={datum.margin.totalCost} />
                                            </strong>
                                        </td>
                                        <td>
                                            <strong>
                                                <Num value={datum.margin.cost} />
                                            </strong>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <GrossMarginFieldGraph
                            farm={farm}
                            measurementUnit={measurementUnit}
                            datum={datum}
                        />
                    </div>
                </div>
            ))}
        </>
    );
};

export default GrossMarginByFieldReport;
