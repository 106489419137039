import type { DayForecast, HourForecast } from 'weather/Weather';

import weatherIcon from '../icons/weatherIcon';

interface WeatherRainProps {
    forecast: DayForecast | HourForecast;
}

const WeatherRain = ({ forecast }: WeatherRainProps) => {
    return (
        <div>
            <div>{weatherIcon(forecast.icon, '', 'mx-auto')}</div>
            {forecast.precipitationAmount > 0 && <div>{forecast.precipitationAmount} mm</div>}
        </div>
    );
};

export default WeatherRain;
