import { authorizedRequest } from '@fieldmargin/webapp-auth';

import MapGroup, { serializeMapGroup } from './MapGroup';
import type { FieldMapDTO } from './MapLayer';
import type MapLayer from './MapLayer';
import { deserializeFieldMap } from './MapLayer';

export const getFieldMapsApi = (farmUuid: string): Promise<MapGroup[]> =>
    authorizedRequest<FieldMapDTO[]>({
        method: 'get',
        url: `/map-api/farm-maps/${farmUuid}`,
    }).then((maps) => maps.map(deserializeFieldMap).map(mapGroupFromMapLayer));

export const updateFarmMapApi = (farmUuid: string, mapGroup: MapGroup) =>
    authorizedRequest<FieldMapDTO>({
        method: 'post',
        url: `/map-api/farm-maps/${farmUuid}/map/${mapGroup.uuid}`,
        data: serializeMapGroup(mapGroup),
    }).then((map) => mapGroupFromMapLayer(deserializeFieldMap(map)));

export const deleteFarmMapApi = (farmUuid: string, mapGroup: MapGroup) => {
    return authorizedRequest({
        method: 'delete',
        url: `/map-api/farm-maps/${farmUuid}/map/${mapGroup.uuid}`,
    }).then(() => mapGroup);
};

const mapGroupFromMapLayer = (mapLayer: MapLayer) =>
    MapGroup({
        uuid: mapLayer.uuid,
        name: mapLayer.name,
        timestamp: mapLayer.timestamp,
        maps: [mapLayer],
        farmIntegrationUuid: mapLayer.farmIntegrationUuid,
    });
