import { Extent, extentFromArray, Projection } from '@fieldmargin/webapp-geo';
import { List, Record } from 'immutable';

interface WmsTilesSpec {
    id: string | null;
    url: string;
    extent: Extent;
    params: { [s: string]: any };
}

const WmsTiles = Record<WmsTilesSpec>({
    id: null,
    url: '',
    extent: Extent(),
    params: {} as { [s: string]: any },
});
interface WmsTiles extends ReturnType<typeof WmsTiles> {}

export interface WmsTilesData {
    url: string;
    extent: number[];
    layers?: string[];
    layer?: string;
}

export const deserializeWmsTiles = (json: WmsTilesData, timestamp: Date) =>
    WmsTiles({
        url: json.url,
        extent: extentFromArray(Projection.WEB_MERCATOR, json.extent),
        params: {
            layers: json.layers ? List(json.layers) : json.layer ? List([json.layer]) : List(),
            time: timestamp.toISOString(),
            version: '1.3.0',
            format: 'image/png8',
            transparent: true,
        },
    });

export default WmsTiles;
