import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import { OperationField } from 'components/operation-field';
import type FullOperation from 'operations/FullOperation';
import type { FullOperationField } from 'operations/OperationField';
import FormattedArea from 'view/units/FormattedArea';

import MarkCompleteCheckbox from '../MarkCompleteCheckbox';
import MarkCompleteModal from '../MarkCompleteModal';

import OperationFieldCompletedInfo from './OperationFieldCompletedInfo';

interface OperationFieldListItemProps {
    farm: Farm;
    fullOperation: FullOperation;
    operationField: FullOperationField;
    onEditFields: VoidFunction;
}

const OperationFieldListItem = ({
    farm,
    fullOperation,
    operationField,
    onEditFields,
}: OperationFieldListItemProps) => {
    const [showCompleteModal, toggleShowCompleteModal] = useToggle(false);

    return (
        <OperationField.Item
            completed={operationField.completedDate !== undefined}
            fieldColor={operationField.fieldUsage?.colour}
        >
            {showCompleteModal && (
                <MarkCompleteModal
                    farm={farm}
                    fullOperation={fullOperation}
                    operationField={operationField}
                    onClose={toggleShowCompleteModal}
                    onEditFields={onEditFields}
                />
            )}

            <div className="flex-grow py-2">
                <div className="flex items-center">
                    <MarkCompleteCheckbox
                        farm={farm}
                        fullOperation={fullOperation}
                        operationField={operationField}
                        onChecked={toggleShowCompleteModal}
                        className="-mt-0.5 h-5"
                    />
                    <strong className="truncate">{operationField.field.name}</strong>
                    <span className="ml-auto">
                        <FormattedArea areaSqm={operationField.areaSqm} withUnit />
                    </span>
                </div>
                <OperationFieldCompletedInfo operationField={operationField} />
            </div>
        </OperationField.Item>
    );
};

export default OperationFieldListItem;
