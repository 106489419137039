import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import { selectCurrentFarm } from 'farms/farms-state';
import type { List } from 'immutable';
import { fetchVehicleLocationsApi } from 'integrations/integrations-data-api';
import { selectHasVehiclesConnected } from 'integrations/integrations-state';
import { allTrue, anyTrue } from 'lib/fp-helpers';
import { isNil } from 'ramda';
import { bindActionCreators } from 'redux';
import DemoMessage from 'sidebar/demo/DemoMessage';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarTitle from 'sidebar/modules/SidebarTitle';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import type Vehicle from 'vehicle-tracking/Vehicle';
import { setVehicles } from 'vehicle-tracking/vehicle-tracking-state';
import ErrorMessage from 'view/ErrorMessage';

import VehicleList from './VehicleList';
import VehiclesEmptyState from './VehiclesEmptyState';

interface VehicleTrackingProps {
    farm: Farm;
    hasVehiclesConnected: boolean;
    activeVehicles: List<Vehicle> | null;
    inactiveVehicles: List<Vehicle> | null;
    setVehicles: typeof setVehicles;
    changeModal: typeof changeModal;
}

const VehicleTracking = ({
    farm,
    hasVehiclesConnected,
    activeVehicles,
    inactiveVehicles,
    setVehicles,
    changeModal,
}: VehicleTrackingProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise<List<Vehicle>>(setVehicles);

    useEffect(() => {
        if (allTrue([hasVehiclesConnected, !activeVehicles, !inactiveVehicles, !pending])) {
            setPromise(fetchVehicleLocationsApi(farm.uuid));
        }
    }, [farm, hasVehiclesConnected, activeVehicles, inactiveVehicles, pending]);

    const handleManageClick = () => {
        changeModal({
            name: 'connections',
            props: {
                initialSection: 'vehicles',
            },
        });
    };

    const handleRefreshClick = () => {
        setPromise(fetchVehicleLocationsApi(farm.uuid));
    };

    const isLoading = anyTrue([
        allTrue([hasVehiclesConnected, isNil(activeVehicles), isNil(inactiveVehicles)]),
        pending,
    ]);

    const activeVehiclesSize = activeVehicles?.size ?? 0;
    const inactiveVehiclesSize = inactiveVehicles?.size ?? 0;

    const hasNoVehicles = anyTrue([
        !hasVehiclesConnected,
        allTrue([activeVehiclesSize === 0, inactiveVehiclesSize === 0]),
    ]);

    return (
        <div className="scrolling">
            <div className="non-scrolling">
                <SidebarTitle title="Vehicles">
                    <Button onClick={handleManageClick} className="mr-3">
                        {t('pro_upgrade_manage')}
                    </Button>
                    {hasVehiclesConnected && (
                        <Button onClick={handleRefreshClick} disabled={pending}>
                            {t('label_refresh')}
                        </Button>
                    )}
                </SidebarTitle>
                {farm.demo && <DemoMessage />}
            </div>
            <div className="scrollable">
                {hasNoVehicles && !pending && (
                    <VehiclesEmptyState onManageClick={handleManageClick} />
                )}
                {!hasNoVehicles && activeVehicles !== null && (
                    <VehicleList vehicles={activeVehicles} header={t('vehicles_active')} active />
                )}
                {!hasNoVehicles && inactiveVehicles !== null && (
                    <VehicleList vehicles={inactiveVehicles} header={t('vehicles_inactive')} />
                )}
                {isLoading && (
                    <SidebarModule editing>
                        <em>{t('vehicles_loading')}</em>
                    </SidebarModule>
                )}
                {error && (
                    <SidebarModule editing>
                        <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>
                    </SidebarModule>
                )}
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        activeVehicles: state.vehicleTrackingState.activeVehicles,
        inactiveVehicles: state.vehicleTrackingState.inactiveVehicles,
        hasVehiclesConnected: selectHasVehiclesConnected(state),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                changeModal,
                setVehicles,
            },
            dispatch
        )
)(VehicleTracking);
