import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import c from 'classnames';
import { ArchivedBarButton } from 'view/ArchivedBar';

interface FieldListArchivedProps extends PropsWithChildren {}

const FieldListArchived = ({ children }: FieldListArchivedProps) => {
    const { t } = useTranslation();
    const [open, toggleOpen] = useToggle(true);
    return (
        <>
            <ArchivedBarButton className="flex justify-between items-center" onClick={toggleOpen}>
                {t('archived')}{' '}
                <i className={c('text-xs', !open ? 'ion-chevron-right' : 'ion-chevron-down')} />
            </ArchivedBarButton>
            {open && children}
        </>
    );
};

export default FieldListArchived;
