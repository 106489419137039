import { useTranslation } from 'react-i18next';
import c from 'classnames';
import Notice from 'view/Notice';

interface DrawingBlockedMessageProps {
    className?: string;
}

const DrawingBlockedMessage = ({ className }: DrawingBlockedMessageProps) => {
    const { t } = useTranslation();
    return <Notice className={c('p-3', className)}>{t('digifarm_zoom_level')}</Notice>;
};

export default DrawingBlockedMessage;
