import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import { usePageParams } from 'hooks/usePageParamas';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import TabNav from 'view/tab-nav/TabNav';
import TabNavItem from 'view/tab-nav/TabNavItem';

import type { GrossMarginContextSchema } from './useGrossMarginContext';

interface GrossMarginReportContainerProps {
    farm: Farm;
    year: number;
    measurementUnit: MeasurementUnit;
}

const GrossMarginReportContainer = ({
    farm,
    year,
    measurementUnit,
}: GrossMarginReportContainerProps) => {
    const { t } = useTranslation();
    const { farmUuid } = usePageParams();
    const { pathname } = useLocation();
    const isActive = (path: string): boolean => {
        return pathname.split('/').slice(-1)[0] === path;
    };

    return (
        <div className="relative">
            <Button
                as={Link}
                to={`/farms/${farmUuid}/reporting`}
                className="absolute top-0 left-0 flex items-center gap-2"
                variant="subtle"
            >
                {icon('back')} {t('back')}
            </Button>
            <h1 className="uppercase text-center text-2xl">{t('gross_margin_report_title')}</h1>
            <TabNav className="uppercase font-bold px-4">
                <TabNavItem
                    active={isActive('gross-margin')}
                    activeClass="border-fm-sky"
                    className="mr-8 px-2"
                >
                    <Link to={`/farms/${farmUuid}/reporting/gross-margin`} className="no-underline">
                        {t('gross_margin_report_by_field')}
                    </Link>
                </TabNavItem>
                <TabNavItem active={isActive('usage')} activeClass="border-fm-sky" className="px-2">
                    <Link
                        to={`/farms/${farmUuid}/reporting/gross-margin/usage`}
                        className="no-underline"
                    >
                        {t('gross_margin_report_by_usage')}
                    </Link>
                </TabNavItem>
            </TabNav>

            <Outlet
                context={
                    {
                        farm,
                        year,
                        measurementUnit,
                    } satisfies GrossMarginContextSchema
                }
            />
        </div>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    year: state.yearsState.currentYear,
    measurementUnit: selectUserAreaMeasurementUnit(state),
}))(GrossMarginReportContainer);
