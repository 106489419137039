import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type Field from 'fields/Field';
import { selectYearFields } from 'fields/fields-selectors';
import type { List } from 'immutable';
import { compareName } from 'lib/util/text';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import ApplyCheckboxFilter from 'view/filters/ApplyCheckboxFilter';

interface YieldFieldFilterProps {
    // From parent
    selected: string[];
    onChange: SingleParamVoidFunction<string[]>;
    // From redux
    fields: List<Field>;
}

const YieldFieldFilter = ({ selected, onChange, fields }: YieldFieldFilterProps) => {
    const { t } = useTranslation();
    return (
        <ApplyCheckboxFilter
            header={t('field_filter')}
            items={fields.map((f) => ({ id: f.uuid, title: f.name })).toArray()}
            selectedItemIds={selected}
            onApply={onChange}
            emptyMsg={t('field_selector_no_selected')}
        />
    );
};

export default connect((state: AppState) => ({
    fields: selectYearFields(state).sort(compareName),
}))(YieldFieldFilter);
