import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { configureStore as reduxConfigureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { apiSlice } from 'api/base.api';
import { envConfig } from 'config/env-config';
import { createLogger } from 'redux-logger';
import rootReducer from 'system/reducer';

/**
 * This is the full store used by the full notes-web application.
 * It should not be included in the lite version of the app as it will mean
 * large dependencies are included which will inflate the size of the bundle.
 */

// Create the middleware instance and methods
const listenerMiddleware = createListenerMiddleware();

export const store = reduxConfigureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        const customMiddleware: any[] = [];

        if (envConfig.isDevelopment) {
            customMiddleware.push(
                createLogger({
                    collapsed: () => true, // Collapse all events by default
                })
            );
        }

        const middleware = getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        })
            .prepend(listenerMiddleware.middleware)
            .concat(customMiddleware)
            .concat(apiSlice.middleware);

        return middleware;
    },
    devTools: {
        serialize: {
            options: { map: true },
        },
    },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
