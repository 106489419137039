import { InputUnit } from 'inputs/Input';
import { compareString } from 'lib/util/text';
import { pipe, sort, uniq } from 'ramda';

import type { NutrientReport, UnitValues } from './NutrientReport';

export const getNutrientUnitsMap = (
    selectedNutrients: string[],
    nutrientReports: NutrientReport[]
): Record<string, InputUnit[]> => {
    const nutrientUnitValues = nutrientReports
        .flatMap((report) => report.fieldNutrient)
        .flatMap((fieldNutrient) => fieldNutrient.operationNutrient)
        .flatMap((operationNutrient) => operationNutrient.rates);

    return selectedNutrients.reduce(
        (map, nutrient) => {
            const units = nutrientUnitValues
                .flatMap<UnitValues>((unitValues) => unitValues[nutrient])
                .reduce((units, unitValue) => {
                    return unitValue !== undefined
                        ? units.concat(Object.keys(unitValue).map((o) => InputUnit[o]))
                        : units;
                }, [] as InputUnit[]);
            map[nutrient] = uniq(units);
            return map;
        },
        {} as Record<string, InputUnit[]>
    );
};

export const sortNutrientReportFieldsByName = (reports: NutrientReport[]): NutrientReport[] =>
    reports.map((report) => ({
        ...report,
        fieldNutrient: report.fieldNutrient.sort((a, b) => compareString(a.fieldName, b.fieldName)),
    }));

export const sortNutrientReportByFieldUsage = sort<NutrientReport>((a, b) =>
    compareString(a.fieldUsage, b.fieldUsage)
);

export const sortNutrientReports = pipe<[NutrientReport[]], NutrientReport[], NutrientReport[]>(
    sortNutrientReportFieldsByName,
    sortNutrientReportByFieldUsage
);
