import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePrevious, useToggle } from '@fieldmargin/webapp-state';
import type { Discussion } from 'discussions/Discussion';
import {
    selectIsSelectingFeatures,
    selectIsSelectingFields,
    selectIsSelectingHerds,
    stopEditing,
} from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import FeatureSelector from 'features/selector/FeatureSelector';
import HerdSelector from 'herd/sidebar/selector/HerdSelector';
import { useCurrentNote } from 'hooks/selectors';
import type Media from 'media/Media';
import type Note from 'notes/Note';
import { useMarkNoteAsRead } from 'notes/note-hooks';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import { selectUserId } from 'users/user-state';
import Fog from 'view/Fog';

import NoteFieldsSelection from '../fields/NoteFieldsSelection';

import EditableNoteDates from './EditableNoteDates';
import EditableNoteHerds from './EditableNoteHerds';
import EditableNoteLocation from './EditableNoteLocation';
import EditableNoteTitle from './EditableNoteTitle';
import EditableNoteUsers from './EditableNoteUsers';
import EditableNoteYear from './EditableNoteYear';
import NoteActivityLogCommentBox from './NoteActivityLogCommentBox';
import NoteDetailsHeader from './NoteDetailsHeader';
import NoteDiscussionList from './NoteDiscussionList';

interface NoteDetailsProps {
    note: Note;
    farm: Farm;
    isAttachingFieldsToNote: boolean;
    isAttachingFeaturesToNote: boolean;
    isAttachingHerdsToNote: boolean;
    stopEditing: VoidFunction;
    noteMedia?: Media[];
    noteDiscussions?: Discussion[];
}

const NoteDetailsBase = ({
    note,
    farm,
    isAttachingFieldsToNote,
    isAttachingFeaturesToNote,
    isAttachingHerdsToNote,
    stopEditing,
    noteMedia,
    noteDiscussions,
}: NoteDetailsProps) => {
    const [headerMenuOpen, toggleHeaderMenuOpen] = useToggle(false);
    const scrollingPane = useRef<HTMLDivElement | null>(null);
    const previousMedia = usePrevious(noteMedia);
    const previousDiscussions = usePrevious(noteDiscussions);
    const markNoteRead = useMarkNoteAsRead(note.uuid, note.year);

    // Mark note as read when viewed and when going to another view.
    useEffect(() => {
        markNoteRead();
        return () => {
            markNoteRead();
            stopEditing();
        };
    }, []);

    // When new discussions or media are added, scroll to the bottom of the page.
    useEffect(() => {
        const mediaChanged =
            noteMedia && previousMedia && previousMedia.length !== noteMedia.length;
        const commentsChanged =
            noteDiscussions &&
            previousDiscussions &&
            previousDiscussions.length !== noteDiscussions.length;
        if (scrollingPane.current && (mediaChanged || commentsChanged)) {
            scrollingPane.current.scrollTop = scrollingPane.current.scrollHeight;
        }
    }, [note, noteMedia]);

    return (
        <>
            {isAttachingFieldsToNote && <NoteFieldsSelection editing note={note} />}
            {isAttachingFeaturesToNote && <FeatureSelector />}
            {isAttachingHerdsToNote && <HerdSelector />}
            <div className="scrollable">
                {headerMenuOpen && <Fog blue onClick={toggleHeaderMenuOpen} className="top-14" />}
                <div className="non-scrolling">
                    <NoteDetailsHeader
                        farm={farm}
                        note={note}
                        menuOpen={headerMenuOpen}
                        onToggleHeaderMenu={toggleHeaderMenuOpen}
                    />
                </div>
                <div className="scrolling" ref={scrollingPane}>
                    <EditableNoteTitle note={note} />
                    <EditableNoteLocation note={note} />
                    <EditableNoteHerds note={note} />
                    <EditableNoteDates note={note} />
                    <EditableNoteUsers note={note} />
                    <EditableNoteYear note={note} />
                    <NoteDiscussionList note={note} />
                </div>
                <div className="non-scrolling">
                    <NoteActivityLogCommentBox note={note} />
                </div>
            </div>
        </>
    );
};

const NoteDetailsConnect = connect(
    (state: AppState, { note }: { note: Note }) => ({
        note,
        farm: selectCurrentFarm(state) as Farm,
        farmUsers: state.farmUsersState.farmUsers,
        myUserId: selectUserId(state),

        isAttachingFieldsToNote: selectIsSelectingFields(state),
        isAttachingFeaturesToNote: selectIsSelectingFeatures(state),
        isAttachingHerdsToNote: selectIsSelectingHerds(state),
        noteMedia: state.mediaState.get(note.uuid),
        noteDiscussions: state.discussionsState.get(note.uuid),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                changeModal,
                stopEditing,
            },
            dispatch
        )
)(NoteDetailsBase);

function NoteDetails() {
    const { currentNote } = useCurrentNote();
    return currentNote && <NoteDetailsConnect note={currentNote} />;
}

export { NoteDetails };
