import { connect } from 'react-redux';
import { AttachmentType } from 'farm-editing/attachments';
import {
    selectEditingAttachmentsByType,
    setEditingAttachmentsByType,
    toggleEditingAttachmentByType,
} from 'farm-editing/farm-editing-state';
import type { FieldsSortBy } from 'fields/fields-state';
import type { Set } from 'immutable';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';

import FieldSelectorFieldsAlphabetical from './FieldSelectorFieldsAlphabetical';
import FieldSelectorFieldsByUsage from './FieldSelectorFieldsByUsage';

interface FieldSelectorProps {
    sortBy: FieldsSortBy;
    editingFieldUuids: Set<string>;
    toggleEditingFieldUuid: SingleParamVoidFunction<string>;
    setEditingFieldUuids: SingleParamVoidFunction<Set<string>>;
}

const FieldSelector = ({
    sortBy,
    editingFieldUuids,
    toggleEditingFieldUuid,
    setEditingFieldUuids,
}: FieldSelectorProps) => {
    const toggleFieldSelected = (uuid: string) => {
        toggleEditingFieldUuid(uuid);
    };
    return (
        <>
            {sortBy === 'usage' && (
                <FieldSelectorFieldsByUsage
                    editingFieldUuids={editingFieldUuids}
                    toggleFieldSelected={toggleFieldSelected}
                    setEditingFieldUuids={setEditingFieldUuids}
                />
            )}
            {sortBy === 'alphabetically' && (
                <FieldSelectorFieldsAlphabetical
                    editingFieldUuids={editingFieldUuids}
                    toggleFieldSelected={toggleFieldSelected}
                />
            )}
        </>
    );
};

export default connect(
    (state: AppState) => ({
        sortBy: state.fieldsState.sortBy,
        editingFieldUuids: selectEditingAttachmentsByType(state, AttachmentType.FIELD),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setEditingFieldUuids: setEditingAttachmentsByType(AttachmentType.FIELD),
                toggleEditingFieldUuid: toggleEditingAttachmentByType(AttachmentType.FIELD),
            },
            dispatch
        )
)(FieldSelector);
