import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import { Box } from 'components/box';
import icon from 'components/icons/icon';
import { SearchBar } from 'components/search-bar';
import { selectCurrentFarm } from 'farms/farms-state';
import type Feature from 'features/Feature';
import type { FeatureTypeWithFeatures } from 'features/features-selectors';
import {
    selectMaybeVisibleFeaturesGroupedByFeatureType,
    selectMaybeVisibleFilteredFeatures,
} from 'features/features-selectors';
import { setFeatureSearchString, setFilterFeaturesByScreen } from 'features/features-state';
import type FeatureType from 'features/FeatureType';
import type { List } from 'immutable';
import { Set } from 'immutable';
import LocalStorageHelper from 'lib/storage/LocalStorageHelper';
import { bindActionCreators } from 'redux';
import DemoMessage from 'sidebar/demo/DemoMessage';
import FilterByMap from 'sidebar/FilterByMap';
import EmptyState from 'sidebar/modules/EmptyState';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarTitle from 'sidebar/modules/SidebarTitle';
import type { AppState } from 'system/store';

import FeatureListFeaturesByType from './FeatureListFeaturesByType';
import FeatureListFilterMessage from './FeatureListFilterMessage';
import FeatureListVisibility from './FeatureListVisibility';
import featuresEmpty from './features-empty.png';

interface FeatureListProps {
    farm: Farm;
    allFeatures: List<Feature>;
    filteredFeatures: List<Feature>;
    filteredFeaturesByType: List<FeatureTypeWithFeatures>;
    featureTypes: List<FeatureType>;
    featureSearchString: string;
    filterFeaturesByScreen: boolean;
    setFilterFeaturesByScreen: typeof setFilterFeaturesByScreen;
    setFeatureSearchString: typeof setFeatureSearchString;
}

const FeatureList = ({
    farm,
    allFeatures,
    filteredFeatures,
    filteredFeaturesByType,
    featureTypes,
    featureSearchString,
    filterFeaturesByScreen,
    setFeatureSearchString,
    setFilterFeaturesByScreen,
}: FeatureListProps) => {
    const { t } = useTranslation();
    const [closedFeatureTypes, setClosedFeatureTypes] = useState(
        Set(
            LocalStorageHelper.getItemAsJson<string[]>(`farm-${farm.uuid}-closedFeatureTypes`) || []
        )
    );

    const handleFeatureTypeClick = (featureTypeUuid: string) => {
        const nextClosedFeatureTypes = closedFeatureTypes.contains(featureTypeUuid)
            ? closedFeatureTypes.remove(featureTypeUuid)
            : closedFeatureTypes.add(featureTypeUuid);

        setClosedFeatureTypes(nextClosedFeatureTypes);
        LocalStorageHelper.setItemAsJson(
            `farm-${farm.uuid}-closedFeatureTypes`,
            nextClosedFeatureTypes.toArray()
        );
    };

    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarTitle title={t('features')}>
                    <Button
                        as={Link}
                        to={`/farms/${farm.uuid}/features/new`}
                        className="flex items-center"
                    >
                        {icon('add', 'white', 'mr-1')} {t('farm_new_feature')}
                    </Button>
                </SidebarTitle>
            </div>
            <SidebarModule editing className="flex justify-between items-center">
                <SearchBar searchFunction={setFeatureSearchString} value={featureSearchString} />
                <FeatureListVisibility />
            </SidebarModule>
            {farm.demo && <DemoMessage />}
            <div className="scrolling">
                {allFeatures.size > 0 ? (
                    <Fragment>
                        <Box>
                            <FilterByMap
                                checked={filterFeaturesByScreen}
                                onChange={() => setFilterFeaturesByScreen(!filterFeaturesByScreen)}
                                label={t('only_show_features_visible_on_map')}
                            />
                        </Box>
                        <FeatureListFilterMessage
                            filteredFeatures={filteredFeatures}
                            featureSearchString={featureSearchString}
                        />
                    </Fragment>
                ) : (
                    <EmptyState header={t('feature_list_empty_header')} image={featuresEmpty}>
                        <p>{t('feature_list_empty_title')}</p>
                        <Markdown>{t('feature_list_empty_hint')}</Markdown>
                    </EmptyState>
                )}
                <FeatureListFeaturesByType
                    featureTypes={featureTypes}
                    filteredFeaturesByType={filteredFeaturesByType}
                    closedFeatureTypes={closedFeatureTypes}
                    onFeatureTypeClick={handleFeatureTypeClick}
                />
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => {
        const { featuresState } = state;
        return {
            farm: selectCurrentFarm(state),
            allFeatures: featuresState.features,
            filteredFeatures: selectMaybeVisibleFilteredFeatures(state),
            filteredFeaturesByType: selectMaybeVisibleFeaturesGroupedByFeatureType(state),
            featureTypes: featuresState.featureTypes,
            featureSearchString: featuresState.featureSearchString,
            filterFeaturesByScreen: featuresState.filterFeaturesByScreen,
        };
    },
    (dispatch) =>
        bindActionCreators(
            {
                setFilterFeaturesByScreen,
                setFeatureSearchString,
            },
            dispatch
        )
)(FeatureList);
