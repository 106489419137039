import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarmHasMapPrinting } from 'farms/farms-state';
import { usePageParams } from 'hooks/usePageParamas';
import { printOut } from 'lib/print';
import { useAppSelector } from 'system/store';
import { trackEvent } from 'utils/trackEvent';
import FeatureLockedModal from 'view/modal/FeatureLockedModal';

const PrintButton = () => {
    const { t } = useTranslation();
    const hasPrinting = useAppSelector(selectCurrentFarmHasMapPrinting);
    const { farmUuid } = usePageParams();
    const [showUpgradeModal, toggleShowUpgradeModal] = useToggle(false);

    const handleClick = () => {
        trackEvent('Print clicked', { farmUuid, blocked: !hasPrinting });
        if (!hasPrinting) {
            !showUpgradeModal && toggleShowUpgradeModal();
        } else {
            printOut();
        }
    };
    return (
        <>
            {showUpgradeModal && (
                <FeatureLockedModal
                    onClose={toggleShowUpgradeModal}
                    header={t('map_printing')}
                    planList={['essentials', 'plus', 'pro']}
                />
            )}
            <Button className="flex items-center" onClick={handleClick}>
                {icon('print', 'white', 'mr-2')}
                {t('print')}
            </Button>
        </>
    );
};

export default PrintButton;
