import type { PropsWithChildren } from 'react';
import c from 'classnames';

const InfoMsg = ({ className, children }: PropsWithChildren<{ className?: string }>) => {
    return (
        <div
            className={c(
                'absolute z-40 flex items-center text-white text-base font-semibold',
                className
            )}
        >
            {children}
        </div>
    );
};

export default InfoMsg;
