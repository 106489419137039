import type { Farm } from '@fieldmargin/webapp-farms';
import { createAction, handleActions } from '@fieldmargin/webapp-state';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess, loadingFarm } from 'farms/farm-loading-state';
import type { List, Map, Set } from 'immutable';
import { Record } from 'immutable';
import type Comment from 'lib/model/Comment';
import type Media from 'media/Media';

import type FullOperation from './FullOperation';
import type Operation from './Operation';
import { getOperationRecordingsApi } from './operations-api';
import type Recording from './Recording';

export const setOperation = createAction<OperationsState, FullOperation>(
    'Set operation',
    (state, payload) => state.setIn(['operations', payload.uuid], payload)
);

export const updateOperations = createAction<OperationsState, Map<string, FullOperation>>(
    'Update operations',
    (state, payload) =>
        state.update('operations', (operations) =>
            operations ? operations.merge(payload) : payload
        )
);

export const removeOperation = createAction<OperationsState, FullOperation>(
    'Remove operation',
    (state, payload) =>
        state.updateIn(['operations'], (operations) =>
            operations.filter((o) => o.uuid !== payload.uuid)
        )
);

/**
 * Mark an operation as read
 */
export const setOperationRead = createAction<OperationsState, string>(
    'Set operation read',
    (state, payload) =>
        state.operations !== null
            ? state.setIn(['operations', payload, 'summary', 'read'], true)
            : state
);

/**
 * Add a comment to an operation
 */
export const setOperationComment = createAction<
    OperationsState,
    { operation: Operation; comment: Comment }
>('Set operation comment', (state, payload) =>
    state.updateIn(['operations', payload.operation.uuid, 'comments'], (comments) =>
        comments.push(payload.comment)
    )
);

/**
 * Set media for an operation
 */
export const setOperationMedia = createAction<
    OperationsState,
    { operation: Operation; newMedia: Media }
>('Set operation media', (state, payload) =>
    state.updateIn(['operations', payload.operation.uuid, 'media'], (media) =>
        media.push(payload.newMedia)
    )
);

/**
 * Remove a media item from an operation
 */
export const removeOperationMedia = createAction<
    OperationsState,
    { operation: Operation; mediaId: string }
>('Remove operation media', (state, payload) =>
    state.updateIn(['operations', payload.operation.uuid, 'media'], (media) =>
        media.filter((m) => m.id !== payload.mediaId)
    )
);

/**
 * Set recordings for an operation
 */
export const setOperationRecordings = createAction<
    OperationsState,
    { operation: Operation; newRecordings: Set<Recording> }
>('Set operation recordings', (state, payload) =>
    state.setIn(['operations', payload.operation.uuid, 'recordings'], payload.newRecordings)
);

/**
 * Set recordings for multiple operations
 */
export const setOperationsRecordings = createAction<
    OperationsState,
    { operation: Operation; recordings: Set<Recording> }[]
>('Set operations recordings', (state, payload) =>
    payload.reduce(
        (nextState, { operation, recordings }) =>
            nextState.setIn(['operations', operation.uuid, 'recordings'], recordings),
        state
    )
);

export const loadOperationsRecordings =
    (farm: Farm, year: number, fullOperations: List<FullOperation>) => async (dispatch) => {
        const recordingData = await Promise.all(
            fullOperations
                .filter((fullOperation) => fullOperation.recordings === null)
                .map(async (fullOperation) => {
                    const recordings = await getOperationRecordingsApi(
                        farm.uuid,
                        year,
                        fullOperation.uuid
                    );
                    return { operation: fullOperation.summary, recordings };
                })
        );
        dispatch(setOperationsRecordings(recordingData));
    };

const OperationsState = Record({
    operations: null as Map<string, FullOperation> | null,
});
export interface OperationsState extends ReturnType<typeof OperationsState> {}
export default OperationsState;

export const operationsReducer = handleActions<OperationsState>(
    OperationsState(),
    [
        setOperation,
        updateOperations,
        setOperationRead,
        removeOperation,
        setOperationComment,
        setOperationMedia,
        removeOperationMedia,
        setOperationRecordings,
        setOperationsRecordings,
    ],
    {
        [loadingFarm.toString()]: () => OperationsState(),
        [loadedFarmSuccess.toString()]: (state, payload: LoadedFarmSuccessPayload) =>
            state.set('operations', payload.operations),
    }
);
