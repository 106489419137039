import { Record } from 'immutable';
import { greaterThanZero, notNil } from 'lib/fp-helpers';
import { numberToStringOrEmpty } from 'lib/util/text';
import { always, cond, filter, isNil, T, unless } from 'ramda';

export const getNutrientTypesArray = () => [
    'nitrogen',
    'phosphorus',
    'potassium',
    'magnesium',
    'sulphur',
    'sodium',
];
export const getMicronutrientTypesArray = () => [
    'calcium',
    'boron',
    'manganese',
    'zinc',
    'iron',
    'selenium',
    'molybdenum',
    'copper',
];

export const getAllNutrientTypes = () => [
    ...getNutrientTypesArray(),
    ...getMicronutrientTypesArray(),
];

class Nutrients extends Record({
    nitrogen: null as number | null,
    phosphorus: null as number | null,
    potassium: null as number | null,
    magnesium: null as number | null,
    sulphur: null as number | null,
    sodium: null as number | null,
}) {}
export default Nutrients;

export class Micronutrients extends Record({
    calcium: null as number | null,
    boron: null as number | null,
    manganese: null as number | null,
    zinc: null as number | null,
    iron: null as number | null,
    selenium: null as number | null,
    molybdenum: null as number | null,
    copper: null as number | null,
}) {}

export type NutrientFormValues = {
    nitrogen?: number;
    phosphorus?: number;
    potassium?: number;
    magnesium?: number;
    sulphur?: number;
    sodium?: number;
};
export type MicronutrientFormValues = {
    calcium?: number;
    boron?: number;
    manganese?: number;
    zinc?: number;
    iron?: number;
    selenium?: number;
    molybdenum?: number;
    copper?: number;
};

export const nutrientsToFormValues = (nutrients: Nutrients): NutrientFormValues =>
    nutrients.toSeq().map(unless(isNil, numberToStringOrEmpty)).toObject() as NutrientFormValues;

export const micronutrientsToFormValues = (
    micronutrients: Micronutrients
): MicronutrientFormValues =>
    micronutrients
        .toSeq()
        .map(unless(isNil, numberToStringOrEmpty))
        .toObject() as MicronutrientFormValues;

export const nutrientFormValuesToNutrients = cond<[NutrientFormValues | undefined], Nutrients>([
    [notNil, (nutrients: NutrientFormValues) => new Nutrients(filter(greaterThanZero, nutrients))],
    [T, always(new Nutrients())],
]);

export const micronutrientFormValuesToMicronutrients = cond<
    [MicronutrientFormValues | undefined],
    Micronutrients
>([
    [
        notNil,
        (nutrients: MicronutrientFormValues) =>
            new Micronutrients(filter(greaterThanZero, nutrients)),
    ],
    [T, always(new Micronutrients())],
]);
