import { acresRateToHectaresRate, converter } from 'utils/conversion';

import type { MeasurementUnit } from './MeasurementUnit';
import { prefersImperialUnits } from './MeasurementUnit';

export const convertUserIdForStorage = (userId?: string) =>
    userId !== undefined ? parseInt(userId, 10) : undefined;

export const convertFloatForStorage = (value?: string) => {
    return value === undefined ? undefined : parseFloat(value);
};

export const convertRateForStorage = (
    value: number | undefined,
    measurementUnit: MeasurementUnit
) => {
    if (value === undefined) {
        return undefined;
    }

    return prefersImperialUnits(measurementUnit) ? acresRateToHectaresRate(value) : value;
};

export const convertWindSpeedForStorage = (
    windSpeed: number | undefined,
    measurementUnit: MeasurementUnit
) => {
    if (windSpeed === undefined || isNaN(windSpeed)) {
        return undefined;
    }

    return prefersImperialUnits(measurementUnit)
        ? converter.convertMilesPerHourToKilometresPerHour(windSpeed)
        : windSpeed;
};

export const convertTemperatureForStorage = (
    temperature: number | undefined,
    measurementUnit: string
) => {
    if (temperature === undefined || isNaN(temperature)) {
        return undefined;
    }

    return measurementUnit === 'FAHRENHEIT'
        ? converter.convertFahrenheitToCelsius(temperature)
        : temperature;
};
