import { useTranslation } from 'react-i18next';

import cutImg1 from './images/cut1.png';
import cutImg2 from './images/cut2.png';
import cutImg3 from './images/cut3.png';
import cutImg4 from './images/cut4.png';
import SubFieldsHelpDiagram from './SubFieldsHelpDiagram';

const SubFieldsHelpCut = () => {
    const { t } = useTranslation();
    return (
        <div style={{ width: 700 }}>
            <h2 className="text-center text-sm">{t('sub_fields_help_cut_title')}</h2>
            <div className="flex text-center">
                <SubFieldsHelpDiagram src={cutImg1} alt="Image showing a selected sub-field">
                    {t('sub_fields_help_cut1')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram
                    src={cutImg2}
                    alt="Image showing a sub field with a hole drawn on it"
                >
                    {t('sub_fields_help_cut2')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram
                    src={cutImg3}
                    alt="Image showing a two sub-fields named 1 and 2"
                >
                    {t('sub_fields_help_cut3')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram
                    src={cutImg4}
                    alt="Image showing a single sub-field with a hole in it"
                >
                    {t('sub_fields_help_cut4')}
                </SubFieldsHelpDiagram>
            </div>
        </div>
    );
};

export default SubFieldsHelpCut;
