import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import getSymbolFromCurrency from 'currency-symbol-map';
import Num from 'view/Num';
import ShortAreaUnit from 'view/units/ShortAreaUnit';
import ShortUnit from 'view/units/ShortUnit';

import type { InputReport } from './InputReport';

interface InputReportTableProps {
    farm: Farm;
    inputReport: InputReport;
}

const InputReportTable = ({ farm, inputReport }: InputReportTableProps) => {
    const { t } = useTranslation();
    return (
        <div className="mb-6">
            <h2 className="font-medium mb-2">
                {t('field_details_input')}: {inputReport.input}
                {inputReport.pesticideData !== undefined
                    ? ` ${inputReport.pesticideData.registrationNumber}`
                    : ''}
            </h2>
            <table className="ReportingTable">
                <thead>
                    <tr>
                        <th>{t('usage')}</th>
                        <th>{t('field')}</th>
                        <th className="w-1/6">{t('applications')}</th>
                        <th className="w-1/6">
                            {t('total_rate')}/
                            <ShortAreaUnit />
                        </th>
                        <th className="w-1/6">
                            {t('total')} (<ShortUnit unit={inputReport.unit} />)
                        </th>
                        <th className="w-1/6">
                            {t('cost')} ({getSymbolFromCurrency(farm.costingsCurrency || 'GBP')})
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {inputReport.usages.map((usage, i) => (
                        <Fragment key={i}>
                            <tr>
                                <th colSpan={2} className="bg-gray-200 font-medium">
                                    {usage.usageName}
                                </th>
                                <td className="bg-gray-200">-</td>
                                <td className="bg-gray-200">-</td>
                                <td className="bg-gray-200">
                                    <Num value={usage.total} />
                                </td>
                                <td className="bg-gray-200">
                                    <Num value={usage.cost} />
                                </td>
                            </tr>
                            {usage.fields.map((field, j) => (
                                <tr key={j}>
                                    <td>&nbsp;</td>
                                    <td>{field.fieldName}</td>
                                    <td>{field.application}</td>
                                    <td>
                                        <Num value={field.totalRate} />
                                    </td>
                                    <td>
                                        <Num value={field.total} />
                                    </td>
                                    <td>
                                        <Num value={field.cost} />
                                    </td>
                                </tr>
                            ))}
                        </Fragment>
                    ))}
                    <tr>
                        <td>
                            <strong>{t('total')}</strong>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <strong>
                                <Num value={inputReport.total} />
                            </strong>
                        </td>
                        <td>
                            <strong>
                                <Num value={inputReport.cost} />
                            </strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default InputReportTable;
