import { useEffect, useRef } from 'react';
import type { Extent, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { GeoPoint, GeoPosition } from '@fieldmargin/webapp-geo';
import type { FeaturesContollerData } from '@fieldmargin/webapp-ol-map';
import {
    Basemap,
    BasemapController,
    FeaturesController,
    OpenLayersMap,
} from '@fieldmargin/webapp-ol-map';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import type Field from 'fields/Field';
import { getFieldName, getYearFieldUsageUuid } from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import type { List } from 'immutable';
import { DEFAULT_COLOUR } from 'lib/colours';
import { uniqueId } from 'lodash';
import { getFieldsExtent } from 'rpa/rpa-utils';

interface SubFieldsUsePreviousMapProps {
    year: number;
    parentField: Field;
    fields: List<Field>;
    fieldUsages: List<FieldUsage>;
}

const SubFieldsUsePreviousMap = ({
    year,
    parentField,
    fields,
    fieldUsages,
}: SubFieldsUsePreviousMapProps) => {
    const mapRef = useRef<OpenLayersMap<{ basemap: string; fields: FeaturesContollerData }> | null>(
        null
    );
    const hostElementId = useRef(uniqueId('use-previous-map-')).current;

    useEffect(() => {
        const fieldFeatures = fields
            .filter((field) => field.geoJson !== null)
            .map((field) => {
                const fieldUsageUuid = getYearFieldUsageUuid(year, field);
                const fieldUsage = fieldUsages.find(
                    (fieldUsage) => fieldUsage.uuid === fieldUsageUuid
                );
                return (field.geoJson as GeoFeatureCollection).features.map((feature) =>
                    feature.set(
                        'properties',
                        OLFeatureProperties({
                            type: 'field',
                            colour: fieldUsage ? fieldUsage.colour : DEFAULT_COLOUR,
                            zIndex: 1,
                            label: getFieldName(field, parentField),
                            strokeWeight: 2,
                        })
                    )
                );
            })
            .flatMap((feature) => feature);

        if (!mapRef.current && fieldFeatures.size > 0) {
            mapRef.current = new OpenLayersMap(
                {
                    hostElementId,
                    viewport: {
                        zoom: 0,
                        maxZoom: 15,
                        centre: GeoPoint({ coordinates: GeoPosition({ x: 0, y: 0 }) }),
                    },
                    interactions: [],
                },
                {
                    basemap: new BasemapController([Basemap.OSM_ROAD], {}, 1, {
                        language: '',
                        country: '',
                    }),
                    fields: new FeaturesController(2, {}, { declutter: false }),
                },
                {}
            );

            mapRef.current.update(
                {
                    basemap: Basemap.NONE,
                    fields: { geoFeatures: fieldFeatures.toArray(), options: { declutter: false } },
                },
                {
                    id: 1,
                    position: {
                        extent: getFieldsExtent(fieldFeatures) as Extent,
                        maxZoom: 15,
                    },
                }
            );
        }
        if (fieldFeatures.size > 0) {
            mapRef.current?.update({
                basemap: Basemap.NONE,
                fields: { geoFeatures: fieldFeatures.toArray(), options: { declutter: false } },
            });
        }
    }, [fields]);

    return <div id={hostElementId} style={{ width: '100%', height: '100%' }} />;
};

export default SubFieldsUsePreviousMap;
