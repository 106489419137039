import type { ComponentProps, MouseEvent, PropsWithChildren } from 'react';
import { createContext, useContext, useRef } from 'react';
import type { PressEvent } from 'react-aria-components';
import { DialogTrigger, Popover } from 'react-aria-components';
import { Link } from 'react-router-dom';
import { ButtonBase } from 'components/button-base';
import { ButtonDownload } from 'components/button-download';
import { useBoolean } from 'hooks/useBoolean';

import styles from './DownloadList.module.css';

type DownloadListButtonProps = PropsWithChildren & ComponentProps<(typeof ButtonBase)['Root']>;
type DownloadListLinkProps = PropsWithChildren &
    ComponentProps<typeof Link> & { disabled: boolean };

interface DownloadListContextState {
    toggle: () => void;
    value: boolean;
}

// CONTEXT PROVIDER
const DownloadListContext = createContext({} as DownloadListContextState);

function DownloadListContextProvider({ children }: PropsWithChildren) {
    const { toggle, value } = useBoolean();

    return (
        <DownloadListContext.Provider value={{ toggle, value }}>
            {children}
        </DownloadListContext.Provider>
    );
}

// BUTTON
function DownloadListButton({ children, onPress, ...props }: DownloadListButtonProps) {
    const { toggle } = useContext(DownloadListContext);

    const handleClick = (e: PressEvent) => {
        onPress && onPress(e);
        toggle();
    };
    return (
        <div className={styles.popoverItemWrapper}>
            <ButtonBase.Root onPress={handleClick} className={styles.popoverItem} {...props}>
                {children}
            </ButtonBase.Root>
        </div>
    );
}

// LINK
function DownloadListLink({ children, onClick, disabled, ...props }: DownloadListLinkProps) {
    const { toggle } = useContext(DownloadListContext);

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        if (disabled) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            onClick && onClick(e);
        }
        toggle();
    };
    return (
        <div className={styles.popoverItemWrapper}>
            <Link
                className={`${styles.popoverItem} ${styles.link}`}
                onClick={handleClick}
                {...props}
            >
                {children}
            </Link>
        </div>
    );
}

// ROOT
function DownloadLisBase({ children }: PropsWithChildren) {
    const { toggle, value } = useContext(DownloadListContext);
    const triggerRef = useRef(null);

    return (
        <DialogTrigger>
            <ButtonDownload ref={triggerRef} onPress={toggle} />

            <Popover triggerRef={triggerRef} onOpenChange={toggle} isOpen={value}>
                <div className={styles.popover}>{children}</div>
            </Popover>
        </DialogTrigger>
    );
}

// ROOT WITH PROVIDER
function DownloadListRoot({ children }: PropsWithChildren) {
    return (
        <DownloadListContextProvider>
            <DownloadLisBase>{children}</DownloadLisBase>
        </DownloadListContextProvider>
    );
}

export { DownloadListRoot, DownloadListButton, DownloadListLink };
