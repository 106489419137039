import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm, FarmUser } from '@fieldmargin/webapp-farms';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { serialize } from '@fieldmargin/webapp-geo';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import icon from 'components/icons/icon';
import { AttachmentType } from 'farm-editing/attachments';
import {
    selectEditingAttachmentsByType,
    selectIsSelectingFeatures,
    selectIsSelectingFields,
    selectIsSelectingHerds,
    stopEditing,
} from 'farm-editing/farm-editing-state';
import { startNewNote } from 'farm-editing/start-editing-reducer';
import { selectCurrentFarm } from 'farms/farms-state';
import FeatureSelector from 'features/selector/FeatureSelector';
import HerdSelector from 'herd/sidebar/selector/HerdSelector';
import { Set } from 'immutable';
import { Form } from 'informed';
import { useCreateNote } from 'notes/note-hooks';
import NoteFieldsSelection from 'notes/sidebar/fields/NoteFieldsSelection';
import NoteNewLocation from 'notes/sidebar/new/NoteNewLocation';
import { bindActionCreators } from 'redux';
import NewDateAndTime from 'sidebar/modules/date/NewDateAndTime';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import NewTitle from 'sidebar/modules/title/NewTitle';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import { selectCurrentFarmUser, selectDisplayableFarmUsers } from 'team/farm-users-state';
import { useFinishTutorialOnMount } from 'tutorials/tutorial-hooks';
import { TutorialTypes } from 'tutorials/TutorialTypes';

import MarkAsTodoField from '../MarkAsTodoField';

import NoteNewFile from './file-upload/NoteNewFile';
import NoteNewComment from './NoteNewComment';
import NoteNewHerd from './NoteNewHerd';
import NoteNewTaggedUsers from './NoteNewTaggedUsers';

import styles from './NoteNew.module.css';

interface NoteNewProps {
    farm: Farm;
    currentFarmUser: FarmUser;
    farmUsers: FarmUser[];

    editingGeoFeatureCollection: GeoFeatureCollection | null;

    isAttachingFieldsToNote: boolean;
    noteFields: Set<string>;

    isAttachingFeaturesToNote: boolean;
    noteFeatures: Set<string>;

    isAttachingHerdsToNote: boolean;
    noteHerds: Set<string>;

    startNewNote: typeof startNewNote;
    stopEditing: typeof stopEditing;
}

interface FormValues {
    title: string;
    task: boolean;
    dueDate?: Date;
    description: string;
    files: File[];
    taggedUsers: Set<FarmUser>;
}

const NoteNew = ({
    farm,
    currentFarmUser,
    farmUsers,

    editingGeoFeatureCollection,

    isAttachingFieldsToNote,
    noteFields,

    isAttachingFeaturesToNote,
    noteFeatures,

    isAttachingHerdsToNote,
    noteHerds,

    startNewNote,
    stopEditing,
}: NoteNewProps) => {
    const { t } = useTranslation();
    const [pending, error, createNote] = useCreateNote();

    useFinishTutorialOnMount(TutorialTypes.SCOUTING);

    useEffect(() => {
        startNewNote();
        return () => {
            stopEditing();
        };
    }, []);

    const handleSave = async (values: FormValues) => {
        if (!editingGeoFeatureCollection) {
            return;
        }
        createNote(
            {
                name: values.title,
                geoJson: serialize(editingGeoFeatureCollection),
                fieldUuids: noteFields.toArray(),
                featureUuids: noteFeatures.toArray(),
                herdUuids: noteHerds.toArray(),
                userIds: values.taggedUsers.map((farmUser) => farmUser.id).toArray(),
            },
            values.task ? { dueDate: values.dueDate } : undefined,
            values.description,
            values.files
        );
    };

    const initialValues = {
        title: '',
        task: false,
        dueDate: new Date(),
        description: '',
        files: [],
        taggedUsers: Set.of(currentFarmUser),
    };

    return (
        <div className={styles.noteNewContainer}>
            {isAttachingFieldsToNote && <NoteFieldsSelection />}
            {isAttachingFeaturesToNote && <FeatureSelector />}
            {isAttachingHerdsToNote && <HerdSelector />}
            <Form
                initialValues={initialValues}
                onSubmit={handleSave}
                className={styles.noteNewForm}
            >
                {({ formState }) => (
                    <Fragment>
                        <div className={styles.headerWrapper}>
                            <SidebarHeader>
                                <SidebarHeaderLink to={`/farms/${farm.uuid}/notes`} returning>
                                    {icon('back', 'dark-blue', 'mr-2')} {t('back')}
                                </SidebarHeaderLink>
                            </SidebarHeader>
                            {error && (
                                <SidebarError
                                    title={t('failed_to_save')}
                                    message={t('something_went_wrong')}
                                />
                            )}
                        </div>
                        <div className={styles.formBody}>
                            <NewTitle field="title" label={t('title')} />
                            <MarkAsTodoField field="task" />
                            {formState.values.task && (
                                <NewDateAndTime
                                    field="dueDate"
                                    label={t('create_task_due_date_title_set')}
                                    required={false}
                                    openDirection="bottom"
                                />
                            )}
                            <NoteNewComment field="description" />
                            <NoteNewFile field="files" />
                            <NoteNewTaggedUsers farmUsers={farmUsers} />
                            <NoteNewLocation />
                            <NoteNewHerd />
                        </div>
                        <div className={styles.footerButtons}>
                            <SubmitButton className="flex-grow mr-4" disabled={pending}>
                                {t('save')}
                            </SubmitButton>

                            <Button
                                as={Link}
                                to={`/farms/${farm.uuid}/notes`}
                                variant="danger-outline"
                                className="flex-grow"
                            >
                                {t('cancel')}
                            </Button>
                        </div>
                    </Fragment>
                )}
            </Form>
        </div>
    );
};

export default connect(
    (state: AppState) => {
        return {
            farm: selectCurrentFarm(state),
            currentFarmUser: selectCurrentFarmUser(state),
            farmUsers: selectDisplayableFarmUsers(state),

            editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,

            isAttachingFieldsToNote: selectIsSelectingFields(state),
            noteFields: selectEditingAttachmentsByType(state, AttachmentType.FIELD),

            isAttachingFeaturesToNote: selectIsSelectingFeatures(state),
            noteFeatures: selectEditingAttachmentsByType(state, AttachmentType.FEATURE),

            isAttachingHerdsToNote: selectIsSelectingHerds(state),
            noteHerds: selectEditingAttachmentsByType(state, AttachmentType.HERD),
        };
    },
    (dispatch) =>
        bindActionCreators(
            {
                startNewNote,
                stopEditing,
            },
            dispatch
        )
)(NoteNew);
