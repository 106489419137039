import { createIconGenerator } from 'lib/util/icon-generator';

import addIcon from './add.svg?raw';
import archiveIcon from './archive.svg?raw';
import arrowRightIcon from './arrow-right.svg?raw';
import backIcon from './back.svg?raw';
import cancelIcon from './cancel.svg?raw';
import closeIcon from './close.svg?raw';
import dateIcon from './date.svg?raw';
import editIcon from './edit.svg?raw';
import editRemoveIcon from './edit-remove.svg?raw';
import errorAlertIcon from './error-alert.svg?raw';
import eyeIcon from './eye.svg?raw';
import eyeOffIcon from './eye-off.svg?raw';
import farmerIcon from './farmer.svg?raw';
import fieldJobIcon from './field-job.svg?raw';
import graphIcon from './graph.svg?raw';
import imageIcon from './image.svg?raw';
import infoIcon from './info.svg?raw';
import linkIcon from './link.svg?raw';
import mapIcon from './map.svg?raw';
import minusIcon from './minus.svg?raw';
import moreIcon from './more.svg?raw';
import newEditIcon from './new-edit.svg?raw';
import noteIcon from './note.svg?raw';
import padlockIcon from './padlock.svg?raw';
import printIcon from './print.svg?raw';
import readingArrowIcon from './reading-arrow.svg?raw';
import removeIcon from './remove.svg?raw';
import searchIcon from './search.svg?raw';
import sendIcon from './send.svg?raw';
import tableIcon from './table.svg?raw';
import taskIcon from './task.svg?raw';
import tickIcon from './tick.svg?raw';
import tickGreyIcon from './tick-grey.svg?raw';
import timeIcon from './time.svg?raw';
import usersIcon from './users.svg?raw';
import wheatIcon from './wheat.svg?raw';

import './icons.scss';

export const iconSrc = {
    add: addIcon,
    archive: archiveIcon,
    'arrow-right': arrowRightIcon,
    back: backIcon,
    cancel: cancelIcon,
    close: closeIcon,
    date: dateIcon,
    edit: editIcon,
    'new-edit': newEditIcon,
    'edit-remove': editRemoveIcon,
    'error-alert': errorAlertIcon,
    eye: eyeIcon,
    'eye-off': eyeOffIcon,
    farmer: farmerIcon,
    'field-job': fieldJobIcon,
    graph: graphIcon,
    image: imageIcon,
    info: infoIcon,
    link: linkIcon,
    map: mapIcon,
    minus: minusIcon,
    more: moreIcon,
    note: noteIcon,
    padlock: padlockIcon,
    print: printIcon,
    'reading-arrow': readingArrowIcon,
    remove: removeIcon,
    search: searchIcon,
    send: sendIcon,
    table: tableIcon,
    task: taskIcon,
    tick: tickIcon,
    'tick-grey': tickGreyIcon,
    time: timeIcon,
    users: usersIcon,
    wheat: wheatIcon,
};

export default createIconGenerator(iconSrc, 'icon');
