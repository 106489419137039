import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useBoolean } from 'hooks/useBoolean';
import { isEmpty } from 'ramda';

interface ShowFiltersContextState {
    open: boolean;
    toggle: () => void;
}

const ShowFiltersContext = createContext({} as ShowFiltersContextState);

function ShowFiltersContextProvider({ children }: PropsWithChildren) {
    const { value, toggle } = useBoolean();

    const _value = useMemo(
        () => ({
            open: value,
            toggle,
        }),
        [value, toggle]
    );

    return <ShowFiltersContext.Provider value={_value}>{children}</ShowFiltersContext.Provider>;
}

const useShowFiltersContext = () => {
    const context = useContext(ShowFiltersContext);

    if (context === undefined || isEmpty(context)) {
        throw new Error('useShowFiltersContext must be used within a ShowFiltersContextProvider');
    }

    return context;
};

export { ShowFiltersContextProvider, useShowFiltersContext };
