import type { MouseEvent } from 'react';
import icon from 'components/icons/icon';
import { selectHerdsSortedByName } from 'herd/herd-selectors';
import type { Set } from 'immutable';
import { useAppSelector } from 'system/store';
import BubbleTag from 'view/molecules/BubbleTag';

interface NoteHerdBubblesProps {
    noteHerds: Set<string>;
    onRemove: (herdUuid: string) => void;
}

const NoteHerdBubbles = ({ noteHerds, onRemove }: NoteHerdBubblesProps) => {
    const herds = useAppSelector(selectHerdsSortedByName);

    const handleRemove = (herdUuid: string, e?: MouseEvent) => {
        e && e.preventDefault();
        onRemove(herdUuid);
    };

    if (!herds || noteHerds.size === 0) {
        return null;
    }

    return (
        <>
            {herds
                .filter((herd) => noteHerds.contains(herd.uuid))
                .map((herd) => (
                    <BubbleTag key={herd.uuid} className="flex items-center mr-3 mb-2">
                        <span>{herd.name}</span>
                        <button
                            className="bare-btn ml-2"
                            onClick={(e) => handleRemove(herd.uuid, e)}
                        >
                            {icon('edit-remove', 'red')}
                        </button>
                    </BubbleTag>
                ))}
        </>
    );
};

export default NoteHerdBubbles;
