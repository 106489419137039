import { useTranslation } from 'react-i18next';
import { ButtonBase } from 'components/button-base';
import { useShowFiltersContext } from 'context/ShowFiltersContextProvider';
import { FilterIcon } from 'icons/FilterIcon';
import { selectTotalFiltersCount } from 'store/slices/notes.slice';
import { useAppSelector } from 'system/store';

import styles from './toggle-filters.module.css';

function TotalAppliedFilersNotification() {
    const totalAppliedFilters = useAppSelector(selectTotalFiltersCount);

    return (
        totalAppliedFilters > 0 && (
            <div className={styles.filterCount} aria-hidden="true">
                {totalAppliedFilters}
            </div>
        )
    );
}

function ToggleFilters() {
    const { t } = useTranslation();

    const { toggle } = useShowFiltersContext();

    return (
        <ButtonBase.Root className={styles.filterButton} onPress={toggle}>
            <ButtonBase.Icon>
                <FilterIcon />
            </ButtonBase.Icon>
            <span>{t('filters')}</span>

            <TotalAppliedFilersNotification />
        </ButtonBase.Root>
    );
}

export { ToggleFilters };
