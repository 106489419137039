interface PointIconProps {
    className?: string;
    fill?: string;
}

const PointIcon = ({ className, fill = '#4da4da' }: PointIconProps) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <g>
                <path style={{ fill: 'none' }} d="M27.7,31.5c0.2,0.3,0.5,0.6,0.8,0.8v-0.8H27.7z" />
                <path style={{ fill: 'none' }} d="M31.5,32.3c0.3-0.2,0.6-0.5,0.8-0.8h-0.8V32.3z" />
                <path
                    style={{ fill: 'none' }}
                    d="M31.5,27.7v0.8h0.8C32.1,28.1,31.9,27.9,31.5,27.7z"
                />
                <path style={{ fill: 'none' }} d="M28.5,27.7c-0.3,0.2-0.6,0.5-0.8,0.8h0.8V27.7z" />
                <path
                    style={{ fill }}
                    d="M24.5,28.5h-22C1.7,28.5,1,29.2,1,30s0.7,1.5,1.5,1.5h22c-0.1-0.5-0.2-1-0.2-1.5C24.3,29.5,24.4,29,24.5,28.5z"
                />
                <path
                    style={{ fill }}
                    d="M31.5,24.5v-22C31.5,1.7,30.8,1,30,1s-1.5,0.7-1.5,1.5v22c0.5-0.1,1-0.2,1.5-0.2S31,24.4,31.5,24.5z"
                />
                <path
                    style={{ fill }}
                    d="M57.5,28.5h-22c0.1,0.5,0.2,1,0.2,1.5c0,0.5-0.1,1-0.2,1.5h22c0.8,0,1.5-0.7,1.5-1.5S58.3,28.5,57.5,28.5z"
                />
                <path
                    style={{ fill }}
                    d="M28.5,35.5v22c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-22c-0.5,0.1-1,0.2-1.5,0.2S29,35.6,28.5,35.5z"
                />
                <path
                    style={{ fill }}
                    d="M32.3,28.5h3.1c-0.5-1.9-2-3.4-3.9-3.9v3.1C31.9,27.9,32.1,28.1,32.3,28.5z"
                />
                <path
                    style={{ fill }}
                    d="M31.5,32.3v3.1c1.9-0.5,3.4-2,3.9-3.9h-3.1C32.1,31.9,31.9,32.1,31.5,32.3z"
                />
                <path
                    style={{ fill }}
                    d="M27.7,31.5h-3.1c0.5,1.9,2,3.4,3.9,3.9v-3.1C28.1,32.1,27.9,31.9,27.7,31.5z"
                />
                <path
                    style={{ fill }}
                    d="M28.5,27.7v-3.1c-1.9,0.5-3.4,2-3.9,3.9h3.1C27.9,28.1,28.1,27.9,28.5,27.7z"
                />
                <path
                    style={{ fill }}
                    d="M30,27.2c0.6,0,1.1,0.2,1.5,0.5v-3.1c-0.5-0.1-1-0.2-1.5-0.2s-1,0.1-1.5,0.2v3.1C28.9,27.4,29.4,27.2,30,27.2z"
                />
                <path
                    style={{ fill }}
                    d="M35.5,28.5h-3.1c0.3,0.4,0.5,1,0.5,1.5c0,0.6-0.2,1.1-0.5,1.5h3.1c0.1-0.5,0.2-1,0.2-1.5C35.7,29.5,35.6,29,35.5,28.5z"
                />
                <path
                    style={{ fill }}
                    d="M27.2,30c0-0.6,0.2-1.1,0.5-1.5h-3.1c-0.1,0.5-0.2,1-0.2,1.5c0,0.5,0.1,1,0.2,1.5h3.1C27.4,31.1,27.2,30.6,27.2,30z"
                />
                <path
                    style={{ fill }}
                    d="M30,32.8c-0.6,0-1.1-0.2-1.5-0.5v3.1c0.5,0.1,1,0.2,1.5,0.2s1-0.1,1.5-0.2v-3.1C31.1,32.6,30.6,32.8,30,32.8z"
                />
            </g>
        </svg>
    );
};

export default PointIcon;
