import type { ReactNode } from 'react';
import classNames from 'classnames';

import type { ColourPickerControllerProps } from './ColourPickerController';
import ColourPickerController from './ColourPickerController';

interface ColourPickerFieldProps extends ColourPickerControllerProps {
    label?: ReactNode;
    className?: string;
}

const ColourPickerField = ({
    label,
    className,
    field,
    validate,
    colours,
}: ColourPickerFieldProps) => (
    <div>
        <div className={classNames('form-field', className)}>
            {label}
            <ColourPickerController field={field} validate={validate} colours={colours} />
        </div>
    </div>
);

export default ColourPickerField;
