import type { ComponentProps, PropsWithChildren } from 'react';
import { Fragment } from 'react';
import { Radio as _Radio, RadioGroup } from 'react-aria-components';

import styles from './Radio.module.css';

type GroupProps = ComponentProps<typeof RadioGroup> & PropsWithChildren;

type InputProps = ComponentProps<typeof _Radio> & PropsWithChildren;

function Group({ ...props }: GroupProps) {
    return <RadioGroup className={styles.group} {...props} />;
}

function Input({ children, ...props }: InputProps) {
    return (
        <_Radio className={styles.radio} {...props}>
            {({ isSelected, isFocusVisible }) => (
                <Fragment>
                    <div
                        className={styles.circleWapper}
                        data-focus-visible={isFocusVisible}
                        data-selected={isSelected}
                    />

                    {children}
                </Fragment>
            )}
        </_Radio>
    );
}

export const Radio = {
    Group,
    Input,
};
