interface TeamIconProps {
    className?: string;
    fill?: string;
}

const TeamIcon = ({ className, fill = '#4da4da' }: TeamIconProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <g id="Group_194" transform="translate(0 6.999)">
                <path
                    id="Path_112"
                    style={{ fill }}
                    d="M22.2,27.4L22.2,27.4c1.6,0,3-1.4,3-3c0-1.6-1.4-3-3-3s-2.7,1.1-3,2.7c0,0.8,0.3,1.6,0.8,2.2
		C20.6,27.1,21.4,27.4,22.2,27.4L22.2,27.4z"
                />
                <path
                    id="Path_113"
                    style={{ fill }}
                    d="M37.8,21.6c-1.6,0-3,1.1-3,2.7l0,0c0,0.8,0.3,1.6,0.8,2.2c0.5,0.5,1.4,0.8,1.9,0.8l0,0
		c1.6,0,3-1.1,3-2.7S39.4,21.9,37.8,21.6L37.8,21.6z"
                />
                <path
                    id="Path_114"
                    style={{ fill }}
                    d="M58.2,15.9c-0.5-0.5-1.1-0.8-1.9-0.8h-6.5l-1.1-7.4c0-4.6-3.8-8.2-8.4-8.2H19.5
		c-4.6,0-8.2,3.5-8.4,8.2L10,15.4H3.5c-1.4,0-2.5,1.1-2.5,2.5c0,0.8,0.3,1.4,0.8,1.9c0.5,0.3,1.1,0.5,1.6,0.8h7.6
		c-0.8,1.9-1.1,3.8-1.1,6c0,7.6,4.4,14.7,11.2,18c2.7,1.4,5.4,1.9,8.4,1.9s6-0.5,9-1.9c2.2-1.1,3.8-2.2,5.4-4.1
		c3.8-3.8,5.7-8.7,5.7-14.2c0-2.2-0.3-4.1-0.8-6h7.6c1.4,0,2.5-1.1,2.5-2.5C58.7,17.3,58.7,16.7,58.2,15.9z M45.1,26.3
		c0,8.2-6.5,15-14.7,15h-0.5c-8.2-0.3-14.7-6.8-14.7-15c0-2.2,0.5-4.1,1.4-6h27C44.6,22.2,45.1,24.4,45.1,26.3L45.1,26.3z M43.8,8.3
		l1.1,7.1h-30L16,8.6V8.3c0-1.9,1.6-3.5,3.5-3.5l0,0h20.7C42.1,4.8,43.8,6.1,43.8,8.3L43.8,8.3z"
                />
                <path
                    id="Path_115"
                    style={{ fill }}
                    d="M33.9,31.7c-2.2,2.2-5.7,2.2-7.9,0c-1.1-1.1-2.7-1.1-3.8,0c-1.1,1.1-1.1,2.7,0,3.8
		c1.9,2.2,4.9,3.3,7.6,3.3c3,0,5.7-1.1,7.6-3.3c1.1-1.1,1.1-2.7,0-3.8C36.4,30.6,35,30.9,33.9,31.7z"
                />
            </g>
        </svg>
    );
};

export default TeamIcon;
