import { useState } from 'react';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import c from 'classnames';
import { getFarmUserName } from 'lib/util/names';
import { useAppSelector } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { selectDisplayableFarmUsers } from 'team/farm-users-state';

import Combobox from './combobox/Combobox';
import ComboboxItem from './combobox/ComboboxItem';

interface FarmUserComboboxProps {
    id: string;
    search: string;
    onSearch: SingleParamVoidFunction<string>;
    className?: string;
    onSelected: SingleParamVoidFunction<FarmUser>;
}

const FarmUserCombobox = ({
    id,
    search,
    onSearch,
    className,
    onSelected,
}: FarmUserComboboxProps) => {
    const [showAll, setShowAll] = useState(false);
    const farmUsers = useAppSelector(selectDisplayableFarmUsers);

    const handleSearch = (value: string) => {
        setShowAll(false);
        onSearch(value);
    };

    const handleUserSelected = (farmUser: FarmUser) => () => {
        onSearch(getFarmUserName(farmUser));
        onSelected(farmUser);
    };

    const displayableUsers =
        !showAll && search !== ''
            ? farmUsers.filter(
                  (farmUser) =>
                      getFarmUserName(farmUser).toLowerCase().indexOf(search.toLowerCase()) >= 0
              )
            : farmUsers;

    return (
        <Combobox
            id={id}
            search={search}
            onSearch={handleSearch}
            className={c(className)}
            onFocus={() => setShowAll(true)}
        >
            {displayableUsers.map((farmUser) => (
                <ComboboxItem
                    key={farmUser.id}
                    item={getFarmUserName(farmUser)}
                    onSelect={handleUserSelected(farmUser)}
                />
            ))}
        </Combobox>
    );
};

export default FarmUserCombobox;
