import { Fragment } from 'react/jsx-runtime';
import { SidebarCard } from 'components/sidebar-card';
import { t } from 'i18next';

import OperationFieldsView from './fields/OperationFieldsView';
import { EditableOperationRecordings } from './recording/EditableOperationRecordings';
import OperationFieldsViewWithYield from './yields/OperationFieldsViewWithYield';
import EditableOperationDates from './EditableOperationDates';
import EditableOperationUsers from './EditableOperationUsers';
import EditableOperationYear from './EditableOperationYear';
import { EditableOperationFuel } from './fuel';
import { useEditableOperationDetailsContext } from './useEditableOperationDetailsContext';

function RenderOperation() {
    const { fullOperation, isHarvesting, operationFields, totalFieldsArea, handleSaveOperation } =
        useEditableOperationDetailsContext();

    return (
        <Fragment>
            {isHarvesting ? (
                <OperationFieldsViewWithYield
                    fullOperation={fullOperation}
                    operationFields={operationFields}
                    totalFieldArea={totalFieldsArea}
                />
            ) : (
                <Fragment>
                    <OperationFieldsView
                        fullOperation={fullOperation}
                        operationFields={operationFields}
                        totalFieldArea={totalFieldsArea}
                    />
                    <EditableOperationRecordings />

                    <EditableOperationFuel />
                </Fragment>
            )}

            <SidebarCard.Root>
                <SidebarCard.Heading as="h4">{t('other')}</SidebarCard.Heading>
                <EditableOperationUsers fullOperation={fullOperation} />
                <EditableOperationDates
                    fullOperation={fullOperation}
                    onSave={handleSaveOperation}
                />
                <EditableOperationYear fullOperation={fullOperation} onSave={handleSaveOperation} />
            </SidebarCard.Root>
        </Fragment>
    );
}

export { RenderOperation };
