import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import EditableActions from 'sidebar/modules/common/EditableActions';
import CheckboxFormField from 'view/form/hook/CheckboxFormField';

interface OperationTaggedUsersFormProps {
    farmUsers: FarmUser[];
    taggedUsers: FarmUser[];
    onSave: (newTaggedUsers: number[]) => Promise<void>;
    toggleEditing: VoidFunction;
}

interface FormValues {
    taggedUsers: { farmUser: FarmUser; checked: boolean }[];
}

const OperationTaggedUsersForm = ({
    farmUsers,
    taggedUsers,
    onSave,
    toggleEditing,
}: OperationTaggedUsersFormProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise();
    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            taggedUsers: farmUsers.map((farmUser) => ({
                farmUser,
                checked: taggedUsers.some((taggedUser) => taggedUser.id === farmUser.id),
            })),
        },
    });
    const { fields } = useFieldArray({ control: methods.control, name: 'taggedUsers' });

    const handleSubmit = (values: FormValues) => {
        setPromise(
            onSave(
                values.taggedUsers
                    .filter((taggedUser) => taggedUser.checked)
                    .map((taggedUser) => taggedUser.farmUser.id)
            )
        );
    };
    return (
        <FormProvider {...methods}>
            <form
                className="py-4 bordered-b relative"
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <h4 className="mb-2">{t('activity_log_tagged')}</h4>
                {fields.map((item, i) => (
                    <CheckboxFormField
                        key={item.farmUser.id}
                        field={`taggedUsers.${i}`}
                        label={`${item.farmUser.firstName} ${item.farmUser.lastName}`}
                    />
                ))}
                <EditableActions disabled={pending} error={error} setEditing={toggleEditing} />
            </form>
        </FormProvider>
    );
};

export default OperationTaggedUsersForm;
