import { createAction, handleActions } from '@fieldmargin/webapp-state';
import { Map, Record } from 'immutable';
import type { Dispatch } from 'redux';
import type { AppState } from 'system/store';
import { selectUserId } from 'users/user-state';
import { trackEvent } from 'utils/trackEvent';

import { saveTutorialProgressApi } from './tutorials-api';
import type { TutorialTypes, TutorialTypeSchema } from './TutorialTypes';
import { serializeTutorials } from './TutorialTypes';

const tutorialSegmentMap = {
    field: 'field',
    farmShare: 'invite',
    fieldHealth: 'field health',
    fieldJob: 'field job',
    fieldUsage: 'field usage',
};

interface StartTutorialAction {
    tutorial: TutorialTypes;
    step: string;
}
export const startTutorial = (payload: StartTutorialAction) => {
    return (dispatch: Dispatch<any>) => {
        trackEvent(`Tutorial ${tutorialSegmentMap[payload.tutorial]} viewed`, {});
        dispatch(startTutorialAction(payload));
    };
};

export const startTutorialAction = createAction<TutorialsState, StartTutorialAction>(
    'Tutorials: Start tutorial',
    (state, payload) =>
        state.set('activeTutorial', payload.tutorial).set('activeStep', payload.step)
);

export const setTutorialStep = createAction<TutorialsState, string | null>(
    'Tutorials: Set step',
    (state, payload) => state.set('activeStep', payload)
);

export const finishTutorial =
    (tutorial: TutorialTypes) => async (dispatch: Dispatch<any>, getState: () => AppState) => {
        const state = getState();
        const userId = selectUserId(state);
        const nextTutorials = state.tutorialsState.tutorials.set(tutorial, true);
        dispatch(resetTutorials());
        await saveTutorialProgressApi(userId, serializeTutorials(nextTutorials));
        dispatch(updateTutorials(nextTutorials));
    };
export const resetTutorials = createAction<TutorialsState, null>(
    'Tutorials: Finish tutorial',
    (state) => state.set('activeTutorial', null).set('activeStep', null)
);

export const updateTutorials = createAction<TutorialsState, Map<string, boolean>>(
    'Tutorials: set tutorials',
    (state, payload) =>
        state.set(
            'tutorials',
            state.tutorials.merge(payload.filter((_, key) => state.tutorials.has(key)))
        )
);

export const TutorialsState = Record({
    tutorials: Map({
        field: false,
        fieldJob: false,
        inputs: false,
        fieldHealth: false,
        herds: false,
        scouting: false,
        data: false,
        farmShare: false,
    }),
    activeTutorial: null as TutorialTypeSchema | null,
    activeStep: null as string | null,
});
export interface TutorialsState extends ReturnType<typeof TutorialsState> {}

export const tutorialsReducer = handleActions<TutorialsState>(TutorialsState(), [
    startTutorialAction,
    setTutorialStep,
    resetTutorials,
    updateTutorials,
]);
