import type { GeoFeatureCollection, GeoObject } from '@fieldmargin/webapp-geo';
import { deserializeLngLat, GeoFeature, serialize } from '@fieldmargin/webapp-geo';
import type { Feature, Polygon } from '@turf/helpers';
import { List } from 'immutable';
import { mapper } from 'lib/fp-helpers';
import type { TurfFns } from 'lib/turf/turf-utils';
import { curry, curryRight, overArgs, spread } from 'lodash';
import flow from 'lodash/flow';

import Field, { getFieldGeoFeatureGeoJson, modifyFieldBoundaryFeature } from './Field';

const curriedModfiyFieldBoundary = curry(modifyFieldBoundaryFeature);

/**
 * Takes the given field and reduces it's boundary by the given margin.
 */
export const reduceFieldByMarginMeters = (field: Field, margin: number, turf: TurfFns): Field =>
    flow(
        getFieldGeoFeatureGeoJson,
        curryRight(turf.bufferOuterPerimeterOnlyByMeters)(-margin),
        deserializeLngLat,
        curriedModfiyFieldBoundary(field)
    )(field);

type HoleLike = Field | GeoFeature | Feature<Polygon>;
const holeToFeature = (hole: HoleLike) =>
    hole instanceof Field
        ? getFieldGeoFeatureGeoJson(hole)
        : hole instanceof GeoFeature
          ? (serialize(hole) as Feature<Polygon>)
          : (hole as Feature<Polygon>);

/**
 * Cuts a hole out of the first field that is the shape of the second field.
 */
export const cutHoleInField = (field: Field, hole: HoleLike, turf: TurfFns): Field =>
    flow(
        turf.differenceOrError,
        deserializeLngLat,
        curriedModfiyFieldBoundary(field)
    )(getFieldGeoFeatureGeoJson(field), holeToFeature(hole));

/**
 * Take the given field and slice it into multiple fields based on the given slice.
 * If the slice does not intersect the field this will return a list of the original field.
 */
export const sliceField = (field: Field, slice: GeoFeatureCollection, turf: TurfFns) => {
    const prepareFieldAndSlice = overArgs(
        (x, y) => [x, y],
        [
            getFieldGeoFeatureGeoJson,
            flow(
                curryRight(turf.geoJsonFeatureFromFeatureCollection)('LineString'),
                turf.lineStringToPolygon
            ),
        ]
    ) as (
        field: Field,
        gfc: GeoFeatureCollection
    ) => [Feature<Polygon>, Feature<Polygon> | undefined];

    return List(
        flow(
            prepareFieldAndSlice,
            spread(turf.splitDifference),
            mapper<Feature<Polygon>, GeoObject>(deserializeLngLat),
            mapper<GeoFeature, Field>(curriedModfiyFieldBoundary(field))
        )(field, slice)
    );
};

/**
 * Merge the second field into the first one.
 * Will error if the fields do not overlap.
 */
export const mergeFields = (first: Field, second: Field, turf: TurfFns) => {
    const buffer = curryRight(turf.bufferOuterPerimeterOnlyByMeters);

    // We need to buffer the fields by a small amount to account for splitting which can leave a
    // tiny gap between features.
    const fieldToFeature = flow(getFieldGeoFeatureGeoJson, buffer(0.001));

    return flow(
        mapper<Field, Feature<Polygon>>(fieldToFeature),
        spread(turf.unionOrError),
        buffer(-0.001),
        deserializeLngLat,
        curriedModfiyFieldBoundary(first)
    )([first, second]);
};
