import { Controller, useForm } from 'react-hook-form';
import { usePromise } from '@fieldmargin/webapp-state';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import OutsidePage from 'auth/OutsidePage';
import { useAction } from 'lib/hooks';
import { setUserAcceptedTerms } from 'users/user-state';
import { acceptTermsApi } from 'users/users-api';
import ErrorMessage from 'view/ErrorMessage';
import { required } from 'view/form/validations';

const validateRequired = required('Required');

interface FormValues {
    termsAgree: boolean;
}

const TermsReacceptPage = () => {
    const updateUser = useAction(setUserAcceptedTerms);
    const { pending, error, setPromise } = usePromise<any>(updateUser);
    const { handleSubmit, control } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            termsAgree: false,
        },
    });
    const onSubmit = () => {
        setPromise(acceptTermsApi());
    };
    return (
        <div className="TermsReacceptPage">
            <OutsidePage>
                <p>
                    We have updated our Terms and Conditions. Please read and agree to the updated
                    terms to continue using fieldmargin.
                </p>

                <form id="register-form" className="stacked-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-8 mb-5">
                        <Controller
                            name="termsAgree"
                            rules={{ validate: validateRequired }}
                            control={control}
                            render={({ fieldState: { error }, field: { onChange } }) => (
                                <Checkbox
                                    id="termsAgree"
                                    onChange={(e) => onChange(e.target.checked)}
                                    label={
                                        <>
                                            I agree to the{' '}
                                            <a
                                                href="https://www.fieldmargin.com/terms-and-privacy-policy.html"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-white underline"
                                            >
                                                Terms & Conditions and Privacy Policy
                                            </a>
                                            {error && (
                                                <ErrorMessage className="text-error-light mt-2">
                                                    {error.message}
                                                </ErrorMessage>
                                            )}
                                        </>
                                    }
                                />
                            )}
                        />
                    </div>
                    <SubmitButton wide disabled={pending}>
                        {pending ? 'Submitting...' : 'Submit'}
                    </SubmitButton>
                    {error && (
                        <ErrorMessage>
                            There was an error during saving, please try again.
                        </ErrorMessage>
                    )}
                </form>
            </OutsidePage>
        </div>
    );
};

export default TermsReacceptPage;
