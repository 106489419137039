import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AttachmentType } from 'farm-editing/attachments';
import { toggleEditingAttachmentByType } from 'farm-editing/farm-editing-state';
import type { FarmMapFeature } from 'farm-map/types';
import { selectCurrentFarm } from 'farms/farms-state';
import { useAction } from 'lib/hooks';
import { useAppSelector } from 'system/store';
import type { Nullable } from 'system/types';

/**
 * Possible scenarios:
 * 1. No attachments are being selected and user clicks on a herd: Navigate to the herd.
 * 2. User is selecting herd attachments and clicks on a herd: Toggle the herd.
 * 3. User is selecting herd attachments and clicks on a non-herd feature: Do nothing, but consider
 *    the click handled to prevent other handlers from running.
 * 4. User clicks on a non-herd feature: Do nothing
 */
export const useFarmMapHerdClick = () => {
    const navigate = useNavigate();
    const farmUuid = useAppSelector(selectCurrentFarm).uuid;

    const selectingState = useAppSelector(
        (state) => state.farmEditingState.selectingMultipleAttachmentType
    );
    const selectingRef = useRef<Nullable<AttachmentType>>(selectingState);
    const toggleHerd = useAction(toggleEditingAttachmentByType(AttachmentType.HERD));

    useEffect(() => {
        selectingRef.current = selectingState;
    }, [selectingState]);

    return (feature: FarmMapFeature) => {
        if (feature.properties.type !== 'HERD') {
            return selectingRef.current === AttachmentType.HERD;
        }

        if (selectingRef.current) {
            toggleHerd(feature.properties.uuid);
        } else {
            navigate(`/farms/${farmUuid}/livestock/${feature.properties.uuid}`);
        }
        return true;
    };
};
