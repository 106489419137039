import { useTranslation } from 'react-i18next';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import type Herd from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import { useDateFormat } from 'hooks/useDateFormat';
import { getDisplayName } from 'lib/util/names';

import type { HerdHistoryArchive, HerdHistoryUnarchive } from './herd-history-generator';

interface HerdHistoryArchiveItemProps {
    herd: Herd;
    historyItem: HerdHistoryArchive | HerdHistoryUnarchive;
    farmUsers: FarmUser[];
}

const HerdHistoryArchiveItem = ({ herd, historyItem, farmUsers }: HerdHistoryArchiveItemProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();

    return (
        <li className="p-0 pb-5">
            <strong className="mb-2 block text-xxs text-gray-500">
                {format(historyItem.date, 'do MMMM yyyy')}
            </strong>
            <span className="flex">
                {herdIcon(herd.type, 'blue')}
                <span className="ml-3">
                    <strong className="block">
                        {t(historyItem.type === 'archive' ? 'archived' : 'unarchived')}
                    </strong>
                    <span className="block text-xxs text-gray-500">
                        {t('created_by')} {getDisplayName(farmUsers, historyItem.item.userId)}
                    </span>
                </span>
            </span>
        </li>
    );
};

export default HerdHistoryArchiveItem;
