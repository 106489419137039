import { useContext } from 'react';
import classNames from 'classnames';
import { GlobalBaseMapControl } from 'components/maps/base-map/GlobalBaseMapControl';
import FullExtentButton from 'components/maps/full-extent/FullExtentButton';
import { setMapView } from 'farm-editing/farm-editing-state';
import SubFieldMapMenu from 'fields/sub-fields/map/SubFieldMapMenu';
import { useActiveSection } from 'hooks/useActiveSection';
import { useAction } from 'lib/hooks';
import { MeasureButton } from 'measure/MeasureButton';
import PrintButton from 'print/PrintButton';
import { UIContext } from 'system/ui-state';
import CurrentWeather from 'weather/CurrentWeather';

import FarmMapControlsInfo from './FarmMapControlsInfo';

import './FarmMapControls.css';

const FarmMapControls = () => {
    const editingSubFields = useActiveSection().sub === 'sub-fields';
    const setMapViewAction = useAction(setMapView);
    const { infoOpen } = useContext(UIContext);

    return (
        <>
            <GlobalBaseMapControl />
            <FullExtentButton setFullExtent={() => setMapViewAction({ type: 'full-extent' })} />
            <div
                className={classNames('no-print FarmMapControlsGroup', {
                    'forefront-abs': infoOpen,
                })}
            >
                {infoOpen && <FarmMapControlsInfo />}
                <MeasureButton />
                <PrintButton />
                <CurrentWeather className={infoOpen ? 'opacity-0' : undefined} />
            </div>
            {editingSubFields && <SubFieldMapMenu />}
        </>
    );
};

export default FarmMapControls;
