import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import { AttachmentType } from 'farm-editing/attachments';
import {
    selectEditingAttachmentsByType,
    setEditingAttachmentsByType,
    startSelectingMultipleAttachments,
    toggleEditingAttachmentByType,
} from 'farm-editing/farm-editing-state';
import type Herd from 'herd/Herd';
import { HerdType } from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import { selectHerdsSortedByName } from 'herd/herd-selectors';
import HerdTypeColourBar from 'herd/sidebar/HerdTypeColourBar';
import { Set } from 'immutable';
import { Form } from 'informed';
import { preventDefaultAnd } from 'lib/dom';
import { useOutOfDatePromise } from 'lib/hooks';
import type Note from 'notes/Note';
import { useUpdateNoteHerds } from 'notes/note-hooks';
import { bindActionCreators } from 'redux';
import EditableActions from 'sidebar/modules/common/EditableActions';
import EditButton from 'sidebar/modules/common/EditButton';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import FarmRememberingLink from 'view/FarmRememberingLink';

import NoteHerdBubbles from '../NoteHerdBubbles';

interface EditableNoteHerdsProps {
    // From parent
    note: Note;

    // From redux
    herds: Herd[] | null;
    editingHerdUuids: Set<string>;
    startSelectingMultipleAttachments: typeof startSelectingMultipleAttachments;
    setEditingHerdUuids: SingleParamVoidFunction<Set<string>>;
    toggleEditingHerdUuid: SingleParamVoidFunction<string>;
}

const EditableNoteHerds = ({
    note,
    herds,
    editingHerdUuids,
    startSelectingMultipleAttachments,
    setEditingHerdUuids,
    toggleEditingHerdUuid,
}: EditableNoteHerdsProps) => {
    const { t } = useTranslation();
    const updateNoteHerds = useUpdateNoteHerds();

    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(() => {
        setEditingHerdUuids(Set());
        setEditing(false);
    });

    const [editing, setEditing] = useState(false);

    if (herds === null) {
        return null;
    }

    const displayableNoteHerds = herds.filter((herd) => note.herdUuids?.includes(herd.uuid));

    const handleSave = () => {
        setPromise(updateNoteHerds(note, editingHerdUuids.toArray()));
    };

    const handleAttachClick = preventDefaultAnd(() => {
        startSelectingMultipleAttachments(AttachmentType.HERD);
    });

    return (
        <Form onSubmit={handleSave}>
            <SidebarModule className="px-0 pb-0" editing={editing}>
                {!editing ? (
                    <>
                        <EditButton
                            setEditing={() => {
                                setEditingHerdUuids(Set(note.herdUuids));
                                setEditing(true);
                            }}
                            className="mr-5"
                        />
                        <h4 className="ml-5">{t('livestock_herds')}</h4>
                        {displayableNoteHerds.length > 0 ? (
                            displayableNoteHerds.map((herd) => (
                                <FarmRememberingLink
                                    to={`/livestock/${herd.uuid}`}
                                    key={herd.uuid}
                                    className="flex items-center pr-5 bordered-t"
                                >
                                    <HerdTypeColourBar herd={herd} className="h-12" />
                                    <strong className="">
                                        <span className="mr-2">{herdIcon(herd.type)}</span>{' '}
                                        {herd.name} {herd.size}
                                    </strong>
                                </FarmRememberingLink>
                            ))
                        ) : (
                            <em className="block ml-5 mb-5 text-gray-550">
                                {t('note_no_herds_placeholder')}
                            </em>
                        )}
                    </>
                ) : (
                    <div className="px-5 pb-5">
                        <Button
                            onClick={handleAttachClick}
                            variant="outline"
                            className={c('flex items-center', {
                                'mb-4': editingHerdUuids.size > 0,
                            })}
                        >
                            {herdIcon(HerdType.SHEEP, 'blue', 'mr-3')}{' '}
                            {t('create_note_attach_herd')}
                        </Button>
                        <NoteHerdBubbles
                            noteHerds={editingHerdUuids}
                            onRemove={toggleEditingHerdUuid}
                        />
                        <EditableActions
                            disabled={pending}
                            error={error}
                            outOfDate={outOfDate}
                            setEditing={() => {
                                setEditingHerdUuids(Set());
                                setEditing(false);
                            }}
                        />
                    </div>
                )}
            </SidebarModule>
        </Form>
    );
};

export default connect(
    (state: AppState) => ({
        herds: selectHerdsSortedByName(state),
        editingHerdUuids: selectEditingAttachmentsByType(state, AttachmentType.HERD),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                startSelectingMultipleAttachments,
                setEditingHerdUuids: setEditingAttachmentsByType(AttachmentType.HERD),
                toggleEditingHerdUuid: toggleEditingAttachmentByType(AttachmentType.HERD),
            },
            dispatch
        )
)(EditableNoteHerds);
