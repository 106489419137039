import { createConfigService } from '@fieldmargin/webapp-configurator';
import { envConfig } from 'config/env-config';

interface ConfigSpec {
    // Name of environment
    environmentName: string;
    // Logs extra things for development
    debug: boolean;
    // Root URL for auth API
    authApiRoot: string;
    // Root URL for rest API
    apiRoot: string;
    // Root URL for media server
    mediaApiRoot: string;
    // Segment source key
    segmentKey: string;
    // Segment user IDs will be prefixed with the following
    segmentIdPrefix: string;

    // Google maps API key
    googleMapsKey: string;
    // Azure Maps API key
    azureMapsKey: string;
    // HERE map API key
    hereApiKey: string;
    // Mapbox access token
    mapboxAccessToken: string;
    // Basic Auth Token which tells the server the request is coming from the web platform on registration & login
    authorizationBasic: string;
    // Root URL for looking up places/locations with Google
    googlePlacesApi: string;
    // Root URL for the Sentinel Hub feature service, for trial users
    sentinelHubWfsApiTrial: string;
    // Root URL for the Sentinel Hub map tiling service, for trial users
    sentinelHubWmsApiTrial: string;
    // Root URL for the Sentinel Hub feature service, for paid users
    sentinelHubWfsApiPaid: string;
    // Root URL for the Sentinel Hub map tiling service, for paid users
    sentinelHubWmsApiPaid: string;
    // Suffix used in Open URLs to integrations (such as -dev)
    integrationUrlSuffix: string;

    // Sentry DSN client key
    sentryDsnKey: string;
    sentryRelease: string;
    sentryEnvironment: string;
    sentryTracesSampleRate: number;

    // Firebase config
    firebaseApiKey: string;
    firebaseAppId: string;
    firebaseAuthDomain: string;
    firebaseDatabaseURL: string;
    firebaseProjectId: string;
    firebaseStorageBucket: string;
    firebaseMessagingSenderId: string;

    paymentWeb: Omit<PaymentWebConfig, 'debug' | 'apiRoot'>;

    orgManagementUrl: string;
}

export interface PaymentWebConfig {
    debug: boolean;
    apiRoot: string;
    // Root URL for marketing site
    marketingRoot: string;
    stripeApiKey: string;
    appStoreLink: string;
    playStoreLink: string;
    cancelCouponMonth: string;
    cancelCouponAnnual: string;
}

const initialConfig: ConfigSpec = {
    // APP
    environmentName: envConfig.mode,
    debug: envConfig.isDevelopment,
    authApiRoot: envConfig.app.authApiRoot,
    apiRoot: envConfig.app.apiRoot,
    mediaApiRoot: envConfig.app.mediaApiRoot,
    authorizationBasic: envConfig.app.authorizationBasic,
    orgManagementUrl: envConfig.app.orgManagementUrl,

    // SEGMENT
    segmentKey: envConfig.segment.key,
    segmentIdPrefix: envConfig.segment.idPrefix,

    // MAPS
    googleMapsKey: envConfig.maps.google,
    azureMapsKey: envConfig.maps.azure,
    mapboxAccessToken: envConfig.maps.mapboxAccessToken,
    hereApiKey: envConfig.maps.hereApiKey,
    googlePlacesApi: envConfig.google.placesApi,

    integrationUrlSuffix: envConfig.integrations.urlSuffix,

    // SENTINEL
    sentinelHubWfsApiTrial: envConfig.sentinelHub.wfsApiTrial,
    sentinelHubWfsApiPaid: envConfig.sentinelHub.wfsApiPaid,
    sentinelHubWmsApiTrial: envConfig.sentinelHub.wmsApiTrial,
    sentinelHubWmsApiPaid: envConfig.sentinelHub.wmsApiPaid,

    // SENTRY
    sentryDsnKey: envConfig.sentry.dnsKey,
    sentryRelease: envConfig.sentry.release,
    sentryEnvironment: envConfig.sentry.environment,
    sentryTracesSampleRate: parseFloat(envConfig.sentry.tracesSampleRate),

    // Firebase config
    firebaseApiKey: envConfig.firebase.apiKey,
    firebaseAppId: envConfig.firebase.appId,
    firebaseAuthDomain: envConfig.firebase.authDomain,
    firebaseDatabaseURL: envConfig.firebase.databaseURL,
    firebaseProjectId: envConfig.firebase.projectId,
    firebaseStorageBucket: envConfig.firebase.storageBucket,
    firebaseMessagingSenderId: envConfig.firebase.messagingSenderId,

    // PAYMENT WEB
    paymentWeb: {
        marketingRoot: envConfig.payment.marketingRoot,

        stripeApiKey: envConfig.payment.stripeApiKey,
        appStoreLink: envConfig.payment.appStoreLink,
        playStoreLink: envConfig.payment.playStoreLink,

        cancelCouponMonth: envConfig.payment.cancelCouponMonth,
        cancelCouponAnnual: envConfig.payment.cancelCouponAnnual,
    },
};

export const getPaymentWebConfig = (): PaymentWebConfig => ({
    ...configService.get('paymentWeb'),
    debug: configService.get('debug'),
    apiRoot: configService.get('apiRoot'),
});

const configService = createConfigService(initialConfig);

export const setConfig = (spec: ConfigSpec) => {
    configService.set(spec);
};

export const getMapKeyConfig = () => ({
    google: configService.get('googleMapsKey'),
    azure: configService.get('azureMapsKey'),
    mapboxAccessToken: configService.get('mapboxAccessToken'),
    mapbox: configService.get('mapboxAccessToken'),
    hereApiKey: configService.get('hereApiKey'),
});

export default configService;
