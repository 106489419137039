import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import icon from 'components/icons/icon';

interface ListDropdownLinkProps extends LinkProps {
    selected?: boolean;
    locked?: boolean;
}

const ListDropdownLink = ({
    className,
    children,
    selected = false,
    locked = false,
    ...props
}: ListDropdownLinkProps) => {
    return (
        <Link
            className={clsx('ListDropdownItem', className, { 'text-gray-400': locked })}
            {...props}
        >
            {children}
            {!locked && (
                <i
                    className={clsx(
                        'ml-auto',
                        selected
                            ? 'ion-checkmark text-xl text-green-600'
                            : 'ion-ios-arrow-right text-xl text-gray-300'
                    )}
                />
            )}
            {locked && icon('padlock', 'blue', 'ml-auto -mr-1.5')}
        </Link>
    );
};

export default ListDropdownLink;
