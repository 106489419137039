import type { PropsWithChildren } from 'react';
import c from 'classnames';

import './ArchivedBar.css';

const ArchivedBar = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
    return <h3 className={c('ArchivedBar', className)}>{children}</h3>;
};

export const ArchivedBarButton = ({
    children,
    className,
    ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
        <button {...props} className={c('ArchivedBar bare-btn focus', className)}>
            {children}
        </button>
    );
};

export default ArchivedBar;
