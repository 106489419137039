import type FieldUsage from 'fields/FieldUsage';
import type { List } from 'immutable';

import { BulkUsageStep } from './BulkUsageContainer';
import FieldUsageChosen from './FieldUsageChosen';
import FieldUsageSelection from './FieldUsageSelection';

interface FieldUsageSectionProps {
    step: BulkUsageStep;
    fieldUsages: List<FieldUsage>;
    selectedFieldUsageUuid: string;

    onUsageChosen: (fieldUsageUuid: string) => void;
    onEditUsage: VoidFunction;
    onAddNewUsage: VoidFunction;
}

const FieldUsageSection = ({
    step,
    fieldUsages,
    selectedFieldUsageUuid,
    onUsageChosen,
    onEditUsage,
    onAddNewUsage,
}: FieldUsageSectionProps) => {
    if (step === BulkUsageStep.SELECT_USAGE) {
        return (
            <FieldUsageSelection
                fieldUsages={fieldUsages}
                selectedFieldUsageUuid={selectedFieldUsageUuid}
                setSelectedFieldUsage={onUsageChosen}
                toggleAddingUsage={onAddNewUsage}
            />
        );
    }
    const selectedFieldUsage = fieldUsages.find(
        (fieldUsage) => fieldUsage.uuid === selectedFieldUsageUuid
    ) as FieldUsage;
    return <FieldUsageChosen onEditUsage={onEditUsage} selectedFieldUsage={selectedFieldUsage} />;
};

export default FieldUsageSection;
