import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import { useCurrentOperation } from 'hooks/useCurrentOperation';
import { isCreator } from 'lib/owner';
import type FullOperation from 'operations/FullOperation';
import { deleteOperationApi } from 'operations/operations-api';
import { removeOperation } from 'operations/operations-state';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import { selectIsFarmOwner } from 'team/farm-users-state';
import { selectUserId } from 'users/user-state';
import { trackEvent } from 'utils/trackEvent';
interface OperationDeletionProps {
    // From redux
    farm: Farm;
    fullOperation?: FullOperation;
    canDelete: boolean;
    removeOperation: typeof removeOperation;
}

const OperationDeletionBase = ({
    farm,
    fullOperation,
    canDelete,
    removeOperation,
}: OperationDeletionProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pending, error, setPromise } = usePromise(() => {
        removeOperation(fullOperation);
    });

    if (fullOperation === undefined) {
        return null;
    }

    let content: ReactNode = null;

    const handleDeletion = async () => {
        const promise = deleteOperationApi(fullOperation.summary);
        setPromise(promise);
        await promise;
        trackEvent('Field operation deleted', {
            farmUuid: farm.uuid,
            jobUuid: fullOperation.uuid,
        });
        navigate(`/farms/${farm.uuid}/notes`);
    };

    if (canDelete) {
        content = (
            <SidebarModule editing>
                <p className="text-lg">{t('message_delete_confirmation_operation')}</p>
                <Button variant="danger" onClick={handleDeletion} disabled={pending}>
                    {t(pending ? 'label_deleting' : 'label_delete')}
                </Button>
            </SidebarModule>
        );
    } else {
        content = (
            <SidebarModule editing>
                <p className="text-lg text-eror-text">{t('message_delete_unsupported')}</p>
            </SidebarModule>
        );
    }
    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader>
                    <SidebarHeaderLink to={`/farms/${farm.uuid}/operations/${fullOperation.uuid}`}>
                        {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                    </SidebarHeaderLink>
                </SidebarHeader>
                {error && (
                    <SidebarError
                        title={t('failed_to_delete')}
                        message={t('something_went_wrong')}
                    />
                )}
            </div>
            <div className="scrolling">
                <SidebarModule editing>
                    <h2 className="mb-0">{fullOperation.summary.name}</h2>
                </SidebarModule>
                {content}
            </div>
        </div>
    );
};

const OperationDeletionBaseConnect = connect(
    (state: AppState, { fullOperation }: Pick<OperationDeletionProps, 'fullOperation'>) => ({
        farm: selectCurrentFarm(state),
        canDelete:
            selectIsFarmOwner(state) ||
            (fullOperation !== undefined && isCreator(fullOperation.summary, selectUserId(state))),
    }),
    (dispatch) => bindActionCreators({ removeOperation }, dispatch)
)(OperationDeletionBase);

function OperationDeletion() {
    const { currentOperation } = useCurrentOperation();

    return <OperationDeletionBaseConnect fullOperation={currentOperation} />;
}

export { OperationDeletion };
