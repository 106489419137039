import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@fieldmargin/webapp-styling/components/button';
import { SearchBar } from 'components/search-bar';
import { AttachmentType } from 'farm-editing/attachments';
import {
    selectEditingAttachmentsByType,
    setEditingAttachmentsByType,
    stopSelectingMultipleAttachments,
} from 'farm-editing/farm-editing-state';
import { HerdType } from 'herd/Herd';
import {
    selectFilteredHerdsSortedByNameGroupedByStatusAndType,
    selectUnlockedHerds,
} from 'herd/herd-selectors';
import { setHerdSearchString } from 'herd/herd-state';
import type { HerdsByType } from 'herd/herd-types';
import { Set } from 'immutable';
import { isEmpty } from 'ramda';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';

import HerdEmptyState from '../HerdEmptyState';

import HerdSelectorHerdTypeHerds from './HerdSelectorHerdTypeHerds';

interface HerdSelectorProps {
    // From redux
    herdSearchString: string;
    herds: HerdsByType;
    selectedHerds: string[];
    unlockedHerds: string[] | undefined;
    stopSelectingMultipleAttachments: typeof stopSelectingMultipleAttachments;
    setHerdSearchString: typeof setHerdSearchString;
    setEditingHerdUuids: SingleParamVoidFunction<Set<string>>;
}

const HerdSelector = ({
    herdSearchString,
    herds,
    selectedHerds,
    unlockedHerds,
    stopSelectingMultipleAttachments,
    setHerdSearchString,
    setEditingHerdUuids,
}: HerdSelectorProps) => {
    const { t } = useTranslation();

    const onDone = () => {
        stopSelectingMultipleAttachments();
    };

    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader className="px-5">
                    <Button onClick={onDone} small variant="danger-outline">
                        {t('cancel')}
                    </Button>
                    <Button onClick={onDone} small>
                        {t('save')}
                    </Button>
                </SidebarHeader>
            </div>
            <SidebarModule className="non-scrolling" editing>
                <h3 className="text-sm uppercase">{t('create_note_attach_herd')}</h3>
                <SearchBar value={herdSearchString} searchFunction={setHerdSearchString} />
            </SidebarModule>
            <div className="px-0 scrolling relative">
                {isEmpty(herds) && <HerdEmptyState />}
                {Object.entries(herds).map(([herdType, herds]) => (
                    <HerdSelectorHerdTypeHerds
                        key={herdType}
                        type={HerdType[herdType]}
                        herds={herds}
                        unlockedHerds={unlockedHerds ?? []}
                        selectedHerds={selectedHerds}
                        setSelectedHerds={(herdUuids) => setEditingHerdUuids(Set(herdUuids))}
                    />
                ))}
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        herdSearchString: state.herdsState.herdSearchString,
        herds: selectFilteredHerdsSortedByNameGroupedByStatusAndType(state).active,
        selectedHerds: selectEditingAttachmentsByType(state, AttachmentType.HERD).toArray(),
        unlockedHerds: selectUnlockedHerds(state),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                stopSelectingMultipleAttachments,
                setHerdSearchString,
                setEditingHerdUuids: setEditingAttachmentsByType(AttachmentType.HERD),
            },
            dispatch
        )
)(HerdSelector);
