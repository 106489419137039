import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getDueDate, getURL } from 'activity/activity-helper';
import ActivityIcon from 'activity/ActivityIcon';
import type Feature from 'features/Feature';
import { selectFeatureTodoTasks } from 'features/features-selectors';
import type { List } from 'immutable';
import type Note from 'notes/Note';
import DueDateWords from 'notes/sidebar/list/DueDateWords';
import type { AppState } from 'system/store';
import RememberingLink from 'view/RememberingLink';

import { useFeaturesContext } from './useFeaturesContext';

interface FeatureTodoProps {
    // From parent - used in mapStateToProps
    feature: Feature;
    // From redux
    tasks: List<Note>;
}

const FeatureTodoBase = ({ tasks }: FeatureTodoProps) => {
    const { t } = useTranslation();
    return (
        <div className="p-5">
            {tasks.size === 0 ? (
                <p>{t('feature_todo_empty')}</p>
            ) : (
                tasks.map((task) => (
                    <RememberingLink
                        key={task.uuid}
                        to={getURL(task)}
                        className="flex gap-3 items-center mb-2 focus py-1"
                    >
                        <ActivityIcon activity={task} />
                        {task.name}
                        {getDueDate(task) !== null && (
                            <DueDateWords className="ml-2" date={getDueDate(task)!} />
                        )}
                    </RememberingLink>
                ))
            )}
        </div>
    );
};

const FeatureTodoBaseConnect = connect(
    (state: AppState, { feature }: Pick<FeatureTodoProps, 'feature'>) => ({
        tasks: selectFeatureTodoTasks(state, feature.uuid),
    })
)(FeatureTodoBase);

function FeatureTodo() {
    const { feature } = useFeaturesContext();

    return <FeatureTodoBaseConnect feature={feature} />;
}

export { FeatureTodo };
