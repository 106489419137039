import type { List } from 'immutable';
import { DEFAULT_COLOUR } from 'lib/colours';
import { isEmpty, prop } from 'ramda';
import type { Nullable } from 'system/types';
import { trackEvent } from 'utils/trackEvent';

export interface FieldUsage {
    uuid: string;
    farmUuid: string;
    name: string;
    cropCode: string;
    description: string;
    colour: string;
    archived: boolean;
    organisationFieldUsageUuid?: string;
}

export interface WriteFieldUsageDTO {
    name: string;
    description?: string;
    colour?: string;
    cropCode?: string;
}

export interface FieldUsageDTO {
    uuid: string;
    farmUuid: string;
    name: Nullable<string>;
    cropCode?: string;
    description: Nullable<string>;
    colour: string;
    archived?: boolean;
    organisationFieldUsageUuid?: Nullable<string>;
}

export const deserializeFieldUsage = (json: FieldUsageDTO): FieldUsage => ({
    uuid: json.uuid,
    farmUuid: json.farmUuid,
    name: json.name ?? '',
    cropCode: json.cropCode ?? '',
    description: json.description ?? '',
    colour: json.colour,
    archived: json.archived === undefined ? false : json.archived,
    organisationFieldUsageUuid: json.organisationFieldUsageUuid ?? undefined,
});

export const getUsageName = (usage: FieldUsage) => {
    if (!usage.name || usage.name.toLowerCase() === 'other') {
        return usage.description === '' ? 'Other' : usage.description;
    }

    return usage.description !== '' ? `${usage.name} - ${usage.description}` : usage.name;
};

export const asBadge = (usage: FieldUsage) => {
    return {
        id: usage.uuid,
        uuid: usage.uuid,
        name: getUsageName(usage),
        colour: usage.colour ? usage.colour : DEFAULT_COLOUR,
        archived: usage.archived,
    };
};

export const asBadgeWithYear = (usage: FieldUsage, year: number) => {
    const badge = asBadge(usage);
    badge.name = `${year}: ${badge.name}`;
    return badge;
};

export const asTypeItem = (usage: FieldUsage) => ({
    id: usage.uuid,
    name: getUsageName(usage),
    colour: usage.colour ? usage.colour : DEFAULT_COLOUR,
});

export const hasNoCropCode = (usage: FieldUsage) =>
    isEmpty(usage.cropCode) || usage.cropCode === 'other';

export const hasCropCode = (usage: FieldUsage) => !hasNoCropCode(usage);

export const getFieldUsageCropCodes = (fieldUsages: List<FieldUsage>) =>
    fieldUsages.filter(hasCropCode).map(prop('cropCode')).toArray();

export default FieldUsage;

export const trackFieldUsageChange = (msg: string) => (fieldUsage: FieldUsage) => {
    trackEvent(`Field usage ${msg}`, {
        fieldUsageUuid: fieldUsage.uuid,
        name: fieldUsage.name,
    });
    return fieldUsage;
};
