import type { Dispatch, SetStateAction } from 'react';
import { useOutletContext } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type Herd from 'herd/Herd';

export interface HerdMergeSchema {
    farm: Farm;
    herd: Herd;
    selectedHerds: string[];
    setSelectedHerds: Dispatch<SetStateAction<string[]>>;
}

export const useHerdMergeContext = () => {
    return useOutletContext<HerdMergeSchema>();
};
