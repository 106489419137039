import { useDispatch, useSelector } from 'react-redux';
import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { serialize } from '@fieldmargin/webapp-geo';
import { usePromise } from '@fieldmargin/webapp-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { usePageFeature } from 'hooks/usePageFeature';
import { dispatchAndReturn } from 'lib/fp-helpers';
import { pipe } from 'ramda';
import type { AppState } from 'system/store';
import { useAppSelector } from 'system/store';
import { selectUserId } from 'users/user-state';

import type Feature from './Feature';
import type { FeatureDTO } from './Feature';
import { mergeFeatureAndWriteProps, trackFeatureChange } from './Feature';
import { createFeature, deleteFeature, updateFeature } from './features-api';
import { removeFeature, setFeature } from './features-state';

const redirectToFeature = (farmUuid: string, navigate: NavigateFunction) => (feature: Feature) =>
    navigate(`/farms/${farmUuid}/features/${feature.uuid}`);

const redirectToFeatures = (farmUuid: string, navigate: NavigateFunction) => () =>
    navigate(`/farms/${farmUuid}/features`);

export const useCreateFeature = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const farm = useAppSelector(selectCurrentFarm);
    const userId = useAppSelector(selectUserId);

    return (title: string, geoJson: GeoFeatureCollection, featureTypeUuid: string) =>
        createFeature(farm.uuid, title, serialize(geoJson), featureTypeUuid)
            // Server doesn't return the feature object so we need to add the createdByUserId here.
            .then((feature) => feature.set('createdByUserId', userId))
            .then(trackFeatureChange('created'))
            .then(dispatchAndReturn<Feature>(dispatch, setFeature))
            .then(redirectToFeature(farm.uuid, navigate));
};

export const useUpdateFeature = (feature: Feature) => {
    const dispatch = useDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);

    return (data: Partial<FeatureDTO>) =>
        updateFeature(farm.uuid, feature.uuid, data)
            .then((savedFeature) => mergeFeatureAndWriteProps(feature, savedFeature))
            .then(trackFeatureChange('updated'))
            .then(pipe(setFeature, dispatch));
};

export const useDeleteFeature = () => {
    const { pending, error, setPromise } = usePromise();
    const { currentFarm } = useCurrentFarm();
    const { feature } = usePageFeature();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const deleteFeatureHandler = () => {
        if (!feature) return;

        return setPromise(
            deleteFeature(currentFarm.uuid, feature)
                .then(trackFeatureChange('deleted'))
                .then(pipe(removeFeature, dispatch))
                .then(redirectToFeatures(currentFarm.uuid, navigate))
        );
    };

    return { deleteFeatureHandler, pending, error };
};
