import Button from '@fieldmargin/webapp-styling/components/button';
import { useEditingFieldUuids } from 'hooks/useEditingFieldUuids';
import { useEditingGeoFeatureCollection } from 'hooks/useEditingGeoFeatureCollection';
import { usePageParams } from 'hooks/usePageParamas';
import { useSelectBoundariesTracking } from 'hooks/useSegmentTracking';
import { useTranslations } from 'hooks/useTranslations';
import { defaultToZero } from 'lib/fp-helpers';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import ReturningLinkButton from 'view/ReturningLinkButton';

interface AutoBoundarySelectFieldsProps {
    onDone: VoidFunction;
}

const AutoBoundarySelectFields = ({ onDone }: AutoBoundarySelectFieldsProps) => {
    const { t, plural } = useTranslations();
    const { farmUuid } = usePageParams();
    const { editingFieldUuids } = useEditingFieldUuids();
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();

    useSelectBoundariesTracking();

    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader className="px-5">
                    <ReturningLinkButton
                        to={`/farms/${farmUuid}/fields/new`}
                        variant="danger-outline"
                        small
                    >
                        {t('cancel')}
                    </ReturningLinkButton>
                    <Button disabled={editingFieldUuids.size === 0} small onClick={onDone}>
                        {t('field_boundary_auto_select_boundaries_continue')}
                    </Button>
                </SidebarHeader>
            </div>
            <div className="scrolling">
                <SidebarModule editing>
                    <h4>{t('field_boundary_auto_select_boundaries_title')}</h4>
                    <p>{t('field_boundary_auto_select_boundaries_desc')}</p>
                    <p>
                        {plural('field_boundary_auto_select_boundaries_selected', {
                            count: editingFieldUuids.size,
                            sprintf: [
                                editingFieldUuids.size,
                                defaultToZero(editingGeoFeatureCollection?.features.size),
                            ],
                        })}
                    </p>
                </SidebarModule>
            </div>
        </div>
    );
};

export default AutoBoundarySelectFields;
