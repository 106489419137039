import { useTranslation } from 'react-i18next';
import Modal from 'modal/Modal';
import UpgradeButton from 'upgrade/UpgradeButton';
import UpgradeLink from 'upgrade/UpgradeLink';
import PadlockIcon from 'view/svg/PadlockIcon';

interface TeamInviteBlockedModalProps {
    onClose: VoidFunction;
}

const TeamInviteBlockedModal = ({ onClose }: TeamInviteBlockedModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal onClose={onClose}>
            <div className="bg-white rounded text-center text-lg flex flex-col items-center gap-6 p-10">
                <PadlockIcon fill="#ce1d18" className="w-12" />
                <h2 className="text-xxl">{t('pro_upgrade_needed')}</h2>
                <p className="mb-0">{t('max_team_size_reached')}</p>
                <p className="mb-0">
                    <UpgradeLink>{t('upgrade_to_invite_more_people')}</UpgradeLink>
                </p>
                <UpgradeButton />
            </div>
        </Modal>
    );
};

export default TeamInviteBlockedModal;
