import { clearEditingGeoFeatureCollection } from 'farm-editing/farm-editing-state';
import { useAppDispatch } from 'system/store';

const useClearEditingGeoFeatureCollection = () => {
    const dispatch = useAppDispatch();

    return { clearEditingGeoFeatureCollection: () => dispatch(clearEditingGeoFeatureCollection()) };
};

export { useClearEditingGeoFeatureCollection };
