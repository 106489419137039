import type { Extent, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { calcArea, concatExtents, extentFromGeo } from '@fieldmargin/webapp-geo';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { List, Map } from 'immutable';
import { logReselect } from 'lib/util/reselect-util';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

import { getClippedTilesForSentinelHubMap } from './sentinel-hub-api';
import { VegetationMapsVisibility } from './vegetation-maps-state';

export const selectIsVegetationMapShowing = createSelector(
    (state: AppState) => state.vegetationMapsState.visibleSentinelHubMap,
    (state: AppState) => state.vegetationMapsState.visibility,
    (sentinelHubMap, visibility) =>
        sentinelHubMap !== undefined && visibility === VegetationMapsVisibility.ON
);

const selectFieldExtents = createSelector(
    (state: AppState) => state.fieldsState.fields,
    (fields) => {
        logReselect('selectFieldExtents');
        return fields
            ? (fields
                  .map((f) => extentFromGeo(f.geoJson as GeoFeatureCollection))
                  .filter((e) => !!e) as List<Extent>)
            : List<Extent>();
    }
);

/**
 * Selects a single Extent that covers all fields on the farm.
 */
export const selectWholeFarmBoundary = createSelector(selectFieldExtents, (extents) => {
    logReselect('selectWholeFarmBoundary');
    return concatExtents(extents);
});

export const selectPremiumLimit = createSelector(
    (state: AppState) => selectCurrentFarm(state),
    (farm) => {
        logReselect('selectPremiumLimit');
        if (!farm) {
            return 0;
        }

        // If there is no hectare limit it implies that the user does not have field health
        return farm.plan.fieldHealthHectareLimit ? farm.plan.fieldHealthHectareLimit * 10000 : 0;
    }
);

export enum FieldPremiumness {
    PREMIUM = 'premium',
    INVALID = 'invalid',
    LIMIT_REACHED = 'limit-reached',
}

export const selectFieldPremiumness = createSelector(
    (fields: List<Field>) => fields,
    (_fields: List<Field>, sizeLimit: number) => sizeLimit,
    (fields, sizeLimit): Map<string, FieldPremiumness> => {
        logReselect('selectFieldPremiumness');
        if (!fields || !sizeLimit) {
            return Map();
        }

        const premiumness = {};
        let total = 0;

        fields = fields.sortBy((field) => field.createdDate.getTime());
        fields.forEach((field) => {
            if (!field.geoJson) {
                premiumness[field.uuid] = FieldPremiumness.INVALID;
                return;
            }

            const size = calcArea(field.geoJson);
            if (size <= 0) {
                premiumness[field.uuid] = FieldPremiumness.INVALID;
                return;
            }

            if (total + size < sizeLimit) {
                total += size;
                premiumness[field.uuid] = FieldPremiumness.PREMIUM;
            } else {
                premiumness[field.uuid] = FieldPremiumness.LIMIT_REACHED;
            }
        });
        return Map(premiumness);
    }
);

export const selectPremiumFields = createSelector(
    (fields: List<Field>) => fields,
    selectFieldPremiumness,
    (fields, fieldPremiumness): List<Field> => {
        logReselect('selectPremiumFields');

        return fields
            ? fields.filter(
                  (field) => fieldPremiumness.get(field.uuid) === FieldPremiumness.PREMIUM
              )
            : List();
    }
);

export const selectSentinelHubTileSets = createSelector(
    selectCurrentFarm,
    (state: AppState) =>
        state.vegetationMapsState.visibleSentinelHubMap
            ? state.vegetationMapsState.farmBoundaryPolygons
            : null,
    (state: AppState) => state.vegetationMapsState.visibleSentinelHubMap,
    (state: AppState) => state.vegetationMapsState.visibleStyle,
    (farm, farmBoundaries, sentinelHubMap, style) => {
        logReselect('selectSentinelHubTileSets');
        if (!farm || !farmBoundaries || !sentinelHubMap) {
            return null;
        }

        return farmBoundaries.map((polygon) =>
            getClippedTilesForSentinelHubMap(sentinelHubMap, polygon, style, farm.freeTrial)
        );
    }
);
