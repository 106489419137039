import type { PropsWithChildren } from 'react';
import { useRef } from 'react';
import { useToggle } from '@fieldmargin/webapp-state';
import classNames from 'classnames';
import icon from 'components/icons/icon';
import { useOutsideComponentClickListener } from 'lib/hooks';
import { uniqueId } from 'lodash';

import './ActionMenu.css';

interface ActionMenuProps extends PropsWithChildren {
    className?: string;
}

const ActionMenu = ({ children, className }: ActionMenuProps) => {
    const id = useRef<string>(uniqueId('ActionMenu')).current;
    const [open, toggleOpen] = useToggle(false);

    useOutsideComponentClickListener(id, () => {
        open && toggleOpen();
    }, [open]);

    return (
        <div className={classNames('ActionMenu', className)} data-elementid={id}>
            <button
                onClick={toggleOpen}
                className={classNames('bare-btn ActionMenuToggle', {
                    ActionMenuToggleOpen: open,
                })}
            >
                {icon('more', 'dark-blue')}
            </button>
            {open && <ul className="ActionMenuItems">{children}</ul>}
        </div>
    );
};

export default ActionMenu;
