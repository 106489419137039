import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import { usePageParams } from 'hooks/usePageParamas';
import { useEditBoundariesTracking } from 'hooks/useSegmentTracking';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import ReturningLinkButton from 'view/ReturningLinkButton';

interface AutoBoundaryEditBoundariesProps {
    onDone: VoidFunction;
}

const AutoBoundaryEditBoundaries = ({ onDone }: AutoBoundaryEditBoundariesProps) => {
    const { t } = useTranslation();
    const { farmUuid } = usePageParams();

    useEditBoundariesTracking();
    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader className="px-5">
                    <ReturningLinkButton
                        to={`/farms/${farmUuid}/fields/new`}
                        variant="danger-outline"
                        small
                    >
                        {t('cancel')}
                    </ReturningLinkButton>
                    <Button small onClick={onDone}>
                        {t('field_boundary_auto_edit_boundaries_continue')}
                    </Button>
                </SidebarHeader>
            </div>
            <div className="scrolling">
                <SidebarModule editing>
                    <h4>{t('field_boundary_auto_edit_boundaries_title')}</h4>
                    <p>{t('field_boundary_auto_edit_boundaries_desc')}</p>
                </SidebarModule>
            </div>
        </div>
    );
};

export default AutoBoundaryEditBoundaries;
