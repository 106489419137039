import type { Options } from 'react-markdown';
import Markdown from 'react-markdown';
import StringOnly from 'view/StringOnly';

const MarkdownNoPara = ({ children, components, ...props }: Options) => {
    return (
        <Markdown components={{ ...components, p: StringOnly }} {...props}>
            {children}
        </Markdown>
    );
};

export default MarkdownNoPara;
