import type { ComponentProps } from 'react';
import { forwardRef } from 'react';
import { clsx } from 'clsx';

import styles from './SidebarToggle.module.css';
interface SidebarToggleProps extends ComponentProps<'button'> {
    open: boolean;
}

const SidebarToggle = forwardRef<HTMLButtonElement, SidebarToggleProps>(
    ({ open, ...props }, ref) => (
        <button
            ref={ref}
            className={clsx(`${styles.buttonSidebarToggle} no-print focus ring-inset`, { open })}
            {...props}
        >
            <i
                className={`${styles.toggleIcon} ${open ? 'ion-chevron-left' : 'ion-chevron-right'}`}
            />
        </button>
    )
);

SidebarToggle.displayName = 'SidebarToggle';

export default SidebarToggle;
