import { createIconGenerator } from 'lib/util/icon-generator';

import cattleIcon from './icons/cattle.svg?raw';
import poultryIcon from './icons/chickens.svg?raw';
import goatsIcon from './icons/goats.svg?raw';
import horsesIcon from './icons/horses.svg?raw';
import otherIcon from './icons/other.svg?raw';
import pigsIcon from './icons/pigs.svg?raw';
import sheepIcon from './icons/sheep.svg?raw';
import { HerdType } from './Herd';

const herdIconSrc = {
    [HerdType.CATTLE]: cattleIcon,
    [HerdType.SHEEP]: sheepIcon,
    [HerdType.PIGS]: pigsIcon,
    [HerdType.GOATS]: goatsIcon,
    [HerdType.HORSES]: horsesIcon,
    [HerdType.POULTRY]: poultryIcon,
    [HerdType.OTHER]: otherIcon,
};

export const herdIcon = createIconGenerator(herdIconSrc, 'icon');
