import { useTranslation } from 'react-i18next';
import c from 'classnames';

import type { VisibilityIcon } from './visibility-icons';
import visibilityIcon from './visibility-icons';

import 'molecules/bubble-slider.scss';

interface ToggleOption {
    name: string;
    i18nKey: string;
    icon: VisibilityIcon;
}

interface VisibilityToggleProps {
    options?: ToggleOption[];
    active: string;
    onSelect: (name: string) => void;
    className?: string;
}

export const OnOffOptions: ToggleOption[] = [
    {
        name: 'on',
        i18nKey: 'layers_on',
        icon: 'eye-on' as VisibilityIcon,
    },
    {
        name: 'off',
        i18nKey: 'layers_off',
        icon: 'eye-off',
    },
];

export const OnOffBorderOptions: ToggleOption[] = [
    {
        name: 'on',
        i18nKey: 'layers_on',
        icon: 'eye-on',
    },
    {
        name: 'border',
        i18nKey: 'layers_border',
        icon: 'border',
    },
    {
        name: 'off',
        i18nKey: 'layers_off',
        icon: 'eye-off',
    },
];

const VisibilityToggle = ({
    options = OnOffOptions,
    active,
    onSelect,
    className,
}: VisibilityToggleProps) => {
    const { t } = useTranslation();
    const activeOption = options.find((o) => o.name === active);

    return (
        <div className={c('visibility-toggle bubble-slider', className)}>
            <div className="label">{`${t('visibility')}: ${
                activeOption ? t(activeOption.i18nKey) : ''
            }`}</div>
            <div className={c('bubble-container', `options-${options.length}`)}>
                <div
                    className={c(
                        'bubble',
                        `position-${activeOption ? options.indexOf(activeOption) : 0}`
                    )}
                />
                {options.map((option, i) => (
                    <VisibilityToggleOption
                        key={i}
                        option={option}
                        index={i}
                        active={activeOption === option}
                        onSelect={onSelect}
                    />
                ))}
            </div>
        </div>
    );
};

const VisibilityToggleOption = ({
    option,
    index,
    active,
    onSelect,
}: {
    option: ToggleOption;
    index: number;
    active: boolean;
    onSelect: (name: string) => void;
}) => (
    <button
        className={c('bare-btn focus option', `option-${index}`, {
            active,
        })}
        onClick={() => !active && onSelect(option.name)}
    >
        {visibilityIcon(option.icon, active ? 'blue' : 'dark-grey')}
    </button>
);

export default VisibilityToggle;
