import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import c from 'classnames';
import icon from 'components/icons/icon';
import { selectFieldAreaPercentageOfLimit, selectTotalFieldArea } from 'fields/fields-selectors';
import { formatArea } from 'lib/geo/maths';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { renderMeasurementUnitShort } from 'lib/MeasurementUnit';
import type { AppState } from 'system/store';
import UpgradeLink from 'upgrade/UpgradeLink';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import { hectaresToSqm } from 'utils/conversion';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';

interface FieldAreaLimitMessageProps {
    farm: Farm;
    totalFieldArea: number;
    percentageOfLimit: number;
    areaUnit: MeasurementUnit;
}

const FieldAreaLimitMessage = ({
    farm,
    totalFieldArea,
    percentageOfLimit,
    areaUnit,
}: FieldAreaLimitMessageProps) => {
    const { t } = useTranslation();
    const isWarning = percentageOfLimit < 100;
    const fieldAreaSqmLimit =
        farm.plan.fieldAreaHectareLimit !== null
            ? hectaresToSqm(farm.plan.fieldAreaHectareLimit)
            : 0;

    return (
        <div className={c('p-4 text-sm relative', isWarning ? 'bg-warn' : 'bg-error-bg-pale')}>
            {isWarning
                ? icon('error-alert', 'orange', 'absolute w-10 h-10', { top: '33%' })
                : icon('padlock', 'red', 'absolute w-10 h-10', { top: '33%' })}
            <UpgradeLink className="block focus">
                <span className="block text-center mx-auto max-w-3/4">
                    <MarkdownNoPara>
                        {t('you_have_used_ha_included_in_the_free_plan', {
                            sprintf: [
                                formatArea(totalFieldArea, areaUnit),
                                formatArea(fieldAreaSqmLimit, areaUnit),
                                renderMeasurementUnitShort(areaUnit),
                            ],
                        })}
                    </MarkdownNoPara>
                    . <span className="underline">{t('upgrade_for_unlimited_area')}</span>
                </span>
            </UpgradeLink>
        </div>
    );
};

export default connect((state: AppState) => ({
    totalFieldArea: selectTotalFieldArea(state),
    percentageOfLimit: selectFieldAreaPercentageOfLimit(state),
    areaUnit: selectUserAreaMeasurementUnit(state),
}))(FieldAreaLimitMessage);
