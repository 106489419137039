import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import { selectDrawingTool } from 'farm-editing/farm-editing-state';
import { useAppDispatch, useAppSelector } from 'system/store';

import { usePageParams } from './usePageParamas';
import { useSegmentTracking } from './useSegmentTracking';

const useDrawSelectTool = () => {
    const dispatch = useAppDispatch();
    const { farmUuid } = usePageParams();
    const { drawField } = useSegmentTracking();
    const drawingTool = useAppSelector((state) => state.farmEditingState.drawingTool);

    const selectTool = (tool: DrawingTool) => {
        if (drawingTool !== tool) {
            dispatch(selectDrawingTool(tool));
        }
        if (tool) {
            drawField(farmUuid ?? '', tool);
        }
    };

    return {
        selectTool,
        drawingTool,
    };
};

export { useDrawSelectTool };
