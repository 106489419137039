import { useTranslation } from 'react-i18next';
import type { List, Set } from 'immutable';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type { FullOperationField } from 'operations/OperationField';
import { selectFullOperationRecordings } from 'operations/operations-selectors';
import type Recording from 'operations/Recording';
import { useAppSelector } from 'system/store';
import FormattedArea from 'view/units/FormattedArea';
import FormattedRate from 'view/units/FormattedRate';
import FormattedTotal from 'view/units/FormattedTotal';
import ShortInputRate from 'view/units/ShortInputRate';
import ShortUnit from 'view/units/ShortUnit';

import OperationPrintSheetRecordingRow from './OperationPrintSheetRecordingRow';

import './OperationPrintSheet.css';

interface OperationPrintSheetRecordingTableProps {
    operationFields: List<FullOperationField>;
    recordings: Set<Recording>;
    measurementUnit: MeasurementUnit;
}

const OperationPrintSheetRecordingTable = ({
    operationFields,
    recordings,
}: OperationPrintSheetRecordingTableProps) => {
    const { t } = useTranslation();

    const inputs = useAppSelector((state) => state.inputsState.inputs);

    const operationRecordings = selectFullOperationRecordings(inputs, recordings);

    const totalArea = operationFields.reduce((total, { areaSqm }) => total + areaSqm, 0);

    return (
        <table className="OperationPrintSheetTable">
            <thead>
                <tr>
                    <th>{t('field')}</th>
                    <th>{t('usage')}</th>
                    <th>{t('area')}</th>
                    <th>{t('field_details_input')}</th>
                    <th>{t('label_rate', { defaultValue: 'Rate' })}</th>
                    <th>{t('total')}</th>
                </tr>
            </thead>
            <tbody>
                {operationFields.map((opField) => (
                    <OperationPrintSheetRecordingRow
                        key={opField.field.uuid}
                        opField={opField}
                        recordings={operationRecordings}
                    />
                ))}
                <tr>
                    <td colSpan={3}>
                        {t('field_work_area_total')}: <FormattedArea areaSqm={totalArea} withUnit />
                    </td>
                    <td colSpan={3}>{t('label_total_inputs', { defaultValue: 'Total inputs' })}</td>
                </tr>
                {operationRecordings.map((opRec) => {
                    return (
                        <tr key={opRec.recording.uuid}>
                            <td colSpan={3} />
                            <td>{opRec.input.name}</td>
                            <td>
                                <FormattedRate rate={opRec.recording.rate} />{' '}
                                <ShortInputRate input={opRec.input} />
                            </td>
                            <td>
                                <FormattedTotal rate={opRec.recording.rate} areaSqm={totalArea} />{' '}
                                <ShortUnit unit={opRec.input.unit} />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default OperationPrintSheetRecordingTable;
