import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useApplicationLayoutContext } from '@notes-web/layout';
import { useCreateFarm } from 'hooks/useCreateFarm';
import ErrorMessage from 'view/ErrorMessage';

import CreateFarmForm from './CreateFarmForm';
import CreateFarmMap from './CreateFarmMap';

interface CreateFarmContainerProps {
    onSuccess?: Parameters<typeof useCreateFarm>[0]['onSuccess'];
    initialValues?: { farmName: string; externalId?: number };
}

const CreateFarmContainer = ({ onSuccess, initialValues }: CreateFarmContainerProps) => {
    const { t } = useTranslation();
    const { Header } = useApplicationLayoutContext();
    const { onCreateFarm, state, dispatch, isError, isPending } = useCreateFarm({
        onSuccess,
    });

    const createFarmHandler = ({ name }: Parameters<typeof onCreateFarm>['0']) => {
        onCreateFarm({ name, externalId: initialValues?.externalId || null });
    };

    return (
        <div className="CreateFarmContainer">
            <Header />
            <ErrorBoundary
                fallback={
                    <ErrorMessage className="bg-white h-full w-full p-5">
                        {t('create_farm_error', {
                            defaultValue:
                                'Something went wrong and we were unable to create your farm. Please try again or contact customer support.',
                        })}
                    </ErrorMessage>
                }
            >
                <CreateFarmForm
                    onSubmit={createFarmHandler}
                    disabled={isPending}
                    onLocationSearch={(places) =>
                        dispatch({ type: 'searchedPlaces', payload: places })
                    }
                    onGeoLocationLookup={(geoPosition) =>
                        dispatch({ type: 'geoLocationLookup', payload: geoPosition })
                    }
                    farmLocationError={state.farmLocationFieldError}
                    searchedPlaces={state.searchedPlaces}
                    selectedPlaceId={state.selectedPlaceId}
                    createFarmError={isError}
                    initialValues={initialValues}
                />
                <CreateFarmMap
                    farmLocation={state.farmLocation}
                    userLocation={state.userLocation}
                    userGeoPosition={state.userGeoPosition}
                    searchedPlaces={state.searchedPlaces}
                    selectedPlaceId={state.selectedPlaceId}
                    onBasemapClick={(point) => dispatch({ type: 'basemapClick', payload: point })}
                    onFeatureClick={(placeId) =>
                        dispatch({ type: 'featureClick', payload: placeId })
                    }
                />
            </ErrorBoundary>
        </div>
    );
};

export default CreateFarmContainer;
