import { type ComponentProps, forwardRef, type ReactNode } from 'react';
import { Button as _Button } from 'react-aria-components';
import { clsx } from 'clsx';
import { Icon } from 'components/icon';

import styles from './ButtonBase.module.css';

const buttonStyleVariants = {
    primary: `${styles.solid} ${styles['solid-primary']}`,
    danger: `${styles.solid} ${styles['solid-danger']}`,
    ['outline-primary']: `${styles.outline} ${styles['color-outline-primary']}`,
    ['outline-danger']: `${styles.outline} ${styles['color-outline-danger']}`,
    ['default']: '',
} as const;

interface ButtonRootProps extends ComponentProps<typeof _Button> {
    children: ReactNode;
    variant?: keyof typeof buttonStyleVariants;
}

const Root = forwardRef<HTMLButtonElement, ButtonRootProps>(
    ({ className, variant = 'default', ...props }, ref) => {
        return (
            <_Button
                className={clsx(styles.button, buttonStyleVariants[variant], className)}
                ref={ref}
                {...props}
            />
        );
    }
);
Root.displayName = 'Button.Root';

function ButtonIcon({ ...props }: ComponentProps<typeof Icon>) {
    return <Icon {...props} />;
}
ButtonIcon.displayName = 'Button.Icon';

export const ButtonBase = { Root, Icon: ButtonIcon };
