import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import DancingWheat from '@fieldmargin/webapp-styling/components/dancing-wheat';
import { useGetFarmMapQuery } from 'api/farm-map.api';
import { hoverGeoFeatureId } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { useAction } from 'lib/hooks';
import { useAppSelector } from 'system/store';
import ErrorMessage from 'view/ErrorMessage';
import { selectCurrentYear } from 'years/years-state';

import { useFarmMapAttachments } from './hooks/useFarmMapAttachments';
import { useFarmMapAutoBoundaryFeatures } from './hooks/useFarmMapAutoBoundaryFeatures';
import { useFarmMapEditingAttachments } from './hooks/useFarmMapEditingAttachments';
import { useFarmMapEditingFeatures } from './hooks/useFarmMapEditingFeatures';
import { useFarmMapFeatureAdd } from './hooks/useFarmMapFeatureAdd';
import { useFarmMapFeatures } from './hooks/useFarmMapFeatures';
import { useFarmMapFeatureUpdated } from './hooks/useFarmMapFeatureUpdated';
import type { FarmMapFieldFeature } from './hooks/useFarmMapFields';
import { useFarmMapFields } from './hooks/useFarmMapFields';
import { useFarmMapFieldTooltips } from './hooks/useFarmMapFieldTooltips';
import { useFarmMapHerds } from './hooks/useFarmMapHerds';
import { useFarmMapInitials } from './hooks/useFarmMapInitials';
import { useFarmMapItemClick } from './hooks/useFarmMapItemClick';
import { useFarmMapMapView } from './hooks/useFarmMapMapView';
import { useFarmMapNotes } from './hooks/useFarmMapNotes';
import { useFarmMapSensors } from './hooks/useFarmMapSensors';
import { useFarmMapStorePosition } from './hooks/useFarmMapStorePosition';
import { useFarmMapSubFields } from './hooks/useFarmMapSubFields';
import { useFarmMapTiledLayers } from './hooks/useFarmMapTiledLayers';
import FarmMapControls from './FarmMapControls';
import NewFarmMap from './NewFarmMap';
import type { FarmMapFeature } from './types';

import 'components/farm/FarmMapContainer.css';

const FarmMapLoader = () => {
    const { t } = useTranslation();
    const farm = useAppSelector(selectCurrentFarm);
    const year = useAppSelector(selectCurrentYear);
    const mapQuery = useGetFarmMapQuery({ farmUuid: farm.uuid, year });
    const geoJsonFeatures: FarmMapFeature[] = mapQuery.data ?? [];

    const basemap = useAppSelector((state) => state.farmEditingState.baseMap);
    const tiledLayers = useFarmMapTiledLayers();
    const fieldFeatures = useFarmMapFields(geoJsonFeatures);
    const { noteFeatures, noteMarkers } = useFarmMapNotes(geoJsonFeatures);
    const featureFeatures = useFarmMapFeatures(geoJsonFeatures);
    const herdFeatures = useFarmMapHerds(geoJsonFeatures);
    const attachmentFeatures = useFarmMapAttachments(geoJsonFeatures);
    const pointFeatures = featureFeatures.pointFeatures;
    const editingFeatures = useFarmMapEditingFeatures();
    const editingAttachments = useFarmMapEditingAttachments(geoJsonFeatures);
    const fieldTooltipProps = useFarmMapFieldTooltips(geoJsonFeatures);
    const initials = useFarmMapInitials();
    const sensors = useFarmMapSensors();
    const subFields = useFarmMapSubFields();
    const autoBoundaryFeatures = useFarmMapAutoBoundaryFeatures();

    const mainFeatures = noteFeatures
        .concat(featureFeatures.features)
        .concat(attachmentFeatures)
        .concat(autoBoundaryFeatures);

    const handleFeatureClick = useFarmMapItemClick(
        geoJsonFeatures.concat(subFields).concat(autoBoundaryFeatures)
    );
    const handleFeatureAdded = useFarmMapFeatureAdd();
    const handleFeatureUpdated = useFarmMapFeatureUpdated();
    const handleFeatureHover = useAction(hoverGeoFeatureId);
    const viewportAction = useFarmMapMapView(
        mainFeatures
            .concat(fieldFeatures.features as FarmMapFieldFeature[])
            .concat(pointFeatures)
            .concat(herdFeatures)
            .concat(attachmentFeatures)
            .concat(editingFeatures)
            .concat(editingAttachments)
            .concat(initials)
            .concat(sensors)
            .concat(subFields)
    );
    const handleMapMove = useFarmMapStorePosition();

    return (
        <div className="FarmMapContainer">
            {mapQuery.isLoading ? (
                <DancingWheat text={t('loading_farm_map')} />
            ) : (
                <ErrorBoundary
                    fallback={
                        <ErrorMessage className="ErrorMessage">
                            {t('something_went_wrong')}
                        </ErrorMessage>
                    }
                >
                    <NewFarmMap
                        basemap={basemap}
                        tiledLayers={tiledLayers}
                        mainFeatures={mainFeatures}
                        noteMarkers={noteMarkers}
                        fieldFeatures={fieldFeatures}
                        pointFeatures={pointFeatures}
                        editingFeatures={editingFeatures}
                        editingAttachments={editingAttachments}
                        herdFeatures={herdFeatures}
                        fieldTooltipProps={fieldTooltipProps}
                        initials={initials}
                        sensors={sensors}
                        subFields={subFields}
                        viewportAction={viewportAction}
                        events={{
                            onFeatureHover: handleFeatureHover,
                            onFeatureClick: handleFeatureClick,
                            onFeatureAdded: handleFeatureAdded,
                            onFeatureUpdated: handleFeatureUpdated,
                            onMapMove: handleMapMove,
                        }}
                    />
                    <FarmMapControls />
                </ErrorBoundary>
            )}
        </div>
    );
};

export default FarmMapLoader;
