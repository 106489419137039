import configService from 'lib/config';
import { omit, partialRight } from 'ramda';

export type MediaType = 'image' | 'file' | 'pdf';
export type MediaItemType = 'NOTE';

interface MediaThumbnailProps {
    url: string;
    cloudStorageKey: string;
    width: number;
    height: number;
    size: number;
}

export default interface Media {
    id: string;
    createdDate: Date;
    createdByUserId: number;
    mediaType: MediaType;
    key: string;
    url: string;
    fileName: string;
    size: number;
    imageProperties: {
        url: string;
        cloudStorageKey: string;
        width: number;
        height: number;
        size: number;
    };
    thumbnailProperties: null | MediaThumbnailProps[];
}

export interface MediaDTO extends Omit<Media, 'createdDate'> {
    createdDate: string;
}

export interface MediaUploadDTO {
    timestamp: string;
    eventId: string;
    eventType: string;
    aggregateType: MediaItemType;
    aggregateId: string;
    data: {
        '@type': string;
        mediaUuid: string;
        filename: string;
        mimeType: string;
        properties: {
            height: string;
            mime_type: string;
            size: string;
            width: string;
        };
        cloudStoragePath: string;
    };
    auditing: {
        userId: number;
    };
    farmUuid: string;
}

/**
 * Deserializes media DTOs into a Media object.
 */
export const deserializeMedia = (json: MediaDTO): Media => ({
    ...json,
    createdDate: new Date(json.createdDate),
});

/**
 * Wrapper for deserializing media upload DTOs that sets the media API root.
 */
export const deserializeUploadMediaWithApi = (json: MediaUploadDTO): Media =>
    deserializeUploadMedia(json, configService.get('mediaApiRoot'));

/**
 * Deserializes media upload DTOs into a Media object.
 * This always sets thumbnailProperties to null because they have not been generated at this point.
 */
export const deserializeUploadMedia = (json: MediaUploadDTO, mediaApiRoot: string): Media => {
    const url = `${mediaApiRoot}/${json.data.cloudStoragePath}`;
    const size = parseFloat(json.data.properties.size);

    return {
        ...omit(
            [
                'timestamp',
                'auditing',
                'eventId',
                'eventType',
                'aggregateType',
                'aggregateId',
                'data',
                'farmUuid',
            ],
            json
        ),
        id: json.data.mediaUuid,
        createdDate: new Date(json.timestamp),
        createdByUserId: json.auditing.userId,
        mediaType: getMediaTypeFromMimeType(json.data.mimeType),
        key: json.data.cloudStoragePath,
        url,
        fileName: json.data.filename,
        size,
        imageProperties: {
            url,
            cloudStorageKey: json.data.cloudStoragePath,
            width: parseFloat(json.data.properties.width),
            height: parseFloat(json.data.properties.height),
            size,
        },
        thumbnailProperties: null,
    };
};

export const getMediaTypeFromMimeType = (mimeType: string) => {
    if (mimeType.includes('image')) {
        return 'image';
    }
    if (mimeType.includes('pdf')) {
        return 'pdf';
    }
    return 'file';
};

export const getMediaThumbnailUrl = (media: Media, size: number) => {
    const thumbnailProps = media.thumbnailProperties?.find((p) => p.width === size);
    return thumbnailProps !== undefined ? thumbnailProps.url : media.url;
};
export const getMediaThumbnail190Url = partialRight(getMediaThumbnailUrl, [190]);

// Methods for deserializing legacy media from old APIs.
export const LEGACY_MEDIA_TYPES = 'image,file,pdf';
export interface LegacyMediaDTO {
    id: string;
    url: string;
    thumbnail_url: string;
    createdDate: number | null;
    createdByUserId: number;
    size: number;
    mediaType: MediaType;
    fileName: string;
}

export const deserializeLegacyMedia = (json: LegacyMediaDTO): Media => ({
    id: json.id,
    url: json.url,
    createdDate: json.createdDate ? new Date(json.createdDate) : new Date(),
    createdByUserId: json.createdByUserId,
    size: json.size,
    mediaType: json.mediaType,
    // only pdfs or files have names
    fileName: json.fileName,
    key: '',
    imageProperties: {
        url: json.url,
        cloudStorageKey: '',
        width: 0,
        height: 0,
        size: 0,
    },
    thumbnailProperties: null,
});
