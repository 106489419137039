import { selectCurrentYearLimitedActivities } from 'notes/notes-filter';
import { useAppSelector } from 'system/store';

const useCurrentYearLimitedActivities = () => {
    const currentYearLimitedActivities = useAppSelector(selectCurrentYearLimitedActivities);

    return {
        currentYearLimitedActivities,
        totalCurrentYearLimitedActivities: currentYearLimitedActivities.size,
    };
};

export { useCurrentYearLimitedActivities };
