import { isMobile, isTablet, osName } from 'react-device-detect';
import loadable from '@loadable/component';
import MobileAppDownload from 'auth/general/MobileAppDownload';
import PageLoading from 'components/PageLoading';
import { useBoolean } from 'hooks/useBoolean';
import featureFlags from 'lib/feature-flags';

import { AppInitializationProvider } from './AppInitialization';

import './i18n';

const AppLite = loadable(() => import('./AppLite').then((module) => ({ default: module.AppLite })));
const AppFull = loadable(() => import('./AppFull').then((module) => ({ default: module.AppFull })));
const MaintenanceScreen = loadable(() => import('view/system/MaintenanceScreen'));

function AppBase() {
    const { value: webView, setTrue } = useBoolean();
    const renderTablet = isTablet && !webView;

    if (featureFlags.get('maintenanceMode')) {
        return <MaintenanceScreen fallback={<PageLoading />} />;
    }

    if (isMobile && !isTablet) {
        return <AppLite fallback={<PageLoading />} />;
    }

    if (renderTablet) {
        return <MobileAppDownload os={osName} onContinue={setTrue} />;
    }

    return <AppFull fallback={<PageLoading />} />;
}

function App() {
    return (
        <AppInitializationProvider>
            <AppBase />
        </AppInitializationProvider>
    );
}

export { App };
