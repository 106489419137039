import type { ReactNode } from 'react';
import { useApplicationLayoutContext } from '@notes-web/layout';

interface TopNavOutsidePageProps {
    children: ReactNode;
}

const TopNavOutsidePage = ({ children }: TopNavOutsidePageProps) => {
    const { Header } = useApplicationLayoutContext();

    return (
        <div className="text-white">
            <Header />
            <div className="mx-auto mb-8 w-80 pt-8">
                <div>{children}</div>
            </div>
        </div>
    );
};

export default TopNavOutsidePage;
