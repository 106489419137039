import type { MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { clsx } from 'clsx';
import icon from 'components/icons/icon';
import { getFieldArea } from 'fields/Field';
import type { FullOperationField } from 'operations/OperationField';
import type { SingleParamVoidFunction } from 'system/types';
import CircleCheckbox from 'view/form/CircleCheckbox';
import DateAndTimeField from 'view/form/hook/DateAndTimeField';
import NumberInputField from 'view/form/hook/NumberInputField';
import WeatherNoteFormField from 'view/form/WeatherNoteFormField';
import WindDirectionFormField from 'view/form/WindDirectionFormField';
import FormattedArea from 'view/units/FormattedArea';

import CompleteOperationField from './CompleteOperationField';
import type {
    EditOperationFieldsFieldValues,
    EditOperationFieldsFormValues,
} from './EditOperationFields';
import YieldCells from './YieldCells';

interface EditOperationFieldRowProps {
    operationField: FullOperationField;
    selected: boolean;
    toggleSelected: SingleParamVoidFunction<string>;
    includeYield?: boolean;
    onFormFieldChange: (
        fieldUuid: string,
        prop: keyof EditOperationFieldsFieldValues,
        value: any
    ) => void;
    onWorkAreaChange: (fieldUuid: string, value: number) => void;
    onDateChange: (fieldUuid: string, value: Date) => void;
    onCompleteChange: (fieldUuid: string, complete: boolean, completedByUserId?: string) => void;
    onYieldRateChange: (fieldUuid: string, yieldRate: number) => void;
    onYieldTotalChange: (fieldUuid: string, yieldTotal: number) => void;
    onRemoveField: SingleParamVoidFunction<string>;
}

const EditOperationFieldRow = ({
    operationField,
    selected,
    toggleSelected,
    includeYield = false,
    onFormFieldChange,
    onWorkAreaChange,
    onDateChange,
    onCompleteChange,
    onYieldRateChange,
    onYieldTotalChange,
    onRemoveField,
}: EditOperationFieldRowProps) => {
    const handleRemove = (e?: MouseEvent) => {
        if (e) e.preventDefault();
        onRemoveField(operationField.field.uuid);
    };
    const {
        formState: { errors },
    } = useFormContext<EditOperationFieldsFormValues>();

    return (
        <tr>
            <td className={selected ? 'bg-blue-50' : ''}>
                <div className="flex justify-center">
                    <CircleCheckbox
                        id={`${operationField.field.uuid}-selected`}
                        value={operationField.field.uuid}
                        checked={selected}
                        onChange={toggleSelected}
                    />
                </div>
            </td>
            <td className={selected ? 'bg-blue-50' : ''}>{operationField.field.name}</td>
            <td className={selected ? 'bg-blue-50' : ''}>
                <FormattedArea areaSqm={getFieldArea(operationField.field)} />
            </td>
            <td className={selected ? 'bg-blue-50' : ''}>
                <NumberInputField
                    field={`${operationField.field.uuid}.workArea`}
                    validate={(value) => (isNaN(value) ? '' : undefined)}
                    inputClassName={clsx('w-16', {
                        'has-error': errors[`${operationField.field.uuid}`]?.workArea,
                    })}
                    onChange={(value) => onWorkAreaChange(operationField.field.uuid, value)}
                    hideErrorMessage
                />
            </td>
            <td className={selected ? 'bg-blue-50' : ''}>
                <DateAndTimeField
                    field={`${operationField.field.uuid}.startDate`}
                    openDirection="bottom"
                    onChange={onFormFieldChange.bind(null, operationField.field.uuid, 'startDate')}
                    classWithDate="bg-white"
                    withoutIcon
                    dateFormat="dd/MM/yy"
                />
            </td>
            <td className={selected ? 'bg-blue-50' : ''}>
                <DateAndTimeField
                    field={`${operationField.field.uuid}.completedDate`}
                    openDirection="bottom"
                    onChange={onDateChange.bind(null, operationField.field.uuid)}
                    classWithDate="bg-white"
                    withoutIcon
                    dateFormat="dd/MM/yy"
                />
            </td>
            {includeYield && (
                <YieldCells
                    operationField={operationField}
                    onYieldRateChange={onYieldRateChange.bind(null, operationField.field.uuid)}
                    onYieldTotalChange={onYieldTotalChange.bind(null, operationField.field.uuid)}
                    className={selected ? 'bg-blue-50' : ''}
                />
            )}
            <td className={selected ? 'bg-blue-50' : ''}>
                <NumberInputField
                    field={`${operationField.field.uuid}.windSpeed`}
                    onChange={(value) =>
                        onFormFieldChange(operationField.field.uuid, 'windSpeed', value)
                    }
                    inputClassName={clsx('w-16', {
                        'has-error': errors[`${operationField.field.uuid}`]?.windSpeed,
                    })}
                />
            </td>
            <td className={selected ? 'bg-blue-50' : ''}>
                <WindDirectionFormField
                    field={`${operationField.field.uuid}.windDirection`}
                    onChange={onFormFieldChange.bind(
                        null,
                        operationField.field.uuid,
                        'windDirection'
                    )}
                />
            </td>
            <td className={selected ? 'bg-blue-50' : ''}>
                <NumberInputField
                    field={`${operationField.field.uuid}.temperature`}
                    onChange={(value) =>
                        onFormFieldChange(operationField.field.uuid, 'temperature', value)
                    }
                    inputClassName={clsx('w-16', {
                        'has-error':
                            errors[`${operationField.field.uuid}`]?.temperature !== undefined,
                    })}
                />
            </td>
            <td className={selected ? 'bg-blue-50' : ''}>
                <WeatherNoteFormField
                    field={`${operationField.field.uuid}.weatherNote`}
                    onChange={onFormFieldChange.bind(
                        null,
                        operationField.field.uuid,
                        'weatherNote'
                    )}
                />
            </td>
            <td className={selected ? 'bg-blue-50' : ''}>
                <CompleteOperationField
                    operationField={operationField}
                    onCompleteChange={(...args) =>
                        onCompleteChange(operationField.field.uuid, ...args)
                    }
                />
            </td>
            <td className="remove">
                <button className="bare-btn h-4 w-4" onClick={handleRemove}>
                    {icon('close', 'red')}
                </button>
            </td>
        </tr>
    );
};

export default EditOperationFieldRow;
