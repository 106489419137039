// This is state intended to be used by a component and not in the global redux state.

import { allTrue } from 'lib/fp-helpers';
import type {
    PesticideCheckResponse,
    PesticideCheckStatus,
} from 'operations/sidebar/pesticide-checking/PesticideCheck';
import type { CodedItem } from 'system/types';

type PesticideCheckModalType = 'invalid' | 'locked' | 'check' | 'results';
export interface PesticideCheckFilter {
    pest?: string;
    cropStage?: string;
    crop?: CodedItem;
    status?: PesticideCheckStatus[];
}

export interface PesticideCheckState {
    modal?: PesticideCheckModalType;
    filters: PesticideCheckFilter;
    pesticideCheckResponse?: PesticideCheckResponse;
}

export type PesticideCheckStateAction =
    | { type: 'closePesticideCheck' }
    | { type: 'setModal'; payload: PesticideCheckModalType | undefined }
    | { type: 'setPest'; payload: string | undefined }
    | { type: 'setCropStage'; payload: string | undefined }
    | { type: 'setCrop'; payload: CodedItem | undefined }
    | { type: 'setStatus'; payload: PesticideCheckStatus[] | undefined }
    | { type: 'setPesticideCheckResponse'; payload: PesticideCheckResponse | undefined };

// Action creators
export const closePesticideCheck = (): PesticideCheckStateAction => ({
    type: 'closePesticideCheck',
});
export const setModal = (payload?: PesticideCheckModalType): PesticideCheckStateAction => ({
    type: 'setModal',
    payload,
});
export const setPest = (payload?: string): PesticideCheckStateAction => ({
    type: 'setPest',
    payload,
});
export const setCropStage = (payload?: string): PesticideCheckStateAction => ({
    type: 'setCropStage',
    payload,
});
export const setCrop = (payload?: CodedItem): PesticideCheckStateAction => ({
    type: 'setCrop',
    payload,
});
export const setStatus = (payload?: PesticideCheckStatus[]): PesticideCheckStateAction => ({
    type: 'setStatus',
    payload,
});
export const setPesticideCheckResponse = (
    payload?: PesticideCheckResponse
): PesticideCheckStateAction => ({
    type: 'setPesticideCheckResponse',
    payload,
});

export const pesticideCheckReducer = (
    state: PesticideCheckState,
    action: PesticideCheckStateAction
) => {
    switch (action.type) {
        case 'closePesticideCheck': {
            return { modal: undefined, filters: {}, pesticideCheckResponse: undefined };
        }
        case 'setModal': {
            return { ...state, modal: action.payload };
        }
        case 'setPest': {
            return { ...state, filters: { ...state.filters, pest: action.payload } };
        }
        case 'setCropStage': {
            return { ...state, filters: { ...state.filters, cropStage: action.payload } };
        }
        case 'setCrop': {
            return { ...state, filters: { ...state.filters, crop: action.payload } };
        }
        case 'setStatus': {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    status: action.payload?.length === 0 ? undefined : action.payload,
                },
            };
        }
        case 'setPesticideCheckResponse': {
            const modal =
                action.payload !== undefined && pesticideCheckHasNoFilters(action.payload)
                    ? 'results'
                    : state.modal;
            return { ...state, pesticideCheckResponse: action.payload, modal };
        }
        default:
            return state;
    }
};

export const pesticideCheckHasNoFilters = (response: PesticideCheckResponse) =>
    allTrue([
        response.filters.cropStages.length === 0,
        response.filters.pests.length === 0,
        Object.keys(response.filters.crops).length === 0,
    ]);
