import icon from 'components/icons/icon';
import { useDateFormat } from 'hooks/useDateFormat';

interface DueDateProps {
    date: Date;
}

const DueDate = ({ date }: DueDateProps) => {
    const { format } = useDateFormat();
    return (
        <div className="flex">
            <span className="mr-4 flex items-center">
                {icon('date', 'dark-blue', 'mr-2')} {format(date, 'do MMM yyyy')}
            </span>
            <span className="flex items-center">
                {icon('time', 'dark-blue', 'mr-2')} {format(date, 'HH:mm')}
            </span>
        </div>
    );
};

export default DueDate;
