import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import { preventDefaultAnd } from 'lib/dom';
import { getMetricI18nKey } from 'sensors/Metric';
import SidebarModule from 'sidebar/modules/SidebarModule';

interface MetricFormStaticProps {
    name: string;
    type: string;
    unit?: string;
    onEdit: VoidFunction;
    onRemove: VoidFunction;
}

const MetricFormStatic = ({ name, type, unit, onEdit, onRemove }: MetricFormStaticProps) => {
    const { t } = useTranslation();
    const handleEdit = preventDefaultAnd(onEdit);
    const handleRemove = preventDefaultAnd(onRemove);
    return (
        <SidebarModule className="py-2 flex items-center gap-5">
            <span>
                <strong>{name}</strong> - {t(getMetricI18nKey(type))}
                {unit !== undefined && ` / ${unit}`}
            </span>
            <button
                onClick={handleEdit}
                className="ml-auto bare-btn text-fm-sky uppercase focus rounded"
            >
                {t('edit')}
            </button>
            <button onClick={handleRemove} className="bare-btn w-4">
                {icon('close', 'red')}
            </button>
        </SidebarModule>
    );
};

export default MetricFormStatic;
