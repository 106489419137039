import type { FormEvent } from 'react';
import { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { usePromise, useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { deleteAccountApi } from 'auth/account-api';
import OutsidePage from 'auth/OutsidePage';
import { useUserDeletionPermission } from 'hooks/selectors';
import { useCurrentUser } from 'hooks/useCurrentUser';
import Modal from 'modal/Modal';
import ContactSupportLink from 'view/ContactSupportLink';
import ErrorMessage from 'view/ErrorMessage';

const UserDeletePage = () => {
    const { userCanDelete } = useUserDeletionPermission();
    const { currentUser } = useCurrentUser();
    const navigate = useNavigate();
    const { pending, error, setPromise } = usePromise(() => {
        navigate('/account/delete/success');
    });
    const [enteredEmail, setEnteredEmail] = useState('');
    const [showModal, toggleModal] = useToggle(false);

    if (currentUser === null) {
        return <Navigate to="/" />;
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        toggleModal();
    };

    const handleDelete = () => {
        setPromise(deleteAccountApi());
    };

    return (
        <OutsidePage className="text-center">
            {showModal && (
                <Modal onClose={toggleModal} className="modal-bg-full">
                    <div className="bg-white p-6 w-full max-w-[500px] text-center">
                        <h2>Are you sure?</h2>
                        <div className="flex flex-col md:flex-row gap-6 justify-between">
                            <Button variant="outline" onClick={toggleModal}>
                                No, cancel
                            </Button>
                            <Button onClick={handleDelete} disabled={pending} variant="danger">
                                Yes, delete my account
                            </Button>
                        </div>
                        {error && (
                            <ErrorMessage className="mt-6">
                                Something went wrong when deleteing your account, please{' '}
                                <ContactSupportLink>contact us</ContactSupportLink> & we will delete
                                your account for you.
                            </ErrorMessage>
                        )}
                    </div>
                </Modal>
            )}
            <h1 className="text-xl">Delete your account</h1>
            {!userCanDelete ? (
                <>
                    <p>You currently have farms with active subscriptions.</p>

                    <p>
                        Please cancel these subscriptions before deleting your account, or{' '}
                        <ContactSupportLink className="text-white underline">
                            contact support
                        </ContactSupportLink>{' '}
                        for more help.
                    </p>
                    <p>
                        <Link to="/" className="text-white underline">
                            Or return to the app
                        </Link>
                    </p>
                </>
            ) : (
                <>
                    <p>Please confirm your email address to delete your fieldmargin account.</p>
                    <p>
                        Once your account has been deleted there is no way of getting it back and
                        all your data will be lost.
                    </p>
                    <form onSubmit={handleSubmit} className="light mb-6">
                        <input
                            type="text"
                            className="simple-text-input mb-6 w-full placeholder:italic placeholder:text-gray-400"
                            id="email"
                            name="email"
                            placeholder="Confirm your email address"
                            value={enteredEmail}
                            onChange={(e) => setEnteredEmail(e.target.value)}
                        />
                        <SubmitButton
                            variant="danger"
                            disabled={currentUser.email !== enteredEmail}
                        >
                            Delete account
                        </SubmitButton>
                    </form>
                    <p>
                        <Link to="/" className="text-white underline">
                            Or return to the app
                        </Link>
                    </p>
                </>
            )}
        </OutsidePage>
    );
};

export default UserDeletePage;
