import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { AxiosProgressEvent } from 'axios';
import { always, defaultTo } from 'ramda';
import type { SingleParamVoidFunction } from 'system/types';

import type { MediaDTO, MediaItemType, MediaUploadDTO } from './Media';
import type Media from './Media';
import { deserializeMedia, deserializeUploadMediaWithApi } from './Media';

const MEDIA_MAX_LONG_SIDE = 190;

/**
 * Fetch all media for a given item.
 * If the item has no media an empty list will be returned.
 */
export const getItemMedia = (farmUuid: string, itemUuid: string, itemType: MediaItemType) =>
    authorizedRequest<MediaDTO[] | null>({
        url: `/media/farms/${farmUuid}/${itemType}/${itemUuid}`,
        params: {
            maxLongSide: MEDIA_MAX_LONG_SIDE,
        },
    }).then((media) => defaultTo(<Media[]>[], media?.map(deserializeMedia)));

/**
 * Upload media to an item.
 */
export const uploadMediaItem = (
    farmUuid: string,
    itemUuid: string,
    itemType: MediaItemType,
    userId: number,
    file: File,
    onUploadProgress: SingleParamVoidFunction<AxiosProgressEvent>
) => {
    const data = new FormData();
    data.append('aggregateType', itemType);
    data.append('aggregateUuid', itemUuid);
    data.append('userId', userId.toString());
    data.append('file', file);

    return authorizedRequest<MediaUploadDTO>({
        method: 'POST',
        url: `/media/farms/${farmUuid}/upload`,
        data,
        onUploadProgress,
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then(deserializeUploadMediaWithApi);
};

/**
 * Deletes a media item from the server.
 * Returns the deleted media UUID.
 */
export const deleteMediaItem = (farmUuid: string, mediaUuid: string) =>
    authorizedRequest({
        method: 'DELETE',
        url: `/media/farms/${farmUuid}/media/${mediaUuid}`,
    }).then(always(mediaUuid));
