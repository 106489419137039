import { Component } from 'react';
import { connect } from 'react-redux';
import type { NavigateFunction } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeature, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import type MapPosition from 'components/maps/model/MapPosition';
import type MapView from 'components/maps/model/MapView';
import type { MapData } from 'components/maps/openlayers/LegacyMapAdapter';
import OpenLayersMapWrapper from 'components/maps/openlayers/OpenLayersMapWrapper';
import type { AttachmentItem } from 'farm-editing/attachments';
import { AttachmentType } from 'farm-editing/attachments';
import {
    addEditingGeoFeature,
    EditingType,
    hoverGeoFeatureId,
    selectDrawingTool,
    selectEditingAttachmentsByType,
    selectIsSelectingFeatures,
    selectIsSelectingFields,
    setEditingAttachments,
    storeMapPosition,
    toggleEditingAttachment,
    toggleEditingAttachmentByType,
    updateEditingGeoFeature,
} from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type Feature from 'features/Feature';
import { selectFilteredFeatures } from 'features/features-selectors';
import type Field from 'fields/Field';
import { selectFilteredFieldsWithParents } from 'fields/fields-selectors';
import { toggleSubFieldSelectedFromMap } from 'fields/sub-fields-state';
import type Herd from 'herd/Herd';
import type { List } from 'immutable';
import { Map, Set } from 'immutable';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { debounce } from 'lodash';
import { setTilesError, setTilesLoading } from 'maps/farm/maps-state';
import type Note from 'notes/Note';
import { selectFilteredNoteSummaries } from 'notes/notes-filter';
import { selectNote } from 'notes/notes-selectors';
import { bindActionCreators } from 'redux';
import { stopDrawingAction } from 'store/draw.slice';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { rememberCurrentLocation } from 'system/ui-state';
import type { ActiveSection } from 'system/url-util';
import { selectUserAreaMeasurementUnit, selectUserLengthMeasurementUnit } from 'users/user-state';

import { selectFullExtent, selectGeoFeaturesToRender, selectMapData } from './select-overall';

import styles from './FarmMap.module.css';

const findItemWithGeoFeature = (items: List<Feature | Note>, id: string) => {
    return items.find((item) => {
        const geoFeature = item.geoJson.features.find((geoFeature) => {
            return geoFeature.id === id;
        });
        return geoFeature ? true : false;
    });
};

export interface ParentProps {
    activeSection: ActiveSection;
    selectedFeatureUuid?: string;
    selectedFieldUuid?: string;
    selectedNoteUuid?: string;
    selectedOperationUuid?: string;
    selectedHerdUuid?: string;
    selectedManualSensorId?: string;
    isEditing: boolean;
}

export interface ReduxProps {
    navigate: NavigateFunction;
    state: AppState;
    farm: Farm;
    mapData: MapData;

    features: List<Feature>;

    fields: List<Field>;

    notes: List<Note>;

    herds: Herd[] | null;

    selectingMultipleAttachmentType: AttachmentType | null;
    selectingSingleAttachmentType: AttachmentType | null;
    isSelectingFields: boolean;
    isSelectingFeatures: boolean;
    editingAttachments: Set<AttachmentItem>;
    setEditingAttachments: typeof setEditingAttachments;
    toggleEditingAttachment: typeof toggleEditingAttachment;

    toggleEditingHerdUuid: SingleParamVoidFunction<string>;

    toggleSubFieldSelectedFromMap: typeof toggleSubFieldSelectedFromMap;

    mapView: MapView;
    lastMapPosition: MapPosition | null;
    storeMapPosition: typeof storeMapPosition;

    editingGeoFeatureCollection: GeoFeatureCollection | null;
    addEditingGeoFeature: typeof addEditingGeoFeature;
    updateEditingGeoFeature: typeof updateEditingGeoFeature;
    hoverGeoFeatureId: typeof hoverGeoFeatureId;

    selectDrawingTool: typeof selectDrawingTool;
    stopDrawingAction: typeof stopDrawingAction;

    areaUnit: MeasurementUnit;
    lengthUnit: MeasurementUnit;

    rememberCurrentLocation: VoidFunction;

    setTilesLoading: (loading: boolean) => void;
    setTilesError: (error: boolean) => void;

    declutterFieldNames: boolean;
}

export type FarmMapProps = ParentProps & ReduxProps;

class FarmMap extends Component<FarmMapProps> {
    private lastBasemap: string;

    constructor(props: FarmMapProps) {
        super(props);

        this.handleClusterClick = this.handleClusterClick.bind(this);
        this.handleGeoFeatureClick = this.handleGeoFeatureClick.bind(this);
        this.handleGeoFeatureHover = this.handleGeoFeatureHover.bind(this);
        this.handleGeoFeatureAdd = this.handleGeoFeatureAdd.bind(this);
        this.handleGeoFeatureUpdate = this.handleGeoFeatureUpdate.bind(this);
        this.handleMapClick = this.handleMapClick.bind(this);
        this.handleMapMove = this.handleMapMove.bind(this);
        this.handleTiledLayersLoading = debounce(this.handleTiledLayersLoading.bind(this), 100);
        this.handleTiledLayersError = debounce(this.handleTiledLayersError.bind(this), 100);
    }

    render() {
        const {
            mapData,
            editingGeoFeatureCollection,
            selectedNoteUuid,
            selectedFeatureUuid,
            selectedFieldUuid,
            selectedManualSensorId,
        } = this.props;

        // When one item is selected, dim the others
        const dimShapes =
            !!editingGeoFeatureCollection ||
            !!selectedNoteUuid ||
            !!selectedFeatureUuid ||
            !!selectedFieldUuid ||
            !!selectedManualSensorId;

        const geoFeatures = selectGeoFeaturesToRender(this.props, dimShapes);

        let geoFeatureMap = Map<string, GeoFeature>();
        geoFeatures.forEach((geoFeature) => {
            geoFeatureMap = geoFeatureMap.set(geoFeature.id.toString(), geoFeature);
        });

        let mapView = this.props.mapView;
        if (mapView.type === 'full-extent') {
            mapView = mapView.set('type', 'extent').set('payload', selectFullExtent(this.props));
        }

        if (mapData.basemap && mapData.basemap !== this.lastBasemap) {
            this.lastBasemap = mapData.basemap;
        }

        return <div className={styles.farmMap}>{this.renderMap(mapView, geoFeatureMap)}</div>;
    }

    renderMap(mapView: MapView, geoFeatureMap: Map<string, GeoFeature>) {
        const { farm, mapData, areaUnit, lengthUnit, declutterFieldNames } = this.props;

        return (
            <OpenLayersMapWrapper
                mapData={mapData}
                defaultPoint={farm.geoJsonPoint}
                geoFeatureMap={geoFeatureMap}
                mapView={mapView}
                onGeoFeatureClick={this.handleGeoFeatureClick}
                onClusterClick={this.handleClusterClick}
                onGeoFeatureHover={this.handleGeoFeatureHover}
                onGeoFeatureAdd={this.handleGeoFeatureAdd}
                onGeoFeatureUpdate={this.handleGeoFeatureUpdate}
                onMapClick={this.handleMapClick}
                onMapMove={this.handleMapMove}
                onTiledLayersLoading={this.handleTiledLayersLoading}
                onTiledLayersError={this.handleTiledLayersError}
                lengthUnits={lengthUnit}
                areaUnits={areaUnit}
                declutterFieldNames={declutterFieldNames}
            />
        );
    }

    handleGeoFeatureHover(id: string) {
        this.props.hoverGeoFeatureId(id);
    }

    handleClusterClick(features: any[]) {
        const { navigate, farm, herds } = this.props;
        if (features.length === 0) {
            return false;
        }

        const feature = features[0];
        if (feature.get('type') === 'herd' && herds) {
            const herdUuid = feature.getId().split(':')[0];
            const herd = herds.find((herd) => herd.uuid === herdUuid);
            if (herd && herd.herdLocation && herd.herdLocation.fieldUuid) {
                navigate(`/farms/${farm.uuid}/fields/${herd.herdLocation.fieldUuid}`);
                return true;
            }
        }

        return false;
    }

    /**
     * - If a note, field, sub field, feature or sensor is being edited then do nothing
     * - However, if a note is being edited and fields are being attached, allow fields to be
     *   clicked and add/remove them to note fields
     * - Otherwise go to the note, field, feature or sensor that is clicked
     */
    handleGeoFeatureClick(id: string, type: string) {
        const {
            activeSection,
            isEditing,
            selectingMultipleAttachmentType,
            selectingSingleAttachmentType,
            isSelectingFields,
            rememberCurrentLocation,
        } = this.props;
        if (isEditing && !selectingMultipleAttachmentType && !selectingSingleAttachmentType) {
            // We don't adjust the view during an edit unless the edit involves attaching notes to fields
            return;
        }

        if (activeSection.sub === 'sub-fields' && type !== 'sub-field') {
            return;
        }

        if (
            type !== 'sub-field' &&
            !selectingMultipleAttachmentType &&
            !selectingSingleAttachmentType
        ) {
            // Don't remember location if we're editing sub-fields or attaching items as it will end
            // up taking the user back to the edit or new note view
            rememberCurrentLocation();
        }

        if (type === 'feature') {
            this.handleFeatureClick(id);
        } else if (type === 'auto-boundary') {
            this.handleAutoBoundaryClick(id);
        } else if (type === 'field' || isSelectingFields) {
            this.handleFieldClick(id);
        } else if (type === 'sub-field') {
            this.handleSubFieldClick(id);
        } else if (type === 'note') {
            this.handeleNoteClick(id);
        } else if (type === 'herd') {
            this.handleHerdClick(id);
        }
    }

    handleFeatureClick(id: string) {
        const { navigate, farm, features, isSelectingFeatures, toggleEditingAttachment } =
            this.props;
        const selectedFeature = findItemWithGeoFeature(features, id);
        if (!selectedFeature) {
            return;
        }

        if (isSelectingFeatures) {
            toggleEditingAttachment({ type: AttachmentType.FEATURE, id: selectedFeature.uuid });
            return;
        }

        navigate(`/farms/${farm.uuid}/features/${selectedFeature.uuid}`);
    }

    handleFieldClick(id: string) {
        const {
            navigate,
            farm,
            fields,
            selectingMultipleAttachmentType,
            selectingSingleAttachmentType,
        } = this.props;
        const selectedField = fields.find(
            (field) => field.geoJson?.features.first<GeoFeature>()?.id === id
        );

        if (!selectedField) {
            return;
        }

        if (selectingMultipleAttachmentType === AttachmentType.FIELD) {
            this.addOrRemoveEditingField(selectedField);
            return;
        }

        if (selectingSingleAttachmentType === AttachmentType.FIELD) {
            this.setEditingField(selectedField);
            return;
        }

        navigate(`/farms/${farm.uuid}/fields/${selectedField.parentUuid ?? selectedField.uuid}`);
    }

    handleSubFieldClick(id: string) {
        const { toggleSubFieldSelectedFromMap } = this.props;
        toggleSubFieldSelectedFromMap(id);
    }

    handleAutoBoundaryClick(id: string) {
        const { toggleEditingAttachment } = this.props;
        toggleEditingAttachment({ id, type: AttachmentType.FIELD });
    }

    addOrRemoveEditingField(field: Field) {
        const { toggleEditingAttachment } = this.props;
        toggleEditingAttachment({ id: field.uuid, type: AttachmentType.FIELD });
    }

    setEditingField(field: Field) {
        const { editingAttachments, setEditingAttachments } = this.props;

        if (editingAttachments.find(({ id }) => id === field.uuid)) {
            setEditingAttachments(editingAttachments.clear());
        } else {
            setEditingAttachments(Set.of({ type: AttachmentType.FIELD, id: field.uuid }));
        }
    }

    handeleNoteClick(id: string) {
        const { navigate, farm, notes } = this.props;
        const selectedNote = findItemWithGeoFeature(notes, id);
        if (selectedNote) {
            navigate(`/farms/${farm.uuid}/notes/${selectedNote.uuid}`);
        }
    }

    handleHerdClick(id: string) {
        const { navigate, farm, herds, selectingMultipleAttachmentType, toggleEditingHerdUuid } =
            this.props;

        if (!herds) {
            return;
        }

        const herdUuid = id.split(':')[0];
        if (!herdUuid) {
            return;
        }

        const herd = herds.find((h) => h.uuid === herdUuid);
        if (herd) {
            selectingMultipleAttachmentType === AttachmentType.HERD
                ? toggleEditingHerdUuid(herd.uuid)
                : navigate(`/farms/${farm.uuid}/livestock/${herd.uuid}`);
        }
    }

    handleGeoFeatureAdd(geoFeature: GeoFeature) {
        const { addEditingGeoFeature, selectDrawingTool, stopDrawingAction } = this.props;
        addEditingGeoFeature(geoFeature);
        selectDrawingTool(null);
        stopDrawingAction();
    }

    handleGeoFeatureUpdate(geoFeature: GeoFeature) {
        const { updateEditingGeoFeature } = this.props;
        updateEditingGeoFeature(geoFeature);
    }

    handleMapClick() {
        const {
            navigate,
            farm,
            selectedFeatureUuid,
            selectedFieldUuid,
            selectedNoteUuid,
            isEditing,
            activeSection,
        } = this.props;
        if (isEditing || activeSection.sub === 'sub-fields') {
            // We don't adjust the view during an edit
            return;
        }
        if (selectedFeatureUuid) {
            navigate(`/farms/${farm.uuid}/features`);
        } else if (selectedFieldUuid) {
            navigate(`/farms/${farm.uuid}/fields`);
        } else if (selectedNoteUuid) {
            navigate(`/farms/${farm.uuid}/notes`);
        }
    }

    handleMapMove(mapPosition: MapPosition) {
        const { storeMapPosition } = this.props;
        storeMapPosition(mapPosition);
    }

    handleTiledLayersLoading(loadingLayers: string[]) {
        const { setTilesLoading } = this.props;
        setTilesLoading(loadingLayers.length > 0);
    }

    handleTiledLayersError(errorLayers: string[]) {
        const { setTilesError } = this.props;
        setTilesError(errorLayers.length > 0);
    }
}

export default connect(
    (
        state: AppState,
        { activeSection, selectedNoteUuid, selectedHerdUuid, selectedManualSensorId }: ParentProps
    ) => {
        const selectedNote =
            selectedNoteUuid !== undefined ? selectNote(state, selectedNoteUuid) : undefined;
        return {
            state,
            farm: selectCurrentFarm(state),

            mapData: selectMapData(state, activeSection, {
                selectedNoteUuid,
                selectedHerdUuid,
                selectedManualSensorId,
            }),

            features: selectFilteredFeatures(state),

            fields: selectFilteredFieldsWithParents(state),

            notes: selectFilteredNoteSummaries(state),

            herds: state.herdsState.herds,

            selectingMultipleAttachmentType: state.farmEditingState.selectingMultipleAttachmentType,
            selectingSingleAttachmentType: state.farmEditingState.selectingSingleAttachmentType,
            isSelectingFields: selectIsSelectingFields(state),
            isSelectingFeatures: selectIsSelectingFeatures(state),
            editingAttachments:
                state.farmEditingState.editingType === EditingType.NOTE ||
                state.farmEditingState.selectingMultipleAttachmentType === AttachmentType.FIELD
                    ? selectEditingAttachmentsByType(state, AttachmentType.FIELD)
                    : selectedNote
                      ? Set(
                            selectedNote.fieldUuids.map((uuid) => ({
                                type: AttachmentType.FIELD,
                                id: uuid,
                            }))
                        )
                      : Set<AttachmentItem>(),

            mapView: state.farmEditingState.mapView,
            lastMapPosition: state.farmEditingState.lastMapPosition,

            editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,

            areaUnit: selectUserAreaMeasurementUnit(state),
            lengthUnit: selectUserLengthMeasurementUnit(state),

            declutterFieldNames: state.fieldsState.declutterFieldNames,
        };
    },
    (dispatch) =>
        bindActionCreators(
            {
                stopDrawingAction,
                addEditingGeoFeature,
                updateEditingGeoFeature,
                selectDrawingTool,
                storeMapPosition,
                toggleEditingAttachment,
                setEditingAttachments,
                rememberCurrentLocation,
                setTilesLoading,
                setTilesError,
                hoverGeoFeatureId,
                toggleSubFieldSelectedFromMap,
                toggleEditingHerdUuid: toggleEditingAttachmentByType(AttachmentType.HERD),
            },
            dispatch
        )
)(FarmMap);
