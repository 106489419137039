import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise, useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import Promise from 'bluebird';
import classNames from 'classnames';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { getFieldWorkedArea, isSubField } from 'fields/Field';
import {
    selectMaybeVisibleMaybeArchivedFieldsWithParents,
    selectMaybeVisibleMaybeArchivedFieldUsagesWithFields,
} from 'fields/fields-selectors';
import type { FieldsSortBy } from 'fields/fields-state';
import { updateAndRemoveFields } from 'fields/fields-state';
import { List } from 'immutable';
import { getUuid, notNil } from 'lib/fp-helpers';
import { areaAsSqm, formatArea } from 'lib/geo/maths';
import { partialRight } from 'ramda';
import { bindActionCreators } from 'redux';
import CreateHeader from 'sidebar/header/CreateHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import { useAppDispatch } from 'system/store';
import { changeModal } from 'system/ui-state';
import UnsavedHookFormChangesChecker from 'unsaved-changes/UnsavedHookFormChangesChecker';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import ErrorMessage from 'view/ErrorMessage';
import { selectCurrentYear } from 'years/years-state';

import FieldSearchBar from '../FieldSearchBar';
import FieldSortAndFilter from '../FieldSortAndFilter';

import { deleteBulkEditField, saveBulkEditField } from './bulk-field-edit-utils';
import BulkFieldEditDeleteWarning from './BulkFieldEditDeleteWarning';
import FieldEditAlphabetical from './FieldEditAlphabetical';
import FieldEditByUsage from './FieldEditByUsage';

import './BulkFieldEdit.css';
import styles from './BulkField.module.css';

interface BulkFieldEditProps {
    farm: Farm;
    year: number;
    fields: List<Field>;
    formValues: BulkFieldEditFormValues;
    sortBy: FieldsSortBy;
    areaAsSqm: (area: number) => number;
    updateAndRemoveFields: typeof updateAndRemoveFields;
}

export interface BulkFieldEditFieldValues {
    name: string;
    id: string;
    workArea: number;
    archived: boolean;
    remove: boolean;
}
export interface BulkFieldEditFormValues {
    fields: {
        [k: string]: BulkFieldEditFieldValues;
    };
}

function HeaderActions({ children }: PropsWithChildren) {
    return (
        <SidebarModule className={styles.actionsHeader} editing>
            {children}
        </SidebarModule>
    );
}

const BulkFieldEdit = ({
    farm,
    year,
    fields,
    formValues,
    sortBy,
    areaAsSqm,
    updateAndRemoveFields,
}: BulkFieldEditProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pending, error, setPromise } = usePromise<{ saved: Field[]; deleted: Field[] }>(
        (fields) => {
            showDeleteWarning && toggleDeleteWarning();
            updateAndRemoveFields({
                update: List(fields.saved),
                remove: List(fields.deleted.map(getUuid)),
            });
            navigate(`/farms/${farm.uuid}/fields`, {
                state: {
                    ignoreUnsavedChanges: true,
                },
            });
        }
    );
    const methods = useForm<BulkFieldEditFormValues>({
        mode: 'onChange',
        defaultValues: formValues,
    });

    const [showDeleteWarning, toggleDeleteWarning] = useToggle(false);

    useEffect(() => {
        methods.reset(formValues, {
            keepDirty: true,
            keepDirtyValues: true,
            keepErrors: true,
        });
    }, [formValues]);

    const handleSubmit = (values: BulkFieldEditFormValues) => {
        const fieldEntries = Object.entries(values.fields);
        const toUpdate = fieldEntries.filter(([_, data]) => !data.remove);
        const toRemove = fieldEntries.filter(([_, data]) => data.remove);

        if (toRemove.length > 0 && !showDeleteWarning) {
            toggleDeleteWarning();
            return;
        }

        const saveField = partialRight(saveBulkEditField, [farm, fields, areaAsSqm, year]);
        const deleteField = partialRight(deleteBulkEditField, [farm, fields]);
        setPromise(
            Promise.props({
                saved: Promise.all(
                    toUpdate.map(([fieldUuid, data]) => saveField(fieldUuid, data)).filter(notNil)
                ),
                deleted: Promise.all(
                    toRemove.map(([fieldUuid, _]) => deleteField(fieldUuid)).filter(notNil)
                ),
            })
        );
    };

    return (
        <FormProvider {...methods}>
            {showDeleteWarning && (
                <BulkFieldEditDeleteWarning
                    onConfirm={handleSubmit.bind(null, methods.getValues())}
                    onCancel={toggleDeleteWarning}
                    fields={fields}
                    toDelete={Object.entries(methods.getValues().fields)
                        .filter(([_, data]) => data.remove)
                        .map(([uuid, _]) => uuid)}
                    pending={pending}
                    error={error}
                />
            )}
            <UnsavedHookFormChangesChecker
                copy={[
                    t('field_bulk_edit_unsaved_changes1'),
                    t('field_bulk_edit_unsaved_changes2'),
                ]}
            >
                <form className="scrollable" onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div className="non-scrolling">
                        <CreateHeader
                            isSaving={pending}
                            backPath={`/farms/${farm.uuid}/fields`}
                            backState={{ ignoreUnsavedChanges: true }}
                            canSave={methods.formState.isDirty}
                        />
                        <HeaderActions>
                            <FieldSearchBar />
                            <FieldSortAndFilter />
                            {methods.formState.errors.fields !== undefined && (
                                <ErrorMessage className="mt-3">
                                    {t('field_bulk_edit_name_validation')}
                                </ErrorMessage>
                            )}
                        </HeaderActions>

                        <HeaderActions>
                            <Button
                                as={Link}
                                to={`/farms/${farm.uuid}/fields/usage`}
                                variant="outline"
                            >
                                {t('label_set_usage')}
                            </Button>

                            <Button
                                onClick={() =>
                                    dispatch(
                                        changeModal({
                                            name: 'copy-field-subfield',
                                        })
                                    )
                                }
                                variant="outline"
                                type="button"
                            >
                                {t('copy_previous_year')}
                            </Button>
                        </HeaderActions>

                        {error && (
                            <SidebarError
                                title={t('failed_to_save')}
                                message={t('something_went_wrong')}
                            />
                        )}
                    </div>
                    <div className="scrolling">
                        <table className={classNames('BulkFieldEdit', `BulkFieldEdit-${sortBy}`)}>
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>{t('label_mapped_area')}</th>
                                    <th>{t('output_rate_area')}</th>
                                    <th>{t('archive')}</th>
                                    <th>{t('label_delete')}</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortBy === 'usage' && <FieldEditByUsage />}
                                {sortBy === 'alphabetically' && <FieldEditAlphabetical />}
                            </tbody>
                        </table>
                    </div>
                </form>
            </UnsavedHookFormChangesChecker>
        </FormProvider>
    );
};

export default connect(
    (state: AppState) => {
        const year = selectCurrentYear(state);
        const fields =
            state.fieldsState.sortBy === 'usage'
                ? selectMaybeVisibleMaybeArchivedFieldUsagesWithFields(state).flatMap(
                      ({ fields }) => fields
                  )
                : selectMaybeVisibleMaybeArchivedFieldsWithParents(state);
        const areaMeasurementUnit = selectUserAreaMeasurementUnit(state);
        return {
            farm: selectCurrentFarm(state),
            year,
            fields,
            formValues: {
                fields: fields.reduce((values, field) => {
                    values[field.uuid] = {
                        name: isSubField(field) ? field.originalName : field.name,
                        id: field.fieldId,
                        workArea: formatArea(getFieldWorkedArea(field, year), areaMeasurementUnit),
                        archived: field.archived,
                        remove: false,
                    };
                    return values;
                }, {}),
            },
            sortBy: state.fieldsState.sortBy,
            areaAsSqm: partialRight(areaAsSqm, [selectUserAreaMeasurementUnit(state)]),
        };
    },
    (dispatch) => bindActionCreators({ updateAndRemoveFields }, dispatch)
)(BulkFieldEdit);
