import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useToggle } from '@fieldmargin/webapp-state';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type FullOperation from 'operations/FullOperation';
import ActionMenuItem from 'sidebar/modules/action-menu/ActionMenuItem';
import { useAppSelector } from 'system/store';
import FeatureLockedModal from 'view/modal/FeatureLockedModal';

interface OperationPrintButtonProps {
    fullOperation: FullOperation;
}

const OperationPrintButton = ({ fullOperation }: OperationPrintButtonProps) => {
    const { t } = useTranslation();
    const farm = useAppSelector(selectCurrentFarm);
    const [showUpgradeModal, toggleUpgradeModal] = useToggle(false);
    const checkAllowed = (e: MouseEvent) => {
        if (!farm.plan.domainPrinting) {
            e.preventDefault();
            !showUpgradeModal && toggleUpgradeModal();
        }
    };

    return (
        <>
            {showUpgradeModal && (
                <FeatureLockedModal
                    onClose={toggleUpgradeModal}
                    header={t('field_job_printing_upgrade')}
                />
            )}
            <ActionMenuItem>
                <Link
                    to={`/farms/${farm.uuid}/operations/${fullOperation.uuid}/job-sheet`}
                    onClick={checkAllowed}
                >
                    {icon('print', 'dark-blue')} {t('print')}
                </Link>
            </ActionMenuItem>
        </>
    );
};

export default OperationPrintButton;
