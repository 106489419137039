import type Note from 'notes/Note';
import { useUpdateNote } from 'notes/note-hooks';
import EditableYear from 'sidebar/modules/year/EditableYear';

interface EditableNoteYearProps {
    // From parent
    note: Note;
}
const EditableNoteYear = ({ note }: EditableNoteYearProps) => {
    const updateNote = useUpdateNote(note);
    const handleSaveYear = (newYear: number) => updateNote({}, newYear);

    return <EditableYear value={note.year} onSave={handleSaveYear} />;
};

export default EditableNoteYear;
