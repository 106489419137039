import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { UIContext } from 'system/ui-state';
import InfoMsg from 'view/InfoMsg';
import Arrow from 'view/svg/Arrow';

import Tab from './Tab';
import TutorialsTab from './TutorialsTab';

import './TabBar.css';

const tabs = [
    {
        path: '/notes',
        icon: 'activity',
        label: 'dashboard_activity',
    },
    {
        path: '/fields',
        icon: 'fields',
        label: 'fields',
    },
    {
        path: '/livestock',
        icon: 'livestock',
        label: 'livestock',
    },
    {
        path: '/features',
        icon: 'features',
        label: 'features',
    },
    {
        path: '/maps',
        icon: 'maps',
        label: 'header_maps',
    },
    {
        path: '/team',
        icon: 'team',
        label: 'team',
    },
    {
        path: '/vehicles',
        icon: 'vehicleTracking',
        label: 'vehicles',
    },
    {
        path: '/data',
        icon: 'data',
        label: 'menu_data',
    },
    {
        path: '/reporting',
        icon: 'reporting',
        label: 'reporting',
    },
    {
        path: '/billing',
        icon: 'billing',
        label: 'menu_billing',
    },
] as const;

const TabBar = () => {
    const { t } = useTranslation();
    const { infoOpen } = useContext(UIContext);

    return (
        <div className={c('TabBar no-print no-translate', { forefront: infoOpen })}>
            {infoOpen && (
                <>
                    <InfoMsg className="top-16 -right-40">
                        <Arrow className="w-12 rotate-120 flip-y mr-3" />
                        {t('info_menu')}
                    </InfoMsg>

                    <InfoMsg className="bottom-4 left-32 w-52 flex-col">
                        {t('info_tutorials')}
                        <Arrow className="w-12 self-start mt-3 rotate-90 flip-y mr-3" />
                    </InfoMsg>
                </>
            )}

            {tabs.map((tab) => (
                <Tab key={tab.label} path={tab.path} icon={tab.icon}>
                    {t(tab.label)}
                </Tab>
            ))}

            <TutorialsTab />
        </div>
    );
};

export default TabBar;
