import { createLogger } from '@fieldmargin/webapp-reporting';
import { createAction } from '@fieldmargin/webapp-state';
import Promise from 'bluebird';
import { DiscussionItemType } from 'discussions/Discussion';
import { getItemDiscussions } from 'discussions/discussions-api';
import { setItemDiscussions } from 'discussions/discussions-state';
import { getItemMedia } from 'media/media-api';
import { setItemMedia } from 'media/media-state';
import type { AppDispatch, AppState } from 'system/store';
import { selectUserId } from 'users/user-state';
import { selectCurrentYear } from 'years/years-state';

import { setNote } from './note-actions';
import { getNote } from './notes-api_new';
import type NotesState from './notes-state';

const logger = createLogger('notes.note-websocket-state');

export const wsNoteDeleted = createAction<NotesState, string>(
    'Notes: delete note from websocket',
    (state, payload) => state.removeIn(['notes', payload])
);

export const wsNoteCreated = (farmUuid: string, noteUuid: string, userId: number) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const currentUserId = selectUserId(state);
        const year = selectCurrentYear(state);
        if (farmUuid !== state.farmsState.activeFarmUuid || currentUserId === userId) {
            return;
        }

        // Update note list
        getNote(farmUuid, year, noteUuid)
            .then((newNote) => {
                if (newNote.farmUuid === getState().farmsState.activeFarmUuid) {
                    dispatch(setNote(newNote));
                }
                return null;
            })
            .catch((e) => {
                logger.error('Unable to add new note from websocket', e);
                return null;
            });
    };
};

export const wsNoteUpdated = (farmUuid: string, noteUuid: string, userId: number) => {
    // Refresh note & its media and discussions.
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const state = getState();
        const currentUserId = selectUserId(state);
        const year = selectCurrentYear(state);
        if (farmUuid !== state.farmsState.activeFarmUuid || currentUserId === userId) {
            return;
        }

        getNote(farmUuid, year, noteUuid)
            .then((note) => {
                dispatch(setNote(note));
                return Promise.props({
                    media: getItemMedia(farmUuid, noteUuid, 'NOTE'),
                    discussions: getItemDiscussions(farmUuid, noteUuid, DiscussionItemType.NOTE),
                }).then(({ media, discussions }) => {
                    dispatch(setItemMedia({ uuid: note.uuid, media }));
                    dispatch(
                        setItemDiscussions({
                            uuid: note.uuid,
                            discussions,
                        })
                    );
                });
            })
            .catch((e) => {
                logger.error('Unable to refresh note from websocket', e);
                return null;
            });
    };
};

export const noteWebSocketReducers = [wsNoteDeleted];
