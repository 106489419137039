import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderDeleteLink from 'sidebar/modules/header/SidebarHeaderDeleteLink';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarHeaderMenu from 'sidebar/modules/header/SidebarHeaderMenu';
import type { Item } from 'system/types';
import Fog from 'view/Fog';

interface EditHeaderProps {
    // From parent
    item: Item;
    backPath: string;
    deletePath: string;
    menuOpen: boolean;
    onToggleHeaderMenu: VoidFunction;
    children?: ReactNode;
}

const EditHeader = ({
    item,
    backPath,
    deletePath,
    menuOpen,
    onToggleHeaderMenu,
    children,
}: EditHeaderProps) => {
    const { t } = useTranslation();
    const fog = menuOpen ? (
        <Fog blue onClick={onToggleHeaderMenu} className="w-3/4" style={{ bottom: -1 }} />
    ) : null;

    return (
        <SidebarHeader className="relative">
            {fog}
            <SidebarHeaderLink returning to={backPath}>
                {icon('back', 'dark-blue', 'mr-3')} {t('back')}
            </SidebarHeaderLink>
            <SidebarHeaderMenu open={menuOpen} onToggleOpen={onToggleHeaderMenu}>
                {children}
                <SidebarHeaderDeleteLink item={item} to={deletePath} />
            </SidebarHeaderMenu>
        </SidebarHeader>
    );
};

export default EditHeader;
