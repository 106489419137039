import { extentIntersectsGeo } from '@fieldmargin/webapp-geo';
import { selectCurrentFarm } from 'farms/farms-state';
import { uuidMatches } from 'lib/fp-helpers';
import { compareName, stringContains } from 'lib/util/text';
import { groupBy, map, pipe, prop, sort, sortBy, takeLast } from 'ramda';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

import { selectHerdField } from './sidebar/herd-ui-utils';
import type { HerdType } from './Herd';
import type Herd from './Herd';
import type { HerdsByStatusAndType } from './herd-types';

export const selectHerd = createSelector(
    (_state: AppState, herdUuid: string) => herdUuid,
    (state) => state.herdsState.herds,
    (herdUuid, herds) => herds?.find(uuidMatches(herdUuid))
);

export const selectHerdsSortedByName = createSelector(
    (state: AppState) => state.herdsState.herds,
    (herds) => (herds !== null ? sort(compareName, herds) : herds)
);

export const selectHerdsFromUuids = createSelector(
    selectHerdsSortedByName,
    (_, herdUuids: string[]) => herdUuids,
    (herds, herdUuids) =>
        herds !== null ? herds.filter((herd) => herdUuids.includes(herd.uuid)) : []
);

export const selectHerdsFilteredBySearchString = createSelector(
    selectHerdsSortedByName,
    (state: AppState) => state.herdsState.herdSearchString,
    (herds, herdSearchString) =>
        herds !== null && herdSearchString !== ''
            ? herds.filter((herd) => stringContains(herd.name, herdSearchString))
            : herds
);

/**
 * Select herds that match the search string and are visible on the map, if the filter is enabled.
 */
export const selectFilteredVisibleHerds = createSelector(
    selectHerdsFilteredBySearchString,
    (state: AppState) => state.fieldsState.fields,
    (state: AppState) => state.herdsState.filterHerdsByMap,
    (state: AppState) => state.farmEditingState.lastMapPosition?.extent ?? null,
    (herds, fields, filterByMap, extent) => {
        if (herds === null || fields === null) {
            return [];
        }
        return filterByMap && extent !== null
            ? herds.filter((herd) => {
                  const field = selectHerdField(fields, herd);
                  return field === undefined || field.geoJson === null
                      ? false
                      : extentIntersectsGeo(extent, field.geoJson);
              })
            : herds;
    }
);

const groupHerdsByType = groupBy<Herd, HerdType>(prop('type'));

export const selectFilteredHerdsSortedByNameGroupedByStatusAndType = createSelector(
    selectFilteredVisibleHerds,
    pipe(
        groupBy((herd) => (herd.archived ? 'archived' : 'active')),
        // @ts-ignore Ramda typescript expects this to return an error, doesn't seem to understand
        // that it can operate over objects too.
        map(groupHerdsByType),
        // GroupBy will leave a key undefined if there are no values for it, so we need to
        // set some defaults.
        (rec: HerdsByStatusAndType) => {
            rec.active = rec.active ?? {};
            rec.archived = rec.archived ?? {};
            return rec;
        }
    ) as (herds: Herd[]) => HerdsByStatusAndType
);

export const selectActiveHerdsForType = createSelector(
    selectHerdsSortedByName,
    (_, herdType: HerdType) => herdType,
    (herds, herdType): Herd[] =>
        herds !== null ? herds.filter((herd) => !herd.archived && herd.type === herdType) : []
);

export const selectIsAllowedToAddHerds = createSelector(
    selectCurrentFarm,
    (state: AppState) => state.herdsState.herds,
    (farm, herds) =>
        herds === null ? false : farm.plan.herdLimit === null || herds.length < farm.plan.herdLimit
);

export const selectUnlockedHerds = createSelector(
    selectCurrentFarm,
    (state: AppState) => state.herdsState.herds,
    (farm, herds) =>
        herds !== null
            ? takeLast(
                  farm.plan.herdLimit !== null ? farm.plan.herdLimit : herds.length,
                  sortBy((herd) => herd.createdDate, herds)
              ).map((herd) => herd.uuid)
            : undefined
);

export const selectIsHerdLocked = createSelector(
    selectUnlockedHerds,
    (_state: AppState, herdUuid: string) => herdUuid,
    (unlockedHerds, herdUuid) =>
        unlockedHerds !== undefined ? !unlockedHerds.includes(herdUuid) : true
);

export const selectFieldHerds = createSelector(
    (_state: AppState, fieldUuid: string) => fieldUuid,
    (state: AppState) => state.herdsState.herds,
    (fieldUuid, herds): Herd[] =>
        herds === null
            ? []
            : herds.filter((herd) => herd.herdLocation?.fieldUuid === fieldUuid && !herd.archived)
);
