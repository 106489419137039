import type { FormEvent } from 'react';
import { useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import icon from 'components/icons/icon';
import type { List } from 'immutable';
import type MediaUploading from 'media/MediaUploading';

import attachSvg from './attach.svg';

import './CommentBox.css';

interface CommentBoxProps {
    onAddComment: (comment: string) => void;
    addCommentPending: boolean;
    addCommentError: boolean;
    onUploadFile: (file: File) => void;
    filesUploading: List<MediaUploading>;
}

const CommentBox = ({
    onAddComment,
    addCommentPending,
    addCommentError,
    onUploadFile,
    filesUploading,
}: CommentBoxProps) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file) => {
            onUploadFile(file);
        });
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleSend = (e: FormEvent) => {
        e.preventDefault();
        if (inputRef.current && inputRef.current.value) {
            onAddComment(inputRef.current.value.trim());
            inputRef.current.value = '';
        }
    };

    return (
        <div className="comment-box">
            <CommentBoxNotices addCommentError={addCommentError} filesUploading={filesUploading} />
            <form className="outer" onSubmit={handleSend}>
                <div className="photo-upload">
                    <div {...getRootProps()} className="photo-dropzone">
                        <input {...getInputProps()} />
                        <img src={attachSvg} className="h-5" alt="Paperclip icon" />
                    </div>
                </div>
                <input
                    type="text"
                    className="input"
                    placeholder={t('activity_log_input_hint')}
                    autoComplete="off"
                    ref={inputRef}
                />
                <SubmitButton disabled={addCommentPending}>{icon('send', 'white')}</SubmitButton>
            </form>
        </div>
    );
};

const CommentBoxNotices = ({
    addCommentError,
    filesUploading,
}: {
    addCommentError: boolean;
    filesUploading: List<MediaUploading>;
}) => {
    const uploading = filesUploading.size ? (
        <div className="uploads-in-progress">
            {filesUploading.map((uploading, i) => {
                const { name, progress } = uploading;
                return (
                    <div className="notice" key={i}>
                        Uploading {name}... ({progress}%)
                    </div>
                );
            })}
        </div>
    ) : null;

    const error = addCommentError ? (
        <div className="notice error" key="error">
            Unable to send message, please try again.
        </div>
    ) : null;

    return uploading || error ? (
        <div className="notices">
            {uploading}
            {error}
        </div>
    ) : null;
};

export default CommentBox;
