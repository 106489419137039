import { useTranslation } from 'react-i18next';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import SidebarModule from 'sidebar/modules/SidebarModule';
import TaggedUsersField from 'sidebar/modules/tagged-users/TaggedUsersField';

interface NoteNewTaggedUsersProps {
    farmUsers: FarmUser[];
}

const NoteNewTaggedUsers = ({ farmUsers }: NoteNewTaggedUsersProps) => {
    const { t } = useTranslation();
    return (
        <div className="NoteNewTaggedUsers relative">
            <SidebarModule editing>
                <h4 className="mb-2">{t('activity_log_tagged')}</h4>
                <TaggedUsersField farmUsers={farmUsers} />
            </SidebarModule>
        </div>
    );
};

export default NoteNewTaggedUsers;
