import { Basemap } from '@fieldmargin/webapp-ol-map';
import FeatureFlags from 'lib/feature-flags';
import { getRemoteConfigStringValue, RemoteConfigKeys } from 'lib/firebase/firebase-connection';

export const baseMapLabels = {
    googleRoadmap: 'Road map (Google)',
    googleSatellite: 'Satellite (Google)',
    azureSatellite: 'map_satellite_azure',
    azureRoad: 'map_road_azure',
    roadmap: 'OpenStreetMap',
    hereHybrid: 'map_satellite_here',
    mapboxSatellite: 'map_satellite_mapbox',
    mapboxTerrain: 'map_terrain',
    google: 'Google Satellite',
    ordnance: 'Ordnance Survey',
    none: 'none',
};

const google = [Basemap.GOOGLE_SATELLITE, Basemap.GOOGLE_ROADMAP];
const azure = [Basemap.AZURE_SATELLITE, Basemap.AZURE_ROAD];
const googleAndAzure = [...google, ...azure];

export const convertOldBasemap = (basemap: string): string => {
    const usingGoogle = getRemoteConfigStringValue(RemoteConfigKeys.WEB_MAPS) === 'google';
    if (usingGoogle) {
        switch (basemap) {
            case 'azureSatellite':
                return Basemap.GOOGLE_SATELLITE;
            case 'azureRoad':
                return Basemap.GOOGLE_ROADMAP;
            case Basemap.HERE_SATELLITE:
                return Basemap.AZURE_SATELLITE;
            default:
                return basemap;
        }
    } else {
        switch (basemap) {
            case 'googleSatellite':
                return Basemap.AZURE_SATELLITE;
            case 'googleRoadmap':
                return Basemap.AZURE_ROAD;
            case Basemap.HERE_SATELLITE:
                return Basemap.AZURE_SATELLITE;
            default:
                return basemap;
        }
    }
};

/**
 * These are the basemaps that should be passed to an OpenLayersMap instance.
 * Order matters here as it's how the items will be displayed in the UI.
 */
export const getAllowedBasemapTypes = (basicOnly: boolean): Basemap[] => {
    if (FeatureFlags.get('premiumMaps')) {
        const maps =
            getRemoteConfigStringValue(RemoteConfigKeys.WEB_MAPS) === 'azure' ? azure : google;
        if (basicOnly) {
            return maps;
        }
        return [
            Basemap.GOOGLE_SATELLITE,
            Basemap.AZURE_SATELLITE,
            Basemap.MAPBOX_SATELLITE,
            Basemap.GOOGLE_ROADMAP,
            Basemap.AZURE_ROAD,
            Basemap.OSM_ROAD,
            Basemap.MAPBOX_TERRAIN,
        ].filter((basemap) => !googleAndAzure.includes(basemap) || maps.includes(basemap));
    }
    return [Basemap.OSM_ROAD];
};

/**
 * These are basemaps that can be displayed to the user as selectable.
 */
export const getDisplayableBasemapTypes = (basicOnly = false): Basemap[] => [
    ...getAllowedBasemapTypes(basicOnly),
    Basemap.NONE,
];

export const getSatelliteBasemap = (): Basemap => {
    const maps =
        getRemoteConfigStringValue(RemoteConfigKeys.WEB_MAPS) === 'google' ? google : azure;
    return maps[0];
};
