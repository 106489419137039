import { geojson } from '@fieldmargin/webapp-geo';
import type { FarmMapFeature, FarmMapFeatureProperties } from 'farm-map/types';
import type { FeatureCollection, Geometry } from 'geojson';
import { prop } from 'ramda';

import { apiSlice } from './base.api';

const isValidFarmMapFeature = (feature: FarmMapFeature) =>
    feature.properties.type === 'HERD' || feature.geometry !== null;

const farmMapApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getFarmMap: builder.query<FarmMapFeature[], { farmUuid: string; year: number }>({
            query: ({ farmUuid, year }) => `farm-map/farm/${farmUuid}/year/${year}`,
            transformResponse: (
                response: FeatureCollection<Geometry, FarmMapFeatureProperties>[]
            ) =>
                response
                    .flatMap(prop('features'))
                    .filter(isValidFarmMapFeature)
                    .map((feature) => geojson.createFeatureWithId(feature) as FarmMapFeature),
        }),
    }),
});

export const { useGetFarmMapQuery } = farmMapApi;
