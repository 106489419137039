import type { Extent, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { concatExtents, extentFromGeo } from '@fieldmargin/webapp-geo';
import { AttachmentType } from 'farm-editing/attachments';
import { selectEditingAttachmentsByType } from 'farm-editing/farm-editing-state';
import type Feature from 'features/Feature';
import { selectFieldsWithFullNames } from 'fields/fields-selectors';
import { List } from 'immutable';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import type { GeoJsonItem } from 'system/types';

export const calculateTotalExtent = (
    shapes: GeoFeatureCollection | null,
    items: List<GeoJsonItem>
): Extent | null => {
    if (shapes === null) {
        return null;
    }

    const shapeExtent = extentFromGeo(shapes);
    const itemExtents = items
        .map((item) => (item.geoJson ? extentFromGeo(item.geoJson) : null))
        .filter((x) => x) as List<Extent>;
    const totalExtents = shapeExtent ? itemExtents.push(shapeExtent) : itemExtents;

    return concatExtents(totalExtents);
};

export const selectEditingFields = createSelector(
    (state: AppState) => selectEditingAttachmentsByType(state, AttachmentType.FIELD),
    selectFieldsWithFullNames,
    (uuids, fields) => fields.filter((field) => uuids.has(field.uuid))
);

export const selectEditingFeatures = createSelector(
    (state: AppState) => selectEditingAttachmentsByType(state, AttachmentType.FEATURE),
    (state: AppState) => state.featuresState.features ?? List<Feature>(),
    (featureUuids, features) => features.filter((feature) => featureUuids.has(feature.uuid))
);
