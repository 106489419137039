import { useTranslation } from 'react-i18next';
import { selectActiveInputs } from 'inputs/inputs-state';
import { useAppSelector } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import ApplyCheckboxFilter from 'view/filters/ApplyCheckboxFilter';

interface InputFilterProps {
    selectedInputUuids: string[];
    onApply: SingleParamVoidFunction<string[]>;
}

const InputFilter = ({ selectedInputUuids, onApply }: InputFilterProps) => {
    const { t } = useTranslation();
    const inputs = useAppSelector(selectActiveInputs);

    return (
        <ApplyCheckboxFilter
            header={t('inputs_filter')}
            emptyMsg={t('inputs_filter_empty')}
            items={inputs.map((i) => ({ id: i.uuid, title: i.name })).toArray()}
            onApply={onApply}
            selectedItemIds={selectedInputUuids}
        />
    );
};

export default InputFilter;
