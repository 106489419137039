import { compareName, compareString } from 'lib/util/text';
import { defaultTo, map, path, pipe, sort, sortBy, toPairs } from 'ramda';
import type { CodedItem, FieldAndCurrentUsage } from 'system/types';

import type { OperationRecording } from '../details/recording/operation-recording-utils';

export enum PesticideCheckStatus {
    'PASS' = 'PASS',
    'WARNING' = 'WARNING',
    'FAIL' = 'FAIL',
    'UNKNOWN' = 'UNKNOWN',
}

export enum PesticideCheckType {
    'EXPIRY_DATE' = 'EXPIRY_DATE',
    'USE_PERMITTED' = 'USE_PERMITTED',
    'WITHHOLDING_PERIOD' = 'WITHHOLDING_PERIOD',
    'HARVEST_INTERVAL' = 'HARVEST_INTERVAL',
    'LATEST_APPLICATION' = 'LATEST_APPLICATION',
    'MAX_DOSE_TREATMENT' = 'MAX_DOSE_TREATMENT',
    'MAX_DOSE_SEASON' = 'MAX_DOSE_SEASON',
    'MAX_TREATMENTS' = 'MAX_TREATMENTS',
    'APPLICATION_INTERVAL' = 'APPLICATION_INTERVAL',
}

export type PesticideCheckExpiryDate = {
    status: PesticideCheckStatus;
    type: PesticideCheckType.EXPIRY_DATE;
    values: { expiryDate: string };
};
export type PesticideCheckUsePermitted = {
    status: PesticideCheckStatus;
    type: PesticideCheckType.USE_PERMITTED;
    values: null;
};
export type PesticideCheckWithholdingPeriod = {
    status: PesticideCheckStatus;
    type: PesticideCheckType.WITHHOLDING_PERIOD;
    values: { withholdingPeriod: string } | null;
};
export type PesticideCheckHarvestInterval = {
    status: PesticideCheckStatus;
    type: PesticideCheckType.HARVEST_INTERVAL;
    values: { harvestInterval: string } | null;
};
export type PesticideCheckLatestApplication = {
    status: PesticideCheckStatus;
    type: PesticideCheckType.LATEST_APPLICATION;
    values: { latestApplication: string } | null;
};
export type PesticideCheckMaxDoseTreatment = {
    status: PesticideCheckStatus;
    type: PesticideCheckType.MAX_DOSE_TREATMENT;
    values: { minDose?: string; maxDose: string; actualDose: string; doseUnits: string };
};
export type PesticideCheckMaxDoseSeason = {
    status: PesticideCheckStatus;
    type: PesticideCheckType.MAX_DOSE_SEASON;
    values: { maxDoseSeason: string; totalDoseSeason: string; doseUnits: string };
};
export type PesticideCheckMaxTreatments = {
    status: PesticideCheckStatus;
    type: PesticideCheckType.MAX_TREATMENTS;
    values: { maxTreatments: string; treatments: string; treatmentUnits: string };
};
export type PesticideCheckApplicationInterval = {
    status: PesticideCheckStatus;
    type: PesticideCheckType.APPLICATION_INTERVAL;
    values: { applicationInterval: string; lastApplication?: string };
};

export type PesticideCheck =
    | PesticideCheckExpiryDate
    | PesticideCheckUsePermitted
    | PesticideCheckWithholdingPeriod
    | PesticideCheckHarvestInterval
    | PesticideCheckLatestApplication
    | PesticideCheckMaxDoseTreatment
    | PesticideCheckMaxDoseSeason
    | PesticideCheckMaxTreatments
    | PesticideCheckApplicationInterval;

export interface PesticideCheckFieldsChecks {
    fieldUuids: string[];
    checks: PesticideCheck[];
}

export interface PesticideCheckResults {
    fieldsCropChecks: PesticideCheckFieldsChecks[] | null;
    fields: Record<string, PesticideCheck[]> | null;
    filters: {
        pests: string[] | null;
        cropStages: string[] | null;
        crops: string[] | null;
    };
}

export interface PesticideCheckRecordingDTO {
    productCode: string;
    inputUuid: string;
    rate: number;
    date: string; // ISO date string
}
export interface PesticideCheckFieldDTO {
    uuid: string;
    cropPrincipleCode?: string;
}

export interface PesticideCheckResponseDTO {
    checks: Record<string, PesticideCheckResults[]>;
    filters: {
        cropStages: Record<string, string>; // crop stage id -> crop stage name
        pests: Record<string, string>; // pest id -> pest name
        crops: Record<string, Record<string, string>>; // crop principle id -> crop id -> crop name
    };
}

export interface PesticideCheckResponseFilters {
    pests: CodedItem[];
    cropStages: CodedItem[];
    crops: Record<string, CodedItem[]>;
}

export interface PesticideCheckResponse {
    filters: PesticideCheckResponseFilters;
    checks: Record<string, PesticideCheckResults[]>;
}

const filterToCodedItem = map<[string, string], CodedItem>(([code, name]) => ({ code, name }));
const filtersToCodedItemsSorted = pipe<
    [Record<string, string>],
    [string, string][],
    CodedItem[],
    CodedItem[]
>(toPairs, filterToCodedItem, sort(compareName));

export const deserializePesticideCheck = (
    json: PesticideCheckResponseDTO
): PesticideCheckResponse => ({
    filters: {
        pests: filtersToCodedItemsSorted(json.filters.pests),
        cropStages: filtersToCodedItemsSorted(json.filters.cropStages),
        crops: Object.entries(json.filters.crops).reduce(
            (crops, [cropPrincipleCode, codedItem]) => {
                crops[cropPrincipleCode] = filtersToCodedItemsSorted(codedItem);
                return crops;
            },
            {}
        ),
    },
    checks: json.checks,
});

export const operationRecordingsToPesticideCheckRecordings = (
    operationRecordings: OperationRecording[],
    operationDate: Date
) =>
    operationRecordings
        .filter((recording) => recording.input.pesticideData !== undefined)
        .map((recording) => ({
            productCode: recording.input.pesticideData!.code,
            inputUuid: recording.input.uuid,
            rate: recording.recording.rate,
            date: operationDate.toISOString(),
        }));

const countChecksByStatus = (status: PesticideCheckStatus) => (check: PesticideCheck[]) =>
    check.reduce(
        (count, { status: checkStatus }) => (checkStatus === status ? count + 1 : count),
        0
    );
export const getTotalChecksByStatus = (
    checks: PesticideCheckResults,
    status: PesticideCheckStatus
) => {
    if (checks.fieldsCropChecks === null) {
        return 0;
    }
    const count = countChecksByStatus(status);
    return checks.fieldsCropChecks.reduce((total, { checks }) => total + count(checks), 0);
};

const checkTypePriority = {
    [PesticideCheckType.EXPIRY_DATE]: 0,
    [PesticideCheckType.USE_PERMITTED]: 1,
    [PesticideCheckType.MAX_DOSE_TREATMENT]: 2,
    [PesticideCheckType.MAX_DOSE_SEASON]: 3,
    [PesticideCheckType.MAX_TREATMENTS]: 4,
    [PesticideCheckType.APPLICATION_INTERVAL]: 5,
    [PesticideCheckType.LATEST_APPLICATION]: 6,
    [PesticideCheckType.WITHHOLDING_PERIOD]: 7,
    [PesticideCheckType.HARVEST_INTERVAL]: 8,
};
// Sort by PesticideCheck status and then PesticideCheck type
export const sortPesticideChecks = (checks: PesticideCheck[]) =>
    checks.sort((a, b) => checkTypePriority[a.type] - checkTypePriority[b.type]);

export const filterByStatus = (status?: PesticideCheckStatus[]) => (checks: PesticideCheck[]) =>
    status !== undefined ? checks.filter((check) => status.includes(check.status)) : checks;

export const addFieldAndUsageToFieldGroups = (
    fieldGroups: PesticideCheckFieldsChecks[],
    fieldsAndUsage: FieldAndCurrentUsage[]
) =>
    sortBy(
        (fg) => fg.fieldUuids.length,
        fieldGroups.map(
            (
                fieldGroup
            ): {
                fieldUuids: string[];
                fields: (FieldAndCurrentUsage | undefined)[];
                checks: PesticideCheck[];
            } => {
                const fields = fieldGroup.fieldUuids
                    .map((fieldUuid) =>
                        fieldsAndUsage.find((field) => field.field.uuid === fieldUuid)
                    )
                    .sort((a, b) => compareString(getFieldName(a), getFieldName(b)));
                return { ...fieldGroup, fields, checks: sortPesticideChecks(fieldGroup.checks) };
            }
        )
    ).reverse();

const getFieldName = pipe<[FieldAndCurrentUsage | undefined], string | undefined, string>(
    path(['field', 'name']),
    defaultTo('')
);

/**
 * Utility function to map over pesticide checks for a PesticideCheckResult.
 * Applies the given map function to the common checks and the field checks.
 */
export const mapPesticideCheckResults = (
    pesticideChecks: PesticideCheckResults,
    mapFn: (checks: PesticideCheck[]) => PesticideCheck[]
): PesticideCheckResults => {
    return {
        ...pesticideChecks,
        fieldsCropChecks:
            pesticideChecks.fieldsCropChecks === null
                ? null
                : pesticideChecks.fieldsCropChecks.map((fieldCropChecks) => ({
                      ...fieldCropChecks,
                      checks: mapFn(fieldCropChecks.checks),
                  })),
    };
};
