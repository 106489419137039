import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import { selectCurrentFarm } from 'farms/farms-state';
import {
    AutoBoundaryCoverage,
    fetchAutoBoundaryCoverage,
} from 'fields/sidebar/auto-boundary/auto-boundary-api';
import { useSegmentTracking } from 'hooks/useSegmentTracking';
import { preventDefaultWhen } from 'lib/dom';
import { always } from 'ramda';
import type { AppState } from 'system/store';

import AutoDetectCoverageMessage from './AutoDetectCoverageMessage';

interface FieldBoundaryAutoProps {
    // From parent
    disabled: boolean;
    // From redux
    farm: Farm;
}

const FieldBoundaryAuto = ({ disabled, farm }: FieldBoundaryAutoProps) => {
    const { t } = useTranslation();
    const { detectBoundary } = useSegmentTracking();
    const [coverage, setCoverage] = useState<AutoBoundaryCoverage>();

    useEffect(() => {
        fetchAutoBoundaryCoverage(farm).then(setCoverage);
    }, [farm, setCoverage]);

    const isDisabled = disabled || coverage === AutoBoundaryCoverage.NONE;

    const handleClick = () => {
        preventDefaultWhen(always(isDisabled));
        detectBoundary(farm.uuid);
    };

    return (
        <>
            <p className="text-gray-450 mb-2">{t('field_boundary_auto_desc')}</p>
            <Button
                as={Link}
                to={`/farms/${farm.uuid}/fields/new/auto`}
                variant="outline"
                onClick={handleClick}
                className={c('px-2 mb-4', {
                    'text-gray-400 border-gray-400 bg-white cursor-not-allowed': disabled,
                })}
            >
                {t('field_boundary_auto_detect')}
            </Button>
            <AutoDetectCoverageMessage coverage={coverage} />
        </>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
}))(FieldBoundaryAuto);
