import type pattern from 'patternomaly';

export enum InputType {
    FERTILIZER = 'FERTILIZER',
    SEED = 'SEED',
    SPRAY = 'SPRAY',
    FUEL = 'FUEL',
    OTHER = 'OTHER',
}

export const INPUT_TYPE_ORDER: globalThis.Record<InputType, number> = {
    SEED: 1,
    FERTILIZER: 2,
    SPRAY: 3,
    FUEL: 4,
    OTHER: 5,
};

export const getSortedInputTypes = () =>
    Object.entries(InputType)
        .sort(([a], [b]) => INPUT_TYPE_ORDER[a] - INPUT_TYPE_ORDER[b])
        .map(([_, inputType]) => inputType);

export interface InputTypeColour {
    background: string;
    text?: string;
    pattern?: Parameters<typeof pattern.draw>[0];
}

export const INPUT_TYPE_COLOURS: globalThis.Record<InputType, InputTypeColour> = {
    [InputType.FERTILIZER]: {
        background: '#ebbc00',
        text: '#2c3e50',
    },
    [InputType.SEED]: {
        background: '#4da4da',
        text: '#ffffff',
    },
    [InputType.SPRAY]: {
        background: '#2c3e50',
        text: '#ffffff',
    },
    [InputType.FUEL]: {
        pattern: 'diagonal-right-left',
        background: '#4699d8',
        text: '#2c3e50',
    },
    [InputType.OTHER]: {
        background: '#c2c1c1',
        text: '#2c3e50',
    },
};

export const getInputTypeI18nKey = (inputType: InputType | string) =>
    inputType === InputType.OTHER ? 'other' : `input_type_${inputType.toLowerCase()}`;

export const getInputTypeI18nKeyShort = (inputType: InputType | string) =>
    inputType === InputType.OTHER
        ? 'other'
        : inputType === InputType.FERTILIZER
          ? 'input_type_fertilizer_short'
          : `input_type_${inputType.toLowerCase()}`;
