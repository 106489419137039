import { selectCurrentFarm } from 'farms/farms-state';
import { listToMap } from 'lib/immutil';
import {
    createReadingGroupApi,
    fetchManualSensorReadingsApi,
    updateReadingGroupApi,
} from 'sensors/manual/manual-sensors-api';
import {
    addManualSensorReadingGroup,
    updateManualSensor,
    updateManualSensorReadingGroup,
} from 'sensors/manual/manual-sensors-state';
import type ManualSensor from 'sensors/manual/ManualSensor';
import ManualSensorReadingGroup from 'sensors/manual/ManualSensorReadingGroup';
import Metric from 'sensors/Metric';
import Reading from 'sensors/Reading';
import { convertMetricValueForStorage } from 'sensors/sensors-util';
import { useAppDispatch, useAppSelector } from 'system/store';
import { selectUserPreferences } from 'users/user-state';

import type { ManualSensorReadingGroupFormValues } from './details/ManualSensorReadingGroupForm';

export const useSaveReadingGroup = (manualSensor: ManualSensor) => {
    const dispatch = useAppDispatch();
    const farm = useAppSelector(selectCurrentFarm);
    const userPreferences = useAppSelector(selectUserPreferences);

    return (values: ManualSensorReadingGroupFormValues) => {
        const readingGroup = ManualSensorReadingGroup({
            sensorId: manualSensor.id as string,
            timestamp: new Date(values.date),
            readings: manualSensor.metrics
                .filter((metric) => values.metrics[metric.id] !== undefined)
                .map((metric) =>
                    Reading({
                        metricId: metric.id,
                        value: convertMetricValueForStorage(
                            metric.type,
                            values.metrics[metric.id],
                            userPreferences
                        ),
                    })
                ),
        });
        return createReadingGroupApi(farm.uuid, manualSensor.id as string, readingGroup)
            .then((readingGroupDTO) =>
                readingGroupDTO.id ? readingGroup.set('id', readingGroupDTO.id) : readingGroup
            )
            .then((readingGroup) => dispatch(addManualSensorReadingGroup(readingGroup)))
            .then(() => fetchManualSensorReadingsApi(farm.uuid, manualSensor.id as string))
            .then((readingGroups) => manualSensor.set('readingGroups', readingGroups))
            .then((manualSensor) => dispatch(updateManualSensor(manualSensor)));
    };
};

export const useUpdateReadingGroup = (
    manualSensor: ManualSensor,
    readingGroup: ManualSensorReadingGroup
) => {
    const dispatch = useAppDispatch();
    const farm = useAppSelector(selectCurrentFarm);
    const userPreferences = useAppSelector(selectUserPreferences);

    const metricMap = listToMap(manualSensor.metrics, (metric) => metric.id);

    return (values: ManualSensorReadingGroupFormValues) => {
        const updatedReadingGroup = readingGroup
            .set('timestamp', values.date)
            .update('readings', (readings) =>
                readings.map((reading) =>
                    metricMap.has(reading.metricId) &&
                    values.metrics[reading.metricId] !== undefined
                        ? reading.set(
                              'value',
                              convertMetricValueForStorage(
                                  metricMap.get(reading.metricId, Metric()).type,
                                  values.metrics[reading.metricId],
                                  userPreferences
                              )
                          )
                        : reading
                )
            );

        return updateReadingGroupApi(
            farm.uuid,
            manualSensor.id as string,
            updatedReadingGroup
        ).then((readingGroup) => dispatch(updateManualSensorReadingGroup(readingGroup)));
    };
};
