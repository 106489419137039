import type { ComponentProps } from 'react';
import { props } from 'bluebird';

function ChevronIcon({ stroke = 'currentColor' }: ComponentProps<'svg'>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 6" {...props}>
            <path fill="transparent" stroke={stroke} strokeWidth={1.5} d="M10 1 5.468 5 .936 1" />
        </svg>
    );
}

export { ChevronIcon };
