import { connect } from 'react-redux';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import type Field from 'fields/Field';
import { getFieldWorkedArea, getYearFieldUsageUuidOrNone } from 'fields/Field';
import { selectFieldUsagesWithNotSet } from 'fields/field-usage-selectors';
import { selectMaybeVisibleMaybeArchivedFieldsWithParents } from 'fields/fields-selectors';
import type FieldUsage from 'fields/FieldUsage';
import type { List, Set } from 'immutable';
import { uuidMatches } from 'lib/fp-helpers';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import SizeBubble from 'view/molecules/size-bubble/SizeBubble';
import FormattedArea from 'view/units/FormattedArea';
import { selectCurrentYear } from 'years/years-state';

interface FieldSelectorFieldsAlphabeticalProps {
    // From parents
    editingFieldUuids: Set<string>;
    toggleFieldSelected: SingleParamVoidFunction<string>;

    // From redux
    fields: List<Field>;
    fieldUsages: List<FieldUsage>;
    year: number;
}

const FieldSelectorFieldsAlphabetical = ({
    editingFieldUuids,
    toggleFieldSelected,
    fields,
    fieldUsages,
    year,
}: FieldSelectorFieldsAlphabeticalProps) => {
    return (
        <>
            {fields.map((field) => (
                <FieldSelectorFieldWithUsage
                    key={field.uuid}
                    field={field}
                    fieldUsages={fieldUsages}
                    year={year}
                    selected={editingFieldUuids.has(field.uuid)}
                    toggleFieldSelected={toggleFieldSelected}
                />
            ))}
        </>
    );
};

export default connect((state: AppState) => ({
    fields: selectMaybeVisibleMaybeArchivedFieldsWithParents(state),
    fieldUsages: selectFieldUsagesWithNotSet(state),
    year: selectCurrentYear(state),
}))(FieldSelectorFieldsAlphabetical);

interface FieldSelectFieldWithUsageProps {
    field: Field;
    fieldUsages: List<FieldUsage>;
    year: number;
    selected: boolean;
    toggleFieldSelected: SingleParamVoidFunction<string>;
}
const FieldSelectorFieldWithUsage = ({
    field,
    fieldUsages,
    year,
    selected,
    toggleFieldSelected,
}: FieldSelectFieldWithUsageProps) => {
    const fieldUsageUuid = getYearFieldUsageUuidOrNone(year, field);
    const fieldUsage = fieldUsages.find(uuidMatches(fieldUsageUuid));

    return (
        <div className="FieldSelectorField flex items-center relative bordered-b pr-5">
            {fieldUsage !== undefined && (
                <div
                    className="w-2 self-stretch mr-3"
                    style={{
                        backgroundColor: fieldUsage.colour,
                    }}
                />
            )}
            <Checkbox
                id={field.uuid}
                checked={selected}
                onChange={() => toggleFieldSelected(field.uuid)}
                label={<div className="name">{field.name}</div>}
                className="overflow-hidden py-4 w-3/4"
            />
            <SizeBubble className="ml-auto">
                <FormattedArea areaSqm={getFieldWorkedArea(field, year)} withUnit />
            </SizeBubble>
        </div>
    );
};
