import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import icon from 'components/icons/icon';
import { useDateFormat } from 'hooks/useDateFormat';
import { getDisplayName } from 'lib/util/names';
import type Note from 'notes/Note';
import { setNote } from 'notes/note-actions';
import { useUpdateNote, useUpdateTask } from 'notes/note-hooks';
import { bindActionCreators } from 'redux';
import DueDate from 'sidebar/modules/date/DueDate';
import EditableDateAndTime from 'sidebar/modules/date/EditableDateAndTime';
import type { AppState } from 'system/store';
import { selectUserId } from 'users/user-state';

interface EditableNoteDatesProps {
    // From parent
    note: Note;

    // From redux
    farmUsers: FarmUser[];
    userId: number;
}

const EditableNoteDates = ({ note, farmUsers, userId }: EditableNoteDatesProps) => {
    return (
        <>
            <CreatedDate note={note} farmUsers={farmUsers} />
            <NoteDueDate note={note} />
            <CompletedDate note={note} farmUsers={farmUsers} userId={userId} />
        </>
    );
};

export default connect(
    (state: AppState) => ({
        farmUsers: state.farmUsersState.farmUsers,
        userId: selectUserId(state),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setNote,
            },
            dispatch
        )
)(EditableNoteDates);

const NoteDueDate = ({ note }: { note: Note }) => {
    const { t } = useTranslation();
    const updateTask = useUpdateTask(note);

    if (!note.task || note.completedDate !== null) {
        return null;
    }

    const handleSave = (date: Date | null) =>
        updateTask(
            date === null ? { setDueTimestampNull: true } : { dueTimestamp: date.valueOf() }
        );

    return (
        <EditableDateAndTime
            label={t('create_task_due_date_title_set')}
            value={note.dueDate}
            onSave={handleSave}
            required={false}
            openDirection="bottom"
        >
            {note.dueDate !== null ? (
                <DueDate date={note.dueDate} />
            ) : (
                <div className="not-set">{t('no_due_date')}</div>
            )}
        </EditableDateAndTime>
    );
};

const CreatedDate = ({ note, farmUsers }: { note: Note; farmUsers: FarmUser[] }) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    const updateNote = useUpdateNote(note);

    if (note.task) {
        return null;
    }
    const createdDate = note.userCreatedDate ? note.userCreatedDate : note.createdDate;
    const handleSave = (date: Date) => updateNote({ userCreatedTimestamp: date.valueOf() });

    return (
        <EditableDateAndTime
            label={t('activity_log_created_date')}
            value={createdDate}
            onSave={handleSave}
            required={true}
            openDirection="bottom"
        >
            {format(createdDate, 'do MMM yyyy, HH:mm')} {t('by')}{' '}
            {getDisplayName(farmUsers, note.createdByUserId)}
        </EditableDateAndTime>
    );
};

const CompletedDate = ({
    note,
    farmUsers,
    userId,
}: {
    note: Note;
    farmUsers: FarmUser[];
    userId: number;
}) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    const updateTask = useUpdateTask(note);

    if (!note.task || note.completedDate === null || note.completedByUserId === null) {
        return null;
    }

    const handleSave = (date: Date | null) =>
        updateTask({
            completedTimestamp: date?.valueOf(),
            completedByUserId: date !== null ? userId : null,
        });

    return (
        <EditableDateAndTime
            label={t('completed')}
            value={note.completedDate}
            onSave={handleSave}
            required={false}
            openDirection="bottom"
        >
            {icon('tick')} {format(note.completedDate, 'do MMM yyyy, HH:mm')} {t('by')}{' '}
            {getDisplayName(farmUsers, note.completedByUserId)}
        </EditableDateAndTime>
    );
};
