import type { List } from 'immutable';
import ItemHistoryActivityItem from 'sidebar/modules/item-history/ItemHistoryActivityItem';
import ItemHistoryDay from 'sidebar/modules/item-history/ItemHistoryDay';

import type { FeatureHistoryItem } from './feature-history-generator';

const FeatureHistoryDay = ({ history }: { history: List<FeatureHistoryItem> }) => {
    if (history.size === 0) {
        return null;
    }

    const first = history.first<FeatureHistoryItem>();

    return (
        <ItemHistoryDay date={first.date}>
            {history.map((item, index) => (
                <ItemHistoryActivityItem key={index} activity={item.item} />
            ))}
        </ItemHistoryDay>
    );
};

export default FeatureHistoryDay;
