import { Record } from 'immutable';

const Comment = Record({
    parentUuid: '',
    id: -1,
    createdByUserId: -1,
    createdDate: new Date(),
    comment: '',
});
interface Comment extends ReturnType<typeof Comment> {}

export interface CommentDTO {
    id: number;
    parentUUID: string;
    createdByUserId: number;
    createdDate: number;
    comment: string;
}

export const deserializeComment = (json: CommentDTO) => {
    return Comment({
        parentUuid: json.parentUUID,
        id: json.id,
        createdByUserId: json.createdByUserId,
        createdDate: new Date(json.createdDate),
        comment: json.comment,
    });
};

export const serializeComment = (comment: Comment): CommentDTO => {
    return {
        parentUUID: comment.parentUuid,
        id: comment.id,
        createdByUserId: comment.createdByUserId,
        createdDate: comment.createdDate.valueOf(),
        comment: comment.comment,
    };
};

export default Comment;
