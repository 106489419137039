import { createIconGenerator } from 'lib/util/icon-generator';

import activityIcon from './icons/activity.svg?raw';
import billingIcon from './icons/billing.svg?raw';
import dataIcon from './icons/data.svg?raw';
import featuresIcon from './icons/features.svg?raw';
import fieldsIcon from './icons/fields.svg?raw';
import livestockIcon from './icons/livestock.svg?raw';
import mapsIcon from './icons/maps.svg?raw';
import notesIcon from './icons/notes.svg?raw';
import reportingIcon from './icons/reporting.svg?raw';
import teamIcon from './icons/team.svg?raw';
import tutorialsIcon from './icons/tutorials.svg?raw';
import vehicleTrackingIcon from './icons/vehicles.svg?raw';

export interface TabBarIcons {
    activity: string;
    data: string;
    fields: string;
    features: string;
    maps: string;
    notes: string;
    reporting: string;
    team: string;
    vehicleTracking: string;
    livestock: string;
    billing: string;
    tutorials: string;
}

const src: TabBarIcons = {
    activity: activityIcon,
    data: dataIcon,
    fields: fieldsIcon,
    features: featuresIcon,
    maps: mapsIcon,
    notes: notesIcon,
    reporting: reportingIcon,
    team: teamIcon,
    vehicleTracking: vehicleTrackingIcon,
    livestock: livestockIcon,
    billing: billingIcon,
    tutorials: tutorialsIcon,
};

export default createIconGenerator(src, 'icon');
