import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { usePromise } from '@fieldmargin/webapp-state';
import icon from 'components/icons/icon';
import { selectSortedFieldUsages } from 'fields/field-usage-selectors';
import type FieldUsage from 'fields/FieldUsage';
import { getUsageName } from 'fields/FieldUsage';
import { usePageParams } from 'hooks/usePageParamas';
import type { List } from 'immutable';
import ExportReportButton from 'reporting/ExportReportButton';
import { getCsvDownloadLink, getYearGrossMarginByUsage } from 'reporting/reporting-api';
import ReportStatusFilter from 'reporting/ReportStatusFilter';
import type { AppState } from 'system/store';
import ContactSupportLink from 'view/ContactSupportLink';
import ErrorMessage from 'view/ErrorMessage';
import ApplyCheckboxFilter from 'view/filters/ApplyCheckboxFilter';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';

import { sortGrossMarginByUsageReport } from './gross-margin-utils';
import type { GrossMargin } from './GrossMargin';
import { useGrossMarginContext } from './useGrossMarginContext';
import type { GrossMarginUsageReportSchema } from './useGrossMarginUsageReportContext';

interface GrossMarginByUsageReportProps {
    usages: List<FieldUsage>;
}

const GrossMarginByUsageReport = ({ usages }: GrossMarginByUsageReportProps) => {
    const { t } = useTranslation();
    const { farmUuid } = usePageParams();
    const { farm, year, measurementUnit } = useGrossMarginContext();
    const { state } = useLocation();
    const isTable = state?.isTable;

    const [selectedUsageUuids, setSelectedUsageUuids] = useState<string[]>([]);
    const [status, setStatus] = useState('all');

    const [reportData, setReportData] = useState<GrossMargin[]>([]);
    const { pending, error, setPromise } = usePromise<GrossMargin[]>((data) =>
        setReportData(sortGrossMarginByUsageReport(data))
    );

    useEffect(() => {
        setReportData([]);
        setPromise(
            getYearGrossMarginByUsage(farm.uuid, year, measurementUnit, status, selectedUsageUuids)
        );
    }, [farm, selectedUsageUuids, status, measurementUnit]);

    return (
        <>
            <div className="flex items-end mb-3">
                <ApplyCheckboxFilter
                    header={t('usage_filter')}
                    emptyMsg={t('usage_filter_empty')}
                    items={usages.map((f) => ({ id: f.uuid, title: getUsageName(f) })).toArray()}
                    selectedItemIds={selectedUsageUuids}
                    onApply={setSelectedUsageUuids}
                />
                <ReportStatusFilter filter={status} setFilter={setStatus} className="ml-4" />
                <ExportReportButton
                    className="ml-auto"
                    filename="gross-margin-by-usage"
                    downloadLink={getCsvDownloadLink(
                        farm.uuid,
                        year,
                        status,
                        'grossMarginByFieldUsage',
                        measurementUnit
                    )}
                />
            </div>
            <div className="flex justify-end mb-6">
                <Link
                    to={`/farms/${farmUuid}/reporting/gross-margin/usage/table`}
                    state={{ isTable: true }}
                    className="block ml-5"
                >
                    {icon('table', isTable ? 'blue' : 'dark-blue')}
                </Link>
                <Link
                    to={`/farms/${farmUuid}/reporting/gross-margin/usage`}
                    state={{ isTable: false }}
                    className="block ml-3"
                >
                    {icon('graph', !isTable ? 'blue' : 'dark-blue')}
                </Link>
            </div>
            {pending && (
                <p>
                    <em>{t('reporting_loading')}</em>
                </p>
            )}
            {error && (
                <ErrorMessage>
                    <MarkdownNoPara components={{ a: ContactSupportLink }}>
                        {t('something_went_wrong_or_contact')}
                    </MarkdownNoPara>
                </ErrorMessage>
            )}
            {reportData !== undefined && (
                <Outlet
                    context={
                        {
                            farm,
                            reportData,
                            measurementUnit,
                        } satisfies GrossMarginUsageReportSchema
                    }
                />
            )}
        </>
    );
};

export default connect((state: AppState) => ({
    usages: selectSortedFieldUsages(state),
}))(GrossMarginByUsageReport);
