import type Field from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import { isEqual } from 'lodash';

export interface ReadOperationFieldDTO {
    fieldUUID: string;
    operationUUID?: string;
    areaSQM: number;
    yieldRateHa?: number;
    yieldTotal?: number;
    completedDate?: number;
    completedByUserId?: number;
    startDate?: number;
    windSpeed?: number;
    direction?: string;
    temperature?: number;
    note?: string;
}

export interface WriteOperationFieldDTO {
    fieldUUID: string;
    areaSQM: number;
    yieldRate?: number;
    yieldTotal?: number;
    completedDate?: number;
    completedByUserId?: number;
    startDate?: number;
    windSpeed?: number;
    direction?: string;
    temperature?: number;
    note?: string;
}

export interface OperationFieldData {
    areaSqm: number;
    yieldRateHa?: number;
    yieldTotal?: number;
    completedDate?: Date;
    completedByUserId?: number;
    startDate?: Date;
    windSpeed?: number;
    windDirection?: string;
    temperature?: number;
    weatherNote?: string;
}

export interface OperationField extends OperationFieldData {
    fieldUuid: string;
}

// This object contains the actual field object so should not be stored in state, but rather
// used as a prop for components.
export interface FullOperationField extends OperationFieldData {
    field: Field;
    fieldUsage?: FieldUsage;
}

export const fullOperationFieldToOperationField = ({
    field,
    ...rest
}: FullOperationField): OperationField => ({
    fieldUuid: field.uuid,
    ...rest,
});

export const hasOperationFieldChanged = (first: OperationField, second: OperationField) =>
    !isEqual(first, second);

export const operationFieldHasWeatherData = (operationField: FullOperationField) =>
    operationField.temperature ||
    operationField.weatherNote ||
    operationField.windDirection ||
    operationField.windSpeed;
