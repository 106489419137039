import { useTranslation } from 'react-i18next';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import c from 'classnames';
import icon from 'components/icons/icon';
import { isCreator } from 'lib/owner';
import { useAppSelector } from 'system/store';
import type { Item } from 'system/types';
import { selectIsFarmOwner } from 'team/farm-users-state';
import { selectUserId } from 'users/user-state';

interface SidebarHeaderDeleteLinkProps extends LinkProps {
    item: Item;
}

const SidebarHeaderDeleteLink = ({ item, className, ...props }: SidebarHeaderDeleteLinkProps) => {
    const { t } = useTranslation();
    const canDelete = useAppSelector(
        (state) => selectIsFarmOwner(state) || isCreator(item, selectUserId(state))
    );

    if (!canDelete) {
        return (
            <div
                className="SidebarHeaderDeleteLink SidebarHeaderDeleteLink-disabled"
                title="Only farm owners or the creator can delete this item"
            >
                {icon('remove', 'grey', 'mr-3')} {t('label_delete')}
            </div>
        );
    }

    return (
        <Link {...props} className={c('SidebarHeaderDeleteLink', className)}>
            {icon('remove', 'red', 'mr-3')} {t('label_delete')}
        </Link>
    );
};

export default SidebarHeaderDeleteLink;
