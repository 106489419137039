import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { usePageParams } from 'hooks/usePageParamas';

import { ButtonBackBase } from '../Buttons/ButtonBack';

import styles from './JohnDeereNavigation.module.css';

function JohnDeereNavigation() {
    const { t } = useTranslation();
    const { farmUuid } = usePageParams();
    const navigate = useNavigate();
    const match = useMatch('/farms/:farmUuid/john-deere');
    const isRootJD = !!match;

    const onPress = () => {
        if (isRootJD) {
            navigate(-1);
        } else {
            navigate(`/farms/${farmUuid}/fields`);
        }
    };

    const buttonLabel = isRootJD ? t('back') : t('back_to_farm');

    return (
        <div className={styles.navigation}>
            <ButtonBackBase onPress={onPress}>{buttonLabel}</ButtonBackBase>
        </div>
    );
}

export { JohnDeereNavigation };
