import type { Map } from 'immutable';
import { hasOwnProperty } from 'utils/hasOwnProperty';

export enum TutorialTypes {
    FIELD = 'field',
    FIELD_JOB = 'fieldJob',
    INPUTS = 'inputs',
    FIELD_HEALTH = 'fieldHealth',
    HERDS = 'herds',
    SCOUTING = 'scouting',
    DATA = 'data',
    FARM_SHARE = 'farmShare',
}

export type TutorialTypeSchema = `${TutorialTypes}`;

export interface TutorialFirebaseDTO {
    field: boolean;
    fieldJob: boolean;
    inputs: boolean;
    fieldHealth: boolean;
    herds: boolean;
    scouting: boolean;
    data: boolean;
    farmShare: boolean;
}

export const serializeTutorials = (tutorials: Map<string, boolean>): TutorialFirebaseDTO =>
    tutorials.reduce(
        (dto, value, key) => {
            if (hasOwnProperty(dto, key as keyof TutorialFirebaseDTO)) {
                dto[key] = value;
            }
            return dto;
        },
        {
            field: false,
            fieldJob: false,
            inputs: false,
            fieldHealth: false,
            herds: false,
            scouting: false,
            data: false,
            farmShare: false,
        }
    );
