import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import c from 'classnames';
import type { List, Set } from 'immutable';
import type Input from 'inputs/Input';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { getOnboardingClassName, useOnboarding } from 'onboarding/onboarding-state';
import type Recording from 'operations/Recording';
import PrimaryPlusButton from 'sidebar/modules/PrimaryPlusButton';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { useFinishTutorial } from 'tutorials/tutorial-hooks';
import { TutorialTypes } from 'tutorials/TutorialTypes';
import { selectUserAreaMeasurementUnit } from 'users/user-state';

import { getRecordingInfo } from '../details/recording/operation-recording-utils';
import EditingOperationRecordingsTable from '../EditingOperationRecordingsTable';

interface NewOperationRecordingsProps {
    // From parent
    editingOperationRecordings: Set<Recording>;
    totalArea: number;
    onRemoveRecording: SingleParamVoidFunction<Recording>;
    onChangeRecording: SingleParamVoidFunction<Recording>;
    startAttachingRecordings: VoidFunction;

    // From redux
    inputs: List<Input>;
    areaMeasurementUnit: MeasurementUnit;
    activeTutorialStep: string | null;
}

const NewOperationRecordings = ({
    editingOperationRecordings,
    totalArea,
    onRemoveRecording,
    onChangeRecording,
    startAttachingRecordings,
    inputs,
    areaMeasurementUnit,
    activeTutorialStep,
}: NewOperationRecordingsProps) => {
    const { t } = useTranslation();
    const { activeStep, nextOnboarding } = useOnboarding();
    const finishTutorial = useFinishTutorial();
    const handleAddClick = useCallback(() => {
        nextOnboarding();
        startAttachingRecordings();
        finishTutorial(TutorialTypes.INPUTS);
    }, [nextOnboarding, startAttachingRecordings, finishTutorial]);
    return (
        <div
            className={c('NewOperationRecordings', {
                forefront: activeTutorialStep === 'add-inputs',
            })}
        >
            <SidebarModule noBorder className="flex justify-between items-center">
                <h4 className="mb-0">{t('inputs_title')}</h4>
                <PrimaryPlusButton
                    onClick={handleAddClick}
                    className={c(getOnboardingClassName('inputs', activeStep))}
                >
                    {t('operation_details_add_input')}
                </PrimaryPlusButton>
            </SidebarModule>
            <EditingOperationRecordingsTable
                areaMeasurementUnit={areaMeasurementUnit}
                operationRecordings={getRecordingInfo(editingOperationRecordings, inputs)}
                totalArea={totalArea}
                onRemove={onRemoveRecording}
                onChange={onChangeRecording}
            />
        </div>
    );
};

export default connect((state: AppState) => ({
    inputs: state.inputsState.inputs,
    areaMeasurementUnit: selectUserAreaMeasurementUnit(state),
    activeTutorialStep: state.tutorialsState.activeStep,
}))(NewOperationRecordings);
