import type { Extent } from '@fieldmargin/webapp-geo';
import { concatExtents } from '@fieldmargin/webapp-geo';
import { List, Record } from 'immutable';
import type { WmsTilesData } from 'maps/common/WmsTiles';
import type WmsTiles from 'maps/common/WmsTiles';
import type { XyzTilesData } from 'maps/common/XyzTiles';
import type XyzTiles from 'maps/common/XyzTiles';

import type MapLayer from './MapLayer';

const MapGroup = Record({
    uuid: '',
    name: '',
    timestamp: new Date(),
    maps: [] as MapLayer[],
    tiles: null as XyzTiles | WmsTiles | null,
    farmIntegrationUuid: '',
});
interface MapGroup extends ReturnType<typeof MapGroup> {}

export interface MapGroupData {
    uuid: string;
    name: string;
    timestamp: number;
    wmsTiles?: WmsTilesData;
    xyzTiles?: XyzTilesData;
    associatedFieldUUIDs: List<string>;
    farmIntegrationUUID: string;
    group: MapGroupGroupData;
}

interface MapGroupGroupData {
    uuid: string;
    name: string;
    upstreamId: string;
    farmIntegrationUUID: string;
}

export const getMapGroupTotalExtent = (mapGroup: MapGroup): Extent | null => {
    // Group has tiles, use the extent of those
    if (mapGroup.tiles) {
        return mapGroup.tiles.extent;
    }
    // Else, sum the extents of all children maps
    return concatExtents(List(mapGroup.maps.map((m) => m.tiles.extent)));
};

export const serializeMapGroup = (mapGroup: MapGroup) => ({
    name: mapGroup.name,
    timestamp: mapGroup.timestamp.valueOf(),
});

export default MapGroup;
