import { serialize } from '@fieldmargin/webapp-geo';
import { EditingType } from 'farm-editing/farm-editing-state';
import type { FarmMapFeature, FarmMapFeatureFeatureProps } from 'farm-map/types';
import type { FeatureCollection, Geometry } from 'geojson';
import { useAppSelector } from 'system/store';

export const useFarmMapEditingFeatures = (): FarmMapFeature[] => {
    const { editingData, editingType, editingGeoFeatureCollection } = useAppSelector(
        ({ farmEditingState }) => ({
            editingData: farmEditingState.editingData,
            editingType: farmEditingState.editingType,
            editingGeoFeatureCollection: farmEditingState.editingGeoFeatureCollection,
        })
    );

    // TODO: Auto boundary should use it's own state and not farmEditingState to store
    // features.
    const isAutoBoundaryEdit =
        editingType === EditingType.AUTO_BOUNDARY && editingData.id !== 'edit-boundaries';

    if (editingType === null || isAutoBoundaryEdit || editingGeoFeatureCollection === null) {
        return [];
    }

    return (
        serialize(editingGeoFeatureCollection, { withProperties: true }) as FeatureCollection<
            Geometry,
            FarmMapFeatureFeatureProps
        >
    ).features;
};
