import { useTranslation } from 'react-i18next';
import { getAllNutrientTypes } from 'inputs/Nutrients';
import type { SingleParamVoidFunction } from 'system/types';
import ApplyCheckboxFilter from 'view/filters/ApplyCheckboxFilter';

interface NutrientsFilterProps {
    selected: string[];
    onChange: SingleParamVoidFunction<string[]>;
    className?: string;
}

const NutrientsFilter = ({ selected, onChange, className }: NutrientsFilterProps) => {
    const { t } = useTranslation();
    return (
        <ApplyCheckboxFilter
            header={t('nutrients')}
            emptyMsg={t('nutrients_filter_empty')}
            items={getAllNutrientTypes().map((n) => ({ id: n, title: t(n) }))}
            selectedItemIds={selected}
            onApply={onChange}
            className={className}
        />
    );
};

export default NutrientsFilter;
