import type { SingleParamVoidFunction } from 'system/types';

import './CircleCheckbox.css';

interface CircleCheckboxProps {
    id: string;
    value: string;
    checked: boolean;
    onChange: SingleParamVoidFunction<string>;
}

const CircleCheckbox = ({ id, value, checked, onChange }: CircleCheckboxProps) => {
    return (
        <div className="CircleCheckbox">
            <input
                type="checkbox"
                className="CircleCheckbox-input"
                id={id}
                value={value}
                checked={checked}
                onChange={(e) => onChange(e.target.value)}
            />
            <label htmlFor={id} className="CircleCheckbox-label" />
        </div>
    );
};

export default CircleCheckbox;
