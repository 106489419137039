import { createAction, handleActions } from '@fieldmargin/webapp-state';
import { isBefore, subWeeks } from 'date-fns';
import { loadedFarmSuccess } from 'farms/farm-loading-state';
import { List, Record } from 'immutable';

import type Vehicle from './Vehicle';

export const setVehicles = createAction<VehicleTrackingState, List<Vehicle>>(
    'Set vehicles',
    (state, vehicles) => {
        let letterCode = 65; // 'A'
        const groupedAndSorted = vehicles
            .groupBy(({ timestamp }) =>
                isBefore(timestamp, subWeeks(new Date(), 1)) ? 'inactive' : 'active'
            )
            .map((v) => v.toList())
            .toMap()
            .map((vehicleGroup) =>
                vehicleGroup
                    .sortBy((v) => v.vehicleName)
                    .map((v) => v.set('displayName', String.fromCharCode(letterCode++)))
            );
        return state.withMutations((s) => {
            s.set('activeVehicles', groupedAndSorted.get('active', List<Vehicle>()));
            s.set('inactiveVehicles', groupedAndSorted.get('inactive', List<Vehicle>()));
        });
    }
);

const VehicleTrackingState = Record({
    activeVehicles: null as List<Vehicle> | null,
    inactiveVehicles: null as List<Vehicle> | null,
});
export interface VehicleTrackingState extends ReturnType<typeof VehicleTrackingState> {}

export const vehicleTrackingReducer = handleActions(VehicleTrackingState(), [setVehicles], {
    [loadedFarmSuccess.toString()]: () => VehicleTrackingState(),
});
