import { useEffect, useRef } from 'react';
import { selectIsEditingAndNotSelecting } from 'farm-editing/farm-editing-state';
import type { FarmMapFeature } from 'farm-map/types';
import { useAppSelector } from 'system/store';

import { useFarmMapFeatureClick } from './useFarmMapFeatureClick';
import { useFarmMapFieldClick } from './useFarmMapFieldClick';
import { useFarmMapHerdClick } from './useFarmMapHerdClick';
import { useFarmMapNoteClick } from './useFarmMapNoteClick';
import { useFarmMapSubFieldClick } from './useFarmMapSubFieldClick';

/**
 * Handle clicks on farm map features.
 * If an item is being edited and no attachments are being selected then do nothing.
 */
export const useFarmMapItemClick = (features: FarmMapFeature[]) => {
    const handleFieldClick = useFarmMapFieldClick();
    const handleFeatureClick = useFarmMapFeatureClick();
    const handleNoteClick = useFarmMapNoteClick();
    const handleHerdClick = useFarmMapHerdClick();
    const handleSubFieldClick = useFarmMapSubFieldClick();
    // Order here matters. The first handler to return true will prevent the others from running.
    const handlers = [
        handleSubFieldClick,
        handleFieldClick,
        handleFeatureClick,
        handleNoteClick,
        handleHerdClick,
    ];

    const featuresRef = useRef<FarmMapFeature[]>(features);

    const notClickable = useAppSelector(selectIsEditingAndNotSelecting);
    const notClickableRef = useRef(notClickable);

    useEffect(() => {
        featuresRef.current = features;
        notClickableRef.current = notClickable;
    }, [features, notClickable]);

    return (id: string | number | undefined) => {
        if (notClickableRef.current) return;

        const feature = featuresRef.current.find((f) => f.id === id);

        if (feature === undefined) return;

        let handled = false;
        handlers.forEach((handler) => {
            if (handled) return;
            handled = handler(feature);
        });
    };
};
