import { Extent, extentFromArray, Projection } from '@fieldmargin/webapp-geo';
import { Record } from 'immutable';

const XyzTiles = Record({
    uuid: '', // TODO make `id`? Do we ever use this?
    urlTemplates: [] as string[],
    projection: Projection.WEB_MERCATOR,
    extent: Extent(),
    minZoom: 0,
    maxZoom: 0,
});
interface XyzTiles extends ReturnType<typeof XyzTiles> {}

export interface XyzTilesData {
    uuid?: string;
    urlTemplates: string[];
    projection: string;
    extent: number[];
    minZoom: number;
    maxZoom: number;
}

export const deserializeXyzTiles = (json: XyzTilesData) => {
    const proj = json.projection as Projection;
    return XyzTiles({
        ...json,
        projection: proj,
        extent: extentFromArray(proj, json.extent),
    });
};

export default XyzTiles;
