import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@fieldmargin/webapp-styling/components/button';
import { stopSelectingMultipleAttachments } from 'farm-editing/farm-editing-state';
import { preventDefaultAnd } from 'lib/dom';
import OperationFieldsSelection from 'operations/sidebar/OperationFieldsSelection';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';

interface NewOperationFieldSelectionProps {
    // From redux
    stopSelectingMultipleAttachments: typeof stopSelectingMultipleAttachments;
}

const NewOperationFieldSelection = ({
    stopSelectingMultipleAttachments,
}: NewOperationFieldSelectionProps) => {
    const { t } = useTranslation();
    const onClick = preventDefaultAnd(() => {
        stopSelectingMultipleAttachments();
    });
    return (
        <div className="note-fields-selection scrollable">
            <div className="non-scrolling">
                <SidebarHeader className="px-5">
                    <Button variant="danger-outline" small onClick={onClick}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={onClick} small>
                        {t('draw_shapes_add_point')}
                    </Button>
                </SidebarHeader>
            </div>
            <OperationFieldsSelection />
        </div>
    );
};

export default connect(
    () => ({}),
    (dispatch) => bindActionCreators({ stopSelectingMultipleAttachments }, dispatch)
)(NewOperationFieldSelection);
