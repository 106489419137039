import { useCurrentPageLocation } from './useCurrentPageLocation';

const useNotesPageLocation = () => {
    const { currentPageLocation } = useCurrentPageLocation();
    const isNotesPage = currentPageLocation.split('/').some((el) => el === 'notes');

    return {
        isNotesPage,
    };
};

export { useNotesPageLocation };
