import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import type { Farm, FarmUser } from '@fieldmargin/webapp-farms';
import { FarmPlanType } from '@fieldmargin/webapp-farms';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import Button from '@fieldmargin/webapp-styling/components/button';
import { createNoteChatLog } from 'chat/chat-generator';
import { DiscussionList } from 'components/discussion-list';
import icon from 'components/icons/icon';
import type { Discussion } from 'discussions/Discussion';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { selectFieldsWithFullNames } from 'fields/fields-selectors';
import type Herd from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import { selectHerdsSortedByName } from 'herd/herd-selectors';
import HerdTypeColourBar from 'herd/sidebar/HerdTypeColourBar';
import { useDateFormat } from 'hooks/useDateFormat';
import { List } from 'immutable';
import { notNil } from 'lib/fp-helpers';
import { printOut } from 'lib/print';
import { getDisplayName } from 'lib/util/names';
import type Media from 'media/Media';
import { getMediaThumbnail190Url } from 'media/Media';
import type Note from 'notes/Note';
import NoteFieldsBubbles from 'notes/sidebar/details/NoteFieldsBubbles';
import withPageNote from 'notes/withPageNote';
import ShapeInformation from 'sidebar/modules/shapes/components/ShapeInformation';
import { calculateTotalExtent } from 'sidebar/modules/shapes/shapes-util';
import type { AppState } from 'system/store';
import { selectDisplayableFarmUsers } from 'team/farm-users-state';
import UpgradeButton from 'upgrade/UpgradeButton';
import FarmLink from 'view/FarmLink';
import fmLogo from 'view/system/logo/fm-logo.svg';

interface NotePrintPageProps {
    farm: Farm;
    note?: Note;
    farmUsers: FarmUser[];
    attachedFields: List<Field>;
    attachedHerds: List<Herd>;
    noteMedia?: Media[];
    noteDiscussions?: Discussion[];
}

const NotePrintPage = ({
    farm,
    note,
    farmUsers,
    attachedFields,
    attachedHerds,
    noteMedia = [],
    noteDiscussions = [],
}: NotePrintPageProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();

    if (note === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/notes`} />;
    }
    const chatLog = createNoteChatLog(noteMedia, noteDiscussions, getMediaThumbnail190Url);

    if (farm.plan.name === FarmPlanType.FREE_PLAN) {
        return (
            <div className="bg-white h-content-full text-black p-8">
                <FarmLink className="flex items-center font-bold mb-6" to={`/notes/${note.uuid}`}>
                    {icon('back', 'dark-blue', 'mr-2')} Back
                </FarmLink>
                <p>
                    {t('note_printing_upgrade', {
                        defaultValue: 'Note printing is not available on the %s plan.',
                        sprintf: [farm.plan.displayName],
                    })}
                </p>
                <p>
                    <UpgradeButton />
                </p>
            </div>
        );
    }

    return (
        <div className="bg-white h-content-full print:h-auto overflow-auto p-8 text-black">
            <div className="no-print flex items-center mb-6">
                <FarmLink className="flex items-center font-bold mr-8" to={`/notes/${note.uuid}`}>
                    {icon('back', 'dark-blue', 'mr-2')} {t('back')}
                </FarmLink>
                <Button onClick={() => printOut()} className="flex items-center">
                    {icon('print', 'white', 'mr-3')} {t('print')}
                </Button>
            </div>
            <img
                src={fmLogo}
                alt="fieldmargin logo"
                className="h-8 hidden print:block print:mb-4"
            />
            <div className="flex mb-8">
                <div className="w-1/2">
                    <h1 className="text-base">{note.name}</h1>
                    <ShapeInformation
                        geoFeatureCollection={note.getGeoFeatureCollection()}
                        extent={calculateTotalExtent(
                            note.getGeoFeatureCollection(),
                            attachedFields.map((field) => ({
                                uuid: field.uuid,
                                geoJson: field.geoJson as GeoFeatureCollection,
                            }))
                        )}
                        hideViewOnMap
                    >
                        {attachedFields.size > 0 && <NoteFieldsBubbles fields={attachedFields} />}
                    </ShapeInformation>
                    <div className="mb-5">
                        <h4>{t('livestock_herds')}</h4>
                        {attachedHerds.size > 0 ? (
                            attachedHerds.map((herd) => (
                                <FarmLink
                                    to={`/livestock/${herd.uuid}`}
                                    key={herd.uuid}
                                    className="flex items-center pr-5"
                                >
                                    <HerdTypeColourBar herd={herd} className="h-12" />
                                    <strong className="">
                                        <span className="mr-2">{herdIcon(herd.type)}</span>{' '}
                                        {herd.name} {herd.size}
                                    </strong>
                                </FarmLink>
                            ))
                        ) : (
                            <em className="block mb-5 text-gray-550">
                                {t('note_no_herds_placeholder')}
                            </em>
                        )}
                    </div>
                    {note.dueDate && (
                        <>
                            <h4>{t('create_task_due_date_title_set')}</h4>
                            <p>
                                {t('due_date', { sprintf: [format(note.dueDate, 'do MMMM yyyy')] })}
                            </p>
                        </>
                    )}
                    {farmUsers && note.taggedUserIds.length > 0 && (
                        <>
                            <h4>{t('activity_log_tagged')}</h4>
                            <p>
                                {farmUsers
                                    .filter((farmUser) => note.taggedUserIds.includes(farmUser.id))
                                    .map((farmUser) => getDisplayName(farmUsers, farmUser.id))
                                    .join(', ')}
                            </p>
                        </>
                    )}
                    <h4>{t('activity_log_year')}</h4>
                    <p>{note.year}</p>
                    {chatLog.dayChatGroups.size > 0 && (
                        <div className="w-96">
                            <h4>{t('activity_log_comments')}</h4>
                            <DiscussionList chatLog={chatLog} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withPageNote(
    connect((state: AppState, { note }: { note?: Note }) => ({
        farm: selectCurrentFarm(state),
        farmUsers: selectDisplayableFarmUsers(state),
        attachedFields: notNil(note?.fieldUuids)
            ? selectFieldsWithFullNames(state).filter((field) =>
                  note?.fieldUuids.includes(field.uuid)
              )
            : List<Field>(),
        attachedHerds:
            selectHerdsSortedByName(state)?.filter((herd) => note?.herdUuids.includes(herd.uuid)) ??
            List<Herd>(),
        noteMedia: note !== undefined ? state.mediaState.get(note.uuid) : [],
        noteDiscussions: note !== undefined ? state.discussionsState.get(note.uuid) : [],
    }))(NotePrintPage)
);
