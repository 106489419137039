import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';

const ItemNotFound = ({ children, backPath }: PropsWithChildren<{ backPath: string }>) => {
    const { t } = useTranslation();
    return (
        <div>
            <SidebarHeader>
                <SidebarHeaderLink to={backPath}>
                    {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                </SidebarHeaderLink>
            </SidebarHeader>
            <SidebarModule editing noBorder>
                {children}
            </SidebarModule>
        </div>
    );
};

export default ItemNotFound;
