import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useParams } from 'react-router-dom';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import { selectFieldsWithFullNames } from 'fields/fields-selectors';
import { arrayToMapWithUuid } from 'lib/fp-helpers';
import type Note from 'notes/Note';
import { addNotes } from 'notes/notes-state';
import { useAppDispatch, useAppSelector } from 'system/store';
import { selectDisplayableFarmUsers } from 'team/farm-users-state';
import UpgradeButton from 'upgrade/UpgradeButton';
import fmLogo from 'view/system/logo/fm-logo.svg';

import type { HerdHistoryItem } from './sidebar/details/herd-history-generator';
import { generateHerdHistory, loadHerdHistory } from './sidebar/details/herd-history-generator';
import HerdHistoryMergeItem from './sidebar/details/HerdHistoryMergeItem';
import HerdHistoryMoveItem from './sidebar/details/HerdHistoryMoveItem';
import HerdHistoryNewFromSplitItem from './sidebar/details/HerdHistoryNewFromSplitItem';
import HerdHistoryNoteItem from './sidebar/details/HerdHistoryNoteItem';
import HerdHistoryRemoveItem from './sidebar/details/HerdHistoryRemoveItem';
import HerdHistorySizeChangeItem from './sidebar/details/HerdHistorySizeChangeItem';
import type { HerdEvent, HerdLocation } from './Herd';

const HerdHistoryPage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const herdUuid = useParams<{ herdUuid: string }>().herdUuid;
    const farm = useAppSelector(selectCurrentFarm);
    const herd = useAppSelector((state) =>
        state.herdsState.herds?.find((herd) => herd.uuid === herdUuid)
    );
    const fields = useAppSelector(selectFieldsWithFullNames);
    const farmUsers = useAppSelector(selectDisplayableFarmUsers);
    const [herdHistory, setHerdHistory] = useState<HerdHistoryItem[]>();
    const { pending, setPromise } = usePromise<{
        herdHistories: HerdEvent[];
        herdLocations: HerdLocation[];
        notes: Note[];
    }>(({ herdHistories, herdLocations, notes }) => {
        dispatch(addNotes(arrayToMapWithUuid(notes)));
        setHerdHistory(generateHerdHistory(herdHistories, herdLocations, fields, notes));
    });

    useEffect(() => {
        if (!herd) return;

        setPromise(loadHerdHistory(farm.uuid, herd));
    }, [herd]);

    if (herd === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/livestock`} />;
    }

    const items = herdHistory
        ? herdHistory.map((item, i) => {
              switch (item.type) {
                  case 'new-from-split':
                      return (
                          <HerdHistoryNewFromSplitItem
                              key={i}
                              herd={herd}
                              historyItem={item}
                              farmUsers={farmUsers}
                          />
                      );
                  case 'move':
                      return (
                          <HerdHistoryMoveItem
                              key={i}
                              herd={herd}
                              historyItem={item}
                              farmUsers={farmUsers}
                          />
                      );
                  case 'remove':
                      return (
                          <HerdHistoryRemoveItem
                              key={i}
                              herd={herd}
                              historyItem={item}
                              farmUsers={farmUsers}
                          />
                      );
                  case 'size-update':
                      return (
                          <HerdHistorySizeChangeItem
                              key={i}
                              herd={herd}
                              historyItem={item}
                              farmUsers={farmUsers}
                          />
                      );
                  case 'merge':
                      return <HerdHistoryMergeItem key={i} herd={herd} historyItem={item} />;
                  case 'note':
                      return (
                          <HerdHistoryNoteItem key={i} historyItem={item} farmUsers={farmUsers} />
                      );
                  default:
                      null;
              }
          })
        : null;

    return (
        <div className="bg-white p-8 h-content-full print:h-auto w-screen overflow-auto">
            <div className="no-print flex items-center">
                <Link
                    className="flex items-center font-bold mr-8"
                    to={`/farms/${farm.uuid}/livestock/${herd?.uuid}`}
                >
                    {icon('back', 'dark-blue', 'mr-2')} {t('back')}
                </Link>
                <Button onClick={() => print()} className="flex items-center">
                    {icon('print', 'white', 'mr-3')} {t('print')}
                </Button>
            </div>
            {!farm.plan.domainPrinting ? (
                <>
                    <p className="mt-4">
                        {t('herd_history_printing_upgrade', {
                            defaultValue:
                                'Livesstock history printing is not allowed on the %s plan.',
                            sprintf: [farm.plan.displayName],
                        })}
                    </p>
                    <p>
                        <UpgradeButton />
                    </p>
                </>
            ) : (
                <>
                    {pending && (
                        <p>
                            <em>{t('loading')}</em>
                        </p>
                    )}
                    <img
                        alt="fieldmargin logo"
                        className="h-8 hidden print:block print:mb-4"
                        src={fmLogo}
                    />
                    <h1 className="text-lg bordered-b pb-4">{herd.name}</h1>
                    <h2 className="text-base mb-1">{t('field_details_history')}</h2>
                    <ul className="p-5 m-0 list-none text-fm-blue">{items}</ul>
                </>
            )}
        </div>
    );
};

export default HerdHistoryPage;
