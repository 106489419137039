import type { Activity } from 'activity/activity-helper';
import {
    activityIsOperation,
    getActivityArchived,
    getActivityFields,
    getActivityName,
    getActivityReadStatus,
    getURL,
} from 'activity/activity-helper';
import ActivityIcon from 'activity/ActivityIcon';
import { clsx } from 'clsx';
import { SidebarCard } from 'components/sidebar-card';
import { selectFieldsWithFullNames } from 'fields/fields-selectors';
import type FullOperation from 'operations/FullOperation';
import OperationCompletionCount from 'operations/sidebar/OperationCompletionCount';
import { useAppSelector } from 'system/store';

import NotePreviewDueDate from './preview/NotePreviewDueDate';
import NotePreviewFields from './preview/NotePreviewFields';
import NotePreviewSummary from './preview/NotePreviewSummary';

import './NotePreview.css';

interface NotePreviewProps {
    activity: Activity;
}

const NotePreview = ({ activity }: NotePreviewProps) => {
    const attachedFields = useAppSelector((state) =>
        getActivityFields(activity, selectFieldsWithFullNames(state)).toArray()
    );
    return (
        <SidebarCard.Root
            className={clsx({
                'bg-info': !getActivityReadStatus(activity),
                'opacity-50': getActivityArchived(activity),
            })}
        >
            <SidebarCard.Link to={getURL(activity)}>
                <div className="NotePreview">
                    <div className="NotePreviewRow">
                        <ActivityIcon activity={activity} />
                        <strong className="NotePreviewName">{getActivityName(activity)}</strong>
                        {activityIsOperation(activity) && (
                            <OperationCompletionCount fullOperation={activity as FullOperation} />
                        )}
                    </div>
                    <NotePreviewFields fields={attachedFields} />
                    <span className="flex justify-between">
                        <NotePreviewSummary activity={activity} />
                        <NotePreviewDueDate activity={activity} />
                    </span>
                </div>
            </SidebarCard.Link>
        </SidebarCard.Root>
    );
};

export default NotePreview;
