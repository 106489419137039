import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import type Field from 'fields/Field';
import { selectFieldUsagesWithNotSet } from 'fields/field-usage-selectors';
import { selectFieldPreviousSubFields } from 'fields/fields-selectors';
import type FieldUsage from 'fields/FieldUsage';
import { copyPreviousSubFieldField } from 'fields/sub-field-utils';
import { setSubFields } from 'fields/sub-fields-state';
import type { List } from 'immutable';
import type { AppState } from 'system/store';
import { useAppDispatch, useAppSelector } from 'system/store';

import SubFieldsUsePreviousModal from '../modal/SubFieldsUsePreviousModal';
import { convertFieldToSubField } from '../SubField';

interface SubFieldsUsePreviousProps {
    parentField: Field;
}

const SubFieldsUsePrevious = ({ parentField }: SubFieldsUsePreviousProps) => {
    const { t } = useTranslation();
    const [showModal, toggleModal] = useToggle(false);
    const dispatch = useAppDispatch();
    const previousSubFields = useAppSelector((state) =>
        selectFieldPreviousSubFields(state, parentField.uuid)
    );
    const fieldUsages = useSelector<AppState, List<FieldUsage>>(selectFieldUsagesWithNotSet);
    const year = useSelector<AppState, number | null>((state) => state.yearsState.currentYear);

    if (previousSubFields.size === 0 || year === null) {
        return null;
    }

    const handleClick = (e?: MouseEvent) => {
        e && e.preventDefault();
        !showModal && toggleModal();
    };

    const handleDone = (subFields: List<Field>, copyUsages: boolean) => {
        dispatch(
            setSubFields(
                subFields
                    .map((subField) =>
                        convertFieldToSubField(
                            copyPreviousSubFieldField(subField, year, copyUsages)
                        )
                    )
                    .sortBy((subField) => subField.field.name.toLowerCase())
            )
        );
    };

    return (
        <>
            {showModal && (
                <SubFieldsUsePreviousModal
                    onClose={toggleModal}
                    onDone={handleDone}
                    parentField={parentField}
                    previousSubFields={previousSubFields}
                    fieldUsages={fieldUsages}
                />
            )}
            <Button variant="outline" onClick={handleClick}>
                {t('sub_fields_use_previous')}
            </Button>
        </>
    );
};

export default SubFieldsUsePrevious;
