import { useEffect } from 'react';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import type { Set } from 'immutable';
import { useField } from 'informed';
import ErrorMessage from 'view/ErrorMessage';

interface ShapeAndFieldsFieldProps {
    shapes?: GeoFeatureCollection;
    fields?: Set<string>;
    required?: boolean;
}

/**
 * This provides a form field to Informed, that allows shapes to be validated as part of a
 * larger form. It does not provide a way for the user to alter shapes, it just validates
 * those provided to it.
 *
 * Validation fails if no shapes or fields are passed to it unless the required prop is false.
 *
 * It will show an error message, nothing else.
 */
const ShapeAndFieldsField = ({ shapes, fields, required }: ShapeAndFieldsFieldProps) => {
    const hasShapes = shapes && shapes.features.size > 0;
    const hasFields = fields && fields.size > 0;

    const { fieldState, fieldApi } = useField<{
        shapes?: GeoFeatureCollection;
        fields?: Set<string>;
    }>({
        field: 'shapes',
        initialValue: { shapes, fields },
        validate: () => {
            return required === false
                ? undefined
                : !hasShapes && !hasFields
                  ? 'Required'
                  : undefined;
        },
    });

    const { value, error } = fieldState;
    const { setValue } = fieldApi;

    useEffect(() => {
        if (value.shapes !== shapes || value.fields !== fields) {
            setValue({ shapes, fields });
        }
    }, [shapes, fields]);

    return error && !(hasShapes || hasFields) ? (
        <ErrorMessage className="mt-3">{error}</ErrorMessage>
    ) : null;
};

export default ShapeAndFieldsField;
