import { useOutletContext } from 'react-router-dom';
import type Feature from 'features/Feature';

export interface FeaturesContextSchema {
    feature: Feature;
}

const useFeaturesContext = () => {
    return useOutletContext<FeaturesContextSchema>();
};

export { useFeaturesContext };
