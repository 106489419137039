import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { GeoPoint } from '@fieldmargin/webapp-geo';
import { serialize } from '@fieldmargin/webapp-geo';
import { List, Map } from 'immutable';
import { listToMap } from 'lib/immutil';
import type { FarmIntegrationSensorsDTO } from 'sensors/FarmIntegrationSensors';
import { deserializeFarmIntegrationSensors } from 'sensors/FarmIntegrationSensors';
import type { HistoryReadingDTO } from 'sensors/HistoryReading';
import { deserializeHistoryReading } from 'sensors/HistoryReading';
import SensorHistory from 'sensors/SensorHistory';
import type { VehicleDTO } from 'vehicle-tracking/Vehicle';
import { deserializeVehicle } from 'vehicle-tracking/Vehicle';

import type { IntegrationDataResponseDTO } from './IntegrationDataResponse';
import { deserializeIntegrationDataResponse } from './IntegrationDataResponse';

export const fetchVehicleLocationsApi = (farmUuid: string) => {
    return authorizedRequest<{ updated_at: string | number; vehicle: VehicleDTO }[]>({
        method: 'get',
        url: `/integration-api/vehicles/${farmUuid}/vehicle-locations`,
    }).then((locations) => List(locations.map(deserializeVehicle)));
};

export const fetchSensorsLatestApi = (farmUuid: string) => {
    return authorizedRequest<FarmIntegrationSensorsDTO[]>({
        url: `/sensor-api/v1/farms/${farmUuid}/sensors-data/latest-sensors-summary`,
    }).then((sensors) =>
        listToMap(
            List(sensors.map(deserializeFarmIntegrationSensors)),
            (i) => i.farmIntegrationUuid
        )
    );
};

export const fetchSensorHistoryApi = (
    farmUuid: string,
    farmIntegrationUuid: string,
    sensorId: string,
    metricId: string,
    view: string
) => {
    return authorizedRequest<IntegrationDataResponseDTO<{ history: HistoryReadingDTO[] }>>({
        url: `/sensor-api/v1/farms/${farmUuid}/sensors-data/sensor-history`,
        params: {
            'farm-integration-uuid': farmIntegrationUuid,
            'sensor-uuid': sensorId,
            'metric-id': metricId,
            view,
        },
    }).then((data) => {
        let result = null as Map<string, SensorHistory> | null;
        if (data.result) {
            const readings = List(
                data.result.history.map((reading) => deserializeHistoryReading(reading))
            );
            result = Map({
                history: SensorHistory({
                    farmIntegrationUuid,
                    period: view,
                    readings,
                }),
            });
        }
        return deserializeIntegrationDataResponse<SensorHistory>(data, result);
    });
};

export interface SaveSensorOverridesResponse {
    farmIntegrationUuid: string;
    sensorId: string;
    overrideName: string;
    overrideLocation: GeoPoint;
}
export const setSensorOverridesApi = (
    farmUuid: string,
    farmIntegrationUuid: string,
    sensorId: string,
    name: string,
    location: GeoPoint
): Promise<SaveSensorOverridesResponse> => {
    return authorizedRequest({
        method: 'POST',
        url: `/sensor-api/v1/farms/${farmUuid}/user-overrides/set-sensor-overrides`,
        data: {
            sensorUUID: sensorId,
            name: name,
            location: serialize(location),
        },
    }).then(() => ({
        farmIntegrationUuid,
        sensorId,
        overrideName: name,
        overrideLocation: location,
    }));
};
