import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { AppState } from 'system/store';

import type Herd from './Herd';
import { selectHerd } from './herd-selectors';

const withPageHerd =
    <Props,>(Component: React.ComponentType<Props>) =>
    (props: Props) => {
        const herdUuid = useParams<{ herdUuid: string }>().herdUuid;
        const herd =
            herdUuid !== undefined
                ? useSelector<AppState, Herd | undefined>((state) => selectHerd(state, herdUuid))
                : undefined;
        return <Component {...props} herd={herd} />;
    };

export default withPageHerd;
