import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import UpgradeButton from 'upgrade/UpgradeButton';
import UpgradeLink from 'upgrade/UpgradeLink';

import reportingEmpty from './reporting-empty.png';

const ReportingUpgrade = () => {
    const { t } = useTranslation();
    return (
        <div className="mx-auto my-8 text-2xl text-center">
            <Markdown components={{ a: () => <UpgradeLink className="no-underline" /> }}>
                {t('reporting_upgrade_title')}
            </Markdown>
            <p className="w-64 mx-auto">
                <UpgradeButton className="w-full" />
            </p>
            <img
                src={reportingEmpty}
                alt="Screenshot of reporting in fieldmargin"
                className="mx-auto"
            />
        </div>
    );
};

export default ReportingUpgrade;
