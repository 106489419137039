import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import c from 'classnames';
import { selectCurrentFarm } from 'farms/farms-state';
import { useCurrentPageLocation } from 'hooks/useCurrentPageLocation';
import { useAppDispatch, useAppSelector } from 'system/store';
import { UIContext } from 'system/ui-state';
import InfoMsg from 'view/InfoMsg';
import Arrow from 'view/svg/Arrow';
import { changeCurrentYear, selectCurrentYear } from 'years/years-state';

import ListDropdown from './ListDropdown';
import ListDropdownItem from './ListDropdownItem';
import ListDropdownLink from './ListDropdownLink';

import './YearChooser.css';

const YearChooser = () => {
    const dispatch = useAppDispatch();
    const currentFarm = useAppSelector(selectCurrentFarm);
    const currentYear = useAppSelector(selectCurrentYear);
    const years = useAppSelector((state) => state.yearsState.years);
    const { t } = useTranslation();
    const { currentPageLocation } = useCurrentPageLocation();

    const { infoOpen } = useContext(UIContext);
    const [open, toggleOpen] = useToggle(false);

    const handleChangeYear = (year: number) => {
        dispatch(changeCurrentYear({ farmUuid: currentFarm.uuid, year }));
        toggleOpen();
    };

    const handleCurrentClick = () => {
        toggleOpen();
    };

    if (years) {
        return (
            <div
                className={c('relative flex items-center justify-center self-stretch w-28', {
                    forefront: infoOpen,
                })}
            >
                {infoOpen && (
                    <InfoMsg className="top-16 left-6 w-full flex-col">
                        <Arrow className="w-12 mb-3" />
                        {t('info_years')}
                    </InfoMsg>
                )}
                <button
                    className="bare-btn focus CurrentItem"
                    onClick={handleCurrentClick}
                    data-dropdown="year-chooser"
                >
                    <span className="block mr-2 max-w-36 truncate">{currentYear}</span>
                    <i className={open ? 'ion-arrow-up-b' : 'ion-arrow-down-b'} />
                </button>
                {open && (
                    <ListDropdown
                        onClose={handleCurrentClick}
                        small
                        className="YearChooser"
                        name="year-chooser"
                    >
                        {years.map((year) => (
                            <ListDropdownItem key={year}>
                                <ListDropdownLink
                                    to={currentPageLocation}
                                    onClick={() => handleChangeYear(year)}
                                    selected={year === currentYear}
                                >
                                    {year}
                                </ListDropdownLink>
                            </ListDropdownItem>
                        ))}
                    </ListDropdown>
                )}
            </div>
        );
    }
    return null;
};

export default YearChooser;
