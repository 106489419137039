import { useEffect, useRef } from 'react';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoPoint } from '@fieldmargin/webapp-geo';
import { createGeoFeatureWithId, deserializeLngLat } from '@fieldmargin/webapp-geo';
import type { FeaturesContollerData } from '@fieldmargin/webapp-ol-map';
import { BasemapController, OpenLayersMap } from '@fieldmargin/webapp-ol-map';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import PointFeatureController from 'components/maps/openlayers/PointFeatureController';
import { getMapKeyConfig } from 'lib/config';
import { uniqueId } from 'lodash';
import { getSatelliteBasemap } from 'system/basemaps/basemaps';

import './FarmInvitationMap.css';

let viewportActionId = 0;

interface FarmInvitationMapProps {
    farm: Farm;
    maxZoom: number;
}

const FarmInvitationMap = ({ farm, maxZoom }: FarmInvitationMapProps) => {
    const basemap = getSatelliteBasemap();
    const hostElementId = useRef(uniqueId('non-editable-map-')).current;
    const mapRef = useRef<OpenLayersMap<{ basemap: string; point: FeaturesContollerData }> | null>(
        null
    );

    const farmCentre = deserializeLngLat(farm.geoJsonPoint) as GeoPoint;
    const geoFeature = createGeoFeatureWithId(
        farmCentre,
        OLFeatureProperties({
            type: 'feature',
            colour: '#e7bb35',
            pointScale: 0.2,
            strokeWeight: 2,
            strokeOpacity: 1,
            zIndex: 1,
        })
    );

    useEffect(() => {
        mapRef.current = new OpenLayersMap(
            {
                hostElementId,
                viewport: {
                    zoom: 15,
                    maxZoom,
                    centre: farmCentre,
                },
                controls: [],
            },
            {
                basemap: new BasemapController([basemap], getMapKeyConfig(), 1, {
                    language: window.navigator?.language ?? 'en-GB',
                    country: farm.country,
                }),
                point: new PointFeatureController(2),
            },
            {}
        );
        mapRef.current.update({
            basemap,
            point: { geoFeatures: [geoFeature], options: {} },
        });
    }, []);
    useEffect(() => {
        mapRef.current?.update(
            {
                basemap,
                point: { geoFeatures: [geoFeature], options: {} },
            },
            {
                id: viewportActionId++,
                position: {
                    centre: farmCentre,
                    zoom: 15,
                },
            }
        );
    }, [farm]);
    return (
        <div className="FarmInvitationMap">
            <div id={hostElementId} style={{ width: '100%', height: '100%' }} />
        </div>
    );
};

export default FarmInvitationMap;
