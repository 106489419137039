import { useToggle } from '@fieldmargin/webapp-state';
import classNames from 'classnames';
import type { List } from 'immutable';
import type { InputReportSummary } from 'inputs/InputReportSummary';
import { capitaliseWord } from 'lib/util/text';
import type FullOperation from 'operations/FullOperation';
import ClickableRow from 'view/a11y/ClickableRow';
import Num from 'view/Num';
import FormattedRate from 'view/units/FormattedRate';
import ShortUnit from 'view/units/ShortUnit';

import FieldInputReportOperationRow from './FieldInputReportOperationRow';

interface FieldInputReportInputRowProps {
    fieldUuid: string;
    input: InputReportSummary;
    operations: List<FullOperation>;
}

const FieldInputReportInputRow = ({
    fieldUuid,
    input,
    operations,
}: FieldInputReportInputRowProps) => {
    const [open, toggleOpen] = useToggle(false);

    return (
        <>
            <ClickableRow onClick={toggleOpen}>
                <td>{input.name}</td>
                <td>{capitaliseWord(input.inputType)}</td>
                <td>{input.pesticideData?.registrationNumber}</td>
                <td>{input.pesticideData?.actives.map((active) => active.name).join(', ')}</td>
                <td>{input.noOfApplication}</td>
                <td>
                    <FormattedRate rate={input.sumRate} noConversion />{' '}
                    <ShortUnit unit={input.inputUnit} />
                </td>
                <td>
                    <FormattedRate rate={input.sumTotals} noConversion />{' '}
                    <ShortUnit unit={input.inputUnit} />
                </td>
                <td>{input.cost && <Num value={input.cost} />}</td>
                <td>
                    <Num value={input.totalCost} />
                    <i
                        className={classNames(
                            'float-right ml-2 cursor-pointer',
                            open ? 'ion-chevron-up' : 'ion-chevron-down'
                        )}
                    />
                </td>
            </ClickableRow>
            {open &&
                operations.map((operation) => (
                    <FieldInputReportOperationRow
                        key={operation.uuid}
                        fieldUuid={fieldUuid}
                        input={input}
                        operation={operation}
                    />
                ))}
        </>
    );
};

export { FieldInputReportInputRow };
