import { connect } from 'react-redux';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { rememberCurrentLocation } from 'system/ui-state';

interface RememberingLinkProps extends LinkProps {
    rememberCurrentLocation: typeof rememberCurrentLocation;
}

/**
 * This component can be used to remember the current location of the user so that they can be
 * returned to it later via a ReturningLink or ReturningLinkButton
 */
const RememberingLink = ({ children, rememberCurrentLocation, ...props }: RememberingLinkProps) => {
    const handleClick = () => {
        rememberCurrentLocation();
    };
    return (
        <Link onClick={handleClick} {...props}>
            {children}
        </Link>
    );
};

export default connect(
    () => ({}),
    (dispatch) =>
        bindActionCreators(
            {
                rememberCurrentLocation,
            },
            dispatch
        )
)(RememberingLink);
