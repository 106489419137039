import { useNavigate } from 'react-router-dom';
import type { FarmMapFeature } from 'farm-map/types';
import { selectCurrentFarm } from 'farms/farms-state';
import { useAppSelector } from 'system/store';

export const useFarmMapNoteClick = () => {
    const navigate = useNavigate();
    const farmUuid = useAppSelector(selectCurrentFarm).uuid;

    return (feature: FarmMapFeature) => {
        if (feature.properties.type !== 'NOTE') {
            return false;
        }
        navigate(`/farms/${farmUuid}/notes/${feature.properties.uuid}`);
        return true;
    };
};
