import { useTranslation } from 'react-i18next';

import { useFeatureHistory } from './feature-history-generator';
import FeatureHistoryDay from './FeatureHistoryDay';
import { useFeaturesContext } from './useFeaturesContext';

const FeatureHistory = () => {
    const { t } = useTranslation();
    const { feature } = useFeaturesContext();
    const featureHistoryByDay = useFeatureHistory(feature);

    return (
        <div className="FeatureHistory py-5 flex flex-col">
            {featureHistoryByDay.size === 0 && <p className="px-5">{t('feature_history_empty')}</p>}
            {featureHistoryByDay.map((history, index) => (
                <FeatureHistoryDay key={index} history={history} />
            ))}
        </div>
    );
};

export default FeatureHistory;
