import type { PropsWithChildren } from 'react';
import c from 'classnames';

const TabNavItem = ({
    children,
    active = false,
    activeClass = 'border-gray-border',
    className,
}: PropsWithChildren<{ active?: boolean; activeClass?: string; className?: string }>) => {
    return (
        <li
            className={c(
                'border-solid border-0 border-b-2 px-0',
                className,
                active ? activeClass : 'border-transparent'
            )}
        >
            {children}
        </li>
    );
};

export default TabNavItem;
