import { connect } from 'react-redux';
import { toggleHiddenFieldUsage } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { getFieldWorkedArea } from 'fields/Field';
import { selectFieldUsagesWithNotSet } from 'fields/field-usage-selectors';
import type { FieldUsageWithFields } from 'fields/fields-selectors';
import { selectMaybeVisibleMaybeArchivedFieldUsagesWithFieldsGroupedByArchived } from 'fields/fields-selectors';
import type FieldUsage from 'fields/FieldUsage';
import { asTypeItem } from 'fields/FieldUsage';
import type { List } from 'immutable';
import { usePersistedItemSelectedState } from 'lib/hooks';
import { bindActionCreators } from 'redux';
import TypeGroupHeader from 'sidebar/modules/TypeGroupHeader';
import type LayerVisibility from 'system/editing/LayerVisibility';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { selectCurrentYear } from 'years/years-state';

import FieldPreview from '../FieldPreview';

import FieldListArchived from './FieldListArchived';
import FieldListFilterMessage from './FieldListFilterMessage';

interface FieldListFieldsByUsageProps {
    // From redux
    year: number;
    fieldUsages: List<FieldUsage>;
    fieldUsagesWithFields: {
        active: List<FieldUsageWithFields>;
        archived: List<FieldUsageWithFields>;
    };
    layerVisibility: LayerVisibility;
    toggleHiddenFieldUsage: typeof toggleHiddenFieldUsage;
}

const FieldListFieldsByUsage = ({
    year,
    fieldUsages,
    fieldUsagesWithFields,
    layerVisibility,
    toggleHiddenFieldUsage,
}: FieldListFieldsByUsageProps) => {
    const [toggleClosedFieldUsage, closedFieldUsageIncludes] =
        usePersistedItemSelectedState('closedFieldUsages');

    const [toggleClosedArchivedUsages, closedArchivedUsageIncludes] =
        usePersistedItemSelectedState('closedArchivedUsages');

    const handleVisibilityClick = (id: string) => {
        toggleHiddenFieldUsage({
            fieldUsageUuid: id,
            value: !layerVisibility.hiddenFieldUsages.contains(id),
            allFieldUsages: fieldUsages,
        });
    };

    const activeItems = fieldUsagesWithFields.active.map(({ fieldUsage, fields }) => (
        <FieldUsageGroup
            key={fieldUsage.uuid}
            year={year}
            isOpen={!closedFieldUsageIncludes(fieldUsage.uuid)}
            fieldUsage={fieldUsage}
            fields={fields}
            layerVisibility={layerVisibility}
            onVisiblityClick={handleVisibilityClick}
            onClick={toggleClosedFieldUsage}
        />
    ));

    const archivedItems = fieldUsagesWithFields.archived.map(({ fieldUsage, fields }) => (
        <FieldUsageGroup
            key={fieldUsage.uuid}
            year={year}
            isOpen={!closedArchivedUsageIncludes(fieldUsage.uuid)}
            fieldUsage={fieldUsage}
            fields={fields}
            layerVisibility={layerVisibility}
            onVisiblityClick={handleVisibilityClick}
            onClick={toggleClosedArchivedUsages}
            archived
        />
    ));

    return (
        <>
            <FieldListFilterMessage
                filteredFields={fieldUsagesWithFields.active
                    .concat(fieldUsagesWithFields.archived)
                    .flatMap(({ fields }) => fields)}
            />
            {activeItems.size > 0 && activeItems}
            {archivedItems.size > 0 && <FieldListArchived>{archivedItems}</FieldListArchived>}
        </>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        year: selectCurrentYear(state),
        fieldUsages: selectFieldUsagesWithNotSet(state),
        fieldUsagesWithFields:
            selectMaybeVisibleMaybeArchivedFieldUsagesWithFieldsGroupedByArchived(state),
        layerVisibility: state.farmEditingState.layerVisibility,
    }),
    (dispatch) => bindActionCreators({ toggleHiddenFieldUsage }, dispatch)
)(FieldListFieldsByUsage);

interface FieldUsageGroupProps {
    year: number;
    isOpen: boolean;
    fieldUsage: FieldUsage;
    fields: List<Field>;
    layerVisibility: LayerVisibility;
    onVisiblityClick: SingleParamVoidFunction<string>;
    onClick: SingleParamVoidFunction<string>;
    archived?: boolean;
}

const FieldUsageGroup = ({
    year,
    isOpen,
    fieldUsage,
    fields,
    layerVisibility,
    onVisiblityClick,
    onClick,
    archived = false,
}: FieldUsageGroupProps) => {
    const fieldItems = fields.map((field) => <FieldPreview key={field.uuid} field={field} />);
    const isItemVisible = !layerVisibility.hiddenFieldUsages.contains(fieldUsage.uuid);
    // Visible if fields are visible and the item itself is visible
    const isVisible = isItemVisible && layerVisibility.fieldsVisibility !== 'off';
    const area = fields.reduce((total, field) => total + getFieldWorkedArea(field, year), 0);
    return (
        <div className="bordered-t">
            <TypeGroupHeader
                typeItem={asTypeItem(fieldUsage)}
                sizes={{ area, points: 0, length: 0 }}
                isVisible={isVisible}
                onVisibilityClick={onVisiblityClick}
                isOpen={isOpen}
                onClick={onClick}
                className={archived ? 'opacity-50' : ''}
            >
                {fieldItems}
            </TypeGroupHeader>
        </div>
    );
};
