import type { ComponentProps } from 'react';

function PlusIcon({ fill = 'currentColor', ...props }: ComponentProps<'svg'>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={14}
            height={24}
            viewBox="0 0 14 24"
            {...props}
        >
            <path
                d="M1350 13h-5v5h-2v-5h-5v-2h5V6h2v5h5v2Z"
                fill={fill}
                transform="translate(-1337)"
            />
        </svg>
    );
}
export { PlusIcon };
