import type { CustomMapSpec } from 'farm-map/types';
import { VegetationMapsVisibility } from 'maps/vegetation/vegetation-maps-state';
import { selectSentinelHubTileSets } from 'maps/vegetation/vegetation-maps-util';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

export const selectSentinelHubMaps = createSelector(
    (state: AppState) => state.vegetationMapsState.visibility,
    (state: AppState) => state.vegetationMapsState.visibleSentinelHubMap,
    (state: AppState) => state.vegetationMapsState.visibleStyle,
    selectSentinelHubTileSets,
    (visibility, visibleSentinelHubMap, visibleStyle, sentinelHubTileSets): CustomMapSpec[] => {
        if (
            visibility === VegetationMapsVisibility.OFF ||
            !visibleSentinelHubMap ||
            !sentinelHubTileSets
        ) {
            return [];
        }

        return sentinelHubTileSets
            .map((tiles, i) => ({
                id: `${visibleSentinelHubMap.id}_${visibleStyle}_${i}`,
                tiles: tiles,
                opacity: 1,
                zIndex: 0,
            }))
            .toArray();
    }
);
