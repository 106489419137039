import { apiSlice } from 'api/base.api';
import type { Polygon } from 'geojson';

interface FarmUuidArg {
    farmUuid: string;
}

interface BeginAuthArgs extends FarmUuidArg {
    returnUrl: string;
}

interface ArgsConnect extends FarmUuidArg {
    jdOrganizationId: string;
}

interface ResponseWithIdAndName {
    id: string;
    name: string;
}

interface JDFieldsResponse extends ResponseWithIdAndName {
    boundary: Polygon;
}

interface ConnectJDResponse {
    farmUUID: string;
    jdOrganisationId: string;
    connectionFeatures: ['vehicle-tracking'];
    jdOrganisationName: string;
}

interface ToggleVehicleTrackingArgs extends FarmUuidArg {
    isEnabled: boolean;
}

interface JDFarmFieldsArgs extends FarmUuidArg {
    jdFarmId: string;
}

interface ConnectedJDFarmResponse {
    connected: boolean;
    farmConnection: ConnectJDResponse;
}

interface ImportJDFieldArgs extends JDFarmFieldsArgs {
    jdFieldId: string;
}

export const johnDeereApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        isJDAuthenticated: builder.query<boolean, void>({
            query: () => ({
                url: '/john-deere-api/v1/oAuth2/isAuthenticated',
            }),
        }),

        beginOAuth2Flow: builder.query<string, BeginAuthArgs>({
            query: ({ farmUuid, returnUrl }) => ({
                url: `/john-deere-api/v1/oAuth2/beginOAuthFlow`,
                responseHandler: 'text',
                params: {
                    farmUuid,
                    clientCallbackUrl: returnUrl,
                },
            }),
        }),

        jdOrganizations: builder.query<ResponseWithIdAndName[], void>({
            query: () => ({
                url: '/john-deere-api/v1/user/getJDOrganizations',
            }),
        }),

        connectJDOrg: builder.mutation<ConnectJDResponse, ArgsConnect>({
            query: ({ farmUuid, jdOrganizationId }) => ({
                url: '/john-deere-api/v1/user/connect',
                method: 'POST',
                body: {
                    farmUUID: farmUuid,
                    jdOrganizationId,
                },
            }),
            invalidatesTags: ['FARM_CONNECTION'],
        }),

        connectedJDFarm: builder.query<ConnectedJDFarmResponse, FarmUuidArg>({
            query: ({ farmUuid }) => ({
                url: `/john-deere-api/v1/farm/${farmUuid}/isConnected`,
            }),
            providesTags: ['FARM_CONNECTION'],
        }),

        toggleVehicleTracking: builder.mutation<boolean, ToggleVehicleTrackingArgs>({
            query: ({ farmUuid, isEnabled }) => ({
                method: 'POST',
                url: `/john-deere-api/v1/farm/${farmUuid}/set-vehicle-tracking`,
                params: {
                    enabled: isEnabled,
                },
            }),
            invalidatesTags: ['FARM_CONNECTION'],
        }),

        disconnectFarmConnection: builder.mutation<void, FarmUuidArg>({
            query: ({ farmUuid }) => ({
                method: 'POST',
                url: `/john-deere-api/v1/farm/${farmUuid}/disconnect`,
            }),
            invalidatesTags: ['FARM_CONNECTION'],
        }),

        getJDFarms: builder.query<ResponseWithIdAndName[], FarmUuidArg>({
            query: ({ farmUuid }) => ({
                url: `/john-deere-api/v1/farm/${farmUuid}/getJDFarms`,
            }),
        }),

        getJDFields: builder.query<JDFieldsResponse[], JDFarmFieldsArgs>({
            query: ({ farmUuid, jdFarmId }) => ({
                url: `/john-deere-api/v1/farm/${farmUuid}/getJDFields`,
                params: {
                    jdFarmId,
                },
            }),
        }),

        importJDField: builder.mutation<boolean, ImportJDFieldArgs>({
            query: ({ farmUuid, jdFarmId, jdFieldId }) => ({
                method: 'POST',
                url: `/john-deere-api/v1/farm/${farmUuid}/importFieldBoundary`,
                params: {
                    jdFarmId,
                    jdFieldId,
                },
            }),
        }),
    }),
});

export const {
    useIsJDAuthenticatedQuery,
    useLazyBeginOAuth2FlowQuery,
    useJdOrganizationsQuery,
    useConnectJDOrgMutation,
    useConnectedJDFarmQuery,
    useToggleVehicleTrackingMutation,
    useDisconnectFarmConnectionMutation,
    useGetJDFarmsQuery,
    useLazyGetJDFieldsQuery,
    useImportJDFieldMutation,
} = johnDeereApi;
