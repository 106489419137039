export enum AttachmentType {
    FEATURE = 'feature',
    FIELD = 'field',
    HERD = 'herd',
}

export interface AttachmentItem {
    type: AttachmentType;
    id: string;
}

export const attachmentTypeIsField = (item: AttachmentItem) => item.type === AttachmentType.FIELD;
export const attachmentTypeIsFeature = (item: AttachmentItem) =>
    item.type === AttachmentType.FEATURE;

export const toAttachmentItem = (attachmentType: AttachmentType) => (id: string) => ({
    id,
    type: attachmentType,
});
