import type { Extent } from '@fieldmargin/webapp-geo';
import { Record } from 'immutable';

import type MapPosition from './MapPosition';

let nextMapViewId = 1;

const MapView = Record({
    id: nextMapViewId++,
    type: null as string | null, // 'full-extent', 'position', 'extent'
    payload: null as MapPosition | Extent | null, // null, MapPosition, Extent
});
interface MapView extends ReturnType<typeof MapView> {}

export interface MapViewSpec {
    type: string;
    payload?: MapPosition | Extent | null;
}

export const nextMapView = (spec: MapViewSpec) =>
    MapView({
        ...spec,
        id: nextMapViewId++,
    });

export default MapView;
