import { useTranslation } from 'react-i18next';
import { filterByScreenState, toggleFilterByScreen } from 'store/slices/notes.slice';
import { useAppDispatch, useAppSelector } from 'system/store';

import { FilterSelect } from '../FilterSelect';

function FilterByScreen() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const handleToggleFilterByScreen = () => {
        dispatch(toggleFilterByScreen());
    };
    const value = useAppSelector(filterByScreenState);

    return (
        <FilterSelect.Root>
            <FilterSelect.Checkbox isSelected={value} onChange={handleToggleFilterByScreen}>
                {t('filters_visibile_screen')}
            </FilterSelect.Checkbox>
        </FilterSelect.Root>
    );
}

export { FilterByScreen };
