import { createSelector } from '@reduxjs/toolkit';
import type { FieldToolTipControllerProps } from 'farm-map/controllers/FieldTooltipController';
import type { FarmMapFeature } from 'farm-map/types';
import { selectFieldUsagesWithNotSet } from 'fields/field-usage-selectors';
import {
    selectCurrentYearFieldsWithPreviousUsages,
    selectFieldsWithFullNames,
} from 'fields/fields-selectors';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import { selectCurrentYear } from 'years/years-state';

interface Props {
    isSettingBulkUsage: boolean;
    features: FarmMapFeature[];
}

/**
 * Select props for the FieldTooltipController.
 */
export const selectFieldTooltipProps = createSelector(
    (_state: AppState, props: Props) => props,
    selectCurrentYear,
    (state: AppState) => state.farmEditingState.hoveredGeoFeatureId,
    selectFieldsWithFullNames,
    selectFieldUsagesWithNotSet,
    selectCurrentYearFieldsWithPreviousUsages,
    selectUserAreaMeasurementUnit,
    (
        { isSettingBulkUsage, features },
        currentYear,
        hoveredGeoFeatureId,
        fields,
        fieldUsages,
        previousFieldUsages,
        areaMeasurementUnit
    ) => {
        const props: FieldToolTipControllerProps = {
            isSelectingFieldUsageFields: isSettingBulkUsage,
            areaMeasurementUnit,
            fieldUsages: fieldUsages.toArray(),
            previousUsages: [],
            currentYear,
        };
        if (hoveredGeoFeatureId === null) {
            return props;
        }
        const selectedFeature = features.find((feature) => feature.id === hoveredGeoFeatureId);

        const selectedField = fields.find(
            (field) => field.uuid === selectedFeature?.properties.uuid
        );

        if (selectedField) {
            props.feature = selectedFeature;
            props.field = selectedField;
            props.previousUsages = previousFieldUsages.get(selectedField.uuid)?.toArray() ?? [];
        }

        return props;
    }
);
