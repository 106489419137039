import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { removeEditingGeoFeatureById } from 'farm-editing/farm-editing-state';
import { useAppDispatch } from 'system/store';

import { useEditingGeoFeatureCollection } from './useEditingGeoFeatureCollection';

const useRemoveFieldBoundary = () => {
    const dispatch = useAppDispatch();
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();

    const handleRemoveBoundary = () => {
        if (!editingGeoFeatureCollection) return;

        if (editingGeoFeatureCollection.features.size > 0) {
            dispatch(
                removeEditingGeoFeatureById({
                    id: editingGeoFeatureCollection.features.first<GeoFeature>().id,
                    nextDrawingTool: null,
                })
            );
        }
    };

    return {
        onRemoveBoundary: handleRemoveBoundary,
    };
};

export { useRemoveFieldBoundary };
