/**
 * This state holds media objects that are attached to items.
 * It is a Map of item UUID to a list of media objects.
 */

import { createAction, handleActions } from '@fieldmargin/webapp-state';
import type { Discussion } from 'discussions/Discussion';
import { defaultToEmptyArray, mapSet } from 'lib/fp-helpers';
import type Note from 'notes/Note';
import { addNewNote } from 'notes/note-actions';

import type Media from './Media';

export type MediaState = Map<string, Media[]>;

export const setItemMedia = createAction<MediaState, { uuid: string; media: Media[] }>(
    'setItemMedia',
    (state, { uuid, media }) => mapSet(state, uuid, media)
);

export const addItemMediaItem = createAction<MediaState, { uuid: string; media: Media }>(
    'addItemMediaItem',
    (state, { uuid, media }) => {
        const items = state.get(uuid);
        return items === undefined
            ? state.set(uuid, [media])
            : mapSet(state, uuid, [...items, media]);
    }
);

export const removeItemMediaItem = createAction<MediaState, { uuid: string; mediaId: string }>(
    'removeItemMediaItem',
    (state, { uuid, mediaId }) => {
        const items = state.get(uuid);
        return items === undefined
            ? state
            : mapSet(
                  state,
                  uuid,
                  items.filter((item) => item.id !== mediaId)
              );
    }
);

export const mediaReducer = handleActions<MediaState>(
    new Map(),
    [setItemMedia, addItemMediaItem, removeItemMediaItem],
    {
        [addNewNote.toString()]: (
            state: MediaState,
            payload: { note: Note; discussion?: Discussion; media?: Media[] }
        ) => mapSet(state, payload.note.uuid, defaultToEmptyArray(payload.media)),
    }
);
