import type { PropsWithChildren } from 'react';

import './ItemHistory.css';

interface ItemHistoryItemProps extends PropsWithChildren {}

const ItemHistoryItem = ({ children }: ItemHistoryItemProps) => {
    return <div className="ItemHistoryItem">{children}</div>;
};

export default ItemHistoryItem;
