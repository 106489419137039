import { Fragment } from 'react';
import { connect } from 'react-redux';
import type { FieldUsageWithFields } from 'fields/fields-selectors';
import {
    selectCurrentYearFieldsWithPreviousUsages,
    selectMaybeVisibleMaybeArchivedFieldUsagesWithFields,
} from 'fields/fields-selectors';
import type FieldUsage from 'fields/FieldUsage';
import { asBadge } from 'fields/FieldUsage';
import type { Map, Set } from 'immutable';
import { List } from 'immutable';
import { useToggleSet } from 'lib/hooks';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';

import { sortFieldsByUsageFirst } from './field-selector-util';
import FieldSelectorField from './FieldSelectorField';
import FieldSelectorGroup from './FieldSelectorGroup';

interface FieldSelectorWithPreviousUsagesProps {
    // From parent
    selected: Set<string>;
    setSelected: SingleParamVoidFunction<Set<string>>;
    filter?: string;

    // From redux
    year: number;
    groups: List<FieldUsageWithFields>;
    fieldPreviousUsageMap: Map<string, List<FieldUsage>>;
}

const FieldSelectorWithPreviousUsages = ({
    selected,
    setSelected,

    year,
    groups,
    fieldPreviousUsageMap,
}: FieldSelectorWithPreviousUsagesProps) => {
    const [closedGroups, toggleGroup] = useToggleSet();

    const toggleFieldSelected = (uuid: string) => {
        if (selected.has(uuid)) {
            setSelected(selected.remove(uuid));
        } else {
            setSelected(selected.add(uuid));
        }
    };

    return (
        <div className="FieldSelector bg-white">
            {groups.map((group) => {
                const isOpen = !closedGroups.includes(group.fieldUsage.uuid);

                return (
                    <Fragment key={group.fieldUsage.uuid}>
                        <FieldSelectorGroup
                            year={year}
                            group={group}
                            selected={selected}
                            setSelected={setSelected}
                            isOpen={isOpen}
                            toggleOpen={() => toggleGroup(group.fieldUsage.uuid)}
                        />

                        {isOpen && (
                            <GroupFields
                                year={year}
                                group={group}
                                fieldPreviousUsageMap={fieldPreviousUsageMap}
                                selected={selected}
                                toggleFieldSelected={toggleFieldSelected}
                            />
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};

interface GroupFieldsProps {
    year: number;
    group: FieldUsageWithFields;
    fieldPreviousUsageMap: Map<string, List<FieldUsage>>;
    selected: Set<string>;
    toggleFieldSelected: (uuid: string) => void;
}
const GroupFields = ({
    year,
    group,
    fieldPreviousUsageMap,
    selected,
    toggleFieldSelected,
}: GroupFieldsProps) => {
    const fields = sortFieldsByUsageFirst(
        group.fields.map((field) => ({
            field,
            previousUsages: fieldPreviousUsageMap.get(field.uuid),
        }))
    );
    const previousYear = year - 1;

    return (
        <div className="fields">
            {fields.map(({ field, previousUsages }) => (
                <FieldSelectorField
                    key={field.uuid}
                    field={field}
                    year={year}
                    subline={<PreviousUsages year={previousYear} usages={previousUsages} />}
                    selected={selected.includes(field.uuid)}
                    toggleSelected={() => toggleFieldSelected(field.uuid)}
                />
            ))}
        </div>
    );
};

const PreviousUsages = ({ year, usages = List() }: { year: number; usages?: List<FieldUsage> }) => (
    <div className="flex text-xxs font-medium mt-2">
        <span className="mr-4">{year}:</span>
        <div className="flex flex-col w-3/4 gap-2">
            {usages.map((usage) => (
                <BadgeItem key={usage.uuid} item={asBadge(usage)} />
            ))}
        </div>
    </div>
);

export default connect(
    (state: AppState) => ({
        year: state.yearsState.currentYear as number,
        fieldPreviousUsageMap: selectCurrentYearFieldsWithPreviousUsages(state),
        groups: selectMaybeVisibleMaybeArchivedFieldUsagesWithFields(state),
    }),
    (dispatch) => bindActionCreators({}, dispatch)
)(FieldSelectorWithPreviousUsages);
