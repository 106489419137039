import { extentIntersectsGeo } from '@fieldmargin/webapp-geo';
import { activityIsNote, isComplete, noteIsTodo } from 'activity/activity-helper';
import type Feature from 'features/Feature';
import { List, Map } from 'immutable';
import { uuidMatches } from 'lib/fp-helpers';
import { listToMap } from 'lib/immutil';
import { logReselect } from 'lib/util/reselect-util';
import { compareName, escapeForRegex } from 'lib/util/text';
import { selectLimitedActivities } from 'notes/notes-filter';
import { defaultTo, prop } from 'ramda';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

import type FeatureType from './FeatureType';

/**
 * Select a single feature given it's UUID.
 */
export const selectFeature = createSelector(
    (_state: AppState, featureUuid: string) => featureUuid,
    (state: AppState) => state.featuresState.features,
    (featureUuid, features) => features?.find(uuidMatches(featureUuid))
);

/**
 * Select features given a list of UUIDs.
 */
export const selectFeaturesFromUuids = createSelector(
    (_: AppState, featureUuids: string[]) => featureUuids,
    (state: AppState) => state.featuresState.features ?? List<Feature>(),
    (featureUuids, features) => features.filter((feature) => featureUuids.includes(feature.uuid))
);

/**
 * Selects features that match the current search term.
 */
export const selectFilteredFeatures = createSelector(
    (state: AppState) => state.featuresState.features,
    (state) => state.featuresState.featureSearchString,
    (features, searchTerm): List<Feature> => {
        logReselect('selectFilteredFeatures');
        if (!features) {
            return List<Feature>();
        }
        return searchTerm !== null && searchTerm !== undefined
            ? features.filter(
                  (feature) => feature.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
              )
            : features;
    }
);

/**
 * Selects filtered features that are visible on the screen, if that setting is enabled.
 */
export const selectMaybeVisibleFilteredFeatures = createSelector(
    selectFilteredFeatures,
    (state) =>
        state.featuresState.filterFeaturesByScreen &&
        state.farmEditingState.lastMapPosition &&
        state.farmEditingState.lastMapPosition.extent,
    (features, extent): List<Feature> => {
        logReselect('selectMaybeVisibleFilteredFeatures');
        return extent
            ? features.filter((feature) => extentIntersectsGeo(extent, feature.geoJson))
            : features;
    }
);

export interface FeatureTypeWithFeatures {
    featureType: FeatureType;
    features: List<Feature>;
}
/**
 * Select features with visibility and search filters applied and returns them as a list of FeatureTypeWithFeatures
 */
export const selectMaybeVisibleFeaturesGroupedByFeatureType = createSelector(
    selectMaybeVisibleFilteredFeatures,
    (state: AppState) => state.featuresState.featureTypes?.sort(compareName) ?? List<FeatureType>(),
    (features, featureTypes) => {
        const groupedByType = features.groupBy(prop('featureTypeUuid')).map((c) => c.toList());
        return featureTypes
            .filter((featureType) => groupedByType.has(featureType.uuid))
            .map((featureType) => ({
                featureType,
                // We know this is not undefined because of the filter above
                features: groupedByType.get(featureType.uuid)!.sort(compareName),
            }));
    }
);

export const selectFeatureTypeMap = createSelector(
    (state: AppState) => defaultTo(List<FeatureType>(), state.featuresState.featureTypes),
    (featureTypes) => listToMap(featureTypes, (featureType) => featureType.uuid)
);

/**
 * Convert features into a map of feature type uuid and the next number that should be used
 * in the default name give to a new feature of that type.
 * See tests for examples.
 */
export const featuresToFeatureNameCountMap = (
    features: List<Feature>,
    featureTypeMap: Map<string, FeatureType>
) =>
    features.reduce((map, feature) => {
        const featureType = featureTypeMap.get(feature.featureTypeUuid);
        if (!featureType) {
            return map;
        }

        const match = new RegExp(`^${escapeForRegex(featureType.name)}( (\\d)+)*$`, 'i').exec(
            feature.name
        );
        if (match === null) {
            return map;
        }

        const nextNumber = match[2] === undefined ? 2 : parseInt(match[2], 10) + 1;
        return map.get(featureType.uuid, 0) >= nextNumber
            ? map
            : map.set(featureType.uuid, nextNumber);
    }, Map<string, number>());

/**
 * Selects the number for each feature type that should be used in the default name given to a
 * feature of that type.
 */
export const selectNextFeatureNameCountForTypeMap = createSelector(
    (state: AppState) => defaultTo(List<Feature>(), state.featuresState.features),
    selectFeatureTypeMap,
    featuresToFeatureNameCountMap
);

/**
 * Select notes or complete tasks for the feature history.
 */
export const selectFeatureHistoryNotes = createSelector(
    (_: AppState, featureUuid: string) => featureUuid,
    selectLimitedActivities,
    (featureUuid, activities) =>
        activities
            .filter(activityIsNote)
            .filter((note) => (note.task ? isComplete(note) : true))
            .filter((note) => note.featureUuids.includes(featureUuid))
);

/**
 * Select incomplete tasks for feature todo
 */
export const selectFeatureTodoTasks = createSelector(
    (_: AppState, featureUuid: string) => featureUuid,
    selectLimitedActivities,
    (featureUuid, activities) =>
        activities
            .filter(activityIsNote)
            .filter(noteIsTodo)
            .filter((note) => note.featureUuids.includes(featureUuid))
);
