import type { CustomMapSpec } from 'farm-map/types';
import { createSelector } from 'reselect';

import { selectIntegrationMaps } from './selectIntegrationMaps';
import { selectSentinelHubMaps } from './selectSentinelHubMaps';

export const selectCustomMaps = createSelector(
    selectSentinelHubMaps,
    selectIntegrationMaps,
    (sentinelHubMaps, integrationMaps): CustomMapSpec[] => {
        return sentinelHubMaps.concat(integrationMaps).map((spec, i) => ({
            ...spec,
            zIndex: i,
        }));
    }
);
