import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess, loadingFarm } from 'farms/farm-loading-state';
import { notUuidMatches, uuidMatches } from 'lib/fp-helpers';

import type Herd from './Herd';

export interface HerdState {
    herds: Herd[] | null;
    filterHerdsByMap: boolean;
    herdSearchString: string;
}

const initialState: HerdState = {
    herds: null,
    filterHerdsByMap: false,
    herdSearchString: '',
};

const herdSlice = createSlice({
    name: 'herds',
    initialState,
    reducers: {
        setHerds: (state, { payload }: PayloadAction<Herd[]>) => {
            const herds = state.herds ?? [];
            payload.forEach((herd) => {
                const index = herds.findIndex(uuidMatches(herd.uuid));
                if (index < 0) {
                    herds.push(herd);
                } else {
                    herds[index] = herd;
                }
            });
            state.herds = herds;
        },
        setHerd: (state, { payload }: PayloadAction<Herd>) => {
            const herds = state.herds ?? [];
            const index = herds.findIndex(uuidMatches(payload.uuid));
            if (index < 0) {
                herds.push(payload);
            } else {
                herds[index] = payload;
            }
            state.herds = herds;
        },
        removeHerd: (state, { payload }: PayloadAction<string>) => {
            state.herds = state.herds?.filter(notUuidMatches(payload)) ?? null;
        },
        setFilterHerdsByMap: (state, { payload }: PayloadAction<boolean>) => {
            state.filterHerdsByMap = payload;
        },
        setHerdSearchString: (state, { payload }: PayloadAction<string>) => {
            state.herdSearchString = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadingFarm.toString(), () => initialState)
            .addCase(
                loadedFarmSuccess.toString(),
                (state, { payload }: PayloadAction<LoadedFarmSuccessPayload>) => {
                    state.herds = payload.herds;
                }
            );
    },
});
export const { setHerds, setHerd, removeHerd, setFilterHerdsByMap, setHerdSearchString } =
    herdSlice.actions;
export const herdsReducer = herdSlice.reducer;
