import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import { deserializeField } from 'fields/Field';
import { setFields } from 'fields/fields-state';
import configService from 'lib/config';
import type { AppState } from 'system/store';
import { useAppDispatch } from 'system/store';
import TakeOverModal from 'view/modal/TakeOverModal';

interface BoundaryUploaderAppProps {
    onClose: VoidFunction;
}

const BoundaryUploaderApp = ({ onClose }: BoundaryUploaderAppProps) => {
    const dispatch = useAppDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);
    const onRefChange = useCallback((hostEl) => {
        if (hostEl !== null && window.FMBoundaryUploader !== undefined) {
            window.FMBoundaryUploader.unmount();
            window.FMBoundaryUploader.init({
                hostEl,
                farm,
                azureMapKey: configService.get('azureMapsKey'),
                onCreateFields: (fields) => {
                    dispatch(setFields(fields.map(deserializeField)));
                    onClose();
                },
                requester: authorizedRequest,
            });
        }
    }, []);

    useEffect(() => {
        return () => {
            window.FMBoundaryUploader?.unmount();
        };
    }, []);

    return (
        <TakeOverModal onClose={onClose}>
            <div id="boundary-uploader-app" className="boundary-uploader-app" ref={onRefChange} />
        </TakeOverModal>
    );
};

export default BoundaryUploaderApp;
