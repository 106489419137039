import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { Activity } from 'activity/activity-helper';
import { getActivityName, getDueDate, getURL } from 'activity/activity-helper';
import ActivityIcon from 'activity/ActivityIcon';
import { selectCurrentFarm } from 'farms/farms-state';
import DueDateWords from 'notes/sidebar/list/DueDateWords';
import type { AppState } from 'system/store';
import RememberingLink from 'view/RememberingLink';

interface FieldTodoItemProps {
    farm: Farm;
    activity: Activity;
}

const FieldTodoItem = ({ activity }: FieldTodoItemProps) => {
    const dueDate = getDueDate(activity);
    return (
        <RememberingLink to={getURL(activity)} className="flex items-center mb-2 focus py-1">
            <ActivityIcon activity={activity} className="mr-3" />
            {getActivityName(activity)}
            {dueDate && <DueDateWords className="ml-2" date={dueDate} />}
        </RememberingLink>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
}))(FieldTodoItem);
