import type { Activity } from 'activity/activity-helper';
import { useLastActivitySummary } from 'hooks/useLastActivitySummary';

import summaryIcon from './summary-icon.svg';

interface NotePreviewSummaryProps {
    activity: Activity;
}

const NotePreviewSummary = ({ activity }: NotePreviewSummaryProps) => {
    const { lastActivitySummary } = useLastActivitySummary(activity);
    return (
        lastActivitySummary && (
            <div className="NotePreviewSummary">
                <img src={summaryIcon} alt="Clock with arrow pointing clockwise" />
                {lastActivitySummary}
            </div>
        )
    );
};

export default NotePreviewSummary;
