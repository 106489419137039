import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess, loadingFarm } from 'farms/farm-loading-state';
import { uuidMatches } from 'lib/fp-helpers';
import { logReselect } from 'lib/util/reselect-util';
import { always, groupBy, sortBy } from 'ramda';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

import type MapGroup from './MapGroup';
import type MapLayer from './MapLayer';

export type MapGroupsByIntegration = globalThis.Record<string, MapGroup[]>;
export interface MapState {
    mapGroupsByIntegration?: MapGroupsByIntegration;
    tilesLoading: boolean;
    tilesError: boolean;
}

const initialState: MapState = {
    mapGroupsByIntegration: undefined,
    tilesLoading: false,
    tilesError: false,
};

const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setMapGroup: (state, { payload }: PayloadAction<MapGroup>) => {
            const mapGroups = state.mapGroupsByIntegration?.[payload.farmIntegrationUuid];
            if (state.mapGroupsByIntegration !== undefined && mapGroups !== undefined) {
                state.mapGroupsByIntegration[payload.farmIntegrationUuid] = mapGroups.map(
                    (mapGroup) => (mapGroup.uuid === payload.uuid ? payload : mapGroup)
                );
            }
        },
        removeFarmMap: (state, { payload }: PayloadAction<MapGroup>) => {
            const mapGroups = state.mapGroupsByIntegration?.[payload.farmIntegrationUuid];
            if (state.mapGroupsByIntegration !== undefined && mapGroups !== undefined) {
                state.mapGroupsByIntegration[payload.farmIntegrationUuid] = mapGroups.filter(
                    (mapGroup) => mapGroup.uuid !== payload.uuid
                );
            }
        },
        setTilesLoading: (state, action: PayloadAction<boolean>) => {
            state.tilesLoading = action.payload;
        },
        setTilesError: (state, action: PayloadAction<boolean>) => {
            state.tilesError = action.payload;
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(loadingFarm.toString(), always(initialState))
            .addCase(
                loadedFarmSuccess.toString(),
                (state, action: PayloadAction<LoadedFarmSuccessPayload>) => {
                    state.mapGroupsByIntegration = groupBy<MapGroup, string>(
                        (mapGroup) => mapGroup.farmIntegrationUuid,
                        action.payload.mapGroups
                    ) as MapGroupsByIntegration;
                }
            ),
});

export const { setMapGroup, setTilesLoading, setTilesError, removeFarmMap } = mapSlice.actions;
export const mapsReducer = mapSlice.reducer;

export const selectMapGroup = createSelector(
    (_: AppState, mapGroupUuid: string) => mapGroupUuid,
    (state: AppState) => state.mapsState.mapGroupsByIntegration,
    (mapGroupUuid: string, mapGroupsByIntegration: MapGroupsByIntegration): MapGroup | undefined =>
        Object.values(mapGroupsByIntegration ?? {})
            .flat()
            .find(uuidMatches(mapGroupUuid))
);

export const selectSortedMapGroupsByIntegration = createSelector(
    (state: AppState) => state.mapsState.mapGroupsByIntegration,
    (state: AppState) => state.integrationsState.farmIntegrations,
    (mapGroupsByIntegration, farmIntegrations): MapGroupsByIntegration => {
        logReselect('selectSortedMapGroupsByIntegration');

        return Object.fromEntries(
            sortBy(
                ([farmIntegrationUuid]) => {
                    const farmIntegration = farmIntegrations?.find(
                        uuidMatches(farmIntegrationUuid)
                    );
                    return farmIntegration ? farmIntegration.name : 'Unknown';
                },
                Object.entries(mapGroupsByIntegration ?? {}).map(
                    ([farmIntegrationUuid, mapGroups]) => {
                        return [
                            farmIntegrationUuid,
                            sortBy((mapGroup) => mapGroup.timestamp.valueOf(), mapGroups).reverse(),
                        ];
                    }
                )
            )
        );
    }
);

export const selectMapsForField = createSelector(
    (state: AppState) => state.mapsState.mapGroupsByIntegration,
    (_state: AppState, fieldUuid: string) => fieldUuid,
    (mapGroupsByIntegration, fieldUuid): MapGroup[] => {
        if (mapGroupsByIntegration === undefined) {
            return [];
        }
        const matchingByIntegration = Object.entries(mapGroupsByIntegration).map(([_, mapGroups]) =>
            mapGroups.filter((mapGroup) =>
                mapGroup.maps.find((map: MapLayer) => map.fieldUuids.includes(fieldUuid))
            )
        );
        return matchingByIntegration.flat();
    }
);
