import { connect } from 'react-redux';
import { useToggle } from '@fieldmargin/webapp-state';
import { OperationRecord } from 'components/operation-record';
import type { Set } from 'immutable';
import { notUuidMatches } from 'lib/fp-helpers';
import { formatArea } from 'lib/geo/maths';
import type FullOperation from 'operations/FullOperation';
import type Recording from 'operations/Recording';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import { selectUserAreaMeasurementUnit } from 'users/user-state';

import type { OperationRecording } from './operation-recording-utils';
import OperationRecordingCardForm from './OperationRecordingCardForm';
import OperationRecordingCardView from './OperationRecordingCardView';

interface OperationRecordingCardProps {
    fullOperation: FullOperation;
    operationRecording: OperationRecording;
    rate: number;
    total: number;
    area: number;
    onRemoveClick: VoidFunction;
    onSave: (recordings: Set<Recording>) => Promise<void>;
}

interface ParentProps {
    fullOperation: FullOperation;
    operationRecording: OperationRecording;
    totalArea: number;
    onSave: (recordings: Set<Recording>) => Promise<void>;
}

const OperationRecordingCard = ({
    fullOperation,
    operationRecording,
    rate,
    total,
    area,
    onRemoveClick,
    onSave,
}: OperationRecordingCardProps) => {
    const [editing, toggleEditing] = useToggle(false);
    const { input } = operationRecording;

    return (
        <OperationRecord.Item data-edit={editing}>
            {editing ? (
                <OperationRecordingCardForm
                    fullOperation={fullOperation}
                    operationRecording={operationRecording}
                    rate={rate}
                    total={total}
                    area={area}
                    toggleEditing={toggleEditing}
                    onSave={onSave}
                />
            ) : (
                <OperationRecordingCardView
                    input={input}
                    rate={rate}
                    total={total}
                    area={area}
                    toggleEditing={toggleEditing}
                    onRemoveClick={onRemoveClick}
                />
            )}
        </OperationRecord.Item>
    );
};

const RemoveConfirmation = ({ inputName }: { inputName: string }) => (
    <h2>Are you sure you want to remove {inputName}?</h2>
);

export default connect(
    (state: AppState, props: ParentProps) => {
        const unit = selectUserAreaMeasurementUnit(state);
        const totalArea = formatArea(props.totalArea, unit);
        return {
            fullOperation: props.fullOperation,
            operationRecording: props.operationRecording,
            rate: props.operationRecording.recording.rate,
            total: props.operationRecording.recording.rate * totalArea,
            area: totalArea,
        };
    },
    (dispatch, props) => ({
        onRemoveClick: () => {
            dispatch(
                changeModal({
                    name: 'async-choice',
                    props: {
                        onConfirm: () =>
                            props.fullOperation.recordings !== null
                                ? props.onSave(
                                      props.fullOperation.recordings.filter(
                                          notUuidMatches(props.operationRecording.recording.uuid)
                                      )
                                  )
                                : Promise.resolve(),
                        content: (
                            <RemoveConfirmation inputName={props.operationRecording.input.name} />
                        ),
                    },
                })
            );
        },
    })
)(OperationRecordingCard);
