import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import icon from 'components/icons/icon';
import Modal from 'modal/Modal';
import UpgradeButton from 'upgrade/UpgradeButton';
import UpgradeLink from 'upgrade/UpgradeLink';

interface HerdLimitModalProps {
    herdLimit: number;
    onClose: VoidFunction;
}

const HerdLimitModal = ({ herdLimit, onClose }: HerdLimitModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal onClose={onClose}>
            <div className="bg-white p-5 text-2xl text-center" style={{ width: 420 }}>
                {icon('padlock', 'red', 'mb-5', { height: 60 })}
                <Markdown>{t('herd_limit_web_title', { sprintf: [herdLimit] })}</Markdown>
                <p className="mb-8 text-xl">
                    <strong>
                        <UpgradeLink onClick={onClose}>{t('find_out_more_paid_plans')}</UpgradeLink>
                    </strong>
                </p>
                <p>
                    <UpgradeButton onClick={onClose} className="w-2/3" />
                </p>
                <p className="text-sm">
                    <a
                        href="https://fieldmargin.com/livestock-management"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('learn_more')}
                    </a>
                </p>
            </div>
        </Modal>
    );
};

export default HerdLimitModal;
