import type { ComponentProps } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { usePromise, useToggle } from '@fieldmargin/webapp-state';
import type { Activity } from 'activity/activity-helper';
import { hasAnyField, hasFieldOutstanding } from 'activity/activity-helper';
import FarmHistoryWarning from 'activity/FarmHistoryWarning';
import { default as Promise } from 'bluebird';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { isSubField } from 'fields/Field';
import { fetchFieldsHerdLocations, fetchFieldsNotes } from 'fields/fields-api';
import { selectField, selectFieldSubFieldsForCurrentYear } from 'fields/fields-selectors';
import AddFieldUsage from 'fields/usage/AddFieldUsage';
import { useAddingUsageState } from 'fields/usage/adding-usage-state';
import withPageField from 'fields/withPageField';
import type { HerdLocation } from 'herd/Herd';
import type Herd from 'herd/Herd';
import { selectFieldHerds } from 'herd/herd-selectors';
import type { List } from 'immutable';
import { isNil } from 'lodash';
import type Note from 'notes/Note';
import {
    selectCurrentYearOutstandingLimitedActivities,
    selectLimitedActivities,
    shouldShowFarmHistoryWarning,
} from 'notes/notes-filter';
import { addNotes } from 'notes/notes-state';
import { useOnboarding } from 'onboarding/onboarding-state';
import { unless } from 'ramda';
import { bindActionCreators } from 'redux';
import EditHeader from 'sidebar/header/EditHeader';
import type { AppState } from 'system/store';
import ArchivedBar from 'view/ArchivedBar';
import Fog from 'view/Fog';
import { selectCurrentYear } from 'years/years-state';

import { useUpdateField, useUpdateSubField } from '../field-hooks';

import { selectFieldRestDuration } from './history/field-history-utils';
import EditableBoundary from './EditableBoundary';
import EditableFieldDescription from './EditableFieldDescription';
import EditableFieldSubFields from './EditableFieldSubFields';
import EditableFieldTitle from './EditableFieldTitle';
import EditableFieldUsage from './EditableFieldUsage';
import FieldActions from './FieldActions';
import FieldArchiveButton from './FieldArchiveButton';
import FieldAttachedHerd from './FieldAttachedHerd';
import FieldRestDays from './FieldRestDays';
import FieldTabs from './FieldTabs';
import FieldWorkedArea from './FieldWorkedArea';

import './EditableFieldDetails.css';

interface EditableFieldDetailsBaseProps {
    farm: Farm;
    year: number;
    field: Field;
    subFields: List<Field>;
    parentField?: Field;
    outstanding: List<Activity>;
    fieldHistory: List<Activity>;
    shouldShowFarmHistoryWarning: boolean;
    attachedHerds: Herd[];
    herds: Herd[];

    addNotes: typeof addNotes;
}

const EditableFieldDetailsBase = ({
    farm,
    year,
    field,
    subFields,
    parentField,
    outstanding,
    fieldHistory,
    addNotes,
    shouldShowFarmHistoryWarning,
    attachedHerds,
    herds,
}: EditableFieldDetailsBaseProps) => {
    const { t } = useTranslation();
    const { nextOnboarding } = useOnboarding();
    const [headerMenuOpen, toggleHeaderMenuOpen] = useToggle(false);
    const updateSubField = useUpdateSubField(field);
    const { updateField } = useUpdateField();

    const [fieldHerdLocations, setFieldHerdLocations] = useState<List<HerdLocation>>();
    const { pending: historyPending, setPromise: setHistoryPromise } = usePromise<{
        herdLocations: List<HerdLocation>;
        notes: Map<string, Note>;
    }>(({ herdLocations, notes }) => {
        setFieldHerdLocations(herdLocations);
        addNotes(notes);
    });

    useEffect(() => {
        nextOnboarding('fieldJob');
    }, []);

    const [{ addingUsage, createdFieldUsageUuid }, toggleAddingUsage, setCreateFieldUsageUuid] =
        useAddingUsageState();
    const [fieldUsageEditing, setFieldUsageEditing] = useState(false);

    // When the view loads or the field changes, fetch the field's herd locations & notes.
    useEffect(() => {
        const fieldUuids = subFields
            .map((sf) => sf.uuid)
            .unshift(field.uuid)
            .toArray();
        setFieldHerdLocations(undefined);
        setHistoryPromise(
            Promise.props({
                herdLocations: fetchFieldsHerdLocations(farm.uuid, year, fieldUuids),
                notes: fetchFieldsNotes(farm.uuid, year, fieldUuids),
            })
        );
    }, [field, subFields]);

    if (addingUsage) {
        return (
            <AddFieldUsage
                onBack={() => {
                    setFieldUsageEditing(true);
                    toggleAddingUsage();
                }}
                onSave={(fieldUsage) => {
                    setFieldUsageEditing(true);
                    setCreateFieldUsageUuid(fieldUsage.uuid);
                    toggleAddingUsage();
                }}
            />
        );
    }

    const handleNameSave = (name: string, fieldId?: string) =>
        isSubField(field) ? updateSubField({ name }) : updateField(field, { name, fieldId });

    const handleUsageSave = (fieldUsageUuid: string | undefined) =>
        isSubField(field)
            ? updateSubField({ fieldUsage: { uuid: fieldUsageUuid }, year })
            : updateField(field, { yearFieldUsage: { year, fieldUsageUuid } });

    const handleBoundarySave = (geoFeatureCollection: GeoFeatureCollection) =>
        isSubField(field)
            ? updateSubField({ geoJson: geoFeatureCollection })
            : updateField(field, { geoJson: geoFeatureCollection });

    const handleDescriptionSave = (description: string) =>
        // Saving a sub field still needs to have the name sent as well in order for the
        // server to think it's a valid request.
        isSubField(field)
            ? updateSubField({ name: field.name, description })
            : updateField(field, { description });

    const handleSaveWorkedArea = (workedArea: number) =>
        isSubField(field)
            ? updateSubField({ year, workedArea })
            : updateField(field, { yearWorkedArea: { year, workedArea } });

    const fieldRestDuration = fieldHerdLocations
        ? selectFieldRestDuration(herds, fieldHerdLocations)
        : undefined;

    return (
        <div className="EditableFieldDetails scrollable">
            {headerMenuOpen && <Fog blue className="top-14" onClick={toggleHeaderMenuOpen} />}
            <div className="non-scrolling">
                <EditHeader
                    item={field}
                    backPath={`/farms/${farm.uuid}/fields`}
                    deletePath={`/farms/${farm.uuid}/fields/${field.uuid}/delete`}
                    menuOpen={headerMenuOpen}
                    onToggleHeaderMenu={toggleHeaderMenuOpen}
                >
                    {(parentField === undefined || !parentField.archived) && (
                        <FieldArchiveButton field={field} onDone={toggleHeaderMenuOpen} />
                    )}
                </EditHeader>
                {field.archived && <ArchivedBar>{t('archived')}</ArchivedBar>}
            </div>
            <div className="scrolling">
                <EditableFieldTitle
                    farm={farm}
                    field={field}
                    parentField={parentField}
                    onSave={handleNameSave}
                />
                <EditableFieldDescription field={field} onSave={handleDescriptionSave} />
                <EditableFieldUsage
                    field={field}
                    onSave={handleUsageSave}
                    toggleAddUsage={toggleAddingUsage}
                    createdFieldUsageUuid={createdFieldUsageUuid}
                    editing={fieldUsageEditing}
                />
                <EditableBoundary field={field} onSave={handleBoundarySave} />
                <FieldWorkedArea field={field} year={year} onSave={handleSaveWorkedArea} />
                {!parentField && <EditableFieldSubFields farm={farm} field={field} />}
                {attachedHerds.length > 0 && (
                    <FieldAttachedHerd farm={farm} herds={attachedHerds} />
                )}
                <FieldRestDays pending={historyPending} fieldRestDuration={fieldRestDuration} />
                <FieldActions field={field} />
                <FieldTabs
                    farm={farm}
                    year={year}
                    field={field}
                    subFields={subFields}
                    todoActivities={outstanding}
                    fieldHistory={fieldHistory}
                    herdLocations={fieldHerdLocations}
                />
            </div>
            {shouldShowFarmHistoryWarning && (
                <div className="non-scrolling">
                    <FarmHistoryWarning />
                </div>
            )}
        </div>
    );
};

function EditableFieldDetails({ ...props }: ComponentProps<typeof EditableFieldDetailsBase>) {
    if (!props.field) {
        return <Navigate to={`/farms/${props.farm.uuid}/fields`} replace />;
    } else {
        return <EditableFieldDetailsBase {...props} />;
    }
}

export default withPageField(
    connect(
        (state: AppState, { field }: { field?: Field }) => {
            const farm = selectCurrentFarm(state);

            if (field === undefined) {
                // Can't do anything if we have no field.
                // The component should handle this.
                return {
                    farm,
                };
            }

            const subFields = selectFieldSubFieldsForCurrentYear(state, field.uuid);

            const fieldUuids = subFields
                .map((subField) => subField.uuid)
                .concat(field.uuid)
                .toSet();

            const outstanding = selectCurrentYearOutstandingLimitedActivities(state).filter(
                (activity) => hasFieldOutstanding(activity, fieldUuids)
            );
            const fieldHistory = selectLimitedActivities(state).filter((activity) =>
                hasAnyField(activity, fieldUuids)
            );

            const attachedHerds = selectFieldHerds(state, field.uuid);

            const parentField = unless<string | undefined, Field | undefined>(
                isNil,
                (parentUuid) => selectField(state, parentUuid as string),
                field.parentUuid
            );

            return {
                farm,
                year: selectCurrentYear(state),
                field,
                subFields,
                parentField,
                herds: state.herdsState.herds,
                outstanding,
                fieldHistory,
                shouldShowFarmHistoryWarning: shouldShowFarmHistoryWarning(state),
                attachedHerds,
            };
        },
        (dispatch) =>
            bindActionCreators(
                {
                    addNotes,
                },
                dispatch
            )
    )(EditableFieldDetails)
);
