import icon from 'components/icons/icon';
import type { InputReportSummary } from 'inputs/InputReportSummary';
import { formatArea } from 'lib/geo/maths';
import { prefersImperialUnits } from 'lib/MeasurementUnit';
import type FullOperation from 'operations/FullOperation';
import { useAppSelector } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import { hectaresRateToAcresRate } from 'utils/conversion';
import FarmRememberingLink from 'view/FarmRememberingLink';
import Num from 'view/Num';
import FormattedRate from 'view/units/FormattedRate';
import FormattedTotal from 'view/units/FormattedTotal';
import ShortUnit from 'view/units/ShortUnit';

interface FieldInputReportOperationRowProps {
    fieldUuid: string;
    input: InputReportSummary;
    operation: FullOperation;
}

const FieldInputReportOperationRow = ({
    fieldUuid,
    input,
    operation,
}: FieldInputReportOperationRowProps) => {
    const areaUnit = useAppSelector(selectUserAreaMeasurementUnit);
    const opField = operation.fields?.find((field) => field.fieldUuid === fieldUuid);
    const recording = operation.recordings?.find((recording) => recording.inputUuid === input.uuid);
    const rate = recording?.rate ?? 0;
    const convertedRate = prefersImperialUnits(areaUnit) ? hectaresRateToAcresRate(rate) : rate;
    const areaSqm = opField?.areaSqm ?? 0;

    return (
        <tr className="bg-gray-200">
            <td colSpan={5}>
                <FarmRememberingLink to={`/operations/${operation.uuid}`}>
                    {icon('field-job', 'blue', 'mr-3')}
                    <span>{operation.summary.name}</span>
                </FarmRememberingLink>
            </td>
            <td>
                <FormattedRate rate={rate} /> <ShortUnit unit={input.inputUnit} />
            </td>
            <td>
                <FormattedTotal rate={rate} areaSqm={areaSqm} />{' '}
                <ShortUnit unit={input.inputUnit} />
            </td>
            <td>
                <Num value={convertedRate * input.unitCost} />
            </td>
            <td>
                <Num value={convertedRate * formatArea(areaSqm, areaUnit) * input.unitCost} />
            </td>
        </tr>
    );
};

export default FieldInputReportOperationRow;
