import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import type { List } from 'immutable';
import type { AppState } from 'system/store';
import ShapeAndFieldsField from 'view/form/hook/ShapeAndFieldsField';

import SidebarModule from '../SidebarModule';

import EditableShapeInformation from './components/EditableShapeInformation';

interface NewShapesProps {
    tools?: List<DrawingTool>;
    maxShapes?: number;
    startEditing: VoidFunction;
    stopEditing: VoidFunction;
    editingGeoFeatureCollection: GeoFeatureCollection | null;
    required?: boolean;
}

const NewShapes = ({
    tools,
    maxShapes,
    startEditing,
    stopEditing,
    editingGeoFeatureCollection,
    required,
}: NewShapesProps) => {
    const { t } = useTranslation();
    useEffect(() => {
        startEditing();
        return () => {
            stopEditing();
        };
    }, []);

    return (
        <SidebarModule className="NewShapes px-0" editing={true}>
            <EditableShapeInformation
                className="px-5"
                label={t('location')}
                tools={tools}
                maxShapes={maxShapes}
            />
            {editingGeoFeatureCollection && (
                <ShapeAndFieldsField shapes={editingGeoFeatureCollection} required={required} />
            )}
        </SidebarModule>
    );
};

export default connect((state: AppState) => ({
    editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,
}))(NewShapes);
