import c from 'classnames';
import { Radio, RadioGroup, useFieldState } from 'informed';
import { getOperationTypeName, OperationType } from 'operations/Operation';
import ErrorMessage from 'view/ErrorMessage';
import { required } from 'view/form/validations';

interface OperationTypeRadioGroupProps {
    field?: string;
    onChange?: (type: string) => void;
    className?: string;
}

const OperationTypeRadioGroup = ({ field, onChange, className }: OperationTypeRadioGroupProps) => {
    const { error } = useFieldState('operationType');
    return (
        <div className={c('OperationTypeRadioGroup', className)}>
            <RadioGroup
                field="operationType"
                validate={required('Please choose an operation type')}
                validateOnChange
            >
                <ul className="m-0 p-0 list-none text-fm-blue">
                    {Object.keys(OperationType).map((operationType) => (
                        <li key={operationType} className="p-0">
                            <div className="pt-1 float-left">
                                <Radio
                                    field={field || undefined}
                                    className="fancy-radio"
                                    id={operationType}
                                    value={operationType}
                                    onChange={(e) => onChange && onChange(e.target.value as string)}
                                />
                                <label htmlFor={operationType} />
                            </div>
                            <label htmlFor={operationType}>
                                {getOperationTypeName(OperationType[operationType])}
                            </label>
                        </li>
                    ))}
                </ul>
            </RadioGroup>
            {error && <ErrorMessage className="mt-5">{error}</ErrorMessage>}
        </div>
    );
};

export default OperationTypeRadioGroup;
