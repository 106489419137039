import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { GeoFeature, GeoPolygon } from '@fieldmargin/webapp-geo';
import { createGeoFeatureWithId, deserializeLngLat } from '@fieldmargin/webapp-geo';
import { createLogger } from '@fieldmargin/webapp-reporting';
import Axios from 'axios';
import type { FeatureCollection, Polygon } from 'geojson';
import { List, Map } from 'immutable';

import type { FarmIntegration } from './FarmIntegration';

const logger = createLogger('integrations.integrations-api');

export const getFarmIntegrationsApi = (farmUuid: string) => {
    return authorizedRequest<FarmIntegration[]>({
        method: 'get',
        url: `/integration-api/farm-integration/${farmUuid}/list`,
    });
};

export const revokeAccessApi = (farmUuid: string, farmIntegrationUuid: string) => {
    return authorizedRequest<boolean>({
        method: 'post',
        url: `/integration-api/farm-integration/${farmUuid}/revoke-access`,
        params: { farmIntegrationUUID: farmIntegrationUuid },
    });
};

export const trackView = (integrationUuid: string) => {
    return authorizedRequest({
        method: 'post',
        url: `/integration-api/tracking/view`,
        params: { integrationUUID: integrationUuid },
    });
};

export const trackVisit = (integrationUuid: string) => {
    return authorizedRequest({
        method: 'post',
        url: `/integration-api/tracking/visit`,
        params: { integrationUUID: integrationUuid },
    });
};

export const fetchRpaParcels = async (sbi: string) => {
    try {
        const response = await Axios.get<
            FeatureCollection<Polygon, { SHEET_ID: string; PARCEL_ID: string }>
        >(
            `https://environment.data.gov.uk/data-services/RPA/LandParcels/wfs?version=2.0.0&request=GetFeature&typeNames=RPA:LandParcels&cql_filter=SBI=${sbi}&srsname=EPSG:4326&outputFormat=application/json`
        );

        return List<GeoFeature>(
            response.data.features.map((geoFeature) =>
                createGeoFeatureWithId(
                    deserializeLngLat(geoFeature.geometry) as GeoPolygon,
                    Map({
                        label: `${geoFeature.properties.SHEET_ID}${geoFeature.properties.PARCEL_ID}`,
                    })
                )
            )
        );
    } catch (e) {
        logger.error('Unable to fetch RPA parcels', e);
        throw new Error('RPA returned error');
    }
};
