import { authorizedRequest } from '@fieldmargin/webapp-auth';

export const getFarmMembershipFinishDate = (farmUuid: string) =>
    authorizedRequest<{ finishAt: string | null } | null>({
        method: 'get',
        url: `/payment-api/user/${farmUuid}/membership`,
    }).then((data) => (!data || !data.finishAt ? null : new Date(data.finishAt)));

export const trialAvailable = () =>
    authorizedRequest<boolean>({
        method: 'get',
        url: `/payment-api/user/trial-available`,
    });
