import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToggle } from '@fieldmargin/webapp-state';
import type Field from 'fields/Field';
import { getFieldWorkedArea } from 'fields/Field';
import EditButton from 'sidebar/modules/common/EditButton';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { UnaryPromiseFunction } from 'system/types';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import BubbleTag from 'view/molecules/BubbleTag';
import FormattedArea from 'view/units/FormattedArea';

import FieldWorkedAreaForm from './FieldWorkedAreaForm';

interface FieldWorkedAreaProps {
    field: Field;
    year: number;
    onSave: UnaryPromiseFunction<number>;
}

const FieldWorkedArea = ({ field, year, onSave }: FieldWorkedAreaProps) => {
    const { t } = useTranslation();
    const [editing, toggleEditing] = useToggle(false);
    const measurementUnit = useSelector(selectUserAreaMeasurementUnit);
    return (
        <SidebarModule editing={editing}>
            {!editing && <EditButton setEditing={toggleEditing} disabled={field.archived} />}
            <span className="block mb-1">{t('field_work_area')}</span>
            {editing ? (
                <FieldWorkedAreaForm
                    field={field}
                    year={year}
                    measurementUnit={measurementUnit}
                    onSave={onSave}
                    onCancel={toggleEditing}
                />
            ) : (
                <BubbleTag>
                    <FormattedArea areaSqm={getFieldWorkedArea(field, year)} withUnit />
                </BubbleTag>
            )}
        </SidebarModule>
    );
};

export default FieldWorkedArea;
