import type { ChangeEvent } from 'react';
import { usePromise } from '@fieldmargin/webapp-state';
import { AttachmentType } from 'farm-editing/attachments';
import {
    addEditingAttachmentByType,
    highlightGeoFeatureId,
    removeEditingAttachmentByType,
    updateEditingGeoFeature,
} from 'farm-editing/farm-editing-state';
import { useSaveAutoBoundaryFields } from 'fields/sidebar/auto-boundary/auto-boundary-hooks';
import { useDebouncedFunc, useZoomToFeature } from 'lib/hooks';
import { useAppDispatch } from 'system/store';

import { useEditingGeoFeatureCollection } from './useEditingGeoFeatureCollection';

const useAutoBoundaryNameForm = () => {
    const { saveFields } = useSaveAutoBoundaryFields();

    const dispatch = useAppDispatch();
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();
    const zoomToFeature = useZoomToFeature();
    const { pending, setPromise } = usePromise();

    const onSubmit = (...args: Parameters<typeof saveFields>) => {
        setPromise(saveFields(...args));
    };

    const onZoom = (featureId: string | number) => {
        const geoFeature = editingGeoFeatureCollection?.features.find(
            (feature) => feature.id === featureId
        );
        if (geoFeature !== undefined) {
            zoomToFeature(geoFeature);
        }
    };

    const onRemoveField = (featureId: string | number) => {
        dispatch(removeEditingAttachmentByType(AttachmentType.FIELD)(featureId.toString()));
        const feature = editingGeoFeatureCollection?.features.find(
            (feature) => feature.id === featureId
        );
        if (feature !== undefined) {
            dispatch(updateEditingGeoFeature(feature.setIn(['properties', 'label'], undefined)));
        }
    };
    const onAddField = (featureId: string | number) => {
        dispatch(addEditingAttachmentByType(AttachmentType.FIELD)(featureId.toString()));
    };

    const onHighlightGeoFeature = (featureId: string | number) => {
        dispatch(highlightGeoFeatureId(featureId.toString()));
    };

    const onNameChange = useDebouncedFunc(
        (featureId: string | number, e: ChangeEvent<HTMLInputElement>) => {
            const feature = editingGeoFeatureCollection?.features.find(
                (feature) => feature.id === featureId
            );
            if (feature !== undefined) {
                dispatch(
                    updateEditingGeoFeature(feature.setIn(['properties', 'label'], e.target.value))
                );
            }
        },
        300
    );

    return {
        onRemoveField,
        onAddField,
        onSubmit,
        onZoom,
        onNameChange,
        onHighlightGeoFeature,
        isPending: pending,
    };
};

export { useAutoBoundaryNameForm };
