import { useTranslation } from 'react-i18next';
import type Field from 'fields/Field';
import type SubField from 'fields/sub-fields/SubField';
import type { List, Set } from 'immutable';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';
import Steps, { Step } from 'view/Steps';

import SubFieldsDataCopyTable from './SubFieldsDataCopyTable';

interface SubFieldsDataCopyOperationsProps {
    parentField: Field;
    subFields: List<SubField>;
    setSelected: (subFieldIds: Set<string>) => void;
    selected: Set<string>;
    stepNumber: number;
    numberOfSteps: number;
}

const SubFieldsDataCopyOperations = ({
    parentField,
    subFields,
    setSelected,
    selected,
    stepNumber,
    numberOfSteps,
}: SubFieldsDataCopyOperationsProps) => {
    const { t } = useTranslation();
    const steps = [...Array(numberOfSteps)];
    return (
        <div className="text-center">
            <h2 className="font-medium">
                <MarkdownNoPara>{t('sub_fields_data_copy_jobs_title')}</MarkdownNoPara>
            </h2>
            {numberOfSteps > 1 && (
                <Steps>
                    {steps.map((_, index) => (
                        <Step
                            selected={index + 1 === stepNumber}
                            done={index + 1 < stepNumber}
                            key={index}
                        >
                            {index + 1}
                        </Step>
                    ))}
                </Steps>
            )}
            <SubFieldsDataCopyTable
                subFields={subFields}
                parentField={parentField}
                selected={selected}
                setSelected={setSelected}
            />
        </div>
    );
};

export default SubFieldsDataCopyOperations;
