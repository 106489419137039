import { List, Record } from 'immutable';

export class ChatLog extends Record({
    dayChatGroups: List<DayChatGroup>(),
}) {}

export class DayChatGroup extends Record({
    date: new Date(),
    userChatGroups: List<UserChatGroup>(),
}) {}

export class UserChatGroup extends Record({
    userId: -1,
    items: List<ChatItem>(),
}) {}

export class ChatComment extends Record({
    type: 'comment',
    id: '',
    date: new Date(),
    userId: -1,

    comment: '',
}) {}

export class ChatImage extends Record({
    type: 'image',
    id: '',
    date: new Date(),
    userId: -1,

    thumbUrl: '',
    onClick: null as Function | null,

    isFarmChat: false,
}) {}

export class ChatFile extends Record({
    type: 'file',
    id: '',
    date: new Date(),
    userId: -1,

    url: '',
    mediaType: '',
    mediaSize: -1,
    fileName: '',

    isFarmChat: false,
}) {}

export type ChatItem = ChatComment | ChatImage | ChatFile;
