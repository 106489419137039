import type { ReactNode } from 'react';
import { connect } from 'react-redux';
import type { User } from '@fieldmargin/webapp-auth';
import TermsReacceptPage from 'auth/terms/TermsReacceptPage';
import FarmInvitation from 'farms/invites/FarmInvitation';
import type FarmInvite from 'farms/invites/FarmInvite';
import type { OrganisationInvite } from 'organisation/organisation-api';
import OrganisationInvites from 'organisation/OrganisationInvites';
import type { AppState } from 'system/store';
interface BlockingModalCheckProps {
    user: User;
    farmInvites?: FarmInvite[];
    organisationInvites?: OrganisationInvite[];
    children: ReactNode;
}

const BlockingModalCheck = ({
    user,
    farmInvites,
    organisationInvites,
    children,
}: BlockingModalCheckProps) => {
    if (user && !user.acceptedLatestTermsVersion) {
        return <TermsReacceptPage />;
    }
    if (farmInvites && farmInvites.length > 0) {
        return <FarmInvitation />;
    }
    if (organisationInvites && organisationInvites.length > 0) {
        return <OrganisationInvites />;
    }
    return <div>{children}</div>;
};

export default connect((state: AppState) => ({
    user: state.user.user,
    farmInvites: state.farmInvites.invites,
    organisationInvites: state.organisationState.invites,
}))(BlockingModalCheck);
