import { connect } from 'react-redux';
import type Field from 'fields/Field';
import { selectMaybeVisibleMaybeArchivedFieldsWithParentsGroupedByArchived } from 'fields/fields-selectors';
import type { List } from 'immutable';
import type { AppState } from 'system/store';

import FieldListArchived from './FieldListArchived';
import FieldListFilterMessage from './FieldListFilterMessage';
import FieldPreviewWithUsage from './FieldPreviewWithUsage';

interface FieldListAlphabeticalProps {
    fields: {
        active: List<Field>;
        archived: List<Field>;
    };
}

const FieldListAlphabetical = ({ fields }: FieldListAlphabeticalProps) => {
    return (
        <>
            <FieldListFilterMessage filteredFields={fields.active.concat(fields.archived)} />
            {fields.active.map((field) => (
                <FieldPreviewWithUsage key={field.uuid} field={field} />
            ))}
            {fields.archived.size > 0 && (
                <FieldListArchived>
                    {fields.archived.map((field) => (
                        <FieldPreviewWithUsage key={field.uuid} field={field} />
                    ))}
                </FieldListArchived>
            )}
        </>
    );
};

export default connect((state: AppState) => ({
    fields: selectMaybeVisibleMaybeArchivedFieldsWithParentsGroupedByArchived(state),
}))(FieldListAlphabetical);
