import { connect } from 'react-redux';
import { selectDisplayableFeatureTypes } from 'features/features-state';
import type FeatureType from 'features/FeatureType';
import { selectDisplayableFieldUsagesWithNotSet } from 'fields/field-usage-selectors';
import type FieldUsage from 'fields/FieldUsage';
import { asBadge } from 'fields/FieldUsage';
import type { List } from 'immutable';
import type { AppState } from 'system/store';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';

// @ts-ignore
import northArrow from './north-arrow.png';

import './PrintLegend.scss';

interface PrintLegendProps {
    fieldUsages: List<FieldUsage>;
    featureTypes: List<FeatureType>;
}

const PrintLegend = ({ fieldUsages, featureTypes }: PrintLegendProps) => {
    const fieldUsageListItems = fieldUsages.map((fieldUsage) => {
        return (
            <li key={fieldUsage.uuid}>
                <BadgeItem item={asBadge(fieldUsage)} />
            </li>
        );
    });
    const featureTypesListItems = featureTypes.map((featureType) => {
        return (
            <li key={featureType.uuid}>
                <BadgeItem item={featureType} />
            </li>
        );
    });

    return (
        <div className="PrintLegend">
            {fieldUsages.size > 0 && (
                <>
                    <h2>Field usages</h2>
                    <ul>{fieldUsageListItems}</ul>
                </>
            )}
            {featureTypes.size > 0 && (
                <>
                    <h2>Feature types</h2>
                    <ul>{featureTypesListItems}</ul>
                </>
            )}
            <img src={northArrow} alt="Arrow pointing up representing North" className="north" />
        </div>
    );
};

export default connect((state: AppState) => ({
    fieldUsages: selectDisplayableFieldUsagesWithNotSet(state),
    featureTypes: selectDisplayableFeatureTypes(state),
}))(PrintLegend);
