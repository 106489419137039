import { Extent, extent, GeoPosition, Projection } from '@fieldmargin/webapp-geo';
import type { geojson } from '@fieldmargin/webapp-ol-map';
import MapPosition from 'components/maps/model/MapPosition';
import { storeMapPosition } from 'farm-editing/farm-editing-state';
import { useAction } from 'lib/hooks';

export const useFarmMapStorePosition = () => {
    const storePosition = useAction(storeMapPosition);

    return (position: geojson.ViewportPosition) => {
        const lngLatExtent = extent.reprojectExtent(position.extent, Projection.LNG_LAT);
        const mapPosition = MapPosition({
            centre: GeoPosition({
                x: position.centre.coordinates[0],
                y: position.centre.coordinates[1],
            }),
            zoom: position.zoom,
            extent: Extent({
                minX: lngLatExtent.minX,
                minY: lngLatExtent.minY,
                maxX: lngLatExtent.maxX,
                maxY: lngLatExtent.maxY,
            }),
        });
        storePosition(mapPosition);
    };
};
