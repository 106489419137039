import type { FarmUser } from '@fieldmargin/webapp-farms';
import { farmHasNoSub, FarmUserRole } from '@fieldmargin/webapp-farms';
import type { PendingFarmInvite } from '@fieldmargin/webapp-farms/dist/PendingFarmInvite';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess, loadingFarm } from 'farms/farm-loading-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type { Set } from 'immutable';
import type { TeamTrackingChanges } from 'lib/firebase/snapshot-parsers';
import { filterObjectByKey } from 'lib/fp-helpers';
import { isFarmOwner } from 'lib/owner';
import { logReselect } from 'lib/util/reselect-util';
import { always, sortBy } from 'ramda';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import type { Nullable } from 'system/types';
import { selectUserId } from 'users/user-state';

import type FarmUserLocation from './FarmUserLocation';

const sortByName = (farmUser: FarmUser) => `${farmUser.firstName} ${farmUser.lastName}`;

interface FarmUsersState {
    farmUsers: Nullable<FarmUser[]>;
    locations: Nullable<Record<string, FarmUserLocation>>;
    pendingInvites: Nullable<PendingFarmInvite[]>;
}

const initialState: FarmUsersState = {
    farmUsers: null,
    locations: null,
    pendingInvites: null,
};

const farmUsersSlice = createSlice({
    name: 'farmUsers',
    initialState,
    reducers: {
        addPendingInvites: (state, { payload }: PayloadAction<PendingFarmInvite[]>) => {
            state.pendingInvites = state.pendingInvites
                ? state.pendingInvites.concat(payload)
                : payload;
        },

        updatePendingInvite: (state, { payload }: PayloadAction<PendingFarmInvite>) => {
            state.pendingInvites = state.pendingInvites
                ? state.pendingInvites.map((invite) =>
                      invite.uuid === payload.uuid ? payload : invite
                  )
                : [payload];
        },

        removePendingInvite: (state, { payload }: PayloadAction<string>) => {
            state.pendingInvites =
                state.pendingInvites?.filter((pendingInvite) => pendingInvite.uuid !== payload) ??
                null;
        },

        setFarmUserLocations: (state, { payload }: PayloadAction<TeamTrackingChanges>) => {
            state.locations =
                state.locations !== null
                    ? {
                          ...filterObjectByKey(
                              (userId) => !payload.removed.includes(parseInt(userId, 10)),
                              state.locations
                          ),
                          ...payload.newOrUpdated,
                      }
                    : payload.newOrUpdated;
        },

        removeFarmUser: (state, { payload }: PayloadAction<number>) => {
            state.farmUsers = state.farmUsers?.filter((fu) => fu.id !== payload) ?? null;
        },

        setFarmUser: (state, { payload }: PayloadAction<FarmUser>) => {
            state.farmUsers =
                state.farmUsers?.map((fu) => (fu.id === payload.id ? payload : fu)) ?? null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadingFarm.toString(), always(initialState))
            .addCase(
                loadedFarmSuccess.toString(),
                (state, { payload }: PayloadAction<LoadedFarmSuccessPayload>) => {
                    state.farmUsers = sortBy(sortByName, payload.farmUsers);
                    state.pendingInvites = payload.pendingFarmUserInvites;
                }
            );
    },
});

export const {
    addPendingInvites,
    updatePendingInvite,
    removePendingInvite,
    setFarmUserLocations,
    removeFarmUser,
    setFarmUser,
} = farmUsersSlice.actions;
export const farmUsersReducer = farmUsersSlice.reducer;

/**
 * Selects farm users which can be displayed in lists of users.
 */
export const selectDisplayableFarmUsers = createSelector(
    (state: AppState) => state.farmUsersState.farmUsers,
    (farmUsers) => {
        logReselect('selectDisplayableFarmUsers');
        return farmUsers ? farmUsers.filter((fu) => !fu.temporary && fu.active) : [];
    }
);

/**
 * Same as selectDisplayableFarmUsers but puts the current user as the first item in the list.
 */
export const selectDisplayableFarmUsersWithUserFirst = createSelector(
    selectDisplayableFarmUsers,
    selectUserId,
    (farmUsers, userId) => {
        const farmUser = farmUsers.find((fu) => fu.id === userId);
        return farmUser ? [farmUser, ...farmUsers.filter((fu) => fu.id !== userId)] : farmUsers;
    }
);

/**
 * Combine active, non temporary farm users with the pending invites and sort them by:
 * - Owner first, alphabetically if there is more than one farm owner
 * - Alphabetically by first name for users or email for pending invites
 */
export const selectDisplayableFarmUsersAndPendingInvitesSortedByOwnerAndFirstName = createSelector(
    selectDisplayableFarmUsers,
    (state: AppState) => state.farmUsersState.pendingInvites,
    (farmUsers, pendingInvites) =>
        farmUsers
            .concat(
                pendingInvites
                    ? pendingInvites.map(({ email, role }) => ({
                          id: -1,
                          pending: true,
                          email,
                          firstName: '',
                          lastName: '',
                          owner: false,
                          role,
                          active: false,
                          temporary: false,
                      }))
                    : []
            )
            .sort((a, b) => {
                const aIsOwner = isFarmOwner(a);
                const bIsOwner = isFarmOwner(b);
                if (aIsOwner && !bIsOwner) {
                    return -1;
                }
                if (bIsOwner && !aIsOwner) {
                    return 1;
                }
                let firstComparison: string = a.firstName;
                let secondComparison: string = b.firstName;
                if (!a.firstName && !b.firstName) {
                    firstComparison = a.email;
                    secondComparison = b.email;
                }
                if (!a.firstName) {
                    firstComparison = a.email;
                }
                if (!b.firstName) {
                    secondComparison = b.email;
                }
                return firstComparison.toLowerCase() >= secondComparison.toLowerCase() ? 1 : -1;
            })
);

export const selectFarmOwners = createSelector(selectDisplayableFarmUsers, (farmUsers) =>
    farmUsers.filter((farmUser) => farmUser.role === FarmUserRole.OWNER)
);

export const selectCurrentFarmUser = createSelector(
    selectUserId,
    (state) => state.farmUsersState.farmUsers ?? [],
    (userId, farmUsers) => farmUsers.find((farmUser) => farmUser.id === userId) as FarmUser
);

/**
 * Selects if the current user is an owner of the current farm.
 */
export const selectIsFarmOwner = createSelector(selectCurrentFarmUser, (farmUser) =>
    isFarmOwner(farmUser)
);

export const selectFarmUsersFromSet = createSelector(
    (_: AppState, farmUserIds: Set<number>) => farmUserIds,
    selectDisplayableFarmUsers,
    (farmUserIds, farmUsers) => farmUsers.filter((farmUser) => farmUserIds.contains(farmUser.id))
);

export const selectPendingInvite = createSelector(
    (_: AppState, farmUser: FarmUser) => farmUser,
    (state: AppState) => state.farmUsersState.pendingInvites,
    (farmUser, pendingInvites) => pendingInvites?.find((invite) => invite.email === farmUser.email)
);

export const selectShouldBlockInviting = createSelector(
    selectCurrentFarm,
    selectDisplayableFarmUsers,
    (farm, farmUsers) => farmUsers.length >= farm.plan.userLimit
);

export const selectMaxInviteCount = createSelector(
    selectCurrentFarm,
    selectDisplayableFarmUsers,
    (farm, farmUsers) => {
        if (farmUsers.length > farm.plan.userLimit) {
            return 0;
        }
        const count = farm.plan.userLimit - farmUsers.length;
        return count < 10 ? count : 10;
    }
);

/**
 * Returns true if the farm has no subscription, otherwise returns true if the farm is using more
 * than 80% of its user limit.
 */
export const selectShouldShowUserLimitMessage = createSelector(
    selectCurrentFarm,
    selectDisplayableFarmUsers,
    (farm, farmUsers) => (farmHasNoSub(farm) ? true : farmUsers.length >= farm.plan.userLimit * 0.8)
);
