/**
 * This state handles sub field creation and editing.
 */

import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { createAction, handleActions } from '@fieldmargin/webapp-state';
import { List, Record, Set } from 'immutable';
import { getFirstFeatureGeometryFromCollection } from 'lib/geo/geometry';
import { updateInList } from 'lib/immutil';
import type { AppState } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

import type SubField from './sub-fields/SubField';
import { createSubField, resetSubFieldId } from './sub-fields/SubField';
import type Field from './Field';
import { modifyFieldBoundaryFeature } from './Field';
import { resetSubFieldColourIndex } from './sub-field-colours';

export enum SubFieldTool {
    NEW = 'new',
    SLICE = 'slice',
    CUT = 'cut',
    MARGIN = 'margin',
    MERGE = 'merge',
}

class SubFieldsState extends Record({
    selectedTool: undefined as SubFieldTool | undefined,
    subFields: List<SubField>(),
    selectedSubFields: Set<string>(),
    history: List<List<SubField>>(),
}) {}

export default SubFieldsState;

export const undoSubFieldChange = createAction<SubFieldsState, any>(
    'Undo sub field change',
    (state) =>
        state.withMutations((transState) => {
            transState.set('subFields', transState.history.last(List()));
            transState.set('history', transState.history.pop());
            transState.set('selectedSubFields', Set());
        })
);

/**
 * Thunk that resets naming & colour data for sub fields and then dispatches
 * the appropriate action to update the state.
 */
export const startSubFieldsFresh = (field: Field) => (dispatch, getState: () => AppState) => {
    const year = selectCurrentYear(getState());
    resetSubFieldId();
    resetSubFieldColourIndex();
    dispatch(startSubFieldsFreshAction(createSubField(field, year)));
};

/**
 * Resets the state to have a single, selected sub field that has the boundary of the given field
 * and no history
 */
const startSubFieldsFreshAction = createAction<SubFieldsState, SubField>(
    'Start sub field fresh',
    (state, subField) =>
        state.withMutations((subFieldsState) => {
            subFieldsState.set('subFields', List.of(subField));
            subFieldsState.set('selectedSubFields', Set.of(subField.id));
            subFieldsState.set('history', List());
        })
);

export const startSubFieldsFromExisting = (subFields: List<SubField>) => (dispatch) => {
    // Work out the number to use for any newly created sub fields. E.g. if there is an existing
    // sub field with name '2', the next id should be 3.
    const startingNumber = subFields
        .map((subField) => parseInt(subField.field.name, 10))
        .filter((value) => value >= 0)
        .sort()
        .reverse()
        .get(0);
    resetSubFieldId(startingNumber);
    dispatch(setSubFields(subFields));
};

export const stopSubFields = createAction<SubFieldsState, any>(
    'Stop sub fields',
    () => new SubFieldsState()
);

export const updateSubFieldBoundary = createAction<
    SubFieldsState,
    { subFieldId: string; geoFeature: GeoFeature }
>('Update sub field boundary', (state, { subFieldId, geoFeature }) => {
    const subField = state.subFields.find((subField) => subField.id === subFieldId);

    if (
        !subField ||
        !subField.field.geoJson ||
        getFirstFeatureGeometryFromCollection(subField.field.geoJson)?.equals(geoFeature.geometry)
    ) {
        return state;
    }

    return addToHistory(state).update('subFields', (subFields) =>
        updateInList(
            subFields,
            (sf) => sf.id === subField.id,
            (subField) =>
                subField.update('field', (field) => modifyFieldBoundaryFeature(field, geoFeature))
        )
    );
});

export const setSelectedSubFields = createAction<SubFieldsState, Set<string>>(
    'Set selected sub fields',
    (state, payload) => state.set('selectedSubFields', payload)
);

export const toggleSubFieldSelectedFromMap = createAction<SubFieldsState, string>(
    'Toggle sub field selected from map',
    (state, geoFeatureId) => {
        const selectedSubField = state.subFields.find(
            (subField) => subField.field.geoJson?.features.first<GeoFeature>()?.id === geoFeatureId
        );
        if (!selectedSubField) {
            return state;
        }

        return state.update('selectedSubFields', (selectedSubFields) =>
            selectedSubFields.contains(selectedSubField.id)
                ? selectedSubFields.remove(selectedSubField.id)
                : selectedSubFields.add(selectedSubField.id)
        );
    }
);

/**
 * Overwrite all sub fields with the given payload
 */
export const setSubFields = createAction<SubFieldsState, List<SubField>>(
    'Set sub fields',
    (state, payload) => state.set('subFields', payload)
);

/**
 * Updates a single sub field within the state without updating history
 */
export const setSubField = createAction<SubFieldsState, SubField>(
    'Set sub field',
    (state, payload) =>
        state.update('subFields', (subFields) =>
            updateInList<SubField>(
                subFields,
                (sf) => payload.id === sf.id,
                () => payload
            )
        )
);

/**
 * Updates a single sub field within the state and updates history
 */
export const setSubFieldAndUpdateHistory = createAction<SubFieldsState, SubField>(
    'Set sub field and update history',
    (state, payload) =>
        addToHistory(state).update('subFields', (subFields) =>
            updateInList<SubField>(
                subFields,
                (sf) => payload.id === sf.id,
                () => payload
            )
        )
);

export const updateSubFieldAndAddNew = createAction<
    SubFieldsState,
    { existingSubField: SubField; newSubField: SubField }
>('Update sub field and add new', (state, payload) =>
    state.withMutations((transState) => {
        addToHistory(transState);
        transState.update('subFields', (subFields) =>
            updateInList<SubField>(
                subFields,
                (sf) => payload.existingSubField.id === sf.id,
                () => payload.existingSubField
            ).push(payload.newSubField)
        );
        transState.set('selectedSubFields', Set());
        return transState;
    })
);

export const finishSubFieldManipulation = createAction<
    SubFieldsState,
    { existingSubField: SubField; newSubFields: List<SubField> }
>('Finish sub-field manipulation', (state, { existingSubField, newSubFields }) =>
    state.withMutations((transState) => {
        addToHistory(transState);
        transState.update('subFields', (subFields) =>
            updateInList<SubField>(
                subFields,
                (sf) => existingSubField.id === sf.id,
                () => existingSubField
            ).concat(newSubFields)
        );
        transState.set('selectedSubFields', Set());
    })
);

export const finishMerge = createAction<SubFieldsState, { toUpdate: SubField; toRemove: string }>(
    'Finish merge',
    (state, { toUpdate, toRemove }) =>
        state.withMutations((transState) => {
            addToHistory(transState);
            transState.update('subFields', (subFields) => {
                return updateInList<SubField>(
                    subFields,
                    (sf) => sf.id === toUpdate.id,
                    () => toUpdate
                ).filter((sf) => sf.id !== toRemove);
            });
            transState.set('selectedSubFields', Set.of(toUpdate.id));
        })
);

export const removeSubField = createAction<SubFieldsState, string>(
    'Remove sub field',
    (state, payload) =>
        state.withMutations((transState) => {
            addToHistory(transState);
            transState.update('subFields', (subFields) =>
                subFields.filter((subField) => subField.id !== payload)
            );
            transState.set('selectedSubFields', Set());
        })
);

export const startDrawingNew = createAction<SubFieldsState, any>('Start drawing new', (state) =>
    state.withMutations((transState) => {
        transState.set('selectedTool', SubFieldTool.NEW);
        transState.set('selectedSubFields', Set());
    })
);

export const stopDrawingNew = createAction<SubFieldsState, any>('Stop drawing new', (state) =>
    state.set('selectedTool', undefined)
);

export const finishDrawingNew = createAction<SubFieldsState, SubField>(
    'Finish drawing new',
    (state, payload) =>
        state.withMutations((transState) => {
            addToHistory(transState);
            transState.set('subFields', state.subFields.push(payload));
            transState.set('selectedSubFields', state.selectedSubFields.add(payload.id));
            transState.set('selectedTool', undefined);
        })
);

export const setSelectedTool = createAction<SubFieldsState, SubFieldTool | undefined>(
    'Set selected tool',
    (state, payload) => state.set('selectedTool', payload)
);

const addToHistory = (state: SubFieldsState) =>
    state.set('history', state.history.push(state.subFields));

export const subFieldsReducer = handleActions<SubFieldsState>(new SubFieldsState(), [
    undoSubFieldChange,
    startSubFieldsFreshAction,
    stopSubFields,
    setSelectedSubFields,
    toggleSubFieldSelectedFromMap,
    updateSubFieldBoundary,
    setSubFields,
    setSubField,
    setSubFieldAndUpdateHistory,
    updateSubFieldAndAddNew,
    finishSubFieldManipulation,
    finishMerge,
    removeSubField,
    startDrawingNew,
    stopDrawingNew,
    finishDrawingNew,
    setSelectedTool,
]);
