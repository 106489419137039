import { useTranslation } from 'react-i18next';

import type { PesticideCheckHarvestInterval } from './PesticideCheck';
import { PesticideCheckStatus } from './PesticideCheck';
import PesticideCheckResultIcon from './PesticideCheckResultIcon';

interface HarvestIntervalResultProps {
    check: PesticideCheckHarvestInterval;
}

const HarvestIntervalResult = ({ check }: HarvestIntervalResultProps) => {
    const { t } = useTranslation();
    return (
        <tr>
            <PesticideCheckResultIcon status={check.status} />
            <td>
                {check.status === PesticideCheckStatus.UNKNOWN
                    ? t('pesticide_check_harvest_interval_no_data')
                    : t('pesticide_check_harvest_interval', {
                          sprintf: [check.values?.harvestInterval],
                      })}
            </td>
        </tr>
    );
};

export default HarvestIntervalResult;
