import type { MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { connect } from 'react-redux';
import { useToggle } from '@fieldmargin/webapp-state';
import type Field from 'fields/Field';
import { isCreator } from 'lib/owner';
import Modal from 'modal/Modal';
import type { AppState } from 'system/store';
import { selectIsFarmOwner } from 'team/farm-users-state';
import { selectUserId } from 'users/user-state';

import type { BulkFieldEditFormValues } from './BulkFieldEdit';

interface FieldEditDeleteCheckboxProps {
    // From parent
    field: Field;
    // From redux
    userCanDelete: boolean;
}

const FieldEditDeleteCheckbox = ({ field, userCanDelete }: FieldEditDeleteCheckboxProps) => {
    const { register } = useFormContext<BulkFieldEditFormValues>();
    const [showBlockedModal, toggleShowBlockedModal] = useToggle(false);
    const handleClick = (e: MouseEvent) => {
        if (!userCanDelete) {
            e.preventDefault();
            toggleShowBlockedModal();
        }
    };
    return (
        <div className="Checkbox -mt-6 relative">
            {showBlockedModal && <BlockedModal onClose={toggleShowBlockedModal} />}
            <input
                type="checkbox"
                id={`fields.${field.uuid}.remove`}
                className="Checkbox-input"
                onClick={handleClick}
                {...register(`fields.${field.uuid}.remove`)}
            />
            <label htmlFor={`fields.${field.uuid}.remove`} className="Checkbox-label" />
        </div>
    );
};

export default connect(
    (state: AppState, { field }: Pick<FieldEditDeleteCheckboxProps, 'field'>) => ({
        userCanDelete: selectIsFarmOwner(state) || isCreator(field, selectUserId(state)),
    })
)(FieldEditDeleteCheckbox);

const BlockedModal = ({ onClose }: { onClose: VoidFunction }) => {
    const { t } = useTranslation();
    return (
        <Modal onClose={onClose}>
            <div className="BulkFieldEditWarningModal">
                <h2>{t('caution')}</h2>
                <Markdown>{t('field_bulk_edit_blocked')}</Markdown>
            </div>
        </Modal>
    );
};
