import { useTranslation } from 'react-i18next';
import c from 'classnames';

interface OptionalLabelProps {
    className?: string;
}

const OptionalLabel = ({ className }: OptionalLabelProps) => {
    const { t } = useTranslation();
    return (
        <em className={c('text-gray-450 font-medium', className)}>{t('create_note_optional')}</em>
    );
};

export default OptionalLabel;
