import type { ChatNotification } from 'chat/chat-state';

interface UnreadNotificationProps {
    notification: ChatNotification;
}

const UnreadNotification = ({ notification }: UnreadNotificationProps) => (
    <div className="unread-notification">
        <p>You have {notification.payload} unread messages</p>
    </div>
);

export default UnreadNotification;
