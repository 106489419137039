import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import type { PesticideData } from 'inputs/Pesticide';

interface RecordingPesticideDataProps {
    pending: boolean;
    pesticideData?: PesticideData;
    unit: ReactNode;
}

const RecordingPesticideData = ({ pending, pesticideData, unit }: RecordingPesticideDataProps) => {
    const { t } = useTranslation();
    return (
        <div className="flex justify-between px-5 py-2">
            {pending || pesticideData === undefined ? (
                <p>
                    <em>{t('loading_pesticide_info')}</em>
                </p>
            ) : (
                <>
                    <span>
                        <strong>{t('input_reg_number')}</strong> {pesticideData.registrationNumber}
                    </span>
                    <span>
                        <strong>{t('type')}</strong> {pesticideData.productCategory.name}
                    </span>
                    <span>
                        <strong>{t('unit')}</strong> {unit}
                    </span>
                </>
            )}
        </div>
    );
};

export default RecordingPesticideData;
