import type { RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';

import { JohnDeere } from './JohnDeere';
import { JohnDeereAuthRedirect } from './JohnDeereAuthRedirect';
import { JohnDeereConnect } from './JohnDeereConnect';
import { JohnDeereConnectOrganisation } from './JohnDeereConnectOrganisation';
import { JohnDeereFarmOverview } from './JohnDeereFarmOverview';
import { JohnDeereImport } from './JohnDeereImport';

export const johnDeereRoutes: RouteObject = {
    path: APP_ROUTES.Fields.JohnDeere,
    element: <JohnDeere />,
    children: [
        {
            element: <JohnDeereAuthRedirect />,
            children: [
                {
                    index: true,
                    element: <JohnDeereConnect />,
                },
                {
                    path: 'select',
                    element: <JohnDeereConnectOrganisation />,
                },
                {
                    path: 'overview',
                    element: <JohnDeereFarmOverview />,
                },
                {
                    path: 'import',
                    element: <JohnDeereImport />,
                },
            ],
        },
    ],
};
