import { isNaN } from 'lodash';
import { curryN, flip } from 'ramda';

export const bytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
        return 'n/a';
    }
    const i = parseInt('' + Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) {
        return `${bytes} ${sizes[i]}`;
    }
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
};

export const roundToDp = (value: number, dp: number) => {
    const mult = Math.pow(10, dp);
    return Math.round(value * mult) / mult;
};

export const roundToSf = (value: number, sf: number) => {
    const converted = +value.toPrecision(sf);
    return converted === Infinity ? 0 : converted;
};

export const parseNumeric = (value: string, nsv?: number) => {
    const num = +value.replace(',', '.');
    return nsv !== undefined && isNaN(num) ? nsv : num;
};

export const parseNumericOrZero = curryN(2, flip(parseNumeric))(0);

export const isNumeric = (value: string) => !isNaN(parseNumeric(value));

export const isNumericPositive = (value: string) => value && isNumeric(value) && +value >= 0;

export const formatPercentage = (value: number) => `${Math.round(value * 100)}%`;
