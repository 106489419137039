import SpeedUnit from 'view/units/SpeedUnit';
import SpeedValue from 'view/units/SpeedValue';
import type { DayForecast, HourForecast } from 'weather/Weather';

import windArrow from './wind-bearing.svg';

interface WeatherWindProps {
    forecast: DayForecast | HourForecast;
}

const WeatherWind = ({ forecast }: WeatherWindProps) => {
    return (
        <div>
            <WindArrow windBearing={forecast.windDirection} />
            <div>
                <SpeedValue value={Math.round(forecast.windSpeed)} round /> <SpeedUnit />
            </div>
        </div>
    );
};

export default WeatherWind;

const WindArrow = ({ windBearing }: { windBearing: number }) => {
    const style = {
        transform: `rotate(${windBearing + 180}deg)`,
    };
    return <img className="mb-4 mx-auto" src={windArrow} style={style} />;
};
