import type { Activity } from 'activity/activity-helper';
import { isAfter, isBefore, parseISO, startOfDay } from 'date-fns';
import type { List } from 'immutable';
import Note from 'notes/Note';
import type { FilterDateType } from 'types';

import { filterActivitiesByDateType } from './filterActivitiesByDateType';

export const filterActivitiesByDateRange = (
    dateRange: Array<string | undefined>,
    dateTypes: Array<FilterDateType>,
    activities: List<Activity>
) => {
    const [startDateStr, endDateStr] = dateRange;
    const startDate = startDateStr ? startOfDay(parseISO(startDateStr)) : null;
    const endDate = endDateStr ? startOfDay(parseISO(endDateStr)) : null;

    const filteredByType = filterActivitiesByDateType(dateTypes, activities);

    if (!startDate && !endDate) {
        return filteredByType;
    }

    return filteredByType.filter((activity) => {
        const isNote = activity instanceof Note;

        //normalized dates so the time is ignorred
        const activityDates = dateTypes
            .map((dateType) => {
                switch (dateType) {
                    case 'completedDate':
                        return isNote ? activity.completedDate : activity.summary.completedDate;
                    case 'createdDate':
                        return isNote ? activity.createdDate : activity.summary.createdDate;
                    case 'dueDate':
                        return isNote ? activity.dueDate : activity.summary.dueDate;
                    default:
                        return null;
                }
            })
            .filter(Boolean)
            .map((date) => startOfDay(parseISO(date!.toISOString())));

        return activityDates.some((activityDate) => {
            if (!activityDate) return false;

            if (startDate && endDate) {
                //return start and end dates are specified
                return !isBefore(activityDate, startDate) && !isAfter(activityDate, endDate);
            } else if (startDate) {
                //return start date is specified
                return !isBefore(activityDate, startDate);
            } else if (endDate) {
                // return end date is specified
                return !isAfter(activityDate, endDate);
            } else {
                return true;
            }
        });
    });
};
