import type { ComponentProps } from 'react';

function ExternalLink({ fill = 'currentColor', ...props }: ComponentProps<'svg'>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 19" {...props}>
            <path
                fill={fill}
                d="M2.5 18c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 .5 16V2c0-.55.196-1.02.587-1.413A1.926 1.926 0 0 1 2.5 0h7v2h-7v14h14V9h2v7c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 16.5 18h-14Zm4.7-5.3-1.4-1.4L15.1 2h-3.6V0h7v7h-2V3.4l-9.3 9.3Z"
            />
        </svg>
    );
}

export { ExternalLink };
