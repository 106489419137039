import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import { undoSubFieldChange } from 'fields/sub-fields-state';
import { useAppDispatch } from 'system/store';

import { subFieldIcon } from '../sub-field-icons';

interface SubFieldsUndoProps {
    disabled: boolean;
}

const SubFieldsUndo = ({ disabled }: SubFieldsUndoProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleUndo = (e?: MouseEvent) => {
        e && e.preventDefault();
        dispatch(undoSubFieldChange());
    };

    return (
        <Button
            small
            variant="outline"
            onClick={handleUndo}
            className="flex items-center ml-auto"
            disabled={disabled}
        >
            {subFieldIcon('undo', disabled ? 'grey' : 'blue', 'mr-3')}
            {t('draw_shapes_undo')}
        </Button>
    );
};

export default SubFieldsUndo;
