import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { Set } from 'immutable';
import { List, Map } from 'immutable';
import type { FieldInputReportSummary, InputReportSummary } from 'inputs/InputReportSummary';
import type { InputType } from 'inputs/InputType';
import { getInputTypeI18nKey, getSortedInputTypes } from 'inputs/InputType';
import { useItemSelectedState } from 'lib/hooks';
import type FullOperation from 'operations/FullOperation';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import type { InputRecordingsByOperation } from './field-reporting-utils';
import { getInputRecordingsByOperation } from './field-reporting-utils';
import FieldInputTableRow from './FieldInputTableRow';
import FieldReportTable from './FieldReportTable';

interface FieldInputTableProps {
    farm: Farm;
    fieldUuids: Set<string>;
    fieldInputReportSummary: FieldInputReportSummary;
    operations: List<FullOperation>;
}

const FieldInputTable = ({
    farm,
    fieldUuids,
    fieldInputReportSummary,
    operations,
}: FieldInputTableProps) => {
    const { t } = useTranslation();
    const [toggleOpen, isOpen] = useItemSelectedState();

    if (fieldInputReportSummary.inputs.length === 0) {
        return null;
    }

    const summariesByType = fieldInputReportSummary.inputs.reduce((map, inputReportSummary) => {
        if (inputReportSummary.sumRateHa === 0) {
            return map;
        }
        const type = inputReportSummary.inputType;
        if (map.has(type)) {
            return map.set(
                type,
                (map.get(type) as List<InputReportSummary>).push(inputReportSummary)
            );
        }
        return map.set(type, List.of(inputReportSummary));
    }, Map<InputType, List<InputReportSummary>>());

    const inputRecordingsByOperation = getInputRecordingsByOperation(
        fieldUuids,
        operations,
        fieldInputReportSummary.inputs
    );

    return (
        <FieldReportTable
            headings={[
                t('field_details_input'),
                t('input_reg_number'),
                <>
                    {t('operation_details_input_rate_empty')}
                    <ShortAreaUnit />
                </>,
                t('total'),
            ]}
        >
            {getSortedInputTypes().map((inputType) =>
                summariesByType.has(inputType) ? (
                    <Fragment key={inputType}>
                        <tr>
                            <th colSpan={4} className="type">
                                {t(getInputTypeI18nKey(inputType))}
                            </th>
                        </tr>
                        {(summariesByType.get(inputType) as List<InputReportSummary>)
                            .sortBy((i) => i.name)
                            .map((i) => (
                                <FieldInputTableRow
                                    key={i.uuid}
                                    farm={farm}
                                    inputReportSummary={i}
                                    recordingsByOperation={
                                        inputRecordingsByOperation.get(
                                            i.uuid
                                        ) as List<InputRecordingsByOperation>
                                    }
                                    open={isOpen(i.uuid)}
                                    toggleOpen={() => toggleOpen(i.uuid)}
                                />
                            ))}
                    </Fragment>
                ) : null
            )}
        </FieldReportTable>
    );
};

export default FieldInputTable;
