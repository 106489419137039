import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { InputTypeColour } from 'inputs/InputType';
import { getInputTypeI18nKey, INPUT_TYPE_COLOURS } from 'inputs/InputType';
import pattern from 'patternomaly';

import type { GrossMargin } from './GrossMargin';

import './GrossMarginByFieldLegend.css';

interface GrossMarginByFieldLegendProps {
    datum: GrossMargin;
}

const GrossMarginByFieldLegend = ({ datum }: GrossMarginByFieldLegendProps) => {
    const { t } = useTranslation();
    return (
        <div className="GrossMarginByFieldLegend">
            {datum.inputs && (
                <>
                    <h4>{t('inputs_title')}</h4>
                    <ul>
                        {datum.inputs.map((input) => (
                            <li key={input.type}>
                                <GrossMarginLegendBox colour={INPUT_TYPE_COLOURS[input.type]} />
                                <p>{t(getInputTypeI18nKey(input.type))}</p>
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {datum.outputs && (
                <>
                    <h4>{t('outputs_title')}</h4>
                    <ul>
                        {datum.outputs.map((output) => (
                            <li key={output.type}>
                                <GrossMarginLegendBox colour={{ background: '#d7dfe3' }} />
                                <p>{output.type}</p>
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {datum.margin && (
                <ul>
                    <li>
                        <span className="gross-margin-item" />
                        <p>{t('gross_margin_title')}</p>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default GrossMarginByFieldLegend;

const GrossMarginLegendBox = ({ colour }: { colour: InputTypeColour }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (canvasRef.current !== null) {
            const ctx = canvasRef.current.getContext('2d');
            if (ctx !== null) {
                const p =
                    colour.pattern !== undefined
                        ? pattern.draw(colour.pattern, '#fff', colour.background)
                        : colour.background;
                ctx.fillStyle = p;
                ctx.fillRect(0, 0, 48, 16);
                ctx.strokeStyle = colour.pattern ? '#ccc' : colour.background;
                ctx.strokeRect(0, 0, 48, 16);
            }
        }
    }, [canvasRef.current]);

    return <canvas ref={canvasRef} height={16} width={48} className="mr-3" />;
};
