import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import { usePageParams } from 'hooks/usePageParamas';
import { useSearchAutoBoundaryArea } from 'hooks/useSearchAutoBoundaryArea';
import { useSelectAutoBoundaryAreaTracking } from 'hooks/useSegmentTracking';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import ErrorMessage from 'view/ErrorMessage';
import ReturningLinkButton from 'view/ReturningLinkButton';

interface AutoBoundarySelectAreaProps {
    onDone: () => void;
}

const AutoBoundarySelectArea = ({ onDone }: AutoBoundarySelectAreaProps) => {
    const { t } = useTranslation();
    const { farmUuid } = usePageParams();
    const { isDisabled, onSearchAutoBoundaryArea, isPending, noBoundaries } =
        useSearchAutoBoundaryArea(onDone);

    useSelectAutoBoundaryAreaTracking();

    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader className="px-5">
                    <ReturningLinkButton
                        to={`/farms/${farmUuid}/fields/new`}
                        variant="danger-outline"
                        small
                    >
                        {t('cancel')}
                    </ReturningLinkButton>
                    <Button disabled={isDisabled} small onClick={onSearchAutoBoundaryArea}>
                        {isPending ? t('searching') : t('search')}
                    </Button>
                </SidebarHeader>
            </div>
            <div className="scrolling">
                <SidebarModule editing>
                    <h4>{t('field_boundary_auto_select_area')}</h4>
                    <p>{t('field_boundary_auto_select_area_desc')}</p>
                    {noBoundaries && <ErrorMessage>{t('digifarm_sorry_no_result')}</ErrorMessage>}
                </SidebarModule>
            </div>
        </div>
    );
};

export default AutoBoundarySelectArea;
