import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePromise } from '@fieldmargin/webapp-state';
import { SidebarCard } from 'components/sidebar-card';
import type FullOperation from 'operations/FullOperation';
import type { OperationType } from 'operations/Operation';
import EditableActions from 'sidebar/modules/common/EditableActions';
import TextInputField from 'view/form/hook/TextInputField';
import { required } from 'view/form/validations';

import OperationTypeFormField from '../OperationTypeFormField';

interface OperationDetailsTitleAndTypeFormProps {
    fullOperation: FullOperation;
    onSave: (name: string, type: OperationType) => Promise<void>;
    toggleEditing: VoidFunction;
}

interface FormValues {
    name: string;
    type: OperationType;
}

const OperationDetailsTitleAndTypeForm = ({
    fullOperation,
    onSave,
    toggleEditing,
}: OperationDetailsTitleAndTypeFormProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise(toggleEditing);
    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            name: fullOperation.summary.name,
            type: fullOperation.summary.type,
        },
    });
    const requiredValidation = required(t('register_required'));

    const handleSubmit = (values: FormValues) => {
        setPromise(onSave(values.name, values.type));
    };

    return (
        <SidebarCard.Root>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <SidebarCard.Heading as="h4">{t('field_job_type')}</SidebarCard.Heading>
                    <OperationTypeFormField />
                    <SidebarCard.Heading as="h4">{t('title')}</SidebarCard.Heading>
                    <TextInputField
                        field="name"
                        inputClassName="w-full"
                        validate={requiredValidation}
                    />
                    <EditableActions disabled={pending} error={error} setEditing={toggleEditing} />
                </form>
            </FormProvider>
        </SidebarCard.Root>
    );
};

export default OperationDetailsTitleAndTypeForm;
