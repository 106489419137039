import { useDispatch, useSelector } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { AxiosError } from 'axios';
import { selectCurrentFarm } from 'farms/farms-state';
import {
    createFeatureType,
    toggleArchiveFeatureType,
    updateFeatureType,
} from 'features/features-api';
import { setFeatureType } from 'features/features-state';
import type FeatureType from 'features/FeatureType';
import { featureTypeToDiff, trackFeatureTypeChange } from 'features/FeatureType';
import { dispatchAndReturn } from 'lib/fp-helpers';
import { mergeRecord } from 'lib/immutil';
import { flow } from 'lodash';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';

export const useCreateFeatureType = (postSave: SingleParamVoidFunction<FeatureType>) => {
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);
    const dispatch = useDispatch();

    return (name: string, colour: string) =>
        createFeatureType(farm.uuid, name, colour)
            .then(trackFeatureTypeChange('created'))
            .then(dispatchAndReturn(dispatch, setFeatureType))
            .then(postSave);
};

export const useUpdateFeatureType = (featureType: FeatureType, postSave: VoidFunction) => {
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);
    const dispatch = useDispatch();

    const possiblePostSave = (postSave: VoidFunction) => (e?: AxiosError) => {
        e === undefined && postSave();
        return Promise.reject();
    };

    return (name: string, colour: string) =>
        updateFeatureType(
            farm.uuid,
            featureType.uuid,
            featureTypeToDiff(featureType, { name, colour })
        )
            // if the promise is rejected at this stage it could be because the API call
            // was not necessary due to no data having changed, in which case we want to call
            // postSave like the update has been successful.
            .catch(possiblePostSave(postSave))
            .then(mergeRecord<FeatureType>(featureType))
            .then(trackFeatureTypeChange('updated'))
            .then(flow(setFeatureType, dispatch))
            .then(postSave);
};

export const useToggleFeatureTypeArchived = () => {
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);
    const dispatch = useDispatch();

    return (featureType: FeatureType) =>
        toggleArchiveFeatureType(farm.uuid, featureType.uuid, !featureType.archived)
            .then(() => featureType.set('archived', !featureType.archived))
            .then(flow(setFeatureType, dispatch));
};
