import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const drawingSlice = createSlice({
    name: 'drawing',
    initialState,
    reducers: {
        startDrawingAction: () => {},
        stopDrawingAction: () => {},
    },
});

export const { startDrawingAction, stopDrawingAction } = drawingSlice.actions;
export const drawingReducer = drawingSlice.reducer;
