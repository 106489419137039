import type { PropsWithChildren } from 'react';

const CardCheckboxList = ({ children }: PropsWithChildren) => {
    return (
        <ul className="p-0 list-none flex items-center justify-center flex-wrap gap-4">
            {children}
        </ul>
    );
};

export default CardCheckboxList;
