import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePromise } from '@fieldmargin/webapp-state';
import { stringContains } from 'lib/util/text';

import type Input from './Input';
import { getPrepopulatedFertilisers } from './inputs-api';
import { selectActiveInputs } from './inputs-state';

export const usePrepopulatedFertilisers = (
    searchTerm: string = ''
): [boolean, boolean, Input[]] => {
    const [fertilisers, setFertilisers] = useState<Input[]>([]);
    const { pending, error, setPromise } = usePromise<Input[]>(setFertilisers);
    const inputNames = useSelector(selectActiveInputs).map((i) => i.name);

    useEffect(() => {
        setPromise(getPrepopulatedFertilisers());
    }, []);

    return [
        pending,
        error,
        fertilisers.filter(
            (f) => !inputNames.includes(f.name) && stringContains(f.name, searchTerm)
        ),
    ];
};
