import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { List } from 'immutable';
import { Form, Option, Select } from 'informed';
import { useOutOfDatePromise } from 'lib/hooks';
import type { AppState } from 'system/store';

import EditableActions from '../common/EditableActions';
import EditButton from '../common/EditButton';
import SidebarModule from '../SidebarModule';

interface EditableYearProps {
    // From parent
    value: number;
    onSave: (newYear: number) => Promise<any>;

    // From redux
    years: List<number>;
}

interface FormValues {
    // Values in an <option> are turned into strings when it changes so we'll force them to be
    // strings and then convert to a number on submit.
    year: string;
}

const EditableYear = ({ value, onSave, years }: EditableYearProps) => {
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(() => setEditing(false));

    const handleSubmit = (values: FormValues) => {
        setPromise(onSave(parseInt(values.year, 10)));
    };

    if (editing) {
        return (
            <SidebarModule className="EditableYear" editing={true}>
                <Form<FormValues>
                    initialValues={{ year: value.toString() }}
                    onSubmit={handleSubmit}
                >
                    <h4>{t('activity_log_year')}</h4>
                    <div className="select-form-field">
                        <Select field="year">
                            {years.map((year) => (
                                <Option key={year} value={year}>
                                    {year}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <EditableActions
                        disabled={pending}
                        error={error}
                        outOfDate={outOfDate}
                        setEditing={setEditing}
                    />
                </Form>
            </SidebarModule>
        );
    }

    return (
        <SidebarModule className="EditableYear">
            <div className="viewing-row">
                <EditButton setEditing={setEditing} />
                <h4>{t('activity_log_year')}</h4>
                <div className="value">{value}</div>
            </div>
        </SidebarModule>
    );
};

export default connect((state: AppState) => ({
    years: state.yearsState.years,
}))(EditableYear);
