import { selectUserHasAnyFarmsWithSubscription } from 'farms/farms-state';
import { useAppSelector } from 'system/store';

const useUserDeletionPermission = () => {
    const userCanDelete = useAppSelector((state) => !selectUserHasAnyFarmsWithSubscription(state));

    return {
        userCanDelete,
    };
};

export { useUserDeletionPermission };
