import fmLogo from './fm-logo.svg';

interface FMLogoDarkProps {
    width: string;
}

const FMLogoDark = ({ width }: FMLogoDarkProps) => (
    <img src={fmLogo} alt="fieldmargin logo" width={width} />
);

export default FMLogoDark;
