import { useState } from 'react';
import { Form } from 'informed';
import { useOutOfDatePromise } from 'lib/hooks';
import DateAndTimeField from 'sidebar/modules/date/DateAndTimeField';

import EditableActions from '../common/EditableActions';
import EditButton from '../common/EditButton';
import SidebarModule from '../SidebarModule';

interface EditableDateAndTimeProps {
    label: string;
    value: Date | null;
    onSave: (newDate: Date | null) => Promise<any>;
    required: boolean;
    children: React.ReactNode;
    openDirection?: 'top' | 'bottom';
}

interface FormValues {
    date: Date | null;
}

const EditableDateAndTime = ({
    label,
    value,
    onSave,
    required,
    children,
    openDirection = 'top',
}: EditableDateAndTimeProps) => {
    const [editing, setEditing] = useState(false);
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(() => setEditing(false));

    const handleSubmit = (values: FormValues) => {
        setPromise(onSave(values.date));
    };
    if (editing) {
        return (
            <SidebarModule className="EditableDateAndTime" editing={true}>
                <Form<FormValues> initialValues={{ date: value }} onSubmit={handleSubmit}>
                    <h4>{label}</h4>
                    <DateAndTimeField
                        field="date"
                        openDirection={openDirection}
                        allowDelete={!required}
                    />
                    <EditableActions
                        disabled={pending}
                        error={error}
                        outOfDate={outOfDate}
                        setEditing={setEditing}
                    />
                </Form>
            </SidebarModule>
        );
    }

    return (
        <SidebarModule className="EditableDateAndTime">
            <EditButton setEditing={setEditing} />
            <h4>{label}</h4>
            {children}
        </SidebarModule>
    );
};

export default EditableDateAndTime;
