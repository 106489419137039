import { Outlet } from 'react-router-dom';

import { JohnDeereNavigation } from './JohnDeereNavigation';

import styles from './JohnDeere.module.css';

function JohnDeere() {
    return (
        <div className={styles.pageWrapper}>
            <div className={styles.pageContainer}>
                <JohnDeereNavigation />
                <Outlet />
            </div>
        </div>
    );
}

export { JohnDeere };
