import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { GeoFeature, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { extentFromGeo } from '@fieldmargin/webapp-geo';
import { useToggle } from '@fieldmargin/webapp-state';
import icon from 'components/icons/icon';
import { preventDefaultAnd } from 'lib/dom';
import ViewOnMap from 'sidebar/modules/shapes/components/ViewOnMap';
import ShapeAndFieldsField from 'view/form/hook/ShapeAndFieldsField';
import Notice from 'view/Notice';

import FieldBoundaryBubble from './FieldBoundaryBubble';
import FieldBoundaryDraw from './FieldBoundaryDraw';

interface FieldBoundaryCreationProps {
    editingGeoFeatureCollection: GeoFeatureCollection | null;
    editing?: boolean;
    onInfoClick?: VoidFunction;
    includeAutoBoundary?: boolean;
}

const FieldBoundaryContainer = ({
    editingGeoFeatureCollection,
    editing = true,
    onInfoClick,
    includeAutoBoundary = false,
}: FieldBoundaryCreationProps) => {
    const { t } = useTranslation();
    const [editHelp, toggleEditHelp] = useToggle(editing === true);

    const handleInfoClick = useCallback(
        preventDefaultAnd(() => onInfoClick && onInfoClick()),
        [onInfoClick]
    );

    const hasBoundary =
        editingGeoFeatureCollection !== null &&
        editingGeoFeatureCollection.features.first<GeoFeature>()?.geometry.type === 'Polygon';

    const viewExtent = hasBoundary ? extentFromGeo(editingGeoFeatureCollection) : null;

    return (
        <>
            <h4 className="px-5 m-0 pt-5 flex items-center">
                {t('field_create_boundary')}{' '}
                {onInfoClick && (
                    <button className="bare-btn ml-2 focus" onClick={handleInfoClick}>
                        {icon('info', 'black', 'w-4')}
                    </button>
                )}
            </h4>
            {hasBoundary ? (
                <>
                    <span className="px-5 mb-1 block">{t('field_boundary_mapped')}</span>
                    <FieldBoundaryBubble
                        editingGeoFeatureCollection={editingGeoFeatureCollection}
                        editing={editing}
                    />
                    {viewExtent !== null && <ViewOnMap extent={viewExtent} className="mx-5 my-2" />}
                    {editHelp && (
                        <Notice className="relative">
                            <p className="mb-0">{t('field_boundary_edit_help')}</p>
                            <button
                                className="bare-btn absolute top-1 right-1"
                                onClick={preventDefaultAnd(toggleEditHelp)}
                            >
                                {icon('close', 'dark-blue', 'w-3')}
                            </button>
                        </Notice>
                    )}
                </>
            ) : (
                <FieldBoundaryDraw includeAutoBoundary={includeAutoBoundary} />
            )}
            {editing && editingGeoFeatureCollection !== null && (
                <ShapeAndFieldsField shapes={editingGeoFeatureCollection} required />
            )}
        </>
    );
};

export default FieldBoundaryContainer;
