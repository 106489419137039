import { uuidMatches } from 'lib/fp-helpers';
import { selectOperations } from 'operations/operations-selectors';
import { useAppSelector } from 'system/store';

import { usePageParams } from './usePageParamas';

const useCurrentOperation = () => {
    const { operationUuid } = usePageParams();
    const operations = useAppSelector(selectOperations);
    const outputs = useAppSelector((state) => state.outputsState.outputs);

    const currentOperation = operationUuid ? operations?.get(operationUuid) : undefined;
    const output =
        currentOperation && currentOperation.summary.outputUuid !== null
            ? outputs.find(uuidMatches(currentOperation.summary.outputUuid))
            : undefined;
    return {
        currentOperation,
        output,
    };
};

export { useCurrentOperation };
