/**
 * Renders numbers in a user friendly way.
 */
type FormatProps =
    | {
          places?: number;
          sigFigs?: never;
      }
    | {
          places?: never;
          sigFigs?: number;
      };

type NumProps = { value: number; compact?: boolean } & FormatProps;

const Num = ({ value, compact = false, places = 2, sigFigs }: NumProps) => {
    const opts: Intl.NumberFormatOptions =
        sigFigs !== undefined
            ? { maximumSignificantDigits: sigFigs }
            : { maximumFractionDigits: places };

    if (compact) {
        opts.notation = 'compact';
    }
    const formatter = new Intl.NumberFormat(window.navigator.language, opts);
    return <>{formatter.format(value)}</>;
};

export default Num;
