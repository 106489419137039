import {
    detectBoundariesLogo,
    drawBoundariesLogo,
    importShapeFileLogo,
    johnDeereLogo,
    rpaLogo,
} from '@notes-web/assets';

export function addFieldOptions(
    app: 'fieldmargin' | 'agreena',
    options: Array<
        'detect-boundaries' | 'rpa' | 'john-deere' | 'draw-boundaries' | 'shape-file' | 'all'
    >
) {
    const data = {
        ['detect-boundaries']: {
            path: app === 'agreena' ? '../boundaries' : '../fields/new/auto',
            title: 'addFields.options.detectBoundaries.title',
            description: 'addFields.options.detectBoundaries.description',
            logo: detectBoundariesLogo,
            disabled: false,
        },
        ['draw-boundaries']: {
            path: app === 'agreena' ? '../draw' : '../fields/new',
            title: 'addFields.options.drawBoundaries.title',
            description: 'addFields.options.drawBoundaries.description',
            logo: drawBoundariesLogo,
            disabled: false,
        },
        rpa: {
            path: app === 'agreena' ? '../rpa' : '../fields/rpa',
            title: 'addFields.options.rpa.title',
            description: 'addFields.options.rpa.description',
            logo: rpaLogo,
            disabled: false,
        },
        ['john-deere']: {
            path: '../john-deere',
            title: 'addFields.options.johnDeere.title',
            description: 'addFields.options.johnDeere.description',
            logo: johnDeereLogo,
            disabled: false,
        },
        ['shape-file']: {
            path: '../fields/boundary-uploader',
            title: 'addFields.options.shapeFiele.title',
            description: 'addFields.options.shapeFiele.description',
            logo: importShapeFileLogo,
            disabled: false,
        },
    };

    if (options.includes('all')) {
        return Object.values(options);
    }

    return options.map((key) => data[key]).filter(Boolean);
}
