import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';

import reportingEmpty from './reporting-empty.png';

interface InputReportingEmptyProps {
    farm: Farm;
}

const InputReportingEmpty = ({ farm }: InputReportingEmptyProps) => {
    const { t } = useTranslation();

    return (
        <div className="mt-8 text-xl text-center">
            <p className="text-fm-blue mb-5">{t('input_summary_empty_no_inputs1')}</p>
            <p className="text-fm-blue mb-5">
                <MarkdownNoPara>{t('input_summary_empty_no_inputs2')}</MarkdownNoPara>
            </p>
            <p className="mt-8 mb-5 mx-auto w-1/4">
                <Button
                    as={Link}
                    to={`/farms/${farm.uuid}/operations/new`}
                    className="flex items-center justify-center"
                >
                    {icon('field-job', 'white', 'mr-2')}
                    {t('add_a_field_job')}
                </Button>
            </p>
            <img
                className="mx-auto"
                src={reportingEmpty}
                alt="Screenshot of reporting in fieldmargin"
            />
        </div>
    );
};

export default InputReportingEmpty;
