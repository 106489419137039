import { Outlet } from 'react-router-dom';
import FarmOverviewFullScreen from 'view/FarmOverviewFullScreen';

import './Reporting.css';

const ReportingContainer = () => {
    return (
        <FarmOverviewFullScreen>
            <div className="min-h-content-full mx-auto p-8">{<Outlet />}</div>
        </FarmOverviewFullScreen>
    );
};

export default ReportingContainer;
