import type { ReactNode } from 'react';

import EditButton from '../common/EditButton';

interface TitleViewProps {
    setEditing: (editing: boolean) => void;
    value: string;
    children?: ReactNode;
}

const TitleView = ({ setEditing, value, children }: TitleViewProps) => {
    return (
        <div>
            <EditButton setEditing={setEditing} />
            <h2>{value}</h2>
            {children}
        </div>
    );
};

export default TitleView;
