import type { MouseEvent } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import c from 'classnames';
import icon from 'components/icons/icon';

import './PlusButtonLink.css';

interface PlusButtonLinkProps extends LinkProps {
    // From parent
    disabled?: boolean;
}

const PlusButtonLink = ({
    children,
    disabled = false,
    className,
    ...props
}: PlusButtonLinkProps) => {
    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        disabled && e.preventDefault();
        props.onClick && props.onClick(e);
    };
    return (
        <Link
            {...props}
            onClick={handleClick}
            className={c('PlusButtonLink focus', { disabled }, className)}
        >
            <span className={c('PlusButtonLinkIcon', { disabled })}>
                {icon('add', disabled ? 'grey' : 'blue', 'mt-0')}
            </span>{' '}
            {children}
        </Link>
    );
};

export default PlusButtonLink;
