import { geojson } from '@fieldmargin/webapp-ol-map';
import type Feature from 'ol/Feature';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

class InitialsController extends geojson.FeaturesController {
    constructor(zIndex: number) {
        super(zIndex, {});
    }

    protected styleFeature(feature: Feature) {
        const image = new Circle({
            radius: 17,
            fill: new Fill({ color: '#bff3bf' }),
            stroke: new Stroke({ color: 'white', width: 2 }),
        });
        return new Style({
            image: image,
            text: new Text({
                text: feature.get('name'),
                font: 'bold 14px sans-serif',
                fill: new Fill({
                    color: '#2c3e50',
                }),
            }),
            zIndex: feature.get('zIndex'),
        });
    }
}

export default InitialsController;
