import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from '@fieldmargin/webapp-styling/components/pricing/pricing-utils';
import { LazyBarChart } from 'components/chart/LazyBarChart';
import { renderMeasurementUnitShort } from 'lib/MeasurementUnit';

import { useGrossMarginUsageReportContext } from './useGrossMarginUsageReportContext';

const GrossMarginByUsageGraph = () => {
    const { t, i18n } = useTranslation();
    const { reportData, farm, measurementUnit } = useGrossMarginUsageReportContext();

    if (reportData.length === 0) {
        return null;
    }

    return (
        <div>
            <LazyBarChart
                direction="horizontal"
                style={{ height: reportData.length * 200 }}
                data={{
                    labels: reportData.map((datum) => datum.name),
                    datasets: [
                        {
                            label: t('output'),
                            backgroundColor: '#4da4da',
                            data: reportData.map((datum) =>
                                datum.totalOutput ? datum.totalOutput.cost : 0
                            ),
                        },
                        {
                            label: t('inputs_title'),
                            backgroundColor: '#2c3e50',
                            data: reportData.map((datum) =>
                                datum.totalInput ? datum.totalInput.cost : 0
                            ),
                        },
                        {
                            label: t('gross_margin_title'),
                            backgroundColor: '#ebbc00',
                            data: reportData.map((datum) => (datum.margin ? datum.margin.cost : 0)),
                        },
                    ],
                }}
                options={{
                    locale: i18n.language,
                    plugins: {
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            formatter: (value) =>
                                new Intl.NumberFormat(window.navigator.language, {
                                    maximumFractionDigits: 2,
                                }).format(value),
                        },
                    },
                    maintainAspectRatio: false,
                    datasets: {
                        bar: {
                            barPercentage: 0.7,
                            categoryPercentage: 0.8,
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: `${getCurrencySymbol(
                                    farm.costingsCurrency || 'GBP'
                                )}/${renderMeasurementUnitShort(measurementUnit)}`,
                                font: {
                                    size: 16,
                                },
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

export { GrossMarginByUsageGraph };
