import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { Location } from 'react-router-dom';
import type { Set } from 'immutable';
import { isUserHintSet } from 'lib/firebase/firebase-hints-api';
import { defaultToZero } from 'lib/fp-helpers';
import { selectUserId } from 'users/user-state';

import { calculateYieldRate } from '../details/yields/yield-utils';

import type { OperationNewFormValues } from './OperationNew';

export const useShowYieldTotalChangeWarning = (): [
    boolean,
    VoidFunction,
    VoidFunction,
    boolean,
    VoidFunction,
    VoidFunction,
] => {
    const userId = useSelector(selectUserId);
    const [seen, setSeen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [canShowTooltip, setCanShowTooltip] = useState(false);

    useEffect(() => {
        isUserHintSet(userId, 'yield-total-change-warning').then((result) => {
            setSeen(result);
            if (result) {
                setCanShowTooltip(true);
            }
        });
    }, [userId]);

    const showModalFn = () => !seen && setShowModal(true);
    const hideModalFn = () => {
        setSeen(true);
        setShowModal(false);
    };

    const showTooltipFn = () => {
        if (canShowTooltip && !showModal) setShowTooltip(true);
    };
    const hideTooltipFn = () => {
        setShowTooltip(false);
    };

    return [showModal, showModalFn, hideModalFn, showTooltip, showTooltipFn, hideTooltipFn];
};

export const isHarvestOperation = (type: string = '') => ['HARVEST', 'HAY_SILAGE'].includes(type);

export enum OperationNewStep {
    TYPE = 'type',
    OUTPUT = 'output',
    FIELDS = 'fields',
    REST = 'rest',
}
export const getNextStep = (
    step: OperationNewStep,
    editingFieldUuids: Set<string>,
    operationType?: string
) => {
    if (isHarvestOperation(operationType) && step === OperationNewStep.TYPE) {
        return OperationNewStep.OUTPUT;
    } else if (
        [OperationNewStep.TYPE, OperationNewStep.OUTPUT].includes(step) &&
        editingFieldUuids.size === 0
    ) {
        return OperationNewStep.FIELDS;
    } else {
        return OperationNewStep.REST;
    }
};

export const getStartingStep = (location: Location<{ operationType?: string }>) => {
    if (location.state?.operationType === undefined) {
        return OperationNewStep.TYPE;
    }

    if (location.state?.operationType === 'HARVEST') {
        return OperationNewStep.OUTPUT;
    }

    return OperationNewStep.FIELDS;
};

/**
 * Use the yield form values to calculate the total yield and total yield rate.
 * Total yield rate is not a sum of the yield rate, but the total yield divided by the total work area.
 */
export const calculateYieldTotals = (formValues: OperationNewFormValues) => {
    const fieldUuids = Object.keys(formValues.fields);

    const totalYield = fieldUuids.reduce((total, fieldUuid) => {
        const fieldValues = formValues.fields[fieldUuid];
        const opFieldYield =
            fieldValues.yieldTotal !== undefined ? defaultToZero(fieldValues.yieldTotal) : 0;
        return total + opFieldYield;
    }, 0);

    const totalArea = fieldUuids.reduce((total, fieldUuid) => {
        const fieldValues = formValues.fields[fieldUuid];
        const area = defaultToZero(fieldValues.workArea);
        return total + area;
    }, 0);

    return {
        totalYield,
        totalYieldRate: calculateYieldRate(totalArea, totalYield),
    };
};
