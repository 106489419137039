import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import Button from '@fieldmargin/webapp-styling/components/button';
import featureFlags from 'lib/feature-flags';

import './FeatureFlagsPage.css';

const FeatureFlagsPage = () => {
    // This is hacky because updating the feature flag values doesn't update the component because
    // they're not in state, so we'll provide a fake state to force an update.
    const [, updateState] = useState<{}>();
    const forceUpdate = useCallback(() => updateState({}), []);

    const baseFlags = featureFlags.getBaseFlags();
    const userFlags = featureFlags.getUserFlags();
    const descriptions = featureFlags.getDescriptions();

    const updateUserFlag = (flagName: string, event: ChangeEvent<HTMLSelectElement>) => {
        const value = {
            default: undefined,
            true: true,
            false: false,
        }[event.target.value];
        featureFlags.setUserFlag(flagName, value);
        forceUpdate();
    };

    const clearAll = () => {
        featureFlags.clearUserFlags();
        forceUpdate();
    };

    const rows = Object.entries(baseFlags)
        .filter(([f]) => f !== 'printingOveride')
        .map(([flagName]) => {
            const value = userFlags[flagName] === undefined ? 'default' : '' + userFlags[flagName];
            return (
                <tr key={flagName}>
                    <td>
                        {flagName}
                        <br />
                        <small>{descriptions[flagName]}</small>
                    </td>
                    <td>{baseFlags[flagName] ? '✔' : '✘'}</td>
                    <td>
                        <select value={value} onChange={updateUserFlag.bind(null, flagName)}>
                            <option value="default">Default</option>
                            <option value="true">✔</option>
                            <option value="false">✘</option>
                        </select>
                    </td>
                    <td>{featureFlags.get(flagName) ? '✔' : '✘'}</td>
                </tr>
            );
        });

    return (
        <div className="FeatureFlagsPage">
            <Button onClick={clearAll}>Clear all</Button>
            <h2>Feature flags </h2>
            <table>
                <thead>
                    <tr>
                        <th>Option</th>
                        <th>Environment</th>
                        <th>User</th>
                        <th>Result</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        </div>
    );
};

export default FeatureFlagsPage;
