import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { FieldInputReportSummary, InputReportSummary } from 'inputs/InputReportSummary';
import configService from 'lib/config';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type { OutputReportSummaryDTO } from 'outputs/OutputReportSummary';
import { deserializeOutputReportSummaries } from 'outputs/OutputReportSummary';
import { map } from 'ramda';

import type { GrossMargin } from './gross-margin/GrossMargin';
import type { InputReportDTO } from './input/InputReport';
import { deserializeInputReports } from './input/InputReport';
import type { InputCostsDTO } from './input-costs/InputCost';
import { deserializeInputCosts } from './input-costs/InputCost';
import type { NutrientReportDTO } from './nutrients/NutrientReport';
import { deserializeNutrientReport } from './nutrients/NutrientReport';

/**
 * Get a list of input usage data for the whole farm for a year.
 */
export const getYearInputSummaryApi = (farmUuid: string, year: number, filter: string | null) => {
    const params: { status?: string } = {};
    if (filter) {
        params.status = filter;
    }
    return authorizedRequest<InputReportSummary[]>({
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/inputsSummary`,
        params,
    });
};

export const getFieldsYearInputSummaryApi = (
    farmUuid: string,
    fieldUuids: string[],
    year: number,
    filter: string | null,
    measurementSystem: MeasurementUnit
) => {
    const url = `/notes-api/farms/${farmUuid}/years/${year}/fields/inputsSummary`;
    const fieldParams = fieldUuids.map((fieldUuid) => `&fields=${fieldUuid}`).join('');
    let params: string = `measurementSystem=${measurementSystem.toUpperCase()}${fieldParams}`;

    if (filter) {
        params = `${params}&status=${filter}`;
    }

    return authorizedRequest<FieldInputReportSummary[]>({
        url: `${url}?${params}`,
    });
};

export const getFieldsYearInputSummaryDownloadLink = (
    farmUuid: string,
    fieldUuids: string[],
    year: number,
    filter: string | null,
    measurementSystem: MeasurementUnit
) => {
    const url = `/notes-api/farms/${farmUuid}/years/${year}/fields/inputsSummary/download`;
    const fieldParams = fieldUuids.map((fieldUuid) => `&fields=${fieldUuid}`).join('');
    let params: string = `measurementSystem=${measurementSystem.toUpperCase()}${fieldParams}`;

    if (filter) {
        params = `${params}&status=${filter}`;
    }
    return `${configService.get('apiRoot')}${url}?${params}`;
};

export const getYearInputReport = (
    farmUuid: string,
    year: number,
    measurementSystem: MeasurementUnit,
    inputUuids: string[],
    filter?: string
) => {
    let url = `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/inputReport?measurementSystem=${measurementSystem.toUpperCase()}`;
    if (filter !== undefined) {
        url += `&status=${filter.toUpperCase()}`;
    }

    if (inputUuids.length > 0) {
        url += inputUuids.map((uuid) => `&operationInput=${uuid}`).join('');
    }

    return authorizedRequest<InputReportDTO[]>({ url }).then(deserializeInputReports);
};

export const getGetYearInputCostsReport = (
    farmUuid: string,
    year: number,
    measurementSystem: MeasurementUnit,
    filter?: string
) => {
    const params: { status?: string; measurementSystem: string } = {
        measurementSystem: measurementSystem.toUpperCase(),
    };
    if (filter !== undefined) {
        params.status = filter.toUpperCase();
    }

    return authorizedRequest<InputCostsDTO>({
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/inputCosts`,
        params,
    }).then(deserializeInputCosts);
};

export const getYearOuputSummary = (
    farmUuid: string,
    year: number,
    measurementSystem: MeasurementUnit,
    filter?: string
) => {
    const params: { status?: string; measurementSystem: string } = {
        measurementSystem: measurementSystem.toUpperCase(),
    };
    if (filter) {
        params.status = filter;
    }

    return authorizedRequest<OutputReportSummaryDTO[]>({
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/outputsSummary`,
        params,
    }).then(deserializeOutputReportSummaries);
};

export const getCsvDownloadLink = (
    farmUuid: string,
    year: number,
    filter: string | null | undefined,
    type:
        | 'inputsSummary'
        | 'outputsSummary'
        | 'inputReport'
        | 'grossMarginByField'
        | 'grossMarginByFieldUsage',
    measurementUnit: MeasurementUnit
) => {
    let link = `${configService.get(
        'apiRoot'
    )}/notes-api/v2/farms/${farmUuid}/years/${year}/operations/${type}/download?measurementSystem=${measurementUnit.toUpperCase()}`;
    if (filter && filter !== 'all') {
        link += `&status=${filter.toUpperCase()}`;
    }
    return link;
};

export const getNutrientReportDownloadLink = (
    farmUuid: string,
    year: number,
    statusFilter: string,
    fields: string[],
    nutrients: string[],
    measurementUnit: MeasurementUnit
) => {
    let link = `${configService.get(
        'apiRoot'
    )}/notes-api/v2/farms/${farmUuid}/years/${year}/operations/nutrientReport/download?measurementSystem=${measurementUnit.toUpperCase()}`;
    if (statusFilter !== 'all') {
        link += `&status=${statusFilter.toUpperCase()}`;
    }
    if (fields.length > 0) {
        link += fields.map((field) => `&field=${field}`).join('');
    }
    if (nutrients.length > 0) {
        link += nutrients.map((nutrient) => `&nutrient=${nutrient}`).join('');
    }
    return link;
};

export const getInputReportCsvDownloadLink = (
    farmUuid: string,
    year: number,
    measurementUnit: MeasurementUnit,
    inputUuids: string[],
    filter?: string
) => {
    let url = getCsvDownloadLink(farmUuid, year, filter, 'inputReport', measurementUnit);
    if (inputUuids.length > 0) {
        url += inputUuids.map((uuid) => `&operationInput=${uuid}`).join('');
    }
    return url;
};

export const getYearGrossMarginByField = (
    farmUuid: string,
    year: number,
    measurementUnit: MeasurementUnit,
    status: string,
    fields: string[]
) => {
    let url = `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/grossMarginByField?measurementSystem=${measurementUnit.toUpperCase()}`;
    if (status !== 'all') {
        url += `&status=${status.toUpperCase()}`;
    }

    if (fields.length > 0) {
        url += fields.map((uuid) => `&field=${uuid}`).join('');
    }

    return authorizedRequest<GrossMargin[]>({
        url,
    });
};

export const getYearGrossMarginByUsage = (
    farmUuid: string,
    year: number,
    measurementUnit: MeasurementUnit,
    status: string,
    usages: string[]
) => {
    let url = `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/grossMarginByFieldUsage?measurementSystem=${measurementUnit.toUpperCase()}`;
    if (status !== 'all') {
        url += `&status=${status.toUpperCase()}`;
    }

    if (usages.length > 0) {
        url += usages.map((uuid) => `&fieldUsage=${uuid}`).join('');
    }

    return authorizedRequest<GrossMargin[]>({
        url,
    }).then((grossMargins) =>
        grossMargins.filter((data) => {
            if (data.totalInput !== null && data.totalInput.cost > 0) {
                return true;
            }
            if (data.totalOutput !== null && data.totalOutput.cost > 0) {
                return true;
            }
            if (data.margin !== null && data.margin.cost > 0) {
                return true;
            }
            return false;
        })
    );
};

export const getYearNutrientReport = (
    farmUuid: string,
    year: number,
    measurementUnit: MeasurementUnit,
    status: string,
    nutrients: string[],
    fields: string[]
) => {
    let url = `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/nutrientReport?measurementSystem=${measurementUnit.toUpperCase()}`;
    if (status !== 'all') {
        url += `&status=${status.toUpperCase()}`;
    }
    if (nutrients.length > 0) {
        url += nutrients.map((nutrient) => `&nutrient=${nutrient}`).join('');
    }
    if (fields.length > 0) {
        url += fields.map((fieldUuid) => `&field=${fieldUuid}`).join('');
    }

    return authorizedRequest<NutrientReportDTO[]>({ url }).then(map(deserializeNutrientReport));
};
