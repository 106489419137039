import type { PropsWithChildren } from 'react';
import c from 'classnames';

interface CardCheckboxListItemProps
    extends PropsWithChildren<{
        value: string;
        selected: boolean;
        onChange: VoidFunction;
    }> {}

const CardCheckboxListItem = ({
    value,
    selected,
    onChange,
    children,
}: CardCheckboxListItemProps) => {
    return (
        <li className="rounded border-fm-sky border-2 border-solid w-36 h-24 p-0">
            <input
                type="checkbox"
                id={value}
                name={value}
                value={value}
                onChange={onChange}
                className="absolute"
                style={{ left: -9999 }}
            />
            <label
                htmlFor={value}
                className={c(
                    'flex items-center justify-center gap-1 flex-col text-sm font-semibold h-full cursor-pointer',
                    selected ? 'bg-fm-sky text-white' : 'bg-white text-fm-blue'
                )}
            >
                {children}
            </label>
        </li>
    );
};

export default CardCheckboxListItem;
