import { connect } from 'react-redux';
import { NoResults } from 'components/no-results';
import type Field from 'fields/Field';
import { useTranslations } from 'hooks/useTranslations';
import type { List } from 'immutable';
import type { AppState } from 'system/store';

interface FieldListFilterMessageProps {
    // From parents
    filteredFields: List<Field>;

    // From redux
    fieldSearchString?: string;
}

const FieldListFilterMessage = ({
    filteredFields,
    fieldSearchString,
}: FieldListFilterMessageProps) => {
    const { t, plural } = useTranslations();

    if (filteredFields.size === 0) {
        return <NoResults>{t('field_selector_empty')}</NoResults>;
    }

    if (fieldSearchString) {
        const message = plural('field_selector_count', {
            count: filteredFields.size,
            sprintf: [filteredFields.size, fieldSearchString],
        });
        return <NoResults>{message}</NoResults>;
    }
    return null;
};

export default connect((state: AppState) => ({
    fieldSearchString: state.fieldsState.fieldSearchString,
}))(FieldListFilterMessage);
