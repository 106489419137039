import { initReactI18next } from 'react-i18next';
import { envConfig } from 'config/env-config';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import sprintf from 'i18next-sprintf-postprocessor';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(sprintf)
    .init({
        backend: {
            loadPath: '/translations/src/{{lng}}/{{ns}}.json',
        },
        load: 'languageOnly',
        debug: envConfig.isDevelopment,
        ns: ['notes', 'notes-plurals', 'agreena'],
        defaultNS: 'notes',
        fallbackLng: 'en',
        supportedLngs: ['en', 'ro', 'uk'],
        interpolation: {
            escapeValue: false,
        },
        postProcess: 'sprintf',
        detection: {
            caches: [],
        },
    });

export default i18n;
