import MapLayerIndex from 'components/maps/model/MapLayerIndex';
import type { FarmMapFeature } from 'farm-map/types';
import { useAppSelector } from 'system/store';

import { useItemAttachmentUuids } from './useItemAttachmentUuids';

export const useFarmMapAttachments = (features: FarmMapFeature[]) => {
    const isEditing = useAppSelector(
        (state) => state.farmEditingState.editingData.id !== undefined
    );
    const ignoreUuids = useItemAttachmentUuids();

    return isEditing
        ? []
        : features
              .filter((feature) => ignoreUuids.includes(feature.properties.uuid))
              .map(styleProps);
};

const styleProps = (feature: FarmMapFeature): FarmMapFeature => ({
    ...feature,
    properties: {
        ...feature.properties,
        colour: '#ffffff',
        pointScale: 6,
        strokeWeight: 2,
        strokeOpacity: 1,
        fillOpacity: 0.4,
        zIndex: MapLayerIndex.NOTE_FIELD_POLYGON,
        // @ts-ignore
        label: feature.properties.name,
    },
});
