import type { GeoFeature } from '@fieldmargin/webapp-geo';
import type { Controller } from '@fieldmargin/webapp-ol-map';
import { syncCollection } from '@fieldmargin/webapp-ol-map';
import { HerdType } from 'herd/Herd';
import Collection from 'ol/Collection';
import type Feature from 'ol/Feature';
import type { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import type OLMap from 'ol/Map';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

import cattle from './markers/cattle.png';
import cattleBlue from './markers/cattle-blue.png';
import chicken from './markers/chickens.png';
import chickenBlue from './markers/chickens-blue.png';
import goats from './markers/goats.png';
import goatsBlue from './markers/goats-blue.png';
import horses from './markers/horses.png';
import horsesBlue from './markers/horses-blue.png';
import iconShadow from './markers/map-note-marker-white-empty.png';
import other from './markers/other.png';
import otherBlue from './markers/other-blue.png';
import pigs from './markers/pigs.png';
import pigsBlue from './markers/pigs-blue.png';
import iconNoShadow from './markers/pin-no-shadow.png';
import sheep from './markers/sheep.png';
import sheepBlue from './markers/sheep-blue.png';

const herdTypeIcons = {
    [HerdType.CATTLE]: cattle,
    [HerdType.SHEEP]: sheep,
    [HerdType.PIGS]: pigs,
    [HerdType.GOATS]: goats,
    [HerdType.HORSES]: horses,
    [HerdType.POULTRY]: chicken,
    [HerdType.OTHER]: other,
};

const herdTypeIconsBlue = {
    [HerdType.CATTLE]: cattleBlue,
    [HerdType.SHEEP]: sheepBlue,
    [HerdType.PIGS]: pigsBlue,
    [HerdType.GOATS]: goatsBlue,
    [HerdType.HORSES]: horsesBlue,
    [HerdType.POULTRY]: chickenBlue,
    [HerdType.OTHER]: otherBlue,
};

class HerdMarkersController implements Controller<GeoFeature[]> {
    private map: OLMap;
    private dataSource: Collection<any>;
    private clusterSource: Cluster;
    private dataLayer: VectorLayer<VectorSource<Feature<Geometry>>>;

    constructor(zIndex: number) {
        this.dataSource = new Collection();
        this.clusterSource = new Cluster({
            distance: 25, // pixels
            source: new VectorSource({
                features: this.dataSource,
            }),
        });
        this.dataLayer = new VectorLayer({
            source: this.clusterSource,
            zIndex,
            style: this.styleFeature,
        });
    }

    public build(map: OLMap) {
        this.map = map;
        this.map.addLayer(this.dataLayer);
    }

    public update(geoFeatures: GeoFeature[]) {
        syncCollection(geoFeatures, this.dataSource);
    }

    protected styleFeature(feature: Feature) {
        if (!feature.get('features')) {
            return [];
        }
        const features = feature.get('features');

        if (features.length > 1) {
            return styleCluster(features);
        }

        return styleHerdMarker(features[0]);
    }
}

const styleCluster = (features: any[]) => {
    const opacity =
        features.map((f) => f.getProperties().pointOpacity).filter((o) => o === 1).length > 0
            ? 1
            : 0.5;
    return [
        new Style({
            image: new Circle({
                radius: 7,
                fill: new Fill({ color: `rgba(255, 255, 255, ${opacity}` }),
            }),
            zIndex: 1,
        }),
        new Style({
            image: new Icon({
                src: iconShadow,
                scale: 0.8,
                anchor: [0.5, 1],
                crossOrigin: 'anonymous',
                opacity,
            }),
            zIndex: 2,
            text: new Text({
                text: `${features.length} herds`,
                font: 'bold 12px sans-serif',
                fill: new Fill({
                    color: `rgba(0, 0, 0, ${opacity})`,
                }),
                offsetY: -52,
            }),
        }),
    ];
};

const styleHerdMarker = (feature) => {
    const props = feature.getProperties();
    const isWhite = props.colour.toLowerCase() === '#ffffff';

    return [
        new Style({
            image: new Circle({
                radius: 7,
                fill: new Fill({ color: `rgba(255, 255, 255, ${props.pointOpacity}` }),
            }),
            zIndex: 1,
        }),
        new Style({
            image: new Icon({
                src: iconShadow,
                scale: 0.6,
                anchor: [0.51, 0.91],
                crossOrigin: 'anonymous',
                opacity: props.pointOpacity,
            }),
            zIndex: 2,
        }),
        new Style({
            image: new Icon({
                src: iconNoShadow,
                scale: 0.53,
                anchor: [0.51, 0.99],
                color: props.colour,
                crossOrigin: 'anonymous',
                opacity: props.pointOpacity,
            }),
            zIndex: 3,
        }),
        new Style({
            image: new Icon({
                src: isWhite
                    ? herdTypeIconsBlue[HerdType[props.label]]
                    : herdTypeIcons[HerdType[props.label]],
                scale: 0.45,
                anchor: [0.51, 1.75],
                crossOrigin: 'anonymous',
                opacity: props.pointOpacity,
            }),
            zIndex: 4,
        }),
    ];
};

export default HerdMarkersController;
