import DancingWheat from '@fieldmargin/webapp-styling/components/dancing-wheat';

const PageLoading = () => (
    <div className="page-loading">
        <div className="top-nav">
            <img
                className="fm-logo"
                src="/images/fm-logo-small.png"
                style={{ width: 'auto', height: 33 }}
            />
        </div>

        <div className="body">
            <DancingWheat />
        </div>
    </div>
);

export default PageLoading;
