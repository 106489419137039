import type { Extent, Geometry, GeoPoint, GeoPolygon, GeoPosition } from '@fieldmargin/webapp-geo';
import {
    deserialize,
    deserializeLngLat,
    extentIntersectsGeo,
    GeoFeature,
    GeoFeatureCollection,
    Projection,
    serialize,
} from '@fieldmargin/webapp-geo';
import type Field from 'fields/Field';
import type {
    Feature,
    FeatureCollection,
    Geometry as GeoJsonGeometry,
    MultiPolygon,
    Point,
    Polygon,
} from 'geojson';
import { List, Map } from 'immutable';
import GeoJSON from 'ol/format/GeoJSON';
import type OLPoint from 'ol/geom/Point';
import type OLPolygon from 'ol/geom/Polygon';
import type { GeoJsonItem } from 'system/types';
import { v4 } from 'uuid';

/**
 * Returns an interior point for the given Polygon. This point will be inside the polygon and not
 * the centroid. This is useful when positioning items inside the polygon as it accounts for
 * fields of weird shape.
 *
 * E.g. a field that looks like this will return a point like X rather than C
 *
 * ------------------
 * |                |
 * |     ------------
 * |  X  | C
 * |     ------------
 * |                |
 * ------------------
 */
export const getInteriorPoint = (polygon: GeoPolygon) => {
    // Working with OpenLayers is easier if we are using GeoJSON rather than our own objects.
    const geoJsonPolygon = serialize(polygon);

    const geojson = new GeoJSON();
    const olPolygon = geojson.readGeometry(geoJsonPolygon) as OLPolygon;
    const olPoint = olPolygon.getInteriorPoint() as OLPoint;

    // Now we can convert the point back to our own GeoPoint object.
    return deserialize(geojson.writeGeometryObject(olPoint), Projection.LNG_LAT) as GeoPoint;
};

/**
 * Same as getInteriorPoint but for geojson objects.
 */
export const getGeoJsonInteriorPoint = (polygon: Polygon) => {
    const geojson = new GeoJSON();
    const olPolygon = geojson.readGeometry(polygon) as OLPolygon;
    const olPoint = olPolygon.getInteriorPoint();
    return geojson.writeGeometryObject(olPoint) as Point;
};

export const removeHolesFromPolygon = (polygon: GeoPolygon) => {
    const coords = polygon.coordinates.get(0);

    // If there are no coordinates, or only 1 set then there are no holes to remove.
    if (coords === undefined || coords.size === 1) {
        return polygon;
    }

    return polygon.update('coordinates', (coordinates) =>
        List.of(coordinates.get(0) as List<GeoPosition>)
    );
};

export const reducePolygonPrecision = (polygon: GeoPolygon, precision: number) =>
    polygon.update('coordinates', (coordinates) =>
        coordinates.map((ring) =>
            ring.map((point) =>
                point
                    .set('x', parseFloat(point.x.toFixed(precision)))
                    .set('y', parseFloat(point.y.toFixed(precision)))
            )
        )
    );

export const multiPolygonFeatureToPolygonFeature = (
    feature: Feature<MultiPolygon>
): Feature<Polygon> => ({
    ...feature,
    geometry: multiPolygonToPolygon(feature.geometry),
});

export const multiPolygonToPolygon = (multiPolygon: MultiPolygon): Polygon => ({
    type: 'Polygon',
    coordinates: multiPolygon.coordinates[0],
});

export const extentIntersectsFieldBoundary = (extent: Extent, field: Field) =>
    field.geoJson !== null && extentIntersectsGeo(extent, field.geoJson);

export const getFirstFeatureGeometryFromCollection = <T extends Geometry>(
    collection: GeoFeatureCollection
) =>
    collection.features.size > 0 ? (collection.features.first<GeoFeature>().geometry as T) : null;

/**
 * Returns a new FeatureCollection with each feature having a unique ID.
 */
export const createFeatureCollectionWithIds = (featureCollection: FeatureCollection) => ({
    ...featureCollection,
    features: featureCollection.features.map(createFeatureWithId),
});

/**
 * Creates a new GeoJSON feature with a unique ID.
 * The id is a UUID v4.
 */
export const createFeatureWithId = <T extends GeoJsonGeometry>(
    feature: Feature<T>
): Feature<T> => ({
    ...feature,
    id: v4(),
});

export const blankFeatureCollection = (): FeatureCollection => ({
    type: 'FeatureCollection',
    features: [],
});

export const geoJsonFeatureToGeoFeature = (feature: Feature): GeoFeature =>
    GeoFeature({
        id: feature.id,
        geometry: deserializeLngLat(feature.geometry) as Geometry,
        properties: feature.properties !== null ? Map(feature.properties) : undefined,
    });

export const itemToGeoJsonItem = <T extends { uuid: string; geoJson: GeoFeatureCollection | null }>(
    item: T
): GeoJsonItem => ({
    uuid: item.uuid,
    geoJson: item.geoJson ?? GeoFeatureCollection(),
});
