import Button from '@fieldmargin/webapp-styling/components/button';
import Modal from 'modal/Modal';
import { useAppDispatch } from 'system/store';
import { changeModal } from 'system/ui-state';
import PadlockIcon from 'view/svg/PadlockIcon';

const FarmUserBlockedModal = () => {
    const dispatch = useAppDispatch();
    const handleSupportClick = () => {
        if (window.Intercom) {
            window.Intercom('showNewMessage');
        } else {
            dispatch(
                changeModal({
                    name: 'feedback',
                    props: {},
                })
            );
        }
    };
    return (
        <Modal allowClose={false}>
            <div className="FarmUpgradePromptModal">
                <PadlockIcon fill="#ce1d18" className="w-12" />
                <h2 className="text-xxl">Upgrade needed</h2>
                <p>
                    This farm has <strong>exceeded the maximum team size</strong>.
                </p>
                <p>
                    Please <strong>ask the farm owner to upgrade</strong> your plan.
                </p>
                <p>
                    <Button variant="outline" onClick={handleSupportClick}>
                        Contact support
                    </Button>
                </p>
            </div>
        </Modal>
    );
};

export default FarmUserBlockedModal;
