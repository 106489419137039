import type { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import type Field from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import type { List } from 'immutable';
import type { SingleParamVoidFunction } from 'system/types';
import Steps, { Step } from 'view/Steps';

import SubFieldsUsePreviousMap from './SubFieldsUsePreviousMap';

import styles from './SubFieldsUsePreviousYearChoice.module.css';

interface SubFieldsUsePreviousYearChoiceProps {
    parentField: Field;
    subFields?: List<Field>;
    years: List<string>;
    selectedYear?: number;
    onYearChange: SingleParamVoidFunction<ChangeEvent<HTMLSelectElement>>;
    fieldUsages: List<FieldUsage>;
    onClose: VoidFunction;
    onUse: VoidFunction;
}

const SubFieldsUsePreviousYearChoice = ({
    parentField,
    subFields,
    years,
    selectedYear,
    onYearChange,
    fieldUsages,
    onClose,
    onUse,
}: SubFieldsUsePreviousYearChoiceProps) => {
    const { t } = useTranslation();
    return (
        <>
            <h2>{t('sub_fields_use_previous_title')}</h2>
            <Steps>
                <Step selected>1</Step>
                <Step>2</Step>
            </Steps>
            <label htmlFor="previous-year">
                <strong className="inline-block mr-2">
                    {t('sub_fields_use_previous_choose_year')}:
                </strong>
                <select
                    id="previous-year"
                    onChange={onYearChange}
                    value={selectedYear?.toString()}
                    className={styles.capitalise}
                >
                    <option value="">{t('year')}</option>
                    {years.map((year, key) => (
                        <option key={key} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </label>
            {subFields && selectedYear ? (
                <>
                    <div style={{ width: 500, height: 500 }}>
                        <SubFieldsUsePreviousMap
                            parentField={parentField}
                            fields={subFields}
                            year={selectedYear}
                            fieldUsages={fieldUsages}
                        />
                    </div>
                    <div className="flex items-center justify-center mt-auto">
                        <Button onClick={onClose} variant="danger-outline" className="mr-4">
                            {t('cancel')}
                        </Button>
                        <Button onClick={onUse}>{t('sub_fields_use_previous_confirm')}</Button>
                    </div>
                </>
            ) : (
                <p className="text-xl text-gray-400 mt-40">
                    <em>{t('sub_fields_use_previous_map_preview')}</em>
                </p>
            )}
        </>
    );
};

export default SubFieldsUsePreviousYearChoice;
