import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import { useMarkHintSeenOnMount } from 'lib/firebase/firebase-hints-api';
import Modal from 'modal/Modal';

import SubFieldsHelpCut from './SubFieldsHelpCut';
import SubFieldsHelpIntro from './SubFieldsHelpIntro';
import SubFieldsHelpMargin from './SubFieldsHelpMargin';
import SubFieldsHelpMerge from './SubFieldsHelpMerge';
import SubFieldsHelpOther from './SubFieldsHelpOther';
import SubFieldsHelpSlice from './SubFieldsHelpSlice';

interface SubFieldsHelpProps {
    onClose: VoidFunction;
}

enum SubFieldsHelpStep {
    INTRO,
    SLICE,
    CUT,
    MARGIN,
    MERGE,
    REST,
}

const stepOrder = [
    SubFieldsHelpStep.INTRO,
    SubFieldsHelpStep.SLICE,
    SubFieldsHelpStep.CUT,
    SubFieldsHelpStep.MARGIN,
    SubFieldsHelpStep.MERGE,
    SubFieldsHelpStep.REST,
];

const SubFieldsHelp = ({ onClose }: SubFieldsHelpProps) => {
    const { t } = useTranslation();
    useMarkHintSeenOnMount('subFieldHelp');
    const [step, setStep] = useState(SubFieldsHelpStep.INTRO);

    const handleBack = () => {
        setStep(stepOrder[stepOrder.indexOf(step) - 1]);
    };
    const handleNext = () => {
        setStep(stepOrder[stepOrder.indexOf(step) + 1]);
    };

    return (
        <Modal onClose={onClose}>
            <div className="bg-white p-4 relative flex flex-col">
                {step === SubFieldsHelpStep.INTRO && <SubFieldsHelpIntro />}
                {step === SubFieldsHelpStep.SLICE && <SubFieldsHelpSlice />}
                {step === SubFieldsHelpStep.CUT && <SubFieldsHelpCut />}
                {step === SubFieldsHelpStep.MARGIN && <SubFieldsHelpMargin />}
                {step === SubFieldsHelpStep.MERGE && <SubFieldsHelpMerge />}
                {step === SubFieldsHelpStep.REST && <SubFieldsHelpOther />}
                <div className="flex justify-center mt-auto pt-8">
                    {stepOrder.indexOf(step) > 0 && (
                        <Button variant="outline" className="mr-4" onClick={handleBack}>
                            {t('back')}
                        </Button>
                    )}
                    {stepOrder.indexOf(step) + 1 < stepOrder.length && (
                        <Button onClick={handleNext}>{t('next')}</Button>
                    )}
                </div>
                <p className="absolute right-5 bottom-5">
                    {stepOrder.indexOf(step) + 1}/{stepOrder.length}
                </p>
            </div>
        </Modal>
    );
};

export default SubFieldsHelp;
