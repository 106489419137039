import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import type FieldUsage from 'fields/FieldUsage';
import { asBadge } from 'fields/FieldUsage';
import type { List, Map, Set } from 'immutable';
import { OutputUnit } from 'outputs/Output';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import type { SingleParamVoidFunction } from 'system/types';
import NativeSelectFormField from 'view/form/native/NativeSelectFormField';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';

interface BulkOutputCreationUsageSelectionProps {
    fieldUsages: List<FieldUsage>;
    selectedUsages: Set<string>;
    selectedUsageUnits: Map<string, OutputUnit>;
    onFieldUsageCheck: SingleParamVoidFunction<string>;
    onUnitChange: (uuid: string, unit?: OutputUnit) => void;
    onBack: VoidFunction;
    onNext: VoidFunction;
}

const BulkOutputCreationUsageSelection = ({
    fieldUsages,
    selectedUsages,
    selectedUsageUnits,
    onFieldUsageCheck,
    onUnitChange,
    onBack,
    onNext,
}: BulkOutputCreationUsageSelectionProps) => {
    const { t } = useTranslation();
    return (
        <div className="scrollable">
            <SidebarHeader className="px-5 mb-4">
                <Button onClick={onBack} variant="danger-outline" small>
                    {t('cancel')}
                </Button>
                <Button
                    onClick={onNext}
                    small
                    variant="outline"
                    disabled={selectedUsages.size === 0}
                >
                    {t('next')}
                </Button>
            </SidebarHeader>
            <div className="scrolling">
                <p className="px-5">{t('bulk_output_creation_stage_1')}</p>
                <table className="table-fixed border-collapse w-full">
                    <thead>
                        <tr className="bordered-b">
                            <th className="px-5 py-1 w-[400px] text-left">{t('usage')}</th>
                            <th className="px-5 py-1 text-left">{t('unit')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fieldUsages.map((fieldUsage) => (
                            <tr key={fieldUsage.uuid} className="bordered-b">
                                <td className="px-5 py-2 relative">
                                    <Checkbox
                                        id={fieldUsage.uuid}
                                        checked={selectedUsages.has(fieldUsage.uuid)}
                                        onChange={() => onFieldUsageCheck(fieldUsage.uuid)}
                                        label={
                                            <BadgeItem
                                                item={asBadge(fieldUsage)}
                                                className="ml-3 font-bold"
                                            />
                                        }
                                    />
                                </td>
                                <td className="px-5 py-2">
                                    <NativeSelectFormField
                                        value={selectedUsageUnits.get(fieldUsage.uuid)}
                                        onChange={(e) =>
                                            onUnitChange(
                                                fieldUsage.uuid,
                                                OutputUnit[e.target.value]
                                            )
                                        }
                                        className="w-full"
                                    >
                                        <option value={undefined}>{t('choose_one')}...</option>
                                        {Object.keys(OutputUnit).map((outputUnit, i) => (
                                            <option key={i} value={OutputUnit[outputUnit]}>
                                                {t(`unit_type_${outputUnit.toLowerCase()}`)}
                                            </option>
                                        ))}
                                    </NativeSelectFormField>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BulkOutputCreationUsageSelection;
