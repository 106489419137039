import { notNil } from 'lib/fp-helpers';
import type { Sizes } from 'lib/geo/shape-size-cacher';

import FormattedArea from './FormattedArea';
import FormattedLine from './FormattedLine';
import FormattedPoints from './FormattedPoints';

interface FormattedGeoDataProps {
    sizes: Sizes;
}

const FormattedGeoData = ({ sizes }: FormattedGeoDataProps) => {
    const ret = [
        sizes.points > 0 ? <FormattedPoints points={sizes.points} withUnit /> : undefined,
        sizes.length > 0 ? <FormattedLine lengthMetres={sizes.length} withUnit /> : undefined,
        sizes.area > 0 ? <FormattedArea areaSqm={sizes.area} withUnit /> : undefined,
    ].filter(notNil);
    return (
        <>
            {ret.length > 0 &&
                ret.reduce((acc, item) => (
                    <>
                        {acc} / {item}
                    </>
                ))}
        </>
    );
};

export default FormattedGeoData;
