import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { selectCurrentFarm } from 'farms/farms-state';
import type Herd from 'herd/Herd';
import type { HerdType } from 'herd/Herd';
import { saveHerd } from 'herd/herd-api';
import { selectIsHerdLocked } from 'herd/herd-selectors';
import { setHerd } from 'herd/herd-state';
import withPageHerd from 'herd/withPageHerd';
import { useOutOfDatePromise } from 'lib/hooks';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import type { AppState } from 'system/store';
import type { CanBeUndefined } from 'types';
import { trackEvent } from 'utils/trackEvent';

import type { HerdFormValues } from './HerdForm';
import HerdForm from './HerdForm';

interface HerdEditBaseProps {
    farm: Farm;
    year: number;
    herd: Herd;
    setHerd: typeof setHerd;
    isLocked: boolean;
}

type HerdEditProps = CanBeUndefined<ComponentProps<typeof HerdEditBase>, 'herd'>;

const HerdEditBase = ({ farm, year, herd, setHerd }: HerdEditBaseProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise<Herd>((savedHerd) => {
        // Herd location isn't returned by the server when saving so we have to set it again
        setHerd({ ...savedHerd, herdLocation: herd.herdLocation });
        trackEvent('Herd updated', {
            farmUuid: farm.uuid,
            herdUuid: herd.uuid,
            herdType: herd.type.toString(),
        });
        navigate(`/farms/${farm.uuid}/livestock/${herd.uuid}`, {
            state: { ignoreUnsavedChanges: true },
        });
    });

    const handleSubmit = (values: HerdFormValues) => {
        setPromise(
            saveHerd(year, {
                ...herd,
                name: values.name,
                type: values.type as HerdType,
                colour: values.colour,
                size: parseInt(values.size, 10),
            })
        );
    };

    const initialValues = {
        name: herd.name,
        colour: herd.colour,
        size: herd.size.toString(),
        type: herd.type,
    };

    const header = (
        <SidebarHeader className="px-5">
            <Button
                as={Link}
                to={`/farms/${farm.uuid}/livestock/${herd.uuid}`}
                state={{ ignoreUnsavedChanges: true }}
                variant="danger-outline"
                small
            >
                {t('cancel')}
            </Button>
            <SubmitButton small disabled={pending}>
                {t(pending ? 'saving' : 'save')}
            </SubmitButton>
        </SidebarHeader>
    );

    return (
        <HerdForm
            onSubmit={handleSubmit}
            initialValues={initialValues}
            header={header}
            error={error}
            outOfDate={outOfDate}
        />
    );
};

function HerdEdit({ herd, ...props }: HerdEditProps) {
    return herd === undefined || props.isLocked ? (
        <Navigate to={`/farms/${props.farm.uuid}/livestock`} />
    ) : (
        <HerdEditBase {...props} herd={herd} />
    );
}

export default withPageHerd(
    connect(
        (state: AppState, { herd }: { herd?: Herd }) => {
            const isLocked = selectIsHerdLocked(state, herd ? herd.uuid : '');
            return {
                farm: selectCurrentFarm(state),
                year: state.yearsState.currentYear,
                herd,
                isLocked,
            };
        },
        (dispatch) => bindActionCreators({ setHerd }, dispatch)
    )(HerdEdit)
);
