import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import LoadableContent from '@fieldmargin/webapp-styling/components/loadable-content';
import type { Activity } from 'activity/activity-helper';
import FarmHistoryWarning from 'activity/FarmHistoryWarning';
import classNames from 'classnames';
import { NoResults } from 'components/no-results';
import { ShowFiltersContextProvider } from 'context/ShowFiltersContextProvider';
import type Note from 'notes/Note';
import { useLoadYearNotes } from 'notes/note-hooks';
import {
    selectAllActivities,
    selectCurrentYearLimitedActivities,
    selectSortedMaybeVisibleFilteredActivitiesGroupedByStatus,
    shouldShowFarmHistoryModal,
    shouldShowFarmHistoryWarning,
} from 'notes/notes-filter';
import { bindActionCreators } from 'redux';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import ArchivedBar from 'view/ArchivedBar';

import NoteListEmptyMessage from './NoteListEmptyMessage';
import { NoteListFilters } from './NoteListFilters';
import { NoteListSearch } from './NoteListSearch';
import NoteListTitle from './NoteListTitle';
import NotePreview from './NotePreview';

import './NoteList.css';

interface NoteListProps {
    notes: Map<string, Note> | null;
    hasAnyActivities: boolean;
    hasYearActivities: boolean;
    filteredGroupedActivities: Map<'active' | 'archived', Activity[]>;
    shouldShowFarmHistoryModal: boolean;
    changeModal: typeof changeModal;
    shouldShowFarmHistoryWarning: boolean;
}

const NoteList = ({
    notes,
    hasAnyActivities,
    hasYearActivities,
    filteredGroupedActivities,
    shouldShowFarmHistoryModal,
    changeModal,
    shouldShowFarmHistoryWarning,
}: NoteListProps) => {
    const { t } = useTranslation();

    const [pending, error] = useLoadYearNotes();

    useEffect(() => {
        shouldShowFarmHistoryModal &&
            changeModal({
                name: 'farm-history-limit',
            });
    }, []);

    const hasLoadedNotes = (notes !== null && !pending) || error;
    const showEmptyMessage = hasLoadedNotes && !hasYearActivities && !shouldShowFarmHistoryWarning;
    const shouldShowList = hasLoadedNotes && hasYearActivities;
    const filteredActiveActivities = filteredGroupedActivities.get('active');
    const filteredArchivedActivities = filteredGroupedActivities.get('archived');

    const hasFilteredActivities = !(
        filteredActiveActivities?.length || filteredArchivedActivities?.length
    );

    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <NoteListTitle />
                <ShowFiltersContextProvider>
                    <NoteListSearch />
                    <NoteListFilters />
                </ShowFiltersContextProvider>
            </div>

            <div className={classNames('scrolling', { NoteListWrapper: !showEmptyMessage })}>
                <LoadableContent
                    loading={(notes === null || pending) && !error}
                    delay={300}
                    fallback={
                        <SidebarModule editing>
                            <em className="text-gray-550">{t('loading')}</em>
                        </SidebarModule>
                    }
                >
                    {showEmptyMessage && (
                        <NoteListEmptyMessage hasAnyActivities={hasAnyActivities} />
                    )}
                    {shouldShowList && (
                        <div className="NoteList">
                            {filteredActiveActivities?.map((activity) => (
                                <NotePreview key={activity.uuid} activity={activity} />
                            ))}

                            {filteredGroupedActivities.get('archived') !== undefined && (
                                <>
                                    <ArchivedBar>{t('layers_notes_archived')}</ArchivedBar>
                                    {filteredArchivedActivities?.map((activity) => (
                                        <NotePreview key={activity.uuid} activity={activity} />
                                    ))}
                                </>
                            )}

                            {hasFilteredActivities && (
                                <NoResults>{t('activities_selector_empty')}</NoResults>
                            )}
                        </div>
                    )}
                </LoadableContent>
                {shouldShowFarmHistoryWarning && <FarmHistoryWarning />}
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        notes: state.notesState.notes,
        hasAnyActivities: selectAllActivities(state).size > 0,
        hasYearActivities: selectCurrentYearLimitedActivities(state).size > 0,
        filteredGroupedActivities: selectSortedMaybeVisibleFilteredActivitiesGroupedByStatus(state),
        shouldShowFarmHistoryModal: shouldShowFarmHistoryModal(state),
        shouldShowFarmHistoryWarning: shouldShowFarmHistoryWarning(state),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                changeModal,
            },
            dispatch
        )
)(NoteList);
