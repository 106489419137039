import { useTranslation } from 'react-i18next';

import type { PesticideCheckWithholdingPeriod } from './PesticideCheck';
import { PesticideCheckStatus } from './PesticideCheck';
import PesticideCheckResultIcon from './PesticideCheckResultIcon';

interface WithholdingPeriodResultProps {
    check: PesticideCheckWithholdingPeriod;
}

const WithholdingPeriodResult = ({ check }: WithholdingPeriodResultProps) => {
    const { t } = useTranslation();
    return (
        <tr>
            <PesticideCheckResultIcon status={check.status} />
            <td>
                {check.status === PesticideCheckStatus.UNKNOWN
                    ? t('pesticide_check_withholding_period_no_data')
                    : t('pesticide_check_withholding_period', {
                          sprintf: [check.values?.withholdingPeriod],
                      })}
            </td>
        </tr>
    );
};

export default WithholdingPeriodResult;
