import { AttachmentType, toAttachmentItem } from 'farm-editing/attachments';
import type { List } from 'immutable';
import { Record, Set } from 'immutable';
import type Input from 'inputs/Input';
import type Comment from 'lib/model/Comment';
import type Media from 'media/Media';
import type { OperationDTO, ReadOperationDTO } from 'operations/Operation';
import Operation, { deserializeOperation } from 'operations/Operation';
import { createSelector } from 'reselect';

import type {
    OperationField,
    ReadOperationFieldDTO,
    WriteOperationFieldDTO,
} from './OperationField';
import type { RecordingDTO } from './Recording';
import type Recording from './Recording';
import { deserializeRecording } from './Recording';

const FullOperation = Record({
    uuid: '',
    summary: Operation(),
    media: null as List<Media> | null,
    comments: null as List<Comment> | null,
    fields: null as Set<OperationField> | null,
    recordings: null as Set<Recording> | null,
});
interface FullOperation extends ReturnType<typeof FullOperation> {}

export interface OperationChangeDTO {
    operation: OperationDTO;
    tags?: { userIds: number[] };
    tagsDeleted?: number[];

    fields?: WriteOperationFieldDTO[];
    fieldsDeleted?: string[];

    operationRecordingDTOS?: RecordingDTO[];
    operationRecordingDeleted?: string[];
}

export interface FullOperationRecording {
    input: Input;
    recording: Recording;
}

export const isFullOperationLoaded = function (fullOperation: FullOperation) {
    return !!(
        fullOperation.summary &&
        fullOperation.media &&
        fullOperation.comments &&
        fullOperation.fields &&
        fullOperation.recordings
    );
};

export const selectOperationFieldCompleteCount = createSelector(
    (operationFields: Set<OperationField> | null) => operationFields,
    (fields) => (fields === null ? 0 : fields.filter((field) => !!field.completedDate).size)
);

export const selectOperationFieldCompletedHa = createSelector(
    (operationFields: Set<OperationField> | null) => operationFields,
    (fields) =>
        fields === null
            ? 0
            : fields
                  .filter((field) => !!field.completedDate)
                  .reduce((sum, field) => sum + field.areaSqm, 0)
);

export interface FullOperationDTO {
    operation: ReadOperationDTO;
    operationFields: ReadOperationFieldDTO[] | null;
    operationRecordingDTOS: RecordingDTO[];
}

export const deserializeFullOperation = (json: FullOperationDTO) =>
    FullOperation({
        uuid: json.operation.uuid,
        summary: deserializeOperation(json.operation),
        fields: deserializeFullOperationFields(json.operationFields),
        recordings: Set(json.operationRecordingDTOS.map(deserializeRecording)),
    });

const deserializeFullOperationFields = (operationFieldDTOs: ReadOperationFieldDTO[] | null) =>
    Set(
        operationFieldDTOs
            ? operationFieldDTOs.map((opField) => ({
                  fieldUuid: opField.fieldUUID,
                  areaSqm: opField.areaSQM,
                  yieldRateHa: opField.yieldRateHa,
                  yieldTotal: opField.yieldTotal,
                  completedDate: opField.completedDate
                      ? new Date(opField.completedDate)
                      : undefined,
                  completedByUserId: opField.completedByUserId
                      ? opField.completedByUserId
                      : undefined,
                  startDate: opField.startDate ? new Date(opField.startDate) : undefined,
                  windSpeed: opField.windSpeed === null ? undefined : opField.windSpeed,
                  windDirection: opField.direction,
                  temperature: opField.temperature === null ? undefined : opField.temperature,
                  weatherNote: opField.note,
              }))
            : []
    );

export const serializeFullOperationFields = (
    operationFields: Set<OperationField>
): WriteOperationFieldDTO[] => {
    return operationFields
        .map(
            ({
                fieldUuid,
                areaSqm,
                yieldRateHa,
                yieldTotal,
                completedDate,
                completedByUserId,
                startDate,
                windSpeed,
                windDirection,
                temperature,
                weatherNote,
            }) => ({
                fieldUUID: fieldUuid,
                areaSQM: areaSqm,
                yieldRate: yieldRateHa,
                yieldTotal: yieldTotal,
                completedDate: completedDate ? completedDate.getTime() : undefined,
                completedByUserId,
                startDate: startDate ? startDate.getTime() : undefined,
                windSpeed,
                direction: windDirection,
                temperature,
                note: weatherNote,
            })
        )
        .toArray();
};

export const deserializeOperationToFullOperation = (json: ReadOperationDTO) =>
    FullOperation({
        uuid: json.uuid,
        summary: deserializeOperation(json),
        fields: deserializeFullOperationFields(json.operationFields),
    });

const toFieldAttachment = toAttachmentItem(AttachmentType.FIELD);
export const getFullOperationAttachmentItems = (fullOperation: FullOperation) =>
    fullOperation.fields?.map((opField) => toFieldAttachment(opField.fieldUuid));

export default FullOperation;
