import c from 'classnames';
import { getYearFieldUsageUuid } from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import { useDateFormat } from 'hooks/useDateFormat';
import type { List } from 'immutable';
import { capitaliseWord } from 'lib/util/text';
import type { FullOperationRecording } from 'operations/FullOperation';
import type { FullOperationField } from 'operations/OperationField';
import { operationFieldHasWeatherData } from 'operations/OperationField';
import { useAppSelector } from 'system/store';
import FormattedArea from 'view/units/FormattedArea';
import FormattedRate from 'view/units/FormattedRate';
import FormattedTotal from 'view/units/FormattedTotal';
import ShortInputRate from 'view/units/ShortInputRate';
import ShortUnit from 'view/units/ShortUnit';
import SpeedUnit from 'view/units/SpeedUnit';
import SpeedValue from 'view/units/SpeedValue';
import Temperature from 'view/units/Temperature';
import TemperatureUnit from 'view/units/TemperatureUnit';

interface OperationPrintSheetRecordingRowProps {
    opField: FullOperationField;
    recordings: List<FullOperationRecording>;
}

const OperationPrintSheetRecordingRow = ({
    opField,
    recordings,
}: OperationPrintSheetRecordingRowProps) => {
    const { format } = useDateFormat();
    const year = useAppSelector((state) => state.yearsState.currentYear as number);
    const fieldUsages = useAppSelector(
        (state) => state.fieldUsageState.fieldUsages as List<FieldUsage>
    );

    const firstOperationRecording =
        recordings.size > 0 ? (recordings.first() as FullOperationRecording) : undefined;

    const fieldUsageUuid = getYearFieldUsageUuid(year, opField.field);
    const fieldUsage = fieldUsages.find((fieldUsage) => fieldUsage.uuid === fieldUsageUuid);
    const hasWeather = operationFieldHasWeatherData(opField);

    return (
        <>
            <tr>
                <td>
                    <strong>{opField.field.name}</strong>
                </td>
                <td>{fieldUsage ? fieldUsage.name : 'None'}</td>
                <td>
                    <FormattedArea areaSqm={opField.areaSqm} withUnit />
                </td>
                <td>{firstOperationRecording && firstOperationRecording.input.name}</td>
                <td>
                    {firstOperationRecording && firstOperationRecording.recording.rate && (
                        <>
                            <FormattedRate rate={firstOperationRecording.recording.rate} />{' '}
                            <ShortInputRate input={firstOperationRecording.input} />
                        </>
                    )}
                </td>
                <td>
                    {firstOperationRecording && (
                        <>
                            <FormattedTotal
                                rate={firstOperationRecording.recording.rate}
                                areaSqm={opField.areaSqm}
                            />{' '}
                            <ShortUnit unit={firstOperationRecording.input.unit} />
                        </>
                    )}
                </td>
            </tr>
            {recordings.shift().map(({ input, recording }) => {
                return (
                    <tr key={input.uuid}>
                        <td colSpan={3} />
                        <td>{input.name}</td>
                        <td>
                            <FormattedRate rate={recording.rate} /> <ShortInputRate input={input} />
                        </td>
                        <td>
                            <FormattedTotal rate={recording.rate} areaSqm={opField.areaSqm} />{' '}
                            <ShortUnit unit={input.unit} />
                        </td>
                    </tr>
                );
            })}
            {opField.startDate && (
                <tr className="border-solid border-0">
                    <td colSpan={6}>
                        Start date: {format(opField.startDate, 'do MMMM yyyy, HH:mm')}
                    </td>
                </tr>
            )}
            <tr className={c('border-solid border-0', { 'border-b': !hasWeather })}>
                <td colSpan={6}>
                    Completed date:{' '}
                    {opField.completedDate && format(opField.completedDate, 'do MMMM yyyy, HH:mm')}
                </td>
            </tr>
            {hasWeather && (
                <tr className="border-solid border-0 border-b">
                    <td colSpan={6}>
                        Weather: {opField.windDirection && `${opField.windDirection} `}
                        {opField.windSpeed && (
                            <>
                                <SpeedValue value={opField.windSpeed} />
                                <SpeedUnit />{' '}
                            </>
                        )}
                        {opField.temperature && (
                            <>
                                <Temperature celsius={opField.temperature} />
                                <TemperatureUnit />{' '}
                            </>
                        )}
                        {opField.weatherNote && capitaliseWord(opField.weatherNote)}
                    </td>
                </tr>
            )}
        </>
    );
};

export default OperationPrintSheetRecordingRow;
