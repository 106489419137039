import { useSelector } from 'react-redux';
import type { AppState } from 'system/store';

const TemperatureUnit = () => {
    const temperatureUnit = useSelector<AppState, string | undefined>(
        (state) => state.user.preferences?.temperatureMeasurementUnit
    );
    const unit = temperatureUnit === 'FAHRENHEIT' ? 'F' : 'C';
    return <>°{unit}</>;
};

export default TemperatureUnit;
