import type { ComponentProps, MouseEvent, PropsWithChildren } from 'react';
import { createContext, Fragment, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import { DownloadList } from 'components/download-list';
import { FieldBoundryDownloadItems } from 'components/field-boundry-download-items';
import icon from 'components/icons/icon';
import { useFileDownload } from 'hooks/useFileDownload';
import { useSegmentTracking } from 'hooks/useSegmentTracking';
import { t } from 'i18next';
import DropdownButton from 'view/dropdown-button/DropdownButton';
import { DropdownButtonItem } from 'view/dropdown-button/DropdownButtonItem';
import FeatureLockedModal from 'view/modal/FeatureLockedModal';

import {
    useDownloadLink,
    useFeatureListDownloadLink,
    useOperationsDownloadLink,
} from './data-export-hooks';
import downloadIcon from './download.svg';
import featuresIcon from './features.svg';
import fieldsIcon from './field-input-nav-icon.svg';

import styles from './DataExport.module.css';

interface DataExportProps {
    hasReporting: boolean;
}

interface DataExportDropDownProps extends PropsWithChildren {
    pending: boolean;
}

const formats: ('CSV' | 'XLS')[] = ['CSV', 'XLS'];

interface DataExportContextState extends DataExportProps {
    showUpgradeModal: boolean;
    toggleUpgradeModal: VoidFunction;
}

type DataExportContextProviderProps = PropsWithChildren & DataExportProps;

const DataExportContext = createContext({} as DataExportContextState);

function DataExportContextProvider({ hasReporting, children }: DataExportContextProviderProps) {
    const [showUpgradeModal, toggleUpgradeModal] = useToggle(false);

    const value = useMemo(
        () => ({ showUpgradeModal, toggleUpgradeModal, hasReporting }),
        [showUpgradeModal, toggleUpgradeModal, hasReporting]
    );
    return <DataExportContext.Provider value={value}>{children}</DataExportContext.Provider>;
}

const useDataExportContext = () => {
    const context = useContext(DataExportContext);

    return context;
};

const useDownloadDataExport = () => {
    const { hasReporting, showUpgradeModal, toggleUpgradeModal } = useDataExportContext();
    const { dataExport } = useSegmentTracking();

    const { pending, fetchFile } = useFileDownload();

    const handleClick = (e: MouseEvent<HTMLAnchorElement>, name: string, format: string) => {
        e.preventDefault();
        if (!hasReporting) {
            !showUpgradeModal && toggleUpgradeModal();
        } else {
            fetchFile(e.currentTarget.href, e.currentTarget.dataset.filename!);
            dataExport(name, format);
        }
    };

    return {
        pending,
        handleClick,
    };
};

function DataExportItems({ ...props }: ComponentProps<'ul'>) {
    return <ul className={styles.items} {...props} />;
}

function DataExportItem({ ...props }: ComponentProps<'li'>) {
    return <li className={styles.item} {...props} />;
}

function DataExportIcon({ ...props }: ComponentProps<'img'>) {
    return <span className={styles.icon} {...props} />;
}

function DataExportDropDown({ pending, children }: DataExportDropDownProps) {
    const { t } = useTranslation();

    return (
        <DropdownButton
            text={
                <Fragment>
                    <img src={downloadIcon} alt="Download icon" className={styles.downloadIcon} />
                    {t(pending ? 'label_downloading' : 'label_download')}
                </Fragment>
            }
            className="w-64"
            disabled={pending}
        >
            {children}
        </DropdownButton>
    );
}

function DataExportFieldJob() {
    const { t } = useTranslation();
    const { pending, handleClick } = useDownloadDataExport();
    const getOperationsDownloadLink = useOperationsDownloadLink();

    return (
        <DataExportItem>
            <DataExportIcon>{icon('field-job', 'blue')}</DataExportIcon>
            <span className="flex-grow">{t('field_jobs')}</span>
            <DataExportDropDown pending={pending}>
                {formats.map((format) => (
                    <DropdownButtonItem.Root key={format}>
                        <DropdownButtonItem.AnchorItem
                            href={getOperationsDownloadLink(format)}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => handleClick(e, 'Field jobs', format)}
                            data-filename={`field-jobs-export.${format.toLowerCase()}`}
                            disabled={pending}
                        >
                            {t('reporting_data_export_field_jobs', { sprintf: [format] })}
                        </DropdownButtonItem.AnchorItem>
                    </DropdownButtonItem.Root>
                ))}
            </DataExportDropDown>
        </DataExportItem>
    );
}

function DataExportNotes() {
    const { t } = useTranslation();
    const { pending, handleClick } = useDownloadDataExport();
    const getDownloadLink = useDownloadLink();

    return (
        <DataExportItem>
            <DataExportIcon>{icon('note', 'blue')}</DataExportIcon>

            <span className="flex-grow">{t('notes')}</span>
            <DataExportDropDown pending={pending}>
                {formats.map((format) => (
                    <DropdownButtonItem.Root key={format}>
                        <DropdownButtonItem.AnchorItem
                            href={getDownloadLink('notes/report', format)}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => handleClick(e, 'Notes', format)}
                            data-filename={`notes-export.${format.toLowerCase()}`}
                            disabled={pending}
                        >
                            {t('reporting_data_export_notes', { sprintf: [format] })}
                        </DropdownButtonItem.AnchorItem>
                    </DropdownButtonItem.Root>
                ))}
            </DataExportDropDown>
        </DataExportItem>
    );
}

function DataExportFields() {
    const { t } = useTranslation();
    const { pending, handleClick } = useDownloadDataExport();
    const getDownloadLink = useDownloadLink();

    return (
        <DataExportItem>
            <DataExportIcon>
                <img src={fieldsIcon} alt="Fields icon" />
            </DataExportIcon>
            <span className="flex-grow">{t('fields')}</span>

            <DownloadList.Root>
                {formats.map((format) => (
                    <DownloadList.Link
                        key={format}
                        to={getDownloadLink('fields/report', format)}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => handleClick(e, 'Fields', format)}
                        data-filename={`fields-export.${format.toLowerCase()}`}
                        disabled={pending}
                    >
                        {t('reporting_data_export_fields', { sprintf: [format] })}
                    </DownloadList.Link>
                ))}

                <FieldBoundryDownloadItems />
            </DownloadList.Root>
        </DataExportItem>
    );
}

function DataExportFeatures() {
    const { t } = useTranslation();
    const { pending, handleClick } = useDownloadDataExport();
    const getFeaturesDownloadLink = useFeatureListDownloadLink();

    return (
        <DataExportItem>
            <DataExportIcon>
                <img src={featuresIcon} alt="Features icon" />
            </DataExportIcon>

            <span className="flex-grow">{t('features')}</span>
            <DataExportDropDown pending={pending}>
                {formats.map((format) => (
                    <DropdownButtonItem.Root key={format}>
                        <DropdownButtonItem.AnchorItem
                            href={getFeaturesDownloadLink(format)}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => handleClick(e, 'Features', format)}
                            data-filename={`features-export.${format.toLowerCase()}`}
                            disabled={pending}
                        >
                            {t('reporting_data_export_features', { sprintf: [format] })}
                        </DropdownButtonItem.AnchorItem>
                    </DropdownButtonItem.Root>
                ))}
            </DataExportDropDown>
        </DataExportItem>
    );
}

function LockDownloadDataExport() {
    const { showUpgradeModal, toggleUpgradeModal } = useDataExportContext();

    return (
        showUpgradeModal && (
            <FeatureLockedModal
                onClose={toggleUpgradeModal}
                header={t('reporting_data_export_upgrade_title')}
            />
        )
    );
}

function DataExportBase() {
    const { t } = useTranslation();

    return (
        <div className="DataExport">
            <LockDownloadDataExport />

            <h2 className="mb-1">{t('reporting_data_export_title')}</h2>
            <p>{t('reporting_data_export_desc')}</p>

            <DataExportItems>
                <DataExportFieldJob />
                <DataExportNotes />
                <DataExportFields />
                <DataExportFeatures />
            </DataExportItems>
        </div>
    );
}

function DataExport({ hasReporting }: DataExportProps) {
    return (
        <DataExportContextProvider hasReporting={hasReporting}>
            <DataExportBase />
        </DataExportContextProvider>
    );
}
export { DataExport };
