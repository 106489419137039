import { useCurrentFarm } from './useCurrentFarm';

const useUpgradeLink = () => {
    const { currentFarm } = useCurrentFarm();

    const upgradeLink = `/farms/${currentFarm.uuid}/billing/upgrade`;
    return { upgradeLink };
};

export { useUpgradeLink };
