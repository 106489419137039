import { List } from 'immutable';

/**
 * Splits a list into sublists based on the value generated by the given function. Groups will be
 * formed of items that have the same result.
 *
 * For example:
 *      splitList(List(['a', 'a', 'b', 'c', 'c']), (i) => i)
 *      List([
 *          List(['a', 'a'])
 *          List(['b'])
 *          List(['c', 'c'])
 *      ])
 */
export const splitList = <T, V>(list: List<T>, fn: (item: T) => V) => {
    const output = [] as T[][];
    let lastValue = undefined as V | undefined;
    let currentItems = [] as T[];

    list.forEach((item, i) => {
        const thisValue = fn(item);
        if (i === 0) {
            currentItems = [];
        } else if (lastValue !== thisValue) {
            output.push(currentItems);
            currentItems = [];
        }
        currentItems.push(item);
        lastValue = thisValue;
    });
    if (currentItems.length > 0) {
        output.push(currentItems);
    }
    return List(output.map((list) => List(list)));
};
