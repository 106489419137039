import { useTranslation } from 'react-i18next';
import EmptyState from 'sidebar/modules/EmptyState';

import herdEmptyState from './herd-empty-state.png';

const HerdEmptyState = () => {
    const { t } = useTranslation();
    return (
        <EmptyState
            header={t('livestock_error_empty').replace('\\n', ' ')}
            image={herdEmptyState}
        />
    );
};

export default HerdEmptyState;
