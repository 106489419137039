import type { MouseEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import c from 'classnames';
import { Form, Select } from 'informed';
import type Input from 'inputs/Input';
import { InputUnit } from 'inputs/Input';
import { getInputTypeI18nKey, InputType } from 'inputs/InputType';
import type { MicronutrientFormValues, NutrientFormValues } from 'inputs/Nutrients';
import {
    micronutrientFormValuesToMicronutrients,
    nutrientFormValuesToNutrients,
} from 'inputs/Nutrients';
import { allPropsEmpty, defaultToZero } from 'lib/fp-helpers';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { capitaliseWord } from 'lib/util/text';
import EditButton from 'sidebar/modules/common/EditButton';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import Fog from 'view/Fog';
import TextInputFormField from 'view/form/TextInputFormField';
import { required } from 'view/form/validations';
import ShortUnit from 'view/units/ShortUnit';

import NutrientFormFields from './NutrientFormFields';
import RecordingFormFields from './RecordingFormFields';

const validateRequired = required('Required');

interface NewInputAndRecordingFormProps {
    totalArea: number;
    measurementUnit: MeasurementUnit;
    initialValues?: Omit<InputAndRecordingFormValues, 'rate' | 'total'>;
    fertiliserUuid?: string;

    onCancel: VoidFunction;
    onSave: (inputValues: Partial<Input>, rate: number) => Promise<void>;
    onAddAnother: (name: string, type: string, unit: InputUnit, rate: number) => void;
    onDone: VoidFunction;
}

export interface InputAndRecordingFormValues {
    name: string;
    unit: string;
    type: string;
    rate: number;
    total: number;

    // Nutrients
    nutrients?: NutrientFormValues;
    micronutrients?: MicronutrientFormValues;
}

const NewInputAndRecordingForm = ({
    totalArea,
    measurementUnit,
    initialValues = {
        name: '',
        unit: '',
        type: '',
    },
    fertiliserUuid,
    onCancel,
    onSave,
    onAddAnother,
    onDone,
}: NewInputAndRecordingFormProps) => {
    const { t } = useTranslation();
    const [step, setStep] = useState<'input' | 'recording'>('input');
    const { pending, error, setPromise } = usePromise();

    const handleSubmit = (values: InputAndRecordingFormValues, setAnother: boolean) => {
        const unit = InputUnit[values.unit];
        const next = setAnother
            ? onAddAnother.bind(null, values.name, values.type, unit, values.rate)
            : onDone;
        setPromise(
            onSave(
                {
                    ...values,
                    type: InputType[values.type],
                    unit,
                    fertiliserUuid,
                    nutrients: nutrientFormValuesToNutrients(values.nutrients),
                    micronutrients: micronutrientFormValuesToMicronutrients(values.micronutrients),
                },
                defaultToZero(values.rate)
            ).then(next)
        );
    };

    const handleCancel = (e: MouseEvent) => {
        e.preventDefault();
        onCancel();
    };

    const handleNextClick = (e: MouseEvent) => {
        e.preventDefault();
        setStep('recording');
    };

    const formInitialValues = {
        ...initialValues,
        rate: NaN,
        total: NaN,
    };

    return (
        <Form<InputAndRecordingFormValues>
            className="NewInputAndRecordingForm h-content-full overflow-auto"
            initialValues={formInitialValues}
        >
            {({ formState, formApi }) => (
                <>
                    <SidebarHeader className="px-5">
                        <Button onClick={handleCancel} variant="danger-outline" small>
                            {t('cancel')}
                        </Button>
                    </SidebarHeader>
                    <div className={c({ hidden: step !== 'input' })}>
                        <div className="p-5 flex gap-3 bordered-b relative">
                            <TextInputFormField
                                field="name"
                                label={t('name')}
                                validate={validateRequired}
                                error={formState.errors ? formState.errors.name : null}
                                className="w-72"
                                inputClassName="w-full"
                                labelClassName="font-bold text-sm uppercase"
                            />
                            <div>
                                <label
                                    htmlFor="unit"
                                    className="cursor-pointer font-bold text-sm uppercase"
                                >
                                    {t('unit')}
                                </label>
                                <Select
                                    field="unit"
                                    id="unit"
                                    className={c('bordered p-2 rounded-sm h-10 focus', {
                                        'error-border': formState.errors?.unit,
                                    })}
                                    validate={validateRequired}
                                    validateOnChange
                                >
                                    <option value="">-</option>
                                    {Object.keys(InputUnit).map((inputUnit) => (
                                        <option key={inputUnit} value={inputUnit}>
                                            {t(`unit_type_${inputUnit.toLowerCase()}`)}
                                        </option>
                                    ))}
                                </Select>
                                {formState.errors?.unit && (
                                    <div className="field-error">{formState.errors.unit}</div>
                                )}
                            </div>
                            <div>
                                <label
                                    htmlFor="type"
                                    className="cursor-pointer font-bold text-sm uppercase"
                                >
                                    {t('type')}
                                </label>
                                <Select
                                    field="type"
                                    id="type"
                                    className={c('bordered p-2 rounded-sm h-10 focus', {
                                        'error-border': formState.errors?.type,
                                    })}
                                    validate={validateRequired}
                                    validateOnChange
                                >
                                    <option value="">{t('choose_one')}</option>
                                    {Object.keys(InputType).map((inputType) => (
                                        <option key={inputType} value={inputType}>
                                            {t(getInputTypeI18nKey(inputType))}
                                        </option>
                                    ))}
                                </Select>
                                {formState.errors?.type && (
                                    <div className="field-error">{formState.errors.type}</div>
                                )}
                            </div>
                        </div>
                        {formState.values.type === InputType.FERTILIZER && (
                            <NutrientFormFields
                                className="bordered-b p-5"
                                initiallyShowMicronutrients={
                                    !allPropsEmpty(initialValues.micronutrients)
                                }
                            />
                        )}
                        <Button
                            onClick={handleNextClick}
                            className="m-5"
                            disabled={
                                formState.values.name === '' ||
                                formState.values.type === '' ||
                                formState.values.unit === ''
                            }
                        >
                            {t('next')}
                        </Button>
                    </div>
                    {step === 'recording' && (
                        <div className="p-5 bordered-b">
                            <EditButton setEditing={() => setStep('input')} />
                            <h2 className="mb-1">{formState.values.name}</h2>
                            <p className="mb-0 text-base">
                                <ShortUnit unit={formState.values.unit} />{' '}
                                {capitaliseWord(formState.values.type)}
                            </p>
                        </div>
                    )}
                    <div className="relative">
                        {step === 'input' && <Fog />}
                        <RecordingFormFields
                            inputUnit={formState.values.unit || InputUnit.GRAMS}
                            inputType={formState.values.type}
                            totalArea={totalArea}
                            measurementUnit={measurementUnit}
                            nutrients={nutrientFormValuesToNutrients(formState.values.nutrients)}
                            micronutrients={micronutrientFormValuesToMicronutrients(
                                formState.values.micronutrients
                            )}
                        />
                        {error && (
                            <p className="px-5 pt-3">
                                <span className="text-error-text">{t('something_went_wrong')}</span>
                            </p>
                        )}
                        <div className="flex flex-wrap gap-4 p-5 bordered-b">
                            <SubmitButton
                                onClick={() => {
                                    formApi.validate();
                                    if (!formState.invalid) {
                                        handleSubmit(formState.values, false);
                                    }
                                }}
                                disabled={pending}
                            >
                                {t('input_rate_set')}
                            </SubmitButton>
                            <SubmitButton
                                variant="outline"
                                onClick={() => {
                                    formApi.validate();
                                    if (!formState.invalid) {
                                        handleSubmit(formState.values, true);
                                    }
                                }}
                                disabled={pending}
                            >
                                {t('input_rate_set_add')}
                            </SubmitButton>
                        </div>
                    </div>
                </>
            )}
        </Form>
    );
};

export default NewInputAndRecordingForm;
