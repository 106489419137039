import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import c from 'classnames';

interface ListDropdownItemProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLLIElement>, HTMLLIElement> {}

const ListDropdownItem = ({ children, className, ...props }: ListDropdownItemProps) => {
    return (
        <li className={c('pl-0', className)} {...props}>
            {children}
        </li>
    );
};

export default ListDropdownItem;
