import c from 'classnames';

interface SubFieldMapMenuButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {}

const SubFieldMapMenuButton = ({
    children,
    className,
    disabled,
    ...props
}: SubFieldMapMenuButtonProps) => {
    return (
        <button
            className={c('bare-btn focus SubFieldMapMenuButton', className)}
            disabled={disabled}
            {...props}
        >
            {children}
        </button>
    );
};

export default SubFieldMapMenuButton;
