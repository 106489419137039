import { useSelector } from 'react-redux';
import type { Activity } from 'activity/activity-helper';
import {
    getCompletedDateForField,
    getCreatedDate,
    getLastCompletedDate,
    hasAnyField,
    hasField,
    isCompleteForAllFields,
    isCompleteOrFieldComplete,
    isNote,
} from 'activity/activity-helper';
import { addHours, addMilliseconds, intervalToDuration, isAfter, startOfDay } from 'date-fns';
import type Field from 'fields/Field';
import { selectFieldSubFields } from 'fields/fields-selectors';
import type { HerdLocation, HerdType } from 'herd/Herd';
import type Herd from 'herd/Herd';
import type { Set } from 'immutable';
import { List } from 'immutable';
import { splitList } from 'lib/util/list';
import type MapGroup from 'maps/farm/MapGroup';
import { selectMapsForField } from 'maps/farm/maps-state';
import { selectLimitedActivities } from 'notes/notes-filter';
import type { AppState } from 'system/store';

export interface FieldHistoryHerdItem {
    herdName: string;
    herdType: HerdType;
    herdLocation: HerdLocation;
}

export interface FieldHistoryRestItem {
    restDuration: Duration;
}

export type FieldHistoryItem =
    | {
          type: 'field-note';
          id: string;
          date: Date | null;
          item: Activity;
      }
    | {
          type: 'field-map';
          id: string;
          date: Date | null;
          item: MapGroup;
      }
    | {
          type: 'herd-move' | 'herd-remove';
          id: string;
          date: Date | null;
          item: FieldHistoryHerdItem;
      }
    | {
          type: 'rest-days';
          id: string;
          date: Date | null;
          item: FieldHistoryRestItem;
      };

/**
 * This groups the given activity items into notes and jobs for the given field and other fields.
 */
const groupFieldAndNonFieldActivities = (
    activities: List<Activity>,
    field: Field,
    otherFieldUuids: Set<string>
) =>
    activities.reduce(
        (map, activity) => {
            const nextMap = { ...map };
            if (hasField(activity, field.uuid)) {
                if (isNote(activity)) {
                    nextMap.fieldNotes = nextMap.fieldNotes.push(activity);
                    return nextMap;
                }
                if (isCompleteOrFieldComplete(activity, field.uuid)) {
                    nextMap.fieldJobs = nextMap.fieldJobs.push(activity);
                    return nextMap;
                }
            }

            if (isNote(activity)) {
                nextMap.otherNotes = nextMap.otherNotes.push(activity);
                return nextMap;
            }
            if (otherFieldUuids.size > 0 && isCompleteForAllFields(activity, otherFieldUuids)) {
                nextMap.otherJobs = nextMap.otherJobs.push(activity);
                return nextMap;
            }
            return nextMap;
        },
        {
            fieldJobs: List<Activity>(),
            fieldNotes: List<Activity>(),
            otherJobs: List<Activity>(),
            otherNotes: List<Activity>(),
        }
    );

const fieldJobToFieldNoteHistoryItem = (activity: Activity, field: Field): FieldHistoryItem => ({
    type: 'field-note',
    id: activity.uuid,
    date: getCompletedDateForField(activity, field.uuid),
    item: activity,
});

const otherJobToFieldNoteHistoryItem = (
    activity: Activity,
    fieldUuids: Set<string>
): FieldHistoryItem => ({
    type: 'field-note',
    id: activity.uuid,
    date: getLastCompletedDate(activity, fieldUuids),
    item: activity,
});

const noteToFieldNoteHistoryItem = (activity: Activity): FieldHistoryItem => ({
    type: 'field-note',
    id: activity.uuid,
    date: getCreatedDate(activity),
    item: activity,
});

const mapGroupToFieldMapHistoryItem = (mapGroup: MapGroup): FieldHistoryItem => ({
    type: 'field-map',
    id: mapGroup.uuid,
    date: mapGroup.timestamp,
    item: mapGroup,
});

export const createFieldHistory = (
    field: Field,
    subFields: List<Field>,
    activities: List<Activity>,
    mapGroups: MapGroup[],
    herdLocations: List<HerdLocation>,
    herds: Herd[]
) => {
    const subFieldUuids = subFields.map((field) => field.uuid).toSet();
    const groupedActivities = groupFieldAndNonFieldActivities(activities, field, subFieldUuids);

    const history = List<FieldHistoryItem>()
        .concat<FieldHistoryItem>(groupedActivities.fieldNotes.map(noteToFieldNoteHistoryItem))
        .concat<FieldHistoryItem>(
            groupedActivities.fieldJobs.map((activity) =>
                fieldJobToFieldNoteHistoryItem(activity, field)
            )
        )
        .concat<FieldHistoryItem>(groupedActivities.otherNotes.map(noteToFieldNoteHistoryItem))
        .concat<FieldHistoryItem>(
            groupedActivities.otherJobs.map((activity) =>
                otherJobToFieldNoteHistoryItem(activity, subFieldUuids)
            )
        )
        .concat<FieldHistoryItem>(mapGroups.map(mapGroupToFieldMapHistoryItem))
        .concat<FieldHistoryItem>(
            herdLocations
                .filter((herdLocation) => herds.find((herd) => herd.uuid === herdLocation.herdUuid))
                .sortBy((herdLocation) => herdLocation.moveDate)
                .reduce((items, herdLocation, index, herdLocations) => {
                    let nextItems = items;
                    const herd = herds.find((herd) => herd.uuid === herdLocation.herdUuid) as Herd;

                    if (!herdLocation.latest && herdLocation.durationHours !== undefined) {
                        const removeDate =
                            herdLocation.durationHours > 0
                                ? addHours(herdLocation.moveDate, herdLocation.durationHours)
                                : addMilliseconds(herdLocation.moveDate, 1);
                        nextItems = nextItems.push({
                            type: 'herd-remove',
                            id: herdLocation.uuid,
                            date: removeDate,
                            item: {
                                herdName: herd.name,
                                herdType: herd.type,
                                herdLocation: { ...herdLocation, moveDate: removeDate },
                            },
                        });

                        // If there are more moves after this & they occur after the remove item
                        // calculate the rest days between this move and the next one.
                        const nextMove = herdLocations.get(index + 1);
                        if (nextMove && isAfter(nextMove.moveDate, removeDate)) {
                            nextItems = nextItems.push({
                                type: 'rest-days',
                                id: herdLocation.uuid,
                                date: nextMove.moveDate,
                                item: {
                                    restDuration: intervalToDuration({
                                        start: removeDate,
                                        end: nextMove.moveDate,
                                    }),
                                },
                            });
                        }
                    }

                    return nextItems.push({
                        type: 'herd-move',
                        id: herdLocation.uuid,
                        date: herdLocation.moveDate,
                        item: {
                            herdName: herd.name,
                            herdType: herd.type,
                            herdLocation,
                        },
                    });
                }, List<FieldHistoryItem>())
        )
        .filter((i) => i.date !== null)
        .sortBy((i) => (i.date as Date).getTime())
        .reverse();

    const historyByDay = splitList<FieldHistoryItem, number>(history, (item) =>
        startOfDay(item.date as Date).getTime()
    );
    return historyByDay;
};

export const useFieldHistory = (field: Field, herdLocations?: List<HerdLocation>) => {
    const subFields = useSelector<AppState, List<Field>>((state) =>
        selectFieldSubFields(state, field.uuid)
    );
    const fieldAndSubFieldUuids = List.of(field.uuid)
        .concat(subFields.map((field) => field.uuid))
        .toSet();
    const activities = useSelector<AppState, List<Activity>>(selectLimitedActivities).filter(
        (activity) => hasAnyField(activity, fieldAndSubFieldUuids)
    );
    const mapGroups = useSelector<AppState, MapGroup[]>((state) =>
        selectMapsForField(state, field.uuid)
    );
    const herds = useSelector<AppState, Herd[]>((state) => state.herdsState.herds ?? []);

    return herdLocations !== undefined
        ? createFieldHistory(field, subFields, activities, mapGroups, herdLocations, herds)
        : undefined;
};
