import { createLogger } from '@fieldmargin/webapp-reporting';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadedFarmSuccess } from 'farms/farm-loading-state';
import type { List } from 'immutable';
import { Record } from 'immutable';
import type { FarmChatChange } from 'lib/firebase/snapshot-parsers';
import { logReselect } from 'lib/util/reselect-util';
import { throttle } from 'lodash';
import MediaUploading from 'media/MediaUploading';
import { reverse, takeWhile } from 'ramda';
import type { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import type { Nullable } from 'system/types';
import { selectUserId } from 'users/user-state';
import { trackEvent } from 'utils/trackEvent';

import { uploadChatFileApi } from './farm-chat/farm-chat-api';
import {
    getHistoryPageApi,
    writeChatMessageApi,
    writeLastReadApi,
} from './firebase/firebase-chat-api';
import type { ChatFile, ChatImage } from './chat-models';
import type ChatMessage from './ChatMessage';

export const FARM_CHAT_PAGE_SIZE = 50;
const logger = createLogger('chat.chat-state');

interface ChatState {
    initialized: boolean;
    farmUuid: string | null;
    messages: ChatMessage[];
    messagesFinished: boolean;
    historyOpen: boolean;
    history: ChatMessage[];
    historyFinished: boolean;
    lastReadUuid: string | null;
    filesUploading: MediaUploading[];
    uploadFileError: boolean;
    notification?: ChatNotification;

    historyScrollPos: number | null; // if set it implies that when the chat is reopened the history should be open and this position scrolled to
    previousScrollPos: number | null; // if set it implies that when the chat is reopened this position should be scrolled to

    chatMediaModalImageUrl: ChatImage | null; // media image that should be displayed in modal

    writeChatMessagePending: boolean;
    writeChatMessageError: boolean;
    getHistoryPagePending: boolean;
    getHistoryPageError: boolean;
}

const initialState: ChatState = {
    initialized: false,
    farmUuid: null,
    messages: [],
    messagesFinished: false,
    historyOpen: false,
    history: [],
    historyFinished: false,
    lastReadUuid: null,
    filesUploading: [],
    uploadFileError: false,
    notification: undefined,

    historyScrollPos: null,
    previousScrollPos: null,

    chatMediaModalImageUrl: null,

    writeChatMessagePending: false,
    writeChatMessageError: false,
    getHistoryPagePending: false,
    getHistoryPageError: false,
};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        initialized: (state) => {
            state.initialized = true;
        },
        updateChatAction: (state, { payload: changes }: PayloadAction<List<FarmChatChange>>) => {
            const messages = changes
                .filter(
                    (change): change is Required<FarmChatChange> => change.message !== undefined
                )
                .reduce((msgs, change) => {
                    switch (change.type) {
                        case 'added':
                            msgs.push(change.message);
                            break;
                        case 'modified':
                            msgs = msgs.map((m) =>
                                m.uuid === change.message.uuid ? change.message : m
                            );
                            break;
                        case 'removed':
                            msgs = msgs.filter((m) => m.uuid !== change.message.uuid);
                            break;
                        default:
                    }
                    return msgs;
                }, state.messages);

            state.messages = messages;
            state.messagesFinished = messages.length < FARM_CHAT_PAGE_SIZE;
        },

        showNotification: (state, action: PayloadAction<ChatNotification>) => {
            state.notification = action.payload;
        },
        dismissNotification: (state, action: PayloadAction<ChatNotification>) => {
            if (state.notification !== undefined && state.notification.id === action.payload.id) {
                state.notification = undefined;
            }
        },
        updateLastRead: (state, action: PayloadAction<string>) => {
            state.lastReadUuid = action.payload;
        },
        toggleHistory: (state) => {
            const historyCurrentlyOpen = state.historyOpen;
            state.historyOpen = !historyCurrentlyOpen;
            state.history = historyCurrentlyOpen ? [] : state.messages;
        },
        setHistoryScrollPos: (state, action: PayloadAction<Nullable<number>>) => {
            state.historyScrollPos = action.payload;
        },
        setPreviousScrollPos: (state, action: PayloadAction<Nullable<number>>) => {
            state.previousScrollPos = action.payload;
        },
        uploadFilePending: (state, action: PayloadAction<MediaUploading>) => {
            state.filesUploading.push(action.payload);
            state.uploadFileError = false;
        },
        uploadFileProgress: (state, action: PayloadAction<MediaUploading>) => {
            state.filesUploading = state.filesUploading.map((existingUpload) =>
                existingUpload.id === action.payload.id ? action.payload : existingUpload
            );
        },
        uploadFileSuccess: (state, action: PayloadAction<MediaUploading>) => {
            state.filesUploading = state.filesUploading.filter(
                (existing) => existing.id !== action.payload.id
            );
        },
        uploadFileFailure: (state, action: PayloadAction<MediaUploading>) => {
            state.filesUploading = state.filesUploading.filter(
                (existing) => existing.id !== action.payload.id
            );
            state.uploadFileError = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadedFarmSuccess.toString(), () => initialState)
            .addCase(getHistoryPage.pending, (state) => {
                state.getHistoryPageError = false;
                state.getHistoryPagePending = true;
            })
            .addCase(getHistoryPage.fulfilled, (state, action) => {
                state.getHistoryPagePending = false;
                state.history = action.payload.messages.concat(state.history);
                state.historyFinished = action.payload.finished;
            })
            .addCase(getHistoryPage.rejected, (state) => {
                state.getHistoryPagePending = false;
                state.getHistoryPageError = true;
            })
            .addCase(writeChatMessage.pending, (state) => {
                state.writeChatMessageError = false;
                state.writeChatMessagePending = true;
            })
            .addCase(writeChatMessage.fulfilled, (state) => {
                state.writeChatMessagePending = false;
            })
            .addCase(writeChatMessage.rejected, (state) => {
                state.writeChatMessagePending = false;
                state.writeChatMessageError = true;
            });
    },
});

export const {
    initialized,
    updateChatAction,
    showNotification,
    dismissNotification,
    updateLastRead,
    toggleHistory,
    setHistoryScrollPos,
    setPreviousScrollPos,
    uploadFilePending,
    uploadFileProgress,
    uploadFileSuccess,
    uploadFileFailure,
} = chatSlice.actions;
export const chatSliceReducer = chatSlice.reducer;

export const updateChat = (changes: List<FarmChatChange>) => {
    return (dispatch: Dispatch, getState: () => AppState) => {
        const state = getState();
        const chatState = state.chatState;
        dispatch(updateChatAction(changes));
        const userId = selectUserId(state);

        // We'll mark the chat as initialized the first time it's updated so that we only show
        // new message notifications after that. Otherwise we'd always show a notification for chats
        // that contain messages when the app loads.
        if (!chatState.initialized) {
            dispatch(initialized());

            // Show a notification for unread messages, if there are any, and DO NOT dismiss it.
            // It'll be removed when either the user clicks it, opens farm chat, or a new message
            // is received.
            const unreadCount = getUnreadMessageCount(
                changes
                    .filter((change) => !!change.message)
                    .map((change) => change.message as ChatMessage)
                    .toArray(),
                chatState.lastReadUuid
            );
            if (unreadCount > 0) {
                const notification = new ChatNotification({
                    id: 'unread-notification',
                    type: 'unread',
                    payload: unreadCount,
                    canBeReplaced: true,
                });
                dispatch(showNotification(notification));
            }
        } else if (
            !state.uiState.farmChatOpen &&
            (!chatState.notification || chatState.notification.canBeReplaced)
        ) {
            // Show new message notifications only if the farm chat is closed and there's not
            // another notification that cannot be replaced being shown. This prevents quickly
            // received new messages causing lots of new notifications.
            const addedChange = changes.find((change) => change.type === 'added');

            if (
                addedChange &&
                addedChange.message &&
                addedChange.message.createdByUserId !== userId
            ) {
                const notification = new ChatNotification({
                    id: addedChange.message.uuid,
                    type: 'newMessage',
                    payload: addedChange.message,
                    canBeReplaced: false,
                });
                dispatch(showNotification(notification));
                setTimeout(() => {
                    dispatch(dismissNotification(notification));
                }, 5000);
            }
        }
    };
};

export const getHistoryPage = createAsyncThunk(
    'getHistoryPage',
    ({ farmUuid, topMessage }: { farmUuid: string; topMessage?: ChatMessage }) =>
        getHistoryPageApi(farmUuid, topMessage)
);

export const writeChatMessage = createAsyncThunk(
    'writeChatMessage',
    ({ farmUuid, message }: { farmUuid: string; message: ChatMessage }) =>
        writeChatMessageApi(farmUuid, message).then(() => {
            trackEvent('Farm Chat comment added', {
                farmUuid,
                messageGroupId: 'general',
            });
        })
);

export const writeLastRead = (farmUuid: string, myUserId: number, lastMessage: ChatMessage) => {
    return (dispatch: Dispatch) =>
        writeLastReadApi(farmUuid, myUserId, lastMessage).then(() => {
            dispatch(updateLastRead(lastMessage.uuid));
        });
};

let nextUploadId = 100;
export const uploadFile = (farmUuid: string, message: ChatMessage, file: any) => {
    return (dispatch: Dispatch) => {
        const model = new MediaUploading({
            id: nextUploadId++,
            parentUuid: message.uuid,
            name: file.name,
            progress: 0,
        });
        dispatch(uploadFilePending(model));

        uploadChatFileApi(
            farmUuid,
            message.uuid,
            file,
            throttle((progressEvent) => {
                dispatch(
                    uploadFileProgress(
                        model.set(
                            'progress',
                            Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        )
                    )
                );
            }, 500)
        )
            .then((uploadedChatFile: ChatFile) => {
                trackEvent('Farm Chat file added', {
                    farmUuid,
                    messageGroupId: 'general',
                    fileType: uploadedChatFile.mediaType,
                });
                const messageWithFile = message
                    .set('type', uploadedChatFile.mediaType)
                    .set('fileUuid', uploadedChatFile.id)
                    .set('fileSize', uploadedChatFile.mediaSize)
                    .set('fileName', uploadedChatFile.fileName);
                return writeChatMessageApi(farmUuid, messageWithFile);
            })
            .then(() => {
                dispatch(uploadFileSuccess(model));
                return null;
            })
            .catch((e) => {
                logger.error('Unable to upload photo', e);
                dispatch(uploadFileFailure(model));
                return null;
            });
    };
};

export class ChatNotification extends Record({
    id: null as string | null, // unique identifier for the notification. Used to prevent timeouts clearing newer notifications.
    type: 'newMessage', // either 'unread' or 'newMessage'
    payload: null as any, // data that the notification should receive.
    canBeReplaced: false, // whether another notification can replace this one
}) {}

const getUnreadMessageCount = (messages: ChatMessage[], lastReadUuid: string | null) => {
    if (!messages.length) {
        return 0;
    }

    if (lastReadUuid) {
        const unread = takeWhile((m) => m.uuid !== lastReadUuid, reverse(messages));
        return unread.length;
    }
    return messages.length;
};

export const selectUnreadMessagesCount = createSelector(
    (state: AppState) => state.chatState.messages,
    (state: AppState) => state.chatState.lastReadUuid,
    (messages, lastReadUuid) => {
        logReselect('selectUnreadMessagesCount');
        const count = getUnreadMessageCount(messages, lastReadUuid);

        if (count === 0) {
            return undefined;
        }
        if (count === FARM_CHAT_PAGE_SIZE) {
            return `${count}+`;
        }
        return `${count}`;
    }
);
