import { List, Record } from 'immutable';

import type { SensorSummaryDTO } from './SensorSummary';
import type SensorSummary from './SensorSummary';
import { deserializeSensorSummary } from './SensorSummary';

const FarmIntegrationSensors = Record({
    farmIntegrationUuid: '',
    fetchSuccessful: false,
    sensors: null as List<SensorSummary> | null,
});
interface FarmIntegrationSensors extends ReturnType<typeof FarmIntegrationSensors> {}

export interface FarmIntegrationSensorsDTO {
    farmIntegrationUUID: string;
    fetchSuccessful: boolean;
    sensors: SensorSummaryDTO[];
}

export const deserializeFarmIntegrationSensors = (json: FarmIntegrationSensorsDTO) => {
    return FarmIntegrationSensors({
        farmIntegrationUuid: json.farmIntegrationUUID,
        fetchSuccessful: json.fetchSuccessful,
        sensors: json.sensors ? List(json.sensors.map(deserializeSensorSummary)) : List(),
    });
};

export default FarmIntegrationSensors;
