import { useSelector } from 'react-redux';
import type { AppState } from 'system/store';

export const useMetricUnit = () => {
    const temperatureUnit = useSelector<AppState, string | undefined>(
        (state) => state.user.preferences?.temperatureMeasurementUnit
    );

    const getMetricUnit = (metricType: string, unit: string | null): string => {
        if (metricType === 'temperature') {
            return `°${temperatureUnit === 'FAHRENHEIT' ? 'F' : 'C'}`;
        }
        if (metricType === 'humidity' || metricType === 'percentage') {
            return '%';
        }
        return unit || '';
    };

    return {
        getMetricUnit,
    };
};
