import type { ReactNode } from 'react';
import LockedTooltip from 'inputs/locked-tooltip/LockedTooltip';
import VerifiedShield from 'view/shield/VerifiedShield';

interface InputListButtonProps {
    name: ReactNode;
    onClick: VoidFunction;
    hideShield?: boolean;
    locked?: boolean;
    children?: ReactNode;
}

const InputListButton = ({
    name,
    onClick,
    children,
    hideShield = false,
    locked = false,
}: InputListButtonProps) => (
    <button
        className="bare-btn flex w-full bordered-t py-2 px-5 text-left"
        onClick={() => !locked && onClick()}
    >
        <span className="mr-2 w-7/12 whitespace-normal">{name}</span>
        <span className="w-1/6 flex gap-1">
            {!hideShield && <VerifiedShield />}
            {locked && <LockedTooltip className="ml-2" />}
        </span>
        {children}
        <i className="ion-chevron-right ml-auto" />
    </button>
);

export default InputListButton;
