import { useSelector } from 'react-redux';
import { MeasurementUnit } from 'lib/MeasurementUnit';
import type { AppState } from 'system/store';
import { selectUserLengthMeasurementUnit } from 'users/user-state';
import { converter } from 'utils/conversion';
import Num from 'view/Num';

interface SpeedValueProps {
    value: number;
    round?: boolean;
}

const SpeedValue = ({ value, round = false }: SpeedValueProps) => {
    const lengthMeasurementUnit = useSelector<AppState, MeasurementUnit>(
        selectUserLengthMeasurementUnit
    );

    const convertedValue =
        lengthMeasurementUnit === MeasurementUnit.IMPERIAL
            ? converter.convertKilometresPerHourToMilesPerHour(value)
            : value;

    return <Num value={round ? Math.round(convertedValue) : convertedValue} />;
};

export default SpeedValue;
