import Button from '@fieldmargin/webapp-styling/components/button';
import { useMarkHintSeenOnMount } from 'lib/firebase/firebase-hints-api';
import Modal from 'modal/Modal';

interface YieldTotalWarningModalProps {
    onClose: VoidFunction;
}

const YieldTotalWarningModal = ({ onClose }: YieldTotalWarningModalProps) => {
    useMarkHintSeenOnMount('yield-total-change-warning');
    return (
        <Modal onClose={onClose}>
            <div className="bg-white p-6 w-[400px] text-center">
                <h2 className="uppercase">Are you sure you want to change the total?</h2>
                <p>
                    Making changes to the total will also update the yields of all the fields on
                    this job.
                </p>
                <p>Do you want to continue?</p>
                <div className="flex justify-center gap-4">
                    <Button onClick={onClose} variant="danger-outline">
                        No, cancel
                    </Button>
                    <Button onClick={onClose}>Yes, change</Button>
                </div>
            </div>
        </Modal>
    );
};

export default YieldTotalWarningModal;
