import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { OperationType } from 'operations/Operation';
import type { AppState } from 'system/store';
import type { FilterDateType, LooseJobsActivityType } from 'types';

interface InitalState {
    listMode: string;
    noteSearchString: string;
    filterByScreen: boolean;
    filterByUserIds: Array<number>;
    filterByJobs: Array<string>;
    filterByTodo: boolean;
    filterByNotes: boolean;
    dateTypes: Array<FilterDateType>;
    dateRange: Array<string | undefined>;
}

type FilterByJobsGroup = Pick<InitalState, 'filterByJobs' | 'filterByNotes' | 'filterByTodo'>;

const initialState: InitalState = {
    listMode: 'all',
    noteSearchString: '',
    filterByScreen: false,
    filterByUserIds: [],
    filterByJobs: [],
    filterByTodo: false,
    filterByNotes: false,
    dateTypes: [],
    dateRange: [],
};

const allJobs = Object.keys(OperationType);
const ALL = 'All';

const filtersSlice = createSlice({
    name: 'noteFilters',
    initialState,
    reducers: {
        updateActiveJobs: (state, action: PayloadAction<LooseJobsActivityType>) => {
            const value = action.payload;

            if (value === ALL.toLocaleLowerCase()) {
                state.listMode = ALL.toLocaleLowerCase();
            } else {
                state.listMode = 'tasks';
            }
        },

        updateNotesSearch: (state, action: PayloadAction<string>) => {
            state.noteSearchString = action.payload;
        },

        toggleFilterByScreen: (state) => {
            state.filterByScreen = !state.filterByScreen;
        },

        updateUserIds: (state, action: PayloadAction<number[]>) => {
            const userIds = action.payload;

            state.filterByUserIds = userIds;
        },

        updateFilterByJobs: (state, action: PayloadAction<string[]>) => {
            const jobs = action.payload;
            if (action.payload.includes(ALL) && !state.filterByJobs.includes(ALL)) {
                state.filterByJobs = allJobs.concat(ALL);
            } else if (!action.payload.includes(ALL) && state.filterByJobs.includes(ALL)) {
                state.filterByJobs = [];
            } else {
                state.filterByJobs = jobs;
            }
        },

        toggleFilterTodo: (state) => {
            state.filterByTodo = !state.filterByTodo;
        },

        toggleFilterNotes: (state) => {
            state.filterByNotes = !state.filterByNotes;
        },

        updateFilterByDateTypes: (state, action: PayloadAction<Array<FilterDateType>>) => {
            state.dateTypes = action.payload;
        },

        updateDateRangeStart: (state, action: PayloadAction<string>) => {
            if (state.dateRange[0] && state.dateRange[0] === action.payload) {
                state.dateRange[0] = undefined;
            } else {
                state.dateRange[0] = action.payload;
            }
        },

        updateDateRangeEnd: (state, action: PayloadAction<string>) => {
            if (state.dateRange[1] && state.dateRange[1] === action.payload) {
                state.dateRange[1] = undefined;
            } else {
                state.dateRange[1] = action.payload;
            }
        },

        resetNotesFilters: () => {
            return initialState;
        },
    },
});

export const notesReducer = filtersSlice.reducer;

export const {
    updateActiveJobs,
    updateNotesSearch,
    resetNotesFilters,
    toggleFilterByScreen,
    updateUserIds,
    updateFilterByJobs,
    toggleFilterTodo,
    toggleFilterNotes,
    updateFilterByDateTypes,
    updateDateRangeStart,
    updateDateRangeEnd,
} = filtersSlice.actions;

export const notesState = (state: AppState) => state.notes;
export const notesSearchState = (state: AppState) => state.notes.noteSearchString;
export const listModeState = (state: AppState) => state.notes.listMode;
export const filterByScreenState = (state: AppState) => state.notes.filterByScreen;
export const usersIdsState = (state: AppState) => state.notes.filterByUserIds;
export const jobsState = (state: AppState) => state.notes.filterByJobs;
export const todoFilterState = (state: AppState) => state.notes.filterByTodo;
export const notesFilterState = (state: AppState) => state.notes.filterByNotes;
export const dateTypesState = (state: AppState) => state.notes.dateTypes;
export const dateRangeState = (state: AppState) => ({
    startDate: state.notes.dateRange[0],
    endDate: state.notes.dateRange[1],
});

export const selectTotalFiltersCount = createSelector([notesState], (filters) => {
    let count = 0;

    for (const key in initialState) {
        // we skip this filters because all three filters needs to be treated as a single count
        if (key === 'filterByJobs' || key === 'filterByTodo' || key === 'filterByNotes') {
            continue;
        }
        if (JSON.stringify(filters[key]) !== JSON.stringify(initialState[key])) {
            count++;
        }
    }

    // Grouped filters so it can be treated as a single count
    const initialFilterGroupState: FilterByJobsGroup = {
        filterByJobs: initialState.filterByJobs,
        filterByTodo: initialState.filterByTodo,
        filterByNotes: initialState.filterByNotes,
    };

    const currentFilterGroupState: FilterByJobsGroup = {
        filterByJobs: filters.filterByJobs,
        filterByTodo: filters.filterByTodo,
        filterByNotes: filters.filterByNotes,
    };

    if (JSON.stringify(initialFilterGroupState) !== JSON.stringify(currentFilterGroupState)) {
        count++;
    }

    return count;
});

export const countJobsFilter = createSelector(
    [jobsState, todoFilterState, notesFilterState],
    (filterByJobs, filterByTodo, filterByNotes) => {
        let count = 0;

        count += filterByJobs.length;

        if (filterByTodo) {
            count += 1;
        }

        if (filterByNotes) {
            count += 1;
        }

        return count;
    }
);

export const activeJobsFilerSelector = createSelector(
    [jobsState, todoFilterState, notesFilterState],
    (filterByJobs, filterByTodo, filterByNotes) => {
        let filtersArray: Array<string> = [];

        if (filterByJobs.includes('All')) {
            filtersArray.push('All');
        } else {
            filtersArray = filtersArray.concat(filterByJobs.filter((job) => job !== 'All'));
        }

        if (filterByTodo) {
            filtersArray.push('Tasks');
        }

        if (filterByNotes) {
            filtersArray.push('Notes');
        }

        return filtersArray;
    }
);
