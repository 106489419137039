import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import c from 'classnames';
import { useFieldState } from 'informed';
import { getOperationTypeName, OperationType } from 'operations/Operation';
import EditButton from 'sidebar/modules/common/EditButton';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import { useSetTutorialStep } from 'tutorials/tutorial-hooks';

import OperationTypeRadioGroup from '../OperationTypeRadioGroup';
interface NewOperationTypeProps {
    field: string;
    editingInitially: boolean;
    onChange: (e: string) => void;
}

const NewOperationType = ({ field, onChange, editingInitially }: NewOperationTypeProps) => {
    const { t } = useTranslation();
    const fieldState = useFieldState<string>(field);
    const [editing, setEditing] = useState(editingInitially);
    const activeTutorialStep = useSelector<AppState, string | null>(
        (state) => state.tutorialsState.activeStep
    );
    const setTutorialStep = useSetTutorialStep();

    const handleChange = (type: string) => {
        setEditing(false);
        onChange(type);
        setTutorialStep('add-fields');
    };
    const editingClasses = !editing ? 'flex justify-between items-center' : 'block';
    return (
        <div
            className={c('NewOperationType bordered-b', editingClasses, {
                forefront: activeTutorialStep === 'choose-type',
            })}
        >
            <SidebarModule noBorder>
                <h4 className="mb-0 flex-grow">{t('field_job_type')}</h4>
            </SidebarModule>
            <SidebarModule noBorder editing={editing}>
                {!editing && (
                    <>
                        <span className="text-base">
                            {getOperationTypeName(OperationType[fieldState.value])}
                        </span>
                        <EditButton className="ml-10" setEditing={setEditing} />
                    </>
                )}
                <div className={editing ? 'block' : 'hidden'}>
                    <OperationTypeRadioGroup field={field} onChange={handleChange} />
                </div>
            </SidebarModule>
        </div>
    );
};

export default NewOperationType;
