import { useTranslation } from 'react-i18next';
import { useFarmUsers } from 'hooks/useFarmUsers';
import { useUserId } from 'hooks/useUserId';
import { updateUserIds, usersIdsState } from 'store/slices/notes.slice';
import { useAppDispatch, useAppSelector } from 'system/store';

import { FilterSelect } from '../FilterSelect';

import styles from './FilterByUsers.module.css';

const MAX_LENGTH = 27;

const truncateString = (str, maxLength) => {
    if (str.length <= maxLength) {
        return str;
    }
    return str.substring(0, maxLength - 3) + '...';
};

function FilterByUsers() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { farmUsers } = useFarmUsers();
    const { userId } = useUserId();
    const usersIds = useAppSelector(usersIdsState);

    const handleUserFilterChange = (userId: string[]) => {
        dispatch(updateUserIds(userId.map(Number)));
    };

    const farmUser = farmUsers.find((user) => user.id === usersIds[0]);
    const farmerFullName = farmUser ? `${farmUser.firstName} ${farmUser.lastName}` : '';

    const label = farmUser
        ? truncateString(farmerFullName, MAX_LENGTH)
        : t('filters_activity_tags_me');

    const buttonLabel = `${label} ${usersIds.length > 1 ? `(+${usersIds.length - 1})` : ''}`;

    return (
        <FilterSelect.Root>
            <FilterSelect.ButtonChevron>{buttonLabel}</FilterSelect.ButtonChevron>
            <FilterSelect.Popover>
                <FilterSelect.Group value={usersIds.map(String)} onChange={handleUserFilterChange}>
                    {farmUsers.map((farmUser) => (
                        <FilterSelect.Checkbox key={farmUser.id} value={`${farmUser.id}`}>
                            <div className={styles.usersGroup}>
                                <span>
                                    {farmUser.id === userId
                                        ? t('filters_activity_tags_me')
                                        : `${farmUser.firstName} ${farmUser.lastName}`}
                                </span>
                                <small>{farmUser.email}</small>
                            </div>
                        </FilterSelect.Checkbox>
                    ))}
                </FilterSelect.Group>
            </FilterSelect.Popover>
        </FilterSelect.Root>
    );
}

export { FilterByUsers };
