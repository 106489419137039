interface FarmUser {
    id: number;
    firstName: string;
    lastName: string;
}

export const getFarmUserName = (farmUser: FarmUser) => `${farmUser.firstName} ${farmUser.lastName}`;

export const getFarmUserDisplayName = (farmUser: FarmUser) =>
    `${farmUser.firstName} ${farmUser.lastName.slice(0, 1).trim()}`;

export const getDisplayName = (farmUsers: FarmUser[], userId: number) => {
    const farmUser = farmUsers.find((farmUser) => farmUser.id === userId);
    return farmUser
        ? (farmUser.firstName + ' ' + farmUser.lastName.slice(0, 1)).trim()
        : 'Unknown farmer';
};

export const getInitials = (farmUser: FarmUser) =>
    `${farmUser.firstName[0]}${farmUser.lastName[0]}`;
