import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import c from 'classnames';
import { useLoadOnboardingTutorials } from 'onboarding/onboarding-hooks';
import { useAppDispatch, useAppSelector } from 'system/store';
import { changeModal } from 'system/ui-state';

import tabIcons from './tab-icons';

import './Tab.css';

const TutorialsTab = () => {
    const { t } = useTranslation();
    const modal = useAppSelector((state) => state.uiState.get('modal'));
    const isActive = modal === 'tutorials';
    const dispatch = useAppDispatch();
    const tutorials = useAppSelector((state) => state.tutorialsState.tutorials);
    const showTutorials = tutorials !== null;
    const loadOnboardingTutorials = useLoadOnboardingTutorials();

    const handleClick = async (e: MouseEvent) => {
        e.preventDefault();
        const selectedTutorials = await loadOnboardingTutorials();
        dispatch(changeModal({ name: 'tutorials', props: { selectedTutorials } }));
    };
    const content = t('hamburger_menu_tutorial');

    return (
        showTutorials && (
            <Link
                to="#"
                className={c('Tab TutorialTab py-6', { active: isActive })}
                onClick={handleClick}
            >
                {tabIcons('tutorials', isActive ? 'white' : 'dark-blue')}
                <div className="tooltip">{content}</div>
                <span>{content}</span>
            </Link>
        )
    );
};

export default TutorialsTab;
