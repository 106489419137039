import type { ChangeEvent } from 'react';
import type { Set } from 'immutable';
import {
    all,
    always,
    any,
    complement,
    converge,
    defaultTo,
    either,
    equals,
    includes,
    isEmpty,
    isNil,
    lensPath,
    lt,
    map,
    not,
    or,
    otherwise,
    pipe,
    prop,
    sortBy,
    values,
    view,
} from 'ramda';
import type { ActionCreator, Dispatch } from 'redux';

import { parseNumeric, parseNumericOrZero } from './util/number';

/**
 * Curried wrapper for Array.prototype.map
 */
export const mapper =
    <ItemIn, ItemOut>(mapFn: (item: ItemIn) => ItemOut) =>
    (args: ItemIn[]) =>
        args.map(mapFn);

/**
 * Takes a map function and returns a function that takes an object and maps its values by
 * applying the map function.
 */
export const mapObject =
    <Value = any>(mapFn: (value: Value) => Value) =>
    (obj: Record<string, Value>) =>
        Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, mapFn(value)]));

/**
 * Filters an object by applying the given filter function to its keys.
 */
export const filterObjectByKey = <Value = any>(
    filterFn: (key: string) => boolean,
    obj: Record<string, Value>
): Record<string, Value> =>
    Object.fromEntries(Object.entries(obj).filter(([key]) => filterFn(key)));

/**
 * Convert an array into a native JavaScript Map, using the given key function to generate the keys.
 */
export const arrayToMap = <Item, Key>(list: Item[], keyFn: (item: Item) => Key) =>
    new Map(list.map((item) => [keyFn(item), item]));

/**
 * Utility function to turn an array of items with a UUID into a native JavaScript Map keyed by
 * the UUID.
 */
export const arrayToMapWithUuid = <Item extends { uuid: string }>(list: Item[]) =>
    arrayToMap(list, getUuid);

/**
 * Map over a native JavaScript Map.
 */
export const mapMap = <Key, Value, NewValue = Value>(
    map: Map<Key, Value>,
    mapFn: (value: Value) => NewValue
) => new Map([...map].map(([key, value]) => [key, mapFn(value)]));

/**
 * Filter a native JavaScript Map.
 */
export const filterMap = <Key, Value>(map: Map<Key, Value>, filterFn: (value: Value) => boolean) =>
    new Map([...map].filter(([, value]) => filterFn(value)));

/**
 * Merge two native JavaScript Maps of the same type.
 */
export const mergeMaps = <Key, Value>(a: Map<Key, Value>, b: Map<Key, Value>) =>
    new Map<Key, Value>([...a, ...b]);

/**
 * Set a new value in a native JavaScript Map.
 * This function returns a new Map instance unlike Map.prototype.set which mutates the original.
 */
export const mapSet = <Key, Value>(map: Map<Key, Value>, key: Key, value: Value) =>
    new Map([...map, [key, value]]);

/**
 * Get a valuue from a native JavaScript Map or return the given default value.
 */
export const mapGetOr = <Key, Value>(map: Map<Key, Value>, key: Key, defaultValue: Value) =>
    defaultTo(defaultValue, map.get(key));

/**
 * Deleta a value in a native JavaScript Map.
 * This function returns a new Map instance unlike Map.prototype.delete which mutates the original.
 */
export const mapDelete = <Key, Value>(map: Map<Key, Value>, key: Key) => {
    const newMap = new Map([...map]);
    newMap.delete(key);
    return newMap;
};

/**
 * Function that maps object values from string to numbers. Useful when working with forms.
 */
export const stringToNumberMapper: (o: Record<string, string>) => Record<string, number> =
    map(parseNumeric);

/**
 * Helper for returning value after redux dispatch
 */
export const dispatchAndReturn =
    <Item>(dispatch: Dispatch, action: ActionCreator<any>) =>
    (item: Item) => {
        dispatch(action(item));
        return item;
    };

/**
 * Calls the given function if there is data, otherwise returns a rejected promise.
 */
export const possibleApiRequest = <Response>(data: object, onRequest: () => Promise<Response>) =>
    Object.keys(data).length > 0 ? onRequest() : Promise.reject(undefined);

export const greaterThanZero = lt(0);
export const notNil: <T>(value: T | null | undefined) => value is T = complement(isNil);
export const notEmpty = complement(isEmpty);
export const isEmptyOrNil = either(isNil, isEmpty);

export const inputChangeValueLens = lensPath<ChangeEvent<HTMLInputElement>, string>([
    'target',
    'value',
]);

export const getNumericInputChangeValue = pipe<[ChangeEvent<HTMLInputElement>], string, number>(
    view(inputChangeValueLens),
    parseNumericOrZero
);

export const notEquals = <T>(value: T) => pipe(equals(value), not);

export const uuidMatches =
    <T extends { uuid?: string }>(uuid: string) =>
    (obj: T) =>
        obj.uuid === uuid;

export const notUuidMatches =
    <T extends { uuid?: string }>(uuid: string) =>
    (obj: T) =>
        obj.uuid !== uuid;

export const notArchived = <Item extends { archived: boolean }>(item: Item) => !item.archived;

export const emptyOrIncludes = (items: string[], value: string) =>
    converge<string[], [(s: string[]) => boolean, (s: string[]) => boolean]>(or, [
        isEmpty,
        includes(value),
    ])(items);

export const allPropsEmpty: (obj?: { [k: string]: any }) => boolean = pipe(
    defaultTo({}),
    values,
    all(isEmpty)
);

export const fetchOr = <PromiseReturn, DefaultReturn = PromiseReturn>(
    promise: Promise<PromiseReturn>,
    defaultVal: DefaultReturn
) => otherwise(always(defaultVal), promise);

export const anyTrue = any<boolean>(equals(true));
export const allTrue = all<boolean>(equals(true));

export const setContains =
    <T>(set: Set<T>) =>
    (value: T) =>
        set.contains(value);

export const defaultToZero = defaultTo(0);
export const defaultToUndefined = defaultTo(undefined);
export const defaultToNull = defaultTo(null);
export const defaultToEmptyArray = defaultTo([]);
export const defaultToEmptyString = defaultTo('');

export const getUuid = prop('uuid');

export const sortByDate = sortBy(prop('date'));
