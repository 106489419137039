import { clsx } from 'clsx';

import './SidebarModule.css';

interface SidebarModuleProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    editing?: boolean;
    noBorder?: boolean;
}

const SidebarModule = ({
    children,
    editing = false,
    noBorder = false,
    className,
    ...props
}: SidebarModuleProps) => {
    return (
        <div
            className={clsx('SidebarModule', className, {
                editing,
                'no-border': noBorder,
            })}
            {...props}
        >
            {children}
        </div>
    );
};

export default SidebarModule;
