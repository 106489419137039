import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import DateAndTimePicker from 'sidebar/modules/date/DateAndTimePicker';
import type { SingleParamVoidFunction } from 'system/types';

interface DateAndTimeFieldProps {
    field: string;
    validate?: any;
    openDirection: 'top' | 'bottom';
    allowDelete?: boolean;
    initialValue?: Date | null;
    maxDate?: Date;
    className?: string;
    onChange?: SingleParamVoidFunction<Date>;
    classWithDate?: string;
    withoutIcon?: boolean;
    dateFormat?: string;
}

const DateAndTimeField = ({
    field,
    validate,
    openDirection,
    allowDelete,
    initialValue,
    maxDate,
    className,
    onChange,
    classWithDate,
    withoutIcon,
    dateFormat,
}: DateAndTimeFieldProps) => {
    const { control } = useFormContext();
    return (
        <Controller
            control={control}
            name={field}
            defaultValue={initialValue}
            rules={validate}
            render={({ field: { onChange: formChange, value }, fieldState: { error } }) => (
                <div className={classNames(className)}>
                    <DateAndTimePicker
                        value={value}
                        allowDelete={allowDelete}
                        onChange={(dateTime: Date) => {
                            formChange(dateTime);
                            onChange && onChange(dateTime);
                        }}
                        openDirection={openDirection || 'top'}
                        maxDate={maxDate}
                        classWithDate={classWithDate}
                        withoutIcon={withoutIcon}
                        dateFormat={dateFormat}
                    />
                    {error && <div className="field-error">{error.message}</div>}
                </div>
            )}
        />
    );
};

export default DateAndTimeField;
