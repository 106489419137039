import type { Extent, GeoPosition } from '@fieldmargin/webapp-geo';
import {
    concatExtents,
    createGeoFeatureWithId,
    extentFromGeo,
    GeoFeature,
    GeoPoint,
} from '@fieldmargin/webapp-geo';
import type { ViewportAction } from '@fieldmargin/webapp-ol-map';
import type { List } from 'immutable';
import { Map } from 'immutable';

import type { Place } from './create-farm-api';
import type CreateFarmState from './CreateFarmState';

/**
 * If there is a farm location convert it to a GeoFeature otherwise return null
 */
export const getFarmLocationGeoFeature = (farmLocation?: GeoPosition) => {
    if (farmLocation) {
        return createGeoFeatureWithId(
            GeoPoint({
                coordinates: farmLocation,
            }),
            Map({
                colour: '#288adb',
                pointScale: '0.3',
            })
        );
    }
    return null;
};

/**
 * Convert searched places, if any, to GeoFeature objects.
 * Selected places will have a different colour dot if there are multiple results.
 */
export const getSearchedPlacesGeoFeatures = (
    searchedPlaces?: List<Place>,
    selectedPlaceId?: string
): GeoFeature[] => {
    if (searchedPlaces) {
        return searchedPlaces
            .map((place) => {
                const selected = selectedPlaceId === place.id;
                return GeoFeature({
                    id: place.id,
                    geometry: GeoPoint({
                        coordinates: place.position,
                    }),
                    properties: Map({
                        colour: selected ? '#288adb' : '#b3b3b3',
                        pointScale: '0.3',
                    }),
                });
            })
            .toArray();
    }
    return [];
};

/**
 * Convert the searched places to an extent that a map can be zoomed to.
 */
export const getSearchedPlacesExtent = (searchedPlaces: List<Place>) => {
    const searchedPlacesPoints = searchedPlaces.map((place) =>
        GeoPoint({ coordinates: place.position })
    );
    const extents = searchedPlacesPoints
        .map((point) => extentFromGeo(point))
        .filter((e) => e) as List<Extent>;
    return concatExtents(extents) as Extent;
};

/**
 * Create a valid ViewportAction for zooming the map to an extent
 */
export const getSearchedPlacesViewportAction = (extent: Extent, id: number): ViewportAction => {
    return {
        id,
        position: {
            extent,
            maxZoom: 16,
        },
    };
};

/**
 * When searched places are returned:
 *
 * - if there is a single place set the farm location and selected place id to the place
 * - if there are multiple places clear the farm location to wipe any previous clicks the user
 *   may have made on the map
 */
export const searchedPlacesReducer = (places: List<Place>, state: CreateFarmState) => {
    let nextState = state
        .set('searchedPlaces', places)
        .set('selectedPlaceId', undefined)
        .set('farmLocationFieldError', false);
    if (places.size === 1) {
        const place = places.get(0) as Place;
        nextState = nextState.set('selectedPlaceId', place.id).set('farmLocation', place.position);
    } else {
        nextState = nextState.set('farmLocation', undefined);
    }
    return nextState;
};

/**
 * When the browser has returned the users geo location, set this to the farm location.
 */
export const geoLocationLookupReducer = (geoPosition: GeoPosition, state: CreateFarmState) => {
    return state
        .set('farmLocation', geoPosition)
        .set('userGeoPosition', geoPosition)
        .set('farmLocationFieldError', false);
};

/**
 * Handle basemap click.
 * In this case the farmLocation should be set to the new location and any selected places should
 * be unset to avoid multiple blue dots on the map.
 */
export const basemapClickReducer = (point: GeoPoint, state: CreateFarmState) => {
    return state
        .set('farmLocation', point.coordinates)
        .set('selectedPlaceId', undefined)
        .set('farmLocationFieldError', false);
};

/**
 * Handle feature clicks. Features are searched places.
 * In this case both the farmLocation and the selectedPlaceId should be set to the clicked feature,
 * if there is a matching searched place for it.
 */
export const featureClickReducer = (placeId: string, state: CreateFarmState) => {
    const clickedPlace = state.searchedPlaces
        ? state.searchedPlaces.find((place) => place.id === placeId)
        : undefined;
    if (clickedPlace) {
        return state
            .set('selectedPlaceId', clickedPlace.id)
            .set('farmLocation', clickedPlace.position)
            .set('farmLocationFieldError', false);
    }
    return state;
};
