import { Record } from 'immutable';
import { MeasurementUnit } from 'lib/MeasurementUnit';
import { isEqual } from 'lodash';
import { acresRateToHectaresRate } from 'utils/conversion';
import { v4 } from 'uuid';

const Recording = Record({
    uuid: '',
    operationUuid: '',
    inputUuid: '',
    rate: -1,
    version: -1,
    archived: false,
});
interface Recording extends ReturnType<typeof Recording> {}

export interface RecordingDTO {
    uuid?: string;
    operationUUID: string;
    operationInputUUID: string;
    rateHa: number;
    version: number;
    archived?: boolean;
}

export const deserializeRecording = (json: RecordingDTO) =>
    Recording({
        uuid: json.uuid as string,
        operationUuid: json.operationUUID,
        inputUuid: json.operationInputUUID,
        rate: json.rateHa,
        version: json.version,
        archived: json.archived,
    });

export const serializeRecording = (recording: Recording): RecordingDTO => {
    const dto: RecordingDTO = {
        operationUUID: recording.operationUuid,
        operationInputUUID: recording.inputUuid,
        rateHa: recording.rate,
        version: recording.version,
        archived: recording.archived,
    };
    if (recording.uuid) {
        dto.uuid = recording.uuid;
    }
    return dto;
};

export const createNewRecording = (
    rate: number,
    operationUuid: string,
    inputUuid: string,
    userMeasurementUnit: MeasurementUnit
) =>
    Recording({
        uuid: v4(),
        operationUuid: operationUuid,
        inputUuid: inputUuid,
        rate:
            userMeasurementUnit === MeasurementUnit.IMPERIAL ? acresRateToHectaresRate(rate) : rate,
    });

export const hasRecordingChanged = (first: Recording, second: Recording) =>
    !isEqual(first.toJS(), second.toJS());

export default Recording;
