import type { ComponentProps } from 'react';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { getActivityMediaThumbUrl } from 'activity/activity-helper';
import { createOperationChatLog } from 'chat/chat-generator';
import icon from 'components/icons/icon';
import { SidebarCardsWrapper } from 'components/sidebar-cards-wrapper';
import { selectIsSelectingFields, stopEditing } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { useOperationDetailsActions } from 'hooks/actions';
import { useOperationDetailsSelector } from 'hooks/selectors';
import { useOperationFieldsSelector } from 'hooks/selectors/src/useOperationFieldsSelector';
import { useCurrentOperation } from 'hooks/useCurrentOperation';
import configService from 'lib/config';
import { curry } from 'lodash';
import { useOnboarding } from 'onboarding/onboarding-state';
import type FullOperation from 'operations/FullOperation';
import { isOperationHarvesting } from 'operations/Operation';
import { deleteOperationMediaApi, markOperationReadApi } from 'operations/operations-api';
import { saveOperation } from 'operations/operations-save-helper';
import { removeOperationMedia, setOperation, setOperationRead } from 'operations/operations-state';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import type { CanBeUndefined } from 'types';

import NewOutputForm from '../outputs/NewOutputForm';

import EditingOperationFieldSelection from './fields/EditingOperationFieldSelection';
import EditableInputsSelector from './recording/EditableInputsSelector';
import OperationDetailsTitleAndType from './OperationDetailsTitleAndType';
import OperationDetailTabs from './OperationDetailTabs';
import OperationOutputView from './OperationOutputView';
import OperationStatusView from './OperationStatusView';
import type { EditableOperationDetailsSchema } from './useEditableOperationDetailsContext';

interface EditableOperationDetailsBaseProps {
    farm: Farm;
    fullOperation: FullOperation;

    setOperation: typeof setOperation;
    removeOperationMedia: typeof removeOperationMedia;
    setOperationRead: typeof setOperationRead;
    stopEditing: typeof stopEditing;

    isAttachingFieldsToActivity: boolean;

    changeModal: typeof changeModal;
}

type EditableOperationDetailsProps = CanBeUndefined<
    ComponentProps<typeof EditableOperationDetailsBase>,
    'fullOperation'
>;

const EditableOperationDetailsBase = ({
    farm,
    fullOperation,
    setOperation,
    removeOperationMedia,
    setOperationRead,
    stopEditing,
    isAttachingFieldsToActivity,
    changeModal,
}: EditableOperationDetailsBaseProps) => {
    const { t } = useTranslation();
    const { nextOnboarding } = useOnboarding();

    const { operationFields } = useOperationFieldsSelector();
    const { handleToggleAddingOutput } = useOperationDetailsActions();
    const { isAddingOutput, isAttachingRecordings, isAttachingFuel } =
        useOperationDetailsSelector();

    // Mark operation as read on mount and unmount.
    useEffect(() => {
        markOperationReadApi(fullOperation.summary).then((nextOperation) => {
            setOperationRead(nextOperation.uuid);
        });

        nextOnboarding('end');

        return () => {
            markOperationReadApi(fullOperation.summary).then((nextOperation) => {
                setOperationRead(nextOperation.uuid);
            });
            stopEditing();
        };
    }, []);

    const handleSaveOperation = async (newFullOperation: FullOperation) => {
        setOperation(await saveOperation(farm.uuid, newFullOperation));
    };

    const handleDeleteMedia = (mediaId: string) => {
        return deleteOperationMediaApi(fullOperation.summary, mediaId).then(() => {
            removeOperationMedia({ operation: fullOperation.summary, mediaId });
        });
    };

    const chatLog = createOperationChatLog(
        fullOperation,
        curry(getActivityMediaThumbUrl)(configService, farm, fullOperation),
        (imageIndex) => {
            changeModal({
                name: 'activity-media',
                props: {
                    initialMediaIndex: imageIndex,
                    media: fullOperation.media?.toArray() ?? [],
                    onDelete: handleDeleteMedia,
                },
            });
        }
    );

    const totalFieldsArea = operationFields.reduce((total, opField) => total + opField.areaSqm, 0);
    const isHarvesting = isOperationHarvesting(fullOperation.summary);

    return (
        <Fragment>
            {(isAttachingRecordings || isAttachingFuel) && (
                <EditableInputsSelector fullOperation={fullOperation} totalArea={totalFieldsArea} />
            )}

            {isAddingOutput && (
                <NewOutputForm
                    onDone={(output) => {
                        handleSaveOperation(
                            fullOperation.setIn(['summary', 'outputUuid'], output.uuid)
                        ).then(handleToggleAddingOutput);
                    }}
                />
            )}
            {isAttachingFieldsToActivity && (
                <EditingOperationFieldSelection fullOperation={fullOperation} />
            )}
            <div className="EditableOperationDetails bg-sidebar-gray-bg scrollable">
                <div className="non-scrolling">
                    <SidebarHeader className="relative">
                        <SidebarHeaderLink returning to={`/farms/${farm.uuid}/notes`}>
                            {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                        </SidebarHeaderLink>
                    </SidebarHeader>
                </div>
                <SidebarCardsWrapper>
                    <OperationDetailsTitleAndType
                        fullOperation={fullOperation}
                        onSave={handleSaveOperation}
                    />
                    {isHarvesting && (
                        <OperationOutputView
                            fullOperation={fullOperation}
                            onAddOutput={handleToggleAddingOutput}
                            onSave={handleSaveOperation}
                        />
                    )}
                    <OperationStatusView fullOperation={fullOperation} />
                    <OperationDetailTabs />
                    <Outlet
                        context={
                            {
                                isHarvesting,
                                fullOperation,
                                chatLog,
                                operationFields,
                                totalFieldsArea,
                                handleSaveOperation,
                            } satisfies EditableOperationDetailsSchema
                        }
                    />
                </SidebarCardsWrapper>
            </div>
        </Fragment>
    );
};

function EditableOperationDetails({ ...props }: EditableOperationDetailsProps) {
    const { currentOperation } = useCurrentOperation();
    return !currentOperation ? (
        <Navigate to={`/farms/${props.farm.uuid}/notes`} replace />
    ) : (
        <EditableOperationDetailsBase {...props} fullOperation={currentOperation} />
    );
}

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        fields: state.fieldsState.fields,
        isAttachingFieldsToActivity: selectIsSelectingFields(state),
    }),
    (dispatch) =>
        bindActionCreators(
            { setOperation, changeModal, removeOperationMedia, setOperationRead, stopEditing },
            dispatch
        )
)(EditableOperationDetails);
