import type { InputUnit } from 'inputs/Input';

import type { NutrientReport } from './NutrientReport';
import NutrientReportFieldRow from './NutrientReportFieldRow';

interface NutrientReportRowProps {
    nutrientReport: NutrientReport;
    selectedNutrients: string[];
    nutrientUnits: Record<string, InputUnit[]>;
    usageColSpan: number;
}

const NutrientReportRow = ({
    nutrientReport,
    selectedNutrients,
    nutrientUnits,
    usageColSpan,
}: NutrientReportRowProps) => {
    return (
        <>
            <tr>
                <td colSpan={usageColSpan} className="bg-gray-200">
                    {nutrientReport.fieldUsage}
                </td>
            </tr>
            {nutrientReport.fieldNutrient.map((fieldNutrient, i) => (
                <NutrientReportFieldRow
                    key={i}
                    fieldNutrient={fieldNutrient}
                    selectedNutrients={selectedNutrients}
                    nutrientUnits={nutrientUnits}
                />
            ))}
        </>
    );
};

export default NutrientReportRow;
