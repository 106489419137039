import icon from 'components/icons/icon';
import { OperationRecord } from 'components/operation-record';
import type Input from 'inputs/Input';
import { inputHasNutrients, inputIsPesticide } from 'inputs/Input';
import { InputNutrientsList } from 'inputs/InputNutrients';
import { capitaliseWord } from 'lib/util/text';
import EditButton from 'sidebar/modules/common/EditButton';
import FormattedRate from 'view/units/FormattedRate';
import ShortAreaUnit from 'view/units/ShortAreaUnit';
import ShortUnit from 'view/units/ShortUnit';

import OperationRecordingCardFertiliserInfo from './OperationRecordingCardFertiliserInfo';
import OperationRecordingCardSprayInfo from './OperationRecordingCardSprayInfo';

interface OperationRecordingCardViewProps {
    input: Input;
    rate: number;
    total: number;
    area: number;
    toggleEditing: VoidFunction;
    onRemoveClick: VoidFunction;
}

const OperationRecordingCardView = ({
    input,
    rate,
    total,
    area,
    toggleEditing,
    onRemoveClick,
}: OperationRecordingCardViewProps) => {
    return (
        <OperationRecord.Details>
            <OperationRecord.Summary>
                <OperationRecord.SummaryName>
                    <h4 className="mb-1 truncate">{input.name}</h4>
                    <InputNutrientsList as="p" input={input} />
                </OperationRecord.SummaryName>

                <OperationRecord.SummaryType>
                    {capitaliseWord(input.type)}
                </OperationRecord.SummaryType>

                <OperationRecord.SummaryRate>
                    <FormattedRate rate={rate} /> <ShortUnit unit={input.unit} />/<ShortAreaUnit />
                </OperationRecord.SummaryRate>

                <OperationRecord.SummaryTotal>
                    <FormattedRate rate={total} /> <ShortUnit unit={input.unit} />
                </OperationRecord.SummaryTotal>

                <OperationRecord.SummaryChevron />
            </OperationRecord.Summary>

            <div>
                {inputIsPesticide(input) && <OperationRecordingCardSprayInfo input={input} />}
                {inputHasNutrients(input) && (
                    <OperationRecordingCardFertiliserInfo input={input} rate={rate} area={area} />
                )}
                <OperationRecord.Actions>
                    <EditButton setEditing={toggleEditing} className="font-bold float-none" />

                    <button onClick={onRemoveClick} className="bare-btn focus">
                        {icon('remove', 'red')}
                    </button>
                </OperationRecord.Actions>
            </div>
        </OperationRecord.Details>
    );
};

export default OperationRecordingCardView;
