import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import icon from 'components/icons/icon';
import type Feature from 'features/Feature';
import type { List } from 'immutable';
import { getItemsSizes } from 'lib/geo/maths';
import { getSizesOfGeometry } from 'lib/geo/shape-size-cacher';
import { compareName } from 'lib/util/text';
import type { SingleParamVoidFunction } from 'system/types';
import BubbleTag from 'view/molecules/BubbleTag';
import FormattedGeoData from 'view/units/FormattedGeoData';

interface NoteFeaturesBubblesProps extends PropsWithChildren {
    // From parent
    features: List<Feature>;
    onRemove?: SingleParamVoidFunction<string>;
}

const NoteFeaturesBubbles = ({ features, onRemove, children }: NoteFeaturesBubblesProps) => {
    const { t } = useTranslation();
    const sizes = getItemsSizes(features.toArray());
    return (
        <>
            <p className="mb-2">
                {t('create_note_attached_feature')} <FormattedGeoData sizes={sizes} />
            </p>
            <div
                className={c('flex items-start flex-wrap gap-3', {
                    'mb-3': children !== undefined,
                })}
            >
                {features.sort(compareName).map((feature) => (
                    <NoteFeatureBubble key={feature.uuid} feature={feature} onRemove={onRemove} />
                ))}
            </div>
            {children}
        </>
    );
};

export default NoteFeaturesBubbles;

const NoteFeatureBubble = ({
    feature,
    onRemove,
}: {
    feature: Feature;
    onRemove?: SingleParamVoidFunction<string>;
}) => {
    const sizes = getSizesOfGeometry(feature.uuid, feature.geoJson);
    return (
        <BubbleTag>
            <span className="truncate">{feature.name}:</span>&nbsp;
            <FormattedGeoData sizes={sizes} />
            {onRemove && (
                <div
                    className="ml-3 cursor-pointer flex-shrink-0"
                    onClick={() => {
                        onRemove(feature.uuid);
                    }}
                >
                    {icon('edit-remove', 'red', undefined, { marginTop: 0 })}
                </div>
            )}
        </BubbleTag>
    );
};
