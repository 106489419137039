import type { FarmUserRole } from '@fieldmargin/webapp-farms';
import { removePendingFarmInvite, updateFarmUserInvite } from '@fieldmargin/webapp-farms';
import type { PendingFarmInvite } from '@fieldmargin/webapp-farms/dist/PendingFarmInvite';
import { selectCurrentFarm } from 'farms/farms-state';
import { getDisplayName } from 'lib/util/names';
import { useAppDispatch, useAppSelector } from 'system/store';

import { removePendingInvite, updatePendingInvite } from './farm-users-state';

/**
 * Returns a function that can be used to get the display name of a farm user.
 * Saves having to pass the farm users list around as a prop.
 */
export const useFarmUserDisplayName = () => {
    const farmUsers = useAppSelector((state) => state.farmUsersState.farmUsers) ?? [];
    return (userId: number) => getDisplayName(farmUsers, userId);
};

export const useRemovePendingInvite = () => {
    const dispatch = useAppDispatch();
    const farm = useAppSelector(selectCurrentFarm);

    return (pendingInvite: PendingFarmInvite) =>
        removePendingFarmInvite(farm.uuid, pendingInvite.userInviteUUID).then(() =>
            dispatch(removePendingInvite(pendingInvite.uuid))
        );
};

export const useUpdatePendingInvite = (pendingInvite: PendingFarmInvite) => {
    const dispatch = useAppDispatch();
    const farm = useAppSelector(selectCurrentFarm);

    return (role: FarmUserRole) =>
        updateFarmUserInvite(farm.uuid, pendingInvite.userInviteUUID, role).then(
            (updatedInvite) => {
                dispatch(updatePendingInvite(updatedInvite));
            }
        );
};
