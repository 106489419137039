import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm, FarmUser } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import LoadableContent from '@fieldmargin/webapp-styling/components/loadable-content';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { selectFieldsWithFullNames } from 'fields/fields-selectors';
import type { HerdEvent, HerdLocation } from 'herd/Herd';
import type Herd from 'herd/Herd';
import type { List } from 'immutable';
import { arrayToMapWithUuid } from 'lib/fp-helpers';
import type Note from 'notes/Note';
import { addNotes } from 'notes/notes-state';
import type { AppState } from 'system/store';
import { useAppDispatch } from 'system/store';
import { selectDisplayableFarmUsers } from 'team/farm-users-state';
import ErrorMessage from 'view/ErrorMessage';

import type { HerdHistoryItem } from './herd-history-generator';
import { generateHerdHistory, loadHerdHistory } from './herd-history-generator';
import HerdHistoryArchiveItem from './HerdHistoryArchiveItem';
import HerdHistoryMergeItem from './HerdHistoryMergeItem';
import HerdHistoryMoveItem from './HerdHistoryMoveItem';
import HerdHistoryNewFromSplitItem from './HerdHistoryNewFromSplitItem';
import HerdHistoryNoteItem from './HerdHistoryNoteItem';
import HerdHistoryRemoveItem from './HerdHistoryRemoveItem';
import HerdHistorySizeChangeItem from './HerdHistorySizeChangeItem';

interface HerdHistoryProps {
    // From redux
    farm: Farm;
    fields: List<Field>;
    farmUsers: FarmUser[];

    // From parent
    herd: Herd;
}

const HerdHistory = ({ farm, fields, herd, farmUsers }: HerdHistoryProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [herdHistory, setHerdHistory] = useState<HerdHistoryItem[]>();
    const { pending, error, setPromise } = usePromise<{
        herdHistories: HerdEvent[];
        herdLocations: HerdLocation[];
        notes: Note[];
    }>(({ herdHistories, herdLocations, notes }) => {
        dispatch(addNotes(arrayToMapWithUuid(notes)));
        setHerdHistory(generateHerdHistory(herdHistories, herdLocations, fields, notes));
    });

    useEffect(() => {
        setPromise(loadHerdHistory(farm.uuid, herd));
    }, [herd]);

    if (error) {
        return <ErrorMessage className="p-5">{t('something_went_wrong')}</ErrorMessage>;
    }

    const items = herdHistory
        ? herdHistory.map((item, i) => {
              switch (item.type) {
                  case 'new-from-split':
                      return (
                          <HerdHistoryNewFromSplitItem
                              key={i}
                              herd={herd}
                              historyItem={item}
                              farmUsers={farmUsers}
                          />
                      );
                  case 'move':
                      return (
                          <HerdHistoryMoveItem
                              key={i}
                              herd={herd}
                              historyItem={item}
                              farmUsers={farmUsers}
                              canDelete
                          />
                      );
                  case 'remove':
                      return (
                          <HerdHistoryRemoveItem
                              key={i}
                              herd={herd}
                              historyItem={item}
                              farmUsers={farmUsers}
                          />
                      );
                  case 'size-update':
                      return (
                          <HerdHistorySizeChangeItem
                              key={i}
                              herd={herd}
                              historyItem={item}
                              farmUsers={farmUsers}
                          />
                      );
                  case 'archive':
                  case 'unarchive':
                      return (
                          <HerdHistoryArchiveItem
                              key={i}
                              herd={herd}
                              historyItem={item}
                              farmUsers={farmUsers}
                          />
                      );
                  case 'merge':
                      return <HerdHistoryMergeItem key={i} herd={herd} historyItem={item} />;
                  case 'note':
                      return (
                          <HerdHistoryNoteItem key={i} historyItem={item} farmUsers={farmUsers} />
                      );
                  default:
                      null;
              }
          })
        : null;

    return (
        <LoadableContent
            loading={pending}
            delay={300}
            fallback={<em className="p-5">Loading herd history...</em>}
        >
            {herdHistory && herdHistory.length > 0 ? (
                <div className="flex flex-col">
                    {farm.plan.domainPrinting && (
                        <Button
                            as={Link}
                            small
                            className="mx-5 self-end inline-flex items-center"
                            to={`/farms/${farm.uuid}/livestock/${herd.uuid}/history`}
                        >
                            {icon('print', 'white', 'mr-3')}
                            {t('field_history_print')}
                        </Button>
                    )}
                    <ul className="p-5 m-0 list-none text-fm-blue">{items}</ul>
                </div>
            ) : !pending && herdHistory ? (
                <div className="p-5">{t('livestock_details_history_empty_web')}</div>
            ) : null}
        </LoadableContent>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    fields: selectFieldsWithFullNames(state),
    farmUsers: selectDisplayableFarmUsers(state),
}))(HerdHistory);
