import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { findByUserWithOwners } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import { bindActionCreators } from '@reduxjs/toolkit';
import { setFarms } from 'farms/farms-state';
import EnvelopeSvg from 'farms/invites/envelope.svg';
import { useAction } from 'lib/hooks';
import type { AppState } from 'system/store';
import FieldmarginLogo from 'top-nav/FieldmarginLogo';
import HelpNavItem from 'top-nav/HelpNavItem';
import TopNav from 'top-nav/TopNav';
import ErrorMessage from 'view/ErrorMessage';

import type { OrganisationInvite } from './organisation-api';
import { acceptOrganisationInvite, declineOrganisationInvite } from './organisation-api';
import { setOrganisationInvites } from './organisation-state';

import './OrganisationInvites.css';

interface OrganisationInvitesProps {
    invites?: OrganisationInvite[];
    setOrganisationInvites: typeof setOrganisationInvites;
}

const OrganisationInvites = ({
    invites = [],
    setOrganisationInvites,
}: OrganisationInvitesProps) => {
    const setFarmsAction = useAction(setFarms);

    const accept = usePromise<[Farm[], string]>(([farms, inviteUuid]) => {
        setOrganisationInvites(invites.filter((invite) => invite.uuid !== inviteUuid));
        setFarmsAction(farms);
    });
    const decline = usePromise<string>((inviteUuid) => {
        setOrganisationInvites(invites.filter((invite) => invite.uuid !== inviteUuid));
    });

    if (invites.length === 0) {
        return null;
    }

    // We only show one invite at a time.
    const invite = invites[0];

    const handleAccept = () => {
        accept.setPromise(
            acceptOrganisationInvite(invite.organisation.uuid, invite.uuid).then((inviteUuid) =>
                Promise.all([findByUserWithOwners(), inviteUuid])
            )
        );
    };
    const handleDecline = () => {
        decline.setPromise(declineOrganisationInvite(invite.organisation.uuid, invite.uuid));
    };

    const pending = accept.pending || decline.pending;
    const error = accept.error || decline.error;

    return (
        <div className="OrganisationInvites">
            <TopNav>
                <FieldmarginLogo />
                <HelpNavItem className="ml-auto" />
            </TopNav>
            <div className="OrganisationInvitesBody">
                <img
                    src={EnvelopeSvg}
                    className="mx-auto mb-6"
                    alt="An icon showing a closed envelope"
                />
                <h2>You have a pending invite to an organisation</h2>
                <p>
                    You have been invited to join <strong>{invite.organisation.name}</strong>
                </p>
                <div className="OrganisationInvitesActions">
                    <Button onClick={handleAccept} disabled={pending}>
                        Accept
                    </Button>
                    <Button onClick={handleDecline} disabled={pending} variant="danger-outline">
                        Decline
                    </Button>
                </div>
                {error && (
                    <ErrorMessage className="mt-4">
                        Something went wrong, please try again.
                    </ErrorMessage>
                )}
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        invites: state.organisationState.invites,
    }),
    (dispatch) => bindActionCreators({ setOrganisationInvites }, dispatch)
)(OrganisationInvites);
