interface FoldedMapIconProps {
    className?: string;
    fill?: string;
}

const FoldedMapIcon = ({ className, fill = '#4da4da' }: FoldedMapIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" className={className}>
            <path
                style={{ fill }}
                d="M58.5,6.9c-1-1-2.1-1.4-3.4-1.4L38.6,10c0,0-16.8-4.5-17.5-4.5c-0.3,0-18.8,4.8-19.5,5.1
		C1.3,11.3,1,12,1,13v38.3c0,1.7,1.7,3.4,3.4,3.1h0.3L21.5,50l16.8,4.5c0.3,0,14-3.4,18.1-4.5l0,0c1.4-0.3,2.4-1.7,2.4-3.1V8.6
		C59.2,7.9,58.8,7.6,58.5,6.9 M36.5,13.4c0,0,0,3.3,0,2.9v33.5c-5.3-1.5-11.4-3.5-13.5-3.8V10.6 M4.8,14.4l13.9-3.9V46
		c0,0-10.6,3.2-13.9,3.9L4.8,14.4 M40.5,13.8l14.9-3.9v36.8l-14.9,3.9L40.5,13.8z M43.1,15.4L43.1,15.4L43.1,15.4L43.1,15.4z"
            />
        </svg>
    );
};

export default FoldedMapIcon;
