import type { CSSProperties } from 'react';
import c from 'classnames';

interface FogProps {
    blue?: boolean;
    onClick?: VoidFunction;
    className?: string;
    style?: CSSProperties;
}

const Fog = ({ blue = false, onClick, className, style }: FogProps) => (
    <div
        className={c(
            'absolute top-0 bottom-0 right-0 left-0 opacity-75 z-10',
            blue ? 'bg-fm-blue' : 'bg-white',
            className
        )}
        style={style}
        onClick={() => onClick && onClick()}
    />
);

export default Fog;
