import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import c from 'classnames';
import type { FieldProps } from 'informed';
import { Text } from 'informed';

import OptionalLabel from './OptionalLabel';

export interface TextInputFormFieldProps extends FieldProps<any, any> {
    label?: ReactNode;
    error?: string;
    light?: boolean;
    labelClassName?: string;
    inputClassName?: string;
    optional?: boolean;
    focusOnRender?: boolean;
}

const TextInputFormField = ({
    field,
    label,
    type = 'text',
    error,
    light = false,
    labelClassName,
    inputClassName,
    optional = false,
    focusOnRender = false,
    ...props
}: TextInputFormFieldProps) => {
    const ref = useRef<Text<any, any>>(null);

    useEffect(() => {
        if (focusOnRender && ref.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref.current.focus();
        }
    }, []);

    return (
        <div className={c('form-field', props.className)}>
            {label && (
                <label htmlFor={field} className={c(labelClassName)}>
                    {label}
                    {optional && (
                        <>
                            {' '}
                            <OptionalLabel />
                        </>
                    )}
                </label>
            )}
            <Text
                {...props}
                id={field}
                ref={ref}
                field={field}
                type={type}
                className={c('simple-text-input', inputClassName, {
                    'has-error': error,
                    light: !!light,
                })}
                validateOnBlur
                validateOnChange
                autoComplete="off"
            />
            {error && <div className="field-error">{error}</div>}
        </div>
    );
};

export default TextInputFormField;
