import { Option, Select } from 'informed';
import { getUnitLongName } from 'lib/unit';
import ShortUnit from 'view/units/ShortUnit';

import { InputUnit } from './Input';
import type { PesticideData } from './Pesticide';

interface PesticideUnitFieldProps {
    pesticideData?: PesticideData;
}

const PesticideUnitField = ({ pesticideData }: PesticideUnitFieldProps) => {
    if (pesticideData === undefined) {
        return null;
    }

    if (pesticideData.doseUnits.length === 1) {
        return <ShortUnit unit={pesticideData.doseUnits[0]} />;
    }

    const units =
        pesticideData.doseUnits.length === 0 ? Object.keys(InputUnit) : pesticideData.doseUnits;

    return (
        <Select field="unit">
            {units.map((unit) => (
                <Option key={unit} value={unit}>
                    {getUnitLongName(unit)}
                </Option>
            ))}
        </Select>
    );
};

export default PesticideUnitField;
