import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import { selectFarms } from 'farms/farms-state';
import { includes } from 'lodash';
import Modal from 'modal/Modal';
import { useAppSelector } from 'system/store';
import CardCheckboxList from 'view/card-checkbox-list/CardCheckboxList';
import CardCheckboxListItem from 'view/card-checkbox-list/CardCheckboxListItem';
import ErrorMessage from 'view/ErrorMessage';
import FieldsIcon from 'view/svg/FieldsIcon';
import FruitIcon from 'view/svg/FruitIcon';
import GrapesIcon from 'view/svg/GrapesIcon';
import HerdIcon from 'view/svg/HerdIcon';
import VegetablesIcon from 'view/svg/VegetablesIcon';
import WheatIcon from 'view/svg/WheatIcon';

import { FarmType } from './onboarding-api';
import { useSaveOnboardingFarmType } from './onboarding-hooks';

const OnboardingFarmType = () => {
    const { t } = useTranslation();
    const hasMultipleFarms = useAppSelector(selectFarms).size > 1;
    const { pending, setPromise } = usePromise();
    const [farmTypes, setFarmTypes] = useState<FarmType[]>();
    const saveFarmType = useSaveOnboardingFarmType(hasMultipleFarms);

    const toggleFarmType = (farmType: FarmType) => () =>
        setFarmTypes(
            farmTypes === undefined
                ? [farmType]
                : farmTypes.includes(farmType)
                  ? farmTypes.filter((ft) => ft !== farmType)
                  : [...farmTypes, farmType]
        );

    const handleSave = () => farmTypes !== undefined && setPromise(saveFarmType(farmTypes));

    const showError = farmTypes !== undefined && farmTypes.length === 0;
    const nextDisabled = pending || farmTypes === undefined || showError;

    return (
        <Modal allowClose={false}>
            <div className="bg-white rounded p-6 text-center" style={{ width: 600 }}>
                <h2>{t('great_your_farm_has_been_created_now_tell_us_what_type_of_farm_is_it')}</h2>
                <p>{t('label_multiple_choice')}</p>
                <CardCheckboxList>
                    <CardCheckboxListItem
                        value={FarmType.ARABLE_CROPS.toString()}
                        selected={includes(farmTypes, FarmType.ARABLE_CROPS)}
                        onChange={toggleFarmType(FarmType.ARABLE_CROPS)}
                    >
                        <WheatIcon
                            className="w-10"
                            fill={includes(farmTypes, FarmType.ARABLE_CROPS) ? 'white' : undefined}
                        />
                        {t('farm_types_arable')}
                    </CardCheckboxListItem>
                    <CardCheckboxListItem
                        value={FarmType.LIVESTOCK.toString()}
                        selected={includes(farmTypes, FarmType.LIVESTOCK)}
                        onChange={toggleFarmType(FarmType.LIVESTOCK)}
                    >
                        <HerdIcon
                            className="w-10"
                            fill={includes(farmTypes, FarmType.LIVESTOCK) ? 'white' : undefined}
                        />
                        {t('livestock')}
                    </CardCheckboxListItem>
                    <CardCheckboxListItem
                        value={FarmType.VINEYARD.toString()}
                        selected={includes(farmTypes, FarmType.VINEYARD)}
                        onChange={toggleFarmType(FarmType.VINEYARD)}
                    >
                        <GrapesIcon
                            className="w-10"
                            fill={includes(farmTypes, FarmType.VINEYARD) ? 'white' : undefined}
                        />
                        {t('farm_types_vineyard')}
                    </CardCheckboxListItem>
                    <CardCheckboxListItem
                        value={FarmType.FRUIT.toString()}
                        selected={includes(farmTypes, FarmType.FRUIT)}
                        onChange={toggleFarmType(FarmType.FRUIT)}
                    >
                        <FruitIcon
                            className="w-10"
                            fill={includes(farmTypes, FarmType.FRUIT) ? 'white' : undefined}
                        />
                        {t('farm_types_fruit')}
                    </CardCheckboxListItem>
                    <CardCheckboxListItem
                        value={FarmType.VEGETABLES.toString()}
                        selected={includes(farmTypes, FarmType.VEGETABLES)}
                        onChange={toggleFarmType(FarmType.VEGETABLES)}
                    >
                        <VegetablesIcon
                            className="w-10"
                            fill={includes(farmTypes, FarmType.VEGETABLES) ? 'white' : undefined}
                        />
                        {t('farm_types_vegetables')}
                    </CardCheckboxListItem>
                    <CardCheckboxListItem
                        value={FarmType.OTHER.toString()}
                        selected={includes(farmTypes, FarmType.OTHER)}
                        onChange={toggleFarmType(FarmType.OTHER)}
                    >
                        <FieldsIcon
                            className="w-8 mb-1 mt-1"
                            fill={includes(farmTypes, FarmType.OTHER) ? 'white' : undefined}
                        />
                        {t('other')}
                    </CardCheckboxListItem>
                </CardCheckboxList>
                {showError && (
                    <ErrorMessage className="mx-auto">
                        {t('please_select_at_least_one_farm_type')}
                    </ErrorMessage>
                )}
                <Button className="mx-auto" onClick={handleSave} disabled={nextDisabled}>
                    {t(hasMultipleFarms ? 'save' : 'next')}
                </Button>
            </div>
        </Modal>
    );
};

export default OnboardingFarmType;
