/**
 * API calls using the new note API after sync update.
 */

import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { handlePagingRequest } from 'lib/paging';
import type { CreateNoteDTO, NoteDTO, WriteNoteDTO, WriteTaskDTO } from 'notes/Note';
import type Note from 'notes/Note';
import { deserializeNote } from 'notes/Note';
import { always } from 'ramda';

/**
 * Fetch all notes for a farm. This fetches all pages.
 */
export const getNotes = (farmUuid: string, year: number) =>
    handlePagingRequest<NoteDTO>(`/notes-api/farms/${farmUuid}/years/${year}/notes`).then((notes) =>
        notes.map(deserializeNote)
    );

/**
 * Fetch a single note from the server.
 */
export const getNote = (farmUuid: string, year: number, noteUuid: string) =>
    authorizedRequest<NoteDTO>({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}`,
    }).then(deserializeNote);

/**
 * Create a note on the server
 */
export const createNote = (farmUuid: string, year: number, data: CreateNoteDTO) =>
    authorizedRequest<string>({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/`,
        method: 'POST',
        data,
    });

/**
 * Update a note on the server. Only the data that has changed should be sent.
 */
export const updateNote = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    data: Partial<WriteNoteDTO>
) =>
    authorizedRequest<string>({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}`,
        method: 'PUT',
        data,
    }).then(always(data));

/**
 * Delete a note on the server.
 */
export const deleteNote = (farmUuid: string, note: Note) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${note.year}/notes/${note.uuid}`,
        method: 'DELETE',
    }).then(always(note));

/**
 * Add fields to a note on the server.
 */
export const addFieldsToNote = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    fieldUuids: string[]
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/addFields`,
        method: 'PUT',
        data: { aggregateUUIDS: fieldUuids },
    }).then(always(fieldUuids));

/**
 * Remove fields from a note on the server.
 */
export const removeFieldsFromNote = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    fieldUuids: string[]
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/removeFields`,
        method: 'PUT',
        data: { fieldUuids },
    }).then(always(fieldUuids));

/**
 * Add features to a note on the server.
 */
export const addFeaturesToNote = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    featureUuids: string[]
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/addFeatures`,
        method: 'PUT',
        data: { aggregateUUIDS: featureUuids },
    }).then(always(featureUuids));

/**
 * Remove features from a note on the server.
 */
export const removeFeaturesFromNote = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    featureUuids: string[]
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/removeFeatures`,
        method: 'PUT',
        // This is not a typo - the API expects featureUuids to be called fieldUuids :/
        data: { fieldUuids: featureUuids },
    }).then(always(featureUuids));

/**
 * Add herds to a note on the server.
 */
export const addHerdsToNote = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    herdUuids: string[]
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/addHerds`,
        method: 'PUT',
        data: { aggregateUUIDS: herdUuids },
    }).then(always(herdUuids));

/**
 * Remove herds from a note on the server.
 */
export const removeHerdsFromNote = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    herdUuids: string[]
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/removeHerds`,
        method: 'PUT',
        data: { fieldUuids: herdUuids },
    }).then(always(herdUuids));

/**
 * Add users to a note on the server.
 */
export const addUsersToNote = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    userIds: number[]
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/addUsers`,
        method: 'PUT',
        data: { userIds },
    }).then(always(userIds));

/**
 * Remove users from a note on the server.
 */
export const removeUsersFromNote = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    userIds: number[]
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/removeUsers`,
        method: 'PUT',
        data: { userIds },
    }).then(always(userIds));

/**
 * Toggle the archive status of a note on the server.
 */
export const toggleArchiveNote = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    archived: boolean
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/archive`,
        method: 'PUT',
        params: { archived },
    });

/**
 * Mark a note as read
 */
export const markNoteRead = (farmUuid: string, year: number, noteUuid: string) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/markNoteRead`,
        method: 'PUT',
    });

/**
 * Convert a note to a task with an optional due date.
 */
export const createTask = (farmUuid: string, year: number, noteUuid: string, dueDate?: Date) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/task`,
        method: 'POST',
        data: { dueDate: dueDate ? dueDate.valueOf() : null },
    }).then(always(dueDate));

/**
 * Update task properties for a note.
 */
export const updateTask = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    data: Partial<WriteTaskDTO>
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/task`,
        method: 'PUT',
        data,
    }).then(always(data));

/**
 * Marks a task as complete on the server
 */
export const completeTask = (
    farmUuid: string,
    year: number,
    noteUuid: string,
    data: { completedDate: number; completedByUserId: number }
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/${noteUuid}/completeTask`,
        method: 'PUT',
        data,
    }).then(always(data));
