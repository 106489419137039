import icon from 'components/icons/icon';

import type { Activity } from './activity-helper';
import { activityIsNote, activityIsOperation, isCompleteOrFieldComplete } from './activity-helper';

interface ActivityIconProps {
    activity: Activity;
    fieldUuid?: string;
    className?: string;
}

const ActivityIcon = ({ activity, fieldUuid = '', className }: ActivityIconProps) => {
    // This will also work for tasks if the field UUID is empty
    const done = isCompleteOrFieldComplete(activity, fieldUuid);

    if (activityIsNote(activity) && activity.task) {
        return <>{icon('task', done ? '#c3c3c3' : 'blue', className)}</>;
    } else if (activityIsOperation(activity)) {
        return <>{icon('field-job', done ? '#c3c3c3' : 'blue', className)}</>;
    }
    return <>{icon('note', 'blue')}</>;
};

export default ActivityIcon;
