import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { selectCurrentFarm } from 'farms/farms-state';
import Modal from 'modal/Modal';
import type FullOperation from 'operations/FullOperation';
import { saveOperationFields } from 'operations/operations-save-helper';
import { setOperation } from 'operations/operations-state';
import { useAppDispatch, useAppSelector } from 'system/store';
import { trackEvent } from 'utils/trackEvent';

import type { MarkCompleteData } from './mark-complete-utils';
import { setAllFieldsComplete } from './mark-complete-utils';
import MarkCompleteForm from './MarkCompleteForm';

interface MarkAllCompleteModalProps {
    onClose: VoidFunction;
    onEditFields: VoidFunction;
    fullOperation: FullOperation;
}

const MarkAllCompleteModal = ({
    onClose,
    onEditFields,
    fullOperation,
}: MarkAllCompleteModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const farm = useAppSelector(selectCurrentFarm);

    const handleEditFields = () => {
        onClose();
        onEditFields();
    };

    const handleSubmit = async (values: MarkCompleteData) => {
        const updatedFullOperation = setAllFieldsComplete(fullOperation, values);
        if (updatedFullOperation.fields === null) {
            return fullOperation;
        }

        const savedOperation = await saveOperationFields(
            farm.uuid,
            fullOperation,
            updatedFullOperation.fields
        );
        trackEvent('Field operation completed', {
            farmUuid: farm.uuid,
            jobUuid: fullOperation.uuid,
        });
        dispatch(setOperation(savedOperation));
    };

    return (
        <Modal onClose={onClose}>
            <div className="bg-white text-center p-6" style={{ width: 750 }}>
                <h2>{t('dialog_complete_all_title_web')}</h2>
                <Markdown
                    components={{
                        p: ({ children }) => <p className="text-base">{children}</p>,
                        a: ({ children }) => (
                            <button
                                className="bare-btn underline text-fm-sky text-base"
                                onClick={handleEditFields}
                            >
                                {children}
                            </button>
                        ),
                    }}
                >
                    {t('dialog_complete_all_desc_web')}
                </Markdown>
                <MarkCompleteForm onClose={onClose} onSubmit={handleSubmit} />
            </div>
        </Modal>
    );
};

export default MarkAllCompleteModal;
