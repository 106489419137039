interface WheatIconProps {
    className?: string;
    fill?: string;
}

const WheatIcon = ({ className, fill = '#4da4da' }: WheatIconProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <path
                style={{ fill }}
                d="M37.2,51.7c0.8-1.1,0.8-2.4,0-3.5L36.9,48c4.4-0.8,8.1-3.3,10.8-7.1c0.8-1.1,0.8-2.4,0-3.5l-0.3-0.3
	c4.4-0.8,8.1-3.3,10.8-7.1c0.8-1.1,0.8-2.4,0-3.5c-1.9-2.4-4.4-4.4-6.8-5.7c0.5-0.3,0.8-0.8,1.4-1.1c3.8-3.8,5.7-9.4,4.9-15.4
	c-0.3-1.4-1.1-2.2-2.4-2.4c-6-0.8-11.6,1.1-15.4,4.9c-0.3,0.3-0.8,0.8-1.1,1.4c-1.4-2.7-3.3-4.9-5.7-6.8c-1.1-0.8-2.4-0.8-3.5,0
	c-3.8,2.7-6.3,6.8-7.1,10.8l-0.3-0.3c-0.8-0.3-2.2-0.3-3,0.3c-3.8,2.7-6.3,6.8-7.1,10.8L12,22.9c-1.1-0.8-2.4-0.8-3.5,0
	c-4.7,3.5-7.3,8.8-7.3,14.2c0,4.6,1.9,8.9,5.2,12.4l-3.3,3.8C2,54.4,2,56,3.1,57.1c1.1,1.1,3,1.1,4.1,0l3.3-3.3
	c3.5,3.3,7.8,5.2,12.4,5.2C28.2,59.1,33.6,56.4,37.2,51.7z M26.6,15.6c0-3.6,1.7-7.3,4.3-10.2c2.7,2.9,4.4,6.6,4.4,10.2
	s-1.7,7.3-4.3,10.2C28.3,23.2,26.6,19.6,26.6,15.6z M16.1,27.6c0-3.6,1.7-7.3,4.3-10.2c2.7,2.9,4.3,6.6,4.3,10.2s-1.7,7.3-4.3,10.2
	C17.8,34.9,16.1,31.2,16.1,27.6z M14.1,38.1c0,3.6-1.7,7.3-4.3,10.2c-2.7-2.9-4.3-6.6-4.3-10.2c0-3.6,1.7-7.3,4.3-10.2
	C12.4,30.8,14.1,34.4,14.1,38.1z M49.9,16.2c-2.7,2.7-6.3,4.3-10.2,4.3c0-4,1.7-7.7,4.3-10.2c2.7-2.7,6.3-4.3,10.2-4.3
	C53.9,9.8,52.6,13.5,49.9,16.2z M23.2,39.4c2.9-2.7,6.6-4.3,10.2-4.3s7.3,1.7,10.2,4.3c-2.9,2.7-6.6,4.3-10.2,4.3
	C29.9,43.7,26.2,42,23.2,39.4z M33.6,28.5c3-2.6,6.7-4.3,10.3-4.3c3.6,0,7.7,1.7,10.2,4.3c-2.9,2.7-6.6,4.4-10.2,4.4
	C40.3,33.2,36.7,31.6,33.6,28.5z M32,51c-2.9,2.7-6.6,4.3-10.2,4.3s-7.3-1.7-10.2-4.3c2.7-2.7,6.3-4.3,10.2-4.3
	C25.5,46.8,29,48.4,32,51z"
            />
        </svg>
    );
};

export default WheatIcon;
