import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import type { CodedItem, SingleParamVoidFunction } from 'system/types';
import ErrorMessage from 'view/ErrorMessage';
import VerifiedShield from 'view/shield/VerifiedShield';

import InputListButton from './InputListButton';

interface PesticidesListProps {
    pesticides?: CodedItem[];
    pending: boolean;
    error: boolean;
    onSelect: SingleParamVoidFunction<CodedItem>;
}

const PesticidesList = ({ pesticides, pending, error, onSelect }: PesticidesListProps) => {
    const { t } = useTranslation();
    if (error) {
        return (
            <Wrapper>
                <ErrorMessage className="px-5 pb-5">{t('error_loading_pesticides')}</ErrorMessage>
            </Wrapper>
        );
    }

    if (pesticides !== undefined && pesticides.length > 0) {
        return (
            <Wrapper>
                {pesticides.map((pesticide, key) => (
                    <InputListButton
                        key={key}
                        name={pesticide.name}
                        hideShield
                        onClick={() => onSelect(pesticide)}
                    />
                ))}
                {pending && (
                    <p className="px-5 py-2 bordered-t">
                        <em>{t('loading')}</em>
                    </p>
                )}
            </Wrapper>
        );
    }

    if (pending) {
        return (
            <Wrapper>
                <p className="px-5 pb-5">
                    <em>{t('loading')}</em>
                </p>
            </Wrapper>
        );
    }

    if (pesticides !== undefined && pesticides.length === 0) {
        return (
            <Wrapper>
                <p className="px-5 pb-5">{t('pesticide_search_empty')}</p>
            </Wrapper>
        );
    }

    return <Wrapper />;
};

export default PesticidesList;

const Wrapper = ({ children }: { children?: ReactNode }) => {
    const { t } = useTranslation();
    return (
        <div className="bordered-b">
            <h3 className="px-5 flex items-center">
                {t('inputs_pesticide_database')}
                <VerifiedShield className="ml-2" />
            </h3>
            {children}
        </div>
    );
};
