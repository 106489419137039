import { useSelector } from 'react-redux';
import { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { selectCurrentFarm } from 'farms/farms-state';
import type { WriteFieldProps } from 'fields/Field';
import { createFields } from 'fields/fields-api';
import { setFields } from 'fields/fields-state';
import { List } from 'immutable';
import type { AppState } from 'system/store';
import { useAppDispatch } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

import type { AutoBoundaryNameFormValues } from './AutoBoundaryNameForm';

type SaveFieldsSuccessAction = (formValues?: AutoBoundaryNameFormValues) => void;

export const createAutoBoundaryWriteFieldProps = (
    formValues: AutoBoundaryNameFormValues,
    editingGeoFeatureCollection: GeoFeatureCollection,
    year: number
): WriteFieldProps[] =>
    editingGeoFeatureCollection.features
        .filter((feature) => formValues.names[feature.id] !== undefined)
        .map((feature) => {
            const name = formValues.names[feature.id];
            return {
                name,
                fieldId: formValues.ids?.[feature.id] ?? '',
                year,
                geoJson: GeoFeatureCollection({ features: List.of(feature) }),
            };
        })
        .toArray();

export const useSaveAutoBoundaryFields = () => {
    const dispatch = useAppDispatch();
    const farm = useSelector(selectCurrentFarm);
    const year = useSelector(selectCurrentYear);
    const editingGeoFeatureCollection = useSelector<AppState, GeoFeatureCollection | null>(
        (state) => state.farmEditingState.editingGeoFeatureCollection
    );

    const saveFields = async (
        formValues: AutoBoundaryNameFormValues,
        onSuccess?: SaveFieldsSuccessAction
    ) => {
        // At this point editingGeoFeatureCollection should not be null,
        // carry on if it is.
        if (editingGeoFeatureCollection === null) return Promise.resolve();

        const toSave = createAutoBoundaryWriteFieldProps(
            formValues,
            editingGeoFeatureCollection,
            year
        );

        return createFields(farm.uuid, toSave)
            .then((fields) => dispatch(setFields(fields)))
            .then(() => {
                if (onSuccess) onSuccess(formValues);
            });
    };

    return {
        saveFields,
    };
};
