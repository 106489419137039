import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeature, GeoFeatureCollection, GeoPoint } from '@fieldmargin/webapp-geo';
import { usePromise, useToggle } from '@fieldmargin/webapp-state';
import icon from 'components/icons/icon';
import { stopEditing } from 'farm-editing/farm-editing-state';
import { startEditingManualSensor } from 'farm-editing/start-editing-reducer';
import { selectCurrentFarm } from 'farms/farms-state';
import { useCurrentManualSensor } from 'hooks/selectors/src/useCurrentManualSensor.selector';
import { List } from 'immutable';
import { bindActionCreators } from 'redux';
import {
    fetchManualSensorReadingsApi,
    updateManualSensorApi,
} from 'sensors/manual/manual-sensors-api';
import { updateManualSensor } from 'sensors/manual/manual-sensors-state';
import type ManualSensor from 'sensors/manual/ManualSensor';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarHeaderMenu from 'sidebar/modules/header/SidebarHeaderMenu';
import SidebarHeaderMenuItem from 'sidebar/modules/header/SidebarHeaderMenuItem';
import EditableShapes from 'sidebar/modules/shapes/EditableShapes';
import SidebarModule from 'sidebar/modules/SidebarModule';
import EditableTitle from 'sidebar/modules/title/EditableTitle';
import type { AppState } from 'system/store';
import ErrorMessage from 'view/ErrorMessage';
import Fog from 'view/Fog';

import ManualSensorExport from './details/ManualSensorExport';
import ManualSensorHistory from './details/ManualSensorHistory';

interface ManualSensorDetailsProps {
    farm: Farm;
    updateManualSensor: typeof updateManualSensor;
    startEditingManualSensor: typeof startEditingManualSensor;
    stopEditing: typeof stopEditing;
}

const ManualSensorDetails = ({
    farm,
    updateManualSensor,
    startEditingManualSensor,
    stopEditing,
}: ManualSensorDetailsProps) => {
    const { t } = useTranslation();
    const [menuOpen, toggleMenuOpen] = useToggle(false);
    const { currentManualSensor: manualSensor } = useCurrentManualSensor();
    const { pending, error, setPromise } = usePromise<ManualSensor>(updateManualSensor);

    useEffect(() => {
        if (
            manualSensor !== undefined &&
            manualSensor.id !== null &&
            manualSensor.readingGroups === null
        ) {
            setPromise(
                fetchManualSensorReadingsApi(farm.uuid, manualSensor.id).then((readingGroups) =>
                    manualSensor.set('readingGroups', readingGroups)
                )
            );
        }
    }, [farm, manualSensor]);

    const handleNameSave = useCallback(
        async (name: string) => {
            if (manualSensor) {
                const nextManualSensor = manualSensor.set('name', name);
                await updateManualSensorApi(farm.uuid, nextManualSensor);
                updateManualSensor(nextManualSensor);
            }
        },
        [farm, manualSensor, updateManualSensor]
    );

    const handleLocationSave = useCallback(
        async (featureCollection: GeoFeatureCollection) => {
            const feature = featureCollection.features.first<GeoFeature>();
            if (feature?.geometry.type === 'Point' && manualSensor) {
                const nextManualSensor = manualSensor.set('location', feature.geometry as GeoPoint);
                await updateManualSensorApi(farm.uuid, nextManualSensor);
                updateManualSensor(nextManualSensor);
            }
        },
        [farm, manualSensor, updateManualSensor]
    );

    if (manualSensor === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/data`} />;
    }

    return (
        <div className="scrollable">
            {menuOpen && <Fog blue className="top-14" onClick={toggleMenuOpen} />}
            <div className="non-scrolling">
                <SidebarHeader className="relative">
                    {menuOpen && (
                        <Fog
                            blue
                            className="w-3/4"
                            style={{ bottom: -1 }}
                            onClick={toggleMenuOpen}
                        />
                    )}
                    <SidebarHeaderLink returning to={`/farms/${farm.uuid}/data`}>
                        {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                    </SidebarHeaderLink>
                    <SidebarHeaderMenu open={menuOpen} onToggleOpen={toggleMenuOpen}>
                        <ManualSensorExport
                            farm={farm}
                            manualSensor={manualSensor}
                            fileType="CSV"
                        />
                        <ManualSensorExport
                            farm={farm}
                            manualSensor={manualSensor}
                            fileType="XLS"
                        />
                        <SidebarHeaderMenuItem>
                            <Link to="edit">
                                {icon('edit', 'dark-blue', 'mr-3')} {t('edit')}
                            </Link>
                        </SidebarHeaderMenuItem>
                        <SidebarHeaderMenuItem>
                            <Link to="delete" className="text-error-text">
                                {icon('remove', 'red', 'mr-3')} {t('label_delete')}
                            </Link>
                        </SidebarHeaderMenuItem>
                    </SidebarHeaderMenu>
                </SidebarHeader>
                <EditableTitle
                    label={t('create_manual_sensor_title_prompt')}
                    value={manualSensor.name}
                    onSave={handleNameSave}
                />
                <EditableShapes
                    value={manualSensor.getGeoJson()}
                    startEditing={() => startEditingManualSensor(manualSensor)}
                    maxShapes={1}
                    tools={List.of('point')}
                    stopEditing={stopEditing}
                    onSave={handleLocationSave}
                />
            </div>
            <div className="scrolling">
                {error && <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>}
                {(manualSensor.readingGroups === null || pending) && (
                    <SidebarModule editing>
                        <em>{t('loading')}</em>
                    </SidebarModule>
                )}
                {manualSensor.readingGroups !== null && !pending && (
                    <ManualSensorHistory manualSensor={manualSensor} />
                )}
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
    }),
    (dispatch) =>
        bindActionCreators({ updateManualSensor, startEditingManualSensor, stopEditing }, dispatch)
)(ManualSensorDetails);
