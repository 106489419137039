import type Metric from 'sensors/Metric';
import type Reading from 'sensors/Reading';

import FormattedMetric from '../list/FormattedMetric';

interface ManualSensorHistoryReadingProps {
    // From parent
    metric: Metric;
    reading: Reading;
}

const ManualSensorHistoryReading = ({ metric, reading }: ManualSensorHistoryReadingProps) => {
    return (
        <div className="flex justify-between gap-3 ml-5 py-2 w-64 bordered-b last:border-none">
            <span>{metric.name}</span>
            <strong className="text-right">
                <FormattedMetric
                    metricType={metric.type}
                    value={reading.value ?? undefined}
                    unit={metric.unit}
                />
            </strong>
        </div>
    );
};

export default ManualSensorHistoryReading;
