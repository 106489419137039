import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type Herd from 'herd/Herd';
import { deleteHerdLocation, fetchHerds } from 'herd/herd-api';
import { setHerds } from 'herd/herd-state';
import withPageHerd from 'herd/withPageHerd';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

interface HerdLocationDeletionProps {
    farm: Farm;
    year: number;
    herd?: Herd;
    setHerds: typeof setHerds;
}

const HerdLocationDeletion = ({ farm, year, herd, setHerds }: HerdLocationDeletionProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams<{ herdLocationUuid: string }>();
    const { pending, error, setPromise } = usePromise(() => {
        navigate(`/farms/${farm.uuid}/livestock/${herd?.uuid}`);
    });

    if (herd === undefined || params.herdLocationUuid === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/livestock`} />;
    }

    const handleDelete = () => {
        setPromise(
            // This component won't render if the herdLocationUuid is undefined so we can assert
            // non-null here.
            deleteHerdLocation(farm.uuid, year, herd.uuid, params.herdLocationUuid!)
                // Once a herd location has been deleted we need to fetch herds again so that
                // the latest location for this herd is correct. We cannot fetch only this herd
                // because the API endpoint for fetching a single herd does not return the
                // latest location.
                .then(() => fetchHerds(farm.uuid, year))
                .then(setHerds)
        );
    };
    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader>
                    <SidebarHeaderLink to={`/farms/${farm.uuid}/livestock/${herd.uuid}`}>
                        {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                    </SidebarHeaderLink>
                </SidebarHeader>
                {error && (
                    <SidebarError
                        title={t('failed_to_delete')}
                        message={t('something_went_wrong')}
                    />
                )}
            </div>
            <div className="scrolling">
                <SidebarModule editing>
                    <h2 className="mb-0">{t('herd_history_move')}</h2>
                </SidebarModule>
                <SidebarModule editing>
                    <h3>{t('are_you_sure_delete_herd_move')}</h3>
                    <p className="text-lg">{t('deleting_herd_move_warning')}</p>
                    <Button variant="danger" onClick={handleDelete} disabled={pending}>
                        {t(pending ? 'label_deleting' : 'label_delete')}
                    </Button>
                </SidebarModule>
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        year: selectCurrentYear(state),
    }),
    (dispatch) => bindActionCreators({ setHerds }, dispatch)
)(withPageHerd(HerdLocationDeletion));
