import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { ChangeEventVoidFunction, CodedItem, SingleParamVoidFunction } from 'system/types';
import { trackEvent } from 'utils/trackEvent';
import NativeSelectFormField from 'view/form/native/NativeSelectFormField';

interface PesticideCheckCropStageFilterProps {
    farm: Farm;
    cropStages?: CodedItem[];
    selected?: string;
    setCropStage: SingleParamVoidFunction<string | undefined>;
    includeNoValue?: boolean;
    disabled?: boolean;
}

const PesticideCheckCropStageFilter = ({
    farm,
    cropStages,
    selected,
    setCropStage,
    includeNoValue = false,
    disabled = false,
}: PesticideCheckCropStageFilterProps) => {
    const { t } = useTranslation();
    const handleChange: ChangeEventVoidFunction<HTMLSelectElement> = (e) => {
        trackEvent('Pesticide check crop stage filter used', { farmUuid: farm.uuid });
        setCropStage(e.target.value);
    };
    return (
        <NativeSelectFormField disabled={disabled} value={selected} onChange={handleChange}>
            {includeNoValue && <option value={undefined}>{t('none_selected')}</option>}
            {cropStages !== undefined &&
                cropStages.map(({ code, name }) => (
                    <option key={code} value={code}>
                        {name}
                    </option>
                ))}
        </NativeSelectFormField>
    );
};

export default PesticideCheckCropStageFilter;
