import type { PropsWithChildren } from 'react';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { useBlocker } from 'react-router-dom';
import { t } from 'i18next';
import Modal from 'modal/Modal';
import { useAppDispatch } from 'system/store';
import { clearModal } from 'system/ui-state';
import ChoiceModal from 'view/modal/ChoiceModal';

interface UnsavedHookFormChangesCheckerProps extends PropsWithChildren {
    copy: string[];
}
const UnsavedHookFormChangesChecker = ({ children, copy }: UnsavedHookFormChangesCheckerProps) => {
    const { formState } = useFormContext();
    const dispatch = useAppDispatch();
    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return (
            (formState.isDirty || Object.keys(formState.dirtyFields).length > 0) &&
            !nextLocation.state?.ignoreUnsavedChanges &&
            currentLocation.pathname !== nextLocation.pathname
        );
    });
    const clearModalHandler = () => {
        dispatch(clearModal);
    };

    return (
        <Fragment>
            {blocker.state === 'blocked' ? (
                <Modal onClose={blocker.reset}>
                    <ChoiceModal
                        clearModal={clearModalHandler}
                        onConfirm={blocker.reset}
                        onCancel={blocker.proceed}
                        cancelText={t('label_leave')}
                        confirmText={t('label_stay')}
                        content={
                            <div className="w-80 mb-5">
                                <h2 className="uppercase text-xxl">
                                    {t('unsaved_changes_header')}
                                </h2>
                                {copy.map((c, i) => (
                                    <p key={i} className="text-2xl">
                                        {c}
                                    </p>
                                ))}
                            </div>
                        }
                    />
                </Modal>
            ) : null}

            {children}
        </Fragment>
    );
};

export default UnsavedHookFormChangesChecker;
