// @ts-ignore
import { useTranslation } from 'react-i18next';

import filterSvg from './filter.svg';

interface FieldReportingFilterProps {
    filter: string;
    setFilter: (filter: string) => void;
}

const FieldReportingFilter = ({ filter, setFilter }: FieldReportingFilterProps) => {
    const { t } = useTranslation();
    return (
        <div className="mr-5">
            <img
                src={filterSvg}
                alt="Filter inputs by job status"
                className="inline-block w-6 align-middle mr-4"
            />
            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                <option value="all">{t('field_details_filter_all')}</option>
                <option value="todo">{t('field_details_filter_incomplete')}</option>
                <option value="done">{t('field_details_filter_complete')}</option>
            </select>
        </div>
    );
};

export default FieldReportingFilter;
