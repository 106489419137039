interface SprayIconProps {
    className?: string;
    fill?: string;
}

const SprayIcon = ({ className, fill = '#4da4da' }: SprayIconProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <path
                style={{ fill }}
                d="M10,49.5c-0.6,5,3.4,9.5,8.5,9.5h23c5.9,0,10-5.9,8-11.4l-9.8-28v-5h5.1c0.6,0,1.2-0.3,1.5-0.9c0.3-0.6,0.3-1.2-0.1-1.7
		L39.4,1.8C39.1,1.3,38.5,1,38,1H14.1c-0.9,0-1.7,0.8-1.7,1.7v6.8c0,0.9,0.8,1.7,1.7,1.7H19c-0.7,2-2.7,3.4-4.9,3.4
		c-0.9,0-1.7,0.8-1.7,1.7c0,0.9,0.8,1.7,1.7,1.7c3.5,0,6.6-2.2,7.9-5.1l0.7,0.7v8.8L15.8,27c-2.1,1.4-3.4,3.6-3.7,6L10,49.5z
		 M19.3,7.8h-3.5V4.4h3.5V7.8z M25,10.9l-2.3-2.3V4.4h13.8l4.3,6.5C38.7,10.9,26.7,10.9,25,10.9z M29.5,54.6H19l0,0
		c-2.9,0-4.1-2.6-3.9-5.4l2-16.7c0.2-1.4,1-2.7,2.1-3.4l7.2-4.9c0.5-0.3,0.8-0.8,0.8-1.3v-7.3H36v4c0,0.2,0,0.4,0.1,0.6l9.4,27.9
		c1.1,3.1-1.2,6.5-4.6,6.5L29.5,54.6"
            />
        </svg>
    );
};

export default SprayIcon;
