interface HerdIconProps {
    className?: string;
    fill?: string;
}

const HerdIcon = ({ className, fill = '#4da4da' }: HerdIconProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <g style={{ fill }}>
                <g>
                    <g>
                        <circle cx="23.5" cy="33.3" r="2.2" />
                    </g>
                </g>
                <g>
                    <g>
                        <circle cx="36.5" cy="33.3" r="2.2" />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            d="M58.4,21.8l-9.7-7.7c-0.3-3.5-3.2-6.3-6.8-6.3c-0.3,0-0.6,0-0.9,0.1c-1.1-1.9-3-3.2-5.1-3.4C34.7,2.3,32.5,1,30,1
				s-4.7,1.3-5.9,3.4C22,4.7,20.1,6,19,7.9c-0.3,0-0.6-0.1-0.9-0.1c-3.6,0-6.5,2.8-6.8,6.3l-9.7,7.7C1.2,22.1,1,22.6,1,23.2
				s0.3,1.1,0.8,1.3c2.8,1.7,6.1,2.3,9.5,1.5v5.3c0,1.5,0.4,3,1.2,4.3l4.7,8.1c0.4,0.8,0.7,1.6,0.7,2.5l0.1,4.3
				c0.1,4.6,3.9,8.4,8.5,8.4h7.1c4.6,0,8.4-3.8,8.5-8.4l0.1-4.3c0-0.9,0.3-1.7,0.7-2.5l4.7-8c0.8-1.3,1.2-2.8,1.2-4.3v-5.3
				c3.2,0.7,6.6,0.2,9.5-1.5c0.5-0.3,0.8-0.8,0.8-1.3C59,22.6,58.8,22.1,58.4,21.8z M19.5,11.6c0.4,0,0.7,0.1,1.1,0.3
				c0.8,0.4,1.8-0.2,2-1c0.4-1.3,1.5-2.2,2.8-2.2c0.9,0.1,1.5-0.4,1.8-1c0.4-1.2,1.6-2,2.8-2c1.2,0,2.4,0.8,2.8,2
				c0.2,0.6,0.8,1.1,1.8,1c1.3,0,2.5,1,2.8,2.2c0.3,0.9,1.1,1.3,2,1c0.4-0.2,0.7-0.3,1.1-0.3c1.7,0,3,1.3,3,3c0,1.5-1.1,2.7-2.6,3
				c-0.3-0.1-0.4-0.1-0.7,0c-0.3,0-0.5-0.1-0.8-0.2c-0.4-0.2-0.9-0.2-1.2,0.1c-0.4,0.2-0.7,0.5-0.8,1c-0.4,1.2-1.5,2.3-3.1,2.2
				c-0.7,0-1.3,0.4-1.5,1c-0.4,1.2-1.6,2-2.8,2c-1.2,0-2.4-0.8-2.8-2c-0.2-0.6-0.8-1.1-1.8-1c-1.3,0-2.5-1-2.8-2.2
				c-0.1-0.4-0.4-0.8-0.8-1c-0.4-0.2-0.8-0.2-1.2-0.1c-0.3,0.1-0.5,0.2-0.8,0.2c-0.3-0.1-0.4-0.1-0.7,0c-1.4-0.2-2.6-1.5-2.6-3
				C16.5,12.9,17.8,11.6,19.5,11.6z M7,22.2l4.7-3.8c0.5,0.9,1.2,1.6,2.1,2.1C12.1,22,9.6,22.9,7,22.2z M44.3,31.9
				c0,0.8-0.2,1.7-0.7,2.4l-4.4,7.5c-0.7,1.2-1,2.5-1.1,3.8l-0.1,4c-0.1,2.6-2.2,4.8-4.8,4.8h-1.7v-3.6c1.9-0.7,3.2-2.4,3.2-4.5
				c0-0.8-0.7-1.6-1.6-1.6c-0.8,0-1.6,0.7-1.6,1.6c0,0.8-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6c0-0.8-0.7-1.6-1.6-1.6
				c-0.8,0-1.6,0.7-1.6,1.6c0,2.1,1.3,3.8,3.2,4.5v3.6h-1.7c-2.6,0-4.7-2.2-4.8-4.8l-0.1-4c0-1.4-0.4-2.7-1.1-3.8l-4.4-7.5
				c-0.5-0.7-0.7-1.6-0.7-2.4v-6.2c1.5-0.8,2.8-2,3.8-3.3c0.1,0,0.1,0,0.2,0c1,1.8,2.8,3,4.8,3.2c1.1,2,3.2,3.2,5.5,3.2
				s4.4-1.2,5.5-3.2c2.1-0.3,3.8-1.4,4.8-3.2c0.1,0,0.1,0,0.2,0c1,1.3,2.3,2.4,3.8,3.3V31.9z M46.1,20.6c0.9-0.5,1.6-1.2,2.1-2.1
				l4.7,3.8C50.4,22.9,47.9,22,46.1,20.6z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default HerdIcon;
