import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import icon from 'components/icons/icon';
import type Herd from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import { useDateFormat } from 'hooks/useDateFormat';
import { getDisplayName } from 'lib/util/names';

import type { HerdHistoryMove } from './herd-history-generator';

interface HerdHistoryMoveItemProps {
    herd: Herd;
    historyItem: HerdHistoryMove;
    farmUsers: FarmUser[];
    canDelete?: boolean;
}

const HerdHistoryMoveItem = ({
    herd,
    historyItem,
    farmUsers,
    canDelete = false,
}: HerdHistoryMoveItemProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    return (
        <li className="p-0 pb-5">
            <strong className="mb-2 block text-xxs text-gray-500">
                {format(historyItem.date, 'do MMMM yyyy')}
            </strong>
            <span className="flex">
                {herdIcon(herd.type, 'blue')}
                <span className="ml-3">
                    <strong className="block">
                        {t('livestock_details_history_moved_to', {
                            sprintf: [
                                historyItem.item.herdLocation.size,
                                historyItem.item.fieldName
                                    ? historyItem.item.fieldName
                                    : t('livestock_details_location_no_location'),
                            ],
                        })}{' '}
                    </strong>
                    <span className="block text-xxs text-gray-500">
                        {t('created_by')}{' '}
                        {getDisplayName(farmUsers, historyItem.item.herdLocation.createdByUserId)}
                    </span>
                </span>
                {canDelete && historyItem.type === 'move' && (
                    <Link
                        to={`delete-move/${historyItem.item.herdLocation.uuid}`}
                        className="bare-btn focus ml-auto w-3 h-3 flex items-center"
                    >
                        {icon('close', 'red')}
                    </Link>
                )}
            </span>
        </li>
    );
};

export default HerdHistoryMoveItem;
