import { Fragment } from 'react';
import { connect } from 'react-redux';
import type { FieldUsageWithFields } from 'fields/fields-selectors';
import { selectMaybeVisibleMaybeArchivedFieldUsagesWithFields } from 'fields/fields-selectors';
import type { List, Set } from 'immutable';
import { useToggleSet } from 'lib/hooks';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { selectCurrentYear } from 'years/years-state';

import FieldSelectorField from './FieldSelectorField';
import FieldSelectorGroup from './FieldSelectorGroup';

interface FieldSelectorFieldsByUsageProps {
    // From parent
    editingFieldUuids: Set<string>;
    toggleFieldSelected: SingleParamVoidFunction<string>;
    setEditingFieldUuids: SingleParamVoidFunction<Set<string>>;
    // From redux
    year: number;
    groups: List<FieldUsageWithFields>;
}

const FieldSelectorFieldsByUsage = ({
    groups,
    editingFieldUuids,
    setEditingFieldUuids,
    toggleFieldSelected,
    year,
}: FieldSelectorFieldsByUsageProps) => {
    const [closedGroups, toggleGroup] = useToggleSet();

    return (
        <div className="FieldSelector">
            {groups.map((group) => {
                const isOpen = !closedGroups.includes(group.fieldUsage.uuid);

                return (
                    <Fragment key={group.fieldUsage.uuid}>
                        <FieldSelectorGroup
                            year={year}
                            group={group}
                            selected={editingFieldUuids}
                            setSelected={setEditingFieldUuids}
                            isOpen={isOpen}
                            toggleOpen={() => toggleGroup(group.fieldUsage.uuid)}
                        />

                        {isOpen && (
                            <div className="fields">
                                {group.fields.map((field) => {
                                    const fieldSelected = editingFieldUuids.includes(field.uuid);

                                    return (
                                        <FieldSelectorField
                                            key={field.uuid}
                                            field={field}
                                            year={year}
                                            selected={fieldSelected}
                                            toggleSelected={() => toggleFieldSelected(field.uuid)}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default connect((state: AppState) => ({
    year: selectCurrentYear(state),
    groups: selectMaybeVisibleMaybeArchivedFieldUsagesWithFields(state),
}))(FieldSelectorFieldsByUsage);
