import {
    VITE_APP_API_ROOT,
    VITE_APP_AUTH_API_ROOT,
    VITE_APP_AUTHORIZATION_BASIC,
    VITE_APP_MEDIA_API_ROOT,
    VITE_APP_ORG_MANAGEMENT_URL,
    VITE_AZURE_MAPS_KEY,
    VITE_BOUNDARY_UPLOADER_ROOT,
    VITE_FIREBASE_API_KEY,
    VITE_FIREBASE_APP_ID,
    VITE_FIREBASE_AUTH_DOMAIN,
    VITE_FIREBASE_DATABASE_URL,
    VITE_FIREBASE_MESSAGING_SENDER_ID,
    VITE_FIREBASE_PROJECT_ID,
    VITE_FIREBASE_STORAGE_BUCKET,
    VITE_GOOGLE_MAPS_KEY,
    VITE_GOOGLE_PLACES_API,
    VITE_HERE_API_KEY,
    VITE_INTEGRATION_URL_SUFFIX,
    VITE_MAPBOX_ACCESS_TOKEN,
    VITE_PAYMENT_WEB_APP_STORE_LINK,
    VITE_PAYMENT_WEB_CANCEL_COUPON_ANNUAL,
    VITE_PAYMENT_WEB_CANCEL_COUPON_MONTH,
    VITE_PAYMENT_WEB_MARKETING_ROOT,
    VITE_PAYMENT_WEB_PLAY_STORE_LINK,
    VITE_PAYMENT_WEB_ROOT,
    VITE_PAYMENT_WEB_STRIPE_API_KEY,
    VITE_SEGMENT_KEY,
    VITE_SEGMENT_PREFIX,
    VITE_SENTINEL_HUB_WFS_API_PAID,
    VITE_SENTINEL_HUB_WFS_API_TRIAL,
    VITE_SENTINEL_HUB_WMS_API_PAID,
    VITE_SENTINEL_HUB_WMS_API_TRIAL,
    VITE_SENTRY_DNS_KEY,
    VITE_SENTRY_ENVIRONMENT,
    VITE_SENTRY_RELEASE,
    VITE_SENTRY_TRACES_SAMPLE_RATE,
} from '../../env';

const firebaseConfig = {
    apiKey: VITE_FIREBASE_API_KEY,
    appId: VITE_FIREBASE_APP_ID,
    authDomain: VITE_FIREBASE_AUTH_DOMAIN,
    databaseURL: VITE_FIREBASE_DATABASE_URL,
    projectId: VITE_FIREBASE_PROJECT_ID,
    storageBucket: VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: VITE_FIREBASE_MESSAGING_SENDER_ID,
};

const segmentConfig = {
    key: VITE_SEGMENT_KEY,
    idPrefix: VITE_SEGMENT_PREFIX,
};

const mapsConfig = {
    google: VITE_GOOGLE_MAPS_KEY,
    azure: VITE_AZURE_MAPS_KEY,
    mapboxAccessToken: VITE_MAPBOX_ACCESS_TOKEN,
    mapbox: VITE_MAPBOX_ACCESS_TOKEN,
    hereApiKey: VITE_HERE_API_KEY,
};

const paymentWebConfig = {
    marketingRoot: VITE_PAYMENT_WEB_MARKETING_ROOT,

    stripeApiKey: VITE_PAYMENT_WEB_STRIPE_API_KEY,
    appStoreLink: VITE_PAYMENT_WEB_APP_STORE_LINK,
    playStoreLink: VITE_PAYMENT_WEB_PLAY_STORE_LINK,

    cancelCouponMonth: VITE_PAYMENT_WEB_CANCEL_COUPON_MONTH,
    cancelCouponAnnual: VITE_PAYMENT_WEB_CANCEL_COUPON_ANNUAL,
};

const appConfig = {
    authApiRoot: VITE_APP_AUTH_API_ROOT,
    apiRoot: VITE_APP_API_ROOT,
    mediaApiRoot: VITE_APP_MEDIA_API_ROOT,
    authorizationBasic: VITE_APP_AUTHORIZATION_BASIC,
    orgManagementUrl: VITE_APP_ORG_MANAGEMENT_URL,
};

const sentinelHubConfig = {
    wfsApiTrial: VITE_SENTINEL_HUB_WFS_API_TRIAL,
    wmsApiTrial: VITE_SENTINEL_HUB_WMS_API_TRIAL,
    wfsApiPaid: VITE_SENTINEL_HUB_WFS_API_PAID,
    wmsApiPaid: VITE_SENTINEL_HUB_WMS_API_PAID,
};

const googleConfig = {
    placesApi: VITE_GOOGLE_PLACES_API,
};

const sentry = {
    dnsKey: VITE_SENTRY_DNS_KEY,
    release: VITE_SENTRY_RELEASE,
    environment: VITE_SENTRY_ENVIRONMENT,
    tracesSampleRate: VITE_SENTRY_TRACES_SAMPLE_RATE,
};

const integrations = {
    urlSuffix: VITE_INTEGRATION_URL_SUFFIX,
};

const getEnvConfig = () => ({
    mode: import.meta.env.MODE,
    isProduction: import.meta.env.PROD,
    isDevelopment: import.meta.env.DEV,
    web_payment_root: VITE_PAYMENT_WEB_ROOT,
    boundry_uploader_root: VITE_BOUNDARY_UPLOADER_ROOT,

    app: appConfig,
    firebase: firebaseConfig,
    google: googleConfig,
    maps: mapsConfig,
    segment: segmentConfig,
    payment: paymentWebConfig,
    sentinelHub: sentinelHubConfig,
    sentry,
    integrations,
});

export const envConfig = getEnvConfig();
