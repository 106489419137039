import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useDateFormat } from 'hooks/useDateFormat';
import Modal from 'modal/Modal';
import ErrorMessage from 'view/ErrorMessage';
import type { Weather } from 'weather/Weather';
import { getHourForecastsForDay } from 'weather/Weather';

import WeatherDayView from './WeatherDayView';
import WeatherTab from './WeatherTab';

import './WeatherModal.css';

interface WeatherModalProps {
    farm: Farm;
    weather: Weather;
    onClose: VoidFunction;
}

const WeatherModal = ({ weather, onClose }: WeatherModalProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    const [selectedTab, setSelectedTab] = useState(0);
    const selectedWeather = weather.dayForecasts[selectedTab];

    return (
        <Modal onClose={onClose}>
            <div className="WeatherModal flex flex-col">
                {weather !== null ? (
                    <>
                        <div className="flex">
                            {weather.dayForecasts.slice(0, 6).map((data, i) => (
                                <WeatherTab
                                    key={i}
                                    selected={i === selectedTab}
                                    onClick={() => setSelectedTab(i)}
                                    dayForecast={data}
                                >
                                    {i === 0
                                        ? t('create_task_today')
                                        : format(data.forecastStart, 'EEEE')}
                                </WeatherTab>
                            ))}
                        </div>
                        <WeatherDayView
                            dayForecast={selectedWeather}
                            hourForecasts={getHourForecastsForDay(
                                selectedWeather,
                                weather.hourForecasts
                            )}
                        />
                    </>
                ) : (
                    <ErrorMessage className="p-5">
                        {t('weather_error', {
                            defaultValue:
                                'Sorry, we were unable to load weather information. Please refresh the page to try again.',
                        })}
                    </ErrorMessage>
                )}
            </div>
        </Modal>
    );
};

export default WeatherModal;
