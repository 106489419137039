import { useEffect, useState } from 'react';
import { usePromise } from '@fieldmargin/webapp-state';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
export type TurfFns = typeof import('lib/turf/turf-fns');

export const loadTurf = async () => await import('lib/turf/turf-fns');

export const useTurf = (): [boolean, boolean, TurfFns | undefined] => {
    const [turf, setTurf] = useState<TurfFns>();
    const { pending, error, setPromise } = usePromise<TurfFns>(setTurf);

    useEffect(() => {
        setPromise(loadTurf());
    }, []);

    return [pending, error, turf];
};
