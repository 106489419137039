import { memo } from 'react';
import c from 'classnames';
import { useDueDateWords } from 'hooks/useDueDateWords';
import { twMerge } from 'tailwind-merge';

interface DueDateWordsProps {
    date: Date;
    className?: string;
}

const DueDateWords = ({ date, className }: DueDateWordsProps) => {
    const [level, wording] = useDueDateWords(date);
    return (
        <div
            className={c(twMerge('text-xs', className), {
                'text-fm-blue': level === '',
                'text-error-text': level === 'urgent',
                'text-fm-gold': level === 'warn',
            })}
        >
            {wording}
        </div>
    );
};

export default memo(DueDateWords);
