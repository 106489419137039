import type { ReactNode } from 'react';
import c from 'classnames';
import icon from 'components/icons/icon';

interface PlusButtonProps {
    onClick: VoidFunction;
    children: ReactNode;
    className?: string;
    disabled?: boolean;
}

const PlusButton = ({ onClick, children, className, disabled = false }: PlusButtonProps) => (
    <button
        onClick={(e) => {
            e && e.preventDefault && e.preventDefault();
            onClick();
        }}
        className={c(
            'focus',
            'rounded-l-full',
            'p-0',
            'flex',
            'items-center',
            'uppercase',
            'text-sm',
            'inline-block',
            'border-none',
            'bg-transparent',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            disabled ? 'text-gray-550' : 'text-fm-sky',
            className
        )}
        disabled={disabled}
    >
        <span
            className={c(
                'flex justify-center items-center w-6 h-6 mr-3 p-px box-content rounded-full border border-solid',
                disabled ? 'border-gray-550' : 'border-fm-sky'
            )}
        >
            {icon('add', disabled ? 'dark-grey' : 'blue', undefined, { marginTop: 0 })}
        </span>{' '}
        {children}
    </button>
);

export default PlusButton;
