import type Field from 'fields/Field';
import { getFieldWorkedArea, getYearFieldUsageUuidOrNone } from 'fields/Field';
import { selectFieldUsagesWithNotSet } from 'fields/field-usage-selectors';
import { useCurrentYearSelector } from 'hooks/selectors';
import { uuidMatches } from 'lib/fp-helpers';
import { useAppSelector } from 'system/store';
import FarmRememberingLink from 'view/FarmRememberingLink';
import SizeBubble from 'view/molecules/size-bubble/SizeBubble';
import FormattedArea from 'view/units/FormattedArea';

interface FieldPreviewWithUsageProps {
    // From parent
    field: Field;
}

const FieldPreviewWithUsage = ({ field }: FieldPreviewWithUsageProps) => {
    const { currentYear } = useCurrentYearSelector();
    const fieldUsages = useAppSelector(selectFieldUsagesWithNotSet);
    const fieldUsageUuid = getYearFieldUsageUuidOrNone(currentYear, field);
    const fieldUsage = fieldUsages.find(uuidMatches(fieldUsageUuid));
    const area = getFieldWorkedArea(field, currentYear);

    return (
        <div className="bordered-t">
            <FarmRememberingLink
                to={`/fields/${field.uuid}`}
                className="flex pr-5 focus ring-inset"
            >
                {fieldUsage !== undefined && (
                    <div
                        className="w-2 self-stretch mr-3"
                        style={{
                            backgroundColor: fieldUsage.colour,
                        }}
                    />
                )}
                <div className="w-2/3 py-4">{field.name}</div>
                <div className="flex-grow flex justify-end text-xs text-gray-500 py-4">
                    {area && (
                        <div className="mx-5">
                            <SizeBubble>
                                <FormattedArea areaSqm={area} withUnit />
                            </SizeBubble>
                        </div>
                    )}
                    <i className="ion-chevron-right" />
                </div>
            </FarmRememberingLink>
        </div>
    );
};

export default FieldPreviewWithUsage;
