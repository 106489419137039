import type Output from 'outputs/Output';
import ShortAreaUnit from 'view/units/ShortAreaUnit';
import ShortUnit from 'view/units/ShortUnit';

interface ShortYieldRateProps {
    output: Output;
}

const ShortYieldRate = ({ output }: ShortYieldRateProps) => {
    return (
        <>
            <ShortUnit unit={output.unit} />/<ShortAreaUnit />
        </>
    );
};

export default ShortYieldRate;
