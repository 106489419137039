import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type Field from 'fields/Field';
import { getYearFieldUsageUuid } from 'fields/Field';
import { selectDisplayableFieldUsages } from 'fields/field-usage-selectors';
import type FieldUsage from 'fields/FieldUsage';
import { asBadge } from 'fields/FieldUsage';
import type { List } from 'immutable';
import EditableBadge from 'sidebar/modules/badge/EditableBadge';
import type { AppState } from 'system/store';
import type { UnaryPromiseFunction } from 'system/types';

interface EditableFieldUsageProps {
    // From parent
    field: Field;
    onSave: UnaryPromiseFunction<string | undefined>;
    createdFieldUsageUuid?: string;
    toggleAddUsage: VoidFunction;
    editing: boolean;

    // From redux
    year: number;
    fieldUsages: List<FieldUsage>;
}
const EditableFieldUsage = ({
    field,
    onSave,
    createdFieldUsageUuid,
    toggleAddUsage,
    editing,
    year,
    fieldUsages,
}: EditableFieldUsageProps) => {
    const { t } = useTranslation();
    const fieldUsageUuid = getYearFieldUsageUuid(year, field);

    return (
        <EditableBadge
            label={t('field_usage_title')}
            value={createdFieldUsageUuid ?? fieldUsageUuid}
            options={fieldUsages.map(asBadge)}
            allowNull={true}
            onSave={onSave}
            forceSelectedId={createdFieldUsageUuid}
            onCreateClick={toggleAddUsage}
            initialEditing={editing}
            className={field.archived ? 'archived' : undefined}
            editingDisabled={field.archived}
        />
    );
};

export default connect((state: AppState) => ({
    year: state.yearsState.currentYear as number,
    fieldUsages: selectDisplayableFieldUsages(state),
}))(EditableFieldUsage);
