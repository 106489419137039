import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import type { List } from 'immutable';
import { bindActionCreators } from 'redux';
import type ManualSensor from 'sensors/manual/ManualSensor';
import EmptyState from 'sidebar/modules/EmptyState';
import type { AppState } from 'system/store';
import ErrorMessage from 'view/ErrorMessage';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';

import { fetchManualSensors } from '../../manual/manual-sensors-state';

import SensorListSensor from './SensorListSensor';
import noSensors from './sensors-empty.png';
import SensorsListGroupHeader from './SensorsListGroupHeader';

interface ManualSensorsListProps {
    farm: Farm;
    manualSensors: List<ManualSensor> | null;
    fetchManualSensors: typeof fetchManualSensors;
    fetchManualSensorsPending: boolean;
    fetchManualSensorsError: boolean;
    activeTutorial: string | null;
}

const ManualSensorsList = ({
    farm,
    manualSensors,
    fetchManualSensors,
    fetchManualSensorsPending,
    fetchManualSensorsError,
    activeTutorial,
}: ManualSensorsListProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        fetchManualSensors(farm.uuid);
    }, []);

    let content: ReactNode = null;
    if (fetchManualSensorsError) {
        content = <ErrorMessage className="p-5">{t('something_went_wrong')}</ErrorMessage>;
    } else if (!manualSensors) {
        content = <div className="p-5">{t('sensors_loading')}</div>;
    } else if (manualSensors.size === 0) {
        content = (
            <EmptyState image={noSensors} className="text-center">
                <h2 className="font-medium text-lg px-8 mb-8">
                    <MarkdownNoPara>{t('sensors_empty_web')}</MarkdownNoPara>
                </h2>
            </EmptyState>
        );
    } else {
        content = manualSensors
            .sortBy((sensor) => sensor.name)
            .map((sensor) => (
                <SensorListSensor key={sensor.id} sensor={sensor} linkTo={sensor.id?.toString()} />
            ));
    }
    return (
        <div className="manual-sensors-list sensors-list-group">
            <SensorsListGroupHeader
                name={t('sensors_monitoring_sites')}
                active
                updating={fetchManualSensorsPending}
                onAdd={() => navigate(`/farms/${farm.uuid}/data/new`)}
                activeTutorial={activeTutorial}
            />
            {content}
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        manualSensors: state.manualSensorsState.sensors,
        fetchManualSensorsPending: state.manualSensorsState.fetchManualSensorsPending,
        fetchManualSensorsError: state.manualSensorsState.fetchManualSensorsError,
        activeTutorial: state.tutorialsState.activeTutorial,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                fetchManualSensors,
            },
            dispatch
        )
)(ManualSensorsList);
