import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { useCurrentManualSensor } from 'hooks/selectors/src/useCurrentManualSensor.selector';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { deleteManualSensorReadingGroupApi } from 'sensors/manual/manual-sensors-api';
import { removeManualSensorReadingGroup } from 'sensors/manual/manual-sensors-state';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import { useAppDispatch } from 'system/store';

const ManualSensorReadingGroupDeletion = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { currentFarm: farm } = useCurrentFarm();
    const { currentManualSensor: manualSensor } = useCurrentManualSensor();
    const readingGroupId = useParams<{ readingGroupId: string }>().readingGroupId;
    const readingGroup = manualSensor?.readingGroups?.find((rg) => rg.id === readingGroupId);

    const navigate = useNavigate();
    const { pending, error, setPromise } = usePromise<string>((readingGroupId) => {
        dispatch(
            removeManualSensorReadingGroup({
                manualSensorId: manualSensor?.id,
                readingGroupId,
            })
        );
        navigate(`/farms/${farm.uuid}/data/${manualSensor?.id}`);
    });

    if (manualSensor === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/data`} />;
    }
    if (readingGroup === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/data/${manualSensor.id}`} />;
    }

    const handleDelete = () => {
        setPromise(
            deleteManualSensorReadingGroupApi(farm.uuid, manualSensor.id as string, readingGroup.id)
        );
    };

    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader>
                    <SidebarHeaderLink to={`/farms/${farm.uuid}/data/${manualSensor.id}`}>
                        {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                    </SidebarHeaderLink>
                </SidebarHeader>
                {error && (
                    <SidebarError
                        title={t('failed_to_delete')}
                        message={t('something_went_wrong')}
                    />
                )}
            </div>
            <div className="scrolling">
                <SidebarModule editing>
                    <h2 className="mb-0">{manualSensor.name}</h2>
                </SidebarModule>
                <SidebarModule editing>
                    <p className="text-lg">{t('sensors_delete_reading_prompt')}</p>
                    <Button variant="danger" onClick={handleDelete} disabled={pending}>
                        {t(pending ? 'label_deleting' : 'label_delete')}
                    </Button>
                </SidebarModule>
            </div>
        </div>
    );
};

export default ManualSensorReadingGroupDeletion;
