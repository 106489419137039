import type { Extent } from '@fieldmargin/webapp-geo';
import { selectCurrentFarm } from 'farms/farms-state';
import type { Set } from 'immutable';
import { List, Map } from 'immutable';
import { getUuid, notArchived, uuidMatches } from 'lib/fp-helpers';
import { dedupeList, listIsEmpty, listToMap } from 'lib/immutil';
import { compareName, stringContains } from 'lib/util/text';
import { curry, defaultTo, isEmpty, isNil, pipe, unless } from 'ramda';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import { sqmToHectares } from 'utils/conversion';
import { selectCurrentYear, selectPreviousYear } from 'years/years-state';

import type Field from './Field';
import {
    fieldIntersectsExtent,
    getFieldArea,
    getFieldName,
    getFieldWorkedArea,
    getYearFieldUsageUuid,
    getYearFieldUsageUuidOrNone,
    groupFieldsByUsage,
} from './Field';
import {
    selectFieldUsageMapWithNotSet,
    selectFieldUsagesWithNotSet,
    selectFilteredFieldUsages,
} from './field-usage-selectors';
import type FieldUsage from './FieldUsage';

export const selectField = createSelector(
    (_state: AppState, fieldUuid: string) => fieldUuid,
    (state: AppState) => state.fieldsState.fields,
    (fieldUuid, fields) => fields?.find(uuidMatches(fieldUuid))
);

export const selectFieldsFromUuids = createSelector(
    (_state: AppState, fieldUuids: Set<string>) => fieldUuids,
    (state: AppState) => state.fieldsState.fields ?? List<Field>(),
    (fieldUuids, fields) => fields.filter((field) => fieldUuids.includes(field.uuid))
);

/**
 * This makes sure that the fields all have the correct display names. This particularly applies
 * to sub fields that should be set to "Parent name - Sub field name".
 */
export const normalizeFieldNames = (fields: List<Field>) => {
    const parentFieldMap = Object.fromEntries(
        fields.filter((field) => field.parentUuid === undefined).map((field) => [field.uuid, field])
    );

    return fields.map((field) => {
        const parentField = field.parentUuid ? parentFieldMap[field.parentUuid] : undefined;
        return field.set('name', getFieldName(field, parentField)).set('originalName', field.name);
    });
};

/**
 * Select all fields from state and give them correct display names.
 */
export const selectFieldsWithFullNames = createSelector(
    (state: AppState) => state.fieldsState.fields,
    pipe(defaultTo(List<Field>()), unless(listIsEmpty, normalizeFieldNames))
);

/**
 * Update the archived state of sub fields if the parent field is archived.
 */
const selectFieldsWithCorrectArchiveState = createSelector(selectFieldsWithFullNames, (fields) =>
    fields.map((field) => {
        const parent = fields.find((f) => f.uuid === field.parentUuid);
        return parent && parent.archived ? field.set('archived', true) : field;
    })
);

/**
 * Selects fields for the current year.
 * Fields with sub fields are included in this.
 */
export const selectYearFieldsWithParents = createSelector(
    selectFieldsWithCorrectArchiveState,
    selectCurrentYear,
    (fields, year) =>
        fields.filter((field) => field.year === undefined || field.year < 0 || field.year === year)
);

/**
 * Selects fields that should be shown in the current year.
 * Any fields that have sub-fields for the year are removed.
 */
export const selectYearFields = createSelector(selectYearFieldsWithParents, (fields) => {
    const parents = fields
        .filter((field) => field.parentUuid !== undefined)
        .map((field) => field.parentUuid as string);
    return fields.filter((field) => !parents.contains(field.uuid));
});

/**
 * Select fields that should be shown in the current year, excluding archived fields.
 * Any fields that have sub-fields for the year are removed.
 */
export const selectYearFieldsWithoutArchived = createSelector(selectYearFields, (fields) =>
    fields.filter((field) => !field.archived)
);

/**
 * Select fields that are parents. Returns an empty list if no fields in state.
 */
export const selectParentFields = createSelector(
    (state: AppState) => state.fieldsState.fields,
    pipe(defaultTo(List<Field>()), (fields) =>
        fields.filter((field) => field.parentUuid === undefined)
    )
);

export const selectSubFields = createSelector(
    (state: AppState) => state.fieldsState.fields,
    pipe(defaultTo(List<Field>()), (fields) =>
        fields.filter((field) => field.parentUuid !== undefined)
    )
);

/**
 * Selects only parent fields and returns them as a map keyed by the field UUID.
 */
export const selectParentFieldMap = createSelector(selectParentFields, (fields) =>
    listToMap(fields, (field) => field.uuid)
);

/**
 * Selects the sub fields for the previous year and returns them grouped into a map keyed
 * by their parent uuid.
 */
export const selectParentSubFieldMapForPreviousYear = createSelector(
    selectPreviousYear,
    selectSubFields,
    (year, fields) =>
        fields
            .filter((field) => field.parentUuid !== undefined && field.year === year)
            .groupBy((field) => field.parentUuid as string)
            .toMap()
            .map((fields) => fields.toList())
);

/**
 * Function to be used by a selector to filter fields by the given search string.
 * Fields are returned if their name, id or their field usage name matches the search string
 * partially or fully.
 */
export const filterFieldsBySearchString = (
    year: number,
    fields: List<Field>,
    matchingFieldUsages: List<FieldUsage>,
    fieldSearchString: string
) => {
    if (isEmpty(fieldSearchString)) {
        return fields;
    }

    const matchingFieldUsageUuids = matchingFieldUsages.map(getUuid);
    return fields.filter((field) => {
        const fieldUsageUuid = getYearFieldUsageUuid(year, field);
        return (
            stringContains(field.name, fieldSearchString) ||
            stringContains(field.fieldId, fieldSearchString) ||
            (fieldUsageUuid !== undefined && matchingFieldUsageUuids.contains(fieldUsageUuid))
        );
    });
};

/**
 * Selects fields whose title or field usage match the current search term.
 * Archived fields are excluded.
 * Returns all fields if search string is empty.
 */
export const selectFilteredFields = createSelector(
    selectCurrentYear,
    selectYearFieldsWithoutArchived,
    selectFilteredFieldUsages,
    (state: AppState) => state.fieldsState.fieldSearchString,
    filterFieldsBySearchString
);

/**
 * Selects fields whose title or field usage match the current search term.
 * Archived fields are included so this can be used when showing fields that are attached to an item.
 * Returns all fields if search string is empty.
 */
export const selectFilteredFieldsIncludingArchived = createSelector(
    selectCurrentYear,
    selectYearFields,
    selectFilteredFieldUsages,
    (state: AppState) => state.fieldsState.fieldSearchString,
    filterFieldsBySearchString
);

/**
 * Selects fields whose title or field usage match the current search term.
 * Returns all fields if search string is empty.
 */
export const selectFilteredFieldsWithParents = createSelector(
    selectCurrentYear,
    selectYearFieldsWithParents,
    selectFilteredFieldUsages,
    (state: AppState) => state.fieldsState.fieldSearchString,
    filterFieldsBySearchString
);

const filterFieldsByExtent = (fields: List<Field>, extent?: Extent | null) =>
    extent !== undefined && extent !== null
        ? fields.filter((field) => fieldIntersectsExtent(field, extent))
        : fields;

/**
 * Select year fields, not including parents, that are filtered by the search string
 * and are visible on the map.
 */
export const selectVisibleFilteredFields = createSelector(
    selectFilteredFieldsIncludingArchived,
    (state: AppState) => state.farmEditingState.lastMapPosition?.extent,
    filterFieldsByExtent
);

/**
 * Select year fields, including parents, that are filtered by the search string
 * and are visible on the map.
 */
export const selectVisibleFilteredFieldsWithParents = createSelector(
    selectFilteredFieldsWithParents,
    (state: AppState) => state.farmEditingState.lastMapPosition?.extent,
    filterFieldsByExtent
);

export interface FieldUsageWithFields {
    fieldUsage: FieldUsage;
    fields: List<Field>;
}

/**
 * Selects field usages active in the current year with their fields.
 */
export const selectYearFieldUsagesWithFields = createSelector(
    selectCurrentYear,
    selectFieldUsagesWithNotSet,
    selectYearFields,
    (year, fieldUsages, fields): FieldUsageWithFields[] => {
        const grouped = groupFieldsByUsage(fields, year);
        return fieldUsages
            .filter((fieldUsage) => grouped.has(fieldUsage.uuid))
            .map((fieldUsage) => ({
                fieldUsage,
                fields: (grouped.get(fieldUsage.uuid) as List<Field>).sort(compareName),
            }))
            .toArray();
    }
);

/**
 * Select fields, parents excluded, with visibility, search & archived filters applied and
 * returns a list of FieldUsageWithField objects.
 */
export const selectMaybeVisibleMaybeArchivedFieldUsagesWithFields = createSelector(
    selectFieldUsagesWithNotSet,
    (state: AppState) =>
        state.fieldsState.filterFieldsByMap
            ? selectVisibleFilteredFields(state)
            : selectFilteredFieldsIncludingArchived(state),
    (state: AppState) => state.fieldsState.showArchivedFields,
    selectCurrentYear,
    (fieldUsages, fields, showArchivedFields, year) => {
        const filteredFields = showArchivedFields
            ? fields
            : fields.filter((field) => !field.archived);
        const fieldsByUsageUuid = groupFieldsByUsage(filteredFields, year).map((fields) =>
            fields.sort(compareName)
        );
        return fieldUsages
            .filter(({ uuid }) => fieldsByUsageUuid.has(uuid))
            .map((fieldUsage) => ({
                fieldUsage,
                fields: fieldsByUsageUuid.get(fieldUsage.uuid) as List<Field>,
            }));
    }
);

/**
 * Select fields, parents excluded, with visibility, search & archived filters applied and
 * returns lists of FieldUsageWithField objects grouped by archived field state.
 */
export const selectMaybeVisibleMaybeArchivedFieldUsagesWithFieldsGroupedByArchived = createSelector(
    selectFieldUsagesWithNotSet,
    (state: AppState) =>
        state.fieldsState.filterFieldsByMap
            ? selectVisibleFilteredFields(state)
            : selectFilteredFieldsIncludingArchived(state),
    (state) => state.fieldsState.showArchivedFields,
    selectCurrentYear,
    (fieldUsages, fields, showArchivedFields, year) => {
        const active = groupFieldsByUsage(
            fields.filter((field) => !field.archived),
            year
        ).map((fields) => fields.sort(compareName));

        const archived = showArchivedFields
            ? groupFieldsByUsage(
                  fields.filter((field) => field.archived),
                  year
              ).map((fields) => fields.sort(compareName))
            : Map();

        return {
            active: fieldUsages
                .filter(({ uuid }) => active.has(uuid))
                .map((fieldUsage) => ({
                    fieldUsage,
                    fields: active.get(fieldUsage.uuid) as List<Field>,
                })),
            archived: fieldUsages
                .filter(({ uuid }) => archived.has(uuid))
                .map((fieldUsage) => ({
                    fieldUsage,
                    fields: archived.get(fieldUsage.uuid) as List<Field>,
                })),
        };
    }
);

/**
 * Selects fields, parents included, with visibility, search & archive filters applied, sorted
 * alphabetically by name
 */
export const selectMaybeVisibleMaybeArchivedFieldsWithParents = createSelector(
    (state: AppState) =>
        state.fieldsState.filterFieldsByMap
            ? selectVisibleFilteredFieldsWithParents(state)
            : selectFilteredFieldsWithParents(state),
    (state: AppState) => state.fieldsState.showArchivedFields,
    (fields, showArchivedFields) => {
        const sorted = fields.sort(compareName);
        return showArchivedFields ? sorted : sorted.filter((field) => !field.archived);
    }
);

/**
 * Selects fields, parents included, with visibility, search & archive filters applied, sorted
 * alphabetically by name and grouped by archived state.
 */
export const selectMaybeVisibleMaybeArchivedFieldsWithParentsGroupedByArchived = createSelector(
    (state: AppState) =>
        state.fieldsState.filterFieldsByMap
            ? selectVisibleFilteredFieldsWithParents(state)
            : selectFilteredFieldsWithParents(state),
    (state: AppState) => state.fieldsState.showArchivedFields,
    (fields, showArchivedFields) => {
        return {
            active: fields.filter((field) => !field.archived).sort(compareName),
            archived: showArchivedFields
                ? fields.filter((field) => field.archived).sort(compareName)
                : List(),
        };
    }
);

/**
 * Caluclates the total mapped area of the parent field boundaries. Sub fields are ignored because
 * these may overlap and inflate the total.
 */
export const selectTotalFieldArea = createSelector(selectParentFields, (fields) =>
    fields.reduce((total, field) => total + getFieldArea(field), 0)
);

/**
 * Calculates the total worked area of the active parent field boundaries. Sub fields are ignored
 * because these may overlap and inflate the total.
 */
export const selectTotalWorkedAreaActive = createSelector(
    selectParentFields,
    selectCurrentYear,
    (fields, year) =>
        fields
            .filter(notArchived)
            .reduce((total, field) => total + getFieldWorkedArea(field, year), 0)
);
/**
 * Calculates the total worked area of the active parent field boundaries. Sub fields are ignored
 * because these may overlap and inflate the total.
 */
export const selectTotalWorkedArea = createSelector(
    selectParentFields,
    selectCurrentYear,
    (fields, year) => fields.reduce((total, field) => total + getFieldWorkedArea(field, year), 0)
);

/**
 * Calculates the total area of the field boundaries for the current year. This will include
 * sub fields and ignore parent fields.
 */
export const selectYearTotalFieldArea = createSelector(
    selectYearFields,
    selectCurrentYear,
    (fields, year) => fields.reduce((total, field) => total + getFieldWorkedArea(field, year), 0)
);

/**
 * Calculates the total area of the active field boundaries for the current year. This will include
 * sub fields and ignore parent fields.
 */
export const selectYearTotalFieldAreaActive = createSelector(
    selectYearFields,
    selectCurrentYear,
    (fields, year) =>
        fields
            .filter(notArchived)
            .reduce((total, field) => total + getFieldWorkedArea(field, year), 0)
);

/**
 * Return fields whose parent uuid matches the given field uuid, or an empty list if there
 * are no fields
 */
export const selectFieldSubFields = createSelector(
    (_state: AppState, fieldUuid?: string) => fieldUuid,
    selectSubFields,
    (fieldUuid, fields) => fields.filter((field) => field.parentUuid === fieldUuid)
);

/**
 * Returns fields whose parent uuid matches the given field uuid in the current year, or an
 * empty list if there are no fields.
 */
export const selectFieldSubFieldsForCurrentYear = createSelector(
    selectFieldSubFields,
    selectCurrentYear,
    (fields, year) => fields.filter((field) => field.year === year).sort(compareName)
);

/**
 * Returns a map, keyed by year, of the sub-fields for a field.
 */
export const selectFieldPreviousSubFields = createSelector(
    selectFieldSubFields,
    selectCurrentYear,
    (fields, currentYear) =>
        Map(
            fields
                .filter((field) => field.year !== undefined && field.year < currentYear)
                .groupBy((field) => field.year?.toString() as string)
                .map((f) => f.toList())
        )
);

/**
 * Returns the fields for the current year with their usage(s) for the previous year
 * Return value is a map keyed on field UUID with a list of field usages as the value.
 *
 * If the previous year the field, or its parent had sub fields all the usages for those sub fields
 * will be returned, otherwise the previous usage for the field or its parent will be returned
 * as the only item in the list.
 */
export const selectCurrentYearFieldsWithPreviousUsages = createSelector(
    selectPreviousYear,
    selectFieldUsageMapWithNotSet,
    selectYearFieldsWithParents,
    selectParentFieldMap,
    selectParentSubFieldMapForPreviousYear,
    (previousYear, fieldUsageMap, fields, parentFieldMap, previousYearParentSubFieldMap) => {
        const getFieldYearFieldUsageCurried = curry(getFieldYearFieldUsage)(
            previousYear,
            fieldUsageMap
        );
        return listToMap(
            fields.map((field) => {
                // Cases
                // 1. Field is a parent with no previous sub fields - get previous year usage
                if (
                    field.parentUuid === undefined &&
                    !previousYearParentSubFieldMap.has(field.uuid)
                ) {
                    return {
                        field,
                        previousUsages: List.of(getFieldYearFieldUsageCurried(field)),
                    };
                }

                // 2. Field is a parent with previous sub fields - get usages for previous sub fields
                if (
                    field.parentUuid === undefined &&
                    previousYearParentSubFieldMap.has(field.uuid)
                ) {
                    const previousSubFields = previousYearParentSubFieldMap.get(
                        field.uuid
                    ) as List<Field>;
                    return {
                        field,
                        previousUsages: previousSubFields.map(getFieldYearFieldUsageCurried),
                    };
                }

                // 3. Field is a sub field whose parent has no previous sub fields - get usage for parent for previous year
                if (
                    field.parentUuid !== undefined &&
                    !previousYearParentSubFieldMap.has(field.parentUuid)
                ) {
                    const parentField = parentFieldMap.get(field.parentUuid) as Field;
                    return {
                        field,
                        previousUsages:
                            parentField !== undefined
                                ? List.of(getFieldYearFieldUsageCurried(parentField))
                                : List(),
                    };
                }

                // 4. Field is a sub field whose parent has previous sub fields - get usages for previous sub fields
                const previousSubFields = previousYearParentSubFieldMap.get(
                    field.parentUuid as string
                ) as List<Field>;
                return {
                    field,
                    previousUsages: previousSubFields.map(getFieldYearFieldUsageCurried),
                };
            }),
            (f) => f.field.uuid
        ).map((a) => dedupeList(a.previousUsages, (fu) => fu.uuid));
    }
);

export const selectPesticideCheckFieldAndCurrentUsages = createSelector(
    (state, fieldUuids) => selectFieldsFromUuids(state, fieldUuids),
    selectFieldUsageMapWithNotSet,
    selectCurrentYear,
    (fields, fieldUsageMap, year) => {
        return fields
            .map((field) => {
                const currentUsage = getFieldYearFieldUsage(year, fieldUsageMap, field);
                return {
                    field,
                    currentUsage,
                };
            })
            .toArray();
    }
);

const getFieldYearFieldUsage = (
    year: number,
    fieldUsageMap: Map<string, FieldUsage>,
    field: Field
) => {
    const fieldUsageUuid = getYearFieldUsageUuidOrNone(year, field);
    return fieldUsageMap.get(fieldUsageUuid) ?? (fieldUsageMap.get('none') as FieldUsage);
};

/**
 * Returns whether more fields can be added to the current farm based on the current total field
 * area and the limit for the farms plan.
 */
export const selectCanCreateMoreFields = createSelector(
    selectCurrentFarm,
    selectTotalFieldArea,
    (farm, totalFieldArea) =>
        isNil(farm.plan.fieldAreaHectareLimit)
            ? true
            : sqmToHectares(totalFieldArea) < farm.plan.fieldAreaHectareLimit
);

/**
 * Select the percentage of the field area limit that has been used.
 * Returns 0 if the farm has no field area limit.
 */
export const selectFieldAreaPercentageOfLimit = createSelector(
    selectCurrentFarm,
    selectTotalFieldArea,
    (farm, totalFieldArea) =>
        isNil(farm.plan.fieldAreaHectareLimit)
            ? 0
            : (sqmToHectares(totalFieldArea) / farm.plan.fieldAreaHectareLimit) * 100
);

/**
 * Returns whether the field area limit warning should be shown.
 * Will return true after 20% of the field area limit has been used.
 */
export const selectShouldShowFieldAreaCount = createSelector(
    selectCurrentFarm,
    selectFieldAreaPercentageOfLimit,
    (farm, percentage) =>
        isNil(farm.plan.fieldAreaHectareLimit) ? false : percentage < 20 ? false : true
);

export const selectYearFieldUsagesFromFields = createSelector(
    selectCurrentYear,
    selectFieldsFromUuids,
    selectFieldUsagesWithNotSet,
    (year, fields, fieldUsages) => {
        const yearFieldUsageUuids = fields.map((field) => getYearFieldUsageUuid(year, field));
        return fieldUsages.filter((fieldUsage) => yearFieldUsageUuids.includes(fieldUsage.uuid));
    }
);
