import { createAction, handleActions } from '@fieldmargin/webapp-state';
import { loadingFarm } from 'farms/farm-loading-state';
import { Record } from 'immutable';
import { filterMap, mergeMaps } from 'lib/fp-helpers';
import { noteReducers } from 'notes/note-actions';
import { noteWebSocketReducers } from 'notes/note-websocket-state';

import type Note from './Note';

class NotesState extends Record({
    // Handled by this state
    noteYearsLoaded: [] as number[],
    notes: null as Map<string, Note> | null,
}) {}
export default NotesState;

/**
 * Add notes to the notes map if it exists, otherwise create it.
 * Note this does NOT overwrite any existing entries.
 * This helps ensure that notes loaded for fields don't overwrite already loaded
 * notes and reset values like media, comments etc.
 */
export const addNotes = createAction<NotesState, Map<string, Note>>('addNotes', (state, payload) =>
    state.update('notes', (notes) =>
        notes !== null
            ? mergeMaps(
                  notes,
                  filterMap(payload, (note) => !notes.has(note.uuid))
              )
            : payload
    )
);

/**
 * Update notes in the map. This will overwrite existing entries as well as add new ones.
 */
export const updateNotes = createAction<NotesState, Map<string, Note>>(
    'updateNotes',
    (state, payload) =>
        state.update('notes', (notes) => (notes !== null ? mergeMaps(notes, payload) : payload))
);

export const addNoteYearLoaded = createAction<NotesState, number>(
    'addYearLoaded',
    (state, payload) => state.set('noteYearsLoaded', [...state.noteYearsLoaded, payload])
);

export const notesReducer = handleActions<NotesState>(
    new NotesState(),
    [addNotes, updateNotes, addNoteYearLoaded, ...noteReducers, ...noteWebSocketReducers],
    {
        [loadingFarm.toString()]: () => new NotesState(),
    }
);
