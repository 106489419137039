import { type ReactNode, useEffect } from 'react';
import { Input, NumberField } from 'react-aria-components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import icon from 'components/icons/icon';
import type { List } from 'immutable';
import { useField, useFormApi, useFormState } from 'informed';
import { inputHasNutrients } from 'inputs/Input';
import { InputNutrientsList } from 'inputs/InputNutrients';
import { getInputTypeI18nKey } from 'inputs/InputType';
import { defaultToZero } from 'lib/fp-helpers';
import { formatArea } from 'lib/geo/maths';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { prefersImperialUnits } from 'lib/MeasurementUnit';
import type Recording from 'operations/Recording';
import { curry, partial } from 'ramda';
import type { SingleParamVoidFunction } from 'system/types';
import { hectaresRateToAcresRate } from 'utils/conversion';
import ShortUnit from 'view/units/ShortUnit';

import type { OperationRecording } from './details/recording/operation-recording-utils';
import {
    calculateRateFromAreaAndTotal,
    calculateTotalFromRateAndArea,
} from './details/recording/operation-recording-utils';
import RecordingNutrientRows from './recordings/RecordingNutrientRows';
import OperationRecordingsTable from './OperationRecordingsTable';

interface RecordingTableFormValues {
    recordings: {
        // Recording UUID -> rate as a string for form purposes
        [k: string]: {
            rate: number;
            total: number;
        };
    };
}

interface EditingOperationRecordingsTableProps {
    operationRecordings: List<OperationRecording>;
    areaMeasurementUnit: MeasurementUnit;
    totalArea: number;
    onRemove: SingleParamVoidFunction<Recording>;
    onChange?: SingleParamVoidFunction<Recording>;
}

const EditingOperationRecordingsTable = ({
    operationRecordings,
    areaMeasurementUnit,
    totalArea,
    onRemove,
    onChange,
}: EditingOperationRecordingsTableProps) => {
    let recordingRows: ReactNode = (
        <tr className="spaced">
            <td>&mdash;</td>
            <td>&mdash;</td>
            <td>&mdash;</td>
            <td className="text-center">&mdash;</td>
            <td className="text-center">&mdash;</td>
            <td />
        </tr>
    );

    if (operationRecordings.size > 0) {
        recordingRows = operationRecordings
            .sortBy((operationRecording) => operationRecording.input.name.toLowerCase())
            .map((operationRecording) => (
                <TableRow
                    key={operationRecording.recording.uuid}
                    operationRecording={operationRecording}
                    areaMeasurementUnit={areaMeasurementUnit}
                    onRemove={onRemove}
                    onChange={onChange}
                    totalArea={totalArea}
                />
            ));
    }

    return <OperationRecordingsTable editing>{recordingRows}</OperationRecordingsTable>;
};

interface TableRowProps {
    operationRecording: OperationRecording;
    areaMeasurementUnit: MeasurementUnit;
    onRemove: SingleParamVoidFunction<Recording>;
    onChange?: SingleParamVoidFunction<Recording>;
    totalArea: number;
}

const TableRow = ({
    operationRecording,
    areaMeasurementUnit,
    onRemove,
    onChange,
    totalArea,
}: TableRowProps) => {
    const { t } = useTranslation();
    const { recording, input } = operationRecording;
    const rateField = `recordings.${recording.uuid}.rate`;
    const totalField = `recordings.${recording.uuid}.total`;
    const formState = useFormState<RecordingTableFormValues>();
    const formApi = useFormApi<RecordingTableFormValues>();
    const area = formatArea(totalArea, areaMeasurementUnit);

    const calculateTotal = curry(calculateTotalFromRateAndArea)(area);
    const calculateRate = curry(calculateRateFromAreaAndTotal)(area);

    const initialRate = prefersImperialUnits(areaMeasurementUnit)
        ? hectaresRateToAcresRate(recording.rate)
        : recording.rate;

    const setFormTotal = partial<string, number, void>(formApi.setValue, [totalField]);
    const setFormRate = partial<string, number, void>(formApi.setValue, [rateField]);

    const handleRateChange = (value: number) => {
        const rate = defaultToZero(value);
        setFormTotal(calculateTotal(rate));
        if (onChange) onChange(recording.set('rate', rate));
    };

    const handleTotalChange = (value: number) => {
        const total = defaultToZero(value);
        const rate = calculateRate(total);
        setFormRate(rate);
        if (onChange) onChange(recording.set('rate', rate));
    };

    const hasNutrients = inputHasNutrients(input);
    const rate = defaultToZero(formState.values.recordings?.[recording.uuid]?.rate);

    return (
        <>
            <tr className={clsx('spaced', { 'has-nutrients': hasNutrients })}>
                <td className="input-name">
                    {input.name} <InputNutrientsList input={input} />
                </td>
                <td className="reg-number">{input.pesticideData?.registrationNumber}</td>
                <td className="input-type">{t(getInputTypeI18nKey(input.type))}</td>
                <td className="recording-rate">
                    <NumberInput
                        field={`recordings.${recording.uuid}.rate`}
                        initialValue={initialRate}
                        onChange={handleRateChange}
                    />
                    {/* <Text
                        field={`recordings.${recording.uuid}.rate`}
                        initialValue={toString(initialRate)}
                        value={toString(initialRate)}
                        type="text"
                        validate={validateNumber}
                        className="simple-text-input"
                        validateOnBlur
                        validateOnChange
                        autoComplete="off"
                        onChange={handleRateChange}
                    /> */}
                </td>
                <td className="recording-total">
                    <NumberInput
                        field={`recordings.${recording.uuid}.total`}
                        initialValue={calculateTotalFromRateAndArea(area, initialRate)}
                        onChange={handleTotalChange}
                        className="inline-block w-3/4 mr-1"
                    />
                    {/* <Text
                        field={`recordings.${recording.uuid}.total`}
                        initialValue={calculateTotalFromRateAndArea(area, initialRate).toString()}
                        type="text"
                        validate={validateNumber}
                        className="simple-text-input inline-block w-3/4 mr-1"
                        validateOnBlur
                        validateOnChange
                        autoComplete="off"
                        onChange={handleTotalChange} */}
                    {/* /> */}
                    <ShortUnit unit={input.unit} />
                </td>
                <td className="remove">
                    <button
                        className="bare-btn bg-white"
                        type="button"
                        onClick={() => onRemove(recording)}
                    >
                        {icon('edit-remove', 'red')}
                    </button>
                </td>
            </tr>
            {hasNutrients && (
                <RecordingNutrientRows input={input} rate={rate} area={area} editing />
            )}
        </>
    );
};

const NumberInput = ({
    field,
    initialValue,
    onChange,
    className,
}: {
    field: string;
    initialValue: number;
    onChange: SingleParamVoidFunction<number>;
    className?: string;
}) => {
    const { fieldApi, fieldState } = useField<number>({ field });
    const handleChange = (value: number) => {
        fieldApi.setValue(value);
        onChange(value);
    };
    useEffect(() => {
        fieldApi.setValue(initialValue);
    }, []);
    return (
        <NumberField
            defaultValue={initialValue}
            value={fieldState.value}
            onChange={handleChange}
            className={className}
        >
            <Input className="simple-text-input" />
        </NumberField>
    );
};

export default EditingOperationRecordingsTable;
