import { createContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useTimeoutState } from '@fieldmargin/webapp-state';
import { autoUpdate, useFloating } from '@floating-ui/react';
import { clsx } from 'clsx';
import { useBoolean } from 'hooks/useBoolean';
import type { ToastState } from 'view/toast/Toast';
import Toast from 'view/toast/Toast';

import SidebarToggle from './SidebarToggle';

import 'sidebar/sidebar-molecules.css';
import styles from './Sidebar.module.css';

interface SidebarToastContextState {
    showSidebarToast: (toastState: ToastState, time: number) => void;
}

export const SidebarToastContext = createContext({} as SidebarToastContextState);

const position = {
    name: 'XYPosition',
    fn() {
        return {
            y: 170,
        };
    },
};

const Sidebar = () => {
    const [toastState, showToast, hideToast] = useTimeoutState<ToastState | null>(null);
    const { value, toggle } = useBoolean(true);

    const { refs, floatingStyles } = useFloating({
        placement: 'right',
        strategy: 'fixed',
        middleware: [position],
        whileElementsMounted: autoUpdate,
    });

    return (
        <div ref={refs.setReference} className={styles.sidebarWrapper}>
            <div className={clsx(`${styles.sidebar} no-print`, { hidden: !value })}>
                {toastState && (
                    <Toast
                        message={toastState.message}
                        level={toastState.level}
                        onClose={hideToast}
                    />
                )}
                <SidebarToastContext.Provider value={{ showSidebarToast: showToast }}>
                    <Outlet />
                </SidebarToastContext.Provider>
            </div>

            <SidebarToggle
                ref={refs.setFloating}
                style={floatingStyles}
                open={value}
                onClick={toggle}
            />
        </div>
    );
};

export default Sidebar;
