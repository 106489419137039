import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { selectFieldsWithFullNames } from 'fields/fields-selectors';
import type Herd from 'herd/Herd';
import { archiveHerd } from 'herd/herd-api';
import { herdIcon } from 'herd/herd-icons';
import { selectIsHerdLocked } from 'herd/herd-selectors';
import { setHerd } from 'herd/herd-state';
import withPageHerd from 'herd/withPageHerd';
import { useDateFormat } from 'hooks/useDateFormat';
import type { List } from 'immutable';
import { preventDefaultAnd } from 'lib/dom';
import { bindActionCreators } from 'redux';
import EditHeader from 'sidebar/header/EditHeader';
import SidebarHeaderMenuItem from 'sidebar/modules/header/SidebarHeaderMenuItem';
import SidebarModule from 'sidebar/modules/SidebarModule';
import ItemTab from 'sidebar/modules/tabs/ItemTab';
import ItemTabList from 'sidebar/modules/tabs/ItemTabList';
import ItemTabPanel from 'sidebar/modules/tabs/ItemTabPanel';
import type { AppState } from 'system/store';
import ArchivedBar from 'view/ArchivedBar';
import Fog from 'view/Fog';
import SmallPill from 'view/SmallPill';
import { selectCurrentYear } from 'years/years-state';

import { selectHerdField } from '../herd-ui-utils';
import HerdTypeColourBar from '../HerdTypeColourBar';

import HerdActions from './HerdActions';
import HerdHistory from './HerdHistory';

interface HerdDetailsProps {
    farm: Farm;
    year: number;
    fields: List<Field>;
    herd?: Herd;
    isLocked: boolean;
    setHerd: typeof setHerd;
}

const HerdDetails = ({ farm, year, fields, herd, isLocked, setHerd }: HerdDetailsProps) => {
    const { t } = useTranslation();
    const { format, formatDistanceToNow } = useDateFormat();
    const [headerMenuOpen, toggleHeaderMenuOpen] = useToggle(false);

    const toggleArchived = preventDefaultAnd(() => {
        if (herd) {
            archiveHerd(year, herd, !herd.archived).then((newHerd) =>
                // The herd returned by the server from archive does not contain the herd location,
                // so we need to add it back in.
                setHerd({ ...newHerd, herdLocation: herd.herdLocation })
            );
            toggleHeaderMenuOpen();
        }
    });

    const herdField = selectHerdField(fields, herd);

    if (!herd) {
        return <Navigate to={`/farms/${farm.uuid}/livestock`} />;
    }

    return (
        <div className="scrollable">
            {headerMenuOpen && <Fog blue className="top-14" onClick={toggleHeaderMenuOpen} />}

            <div className="non-scrolling">
                <EditHeader
                    item={herd}
                    backPath={`/farms/${farm.uuid}/livestock`}
                    deletePath={`/farms/${farm.uuid}/livestock/${herd.uuid}/delete`}
                    menuOpen={headerMenuOpen}
                    onToggleHeaderMenu={toggleHeaderMenuOpen}
                >
                    <SidebarHeaderMenuItem>
                        <button
                            className="bare-btn w-full whitespace-normal text-left"
                            onClick={toggleArchived}
                        >
                            {icon('archive', 'dark-blue', 'mr-3')}{' '}
                            {herd.archived ? t('unarchive') : t('archive')}
                        </button>
                    </SidebarHeaderMenuItem>
                </EditHeader>
            </div>
            <div className="scrolling">
                {herd.archived && <ArchivedBar>{t('archived')}</ArchivedBar>}
                <div
                    className={c('flex border-solid bordered-b bg-gray-100 relative', {
                        'text-gray-400': herd.archived,
                    })}
                >
                    <HerdTypeColourBar herd={herd} />
                    <h1 className="py-5 pr-4 text-base w-3/4 truncate">
                        {herdIcon(herd.type, herd.archived ? 'gray' : 'dark-blue')}{' '}
                        <span className="ml-2">{herd.name}</span>
                    </h1>
                    {!isLocked && (
                        <Link
                            to="edit"
                            className="pr-5 flex-grow self-center text-right text-sm uppercase text-fm-sky"
                        >
                            {t('edit')}
                        </Link>
                    )}
                </div>
                <SidebarModule
                    className={c('flex items-center', { 'text-gray-400': herd.archived })}
                >
                    <h4 className="mb-0 mr-8">{t('livestock_details_count')}</h4>
                    <span className="text-base">{herd.size}</span>
                    {!isLocked && (
                        <Button
                            as={Link}
                            className="ml-auto"
                            variant="outline"
                            to={`/farms/${farm.uuid}/livestock/${herd.uuid}/edit`}
                            small
                        >
                            {t('livestock_details_add_remove')}
                        </Button>
                    )}
                </SidebarModule>
                <SidebarModule
                    className={c('flex justify-between items-center', {
                        'text-gray-400': herd.archived,
                    })}
                >
                    <div className="flex flex-col items-start">
                        <h4 className="mb-1">{t('livestock_details_location')}</h4>
                        {herdField && <SmallPill>{herdField.name}</SmallPill>}
                    </div>
                    {!isLocked &&
                        (herdField ? (
                            <Button
                                as={Link}
                                to={`/farms/${farm.uuid}/livestock/${herd.uuid}/move`}
                                small
                                onClick={(e) => {
                                    herd.archived && e.preventDefault();
                                }}
                                className={herd.archived ? 'opacity-50 cursor-not-allowed' : ''}
                            >
                                {t('livestock_details_move')}
                            </Button>
                        ) : (
                            <Button
                                as={Link}
                                to={`/farms/${farm.uuid}/livestock/${herd.uuid}/move`}
                                variant="outline"
                                small
                                onClick={(e) => {
                                    herd.archived && e.preventDefault();
                                }}
                                className={herd.archived ? 'opacity-50 cursor-not-allowed' : ''}
                            >
                                {t('draw_shapes_add_point')}
                            </Button>
                        ))}
                </SidebarModule>
                <SidebarModule
                    className={c('flex items-center', { 'text-gray-400': herd.archived })}
                >
                    <h4 className="mb-0 flex-grow">{t('livestock_details_move_date')}</h4>
                    <span>
                        {herd.herdLocation
                            ? `${format(
                                  herd.herdLocation.moveDate,
                                  'dd/MM/yy'
                              )} - ${formatDistanceToNow(herd.herdLocation.moveDate)}`
                            : t('livestock_details_move_none')}
                    </span>
                </SidebarModule>
                <HerdActions farm={farm} herd={herd} />
                <ItemTabList>
                    <ItemTab to="">{t('field_details_history')}</ItemTab>
                </ItemTabList>
                <ItemTabPanel>
                    <HerdHistory herd={herd} />
                </ItemTabPanel>
            </div>
        </div>
    );
};

export default withPageHerd(
    connect(
        (state: AppState, { herd }: { herd?: Herd }) => {
            const isLocked = selectIsHerdLocked(state, herd ? herd.uuid : '');
            return {
                farm: selectCurrentFarm(state),
                year: selectCurrentYear(state),
                fields: selectFieldsWithFullNames(state),
                herd,
                isLocked,
            };
        },
        (dispatch) => bindActionCreators({ setHerd }, dispatch)
    )(HerdDetails)
);
