import type { ChangeEvent, HTMLInputTypeAttribute, ReactNode } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { hasPath } from 'ramda';
import type { SingleParamVoidFunction } from 'system/types';

import OptionalLabel from '../OptionalLabel';

interface TextInputFieldProps {
    field: string;
    label?: ReactNode;
    light?: boolean;
    className?: string;
    labelClassName?: string;
    inputClassName?: string;
    optional?: boolean;
    focusOnRender?: boolean;
    validate?: (value: any) => string | boolean | undefined;
    onChange?: SingleParamVoidFunction<string>;
    onFocus?: VoidFunction;
    type?: HTMLInputTypeAttribute;
    placeholder?: string;
    disabled?: boolean;
    value?: string;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const TextInputField = ({
    field,
    label,
    light = false,
    className,
    labelClassName,
    inputClassName,
    optional = false,
    focusOnRender = false,
    validate,
    onChange,
    onFocus,
    type = 'text',
    placeholder,
    disabled = false,
    value,
    inputProps,
}: TextInputFieldProps) => {
    const {
        register,
        formState: { errors },
        setFocus,
    } = useFormContext();
    const error = hasPath(field.split('.'), errors);

    useEffect(() => {
        if (focusOnRender) {
            setFocus(field);
        }
    }, []);

    return (
        <div className={classNames('form-field', className)}>
            {label && (
                <label htmlFor={field} className={classNames(labelClassName)}>
                    {label}
                    {optional && (
                        <>
                            {' '}
                            <OptionalLabel />
                        </>
                    )}
                </label>
            )}
            <input
                {...inputProps}
                type={type}
                id={field}
                {...register(field, {
                    value,
                    validate,
                    onChange: (e: ChangeEvent<HTMLInputElement>) =>
                        onChange && onChange(e.target.value),
                    valueAsNumber: type === 'number',
                })}
                disabled={disabled}
                onFocus={onFocus}
                className={classNames('simple-text-input', inputClassName, {
                    'has-error': error,
                    light,
                })}
                autoComplete="off"
                placeholder={placeholder}
            />
            <ErrorMessage
                errors={errors}
                name={field}
                render={({ message }) => <div className="field-error">{message}</div>}
            />
        </div>
    );
};

export default TextInputField;
