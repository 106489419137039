import type { ComponentPropsWithRef } from 'react';
import c from 'classnames';

interface NativeSelectFormFieldProps extends ComponentPropsWithRef<'select'> {}

const NativeSelectFormField = ({ children, className, ...props }: NativeSelectFormFieldProps) => {
    return (
        <select className={c('bordered p-2 rounded-sm w-52 h-10 focus', className)} {...props}>
            {children}
        </select>
    );
};

export default NativeSelectFormField;
