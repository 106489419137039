import { useParams } from 'react-router-dom';
import { selectFeature } from 'features/features-selectors';
import { useAppSelector } from 'system/store';

const usePageFeature = () => {
    const { featureUuid } = useParams<{ featureUuid: string }>();

    const feature = useAppSelector((state) =>
        featureUuid !== undefined ? selectFeature(state, featureUuid) : undefined
    );

    return { feature };
};

export { usePageFeature };
