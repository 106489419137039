import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { FieldsSortBy } from 'fields/fields-state';
import {
    setDeclutterFieldNames,
    setFieldsSortBy,
    setFilterFieldsByMap,
    setShowArchivedFields,
} from 'fields/fields-state';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import Dropdown, { DropdownItem } from 'view/dropdown/Dropdown';
import CheckboxFilterItem from 'view/filters/CheckboxFilterItem';

interface FieldSortAndFilterProps {
    sortBy: FieldsSortBy;
    filterFieldsByMap: boolean;
    setFieldsSortBy: typeof setFieldsSortBy;
    setFilterFieldsByMap: typeof setFilterFieldsByMap;
    declutterFieldNames: boolean;
    setDeclutterFieldNames: typeof setDeclutterFieldNames;
    showArchivedFields: boolean;
    setShowArchivedFields: typeof setShowArchivedFields;
}

const FieldSortAndFilter = ({
    sortBy,
    filterFieldsByMap,
    setFieldsSortBy,
    setFilterFieldsByMap,
    declutterFieldNames,
    setDeclutterFieldNames,
    showArchivedFields,
    setShowArchivedFields,
}: FieldSortAndFilterProps) => {
    const { t } = useTranslation();

    const filterCount = [filterFieldsByMap, declutterFieldNames, showArchivedFields].reduce(
        (count, filter) => (filter ? count + 1 : count),
        0
    );
    return (
        <Dropdown
            title={`${sortBy === 'usage' ? t('by_usages') : 'A-Z'}${
                filterCount > 0 ? `, ${t('filters')} (${filterCount})` : ''
            }`}
            className="relative min-w-[208px]"
        >
            <DropdownItem>
                <strong>{t('sort_by')}</strong>
            </DropdownItem>
            <DropdownItem>
                <input
                    type="radio"
                    className="fancy-radio"
                    name="sortBy"
                    id="alphabetically"
                    value="alphabetically"
                    checked={sortBy === 'alphabetically'}
                    onChange={() => setFieldsSortBy('alphabetically')}
                />
                <label htmlFor="alphabetically">A-Z</label>
            </DropdownItem>
            <DropdownItem>
                <input
                    type="radio"
                    className="fancy-radio"
                    name="sortBy"
                    id="usage"
                    value="usage"
                    checked={sortBy === 'usage'}
                    onChange={() => setFieldsSortBy('usage')}
                />
                <label htmlFor="usage">{t('by_usages')}</label>
            </DropdownItem>
            <DropdownItem>
                <strong>{t('filter')}</strong>
            </DropdownItem>
            <CheckboxFilterItem
                label={t('filters_visibile_screen')}
                selected={filterFieldsByMap}
                onChange={setFilterFieldsByMap}
                id="field-visiblity"
            />
            <CheckboxFilterItem
                label={t('filter_declutter_fields')}
                selected={declutterFieldNames}
                onChange={setDeclutterFieldNames}
                id="declutter-field-names"
            />
            <CheckboxFilterItem
                label={t('filters_archived')}
                selected={showArchivedFields}
                onChange={setShowArchivedFields}
                id="show-archived-fields"
            />
        </Dropdown>
    );
};

export default connect(
    (state: AppState) => ({
        sortBy: state.fieldsState.sortBy,
        filterFieldsByMap: state.fieldsState.filterFieldsByMap,
        declutterFieldNames: state.fieldsState.declutterFieldNames,
        showArchivedFields: state.fieldsState.showArchivedFields,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setFieldsSortBy,
                setFilterFieldsByMap,
                setDeclutterFieldNames,
                setShowArchivedFields,
            },
            dispatch
        )
)(FieldSortAndFilter);
