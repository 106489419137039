import { useState } from 'react';
import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { selectDrawingTool } from 'farm-editing/farm-editing-state';
import { useFreshEditingGeoFeatureCollection } from 'fields/sub-field-hooks';
import { cutHoleInField } from 'fields/sub-fields-manipulations';
import { selectSelectedSubFields } from 'fields/sub-fields-selectors';
import { updateSubFieldAndAddNew } from 'fields/sub-fields-state';
import { useClearEditingGeoFeatureCollection } from 'hooks/useClearEditingGeoFeatureCollection';
import { useDrawingToolOnMount } from 'lib/hooks';
import type { TurfFns } from 'lib/turf/turf-utils';
import { useAppDispatch, useAppSelector } from 'system/store';
import ErrorMessage from 'view/ErrorMessage';
import { selectCurrentYear } from 'years/years-state';

import type SubField from '../SubField';
import { createEmptySubField } from '../SubField';

interface SubFieldsCutProps {
    onDone: VoidFunction;
    turf: TurfFns;
}

const SubFieldsCut = ({ onDone, turf }: SubFieldsCutProps) => {
    // Cut only operates on a single sub field, so choose the first one.
    // Option should be disabled in menu if more than one is selected but good to handle this
    // just in case.
    const subField = useAppSelector(selectSelectedSubFields).get(0);
    const year = useAppSelector(selectCurrentYear);
    const { clearEditingGeoFeatureCollection } = useClearEditingGeoFeatureCollection();

    const dispatch = useAppDispatch();
    const [multiPolygonError, setMultiPolygonError] = useState(false);

    useDrawingToolOnMount('area');

    useFreshEditingGeoFeatureCollection<SubField>((editingGeoFeatureCollection, subField) => {
        try {
            if (
                subField &&
                editingGeoFeatureCollection &&
                editingGeoFeatureCollection.features.size > 0
            ) {
                setMultiPolygonError(false);
                const updatedField = cutHoleInField(
                    subField.field,
                    editingGeoFeatureCollection.features.get(0) as GeoFeature,
                    turf
                );
                const newSubField = createEmptySubField(
                    subField.field.parentUuid as string,
                    year,
                    editingGeoFeatureCollection
                );

                dispatch(
                    updateSubFieldAndAddNew({
                        existingSubField: subField.set('field', updatedField),
                        newSubField,
                    })
                );

                onDone();
                clearEditingGeoFeatureCollection();
            }
        } catch (e) {
            if (e.message === 'difference-multi-polygon') {
                setMultiPolygonError(true);
                dispatch(selectDrawingTool('area'));
                clearEditingGeoFeatureCollection();
            } else {
                throw new Error(e);
            }
        }
    }, subField);

    if (!subField || !subField.field.geoJson) {
        return null;
    }

    return (
        <div className="text-center">
            <p className="mb-1">Cut out a shape from your sub-field</p>
            {multiPolygonError && (
                <ErrorMessage>You cannot cut a sub-field into multiple sections</ErrorMessage>
            )}
        </div>
    );
};

export default SubFieldsCut;
