import type { Map } from 'immutable';
import { Record } from 'immutable';

const IntegrationDataResponse = Record({
    farmIntegrationUuid: '',
    success: false,
    result: null as Map<string, any> | null,
});
interface IntegrationDataResponse extends ReturnType<typeof IntegrationDataResponse> {}

export interface IntegrationDataResponseDTO<T> {
    farmIntegrationUUID: string;
    success: boolean;
    result: T;
}

export const deserializeIntegrationDataResponse = <T>(
    json: IntegrationDataResponseDTO<any>,
    deserializedResults: Map<string, T> | null
) => {
    return new IntegrationDataResponse({
        farmIntegrationUuid: json.farmIntegrationUUID,
        success: json.success,
        result: deserializedResults,
    });
};

export default IntegrationDataResponse;
