import icon from 'components/icons/icon';

interface SidebarErrorProps {
    title: string;
    message: string;
}

const SidebarError = ({ title, message }: SidebarErrorProps) => (
    <div className="bg-error-bg text-white p-5 flex items-center">
        {icon('error-alert', 'white')}
        <div className="ml-4">
            <div className="text-sm font-bold uppercase">{title}</div>
            <div>{message}</div>
        </div>
    </div>
);

export default SidebarError;
