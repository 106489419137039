import { createLogger } from '@fieldmargin/webapp-reporting';
import { List, Set } from 'immutable';
import LocalStorageHelper from 'lib/storage/LocalStorageHelper';
import { isArray } from 'lodash';
import type { VisibilityOptions } from 'system/types';

import { MapVisibilityKey } from './farm-editing-maps';
import LayerVisibility from './LayerVisibility';

const logger = createLogger('system.editing.farm-editing-persister');

// Ideally you should never remove items from this interface, as users may have them
interface SerialisedData {
    farmUuid?: string;
    displayNotes?: boolean;
    featuresVisibility?: VisibilityOptions;
    fieldsVisibility?: VisibilityOptions;
    hiddenFeatureTypes?: string[];
    hiddenFieldUsages?: (string | null)[];
    visibleMapGroups?: {
        mapUuid: string;
        farmIntegrationUuid: string;
        visibility: VisibilityOptions;
    }[];
    hiddenMapIntegrations?: string[];
}

export const deserializeLayerVisibility = (farmUuid: string, serialised: SerialisedData) => {
    return LayerVisibility({
        ...serialised,
        farmUuid: farmUuid,
        hiddenFeatureTypes: Set(serialised.hiddenFeatureTypes || []),
        hiddenFieldUsages: Set(serialised.hiddenFieldUsages || []),
        visibleMapGroups: List(
            serialised.visibleMapGroups && isArray(serialised.visibleMapGroups)
                ? serialised.visibleMapGroups.map(MapVisibilityKey)
                : []
        ),
        hiddenMapIntegrations: Set(serialised.hiddenMapIntegrations || []),
    });
};

export const loadLayerVisibility = (farmUuid: string) => {
    const layerVisibility = LayerVisibility({
        farmUuid: farmUuid,
    });
    const serialised = LocalStorageHelper.getItemAsJson<SerialisedData>(
        `farm-${farmUuid}-layerVisibility`
    );
    if (serialised) {
        try {
            return deserializeLayerVisibility(farmUuid, serialised);
        } catch (e) {
            logger.error('Unable to deserialize layer visibility', e);
        }
    }
    return layerVisibility;
};

export const serializeLayerVisibility = (layerVisibility: LayerVisibility) =>
    layerVisibility.toJSON();

export const persistLayerVisibility = (layerVisibility: LayerVisibility) => {
    LocalStorageHelper.setItemAsJson(
        `farm-${layerVisibility.farmUuid}-layerVisibility`,
        serializeLayerVisibility(layerVisibility)
    );
};
