import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useToggle } from '@fieldmargin/webapp-state';
import AdminPanel from 'admin/AdminPanel';
import c from 'classnames';
import { selectCurrentFarm } from 'farms/farms-state';
import featureFlags from 'lib/feature-flags';
import { selectUserOwnsOrganisation } from 'organisation/organisation-state';
import { pipe } from 'ramda';
import { useAppDispatch, useAppSelector } from 'system/store';
import type { ChangeModalSpec } from 'system/ui-state';
import { changeModal, rememberCurrentLocation, UIContext } from 'system/ui-state';
import { signOut } from 'users/user-state';
import InfoMsg from 'view/InfoMsg';
import Arrow from 'view/svg/Arrow';

import ListDropdown from './ListDropdown';
import ListDropdownItem from './ListDropdownItem';
import settingsIcon from './settings.svg';
import TopNavItem from './TopNavItem';

const SettingsNavItem = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const activeFarm = useAppSelector(selectCurrentFarm);
    const userOwnsOrg = useAppSelector(selectUserOwnsOrganisation);
    const organisation = useAppSelector((state) => state.organisationState.organisation);

    const { infoOpen } = useContext(UIContext);
    const [open, toggleOpen] = useToggle(false);

    const [showAdminPanel, toggleAdminPanel] = useToggle(false);

    const openModal = (spec: ChangeModalSpec) => () => {
        toggleOpen();
        dispatch(changeModal(spec));
    };
    const handleLogout = () => {
        toggleOpen();
        dispatch(signOut());
    };

    const handleClick = () => {
        toggleOpen();
    };

    const handleOrganisationClick = () => {
        dispatch(rememberCurrentLocation());
        toggleOpen();
    };

    const showFarmItems = activeFarm !== undefined && !activeFarm.plan.blocked;

    return (
        <div className="relative flex">
            {showAdminPanel && <AdminPanel onClose={toggleAdminPanel} />}
            {infoOpen && (
                <InfoMsg className="hidden xl:flex w-32 top-14 right-0 flex-col">
                    <Arrow className="w-12 mb-2 mr-1 self-end" />
                    {t('info_settings')}
                </InfoMsg>
            )}
            <TopNavItem
                className={c({ forefront: infoOpen })}
                onClick={handleClick}
                data-dropdown="settings"
            >
                <img src={settingsIcon} alt="A cog icon" />
                <div className="sr-only">{t('settings')}</div>
            </TopNavItem>
            {open && (
                <ListDropdown onClose={toggleOpen} className="right-0 left-auto" name="settings">
                    <>
                        {showFarmItems && (
                            <ListDropdownItem>
                                <button
                                    className="bare-btn ListDropdownItem"
                                    onClick={openModal({ name: 'settings' })}
                                >
                                    {t('hamburger_menu_settings_farm')}
                                </button>
                            </ListDropdownItem>
                        )}
                        <ListDropdownItem>
                            <button
                                className="bare-btn ListDropdownItem"
                                onClick={openModal({
                                    name: 'settings',
                                    props: { initialTab: 'user' },
                                })}
                            >
                                {t('user_settings')}
                            </button>
                        </ListDropdownItem>
                        {showFarmItems && (
                            <>
                                <ListDropdownItem>
                                    <button
                                        className="bare-btn ListDropdownItem"
                                        onClick={openModal({ name: 'connections' })}
                                    >
                                        {t('settings_connections')}
                                    </button>
                                </ListDropdownItem>
                                {userOwnsOrg && organisation !== undefined && (
                                    <ListDropdownItem>
                                        <Link
                                            to={`/organisations/${organisation.uuid}/settings`}
                                            className="bare-btn ListDropdownItem"
                                            onClick={handleOrganisationClick}
                                        >
                                            {t('settings_organisations')}
                                        </Link>
                                    </ListDropdownItem>
                                )}
                            </>
                        )}
                        {featureFlags.get('adminTools') && (
                            <ListDropdownItem>
                                <button
                                    className="bare-btn ListDropdownItem"
                                    onClick={pipe(toggleOpen, toggleAdminPanel)}
                                >
                                    Admin panel
                                </button>
                            </ListDropdownItem>
                        )}
                        <ListDropdownItem>
                            <button className="bare-btn ListDropdownItem" onClick={handleLogout}>
                                {t('logout')}
                            </button>
                        </ListDropdownItem>
                    </>
                </ListDropdown>
            )}
        </div>
    );
};

export default SettingsNavItem;
