import { analytics } from '@notes-web/analytics';

import { VITE_APP_NAME } from '../../env';

type Track = Parameters<typeof analytics.track>;
type TrackPage = Parameters<typeof analytics.page>;
type TrackIdentify = Parameters<typeof analytics.identify>;

const trackEvent = (...args: Track) => {
    if (VITE_APP_NAME === 'fieldmargin') {
        return analytics.track(...args);
    }
};

const pageTracking = (...args: TrackPage) => {
    if (VITE_APP_NAME === 'fieldmargin') {
        return analytics.page(...args);
    }
};

const trackPage = (...args: TrackPage) => {
    if (VITE_APP_NAME === 'fieldmargin') {
        return analytics.page(...args);
    }
};
const trackIdentify = (...args: TrackIdentify) => {
    if (VITE_APP_NAME === 'fieldmargin') {
        return analytics.identify(...args);
    }
};

export { trackEvent, trackPage, pageTracking, trackIdentify };
