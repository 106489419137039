import type Field from 'fields/Field';
import { getYearFieldUsageUuid } from 'fields/Field';
import { selectFieldUsagesWithoutCropTypes } from 'fields/field-usage-selectors';
import { selectFieldsFromUuids, selectFieldsWithFullNames } from 'fields/fields-selectors';
import type FieldUsage from 'fields/FieldUsage';
import { getUsageName } from 'fields/FieldUsage';
import type { Set } from 'immutable';
import { List, Map } from 'immutable';
import type Input from 'inputs/Input';
import { getUuid, notNil } from 'lib/fp-helpers';
import { defaultTo } from 'ramda';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

import type FullOperation from './FullOperation';
import type { FullOperationRecording } from './FullOperation';
import type { FullOperationField } from './OperationField';
import type Recording from './Recording';

export const selectOperation = createSelector(
    (_state: AppState, operationUuid: string) => operationUuid,
    (state: AppState) => state.operationsState.operations,
    (operationUuid, operations) => operations?.get(operationUuid)
);

export const selectFullOperationFields = createSelector(
    (_state: AppState, fullOperation?: FullOperation) => fullOperation,
    selectFieldsWithFullNames,
    (state: AppState) => state.fieldUsageState.fieldUsages ?? List<FieldUsage>(),
    (fullOperation, fields, fieldUsages) => {
        if (fullOperation === undefined || fullOperation.fields === null) {
            return List<FullOperationField>();
        }

        return fullOperation.fields
            .filter(
                (operationField) =>
                    fields.find((field) => field.uuid === operationField.fieldUuid) !== undefined
            )
            .map(({ fieldUuid, ...rest }) => {
                const field = fields.find((field) => field.uuid === fieldUuid) as Field;
                const fieldUsageUuid = getYearFieldUsageUuid(fullOperation.summary.year, field);
                const fieldUsage = fieldUsages.find(({ uuid }) => uuid === fieldUsageUuid);

                return {
                    field,
                    fieldUsage,
                    ...rest,
                };
            })
            .toList()
            .sortBy(
                (operationField) =>
                    `${
                        operationField.fieldUsage !== undefined
                            ? getUsageName(operationField.fieldUsage)
                            : ' '
                    } / ${operationField.field.name.toLowerCase()}`
            );
    }
);

export const selectFullOperationRecordings = createSelector(
    (inputs: List<Input> | null) => inputs,
    (_inputs: List<Input> | null, operationRecordings: Set<Recording> | null) =>
        operationRecordings,
    (inputs, operationRecordings) =>
        inputs && operationRecordings
            ? (operationRecordings
                  .toList()
                  .filter((recording) => !recording.archived)
                  .map((recording) => {
                      const input = inputs.find((input) => input.uuid === recording.inputUuid);
                      return input ? { input, recording } : null;
                  })
                  .filter((x) => !!x) as List<FullOperationRecording>)
            : List<FullOperationRecording>()
);

export const selectCurrentYearOperationsList = createSelector(
    (state: AppState) => state.yearsState.currentYear,
    (state: AppState) => state.operationsState.operations,
    (year, operations) => {
        if (!year || !operations) {
            return List();
        }
        return operations.filter((operation) => operation.summary.year === year).toList();
    }
);

export const selectCurrentYearHasOperations = createSelector(
    selectCurrentYearOperationsList,
    (operations) => operations.size > 0
);

export const selectCurrentYearOperationsForField = createSelector(
    (_, fieldUuid: string) => fieldUuid,
    (state: AppState) => state.operationsState.operations,
    (state: AppState) => selectCurrentYear(state),
    (fieldUuid, operations, year) =>
        defaultTo(
            Map<string, FullOperation>(),
            operations?.filter(
                (operation) =>
                    operation.summary.year === year &&
                    operation.fields?.find((opField) => opField.fieldUuid === fieldUuid) !==
                        undefined
            )
        )
);

/**
 * Selects fields that cannot have pesticide checking run against them because they have a
 * usage that does not have a valid crop code.
 */
export const selectInvalidFieldsForPesticideChecking = createSelector(
    selectFieldsFromUuids,
    selectCurrentYear,
    selectFieldUsagesWithoutCropTypes,
    (fields, year, fieldUsages) => {
        const invalidFieldUsages = fieldUsages.map(getUuid);
        return fields.filter((field) => {
            const yearFieldUsageUuid = getYearFieldUsageUuid(year, field);
            return (
                yearFieldUsageUuid !== undefined && invalidFieldUsages.contains(yearFieldUsageUuid)
            );
        });
    }
);

export const selectInvalidFieldUsagesForPesticideChecking = createSelector(
    selectCurrentYear,
    selectFieldUsagesWithoutCropTypes,
    selectInvalidFieldsForPesticideChecking,
    (year, fieldUsages, fields) => {
        const yearFieldUsageUuids = fields
            .map((field) => getYearFieldUsageUuid(year, field))
            .filter(notNil);
        return fieldUsages.filter((usage) => yearFieldUsageUuids.contains(usage.uuid));
    }
);

export const selectOperations = (state: AppState) => state.operationsState.operations;
