import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import c from 'classnames';
import { selectCurrentFarm } from 'farms/farms-state';
import { useIsFarmOwner } from 'hooks/selectors/src/useIsFarmOwner';
import type { AppState } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

import UsageReporting from './usage/UsageReporting';
import { DataExport } from './DataExport';
import ReportingNav from './ReportingNav';
import ReportingUpgrade from './ReportingUpgrade';

interface ReportingHomeProps {
    // From Redux
    farm: Farm;
    year: number;
}

const ReportingHome = ({ farm, year }: ReportingHomeProps) => {
    const { t } = useTranslation();
    const { isFarmOwner } = useIsFarmOwner();
    const hasReporting = farm.plan.reportingLevel !== 'NO_REPORTING';
    const hasFullReporting = farm.plan.reportingLevel === 'ALL_REPORTING';

    return (
        <section>
            <h1 className="text-center uppercase mb-8">
                {t('reporting_header', { sprintf: [year] })}
            </h1>
            {isFarmOwner ? (
                <div className="flex gap-6">
                    <div className={c(hasFullReporting ? 'w-2/3' : 'mx-auto')}>
                        {hasReporting ? <ReportingNav farm={farm} /> : <ReportingUpgrade />}
                        <DataExport hasReporting={farm.plan.reportingLevel === 'ALL_REPORTING'} />
                    </div>
                    {hasFullReporting && (
                        <div className="w-1/3">
                            <UsageReporting />
                        </div>
                    )}
                </div>
            ) : (
                <p className="text-center text-xl">{t('reporting_member_access')}</p>
            )}
        </section>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    year: selectCurrentYear(state),
}))(ReportingHome);
