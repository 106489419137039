import type { ReactNode } from 'react';
import { clsx } from 'clsx';

import './FieldReportTable.css';

interface FieldReportTableProps {
    headings: ReactNode[];
    children: ReactNode;
    className?: string;
    firstHeaderClass?: string;
}

const FieldReportTable = ({
    headings,
    children,
    className,
    firstHeaderClass = 'w-1/3',
}: FieldReportTableProps) => {
    return (
        <table className={clsx('FieldReportTable', className)}>
            <thead>
                <tr>
                    {headings.map((heading, i) => (
                        <th key={i} className={clsx(i === 0 && firstHeaderClass)}>
                            {heading}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
};

export default FieldReportTable;
