interface FruitIconProps {
    className?: string;
    fill?: string;
}

const FruitIcon = ({ className, fill = '#4da4da' }: FruitIconProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <g transform="translate(-1.888 -7.136)">
                <g transform="translate(2.411 7.642)">
                    <path
                        id="Path_3399"
                        style={{ fill }}
                        d="M55.9,34.7L55.9,34.7c-2-2.9-3.4-6.2-4.2-9.6c-1.5-7.3-5.1-11.7-9.9-12.2
			c0.6-3.2,2.9-5.4,2.6-6.1c-0.3-0.9-0.9-0.7-2.9-1.2c-1.5-0.4-2,4.2-1.8,7.3c-4,0.5-7.1,3.7-8.9,9c-3.2-1.3-6.8-1.2-10,0.4
			c0.8-2.6,2.2-5,4.1-7c0.5-0.5,0.4-1.2,0-1.7c-0.2-0.2-0.4-0.3-0.6-0.3h-0.1c-1.5-0.3-3,0.4-3.9,1.7c-1.4,2.3-2.1,4.9-2.1,7.5
			c-0.1-0.1-0.2-0.1-0.4-0.1C14.5,21,8.4,19.4,3.7,25c-6.7,7.7-1.6,23,2.4,27.6c1.8,1.9,4.4,3,7,2.8c1.9,0,3.8-0.3,5.6-0.9
			c0.2-0.1,0.5-0.1,0.7,0c2.1,0.7,6.7,1.7,10.3-0.1c1.5,0.6,3,1,4.6,1c1.6,0,3.2-0.3,4.6-1c1-0.4,2.1-0.4,3.1,0c1.5,0.6,3,1,4.6,1
			c6.4,0,11.7-5.2,11.7-11.7c0,0,0,0,0,0C58.5,40.5,57.6,37.4,55.9,34.7z M20.4,51.7c-0.8-0.2-1.6-0.2-2.4,0c-1.7,0.5-7.2,2-9.7-1
			c-3.9-4.5-7.6-18-2.4-24c1.5-1.9,3.7-2.9,6.1-2.9c1.6,0,3.1,0.4,4.6,1c0.8,0.3,1.6,0.5,2.4,0.6h0.1c0.9,0,1.8-0.1,2.6-0.5
			c3.1-1.3,7.4-2.1,10.8,1.8c5.2,6,1.5,19.5-2.4,24C27.6,53.7,22,52.3,20.4,51.7z M46.9,52.5c-1.2,0-2.4-0.3-3.5-0.7
			c-1.7-0.7-3.6-0.7-5.3,0c-1.1,0.5-2.3,0.7-3.5,0.7c-0.7,0-1.4-0.1-2.1-0.2c4-5,8.7-19.8,2.2-27.4c-0.5-0.5-0.9-1-1.5-1.5
			c1-3.4,3.2-7.8,7.6-7.8c5.4,0,7.5,7,8.2,10.1c0.9,3.8,2.4,7.3,4.6,10.5l0,0c1.4,2.2,2.2,4.8,2.3,7.4
			C55.8,48.5,51.8,52.5,46.9,52.5L46.9,52.5z"
                    />
                    <path
                        id="Path_3400"
                        style={{ fill }}
                        d="M15.4,19.7c0.6,0.2,1.2-0.2,1.4-0.8c0-0.1,0-0.3,0-0.4c0-1.7-0.6-3.4-1.6-4.8
			c-1.2-1.3-2.7-2.1-4.3-2.5c-0.6-0.2-1.2,0.2-1.4,0.8c0,0.1,0,0.3,0,0.4c0,1.7,0.6,3.4,1.6,4.8C12.3,18.5,13.8,19.4,15.4,19.7z"
                    />
                    <path
                        id="Path_3401"
                        style={{ fill }}
                        d="M36.2,12.1c0.6,0.2,1.2-0.2,1.4-0.8c0-0.1,0-0.3,0-0.4c0-1.7-0.6-3.4-1.6-4.8
			c-1.2-1.3-2.7-2.1-4.3-2.5c-0.6-0.2-1.2,0.2-1.4,0.8c0,0.1,0,0.3,0,0.4c0,1.7,0.6,3.4,1.6,4.8C33,10.8,34.5,11.7,36.2,12.1z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default FruitIcon;
