import { MeasurementUnit } from 'lib/MeasurementUnit';
import { useAppSelector } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import { converter, sqmToHectares } from 'utils/conversion';
import Num from 'view/Num';

import ShortAreaUnit from './ShortAreaUnit';

interface FormattedAreaProps {
    areaSqm: number;
    withUnit?: boolean;
}

const FormattedArea = ({ areaSqm, withUnit = false }: FormattedAreaProps) => {
    const areaUnit = useAppSelector(selectUserAreaMeasurementUnit);
    const area =
        areaUnit === MeasurementUnit.METRIC
            ? sqmToHectares(areaSqm)
            : converter.convertSquareMetresToAcres(areaSqm);

    return (
        <>
            <Num value={area} sigFigs={4} />
            {withUnit && (
                <>
                    {' '}
                    <ShortAreaUnit />
                </>
            )}
        </>
    );
};

export default FormattedArea;
