import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import type { Discussion } from 'discussions/Discussion';
import { DiscussionItemType } from 'discussions/Discussion';
import { createDiscussion } from 'discussions/discussions-api';
import { selectCurrentFarm } from 'farms/farms-state';
import { arrayToMapWithUuid, defaultToNull } from 'lib/fp-helpers';
import { useAction, useActionAndReturn } from 'lib/hooks';
import type Media from 'media/Media';
import { uploadMediaItem } from 'media/media-api';
import type { CreateNoteDTO, WriteNoteDTO, WriteTaskDTO } from 'notes/Note';
import type Note from 'notes/Note';
import {
    convertWriteNoteDTOToNote,
    mergeNoteAndWriteProps,
    mergeNoteAndWriteTaskProps,
    trackNoteChange,
} from 'notes/Note';
import { curry } from 'ramda';
import { useAppDispatch, useAppSelector } from 'system/store';
import { selectUserId } from 'users/user-state';
import { selectCurrentYear, showYearCopyModal } from 'years/years-state';

import { addNewNote, removeNote, setNote, setNoteRead } from './note-actions';
import {
    addFeaturesToNote,
    addFieldsToNote,
    addHerdsToNote,
    addUsersToNote,
    completeTask,
    createNote,
    createTask,
    deleteNote,
    getNotes,
    markNoteRead,
    removeFeaturesFromNote,
    removeFieldsFromNote,
    removeHerdsFromNote,
    removeUsersFromNote,
    toggleArchiveNote,
    updateNote,
    updateTask,
} from './notes-api_new';
import { addNotes, addNoteYearLoaded } from './notes-state';

/**
 * Loads notes for the current year if they haven't already been loaded.
 */
export const useLoadYearNotes = (): [boolean, boolean] => {
    const dispatch = useAppDispatch();
    const addNotesAction = useAction(addNotes);
    const addNoteYearLoadedAction = useAction(addNoteYearLoaded);

    const farm = useAppSelector(selectCurrentFarm);
    const year = useAppSelector(selectCurrentYear);
    const noteYearsLoaded = useAppSelector((state) => state.notesState.noteYearsLoaded);

    const { pending, error, setPromise } = usePromise<Map<string, Note>>((notes) => {
        addNotesAction(notes);
        addNoteYearLoadedAction(year);
        dispatch(showYearCopyModal());
    });

    useEffect(() => {
        if (!noteYearsLoaded.includes(year)) {
            setPromise(getNotes(farm.uuid, year).then(arrayToMapWithUuid));
        }
    }, [year]);

    return [pending, error];
};

export const addCreatedLastActivity = (userId: number, note: Note) =>
    note.set('lastActivity', {
        type: 'created',
        date: new Date(),
        userId,
    });

export const maybeCreateTask = async (
    farm: Farm,
    year: number,
    note: Note,
    task?: { dueDate?: Date }
) => {
    if (task === undefined) {
        return note;
    }
    const dueDate = await createTask(farm.uuid, year, note.uuid, task.dueDate);
    return note.set('task', true).set('dueDate', defaultToNull(dueDate));
};

export const maybeAddDiscussion = async (
    farm: Farm,
    userId: number,
    note: Note,
    comment?: string
): Promise<Discussion | undefined> =>
    comment !== undefined && comment !== ''
        ? {
              id: await createDiscussion(farm.uuid, note.uuid, DiscussionItemType.NOTE, comment),
              createdDate: new Date(),
              createdByUserId: userId,
              comment,
          }
        : undefined;

export const maybeAddMedia = async (
    farm: Farm,
    userId: number,
    note: Note,
    files?: File[]
): Promise<Media[] | undefined> =>
    files !== undefined && files.length > 0
        ? await Promise.all(
              files.map((file) =>
                  uploadMediaItem(farm.uuid, note.uuid, 'NOTE', userId, file, () => {})
              )
          )
        : undefined;

/**
 * This should be used when creating a note. It will handle creating the note & adding any
 * discussions or media items that are present.
 */
export const useCreateNote = (): [
    boolean,
    boolean,
    (dto: CreateNoteDTO, task?: { dueDate?: Date }, comment?: string, files?: File[]) => void,
] => {
    const navigate = useNavigate();
    const farm = useAppSelector(selectCurrentFarm);
    const year = useAppSelector(selectCurrentYear);
    const userId = useAppSelector(selectUserId);
    const addNewNoteAction = useAction(addNewNote);

    const { pending, error, setPromise } = usePromise<{
        note: Note;
        discussion?: Discussion;
        media?: Media[];
    }>(({ note }) => {
        navigate(`/farms/${farm.uuid}/notes/${note.uuid}`, { state: { newNote: true } });
    });

    const addLastActivity = curry(addCreatedLastActivity)(userId);

    const createFn = (
        dto: CreateNoteDTO,
        task?: { dueDate?: Date },
        comment?: string,
        files?: File[]
    ) => {
        setPromise(
            createNote(farm.uuid, year, dto)
                .then((uuid) => convertWriteNoteDTOToNote(uuid, farm.uuid, year, dto))
                .then(trackNoteChange('created'))
                .then(addLastActivity)
                .then((note) => maybeCreateTask(farm, year, note, task))
                .then(async (note) => ({
                    note,
                    discussion: await maybeAddDiscussion(farm, userId, note, comment),
                }))
                .then(async (props) => ({
                    ...props,
                    media: await maybeAddMedia(farm, userId, props.note, files),
                }))
                .then((payload) => {
                    addNewNoteAction(payload);
                    return payload;
                })
        );
    };

    return [pending, error, createFn];
};

/**
 * This should be used when updating note properties.
 * It should not be used to update media or comments on a note.
 */
export const useUpdateNote = (note: Note) => {
    const setNoteAction = useActionAndReturn<Note>(setNote);
    const farm = useSelector(selectCurrentFarm);
    const year = useSelector(selectCurrentYear);

    return (data: Partial<WriteNoteDTO>, newYear?: number) =>
        updateNote(farm.uuid, newYear !== undefined ? newYear : year, note.uuid, data)
            .then((savedNote) => mergeNoteAndWriteProps(note, savedNote, newYear))
            .then(trackNoteChange('updated'))
            .then(setNoteAction);
};

/**
 * This should be used when deleting a note.
 */
export const useDeleteNote = (note: Note | undefined): [boolean, boolean, VoidFunction] => {
    const navigate = useNavigate();
    const farm = useSelector(selectCurrentFarm);
    const removeNoteAction = useAction(removeNote);

    const { pending, error, setPromise } = usePromise<Note>((note) => {
        trackNoteChange('deleted')(note);
        removeNoteAction(note);
        navigate(`/farms/${farm.uuid}/notes`);
    });

    const deleteFn = () => note && setPromise(deleteNote(farm.uuid, note));
    return [pending, error, deleteFn];
};

/**
 * Used to update fields on a note.
 */
export const useUpdateNoteFields = () => {
    const setNoteAction = useActionAndReturn<Note>(setNote);
    const farm = useSelector(selectCurrentFarm);
    const year = useSelector(selectCurrentYear);

    return (note: Note, fieldUuids: string[]) => {
        const toAdd = fieldUuids.filter((uuid) => !note.fieldUuids.includes(uuid));
        const toRemove = note.fieldUuids.filter((uuid) => !fieldUuids.includes(uuid));

        return Promise.all([
            toAdd.length > 0
                ? addFieldsToNote(farm.uuid, year, note.uuid, toAdd)
                : Promise.resolve<string[]>([]),
            toRemove.length > 0
                ? removeFieldsFromNote(farm.uuid, year, note.uuid, toRemove)
                : Promise.resolve<string[]>([]),
        ])
            .then(([toAdd, toRemove]) =>
                note.set(
                    'fieldUuids',
                    note.fieldUuids.concat(toAdd).filter((uuid) => !toRemove.includes(uuid))
                )
            )
            .then(setNoteAction);
    };
};

/**
 * Used to update features on a note.
 */
export const useUpdateNoteFeatures = () => {
    const setNoteAction = useActionAndReturn<Note>(setNote);
    const farm = useSelector(selectCurrentFarm);
    const year = useSelector(selectCurrentYear);

    return (note: Note, featureUuids: string[]) => {
        const toAdd = featureUuids.filter((uuid) => !note.featureUuids.includes(uuid));
        const toRemove = note.featureUuids.filter((uuid) => !featureUuids.includes(uuid));

        return Promise.all([
            toAdd.length > 0
                ? addFeaturesToNote(farm.uuid, year, note.uuid, toAdd)
                : Promise.resolve<string[]>([]),
            toRemove.length > 0
                ? removeFeaturesFromNote(farm.uuid, year, note.uuid, toRemove)
                : Promise.resolve<string[]>([]),
        ])
            .then(([toAdd, toRemove]) =>
                note.set(
                    'featureUuids',
                    note.featureUuids.concat(toAdd).filter((uuid) => !toRemove.includes(uuid))
                )
            )
            .then(setNoteAction);
    };
};

/**
 * Used to update herds on a note.
 */
export const useUpdateNoteHerds = () => {
    const setNoteAction = useActionAndReturn<Note>(setNote);
    const farm = useSelector(selectCurrentFarm);
    const year = useSelector(selectCurrentYear);

    return (note: Note, herdUuids: string[]) => {
        const toAdd = herdUuids.filter((uuid) => !note.herdUuids.includes(uuid));
        const toRemove = note.herdUuids.filter((uuid) => !herdUuids.includes(uuid));

        return Promise.all([
            toAdd.length > 0
                ? addHerdsToNote(farm.uuid, year, note.uuid, toAdd)
                : Promise.resolve<string[]>([]),
            toRemove.length > 0
                ? removeHerdsFromNote(farm.uuid, year, note.uuid, toRemove)
                : Promise.resolve<string[]>([]),
        ])
            .then(([toAdd, toRemove]) =>
                note.set(
                    'herdUuids',
                    note.herdUuids.concat(toAdd).filter((uuid) => !toRemove.includes(uuid))
                )
            )
            .then(setNoteAction);
    };
};

/**
 * Used to update users on a note.
 */
export const useUpdateNoteUsers = () => {
    const setNoteAction = useActionAndReturn<Note>(setNote);
    const farm = useSelector(selectCurrentFarm);
    const year = useSelector(selectCurrentYear);

    return (note: Note, userIds: number[]) => {
        const toAdd = userIds.filter((id) => !note.taggedUserIds.includes(id));
        const toRemove = note.taggedUserIds.filter((id) => !userIds.includes(id));

        return Promise.all([
            toAdd.length > 0
                ? addUsersToNote(farm.uuid, year, note.uuid, toAdd)
                : Promise.resolve<number[]>([]),
            toRemove.length > 0
                ? removeUsersFromNote(farm.uuid, year, note.uuid, toRemove)
                : Promise.resolve<number[]>([]),
        ])
            .then(([toAdd, toRemove]) =>
                note.set(
                    'taggedUserIds',
                    note.taggedUserIds.concat(toAdd).filter((uuid) => !toRemove.includes(uuid))
                )
            )
            .then(setNoteAction);
    };
};

/**
 * Use to toggle the archived state of a note.
 */
export const useToggleNoteArchived = (note: Note) => {
    const setNoteAction = useActionAndReturn<Note>(setNote);
    const farm = useSelector(selectCurrentFarm);
    const year = useSelector(selectCurrentYear);

    return () =>
        toggleArchiveNote(farm.uuid, year, note.uuid, !note.archived)
            .then(() => note.set('archived', !note.archived))
            .then(trackNoteChange('archived'))
            .then(setNoteAction);
};

/**
 * Should be used to mark a note as read
 */
export const useMarkNoteAsRead = (noteUuid: string, year: number) => {
    const dispatch = useAppDispatch();
    const farm = useSelector(selectCurrentFarm);

    return () => {
        // Update UI first & assume that the API call will succeed
        dispatch(setNoteRead(noteUuid));
        return markNoteRead(farm.uuid, year, noteUuid);
    };
};

/**
 * Used to convert a note to a task
 */
export const useCreateTask = (note: Note) => {
    const setNoteAction = useActionAndReturn<Note>(setNote);
    const farm = useSelector(selectCurrentFarm);
    const year = useSelector(selectCurrentYear);

    return () =>
        createTask(farm.uuid, year, note.uuid)
            .then((dueDate) => note.set('dueDate', defaultToNull(dueDate)))
            .then(trackNoteChange(`converted to task`))
            .then((note) => note.set('task', true))
            .then(setNoteAction);
};

/**
 * Used to update task properties for a note.
 */
export const useUpdateTask = (note: Note) => {
    const setNoteAction = useActionAndReturn<Note>(setNote);
    const farm = useSelector(selectCurrentFarm);
    const year = useSelector(selectCurrentYear);
    const userId = useSelector(selectUserId);

    return (data: Partial<WriteTaskDTO>) => {
        const taskIsComplete = note.completedDate !== null && note.completedByUserId !== null;
        const changingToComplete =
            !taskIsComplete && data.completedTimestamp !== null && data.completedByUserId !== null;
        const changingDueDate = data.dueTimestamp !== undefined;
        const changeEvent = changingDueDate
            ? 'updated'
            : changingToComplete
              ? 'completed'
              : 'reopened';

        return updateTask(farm.uuid, year, note.uuid, data)
            .then((savedData) => mergeNoteAndWriteTaskProps(note, savedData, userId))
            .then(trackNoteChange(changeEvent))
            .then(setNoteAction);
    };
};

/**
 * Used to mark a task as complete.
 */
export const useCompleteTask = (note: Note) => {
    const setNoteAction = useActionAndReturn<Note>(setNote);
    const farm = useSelector(selectCurrentFarm);
    const year = useSelector(selectCurrentYear);

    return (data: { completedByUserId: number; completedDate: Date }) =>
        completeTask(farm.uuid, year, note.uuid, {
            completedByUserId: data.completedByUserId,
            completedDate: data.completedDate.valueOf(),
        })
            .then((savedData) =>
                mergeNoteAndWriteTaskProps(note, {
                    completedTimestamp: savedData.completedDate,
                    completedByUserId: savedData.completedByUserId,
                })
            )
            .then(trackNoteChange('completed'))
            .then(setNoteAction);
};
