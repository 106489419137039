import { createFeatureFlagService } from '@fieldmargin/webapp-configurator';
import LocalStorageHelper from 'lib/storage/LocalStorageHelper';

const featureFlags = {
    adminTools: false,
    maintenanceMode: false,
    premiumMaps: true,
    thirtyMhz: false,
    printingOveride: false,
    newFarmMap: false,
};

const descriptions = {
    adminTools: 'Awesome things, just for admins',
    maintenanceMode: 'Show maintenance screen rather than loading the app',
    premiumMaps: 'Show more base map choices from Azure, HERE and Mapbox',
    thirtyMhz: 'Show 30MHz connection',
    newFarmMap: 'Use new improved farm map',
};

export default createFeatureFlagService(featureFlags, descriptions, LocalStorageHelper);
