import type { ComponentProps } from 'react';
import { forwardRef } from 'react';

import styles from './sidebar-cards-wrapper.module.css';

const SidebarCardsWrapper = forwardRef<HTMLDivElement, ComponentProps<'div'>>((props, ref) => {
    return <div className={styles.cardsWrapper} ref={ref} {...props} />;
});

SidebarCardsWrapper.displayName = 'SidebarCardsWrapper';

export { SidebarCardsWrapper };
