import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import { selectCurrentFarm } from 'farms/farms-state';
import { isAllowedRPAImport } from 'lib/localised-functionality/localised-functionality';
import { curry, flow } from 'lodash';
import { useOnboarding } from 'onboarding/onboarding-state';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';

import drawIcon from './draw_icon.svg';
import importIcon from './import_icon.svg';

const FieldOnboarding = () => {
    const dispatch = useDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);
    const { skipOnboarding, nextOnboarding } = useOnboarding();

    const dispatchChangeModal = flow(curry(changeModal), dispatch);

    const handleImportClick = (e: React.MouseEvent) => {
        e.preventDefault();
        dispatchChangeModal({
            name: 'connections',
            props: {
                initialSection: 'fields',
            },
        });
        skipOnboarding();
    };

    const handleRpaClick = (e: React.MouseEvent) => {
        e.preventDefault();
        dispatchChangeModal({
            name: 'connections',
            props: {
                initialSection: 'fields',
                initialIntegration: 'rpa',
            },
        });
        nextOnboarding('rpa');
    };

    const allowedRpaImport = isAllowedRPAImport(farm.geoJsonPoint);
    const rpaSection = allowedRpaImport ? (
        <div className="mr-8">
            <a
                href="#"
                className="bg-blue-50 rounded p-8 flex justify-center items-center w-32 h-32 mb-4 mx-auto"
                onClick={handleRpaClick}
            >
                <img src={importIcon} alt="Import icon" />
            </a>
            <Button onClick={handleRpaClick} className="w-32 whitespace-normal max-h-16 text-xs">
                Import from RPA
            </Button>
        </div>
    ) : null;

    return (
        <div className="bg-fm-blue bg-opacity-75 w-screen h-screen absolute z-20 flex justify-center items-center top-0">
            <div
                className="bg-white text-center relative px-8 pt-6 text-lg"
                style={{ width: allowedRpaImport ? 600 : 500 }}
            >
                <h1 className="text-2xl">Get started by adding your fields</h1>
                <p>
                    Build a map of your farm that you can set uses on and attach tasks or field jobs
                </p>
                <div className="flex justify-center">
                    {rpaSection}
                    <div className="mr-8">
                        <Link
                            to={`/farms/${farm.uuid}/fields/new`}
                            className="bg-blue-50 rounded p-8 flex justify-center items-center w-32 h-32 mb-4 mx-auto"
                        >
                            <img src={drawIcon} alt="Draw icon" />
                        </Link>
                        <Button
                            as={Link}
                            to={`/farms/${farm.uuid}/fields/new`}
                            className="block w-32 whitespace-normal max-h-16 text-xs"
                            onClick={() => nextOnboarding()}
                        >
                            Draw on map
                        </Button>
                    </div>
                    <div>
                        <a
                            href="#"
                            className="bg-blue-50 rounded p-8 flex justify-center items-center w-32 h-32 mb-4 mx-auto"
                            onClick={handleImportClick}
                        >
                            <img src={importIcon} alt="Import icon" />
                        </a>
                        <Button
                            onClick={handleImportClick}
                            className="w-32 whitespace-normal max-h-16 text-xs"
                        >
                            Import boundaries
                        </Button>
                    </div>
                </div>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        skipOnboarding();
                    }}
                    className="text-xs underline block mt-8 mb-2"
                >
                    Skip tutorial
                </a>
            </div>
        </div>
    );
};

export default FieldOnboarding;
