import { useDateFormat } from 'hooks/useDateFormat';
import { useTranslations } from 'hooks/useTranslations';

import type { FieldHistoryItem, FieldHistoryRestItem } from './field-history-generator';

interface FieldHistoryRestDaysItemProps {
    historyItem: FieldHistoryItem;
}

const FieldHistoryRestDaysItem = ({ historyItem }: FieldHistoryRestDaysItemProps) => {
    const { t, plural } = useTranslations();
    const { formatDuration } = useDateFormat();
    const item = historyItem.item as FieldHistoryRestItem;
    const formattedDuration = formatDuration(item.restDuration, {
        format: ['years', 'months', 'days'],
    });

    return (
        <p className="pl-12 pr-5 m-0 ml-2 mt-2">
            <strong>
                {t('field_details_history_rest', {
                    sprintf: [
                        formattedDuration === ''
                            ? plural('distance_days', { count: 0, sprintf: ['0'] })
                            : formattedDuration,
                    ],
                })}
            </strong>
        </p>
    );
};

export default FieldHistoryRestDaysItem;
