import { useAppSelector } from 'system/store';
import { selectIsFarmOwner } from 'team/farm-users-state';

const useIsFarmOwner = () => {
    const isFarmOwner = useAppSelector(selectIsFarmOwner);

    return {
        isFarmOwner,
    };
};

export { useIsFarmOwner };
