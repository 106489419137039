import { useTranslation } from 'react-i18next';
import type { Extent } from '@fieldmargin/webapp-geo';
import c from 'classnames';
import icon from 'components/icons/icon';
import { setMapView } from 'farm-editing/farm-editing-state';
import { preventDefaultAnd } from 'lib/dom';
import { useAppDispatch } from 'system/store';

interface ViewOnMapProps {
    extent: Extent;
    onClick?: VoidFunction;
    className?: string;
}

const ViewOnMap = ({ extent, onClick, className }: ViewOnMapProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const handleClick = preventDefaultAnd(() => {
        dispatch(
            setMapView({
                type: 'extent',
                payload: extent,
            })
        );
        onClick && onClick();
    });

    return (
        <button
            className={c('bare-btn underline flex items-center focus', className)}
            onClick={handleClick}
        >
            {icon('eye', 'dark-blue', 'mr-2')}{' '}
            <span className="text">{t('operation_details_view_on_map')}</span>
        </button>
    );
};

export default ViewOnMap;
