import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import { selectDrawingBlocked, selectDrawingTool } from 'farm-editing/farm-editing-state';
import type { TFunction } from 'i18next';
import type { List } from 'immutable';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';

import arrowSvg from './images/arrow.svg';
import DrawingBlockedMessage from './DrawingBlockedMessage';
import ShapeControl from './ShapeControl';

interface ShapeControlsProps {
    // From parent
    tools?: List<DrawingTool>;
    maxShapes?: number;
    fieldsAttachable?: boolean;

    // From redux
    editingGeoFeatureCollection: any; // GeoFeatureCollection
    drawingTool?: DrawingTool;
    drawingBlocked: boolean;
    selectDrawingTool: typeof selectDrawingTool;

    // From i18next
    t: TFunction;
}

class ShapeControls extends PureComponent<ShapeControlsProps> {
    render() {
        const { maxShapes, editingGeoFeatureCollection, fieldsAttachable, tools, t } = this.props;
        if (maxShapes && editingGeoFeatureCollection.features.size >= maxShapes) {
            return null;
        }

        const headerTxt =
            tools === undefined || tools.size > 1
                ? fieldsAttachable
                    ? t('draw_select_shape_or_fields')
                    : t('draw_select_shape')
                : '';
        return (
            <div className="text-sm mb-4">
                <h3 className="mb-3 font-medium text-xs text-gray-500">{headerTxt}</h3>
                {this.renderControls()}
                {this.renderFooter()}
            </div>
        );
    }

    renderControls() {
        const { tools, drawingTool, drawingBlocked } = this.props;

        if (tools && tools.size === 1 && tools.get(0) === drawingTool) {
            return null;
        }

        const controls = [] as JSX.Element[];
        if (!tools || tools.includes('point')) {
            controls.push(
                <ShapeControl
                    key="point"
                    tool="point"
                    selected={drawingTool === 'point'}
                    onClick={() => this.handleShapeControlClick('point')}
                    disabled={drawingBlocked}
                />
            );
        }
        if (!tools || tools.includes('line')) {
            controls.push(
                <ShapeControl
                    key="line"
                    tool="line"
                    selected={drawingTool === 'line'}
                    onClick={() => this.handleShapeControlClick('line')}
                    disabled={drawingBlocked}
                />
            );
        }
        if (!tools || tools.includes('area')) {
            controls.push(
                <ShapeControl
                    key="area"
                    tool="area"
                    selected={drawingTool === 'area'}
                    onClick={() => this.handleShapeControlClick('area')}
                    disabled={drawingBlocked}
                />
            );
        }

        if (!tools || tools.includes('circle')) {
            controls.push(
                <ShapeControl
                    key="circle"
                    tool="circle"
                    selected={drawingTool === 'circle'}
                    onClick={() => this.handleShapeControlClick('circle')}
                    disabled={drawingBlocked}
                />
            );
        }
        return (
            <>
                <div className="mb-3 flex flex-wrap gap-3">{controls}</div>
                {drawingBlocked && <DrawingBlockedMessage className="my-2" />}
            </>
        );
    }

    renderFooter() {
        const { drawingTool, t } = this.props;
        if (drawingTool) {
            let message = t('draw_on_map_web');
            if (drawingTool === 'point') {
                message = t('place_a_point_on_the_map_web');
            }
            return (
                <div className="text-gray-500 flex gap-2 items-center">
                    <span>{message}</span>
                    <img src={arrowSvg} alt="Arrow pointing right to map" />
                </div>
            );
        }
        return null;
    }

    handleShapeControlClick(control: DrawingTool) {
        const { drawingTool, selectDrawingTool } = this.props;
        selectDrawingTool(drawingTool !== control ? control : null);
    }
}

export default connect(
    (state: AppState) => ({
        editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,
        drawingTool: state.farmEditingState.drawingTool,
        drawingBlocked: selectDrawingBlocked(state),
    }),
    (dispatch) => bindActionCreators({ selectDrawingTool }, dispatch)
)(withTranslation()(ShapeControls));
