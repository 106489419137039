import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { setMapGroupVisibility } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { useDateFormat } from 'hooks/useDateFormat';
import type MapGroup from 'maps/farm/MapGroup';
import { bindActionCreators } from 'redux';
import { getMapVisibility } from 'system/editing/farm-editing-maps';
import type { AppState } from 'system/store';
import type { VisibilityOptions } from 'system/types';

import MapViewOptions from './MapViewOptions';

interface ReduxProps {
    farm: Farm;
    mapGroupVisibility: VisibilityOptions;
    setMapGroupVisibility: (data: { mapGroup: MapGroup; value: VisibilityOptions }) => void;
}

interface ParentProps {
    mapGroup: MapGroup;
}

type MapGroupListItemProps = ParentProps & ReduxProps;

const MapGroupListItem = ({
    farm,
    mapGroup,
    mapGroupVisibility,
    setMapGroupVisibility,
}: MapGroupListItemProps) => {
    const { format } = useDateFormat();
    return (
        <Link
            to={`/farms/${farm.uuid}/maps/${mapGroup.uuid}`}
            className="flex items-center justify-between p-5 focus ring-inset bordered-b"
        >
            <div className="flex-grow">
                <div className="mb-1">{mapGroup.name}</div>
                <div className="text-xs text-gray-500">
                    {format(mapGroup.timestamp, 'do MMMM yyyy')}
                </div>
            </div>
            <MapViewOptions
                mapGroup={mapGroup}
                mapGroupVisibility={mapGroupVisibility}
                setMapGroupVisibility={setMapGroupVisibility}
            />
            <i className="ion-chevron-right ml-4" />
        </Link>
    );
};

export default connect(
    (state: AppState, props: ParentProps) => ({
        farm: selectCurrentFarm(state),
        mapGroupVisibility: getMapVisibility(
            state.farmEditingState.layerVisibility.visibleMapGroups,
            state.farmEditingState.layerVisibility.hiddenMapIntegrations,
            props.mapGroup
        ),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setMapGroupVisibility,
            },
            dispatch
        )
)(MapGroupListItem);
