import { useSelector } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import type { Set } from 'immutable';
import type Input from 'inputs/Input';
import { getInputTypeForOperation, propsToInput } from 'inputs/Input';
import { useGetOrCreateInput } from 'inputs/input-utils';
import { maybeSetOf } from 'lib/immutil';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { OperationType } from 'operations/Operation';
import type Recording from 'operations/Recording';
import { createNewRecording } from 'operations/Recording';
import InputsSelector from 'operations/sidebar/recordings/InputsSelector';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';

interface NewInputsSelectorProps {
    // From parent
    onBack: VoidFunction;
    totalArea: number;
    editingOperationRecordings: Set<Recording>;
    setEditingOperationRecordings: (editingRecordings: Set<Recording>) => void;
    operationType?: string;
}

const NewInputsSelector = ({
    onBack,
    totalArea,
    editingOperationRecordings,
    setEditingOperationRecordings,
    operationType,
}: NewInputsSelectorProps) => {
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);
    const areaMeasurementUnit = useSelector<AppState, MeasurementUnit>(
        selectUserAreaMeasurementUnit
    );
    const getOrCreateInput = useGetOrCreateInput();

    const handleSaveRecording = async (selectedInput: Input, rate: number) => {
        const recording = createNewRecording(rate, '', selectedInput.uuid, areaMeasurementUnit);
        if (editingOperationRecordings !== null) {
            setEditingOperationRecordings(editingOperationRecordings.add(recording));
        }
        return Promise.resolve();
    };

    const handleSaveNewInputAndRecording = async (inputValues: Partial<Input>, rate: number) => {
        const newInput = propsToInput({ ...inputValues, farmUuid: farm.uuid });
        const input = await getOrCreateInput(newInput);
        const recording = createNewRecording(rate, '', input.uuid, areaMeasurementUnit);
        if (editingOperationRecordings !== null) {
            setEditingOperationRecordings(editingOperationRecordings.add(recording));
        }
    };

    const inputTypeFilter =
        operationType !== undefined
            ? getInputTypeForOperation(OperationType[operationType])
            : undefined;

    return (
        <InputsSelector
            onSaveNewRecording={handleSaveRecording}
            onSaveNewInputAndRecording={handleSaveNewInputAndRecording}
            onBack={onBack}
            totalArea={totalArea}
            initialInputTypeFilter={maybeSetOf(inputTypeFilter)}
        />
    );
};

export default NewInputsSelector;
