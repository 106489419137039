import { authorizedRequest, authorizedRequestWithResponse } from '@fieldmargin/webapp-auth';

export enum FarmType {
    ARABLE_CROPS = 'ARABLE_CROPS',
    LIVESTOCK = 'LIVESTOCK',
    FRUIT = 'FRUIT',
    VEGETABLES = 'VEGETABLES',
    VINEYARD = 'VINEYARD',
    OTHER = 'OTHER',
}

export enum OnboardingTutorial {
    MAPPING = 'MAPPING',
    PLANNING = 'PLANNING',
    LIVESTOCK = 'LIVESTOCK',
    SCOUTING = 'SCOUTING',
    INPUTS = 'INPUTS',
    SATELLITE = 'SATELLITE',
    COMMUNICATION = 'COMMUNICATION',
    LOGGING = 'LOGGING',
}

export const saveOnboardingFarmType = (farmUuid: string, farmType: FarmType[]) =>
    authorizedRequest({
        url: `/farms-api/v2/farms/${farmUuid}/onboarding/whatfarm`,
        method: 'post',
        data: { values: farmType },
    });

export const saveOnboardingTutorials = (farmUuid: string, tutorials: OnboardingTutorial[]) =>
    authorizedRequest({
        url: `/farms-api/v2/farms/${farmUuid}/onboarding/done`,
        method: 'post',
        data: { values: tutorials },
    });

export const getOnboardingTutorials = (farmUuid: string) =>
    authorizedRequestWithResponse<{ values: OnboardingTutorial[] }>({
        url: `/farms-api/v2/farms/${farmUuid}/onboarding/done`,
    }).then((response) => (response.status === 204 ? [] : response.data.values));
