import type { PropsWithChildren, ReactNode } from 'react';
import { Children, createContext, useRef } from 'react';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import { clsx } from 'clsx';
import { getElementDistanceFromBottom } from 'lib/dom';
import { useDocumentEventListener, useOutsideComponentClickListener } from 'lib/hooks';
import { uniqueId } from 'lodash';

import styles from './DropdownButton.module.css';

interface DropdownButtonProps extends PropsWithChildren {
    text: ReactNode;
    className?: string;
    disabled?: boolean;
}

export const DropdownButtonContext = createContext<VoidFunction>(() => {});

const DropdownButton = ({ text, children, disabled, className }: DropdownButtonProps) => {
    const id = useRef(uniqueId('dropdown-button-'));
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [menuOpen, toggleMenuOpen] = useToggle(false);

    useOutsideComponentClickListener(
        id.current,
        () => {
            if (menuOpen) toggleMenuOpen();
        },
        [menuOpen]
    );

    useDocumentEventListener(
        'keyup',
        (e: KeyboardEvent) => {
            if (e.key === 'Escape' && menuOpen) toggleMenuOpen();
        },
        [menuOpen]
    );

    return (
        <DropdownButtonContext.Provider value={toggleMenuOpen}>
            <div ref={wrapperRef} data-elementid={id.current}>
                <Button
                    onClick={() => toggleMenuOpen()}
                    className={clsx(styles.dropdownButton, className)}
                    disabled={disabled}
                >
                    {text} <i className={clsx(styles.chevron, 'ion-chevron-down')} />
                    {menuOpen && (
                        <ul
                            className={clsx(
                                styles.list,
                                wrapperRef.current !== null &&
                                    getElementDistanceFromBottom(wrapperRef.current) >
                                        Children.count(children) * 45
                                    ? styles.listDown
                                    : styles.listUp
                            )}
                        >
                            {children}
                        </ul>
                    )}
                </Button>
            </div>
        </DropdownButtonContext.Provider>
    );
};

export default DropdownButton;
