import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { useCurrentNote } from 'hooks/selectors';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { useDeleteNote } from 'notes/note-hooks';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';

const NoteDeletion = () => {
    const { t } = useTranslation();
    const { currentFarm } = useCurrentFarm();
    const { currentNote, canDeleteNote } = useCurrentNote();
    const [pending, error, deleteNote] = useDeleteNote(currentNote);

    const renderContent = () => {
        if (canDeleteNote) {
            const buttonText = t(pending ? 'label_deleting' : 'label_delete');
            return (
                <SidebarModule editing>
                    <p className="text-lg">{t('message_delete_confirmation_note')}</p>
                    <Button variant="danger" onClick={deleteNote} disabled={pending}>
                        {buttonText}
                    </Button>
                </SidebarModule>
            );
        } else {
            return (
                <SidebarModule editing>
                    <p className="text-lg text-error-text">{t('message_delete_unsupported')}</p>
                </SidebarModule>
            );
        }
    };

    return (
        <div className="NoteDeletion scrollable">
            <div className="non-scrolling">
                <SidebarHeader>
                    <SidebarHeaderLink to={`/farms/${currentFarm.uuid}/notes/${currentNote?.uuid}`}>
                        {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                    </SidebarHeaderLink>
                </SidebarHeader>
                {error && (
                    <SidebarError
                        title={t('failed_to_delete')}
                        message={t('something_went_wrong')}
                    />
                )}
            </div>
            <div className="note-info scrolling">
                <SidebarModule editing>
                    <h2 className="mb-0">{currentNote?.name}</h2>
                </SidebarModule>
                {renderContent()}
            </div>
        </div>
    );
};

export { NoteDeletion };
