import type { ReactNode } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    useConnectedJDFarmQuery,
    useDisconnectFarmConnectionMutation,
    useToggleVehicleTrackingMutation,
} from '@notes-web/api';
import { skipToken } from '@reduxjs/toolkit/query';
import clsx from 'clsx';
import { ButtonBase } from 'components/button-base';
import { usePageParams } from 'hooks/usePageParamas';

import { VITE_APP_NAME } from '../../../../../env';
import { Sidebar } from '../Sidebar';

import styles from './JohnDeereFarmOverview.module.css';

interface OverviewCardProps {
    title: string;
    description: string;
    element?: ReactNode;
}

function OverviewCard({ title, description, element }: OverviewCardProps) {
    return (
        <Sidebar.Card className={styles.card}>
            <Sidebar.Title className={styles.cardTitle}>{title}</Sidebar.Title>
            <Sidebar.Description className={styles.cardDescription}>
                {description}
            </Sidebar.Description>
            {element && <div className={styles.cardElement}>{element}</div>}
        </Sidebar.Card>
    );
}

function Disconenct() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { farmUuid } = usePageParams();
    const [triggerFarmDisconnect] = useDisconnectFarmConnectionMutation();

    const onDisconnect = () => {
        if (!farmUuid) return;
        triggerFarmDisconnect({ farmUuid }).then(() => navigate('../select'));
    };

    return (
        <ButtonBase.Root variant="outline-primary" onPress={onDisconnect}>
            {t('label_disconnect')}
        </ButtonBase.Root>
    );
}

function Import() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const onNavigate = () => {
        navigate('../import');
    };
    return (
        <ButtonBase.Root variant="outline-primary" onPress={onNavigate}>
            {t('import')}
        </ButtonBase.Root>
    );
}

function VehicleTracking() {
    const { t } = useTranslation();
    const { farmUuid } = usePageParams();
    const { data: connectedJDFarm, isLoading } = useConnectedJDFarmQuery(
        farmUuid ? { farmUuid } : skipToken
    );
    const [tiggerVehicleTracking] = useToggleVehicleTrackingMutation();
    const isTrackingEnabled =
        connectedJDFarm?.farmConnection.connectionFeatures.includes('vehicle-tracking');

    const value = isTrackingEnabled;

    const onToggleTracking = (isEnabled: boolean) => {
        if (!farmUuid) return;

        tiggerVehicleTracking({ farmUuid, isEnabled });
    };
    return (
        <div className={styles.toggleGroup}>
            <ButtonBase.Root
                className={clsx(styles.buttonToggle, {
                    [styles.toggleLeftSelected]: value,
                    [styles.toggleLeftUnselected]: !value,
                    [styles.toggleSelected]: value,
                    [styles.toggleUnselected]: !value,
                })}
                onPress={() => onToggleTracking(true)}
                isPending={isLoading}
                isDisabled={isLoading || value}
                aria-label={t('on')}
            >
                {t('on')}
            </ButtonBase.Root>

            <ButtonBase.Root
                className={clsx(styles.buttonToggle, {
                    [styles.toggleRightSelected]: !value,
                    [styles.toggleRightUnselected]: value,
                    [styles.toggleSelected]: !value,
                    [styles.toggleUnselected]: value,
                })}
                onPress={() => onToggleTracking(false)}
                isPending={isLoading}
                isDisabled={isLoading || !value}
                aria-label={t('off')}
            >
                {t('off')}
            </ButtonBase.Root>
        </div>
    );
}

function JohnDeereFarmOverview() {
    const { t } = useTranslation();
    const { farmUuid } = usePageParams();
    const { data: connectedJDFarm } = useConnectedJDFarmQuery(farmUuid ? { farmUuid } : skipToken);
    const organizationName = connectedJDFarm?.farmConnection.jdOrganisationName;

    return (
        <Fragment>
            <Sidebar.Card>
                <Sidebar.Heading>Farm overview</Sidebar.Heading>
            </Sidebar.Card>

            <OverviewCard
                title={t('jd_overview_card_1_title')}
                description={t('jd_overview_card_1_description', { sprintf: [organizationName] })}
                element={<Disconenct />}
            />
            <OverviewCard
                title={t('jd_overview_card_2_title')}
                description={t('jd_overview_card_2_description', { sprintf: [VITE_APP_NAME] })}
                element={<Import />}
            />
            <OverviewCard
                title={t('jd_overview_card_3_title')}
                description={t('jd_overview_card_3_description', { sprintf: [VITE_APP_NAME] })}
                element={<VehicleTracking />}
            />
        </Fragment>
    );
}

export { JohnDeereFarmOverview };
