import { useTranslation } from 'react-i18next';
import c from 'classnames';
import type Field from 'fields/Field';
import type Herd from 'herd/Herd';
import { useDateFormat } from 'hooks/useDateFormat';
import SmallPill from 'view/SmallPill';

import HerdTypeColourBar from '../HerdTypeColourBar';

interface HerdPreviewDetailsProps {
    herd: Herd;
    herdField?: Field;
    isLocked?: boolean;
}

const HerdPreviewDetails = ({ herd, herdField, isLocked = false }: HerdPreviewDetailsProps) => {
    const { t } = useTranslation();
    const { format, formatDistanceToNow } = useDateFormat();
    return (
        <div className="flex h-full">
            <HerdTypeColourBar herd={herd} />
            <div className={c('flex flex-col py-2', { 'opacity-50': isLocked })}>
                <span>{herd.name}</span>
                {!herd.archived && herdField && (
                    <div className="my-1">
                        <SmallPill>{herdField.name}</SmallPill>
                    </div>
                )}
                {!herd.archived && herd.herdLocation && (
                    <span className="text-gray-500 text-xxs">
                        {t('livestock_moved', {
                            sprintf: [
                                format(herd.herdLocation.moveDate, 'dd/MM/yy'),
                                formatDistanceToNow(herd.herdLocation.moveDate),
                            ],
                        })}
                    </span>
                )}
            </div>
        </div>
    );
};

export default HerdPreviewDetails;
