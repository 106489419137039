import type Input from 'inputs/Input';
import type { CodedItem, FieldAndCurrentUsage } from 'system/types';

import type { PesticideCheckFilter } from '../details/recording/pesticide-check-state';

import {
    filterByStatus,
    mapPesticideCheckResults,
    type PesticideCheckResponse,
    type PesticideCheckResults,
} from './PesticideCheck';
import PesticideCheckInvalidInput from './PesticideCheckInvalidInput';
import PesticideCheckResultsTable from './PesticideCheckResultsTable';

interface PesticideCheckResultsProps {
    input: Input;
    fields: FieldAndCurrentUsage[];
    pesticideCheckResponse: PesticideCheckResponse;
    filters: PesticideCheckFilter;
}

const PesticideCheckResults = ({
    input,
    fields,
    pesticideCheckResponse,
    filters,
}: PesticideCheckResultsProps) => {
    if (input.pesticideData === undefined) {
        return <PesticideCheckInvalidInput input={input} />;
    }

    // Filter to the given pest and crop stage.
    const filter = hasPestCropStageAndCrop(filters.pest, filters.cropStage, filters.crop);
    let pesticideChecks = pesticideCheckResponse.checks[input.pesticideData.code].find(filter);
    // If no check for the filters is found also look for any check that has no filters because these
    // are inputs that are not allowed on the crop.
    if (pesticideChecks === undefined) {
        pesticideChecks =
            pesticideCheckResponse.checks[input.pesticideData.code].find(hasNoPestCropStageOrCrop);
    }
    if (pesticideChecks === undefined) {
        return null;
    }

    // Sort pesticide checks and filter by status, if any are selected
    const statusFilter = filterByStatus(filters.status);
    pesticideChecks = mapPesticideCheckResults(pesticideChecks, statusFilter);

    return (
        <PesticideCheckResultsTable
            input={input}
            fields={fields}
            startOpen
            pesticideChecks={pesticideChecks}
        />
    );
};

const hasPestCropStageAndCrop =
    (pest = '', cropStage = '', crop: CodedItem = { code: '', name: '' }) =>
    (pesticideChecks: PesticideCheckResults) =>
        pesticideChecks.filters.pests?.includes(pest) &&
        pesticideChecks.filters.cropStages?.includes(cropStage) &&
        pesticideChecks.filters.crops?.includes(crop.code);

const hasNoPestCropStageOrCrop = (pesticideChecks: PesticideCheckResults) =>
    pesticideChecks.filters.pests === null ||
    pesticideChecks.filters.cropStages === null ||
    pesticideChecks.filters.crops === null;

export default PesticideCheckResults;
