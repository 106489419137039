import { useTranslation } from 'react-i18next';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import icon from 'components/icons/icon';

interface PendingTeamMemberProps {
    // From parent
    farmUser: FarmUser;
}

const PendingTeamMember = ({ farmUser }: PendingTeamMemberProps) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="icon">{icon('users', 'grey', 'user-icon')}</div>
            <div className="info pending">
                {t('pending')}
                <div className="email">{farmUser.email}</div>
            </div>
        </>
    );
};

export default PendingTeamMember;
