import type { Farm } from '@fieldmargin/webapp-farms';
import { isCompleteForAllFields } from 'activity/activity-helper';
import type Field from 'fields/Field';
import type { Set } from 'immutable';
import { List, Map } from 'immutable';
import type { InputReportSummary } from 'inputs/InputReportSummary';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type FullOperation from 'operations/FullOperation';
import type { OperationField } from 'operations/OperationField';
import type Recording from 'operations/Recording';
import type Output from 'outputs/Output';
import { getFieldsYearInputSummaryApi } from 'reporting/reporting-api';

export const loadFieldInputReport = async (
    farm: Farm,
    year: number,
    field: Field,
    filter: string | null,
    fullOperations: List<FullOperation>,
    loadOperationsRecordings: (
        farm: Farm,
        year: number,
        fullOperations: List<FullOperation>
    ) => void,
    measurementUnit: MeasurementUnit
) => {
    // First up we'll need to make sure the operations have recordings...
    loadOperationsRecordings(farm, year, fullOperations);
    // Now we can load the report
    return await getFieldsYearInputSummaryApi(
        farm.uuid,
        [field.uuid],
        year,
        filter,
        measurementUnit
    );
};

export interface InputRecordingsByOperation {
    operation: FullOperation;
    recording: Recording;
    areaSqm: number;
}

export const getInputRecordingsByOperation = (
    fieldUuids: Set<string>,
    operations: List<FullOperation>,
    inputReportSummaries: InputReportSummary[]
) =>
    inputReportSummaries.reduce((map, inputReportSummary) => {
        const relativeOps = operations.filter(
            (operation) =>
                operation.recordings !== null &&
                operation.recordings.filter(
                    (recording) => recording.inputUuid === inputReportSummary.uuid
                ).size > 0
        );
        return map.set(
            inputReportSummary.uuid,
            relativeOps.reduce(
                (list, operation) =>
                    (operation.recordings as Set<Recording>).reduce(
                        (nextList, recording) =>
                            !recording.archived &&
                            recording.inputUuid === inputReportSummary.uuid &&
                            recording.rate > 0
                                ? nextList.concat(
                                      (operation.fields as Set<OperationField>)
                                          .filter((opField) =>
                                              fieldUuids.contains(opField.fieldUuid)
                                          )
                                          .map((opField) => ({
                                              operation,
                                              recording,
                                              areaSqm: opField.areaSqm,
                                          }))
                                  )
                                : nextList,
                        list
                    ),
                List<InputRecordingsByOperation>()
            )
        );
    }, Map<string, List<InputRecordingsByOperation>>());

/**
 * Group a list of operations for the given field into those that have inputs and those that
 * have yields. Operations may belong to none, one or both of these groups.
 */
export const groupFieldOperationsByReportType = (
    field: Field,
    subFields: List<Field>,
    operations: List<FullOperation>
) => {
    const fieldUuids = subFields
        .map((f) => f.uuid)
        .unshift(field.uuid)
        .toSet();
    return operations.reduce(
        (map, operation) => {
            const operationField = operation.fields
                ? operation.fields.find((f) => fieldUuids.contains(f.fieldUuid))
                : undefined;

            // Don't add the operation if it isn't linked to this field
            if (!operationField) {
                return map;
            }

            if (operation.recordings && operation.recordings.size > 0) {
                map.withInputs = map.withInputs.push(operation);
            }
            if (operationField.yieldRateHa) {
                map.withYields = map.withYields.push(operation);
            }
            return map;
        },
        { withInputs: List<FullOperation>(), withYields: List<FullOperation>() }
    );
};

interface OutputReportData {
    output: Output;
    yieldRateHa: number;
    yieldTotal: number;
    operations: List<{
        uuid: string;
        name: string;
        field: OperationField;
    }>;
}

export const getOutputReport = (
    operations: List<FullOperation>,
    outputs: List<Output>,
    fieldUuids: Set<string>
): List<OutputReportData> => {
    return operations
        .reduce((map, operation) => {
            if (!operation.fields || !operation.summary.outputUuid) {
                return map;
            }

            const output = outputs.find((o) => o.uuid === operation.summary.outputUuid);
            const opFields = operation.fields.filter((f) => fieldUuids.contains(f.fieldUuid));

            if (!output || opFields.size === 0) {
                return map;
            }

            const totalOpFieldsYieldRateHa = opFields.reduce(
                (total, opField) => total + (opField.yieldRateHa || 0),
                0
            );
            const totalOpFieldsYieldTotal = opFields.reduce(
                (total, opField) => total + (opField.yieldTotal || 0),
                0
            );

            const opFieldOperations = opFields
                .map((opField) => ({
                    uuid: operation.uuid,
                    name: operation.summary.name,
                    field: opField,
                }))
                .toList();

            if (!map.has(output.uuid)) {
                return map.set(output.uuid, {
                    output,
                    yieldRateHa: totalOpFieldsYieldRateHa,
                    yieldTotal: totalOpFieldsYieldTotal,
                    operations: opFieldOperations,
                });
            }

            const current = map.get(output.uuid) as OutputReportData;
            const nextYieldTotal = current.yieldTotal + totalOpFieldsYieldTotal;
            const nextYieldRate = current.yieldRateHa + totalOpFieldsYieldRateHa;
            const nextOperations = current.operations.concat(opFieldOperations);

            return map.set(output.uuid, {
                output,
                yieldRateHa: nextYieldRate,
                yieldTotal: nextYieldTotal,
                operations: nextOperations,
            });
        }, Map<string, OutputReportData>())
        .toList()
        .sortBy((outputReport) => outputReport.output.name);
};

export const filterOperationsByCompleteness = (
    operation: FullOperation,
    filter: string,
    fieldUuids: Set<string>
) => {
    const complete = isCompleteForAllFields(operation, fieldUuids);
    return filter === 'done' ? complete : !complete;
};
