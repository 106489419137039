import type { FarmIntegration } from 'integrations/FarmIntegration';
import type MapGroup from 'maps/farm/MapGroup';
import MapGroupListItem from 'maps/farm/sidebar/list/MapGroupListItem';
import VisibilityToggle from 'sidebar/visibility-toggle/VisibilityToggle';

interface MapsListIntegrationProps {
    farmIntegration: FarmIntegration;
    mapGroups: MapGroup[];
    visible: boolean;
    setVisible: (visible: boolean) => void;
}

const MapsListIntegration = ({
    farmIntegration,
    mapGroups,
    visible,
    setVisible,
}: MapsListIntegrationProps) => {
    const items = mapGroups.map((mapGroup, mapGroupUuid) => {
        return <MapGroupListItem key={mapGroupUuid} mapGroup={mapGroup} />;
    });

    return (
        <div className="MapsListIntegration">
            <div className="flex items-center justify-between bg-gray-100 p-5 separator-bottom">
                {farmIntegration && farmIntegration.logoUri && (
                    <img
                        className="w-12 h-12 mr-5"
                        src={farmIntegration.logoUri}
                        alt={name + ' logo'}
                    />
                )}
                <h4 className="mb-0 text-lg flex-grow">
                    {farmIntegration ? farmIntegration.name : 'Unknown'}
                </h4>

                <VisibilityToggle
                    active={visible ? 'on' : 'off'}
                    onSelect={(value) => setVisible(value === 'on')}
                />
            </div>
            <div className="items">{items}</div>
        </div>
    );
};

export default MapsListIntegration;
