import type { List, Seq } from 'immutable';
import { Record } from 'immutable';
import { greaterThanZero, notNil } from 'lib/fp-helpers';
import { OperationType } from 'operations/Operation';
import { always, both, cond, equals } from 'ramda';

import { InputType } from './InputType';
import Nutrients, { Micronutrients } from './Nutrients';
import type { PesticideData } from './Pesticide';
import { pesticideDataAppearsTheSame } from './Pesticide';

export enum InputUnit {
    GRAMS = 'GRAMS',
    KILOGRAMS = 'KILOGRAMS',
    METRIC_TONNES = 'METRIC_TONNES',
    MILLILITRES = 'MILLILITRES',
    LITRES = 'LITRES',
    OUNCES = 'OUNCES',
    POUNDS = 'POUNDS',
    IMPERIAL_TONS = 'IMPERIAL_TONS',
    FLUID_OUNCES = 'FLUID_OUNCES',
    GALLONS = 'GALLONS',
    COUNT = 'COUNT',
    CUBIC_METER = 'CUBIC_METER',
    PINT = 'PINT',
    QUART = 'QUART',
    MEGALITRE = 'MEGALITRE',
    HUNDRED_WEIGHT = 'HUNDRED_WEIGHT',
}

class Input extends Record({
    uuid: '',
    farmUuid: '',
    fertiliserUuid: undefined as string | undefined,
    name: '',
    type: InputType.OTHER,
    subType: undefined as string | undefined,
    unit: InputUnit.GRAMS,
    archived: false,
    version: -1,
    createdDate: new Date(),
    pesticideData: undefined as PesticideData | undefined,
    nutrients: new Nutrients(),
    micronutrients: new Micronutrients(),
}) {}

export const propsToInput = (props: Partial<Input>) =>
    new Input({
        ...props,
        subType:
            props.pesticideData !== undefined
                ? props.pesticideData.productCategory.name
                : undefined,
    });

export interface InputDTO {
    uuid: string;
    farmUUID: string;
    fertiliserUUID?: string;
    name: string;
    inputType: InputType;
    subType: string | null;
    inputUnit: InputUnit;
    archived: boolean;
    version: number;
    createdDate?: number;
    pesticideDB?: PesticideData;

    nutrients: NutrientDTO | null;
    micronutrients: MicronutrientDTO | null;
}

interface NutrientDTO {
    nitrogen: number | null;
    phosphorus: number | null;
    potassium: number | null;
    magnesium: number | null;
    sulphur: number | null;
    sodium: number | null;
}
interface MicronutrientDTO {
    calcium: number | null;
    boron: number | null;
    manganese: number | null;
    zinc: number | null;
    iron: number | null;
    selenium: number | null;
    molybdenum: number | null;
    copper: number | null;
}

export const deserializeInput = (json: InputDTO) =>
    new Input({
        uuid: json.uuid,
        farmUuid: json.farmUUID,
        fertiliserUuid: json.fertiliserUUID,
        name: json.name,
        type: json.inputType,
        subType: json.subType ?? undefined,
        unit: json.inputUnit,
        archived: json.archived,
        version: json.version,
        createdDate: new Date(json.createdDate as number),
        pesticideData: json.pesticideDB === null ? undefined : json.pesticideDB,
        nutrients: deserializeNutrients(json.nutrients),
        micronutrients: deserializeMicronutrients(json.micronutrients),
    });

export const serializeInput = (input: Input): InputDTO => ({
    uuid: input.uuid,
    farmUUID: input.farmUuid,
    fertiliserUUID: input.fertiliserUuid,
    name: input.name,
    inputType: input.type,
    subType: input.subType ?? null,
    inputUnit: input.unit,
    archived: input.archived,
    version: input.version,
    pesticideDB: input.pesticideData,
    nutrients: input.type === InputType.FERTILIZER ? input.nutrients.toObject() : null,
    micronutrients: input.type === InputType.FERTILIZER ? input.micronutrients.toObject() : null,
});

export const inputsAppearSame = (inputA: Input, inputB: Input) =>
    inputA.name === inputB.name &&
    inputA.type === inputB.type &&
    inputA.unit === inputB.unit &&
    (inputA.pesticideData !== undefined && inputB.pesticideData !== undefined
        ? pesticideDataAppearsTheSame(inputA.pesticideData, inputB.pesticideData)
        : true);

export const inputIsPesticide = (input: Input) => input.pesticideData !== undefined;

export const getActiveInputNutrients = (input: Input) =>
    input.nutrients.toSeq().filter(both(notNil, greaterThanZero)).toKeyedSeq() as Seq.Keyed<
        string,
        number
    >;

export const getActiveInputMicronutrients = (input: Input) =>
    input.micronutrients.toSeq().filter(both(notNil, greaterThanZero)).toKeyedSeq() as Seq.Keyed<
        string,
        number
    >;

export const getAllActiveInputNutrients = (input: Input) =>
    getActiveInputNutrients(input).concat(getActiveInputMicronutrients(input)).toArray();

export const inputHasNutrients = (input: Input) => {
    return (
        getActiveInputNutrients(input).toArray().length > 0 ||
        getActiveInputMicronutrients(input).toArray().length > 0
    );
};

export const getInputTypeForOperation = cond<[OperationType], InputType>([
    [equals(OperationType.FERTILIZE), always(InputType.FERTILIZER)],
    [equals(OperationType.SPRAY), always(InputType.SPRAY)],
    [equals(OperationType.PLANT), always(InputType.SEED)],
]);

export const getInputPesticideCodes = (inputs: List<Input>) =>
    inputs
        .filter(inputIsPesticide)
        .map((input) => input.pesticideData!.code)
        .toArray();

export default Input;

// Prepopulated fertilisers

export interface FertiliserDTO {
    uuid: string;
    name: string;
    nutrients: NutrientDTO | null;
    micronutrients: MicronutrientDTO | null;
}
export const deserializeFertiliser = (json: FertiliserDTO) =>
    new Input({
        name: json.name,
        fertiliserUuid: json.uuid,
        nutrients: deserializeNutrients(json.nutrients),
        micronutrients: deserializeMicronutrients(json.micronutrients),
        type: InputType.FERTILIZER,
    });

const deserializeNutrients = (json: NutrientDTO | null) =>
    json !== null
        ? new Nutrients(
              Object.fromEntries(Object.entries(json).filter(([_, value]) => notNil(value)))
          )
        : new Nutrients();

const deserializeMicronutrients = (json: MicronutrientDTO | null) =>
    json !== null
        ? new Micronutrients(
              Object.fromEntries(Object.entries(json).filter(([_, value]) => notNil(value)))
          )
        : new Micronutrients();
