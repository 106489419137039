import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { AppState } from 'system/store';
import { useAppDispatch } from 'system/store';

import { finishTutorial, setTutorialStep, startTutorial } from './tutorials-state';
import type { TutorialTypes } from './TutorialTypes';

export const useStartTutorial = () => {
    const dispatch = useAppDispatch();
    return (tutorial: TutorialTypes, step: string) => dispatch(startTutorial({ tutorial, step }));
};

export const useFinishTutorialOnMount = (tutorial: TutorialTypes) => {
    const dispatch = useAppDispatch();
    const activeTutorial = useSelector<AppState, string | null>(
        (state) => state.tutorialsState.activeTutorial
    );

    useEffect(() => {
        if (activeTutorial === tutorial) dispatch(finishTutorial(tutorial));
    }, []);
};

export const useFinishTutorial = () => {
    const dispatch = useAppDispatch();
    const activeTutorial = useSelector<AppState, string | null>(
        (state) => state.tutorialsState.activeTutorial
    );

    return (tutorial: TutorialTypes) => {
        if (activeTutorial === tutorial) dispatch(finishTutorial(tutorial));
    };
};

export const useSetTutorialStepOnMount = (step: string) => {
    const dispatch = useAppDispatch();
    const activeTutorial = useSelector<AppState, string | null>(
        (state) => state.tutorialsState.activeTutorial
    );

    useEffect(() => {
        if (activeTutorial !== null) dispatch(setTutorialStep(step));
    }, []);
};

export const useSetTutorialStep = () => {
    const dispatch = useAppDispatch();
    const activeTutorial = useSelector<AppState, string | null>(
        (state) => state.tutorialsState.activeTutorial
    );

    return (step: string | null) => {
        if (activeTutorial !== null) dispatch(setTutorialStep(step));
    };
};
