import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import c from 'classnames';
import { uniqueId } from 'lodash';

interface FilterByMapProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    label: string;
    className?: string;
}

const FilterByMap = ({ checked, onChange, label, className }: FilterByMapProps) => {
    const id = uniqueId('filter-by-map-');
    return (
        <div className={c('text-gray-500', className)}>
            <Checkbox
                checked={checked}
                id={id}
                onChange={(e) => onChange(e.target.checked)}
                label={label}
            />
        </div>
    );
};

export default FilterByMap;
