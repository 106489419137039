import { useTranslation } from 'react-i18next';
import c from 'classnames';
import type { SingleParamVoidFunction } from 'system/types';

import './EditButton.css';

interface EditButtonProps {
    setEditing: SingleParamVoidFunction<boolean>;
    disabled?: boolean;
    className?: string;
}

const EditButton = ({ setEditing, disabled = false, className }: EditButtonProps) => {
    const { t } = useTranslation();
    return (
        <button
            className={c('EditButton bare-btn focus', className)}
            disabled={disabled}
            onClick={() => setEditing(true)}
        >
            {t('edit')}
        </button>
    );
};

export default EditButton;
