import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { usePromise } from '@fieldmargin/webapp-state';
import DancingWheat from '@fieldmargin/webapp-styling/components/dancing-wheat';
import configService from 'lib/config';
import { useAction } from 'lib/hooks';
import { useAppSelector } from 'system/store';
import { setReturnTo } from 'system/ui-state';

import { fetchOrganisation } from './organisation-api';
import type { Organisation } from './organisation-state';
import { setOrganisation } from './organisation-state';

const OrganisationManagement = () => {
    const navigate = useNavigate();
    const params = useParams<{ organisationUuid: string }>();
    const organisation = useAppSelector((state) => state.organisationState.organisation);
    const backPath = useAppSelector((state) => state.uiState.returnTo);
    const clearReturnTo = useAction(setReturnTo);
    const setOrg = useAction(setOrganisation);
    const [loaded, setLoaded] = useState(false);
    const { setPromise } = usePromise<Organisation>(setOrg);

    useEffect(() => {
        if (window.FMOrganisationManagement === undefined) {
            const script = document.createElement('script');
            script.src = configService.get('orgManagementUrl');
            script.onload = () => setLoaded(true);
            document.body.appendChild(script);
        } else {
            window.FMOrganisationManagement.unmount();
            setLoaded(true);
        }

        if (organisation === undefined && params.organisationUuid !== undefined) {
            setPromise(fetchOrganisation(params.organisationUuid));
        }

        return () => {
            clearReturnTo(undefined);
            window.FMOrganisationManagement?.unmount();
        };
    }, []);

    const onRefChange = useCallback(
        (hostEl: HTMLDivElement) => {
            if (
                loaded &&
                organisation !== undefined &&
                hostEl !== null &&
                window.FMOrganisationManagement !== undefined
            ) {
                window.FMOrganisationManagement.unmount();
                window.FMOrganisationManagement.init({
                    hostEl,
                    requester: authorizedRequest,
                    organisation,
                    onBack: () => navigate(backPath ?? '/'),
                });
            }
        },
        [loaded, organisation]
    );
    return (
        <>
            {!loaded && (
                <div className="h-screen flex items-center justify-center">
                    <DancingWheat text="Loading organisation settings" />
                </div>
            )}
            <div ref={onRefChange}></div>
        </>
    );
};

export default OrganisationManagement;
