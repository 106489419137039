import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import type Input from 'inputs/Input';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { renderMeasurementUnitShort } from 'lib/MeasurementUnit';
import { getUnitShortName } from 'lib/unit';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import ShortInputRate from 'view/units/ShortInputRate';

import type { PesticideCheckMaxDoseSeason } from './PesticideCheck';
import { PesticideCheckStatus } from './PesticideCheck';
import PesticideCheckResultIcon from './PesticideCheckResultIcon';

interface MaxDoseSeasonResultProps {
    // From parent
    input: Input;
    check: PesticideCheckMaxDoseSeason;

    // From redux
    areaUnit: MeasurementUnit;
}

const MaxDoseSeasonResult = ({ input, check, areaUnit }: MaxDoseSeasonResultProps) => {
    if (check.status === PesticideCheckStatus.UNKNOWN) {
        return (
            <tr>
                <PesticideCheckResultIcon status={check.status} />
                <td>
                    <Trans
                        i18nKey="pesticide_check_max_dose_season_no_data"
                        components={{ rate: <ShortInputRate input={input} /> }}
                        tOptions={{ sprintf: [check.values.totalDoseSeason] }}
                    >
                        Your total dose for this farm year is {check.values.totalDoseSeason}{' '}
                        <ShortInputRate input={input} />. Please check the label for max dose per
                        season.
                    </Trans>
                </td>
            </tr>
        );
    }

    // If we have a unit mismatch we need to show a different message
    const inputUnit = `${getUnitShortName(input.unit)}/${renderMeasurementUnitShort(
        areaUnit
    )}`.toLowerCase();
    const checkUnit = check.values?.doseUnits.toLowerCase();
    const status = inputUnit !== checkUnit ? PesticideCheckStatus.WARNING : check.status;

    return (
        <tr>
            <PesticideCheckResultIcon status={status} />
            <td>
                {inputUnit !== checkUnit ? (
                    <Trans
                        i18nKey="pesticide_check_max_dose_season_unit_mismatch"
                        components={{ rate: <ShortInputRate input={input} /> }}
                        tOptions={{
                            sprintf: [
                                check.values.totalDoseSeason,
                                check.values.maxDoseSeason,
                                checkUnit !== '-' ? checkUnit.toUpperCase() : null,
                            ],
                        }}
                    >
                        Total quantity applied for this year {check.values.totalDoseSeason}{' '}
                        <ShortInputRate input={input} />. Max dose per season{' '}
                        {check.values.maxDoseSeason}{' '}
                        {checkUnit !== '-' ? checkUnit.toUpperCase() : null}.
                    </Trans>
                ) : check.status === PesticideCheckStatus.FAIL ? (
                    <Trans
                        i18nKey="pesticide_check_max_dose_season_fail"
                        components={{ rate: <ShortInputRate input={input} />, strong: <strong /> }}
                        tOptions={{
                            sprintf: [check.values.totalDoseSeason, check.values.maxDoseSeason],
                        }}
                    >
                        <strong>Max dose per season exceeded:</strong> Total quantity applied for
                        this year of {check.values.totalDoseSeason} <ShortInputRate input={input} />{' '}
                        exceeds {check.values.maxDoseSeason} <ShortInputRate input={input} /> max
                        dose per season
                    </Trans>
                ) : (
                    <Trans
                        i18nKey="pesticide_check_max_dose_season_pass"
                        components={{ rate: <ShortInputRate input={input} />, strong: <strong /> }}
                        tOptions={{
                            sprintf: [check.values.totalDoseSeason, check.values.maxDoseSeason],
                        }}
                    >
                        Total quantity applied for this year of {check.values.totalDoseSeason}{' '}
                        <ShortInputRate input={input} /> within {check.values.maxDoseSeason}{' '}
                        <ShortInputRate input={input} /> max dose per season
                    </Trans>
                )}
            </td>
        </tr>
    );
};

export default connect((state: AppState) => ({
    areaUnit: selectUserAreaMeasurementUnit(state),
}))(MaxDoseSeasonResult);
