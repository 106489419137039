import type { Extent } from '@fieldmargin/webapp-geo';
import { deserialize, extentFromGeo, Projection } from '@fieldmargin/webapp-geo';

const englandPolygon = deserialize(
    {
        type: 'Polygon',
        coordinates: [
            [
                [1.621314323247138, 51.0664923011027],
                [0.12568389986411918, 50.63440629110113],
                [-1.4667646484142267, 50.502008474125546],
                [-3.3957010963287826, 50.41294285404223],
                [-3.7603760459345192, 50.07855366920106],
                [-4.45618894518225, 50.24066680052749],
                [-5.275569719296388, 49.87363349931641],
                [-5.882908818639771, 50.076862630974915],
                [-4.787566444823986, 50.98465308513758],
                [-4.067918320602025, 51.36432060265338],
                [-3.1619002965815537, 51.2838827848378],
                [-2.962954021796641, 51.513817301750976],
                [-3.1361885966093515, 52.0419641559954],
                [-3.18805117155328, 53.52586295325332],
                [-3.7763061209172966, 54.75004980522701],
                [-1.774266998081773, 56.066634140311265],
                [-1.0550181238593803, 54.75885108100985],
                [0.3015535246739813, 54.121613400540355],
                [0.597836949353658, 53.05070650996555],
                [1.7344218481248541, 52.94810788871064],
                [1.936562122906314, 52.363019329038025],
                [1.4976266733808625, 51.79769205641165],
                [1.0927073238186358, 51.62546396535262],
                [0.9444657989789049, 51.47135994709626],
                [1.526372673349785, 51.44454273515544],
                [1.621314323247138, 51.0664923011027],
            ],
        ],
    },
    Projection.LNG_LAT
);

export default extentFromGeo(englandPolygon) as Extent;
