import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { getZIndex } from 'components/maps/model/MapLayerIndex';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import type SubField from 'fields/sub-fields/SubField';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

export const selectSubFields = createSelector(
    (state: AppState) => state.subFieldsState.selectedTool,
    (state: AppState) => state.subFieldsState.subFields,
    (state: AppState) => state.subFieldsState.selectedSubFields,
    (selectedTool, subFields, selectedSubFields) => {
        return subFields
            .filter((subField) => subField.field.geoJson !== null)
            .flatMap((subField) =>
                subFieldToGeoFeature(
                    subField,
                    selectedTool === undefined &&
                        selectedSubFields.size === 1 &&
                        selectedSubFields.contains(subField.id),
                    selectedTool !== undefined && selectedSubFields.contains(subField.id)
                )
            );
    }
);

const subFieldToGeoFeature = (subField: SubField, editable: boolean, passiveEditMode: boolean) => {
    const geoFeatureCollection = subField.field.geoJson as GeoFeatureCollection;

    return geoFeatureCollection.features.map((geoFeature) => {
        return geoFeature.set(
            'properties',
            OLFeatureProperties({
                type: 'sub-field',
                editable,
                passiveEditMode,
                colour: subField.colour,
                pointScale: 9,
                strokeWeight: 3,
                strokeOpacity: 1,
                fillOpacity: 0.75,
                zIndex: getZIndex(true, 'sub-field', geoFeature.geometry.type),
                label: subField.field.name,
            })
        );
    });
};
