import type { ReactNode } from 'react';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import { path, pipe } from 'ramda';
import type { SingleParamVoidFunction } from 'system/types';
import { DropdownItem } from 'view/dropdown/Dropdown';

interface CheckboxFilterItemProps {
    id: string;
    label: ReactNode;
    selected: boolean;
    onChange: SingleParamVoidFunction<boolean>;
}
const CheckboxFilterItem = ({ id, label, selected, onChange }: CheckboxFilterItemProps) => {
    return (
        <DropdownItem>
            <Checkbox
                id={id}
                checked={selected}
                onChange={pipe(path(['target', 'checked']), onChange)}
                label={label}
            />
        </DropdownItem>
    );
};
export default CheckboxFilterItem;
