import c from 'classnames';

const TilesMessageControl = ({ loading, error }) => {
    if (loading || error) {
        const content = loading ? 'Loading map tiles...' : 'Error loading map tiles';
        return (
            <div className="bg-fm-blue py-2 px-4 h-10 no-print absolute z-10 top-32 left-10">
                <p className={c('m-0 text-base', error ? 'text-red-500' : 'text-white')}>
                    {content}
                </p>
            </div>
        );
    }
    return null;
};

export default TilesMessageControl;
