import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { InputNutrientSymbol } from 'inputs/InputNutrients';
import { flatten, uniq } from 'ramda';
import ShortAreaUnit from 'view/units/ShortAreaUnit';
import ShortUnit from 'view/units/ShortUnit';

import { getNutrientUnitsMap } from './nutrient-report-utils';
import type { NutrientReport } from './NutrientReport';
import NutrientReportRow from './NutrientReportRow';

interface NutrientReportTableProps {
    nutrientReports: NutrientReport[];
    selectedNutrients: string[];
}

const NutrientReportTable = ({ nutrientReports, selectedNutrients }: NutrientReportTableProps) => {
    const { t } = useTranslation();
    const nutrientUnits = getNutrientUnitsMap(selectedNutrients, nutrientReports);
    const allUnits = uniq(flatten(Object.values(nutrientUnits))).length;
    // Every possible unit * all selected units + 3 columns for usage, field name and area
    const usageColSpan = allUnits * selectedNutrients.length + 3;
    return (
        <table className="ReportingTable border-solid border-0 border-b border-gray-border">
            <thead>
                <tr>
                    <th>{t('usage')}</th>
                    <th>{t('field')}</th>
                    <th>
                        {t('area')} (<ShortAreaUnit />)
                    </th>
                    {selectedNutrients.map((nutrient) => (
                        <Fragment key={nutrient}>
                            {nutrientUnits[nutrient].map((unit) => (
                                <th key={unit}>
                                    <InputNutrientSymbol nutrient={nutrient} />{' '}
                                    <ShortUnit unit={unit} />/<ShortAreaUnit />
                                </th>
                            ))}
                        </Fragment>
                    ))}
                </tr>
            </thead>
            <tbody>
                {nutrientReports.map((nutrientReport) => (
                    <NutrientReportRow
                        key={nutrientReport.fieldUsage}
                        nutrientReport={nutrientReport}
                        selectedNutrients={selectedNutrients}
                        nutrientUnits={nutrientUnits}
                        usageColSpan={usageColSpan}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default NutrientReportTable;
