const MapLayerIndex = {
    LAYER_BASEMAP: 1,
    LAYER_CUSTOM_START: 100,
    LAYER_CUSTOM_END: 199,
    LAYER_MAIN: 200,
    LAYER_FIELDS: 201,
    LAYER_NOTES: 202,
    LAYER_SENSORS: 203,
    LAYER_VEHICLES: 204,
    LAYER_POINT_FEATURES: 205,
    LAYER_HERDS: 206,
    LAYER_USER_LOCATIONS: 207,
    LAYER_PASSIVE_EDIT: 208,
    LAYER_EDITING: 209,
    LAYER_SUB_FIELDS: 210,

    FIELD_POLYGON: 11,
    FIELD_LINE_STRING: 12,
    FIELD_POINT: 13,

    FEATURE_POLYGON: 21,
    FEATURE_LINE_STRING: 22,
    FEATURE_POINT: 23,

    NOTE_POLYGON: 31,
    NOTE_LINE_STRING: 32,
    NOTE_POINT: 33,

    NOTE_FIELD_POLYGON: 41,

    ACTIVE_POLYGON: 51,
    ACTIVE_LINE_STRING: 52,
    ACTIVE_POINT: 53,
};

export const getZIndex = (isActiveSection: boolean, itemType: string, geoFeatureType: string) => {
    if (isActiveSection) {
        if (geoFeatureType === 'Polygon') {
            return MapLayerIndex.ACTIVE_POLYGON;
        } else if (geoFeatureType === 'LineString') {
            return MapLayerIndex.ACTIVE_LINE_STRING;
        } else if (geoFeatureType === 'Point') {
            return MapLayerIndex.ACTIVE_POINT;
        }
    }
    if (itemType === 'feature') {
        if (geoFeatureType === 'Polygon') {
            return MapLayerIndex.FEATURE_POLYGON;
        } else if (geoFeatureType === 'LineString') {
            return MapLayerIndex.FEATURE_LINE_STRING;
        } else if (geoFeatureType === 'Point') {
            return MapLayerIndex.FEATURE_POINT;
        }
    } else if (itemType === 'note') {
        if (geoFeatureType === 'Polygon') {
            return MapLayerIndex.NOTE_POLYGON;
        } else if (geoFeatureType === 'LineString') {
            return MapLayerIndex.NOTE_LINE_STRING;
        } else if (geoFeatureType === 'Point') {
            return MapLayerIndex.NOTE_POINT;
        }
    } else if (itemType === 'field') {
        if (geoFeatureType === 'Polygon') {
            return MapLayerIndex.FIELD_POLYGON;
        } else if (geoFeatureType === 'LineString') {
            return MapLayerIndex.FIELD_LINE_STRING;
        } else if (geoFeatureType === 'Point') {
            return MapLayerIndex.FIELD_POINT;
        }
    }
};

export default MapLayerIndex;
