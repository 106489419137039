export enum MeasurementUnit {
    METRIC = 'metric',
    IMPERIAL = 'imperial',
}

const unitDisplayMap = {
    [MeasurementUnit.METRIC]: {
        short: () => 'Ha',
        long: (plural: boolean) => (plural ? 'Hectares' : 'Hectare'),
    },
    [MeasurementUnit.IMPERIAL]: {
        short: () => 'Ac',
        long: (plural: boolean) => (plural ? 'Acres' : 'Acre'),
    },
};

export const renderMeasurementUnitShort = (unit: MeasurementUnit) => unitDisplayMap[unit].short();

export const renderMeasurementUnitLong = (unit: MeasurementUnit, plural = true) =>
    unitDisplayMap[unit].long(plural);

export const prefersImperialUnits = (measurementUnit: MeasurementUnit) =>
    measurementUnit === MeasurementUnit.IMPERIAL;
