import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { default as c, default as classNames } from 'classnames';
import icon from 'components/icons/icon';
import type { Colour } from 'lib/colours';
import { colourList } from 'lib/colours';

interface ColourPickerProps {
    selectedColour: string;
    onChange: (colour: string) => void;
    colours?: Colour[];
    className?: string;
}

const ColourPicker = ({
    selectedColour,
    onChange,
    colours = colourList,
    className,
}: ColourPickerProps) => {
    const { t } = useTranslation();
    return (
        <div className={classNames('ColourPicker flex flex-wrap box-border', className)}>
            {colours.map(({ name, colour }) => {
                const isWhite = name.toLowerCase() === 'white';
                const selected =
                    selectedColour && selectedColour.toLowerCase() === colour.toLowerCase();

                const style = {
                    backgroundColor: colour,
                };

                const tick = selected ? icon('tick', isWhite ? 'blue' : 'white') : null;

                const handleColourChange = (e?: MouseEvent) => {
                    e && e.preventDefault();
                    onChange(colour);
                };

                return (
                    <button
                        className="bare-btn w-14 mb-2 mr-6 text-center text-xxs font-medium flex flex-col items-center focus"
                        key={name}
                        onClick={handleColourChange}
                    >
                        <span className="w-7 h-7 inline-block mb-2">
                            <span
                                className={c(
                                    'h-full w-full rounded hover:scale-110 flex items-center justify-center border-solid border-transparent',
                                    isWhite ? 'border-gray-200' : null
                                )}
                                style={style}
                            >
                                {tick}
                            </span>
                        </span>
                        <span>{t(`colour_${name.toLowerCase()}`)}</span>
                    </button>
                );
            })}
        </div>
    );
};

export default ColourPicker;
