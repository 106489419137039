import type { List } from 'immutable';
import { Radio, RadioGroup, useFieldState } from 'informed';
import { uniqueId } from 'lodash';
import type { SingleParamVoidFunction } from 'system/types';
import ErrorMessage from 'view/ErrorMessage';
import { required } from 'view/form/validations';
import type { BadgeItemWithIdLike } from 'view/molecules/badge-item/BadgeItem';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';

interface BadgeRadioGroupProps {
    field: string;
    options: List<BadgeItemWithIdLike>;
    allowNull: boolean;
    onChange?: SingleParamVoidFunction<BadgeItemWithIdLike>;
}

const BadgeRadioGroup = ({ field, options, allowNull, onChange }: BadgeRadioGroupProps) => {
    const { error } = useFieldState(field);

    let radios = options.map((item) => {
        const inputId = uniqueId('badge-item-');
        return (
            <div className="flex items-center mb-3" key={item.id}>
                <div className="relative">
                    <Radio
                        className="fancy-radio"
                        id={inputId}
                        value={item.id}
                        onChange={() => onChange && onChange(item)}
                    />
                    <label className="h-5" htmlFor={inputId} />
                </div>
                <label htmlFor={inputId}>
                    <BadgeItem item={item} />
                </label>
            </div>
        );
    });

    if (allowNull) {
        const unsetInputId = uniqueId('badge-item-');
        radios = radios.unshift(
            <div className="flex items-center mb-3" key="unset">
                <div className="relative">
                    <Radio className="fancy-radio" id={unsetInputId} value={undefined} />
                    <label className="h-5" htmlFor={unsetInputId} />
                </div>
                <label className="italic text-gray-500" htmlFor={unsetInputId}>
                    -- Not set --
                </label>
            </div>
        );
    }

    return (
        <div className="relative">
            <RadioGroup
                field={field}
                validate={allowNull ? () => undefined : required('Required')}
                validateOnChange
            >
                {radios}
            </RadioGroup>
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </div>
    );
};

export default BadgeRadioGroup;
