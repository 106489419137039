import OutsidePage from '../OutsidePage';

import RenderOSButton from './RenderOSButton';

import styles from './MobileAppDownload.module.css';

interface MobileAppDownloadProps {
    os: string;
    onContinue?: VoidFunction;
}

const MobileAppDownload = ({ os, onContinue }: MobileAppDownloadProps) => (
    <OutsidePage>
        <h2>Your farm in your pocket.</h2>
        <p>For the best mobile experience please download our app below.</p>
        <RenderOSButton OS={os} />
        {onContinue && (
            <div className={styles.continueLinkWrapper}>
                <a
                    className={styles.continueLink}
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        onContinue();
                    }}
                >
                    Click here to continue to our web app
                </a>
            </div>
        )}
    </OutsidePage>
);

export default MobileAppDownload;
