import { useTranslation } from 'react-i18next';

const BulkFieldEditArchivedHeader = () => {
    const { t } = useTranslation();
    return (
        <tr className="BulkFieldEditArchivedHeader">
            <th>{t('archived')}</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>{t('unarchive')}</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
        </tr>
    );
};

export default BulkFieldEditArchivedHeader;
