import { useTranslation } from 'react-i18next';
import { Radio } from 'components/radio';
import { listModeState, updateActiveJobs } from 'store/slices/notes.slice';
import { useAppDispatch, useAppSelector } from 'system/store';

function ToggleActivity() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const all = t('all');
    const incomplete = t('filters_incomplete');
    const listMode = useAppSelector(listModeState);

    const onChangeHandler = (value: string) => {
        dispatch(updateActiveJobs(value));
    };
    return (
        <Radio.Group
            defaultValue="all"
            value={listMode}
            onChange={onChangeHandler}
            orientation="horizontal"
            aria-label="Toggle Activity"
        >
            <Radio.Input value="all">{all}</Radio.Input>
            <Radio.Input value="tasks"> {incomplete}</Radio.Input>
        </Radio.Group>
    );
}

export { ToggleActivity };
