import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { List } from 'immutable';
import { memoize } from 'lodash';
import { map } from 'ramda';

import type { FertiliserDTO, InputDTO } from './Input';
import type Input from './Input';
import { deserializeFertiliser, deserializeInput, serializeInput } from './Input';

export const getInputsApi = (farmUuid: string) =>
    authorizedRequest<InputDTO[]>({
        url: `/notes-api/v2/farms/${farmUuid}/operations/inputs/`,
    }).then((inputs) => List(inputs.map(deserializeInput)));

export const saveInputApi = (farmUuid: string, input: Input) =>
    authorizedRequest<InputDTO>({
        method: 'post',
        url: `/notes-api/v2/farms/${farmUuid}/operations/inputs/`,
        data: serializeInput(input),
    }).then(deserializeInput);

export interface InputCostDTO {
    operationInputUUID: string;
    unitCost: number | null;
    year: number;
}

export const saveInputCosts = (farmUuid: string, year: number, costs: InputCostDTO[]) =>
    authorizedRequest<void>({
        method: 'post',
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/inputs/`,
        data: costs,
    });

export const getInputCosts = (farmUuid: string, year: number) =>
    authorizedRequest<InputCostDTO[]>({
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/inputs/`,
    });

export const getPrepopulatedFertilisers = memoize(() =>
    authorizedRequest<FertiliserDTO[]>({
        url: `/notes-api/fertiliser`,
    }).then(map(deserializeFertiliser))
);
