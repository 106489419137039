import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import { createNoteChatLog } from 'chat/chat-generator';
import { DiscussionList } from 'components/discussion-list';
import type { Discussion } from 'discussions/Discussion';
import { DiscussionItemType } from 'discussions/Discussion';
import { getItemDiscussions } from 'discussions/discussions-api';
import { setItemDiscussions } from 'discussions/discussions-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { defaultToEmptyArray } from 'lib/fp-helpers';
import type Media from 'media/Media';
import { getMediaThumbnail190Url } from 'media/Media';
import { deleteMediaItem, getItemMedia } from 'media/media-api';
import { removeItemMediaItem, setItemMedia } from 'media/media-state';
import type Note from 'notes/Note';
import { bindActionCreators } from 'redux';
import EmptyState from 'sidebar/modules/EmptyState';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';

import commentsEmpty from './comments-empty.png';

interface NoteDiscussionListProps {
    // From parent
    note: Note;

    // From redux
    farm: Farm;
    noteMedia?: Media[];
    noteDiscussions?: Discussion[];
    changeModal: typeof changeModal;
    setItemMedia: typeof setItemMedia;
    removeItemMediaItem: typeof removeItemMediaItem;
    setItemDiscussions: typeof setItemDiscussions;
}

const NoteDiscussionList = ({
    note,
    farm,
    noteMedia,
    noteDiscussions,
    changeModal,
    setItemMedia,
    removeItemMediaItem,
    setItemDiscussions,
}: NoteDiscussionListProps) => {
    const { t } = useTranslation();
    const { pending: mediaPending, setPromise: setMediaPromise } = usePromise<Media[]>((media) =>
        setItemMedia({ uuid: note.uuid, media })
    );
    const { pending: discussionsPending, setPromise: setDiscussionsPromise } = usePromise<
        Discussion[]
    >((discussions) => setItemDiscussions({ uuid: note.uuid, discussions }));

    useEffect(() => {
        if (noteMedia === undefined) {
            setMediaPromise(getItemMedia(farm.uuid, note.uuid, 'NOTE'));
            setDiscussionsPromise(
                getItemDiscussions(farm.uuid, note.uuid, DiscussionItemType.NOTE)
            );
        }
    }, [note, noteMedia]);

    const handleRemoveMedia = (mediaId: string) =>
        deleteMediaItem(farm.uuid, mediaId).then((mediaId) =>
            removeItemMediaItem({ uuid: note.uuid, mediaId })
        );

    const chatLog = createNoteChatLog(
        defaultToEmptyArray(noteMedia),
        defaultToEmptyArray(noteDiscussions),
        getMediaThumbnail190Url,
        (imageIndex) => {
            changeModal({
                name: 'activity-media',
                props: {
                    media: noteMedia,
                    initialMediaIndex: imageIndex,
                    onDelete: handleRemoveMedia,
                },
            });
        }
    );

    const pending = mediaPending || discussionsPending;

    return (
        <>
            {chatLog.dayChatGroups.size === 0 && (
                <EmptyState image={commentsEmpty}>
                    <p>{t('activity_log_empty_comments_title')}</p>
                </EmptyState>
            )}

            {chatLog !== undefined && <DiscussionList chatLog={chatLog} />}
            {chatLog === undefined && pending && (
                <p>
                    <em>{t('loading')}</em>
                </p>
            )}
        </>
    );
};

export default connect(
    (state: AppState, { note }: { note: Note }) => ({
        note,
        farm: selectCurrentFarm(state),
        noteMedia: state.mediaState.get(note.uuid),
        noteDiscussions: state.discussionsState.get(note.uuid),
    }),
    (dispatch) =>
        bindActionCreators(
            { changeModal, setItemMedia, removeItemMediaItem, setItemDiscussions },
            dispatch
        )
)(NoteDiscussionList);
