import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import c from 'classnames';
import ErrorMessage from 'view/ErrorMessage';
import OutOfDateErrorMessage from 'view/OutOfDateErrorMessage';

interface EditableActionsProps {
    disabled: boolean;
    error: boolean;
    outOfDate?: boolean;
    setEditing: (value: boolean) => void;
    small?: boolean;
    className?: string;
}

const EditableActions = ({
    disabled,
    error,
    outOfDate = false,
    setEditing,
    small = false,
    className,
}: EditableActionsProps) => {
    const { t } = useTranslation();
    return (
        <div className={c('mt-4', { small }, className)}>
            <div className="flex gap-2">
                <Button
                    variant="danger-outline"
                    className="flex-grow"
                    type="button"
                    onClick={() => setEditing(false)}
                >
                    {t('cancel')}
                </Button>
                <SubmitButton disabled={disabled} className="flex-grow">
                    {t('save')}
                </SubmitButton>
            </div>
            {error && <ErrorMessage className="mt-3">{t('something_went_wrong')}</ErrorMessage>}
            {outOfDate && <OutOfDateErrorMessage className="mt-3" />}
        </div>
    );
};

export default EditableActions;
