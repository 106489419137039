import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';

interface MarkCompleteButtonProps {
    complete: boolean;
    pending: boolean;
    onToggle: VoidFunction;
}

const MarkCompleteButton = ({ complete, pending, onToggle }: MarkCompleteButtonProps) => {
    const { t } = useTranslation();

    if (!complete) {
        return (
            <Button onClick={onToggle} disabled={pending}>
                {pending ? t('saving') : t('create_task_mark_done')}
            </Button>
        );
    }
    return (
        <Button variant="outline" onClick={onToggle} disabled={pending}>
            {icon('tick', 'blue')} {pending ? t('saving') : t('completed')}
        </Button>
    );
};

export default MarkCompleteButton;
