import type { ComponentProps, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import styles from './OperationRecord.module.css';

function Header() {
    const { t } = useTranslation();

    return (
        <div className={styles.header}>
            <span className={styles.headerName}>{t('name')}</span>
            <span className={styles.headerType}>{t('type')}</span>
            <span className={styles.headerRate}>
                {t('operation_details_input_rate_empty')}
                <ShortAreaUnit />
            </span>
            <span className={styles.headerTotal}>{t('total')}</span>
        </div>
    );
}

function List({ children }: PropsWithChildren) {
    return <ul className={styles.list}>{children}</ul>;
}

function Item({ ...props }: ComponentProps<'li'>) {
    return <li className={styles.li} {...props} />;
}

function Summary({ ...props }: ComponentProps<'summary'>) {
    return <summary className={styles.summary} {...props} />;
}

function SummaryName({ ...props }: ComponentProps<'div'>) {
    return <div className={styles.summaryName} {...props} />;
}

function SummaryType({ ...props }: ComponentProps<'span'>) {
    return <span className={styles.summaryType} {...props} />;
}

function SummaryRate({ ...props }: ComponentProps<'span'>) {
    return <span className={styles.summaryRate} {...props} />;
}

function SummaryTotal({ ...props }: ComponentProps<'span'>) {
    return <span className={styles.summaryTotal} {...props} />;
}

function SummaryChevron() {
    return <i className="ml-auto ion-chevron-down text-fm-sky text-xl" />;
}

function Details({ ...props }: ComponentProps<'details'>) {
    return <details className={styles.details} {...props} />;
}

function Table({ ...props }: ComponentProps<'table'>) {
    return <table className={styles.table} {...props} />;
}
function TableBody({ ...props }: ComponentProps<'tbody'>) {
    return <tbody {...props} />;
}
function TableRow({ ...props }: ComponentProps<'tr'>) {
    return <tr {...props} />;
}

function TableHeaderCell({ ...props }: ComponentProps<'th'>) {
    return <th className={clsx(styles.th, props.className)} {...props} />;
}
function TableCell({ ...props }: ComponentProps<'td'>) {
    return <td className={clsx(styles.td, props.className)} {...props} />;
}

function Actions({ ...props }: ComponentProps<'div'>) {
    return <div className={styles.actions} {...props} />;
}

List.displayName = 'OperationRecord.List';
Item.displayName = 'OperationRecord.Item';
Summary.displayName = 'OperationRecord.Summary';
SummaryName.displayName = 'OperationRecord.SummaryName';
SummaryType.displayName = 'OperationRecord.SummaryType';
SummaryRate.displayName = 'OperationRecord.SummaryRate';
SummaryTotal.displayName = 'OperationRecord.SummaryTotal';
SummaryChevron.displayName = 'OperationRecord.SummaryChevron';
Details.displayName = 'OperationRecord.Details';
Table.displayName = 'OperationRecord.Table';
TableBody.displayName = 'OperationRecord.TableBody';
TableRow.displayName = 'OperationRecord.TableRow';
TableHeaderCell.displayName = 'OperationRecord.TableHeaderCell';
TableCell.displayName = 'OperationRecord.TableCell';
Actions.displayName = 'OperationRecord.Actions';

export const OperationRecord = {
    Header,
    List,
    Item,
    Summary,
    SummaryName,
    SummaryType,
    SummaryRate,
    SummaryTotal,
    SummaryChevron,
    Details,
    Table,
    TableBody,
    TableRow,
    TableHeaderCell,
    TableCell,
    Actions,
};
