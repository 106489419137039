import { useState } from 'react';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import type { List } from 'immutable';

import EditButton from '../common/EditButton';
import SidebarModule from '../SidebarModule';

import ShapeInformation from './components/ShapeInformation';
import EditableShapesForm from './EditableShapesForm';

interface EditableShapesProps {
    value: GeoFeatureCollection;
    tools?: List<DrawingTool>;
    maxShapes?: number;
    includePerimeter?: boolean;
    startEditing: VoidFunction;
    stopEditing: VoidFunction;
    onSave: (value: GeoFeatureCollection) => Promise<any>;
}

const EditableShapes = ({
    value,
    tools,
    maxShapes,
    includePerimeter = false,
    startEditing,
    onSave,
}: EditableShapesProps) => {
    const [editing, setEditing] = useState(false);

    return (
        <SidebarModule className="EditableShapes" editing={editing}>
            {!editing ? (
                <>
                    <EditButton
                        setEditing={(value) => {
                            setEditing(value);
                            startEditing();
                        }}
                    />
                    <ShapeInformation
                        geoFeatureCollection={value}
                        includePerimeter={includePerimeter}
                    />
                </>
            ) : (
                <EditableShapesForm
                    onSave={onSave}
                    tools={tools}
                    maxShapes={maxShapes}
                    setEditing={setEditing}
                    startEditing={startEditing}
                />
            )}
        </SidebarModule>
    );
};

export default EditableShapes;
