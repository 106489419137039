import type { Farm } from '@fieldmargin/webapp-farms';
import type { FieldValue } from 'firebase/firestore';
import { serverTimestamp } from 'firebase/firestore';
import { Record } from 'immutable';
import type configService from 'lib/config';

const ChatMessage = Record({
    uuid: '',
    createdByUserId: -1,
    createdDate: new Date(),
    serverCreatedDate: new Date(),
    type: '', // 'text', 'image', 'pdf', 'file'
    text: '',
    fileUuid: '',
    fileSize: -1,
    fileName: '',
});
interface ChatMessage extends ReturnType<typeof ChatMessage> {}

export interface ChatMessageDTO {
    uuid: string;
    createdByUserId: number;
    createdDate: Date;
    serverCreatedDate?: Date | FieldValue;
    type: string;
    text: string;
    fileUUID: string;
    fileSize: number;
    fileName: string;
}

export const serializeChatMessage = (message: ChatMessage): ChatMessageDTO => ({
    uuid: message.uuid,
    createdByUserId: message.createdByUserId,
    createdDate: message.createdDate,
    serverCreatedDate: message.serverCreatedDate ? message.serverCreatedDate : serverTimestamp(),
    type: message.type,
    text: message.text,
    fileUUID: message.fileUuid,
    fileSize: message.fileSize,
    fileName: message.fileName,
});

const validTypes = ['text', 'image', 'pdf', 'file'];
export const isValidChatMessage = (message: ChatMessage) => validTypes.includes(message.type);

export const getChatImageUrl = (
    config: typeof configService,
    farm: Farm,
    message: ChatMessage,
    thumb = false
) =>
    `${config.get('apiRoot')}/notes-api/v2/farms/${farm.uuid}/messageGroups/general/media/` +
    `${message.fileUuid}/?messageUUID=${message.uuid}` +
    `&isImageFile=true` +
    (thumb ? `&maxLongSide=190&isSquare=true` : '');

export const getChatFileUrl = (config: typeof configService, farm: Farm, message: ChatMessage) =>
    `${config.get('apiRoot')}/notes-api/v2/farms/${farm.uuid}/messageGroups/general/media/` +
    `${message.fileUuid}/?messageUUID=${message.uuid}&isImageFile=false`;

export default ChatMessage;
