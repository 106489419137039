import type { Farm } from '@fieldmargin/webapp-farms';
import type { MeasurementUnit } from 'lib/MeasurementUnit';

import type { GrossMargin } from './GrossMargin';
import GrossMarginFieldChart from './GrossMarginFieldChart';

interface GrossMarginFieldGraphProps {
    farm: Farm;
    measurementUnit: MeasurementUnit;
    datum: GrossMargin;
}

const GrossMarginFieldGraph = ({ farm, measurementUnit, datum }: GrossMarginFieldGraphProps) => {
    return (
        <div className="w-2/3">
            <GrossMarginFieldChart farm={farm} measurementUnit={measurementUnit} datum={datum} />
        </div>
    );
};

export default GrossMarginFieldGraph;
