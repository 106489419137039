import { useState } from 'react';
import { useToggle } from '@fieldmargin/webapp-state';

export interface AddingTypeState {
    addingType: boolean;
    createdFeatureTypeUuid?: string;
}

export const useAddingTypeState = (): [
    AddingTypeState,
    VoidFunction,
    (featureTypeUuid: string) => void,
] => {
    const [addingType, toggleAddingType] = useToggle(false);
    const [createdFeatureTypeUuid, setCreatedFeatureTypeUuid] = useState<string>();

    return [{ addingType, createdFeatureTypeUuid }, toggleAddingType, setCreatedFeatureTypeUuid];
};
