import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import c from 'classnames';
import type Field from 'fields/Field';
import { getFieldWorkedArea, getYearFieldUsageUuidOrNone } from 'fields/Field';
import { selectFieldUsagesWithNotSet } from 'fields/field-usage-selectors';
import type FieldUsage from 'fields/FieldUsage';
import type { List } from 'immutable';
import { DEFAULT_COLOUR } from 'lib/colours';
import { defaultToZero } from 'lib/fp-helpers';
import { groupBy } from 'ramda';
import type { AppState } from 'system/store';
import { ArchivedBarButton } from 'view/ArchivedBar';
import RememberingLink from 'view/RememberingLink';
import FormattedArea from 'view/units/FormattedArea';

import './SubFieldsList.css';

interface SubFieldsListProps {
    farm: Farm;
    parentField: Field;
    subFields: List<Field>;
}

const SubFieldsList = ({ farm, parentField, subFields }: SubFieldsListProps) => {
    const { t } = useTranslation();
    const [archivedOpen, toggleArchiveOpen] = useToggle(true);

    const grouped = groupBy(
        (subField) => (subField.archived ? 'archived' : 'active'),
        subFields.toArray()
    );

    const totalArea = defaultToZero(
        grouped.active?.reduce((total, subField) => {
            return total + (subField.year ? getFieldWorkedArea(subField, subField.year) : 0);
        }, 0)
    );

    return (
        <div className="SubFieldsList">
            <ul>
                {grouped.active?.map((subField) => (
                    <SubFieldListItem
                        key={subField.uuid}
                        farm={farm}
                        parentField={parentField}
                        subField={subField}
                        archived={parentField.archived}
                    />
                ))}
            </ul>
            <div className={c('SubFieldsListArea bordered-b', { archived: parentField.archived })}>
                <strong className="ml-5 w-1/2">{t('field_details_subfields_total_area')}</strong>
                <strong>
                    <FormattedArea areaSqm={totalArea} withUnit />
                </strong>
            </div>
            {grouped.archived !== undefined && grouped.archived.length > 0 && (
                <>
                    <ArchivedBarButton onClick={toggleArchiveOpen} className="flex justify-between">
                        {t('archived')}
                        <i
                            className={c(
                                'text-sm -mr-1.5',
                                archivedOpen ? 'ion-chevron-up' : 'ion-chevron-down'
                            )}
                        />
                    </ArchivedBarButton>
                    {archivedOpen && (
                        <ul>
                            {grouped.archived.map((subField) => (
                                <SubFieldListItem
                                    key={subField.uuid}
                                    farm={farm}
                                    parentField={parentField}
                                    subField={subField}
                                    archived
                                />
                            ))}
                        </ul>
                    )}
                </>
            )}
        </div>
    );
};

interface SubFieldListItemProps {
    farm: Farm;
    parentField: Field;
    subField: Field;
    archived: boolean;
}
const SubFieldListItem = ({ farm, parentField, subField, archived }: SubFieldListItemProps) => {
    const fieldUsages = useSelector<AppState, List<FieldUsage>>(selectFieldUsagesWithNotSet);
    const fieldUsageUuid = getYearFieldUsageUuidOrNone(subField.year as number, subField);
    const fieldUsage = fieldUsages.find((fu) => fu.uuid === fieldUsageUuid);

    return (
        <li className={c('SubFieldListItem', { archived })}>
            <RememberingLink
                to={`/farms/${farm.uuid}/fields/${subField.uuid}`}
                className={c(
                    'flex items-center no-underline focus',
                    archived ? 'text-archived' : 'text-black'
                )}
            >
                <div
                    className="w-2 mr-3 self-stretch"
                    style={{
                        backgroundColor: fieldUsage ? fieldUsage.colour : DEFAULT_COLOUR,
                    }}
                />
                <strong className="py-3 w-1/2">
                    {parentField.name} - {subField.name}
                </strong>
                <strong>
                    <FormattedArea
                        areaSqm={getFieldWorkedArea(subField, subField.year!)}
                        withUnit
                    />
                </strong>
                <span className="ml-auto mr-4 text-gray-550 ion-chevron-right" />
            </RememberingLink>
        </li>
    );
};

export default SubFieldsList;
