import LoadableContent from '@fieldmargin/webapp-styling/components/loadable-content';
import { useDateFormat } from 'hooks/useDateFormat';
import { useTranslations } from 'hooks/useTranslations';
import SidebarModule from 'sidebar/modules/SidebarModule';

interface FieldRestDaysProps {
    pending: boolean;
    fieldRestDuration?: Duration;
}

const FieldRestDays = ({ pending, fieldRestDuration }: FieldRestDaysProps) => {
    const { t, plural } = useTranslations();
    const { formatDuration } = useDateFormat();
    const formattedDuration = fieldRestDuration
        ? formatDuration(fieldRestDuration, {
              format: ['years', 'months', 'days'],
          })
        : undefined;
    return (
        <LoadableContent loading={pending} delay={300}>
            {fieldRestDuration !== undefined && (
                <SidebarModule>
                    <h4 className="inline">
                        {t('field_rest_time', {
                            sprintf: [
                                formattedDuration === ''
                                    ? plural('distance_days', { count: 0, sprintf: ['0'] })
                                    : formattedDuration,
                            ],
                        })}
                    </h4>
                </SidebarModule>
            )}
        </LoadableContent>
    );
};

export default FieldRestDays;
