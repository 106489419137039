import { createContext } from 'react';
import { createAction, handleActions } from '@fieldmargin/webapp-state';
import { Record } from 'immutable';

import { loadedFarmSuccess } from '../farms/farm-loading-state';

import type { AppDispatch } from './store';

export const changeModal = createAction<UiState, ChangeModalSpec>(
    'Change modal',
    (state, payload) =>
        state
            .set('modal', payload.name)
            .set('modalProps', payload.props || {})
            .set('farmChatOpen', false)
);
export const clearModal = createAction<UiState, any>('Clear modal', (state) =>
    state.set('modal', '').set('modalProps', {})
);
export const toggleFarmChat = createAction<UiState, any>('UI: Toggle farm chat', (state) =>
    state.set('farmChatOpen', !state.farmChatOpen)
);
export const setReturnTo = createAction<UiState, string | undefined>(
    'UI: Set return to',
    (state, payload) => state.set('returnTo', payload)
);

export type ModalProps = { [k: string]: any };
export interface ChangeModalSpec {
    name: string;
    props?: ModalProps;
}

export const rememberCurrentLocation = () => {
    return (dispatch: AppDispatch) => {
        dispatch(setReturnTo(window.location.pathname));
    };
};

const UiState = Record({
    modal: '',
    modalProps: {} as ModalProps,
    farmChatOpen: false,
    returnTo: undefined as undefined | string,
});
interface UiState extends ReturnType<typeof UiState> {}

export const uiReducer = handleActions<UiState>(
    UiState(),
    [changeModal, clearModal, toggleFarmChat, setReturnTo],
    {
        [loadedFarmSuccess.toString()]: () => UiState(),
    }
);

export default UiState;

/**
 * This context will eventually replace the UI redux state.
 */
export const UIContext = createContext({
    infoOpen: false,
    toggleInfo: () => {},
});
