import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import c from 'classnames';
import AreaIcon from 'view/svg/AreaIcon';
import CircleIcon from 'view/svg/CircleIcon';
import LineIcon from 'view/svg/LineIcon';
import PointIcon from 'view/svg/PointIcon';

interface ShapeControlProps {
    tool: DrawingTool;
    selected: boolean;
    onClick: VoidFunction;
    className?: string;
    disabled?: boolean;
}

const imgMap = {
    point: PointIcon,
    line: LineIcon,
    area: AreaIcon,
    circle: CircleIcon,
};

const translationMap = {
    point: 'title_description_point',
    line: 'title_description_line',
    area: 'area',
    circle: 'circle',
};

const ShapeControl = ({
    tool,
    selected,
    onClick,
    className,
    disabled = false,
}: ShapeControlProps) => {
    const { t } = useTranslation();

    const handleClick = (e?: MouseEvent) => {
        e && e.preventDefault();
        onClick();
    };
    const Icon = imgMap[tool];

    return (
        <button
            className={c(
                'bare-btn w-24 px-2 h-10 rounded bg-white border-solid border-2 border-fm-sky flex items-center focus cursor-pointer disabled:border-gray-400 disabled:text-gray-400 disabled:cursor-not-allowed',
                { 'opacity-50': selected },
                className
            )}
            onClick={handleClick}
            disabled={disabled}
        >
            {Icon && <Icon className="w-6 mr-2" fill={disabled ? '#a3a3a3' : '#4da4da'} />}
            <span
                className={c(
                    'font-bold text-xs text-left uppercase',
                    disabled ? 'text-gray-400' : 'text-fm-sky'
                )}
            >
                {t(translationMap[tool])}
            </span>
        </button>
    );
};

export default ShapeControl;
