import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePrevious, usePromise } from '@fieldmargin/webapp-state';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import { useCurrentOperation } from 'hooks/useCurrentOperation';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';

import type FullOperation from './FullOperation';
import { isFullOperationLoaded } from './FullOperation';
import { fetchFullOperation } from './operations-api';
import { setOperation } from './operations-state';

interface FullOperationLoaderProps {
    farm: Farm;
    setOperation: typeof setOperation;
}

const FullOperationLoader = ({ farm, setOperation }: FullOperationLoaderProps) => {
    const { currentOperation } = useCurrentOperation();

    const { error, setPromise } = usePromise((fullOperation: FullOperation) => {
        setOperation(fullOperation);
    });

    const previousOperation = usePrevious(currentOperation);
    useEffect(() => {
        if (currentOperation !== undefined) {
            if (!previousOperation || previousOperation.uuid !== currentOperation.uuid) {
                setPromise(fetchFullOperation(currentOperation.summary.farmUuid, currentOperation));
            }
        }
    }, [currentOperation]);

    if (currentOperation === undefined || !isFullOperationLoaded(currentOperation)) {
        const content =
            error || !currentOperation ? (
                <p>Sorry, we were unable to load this field job.</p>
            ) : (
                <p>
                    <em>Loading...</em>
                </p>
            );
        return (
            <div>
                <SidebarHeader>
                    <SidebarHeaderLink to={`/farms/${farm.uuid}/notes`}>
                        {icon('back', 'dark-blue', 'mr-3')} Back
                    </SidebarHeaderLink>
                </SidebarHeader>
                <SidebarModule>
                    <div>
                        <h2 className="mb-0">{currentOperation?.summary.name}</h2>
                    </div>
                </SidebarModule>
                <SidebarModule editing>{content}</SidebarModule>
            </div>
        );
    }
    return <Outlet />;
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
    }),
    (dispatch) => bindActionCreators({ setOperation }, dispatch)
)(FullOperationLoader);
