import { AttachmentType } from 'farm-editing/attachments';
import {
    selectEditingAttachmentsByType,
    selectIsSelectingFields,
} from 'farm-editing/farm-editing-state';
import type { FarmMapFeature, FarmMapFeatureFeatureProps } from 'farm-map/types';
import type { Feature, Geometry } from 'geojson';
import { useActiveSection } from 'hooks/useActiveSection';
import { useCurrentItemOutsideRoute } from 'hooks/useCurrentItemOutsideRoute';
import { DEFAULT_COLOUR } from 'lib/colours';
import { notNil } from 'lib/fp-helpers';
import { groupBy } from 'ramda';
import { useAppSelector } from 'system/store';
import { VisibilityOptions } from 'system/types';

import { useItemAttachmentUuids } from './useItemAttachmentUuids';

export type FarmMapFeatureFeature = Feature<Geometry, FarmMapFeatureFeatureProps>;

/**
 * - Features are always shown regardless of the active section
 * - Feature labels are only shown if the active section is features
 * - No features are shown if the user is selecting fields
 * - No features are shown if the user has turned off visibility
 * - If the user has turned off visibility for a particular feature usage, features within that
 *   will not be shown.
 * - If a feature is being edited it is not shown.
 * - If an item with the feature attached is being viewed it is not shown
 */
export const useFarmMapFeatures = (features: FarmMapFeature[]) => {
    const activeSection = useActiveSection();
    const selectedItems = useCurrentItemOutsideRoute();
    const currentFeature = selectedItems.feature;
    const isSelectingFields = useAppSelector(selectIsSelectingFields);
    const displayFeatures =
        useAppSelector((state) => state.farmEditingState.layerVisibility.featuresVisibility) !==
        VisibilityOptions.OFF;
    const hiddenFeatureTypes = useAppSelector((state) =>
        state.farmEditingState.layerVisibility.hiddenFeatureTypes.toArray()
    );
    const editingId = useAppSelector((state) => state.farmEditingState.editingData.id);
    const editingFeatureUuids = useAppSelector((state) =>
        selectEditingAttachmentsByType(state, AttachmentType.FEATURE)
    ).toArray();
    const itemFeatureUuids = useItemAttachmentUuids();

    const featureUuids = features.map((feature) => feature.properties.uuid);
    const showLabelsFor =
        activeSection.main !== 'features'
            ? []
            : currentFeature !== undefined
              ? [currentFeature.uuid]
              : featureUuids;
    const nonFeatureItemSelected = Object.entries(selectedItems).some(
        ([key, value]) => key !== 'feature' && notNil(value)
    );
    const dimFeatures = nonFeatureItemSelected
        ? featureUuids
        : currentFeature !== undefined
          ? featureUuids.filter((uuid) => uuid !== currentFeature.uuid)
          : [];

    return getFeatureFeatures(features, {
        displayFeatures: displayFeatures && !isSelectingFields,
        hiddenFeatureTypes,
        ignoreUuids: editingFeatureUuids
            .concat(itemFeatureUuids)
            .concat(editingId !== undefined ? [editingId] : []),
        showLabelsFor,
        dimFeatures,
    });
};

export const getFeatureFeatures = (
    features: FarmMapFeature[],
    opts: {
        displayFeatures: boolean;
        hiddenFeatureTypes: string[];
        ignoreUuids?: string[];
        showLabelsFor: string[];
        dimFeatures: string[];
    }
) => {
    if (!opts.displayFeatures) {
        return { features: [], pointFeatures: [] };
    }

    const featureGeoJsonFeatures = features
        .filter(isFeature)
        .map((feature) =>
            styleProps(
                feature,
                opts.showLabelsFor.includes(feature.properties.uuid),
                opts.dimFeatures.includes(feature.properties.uuid)
            )
        );
    const { nonPointFeatures, pointFeatures } = groupBy(
        (feature) => (feature.geometry.type === 'Point' ? 'pointFeatures' : 'nonPointFeatures'),
        featureGeoJsonFeatures
    );

    const filterFn = (feature: FarmMapFeatureFeature) =>
        !opts.hiddenFeatureTypes.includes(feature.properties.featureTypeUuid) &&
        !opts.ignoreUuids?.includes(feature.properties.uuid);

    return {
        features: nonPointFeatures?.filter(filterFn) ?? [],
        pointFeatures: pointFeatures?.filter(filterFn) ?? [],
    };
};

const isFeature = (feature: FarmMapFeatureFeature): feature is FarmMapFeatureFeature =>
    feature.properties.type === 'FEATURE';

const styleProps = (
    feature: FarmMapFeatureFeature,
    displayLabel: boolean,
    dim: boolean
): FarmMapFeatureFeature => ({
    ...feature,
    properties: {
        ...feature.properties,
        colour:
            feature.properties.featureTypeColour === ''
                ? DEFAULT_COLOUR
                : feature.properties.featureTypeColour,
        pointScale: feature.geometry.type === 'Point' ? 0.2 : 6,
        strokeWeight: 2,
        strokeOpacity: dim ? 0.5 : 1,
        fillOpacity: dim ? 0.2 : 0.4,
        label: displayLabel ? feature.properties.name : undefined,
    },
});
