import type { ReactNode } from 'react';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import type Field from 'fields/Field';
import { getFieldWorkedArea } from 'fields/Field';
import SizeBubble from 'view/molecules/size-bubble/SizeBubble';
import FormattedArea from 'view/units/FormattedArea';

interface FieldSelectorFieldProps {
    field: Field;
    year: number;
    subline?: ReactNode;
    selected: boolean;
    toggleSelected: VoidFunction;
}

const FieldSelectorField = ({
    field,
    year,
    subline,
    selected,
    toggleSelected,
}: FieldSelectorFieldProps) => {
    return (
        <div className="FieldSelectorField flex items-center justify-between relative bordered-b pl-11 pr-9 py-4">
            <Checkbox
                id={field.uuid}
                checked={selected}
                onChange={toggleSelected}
                label={
                    <>
                        <div className="name">{field.name}</div>
                        {subline}
                    </>
                }
                className="overflow-hidden"
            />
            <SizeBubble>
                <FormattedArea areaSqm={getFieldWorkedArea(field, year)} withUnit />
            </SizeBubble>
        </div>
    );
};

export default FieldSelectorField;
