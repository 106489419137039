import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import { getNutrientTypesArray } from 'inputs/Nutrients';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { getYearNutrientReport } from 'reporting/reporting-api';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import ErrorMessage from 'view/ErrorMessage';
import { selectCurrentYear } from 'years/years-state';

import { sortNutrientReports } from './nutrient-report-utils';
import type { NutrientReport } from './NutrientReport';
import NutrientReportFilters from './NutrientReportFilters';
import NutrientReportTable from './NutrientReportTable';

interface NutrientsReportContainerProps {
    farm: Farm;
    year: number;
    measurementUnit: MeasurementUnit;
}

const NutrientsReportContainer = ({
    farm,
    year,
    measurementUnit,
}: NutrientsReportContainerProps) => {
    const { t } = useTranslation();
    const [nutrientFilter, setNutrientFilter] = useState<string[]>(getNutrientTypesArray());
    const [fieldFilter, setFieldFilter] = useState<string[]>([]);
    const [statusFilter, setStatusFilter] = useState('all');
    const [nutrientReports, setNutrientReports] = useState<NutrientReport[]>();
    const { pending, error, setPromise } = usePromise<NutrientReport[]>(setNutrientReports);

    useEffect(() => {
        setPromise(
            getYearNutrientReport(
                farm.uuid,
                year,
                measurementUnit,
                statusFilter,
                nutrientFilter,
                fieldFilter
            ).then(sortNutrientReports)
        );
    }, [farm, year, measurementUnit, statusFilter, nutrientFilter, fieldFilter]);

    return (
        <div className="relative">
            <Button
                as={Link}
                to={`/farms/${farm.uuid}/reporting`}
                className="absolute top-0 left-0 flex items-center gap-2"
                variant="subtle"
            >
                {icon('back')} {t('back')}
            </Button>
            <h1 className="uppercase text-center text-2xl">{t('nutrient_report_title')}</h1>
            <NutrientReportFilters
                farm={farm}
                year={year}
                measurementUnit={measurementUnit}
                nutrientFilter={nutrientFilter}
                fieldFilter={fieldFilter}
                statusFilter={statusFilter}
                onNutrientFilterChange={setNutrientFilter}
                onFieldFilterChange={setFieldFilter}
                onStatusFilterChange={setStatusFilter}
            />
            {pending && (
                <p className="text-gray-400">
                    <em>{t('reporting_loading')}</em>
                </p>
            )}
            {error && <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>}
            {nutrientReports?.length === 0 && !pending && <p>{t('reporting_no_data')}</p>}
            {nutrientReports !== undefined && nutrientReports.length > 0 && (
                <NutrientReportTable
                    nutrientReports={nutrientReports}
                    selectedNutrients={nutrientFilter}
                />
            )}
        </div>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    year: selectCurrentYear(state),
    measurementUnit: selectUserAreaMeasurementUnit(state),
}))(NutrientsReportContainer);
