import { useSelector } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import type { List } from 'immutable';
import type { AppState } from 'system/store';
import { useAppDispatch, useAppSelector } from 'system/store';
import { trackEvent } from 'utils/trackEvent';

import type Input from './Input';
import { inputIsPesticide, inputsAppearSame } from './Input';
import { saveInputApi } from './inputs-api';
import { setInput } from './inputs-state';

export const shouldShowInputWarning = (farm: Farm, inputs: List<Input>) => {
    if (!farm.plan.inputLimit) {
        return false;
    }

    if (farm.plan.inputLimitThreshold && inputs.size < farm.plan.inputLimitThreshold) {
        return false;
    }

    return farm.plan.inputLimit > inputs.size;
};

export const isOnOrOverInputLimit = (farm: Farm, inputs: List<Input>) => {
    if (!farm.plan.inputLimit) {
        return false;
    }

    return farm.plan.inputLimit <= inputs.size;
};

export const trackNewInput = (input: Input, farm: Farm) => {
    if (!inputIsPesticide(input)) {
        trackEvent('Input created', { farmUuid: farm.uuid });
    } else {
        trackEvent('Input created from pesticide', {
            farmUuid: farm.uuid,
            name: input.name,
            productCode: input.pesticideData?.code,
        });
    }
};

/**
 * A hook that attempts to find the given input, otherwise it creates it and adds it to state.
 * This covers a case where the recording call may fail and the user tries again or the user has
 * clicked on a pesticide from the pesticides list that they have already used before.
 */
export const useGetOrCreateInput = () => {
    const dispatch = useAppDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);
    const inputs = useAppSelector((state) => state.inputsState.inputs);
    return (input: Input) =>
        Promise.resolve(
            inputs.find((i) => inputsAppearSame(input, i)) ??
                saveInputApi(farm.uuid, input).then((input) => {
                    trackNewInput(input, farm);
                    dispatch(setInput(input));
                    return input;
                })
        );
};
