import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import { OperationField } from 'components/operation-field';
import { SidebarCard } from 'components/sidebar-card';
import { AttachmentType } from 'farm-editing/attachments';
import { startSelectingMultipleAttachments } from 'farm-editing/farm-editing-state';
import { startEditingOperation } from 'farm-editing/start-editing-reducer';
import { selectCurrentFarm } from 'farms/farms-state';
import { useOperationDetailsActions } from 'hooks/actions';
import type { List } from 'immutable';
import { uuidMatches } from 'lib/fp-helpers';
import type FullOperation from 'operations/FullOperation';
import type { FullOperationField } from 'operations/OperationField';
import YieldUpgrade from 'operations/sidebar/YieldUpgrade';
import { selectOutputsSortedByName } from 'outputs/outputs-state';
import { useAppDispatch, useAppSelector } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import Num from 'view/Num';
import FormattedArea from 'view/units/FormattedArea';
import ShortUnit from 'view/units/ShortUnit';

import OperationFieldListItemWithYield from '../fields/OperationFieldListItemWithYield';

import { calculateOperationTotalYieldRate, calculateOperationTotalYields } from './yield-utils';

interface OperationFieldsViewWithYield {
    fullOperation: FullOperation;
    operationFields: List<FullOperationField>;
    totalFieldArea: number;
}

const OperationFieldsViewWithYield = ({
    fullOperation,
    operationFields,
    totalFieldArea,
}: OperationFieldsViewWithYield) => {
    const { t } = useTranslation();
    const { handleToggleEditingFields: toggleEditingFields } = useOperationDetailsActions();
    const dispatch = useAppDispatch();
    const farm = useAppSelector(selectCurrentFarm);
    const areaMeasurementUnit = useAppSelector(selectUserAreaMeasurementUnit);
    const output = useAppSelector(selectOutputsSortedByName).find(
        uuidMatches(fullOperation.summary.outputUuid ?? '')
    );

    if (!farm.plan.yieldRecording) {
        return (
            <SidebarCard.Root>
                <SidebarCard.Heading as="h4">{t('yield')}</SidebarCard.Heading>
                <YieldUpgrade />
            </SidebarCard.Root>
        );
    }

    return (
        <SidebarCard.Root>
            <SidebarCard.Header>
                <SidebarCard.Heading as="h4">{t('fields')}</SidebarCard.Heading>
                <SidebarCard.AddButton
                    onPress={() => {
                        dispatch(startEditingOperation(fullOperation));
                        dispatch(startSelectingMultipleAttachments(AttachmentType.FIELD));
                    }}
                >
                    {t('operation_details_add_fields')}
                </SidebarCard.AddButton>
            </SidebarCard.Header>

            <div className="OperationFieldsViewSetYield">
                <p>{t('how_much_did_you_harvest')}</p>
                <Button
                    onClick={toggleEditingFields}
                    variant="subtle"
                    small
                    className="OperationFieldsViewSetYieldBtn"
                    disabled={operationFields.size === 0}
                >
                    {t('set_yield')}
                </Button>
            </div>
            {operationFields.size > 0 && (
                <>
                    <OperationField.List>
                        {operationFields.map((operationField) => (
                            <OperationFieldListItemWithYield
                                key={operationField.field.uuid}
                                farm={farm}
                                fullOperation={fullOperation}
                                operationField={operationField}
                                onEditFields={toggleEditingFields}
                            />
                        ))}
                    </OperationField.List>
                    <p className="OperationFieldTotals">
                        <strong>{t('total')}</strong>
                    </p>
                    <p className="OperationFieldTotals">
                        <strong className="OperationFieldWorkAreaTotal">
                            <FormattedArea areaSqm={totalFieldArea} withUnit />
                        </strong>
                        <strong className="OperationFieldYieldRate">
                            <Num
                                value={calculateOperationTotalYieldRate(
                                    operationFields,
                                    areaMeasurementUnit
                                )}
                                sigFigs={4}
                            />
                            {output !== undefined && (
                                <>
                                    {' '}
                                    <ShortUnit unit={output?.unit} />
                                </>
                            )}
                        </strong>
                        <strong className="OperationFieldYieldTotal">
                            <strong>
                                <Num
                                    value={calculateOperationTotalYields(operationFields)}
                                    sigFigs={4}
                                />
                                {output !== undefined && (
                                    <>
                                        {' '}
                                        <ShortUnit unit={output?.unit} />
                                    </>
                                )}
                            </strong>
                        </strong>
                    </p>
                </>
            )}
        </SidebarCard.Root>
    );
};

export default OperationFieldsViewWithYield;
