import { useRef } from 'react';
import classNames from 'classnames';
import { eventIsSpaceOrEnterKeyPress, preventDefaultAnd } from 'lib/dom';
import { uniqueId } from 'lodash';
import { when } from 'ramda';

interface ClickableRowProps extends React.HTMLProps<HTMLTableRowElement> {
    onClick: VoidFunction;
}

const ClickableRow = ({ children, onClick, className, ...props }: ClickableRowProps) => {
    const id = useRef(uniqueId('clickable-row-'));

    const handleKeyDown = when<React.KeyboardEvent<HTMLTableRowElement>, void>(
        eventIsSpaceOrEnterKeyPress,
        preventDefaultAnd(onClick)
    );

    return (
        <tr
            id={id.current}
            className={classNames('cursor-pointer focus ring-inset', className)}
            tabIndex={0}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            role="button"
            {...props}
        >
            {children}
        </tr>
    );
};

export default ClickableRow;
