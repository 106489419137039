import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { usePageMapGroup } from 'hooks/selectors';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import type MapGroup from 'maps/farm/MapGroup';
import { deleteFarmMapApi } from 'maps/farm/maps-api';
import { removeFarmMap } from 'maps/farm/maps-state';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import { useAppDispatch } from 'system/store';

const MapGroupDeletion = () => {
    const { mapGroup } = usePageMapGroup();
    const { currentFarm } = useCurrentFarm();
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pending, error, setPromise } = usePromise<MapGroup>((mapGroup) => {
        dispatch(removeFarmMap(mapGroup));
        navigate(`/farms/${currentFarm.uuid}/maps`);
    });

    const handleDelete = () => {
        if (mapGroup) {
            setPromise(deleteFarmMapApi(currentFarm.uuid, mapGroup));
        }
    };

    return (
        mapGroup && (
            <div className="scrollable">
                <div className="non-scrolling">
                    <SidebarHeader>
                        <SidebarHeaderLink to={`/farms/${currentFarm.uuid}/maps/${mapGroup.uuid}`}>
                            {icon('back', 'dark-blue', 'mr-3')} Back
                        </SidebarHeaderLink>
                    </SidebarHeader>
                    {error && (
                        <SidebarError
                            title={t('failed_to_delete')}
                            message={t('something_went_wrong')}
                        />
                    )}
                </div>
                <div className="scrolling">
                    <SidebarModule editing>
                        <h2 className="mb-0">{mapGroup.name}</h2>
                    </SidebarModule>
                    <SidebarModule editing>
                        <p className="text-lg">{t('message_delete_confirmation_map')}</p>
                        <Button variant="danger" onClick={handleDelete} disabled={pending}>
                            {t(pending ? 'label_deleting' : 'label_delete')}
                        </Button>
                    </SidebarModule>
                </div>
            </div>
        )
    );
};

export { MapGroupDeletion };
