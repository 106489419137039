import type Field from 'fields/Field';
import type { FieldHistoryItem } from 'fields/sidebar/details/history/field-history-generator';
import FieldHistoryHerdMoveItem from 'fields/sidebar/details/history/FieldHistoryHerdMoveItem';
import FieldHIstoryHerdRemoveItem from 'fields/sidebar/details/history/FieldHIstoryHerdRemoveItem';
import FieldHistoryMapItem from 'fields/sidebar/details/history/FieldHistoryMapItem';
import FieldHistoryRestDaysItem from 'fields/sidebar/details/history/FieldHistoryRestDaysItem';
import type { List } from 'immutable';
import ItemHistoryActivityItem from 'sidebar/modules/item-history/ItemHistoryActivityItem';
import ItemHistoryDay from 'sidebar/modules/item-history/ItemHistoryDay';

interface FieldHistoryDayProps {
    field: Field;
    history: List<FieldHistoryItem>;
}

const FieldHistoryDay = ({ field, history }: FieldHistoryDayProps) => {
    if (history.size === 0) {
        return null;
    }

    const firstItem = history.first() as FieldHistoryItem;

    if (!firstItem.date) {
        return null;
    }

    return (
        <ItemHistoryDay date={firstItem.date}>
            {history.map((item, index) => (
                <FieldHistoryDayItem key={index} item={item} field={field} />
            ))}
        </ItemHistoryDay>
    );
};

const FieldHistoryDayItem = ({ item, field }: { item: FieldHistoryItem; field: Field }) => {
    switch (item.type) {
        case 'field-note':
            return <ItemHistoryActivityItem activity={item.item} fieldUuid={field.uuid} />;
        case 'field-map':
            return <FieldHistoryMapItem mapGroup={item.item} />;
        case 'herd-move':
            return <FieldHistoryHerdMoveItem historyItem={item} />;
        case 'herd-remove':
            return <FieldHIstoryHerdRemoveItem historyItem={item} />;
        case 'rest-days':
            return <FieldHistoryRestDaysItem historyItem={item} />;
        default:
            return null;
    }
};

export default FieldHistoryDay;
