import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import { isCreator } from 'lib/owner';
import { useAppSelector } from 'system/store';
import type { Item } from 'system/types';
import { selectIsFarmOwner } from 'team/farm-users-state';
import { selectUserId } from 'users/user-state';
import FarmLink from 'view/FarmLink';

import ActionMenuItem from './ActionMenuItem';

interface ActionMenuDeleteItemProps {
    to: string;
    item: Item;
}

const ActionMenuDeleteItem = ({ to, item }: ActionMenuDeleteItemProps) => {
    const { t } = useTranslation();
    const canDelete = useAppSelector(
        (state) => selectIsFarmOwner(state) || isCreator(item, selectUserId(state))
    );
    return (
        <ActionMenuItem>
            {canDelete ? (
                <FarmLink to={to} className="text-error-text">
                    {icon('remove', 'red')} {t('label_delete')}
                </FarmLink>
            ) : (
                <div>
                    {icon('remove', 'grey')} {t('label_delete')}
                    <div className="sr-only">
                        Only farm owners or the creator can delete this item
                    </div>
                </div>
            )}
        </ActionMenuItem>
    );
};

export default ActionMenuDeleteItem;
