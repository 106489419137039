import { connect } from 'react-redux';
import { setHerdsVisibility } from 'farm-editing/farm-editing-state';
import { bindActionCreators } from 'redux';
import VisibilityToggle from 'sidebar/visibility-toggle/VisibilityToggle';
import type { AppState } from 'system/store';

interface HerdListVisibilityProps {
    herdsVisibility: string;
    setHerdsVisibility: (fieldsVisibility: string) => void;
}

const HerdListVisibility = ({ herdsVisibility, setHerdsVisibility }: HerdListVisibilityProps) => {
    return <VisibilityToggle active={herdsVisibility} onSelect={setHerdsVisibility} />;
};

export default connect(
    (state: AppState) => ({
        herdsVisibility: state.farmEditingState.layerVisibility.herdsVisibility,
    }),
    (dispatch) => bindActionCreators({ setHerdsVisibility }, dispatch)
)(HerdListVisibility);
