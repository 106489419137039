import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { List } from 'immutable';
import configService from 'lib/config';
import { always } from 'ramda';
import type { ManualSensorResponseDTO } from 'sensors/manual/ManualSensor';
import type ManualSensor from 'sensors/manual/ManualSensor';
import { deserializeManualSensor, serializeManualSensor } from 'sensors/manual/ManualSensor';
import type { ManualSensorReadingGroupDTO } from 'sensors/manual/ManualSensorReadingGroup';
import type ManualSensorReadingGroup from 'sensors/manual/ManualSensorReadingGroup';
import {
    deserializeManualSensorReadingGroup,
    serializeManualSensorReadingGroup,
} from 'sensors/manual/ManualSensorReadingGroup';
import type { MetricDTO } from 'sensors/Metric';

export const fetchManualSensorsLatestApi = (farmUuid: string) => {
    return authorizedRequest<{ sensors: ManualSensorResponseDTO[] }>({
        url: `/sensor-api/v1/farms/${farmUuid}/manual`,
    }).then((data) => List(data.sensors.map((sensor) => deserializeManualSensor(sensor))));
};

export const createManualSensorApi = (farmUuid: string, manualSensorSumary: ManualSensor) => {
    return authorizedRequest<ManualSensorResponseDTO>({
        url: `/sensor-api/v1/farms/${farmUuid}/manual`,
        method: 'post',
        data: serializeManualSensor(farmUuid, manualSensorSumary),
    }).then(deserializeManualSensor);
};

export const updateManualSensorApi = (farmUuid: string, manualSensorSumary: ManualSensor) => {
    return authorizedRequest<ManualSensorResponseDTO>({
        url: `/sensor-api/v1/farms/${farmUuid}/manual/${manualSensorSumary.id}`,
        method: 'post',
        data: serializeManualSensor(farmUuid, manualSensorSumary),
    }).then(deserializeManualSensor);
};

export const fetchManualSensorReadingsApi = (farmUuid: string, manualSensorId: string) => {
    return authorizedRequest<{
        readingGroups: ManualSensorReadingGroupDTO[];
        metrics: MetricDTO[];
    }>({
        url: `/sensor-api/v1/farms/${farmUuid}/manual/${manualSensorId}/readings`,
    }).then((data) =>
        List(
            data.readingGroups.map((readingGroup) =>
                deserializeManualSensorReadingGroup(readingGroup, data.metrics)
            )
        )
    );
};

export const deleteManualSensorApi = (farmUuid: string, manualSensorId: string) => {
    return authorizedRequest({
        url: `/sensor-api/v1/farms/${farmUuid}/manual/${manualSensorId}`,
        method: 'delete',
    }).then(() => manualSensorId);
};

export const deleteManualSensorReadingGroupApi = (
    farmUuid: string,
    manualSensorId: string,
    readingGroupId: string
) => {
    return authorizedRequest({
        url: `/sensor-api/v1/farms/${farmUuid}/manual/${manualSensorId}/readings/${readingGroupId}`,
        method: 'delete',
    }).then(() => readingGroupId);
};

export const createReadingGroupApi = (
    farmUuid: string,
    manualSensorId: string,
    readingGroup: ManualSensorReadingGroup
) => {
    return authorizedRequest<ManualSensorReadingGroupDTO>({
        url: `/sensor-api/v1/farms/${farmUuid}/manual/${manualSensorId}/readings`,
        method: 'post',
        data: serializeManualSensorReadingGroup(readingGroup),
    });
};

export const updateReadingGroupApi = (
    farmUuid: string,
    manualSensorId: string,
    readingGroup: ManualSensorReadingGroup
) =>
    authorizedRequest({
        url: `/sensor-api/v1/farms/${farmUuid}/manual/${manualSensorId}/readings/${readingGroup.id}`,
        method: 'post',
        data: serializeManualSensorReadingGroup(readingGroup),
    }).then(always(readingGroup));

export const getManualSensorDownloadLink = (
    farmUuid: string,
    manualSensorId: string,
    format: 'CSV' | 'XLS' = 'CSV'
) =>
    `${configService.get(
        'apiRoot'
    )}/sensor-api/v1/farms/${farmUuid}/manual/${manualSensorId}/report?fileFormat=${format}`;
