import type { ReactNode } from 'react';
import type { Validate } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import { ErrorMessage } from '@hookform/error-message';
import { hasOwnProperty } from 'utils/hasOwnProperty';
import FMErrorMessage from 'view/ErrorMessage';

interface CheckboxFormFieldProps {
    field: string;
    label?: ReactNode;
    validate?: Validate<boolean, any>;
}

const CheckboxFormField = ({ field, label, validate }: CheckboxFormFieldProps) => {
    const { control, formState } = useFormContext();
    return (
        <div className="form-field">
            <Controller
                name={field}
                rules={{ validate }}
                control={control}
                render={({ field: { value, onChange } }) => (
                    <Checkbox
                        id={field}
                        onChange={(e) =>
                            onChange(
                                hasOwnProperty(value, 'checked')
                                    ? { ...value, checked: e.target.checked }
                                    : e.target.checked
                            )
                        }
                        label={label}
                        checked={hasOwnProperty(value, 'checked') ? value.checked : value}
                    />
                )}
            />
            <ErrorMessage
                errors={formState.errors}
                name={field}
                render={({ message }) => (
                    <FMErrorMessage className="mt-2">{message}</FMErrorMessage>
                )}
            />
        </div>
    );
};

export default CheckboxFormField;
