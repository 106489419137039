import { OnboardingTutorial } from './onboarding-api';
import { useSaveOnboardingTutorials } from './onboarding-hooks';
import { onboardingSteps, useOnboarding } from './onboarding-state';

const OnboardingContainer = () => {
    const { activeStep, skipOnboarding } = useOnboarding();
    const saveOnboardingTutorials = useSaveOnboardingTutorials(skipOnboarding, false);

    if (!activeStep || !onboardingSteps[activeStep]) {
        return null;
    }

    const handleSkip = () => {
        saveOnboardingTutorials([
            OnboardingTutorial.MAPPING,
            OnboardingTutorial.PLANNING,
            OnboardingTutorial.LIVESTOCK,
            OnboardingTutorial.SCOUTING,
            OnboardingTutorial.INPUTS,
            OnboardingTutorial.SATELLITE,
            OnboardingTutorial.COMMUNICATION,
            OnboardingTutorial.LOGGING,
        ]);
    };

    const Onboarding = onboardingSteps[activeStep];
    return <Onboarding onSkip={handleSkip} />;
};

export default OnboardingContainer;
