import { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@fieldmargin/webapp-styling/components/button';
import { SearchBar } from 'components/search-bar';
import { AttachmentType } from 'farm-editing/attachments';
import {
    selectEditingAttachmentsByType,
    setEditingAttachmentsByType,
    stopSelectingMultipleAttachments,
    toggleEditingAttachmentByType,
} from 'farm-editing/farm-editing-state';
import type { FeatureTypeWithFeatures } from 'features/features-selectors';
import { selectMaybeVisibleFeaturesGroupedByFeatureType } from 'features/features-selectors';
import { setFeatureSearchString, setFilterFeaturesByScreen } from 'features/features-state';
import { asTypeItem } from 'features/FeatureType';
import type { List, Set } from 'immutable';
import { preventDefaultAnd } from 'lib/dom';
import { useToggleSet } from 'lib/hooks';
import { bindActionCreators } from 'redux';
import FilterByMap from 'sidebar/FilterByMap';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import GroupItemSelect from 'sidebar/modules/selector/GroupItemSelect';
import GroupSelect from 'sidebar/modules/selector/GroupSelect';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';

interface FeatureSelectorProps {
    featureTypeGroups: List<FeatureTypeWithFeatures>;
    featureSearchString: string;
    setFeatureSearchString: typeof setFeatureSearchString;
    filterFeaturesByScreen: boolean;
    setFilterFeaturesByScreen: typeof setFilterFeaturesByScreen;
    editingFeatureUuids: Set<string>;
    setEditingFeatureUuids: SingleParamVoidFunction<Set<string>>;
    toggleEditingFeatureUuid: SingleParamVoidFunction<string>;
    stopSelectingMultipleAttachments: typeof stopSelectingMultipleAttachments;
}

const FeatureSelector = ({
    featureTypeGroups,
    featureSearchString,
    setFeatureSearchString,
    filterFeaturesByScreen,
    setFilterFeaturesByScreen,
    editingFeatureUuids,
    setEditingFeatureUuids,
    toggleEditingFeatureUuid,
    stopSelectingMultipleAttachments,
}: FeatureSelectorProps) => {
    const { t } = useTranslation();
    const [closedFeatureTypes, toggleFeatureTypeClosed] = useToggleSet();
    const initialFeatures = useRef<Set<string>>(editingFeatureUuids);

    const handleBackClick = preventDefaultAnd(() => {
        // Reset back to previously attached features
        setEditingFeatureUuids(initialFeatures.current);
        stopSelectingMultipleAttachments();
    });

    const handleDoneClick = preventDefaultAnd(stopSelectingMultipleAttachments);

    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader className="px-5">
                    <Button onClick={handleBackClick} variant="danger-outline" small>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDoneClick} small>
                        {t('save')}
                    </Button>
                </SidebarHeader>
            </div>
            <div className="non-scrolling">
                <SidebarModule editing>
                    <h3 className="text-sm uppercase">{t('create_note_attach_feature')}</h3>
                    <div className="flex justify-between items-center gap-4">
                        <SearchBar
                            searchFunction={setFeatureSearchString}
                            value={featureSearchString}
                            className="w-64"
                        />
                        <FilterByMap
                            checked={filterFeaturesByScreen}
                            onChange={() => setFilterFeaturesByScreen(!filterFeaturesByScreen)}
                            label={t('only_show_features_visible_on_map')}
                        />
                    </div>
                </SidebarModule>
            </div>
            <div className="scrolling">
                {featureTypeGroups.map(({ featureType, features }) => (
                    <Fragment key={featureType.uuid}>
                        <GroupSelect
                            group={asTypeItem(featureType)}
                            items={features.toArray()}
                            selectedItems={editingFeatureUuids}
                            onToggleChecked={setEditingFeatureUuids}
                            onToggleOpened={() => toggleFeatureTypeClosed(featureType.uuid)}
                            isOpen={!closedFeatureTypes.includes(featureType.uuid)}
                        />
                        {!closedFeatureTypes.includes(featureType.uuid) &&
                            features.map((feature) => (
                                <GroupItemSelect
                                    key={feature.uuid}
                                    item={feature}
                                    selected={editingFeatureUuids.has(feature.uuid)}
                                    toggleSelected={() => toggleEditingFeatureUuid(feature.uuid)}
                                />
                            ))}
                    </Fragment>
                ))}
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        featureTypeGroups: selectMaybeVisibleFeaturesGroupedByFeatureType(state),
        featureSearchString: state.featuresState.featureSearchString,
        filterFeaturesByScreen: state.featuresState.filterFeaturesByScreen,
        editingFeatureUuids: selectEditingAttachmentsByType(state, AttachmentType.FEATURE),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setFeatureSearchString,
                setFilterFeaturesByScreen,
                setEditingFeatureUuids: setEditingAttachmentsByType(AttachmentType.FEATURE),
                toggleEditingFeatureUuid: toggleEditingAttachmentByType(AttachmentType.FEATURE),
                stopSelectingMultipleAttachments,
            },
            dispatch
        )
)(FeatureSelector);
