import { useContext } from 'react';
import { useSelector } from 'react-redux';
import type { User } from '@fieldmargin/webapp-auth';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectFarms } from 'farms/farms-state';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import type { List } from 'immutable';
import type { AppState } from 'system/store';
import { UIContext } from 'system/ui-state';
import type { ActiveSection } from 'system/url-util';
import InfoBackground from 'view/InfoBackground';

import DemoExpiryMessage from './DemoExpiryMessage';
import DemoUserMessage from './DemoUserMessage';
import FarmChatNavItem from './FarmChatNavItem';
import FarmChooser from './FarmChooser';
import FieldmarginLogo from './FieldmarginLogo';
import HelpNavItem from './HelpNavItem';
import InfoNavItem from './InfoNavItem';
import SettingsNavItem from './SettingsNavItem';
import TopNav from './TopNav';
import TopNavUpgradePrompt from './TopNavUpgradePrompt';
import YearChooser from './YearChooser';

import './FarmTopNav.css';
interface FarmTopNavProps {
    activeSection?: ActiveSection;
}

const FarmTopNav = ({ activeSection }: FarmTopNavProps) => {
    const { currentFarm } = useCurrentFarm();
    const activeFarmUuid = useSelector<AppState, string | null>(
        (state) => state.farmsState.activeFarmUuid
    );
    const farms = useSelector<AppState, List<Farm> | null>(selectFarms);
    const user = useSelector<AppState, User | null>((state) => state.user.user);
    const { infoOpen } = useContext(UIContext);
    return (
        <TopNav className="FarmTopNav">
            {infoOpen && <InfoBackground className="w-full h-15" />}
            <FieldmarginLogo />
            <nav className="flex justify-center items-center flex-grow" style={{ width: 350 }}>
                {user && currentFarm && farms?.size === 1 && (farms.get(0) as Farm).demo ? (
                    <DemoUserMessage />
                ) : (
                    <>
                        {currentFarm && currentFarm.demo && (
                            <DemoExpiryMessage farm={currentFarm} />
                        )}
                        <FarmChooser
                            currentFarm={currentFarm}
                            activeSection={activeSection || {}}
                        />
                    </>
                )}
                {currentFarm && <YearChooser />}
            </nav>
            {currentFarm && <TopNavUpgradePrompt />}
            {activeFarmUuid !== null && <FarmChatNavItem />}
            <HelpNavItem />
            <InfoNavItem />
            <SettingsNavItem />
        </TopNav>
    );
};

export default FarmTopNav;
