import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import c from 'classnames';
import icon from 'components/icons/icon';
import type { List } from 'immutable';
import type Input from 'inputs/Input';
import { InputNutrientsList } from 'inputs/InputNutrients';
import type { InputReportSummary } from 'inputs/InputReportSummary';
import { getInputTypeI18nKeyShort } from 'inputs/InputType';
import type { AppState } from 'system/store';
import ClickableRow from 'view/a11y/ClickableRow';
import RememberingLink from 'view/RememberingLink';
import FormattedRate from 'view/units/FormattedRate';
import FormattedTotal from 'view/units/FormattedTotal';
import ShortUnit from 'view/units/ShortUnit';

import type { InputRecordingsByOperation } from './field-reporting-utils';

interface FieldInputTableRowProps {
    farm: Farm;
    inputReportSummary: InputReportSummary;
    recordingsByOperation: List<InputRecordingsByOperation>;
    open: boolean;
    toggleOpen: VoidFunction;

    // From redux
    input?: Input;
}

const FieldInputTableRow = ({
    farm,
    inputReportSummary,
    recordingsByOperation,
    open,
    toggleOpen,
    input,
}: FieldInputTableRowProps) => {
    const { t } = useTranslation();
    return (
        <>
            <ClickableRow
                key={inputReportSummary.uuid}
                onClick={toggleOpen}
                aria-expanded={open}
                aria-pressed={open}
            >
                <td>
                    {inputReportSummary.name} (
                    {t(getInputTypeI18nKeyShort(inputReportSummary.inputType))}){' '}
                    {recordingsByOperation.size}x{' '}
                    {input !== undefined && <InputNutrientsList input={input} />}
                </td>
                <td>{inputReportSummary.pesticideData?.registrationNumber || '-'}</td>
                <td>
                    <FormattedRate rate={inputReportSummary.sumRate} noConversion />{' '}
                    <ShortUnit unit={inputReportSummary.inputUnit} />
                </td>
                <td>
                    <FormattedRate rate={inputReportSummary.sumTotals} noConversion />{' '}
                    <ShortUnit unit={inputReportSummary.inputUnit} />
                    <i
                        className={c(
                            'float-right ml-2 cursor-pointer',
                            open ? 'ion-chevron-up' : 'ion-chevron-down'
                        )}
                    />
                </td>
            </ClickableRow>
            {open &&
                recordingsByOperation.map(({ operation, recording, areaSqm }, index) => (
                    <tr className="bg-gray-200" key={index}>
                        <td colSpan={2}>
                            <RememberingLink
                                to={`/farms/${farm.uuid}/operations/${operation.uuid}`}
                            >
                                {icon('field-job', 'blue', 'mr-3')}
                                <span className="truncate inline-block align-middle w-2/3">
                                    {operation.summary.name}
                                </span>
                            </RememberingLink>
                        </td>
                        <td>
                            <FormattedRate rate={recording.rate} />
                            <ShortUnit unit={inputReportSummary.inputUnit} />
                        </td>
                        <td>
                            <FormattedTotal rate={recording.rate} areaSqm={areaSqm} />{' '}
                            <ShortUnit unit={inputReportSummary.inputUnit} />
                        </td>
                    </tr>
                ))}
        </>
    );
};

export default connect((state: AppState, { inputReportSummary }: FieldInputTableRowProps) => ({
    input: state.inputsState.inputs.find((i) => i.uuid === inputReportSummary.uuid),
}))(FieldInputTableRow);
