import type { FarmMapFeature, FarmMapHerdFeatureProps } from 'farm-map/types';
import type { Feature, Geometry, Polygon } from 'geojson';
import { useActiveSection } from 'hooks/useActiveSection';
import { getGeoJsonInteriorPoint } from 'lib/geo/geometry';
import { useAppSelector } from 'system/store';
import { VisibilityOptions } from 'system/types';

export type FarmMapHerdFeature = Feature<Geometry, FarmMapHerdFeatureProps>;

/**
 * Herds are shown on any activeSection.
 * No herds are shown in the user is editing sub-fields.
 * No herds are show if the user has turned off visibility.
 * Herd markers are shown on an interior point of the field they are associated with.
 */
export const useFarmMapHerds = (features: FarmMapFeature[]) => {
    const isEditingSubFields = useActiveSection().sub === 'sub-fields';
    const displayHerds =
        useAppSelector((state) => state.farmEditingState.layerVisibility.herdsVisibility) !==
        VisibilityOptions.OFF;
    return getHerdMarkers(features, isEditingSubFields, displayHerds);
};

export const getHerdMarkers = (
    features: FarmMapFeature[],
    isEditingSubFields: boolean,
    displayHerds: boolean
) => {
    if (isEditingSubFields || !displayHerds) {
        return [];
    }

    const fieldFeatures = features.filter((feature) => feature.properties.type === 'FIELD');

    return features
        .filter(isHerdFeature)
        .map((feature) => {
            const fieldFeature = fieldFeatures.find(
                (fieldFeature) => fieldFeature.properties.uuid === feature.properties.fieldUuid
            );
            return fieldFeature !== undefined
                ? {
                      ...feature,
                      geometry: getGeoJsonInteriorPoint(fieldFeature.geometry as Polygon),
                  }
                : feature;
        })
        .filter((marker) => marker.geometry !== null) as FarmMapHerdFeature[];
};

const isHerdFeature = (feature: FarmMapFeature): feature is FarmMapHerdFeature =>
    feature.properties.type === 'HERD';
