import { useTranslation } from 'react-i18next';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import ReturningLinkButton from 'view/ReturningLinkButton';

interface CreateHeaderProps {
    isSaving: boolean;
    canSave?: boolean;
    backPath: string;
    backState?: any;
}

const CreateHeader = ({ isSaving, canSave = true, backPath, backState }: CreateHeaderProps) => {
    const { t } = useTranslation();
    return (
        <SidebarHeader className="px-5">
            <ReturningLinkButton to={backPath} state={backState} variant="danger-outline" small>
                {t('cancel')}
            </ReturningLinkButton>
            <SubmitButton disabled={isSaving || !canSave} small>
                {isSaving ? t('saving') : t('save')}
            </SubmitButton>
        </SidebarHeader>
    );
};

export default CreateHeader;
