export interface Colour {
    name: string;
    colour: string;
}

export const DEFAULT_COLOUR = '#999999';

export const colourMap: Record<string, Colour> = {
    grey: { name: 'Grey', colour: DEFAULT_COLOUR },
    red: { name: 'Red', colour: '#FA3312' },
    brick: { name: 'Brick', colour: '#C93E27' },
    wine: { name: 'Wine', colour: '#8E0101' },
    orange: { name: 'Orange', colour: '#FF861F' },
    rust: { name: 'Rust', colour: '#C76026' },
    brown: { name: 'Brown', colour: '#885A1C' },
    soil: { name: 'Soil', colour: '#B37E34' },
    gold: { name: 'Gold', colour: '#EDC233' },
    yellow: { name: 'Yellow', colour: '#F7F72B' },
    lime: { name: 'Lime', colour: '#C7F211' },
    leaf: { name: 'Leaf', colour: '#90A519' },
    grass: { name: 'Grass', colour: '#55A548' },
    mint: { name: 'Mint', colour: '#BBF4DB' },
    cyan: { name: 'Cyan', colour: '#1DFFED' },
    sky: { name: 'Sky', colour: '#92D2FA' },
    sea: { name: 'Sea', colour: '#288ADB' },
    peacock: { name: 'Peacock', colour: '#077BA5' },
    navy: { name: 'Navy', colour: '#1D2AC4' },
    violet: { name: 'Violet', colour: '#8363DB' },
    lilac: { name: 'Lilac', colour: '#C3AEFC' },
    plum: { name: 'Plum', colour: '#983987' },
    fuchsia: { name: 'Fuchsia', colour: '#EF00F8' },
    magenta: { name: 'Magenta', colour: '#E30083' },
    pink: { name: 'Pink', colour: '#FD85CB' },
    blue: { name: 'Blue', colour: '#14439D' },
    green: { name: 'Green', colour: '#186C1E' },
    black: { name: 'Black', colour: '#000000' },
    white: { name: 'White', colour: '#FFFFFF' },
};

export const colourList = [
    colourMap.grey,
    colourMap.red,
    colourMap.brick,
    colourMap.wine,
    colourMap.orange,
    colourMap.rust,
    colourMap.brown,
    colourMap.soil,
    colourMap.gold,
    colourMap.yellow,
    colourMap.lime,
    colourMap.leaf,
    colourMap.grass,
    colourMap.mint,
    colourMap.cyan,
    colourMap.sky,
    colourMap.sea,
    colourMap.peacock,
    colourMap.navy,
    colourMap.violet,
    colourMap.lilac,
    colourMap.plum,
    colourMap.fuchsia,
    colourMap.magenta,
    colourMap.pink,
    colourMap.blue,
    colourMap.green,
    colourMap.black,
    colourMap.white,
];

export const FieldColours = [
    colourMap.red,
    colourMap.brick,
    colourMap.wine,
    colourMap.orange,
    colourMap.rust,
    colourMap.brown,
    colourMap.soil,
    colourMap.gold,
    colourMap.yellow,
    colourMap.lime,
    colourMap.leaf,
    colourMap.grass,
    colourMap.mint,
    colourMap.cyan,
    colourMap.sky,
    colourMap.sea,
    colourMap.peacock,
    colourMap.navy,
    colourMap.violet,
    colourMap.lilac,
    colourMap.plum,
    colourMap.fuchsia,
    colourMap.magenta,
    colourMap.pink,
];

export const herdColours = [
    colourMap.red,
    colourMap.orange,
    colourMap.yellow,
    colourMap.lime,
    colourMap.blue,
    colourMap.violet,
    colourMap.pink,
    colourMap.white,
    colourMap.black,
    colourMap.grey,
    colourMap.wine,
    colourMap.brown,
    colourMap.gold,
    colourMap.green,
    colourMap.sky,
    colourMap.lilac,
];
