import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import c from 'classnames';
import type Field from 'fields/Field';
import { selectFieldSubFieldsForCurrentYear } from 'fields/fields-selectors';
import { useSegmentTracking } from 'hooks/useSegmentTracking';
import type { List } from 'immutable';
import { useAction } from 'lib/hooks';
import PlusButtonLink from 'sidebar/modules/PlusButtonLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import { rememberCurrentLocation } from 'system/ui-state';
import FeatureLockedModal from 'view/modal/FeatureLockedModal';

import SubFieldsList from './SubFieldsList';

interface EditableFieldSubFieldsProps {
    farm: Farm;
    field: Field;
}

const EditableFieldSubFields = ({ farm, field }: EditableFieldSubFieldsProps) => {
    const { t } = useTranslation();
    const { editSubfields } = useSegmentTracking();
    const rememberLocation = useAction(rememberCurrentLocation);

    const subFields = useSelector<AppState, List<Field>>((state) =>
        selectFieldSubFieldsForCurrentYear(state, field.uuid)
    );

    const [showUpgradeModal, toggleUpgradeModal] = useToggle(false);

    const handleSubFieldsEdit = (e: MouseEvent) => {
        if (!farm.plan.subFields && !farm.freeTrial) {
            e.preventDefault();
            !showUpgradeModal && toggleUpgradeModal();
            return;
        }
        if (field.archived) {
            e.preventDefault();
            return;
        }
        rememberLocation();
        editSubfields();
    };

    return (
        <SidebarModule className={c('px-0 pb-0', { archived: field.archived })}>
            {showUpgradeModal && (
                <FeatureLockedModal
                    onClose={toggleUpgradeModal}
                    header={t('field_details_subfields')}
                />
            )}
            <h4 className="px-5 flex items-center justify-between">
                {t('field_details_subfields')}{' '}
                {subFields.size > 0 && (
                    <Link
                        to="sub-fields"
                        className={c('EditableFieldSubFields-EditLink focus', {
                            archived: field.archived,
                        })}
                        onClick={handleSubFieldsEdit}
                    >
                        {t('edit')}
                    </Link>
                )}
            </h4>
            {subFields.size > 0 ? (
                <SubFieldsList farm={farm} parentField={field} subFields={subFields} />
            ) : (
                <>
                    <p className="text-gray-450 mx-5">
                        <em>{t('field_details_subfields_prompt')}</em>
                    </p>
                </>
            )}
            <PlusButtonLink
                className="mx-5 mb-3"
                to="sub-fields"
                onClick={handleSubFieldsEdit}
                disabled={field.archived}
            >
                {t('field_details_subfields_add')}
            </PlusButtonLink>
        </SidebarModule>
    );
};

export default EditableFieldSubFields;
