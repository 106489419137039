import { setHours, setMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { isArray, isString } from 'lodash';

/**
 * The Notes API returns ISO dates as an array and other dates as a unix timestamp. This function
 * attempts to deal with both.
 */
export const parseServerDate = (dateStr: string | number[]) => {
    if (!dateStr) {
        return null;
    }
    if (isArray(dateStr) && dateStr.length >= 2) {
        // Java months are 1-indexed, JS months are 0-indexed, so we must adjust
        dateStr[1] = dateStr[1] - 1;
        // @ts-ignore
        return new Date(...dateStr);
    }
    return new Date(dateStr as string);
};

/**
 * Take some user input that should be a time, and convert it to a Date.
 */
export const userStringToDateTime = (date: Date, timeStr?: string) => {
    if (!timeStr || !isString(timeStr)) {
        return date;
    }
    const filtered = timeStr.replace(/[^0-9]/g, '').substr(0, 4);
    if (filtered.length < 3) {
        return date;
    }
    const mins = Math.min(59, +filtered.substr(filtered.length - 2));
    const hours = Math.min(23, +filtered.substr(0, filtered.length - 2));
    return setHours(setMinutes(date, mins), hours);
};

export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
export const createTzDate = (date: Date) => utcToZonedTime(date, getTimezone());
export const createNowTzDate = () => createTzDate(new Date());
