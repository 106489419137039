import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import { useDateFormat } from 'hooks/useDateFormat';
import type { List } from 'immutable';
import type ManualSensor from 'sensors/manual/ManualSensor';
import type Metric from 'sensors/Metric';
import type Reading from 'sensors/Reading';
import type SensorSummary from 'sensors/SensorSummary';

import FormattedMetric from './FormattedMetric';
import signalGreenSvg from './signal-green.svg';
import signalGreySvg from './signal-grey.svg';

interface SensorListSensorProps {
    sensor: ManualSensor | SensorSummary;
    active?: boolean;
    renderLinks?: (sensor: ManualSensor | SensorSummary) => ReactNode;
    linkTo?: string;
}

const SensorListSensor = ({ sensor, active, renderLinks, linkTo }: SensorListSensorProps) => {
    const { formatDistanceToNow } = useDateFormat();
    const links = renderLinks && renderLinks(sensor);
    const content = (
        <div className="flex separator-bottom">
            {active !== undefined && (
                <img
                    className="w-5 self-start mt-4 ml-5"
                    src={active ? signalGreenSvg : signalGreySvg}
                />
            )}
            <div className={clsx('flex-grow px-5', { indented: active !== undefined })}>
                <div className="flex justify-between items-center py-3">
                    <div className="text-sm text-black font-bold">{sensor.getName()}</div>
                    {sensor.updatedAt && (
                        <div className="text-gray-500 text-xs">
                            {formatDistanceToNow(sensor.updatedAt, { addSuffix: true })}
                        </div>
                    )}
                </div>
                {sensor.metrics &&
                    sensor.metrics
                        .sortBy((metric) => metric.name)
                        .map((metric) => (
                            <SensorListMetric
                                key={metric.id}
                                metric={metric}
                                latestReadings={sensor.latestReadings}
                            />
                        ))}
                {links && (
                    <div className="border-solid border-0 border-t border-gray-200 text-xs font-medium py-3">
                        {links}
                    </div>
                )}
            </div>
        </div>
    );

    if (linkTo) {
        return (
            <Link
                to={linkTo}
                className={clsx('block focus ring-inset', { disabled: active === false })}
            >
                {content}
            </Link>
        );
    }

    return <div className={clsx('', { disabled: active === false })}>{content}</div>;
};

const SensorListMetric = ({
    metric,
    latestReadings,
}: {
    metric: Metric;
    latestReadings: List<Reading> | null;
}) => {
    const reading = latestReadings
        ? latestReadings.find((reading) => reading.metricId === metric.id)
        : undefined;
    return (
        <div
            key={metric.id}
            className="flex items-center border-solid border-0 border-t border-gray-200 text-black py-3"
        >
            <span className="flex-grow mr-2 text-xs">{metric.name}</span>
            <strong className="text-base text-right">
                <FormattedMetric
                    metricType={metric.type}
                    value={reading?.value ?? undefined}
                    unit={metric.unit}
                />
            </strong>
        </div>
    );
};

export default SensorListSensor;
