import type { FarmUser } from '@fieldmargin/webapp-farms';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import { Set } from 'immutable';
import { useField } from 'informed';
import { uniqueId } from 'lodash';

import './TaggedUsersField.scss';

interface TaggedUsersFieldProps {
    farmUsers: FarmUser[];
}

const TaggedUsersField = ({ farmUsers }: TaggedUsersFieldProps) => {
    const { fieldState, fieldApi } = useField<Set<FarmUser>>({
        field: 'taggedUsers',
        // @ts-ignore TS def is missing keepState
        keepState: true,
    });

    let { value } = fieldState;
    const { setValue } = fieldApi;

    value = value || Set();

    const checkboxes = farmUsers.map((farmUser) => {
        const inputId = uniqueId('tagged-user-');

        return (
            <div key={farmUser.id} className="item">
                <Checkbox
                    id={inputId}
                    defaultChecked={value.has(farmUser)}
                    onChange={() => {
                        if (value.has(farmUser)) {
                            setValue(value.delete(farmUser));
                        } else {
                            setValue(value.add(farmUser));
                        }
                    }}
                    label={`${farmUser.firstName} ${farmUser.lastName}`}
                />
            </div>
        );
    });

    return <div className="TaggedUsersField">{checkboxes}</div>;
};

export default TaggedUsersField;
