import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess, loadingFarm } from 'farms/farm-loading-state';
import config from 'lib/config';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

import type { FarmIntegration } from './FarmIntegration';

export interface Integration {
    uuid: string;
    name: string;
    logoUri: string;
    supports: string[];
    listDescription: string;
    fullDescription: string;

    public: boolean;
    isInternal: boolean;
    detailsTemplate: string | null;
    openText: string;
    openUrl: string | null;
    siteUrl: string;
}

export const createIntegrationOpenUrl = (url: string, farmUuid: string) =>
    url.replace('FARM_UUID', farmUuid).replace('SUFFIX', config.get('integrationUrlSuffix') || '');

export interface IntegrationsState {
    farmIntegrations: null | FarmIntegration[];
}

const initialState: IntegrationsState = { farmIntegrations: null };

const integrationsSlice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        removeIntegration: (state, { payload }: PayloadAction<string>) => {
            if (state.farmIntegrations !== null) {
                state.farmIntegrations = state.farmIntegrations.filter((fi) => fi.uuid !== payload);
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadingFarm.toString(), () => initialState)
            .addCase(
                loadedFarmSuccess.toString(),
                (state, { payload }: PayloadAction<LoadedFarmSuccessPayload>) => {
                    state.farmIntegrations = payload.farmIntegrations;
                }
            );
    },
});

export const { removeIntegration } = integrationsSlice.actions;
export const integrationsReducer = integrationsSlice.reducer;

export const selectHasIntegrationWithScopeConnected = createSelector(
    (state: AppState, _scope: string) => state.integrationsState.farmIntegrations,
    (_state: AppState, scope: string) => scope,
    (farmIntegrations, scope) => {
        if (!farmIntegrations) {
            return null;
        }
        return !!farmIntegrations.find((fi) => fi.agreedScopes.includes(scope));
    }
);

export const selectHasSensorsConnected = (state: AppState) =>
    selectHasIntegrationWithScopeConnected(state, 'sensors:write');
export const selectHasVehiclesConnected = (state: AppState) =>
    selectHasIntegrationWithScopeConnected(state, 'vehicles:write');

export const selectHasPycnoConnected = createSelector(
    (state: AppState) => state.integrationsState.farmIntegrations,
    (farmIntegrations) =>
        farmIntegrations?.find(
            ({ integrationUUID }) => integrationUUID === '204c4a76-2eaa-4e76-82fc-8f01d687ff31'
        ) !== undefined
);
