import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { useCurrentManualSensor } from 'hooks/selectors/src/useCurrentManualSensor.selector';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { List, Set } from 'immutable';
import { ArrayField, Form } from 'informed';
import { preventDefaultAnd } from 'lib/dom';
import { addOrRemoveInSet } from 'lib/immutil';
import { updateManualSensorApi } from 'sensors/manual/manual-sensors-api';
import { fetchManualSensors } from 'sensors/manual/manual-sensors-state';
import type ManualSensor from 'sensors/manual/ManualSensor';
import Metric from 'sensors/Metric';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarError from 'sidebar/SidebarError';
import { useAppDispatch } from 'system/store';

import EditMetricFormField from './form/EditMetricFormField';
import MetricFormContainer from './form/MetricFormContainer';

interface FormValues {
    existingMetrics: Record<string, string>; // metric id => metric name map
    newMetrics: { metricName: string; metricType: string; metricUnit?: string }[];
}

const ManualSensorEdit = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currentFarm: farm } = useCurrentFarm();
    const { currentManualSensor: manualSensor } = useCurrentManualSensor();

    const { pending, error, setPromise } = usePromise<ManualSensor>(() => {
        dispatch(fetchManualSensors(farm.uuid));

        if (manualSensor) {
            navigate(`/farms/${farm.uuid}/data/${manualSensor.id}`);
        }
    });

    const [addingMetric, setAddingMetric] = useState(false);
    const [addedMetrics, setAddedMetrics] = useState<Set<string>>(Set());
    const [removedMetrics, setRemovedMetrics] = useState<Set<string>>(Set());

    if (manualSensor === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/data`} />;
    }

    const handleSubmit = (values: FormValues) => {
        const newMetrics = manualSensor.metrics
            .filter(
                (metric) =>
                    !removedMetrics.has(metric.id) &&
                    values.existingMetrics[metric.id] !== undefined
            )
            .map((metric) => metric.set('name', values.existingMetrics[metric.id]))
            .concat(
                values.newMetrics
                    ? values.newMetrics.map((newMetric) =>
                          Metric({
                              name: newMetric.metricName,
                              type: newMetric.metricType,
                              unit: newMetric.metricUnit,
                          })
                      )
                    : List()
            );

        const newManualSensor = manualSensor.merge({
            metrics: newMetrics,
        });

        setPromise(updateManualSensorApi(farm.uuid, newManualSensor));
    };

    const initialValues = {
        existingMetrics: manualSensor.metrics.reduce(
            (map, metric) => {
                map[metric.id] = metric.name;
                return map;
            },
            {} as Record<string, string>
        ),
    };

    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit} className="scrollable">
            <>
                <div className="non-scrolling">
                    <SidebarHeader className="px-5">
                        <Button
                            as={Link}
                            to={`/farms/${farm.uuid}/data/${manualSensor.id}`}
                            variant="danger-outline"
                            small
                        >
                            {t('cancel')}
                        </Button>
                        <SubmitButton disabled={pending} small>
                            {t(pending ? 'saving' : 'save')}
                        </SubmitButton>
                    </SidebarHeader>
                </div>
                <div className="scrolling">
                    <div className="pt-5">
                        <h4 className="px-5">{t('sensors_new_metric_title')}</h4>
                        <ArrayField field="newMetrics">
                            {({ add }) => (
                                <>
                                    {manualSensor.metrics
                                        .filter((metric) => !removedMetrics.has(metric.id))
                                        .map((metric) => (
                                            <EditMetricFormField
                                                key={metric.id}
                                                field="existingMetrics"
                                                metric={metric}
                                                onRemove={(metricId) => {
                                                    const nextRemovedMetrics =
                                                        removedMetrics.add(metricId);
                                                    setRemovedMetrics(nextRemovedMetrics);
                                                    if (
                                                        nextRemovedMetrics.size ===
                                                        manualSensor.metrics.size
                                                    ) {
                                                        setAddingMetric(true);
                                                        add();
                                                    }
                                                }}
                                            />
                                        ))}
                                    {/* @ts-ignore ArrayField does not have Items as a property in the informed type defs */}
                                    <ArrayField.Items>
                                        {({ remove, field }) => (
                                            <MetricFormContainer
                                                field={field}
                                                remove={remove}
                                                addedMetrics={addedMetrics}
                                                add={(id: string) => {
                                                    setAddedMetrics(
                                                        addOrRemoveInSet(addedMetrics, id)
                                                    );
                                                    setAddingMetric(false);
                                                }}
                                                required={
                                                    removedMetrics.size ===
                                                    manualSensor.metrics.size
                                                }
                                            />
                                        )}
                                        {/* @ts-ignore */}
                                    </ArrayField.Items>
                                    {!addingMetric && (
                                        <Button
                                            className="mt-5 mx-5"
                                            variant="outline"
                                            onClick={preventDefaultAnd(() => {
                                                setAddingMetric(true);
                                                add();
                                            })}
                                        >
                                            {t('sensors_new_metric_add')}
                                        </Button>
                                    )}
                                </>
                            )}
                        </ArrayField>
                    </div>
                </div>
                {error && (
                    <div className="non-scrolling">
                        <SidebarError
                            title={t('failed_to_save')}
                            message={t('something_went_wrong')}
                        />
                    </div>
                )}
            </>
        </Form>
    );
};

export default ManualSensorEdit;
