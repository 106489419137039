import { useLocation } from 'react-router-dom';

const useCurrentPageLocation = () => {
    const location = useLocation();

    const { pathname, hash } = location;

    return {
        currentPageLocation: pathname,
        currentPageHash: hash,
    };
};

export { useCurrentPageLocation };
