import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { farmHasCropCheck } from '@fieldmargin/webapp-farms';
import { OperationRecord } from 'components/operation-record';
import { SidebarCard } from 'components/sidebar-card';
import { useOperationDetailsActions } from 'hooks/actions';
import { usePasedInputsRecordings } from 'hooks/selectors';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import type { Set } from 'immutable';
import type FullOperation from 'operations/FullOperation';
import { OperationType } from 'operations/Operation';
import type Recording from 'operations/Recording';
import { prop } from 'ramda';

import OperationRecordingCard from './OperationRecordingCard';
import PesticidesCheckButton from './PesticidesCheckButton';

interface OperationRecordingsViewProps {
    // From parent
    fullOperation: FullOperation;
    recordings: Set<Recording> | null;
    totalArea: number;
    onSave: (recordings: Set<Recording>) => Promise<void>;
}

const OperationRecordingsView = ({
    fullOperation,
    recordings,
    totalArea,
    onSave,
}: OperationRecordingsViewProps) => {
    const { t } = useTranslation();
    const { inputs } = usePasedInputsRecordings();
    const { currentFarm } = useCurrentFarm();

    const { handleToggleAttachingRecordings: startAttachingRecordings } =
        useOperationDetailsActions();
    if (!recordings) {
        return null;
    }
    const hasInputs = inputs && inputs?.size > 0;

    return (
        <SidebarCard.Root>
            <SidebarCard.Header>
                <SidebarCard.Heading as="h4">{t('inputs_title')}</SidebarCard.Heading>
                <SidebarCard.AddButton onPress={startAttachingRecordings}>
                    {t('operation_details_add_input')}
                </SidebarCard.AddButton>
            </SidebarCard.Header>
            {hasInputs && (
                <Fragment>
                    <OperationRecord.Header />
                    <OperationRecord.List>
                        {inputs
                            .sortBy((operationRecording) =>
                                operationRecording.input.name.toLowerCase()
                            )
                            .map((operationRecording) => (
                                <OperationRecordingCard
                                    key={operationRecording.recording.uuid}
                                    fullOperation={fullOperation}
                                    operationRecording={operationRecording}
                                    totalArea={totalArea}
                                    onSave={onSave}
                                />
                            ))}
                    </OperationRecord.List>
                </Fragment>
            )}
            {fullOperation.summary.type === OperationType.SPRAY &&
                farmHasCropCheck(currentFarm) && (
                    <PesticidesCheckButton
                        fullOperation={fullOperation}
                        fieldUuids={fullOperation.fields?.map(prop('fieldUuid'))}
                        recordings={recordings}
                        className="self-end"
                    />
                )}
        </SidebarCard.Root>
    );
};

export { OperationRecordingsView };
