import { useSaveOperation } from 'hooks/query';
import { useTotalFieldsArea } from 'hooks/selectors/src/useOperationTotalFieldsArea';
import { useCurrentOperation } from 'hooks/useCurrentOperation';

import { OperationRecordingsView } from './OperationRecordingsView';

function EditableOperationRecordings() {
    const { currentOperation } = useCurrentOperation();
    const { totalFieldsArea } = useTotalFieldsArea();
    const { handleSaveOperation } = useSaveOperation();

    return (
        currentOperation && (
            <OperationRecordingsView
                fullOperation={currentOperation}
                recordings={currentOperation.recordings}
                totalArea={totalFieldsArea}
                onSave={handleSaveOperation}
            />
        )
    );
}

export { EditableOperationRecordings };
