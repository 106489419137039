import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import { asField, useFieldState } from 'informed';
import SidebarModule from 'sidebar/modules/SidebarModule';
import BubbleTag from 'view/molecules/BubbleTag';

import fileSvg from './file.svg';

import './NoteNewFile.css';

interface NoteNewFileProps {
    field: string;
}

const FileInputField = asField<{ field: string; multiple?: boolean }>(
    ({ field, fieldApi, multiple = false }) => (
        <input
            className="file-input"
            type="file"
            id={field}
            onChange={(e) => {
                const fileArr: File[] = [];
                const files = e.target.files as FileList;
                for (let i = 0; i < files.length; i++) {
                    files.item(i) && fileArr.push(files.item(i) as File);
                }
                fieldApi.setValue(fileArr);
            }}
            multiple={multiple}
        />
    )
);

const NoteNewFile = ({ field }: NoteNewFileProps) => {
    const { t } = useTranslation();
    const fieldState = useFieldState<File[]>(field);

    return (
        <SidebarModule className="NoteNewFile" editing={true}>
            <Button onClick={() => {}} className="flex items-center">
                <img className="h-5 mr-3" src={fileSvg} />{' '}
                {fieldState.value && fieldState.value.length > 0
                    ? t('create_note_change_file')
                    : t('create_note_attach_file')}
            </Button>
            <FileInputField field={field} multiple />
            {fieldState.value && (
                <div className="mt-4 space-y-3 space-x-3">
                    {fieldState.value.map((file, i) => (
                        <BubbleTag key={i} className="max-w-1/2">
                            <span className="truncate">{file.name}</span>
                        </BubbleTag>
                    ))}
                </div>
            )}
        </SidebarModule>
    );
};

export default NoteNewFile;
