import { compareAsc, compareDesc, isAfter, isBefore, subDays } from 'date-fns';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { List } from 'immutable';
import { mapMap } from 'lib/fp-helpers';
import { immutableMapToNativeMap } from 'lib/immutil';
import LocalStorageHelper from 'lib/storage/LocalStorageHelper';
import { logReselect } from 'lib/util/reselect-util';
import { curry, pipe } from 'ramda';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import type { LooseJobsActivityType } from 'types';
import { filterActivitiesByDateRange } from 'utils/filterActivitiesByDateRange';
import { filterActivitiesByDateType } from 'utils/filterActivitiesByDateType';
import { filterActivitiesByJobType } from 'utils/filterActivitiesByJobType';
import { filterActivitiesByNotes } from 'utils/filterActivitiesByNotes';
import { filterActivitiesByTaggedUsers } from 'utils/filterActivitiesByTaggedUsers';
import { filterActivitiesByTodo } from 'utils/filterActivitiesByToDo';
import { selectCurrentYear } from 'years/years-state';

import type { Activity } from '../activity/activity-helper';
import {
    activityIsNote,
    filterActivitiesByExtent,
    filterActivitiesBySearchString,
    getActivityYear,
    getDueDate,
    getLastActivityDate,
    getServerCreatedDate,
    groupActivitiesByStatus,
    isTodo,
} from '../activity/activity-helper';

/**
 * This selects all activities and does NOT filter by plan history limits. It should therefore
 * only be used when you actually do need to know the total number of activity items a farm has.
 */
export const selectAllActivities = createSelector(
    (state: AppState) => state.notesState.notes,
    (state: AppState) => state.operationsState.operations,
    (notes, operations): List<Activity> => {
        return List().concat(
            notes ? notes.values() : List(),
            operations ? operations.values() : List()
        );
    }
);

/**
 * Selects all activities filtering by the current active year. It does NOT filter by plan
 * history limits so should only be used when you need to know the total number of activity items
 * a farm has for the current farm year.
 */
export const selectCurrentYearActivities = createSelector(
    selectAllActivities,
    selectCurrentYear,
    (activities, year): List<Activity> =>
        activities.filter((activity) => activity && getActivityYear(activity) === year)
);

/**
 * Selects all activities filtering by the current year and plan history limit.
 */
export const selectCurrentYearLimitedActivities = createSelector(
    selectCurrentYearActivities,
    selectCurrentFarm,
    (activities, farm) => {
        const historyLimitDays = farm.plan.historyLimitDays;
        if (historyLimitDays) {
            return activities.filter((activity) => {
                const historyLimitDate = subDays(new Date(), historyLimitDays);
                return isAfter(getServerCreatedDate(activity), historyLimitDate);
            });
        }
        return activities;
    }
);

/**
 * Selects all activities filtering by plan history limit only.
 */
export const selectLimitedActivities = createSelector(
    selectAllActivities,
    selectCurrentFarm,
    (activities, farm) => {
        const historyLimitDays = farm.plan.historyLimitDays;
        if (historyLimitDays) {
            return activities.filter((activity) => {
                const historyLimitDate = subDays(new Date(), historyLimitDays);
                return isAfter(getServerCreatedDate(activity), historyLimitDate);
            });
        }
        return activities;
    }
);

/**
 * Select current year activities limited by plan limit that are outstanding
 */
export const selectCurrentYearOutstandingLimitedActivities = createSelector(
    selectCurrentYearLimitedActivities,
    (activities) => activities.filter(isTodo)
);

export const maybeFilterTasks =
    (listMode: LooseJobsActivityType) => (activities: List<Activity>) =>
        listMode === 'tasks' ? activities.filter(isTodo) : activities;

/**
 * Selects activities for the current year that match the current filters (excluding visibility).
 */

type FilterFunction = (activities: List<Activity>) => List<Activity>;

export const selectFilteredActivities = createSelector(
    selectCurrentYearLimitedActivities,
    (state: AppState) => state.notes.listMode,
    (state: AppState) => state.notes.noteSearchString,
    (state: AppState) => state.notes.filterByUserIds,
    (state: AppState) => state.notes.filterByJobs,
    (state: AppState) => state.notes.filterByNotes,
    (state: AppState) => state.notes.filterByTodo,
    (state: AppState) => state.notes.dateTypes,
    (state: AppState) => state.notes.dateRange,
    (
        activities,
        listMode,
        noteSearchString,
        filterByUserIds,
        filterByJobs,
        isNotesActive,
        isToDoActive,
        dateTypes,
        dateRage
    ) => {
        logReselect('selectFilteredActivities');
        const filterBySearchString: FilterFunction = curry(filterActivitiesBySearchString)(
            noteSearchString
        );
        const filterByTaggedUsers: FilterFunction = curry(filterActivitiesByTaggedUsers)(
            filterByUserIds
        );
        const filterByJobType: FilterFunction = curry(filterActivitiesByJobType)(filterByJobs);
        const filterByNotes: FilterFunction = curry(filterActivitiesByNotes)(isNotesActive);
        const filterByTodo: FilterFunction = curry(filterActivitiesByTodo)(isToDoActive);
        const filterByDateType: FilterFunction = curry(filterActivitiesByDateType)(dateTypes);
        const filterByDateRange: FilterFunction = curry(filterActivitiesByDateRange)(dateRage)(
            dateTypes
        );

        return pipe(
            maybeFilterTasks(listMode),
            filterBySearchString,
            filterByTaggedUsers,
            filterByJobType,
            filterByNotes,
            filterByTodo,
            filterByDateType,
            filterByDateRange
        )(activities);
    }
);

/**
 * Select summaries for notes and tasks that match the current year and filters (excl visibility)
 */
export const selectFilteredNoteSummaries = createSelector(selectFilteredActivities, (activities) =>
    activities.filter(activityIsNote)
);

/**
 * Selects searched and filtered notes that are visible on the screen, if that setting is enabled.
 */
export const selectVisibleFilteredActivities = createSelector(
    selectFilteredActivities,
    (state: AppState) =>
        state.farmEditingState.lastMapPosition && state.farmEditingState.lastMapPosition.extent,
    (state: AppState) => state.fieldsState.fields ?? List<Field>(),

    (activities, extent, fields): List<Activity> => {
        logReselect('selectVisibleFilteredNotes');
        if (!extent) {
            return activities;
        }
        return filterActivitiesByExtent(extent, fields, activities);
    }
);

export const selectMaybeVisibleFilteredActivities = createSelector(
    (state: AppState) =>
        state.notes.filterByScreen
            ? selectVisibleFilteredActivities(state)
            : selectFilteredActivities(state),
    (activities) => activities
);

/**
 * Selects searched, filtered and maybe visible notes in a sorted order based on list mode.
 */
export const selectSortedMaybeVisibleFilteredActivitiesGroupedByStatus = createSelector(
    selectMaybeVisibleFilteredActivities,
    (state: AppState) => (state.notes.listMode === 'tasks' ? 'due_date' : 'last_activity'),
    (activities, sortOrder): Map<'active' | 'archived', Activity[]> => {
        const grouped = immutableMapToNativeMap(groupActivitiesByStatus(activities));
        return mapMap(grouped, (activities) => sortActivities(activities, sortOrder).toArray());
    }
);

export const sortActivities = (
    activities: List<Activity>,
    sortOrder: 'due_date' | 'last_activity'
) => {
    // When sorting by due date, we have four sections:
    //  - tasks in TODO state with a due date, ordered by due date
    //  - tasks in TODO state without a due date, ordered by last activity
    //  - notes, and other tasks, ordered by last activity
    // When sorting by last modified, we have two sections:
    //  - notes and tasks, ordered by last activity

    const compare = (a: Activity, b: Activity) => {
        const aTodo = isTodo(a);
        const bTodo = isTodo(b);
        const aDueDate = getDueDate(a);
        const bDueDate = getDueDate(b);

        // If sorting by due date, these go first
        if (sortOrder === 'due_date') {
            if (aTodo && !bTodo) {
                return -1;
            }
            if (!aTodo && bTodo) {
                return 1;
            }

            if (aTodo && bTodo && aDueDate && bDueDate) {
                // Both in first section, order soonest due date first
                return compareAsc(aDueDate, bDueDate);
            }
            if (aTodo && aDueDate) {
                // A is in first, B in second
                return -1;
            }
            if (bTodo && bDueDate) {
                // B is in first, A is in second
                return 1;
            }
        }

        // Order by most recent first
        return compareDesc(getLastActivityDate(a), getLastActivityDate(b));
    };
    return activities.sort(compare);
};

export const shouldShowFarmHistoryModal = (state: AppState) => {
    const farm = selectCurrentFarm(state);
    const activities = selectAllActivities(state);
    if (!farm.plan.historyLimitDays || LocalStorageHelper.getItem('seenFarmHistoryModal')) {
        return false;
    }

    return (
        activities.filter((activity) => {
            const historyLimitDate = subDays(new Date(), farm.plan.historyLimitDays as number);
            return isBefore(getServerCreatedDate(activity), historyLimitDate);
        }).size > 0
    );
};

export const shouldShowFarmHistoryWarning = createSelector(
    selectCurrentFarm,
    selectCurrentYearActivities,
    selectCurrentYearLimitedActivities,
    (farm, yearActivities, yearLimitedActivities) => {
        if (!farm.plan.historyLimitDays) {
            return false;
        }
        if (yearActivities.size !== 0 && yearActivities.size !== yearLimitedActivities.size) {
            return true;
        }
        return false;
    }
);
