import type { PropsWithChildren } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/icon';
import { parseISO } from 'date-fns';
import { useDateFormat } from 'hooks/useDateFormat';
import { CalendarIcon } from 'icons/CalendarIcon';
import { dateRangeState, updateDateRangeEnd, updateDateRangeStart } from 'store/slices/notes.slice';
import { useAppDispatch, useAppSelector } from 'system/store';
import { normalizedDate } from 'utils/normalizeDate';
import { parseStringToDate } from 'utils/parseStringToDate';

import { FilterSelect } from '../FilterSelect';

import { FilterCalendar } from './FilterCalendar';

import styles from './FilterDatesRange.module.css';

function CalendarButton({ children }: PropsWithChildren) {
    return (
        <FilterSelect.Button className={styles.calendarButton}>
            <span>{children}</span>
            <Icon className={styles.calendarIcon}>
                <CalendarIcon />
            </Icon>
        </FilterSelect.Button>
    );
}

function FilterDatesRange() {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    const dispatch = useAppDispatch();
    const { startDate, endDate } = useAppSelector(dateRangeState);

    const parseStartDate = startDate ? parseISO(startDate) : undefined;
    const parseEndDate = endDate ? parseISO(endDate) : undefined;

    const startDateLabel = startDate ? parseStringToDate(startDate, format) : t('start_date');
    const endDateLabel = endDate ? parseStringToDate(endDate, format) : t('end_date');

    const onChangeStartDate = (date: Date) => {
        dispatch(updateDateRangeStart(normalizedDate(date).toISOString()));
    };

    const onChangeEndDate = (date: Date) => {
        dispatch(updateDateRangeEnd(normalizedDate(date).toISOString()));
    };

    return (
        <Fragment>
            <FilterSelect.Root>
                <CalendarButton>{startDateLabel}</CalendarButton>
                <FilterSelect.Popover>
                    <FilterCalendar
                        value={parseStartDate}
                        maxDate={parseEndDate}
                        onChange={onChangeStartDate}
                    />
                </FilterSelect.Popover>
            </FilterSelect.Root>

            <FilterSelect.Root>
                <CalendarButton>{endDateLabel}</CalendarButton>
                <FilterSelect.Popover>
                    <FilterCalendar
                        value={parseEndDate}
                        minDate={parseStartDate}
                        onChange={onChangeEndDate}
                    />
                </FilterSelect.Popover>
            </FilterSelect.Root>
        </Fragment>
    );
}

export { FilterDatesRange };
