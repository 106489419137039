import type { MouseEvent, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import FeatureLockedModal from 'view/modal/FeatureLockedModal';

import fieldInputIcon from './field-input-nav-icon.svg';
import grossMarginIcon from './gross-margin-nav-icon.svg';
import inputCostsIcon from './input-cost-nav-icon.svg';
import inputIcon from './input-nav-icon.svg';
import inputSummaryIcon from './input-summary-nav-icon.svg';
import nutrientIcon from './nutrient-nav-icon.svg';
import yieldIcon from './yield-nav-icon.svg';

interface ReportingNavProps {
    farm: Farm;
}

const ReportingNav = ({ farm }: ReportingNavProps) => {
    const { t } = useTranslation();
    const [showUpgradeModal, toggleUpgradeModal] = useToggle(false);

    const handleToggleUpgradeModal = () => {
        !showUpgradeModal && toggleUpgradeModal();
    };

    return (
        <nav className="ReportingNav">
            {showUpgradeModal && <FeatureLockedModal onClose={toggleUpgradeModal} />}
            <h2>{t('inputs_title')}</h2>
            <ul>
                <ReportingNavItem
                    to={`/farms/${farm.uuid}/reporting/input-summary`}
                    heading={t('input_summary_title')}
                    subheading={t('input_summary_desc')}
                    image={inputSummaryIcon}
                />
                <PremiumReportingNavItem
                    farm={farm}
                    to={`/farms/${farm.uuid}/reporting/input`}
                    heading={t('input_report_title')}
                    subheading={t('input_report_desc')}
                    image={inputIcon}
                    onNotAllowed={handleToggleUpgradeModal}
                />
                <ReportingNavItem
                    to={`/farms/${farm.uuid}/reporting/field-input-summary`}
                    heading={t('field_input_report_title')}
                    subheading={t('field_input_report_desc')}
                    image={fieldInputIcon}
                />
            </ul>
            <h2>{t('outputs_title')}</h2>
            <ul>
                <ReportingNavItem
                    to={`/farms/${farm.uuid}/reporting/yield`}
                    heading={t('yield_report_title')}
                    subheading={t('yield_report_desc')}
                    image={yieldIcon}
                />
            </ul>
            <h2>{t('costings_title')}</h2>
            <ul>
                <PremiumReportingNavItem
                    farm={farm}
                    to={`/farms/${farm.uuid}/reporting/input-costs`}
                    heading={t('input_costs_title')}
                    subheading={t('input_costs_desc')}
                    image={inputCostsIcon}
                    onNotAllowed={handleToggleUpgradeModal}
                />
                <PremiumReportingNavItem
                    farm={farm}
                    to={`/farms/${farm.uuid}/reporting/gross-margin`}
                    heading={t('gross_margin_title')}
                    subheading={t('gross_margin_desc')}
                    image={grossMarginIcon}
                    onNotAllowed={handleToggleUpgradeModal}
                />
            </ul>
            <h2>{t('nutrition_title')}</h2>
            <ul>
                <PremiumReportingNavItem
                    farm={farm}
                    to={`/farms/${farm.uuid}/reporting/nutrients`}
                    heading={t('nutrient_report_title')}
                    subheading={t('nutrient_report_desc')}
                    image={nutrientIcon}
                    onNotAllowed={handleToggleUpgradeModal}
                />
            </ul>
        </nav>
    );
};

const ReportingNavItem = ({
    to,
    heading,
    subheading,
    image,
    onClick,
}: {
    to: string;
    heading: string;
    subheading: string;
    image: any;
    onClick?: MouseEventHandler;
}) => (
    <li className="ReportingNavItem">
        <Link to={to} className="ReportingNavLink" onClick={onClick}>
            <div>
                <strong className="block uppercase text-xl mb-3">{heading}</strong>
                <span className="text-sm">{subheading}</span>
            </div>
            <img src={image} alt={heading} className="w-24" />
        </Link>
    </li>
);

const PremiumReportingNavItem = ({
    farm,
    onNotAllowed,
    requiredLevel = 'ALL_REPORTING',
    ...props
}: {
    farm: Farm;
    to: string;
    heading: string;
    subheading: string;
    image: any;
    onNotAllowed: VoidFunction;
    requiredLevel?: string;
}) => {
    const handleClick = (e: MouseEvent) => {
        if (farm.plan.reportingLevel !== requiredLevel) {
            e.preventDefault();
            onNotAllowed();
        }
    };

    return <ReportingNavItem {...props} onClick={handleClick} />;
};

export default ReportingNav;
