interface FieldsIconProps {
    className?: string;
    fill?: string;
}

const FieldsIcon = ({ className, fill = '#4da4da' }: FieldsIconProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 20 20"
            className={className}
        >
            <path
                style={{ fill }}
                d="M16.2,0H3.8C1.7,0,0,1.7,0,3.8v12.5C0,18.3,1.7,20,3.8,20h12.5c2.1,0,3.7-1.7,3.8-3.8V3.8
		C20,1.7,18.3,0,16.2,0z M18.5,16.2c0,1.2-1,2.2-2.2,2.2H3.8c-1.2,0-2.2-1-2.2-2.2V3.8c0-1.2,1-2.2,2.2-2.2h12.5
		c1.2,0,2.2,1,2.2,2.2C18.5,3.8,18.5,16.2,18.5,16.2z M14.5,3.9c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.5,0.2l-1.4,1.4l-1.4-1.4
		c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.5,0.2L8.1,5.3L6.7,3.9C6.5,3.8,6.3,3.7,6.1,3.7c-0.2,0-0.4,0.1-0.5,0.2L3.6,5.9
		c-0.3,0.3-0.3,0.8,0,1.1s0.8,0.3,1.1,0l1.4-1.4l1.4,1.4c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0L10,5.5l1.4,1.4c0.3,0.3,0.8,0.3,1.1,0
		c0,0,0,0,0,0l1.4-1.4l1.4,1.4c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1L14.5,3.9z M14.5,8.5c-0.2-0.1-0.4-0.2-0.6-0.2
		c-0.2,0-0.4,0.1-0.5,0.2l-1.4,1.4l-1.4-1.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.5,0.2L8.1,9.9L6.7,8.5
		C6.5,8.4,6.3,8.3,6.1,8.3c-0.2,0-0.4,0.1-0.5,0.2l-1.9,1.9c-0.3,0.3-0.3,0.8,0,1.1s0.8,0.3,1.1,0l1.4-1.4l1.4,1.4
		c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0l1.4-1.4l1.4,1.4c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0l1.4-1.4l1.4,1.4c0.3,0.3,0.8,0.3,1.1,0
		c0.3-0.3,0.3-0.8,0-1.1l0,0L14.5,8.5z M14.5,13.1c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.5,0.2l-1.4,1.4l-1.4-1.4
		c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.5,0.2l-1.4,1.4l-1.4-1.4c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.5,0.2L3.6,15
		c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0l0,0l1.4-1.4L7.5,16c0.3,0.3,0.8,0.3,1.1,0l1.4-1.4l1.4,1.4c0.3,0.3,0.8,0.3,1.1,0
		c0,0,0,0,0,0l1.4-1.4l1.4,1.4c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1l0,0L14.5,13.1z"
            />
        </svg>
    );
};

export default FieldsIcon;
