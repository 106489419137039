import type { PropsWithChildren } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { Map } from 'immutable';
import type { FormState } from 'informed';
import { useFormState } from 'informed';
import Modal from 'modal/Modal';
import { useAppDispatch } from 'system/store';
import { clearModal } from 'system/ui-state';
import ChoiceModal from 'view/modal/ChoiceModal';

interface UnsavedFormChangesCheckerProps extends PropsWithChildren {
    copy: string[];
    initialValues?: any;
}
const UnsavedFormChangesChecker = ({
    children,
    copy,
    initialValues,
}: UnsavedFormChangesCheckerProps) => {
    const { t } = useTranslation();
    const formState = useFormState() as FormState<any>;
    const dispatch = useAppDispatch();
    const blocker = useBlocker(({ nextLocation }) => {
        if (nextLocation && (!nextLocation.state || !nextLocation.state.ignoreUnsavedChanges)) {
            // If form has shapes
            if (formState.values && formState.values.shapes) {
                if (
                    Object.keys(formState.values).length > 1 ||
                    formState.values.shapes.shapes.features.size > 0
                ) {
                    return true;
                }
            } else {
                // Compare current values with initial values if provided
                if (initialValues && !Map(initialValues).equals(Map(formState.values))) {
                    return true;
                } else if (!initialValues && formState.dirty) {
                    return true;
                }
            }
        }

        return false;
    });

    const clearModalHandler = () => {
        dispatch(clearModal);
    };

    return (
        <Fragment>
            {blocker.state === 'blocked' ? (
                <Modal onClose={blocker.reset}>
                    <ChoiceModal
                        clearModal={clearModalHandler}
                        onConfirm={blocker.reset}
                        onCancel={blocker.proceed}
                        cancelText={t('label_leave')}
                        confirmText={t('label_stay')}
                        content={
                            <div className="w-80 mb-5">
                                <h2 className="uppercase text-xxl">
                                    {t('unsaved_changes_header')}
                                </h2>
                                {copy.map((c, i) => (
                                    <p key={i} className="text-2xl">
                                        {c}
                                    </p>
                                ))}
                            </div>
                        }
                    />
                </Modal>
            ) : null}

            {children}
        </Fragment>
    );
};

export default UnsavedFormChangesChecker;
