import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { makeAuthorizationRequest } from '@fieldmargin/webapp-auth';
import { findByUserWithOwners } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import AppError from 'components/AppError';
import PageLoading from 'components/PageLoading';
import { setFarms } from 'farms/farms-state';
import { fetchFarmInvites } from 'farms/invites/farm-invites-state';
import { useAction } from 'lib/hooks';
import { isString } from 'lodash';
import { fetchUserPendingOrganisationInvites } from 'organisation/organisation-api';
import { setOrganisationInvites } from 'organisation/organisation-state';
import queryString from 'query-string';
import { useAppDispatch } from 'system/store';
import { getUserAccount, setUser, setUserPreferences } from 'users/user-state';
import { getUserPreferencesApi } from 'users/users-api';
import BlockingModalCheck from 'view/system/BlockingModalCheck';

import { useAppInitialization } from './AppInitialization';
import { FarmInitialise } from './FarmInitialise';

/**
 * Any routes within this container will require the user to have logged in.
 * If the user is not logged in they'll be redirect to the sign in page.
 */
const FieldmarginAuthenticatedContainer = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { signedIn } = useAppInitialization();

    const setUserAction = useAction(setUser);
    const setFarmsAction = useAction(setFarms);
    const setUserPreferencesAction = useAction(setUserPreferences);
    const setOrganisationInvitesAction = useAction(setOrganisationInvites);
    const { notYetFetched, pending, error, setPromise } = usePromise();

    useEffect(() => {
        const params = queryString.parse(location.search);
        const inviteUuid = isString(params.uuid) ? params.uuid : undefined;
        setPromise(
            Promise.all([
                getUserAccount().then(setUserAction),
                findByUserWithOwners().then(setFarmsAction),
                dispatch(fetchFarmInvites(inviteUuid)),
                fetchUserPendingOrganisationInvites().then(setOrganisationInvitesAction),
                getUserPreferencesApi().then(setUserPreferencesAction),
            ])
        );
    }, []);

    if (!signedIn || notYetFetched || pending) {
        if (!signedIn) {
            makeAuthorizationRequest();
        }
        return <PageLoading />;
    }

    if (error) {
        return <AppError />;
    }

    return (
        <BlockingModalCheck>
            <FarmInitialise nextRoute="notes">
                <Outlet />
            </FarmInitialise>
        </BlockingModalCheck>
    );
};

export { FieldmarginAuthenticatedContainer };
