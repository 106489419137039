import { useAppSelector } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

const useCurrentYearSelector = () => {
    const currentYear = useAppSelector((state) => selectCurrentYear(state));

    return { currentYear };
};

export { useCurrentYearSelector };
