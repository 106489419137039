import type { GeoFeature, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { getZIndex } from 'components/maps/model/MapLayerIndex';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import { selectIsSelectingFields } from 'farm-editing/farm-editing-state';
import type Feature from 'features/Feature';
import { selectFilteredFeatures } from 'features/features-selectors';
import type FeatureType from 'features/FeatureType';
import { List } from 'immutable';
import { DEFAULT_COLOUR } from 'lib/colours';
import { logReselect } from 'lib/util/reselect-util';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import { VisibilityOptions } from 'system/types';
import type { ActiveSection } from 'system/url-util';

/**
 * - Features are always shown regardless of the active section
 * - No features are shown if the user is selecting fields
 * - No features are shown if the user has turned off visibility
 * - If the user has turned off visibility for a particular feature usage, features within that
 *   will not be shown.
 * - If a note or field is selected or being created/edited, features will be dimmed
 * - If a or feature is selected or being edited or created then the other features will be dimmed
 */
export const selectFeatures = createSelector(
    selectFilteredFeatures,
    (state: AppState) => state.featuresState.featureTypes,
    (_state: AppState, activeSection: ActiveSection) => activeSection.main === 'features',
    (_state: AppState, _activeSection: ActiveSection, selectedFeatureUuid?: string) =>
        selectedFeatureUuid,
    (
        _state: AppState,
        _activeSection: ActiveSection,
        _selectedFeatureUuid: string | undefined,
        dimShapes: boolean
    ) => dimShapes,
    (state: AppState) => state.farmEditingState.editingType,
    (state: AppState) => state.farmEditingState.editingData,
    (state: AppState) => state.farmEditingState.editingGeoFeatureCollection,
    (state: AppState) => state.farmEditingState.layerVisibility.featuresVisibility,
    (state: AppState) => state.farmEditingState.layerVisibility.hiddenFeatureTypes,
    (state: AppState) => selectIsSelectingFields(state),
    (state: AppState) => state.farmEditingState.highlightedGeoFeatureId,
    (
        features: List<Feature>,
        featureTypes: List<FeatureType>,
        isActiveSection,
        selectedFeatureUuid,
        dimShapes,
        editingType,
        editingData,
        editingGeoFeatureCollection,
        featuresVisibility,
        hiddenFeatureTypes,
        isSelectingFields,
        highlightedGeoFeatureId
    ): GeoFeature[] => {
        logReselect('selectFeatures');
        if (isSelectingFields) {
            return [];
        }

        const visibleFeatures = features.filter((feature) => {
            return (
                feature.uuid === selectedFeatureUuid ||
                feature.uuid === editingData.id ||
                (featuresVisibility !== 'off' &&
                    !hiddenFeatureTypes.contains(feature.featureTypeUuid))
            );
        });

        let geoFeatures = List();
        visibleFeatures.forEach((feature) => {
            const featureType = featureTypes.find(
                (featureType) => featureType.uuid === feature.featureTypeUuid
            );
            if (feature.uuid === editingData.id && editingGeoFeatureCollection) {
                geoFeatures = geoFeatures.concat(
                    featureToGeoFeatures(
                        highlightedGeoFeatureId,
                        isActiveSection,
                        featuresVisibility,
                        feature,
                        featureType,
                        editingGeoFeatureCollection,
                        false,
                        true
                    )
                );
            } else {
                geoFeatures = geoFeatures.concat(
                    featureToGeoFeatures(
                        highlightedGeoFeatureId,
                        isActiveSection,
                        featuresVisibility,
                        feature,
                        featureType,
                        feature.geoJson,
                        dimShapes && feature.uuid !== selectedFeatureUuid,
                        false
                    )
                );
            }
        });

        if (editingType === 'feature' && !editingData.id && editingGeoFeatureCollection) {
            // New feature being created
            geoFeatures = geoFeatures.concat(
                featureToGeoFeatures(
                    highlightedGeoFeatureId,
                    isActiveSection,
                    featuresVisibility,
                    null,
                    undefined,
                    editingGeoFeatureCollection,
                    false,
                    true
                )
            );
        }
        return geoFeatures.toArray();
    }
);

export const selectNonPointFeatures = createSelector(selectFeatures, (features) =>
    features.filter((feature) => feature.geometry.type !== 'Point')
);

export const selectPointFeatures = createSelector(selectFeatures, (features) =>
    features.filter((feature) => feature.geometry.type === 'Point')
);

const featureToGeoFeatures = (
    highlightedGeoFeatureId: string | null,
    isActiveSection: boolean,
    featuresVisibility: VisibilityOptions,
    feature: Feature | null,
    featureType: FeatureType | undefined,
    geoFeatureCollection: GeoFeatureCollection,
    isDimmed: boolean,
    isEditing: boolean
) => {
    let label = undefined as string | undefined;
    if (isActiveSection && feature && !isDimmed) {
        label = feature.name;
    }
    return geoFeatureCollection.features.map((geoFeature) => {
        const isHighlighted = geoFeature.id === highlightedGeoFeatureId;
        return geoFeature.set(
            'properties',
            OLFeatureProperties({
                type: 'feature',
                editable: isEditing,
                colour: featureType ? featureType.colour : DEFAULT_COLOUR,
                pointScale: geoFeature.geometry.type === 'Point' ? 0.2 : isHighlighted ? 9 : 6,
                strokeWeight: isHighlighted ? 3 : 2,
                strokeOpacity: isDimmed ? 0.5 : 1,
                fillOpacity:
                    featuresVisibility === VisibilityOptions.ON ? (isDimmed ? 0.2 : 0.4) : 0,
                zIndex: getZIndex(isActiveSection, 'feature', geoFeature.geometry.type),
                label,
            })
        );
    });
};
