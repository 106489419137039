import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import Promise from 'bluebird';
import { AttachmentType } from 'farm-editing/attachments';
import {
    setEditingAttachmentsByType,
    startSelectingMultipleAttachments,
    stopEditing,
} from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { mergeFieldAndWriteProps } from 'fields/Field';
import { selectDisplayableFieldUsagesWithNotSet } from 'fields/field-usage-selectors';
import { updateField } from 'fields/fields-api';
import { setFields } from 'fields/fields-state';
import type FieldUsage from 'fields/FieldUsage';
import AddFieldUsage from 'fields/usage/AddFieldUsage';
import { useAddingUsageState } from 'fields/usage/adding-usage-state';
import { useSegmentTracking } from 'hooks/useSegmentTracking';
import type { List } from 'immutable';
import { Set } from 'immutable';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import { selectEditingFields } from 'sidebar/modules/shapes/shapes-util';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';

import FieldSection from './FieldSection';
import FieldUsageSection from './FieldUsageSection';

interface BulkUsageContainerProps {
    farm: Farm;
    year: number;
    fieldUsages: List<FieldUsage>;

    setFields: typeof setFields;
    editingFields: List<Field>;
    setEditingFieldUuids: SingleParamVoidFunction<Set<string>>;
    startSelectingMultipleAttachments: typeof startSelectingMultipleAttachments;
    stopEditing: typeof stopEditing;
}

export enum BulkUsageStep {
    SELECT_USAGE,
    SELECT_FIELDS,
}

const BulkUsageContainer = ({
    farm,
    year,
    fieldUsages,
    setFields,
    editingFields,
    setEditingFieldUuids,
    startSelectingMultipleAttachments,
    stopEditing,
}: BulkUsageContainerProps) => {
    const { t } = useTranslation();
    const { setUsages, createUsage } = useSegmentTracking();

    const navigate = useNavigate();
    const [step, setStep] = useState(BulkUsageStep.SELECT_USAGE);
    const [selectedFieldUsageUuid, setSelectedFieldUsageUuid] = useState('');
    const [{ addingUsage }, toggleAddingUsage] = useAddingUsageState();

    useEffect(() => {
        startSelectingMultipleAttachments(AttachmentType.FIELD);
        return () => {
            stopEditing();
        };
    }, []);

    const { pending, error, setPromise } = usePromise<{ fields: Field[]; reset: boolean }>(
        ({ fields, reset }) => {
            setFields(fields);

            if (reset) {
                setSelectedFieldUsageUuid('');
                setEditingFieldUuids(Set());
                setStep(BulkUsageStep.SELECT_USAGE);
            } else {
                navigate(`/farms/${farm.uuid}/fields`);
            }
        }
    );

    const handleSet = (reset: boolean) => {
        setUsages();

        const fieldUsageUuid =
            selectedFieldUsageUuid === 'none' ? undefined : selectedFieldUsageUuid;
        setPromise(
            Promise.props({
                fields: Promise.all(
                    editingFields.map((field) =>
                        updateField(farm.uuid, field.uuid, {
                            yearFieldUsage: { year, fieldUsageUuid },
                        }).then((savedData) => mergeFieldAndWriteProps(field, savedData))
                    )
                ),
                reset,
            })
        );
    };

    const handleUsageChosen = (fieldUsageUuid: string) => {
        setSelectedFieldUsageUuid(fieldUsageUuid);
        setStep(BulkUsageStep.SELECT_FIELDS);
    };

    const handleUsageCreated = (fieldUsage: FieldUsage) => {
        handleUsageChosen(fieldUsage.uuid);
        toggleAddingUsage();
        createUsage();
    };

    const handleEditUsage = () => {
        setStep(BulkUsageStep.SELECT_USAGE);
    };

    if (addingUsage) {
        return <AddFieldUsage onBack={toggleAddingUsage} onSave={handleUsageCreated} />;
    }

    return (
        <div className="FieldUsageFieldEdit scrollable">
            <div className="non-scrolling">
                <SidebarHeader className="px-5 py-3.5 h-auto items-start">
                    <Button
                        as={Link}
                        to={`/farms/${farm.uuid}/fields`}
                        variant="danger-outline"
                        small
                    >
                        {t('cancel')}
                    </Button>
                    <div className="flex flex-wrap gap-4 justify-end">
                        <Button
                            variant="outline"
                            disabled={pending || selectedFieldUsageUuid === ''}
                            onClick={handleSet.bind(null, true)}
                            small
                        >
                            {t('save_and_set_another')}
                        </Button>
                        <Button
                            disabled={pending || selectedFieldUsageUuid === ''}
                            onClick={handleSet.bind(null, false)}
                            small
                        >
                            {t('save')}
                        </Button>
                    </div>
                </SidebarHeader>
                {error && (
                    <SidebarError title={t('failed_to_save')} message={t('something_went_wrong')} />
                )}
            </div>
            <div className="scrolling">
                <FieldUsageSection
                    step={step}
                    fieldUsages={fieldUsages}
                    selectedFieldUsageUuid={selectedFieldUsageUuid}
                    onUsageChosen={handleUsageChosen}
                    onEditUsage={handleEditUsage}
                    onAddNewUsage={toggleAddingUsage}
                />

                <FieldSection
                    step={step}
                    selectedFields={editingFields}
                    setSelectedFields={setEditingFieldUuids}
                />
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state) as Farm,
        year: state.yearsState.currentYear as number,
        fieldUsages: selectDisplayableFieldUsagesWithNotSet(state),
        editingFields: selectEditingFields(state),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setFields,
                setEditingFieldUuids: setEditingAttachmentsByType(AttachmentType.FIELD),
                startSelectingMultipleAttachments,
                stopEditing,
            },
            dispatch
        )
)(BulkUsageContainer);
