import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import { useAppDispatch } from 'system/store';
import { changeModal, rememberCurrentLocation } from 'system/ui-state';
import FeatureLockedModal from 'view/modal/FeatureLockedModal';

import editCostsSvg from './edit-costs.svg';

interface EditInputCostsButtonProps {
    farm: Farm;
    className?: string;
}

const EditInputCostsButton = ({ farm, className }: EditInputCostsButtonProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showUpgradeModal, toggleShowUpgradeModal] = useToggle(false);

    const handleCostsClick = (e: MouseEvent) => {
        e.preventDefault();

        if (farm.plan.reportingLevel !== 'ALL_REPORTING') {
            !showUpgradeModal && toggleShowUpgradeModal();
            return;
        }

        dispatch(
            changeModal({
                name: 'choice',
                props: {
                    content: (
                        <div className="w-80 mb-5">
                            <h3 className="uppercase">{t('edit_costs_warning_title')}</h3>
                            <p>{t('edit_costs_warning_desc')}</p>
                        </div>
                    ),
                    confirmText: t('confirm_understant'),
                    onConfirm: () => {
                        dispatch(rememberCurrentLocation());
                        navigate(`/farms/${farm.uuid}/reporting/input-summary/edit-costs`);
                    },
                },
            })
        );
    };

    return (
        <>
            {showUpgradeModal && <FeatureLockedModal onClose={toggleShowUpgradeModal} />}
            <Button
                as={Link}
                to={`/farms/${farm.uuid}/reporting/input-summary/edit-costs`}
                variant="outline"
                small
                onClick={handleCostsClick}
                className={c('flex items-center', className)}
            >
                <img src={editCostsSvg} alt="Icon showing a money bag" className="w-5 mr-2" />
                {t('label_edit_costs')}
            </Button>
        </>
    );
};

export default EditInputCostsButton;
