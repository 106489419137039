import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { DiscussionList } from 'components/discussion-list';
import commentsEmpty from 'notes/sidebar/details/comments-empty.png';
import EmptyState from 'sidebar/modules/EmptyState';

import { OperationCommentInput } from './OperationCommentInput';
import { useEditableOperationDetailsContext } from './useEditableOperationDetailsContext';

function OperationComments() {
    const { t } = useTranslation();
    const { chatLog } = useEditableOperationDetailsContext();

    return (
        <Fragment>
            {!chatLog.dayChatGroups.size && (
                <EmptyState image={commentsEmpty}>
                    <p>{t('activity_log_empty_comments_title')}</p>
                </EmptyState>
            )}
            <DiscussionList chatLog={chatLog} />

            <OperationCommentInput />
        </Fragment>
    );
}

export { OperationComments };
