import { clsx } from 'clsx';
import type Input from 'inputs/Input';
import { getActiveInputMicronutrients, getActiveInputNutrients } from 'inputs/Input';
import { InputNutrientSymbol } from 'inputs/InputNutrients';
import { capitaliseWord } from 'lib/util/text';
import FormattedRate from 'view/units/FormattedRate';
import ShortUnit from 'view/units/ShortUnit';

import {
    calculateNutrientRate,
    calculateTotalFromRateAndArea,
} from '../details/recording/operation-recording-utils';

interface RecordingNutrientRowsProps {
    input: Input;
    rate: number;
    area: number;
    editing?: boolean;
}

const RecordingNutrientRows = (props: RecordingNutrientRowsProps) => {
    const nutrients = getActiveInputNutrients(props.input).toArray();
    const micronutrients = getActiveInputMicronutrients(props.input).toArray();
    return (
        <>
            {nutrients.map(([nutrient, percentage], index, arr) => (
                <Row
                    key={nutrient}
                    {...props}
                    nutrient={nutrient}
                    percentage={percentage}
                    className={clsx({
                        'last-row': micronutrients.length === 0 && index === arr.length - 1,
                    })}
                />
            ))}
            {micronutrients.map(([nutrient, percentage], index, arr) => (
                <Row
                    key={nutrient}
                    {...props}
                    nutrient={nutrient}
                    percentage={percentage}
                    className={clsx({ 'last-row': index === arr.length - 1 })}
                />
            ))}
        </>
    );
};

export default RecordingNutrientRows;

interface RowProps {
    input: Input;
    nutrient: string;
    area: number;
    rate: number;
    percentage: number;
    editing?: boolean;
    className?: string;
}
const Row = ({ input, nutrient, area, rate, percentage, editing = false, className }: RowProps) => {
    const nutrientRate = calculateNutrientRate(rate, percentage);
    return (
        <tr
            key={nutrient}
            className={clsx(
                'spaced nutrient-row',
                // 'last-row': index === arr.length - 1,
                className
            )}
        >
            <td colSpan={3}>{capitaliseWord(nutrient)}</td>
            <td className="recording-rate">
                <FormattedRate rate={nutrientRate} /> <InputNutrientSymbol nutrient={nutrient} />
            </td>
            <td className="recording-total">
                <FormattedRate rate={calculateTotalFromRateAndArea(area, nutrientRate)} />{' '}
                <ShortUnit unit={input.unit} />
            </td>
            {editing && <td className="remove" />}
        </tr>
    );
};
