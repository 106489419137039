import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import EmptyState from 'sidebar/modules/EmptyState';
import { trackEvent } from 'utils/trackEvent';
import ExternalLink from 'view/ExternalLink';

import fieldHealthImg from './field-health.png';

interface FieldHealthUpgradeMessageProps {
    farm: Farm;
    isFarmOwner: boolean;
    trialAvailable: boolean;
}

const FieldHealthUpgradeMessage = ({
    farm,
    isFarmOwner,
    trialAvailable,
}: FieldHealthUpgradeMessageProps) => {
    const { t } = useTranslation();

    return (
        <EmptyState
            header="Use field health to identify problems and save you time"
            image={fieldHealthImg}
            className="separator-bottom"
        >
            <Markdown
                components={{
                    a: (props) => (
                        <ExternalLink {...props} href="https://www.fieldmargin.com/field-health" />
                    ),
                }}
            >
                {t('field_health_description')}
            </Markdown>
            <p>
                {isFarmOwner && (
                    <Button
                        as={Link}
                        to={`/farms/${farm.uuid}/billing/upgrade`}
                        onClick={() =>
                            trackEvent('Field health upgrade link clicked', {
                                farmUuid: farm.uuid,
                            })
                        }
                    >
                        {t(trialAvailable ? 'start_14_day_free_trial' : 'upgrade_to_pro')}
                    </Button>
                )}
            </p>
        </EmptyState>
    );
};

export default FieldHealthUpgradeMessage;
