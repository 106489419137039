import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import icon from 'components/icons/icon';
import { AttachmentType } from 'farm-editing/attachments';
import { setEditingAttachmentsByType } from 'farm-editing/farm-editing-state';
import type Herd from 'herd/Herd';
import { Set } from 'immutable';
import { useAction } from 'lib/hooks';
import RememberingLinkButton from 'view/RememberingLinkButton';

interface HerdAddNoteProps {
    // From parents
    farm: Farm;
    herd: Herd;
}

const HerdAddNote = ({ farm, herd }: HerdAddNoteProps) => {
    const { t } = useTranslation();
    const setEditingHerdUuids = useAction(setEditingAttachmentsByType(AttachmentType.HERD));
    const handleClick = () => {
        setEditingHerdUuids(Set.of(herd.uuid));
    };
    return (
        <RememberingLinkButton
            to={`/farms/${farm.uuid}/notes/new`}
            variant="outline"
            onClick={handleClick}
            className="flex-inline items-center gap-2"
        >
            {icon('note', 'blue')} {t('add_note')}
        </RememberingLinkButton>
    );
};

export default HerdAddNote;
