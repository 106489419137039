import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { GeoPoint } from '@fieldmargin/webapp-geo';
import MapPosition from 'components/maps/model/MapPosition';
import { setMapView } from 'farm-editing/farm-editing-state';
import { bindActionCreators } from 'redux';

interface ViewLocationLinkProps {
    text?: string;
    location: GeoPoint;
    setMapView: typeof setMapView;
}

const ViewLocationLink = ({ text, location, setMapView }: ViewLocationLinkProps) => {
    const { t } = useTranslation();
    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        setMapView({
            type: 'position',
            payload: MapPosition({
                centre: location.coordinates,
                zoom: 18,
            }),
        });
    };

    return (
        <button onClick={handleClick} className="bare-btn inline-block mr-4 underline">
            {text || t('operation_details_view_on_map')}
        </button>
    );
};

export default connect(
    () => ({}),
    (dispatch) =>
        bindActionCreators(
            {
                setMapView,
            },
            dispatch
        )
)(ViewLocationLink);
