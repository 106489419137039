import { useEffect, useRef, useState } from 'react';
import { Input, NumberField } from 'react-aria-components';
import { clsx } from 'clsx';
import { useField } from 'informed';

import type { TextInputFormFieldProps } from './TextInputFormField';

type PulsingNumberInputProps = Omit<TextInputFormFieldProps, 'onChange'> & {
    onChange: (value: number) => void;
};

const PulsingNumberInput = ({ field, onChange, ...props }: PulsingNumberInputProps) => {
    const { fieldApi, fieldState } = useField<number>({ field });
    const timeout = useRef<NodeJS.Timeout>();

    const [pulse, setPulse] = useState(false);
    useEffect(() => {
        if (fieldState.value !== undefined) {
            setPulse(true);
            timeout.current = setTimeout(() => {
                setPulse(false);
                setSelfChange(false);
            }, 500);
        }
    }, [fieldState.value]);

    const [selfChange, setSelfChange] = useState(false);
    const handleChange = (value: number) => {
        setSelfChange(true);
        fieldApi.setValue(value);
        if (onChange) onChange(value);
    };
    return (
        <NumberField value={fieldState.value} minValue={0} onChange={handleChange}>
            <Input
                className={clsx('PulsingTextInput simple-text-input', props.inputClassName, {
                    pulse: pulse && !selfChange,
                    'has-error': fieldState.error,
                })}
                autoComplete="off"
                onClick={props.onClick}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
            />
        </NumberField>
    );
};

export default PulsingNumberInput;
