import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { List } from 'immutable';
import { preventDefaultAnd } from 'lib/dom';
import { andThen, pipe, prop } from 'ramda';
import { bindActionCreators } from 'redux';
import type ManualSensor from 'sensors/manual/ManualSensor';
import type ManualSensorReadingGroup from 'sensors/manual/ManualSensorReadingGroup';
import PrimaryPlusButton from 'sidebar/modules/PrimaryPlusButton';
import SidebarModule from 'sidebar/modules/SidebarModule';
import { changeModal } from 'system/ui-state';

import { useSaveReadingGroup } from '../manual-sensor-hooks';

import ManualSensorHistoryItem from './ManualSensorHistoryItem';
import ManualSensorReadingGroupForm from './ManualSensorReadingGroupForm';

interface ManualSensorHistoryProps {
    // From parent
    manualSensor: ManualSensor;
    // From redux
    changeModal: typeof changeModal;
}

const ManualSensorHistory = ({ manualSensor, changeModal }: ManualSensorHistoryProps) => {
    const { t } = useTranslation();
    const saveReadingGroup = useSaveReadingGroup(manualSensor);
    const [addingReading, toggleAddingReading] = useToggle(false);

    const handleShowHistory = preventDefaultAnd(() => {
        changeModal({
            name: 'manual-sensor-history',
            props: { manualSensor },
        });
    });

    const handleSaveReadingGroup = pipe(saveReadingGroup, andThen(toggleAddingReading));

    const readingGroups = (manualSensor.readingGroups ?? List<ManualSensorReadingGroup>())
        .sortBy(prop('timestamp'))
        .reverse();

    return (
        <>
            {addingReading && (
                <ManualSensorReadingGroupForm
                    manualSensor={manualSensor}
                    onSave={handleSaveReadingGroup}
                    onCancel={toggleAddingReading}
                />
            )}
            {!addingReading && (
                <SidebarModule className="flex items-center justify-between">
                    {readingGroups.size === 0 && (
                        <p className="mb-0 text-gray-600 w-2/3">
                            <em>{t('sensors_history_empty_title')}</em>
                        </p>
                    )}
                    {manualSensor.hasGraphableMetrics() && (
                        <Button
                            variant="outline"
                            onClick={handleShowHistory}
                            small
                            className="flex items-center gap-2"
                        >
                            {icon('graph', 'blue')} {t('sensor_details_view_graph')}
                        </Button>
                    )}
                    <PrimaryPlusButton onClick={toggleAddingReading} small>
                        {t('sensors_add_reading')}
                    </PrimaryPlusButton>
                </SidebarModule>
            )}
            {readingGroups.map((readingGroup) => (
                <ManualSensorHistoryItem
                    key={readingGroup.id}
                    manualSensor={manualSensor}
                    readingGroup={readingGroup}
                />
            ))}
        </>
    );
};

export default connect(
    () => ({}),
    (dispatch) => bindActionCreators({ changeModal }, dispatch)
)(ManualSensorHistory);
