import type { ReactNode } from 'react';
import c from 'classnames';

interface ErrorMessageProps {
    className?: string;
    children: ReactNode;
}

const ErrorMessage = ({ children, className }: ErrorMessageProps) => {
    return <p className={c('text-error-text mb-4', className)}>{children}</p>;
};

export default ErrorMessage;
