import { useDeleteFarm, useLeaveFarm } from 'farms/farm-hooks';

import { useIsFarmOwner } from './selectors/src/useIsFarmOwner';
import { useCurrentFarm } from './useCurrentFarm';

function useFarmAction() {
    const { isFarmOwner } = useIsFarmOwner();
    const { currentFarm } = useCurrentFarm();
    const [deletePending, deleteError, handleDelete] = useDeleteFarm(currentFarm);
    const [leavePending, leaveError, handleLeave] = useLeaveFarm(currentFarm);

    const pending = isFarmOwner ? deletePending : leavePending;
    const error = isFarmOwner ? deleteError : leaveError;
    const handleClick = isFarmOwner ? handleDelete : handleLeave;

    return { pending, error, handleClick };
}

export { useFarmAction };
