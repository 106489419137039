import type { DependencyList } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import type { GeoObject } from '@fieldmargin/webapp-geo';
import { extentFromGeo } from '@fieldmargin/webapp-geo';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import { usePromise } from '@fieldmargin/webapp-state';
import type { Action, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { selectDrawingTool, setMapView } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { List, Set } from 'immutable';
import { debounce } from 'lodash';
import type { AppState } from 'system/store';
import { useAppDispatch } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';

import LocalStorageHelper from './storage/LocalStorageHelper';
import { addOrRemoveInSet } from './immutil';

/**
 * This hook acts like the redux bindActionCreators method but for a single action.
 * It saves the need to use the useDispatch hook in the component.
 */
export const useAction = <Payload = void>(
    action: (
        payload: Payload
    ) => Action | PayloadAction<Payload> | ThunkAction<any, AppState, any, PayloadAction<Payload>>
) => {
    const dispatch = useAppDispatch();
    return (payload: Payload) => dispatch(action(payload));
};

/**
 * This hook is the same as useAction but it returns the payload after.
 * This is useful when composing functions or using promises.
 */
export const useActionAndReturn = <Payload = void>(
    action: (payload: Payload) => Action | PayloadAction<Payload>
) => {
    const dispatch = useAppDispatch();
    return (payload: Payload) => {
        dispatch(action(payload));
        return payload;
    };
};

/**
 * This hook stores a list of string identifiers for items that are selected.
 * Methods are provided to toggle selected state for identifiers, check if an
 * identifier is selected, set all values and check if there are any values.
 */
export const useItemSelectedState = (
    initialValues: List<string> = List()
): [
    (id: string) => void,
    (id: string) => boolean,
    SingleParamVoidFunction<List<string>>,
    () => boolean,
] => {
    const [state, setState] = useState<List<string>>(initialValues);

    const toggleSelected = (id: string) =>
        setState(state.contains(id) ? state.filter((i) => i !== id) : state.push(id));

    const isSelected = (id: string) => state.contains(id);

    const anySelected = () => state.size > 0;

    return [toggleSelected, isSelected, setState, anySelected];
};

/**
 * This hook is similar to useItemSelectedState but it persists the state to local storage
 * for the current farm.
 * Methods are provided to toggle selected state for identifiers, check if an
 * identifier is selected.
 */
export const usePersistedItemSelectedState = (
    key: string
): [SingleParamVoidFunction<string>, (id: string) => boolean] => {
    const farm = useSelector(selectCurrentFarm);

    const [items, setItems] = useState(
        LocalStorageHelper.getItemAsJson<string[]>(`farm-${farm.uuid}-${key}`) || []
    );

    const toggleItem = (usageUuid: string) => {
        const nextItems = items.includes(usageUuid)
            ? items.filter((uuid) => uuid !== usageUuid)
            : [...items, usageUuid];

        setItems(nextItems);
        LocalStorageHelper.setItemAsJson(`farm-${farm.uuid}-${key}`, nextItems);
    };

    const has = (id: string) => items.includes(id);

    return [toggleItem, has];
};

export const useToggleSet = <T = string>(initial = Set<T>()): [Set<T>, (s: T) => void] => {
    const [items, setItems] = useState(initial);

    const toggle = (id: T) => {
        setItems(addOrRemoveInSet(items, id));
    };

    return [items, toggle];
};

/**
 * Hook to set a drawing tool on mount and unset on unmount.
 */
export const useDrawingToolOnMount = (drawingTool: DrawingTool | null) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(selectDrawingTool(drawingTool));
        return () => {
            dispatch(selectDrawingTool(null));
        };
    }, []);
};

/**
 * This hook will set the map view to the extent of the given object on mount and when
 * that feature changes.
 */
export const useZoomToFeatureOnMount = (feature?: GeoObject) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (feature) {
            dispatch(
                setMapView({
                    type: 'extent',
                    payload: extentFromGeo(feature),
                })
            );
        }
    }, [feature]);
};

/**
 * This hook will return a function that accepts a GeoObject and zooms to that object when called.
 */
export const useZoomToFeature = () => {
    const dispatch = useAppDispatch();
    return (feature: GeoObject) => {
        dispatch(
            setMapView({
                type: 'extent',
                payload: extentFromGeo(feature),
            })
        );
    };
};

/**
 * This hook wraps usePromise whilst handling out-of-date errors from the server
 */
export const useOutOfDatePromise = <ResolvedType>(
    callback?: SingleParamVoidFunction<ResolvedType>
): [boolean, boolean, boolean, SingleParamVoidFunction<Promise<ResolvedType>>] => {
    const { pending, error, setPromise } = usePromise<ResolvedType | 'out-of-date'>((value) => {
        value !== 'out-of-date' && callback !== undefined && callback(value);
    });
    const [outOfDate, setOutOfDate] = useState(false);

    const setOutOfDatePromise = (promise: Promise<ResolvedType | 'out-of-date'>) => {
        setPromise(
            promise.catch((e) => {
                if (e.response?.data?.error === 'out-of-date') {
                    setOutOfDate(true);
                    return 'out-of-date';
                }
                throw new Error(e);
            })
        );
    };

    return [pending, outOfDate, error, setOutOfDatePromise];
};

/**
 * Takes a given function and returns a debounced version of it.
 * Uses refs because then the returned value is available in effect hooks.
 */
export const useDebouncedFunc = (fn: any, delay: number) => {
    const requestRef = useRef(debounce(fn, delay));
    return requestRef.current;
};

/**
 * This hook handles debounced search state for any items.
 * It can be used where the search term comes from the component itself, or where it comes from
 * the parent.
 *
 * Returns the following as an array:
 *  - current search term - this can be ignored if the search term comes from the parent
 *  - whether a search is pending
 *  - whether a search errored
 *  - A function to perform a search - can be ignored if the search term comes from the parent
 *  - A function to forecfully set the search term without actually performing a search.
 */
export const useDebouncedSearch = <ReturnValue>(
    apiCall: (search: string) => Promise<ReturnValue>,
    initialSearch = '',
    delay = 300
): [
    string,
    ReturnValue | undefined,
    boolean,
    boolean,
    SingleParamVoidFunction<string>,
    SingleParamVoidFunction<string>,
] => {
    const [search, setSearch] = useState(initialSearch);
    const [returnValue, setReturnValue] = useState<ReturnValue>();
    const { pending, error, setPromise } = usePromise<ReturnValue>(setReturnValue);

    const debouncedSearch = useDebouncedFunc((value: string) => {
        setReturnValue(undefined);
        value && setPromise(apiCall(value));
    }, delay);

    useEffect(() => {
        if (initialSearch !== '') {
            debouncedSearch(initialSearch);
        }
    }, [initialSearch]);

    const handleSearch = (value: string) => {
        setSearch(value);
        if (value === '') {
            debouncedSearch.cancel();
            setReturnValue(undefined);
        } else {
            debouncedSearch(value);
        }
    };

    return [search, returnValue, pending, error, handleSearch, setSearch];
};

/**
 * This hook handles the correct adding and removing of document event listeners.
 */
export const useDocumentEventListener = (
    eventType: string,
    fn: EventListener,
    vars: DependencyList = []
) => {
    useEffect(() => {
        document.addEventListener(eventType, fn, { capture: true });

        return () => {
            document.removeEventListener(eventType, fn, { capture: true });
        };
    }, vars);
};

export const useOutsideComponentClickListener = (
    idToMatch: string,
    onClick: VoidFunction,
    vars: DependencyList = []
) =>
    useDocumentEventListener(
        'click',
        (e: MouseEvent) => {
            if (
                e.target !== null &&
                e.composedPath().filter((el: HTMLElement) => el.dataset?.elementid === idToMatch)
                    .length === 0
            ) {
                onClick();
            }
        },
        [idToMatch, ...vars]
    );

export const useInsideComponentKeyUpListener = (
    idToMatch: string,
    onKeyUp: SingleParamVoidFunction<KeyboardEvent>,
    vars: DependencyList = []
) =>
    useDocumentEventListener(
        'keyup',
        (e: KeyboardEvent) => {
            if (
                document.activeElement !== null &&
                e.composedPath().filter((el: HTMLElement) => el.dataset?.elementid === idToMatch)
                    .length === 1
            ) {
                onKeyUp(e);
            }
        },
        vars
    );
