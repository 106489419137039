import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import { getCurrencySymbol } from '@fieldmargin/webapp-styling/components/pricing/pricing-utils';
import type { ChartData, ChartOptions } from 'chart.js';
import { LazyBarChart } from 'components/chart/LazyBarChart';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { renderMeasurementUnitShort } from 'lib/MeasurementUnit';

import { getGrossMarginFieldGraphData } from './gross-margin-utils';
import type { GrossMargin } from './GrossMargin';
import GrossMarginByFieldLegend from './GrossMarginByFieldLegend';

import styles from './GrossMarginFieldChart.module.css';

interface GrossMarginFieldChartProps {
    farm: Farm;
    measurementUnit: MeasurementUnit;
    datum: GrossMargin;
}

const formatDataLabel = (value: number) =>
    new Intl.NumberFormat(window.navigator.language, { maximumFractionDigits: 2 }).format(value);

const GrossMarginFieldChart = ({ farm, measurementUnit, datum }: GrossMarginFieldChartProps) => {
    const { i18n, t } = useTranslation();
    const data = {
        labels: ['', '', ''],
        datasets: getGrossMarginFieldGraphData(datum, t),
    } as unknown as ChartData<'bar'>;

    const options: ChartOptions<'bar'> = {
        locale: i18n.language,
        maintainAspectRatio: false,

        plugins: {
            legend: {
                display: false,
            },

            datalabels: {
                formatter: (value, context) => {
                    const formattedValue = formatDataLabel(
                        context.dataset.label === 'Gross Margin' ? value : Math.abs(value)
                    );
                    return `${context.dataset.label}: ${formattedValue}`;
                },

                display: (context) => {
                    const data = context.dataset.data[context.dataIndex];
                    const number = Math.abs(typeof data === 'number' ? data : 0);
                    return context.dataIndex === 1 && number !== 0;
                },

                align: (context) => {
                    if ('index' in context.dataset) {
                        return (context.dataset.index as number) % 2 === 0 ? 'left' : 'right';
                    }
                    return 'left';
                },

                offset: (context) => {
                    return context.dataset.type === 'bar' ? 90 : 0;
                },

                font: {
                    family: 'Montserrat',
                    weight: 500,
                },
            },
        },

        scales: {
            x: {
                stacked: true,
            },

            y: {
                position: 'left',
                stacked: true,

                ticks: {
                    padding: 10,
                },
                title: {
                    display: true,
                    text: `${getCurrencySymbol(
                        farm.costingsCurrency || 'GBP'
                    )}/${renderMeasurementUnitShort(measurementUnit)}`,
                },
            },
        },
    };

    return (
        <div className={styles.chartContainer}>
            <div className={styles.chart}>
                <LazyBarChart direction="vertical" data={data} options={options} />
            </div>

            <GrossMarginByFieldLegend datum={datum} />
        </div>
    );
};

export default GrossMarginFieldChart;
