import { isAfter, isSameDay, subHours } from 'date-fns';
import { createNowTzDate, createTzDate } from 'lib/util/date';
import { defaultTo, omit } from 'ramda';

type WeatherIconType =
    | 'clear-day'
    | 'clear-night'
    | 'cloudy'
    | 'fog'
    | 'partly-cloudy-day'
    | 'partly-cloudy-night'
    | 'rain'
    | 'sleet'
    | 'snow'
    | 'wind';

const weatherIconMap = {
    Clear: 'clear-day',
    Cloudy: 'cloudy',
    Dust: 'cloudy',
    Fog: 'fog',
    Haze: 'fog',
    MostlyClear: 'clear-day',
    MostlyCloudy: 'cloudy',
    PartlyCloudy: 'partly-cloudy-day',
    ScatteredThunderstorms: 'rain',
    Smoke: 'fog',
    Breezy: 'wind',
    Windy: 'wind',
    Drizzle: 'rain',
    HeavyRain: 'rain',
    Rain: 'rain',
    Showers: 'rain',
    Flurries: 'sleet',
    HeavySnow: 'snow',
    MixedRainAndSleet: 'sleet',
    MixedRainAndSnow: 'sleet',
    MixedRainfall: 'rain',
    MixedSnowAndSleet: 'sleet',
    ScatteredShowers: 'rain',
    ScatteredSnowShowers: 'snow',
    Sleet: 'sleet',
    Snow: 'snow',
    SnowShowers: 'snow',
    Blizzard: 'snow',
    BlowingSnow: 'snow',
    FreezingDrizzle: 'sleet',
    FreezingRain: 'sleet',
    Frigid: 'cloudy',
    Hail: 'snow',
    Hot: 'clear-day',
    Hurricane: 'rain',
    IsolatedThunderstorms: 'rain',
    SevereThunderstorm: 'rain',
    Thunderstorm: 'rain',
    Tornado: 'rain',
    TropicalStorm: 'rain',
};

const getWeatherIcon = (conditionCode: string): WeatherIconType =>
    defaultTo('cloudy', weatherIconMap[conditionCode]);

export interface WeatherDTO {
    currentWeather?: CurrentWeatherDTO;
    forecastDaily: {
        days: DayForecastDTO[];
    };
    forecastHourly: {
        hours: HourForecastDTO[];
    };
}

export interface Weather {
    currentWeather?: CurrentWeather;
    dayForecasts: DayForecast[];
    hourForecasts: HourForecast[];
}
export const deserializeWeather = (json: WeatherDTO): Weather => ({
    currentWeather:
        json.currentWeather !== undefined
            ? deserializeCurrentWeather(json.currentWeather)
            : undefined,
    dayForecasts: json.forecastDaily.days.map(deserializeDayForecast),
    hourForecasts: json.forecastHourly.hours.map(deserializeHourForecast),
});

interface CurrentWeatherDTO {
    asOf: string;
    cloudCover: number;
    conditionCode: string;
    humidity: number;
    precipitationIntensity: number;
    pressure: number;
    temperature: number;
    temperatureApparent: number;
    temperatureDewPoint: number;
    uvIndex: number;
    visibility: number;
    windDirection: number;
    windGust: number;
    windSpeed: number;
}

export interface CurrentWeather extends Omit<CurrentWeatherDTO, 'asOf' | 'conditionCode'> {
    asOf: Date;
    icon: WeatherIconType;
}
const deserializeCurrentWeather = (json: CurrentWeatherDTO): CurrentWeather => ({
    ...omit(['conditionCode'], json),
    asOf: createTzDate(new Date(json.asOf)),
    icon: getWeatherIcon(json.conditionCode),
});

interface DayForecastDTO {
    conditionCode: string;
    forecastStart: string;
    maxUvIndex: number;
    moonPhase: string;
    precipitationAmount: number;
    precipitationChance: number;
    precipitationType: string;
    summary: string;
    sunrise: string;
    sunset: string;
    temperatureMax: number;
    temperatureMin: number;
    daytimeForecast: {
        cloudCover: number;
        humidity: number;
        windDirection: number;
        windSpeed: number;
    };
}

export interface DayForecast
    extends Omit<
        DayForecastDTO,
        'forecastStart' | 'sunrise' | 'sunset' | 'daytimeForecast' | 'conditionCode'
    > {
    forecastStart: Date;
    sunrise: Date;
    sunset: Date;
    cloudCover: number;
    humidity: number;
    windDirection: number;
    windSpeed: number;
    icon: WeatherIconType;
}

const deserializeDayForecast = (json: DayForecastDTO): DayForecast => ({
    ...omit(['forecastStart', 'sunrise', 'sunset', 'daytimeForecast', 'conditionCode'], json),
    ...json.daytimeForecast,
    forecastStart: createTzDate(new Date(json.forecastStart)),
    sunrise: createTzDate(new Date(json.sunrise)),
    sunset: createTzDate(new Date(json.sunset)),
    icon: getWeatherIcon(json.conditionCode),
});

interface HourForecastDTO {
    cloudCover: number;
    conditionCode: string;
    forecastStart: string;
    humidity: number;
    precipitationAmount: number;
    precipitationChance: number;
    precipitationIntensity: number;
    precipitationType: string;
    pressure: number;
    temperature: number;
    temperatureApparent: number;
    temperatureDewPoint: number;
    uvIndex: number;
    visibility: number;
    windDirection: number;
    windGust: number;
    windSpeed: number;
}

export interface HourForecast extends Omit<HourForecastDTO, 'forecastStart' | 'conditionCode'> {
    forecastStart: Date;
    icon: WeatherIconType;
}
const deserializeHourForecast = (json: HourForecastDTO): HourForecast => ({
    ...omit(['conditionCode'], json),
    forecastStart: createTzDate(new Date(json.forecastStart)),
    icon: getWeatherIcon(json.conditionCode),
});

export const getHourForecastsForDay = (dayForecast: DayForecast, hourForecasts: HourForecast[]) =>
    // Hour forecasts should be selected if they are on the same day as the day forecase and
    // that hour is not in the past.
    hourForecasts.filter(
        (hourForecast) =>
            isSameDay(dayForecast.forecastStart, hourForecast.forecastStart) &&
            // Subtract 1 hour from the time now because otherwise isAfter returns false
            isAfter(hourForecast.forecastStart, subHours(createNowTzDate(), 1))
    );
