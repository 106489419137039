import type { List } from 'immutable';
import type FullOperation from 'operations/FullOperation';

import { useCurrentYearSelector } from './selectors';

const useCurrentYearFieldInputReportOperations = (operations: List<FullOperation>) => {
    const { currentYear } = useCurrentYearSelector();

    const filteredOperations = (inputUuid: string) =>
        operations.filter(
            (operation) =>
                operation.summary.year === currentYear &&
                operation.recordings?.map((recording) => recording.inputUuid).includes(inputUuid)
        );

    return {
        currentYearFieldInputReport: filteredOperations,
    };
};

export { useCurrentYearFieldInputReportOperations };
