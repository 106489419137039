interface CircleIconProps {
    className?: string;
    fill?: string;
}

const CircleIcon = ({ className, fill = '#4da4da' }: CircleIconProps) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <g>
                <path
                    style={{ fill: 'none' }}
                    d="M30.1,50.8c-1,0-1.9,0.6-2.4,1.4c0.8,0.1,1.6,0.1,2.4,0.1s1.6,0,2.4-0.1C32,51.3,31.2,50.8,30.1,50.8z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M30.1,9.3c1.1,0,1.9-0.6,2.4-1.4c-0.8-0.1-1.6-0.1-2.4-0.1s-1.6,0-2.4,0.1C28.2,8.7,29,9.3,30.1,9.3z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M49.6,22.3c0.5,0.5,1.1,0.7,1.8,0.8c-0.5-1.5-1.2-3-2-4.4C48.5,19.8,48.6,21.3,49.6,22.3z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M11.5,39.8c-0.6-0.6-1.3-0.8-2-0.8c0.7,1.5,1.5,2.9,2.4,4.2C12.5,42,12.5,40.6,11.5,39.8z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M27.6,54.6c0.4,1,1.4,1.7,2.5,1.7c1.2,0,2.1-0.7,2.5-1.7c-0.8,0.1-1.7,0.1-2.5,0.1
		C29.2,54.8,28.4,54.8,27.6,54.6z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M10.8,18.8c-0.8,1.4-1.5,2.9-2,4.4c0.6,0,1.3-0.3,1.8-0.8C11.5,21.3,11.6,19.8,10.8,18.8z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M46.6,37.8c1.4-1.4,3.2-1.9,5-1.5c0.6-2,0.9-4.1,0.9-6.2c0-1.4-0.1-2.8-0.4-4.2c-1.6,0.2-3.2-0.3-4.5-1.6
		c-2.2-2.2-2.2-5.7,0-7.8c0,0,0.1,0,0.1-0.1c-3.1-3.9-7.5-6.8-12.5-8C34.4,10.5,32.4,12,30,12s-4.5-1.5-5.2-3.7c-5,1.2-9.4,4-12.5,8
		c0,0,0.1,0,0.1,0.1c2.2,2.2,2.2,5.7,0,7.8c-1.2,1.2-2.9,1.7-4.5,1.6c-0.3,1.4-0.4,2.7-0.4,4.2c0,2.2,0.3,4.2,0.9,6.2
		c1.7-0.3,3.6,0.2,5,1.5c2.1,2.1,2.1,5.3,0.3,7.5c3,3.1,6.8,5.4,11.1,6.4c0.8-2.2,2.8-3.7,5.2-3.7s4.5,1.5,5.2,3.7
		c4.3-1,8.2-3.3,11.1-6.4C44.5,43,44.6,39.8,46.6,37.8z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M32.5,5.4c-0.4-1-1.4-1.7-2.5-1.7S28,4.4,27.6,5.4c0.8-0.1,1.7-0.1,2.5-0.1C31,5.3,31.8,5.3,32.5,5.4z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M52.5,43.6c1-1,1-2.5,0.3-3.6c-0.7,1.5-1.5,3-2.5,4.3C51.1,44.5,52,44.2,52.5,43.6z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M7.6,43.6c0.6,0.6,1.4,0.8,2.2,0.8c-1-1.3-1.8-2.8-2.5-4.3C6.6,41.1,6.6,42.6,7.6,43.6z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M48.6,39.8c-0.9,0.9-1,2.3-0.4,3.4c1-1.3,1.8-2.7,2.4-4.2C50,39,49.2,39.1,48.6,39.8z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M53.5,18.4c-0.5-0.5-1.2-0.8-1.9-0.8c0.8,1.4,1.5,3,2.1,4.5C54.5,21,54.6,19.4,53.5,18.4z"
                />
                <path
                    style={{ fill: 'none' }}
                    d="M6.6,18.4c-1,1-1,2.7-0.1,3.7C7,20.5,7.7,19,8.6,17.6C7.9,17.6,7.2,17.8,6.6,18.4z"
                />
                <path
                    style={{ fill }}
                    d="M52.5,30c0,2.2-0.3,4.2-0.9,6.2c0.8,0.2,1.6,0.5,2.3,1C54.6,35,55,32.5,55,30c0-1.7-0.2-3.4-0.5-5
		c-0.7,0.5-1.5,0.7-2.4,0.8C52.3,27.2,52.5,28.6,52.5,30z"
                />
                <path
                    style={{ fill }}
                    d="M24.9,8.4c-0.2-0.6-0.3-1.2-0.3-1.8c0-0.2,0-0.4,0-0.6c-5.8,1.3-10.9,4.6-14.4,9.2c0.8,0.2,1.5,0.7,2.2,1.3
		C15.5,12.4,19.9,9.6,24.9,8.4z"
                />
                <path
                    style={{ fill }}
                    d="M7.6,30c0-1.4,0.1-2.8,0.4-4.2c-0.8-0.1-1.6-0.3-2.4-0.8c-0.3,1.6-0.5,3.3-0.5,5c0,2.5,0.4,4.9,1.1,7.2
		c0.7-0.5,1.5-0.9,2.3-1C7.9,34.2,7.6,32.2,7.6,30z"
                />
                <path
                    style={{ fill }}
                    d="M24.9,51.6c-4.3-1-8.2-3.3-11.1-6.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.5-1.1,0.9-1.7,1.2
		c3.4,3.6,7.8,6.2,12.8,7.4c0-0.2,0-0.4,0-0.6C24.6,52.8,24.7,52.2,24.9,51.6z"
                />
                <path
                    style={{ fill }}
                    d="M35.2,8.4c5,1.2,9.4,4,12.5,8c0.6-0.6,1.4-1,2.2-1.3c-3.5-4.5-8.5-7.9-14.4-9.2c0,0.2,0,0.4,0,0.6
		C35.5,7.2,35.5,7.8,35.2,8.4z"
                />
                <path
                    style={{ fill }}
                    d="M46.5,45.2c-3,3.1-6.8,5.4-11.1,6.4c0.2,0.6,0.3,1.2,0.3,1.8c0,0.2,0,0.4,0,0.6c5-1.1,9.5-3.7,12.8-7.4
		c-0.6-0.3-1.2-0.6-1.7-1.2C46.5,45.5,46.5,45.3,46.5,45.2z"
                />
                <path
                    style={{ fill }}
                    d="M30.1,12.1c2.4,0,4.5-1.5,5.2-3.7c-0.9-0.2-1.9-0.4-2.8-0.5c-0.5,0.8-1.3,1.4-2.4,1.4s-1.9-0.6-2.4-1.4
		c-1,0.1-1.9,0.3-2.8,0.5C25.6,10.5,27.7,12.1,30.1,12.1z"
                />
                <path
                    style={{ fill }}
                    d="M30.1,3.8c1.2,0,2.1,0.7,2.5,1.7c1,0.1,2,0.3,3,0.5c-0.3-2.9-2.6-5-5.5-5s-5.2,2.1-5.5,4.9c1-0.2,2-0.4,3-0.5
		C28,4.5,29,3.8,30.1,3.8z"
                />
                <path
                    style={{ fill }}
                    d="M24.9,8.4c0.9-0.2,1.9-0.4,2.8-0.5c-0.2-0.4-0.4-0.9-0.4-1.4c0-0.4,0.1-0.8,0.3-1.1c-1,0.1-2,0.3-3,0.5
		c0,0.2,0,0.4,0,0.6C24.6,7.2,24.7,7.8,24.9,8.4z"
                />
                <path
                    style={{ fill }}
                    d="M32.8,6.5c0,0.5-0.1,1-0.4,1.4c1,0.1,1.9,0.3,2.8,0.5c0.2-0.6,0.3-1.2,0.3-1.8c0-0.2,0-0.4,0-0.6
		c-1-0.2-2-0.4-3-0.5C32.8,5.8,32.8,6.1,32.8,6.5z"
                />
                <path
                    style={{ fill }}
                    d="M30.1,56.2c-1.2,0-2.1-0.7-2.5-1.7c-1-0.1-2-0.3-3-0.5c0.3,2.8,2.6,4.9,5.5,4.9s5.2-2.1,5.5-4.9
		c-1,0.2-2,0.4-3,0.5C32.1,55.5,31.3,56.2,30.1,56.2z"
                />
                <path
                    style={{ fill }}
                    d="M30.1,48c-2.4,0-4.5,1.5-5.2,3.7c0.9,0.2,1.9,0.4,2.8,0.5c0.5-0.8,1.4-1.4,2.4-1.4c1.1,0,1.9,0.6,2.4,1.4
		c1-0.1,1.9-0.3,2.8-0.5C34.5,49.5,32.5,48,30.1,48z"
                />
                <path
                    style={{ fill }}
                    d="M27.3,53.5c0-0.5,0.2-1,0.4-1.4c-1-0.1-1.9-0.3-2.8-0.5c-0.2,0.6-0.3,1.2-0.3,1.8c0,0.2,0,0.4,0,0.6
		c1,0.2,2,0.4,3,0.5C27.4,54.2,27.3,54,27.3,53.5z"
                />
                <path
                    style={{ fill }}
                    d="M35.2,51.6c-0.9,0.2-1.9,0.4-2.8,0.5c0.2,0.4,0.4,0.9,0.4,1.4c0,0.4-0.1,0.8-0.2,1.1c1-0.1,2-0.3,3-0.5
		c0-0.2,0-0.4,0-0.6C35.5,52.8,35.5,52.2,35.2,51.6z"
                />
                <path
                    style={{ fill }}
                    d="M12.5,24.2c2.2-2.2,2.2-5.7,0-7.8c0,0-0.1,0-0.1-0.1c-0.6,0.7-1.1,1.5-1.6,2.3c0.8,1.1,0.8,2.6-0.2,3.6
		C10.1,22.8,9.4,23,8.8,23c-0.3,0.9-0.5,1.8-0.7,2.7C9.6,26,11.3,25.5,12.5,24.2z"
                />
                <path
                    style={{ fill }}
                    d="M6.6,18.4c0.5-0.5,1.2-0.8,1.9-0.8c0.5-0.9,1.1-1.7,1.7-2.5c-1.9-0.6-4.1-0.2-5.6,1.3c-2.2,2.2-2.2,5.7,0,7.8
		c0.3,0.3,0.6,0.6,1,0.8c0.2-1,0.5-2,0.8-2.9C5.6,21,5.6,19.4,6.6,18.4z"
                />
                <path
                    style={{ fill }}
                    d="M10.5,18.4c0.1,0.1,0.2,0.2,0.2,0.3c0.5-0.8,1-1.6,1.6-2.3c-0.6-0.6-1.4-1-2.2-1.3c-0.6,0.8-1.2,1.6-1.7,2.5
		C9.3,17.6,10,17.8,10.5,18.4z"
                />
                <path
                    style={{ fill }}
                    d="M8.7,23.1c-0.8,0-1.6-0.2-2.1-0.8c-0.1-0.1-0.1-0.1-0.1-0.2c-0.4,1-0.6,1.9-0.8,2.9c0.7,0.5,1.5,0.7,2.4,0.8
		C8.2,24.9,8.4,24,8.7,23.1z"
                />
                <path
                    style={{ fill }}
                    d="M54,37.2c-0.3,1-0.7,1.9-1.1,2.8c0.8,1.1,0.7,2.6-0.3,3.6c-0.6,0.6-1.5,0.9-2.2,0.8c-0.6,0.8-1.2,1.6-1.9,2.3
		c2,0.9,4.5,0.5,6.1-1.2c2.2-2.2,2.2-5.7,0-7.8C54.3,37.5,54.1,37.5,54,37.2z"
                />
                <path
                    style={{ fill }}
                    d="M48.6,39.8c0.6-0.6,1.3-0.8,2-0.8c0.4-0.9,0.7-1.8,1-2.7c-1.7-0.3-3.6,0.2-5,1.5c-2.1,2.1-2.1,5.3-0.3,7.5
		c0.7-0.7,1.3-1.4,1.8-2.2C47.6,42,47.8,40.6,48.6,39.8z"
                />
                <path
                    style={{ fill }}
                    d="M50.6,39c0.7,0,1.4,0.3,1.9,0.8c0.1,0.1,0.2,0.2,0.3,0.4c0.4-0.9,0.8-1.8,1.1-2.8c-0.7-0.5-1.5-0.9-2.3-1
		C51.3,37.1,51,38,50.6,39z"
                />
                <path
                    style={{ fill }}
                    d="M48.6,43.6c-0.2-0.2-0.3-0.4-0.4-0.5c-0.6,0.8-1.2,1.5-1.8,2.2c0.1,0.1,0.2,0.2,0.3,0.3
		c0.5,0.5,1.1,0.9,1.7,1.2c0.7-0.7,1.3-1.5,1.9-2.3C49.6,44.3,49,44.1,48.6,43.6z"
                />
                <path
                    style={{ fill }}
                    d="M13.5,37.8c-1.4-1.4-3.2-1.9-5-1.5c0.3,0.9,0.6,1.8,1,2.7c0.7,0,1.5,0.2,2,0.8c0.9,0.9,1,2.3,0.4,3.4
		c0.6,0.8,1.2,1.5,1.8,2.2C15.6,43,15.5,39.8,13.5,37.8z"
                />
                <path
                    style={{ fill }}
                    d="M7.6,43.6c-1-1-1-2.5-0.3-3.6c-0.4-0.9-0.8-1.8-1.1-2.8c-0.2,0.2-0.4,0.3-0.6,0.5c-2.2,2.2-2.2,5.7,0,7.8
		c1.7,1.7,4.1,2,6.1,1.2c-0.7-0.7-1.3-1.5-1.9-2.4C9,44.5,8.2,44.2,7.6,43.6z"
                />
                <path
                    style={{ fill }}
                    d="M7.6,39.8C8.1,39.2,8.8,39,9.5,39c-0.4-0.9-0.7-1.8-1-2.7c-0.8,0.2-1.6,0.5-2.3,1c0.3,1,0.7,1.9,1.1,2.8
		C7.4,40,7.5,39.8,7.6,39.8z"
                />
                <path
                    style={{ fill }}
                    d="M13.7,45.2c-0.7-0.7-1.3-1.4-1.8-2.2c-0.1,0.2-0.2,0.4-0.4,0.5c-0.5,0.5-1.1,0.7-1.7,0.8
		c0.6,0.8,1.2,1.6,1.9,2.4c0.6-0.3,1.2-0.6,1.7-1.2C13.6,45.5,13.6,45.3,13.7,45.2z"
                />
                <path
                    style={{ fill }}
                    d="M53.5,18.4c1.1,1.1,1,2.7,0.1,3.7c0.3,1,0.6,1.9,0.8,2.9c0.4-0.2,0.7-0.5,1-0.8c2.2-2.2,2.2-5.7,0-7.8
		c-1.5-1.5-3.7-1.9-5.6-1.3c0.6,0.8,1.2,1.6,1.7,2.5C52.2,17.6,53,17.8,53.5,18.4z"
                />
                <path
                    style={{ fill }}
                    d="M47.6,24.2c1.2,1.2,2.9,1.7,4.5,1.6c-0.2-0.9-0.4-1.9-0.7-2.8c-0.7,0-1.3-0.3-1.8-0.8c-1-1-1-2.6-0.2-3.6
		c-0.5-0.8-1-1.6-1.6-2.3c0,0-0.1,0-0.1,0.1C45.5,18.6,45.5,22.1,47.6,24.2z"
                />
                <path
                    style={{ fill }}
                    d="M53.5,22.3c-0.6,0.6-1.4,0.8-2.1,0.8c0.3,0.9,0.5,1.8,0.7,2.8c0.8-0.1,1.6-0.3,2.4-0.8c-0.2-1-0.5-2-0.8-2.9
		C53.6,22.2,53.5,22.2,53.5,22.3z"
                />
                <path
                    style={{ fill }}
                    d="M49.3,18.8c0.1-0.1,0.2-0.2,0.2-0.3c0.5-0.5,1.3-0.8,2-0.8c-0.5-0.9-1.1-1.7-1.7-2.5c-0.8,0.2-1.5,0.7-2.2,1.3
		C48.3,17.1,48.8,17.9,49.3,18.8z"
                />
            </g>
        </svg>
    );
};

export default CircleIcon;
