interface WeatherNoteIconProps {
    note: string;
}

const WeatherNoteIcon = ({ note }: WeatherNoteIconProps) => {
    switch (note.toLowerCase()) {
        case 'sunny':
            return <>☀️</>;
        case 'rainy':
            return <>🌧</>;
        case 'cloudy':
            return <>⛅️</>;
        case 'snow':
            return <>🌨</>;
        case 'icy':
            return <>❄️</>;
        default:
            return null;
    }
};

export default WeatherNoteIcon;
