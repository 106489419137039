import { useTranslation } from 'react-i18next';

import mergeImg1 from './images/merge1.png';
import mergeImg2 from './images/merge2.png';
import mergeImg3 from './images/merge3.png';
import mergeImg4 from './images/merge4.png';
import SubFieldsHelpDiagram from './SubFieldsHelpDiagram';

const SubFieldsHelpMerge = () => {
    const { t } = useTranslation();
    return (
        <div style={{ width: 700 }}>
            <h2 className="text-center text-sm">{t('sub_fields_help_merge_title')}</h2>
            <div className="flex text-center">
                <SubFieldsHelpDiagram src={mergeImg1} alt="Image showing a selected sub-field">
                    {t('sub_fields_help_merge1')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram src={mergeImg2} alt="Image showing the merge tool selected">
                    {t('sub_fields_help_merge2')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram src={mergeImg3} alt="Image showing two sub-fields selected">
                    {t('sub_fields_help_merge3')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram
                    src={mergeImg4}
                    alt="Image showing that two sub-fields have been merged into one"
                >
                    {t('sub_fields_help_merge4')}
                </SubFieldsHelpDiagram>
            </div>
        </div>
    );
};

export default SubFieldsHelpMerge;
