import type { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import Button from '@fieldmargin/webapp-styling/components/button';
import OutsidePage from 'auth/OutsidePage';

import './AppError.scss';

const AppError = () => {
    const handleReload = (e: MouseEvent) => {
        e.preventDefault();
        window.location.reload();
    };
    return (
        <div className="app-error">
            <OutsidePage>
                <p>Sorry, there was an unexpected error in the app.</p>
                <p>
                    Our developers have been notified and will investigate the issue. You can
                    refresh the page to try again.
                </p>
                <p>Please do not hesitate to contact us if you need further help.</p>
                <div className="button-container">
                    <Button as={Link} to="/" wide onClick={handleReload}>
                        Take me home
                    </Button>
                </div>
            </OutsidePage>
        </div>
    );
};

export default AppError;
