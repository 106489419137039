import type { List } from 'immutable';
import { MeasurementUnit } from 'lib/MeasurementUnit';
import { isNaN } from 'lodash';
import type { OperationFieldData } from 'operations/OperationField';
import { converter, sqmToHectares } from 'utils/conversion';

/**
 * Calculates the yield rate per hectare from the work area and total yield.
 * Will convert the work area to hecates if user is using imperial measurements.
 * Yield rate per ha = total yield / work area
 */
export const calculateYieldRateHa = (
    workArea: number,
    totalYield: number,
    measurementUnit: MeasurementUnit
) => {
    if (workArea === 0) {
        return 0;
    }

    if (measurementUnit === MeasurementUnit.METRIC) {
        return totalYield / workArea;
    }

    return totalYield / converter.convertAcresToHectares(workArea);
};

export const calculateYieldRate = (workArea: number, yieldTotal: number) =>
    workArea > 0 && !isNaN(yieldTotal) && !isNaN(workArea) ? yieldTotal / workArea : 0;

export const calculateYieldTotal = (workArea: number, yieldRate: number) => workArea * yieldRate;

/**
 * Calculates the total yield rate for a single operation.
 * Total yield rate = sum of total yields / sum of work areas.
 */
export const calculateOperationTotalYieldRate = (
    operationFields: List<OperationFieldData>,
    measurementUnit: MeasurementUnit
) => {
    const totalYields = calculateOperationTotalYields(operationFields);
    const totalArea = operationFields.reduce(
        (total, operationField) =>
            total +
            (measurementUnit === MeasurementUnit.METRIC
                ? sqmToHectares(operationField.areaSqm)
                : converter.convertSquareMetresToAcres(operationField.areaSqm)),
        0
    );

    return totalArea > 0 ? totalYields / totalArea : 0;
};

/**
 * Calculates the total yield for a single operation
 * Total yield = sum of all total yields
 */
export const calculateOperationTotalYields = (operationFields: List<OperationFieldData>) => {
    return operationFields.reduce(
        (total, operationField) => total + (operationField.yieldTotal || 0),
        0
    );
};
