import { useTranslation } from 'react-i18next';

interface ShortUnitProps {
    unit: string;
}

const ShortUnit = ({ unit }: ShortUnitProps) => {
    const { t } = useTranslation();
    return <>{t(`unit_type_${unit.toLowerCase()}_abbr`, { defaultValue: '' })}</>;
};

export default ShortUnit;
