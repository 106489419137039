import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import { useEffect, useState } from 'react';
import { initAuth, sessionIsAuthenticated } from '@fieldmargin/webapp-auth';
import { logging } from '@fieldmargin/webapp-reporting';
import { afterAuth } from 'auth/auth';
import { envConfig } from 'config/env-config';
import { featureFlagsConfig } from 'feature-flags';
import configService from 'lib/config';
import featureFlags from 'lib/feature-flags';
import { fetchFirebaseTokenAndInit } from 'lib/firebase/firebase-connection';

interface AppInitializationState {
    isInitialized: boolean;
    signedIn: boolean;
}

const useInitializeApp = () => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [signedIn, setSignedIn] = useState(false);

    useEffect(() => {
        // Set feature flags
        featureFlags.set(featureFlagsConfig.features, featureFlagsConfig.farmFeatures);
        // Initialize authentication
        initAuth({
            authApiRoot: configService.get('authApiRoot'),
            apiRoot: configService.get('apiRoot'),
            redirectRoot: window.location.origin,
            afterAuth,
        });

        // Set logging level
        logging.setRemoteLogLevel(logging.LOG_LEVEL.WARN);
        if (envConfig.isDevelopment) {
            logging.setConsoleLogLevel(logging.LOG_LEVEL.DEBUG);
            window.console.debug('Initialized', configService.getAll());
        }

        // Check if the user is authenticated
        sessionIsAuthenticated()
            .then((signedIn) => {
                return signedIn ? fetchFirebaseTokenAndInit().then(() => signedIn) : signedIn;
            })
            .then((signedIn) => {
                setSignedIn(signedIn);
                setIsInitialized(true);
            });
    }, []);

    return { isInitialized, signedIn };
};

const AppInitializationContext = createContext({} as AppInitializationState);

function AppInitializationProvider({ children }: PropsWithChildren) {
    const { isInitialized, signedIn } = useInitializeApp();

    return (
        <AppInitializationContext.Provider value={{ isInitialized, signedIn }}>
            {children}
        </AppInitializationContext.Provider>
    );
}

const useAppInitialization = () => {
    const context = useContext(AppInitializationContext);

    return context;
};

export { AppInitializationProvider, useAppInitialization };
