import c from 'classnames';

interface VerifiedShieldProps {
    className?: string;
    fill?: string;
}

const VerifiedShield = ({ className, fill = '#57AC01' }: VerifiedShieldProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={c('w-6 h-6 block', className)}
            aria-labelledby="shieldSvgTitle"
        >
            <title id="shieldSvgTitle">Green shield with white tick</title>
            <path
                style={{ fill }}
                d="M55.2,9.1c-0.1-0.7-0.6-1.4-1.4-1.6L30.5,1.1c-0.3-0.1-0.7-0.1-1,0L6.2,7.6
	C5.5,7.8,4.9,8.4,4.8,9.1c-0.1,1-3.2,23.9,4.7,35.4c4.8,7,11.9,12.1,20,14.5c0.3,0.1,0.6,0.1,0.9,0c8.1-2.4,15.2-7.5,20-14.5
	C58.4,33.1,55.3,10.1,55.2,9.1z M45,22.5L29.1,38.4c-0.7,0.7-1.9,0.7-2.7,0c0,0,0,0,0,0l-9.8-9.8c-0.7-0.7-0.7-1.9,0-2.7
	c0,0,0,0,0,0l2-2c0.7-0.7,1.9-0.7,2.7,0l6.5,6.5l12.6-12.6c0.7-0.7,1.9-0.7,2.7,0c0,0,0,0,0,0l2,2C45.8,20.6,45.8,21.8,45,22.5
	L45,22.5z"
            />
        </svg>
    );
};

export default VerifiedShield;
