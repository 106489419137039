import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToggle } from '@fieldmargin/webapp-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { useFileDownload } from 'hooks/useFileDownload';
import DropdownButton from 'view/dropdown-button/DropdownButton';
import { DropdownButtonItem } from 'view/dropdown-button/DropdownButtonItem';
import FeatureLockedModal from 'view/modal/FeatureLockedModal';

import downloadIcon from './download.svg';

interface ExportReportButtonProps {
    // From parent
    filename: string;
    downloadLink: string;
    className?: string;
}

const ExportReportButton = ({ filename, downloadLink, className }: ExportReportButtonProps) => {
    const { t } = useTranslation();
    const farm = useSelector(selectCurrentFarm);
    const exportAllowed = farm.plan.reportingLevel === 'ALL_REPORTING';
    const [showUpgradeModal, toggleUpgradeModal] = useToggle(false);
    const { pending, fetchFile } = useFileDownload();

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (!exportAllowed) {
            !showUpgradeModal && toggleUpgradeModal();
        } else {
            fetchFile(e.currentTarget.href, `${filename}.${e.currentTarget.dataset.type!}`);
        }
    };

    return (
        <>
            {showUpgradeModal && (
                <FeatureLockedModal
                    onClose={toggleUpgradeModal}
                    header={t('reporting_data_export_upgrade_title')}
                />
            )}
            <DropdownButton
                text={
                    <>
                        <img src={downloadIcon} className="w-6 mr-3" alt="Download icon" />{' '}
                        {t(pending ? 'label_downloading' : 'label_download')}
                    </>
                }
                className={className}
                disabled={pending}
            >
                <DropdownButtonItem.Root>
                    <DropdownButtonItem.AnchorItem
                        href={`${downloadLink}&fileFormat=CSV`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClick}
                        data-type="csv"
                    >
                        CSV
                    </DropdownButtonItem.AnchorItem>
                </DropdownButtonItem.Root>
                <DropdownButtonItem.Root>
                    <DropdownButtonItem.AnchorItem
                        href={`${downloadLink}&fileFormat=XLS`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleClick}
                        data-type="xls"
                    >
                        XLS
                    </DropdownButtonItem.AnchorItem>
                </DropdownButtonItem.Root>
            </DropdownButton>
        </>
    );
};

export default ExportReportButton;
