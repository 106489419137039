import { useDateFormat } from 'hooks/useDateFormat';
import Temperature from 'view/units/Temperature';
import TemperatureUnit from 'view/units/TemperatureUnit';
import type { HourForecast } from 'weather/Weather';

import WeatherRain from './WeatherRain';
import WeatherWind from './WeatherWind';

interface WeatherHourViewProps {
    hourForecasts: HourForecast[];
}

const WeatherHourView = ({ hourForecasts }: WeatherHourViewProps) => {
    const { format } = useDateFormat();
    return (
        <div className="grid grid-cols-12 gap-y-8">
            {hourForecasts.map((hourForecast, i) => (
                <div key={i} className="text-center">
                    <h4 className="mb-0">{format(hourForecast.forecastStart, 'h a')}</h4>
                    <WeatherRain forecast={hourForecast} />
                    <WeatherWind forecast={hourForecast} />
                    <div>
                        <Temperature celsius={hourForecast.temperature} round />
                        <TemperatureUnit />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default WeatherHourView;
