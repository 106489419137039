import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import Button from '@fieldmargin/webapp-styling/components/button';
import EmptyState from 'sidebar/modules/EmptyState';
import ExternalLink from 'view/ExternalLink';

import noVehicles from './vehicles-empty.png';

interface VehiclesEmptyStateProps {
    onManageClick: VoidFunction;
}

const VehiclesEmptyState = ({ onManageClick }: VehiclesEmptyStateProps) => {
    const { t } = useTranslation();
    return (
        <EmptyState header={t('vehicles_empty_title')} image={noVehicles}>
            <p>{t('vehicles_empty_desc')}</p>
            <Markdown components={{ a: ExternalLink }}>{t('vehicles_empty_contact')}</Markdown>
            <p>
                <Button onClick={onManageClick}>{t('vehicles_manage')}</Button>
            </p>
        </EmptyState>
    );
};

export default VehiclesEmptyState;
