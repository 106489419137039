import { colourList } from 'lib/colours';

let currentIndex = 0;
export const resetSubFieldColourIndex = () => (currentIndex = 0);

export const getNextSubFieldColour = () => {
    if (currentIndex + 1 > colourList.length) {
        currentIndex = 0;
    }
    return colourList[currentIndex++].colour;
};
