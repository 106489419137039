import { useState } from 'react';
import Button from '@fieldmargin/webapp-styling/components/button';
import { mergeFields } from 'fields/sub-fields-manipulations';
import { selectSelectedSubFields } from 'fields/sub-fields-selectors';
import { finishMerge } from 'fields/sub-fields-state';
import type { TurfFns } from 'lib/turf/turf-utils';
import { useAppDispatch, useAppSelector } from 'system/store';
import ErrorMessage from 'view/ErrorMessage';

import type SubField from '../SubField';

interface SubFieldMergeProps {
    onDone: VoidFunction;
    turf: TurfFns;
}

const SubFieldMerge = ({ onDone, turf }: SubFieldMergeProps) => {
    const dispatch = useAppDispatch();
    const subFields = useAppSelector(selectSelectedSubFields);
    const [error, setError] = useState<string>();

    const handleMerge = () => {
        setError(undefined);
        if (subFields.size === 2) {
            try {
                const firstSubField = subFields.get(0) as SubField;
                const secondSubField = subFields.get(1) as SubField;
                const newField = mergeFields(firstSubField.field, secondSubField.field, turf);
                dispatch(
                    finishMerge({
                        toUpdate: firstSubField.set('field', newField),
                        toRemove: secondSubField.id,
                    })
                );
                onDone();
            } catch (e) {
                if (e.message === 'union-multi-polygon') {
                    setError('Cannot merge sub fields that are not next to each other');
                } else {
                    setError('Something went wrong when merging fields');
                }
            }
        }
    };

    return (
        <div className="text-center mt-3">
            <p>Select another sub-field to merge</p>
            <Button small variant="danger-outline" onClick={onDone}>
                Cancel
            </Button>
            <Button small className="ml-3" onClick={handleMerge} disabled={subFields.size < 2}>
                Apply
            </Button>
            {error && <ErrorMessage className="mt-3">{error}</ErrorMessage>}
        </div>
    );
};

export default SubFieldMerge;
