import { useFormContext } from 'react-hook-form';
import type { InputReportSummary } from 'inputs/InputReportSummary';
import NumberInputField from 'view/form/hook/NumberInputField';

import { calculateTotalFromUnitCost } from '../input-summary-utils';

import type { EditInputCostsFormValues } from './EditInputCostsForm';

interface EditInputCostUnitFieldProps {
    inputReportSummary: InputReportSummary;
}

const EditInputCostUnitField = ({ inputReportSummary }: EditInputCostUnitFieldProps) => {
    const field = `${inputReportSummary.uuid}.unit`;
    const formApi = useFormContext<EditInputCostsFormValues>();

    const handleChange = (value: number) => {
        if (inputReportSummary.sumTotals !== 0 && !isNaN(value)) {
            formApi.setValue(
                `${inputReportSummary.uuid}.total`,
                calculateTotalFromUnitCost(inputReportSummary.sumTotals, value)
            );
        }
    };

    const initialValue =
        inputReportSummary.unitCost === undefined || inputReportSummary.unitCost === 0
            ? NaN
            : inputReportSummary.unitCost;
    return (
        <NumberInputField
            field={field}
            inputClassName="h-8 w-24"
            minValue={0}
            initialValue={initialValue}
            onChange={handleChange}
        />
    );
};

export default EditInputCostUnitField;
