import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { useAppSelector } from 'system/store';
import FormattedArea from 'view/units/FormattedArea';

import { selectTotalWorkedAreaActive } from './fields-selectors';

interface TotalFieldAreaProps {
    className?: string;
}

const TotalFieldArea = ({ className }: TotalFieldAreaProps) => {
    const { t } = useTranslation();
    const totalArea = useAppSelector(selectTotalWorkedAreaActive);
    return (
        <div className={c(className)}>
            <strong>
                {t('field_work_area_total')}: <FormattedArea areaSqm={totalArea} withUnit />
            </strong>
        </div>
    );
};

export default TotalFieldArea;
