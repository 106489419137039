import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import type Feature from 'features/Feature';
import ItemTab from 'sidebar/modules/tabs/ItemTab';
import ItemTabList from 'sidebar/modules/tabs/ItemTabList';
import ItemTabPanel from 'sidebar/modules/tabs/ItemTabPanel';

import type { FeaturesContextSchema } from './useFeaturesContext';

interface FeatureTabsProps {
    // From parent
    feature: Feature;
    pending: boolean;
}

const FeatureTabs = ({ feature, pending }: FeatureTabsProps) => {
    const { t } = useTranslation();
    return (
        <>
            <ItemTabList>
                <ItemTab to="">{t('field_details_todo')}</ItemTab>
                <ItemTab to="history">{t('label_feature_history')}</ItemTab>
            </ItemTabList>
            <ItemTabPanel>
                {pending && (
                    <p className="p-5">
                        <em className="text-gray-550">{t('loading')}</em>
                    </p>
                )}
                {!pending && (
                    <Outlet
                        context={
                            {
                                feature,
                            } satisfies FeaturesContextSchema
                        }
                    />
                )}
            </ItemTabPanel>
        </>
    );
};

export default FeatureTabs;
