import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import CreateFarmContainer from 'farms/create/CreateFarmContainer';
import { useOnboarding } from 'onboarding/onboarding-state';
import { trackEvent } from 'utils/trackEvent';

function CreateFarmFieldmargin() {
    const navigate = useNavigate();
    const { startOnboarding } = useOnboarding();

    const onSuccess = (farm: Farm) => {
        trackEvent('Farm created', { farmUuid: farm.uuid });
        startOnboarding();
        navigate(`/farms/${farm.uuid}/onboarding`);
    };

    return <CreateFarmContainer onSuccess={onSuccess} />;
}

export { CreateFarmFieldmargin };
