import { selectedOutputsWithOperationsSortedByName } from 'outputs/outputs-state';
import { useAppSelector } from 'system/store';

const useYeldReportingOutputs = () => {
    const yeldReportingOutputs = useAppSelector(selectedOutputsWithOperationsSortedByName);

    return {
        yeldReportingOutputs,
    };
};

export { useYeldReportingOutputs };
