import type Field from 'fields/Field';
import type Herd from 'herd/Herd';
import type { TFunction } from 'i18next';
import type { List } from 'immutable';
import { toLower } from 'ramda';

export const nameShouldUpdate = (nameValue: string | undefined, t: TFunction) => {
    if (nameValue === undefined || nameValue === '') {
        return true;
    }

    return (
        [
            t('livestock_type_cattle'),
            t('livestock_type_sheep'),
            t('livestock_type_pigs'),
            t('livestock_type_goats'),
            t('livestock_type_horses'),
            t('livestock_type_poultry'),
            t('other'),
        ]
            .map(toLower)
            .indexOf(nameValue.toLowerCase()) > -1
    );
};

export const selectHerdField = (fields: List<Field>, herd: Herd | undefined) =>
    herd?.herdLocation
        ? fields.find((field) => field.uuid === herd.herdLocation?.fieldUuid)
        : undefined;
