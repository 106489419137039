import { Record } from 'immutable';
import { deserializeMetricValue } from 'sensors/sensors-util';

import type { MetricDTO } from './Metric';

const Reading = Record({
    metricId: '',
    value: null as number | string | null,
});
interface Reading extends ReturnType<typeof Reading> {}

export interface ReadingDTO {
    metricId: string;
    value: string;
}

export const deserializeReading = (json: ReadingDTO, metric: MetricDTO) => {
    return Reading({
        metricId: json.metricId,
        value: deserializeMetricValue(metric.type, json.value),
    });
};

export const serializeReading = (reading: Reading): ReadingDTO => {
    return {
        metricId: reading.metricId,
        value: reading.value !== null ? reading.value.toString() : '',
    };
};

export default Reading;
