import { Link } from 'react-router-dom';
import { useToggle } from '@fieldmargin/webapp-state';
import icon from 'components/icons/icon';
import { useDateFormat } from 'hooks/useDateFormat';
import { listToMap } from 'lib/immutil';
import { andThen, pipe } from 'ramda';
import type ManualSensor from 'sensors/manual/ManualSensor';
import type ManualSensorReadingGroup from 'sensors/manual/ManualSensorReadingGroup';
import type Metric from 'sensors/Metric';
import EditButton from 'sidebar/modules/common/EditButton';
import SidebarModule from 'sidebar/modules/SidebarModule';

import { useUpdateReadingGroup } from '../manual-sensor-hooks';

import ManualSensorHistoryReading from './ManualSensorHistoryReading';
import ManualSensorReadingGroupForm from './ManualSensorReadingGroupForm';

interface ManualSensorReadingProps {
    manualSensor: ManualSensor;
    readingGroup: ManualSensorReadingGroup;
}

const ManualSensorHistoryItem = ({ manualSensor, readingGroup }: ManualSensorReadingProps) => {
    const { format } = useDateFormat();
    const updateReadingGroup = useUpdateReadingGroup(manualSensor, readingGroup);
    const [editing, toggleEditing] = useToggle(false);

    const handleUpdateReadingGroup = pipe(updateReadingGroup, andThen(toggleEditing));

    const metricMap = listToMap(manualSensor.metrics, (metric) => metric.id);
    const readings = readingGroup.readings
        .filter((reading) => metricMap.has(reading.metricId))
        .map((reading) => ({ reading, metric: metricMap.get(reading.metricId) as Metric }))
        .sortBy(({ metric }) => metric.name);

    return (
        <>
            {editing && (
                <ManualSensorReadingGroupForm
                    manualSensor={manualSensor}
                    onCancel={toggleEditing}
                    onSave={handleUpdateReadingGroup}
                    initialValues={{
                        date: readingGroup.timestamp,
                        metrics: listToMap(readingGroup.readings, (reading) => reading.metricId)
                            .map((reading) => reading.value?.toString() ?? '')
                            .toObject(),
                    }}
                />
            )}
            {!editing && (
                <SidebarModule>
                    <div className="mb-2 flex items-center gap-6">
                        <h4 className="mb-0 text-sm">
                            {format(readingGroup.timestamp, 'do MMM yyyy, HH:mm')}
                        </h4>
                        <EditButton className="float-none ml-auto" setEditing={toggleEditing} />
                        <Link to={`readings/${readingGroup.id}/delete`}>
                            {icon('edit-remove', 'red')}
                        </Link>
                    </div>
                    {readings.map(({ metric, reading }) => {
                        return (
                            <ManualSensorHistoryReading
                                key={metric.id}
                                metric={metric}
                                reading={reading}
                            />
                        );
                    })}
                </SidebarModule>
            )}
        </>
    );
};

export default ManualSensorHistoryItem;
