import { createAction } from '@fieldmargin/webapp-state';
import type { Discussion } from 'discussions/Discussion';
import { mapDelete, mapSet } from 'lib/fp-helpers';
import type Media from 'media/Media';
import type Note from 'notes/Note';

import type NotesState from './notes-state';

const addNoteToState = (state: NotesState, note: Note) =>
    state.set(
        'notes',
        state.notes !== null ? mapSet(state.notes, note.uuid, note) : new Map([[note.uuid, note]])
    );

export const addNewNote = createAction<
    NotesState,
    { note: Note; discussion?: Discussion; media?: Media[] }
>('addNewNote', (state, payload) => addNoteToState(state, payload.note));

export const setNote = createAction<NotesState, Note>('setNote', addNoteToState);

export const removeNote = createAction<NotesState, Note>('removeNote', (state, payload) =>
    state.update('notes', (notes) => (notes !== null ? mapDelete(notes, payload.uuid) : notes))
);

export const setNoteRead = createAction<NotesState, string>('setNoteRead', (state, payload) =>
    state.notes !== null && state.notes.get(payload) !== undefined
        ? state.set(
              'notes',
              mapSet(state.notes, payload, state.notes.get(payload)!.set('read', true))
          )
        : state
);

export const noteReducers = [addNewNote, setNote, removeNote, setNoteRead];
