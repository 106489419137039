import FarmLoader from 'components/farm/FarmLoader';
import { FarmLoaderModals } from 'components/farm/FarmLoaderModals';

function FieldmarginFarmLoader() {
    return (
        <FarmLoader>
            <FarmLoaderModals />
        </FarmLoader>
    );
}

export { FieldmarginFarmLoader };
