import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type Herd from 'herd/Herd';
import { deleteHerd } from 'herd/herd-api';
import { removeHerd } from 'herd/herd-state';
import withPageHerd from 'herd/withPageHerd';
import { isCreator } from 'lib/owner';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import { selectIsFarmOwner } from 'team/farm-users-state';
import { selectUserId } from 'users/user-state';

interface HerdDeleteProps {
    farm: Farm;
    year: number;
    herd?: Herd;
    canDelete: boolean;
    removeHerd: typeof removeHerd;
}

const HerdDelete = ({ farm, year, herd, canDelete, removeHerd }: HerdDeleteProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pending, error, setPromise } = usePromise(() => {
        herd && removeHerd(herd.uuid);
        navigate(`/farms/${farm.uuid}/livestock`);
    });

    if (herd === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/livestock`} />;
    }

    const handleDelete = () => {
        setPromise(deleteHerd(year, herd));
    };

    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader>
                    <SidebarHeaderLink to={`/farms/${farm.uuid}/livestock/${herd.uuid}`}>
                        {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                    </SidebarHeaderLink>
                </SidebarHeader>
                {error && (
                    <SidebarError
                        title={t('failed_to_delete')}
                        message={t('something_went_wrong')}
                    />
                )}
            </div>
            <div className="scrolling">
                <SidebarModule editing>
                    <h2 className="mb-0">{herd.name}</h2>
                </SidebarModule>
                {canDelete ? (
                    <SidebarModule editing>
                        <p className="text-lg">{t('message_delete_confirmation_herd')}</p>
                        <Button variant="danger" onClick={handleDelete} disabled={pending}>
                            {t(pending ? 'label_deleting' : 'label_delete')}
                        </Button>
                    </SidebarModule>
                ) : (
                    <SidebarModule editing>
                        <p className="text-lg text-error-text">{t('message_delete_unsupported')}</p>
                    </SidebarModule>
                )}
            </div>
        </div>
    );
};

export default withPageHerd(
    connect(
        (state: AppState, { herd }: Pick<HerdDeleteProps, 'herd'>) => ({
            farm: selectCurrentFarm(state),
            year: state.yearsState.currentYear,
            canDelete:
                selectIsFarmOwner(state) ||
                (herd !== undefined && isCreator(herd, selectUserId(state))),
        }),
        (dispatch) => bindActionCreators({ removeHerd }, dispatch)
    )(HerdDelete)
);
