import { useSelector } from 'react-redux';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { renderMeasurementUnitShort } from 'lib/MeasurementUnit';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';

const ShortAreaUnit = () => {
    const areaMeasurementUnit = useSelector<AppState, MeasurementUnit>(
        selectUserAreaMeasurementUnit
    );
    return <>{renderMeasurementUnitShort(areaMeasurementUnit)}</>;
};

export default ShortAreaUnit;
