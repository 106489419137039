import {
    removeEditingGeoFeaturesById,
    setEditingData,
    stopSelectingMultipleAttachments,
} from 'farm-editing/farm-editing-state';
import { useAppDispatch } from 'system/store';

import { useEditingFieldUuids } from './useEditingFieldUuids';

const useEditSelectedFields = () => {
    const dispatch = useAppDispatch();
    const { editingFieldUuids } = useEditingFieldUuids();

    const handlerEditSelectedFields = () => {
        // Remove unselected items from editingGeoFeatureCollection
        dispatch(removeEditingGeoFeaturesById(editingFieldUuids.toArray()));

        // This prevents clicks on the map from selecting or deselecting fields.
        dispatch(stopSelectingMultipleAttachments());

        dispatch(setEditingData({ id: 'edit-boundaries' }));
    };

    return { handlerEditSelectedFields };
};

export { useEditSelectedFields };
