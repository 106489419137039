import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import { stopEditing } from 'farm-editing/farm-editing-state';
import type { List } from 'immutable';
import { Form } from 'informed';
import { useOutOfDatePromise } from 'lib/hooks';
import { useAppDispatch, useAppSelector } from 'system/store';

import EditableActions from '../common/EditableActions';

import EditableShapeInformation from './components/EditableShapeInformation';
import ShapeAndFieldsField from './ShapeAndFieldsField';

interface EditableShapesFormProps {
    onSave: (geoFeatureCollection: GeoFeatureCollection) => Promise<any>;
    tools?: List<DrawingTool>;
    maxShapes?: number;
    setEditing: (editing: boolean) => void;
    startEditing: VoidFunction;
}

const EditableShapesForm = ({
    onSave,
    tools,
    maxShapes,
    setEditing,
    startEditing,
}: EditableShapesFormProps) => {
    const { t } = useTranslation();
    const editingGeoFeatureCollection = useAppSelector(
        (state) => state.farmEditingState.editingGeoFeatureCollection
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        startEditing();

        return () => {
            dispatch(stopEditing());
        };
    }, []);

    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(() => setEditing(false));

    const handleSave = () => {
        if (editingGeoFeatureCollection) {
            setPromise(onSave(editingGeoFeatureCollection));
        } else {
            setEditing(false);
        }
    };

    // There won't be an editingGeoFeatureCollection until startEditing is called on mount so
    // wait a tick before rendering.
    return editingGeoFeatureCollection ? (
        <Form onSubmit={handleSave}>
            <EditableShapeInformation label={t('location')} tools={tools} maxShapes={maxShapes} />
            <ShapeAndFieldsField shapes={editingGeoFeatureCollection} />
            <EditableActions
                disabled={pending || editingGeoFeatureCollection.features.size === 0}
                error={error}
                outOfDate={outOfDate}
                setEditing={setEditing}
            />
        </Form>
    ) : null;
};

export default EditableShapesForm;
