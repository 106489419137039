import type { Farm } from '@fieldmargin/webapp-farms';
import { FarmPlanType, findByUserWithOwners } from '@fieldmargin/webapp-farms';
import { createAction, createAjaxAction, handleActions } from '@fieldmargin/webapp-state';
import Promise from 'bluebird';
import { loadedFarmSuccess } from 'farms/farm-loading-state';
import { List, Record } from 'immutable';
import featureFlags from 'lib/feature-flags';
import { compareName } from 'lib/util/text';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import { selectUserId } from 'users/user-state';

import type { LoadedFarmSuccessPayload } from './farm-loading-state';

export const fetchFarms = createAjaxAction('fetchFarms', Promise.promisify(findByUserWithOwners), {
    success: (state: FarmsState, farms: Farm[]) =>
        state.set('farms', List(farms).sortBy(farmSortBy)),
});

export const setFarms = createAction<FarmsState, Farm[]>('Set farms', (state, payload) =>
    state.set('farms', List(payload).sort(compareName))
);

export const leaveFarm = createAction<FarmsState, void>('Farm: leave farm', (state) =>
    state.set('activeFarmUuid', null)
);

export const updateFarmName = createAction<FarmsState, Farm>(
    'Farm: Update name',
    (state, payload) =>
        state.updateIn(['farms'], (farms: List<Farm>) =>
            farms.map((f) => (f.uuid === payload.uuid ? payload : f))
        )
);

export const removeFarm = createAction<FarmsState, string>('Farm: delete farm', (state, payload) =>
    state
        .set('farms', state.farms ? state.farms.filter((farm) => farm.uuid !== payload) : List())
        .set('activeFarmUuid', null)
);

export const addFarm = createAction<FarmsState, Farm>('Add farm', (state, payload) =>
    state.update('farms', (farms) =>
        farms !== null ? farms.push(payload).sortBy(farmSortBy) : List.of(payload)
    )
);

export const setFarm = createAction<FarmsState, Farm>('Set farm', (state, payload) =>
    state.update('farms', (farms) =>
        farms !== null
            ? farms.map((farm) => (farm.uuid === payload.uuid ? payload : farm))
            : List.of(payload)
    )
);

export const setFarmPlan = createAction<FarmsState, Farm>('Set farm plan', (state, payload) =>
    state.update('farms', (farms) =>
        farms !== null
            ? farms.map((farm) =>
                  farm.uuid === payload.uuid
                      ? { ...farm, plan: payload.plan, freeTrial: payload.freeTrial }
                      : farm
              )
            : farms
    )
);

export const FarmsState = Record({
    farms: null as null | List<Farm>,
    activeFarmUuid: null as string | null,

    fetchFarmsPending: false,
    fetchFarmsError: false,
});
export interface FarmsState extends ReturnType<typeof FarmsState> {}

const farmSortBy = (farm: Farm): string => farm.name;

export const farmsReducer = handleActions<FarmsState>(
    FarmsState(),
    [
        setFarms,
        leaveFarm,
        removeFarm,
        updateFarmName,
        addFarm,
        setFarm,
        setFarmPlan,
        ...fetchFarms.creators,
    ],
    {
        [loadedFarmSuccess.toString()]: (state: FarmsState, payload: LoadedFarmSuccessPayload) =>
            state.set('activeFarmUuid', payload.farm.uuid),
    }
);

export const selectFarms = createSelector(
    (state: AppState) => state.farmsState.farms,
    (farms) => farms as List<Farm>
);

export const selectFreeFarms = createSelector(selectFarms, (farms) =>
    farms.filter((farm) => farm.plan.name === FarmPlanType.FREE_PLAN)
);

export const selectCurrentFarm = createSelector(
    selectFarms,
    (state: AppState) => state.farmsState.activeFarmUuid,
    (farms, activeFarmUuid) => farms.find((farm) => farm.uuid === activeFarmUuid) as Farm
);

export const selectCurrentFarmHasMapPrinting = createSelector(
    selectCurrentFarm,
    (farm) =>
        featureFlags.get('printingOveride', farm.uuid) || (farm.plan.mapPrinting && !farm.freeTrial)
);

export const selectFreeFarmOwnerCount = createSelector(
    selectFreeFarms,
    selectUserId,
    (freeFarms, userId) =>
        freeFarms.reduce(
            (count, farm) =>
                farm.owners.find((farmUser) => farmUser.id === userId) !== undefined
                    ? count + 1
                    : count,
            0
        )
);

export const selectUserCanAddMoreFreeFarms = createSelector(
    (_state: AppState, allowedFreeFarms: number) => allowedFreeFarms,
    selectFreeFarmOwnerCount,
    (allowedFreeFarms, freeFarmCount) => freeFarmCount < allowedFreeFarms
);

export const selectUserHasAnyFarmsWithSubscription = createSelector(
    (state: AppState) => state.user.user,
    selectFarms,
    (user, farms) =>
        user !== null
            ? farms
                  .filter((farm) => farm.owners.find((owner) => owner.id === user.id) !== undefined)
                  .some((farm) => farm.plan.name !== FarmPlanType.FREE_PLAN)
            : false
);
