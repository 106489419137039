import type { MouseEvent } from 'react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@fieldmargin/webapp-styling/components/button';
import { AttachmentType } from 'farm-editing/attachments';
import {
    selectEditingAttachmentsByType,
    setEditingAttachmentsByType,
    stopSelectingMultipleAttachments,
} from 'farm-editing/farm-editing-state';
import { setFieldSearchString } from 'fields/fields-state';
import FieldSelector from 'fields/sidebar/field-selector/FieldSelector';
import FieldSearchBar from 'fields/sidebar/FieldSearchBar';
import FieldSortAndFilter from 'fields/sidebar/FieldSortAndFilter';
import { Set } from 'immutable';
import type Note from 'notes/Note';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';

interface ParentProps {
    editing?: boolean;
}

interface ReduxProps {
    noteFields: Set<string>;
    stopSelectingMultipleAttachments: typeof stopSelectingMultipleAttachments;
    setEditingFieldUuids: SingleParamVoidFunction<Set<string>>;
    clearFieldSearchString: VoidFunction;
}

type NoteFieldsSelectionProps = ParentProps & ReduxProps;

const NoteFieldsSelection = ({
    noteFields,
    stopSelectingMultipleAttachments,
    setEditingFieldUuids,
    clearFieldSearchString,
}: NoteFieldsSelectionProps) => {
    const { t } = useTranslation();

    const initialNoteFields = useRef<Set<string>>(noteFields);

    useEffect(() => {
        clearFieldSearchString();
        return () => {
            clearFieldSearchString();
        };
    }, []);

    const handleBackClick = (e: MouseEvent) => {
        e.preventDefault();
        // Reset back to previously attached fields
        setEditingFieldUuids(initialNoteFields.current);
        stopSelectingMultipleAttachments();
    };

    const handleDoneClick = (e: MouseEvent) => {
        e.preventDefault();
        stopSelectingMultipleAttachments();
    };

    return (
        <div className="note-fields-selection scrollable">
            <div className="non-scrolling">
                <SidebarHeader className="px-5">
                    <Button onClick={handleBackClick} variant="danger-outline" small>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDoneClick} small>
                        {t('save')}
                    </Button>
                </SidebarHeader>
            </div>
            <div className="non-scrolling">
                <SidebarModule editing>
                    <h3 className="text-sm uppercase">{t('create_note_attach_field')}</h3>
                    <p>{t('create_note_attach_field_desc')}</p>
                    <div className="flex justify-between items-center">
                        <FieldSearchBar />
                        <FieldSortAndFilter />
                    </div>
                </SidebarModule>
            </div>
            <div className="scrolling">
                <FieldSelector />
            </div>
        </div>
    );
};

export default connect(
    (state: AppState, { note }: { note?: Note }) => {
        return {
            noteFields:
                note !== undefined
                    ? Set(note.fieldUuids)
                    : selectEditingAttachmentsByType(state, AttachmentType.FIELD),
        };
    },
    (dispatch) =>
        bindActionCreators(
            {
                stopSelectingMultipleAttachments,
                setEditingFieldUuids: setEditingAttachmentsByType(AttachmentType.FIELD),
                clearFieldSearchString: setFieldSearchString.bind(null, ''),
            },
            dispatch
        )
)(NoteFieldsSelection);
