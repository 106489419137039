import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import FarmBlockedModal from 'farms/FarmBlockedModal';
import FarmLimitModals from 'farms/FarmLimitModals';
import FarmUserBlockedModal from 'farms/FarmUserBlockedModal';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import OnboardingContainer from 'onboarding/OnboardingContainer';

function FarmLoaderModals() {
    const { currentFarm } = useCurrentFarm();
    const { pathname } = useLocation();

    const isBillingPage = pathname.includes('billing');

    return (
        <Fragment>
            {currentFarm.plan.blocked && !isBillingPage && <FarmBlockedModal farm={currentFarm} />}
            {currentFarm.blocked && <FarmUserBlockedModal />}
            {!isBillingPage && !currentFarm.plan.blocked && <FarmLimitModals />}
            <OnboardingContainer />
        </Fragment>
    );
}

export { FarmLoaderModals };
