import { useAppSelector } from 'system/store';

const useFieldsSelector = () => {
    const fields = useAppSelector((state) => state.fieldsState.fields);

    return {
        fields,
    };
};

export { useFieldsSelector };
