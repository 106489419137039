import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import { List, Map } from 'immutable';
import { getInputTypeI18nKey, getSortedInputTypes } from 'inputs/InputType';
import Num from 'view/Num';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import type { FieldInputCosts } from './InputCost';
import { InputCost } from './InputCost';
import InputCostsFieldRow from './InputCostsFieldRow';
import { useInputCostsContext } from './useInputCostsContext';

const InputCostsTable = () => {
    const { t } = useTranslation();
    const { farm, inputCosts } = useInputCostsContext();

    const fieldsByUsage = inputCosts.fields.reduce(
        (map, fieldInputCosts) =>
            map.has(fieldInputCosts.fieldUsage)
                ? map.updateIn([fieldInputCosts.fieldUsage], (i) => i.push(fieldInputCosts))
                : map.set(fieldInputCosts.fieldUsage, List.of(fieldInputCosts)),
        Map<string, List<FieldInputCosts>>()
    );

    const inputTypes = getSortedInputTypes();

    return (
        <table className="ReportingTable">
            <thead>
                <tr>
                    <td className="border-none">&nbsp;</td>
                    {inputTypes.map((inputType) => (
                        <th
                            key={inputType}
                            colSpan={2}
                            className="bg-fm-blue text-white text-center"
                        >
                            {t(getInputTypeI18nKey(inputType))}
                        </th>
                    ))}
                </tr>
                <tr>
                    <th>{t('field')}</th>
                    {inputTypes.map((inputType) => (
                        <Fragment key={inputType}>
                            <th>
                                {t('reporting_total_cost')} (
                                {getSymbolFromCurrency(farm.costingsCurrency || 'GBP')})
                            </th>
                            <th>
                                {t('cost')}/
                                <ShortAreaUnit /> (
                                {getSymbolFromCurrency(farm.costingsCurrency || 'GBP')})
                            </th>
                        </Fragment>
                    ))}
                </tr>
            </thead>
            <tbody>
                {fieldsByUsage.keySeq().map((key, i) => (
                    <Fragment key={i}>
                        <tr>
                            <td colSpan={11} className="bg-gray-200">
                                {key}
                            </td>
                        </tr>
                        {(fieldsByUsage.get(key) as List<FieldInputCosts>).map(
                            (fieldInputCosts, j) => (
                                <InputCostsFieldRow key={j} inputCosts={fieldInputCosts} />
                            )
                        )}
                    </Fragment>
                ))}
                <tr>
                    <td>
                        <strong>{t('total').toUpperCase()}</strong>
                    </td>
                    {inputTypes.map((inputType) => {
                        const costs = inputCosts.totals.get(inputType, new InputCost());
                        return (
                            <Fragment key={inputType}>
                                <td>
                                    <strong>
                                        <Num value={costs.totalCost} />
                                    </strong>
                                </td>
                                <td>
                                    <strong>
                                        <Num value={costs.cost} />
                                    </strong>
                                </td>
                            </Fragment>
                        );
                    })}
                </tr>
            </tbody>
        </table>
    );
};

export default InputCostsTable;
