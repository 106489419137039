import type { FieldProps } from 'informed';
import { useField } from 'informed';

interface HiddenFormFieldProps extends FieldProps<any, any> {}

const HiddenFormField = ({ field, ...props }: HiddenFormFieldProps) => {
    const { fieldState } = useField({ field, type: 'text', ...props });

    return <input type="hidden" value={fieldState.value || ''} id={field} />;
};

export default HiddenFormField;
