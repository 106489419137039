import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { connect } from 'react-redux';
import { selectDisplayableFieldUsages } from 'fields/field-usage-selectors';
import type FieldUsage from 'fields/FieldUsage';
import { asBadge } from 'fields/FieldUsage';
import type { List } from 'immutable';
import NewBadge from 'sidebar/modules/badge/NewBadge';
import type { AppState } from 'system/store';

import emptyState from '../field-usages-empty.png';

interface FieldNewUsageProps {
    // From parent
    onCreate: VoidFunction;
    // From redux
    fieldUsages: List<FieldUsage>;
}

const FieldNewUsage = ({ fieldUsages, onCreate }: FieldNewUsageProps) => {
    const { t } = useTranslation();
    return (
        <NewBadge
            field="fieldUsageUuid"
            label={t('field_usage_title')}
            options={fieldUsages.map(asBadge)}
            allowNull
            onCreate={onCreate}
            emptyState={
                <>
                    <h3>{t('field_usage_empty_title')}</h3>
                    <Markdown>{t('field_usage_empty_desc')}</Markdown>
                    <img
                        src={emptyState}
                        alt="A list of usages. Barley, maize, oats and pasture"
                        className="block ml-10 mb-4"
                    />
                </>
            }
        />
    );
};

export default connect((state: AppState) => ({
    fieldUsages: selectDisplayableFieldUsages(state),
}))(FieldNewUsage);
