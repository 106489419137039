import { Record } from 'immutable';

export enum OutputUnit {
    KILOGRAMS = 'KILOGRAMS',
    METRIC_TONNES = 'METRIC_TONNES',
    LITRES = 'LITRES',
    POUNDS = 'POUNDS',
    IMPERIAL_TONS = 'IMPERIAL_TONS',
    GALLONS = 'GALLONS',
    BUSHEL = 'BUSHEL',
    COUNT = 'COUNT',
}

class Output extends Record({
    uuid: '',
    farmUuid: '',
    name: '',
    unit: OutputUnit.KILOGRAMS,
    archived: false,
    version: -1,
    createdDate: new Date(),
}) {}
export default Output;

export interface OutputDTO {
    uuid: string;
    farmUUID: string;
    name: string;
    unit: OutputUnit;
    archived: boolean;
    createdDate: number;
    version: number;
}

export const deserializeOutput = (outputDTO: OutputDTO) =>
    new Output({
        ...outputDTO,
        farmUuid: outputDTO.farmUUID,
        createdDate: new Date(outputDTO.createdDate),
    });

export const serializeOutput = (output: Output) => ({
    uuid: output.uuid,
    farmUUID: output.farmUuid,
    name: output.name,
    unit: output.unit,
    archived: output.archived,
    version: output.version,
});
