import { useEffect } from 'react';
import {
    clearEditingGeoFeatureCollection,
    setCustomHelpMessage,
} from 'farm-editing/farm-editing-state';
import type Field from 'fields/Field';
import { sliceField } from 'fields/sub-fields-manipulations';
import { selectSelectedSubFields } from 'fields/sub-fields-selectors';
import { finishSubFieldManipulation } from 'fields/sub-fields-state';
import { notNil } from 'lib/fp-helpers';
import { useAction, useDrawingToolOnMount } from 'lib/hooks';
import type { TurfFns } from 'lib/turf/turf-utils';
import { useAppSelector } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

import type SubField from '../SubField';
import { createSubField, updateSubFieldField } from '../SubField';

interface SubFieldSliceProps {
    onDone: VoidFunction;
    turf: TurfFns;
}

const SubFieldSlice = ({ onDone, turf }: SubFieldSliceProps) => {
    const subField = useSubFieldSlice(onDone, turf);
    return notNil(subField?.field.geoJson) ? (
        <div className="text-center">
            <p className="mb-1">Draw a line across the selected sub-field to divide it.</p>
            <p className="mb-1">The line must start & finish outside the field boundary.</p>
        </div>
    ) : null;
};

export default SubFieldSlice;

/**
 * This hook handles the data fetching & logic for slicing a sub field.
 * The component is dumb & this does all the work.
 */
const useSubFieldSlice = (onDone: VoidFunction, turf: TurfFns): SubField | undefined => {
    // Slice only operates on a single sub field, so choose the first one.
    // Option should be disabled in menu if more than one is selected but good to handle this
    // just in case.
    const subField = useAppSelector(selectSelectedSubFields).get(0);
    const year = useAppSelector(selectCurrentYear);
    const editingGeoFeatureCollection = useAppSelector(
        (state) => state.farmEditingState.editingGeoFeatureCollection
    );
    const setHelpMsg = useAction(setCustomHelpMessage);
    const clearLine = useAction(clearEditingGeoFeatureCollection);
    const finishSlice = useAction(finishSubFieldManipulation);

    // Set up the drawing tools for slicing, clearing them on unmount.
    useDrawingToolOnMount('line');
    useEffect(() => {
        setHelpMsg('Click to start drawing. Line must start & end outside boundary');
        return () => {
            setHelpMsg(undefined);
        };
    }, []);

    // When the user has finished drawing, slice the field and finish the manipulation.
    useEffect(() => {
        const hasLine =
            editingGeoFeatureCollection && editingGeoFeatureCollection.features.size > 0;
        if (subField && hasLine) {
            const newFields = sliceField(subField.field, editingGeoFeatureCollection, turf);
            finishSlice({
                existingSubField: updateSubFieldField(subField)(newFields.get(0) as Field),
                newSubFields: newFields.slice(1).map((field: Field) => createSubField(field, year)),
            });
            onDone();
        }
        clearLine();
    }, [subField, editingGeoFeatureCollection]);

    return subField;
};
