import { type ComponentProps, forwardRef } from 'react';
import { ButtonBase } from 'components/button-base';
import { t } from 'i18next';
import { ChevronIcon } from 'icons/ChevronIcon';
import { DownloadIcon } from 'icons/DownloadIcon';

import styles from './ButtonDownload.module.css';

interface ButtonDownloadProps extends Omit<ComponentProps<typeof ButtonBase.Root>, 'children'> {
    pending?: boolean;
}

const ButtonDownload = forwardRef<HTMLButtonElement, ButtonDownloadProps>(
    ({ pending, ...props }, ref) => {
        return (
            <ButtonBase.Root className={styles.button} {...props} ref={ref}>
                <ButtonBase.Icon className={styles.iconDownload}>
                    <DownloadIcon />
                </ButtonBase.Icon>

                <span>{t(pending ? 'label_downloading' : 'label_download')}</span>

                <ButtonBase.Icon className={styles.iconChevron}>
                    <ChevronIcon />
                </ButtonBase.Icon>
            </ButtonBase.Root>
        );
    }
);

ButtonDownload.displayName = 'ButtonDownload';

export { ButtonDownload };
