import type { geojson } from '@fieldmargin/webapp-ol-map';
import { selectCustomMaps } from 'farm-map/selectors/selectCustomMaps';
import type { CustomMapSpec } from 'farm-map/types';
import WmsTiles from 'maps/common/WmsTiles';
import XyzTiles from 'maps/common/XyzTiles';
import { useAppSelector } from 'system/store';

export const useFarmMapTiledLayers = () => {
    const customMaps = useAppSelector(selectCustomMaps);
    return customMaps.map(customMapSpecToTiledLayerSpec);
};

const customMapSpecToTiledLayerSpec = (customMap: CustomMapSpec): geojson.TiledLayerSpec => ({
    id: customMap.id,
    extent: customMap.tiles.extent,
    xyzTiles:
        customMap.tiles instanceof XyzTiles
            ? {
                  urlTemplates: customMap.tiles.urlTemplates,
                  minZoom: customMap.tiles.minZoom,
                  maxZoom: customMap.tiles.maxZoom,
              }
            : undefined,
    wmsTiles:
        customMap.tiles instanceof WmsTiles
            ? {
                  url: customMap.tiles.url,
                  params: customMap.tiles.params,
                  tileGrid: {
                      minZoom: 15,
                      maxZoom: 15,
                      tileSize: [512, 515],
                  },
              }
            : undefined,
    opacity: customMap.opacity,
    zIndex: customMap.zIndex,
});
