import { List } from 'immutable';
import { DEFAULT_COLOUR } from 'lib/colours';
import { listToMap } from 'lib/immutil';
import { compareString, stringContains } from 'lib/util/text';
import { isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

import type FieldUsage from './FieldUsage';
import { getUsageName, hasNoCropCode } from './FieldUsage';

/**
 * Selects field usages, sorted alphabetically by their name.
 */
export const selectSortedFieldUsages = createSelector(
    (state: AppState) => state.fieldUsageState.fieldUsages,
    (fieldUsages) =>
        fieldUsages
            ? fieldUsages.sort((a, b) => compareString(getUsageName(a), getUsageName(b)))
            : List()
);

/**
 * Selects field usages, sorted alphabetically by their name, with a "none" option prepended.
 */
export const selectFieldUsagesWithNotSet = createSelector(
    selectSortedFieldUsages,
    (fieldUsages): List<FieldUsage> =>
        fieldUsages.unshift({
            uuid: 'none',
            name: 'No usage set',
            colour: DEFAULT_COLOUR,
            description: '',
            cropCode: '',
        })
);

/**
 * Selects field usages whose title matches the current fieldSearchString.
 * Will return all usages if search string is empty.
 */
export const selectFilteredFieldUsages = createSelector(
    selectFieldUsagesWithNotSet,
    (state: AppState) => state.fieldsState.fieldSearchString,
    (fieldUsages, fieldSearchString) => {
        return !isEmpty(fieldSearchString)
            ? fieldUsages.filter((fu) => stringContains(getUsageName(fu), fieldSearchString))
            : fieldUsages;
    }
);

/**
 * Select only field usages that are active, with a "none" option prepended.
 */
export const selectDisplayableFieldUsagesWithNotSet = createSelector(
    selectFieldUsagesWithNotSet,
    (fieldUsages) => fieldUsages.filter((fu) => !fu.archived)
);

/**
 * Select only field usages that are active.
 */
export const selectDisplayableFieldUsages = createSelector(selectSortedFieldUsages, (fieldUsages) =>
    fieldUsages.filter((fu) => !fu.archived)
);

/**
 * Select a map of field usages keyed by their UUID.
 */
export const selectFieldUsageMapWithNotSet = createSelector(
    selectFieldUsagesWithNotSet,
    (fieldUsages) => listToMap(fieldUsages, (fieldUsage) => fieldUsage.uuid)
);

export const selectFieldUsagesWithoutCropTypes = createSelector(
    selectDisplayableFieldUsages,
    (fieldUsages) => fieldUsages.filter(hasNoCropCode)
);
