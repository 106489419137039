import type { RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';
import AuthorizationCompletion from 'auth/AurthorizationCompletion';

export const authRoutes: RouteObject = {
    path: APP_ROUTES.Auth.Root,
    children: [
        {
            path: APP_ROUTES.Auth.Complete,
            element: <AuthorizationCompletion />,
        },
    ],
};
