import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { getZIndex } from 'components/maps/model/MapLayerIndex';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import { EditingType } from 'farm-editing/farm-editing-state';
import { List } from 'immutable';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

export const selectMeasureShapes = createSelector(
    (state: AppState) => state.farmEditingState.editingType,
    (state: AppState) => state.farmEditingState.editingGeoFeatureCollection,
    (editingType, editingGeoFeatureCollection) => {
        if (editingType !== EditingType.MEASURE || !editingGeoFeatureCollection) {
            return List<GeoFeature>();
        }
        return editingGeoFeatureCollection.features.map((geoFeature) => {
            return geoFeature.set(
                'properties',
                OLFeatureProperties({
                    type: 'measure-shape',
                    editable: true,
                    zIndex: getZIndex(true, 'measure', geoFeature.geometry.type),
                })
            );
        });
    }
);
