import type { FocusEvent } from 'react';
import { useEffect } from 'react';
import { Input, NumberField } from 'react-aria-components';
import { clsx } from 'clsx';
import { useField, useFieldState } from 'informed';

interface WorkAreaFieldProps {
    field: string;
    initialArea?: number;
    onChange?: (value: number) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    className?: string;
}

const WorkAreaField = ({
    field,
    initialArea,
    onChange,
    onBlur,
    disabled = false,
    className,
}: WorkAreaFieldProps) => {
    const { error } = useFieldState(field);
    const { fieldApi } = useField({ field });

    const handleChange = (value: number) => {
        fieldApi.setValue(value);
        if (onChange) onChange(value);
    };
    useEffect(() => {
        // On mount we'll mark the field as touched so that the onChange event of the form
        // fires, causing the work area values to be set correctly.
        fieldApi.setTouched(true);
        if (initialArea) fieldApi.setValue(initialArea);
    }, []);

    return (
        <NumberField
            defaultValue={initialArea}
            validate={(value) => (value >= 0 ? undefined : 'Required')}
            minValue={0}
            onChange={handleChange}
            onBlur={onBlur}
            isDisabled={disabled}
        >
            <Input
                className={clsx('simple-text-input', { 'has-error': error }, className)}
                autoComplete="off"
            />
        </NumberField>
    );
};

export default WorkAreaField;
