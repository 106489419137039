import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import LoadableContent from '@fieldmargin/webapp-styling/components/loadable-content';
import icon from 'components/icons/icon';
import EditInputCostsButton from 'reporting/EditInputCostsButton';
import ExportReportButton from 'reporting/ExportReportButton';
import { getCsvDownloadLink } from 'reporting/reporting-api';
import ReportingUpgrade from 'reporting/ReportingUpgrade';
import ReportStatusFilter from 'reporting/ReportStatusFilter';
import ErrorMessage from 'view/ErrorMessage';

import InputReportingEmpty from '../InputReportingEmpty';

import InputSummaryTable from './InputSummaryTable';
import { useInputSummaryContext } from './useInputSummaryContext';

const InputSummaryView = () => {
    const { t } = useTranslation();
    const { farm, year, reportData, filter, onFilterChange, error, pending, areaMeasurementUnit } =
        useInputSummaryContext();

    if (farm.plan.reportingLevel === 'NO_REPORTING') {
        return (
            <div className="InputReporting text-center">
                <h1 className="uppercase text-2xl">
                    {t('input_summary_header', { sprintf: [year] })}
                </h1>
                {!farm.demo && <ReportingUpgrade />}
            </div>
        );
    }

    let children: ReactNode;
    if (!pending && error) {
        children = <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>;
    } else if (reportData && reportData.length === 0) {
        children = <EmptyMessage farm={farm} filter={filter} />;
    } else if (reportData) {
        children = <InputSummaryTable farm={farm} inputReportSummaries={reportData} />;
    }

    return (
        <div className="relative">
            <Button
                as={Link}
                to={`/farms/${farm.uuid}/reporting`}
                className="absolute top-0 left-0 flex items-center gap-2"
                variant="subtle"
            >
                {icon('back')} {t('back')}
            </Button>
            <h1 className="uppercase text-center text-2xl">
                {t('input_summary_header', { sprintf: [year] })}
            </h1>
            {((reportData && reportData.length > 0) || filter !== 'all') && (
                <div className="flex items-center mb-5">
                    <EditInputCostsButton farm={farm} />
                    <ReportStatusFilter
                        filter={filter}
                        setFilter={onFilterChange}
                        disabled={pending}
                        className="ml-auto"
                    />
                    <ExportReportButton
                        filename="input-summary-export"
                        downloadLink={getCsvDownloadLink(
                            farm.uuid,
                            year,
                            filter,
                            'inputsSummary',
                            areaMeasurementUnit
                        )}
                    />
                </div>
            )}
            <LoadableContent
                loading={!error && (reportData === null || pending)}
                delay={300}
                fallback={
                    <p>
                        <em>{t('reporting_loading')}</em>
                    </p>
                }
            >
                {children}
            </LoadableContent>
        </div>
    );
};

export default InputSummaryView;

const EmptyMessage = ({ farm, filter }: { farm: Farm; filter: string }) => {
    const { t } = useTranslation();
    if (filter === 'all') {
        return <InputReportingEmpty farm={farm} />;
    } else if (filter === 'todo') {
        return <p>{t('input_summary_empty_incomplete')}</p>;
    } else if (filter === 'done') {
        return <p>{t('input_summary_empty_complete')}</p>;
    }
};
