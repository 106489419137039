import type { ComponentType, PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { ActiveSection } from 'system/url-util';

interface HeaderProps {
    activeSection?: ActiveSection;
}

interface ApplicationLayoutProps {
    CreateFarm: {
        TipBox?: ComponentType;
    };
    Navigation?: ComponentType;
    Header: ComponentType<HeaderProps>;
    Map?: {
        Button: {
            Print: ComponentType<HeaderProps>;
            Measure: ComponentType<HeaderProps>;
        };
    };
}

const ApplicationLayoutContext = createContext({} as ApplicationLayoutProps);

function AplicationLayoutProvider({
    children,
    ...props
}: PropsWithChildren<ApplicationLayoutProps>) {
    const value = useMemo(() => props, [props]);

    return (
        <ApplicationLayoutContext.Provider value={value}>
            {children}
        </ApplicationLayoutContext.Provider>
    );
}

const useApplicationLayoutContext = () => {
    const context = useContext(ApplicationLayoutContext);
    return context;
};

export { AplicationLayoutProvider, useApplicationLayoutContext, type ApplicationLayoutProps };
