import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePromise } from '@fieldmargin/webapp-state';
import type Field from 'fields/Field';
import { getFieldWorkedArea } from 'fields/Field';
import { areaAsSqm, formatArea } from 'lib/geo/maths';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import EditableActions from 'sidebar/modules/common/EditableActions';
import type { UnaryPromiseFunction } from 'system/types';
import ErrorMessage from 'view/ErrorMessage';
import NumberInputField from 'view/form/hook/NumberInputField';

interface FieldWorkedAreaFormProps {
    field: Field;
    year: number;
    measurementUnit: MeasurementUnit;
    onSave: UnaryPromiseFunction<number>;
    onCancel: VoidFunction;
}

interface FormData {
    workedArea: number;
}

const FieldWorkedAreaForm = ({
    field,
    year,
    measurementUnit,
    onSave,
    onCancel,
}: FieldWorkedAreaFormProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise(onCancel);

    const methods = useForm<FormData>({
        mode: 'onChange',
        defaultValues: {
            workedArea: formatArea(getFieldWorkedArea(field, year), measurementUnit),
        },
    });

    const onSubmit = (data: FormData) => {
        setPromise(onSave(areaAsSqm(data.workedArea, measurementUnit)));
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <NumberInputField field="workedArea" minValue={0} />
                {methods.formState.errors.workedArea && (
                    <ErrorMessage className="mt-1">{t('worked_area_validation')}</ErrorMessage>
                )}
                <EditableActions disabled={pending} error={error} setEditing={onCancel} />
            </form>
        </FormProvider>
    );
};

export default FieldWorkedAreaForm;
