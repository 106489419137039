import { useOutletContext } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { MeasurementUnit } from 'lib/MeasurementUnit';

import type { GrossMargin } from './GrossMargin';

export interface GrossMarginUsageReportSchema {
    farm: Farm;
    reportData: GrossMargin[];
    measurementUnit: MeasurementUnit;
}

const useGrossMarginUsageReportContext = () => {
    return useOutletContext<GrossMarginUsageReportSchema>();
};

export { useGrossMarginUsageReportContext };
