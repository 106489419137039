import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { NoteDTO } from 'notes/Note';
import { deserializeNote } from 'notes/Note';

import type { HerdEventDTO, HerdLocationDTO, ReadHerdDTO, WriteHerdLocation } from './Herd';
import type Herd from './Herd';
import {
    deserializeHerd,
    deserializeHerdEvent,
    deserializeHerdLocation,
    serializeHerd,
    serializeHerdLocation,
} from './Herd';

export const fetchHerds = (farmUuid: string, year: number) =>
    authorizedRequest<ReadHerdDTO[]>({
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/herds`,
    }).then((herds) => herds.map(deserializeHerd));

export const saveHerd = (year: number, herd: Herd) =>
    authorizedRequest<ReadHerdDTO>({
        url: `/notes-api/v2/farms/${herd.farmUuid}/years/${year}/herds`,
        method: 'post',
        data: serializeHerd(herd),
    }).then(deserializeHerd);

export const archiveHerd = (year: number, herd: Herd, archived: boolean) =>
    authorizedRequest<ReadHerdDTO>({
        url: `/notes-api/v2/farms/${herd.farmUuid}/years/${year}/herds/${herd.uuid}/archive`,
        params: { archived },
        method: 'PUT',
    }).then(deserializeHerd);

export const deleteHerd = (year: number, herd: Herd) =>
    authorizedRequest({
        url: `/notes-api/v2/farms/${herd.farmUuid}/years/${year}/herds/${herd.uuid}/`,
        method: 'delete',
    });

export const saveHerdLocation = (farmUuid: string, year: number, herdLocation: WriteHerdLocation) =>
    authorizedRequest<HerdLocationDTO>({
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/herds/${herdLocation.herdUuid}/locations`,
        method: 'post',
        data: serializeHerdLocation(herdLocation),
    }).then(deserializeHerdLocation);

export const fetchHerdHistory = (herd: Herd) =>
    authorizedRequest<{ herdHistories: HerdEventDTO[]; herdLocations: HerdLocationDTO[] }>({
        url: `/notes-api/v2/farms/${herd.farmUuid}/herds/${herd.uuid}/historyAndLocation`,
    }).then(({ herdHistories, herdLocations }) => ({
        herdHistories: herdHistories.map(deserializeHerdEvent),
        herdLocations: herdLocations.map(deserializeHerdLocation),
    }));

export const fetchHerdNotes = (farmUuid: string, herdUuid: string) =>
    authorizedRequest<NoteDTO[]>({
        url: `/notes-api/farms/${farmUuid}/notes/herds/${herdUuid}`,
    }).then((notes) => notes.map(deserializeNote));

export const deleteHerdLocation = (
    farmUuid: string,
    year: number,
    herdUuid: string,
    herdLocationUuid: string
) =>
    authorizedRequest({
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/herds/${herdUuid}/locations/${herdLocationUuid}`,
        method: 'delete',
    });

export const splitHerd = (
    farmUuid: string,
    year: number,
    sourceHerd: Herd,
    splitHerds: { name: string; size: number; colour: string }[]
) =>
    authorizedRequest<ReadHerdDTO[]>({
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/herds/${sourceHerd.uuid}/split`,
        method: 'post',
        data: {
            sourceHerd: serializeHerd(sourceHerd),
            splitHerds,
        },
    }).then((herds) => herds.map(deserializeHerd));

export const mergeHerds = (
    farmUuid: string,
    year: number,
    destinationHerd: Herd,
    mergeHerdUuid: string[]
) =>
    authorizedRequest<ReadHerdDTO>({
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/herds/${destinationHerd.uuid}/merge`,
        method: 'post',
        data: { destinationHerd: serializeHerd(destinationHerd), mergeHerdUuid },
    }).then(deserializeHerd);
