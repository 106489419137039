import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { AppState } from 'system/store';

import type Feature from './Feature';
import { selectFeature } from './features-selectors';

const withPageFeature =
    <Props,>(Component: React.ComponentType<Props>) =>
    (props: Props) => {
        const featureUuid = useParams<{ featureUuid: string }>().featureUuid;
        const feature =
            featureUuid !== undefined
                ? useSelector<AppState, Feature | undefined>((state) =>
                      selectFeature(state, featureUuid)
                  )
                : undefined;

        return <Component {...props} feature={feature} />;
    };

export default withPageFeature;
