import { useTranslation } from 'react-i18next';
import type Field from 'fields/Field';
import { getFieldArea } from 'fields/Field';
import { removeSubField, setSelectedSubFields, setSubField } from 'fields/sub-fields-state';
import SidebarModule from 'sidebar/modules/SidebarModule';
import { useAppDispatch, useAppSelector } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import FormattedArea from 'view/units/FormattedArea';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import type SubField from '../SubField';

import SubFieldsListItem from './SubFieldsListItem';

interface SubFieldsListProps {
    parentField: Field;
    onRemoveSubField: SingleParamVoidFunction<string>;
    onToggleError: SingleParamVoidFunction<string>;
}

const SubFieldsList = ({ parentField, onRemoveSubField, onToggleError }: SubFieldsListProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const subFields = useAppSelector((state) => state.subFieldsState.subFields);
    const selectedSubFields = useAppSelector((state) => state.subFieldsState.selectedSubFields);

    const handleUpdateSubField = (subField: SubField) => {
        dispatch(setSubField(subField));
    };

    const handleSelect = (subFieldId: string) => {
        dispatch(
            setSelectedSubFields(
                selectedSubFields.contains(subFieldId)
                    ? selectedSubFields.remove(subFieldId)
                    : selectedSubFields.add(subFieldId)
            )
        );
    };

    const handleDelete = (subFieldId: string) => {
        dispatch(removeSubField(subFieldId));
        onRemoveSubField(subFieldId);
    };

    return (
        <SidebarModule editing className="px-0">
            <div className="px-5 mb-4">
                <h4>{t('field_details_subfields')}</h4>
                <p className="mb-0 text-gray-450">{t('sub_fields_name_desc1')}</p>
                <p className="text-gray-450">{t('sub_fields_name_desc2')}</p>
            </div>
            <div className="flex justify-end">
                <strong className="w-1/4 pl-8">{t('name')}</strong>
                <strong className="w-1/2 pl-3">
                    {t('area')} (<ShortAreaUnit />)
                </strong>
            </div>
            {subFields.map((subField) => (
                <SubFieldsListItem
                    key={subField.id}
                    parentField={parentField}
                    subField={subField}
                    selected={selectedSubFields.contains(subField.id)}
                    onSelect={handleSelect}
                    onRemove={subFields.size > 1 ? handleDelete : undefined}
                    onChange={handleUpdateSubField}
                    onToggleError={onToggleError}
                />
            ))}
            <p className="px-5 mt-4">
                <strong>
                    {t('field_work_area_total')} (<ShortAreaUnit />
                    ): <FormattedArea areaSqm={getFieldArea(parentField)} />
                </strong>
            </p>
        </SidebarModule>
    );
};

export default SubFieldsList;
