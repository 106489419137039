import type Field from 'fields/Field';
import { compareName } from 'lib/util/text';
import { sort } from 'ramda';
import SmallPill from 'view/SmallPill';

interface NotePreviewFieldsProps {
    fields: Field[];
    remainder: number;
}

const NotePreviewFields = ({ fields, remainder }: NotePreviewFieldsProps) => {
    return fields.length > 0 ? (
        <div className="NotePreviewFields">
            {fields.map((field) => (
                <SmallPill key={field.uuid} className="NotePreviewField">
                    {field.name}
                </SmallPill>
            ))}
            {remainder > 0 && <small>(+{remainder})</small>}
        </div>
    ) : null;
};

// This function is used to sort the fields by name & saves having this logic in the component.
const prepProps = (props: Pick<NotePreviewFieldsProps, 'fields'>): NotePreviewFieldsProps => ({
    fields: sort(compareName, props.fields).slice(0, 3),
    remainder: props.fields.length - 3,
});
export default (props: Pick<NotePreviewFieldsProps, 'fields'>) =>
    NotePreviewFields(prepProps(props));
