import { createLogger } from '@fieldmargin/webapp-reporting';
import Promise from 'bluebird';
import type ChatMessage from 'chat/ChatMessage';
import { serializeChatMessage } from 'chat/ChatMessage';
import {
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    setDoc,
    startAfter,
} from 'firebase/firestore';
import {
    getFarmUserRef,
    getMessageRef,
    getMessagesRef,
    messageFromDoc,
} from 'lib/firebase/firebase-connection';

const logger = createLogger('chat.firebase.firebase-chat-api');

export const HISTORY_PAGE_SIZE = 20;

export const writeChatMessageApi = (farmUuid: string, message: ChatMessage) => {
    const data = serializeChatMessage(message);
    return Promise.resolve(
        setDoc(doc(getMessagesRef(farmUuid), message.uuid), data as { [key: string]: any })
    ).tapCatch(logger.handleRequestError('Error sending chat message'));
};

export const writeLastReadApi = (farmUuid: string, myUserId: number, message: ChatMessage) => {
    return Promise.resolve(
        setDoc(getFarmUserRef(farmUuid, myUserId), {
            lastReadUUID: message.uuid,
            lastReadCreatedDate: message.createdDate,
        })
    ).tapCatch(logger.handleRequestError('Error writing last read'));
};

export const getHistoryPageApi = (farmUuid: string, topMessage?: ChatMessage) => {
    let historyQuery = query(
        getMessagesRef(farmUuid),
        orderBy('createdDate', 'desc'),
        limit(HISTORY_PAGE_SIZE)
    );

    let promise: PromiseLike<any>;
    if (topMessage) {
        const messageRef = getMessageRef(farmUuid, topMessage.uuid);
        promise = getDoc(messageRef).then((topMessageDoc) => {
            historyQuery = query(
                getMessagesRef(farmUuid),
                orderBy('createdDate', 'desc'),
                limit(HISTORY_PAGE_SIZE),
                startAfter(topMessageDoc)
            );
            return getDocs(historyQuery);
        });
    } else {
        promise = getDocs(historyQuery);
    }
    return Promise.resolve(promise)
        .then((querySnapshot) => {
            const messages: ChatMessage[] = querySnapshot.docs.reverse().map(messageFromDoc);
            return {
                messages,
                finished: messages.length < HISTORY_PAGE_SIZE,
            };
        })
        .tapCatch(logger.handleRequestError('Error fetching history'));
};
