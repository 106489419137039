import { useCurrentOperation } from 'hooks/useCurrentOperation';

const useOperationRecordingsSelector = () => {
    const { currentOperation } = useCurrentOperation();

    return {
        recordings: currentOperation?.get('recordings'),
    };
};

export { useOperationRecordingsSelector };
