import { OperationRecord } from 'components/operation-record';
import type Input from 'inputs/Input';
import { getAllActiveInputNutrients } from 'inputs/Input';
import { InputNutrientSymbol } from 'inputs/InputNutrients';
import { InputType } from 'inputs/InputType';
import { capitaliseWord } from 'lib/util/text';
import FormattedRate from 'view/units/FormattedRate';
import ShortUnit from 'view/units/ShortUnit';

import { calculateNutrientRate, calculateTotalFromRateAndArea } from './operation-recording-utils';

import styles from './OperationRecordingCardFertiliserInfo.module.css';

interface OperationRecordingCardFertiliserInfoProps {
    input: Input;
    nutrients: {
        nutrient: string;
        rate: number;
        total: number;
    }[];
}

interface ParentProps {
    input: Input;
    rate: number;
    area: number;
}

const OperationRecordingCardFertiliserInfo = ({
    input,
    nutrients,
}: OperationRecordingCardFertiliserInfoProps) => {
    return (
        input.type === InputType.FERTILIZER && (
            <OperationRecord.Table>
                <OperationRecord.TableBody>
                    {nutrients.map(({ nutrient, rate, total }) => (
                        <OperationRecord.TableRow key={nutrient}>
                            <OperationRecord.TableHeaderCell className={styles.nutrient}>
                                {capitaliseWord(nutrient)}
                            </OperationRecord.TableHeaderCell>

                            <OperationRecord.TableCell className={styles.nutrientRate}>
                                <FormattedRate rate={rate} />{' '}
                                <InputNutrientSymbol nutrient={nutrient} />
                            </OperationRecord.TableCell>

                            <OperationRecord.TableCell>
                                <FormattedRate rate={total} /> <ShortUnit unit={input.unit} />
                            </OperationRecord.TableCell>
                        </OperationRecord.TableRow>
                    ))}
                </OperationRecord.TableBody>
            </OperationRecord.Table>
        )
    );
};

const prepProps = (props: ParentProps) => {
    const nutrients = getAllActiveInputNutrients(props.input).map(([nutrient, percentage]) => {
        const nutrientRate = calculateNutrientRate(percentage, props.rate);
        return {
            nutrient,
            rate: nutrientRate,
            total: calculateTotalFromRateAndArea(nutrientRate, props.area),
        };
    });
    return { input: props.input, nutrients };
};

export default (props: ParentProps) => OperationRecordingCardFertiliserInfo(prepProps(props));
