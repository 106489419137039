import { useTranslation } from 'react-i18next';
import type Input from 'inputs/Input';

import type { PesticideCheckUsePermitted } from './PesticideCheck';
import { PesticideCheckStatus } from './PesticideCheck';
import PesticideCheckResultIcon from './PesticideCheckResultIcon';

interface UsePermittedResultProps {
    input: Input;
    crop?: string;
    check: PesticideCheckUsePermitted;
}

const UsePermittedResult = ({ input, crop, check }: UsePermittedResultProps) => {
    const { t } = useTranslation();
    return (
        <tr>
            <PesticideCheckResultIcon status={check.status} />
            <td>
                {check.status === PesticideCheckStatus.FAIL
                    ? t('pesticide_check_use_fail', { sprintf: [input.name, crop] })
                    : check.status === PesticideCheckStatus.UNKNOWN
                      ? t('pesticide_check_use_no_data', { sprintf: [input.name, crop] })
                      : t('pesticide_check_use_valid', { sprintf: [input.name, crop] })}
            </td>
        </tr>
    );
};

export default UsePermittedResult;
