import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm, FarmUser } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { Set } from 'immutable';
import type FullOperation from 'operations/FullOperation';
import { saveOperationTaggedUsers } from 'operations/operations-save-helper';
import { setOperation } from 'operations/operations-state';
import { bindActionCreators } from 'redux';
import EditButton from 'sidebar/modules/common/EditButton';
import type { AppState } from 'system/store';
import { selectDisplayableFarmUsers, selectFarmUsersFromSet } from 'team/farm-users-state';
import { trackEvent } from 'utils/trackEvent';

import OperationTaggedUsersForm from './OperationTaggedUsersForm';

interface EditableOperationUsersProps {
    // From parent
    fullOperation: FullOperation;

    // From redux
    farm: Farm;
    taggedUsers?: FarmUser[];
    farmUsers: FarmUser[];
    setOperation: typeof setOperation;
}

const EditableOperationUsers = ({
    farm,
    fullOperation,
    taggedUsers = [],
    farmUsers,
    setOperation,
}: EditableOperationUsersProps) => {
    const { t } = useTranslation();
    const [editing, toggleEditing] = useToggle(false);

    const handleSubmit = (newTaggedUsers: number[]) =>
        saveOperationTaggedUsers(farm.uuid, fullOperation, Set(newTaggedUsers)).then(
            (operation) => {
                setOperation(operation);
                toggleEditing();
                trackEvent('Field operation users tagged', {
                    farmUuid: fullOperation.summary.farmUuid,
                    jobUuid: fullOperation.uuid,
                });
            }
        );

    return editing ? (
        <OperationTaggedUsersForm
            farmUsers={farmUsers}
            taggedUsers={taggedUsers}
            onSave={handleSubmit}
            toggleEditing={toggleEditing}
        />
    ) : (
        <div className="flex gap-2 items-start py-4 bordered-b">
            <h4 className="mb-0 w-32">{t('activity_log_tagged')}</h4>
            {taggedUsers.length === 0 ? (
                <span className="not-set">{t('no_tagged_users')}</span>
            ) : (
                <ol className="list-none pl-0 mb-0 text-fm-blue">
                    {taggedUsers.map((user) => (
                        <li
                            key={user.id}
                            className="pl-0"
                        >{`${user.firstName} ${user.lastName}`}</li>
                    ))}
                </ol>
            )}
            <EditButton setEditing={toggleEditing} className="font-bold ml-auto float-none" />
        </div>
    );
};

export default connect(
    (state: AppState, { fullOperation }: { fullOperation: FullOperation }) => ({
        farm: selectCurrentFarm(state),
        taggedUsers: selectFarmUsersFromSet(state, fullOperation.summary.taggedUserIds),
        farmUsers: selectDisplayableFarmUsers(state),
    }),
    (dispatch) => bindActionCreators({ setOperation }, dispatch)
)(EditableOperationUsers);
