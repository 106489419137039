import { useTranslation } from 'react-i18next';
import EmptyState from 'sidebar/modules/EmptyState';
import UpgradeButton from 'upgrade/UpgradeButton';

import upgradeImg from './yield-upgrade.png';

const YieldUpgrade = () => {
    const { t } = useTranslation();
    return (
        <EmptyState image={upgradeImg} className="bg-gray-100 pt-3 bordered-b">
            <h2 className="font-medium text-xl text-center">
                {t('create_operation_upgrade_output').replace('\\n', ' ')}
            </h2>
            <p className="text-center">
                <UpgradeButton className="w-1/3" />
            </p>
        </EmptyState>
    );
};

export default YieldUpgrade;
