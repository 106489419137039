import type FieldUsage from 'fields/FieldUsage';
import { DEFAULT_COLOUR } from 'lib/colours';

interface OperationFieldUsageMarkerProps {
    fieldUsage?: FieldUsage | null;
}

const OperationFieldUsageMarker = ({ fieldUsage }: OperationFieldUsageMarkerProps) => {
    return (
        <div
            className="h-full mr-3 usage-marker"
            style={{
                backgroundColor: fieldUsage ? fieldUsage.colour : DEFAULT_COLOUR,
            }}
        />
    );
};

export default OperationFieldUsageMarker;
