import { PureComponent } from 'react';
import { connect } from 'react-redux';
import icon from 'components/icons/icon';
import { setMapGroupVisibility } from 'farm-editing/farm-editing-state';
import type MapGroup from 'maps/farm/MapGroup';
import MapViewOptions from 'maps/farm/sidebar/list/MapViewOptions';
import { bindActionCreators } from 'redux';
import ItemHistoryItem from 'sidebar/modules/item-history/ItemHistoryItem';
import { getMapVisibility } from 'system/editing/farm-editing-maps';
import type { AppState } from 'system/store';
import type { VisibilityOptions } from 'system/types';

interface ReduxProps {
    sourceName: string;
    mapGroupVisibility: VisibilityOptions;
    setMapGroupVisibility: (data: { mapGroup: MapGroup; value: VisibilityOptions }) => void;
}

interface ParentProps {
    mapGroup: MapGroup;
}

type FieldHistoryMapItemProps = ParentProps & ReduxProps;

class FieldHistoryMapItem extends PureComponent<FieldHistoryMapItemProps> {
    render() {
        const { mapGroup, sourceName, mapGroupVisibility, setMapGroupVisibility } = this.props;

        return (
            <ItemHistoryItem>
                {icon('map', 'dark-blue')}
                <div className="flex flex-col flex-grow">
                    <span>{mapGroup.name}</span>
                    <span>{sourceName}</span>
                </div>
                <MapViewOptions
                    mapGroup={mapGroup}
                    mapGroupVisibility={mapGroupVisibility}
                    setMapGroupVisibility={setMapGroupVisibility}
                />
            </ItemHistoryItem>
        );
    }
}

export default connect(
    (state: AppState, props: ParentProps) => {
        const farmIntegration = state.integrationsState.farmIntegrations
            ? state.integrationsState.farmIntegrations.find(
                  (fi) => fi.uuid === props.mapGroup.farmIntegrationUuid
              )
            : undefined;
        return {
            sourceName: farmIntegration ? farmIntegration.name : 'Unknown',
            mapGroupVisibility: getMapVisibility(
                state.farmEditingState.layerVisibility.visibleMapGroups,
                state.farmEditingState.layerVisibility.hiddenMapIntegrations,
                props.mapGroup
            ),
        };
    },
    (dispatch) =>
        bindActionCreators(
            {
                setMapGroupVisibility,
            },
            dispatch
        )
)(FieldHistoryMapItem);
