import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { ButtonVariant } from '@fieldmargin/webapp-styling/components/button';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import { selectCurrentFarm } from 'farms/farms-state';
import type { AppState } from 'system/store';

interface UpgradeButtonProps {
    farm: Farm;
    variant?: ButtonVariant;
    className?: string;
    children?: ReactNode;
    onClick?: VoidFunction;
    small?: boolean;
}

const UpgradeButton = ({
    farm,
    variant = 'primary',
    className,
    children,
    onClick = () => null,
    small = false,
}: UpgradeButtonProps) => {
    const { t } = useTranslation();
    return farm.demo ? null : (
        <Button
            as={Link}
            to={`/farms/${farm.uuid}/billing/upgrade`}
            className={c(className)}
            onClick={onClick}
            variant={variant}
            small={small}
        >
            {children || t('upgrade')}
        </Button>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
}))(UpgradeButton);
