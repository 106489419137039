import { Fragment } from 'react/jsx-runtime';
import { Navigate, Outlet } from 'react-router-dom';
import { useIsJDAuthenticatedQuery } from '@notes-web/api';

import { DancingWheat } from '../DancingWheat';

function JohnDeereAuthRedirect() {
    const { data, isLoading } = useIsJDAuthenticatedQuery();

    if (isLoading) {
        return <DancingWheat />;
    }

    if (data) {
        return (
            <Fragment>
                <Navigate to="select" replace />
                <Outlet />
            </Fragment>
        );
    }

    return <Outlet />;
}

export { JohnDeereAuthRedirect };
