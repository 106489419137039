import { useEffect } from 'react';
import { useOnMount, usePrevious } from '@fieldmargin/webapp-state';
import { useFieldApi } from 'informed';

/**
 * Sets the value of an Informed field if this component updates and the value of `value` has changed
 */
const ForceFieldValueOnUpdate = ({ field, value }) => {
    const fieldApi = useFieldApi(field);
    const prevValue = usePrevious(value);
    const isMount = useOnMount();

    useEffect(() => {
        if (!isMount && value !== prevValue) {
            fieldApi.setValue(value);
        }
    }, [value]);

    return null;
};

export default ForceFieldValueOnUpdate;
