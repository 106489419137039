import { createContext, useEffect, useState } from 'react';
import { isArray } from 'lodash';
import queryString from 'query-string';

export interface LoadTimeState {
    coupon?: string;
    interval?: string;
}
export const LoadTimeContext = createContext<LoadTimeState>({});

export const useLoadTimeState = () => {
    const [state, setState] = useState<LoadTimeState>({});
    useEffect(() => {
        const params = queryString.parse(location.search);
        if (
            Object.keys(params).length > 0 &&
            !isArray(params.coupon) &&
            !isArray(params.interval)
        ) {
            setState({
                coupon: params.coupon ?? undefined,
                interval: params.interval ?? undefined,
            });
        }
    }, []);
    return state;
};
