import { connect } from 'react-redux';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { ButtonVariant } from '@fieldmargin/webapp-styling/components/button';
import Button from '@fieldmargin/webapp-styling/components/button';
import { props } from 'bluebird';
import type { Location } from 'history';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { setReturnTo } from 'system/ui-state';

interface ReturningLinkButtonProps extends LinkProps {
    // From parent
    variant?: ButtonVariant;
    wide?: boolean;
    small?: boolean;

    // From redux
    returnTo?: string;
    setReturnTo: typeof setReturnTo;
}

/**
 * This component can be used to return the user to a previously remembered URL.
 * It should be used in conjunction with RememberingLink or RememberingLinkButton
 */
const ReturningLinkButton = ({
    variant = 'primary',
    wide = false,
    small = false,
    children,
    to,
    returnTo,
    setReturnTo,
    state,
}: ReturningLinkButtonProps) => {
    const handleClick = () => {
        setReturnTo(undefined);
    };

    const returnToLocation = {
        pathname: returnTo,
    } as Location;

    return (
        <Button
            as={Link}
            to={returnTo !== undefined ? returnToLocation : to}
            onClick={handleClick}
            state={state}
            {...{ ...props, variant, wide, small }}
        >
            {children}
        </Button>
    );
};

export default connect(
    (state: AppState) => ({
        returnTo: state.uiState.returnTo,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setReturnTo,
            },
            dispatch
        )
)(ReturningLinkButton);
