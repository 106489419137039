import { memo } from 'react';
import c from 'classnames';

interface InitialBadgeProps {
    initial: string;
    inactive?: boolean;
    className?: string;
}

const InitialBadge = memo(({ initial, inactive = false, className }: InitialBadgeProps) => (
    <span
        className={c(
            'text-fm-blue font-bold border-solid border border-white rounded-full w-10 h-10 flex items-center justify-center',
            inactive ? 'bg-gray-350' : 'bg-initial-badge',
            className
        )}
    >
        {initial}
    </span>
));

export default InitialBadge;
