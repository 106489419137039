import { useTranslation } from 'react-i18next';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import type FeatureType from 'features/FeatureType';
import { Form } from 'informed';
import { FieldColours } from 'lib/colours';
import ErrorMessage from 'view/ErrorMessage';
import ColourSelectorField from 'view/form/ColourSelectorField';
import TextInputFormField from 'view/form/TextInputFormField';
import { required } from 'view/form/validations';

interface FeatureTypeFormProps {
    // From parent
    onCancel: VoidFunction;
    onSave: (name: string, colour: string) => Promise<void>;
    featureType?: FeatureType;
}

interface FormValues {
    name: string;
    colour: string;
}

const FeatureTypeForm = ({ featureType, onCancel, onSave }: FeatureTypeFormProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise();

    const validateName = required(t('missing_name'));
    const validateColour = required(t('colour_validation'));

    const handleSubmit = (values: FormValues) => {
        setPromise(onSave(values.name, values.colour));
    };

    const initialValues = featureType
        ? { name: featureType.name, colour: featureType.colour }
        : { name: '', colour: '' };
    return (
        <Form<FormValues>
            onSubmit={handleSubmit}
            className="FeatureTypeForm stacked-form"
            initialValues={initialValues}
        >
            {({ formState }) => (
                <>
                    <TextInputFormField
                        field="name"
                        label={t('name')}
                        validate={validateName}
                        error={formState.errors ? formState.errors.name : null}
                    />
                    <ColourSelectorField
                        field="colour"
                        label={t('colour')}
                        validate={validateColour}
                        colours={FieldColours}
                    />
                    {error && <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>}

                    <div className="pb-5 flex justify-between">
                        <Button
                            className="flex-grow mr-4"
                            variant="danger-outline"
                            onClick={onCancel}
                        >
                            {t('cancel')}
                        </Button>
                        <SubmitButton disabled={pending} className="flex-grow">
                            {pending ? t('saving') : t('save')}
                        </SubmitButton>
                    </div>
                </>
            )}
        </Form>
    );
};

export default FeatureTypeForm;
