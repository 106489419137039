import { memo } from 'react';
import { dismissNotification } from 'chat/chat-state';
import { useAppDispatch, useAppSelector } from 'system/store';
import { toggleFarmChat } from 'system/ui-state';

import NewMessageNotification from './NewMessageNotification';
import UnreadNotification from './UnreadNotification';

import './FarmChatNotification.css';

const FarmChatNotification = memo(() => {
    const notification = useAppSelector((state) => state.chatState.notification);
    const dispatch = useAppDispatch();

    return notification !== undefined ? (
        <div
            className="FarmChatNotification"
            onClick={() => {
                dispatch(dismissNotification(notification));
                dispatch(toggleFarmChat());
            }}
        >
            {notification.type === 'newMessage' ? (
                <NewMessageNotification notification={notification} />
            ) : (
                <UnreadNotification notification={notification} />
            )}
        </div>
    ) : null;
});

export default FarmChatNotification;
