import { createSelector } from '@reduxjs/toolkit';
import type { FarmUserAndLocation } from 'farm-map/types';
import type { AppState } from 'system/store';
import { selectDisplayableFarmUsers } from 'team/farm-users-state';

export const selectFarmUsersAndLocations = createSelector(
    selectDisplayableFarmUsers,
    (state: AppState) => state.farmUsersState.locations ?? {},
    (farmUsers, locations) =>
        farmUsers
            .filter((fu) => locations[fu.id.toString()])
            .map<FarmUserAndLocation>((farmUser) => ({
                farmUser,
                location: locations[farmUser.id.toString()],
            }))
);
