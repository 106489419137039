import type { FormEventHandler, MouseEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePromise } from '@fieldmargin/webapp-state';
import { OperationRecord } from 'components/operation-record';
import type { Set } from 'immutable';
import type Input from 'inputs/Input';
import { inputHasNutrients, inputIsPesticide } from 'inputs/Input';
import { InputNutrientsList } from 'inputs/InputNutrients';
import { defaultToZero } from 'lib/fp-helpers';
import { prefersImperialUnits } from 'lib/MeasurementUnit';
import { capitaliseWord } from 'lib/util/text';
import type FullOperation from 'operations/FullOperation';
import type Recording from 'operations/Recording';
import EditableActions from 'sidebar/modules/common/EditableActions';
import { useAppSelector } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import { acresRateToHectaresRate } from 'utils/conversion';
import NumberInputField from 'view/form/hook/NumberInputField';

import type { OperationRecording } from './operation-recording-utils';
import {
    calculateRateFromAreaAndTotal,
    calculateTotalFromRateAndArea,
} from './operation-recording-utils';
import OperationRecordingCardFertiliserInfo from './OperationRecordingCardFertiliserInfo';
import OperationRecordingCardSprayInfo from './OperationRecordingCardSprayInfo';

interface WrapperProps {
    fullOperation: FullOperation;
    operationRecording: OperationRecording;
    rate: number;
    total: number;
    area: number;
    toggleEditing: VoidFunction;
    onSave: (recordings: Set<Recording>) => Promise<void>;
}

interface FormValues {
    rate: number;
    total: number;
}

const Wrapper = ({
    fullOperation,
    operationRecording,
    rate,
    total,
    area,
    toggleEditing,
    onSave,
}: WrapperProps) => {
    const { input } = operationRecording;
    const { pending, error, setPromise } = usePromise(toggleEditing);
    const areaUnit = useAppSelector(selectUserAreaMeasurementUnit);

    const methods = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            rate,
            total,
        },
    });

    const handleClick = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        if (target.tagName !== 'INPUT') toggleEditing();
    };

    const handleRateChange = (value: number) => {
        const rateNumber = defaultToZero(value);
        methods.setValue('total', calculateTotalFromRateAndArea(rateNumber, area));
    };

    const handleTotalChange = (value: number) => {
        const totalNumber = defaultToZero(value);
        methods.setValue('rate', calculateRateFromAreaAndTotal(area, totalNumber));
    };

    const handleSubmit = (data: FormValues) => {
        if (fullOperation.recordings === null) {
            setPromise(Promise.resolve());
            return;
        }

        const updatedRecordings = fullOperation.recordings.map((recording) => {
            if (recording.uuid !== operationRecording.recording.uuid) {
                return recording;
            }

            const formRate = defaultToZero(data.rate);
            const rate = prefersImperialUnits(areaUnit)
                ? acresRateToHectaresRate(formRate)
                : formRate;
            return recording.set('rate', rate);
        });

        setPromise(onSave(updatedRecordings));
    };

    return (
        <FormProvider {...methods}>
            <OperationRecordingCardForm
                onSubmit={methods.handleSubmit(handleSubmit)}
                input={input}
                rate={rate}
                area={area}
                toggleEditing={toggleEditing}
                onSummaryClick={handleClick}
                onRateChange={handleRateChange}
                onTotalChange={handleTotalChange}
                pending={pending}
                error={error}
            />
        </FormProvider>
    );
};

interface OperationRecordingCardFormProps {
    onSubmit: FormEventHandler;
    input: Input;
    rate: number;
    area: number;
    toggleEditing: VoidFunction;
    onSummaryClick: SingleParamVoidFunction<MouseEvent>;
    onRateChange: SingleParamVoidFunction<number>;
    onTotalChange: SingleParamVoidFunction<number>;
    pending: boolean;
    error: boolean;
}

const OperationRecordingCardForm = ({
    onSubmit,
    input,
    rate,
    area,
    toggleEditing,
    onSummaryClick,
    onRateChange,
    onTotalChange,
    pending,
    error,
}: OperationRecordingCardFormProps) => {
    return (
        <form onSubmit={onSubmit} className="flex gap-2 w-full">
            <OperationRecord.Details open={true}>
                <OperationRecord.Summary onClick={onSummaryClick}>
                    <OperationRecord.SummaryName>
                        <h4 className="mb-1 truncate">{input.name}</h4>
                        <InputNutrientsList as="p" input={input} />
                    </OperationRecord.SummaryName>

                    <OperationRecord.SummaryType>
                        {capitaliseWord(input.type)}
                    </OperationRecord.SummaryType>

                    <OperationRecord.SummaryRate>
                        <NumberInputField field="rate" onChange={onRateChange} />
                    </OperationRecord.SummaryRate>

                    <OperationRecord.SummaryTotal>
                        <NumberInputField field="total" onChange={onTotalChange} />
                    </OperationRecord.SummaryTotal>

                    <OperationRecord.SummaryChevron />
                </OperationRecord.Summary>

                <div>
                    {inputIsPesticide(input) && <OperationRecordingCardSprayInfo input={input} />}
                    {inputHasNutrients(input) && (
                        <OperationRecordingCardFertiliserInfo
                            input={input}
                            rate={rate}
                            area={area}
                        />
                    )}
                    <EditableActions disabled={pending} error={error} setEditing={toggleEditing} />
                </div>
            </OperationRecord.Details>
        </form>
    );
};

export default Wrapper;
