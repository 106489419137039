// A transitory object to be used when creating and editing sub fields only.

import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import Field from 'fields/Field';
import { getNextSubFieldColour } from 'fields/sub-field-colours';
import { copyFieldForSubField } from 'fields/sub-field-utils';
import { Record } from 'immutable';
import { DEFAULT_COLOUR } from 'lib/colours';
import { uniqueId } from 'lodash';

class SubField extends Record({
    id: '',
    field: new Field(),
    colour: DEFAULT_COLOUR,
}) {}

let subFieldId = 0;

export const resetSubFieldId = (value?: number) => (subFieldId = value !== undefined ? value : 0);

export const convertFieldToSubField = (field: Field) => {
    if (!field.parentUuid) {
        throw new Error('Attempting to convert field that has no parent');
    }
    return new SubField({
        id: uniqueId('sub-field-'),
        field: field,
        colour: getNextSubFieldColour(),
    });
};

export const createSubField = (field: Field, year: number) => {
    const id = uniqueId('sub-field-');
    return new SubField({
        id,
        field: copyFieldForSubField(field, (++subFieldId).toString(), year),
        colour: getNextSubFieldColour(),
    });
};

export const createEmptySubField = (
    parentUuid: string,
    year: number,
    fieldBoundary?: GeoFeatureCollection
) => {
    const id = uniqueId('sub-field-');
    return new SubField({
        id,
        field: new Field({
            name: (++subFieldId).toString(),
            geoJson: fieldBoundary !== undefined ? fieldBoundary : undefined,
            year,
            parentUuid,
        }),
        colour: getNextSubFieldColour(),
    });
};

export const updateSubFieldField = (subField: SubField) => (field: Field) =>
    subField.set('field', field);

export default SubField;
