export interface NutrientReportDTO {
    fieldUsage: string;
    fieldNutrient: FieldNutrientDTO[];
}

export interface NutrientReport {
    fieldUsage: string;
    fieldNutrient: FieldNutrient[];
}

export const deserializeNutrientReport = (dto: NutrientReportDTO): NutrientReport => ({
    fieldUsage: dto.fieldUsage,
    fieldNutrient: dto.fieldNutrient.map(deserializeFieldNutrient),
});

export type UnitValues = { [k: string]: number };

type NutrientUnitValues = {
    nitrogen?: UnitValues;
    phosphorus?: UnitValues;
    potassium?: UnitValues;
    magnesium?: UnitValues;
    sulphur?: UnitValues;
    sodium?: UnitValues;
    calcium?: UnitValues;
    boron?: UnitValues;
    manganese?: UnitValues;
    zinc?: UnitValues;
    iron?: UnitValues;
    selenium?: UnitValues;
    molybdenum?: UnitValues;
    copper?: UnitValues;
};

export interface FieldNutrientDTO {
    fieldName: string;
    area: number;
    sumrate: NutrientUnitValues;
    sumtotal: NutrientUnitValues;
    operationNutrient: OperationNutrientDTO[];
}

export interface FieldNutrient {
    fieldName: string;
    area: number;
    rates: NutrientUnitValues;
    totals: NutrientUnitValues;
    operationNutrient: OperationNutrient[];
}

const deserializeFieldNutrient = (dto: FieldNutrientDTO): FieldNutrient => ({
    fieldName: dto.fieldName,
    area: dto.area,
    rates: dto.sumrate,
    totals: dto.sumtotal,
    operationNutrient: dto.operationNutrient.map(deserializeOperationNutrient),
});

export interface OperationNutrientDTO {
    uuid: string;
    operationTitle: string;
    workArea: number;
    rate: NutrientUnitValues;
    total: NutrientUnitValues;
}

export interface OperationNutrient {
    uuid: string;
    operationTitle: string;
    workArea: number;
    rates: NutrientUnitValues;
    totals: NutrientUnitValues;
}

const deserializeOperationNutrient = (dto: OperationNutrientDTO): OperationNutrient => ({
    uuid: dto.uuid,
    operationTitle: dto.operationTitle,
    workArea: dto.workArea,
    rates: dto.rate,
    totals: dto.total,
});
