import { useTranslation } from 'react-i18next';
import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { calcLength } from '@fieldmargin/webapp-geo';
import type { SingleParamVoidFunction } from 'system/types';
import FormattedLine from 'view/units/FormattedLine';

import ShapeBubble from './ShapeBubble';

interface LineShapeBubbleProps {
    index?: number;
    geoFeature: GeoFeature;
    onHoverGeoFeature?: SingleParamVoidFunction<string | number | null>;
    onRemove?: SingleParamVoidFunction<string | number>;
}

const LineShapeBubble = ({
    index,
    geoFeature,
    onHoverGeoFeature,
    onRemove,
}: LineShapeBubbleProps) => {
    const { t } = useTranslation();
    const label = index ? `${t('title_description_line')} ${index}` : t('preferences_length');
    return (
        <ShapeBubble
            geoFeature={geoFeature}
            label={
                <>
                    {label}:{' '}
                    <FormattedLine lengthMetres={calcLength(geoFeature.geometry)} withUnit />
                </>
            }
            onHoverGeoFeature={onHoverGeoFeature}
            onRemove={onRemove}
        />
    );
};

export default LineShapeBubble;
