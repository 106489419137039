import { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { Extent, GeoFeature } from '@fieldmargin/webapp-geo';
import { extentFromGeo } from '@fieldmargin/webapp-geo';
import { useToggle } from '@fieldmargin/webapp-state';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import type { List } from 'immutable';
import { preventDefaultAnd } from 'lib/dom';
import { filterObjectByKey } from 'lib/fp-helpers';
import type { SingleParamVoidFunction } from 'system/types';
import ErrorMessage from 'view/ErrorMessage';
import ZoomIcon from 'view/svg/ZoomIcon';

import RPANameWarningModal from './RPANameWarningModal';

import './RPAImportForm.css';

interface RPAImportFormProps {
    fields: List<GeoFeature>;
    selected: List<string>;
    ignored: List<string>;
    highlighted?: string;
    createPending: boolean;
    onSubmit: SingleParamVoidFunction<RPAImportFormValues>;
    onHighlight: (id?: string) => void;
    onChange: (id: string, label: string) => void;
    onIgnore: SingleParamVoidFunction<string>;
    onZoomClick: SingleParamVoidFunction<Extent | undefined>;
}

export interface RPAImportFormValues {
    fields: Record<
        string,
        {
            name: string;
            id: string;
        }
    >;
}

const RPAImportForm = ({
    fields,
    selected,
    ignored,
    highlighted,
    createPending,
    onSubmit,
    onHighlight,
    onChange,
    onIgnore,
    onZoomClick,
}: RPAImportFormProps) => {
    const { t } = useTranslation();
    const [showNameModal, toggleNameModal] = useToggle(false);

    const {
        handleSubmit,
        register,
        formState: { errors },
        getValues,
        setFocus,
    } = useForm<RPAImportFormValues>({
        mode: 'onBlur',
        defaultValues: {
            fields: fields.reduce((values, field) => {
                values[field.id] = {
                    name: '',
                    id: field.properties.get('label'),
                };
                return values;
            }, {}),
        },
    });

    useEffect(() => {
        if (highlighted !== undefined) {
            setFocus(`fields.${highlighted}.name`);
        }
    }, [highlighted]);

    const handleFormSubmit = (values: RPAImportFormValues) => {
        const hasFieldsWithNoNames = Object.values(
            filterObjectByKey((fieldUuid) => !ignored.includes(fieldUuid), values.fields)
        ).some(({ name }) => name === '');
        if (hasFieldsWithNoNames) {
            toggleNameModal();
        } else {
            onSubmit(values);
        }
    };

    const handleNameModalConfirm = () => {
        onSubmit(getValues());
    };

    const handleZoomClick = (geoFeature: GeoFeature) => {
        onZoomClick(extentFromGeo(geoFeature) ?? undefined);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)} className="RPAImportForm">
            {showNameModal && (
                <RPANameWarningModal
                    onClose={toggleNameModal}
                    onConfirm={handleNameModalConfirm}
                    pending={createPending}
                />
            )}
            <SubmitButton
                disabled={createPending || selected.size === 0}
                className="RPAImportFormSubmit"
            >
                {createPending ? t('creating_fields') : t('create_fields')}
            </SubmitButton>
            <div className="RPAImportFormFields">
                <strong className="col-start-2">{t('layers_fields_titles')}</strong>
                <strong className="col-span-2">{t('field_ids')}</strong>
                {fields.map((field) => (
                    <Fragment key={field.id}>
                        <button
                            className="bare-btn focus mt-2.5"
                            onClick={preventDefaultAnd(() => handleZoomClick(field))}
                        >
                            <ZoomIcon className="fill-fm-sky" />
                        </button>
                        <div>
                            <input
                                className="simple-text-input w-full"
                                type="text"
                                disabled={ignored.includes(field.id.toString())}
                                onFocus={() => onHighlight(field.id.toString())}
                                {...register(`fields.${field.id.toString()}.name`, {
                                    // Name field is required if the matching ID field is empty.
                                    required: getValues().fields[field.id.toString()].id === '',
                                    onBlur: onHighlight,
                                    onChange: (e) => onChange(field.id.toString(), e.target.value),
                                })}
                                autoComplete="off"
                            />
                            {errors.fields?.[field.id]?.name && (
                                <ErrorMessage>{t('register_required')}</ErrorMessage>
                            )}
                        </div>
                        <input
                            className="simple-text-input w-full"
                            type="text"
                            disabled={ignored.includes(field.id.toString())}
                            {...register(`fields.${field.id}.id`)}
                        />
                        <Checkbox
                            id={field.id.toString()}
                            checked={selected.includes(field.id.toString())}
                            onChange={() => onIgnore(field.id.toString())}
                            className="mt-2.5"
                        />
                    </Fragment>
                ))}
            </div>
        </form>
    );
};

export default RPAImportForm;
