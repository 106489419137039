import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';

import './operation-table.css';

interface OperationFieldsTableProps {
    children: ReactNode;
    editing?: boolean;
    className?: string;
}

const OperationFieldsTable = ({ editing, children, className }: OperationFieldsTableProps) => {
    const { t } = useTranslation();
    return (
        <table className={c('OperationTable', { editing }, className)}>
            {editing && (
                <thead>
                    <tr className="spaced">
                        <th className="field-name">{t('name')}</th>
                        <th className="field-area">{t('field_area')}</th>
                        <th className="work-area">{t('output_rate_area')}</th>
                        <th />
                    </tr>
                </thead>
            )}
            <tbody>{children}</tbody>
        </table>
    );
};

export default OperationFieldsTable;
