import type { ReactNode } from 'react';
import type { GeoFeature } from '@fieldmargin/webapp-geo';
import icon from 'components/icons/icon';
import BubbleTag from 'view/molecules/BubbleTag';

interface ShapeBubbleProps {
    geoFeature: GeoFeature;
    label: ReactNode;
    onHoverGeoFeature?: (id: string | number | null) => void;
    onRemove?: (id: string | number) => void;
}

const ShapeBubble = ({ geoFeature, label, onHoverGeoFeature, onRemove }: ShapeBubbleProps) => {
    const handleMouseEnterShape = (id: number | string) => {
        onHoverGeoFeature && onHoverGeoFeature(id);
    };

    const handleMouseLeaveShape = () => {
        onHoverGeoFeature && onHoverGeoFeature(null);
    };

    const remove = onRemove ? (
        <button className="bare-btn focus rounded ml-3" onClick={() => onRemove(geoFeature.id)}>
            {icon('edit-remove', 'red', undefined, { marginTop: 0 })}
        </button>
    ) : null;
    return (
        <BubbleTag
            key={geoFeature.id}
            onMouseEnter={() => handleMouseEnterShape(geoFeature.id)}
            onMouseLeave={handleMouseLeaveShape}
        >
            {label}
            {remove}
        </BubbleTag>
    );
};

export default ShapeBubble;
