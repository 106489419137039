import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import c from 'classnames';
import type Field from 'fields/Field';
import { getFieldName } from 'fields/Field';
import EditButton from 'sidebar/modules/common/EditButton';
import SidebarModule from 'sidebar/modules/SidebarModule';
import FarmLink from 'view/FarmLink';
import RememberingLinkButton from 'view/RememberingLinkButton';

import EditableFieldTitleForm from './EditableFieldTitleForm';

import '../Field.css';

interface EditableFieldTitleProps {
    farm: Farm;
    field: Field;
    parentField?: Field;
    onSave: (newTitle: string, newId: string) => Promise<any>;
}

const EditableFieldTitle = ({ farm, field, parentField, onSave }: EditableFieldTitleProps) => {
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);

    const displayName = getFieldName(field, parentField);

    if (editing) {
        return (
            <SidebarModule className="Title" editing={true}>
                <EditableFieldTitleForm field={field} onSave={onSave} setEditing={setEditing} />
            </SidebarModule>
        );
    }

    return (
        <SidebarModule className={c('Title', { archived: field.archived })}>
            <EditButton setEditing={setEditing} disabled={field.archived} />
            {parentField !== undefined && (
                <FieldBreadcrumb field={field} parentField={parentField} />
            )}
            <h2 className="flex items-center gap-2">
                <div className="flex flex-col gap-1">
                    <span>{displayName}</span>
                    {field.fieldId !== '' && <span className="text-sm">{field.fieldId}</span>}
                </div>
                {parentField !== undefined && (
                    <RememberingLinkButton
                        to={`/farms/${farm.uuid}/fields/${parentField.uuid}`}
                        variant="outline"
                        className="ml-auto"
                        small
                        disabled={field.archived}
                    >
                        {t('field_goto_parent')}
                    </RememberingLinkButton>
                )}
            </h2>
        </SidebarModule>
    );
};

const FieldBreadcrumb = ({ field, parentField }: { field: Field; parentField: Field }) => (
    <div className={c('mb-4', { archived: field.archived })}>
        <FarmLink
            to={`/fields/${parentField.uuid}`}
            className={c('underline', { archived: field.archived })}
        >
            {parentField.name}
        </FarmLink>{' '}
        &gt; {getFieldName(field, parentField)}
    </div>
);

export default EditableFieldTitle;
