import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { GeoPolygon } from '@fieldmargin/webapp-geo';
import { deserialize, GeoFeatureCollection, Projection, reproject } from '@fieldmargin/webapp-geo';
import type { FeatureCollection } from '@turf/helpers';
import type { HerdLocation, HerdLocationDTO } from 'herd/Herd';
import { deserializeHerdLocation } from 'herd/Herd';
import { List } from 'immutable';
import { arrayToMapWithUuid, mapMap, possibleApiRequest } from 'lib/fp-helpers';
import { reducePolygonPrecision } from 'lib/geo/geometry';
import { curry } from 'lodash';
import type { NoteDTO } from 'notes/Note';
import type Note from 'notes/Note';
import { deserializeNote } from 'notes/Note';
import { always } from 'ramda';

import type { FieldDTO, WriteFieldProps, WriteSubFieldProps } from './Field';
import type Field from './Field';
import {
    convertWriteFieldPropsToField,
    deserializeField,
    serializeWriteFieldProps,
    serializeWriteSubFieldProps,
} from './Field';

/**
 * Get all fields for a farm
 * Sub-fields are returned as fields in this list and not as a property of the
 * parent field.
 */
export const getFields = (farmUuid: string) =>
    authorizedRequest<FieldDTO[]>({
        url: `/notes-api/farms/${farmUuid}/fields/`,
    }).then((fields) => List(fields.map(deserializeField)));

/**
 * Create a field on the server
 */
export const createField = (farmUuid: string, props: WriteFieldProps) =>
    authorizedRequest<string>({
        url: `/notes-api/farms/${farmUuid}/fields/`,
        method: 'POST',
        data: serializeWriteFieldProps(props),
    }).then((uuid) => convertWriteFieldPropsToField(uuid, props));

/**
 * Create fields in bulk
 */
export const createFields = (farmUuid: string, fieldProps: WriteFieldProps[]) =>
    authorizedRequest<string[]>({
        url: `/notes-api/farms/${farmUuid}/fields/bulkCreate`,
        method: 'POST',
        data: fieldProps.map(serializeWriteFieldProps),
    }).then((uuids) =>
        uuids.map((fieldUuid, index) => convertWriteFieldPropsToField(fieldUuid, fieldProps[index]))
    );

/**
 * Update field data on the server. Not all data has to be sent in this request.
 */
export const updateField = (farmUuid: string, fieldUuid: string, props: Partial<WriteFieldProps>) =>
    possibleApiRequest<Partial<WriteFieldProps>>(props, () =>
        authorizedRequest({
            url: `/notes-api/farms/${farmUuid}/fields/${fieldUuid}/`,
            method: 'PUT',
            data: serializeWriteFieldProps(props),
        }).then(always(props))
    );

/**
 * Delete a field on the server.
 */
export const deleteField = (farmUuid: string, field: Field) => {
    return authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/fields/${field.uuid}/`,
        method: 'DELETE',
    }).then(() => field);
};

/**
 * Toggle a field as archived on the server.
 */
export const toggleFieldArchived = (farmUuid: string, fieldUuid: string, archived: boolean) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/fields/${fieldUuid}/archive`,
        method: 'PUT',
        params: { archived },
    });

/**
 * Create a sub field on the server. This is different than creating a field because the data
 * includes a parent field uuid and a year.
 * This resolves with the UUID of the newly create sub field.
 */
export const createSubField = (farmUuid: string, props: WriteSubFieldProps) =>
    authorizedRequest<string>({
        url: `/notes-api/farms/${farmUuid}/fields/subField`,
        method: 'POST',
        data: serializeWriteSubFieldProps(props),
    });

/**
 * Update a sub field on the server. This is different than creating a field because the data
 * includes a parent field uuid and a year.
 * This resolves with the UUID of the newly create sub field.
 */
export const updateSubField = (
    farmUuid: string,
    fieldUuid: string,
    props: Partial<WriteSubFieldProps>
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/fields/subField/${fieldUuid}`,
        method: 'PUT',
        data: serializeWriteSubFieldProps(props),
    }).then(always(props));

/**
 * Makes requests for notes for all the given fields.
 */
export const fetchFieldsNotes = async (farmUuid: string, year: number, fieldUuids: string[]) => {
    try {
        const all = await Promise.all(
            fieldUuids.map((fieldUuid) => fetchFieldNotes(farmUuid, year, fieldUuid))
        );
        const merged = all.reduce((map, notes) => new Map([...map, ...notes]));
        return merged;
    } catch (_e) {
        return new Map<string, Note>();
    }
};

/**
 * Fetch notes for a single field.
 */
export const fetchFieldNotes = (
    farmUuid: string,
    year: number,
    fieldUuid: string
): Promise<Map<string, Note>> =>
    authorizedRequest<NoteDTO[]>({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/fields/${fieldUuid}`,
    }).then((notes) => mapMap(arrayToMapWithUuid(notes), deserializeNote));

/**
 * Makes requests for herd locations for all the given fields.
 */
export const fetchFieldsHerdLocations = async (
    farmUuid: string,
    year: number,
    fieldUuids: string[]
) => {
    const getFieldHerdLocation = curry(fetchFieldHerdLocations)(farmUuid)(year);
    try {
        const all = await Promise.all(fieldUuids.map(getFieldHerdLocation));
        return List(all).flatten();
    } catch (_e) {
        return List<HerdLocation>();
    }
};

/**
 * Makes a request for herd locations for a single field.
 */
export const fetchFieldHerdLocations = (farmUuid: string, year: number, fieldUuid: string) =>
    authorizedRequest<HerdLocationDTO[]>({
        url: `/notes-api/farms/${farmUuid}/years/${year}/fields/${fieldUuid}/herds/locations`,
    }).then((herdLocations) => List(herdLocations.map(deserializeHerdLocation)));

/**
 * Copy sub-fields and field usages from the source year to the destination year.
 */
export const copyYearFields = ({
    farmUuid,
    sourceYear,
    destinationYear,
    copySubFields,
    copyFieldUsages,
    copyWorkedArea,
}: {
    farmUuid: string;
    sourceYear: number;
    destinationYear: number;
    copySubFields: boolean;
    copyFieldUsages: boolean;
    copyWorkedArea: boolean;
}) =>
    authorizedRequest<void>({
        method: 'post',
        url: `/notes-api/farms/${farmUuid}/fields/copy`,
        data: {
            sourceYear,
            destinationYear,
            copyFieldUsages,
            copySubFields,
            copyWorkedArea,
        },
    })
        // We need to fetch fields again after copying because there is no response from
        // the server when copying.
        .then(() => getFields(farmUuid));

/**
 * Fetch the polygons that should be used as areas to show field health maps.
 */
export const fetchFieldHealthPolygons = (farmUuid: string) =>
    authorizedRequest<{ geoJson: FeatureCollection | null }>({
        url: `/notes-api/farms/${farmUuid}/fields/premium/vegetation`,
    })
        .then(({ geoJson }) =>
            geoJson !== null
                ? (deserialize(geoJson, Projection.LNG_LAT) as GeoFeatureCollection)
                : GeoFeatureCollection()
        )
        .then((featureCollection) => reproject(featureCollection, Projection.WEB_MERCATOR))
        .then((featureCollection) =>
            featureCollection.features.map((feature) => feature.geometry as GeoPolygon)
        )
        .then((polygons) => polygons.map((polygon) => reducePolygonPrecision(polygon, 4)));
