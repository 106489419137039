import type { RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';
import FullOperationLoader from 'operations/FullOperationLoader';
import { OperationDeletion } from 'operations/sidebar/deletion/OperationDeletion';
import EditableOperationDetails from 'operations/sidebar/details/EditableOperationDetails';
import { OperationComments } from 'operations/sidebar/details/OperationComments';
import { RenderOperation } from 'operations/sidebar/details/RenderOperation';
import EditOperationFields from 'operations/sidebar/edit-fields/EditOperationFields';
import OperationNew from 'operations/sidebar/new/OperationNew';
import ErrorMessage from 'view/ErrorMessage';

export const operationsRoutes: RouteObject = {
    path: APP_ROUTES.Operations.Root,

    children: [
        {
            path: APP_ROUTES.Operations.New,
            element: <OperationNew />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when creating a field job, please refresh the page.
                </ErrorMessage>
            ),
            children: [
                {
                    path: 'add-fields',
                    element: <OperationNew />,
                },
            ],
        },

        {
            path: APP_ROUTES.Operations.Operation,
            element: <FullOperationLoader />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing a field job, please refresh the page.
                </ErrorMessage>
            ),
            children: [
                {
                    path: '',
                    element: <EditableOperationDetails />,

                    children: [
                        {
                            index: true,
                            element: <RenderOperation />,
                        },
                        {
                            path: APP_ROUTES.Operations.OperationComments,
                            element: <OperationComments />,
                        },
                        {
                            path: APP_ROUTES.Operations.EditFields,
                            element: <EditOperationFields />,
                        },
                        {
                            path: APP_ROUTES.Operations.AddFields,
                        },
                        {
                            path: APP_ROUTES.Operations.AddRecordings,
                        },
                        {
                            path: APP_ROUTES.Operations.AddFuel,
                        },
                        {
                            path: APP_ROUTES.Operations.AddOutput,
                        },
                    ],
                },
                {
                    path: APP_ROUTES.Operations.Delete,
                    element: <OperationDeletion />,
                },
            ],
        },
    ],
};
