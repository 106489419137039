import { useTranslation } from 'react-i18next';
import type { GeoPosition } from '@fieldmargin/webapp-geo';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { useApplicationLayoutContext } from '@notes-web/layout';
import type { List } from 'immutable';
import { Form } from 'informed';
import TextInputFormField from 'view/form/TextInputFormField';
import { required } from 'view/form/validations';

import { VITE_APP_NAME } from '../../../env';

import type { Place } from './create-farm-api';
import CreateFarmLocationSearch from './CreateFarmLocationSearch';

import './CreateFarmForm.scss';

const validateRequired = required('Please enter a farm name');

interface CreateFarmFormProps {
    disabled: boolean;
    onSubmit: (values: CreateFarmFormValues) => void;
    onLocationSearch: (places: List<Place>) => void;
    onGeoLocationLookup: (geoPosition: GeoPosition) => void;
    farmLocationError: boolean;
    searchedPlaces?: List<Place>;
    selectedPlaceId?: string;
    createFarmError: boolean;
    initialValues?: { farmName: string };
}

export interface CreateFarmFormValues {
    name: string;
}

const CreateFarmForm = ({
    disabled,
    onSubmit,
    onLocationSearch,
    onGeoLocationLookup,
    farmLocationError,
    searchedPlaces,
    selectedPlaceId,
    createFarmError,
    initialValues,
}: CreateFarmFormProps) => {
    const { t } = useTranslation();
    const { CreateFarm } = useApplicationLayoutContext();

    return (
        <Form<CreateFarmFormValues> className="CreateFarmForm" onSubmit={onSubmit}>
            {({ formState }) => (
                <>
                    <h1>{t('create_farm')}</h1>
                    <p>
                        {t(`create_farm_desc_${VITE_APP_NAME}`, {
                            defaultValue:
                                'Once you have set up your farm location you will be able to start mapping your farm and recording work',
                        })}
                    </p>
                    <div className="name-field">
                        <TextInputFormField
                            field="name"
                            label={t('farm_name')}
                            validate={validateRequired}
                            validateOnBlur
                            validateOnChange
                            placeholder={t('farm_name')}
                            error={formState.errors.name}
                            initialValue={initialValues?.farmName}
                        />
                    </div>
                    <CreateFarmLocationSearch
                        onLocationSearch={onLocationSearch}
                        onGeoLocationLookup={onGeoLocationLookup}
                        searchedPlaces={searchedPlaces}
                        selectedPlaceId={selectedPlaceId}
                    />
                    {farmLocationError && (
                        <div className="field-error">
                            {t('create_farm_select_location', {
                                defaultValue: 'Please select your farms location',
                            })}
                        </div>
                    )}
                    {createFarmError && (
                        <div className="field-error">
                            {t('create_farm_error', {
                                defaultValue:
                                    'Something went wrong and we were unable to create your farm. Please try again or contact customer support.',
                            })}
                        </div>
                    )}
                    <SubmitButton className="create-btn mb-8" disabled={disabled}>
                        {t('create_farm')}
                    </SubmitButton>

                    {CreateFarm.TipBox && <CreateFarm.TipBox />}
                </>
            )}
        </Form>
    );
};

export default CreateFarmForm;
