import type { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import type { HerdType } from 'herd/Herd';
import type Herd from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import { notEquals } from 'lib/fp-helpers';
import { uniq } from 'ramda';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { SingleParamVoidFunction } from 'system/types';

import HerdSelectorHerd from './HerdSelectorHerd';

interface HerdSelectorHerdTypeHerdsProps {
    type: HerdType;
    herds: Herd[];
    unlockedHerds: string[];
    selectedHerds: string[];
    setSelectedHerds: SingleParamVoidFunction<string[]>;
}

const HerdSelectorHerdTypeHerds = ({
    type,
    herds,
    unlockedHerds,
    selectedHerds,
    setSelectedHerds,
}: HerdSelectorHerdTypeHerdsProps) => {
    const { t } = useTranslation();

    const herdUuids = herds.map((herd) => herd.uuid);

    const handleSelectHerd = (herdUuid: string) => {
        setSelectedHerds(
            selectedHerds.includes(herdUuid)
                ? selectedHerds.filter(notEquals(herdUuid))
                : [...selectedHerds, herdUuid]
        );
    };

    const handleSelectType = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedHerds(
            e.target.checked
                ? uniq(selectedHerds.concat(herds.map((herd) => herd.uuid)))
                : herds.reduce((s, herd) => s.filter(notEquals(herd.uuid)), selectedHerds)
        );
    };

    const typeIsSelected =
        selectedHerds.length > 0 && herdUuids.every((uuid) => selectedHerds.includes(uuid));

    const allHerdsUnlocked =
        unlockedHerds.filter((herdUuid) => herdUuids.includes(herdUuid)).length ===
        herdUuids.length;

    return (
        <>
            <SidebarModule editing className="flex justify-between cursor-pointer pl-0">
                <div className="pl-2 pr-1 relative">
                    <Checkbox
                        id={type}
                        checked={typeIsSelected}
                        onChange={handleSelectType}
                        disabled={!allHerdsUnlocked}
                    />
                </div>
                <strong className="flex-grow">
                    <span className="mr-2">{herdIcon(type)}</span>{' '}
                    {t(`livestock_type_${type.toLowerCase()}`)}
                </strong>
                <strong>{herds.reduce((total, herd) => total + herd.size, 0)}</strong>
            </SidebarModule>
            {herds.map((herd) => (
                <HerdSelectorHerd
                    key={herd.uuid}
                    herd={herd}
                    selected={selectedHerds.includes(herd.uuid)}
                    locked={!unlockedHerds.includes(herd.uuid)}
                    onChange={handleSelectHerd}
                />
            ))}
        </>
    );
};

export default HerdSelectorHerdTypeHerds;
