import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromise } from '@fieldmargin/webapp-state';
import CommentBox from 'chat/chatlog/CommentBox';
import { List } from 'immutable';
import { uniqueId } from 'lodash';
import MediaUploading from 'media/MediaUploading';
import SidebarError from 'sidebar/SidebarError';

interface ActivityLogCommentBoxProps {
    addComment: (comment: string) => PromiseLike<any>;
    // Promise resolves with filename of media
    uploadFile: (file: any, progressCb: (progress: number) => void) => Promise<string>;
}

const ActivityLogCommentBox = ({ addComment, uploadFile }: ActivityLogCommentBoxProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise();
    const [filesUploading, setFilesUploading] = useState(List<MediaUploading>());
    const [uploadError, setUploadError] = useState(false);
    const latestFilesUploading = useRef(List<MediaUploading>());

    const handleAddComment = (comment: string) => setPromise(addComment(comment));

    const handleUploadFile = (file: File) => {
        const model = new MediaUploading({
            id: uniqueId('file-upload-'),
            name: file.name,
        });
        const nextFilesUploading = latestFilesUploading.current.push(model);
        latestFilesUploading.current = nextFilesUploading;
        uploadFile(file, (progress: number) => {
            const nextFilesUploading = latestFilesUploading.current.map((f) => {
                if (f.name === file.name) {
                    return f.set('progress', progress);
                }
                return f;
            });
            setFilesUploading(nextFilesUploading);
            latestFilesUploading.current = nextFilesUploading;
        })
            .then((filename) => {
                const nextFilesUploading = latestFilesUploading.current.filter(
                    (f) => f.name !== filename
                );
                setFilesUploading(nextFilesUploading);
                latestFilesUploading.current = nextFilesUploading;
            })
            .catch(() => {
                setUploadError(true);
            });
    };

    return (
        <>
            {uploadError && (
                <SidebarError
                    title={t('activity_log_failed_to_send')}
                    message={t('something_went_wrong')}
                />
            )}
            {error && (
                <SidebarError
                    title={t('activity_log_failed_to_send')}
                    message={t('something_went_wrong')}
                />
            )}
            <CommentBox
                onAddComment={handleAddComment}
                addCommentPending={pending}
                addCommentError={false}
                filesUploading={filesUploading}
                onUploadFile={handleUploadFile}
            />
        </>
    );
};

export default ActivityLogCommentBox;
