import { useCurrentFarm } from './useCurrentFarm';
import { useFarmInputs } from './useFarmInputs';

const useInputUpgradeLimit = () => {
    const { currentFarm } = useCurrentFarm();
    const { inputs } = useFarmInputs();

    const remainingCount = (currentFarm.plan.inputLimit ?? 0) - inputs.size;
    const isInputLimitHit = remainingCount < 1;

    return {
        remainingCount,
        isInputLimitHit,
    };
};

export { useInputUpgradeLimit };
