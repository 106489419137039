import type { RecordOf } from 'immutable';
import { List, Map, Set } from 'immutable';
import { uniqBy, when } from 'ramda';
import { hasOwnProperty } from 'utils/hasOwnProperty';

import { stringContains } from './util/text';
import { emptyOrIncludes, notNil, uuidMatches } from './fp-helpers';

/**
 * Apply fn to all items in the list with the given uuid.
 * @param items  list of items with a `uuid` property
 * @param compareFn  function that returns true if list item matches, false otherwise
 * @param fn  item => item
 * @return updated list
 */
export const updateInList = <T>(
    items: List<T>,
    compareFn: (a: T) => boolean,
    fn: (a: T) => T
): List<T> => {
    if (!items) {
        return items;
    }
    return items.map((item) => {
        if (compareFn(item)) {
            return fn(item);
        }
        return item;
    });
};

/**
 * Replace item in a list if one with the same uuid already exists, or push it to the end.
 * @param items  list of items
 * @param newItem  item to replace or add into the list
 * @param compareFn  function that returns true if list item matches, false otherwise
 * @return updated list
 */
export const replaceOrAdd = <T>(
    items: List<T>,
    newItem: T,
    compareFn: (a: T) => boolean
): List<T> => {
    if (!items) {
        return items;
    }
    const existingIndex = items.findIndex(compareFn);
    return existingIndex >= 0 ? items.set(existingIndex, newItem) : items.push(newItem);
};

interface VersionedItem {
    uuid: string;
    version: number;
}

/**
 * Like `replaceOrAdd` but modification only takes place if the newItem has the same or higher
 * version property than the existing item. Missing items will always be added.
 */
export const replaceOrAddVersioned = (
    items: List<VersionedItem>,
    newItem: VersionedItem
): List<VersionedItem> => {
    if (!items) {
        return items;
    }

    const entry = items.findEntry(uuidMatches(newItem.uuid));
    return entry === undefined
        ? items.push(newItem)
        : entry[1].version > newItem.version
          ? items
          : items.set(entry[0], newItem);
};

/**
 * Converts a list to a map. The key for each entry is the result of calling `fn` on the item.
 */
export const listToMap = <K, V>(list: List<V>, fn: (v: V) => K): Map<K, V> => {
    return Map(list.map((i) => [fn(i), i]));
};

/**
 * Remove duplicate items from a list. Uniqueness is based on the result of calling `fn` on each item.
 * When duplicates are found, the first in the list is kept.
 */
export const dedupeList = <K, V>(list: List<V>, fn: (v: V) => K): List<V> =>
    List(uniqBy(fn, list.toArray()));

/**
 * Same as dedupeList but when duplicates are found the last item in the list is kept.
 */
export const dedupeListRight = <K, V>(list: List<V>, fn: (v: V) => K): List<V> =>
    List(uniqBy(fn, list.toArray().reverse())).reverse();

export const addOrRemoveInSet = <T>(set: Set<T>, item: T) =>
    set.contains(item) ? set.remove(item) : set.add(item);

export const addOrRemoveInArray = <Item>(list: Item[], item: Item) =>
    list.includes(item) ? list.filter((i) => i !== item) : list.concat(item);

export const searchList = <Item>(
    list: List<Item>,
    getField: (item: Item) => string,
    searchTerm: string
) => list.filter((item) => stringContains(getField(item), searchTerm));

/**
 * Transform any data in item using the given transform function in the map param.
 * E.g. mapItemData({uuid: '123'}, {uuid: value => 'foo'}) ===> {uuid: 'foo'}
 */
export const mapItemData = <Item extends object>(
    item: Item,
    map: { [k: string]: (value: any) => any }
) =>
    Object.keys(item).reduce((newObj, key) => {
        if (hasOwnProperty(map, key)) {
            newObj[key] = map[key](item[key]);
        } else {
            newObj[key] = item[key];
        }
        return newObj;
    }, {} as Item);

export const mergeRecord =
    <Item extends RecordOf<any>>(item: Item, propMap: { [k: string]: (value: any) => any } = {}) =>
    (partial: Partial<Item>) =>
        item.merge(mapItemData(partial, propMap));

export const setEmptyOrIncludes = (set: Set<string>, value: string) =>
    emptyOrIncludes(set.toArray(), value);

export const setOf = <T>(value: T) => Set.of(value);

/**
 * Returns a Set of the given value if it is not nil, otherwise returns undefined.
 * We have to define return type correctly because the typings for `when` state that the return
 * value could be the input type which in this case is not true.
 */
export const maybeSetOf = when(notNil, setOf) as <T>(value?: T) => Set<T> | undefined;

export const arrayToList = <T>(arr: T[]) => List(arr);
export const listToArray = <T>(list: List<T>) => list.toArray();
export const listIsEmpty = <T>(list: List<T>) => list.size === 0;

export const immutableMapToNativeMap = <Key, Value>(
    map: Map<Key, Value>
): globalThis.Map<Key, Value> => new globalThis.Map(map.toArray());
