import { useParams } from 'react-router-dom';
interface UrlParamsKeys extends Record<string, string | undefined> {
    operationUuid?: string;
    herdUuid?: string;
    noteUuid?: string;
    fieldUuid?: string;
    farmUuid?: string;
    featureUuid?: string;
    sensorId?: string;
    organisationUuid?: string;
    mapGroupUuid?: string;
    manualSensorId?: string;
}

const usePageParams = () => {
    return useParams<UrlParamsKeys>();
};

export { usePageParams };
