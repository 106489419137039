import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { List } from 'immutable';

import { styleIgnoredRpaFeature, styleRpaFeature } from './rpa-utils';
import type { RPAState } from './rps-state';

export const selectedSelectedRpaFeatures = (state: RPAState) =>
    state.features && state.selected.size > 0
        ? state.features.filter((geoFeature) => state.selected.contains(geoFeature.id.toString()))
        : List<GeoFeature>();

export const selectSelectedRpaFeaturesWithStyles = (state: RPAState) =>
    selectedSelectedRpaFeatures(state).map((geoFeature) =>
        styleRpaFeature(geoFeature, state.highlighted === geoFeature.id.toString())
    );

export const selectedIgnoredRpaFeatures = (state: RPAState) =>
    state.features && state.ignored.size > 0
        ? state.features.filter((geoFeature) => state.ignored.contains(geoFeature.id.toString()))
        : List<GeoFeature>();

export const selectIgnoredRpaFeaturesWithStyles = (state: RPAState) =>
    selectedIgnoredRpaFeatures(state).map(styleIgnoredRpaFeature);

export const selectedFailedRpaFeatures = (state: RPAState) =>
    state.features && state.failed.size > 0
        ? state.features.filter((geoFeature) => state.failed.contains(geoFeature.id.toString()))
        : List<GeoFeature>();
