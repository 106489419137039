import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';

import styles from './SizeBubble.module.css';

interface SizeBubbleProps extends PropsWithChildren {
    total?: boolean;
    className?: string;
}

const SizeBubble = ({ children, total, className }: SizeBubbleProps) => {
    const { t } = useTranslation();
    if (!children) {
        return null;
    }

    return (
        <span className={clsx(styles.sizeBubble, { [styles.total]: total }, className)}>
            {total && `${t('total')} `}
            {children}
        </span>
    );
};

export default SizeBubble;
