import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import Modal from 'modal/Modal';

import { useOnboarding } from './onboarding-state';

interface OnboardingStartProps {
    onSkip: VoidFunction;
}

const OnboardingStart = ({ onSkip }: OnboardingStartProps) => {
    const { t } = useTranslation();
    const { nextOnboarding } = useOnboarding();

    return (
        <Modal onClose={onSkip}>
            <div className="bg-white rounded p-6 text-center" style={{ width: 750 }}>
                <h2>{t('onboarding_title', { defaultValue: 'Getting started' })}</h2>
                <div className="flex justify-evenly mb-6 text-left">
                    <div>
                        <p>
                            {t('onboarding_desc', {
                                defaultValue:
                                    'Now you have made your farm we will take you through some of the things you can do in fieldmargin.',
                            })}
                        </p>
                        <ul className="text-fm-blue">
                            <li>
                                {t('onboarding_desc_mapping', { defaultValue: 'Mapping fields' })}
                            </li>
                            <li>
                                {t('onboarding_desc_field_usages', {
                                    defaultValue: 'Recording field usages/crops',
                                })}
                            </li>
                            <li>
                                {t('onboading_desc_jobs', { defaultValue: 'Recording field work' })}
                            </li>
                            <li>
                                {t('onboarding_desc_inputs', { defaultValue: 'Adding inputs' })}
                            </li>
                        </ul>
                        <p>
                            {t('onboading_desc_skip', {
                                defaultValue:
                                    'You can skip this at any time, you can see the steps again by click on "Tutorials" at the bottom of the menu on the left.',
                            })}
                        </p>
                    </div>
                </div>
                <Button onClick={() => nextOnboarding('fields')}>{t('next')}</Button>
            </div>
        </Modal>
    );
};

export default OnboardingStart;
