import type { MouseEvent } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import icon from 'components/icons/icon';
import { addHours, getDate, getMonth, getYear, setDate, setMonth, setYear } from 'date-fns';
import { useDateFormat } from 'hooks/useDateFormat';
import type { SingleParamVoidFunction } from 'system/types';

import DateButton from './DateButton';

import 'react-calendar/dist/Calendar.css';
import 'view/react-calendar.css';

interface DatePickerProps {
    value: Date | null;
    onChange: (newValue: Date) => void;
    open: boolean;
    openDirection: 'top' | 'bottom';
    onOpen: SingleParamVoidFunction<boolean>;
    maxDate?: Date;
    disabled?: boolean;
    classWithDate?: string;
    withoutIcon?: boolean;
    dateFormat?: string;
}

const DatePicker = ({
    value,
    onChange,
    open,
    openDirection,
    onOpen,
    maxDate,
    disabled,
    classWithDate,
    withoutIcon = false,
    dateFormat = 'do MMM yyyy',
}: DatePickerProps) => {
    const { t, i18n } = useTranslation();
    const { format } = useDateFormat();
    let label = t('set_date');
    if (value) {
        label = format(value, dateFormat);
    }

    const handleDateSelect = (date: Date) => {
        if (value) {
            let updated = value;
            updated = setYear(updated, getYear(date));
            updated = setMonth(updated, getMonth(date));
            updated = setDate(updated, getDate(date));
            onChange(updated);
        } else {
            onChange(addHours(date, 12));
        }
    };

    const handleOpen = (e?: MouseEvent) => {
        e && e.preventDefault();
        onOpen(!open);
    };

    return (
        <div className="DatePicker relative">
            <DateButton
                onClick={handleOpen}
                disabled={disabled}
                className={value ? classWithDate : undefined}
            >
                {!withoutIcon && icon('date', 'dark-blue', 'mr-2')} {label}
            </DateButton>
            {open && (
                <div
                    className={c(
                        'absolute left-0 z-20',
                        openDirection === 'top' ? 'bottom-11' : 'top-11'
                    )}
                >
                    <Calendar
                        locale={i18n.language}
                        calendarType="iso8601"
                        onChange={handleDateSelect}
                        value={value || undefined}
                        maxDate={maxDate}
                        className="bordered h-64"
                    />
                </div>
            )}
        </div>
    );
};

export default DatePicker;
