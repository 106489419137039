import { useTranslation } from 'react-i18next';
import { AutoBoundaryCoverage } from 'fields/sidebar/auto-boundary/auto-boundary-api';

interface AutoDetectCoverageMessageProps {
    coverage?: AutoBoundaryCoverage;
}

const COVERAGE_MSG_MAP = {
    [AutoBoundaryCoverage.NONE]: 'map_drawingstatus_none',
    [AutoBoundaryCoverage.LITTLE]: 'Wmap_drawingstatus_little',
    [AutoBoundaryCoverage.SOME]: 'map_drawingstatus_some',
    [AutoBoundaryCoverage.PARTIAL]: 'map_drawingstatus_partial',
};

const AutoDetectCoverageMessage = ({ coverage }: AutoDetectCoverageMessageProps) => {
    const { t } = useTranslation();
    return coverage === AutoBoundaryCoverage.COMPLETE ? null : (
        <p className="mt-2">
            <em>
                {t(
                    coverage === undefined
                        ? 'field_boundary_auto_coverage_check'
                        : COVERAGE_MSG_MAP[coverage]
                )}
            </em>
        </p>
    );
};

export default AutoDetectCoverageMessage;
