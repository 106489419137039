import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { setMapView } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type { AppState } from 'system/store';
import { useAppDispatch } from 'system/store';

import BoundaryUploaderApp from './BoundaryUploaderApp';

const BoundaryUploaderContainer = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);
    const onClose = () => {
        dispatch(
            setMapView({
                type: 'full-extent',
            })
        );
        navigate(`/farms/${farm.uuid}/fields`);
    };
    return <BoundaryUploaderApp onClose={onClose} />;
};

export default BoundaryUploaderContainer;
