import type { ComponentPropsWithoutRef, ElementType, FunctionComponent, ReactNode } from 'react';
import { hasOwnProperty } from 'utils/hasOwnProperty';
import Num from 'view/Num';

import type Input from './Input';
import { getActiveInputMicronutrients, getActiveInputNutrients, inputHasNutrients } from './Input';

interface BaseProps<AsElement extends ElementType> {
    input: Input;
    as?: AsElement;
}

type InputNutrientsProps<AsElement extends ElementType> = BaseProps<AsElement> &
    Omit<ComponentPropsWithoutRef<AsElement>, keyof BaseProps<AsElement>>;

export const InputNutrientsList = <AsElement extends ElementType>({
    input,
    as,
}: InputNutrientsProps<AsElement>) => {
    if (!inputHasNutrients(input)) {
        return null;
    }
    const Wrapper = as ?? 'strong';

    return (
        <Wrapper>
            (
            {getActiveInputNutrients(input)
                .concat(getActiveInputMicronutrients(input))
                .toArray()
                .filter(([key, _]) =>
                    hasOwnProperty(
                        nutrientListComponentMap,
                        key as keyof typeof nutrientListComponentMap
                    )
                )
                .map<ReactNode>(([key, value]) => {
                    const Component = nutrientListComponentMap[key];
                    return <Component key={key} value={value} />;
                })
                .reduce((next, curr) => [next, ', ', curr])}
            )
        </Wrapper>
    );
};

export const InputNutrientSymbol = ({ nutrient }: { nutrient: string }) => {
    const Component = nutrientSymbolMap[nutrient];
    if (Component !== undefined) {
        return <Component />;
    }
    return null;
};

const nutrientSymbolMap: { [k: string]: FunctionComponent } = {
    nitrogen: () => <>N</>,
    phosphorus: () => (
        <>
            P<sub>2</sub>O<sub>5</sub>
        </>
    ),
    potassium: () => (
        <>
            K<sub>2</sub>O
        </>
    ),
    sodium: () => (
        <>
            Na<sub>2</sub>O
        </>
    ),
    magnesium: () => <>MgO</>,
    sulphur: () => (
        <>
            SO<sub>3</sub>
        </>
    ),
    calcium: () => <>Ca</>,
    zinc: () => <>Zn</>,
    manganese: () => <>Mn</>,
    copper: () => <>Cu</>,
    boron: () => <>B</>,
    iron: () => <>Fe</>,
    molybdenum: () => <>Mo</>,
    selenium: () => <>Se</>,
};

const nutrientListComponentMap = {
    nitrogen: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="nitrogen" />
        </>
    ),
    phosphorus: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="phosphorus" />
        </>
    ),
    potassium: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="potassium" />
        </>
    ),
    sodium: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="sodium" />
        </>
    ),
    magnesium: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="magnesium" />
        </>
    ),
    sulphur: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="sulphur" />
        </>
    ),
    calcium: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="calcium" />
        </>
    ),
    zinc: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="zinc" />
        </>
    ),
    manganese: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="manganese" />
        </>
    ),
    copper: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="copper" />
        </>
    ),
    boron: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="boron" />
        </>
    ),
    iron: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="iron" />
        </>
    ),
    molybdenum: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="molybdenum" />
        </>
    ),
    selenium: ({ value }: { value: number }) => (
        <>
            <Num value={value} />% <InputNutrientSymbol nutrient="selenium" />
        </>
    ),
};
