import { useFormContext } from 'react-hook-form';
import type { SingleParamVoidFunction } from 'system/types';

interface WindDirectionFormFieldProps {
    field: string;
    onChange?: SingleParamVoidFunction<string>;
}

const WindDirectionFormField = ({ field, onChange }: WindDirectionFormFieldProps) => {
    const { register } = useFormContext();
    return (
        <select
            {...register(field, {
                onChange: (e) => onChange && onChange(e.target.value),
            })}
            className="bordered p-2 rounded-sm h-10 focus"
        >
            <option value={undefined}></option>
            <option value="N">N</option>
            <option value="NE">NE</option>
            <option value="E">E</option>
            <option value="SE">SE</option>
            <option value="S">S</option>
            <option value="SW">SW</option>
            <option value="W">W</option>
            <option value="NW">NW</option>
        </select>
    );
};

export default WindDirectionFormField;
