import type { Farm } from '@fieldmargin/webapp-farms';
import type Herd from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import HerdTypeColourBar from 'herd/sidebar/HerdTypeColourBar';
import RememberingLink from 'view/RememberingLink';

interface FieldAttachedHerdProps {
    farm: Farm;
    herds: Herd[];
}

const FieldAttachedHerd = ({ farm, herds }: FieldAttachedHerdProps) => {
    return (
        <>
            {herds.map((herd) => (
                <div key={herd.uuid} className="bg-gray-100 pr-5 bordered-b">
                    <RememberingLink
                        to={`/farms/${farm.uuid}/livestock/${herd.uuid}`}
                        className="flex"
                    >
                        <HerdTypeColourBar herd={herd} />
                        <div className="py-5">
                            {herdIcon(herd.type)}
                            <strong className="ml-3">
                                {herd.name} {herd.size}
                            </strong>
                        </div>
                    </RememberingLink>
                </div>
            ))}
        </>
    );
};

export default FieldAttachedHerd;
