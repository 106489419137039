import { useTranslation } from 'react-i18next';
import type { Activity } from 'activity/activity-helper';
import {
    activityIsOperation,
    getActivityCompletedByUserId,
    getActivityCompletedDate,
    getActivityCreatedByUserId,
    getActivityName,
    getURL,
    isComplete,
} from 'activity/activity-helper';
import ActivityIcon from 'activity/ActivityIcon';
import FieldHistoryOperationRecordings from 'fields/sidebar/details/history/FieldHistoryOperationRecordings';
import { useFarmUserDisplayName } from 'team/farm-users-hooks';
import RememberingLink from 'view/RememberingLink';

import ItemHistoryItem from './ItemHistoryItem';

interface ItemHistoryActivityItemProps {
    activity: Activity;
    fieldUuid?: string;
}

const ItemHistoryActivityItem = ({ activity, fieldUuid }: ItemHistoryActivityItemProps) => {
    const { t } = useTranslation();
    const getDisplayName = useFarmUserDisplayName();
    const completedDate = getActivityCompletedDate(activity);
    const completedByUserId = getActivityCompletedByUserId(activity);
    return (
        <ItemHistoryItem>
            <RememberingLink to={getURL(activity)} className="flex gap-3 focus">
                <ActivityIcon activity={activity} fieldUuid={fieldUuid} />
                <div>
                    <strong>{getActivityName(activity)}</strong>
                    {activityIsOperation(activity) && activity.recordings !== null && (
                        <FieldHistoryOperationRecordings fullOperation={activity} />
                    )}
                    {isComplete(activity) && completedDate && completedByUserId && (
                        <span className="ItemHistorySubText">
                            {t('completed_by')} {getDisplayName(completedByUserId)}
                        </span>
                    )}
                    {!isComplete(activity) && (
                        <span className="ItemHistorySubText">
                            {t('created_by')} {getDisplayName(getActivityCreatedByUserId(activity))}
                        </span>
                    )}
                </div>
            </RememberingLink>
        </ItemHistoryItem>
    );
};

export default ItemHistoryActivityItem;
