import type { Farm } from '@fieldmargin/webapp-farms';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import ExportReportButton from 'reporting/ExportReportButton';
import { getNutrientReportDownloadLink } from 'reporting/reporting-api';
import ReportStatusFilter from 'reporting/ReportStatusFilter';
import YearFieldFilter from 'reporting/YearFieldFilter';
import type { SingleParamVoidFunction } from 'system/types';

import NutrientsFilter from './NutrientsFilter';

interface NutrientReportFiltersProps {
    farm: Farm;
    year: number;
    measurementUnit: MeasurementUnit;
    nutrientFilter: string[];
    fieldFilter: string[];
    statusFilter: string;

    onNutrientFilterChange: SingleParamVoidFunction<string[]>;
    onFieldFilterChange: SingleParamVoidFunction<string[]>;
    onStatusFilterChange: SingleParamVoidFunction<string>;
}

const NutrientReportFilters = ({
    farm,
    year,
    measurementUnit,
    nutrientFilter,
    fieldFilter,
    statusFilter,
    onNutrientFilterChange,
    onFieldFilterChange,
    onStatusFilterChange,
}: NutrientReportFiltersProps) => {
    return (
        <div className="flex items-end mb-6">
            <NutrientsFilter
                selected={nutrientFilter}
                onChange={onNutrientFilterChange}
                className="mr-4"
            />
            <YearFieldFilter selected={fieldFilter} onChange={onFieldFilterChange} />
            <ReportStatusFilter
                filter={statusFilter}
                setFilter={onStatusFilterChange}
                className="ml-auto"
            />
            <ExportReportButton
                filename="nutrient-report"
                downloadLink={getNutrientReportDownloadLink(
                    farm.uuid,
                    year,
                    statusFilter,
                    fieldFilter,
                    nutrientFilter,
                    measurementUnit
                )}
            />
        </div>
    );
};

export default NutrientReportFilters;
