import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from '@react-spring/web';
import { clsx } from 'clsx';
import { Filters } from 'components/filters/Filters';
import { useShowFiltersContext } from 'context/ShowFiltersContextProvider';
import { useCurrentYearLimitedActivities } from 'hooks/useCurrentYearLimitedActivities';
import { useFilteredActivities } from 'hooks/useFilteredActivities';
import { resetNotesFilters } from 'store/slices/notes.slice';
import { useAppDispatch } from 'system/store';
import useResizeObserver from 'use-resize-observer';

function ButtonClearFilters() {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const onClickHandler = () => {
        dispatch(resetNotesFilters());
    };

    return (
        <button onClick={onClickHandler} className="bare-btn underline">
            {t('clear_filters')}
        </button>
    );
}

function Message() {
    const { t } = useTranslation();
    const { totalFilteredActivities } = useFilteredActivities();
    const { totalCurrentYearLimitedActivities } = useCurrentYearLimitedActivities();

    return (
        <span>
            {totalFilteredActivities === 0
                ? t('no_items_found_matching_filters')
                : t('matching_items_found', {
                      sprintf: [totalFilteredActivities, totalCurrentYearLimitedActivities],
                  })}
        </span>
    );
}

function FiltersWrapper({ children }: PropsWithChildren) {
    const { open } = useShowFiltersContext();
    const { ref, height = 1 } = useResizeObserver<HTMLDivElement>({ box: 'border-box' });

    const animatedStyle = useSpring({
        from: { height: 0, opacity: 0 },
        to: {
            height: open ? height : 0,
            opacity: open ? 1 : 0,
        },
        config: { tension: 210, friction: 25 },
    });

    return (
        <animated.div
            style={{ overflow: 'hidden', ...animatedStyle }}
            className={clsx(open && 'bordered-b')}
        >
            {open && (
                <div ref={ref} className="p-5 text-gray-500">
                    {children}
                </div>
            )}
        </animated.div>
    );
}

function NoteListFilters() {
    const { totalFilteredActivities } = useFilteredActivities();
    const { totalCurrentYearLimitedActivities } = useCurrentYearLimitedActivities();

    if (totalCurrentYearLimitedActivities === 0) {
        return null;
    }

    const hasFilterMessage =
        totalFilteredActivities === 0 ||
        totalCurrentYearLimitedActivities !== totalFilteredActivities;

    return (
        <FiltersWrapper>
            <Filters />

            {hasFilterMessage && (
                <div className="flex gap-2 text-sm text-gray-500 mt-5">
                    <Message />
                    <ButtonClearFilters />
                </div>
            )}
        </FiltersWrapper>
    );
}

export { NoteListFilters };
