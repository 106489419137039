import { Fragment } from 'react';
import { getSortedInputTypes } from 'inputs/InputType';
import Num from 'view/Num';

import type { FieldInputCosts } from './InputCost';
import { InputCost } from './InputCost';

interface InputCostsFieldRowProps {
    inputCosts: FieldInputCosts;
}

const InputCostsFieldRow = ({ inputCosts }: InputCostsFieldRowProps) => {
    const inputTypes = getSortedInputTypes();
    return (
        <tr>
            <td>{inputCosts.fieldName}</td>
            {inputTypes.map((inputType) => {
                const costs = inputCosts.costs.get(inputType, new InputCost());
                return (
                    <Fragment key={inputType}>
                        <td>
                            <Num value={costs.totalCost} />
                        </td>
                        <td>
                            <Num value={costs.cost} />
                        </td>
                    </Fragment>
                );
            })}
        </tr>
    );
};

export default InputCostsFieldRow;
