import { createAjaxAction } from '@fieldmargin/webapp-state';
import Promise from 'bluebird';
import { loadingFarm } from 'farms/farm-loading-state';
import type { List, Map } from 'immutable';
import { Record } from 'immutable';
import { fetchSensorsLatestApi } from 'integrations/integrations-data-api';
import { updateInList } from 'lib/immutil';
import type { Action } from 'redux-actions';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

import type FarmIntegrationSensors from './FarmIntegrationSensors';
import type SensorSummary from './SensorSummary';

export const fetchIntegrationSensors = createAjaxAction(
    'fetchIntegrationSensors',
    (farmUuid: string) => Promise.resolve(fetchSensorsLatestApi(farmUuid)),
    {
        success: (state: SensorsState, payload: Map<string, FarmIntegrationSensors>) =>
            state.set('sensorsByIntegration', payload),
    }
);

export const setSensor = createAction('Set sensor');

export const selectSensorById = createSelector(
    (_: AppState, sensorId: string) => sensorId,
    (state: AppState) => state.sensorsState.sensorsByIntegration,
    (sensorId, sensorsByIntegration) =>
        sensorsByIntegration
            ?.filter((integration) => integration.sensors !== null)
            .valueSeq()
            .toList()
            .flatMap((i) => i.sensors as List<SensorSummary>)
            .find((sensor) => sensor.id === sensorId)
);

const SensorsState = Record({
    sensorsByIntegration: null as Map<string, FarmIntegrationSensors> | null,
    fetchIntegrationSensorsPending: false,
    fetchIntegrationSensorsError: false,
});
export interface SensorsState extends ReturnType<typeof SensorsState> {}

export const sensorsReducer = handleActions<SensorsState>(
    {
        [loadingFarm.toString()]: () => SensorsState(),

        ...fetchIntegrationSensors.reducers,

        [setSensor.toString()]: (state, action: Action<any>) =>
            state.updateIn(
                ['sensorsByIntegration', action.payload.farmIntegrationUuid, 'sensors'],
                (sensors) =>
                    updateInList<SensorSummary>(
                        sensors,
                        (s) => s.id === action.payload.sensorId,
                        (sensor) =>
                            sensor
                                .set('overrideName', action.payload.overrideName)
                                .set('overrideLocation', action.payload.overrideLocation)
                    )
            ),
    },
    SensorsState()
);
