import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import type Field from 'fields/Field';
import { getFieldArea, getFieldName } from 'fields/Field';
import type SubField from 'fields/sub-fields/SubField';
import type { List } from 'immutable';
import { Set } from 'immutable';
import { addOrRemoveInSet } from 'lib/immutil';
import { uniqueId } from 'lodash';
import type { SingleParamVoidFunction } from 'system/types';
import FormattedArea from 'view/units/FormattedArea';

interface SubFieldsDataCopyTableProps {
    parentField: Field;
    subFields: List<SubField>;
    selected: Set<string>;
    setSelected: SingleParamVoidFunction<Set<string>>;
}

const SubFieldsDataCopyTable = ({
    subFields,
    parentField,
    selected,
    setSelected,
}: SubFieldsDataCopyTableProps) => {
    const { t } = useTranslation();
    const selectAllId = useRef<string>(uniqueId('select-all-sub-fields-'));

    const handleSelectAll = () => {
        setSelected(
            selected.size === subFields.size
                ? Set()
                : subFields.map((subField) => subField.id).toSet()
        );
    };
    const handleSubFieldChecked = (id: string) => () => {
        setSelected(addOrRemoveInSet(selected, id));
    };

    return (
        <table className="w-full text-left border-collapse flex flex-col" style={{ height: 300 }}>
            <thead className="flex-auto flex-grow-0 flex-shrink-0">
                <tr className="w-full table table-fixed">
                    <th className="bordered-b font-medium pl-6 w-3/4">{t('name')}</th>
                    <th className="bordered-b font-medium text-center">{t('area')}</th>
                </tr>
                <tr className="w-full table table-fixed">
                    <td className="flex items-center py-3">
                        <Checkbox
                            id={selectAllId.current}
                            onChange={handleSelectAll}
                            checked={selected.size === subFields.size}
                            label={t('select_all')}
                        />
                    </td>
                </tr>
            </thead>
            <tbody className="flex-auto block overflow-x-hidden overflow-y-auto">
                {subFields.map((subField) => (
                    <tr key={subField.id} className="w-full table table-fixed">
                        <td className="flex items-center py-3 w-3/4">
                            <Checkbox
                                id={`${subField.id}-copy-checkbox`}
                                onChange={handleSubFieldChecked(subField.id)}
                                checked={selected.contains(subField.id)}
                                label={getFieldName(subField.field, parentField)}
                            />
                        </td>
                        <td className="py-3 text-center w-1/4">
                            <FormattedArea areaSqm={getFieldArea(subField.field)} withUnit />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default SubFieldsDataCopyTable;
