import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import { analytics } from '@notes-web/analytics';
import { stopEditing, stopSelectingMultipleAttachments } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { getFieldWorkedArea } from 'fields/Field';
import { useOperationDetailsActions } from 'hooks/actions';
import type { List } from 'immutable';
import type FullOperation from 'operations/FullOperation';
import { saveOperationFields } from 'operations/operations-save-helper';
import { setOperation } from 'operations/operations-state';
import OperationFieldsSelection from 'operations/sidebar/OperationFieldsSelection';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import { selectEditingFields } from 'sidebar/modules/shapes/shapes-util';
import type { AppState } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

interface EditingOperationFieldSelectionProps {
    // From parent
    fullOperation: FullOperation;

    // From redux
    farm: Farm;
    year: number;
    editingFields: List<Field>;
    stopSelectingMultipleAttachments: typeof stopSelectingMultipleAttachments;
    stopEditing: VoidFunction;
    setOperation: typeof setOperation;
}

const EditingOperationFieldSelection = ({
    farm,
    year,
    fullOperation,
    editingFields,
    stopSelectingMultipleAttachments,
    stopEditing,
    setOperation,
}: EditingOperationFieldSelectionProps) => {
    const { t } = useTranslation();
    const { navigateCurrentOperation } = useOperationDetailsActions();

    const { pending, setPromise } = usePromise<FullOperation>((fullOperation) => {
        setOperation(fullOperation);
        stopSelectingMultipleAttachments();
        stopEditing();
    });

    analytics.track('attach_fields_operation', {
        farmUuid: fullOperation.summary.farmUuid,
        jobUuid: fullOperation.uuid,
    });

    const handleBack = (e: MouseEvent) => {
        e.preventDefault();
        stopSelectingMultipleAttachments();
        stopEditing();
        navigateCurrentOperation();
    };

    const handleDone = () => {
        setPromise(
            saveOperationFields(
                farm.uuid,
                fullOperation,
                editingFields
                    .map((field) => {
                        const existingField = fullOperation.fields?.find(
                            (opField) => opField.fieldUuid === field.uuid
                        );
                        return existingField !== undefined
                            ? existingField
                            : {
                                  fieldUuid: field.uuid,
                                  areaSqm: getFieldWorkedArea(field, year),
                              };
                    })
                    .toSet()
            )
        );

        navigateCurrentOperation();
    };

    return (
        <div className="note-fields-selection scrollable">
            <div className="non-scrolling">
                <SidebarHeader className="px-5">
                    <Button variant="danger-outline" small onClick={handleBack}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDone} disabled={pending} small>
                        {t(pending ? 'saving' : 'save')}
                    </Button>
                </SidebarHeader>
            </div>
            <OperationFieldsSelection />
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        year: selectCurrentYear(state),
        editingFields: selectEditingFields(state),
    }),
    (dispatch) =>
        bindActionCreators(
            { stopSelectingMultipleAttachments, stopEditing, setOperation },
            dispatch
        )
)(EditingOperationFieldSelection);
