import { List, Record } from 'immutable';

import type { MetricDTO } from '../Metric';
import type { ReadingDTO } from '../Reading';
import type Reading from '../Reading';
import { deserializeReading, serializeReading } from '../Reading';

const ManualSensorReadingGroup = Record({
    id: '',
    sensorId: '',
    timestamp: new Date(),
    readings: List<Reading>(),
});
interface ManualSensorReadingGroup extends ReturnType<typeof ManualSensorReadingGroup> {}

export interface ManualSensorReadingGroupDTO {
    id: string | null;
    sensorId: string;
    timestamp: number;
    readings: ReadingDTO[];
}

export const deserializeManualSensorReadingGroup = (
    json: ManualSensorReadingGroupDTO,
    metrics: MetricDTO[]
) => {
    return ManualSensorReadingGroup({
        id: json.id ? json.id : '',
        sensorId: json.sensorId,
        timestamp: new Date(json.timestamp),
        readings: List(
            json.readings.map((reading) =>
                deserializeReading(
                    reading,
                    metrics.find((m) => m.id === reading.metricId) as MetricDTO
                )
            )
        ),
    });
};

export const serializeManualSensorReadingGroup = (
    readingGroup: ManualSensorReadingGroup
): ManualSensorReadingGroupDTO => {
    return {
        id: readingGroup.id ? readingGroup.id : null,
        sensorId: readingGroup.sensorId,
        timestamp: readingGroup.timestamp.valueOf(), // this gives millisecond precision
        readings: readingGroup.readings.map((reading) => serializeReading(reading)).toArray(),
    };
};

export default ManualSensorReadingGroup;
