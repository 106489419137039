import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import { useOutOfDatePromise } from 'lib/hooks';
import type FullOperation from 'operations/FullOperation';
import EditableActions from 'sidebar/modules/common/EditableActions';
import EditButton from 'sidebar/modules/common/EditButton';
import { useAppSelector } from 'system/store';
import SelectFormField from 'view/form/hook/SelectFormField';

interface EditableOperationYearProps {
    fullOperation: FullOperation;
    onSave: (fullOperation: FullOperation) => Promise<any>;
}

interface FormValues {
    year: string;
}

const EditableOperationYear = ({ fullOperation, onSave }: EditableOperationYearProps) => {
    const { t } = useTranslation();
    const [editing, toggleEditing] = useToggle(false);
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(toggleEditing);
    const years = useAppSelector((state) => state.yearsState.years?.toArray() ?? []);

    const handleSubmit = ({ year }: FormValues) =>
        setPromise(onSave(fullOperation.setIn(['summary', 'year'], year)));

    const methods = useForm<FormValues>({
        defaultValues: {
            year: fullOperation.summary.year.toString(),
        },
    });

    return editing ? (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} className="py-4">
                <h4 className="mb-2">{t('activity_log_year')}</h4>
                <div className="form-field">
                    <SelectFormField field="year">
                        {years.map((year) => (
                            <option value={year.toString()} key={year}>
                                {year}
                            </option>
                        ))}
                    </SelectFormField>
                    <EditableActions
                        disabled={pending}
                        error={error}
                        outOfDate={outOfDate}
                        setEditing={toggleEditing}
                    />
                </div>
            </form>
        </FormProvider>
    ) : (
        <div className="flex gap-2 items-start py-4">
            <h4 className="mb-0 w-32">{t('activity_log_year')}</h4>
            <div className="value">{fullOperation.summary.year}</div>
            <EditButton setEditing={toggleEditing} className="ml-auto font-bold float-none" />
        </div>
    );
};

export default EditableOperationYear;
