import { useSelector } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import type { AppState } from 'system/store';
import { useAppDispatch, useAppSelector } from 'system/store';
import { changeModal } from 'system/ui-state';

import type { FarmType, OnboardingTutorial } from './onboarding-api';
import {
    getOnboardingTutorials,
    saveOnboardingFarmType,
    saveOnboardingTutorials,
} from './onboarding-api';
import { useOnboarding } from './onboarding-state';

const saveOnboardingFarmTypes = (farmUuid: string, farmTypes: FarmType[]) =>
    farmTypes.length > 0 ? saveOnboardingFarmType(farmUuid, farmTypes) : Promise.resolve();

export const useSaveOnboardingFarmType = (skip = false) => {
    const farm = useAppSelector(selectCurrentFarm);
    const { nextOnboarding, skipOnboarding } = useOnboarding();

    return (farmTypes: FarmType[]) =>
        saveOnboardingFarmTypes(farm.uuid, farmTypes).finally(() => {
            if (skip) {
                skipOnboarding();
            } else {
                nextOnboarding('start');
            }
        });
};

export const useSaveOnboardingTutorials = (onSkip: VoidFunction, showTutorials = true) => {
    const dispatch = useAppDispatch();
    const farm = useAppSelector(selectCurrentFarm);

    return (tutorials: OnboardingTutorial[]) =>
        saveOnboardingTutorials(farm.uuid, tutorials).finally(() => {
            showTutorials &&
                dispatch(
                    changeModal({ name: 'tutorials', props: { selectedTutorials: tutorials } })
                );
            onSkip();
        });
};

export const useLoadOnboardingTutorials = () => {
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);

    return () => getOnboardingTutorials(farm.uuid);
};
