import { FeaturesController } from '@fieldmargin/webapp-ol-map';
import type Feature from 'ol/Feature';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

class SensorsController extends FeaturesController {
    constructor(zIndex: number) {
        super(zIndex, {});
    }

    protected styleFeature(feature: Feature) {
        const props = feature.getProperties();
        let textStyle: Text | undefined = undefined;
        if (feature.get('label')) {
            textStyle = new Text({
                font: `bold 12px sans-serif`,
                fill: new Fill({ color: '#333' }),
                stroke: new Stroke({ color: '#fff', width: 3 }),
                text: feature.get('label'),
                overflow: true,
                offsetY: props.pointScale + 10,
            });
        }

        const image = new Circle({
            radius: props.pointScale,
            stroke: new Stroke({ color: 'white', width: 2 }),
            fill: new Fill({ color: props.colour }),
        });
        return new Style({
            image: image,
            text: textStyle,
        });
    }
}

export default SensorsController;
