import { connect } from 'react-redux';
import { setFieldsVisibility } from 'farm-editing/farm-editing-state';
import { bindActionCreators } from 'redux';
import VisibilityToggle, { OnOffBorderOptions } from 'sidebar/visibility-toggle/VisibilityToggle';
import type { AppState } from 'system/store';

interface FieldListVisibilityProps {
    fieldsVisibility: string;
    setFieldsVisibility: (fieldsVisibility: string) => void;
}

const FieldListVisibility = ({
    fieldsVisibility,
    setFieldsVisibility,
}: FieldListVisibilityProps) => (
    <div className="field-list-visibility">
        <VisibilityToggle
            options={OnOffBorderOptions}
            active={fieldsVisibility}
            onSelect={setFieldsVisibility}
        />
    </div>
);

export default connect(
    (state: AppState) => ({
        fieldsVisibility: state.farmEditingState.layerVisibility.fieldsVisibility,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setFieldsVisibility,
            },
            dispatch
        )
)(FieldListVisibility);
