import type { ReactNode } from 'react';
import c from 'classnames';

interface OutsidePageProps {
    children: ReactNode;
    className?: string;
}

const OutsidePage = ({ children, className }: OutsidePageProps) => (
    <div className={c('text-white', className)}>
        <div className="mx-auto mb-8 w-80 pt-14">
            <img
                src="/images/fm-logo-440.png"
                className="block w-52 mx-auto mb-8"
                alt="fieldmargin logo"
            />
            <div>{children}</div>
        </div>
    </div>
);

export default OutsidePage;
