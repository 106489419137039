import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { deserializeLngLat } from '@fieldmargin/webapp-geo';
import { updateEditingGeoFeature } from 'farm-editing/farm-editing-state';
import type { Feature } from 'geojson';
import { useAction } from 'lib/hooks';

export const useFarmMapFeatureUpdated = () => {
    const updateFeature = useAction(updateEditingGeoFeature);

    return (feature: Feature) => {
        if (feature.id !== undefined) {
            const geoFeature = deserializeLngLat(feature) as GeoFeature;
            updateFeature(geoFeature.set('id', feature.id));
        }
    };
};
