import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AttachmentType } from 'farm-editing/attachments';
import { toggleEditingAttachmentByType } from 'farm-editing/farm-editing-state';
import type { FarmMapFeature } from 'farm-map/types';
import { selectCurrentFarm } from 'farms/farms-state';
import { useAction } from 'lib/hooks';
import { useAppSelector } from 'system/store';
import type { Nullable } from 'system/types';

/**
 * Possible scenarios:
 * 1. No attachments are being selected and user clicks on a feature: Navigate to the feature.
 * 2. Multiple attachments are being selected and user clicks on a feature: Toggle if the feature is attached.
 * 3. User clicks on a non-feature feature & is selecting multiple features: Do nothing, but
 *    consider the click handled so that no other effects take place.
 * 4. User clicks on a non-feature feature & is not selecting features: Do nothing.
 */
export const useFarmMapFeatureClick = () => {
    const navigate = useNavigate();
    const farmUuid = useAppSelector(selectCurrentFarm).uuid;

    // We have to use refs to store this state because otherwise the data is stale
    // in the returned function.
    const selectingMultipleState = useAppSelector(
        (state) => state.farmEditingState.selectingMultipleAttachmentType
    );
    const selectingMultipleRef = useRef<Nullable<AttachmentType>>(selectingMultipleState);
    const toggleFeatureAttachment = useAction(
        toggleEditingAttachmentByType(AttachmentType.FEATURE)
    );

    useEffect(() => {
        selectingMultipleRef.current = selectingMultipleState;
    }, [selectingMultipleState]);

    return (feature: FarmMapFeature) => {
        if (
            selectingMultipleRef.current !== null &&
            selectingMultipleRef.current !== AttachmentType.FEATURE
        ) {
            return false;
        }

        if (feature.properties.type !== 'FEATURE') {
            return selectingMultipleRef.current === AttachmentType.FEATURE;
        }
        if (selectingMultipleRef.current) {
            toggleFeatureAttachment(feature.properties.uuid);
            return true;
        }

        navigate(`/farms/${farmUuid}/features/${feature.properties.uuid}`);
        return true;
    };
};
