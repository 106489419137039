import { serialize } from '@fieldmargin/webapp-geo';
import { AttachmentType } from 'farm-editing/attachments';
import { EditingType, selectEditingAttachmentsByType } from 'farm-editing/farm-editing-state';
import type { FeatureCollection, Polygon } from 'geojson';
import { DEFAULT_COLOUR } from 'lib/colours';
import { useAppSelector } from 'system/store';

import type { FarmMapFieldFeature } from './useFarmMapFields';

export const useFarmMapAutoBoundaryFeatures = () => {
    const autoBoundaryActive = useAppSelector(
        (state) => state.farmEditingState.editingType === EditingType.AUTO_BOUNDARY
    );
    const editingFeatureCollection = useAppSelector(
        (state) => state.farmEditingState.editingGeoFeatureCollection
    );
    const editingFieldUuids = useAppSelector((state) =>
        selectEditingAttachmentsByType(state, AttachmentType.FIELD)
    );
    const editingId = useAppSelector((state) => state.farmEditingState.editingData).id;
    const highlightedGeoFeatureId = useAppSelector(
        (state) => state.farmEditingState.highlightedGeoFeatureId
    );

    if (
        !autoBoundaryActive ||
        editingId === 'edit-boundaries' ||
        editingFeatureCollection === null
    ) {
        return [];
    }

    return (
        serialize(editingFeatureCollection, { withProperties: true }) as FeatureCollection<Polygon>
    ).features.map<FarmMapFieldFeature>((feature) => {
        const isSelected = editingFieldUuids.has(feature.id?.toString() ?? '');
        const isHighlighted = highlightedGeoFeatureId === feature.id;
        const colour = isHighlighted ? '#4da4da' : isSelected ? '#ffffff' : DEFAULT_COLOUR;
        return {
            ...feature,
            properties: {
                ...feature.properties,
                type: 'FIELD',
                uuid: feature.id?.toString() ?? '',
                name: '',
                fieldUsageUuid: '',
                colour,
                pointScale: 6,
                strokeWeight: 2,
                strokeOpacity: 1,
                fillOpacity: 0.4,
            },
        };
    });
};
