import type { MouseEvent } from 'react';
import c from 'classnames';
import icon from 'components/icons/icon';
import type Field from 'fields/Field';
import { getFieldArea } from 'fields/Field';
import type { SingleParamVoidFunction } from 'system/types';
import ClickableDiv from 'view/a11y/ClickableDiv';
import NativeTextFormField from 'view/form/native/NativeTextFormField';
import { required } from 'view/form/validations';
import FormattedArea from 'view/units/FormattedArea';

import type SubField from '../SubField';

const validateRequired = required('Required');

interface SubFieldsListItemProps {
    parentField: Field;
    subField: SubField;
    selected: boolean;
    onSelect: (id: string) => void;
    onRemove?: (id: string) => void;
    onChange: (subField: SubField) => void;
    onToggleError: SingleParamVoidFunction<string>;
}

const SubFieldsListItem = ({
    parentField,
    subField,
    selected,
    onSelect,
    onRemove,
    onChange,
    onToggleError,
}: SubFieldsListItemProps) => {
    const handleSelect = () => {
        onSelect(subField.id);
    };
    const handleRemove = (e?: MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (onRemove) onRemove(subField.id);
    };
    const handleChange = (name: string) => {
        onChange(subField.setIn(['field', 'name'], name));
    };
    return (
        <ClickableDiv
            className={c('flex items-center', { 'bg-gray-200': selected })}
            onClick={handleSelect}
        >
            <div
                className="w-2 self-stretch mr-3"
                style={{
                    backgroundColor: subField.colour,
                }}
            />
            <span className="w-1/4 truncate mr-2 py-2">{parentField.name}</span>
            <NativeTextFormField
                id={subField.id}
                value={subField.field.name}
                onChange={handleChange}
                validate={validateRequired}
                validateOnChange
                validateOnBlur
                className="w-1/4 mr-4 py-2"
                inputClassName="w-full"
                toggleError={onToggleError}
            />
            <span className="w-1/4 py-2">
                <FormattedArea areaSqm={getFieldArea(subField.field)} />
            </span>
            {onRemove && (
                <div className="w-1/4 mr-5 text-right">
                    <button className="bare-btn focus" onClick={handleRemove}>
                        {icon('close', 'red', 'max-h-4')}
                    </button>
                </div>
            )}
        </ClickableDiv>
    );
};

export default SubFieldsListItem;
