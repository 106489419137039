import { useTranslation } from 'react-i18next';
import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { calcArea } from '@fieldmargin/webapp-geo';
import type { SingleParamVoidFunction } from 'system/types';
import FormattedArea from 'view/units/FormattedArea';

import ShapeBubble from './ShapeBubble';

interface AreaShapeBubbleProps {
    index?: number;
    geoFeature: GeoFeature;
    onHoverGeoFeature?: SingleParamVoidFunction<string | number | null>;
    onRemove?: SingleParamVoidFunction<string | number>;
}

const AreaShapeBubble = ({
    index,
    geoFeature,
    onHoverGeoFeature,
    onRemove,
}: AreaShapeBubbleProps) => {
    const { t } = useTranslation();
    const area = t('area');
    const label = index !== undefined ? `${area} ${index}` : area;
    return (
        <ShapeBubble
            geoFeature={geoFeature}
            label={
                <>
                    {label}: <FormattedArea areaSqm={calcArea(geoFeature.geometry)} withUnit />
                </>
            }
            onHoverGeoFeature={onHoverGeoFeature}
            onRemove={onRemove}
        />
    );
};

export default AreaShapeBubble;
