import { useTranslation } from 'react-i18next';
import type { GeoFeature, GeoPoint } from '@fieldmargin/webapp-geo';
import type { SingleParamVoidFunction } from 'system/types';
import Num from 'view/Num';

import ShapeBubble from './ShapeBubble';

interface PointShapeBubbbleProps {
    index?: number;
    geoFeature: GeoFeature;
    onHoverGeoFeature?: SingleParamVoidFunction<string | number | null>;
    onRemove?: SingleParamVoidFunction<string | number>;
}

const PointShapeBubbble = ({
    index,
    geoFeature,
    onHoverGeoFeature,
    onRemove,
}: PointShapeBubbbleProps) => {
    const { t } = useTranslation();
    if (geoFeature.geometry.type !== 'Point') {
        return null;
    }

    const geometry = geoFeature.geometry as GeoPoint;
    const label = (
        <>
            {t('title_description_point')}
            {index !== undefined ? ` ${index}` : ''}:{' '}
            <Num value={geometry.coordinates.y} places={5} />,{' '}
            <Num value={geometry.coordinates.x} places={5} />{' '}
        </>
    );
    return (
        <ShapeBubble
            geoFeature={geoFeature}
            label={label}
            onHoverGeoFeature={onHoverGeoFeature}
            onRemove={onRemove}
        />
    );
};

export default PointShapeBubbble;
