import { Record } from 'immutable';

const OLFeatureProperties = Record({
    // 'note', 'feature', 'field', returned to click handlers to describe what was clicked
    type: null,
    // If shape can be edited
    editable: false,

    // If a shape should be viewed in edit styles, without actually being editable
    passiveEditMode: false,

    // Colour of shape (when not being edited)
    colour: '#fff',
    // Style of points
    pointScale: 6,
    pointOpacity: 1,
    // Style of lines and polygon boundaries
    strokeWeight: 3,
    strokeOpacity: 1,
    lineDash: undefined,
    // Style of polygon fill
    fillOpacity: 0.4,
    // Z-ordering of shape
    zIndex: 1,

    // Text to shown on the shape
    label: null,
    // If lines should end in circles
    lineEndCircles: false,
    // Marker to be shown at the start of this shape
    noteMarker: null,
    // If this shape should not be drawn (you can still have have noteMarker=true)
    hideShapes: false,

    // Whether this marker should move when updated, e.g. if it's used for tracking
    updateGeometry: false,
});

export default OLFeatureProperties;
