import type { ComponentProps, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar } from '@notes-web/components';

import { Timeline } from '../Timeline';

interface OnboardingBaseProps {
    title: ReactNode;
    description: ReactNode;
    timeline: ComponentProps<typeof Timeline.Data>['data'];
    onContinue: () => void;
}

function OnboardingBase({ title, description, timeline, onContinue }: OnboardingBaseProps) {
    const { t } = useTranslation();

    return (
        <Sidebar.Root>
            <Sidebar.Body>
                <Sidebar.Header>
                    <Sidebar.Heading>{title}</Sidebar.Heading>

                    <Sidebar.Description>{description}</Sidebar.Description>
                </Sidebar.Header>

                <Sidebar.Card>
                    <Timeline.Data data={timeline} />
                </Sidebar.Card>
            </Sidebar.Body>

            <Sidebar.Footer>
                <Sidebar.ButtonFooter onPress={onContinue}>{t('continue')}</Sidebar.ButtonFooter>
            </Sidebar.Footer>
        </Sidebar.Root>
    );
}

export { OnboardingBase };
