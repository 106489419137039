import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import icon from 'components/icons/icon';
import { NoResults } from 'components/no-results';
import { SearchBar } from 'components/search-bar';
import { selectCurrentFarm } from 'farms/farms-state';
import type Herd from 'herd/Herd';
import { HerdType } from 'herd/Herd';
import {
    selectFilteredHerdsSortedByNameGroupedByStatusAndType,
    selectIsAllowedToAddHerds,
} from 'herd/herd-selectors';
import { setFilterHerdsByMap, setHerdSearchString } from 'herd/herd-state';
import type { HerdsByStatusAndType } from 'herd/herd-types';
import HerdLimitModal from 'herd/modal/HerdLimitModal';
import { isEmpty } from 'ramda';
import { bindActionCreators } from 'redux';
import FilterByMap from 'sidebar/FilterByMap';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarTitle from 'sidebar/modules/SidebarTitle';
import type { AppState } from 'system/store';
import { TutorialTypes } from 'tutorials/TutorialTypes';
import UpgradeLink from 'upgrade/UpgradeLink';
import ArchivedBar from 'view/ArchivedBar';

import HerdEmptyState from '../HerdEmptyState';
import HerdListVisibility from '../HerdListVisibility';

import HerdTypeHerdsList from './HerdTypeHerdsList';

interface HerdListProps {
    farm: Farm;
    herds: Herd[] | null;
    groupedHerds: HerdsByStatusAndType;
    filterHerdsByMap: boolean;
    setFilterHerdsByMap: typeof setFilterHerdsByMap;
    herdSearchString: string;
    setHerdSearchString: typeof setHerdSearchString;
    isAllowedToAddHerd: boolean;
    activeTutorial: string | null;
}

const HerdList = ({
    farm,
    herds,
    groupedHerds,
    filterHerdsByMap,
    setFilterHerdsByMap,
    herdSearchString,
    setHerdSearchString,
    isAllowedToAddHerd,
    activeTutorial,
}: HerdListProps) => {
    const { t } = useTranslation();
    const [showLimitModal, toggleLimitModal] = useToggle(false);

    const hasActiveHerds = !isEmpty(groupedHerds.active);
    const hasAchivedHerds = !isEmpty(groupedHerds.archived);

    const noHeardsSearchResult = !(hasActiveHerds || hasAchivedHerds);

    const handleNewClick = useCallback(
        (e: MouseEvent) => {
            if (!isAllowedToAddHerd) {
                e.preventDefault();
                toggleLimitModal();
            }
        },
        [isAllowedToAddHerd]
    );

    return (
        <div className="scrollable">
            {showLimitModal && (
                <HerdLimitModal
                    onClose={toggleLimitModal}
                    herdLimit={farm.plan.herdLimit as number}
                />
            )}
            <div className="non-scrolling relative">
                <SidebarTitle title={t('livestock')}>
                    <Button
                        as={Link}
                        to={`/farms/${farm.uuid}/livestock/new`}
                        onClick={handleNewClick}
                        className={c('flex items-center', {
                            forefront: activeTutorial === TutorialTypes.HERDS,
                        })}
                    >
                        {icon('add', 'white', 'mr-1')} {t('livestock_add')}
                    </Button>
                </SidebarTitle>
                <SidebarModule editing className="flex items-center justify-between">
                    <SearchBar searchFunction={setHerdSearchString} value={herdSearchString} />
                    <HerdListVisibility />
                </SidebarModule>
                {herds && farm.plan.herdLimit && herds.length > farm.plan.herdLimit && (
                    <div className="bg-error-bg-pale p-4 text-sm relative">
                        {icon('padlock', 'red', 'absolute w-10 h-10', { top: '33%' })}
                        <UpgradeLink className="block focus">
                            <span className="block text-center mx-auto max-w-3/4">
                                <strong>
                                    {t('pro_upgrade_prompt_herds_title_over_limit', {
                                        sprintf: [
                                            herds.length - (farm.plan.herdLimit as number),
                                            farm.plan.herdLimit,
                                        ],
                                    })}
                                </strong>
                                . {t('pro_upgrade_prompt_herds_subtitle_over_limit')}
                            </span>
                        </UpgradeLink>
                    </div>
                )}
            </div>
            <div className="scrolling">
                <FilterByMap
                    checked={filterHerdsByMap}
                    onChange={setFilterHerdsByMap}
                    label={t('herd_list_visible_filter')}
                    className="bordered-b p-5"
                />
                {herds && herds.length === 0 && <HerdEmptyState />}

                {hasActiveHerds &&
                    Object.entries(groupedHerds.active).map(([herdType, herds]) => (
                        <HerdTypeHerdsList key={herdType} herds={herds} type={HerdType[herdType]} />
                    ))}

                {hasAchivedHerds && (
                    <>
                        <ArchivedBar>{t('archived_herds')}</ArchivedBar>
                        {Object.entries(groupedHerds.archived).map(([herdType, herds]) => (
                            <HerdTypeHerdsList
                                key={herdType}
                                herds={herds}
                                type={HerdType[herdType]}
                                archived
                            />
                        ))}
                    </>
                )}

                {noHeardsSearchResult && <NoResults>{t('herds_selector_empty')}</NoResults>}
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        herds: state.herdsState.herds,
        groupedHerds: selectFilteredHerdsSortedByNameGroupedByStatusAndType(state),
        filterHerdsByMap: state.herdsState.filterHerdsByMap,
        herdSearchString: state.herdsState.herdSearchString,
        isAllowedToAddHerd: selectIsAllowedToAddHerds(state),
        activeTutorial: state.tutorialsState.activeTutorial,
    }),
    (dispatch) => bindActionCreators({ setFilterHerdsByMap, setHerdSearchString }, dispatch)
)(HerdList);
