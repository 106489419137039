import { useAppSelector } from 'system/store';
import { selectUserId } from 'users/user-state';

const useUserId = () => {
    const userId = useAppSelector(selectUserId);

    return { userId };
};

export { useUserId };
