import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import c from 'classnames';
import { AttachmentType } from 'farm-editing/attachments';
import {
    selectEditingAttachmentsByTypes,
    selectEditingFieldAndFeaturesGeoJson,
} from 'farm-editing/farm-editing-state';
import type { List, Set } from 'immutable';
import EditableShapeInformation from 'sidebar/modules/shapes/components/EditableShapeInformation';
import { calculateTotalExtent } from 'sidebar/modules/shapes/shapes-util';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import type { GeoJsonItem } from 'system/types';

import NoteFieldsEdit from '../details/NoteFieldsEdit';
import NoteFeaturesEdit from '../NoteFeaturesEdit';

interface NoteNewLocation {
    // From parent
    tools?: List<DrawingTool>;
    maxShapes?: number;

    // From redux
    editingAttachmentUuids: Set<string>;
    editingShapes: GeoFeatureCollection | null;
    editingAttachmentGeoJson: List<GeoJsonItem>;
}

const NoteNewLocation = ({
    tools,
    maxShapes,
    editingShapes,
    editingAttachmentUuids,
    editingAttachmentGeoJson,
}: NoteNewLocation) => {
    const { t } = useTranslation();
    return (
        <SidebarModule className="NewShapesAndFields" editing={true}>
            <EditableShapeInformation
                label={t('location')}
                tools={tools}
                maxShapes={maxShapes}
                extraExtent={calculateTotalExtent(editingShapes, editingAttachmentGeoJson)}
                fieldsAttachable={true}
            >
                <div
                    className={c({ 'flex items-center gap-2': editingAttachmentUuids.size === 0 })}
                >
                    <NoteFieldsEdit />
                    <NoteFeaturesEdit />
                </div>
            </EditableShapeInformation>
        </SidebarModule>
    );
};

export default connect((state: AppState) => ({
    editingShapes: state.farmEditingState.editingGeoFeatureCollection,
    editingAttachmentUuids: selectEditingAttachmentsByTypes(state, [
        AttachmentType.FIELD,
        AttachmentType.FEATURE,
    ]),
    editingAttachmentGeoJson: selectEditingFieldAndFeaturesGeoJson(state),
}))(NoteNewLocation);
