import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import type Input from 'inputs/Input';
import ErrorMessage from 'view/ErrorMessage';

interface PesticideCheckInvalidInputProps {
    input: Input;
    startOpen?: boolean;
}

const PesticideCheckInvalidInput = ({
    input,
    startOpen = false,
}: PesticideCheckInvalidInputProps) => {
    const { t } = useTranslation();
    const [open, toggleOpen] = useToggle(startOpen);
    return (
        <div className="PesticideCheckInvalidInput">
            <button className="bare-btn focus ring-inset" onClick={toggleOpen}>
                {input.name}
                <i className={open ? 'ion-chevron-down' : 'ion-chevron-up'} />
            </button>
            {open && (
                <ErrorMessage className="PesticideCheckInvalidMsg">
                    {t('pesticide_check_error_no_inputs')}
                </ErrorMessage>
            )}
        </div>
    );
};

export default PesticideCheckInvalidInput;
