import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import c from 'classnames';
import { useOutsideComponentClickListener } from 'lib/hooks';
import { addOrRemoveInArray } from 'lib/immutil';
import { uniqueId } from 'lodash';
import { isEmpty } from 'ramda';
import type { SingleParamVoidFunction } from 'system/types';

interface ApplyCheckboxFilter {
    header?: string;
    emptyMsg: string;
    onApply: SingleParamVoidFunction<string[]>;
    selectedItemIds: string[];
    items: { id: string; title: string }[];
    className?: string;
}

const ApplyCheckboxFilter = ({
    header,
    emptyMsg,
    onApply,
    selectedItemIds,
    items,
    className,
}: ApplyCheckboxFilter) => {
    const elId = useRef(uniqueId('apply-checkbox-filter-'));
    const selectedItems = items.filter((item) => selectedItemIds.includes(item.id));
    const [open, toggleOpen] = useToggle(false);

    useOutsideComponentClickListener(elId.current, () => open && toggleOpen(), [open]);

    const handleApply = (nextSelectedIds: string[]) => {
        onApply(nextSelectedIds);
        toggleOpen();
    };

    return (
        <div className={c('relative', className)} data-elementid={elId.current}>
            {header && <h4 className="font-medium">{header}</h4>}
            <button
                className="bare-btn bordered p-2 rounded-sm w-52 h-10 flex justify-between cursor-pointer focus"
                onClick={toggleOpen}
            >
                <span className="truncate pr-1">
                    {!isEmpty(selectedItems) ? (
                        selectedItems.map((item) => item.title).join(', ')
                    ) : (
                        <em className="text-gray-400">{emptyMsg}</em>
                    )}
                </span>
                <i className="ion-chevron-down ml-1" />
            </button>
            {open && (
                <ApplyCheckboxFilterList
                    items={items}
                    selectedItemIds={selectedItemIds}
                    onApply={handleApply}
                />
            )}
        </div>
    );
};

interface ApplyCheckboxFilterListProps {
    selectedItemIds: string[];
    onApply: SingleParamVoidFunction<string[]>;
    items: { id: string; title: string }[];
}
const ApplyCheckboxFilterList = ({
    selectedItemIds,
    onApply,
    items,
}: ApplyCheckboxFilterListProps) => {
    const { t } = useTranslation();
    const [nextSelectedInputUuids, setNextSelectedInputUuids] = useState<string[]>(selectedItemIds);

    const handleInputChange = (inputUuid: string) => {
        setNextSelectedInputUuids(addOrRemoveInArray(nextSelectedInputUuids, inputUuid));
    };

    return (
        <div className="bordered bg-white shadow rounded-sm absolute z-10 top-full w-full">
            <div className="flex justify-between p-2">
                <Button small className="px-2" onClick={() => onApply(nextSelectedInputUuids)}>
                    {t('label_apply')}
                </Button>
                <Button small className="px-2" variant="outline" onClick={() => onApply([])}>
                    {t('label_clear')}
                </Button>
            </div>
            <ol className="list-none p-0 overflow-auto max-h-80">
                {items.map((item) => (
                    <li className="bordered-t p-2 relative" key={item.id}>
                        <Checkbox
                            id={item.id}
                            checked={nextSelectedInputUuids.includes(item.id)}
                            onChange={() => handleInputChange(item.id)}
                            label={item.title}
                        />
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default ApplyCheckboxFilter;
