import { useYeldReportingOutputs } from 'hooks/useYeldReportingOutputs';
import type Output from 'outputs/Output';
import type OutputReportSummary from 'outputs/OutputReportSummary';
import Num from 'view/Num';
import ShortUnit from 'view/units/ShortUnit';

import sortAsc from '../sort-asc.svg';
import sortDesc from '../sort-desc.svg';

interface YieldReportOutputRowProps {
    outputReportSummary: OutputReportSummary;
    isOpen: boolean;
    toggleOpen: VoidFunction;
}

const YieldReportOutputRow = ({
    outputReportSummary,
    isOpen,
    toggleOpen,
}: YieldReportOutputRowProps) => {
    const { yeldReportingOutputs } = useYeldReportingOutputs();

    const output = yeldReportingOutputs?.find(
        (output) => output.uuid === outputReportSummary.outputUuid
    ) as Output;

    return (
        output && (
            <>
                <tr>
                    <td className="sortable">
                        <button className="bare-btn sort" onClick={toggleOpen}>
                            {outputReportSummary.outputName}
                            <img
                                className="w-6 float-right"
                                src={isOpen ? sortDesc : sortAsc}
                                alt="Icon representing open state"
                            />
                        </button>
                    </td>
                    <td>
                        <Num value={outputReportSummary.yieldRate} sigFigs={4} />{' '}
                        <ShortUnit unit={output.unit} />
                    </td>
                    <td>
                        <Num value={outputReportSummary.yieldTotal} sigFigs={4} />{' '}
                        <ShortUnit unit={output.unit} />
                    </td>
                    <td>
                        <Num value={outputReportSummary.income} />
                    </td>
                    <td>
                        <Num value={outputReportSummary.totalIncome} />
                    </td>
                </tr>
                {isOpen &&
                    outputReportSummary.fields.map(
                        ({ fieldUuid, fieldName, yieldRate, yieldTotal, income, totalIncome }) => (
                            <tr key={fieldUuid} className="bg-gray-100">
                                <td>
                                    <span className="ml-4">{fieldName}</span>
                                </td>
                                <td>
                                    <Num value={yieldRate} sigFigs={4} />{' '}
                                    <ShortUnit unit={output.unit} />
                                </td>
                                <td>
                                    <Num value={yieldTotal} sigFigs={4} />{' '}
                                    <ShortUnit unit={output.unit} />
                                </td>
                                <td>
                                    <Num value={income} />
                                </td>
                                <td>
                                    <Num value={totalIncome} />
                                </td>
                            </tr>
                        )
                    )}
            </>
        )
    );
};

export default YieldReportOutputRow;
