import { isUuid } from 'lib/util/text';
import { isArray } from 'lodash';
import type { ParsedQuery } from 'query-string';

export interface ActiveSection {
    main?: string;
    sub?: string;
}

export const isQueryParamString = (params: ParsedQuery<string>, key: string) =>
    !isArray(params[key]) && params[key] !== undefined && params[key] !== null;

export const getActiveSectionFromPath = (pathname: string): ActiveSection => {
    const m = pathname.match(/\/farms\/[0-9a-f-]+\/([a-z]+)(\/[a-zA-Z0-9-]+)?(\/[a-z-A-Z]+)?/);
    if (m) {
        const main = m[1];
        const subOrUuid = m[2] ? m[2].replace('/', '') : undefined;
        const possibleSub = m[3] ? m[3].replace('/', '') : undefined;

        if (!subOrUuid) {
            return { main, sub: undefined };
        }

        if (isUuid(subOrUuid)) {
            return { main, sub: possibleSub };
        }

        return { main, sub: subOrUuid };
    }
    return {};
};

export const activeSectionToPath = (activeSection: ActiveSection) => {
    if (activeSection.main === undefined) {
        return '';
    }

    let path = activeSection.main === 'operations' ? 'notes' : activeSection.main;
    if (activeSection.sub !== undefined) {
        path += `/${activeSection.sub}`;
    }
    return path;
};
