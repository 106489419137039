import { useSelector } from 'react-redux';
import type { AppState } from 'system/store';
import { converter } from 'utils/conversion';
import Num from 'view/Num';

interface TemperatureProps {
    celsius: number;
    round?: boolean;
}

const Temperature = ({ celsius, round = false }: TemperatureProps) => {
    const temperatureUnit = useSelector<AppState, string | undefined>(
        (state) => state.user.preferences?.temperatureMeasurementUnit
    );

    const value =
        temperatureUnit === 'FAHRENHEIT' ? converter.convertCelsiusToFahrenheit(celsius) : celsius;

    return <Num value={round ? Math.round(value) : value} />;
};

export default Temperature;
