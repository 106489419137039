import type { ReactNode } from 'react';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import { getSizesOfGeometry } from 'lib/geo/shape-size-cacher';
import type { GeoJsonItemWithName } from 'system/types';
import SizeBubble from 'view/molecules/size-bubble/SizeBubble';
import FormattedGeoData from 'view/units/FormattedGeoData';

interface GroupItemSelectProps {
    // From parent
    item: GeoJsonItemWithName;
    selected: boolean;
    toggleSelected: VoidFunction;
    subline?: ReactNode;
}

const GroupItemSelect = ({ item, selected, toggleSelected, subline }: GroupItemSelectProps) => {
    const sizes = getSizesOfGeometry(item.uuid, item.geoJson);
    return (
        <div className="GroupItemSelect flex items-center justify-between relative bordered-b pl-11 pr-9 py-4">
            <Checkbox
                id={item.uuid}
                checked={selected}
                onChange={toggleSelected}
                label={
                    <>
                        <div className="name">{item.name}</div>
                        {subline}
                    </>
                }
                className="overflow-hidden"
            />
            <SizeBubble>
                <FormattedGeoData sizes={sizes} />
            </SizeBubble>
        </div>
    );
};

export default GroupItemSelect;
