import { selectFieldUsagesWithNotSet } from 'fields/field-usage-selectors';
import {
    selectYearFields,
    selectYearFieldsWithoutArchived,
    selectYearTotalFieldArea,
    selectYearTotalFieldAreaActive,
} from 'fields/fields-selectors';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

import { calculateUsageArea } from './usage-reporting-maths';
import type { UsageReportDatum } from './UsageReporting';

export const selectUsageReportData = createSelector(
    (state: AppState, includeArchived: boolean) =>
        includeArchived ? selectYearFields(state) : selectYearFieldsWithoutArchived(state),
    selectFieldUsagesWithNotSet,
    selectCurrentYear,
    (state: AppState, includeArchived: boolean) =>
        includeArchived ? selectYearTotalFieldArea(state) : selectYearTotalFieldAreaActive(state),
    (fields, fieldUsages, year, totalArea): UsageReportDatum[] => {
        if (fieldUsages.size === 0) {
            return [];
        }

        return fieldUsages
            .map((fieldUsage) => {
                const uuid = fieldUsage.uuid === 'none' ? undefined : fieldUsage.uuid;
                const areaSqm = calculateUsageArea(uuid, year, fields);

                return {
                    fieldUsage,
                    areaSqm,
                    percentage: (areaSqm / totalArea) * 100,
                };
            })
            .filter((datum) => datum.areaSqm > 0)
            .sortBy((datum) => datum.areaSqm)
            .reverse()
            .toArray();
    }
);
