import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { useLazyBeginOAuth2FlowQuery } from '@notes-web/api';
import { johnDeereLogo } from '@notes-web/assets';
import { ExternalLink } from '@notes-web/icons';
import { ButtonBase } from 'components/button-base';
import { usePageParams } from 'hooks/usePageParamas';

import { Sidebar } from '../Sidebar';

import styles from './JohnDeereConnect.module.css';

function JohnDeereConnect() {
    const { t } = useTranslation();
    const [triggerOAuthFlow] = useLazyBeginOAuth2FlowQuery();
    const { farmUuid } = usePageParams();

    const navigateExternalSite = () => {
        window.open('https://myjohndeere.deere.com', '_blank', 'noopener,noreferrer');
    };

    const onConnect = () => {
        if (!farmUuid) return;

        triggerOAuthFlow({ farmUuid, returnUrl: `${window.location}/select` }).then((response) => {
            if (response.isSuccess && response.data) {
                window.location.href = response.data;
            }
        });
    };

    return (
        <Fragment>
            <Sidebar.Card className={styles.jdCard}>
                <Sidebar.Heading>{t('john_deere_header')}</Sidebar.Heading>
                <Sidebar.Description>{t('connections_john_deere_desc_long')}</Sidebar.Description>

                <img className={styles.jdLogo} src={johnDeereLogo} alt=" john deere logo" />
            </Sidebar.Card>

            <Sidebar.Card className={styles.buttonsGroup}>
                <ButtonBase.Root variant="outline-primary" onPress={navigateExternalSite}>
                    {t('visit_site')}
                    <ButtonBase.Icon>
                        <ExternalLink />
                    </ButtonBase.Icon>
                </ButtonBase.Root>
                <ButtonBase.Root variant="primary" isDisabled={!farmUuid} onPress={onConnect}>
                    {t('connect_john_deere')}
                </ButtonBase.Root>
            </Sidebar.Card>
        </Fragment>
    );
}

export { JohnDeereConnect };
