import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useRef } from 'react';
import c from 'classnames';
import { eventIsSpaceOrEnterKeyPress, preventDefaultAnd } from 'lib/dom';
import { uniqueId } from 'lodash';
import { when } from 'ramda';

interface ClickableDivProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    onClick: VoidFunction;
}

const ClickableDiv = ({ className, children, onClick, ...props }: ClickableDivProps) => {
    const id = useRef(uniqueId('clickable-div-'));

    const handleKeyDown = when<React.KeyboardEvent<HTMLDivElement>, void>(
        eventIsSpaceOrEnterKeyPress,
        preventDefaultAnd(onClick)
    );

    return (
        <div
            id={id.current}
            className={c('cursor-pointer focus ring-inset', className)}
            onClick={onClick}
            {...props}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
        >
            {children}
        </div>
    );
};

export default ClickableDiv;
