import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import type { Extent } from '@fieldmargin/webapp-geo';
import { preventDefaultAnd } from 'lib/dom';
import type { SingleParamVoidFunction } from 'system/types';
import ErrorMessage from 'view/ErrorMessage';

import {
    selectedFailedRpaFeatures,
    selectIgnoredRpaFeaturesWithStyles,
    selectSelectedRpaFeaturesWithStyles,
} from './rpa-selectors';
import type { RPAImportFormValues } from './RPAImportForm';
import RPAImportForm from './RPAImportForm';
import RPAImportMap from './RPAImportMap';
import type { RPAState } from './rps-state';

import './ImportRPAFields.css';

interface ImportRpaFieldsProps {
    rpaState: RPAState;
    featureClick: SingleParamVoidFunction<string>;
    onHighlight: (id?: string) => void;
    onDeselect: SingleParamVoidFunction<string>;
    setFeatureLabel: (id: string, label: string) => void;
    onCreate: SingleParamVoidFunction<RPAImportFormValues>;
    createPending: boolean;
    createError: boolean;
    onFinish: VoidFunction;
}

const ImportRpaFields = ({
    rpaState,
    featureClick,
    onHighlight,
    onDeselect,
    setFeatureLabel,
    onCreate,
    createPending,
    createError,
    onFinish,
}: ImportRpaFieldsProps) => {
    const { t } = useTranslation();
    const [zoomExtent, setZoomExtent] = useState<Extent>();
    const containerRef = useRef<HTMLDivElement | null>(null);

    if (!rpaState.features) {
        return null;
    }

    const fieldFeatures = selectSelectedRpaFeaturesWithStyles(rpaState).concat(
        selectIgnoredRpaFeaturesWithStyles(rpaState)
    );

    return (
        <div className="ImportRpaFields" ref={containerRef}>
            <div className="ImportRpaFieldsWrapper">
                <div>
                    <p>
                        <strong>
                            {t('fields_selected', {
                                sprintf: [rpaState.selected.size, rpaState.features.size],
                            })}
                        </strong>
                    </p>
                    <RPAImportMap
                        fieldFeatures={fieldFeatures}
                        onFeatureClick={featureClick}
                        zoomExtent={zoomExtent}
                    />
                    {rpaState.failed.size > 0 && (
                        <div className="mt-5">
                            <p>{t('rpa_create_failed')}:</p>
                            <ul>
                                {selectedFailedRpaFeatures(rpaState).map((f, i) => (
                                    <li key={i}>{f.properties.get('label') as string}</li>
                                ))}
                            </ul>
                            <Markdown
                                components={{
                                    a: (props: any) => (
                                        <a
                                            {...props}
                                            href="#"
                                            onClick={preventDefaultAnd(onFinish)}
                                        />
                                    ),
                                }}
                            >
                                {t('rpa_create_failed_try_again')}
                            </Markdown>
                        </div>
                    )}
                    {createError && <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>}
                </div>
                <div className="overflow-auto scroll-smooth">
                    <RPAImportForm
                        fields={rpaState.features}
                        selected={rpaState.selected}
                        ignored={rpaState.ignored}
                        highlighted={rpaState.highlighted}
                        createPending={createPending}
                        onSubmit={onCreate}
                        onHighlight={onHighlight}
                        onChange={setFeatureLabel}
                        onIgnore={onDeselect}
                        onZoomClick={setZoomExtent}
                    />
                </div>
            </div>
        </div>
    );
};

export default ImportRpaFields;
