import type { MouseEvent } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import { clsx } from 'clsx';
import icon from 'components/icons/icon';
import { selectCanCreateMoreFields } from 'fields/fields-selectors';
import FieldAreaLimitModal from 'fields/modal/FieldAreaLimitModal';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import type { TutorialTypeSchema } from 'tutorials/TutorialTypes';
import { TutorialTypes } from 'tutorials/TutorialTypes';
import DropdownButton from 'view/dropdown-button/DropdownButton';
import { DropdownButtonItem } from 'view/dropdown-button/DropdownButtonItem';

interface NewFieldMenuProps {
    farm: Farm;
    changeModal: typeof changeModal;
    activeTutorial?: TutorialTypeSchema;
    canCreateFields: boolean;
}

const NewFieldMenu = ({
    farm,
    changeModal,
    activeTutorial,
    canCreateFields,
}: NewFieldMenuProps) => {
    const { t } = useTranslation();

    const tutorialActive = activeTutorial === TutorialTypes.FIELD;
    const [showFieldLimitModal, toggleShowFieldLimitModal] = useToggle(false);

    const handleDrawClick = (e: MouseEvent) => {
        if (!canCreateFields) {
            e.preventDefault();
            toggleShowFieldLimitModal();
        }
    };
    const handleImportClick = (e: MouseEvent) => {
        e.preventDefault();
        if (!canCreateFields) {
            toggleShowFieldLimitModal();
        } else {
            changeModal({
                name: 'connections',
                props: {
                    initialSection: 'fields',
                },
            });
        }
    };

    return (
        <Fragment>
            {showFieldLimitModal && <FieldAreaLimitModal onClose={toggleShowFieldLimitModal} />}
            <DropdownButton
                text={
                    <>
                        {icon('add', 'white', 'mr-3')} {t('draw_shapes_add_point')}
                    </>
                }
                className={clsx('mr-3', { forefront: tutorialActive })}
            >
                <DropdownButtonItem.Root>
                    <DropdownButtonItem.LinkItem
                        to={`/farms/${farm.uuid}/fields/new`}
                        onClick={handleDrawClick}
                    >
                        {t('draw')}
                    </DropdownButtonItem.LinkItem>
                </DropdownButtonItem.Root>
                <DropdownButtonItem.Root>
                    <DropdownButtonItem.LinkItem to="#" onClick={handleImportClick}>
                        {t('import')}
                    </DropdownButtonItem.LinkItem>
                </DropdownButtonItem.Root>
            </DropdownButton>
            <Button as={Link} to={`/farms/${farm.uuid}/fields/usage`} variant="outline">
                {t('label_set_usage')}
            </Button>
        </Fragment>
    );
};

export default connect(
    (state: AppState) => ({
        activeTutorial: state.tutorialsState.activeTutorial,
        canCreateFields: selectCanCreateMoreFields(state),
    }),
    (dispatch) => bindActionCreators({ changeModal }, dispatch)
)(NewFieldMenu);
