import { useSelector } from 'react-redux';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import type { AppState } from 'system/store';
import { useAppDispatch } from 'system/store';
import { rememberCurrentLocation } from 'system/ui-state';

interface FarmRememberingLinkProps extends LinkProps {}

const FarmRememberingLink = (props: FarmRememberingLinkProps) => {
    const dispatch = useAppDispatch();
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);

    const handleClick = () => {
        dispatch(rememberCurrentLocation());
    };

    return (
        <Link
            to={`/farms/${farm.uuid}${props.to}`}
            onClick={handleClick}
            className={props.className}
        >
            {props.children}
        </Link>
    );
};

export default FarmRememberingLink;
