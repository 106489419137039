import type { Activity } from 'activity/activity-helper';
import { getActivityCompletedDate, getCreatedDate, getDueDate } from 'activity/activity-helper';
import type { List } from 'immutable';
import type { FilterDateType } from 'types';

export const filterActivitiesByDateType = (
    dateTypes: Array<FilterDateType>,
    activities: List<Activity>
) => {
    if (!dateTypes.length) {
        return activities;
    }

    return activities.filter((activity) => {
        for (const dateType of dateTypes) {
            switch (dateType) {
                case 'completedDate':
                    if (getActivityCompletedDate(activity)) {
                        return true;
                    }
                    break;
                case 'createdDate':
                    if (getCreatedDate(activity)) {
                        return true;
                    }
                    break;
                case 'dueDate':
                    if (getDueDate(activity)) {
                        return true;
                    }
                    break;
                default:
                    break;
            }
        }

        return false;
    });
};
