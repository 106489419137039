import type { ChartDataset } from 'chart.js';
import type { TFunction } from 'i18next';
import { getInputTypeI18nKey, INPUT_TYPE_COLOURS } from 'inputs/InputType';
import { compareString } from 'lib/util/text';
import pattern from 'patternomaly';
import { pipe, prop, replace, sort } from 'ramda';

import type { GrossMargin } from './GrossMargin';

const DATASET_LABEL_COLOR = '#000';
const DATASET_BAR_THICKNESS = 150;

export const getGrossMarginFieldGraphData = (datum: GrossMargin, t: TFunction) => {
    const inputDataset: ChartDataset<'bar' | 'line'>[] = datum.inputs
        ? datum.inputs.map((input, index) => {
              const colours = INPUT_TYPE_COLOURS[input.type];

              return {
                  index,
                  barThickness: DATASET_BAR_THICKNESS,

                  type: 'bar',
                  label: t(getInputTypeI18nKey(input.type)),
                  data: [null, -input.cost, null],
                  backgroundColor:
                      colours.pattern !== undefined
                          ? pattern.draw(colours.pattern, '#fff', colours.background)
                          : colours.background,
                  datalabels: {
                      color: DATASET_LABEL_COLOR,
                  },
              };
          })
        : [];

    const outputDataset: ChartDataset<'bar' | 'line'>[] = datum.outputs
        ? datum.outputs.map((output) => ({
              type: 'bar',
              barThickness: DATASET_BAR_THICKNESS,

              label: output.type,
              data: [null, output.cost, null],
              backgroundColor: '#d7dfe3',

              datalabels: {
                  color: DATASET_LABEL_COLOR,
              },
          }))
        : [];

    const marginDataset: ChartDataset<'bar' | 'line'>[] = [
        {
            type: 'line',
            label: t('gross_margin_title'),
            data: datum.margin
                ? [datum.margin.cost, datum.margin.cost, datum.margin.cost]
                : [0, 0, 0],
            borderColor: '#2c3e50',
            borderDash: [10, 10],
            pointRadius: 0,
            datalabels: {
                align: 'end',
                display: (context) => context.dataIndex === 2,
                color: DATASET_LABEL_COLOR,
            },
        },
    ];

    return marginDataset.concat(inputDataset).concat(outputDataset);
};

const removeHypenFromName = pipe<[GrossMargin], string, string>(prop('name'), replace(' - ', ''));

/**
 * Gross margin usage report may contain leading hyphens for the usage name, where the usage has
 * no crop type set. This function removes the hyphens from the usage name and then compares
 * the values, so that the data is sorted alphabetically by usage name.
 */
export const sortGrossMarginByUsageReport = (data: GrossMargin[]) =>
    sort((a, b) => compareString(removeHypenFromName(a), removeHypenFromName(b)), data);
