import type { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import c from 'classnames';
import icon from 'components/icons/icon';

interface VisibilityButtonProps
    extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    visible: boolean;
    iconClassName?: string;
}

const VisibilityButton = ({
    visible,
    iconClassName,
    className,
    ...props
}: VisibilityButtonProps) => {
    return (
        <button className={c('bare-btn bg-transparent focus', className)} {...props}>
            {visible
                ? icon('eye', 'blue', iconClassName)
                : icon('eye-off', 'dark-grey', iconClassName)}
        </button>
    );
};

export default VisibilityButton;
