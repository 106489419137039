import { useAppSelector } from 'system/store';
import { selectDisplayableFarmUsersWithUserFirst } from 'team/farm-users-state';

const useFarmUsers = () => {
    const farmUsers = useAppSelector((state) => selectDisplayableFarmUsersWithUserFirst(state));

    return {
        farmUsers,
    };
};

export { useFarmUsers };
