import { useSelector } from 'react-redux';
import { MeasurementUnit } from 'lib/MeasurementUnit';
import type { AppState } from 'system/store';
import { selectUserLengthMeasurementUnit } from 'users/user-state';

const SpeedUnit = () => {
    const lengthMeasurementUnit = useSelector<AppState, MeasurementUnit>(
        selectUserLengthMeasurementUnit
    );
    return <>{lengthMeasurementUnit === MeasurementUnit.IMPERIAL ? 'mph' : 'km/h'}</>;
};

export default SpeedUnit;
