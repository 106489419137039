import type { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, MouseEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import { setHours, setMinutes, startOfHour, startOfMinute } from 'date-fns';
import { useDateFormat } from 'hooks/useDateFormat';
import { userStringToDateTime } from 'lib/util/date';

import DateButton from './DateButton';
import TimePickerTimeList from './TimePickerTimeList';

interface TimePickerProps {
    value?: Date;
    onChange: (newValue: Date) => void;
    open: boolean;
    openDirection: 'top' | 'bottom';
    onOpen: (newOpen: boolean) => void;
    disabled?: boolean;
    includeDone?: boolean;
    classWithDate?: string;
    withoutIcon?: boolean;
}

const TimePicker = ({
    value,
    onChange,
    open,
    openDirection,
    onOpen,
    disabled,
    includeDone = true,
    classWithDate,
    withoutIcon = false,
}: TimePickerProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    const [inputValue, setInputValue] = useState<string>(
        format(value || startOfHour(new Date()), 'HH:mm')
    );

    const handleTimeOpenToggle = (e?: MouseEvent) => {
        e && e.preventDefault();
        onOpen(!open);
    };

    const handleTimeSelect = (time: string) => {
        const bits = time.split(':');

        let updated = value || new Date();
        updated = setHours(updated, +bits[0]);
        updated = setMinutes(updated, +bits[1]);
        setInputValue(format(updated, 'HH:mm'));
        onChange(startOfMinute(updated));
    };

    const handleDone = () => {
        onChange(userStringToDateTime(value || new Date(), inputValue));
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };
    const handleInputKeyDown = (e: ReactKeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleDone();
        }
    };

    return (
        <div className="TimePicker relative">
            {!open && (
                <DateButton
                    onClick={handleTimeOpenToggle}
                    disabled={disabled}
                    className={value ? classWithDate : undefined}
                >
                    {!withoutIcon && icon('time', 'dark-blue', 'mr-2')}{' '}
                    {value ? format(value, 'HH:mm') : t('set_time')}
                </DateButton>
            )}
            {open && (
                <div className="flex items-center">
                    <input
                        type="text"
                        className="simple-text-input w-24 h-11 mr-2"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyDown}
                    />
                    {includeDone && (
                        <button className="bare-btn focus ring-inset" onClick={handleDone}>
                            {icon('tick')}
                        </button>
                    )}
                </div>
            )}
            {open && (
                <TimePickerTimeList
                    value={value}
                    onSelectTime={handleTimeSelect}
                    openDirection={openDirection}
                />
            )}
        </div>
    );
};

export default TimePicker;
