import type Field from 'fields/Field';
import type { List } from 'immutable';
import { Map } from 'immutable';
import type { OutputReportFieldSummary } from 'outputs/OutputReportSummary';
import type OutputReportSummary from 'outputs/OutputReportSummary';

const updateOutputReportSummaryFieldNames = (
    fields: List<OutputReportFieldSummary>,
    fieldsNameMap: Map<string, string>
) =>
    fields.map((field) =>
        fieldsNameMap.has(field.fieldUuid)
            ? field.set('fieldName', fieldsNameMap.get(field.fieldUuid) as string)
            : field
    );

/**
 * This makes sure that the fields for each output report summary have the correct name. This is
 * necessary because it's possible that sub fields would only have their name & not the parent
 * name as well.
 */
export const normalizeOutputReportSummaries = (
    outputReportSummaries: List<OutputReportSummary>,
    fields: List<Field>
) => {
    const fieldsNameMap = fields.reduce(
        (map, field) => map.set(field.uuid, field.name),
        Map<string, string>()
    );

    return outputReportSummaries.map((ors) =>
        ors.update('fields', (fields) => updateOutputReportSummaryFieldNames(fields, fieldsNameMap))
    );
};
