import { useSelector } from 'react-redux';
import { renderMeasurementUnitShort } from 'lib/MeasurementUnit';
import { selectUserLengthMeasurementUnit } from 'users/user-state';

const ShortDistanceUnit = () => {
    const lengthUnits = useSelector(selectUserLengthMeasurementUnit);
    return <>{renderMeasurementUnitShort(lengthUnits)}</>;
};

export default ShortDistanceUnit;
