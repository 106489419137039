import { useTranslation } from 'react-i18next';
import TopNavOutsidePage from 'auth/TopNavOutsidePage';

const FarmError = () => {
    const { t } = useTranslation();
    return (
        <div className="FarmError">
            <TopNavOutsidePage>
                <div className="floating-box">
                    <div className="header">
                        <h2>{t('error')}</h2>
                    </div>
                    <div className="cell">
                        <p>
                            {t('web_crash1', {
                                defaultValue:
                                    'Sorry, there was an unexpected error loading this farm.',
                            })}
                        </p>
                        <p>
                            {t('web_crash2', {
                                defaultValue:
                                    'Our developers have been notified and will investigate the issue. You can refresh the page to try again or try opening a different farm.',
                            })}
                        </p>
                        <p>
                            {t('web_crash3', {
                                defaultValue:
                                    'Please do not hesitate to contact us if you need further help.',
                            })}
                        </p>
                    </div>
                </div>
            </TopNavOutsidePage>
        </div>
    );
};

export default FarmError;
