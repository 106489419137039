import { Fragment } from 'react';
import { useToggle } from '@fieldmargin/webapp-state';
import c from 'classnames';
import icon from 'components/icons/icon';
import type { InputUnit } from 'inputs/Input';
import ClickableRow from 'view/a11y/ClickableRow';
import FarmRememberingLink from 'view/FarmRememberingLink';
import Num from 'view/Num';

import type { FieldNutrient } from './NutrientReport';

interface NutrientReportFieldRowProps {
    // From parent
    fieldNutrient: FieldNutrient;
    selectedNutrients: string[];
    nutrientUnits: Record<string, InputUnit[]>;
}

const NutrientReportFieldRow = ({
    fieldNutrient,
    selectedNutrients,
    nutrientUnits,
}: NutrientReportFieldRowProps) => {
    const [expanded, toggleExpanded] = useToggle(false);
    return (
        <>
            <ClickableRow onClick={toggleExpanded}>
                <td className="bg-white border-side-only" />
                <td>
                    <div className="flex justify-between gap-2">
                        {fieldNutrient.fieldName}
                        <i className={c(expanded ? 'ion-chevron-up' : 'ion-chevron-down')} />
                    </div>
                </td>
                <td>
                    <Num sigFigs={4} value={fieldNutrient.area} />
                </td>
                {selectedNutrients.map((nutrient, i) => (
                    <Fragment key={i}>
                        {nutrientUnits[nutrient]?.map((unit) => (
                            <td key={unit}>
                                <Num value={fieldNutrient.rates[nutrient]?.[unit] ?? 0} />
                            </td>
                        ))}
                    </Fragment>
                ))}
            </ClickableRow>
            {expanded &&
                fieldNutrient.operationNutrient.map((operationNutrient, i) => (
                    <tr key={i} className="bg-gray-50">
                        <td className="bg-white border-side-only" />
                        <td>
                            <FarmRememberingLink
                                to={`/operations/${operationNutrient.uuid}`}
                                className="block focus"
                            >
                                {icon('field-job', 'blue', 'mr-1')}{' '}
                                {operationNutrient.operationTitle}
                            </FarmRememberingLink>
                        </td>
                        <td>
                            <Num sigFigs={4} value={operationNutrient.workArea} />
                        </td>
                        {selectedNutrients.map((nutrient, i) => (
                            <Fragment key={i}>
                                {nutrientUnits[nutrient]?.map((unit) => (
                                    <td key={unit}>
                                        <Num
                                            value={operationNutrient.rates[nutrient]?.[unit] ?? 0}
                                        />
                                    </td>
                                ))}
                            </Fragment>
                        ))}
                    </tr>
                ))}
        </>
    );
};
export default NutrientReportFieldRow;
