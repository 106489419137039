import { useTranslation } from 'react-i18next';
import type { TOptions } from 'i18next';

const useTranslations = () => {
    const { t } = useTranslation();
    const plural = (key: string, options: TOptions) => {
        return t(key, { ...options, ns: 'notes-plurals' });
    };
    return { t, plural };
};

export { useTranslations };
