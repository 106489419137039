import { useTranslation } from 'react-i18next';

import ErrorMessage from './ErrorMessage';

interface OutOfDateErrorMessageProps {
    className?: string;
}

const OutOfDateErrorMessage = ({ className }: OutOfDateErrorMessageProps) => {
    const { t } = useTranslation();
    return <ErrorMessage className={className}>{t('out_of_date_message')}</ErrorMessage>;
};

export default OutOfDateErrorMessage;
