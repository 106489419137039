import { useTranslation } from 'react-i18next';
import InfoMsg from 'view/InfoMsg';
import Arrow from 'view/svg/Arrow';

import './FarmMapControlsInfo.css';

const FarmMapControlsInfo = () => {
    const { t } = useTranslation();
    return (
        <>
            <InfoMsg className="FarmMapMeasureInfo">
                {t('info_measure')}
                <Arrow className="FarmMapMeasureInfoArrow" />
            </InfoMsg>
            <InfoMsg className="FarmMapPrintInfo">
                <Arrow className="FarmMapPrintInfoArrow" />
                {t('info_print')}
            </InfoMsg>
        </>
    );
};

export default FarmMapControlsInfo;
