import type {
    FieldArrayWithId,
    UseFieldArrayAppend,
    UseFieldArrayRemove,
    UseFormReturn,
} from 'react-hook-form';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '@fieldmargin/webapp-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type Herd from 'herd/Herd';
import { fetchHerds, splitHerd } from 'herd/herd-api';
import { setHerds } from 'herd/herd-state';
import { useAction } from 'lib/hooks';
import { useAppSelector } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { selectCurrentYear } from 'years/years-state';

interface HerdSplitFormValues {
    groups: { name: string; number: string; colour: string }[];
}

export const useHerdSplitForm = (
    herd: Herd
): {
    methods: UseFormReturn<HerdSplitFormValues>;
    fields: FieldArrayWithId<HerdSplitFormValues, 'groups', 'id'>[];
    append: UseFieldArrayAppend<HerdSplitFormValues, 'groups'>;
    remove: UseFieldArrayRemove;
    handleSubmit: SingleParamVoidFunction<HerdSplitFormValues>;
    pending: boolean;
    error: boolean;
    groupTotal: number;
    canSave: boolean;
} => {
    const farm = useAppSelector(selectCurrentFarm);
    const year = useAppSelector(selectCurrentYear);
    const setHerdsAction = useAction(setHerds);

    const navigate = useNavigate();
    const { pending, error, setPromise } = usePromise<Herd[]>((herds) => {
        setHerdsAction(herds);
        navigate(`/farms/${farm.uuid}/livestock`);
    });

    const methods = useForm<HerdSplitFormValues>({
        mode: 'onChange',
        defaultValues: {
            groups: [
                { name: herd.name, number: herd.size.toString(), colour: herd.colour },
                { name: '', number: '', colour: herd.colour },
            ],
        },
    });
    const { fields, append, remove } = useFieldArray<HerdSplitFormValues>({
        control: methods.control,
        name: 'groups',
    });
    const groupWatch = methods.watch('groups');

    const handleSubmit = (values: HerdSplitFormValues) => {
        const [firstGroup, ...groups] = values.groups;
        const sourceHerd = {
            ...herd,
            name: firstGroup.name,
            size: parseInt(firstGroup.number, 10),
            colour: firstGroup.colour,
        };
        setPromise(
            splitHerd(
                farm.uuid,
                year,
                sourceHerd,
                groups.map((group) => ({
                    name: group.name,
                    colour: group.colour,
                    size: parseInt(group.number, 10),
                }))
            ).then(() => fetchHerds(farm.uuid, year))
        );
    };

    const groupTotal = groupWatch.reduce(
        (total, group) => (group.number !== '' ? total + parseInt(group.number, 10) : total),
        0
    );

    const canSave =
        groupWatch.every(
            (group) => group.name !== '' && group.number !== '' && group.colour !== ''
        ) && groupTotal <= herd.size;

    return {
        methods,
        fields,
        append,
        remove,
        handleSubmit,
        pending,
        error,
        groupTotal,
        canSave,
    };
};
