import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import c from 'classnames';
import icon from 'components/icons/icon';
import { AttachmentType } from 'farm-editing/attachments';
import type { EditingType } from 'farm-editing/farm-editing-state';
import { addEditingAttachmentByType, stopEditing } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { getOnboardingClassName, useOnboarding } from 'onboarding/onboarding-state';
import { bindActionCreators } from 'redux';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { trackEvent } from 'utils/trackEvent';
import RememberingLinkButton from 'view/RememberingLinkButton';
import VegetationIcon from 'view/svg/VegetationIcon';

interface FieldActionsProps {
    // From parent
    field: Field;

    // From redux
    farm: Farm;
    addEditingFieldUuid: SingleParamVoidFunction<string>;
    editingType: EditingType | null;
    stopEditing: typeof stopEditing;
}

const FieldActions = ({
    farm,
    field,
    addEditingFieldUuid,
    editingType,
    stopEditing,
}: FieldActionsProps) => {
    const { t } = useTranslation();
    const { activeStep, nextOnboarding } = useOnboarding();

    const handleStartActivity = () => {
        if (editingType !== null) {
            stopEditing();
        }
        addEditingFieldUuid(field.uuid);
        nextOnboarding();
    };

    return (
        <SidebarModule className="flex gap-4 flex-wrap">
            <RememberingLinkButton
                to={`/farms/${farm.uuid}/operations/new`}
                onClick={handleStartActivity}
                className={c(
                    'flex items-center gap-2',
                    getOnboardingClassName('fieldJob', activeStep)
                )}
                disabled={field.archived}
            >
                {icon('field-job', 'white')}
                {t('add_field_job')}
            </RememberingLinkButton>
            <RememberingLinkButton
                to={`/farms/${farm.uuid}/notes/new`}
                onClick={handleStartActivity}
                className="flex items-center gap-2"
                variant="outline"
                disabled={field.archived}
            >
                {icon('note', field.archived ? 'grey' : 'blue')}
                {t('walkthrough_notes_action')}
            </RememberingLinkButton>
            <RememberingLinkButton
                to={`/farms/${farm.uuid}/maps`}
                className="flex items-center gap-2"
                onClick={() =>
                    trackEvent('Field view health prompt clicked', { farmUuid: farm.uuid })
                }
                variant="outline"
                disabled={field.archived}
            >
                <VegetationIcon className="w-6" fill={field.archived ? '#969696' : '#4da4da'} />{' '}
                {t('field_health')}
            </RememberingLinkButton>
        </SidebarModule>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        editingType: state.farmEditingState.editingType,
    }),
    (dispatch) =>
        bindActionCreators(
            { addEditingFieldUuid: addEditingAttachmentByType(AttachmentType.FIELD), stopEditing },
            dispatch
        )
)(FieldActions);
