import { connect } from 'react-redux';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import { Set } from 'immutable';
import type Note from 'notes/Note';
import { useUpdateNoteUsers } from 'notes/note-hooks';
import EditableTaggedUsers from 'sidebar/modules/tagged-users/EditableTaggedUsers';
import type { AppState } from 'system/store';
import { selectDisplayableFarmUsers, selectFarmUsersFromSet } from 'team/farm-users-state';
import { selectUserId } from 'users/user-state';

interface EditableNoteUsersProps {
    // From parent
    note: Note;

    // From redux
    taggedUsers: Set<FarmUser>;
    farmUsers: FarmUser[];
}

const EditableNoteUsers = ({ note, taggedUsers, farmUsers }: EditableNoteUsersProps) => {
    const updateNoteUsers = useUpdateNoteUsers();

    const handleSave = (newTaggedUsers: Set<FarmUser>) =>
        updateNoteUsers(note, newTaggedUsers.map((farmUser) => farmUser.id).toArray());

    return <EditableTaggedUsers value={taggedUsers} farmUsers={farmUsers} onSave={handleSave} />;
};

export default connect((state: AppState, { note }: { note: Note }) => ({
    myUserId: selectUserId(state),
    taggedUsers: Set(selectFarmUsersFromSet(state, Set(note.taggedUserIds))),
    farmUsers: selectDisplayableFarmUsers(state),
}))(EditableNoteUsers);
