import { useEffect } from 'react';
import { stopEditing } from 'farm-editing/farm-editing-state';
import { startNewField } from 'farm-editing/start-editing-reducer';
import { useAppDispatch } from 'system/store';

const useEditingNewFieldEffect = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(startNewField());

        return () => {
            dispatch(stopEditing());
        };
    }, [dispatch]);
};

export { useEditingNewFieldEffect };
