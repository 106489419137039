import { Record } from 'immutable';

class MediaUploading extends Record({
    id: -1 as any,
    parentUuid: '',
    name: '',
    progress: 0,
}) {}

export default MediaUploading;
