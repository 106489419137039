import i18next from 'i18next';
import { Record, Set } from 'immutable';
import type { LastActivity, LegacyLastActivityDTO } from 'lib/LastActivity';
import { deserializeLegacyLastActivity } from 'lib/LastActivity';

import type { ReadOperationFieldDTO } from './OperationField';

export enum OperationType {
    FERTILIZE = 'FERTILIZE',
    HARVEST = 'HARVEST',
    HAY_SILAGE = 'HAY_SILAGE',
    PLANT = 'PLANT',
    SPRAY = 'SPRAY',
    TILL = 'TILL',
    IRRIGATE = 'IRRIGATE',
    OTHER = 'OTHER',
}

const Operation = Record({
    farmUuid: '',
    year: -1,
    uuid: '',
    name: '',
    createdByUserId: -1,
    createdDate: new Date(),
    userCreatedDate: null as Date | null,
    dueDate: null as Date | null,
    completedDate: null as Date | null,
    completedByUserId: null as number | null,
    lastModifiedDate: new Date(),
    lastModifiedByUserId: -1,
    lastActivity: null as LastActivity | null,
    read: false,
    archived: false,
    taggedUserIds: Set<number>(),
    type: OperationType.OTHER,
    status: null as 'TODO' | 'DONE' | null,
    outputUuid: null as string | null,
    version: -1,
});
interface Operation extends ReturnType<typeof Operation> {}

export interface OperationDTO {
    farmUUID: string;
    year: number;
    uuid: string;
    title: string;
    archived: boolean;
    userCreatedDate: number | null;
    dueDate: number | null;
    completedDate: number | null;
    completedByUserId: number | null;
    type: OperationType;
    status: 'TODO' | 'DONE' | null;
    operationOutputUUID: string | null;
    version: number;
}

export interface ReadOperationDTO extends OperationDTO {
    createdDate: number;
    createdByUserId: number;
    lastModifiedDate: number;
    lastModifiedByUserId: number;
    lastActivity: LegacyLastActivityDTO;
    operationFields: ReadOperationFieldDTO[];
    read: boolean;
    taggedUserIds: number[];
}

export const deserializeOperation = (json: ReadOperationDTO) =>
    Operation({
        farmUuid: json.farmUUID,
        year: json.year,
        uuid: json.uuid,
        name: json.title,
        createdByUserId: json.createdByUserId,
        createdDate: new Date(json.createdDate),
        userCreatedDate: json.userCreatedDate ? new Date(json.userCreatedDate) : null,
        dueDate: json.dueDate ? new Date(json.dueDate) : null,
        completedDate: json.completedDate ? new Date(json.completedDate) : null,
        completedByUserId: json.completedByUserId || null,
        lastModifiedDate: new Date(json.lastModifiedDate),
        lastModifiedByUserId: json.lastModifiedByUserId,
        lastActivity: deserializeLegacyLastActivity(json.lastActivity),
        read: json.read,
        archived: json.archived,
        taggedUserIds: Set(json.taggedUserIds),
        type: json.type,
        status: json.status,
        outputUuid: json.operationOutputUUID,
        version: json.version,
    });

export const serializeOperation = (operation: Operation): OperationDTO => ({
    farmUUID: operation.farmUuid,
    year: operation.year,
    uuid: operation.uuid,
    title: operation.name,
    userCreatedDate: operation.userCreatedDate ? operation.userCreatedDate.getTime() : null,
    dueDate: operation.dueDate ? operation.dueDate.getTime() : null,
    completedDate: operation.completedDate ? operation.completedDate.getTime() : null,
    completedByUserId: operation.completedByUserId,
    archived: operation.archived,
    type: operation.type,
    status: operation.status,
    operationOutputUUID: operation.outputUuid,
    version: operation.version,
});

export default Operation;

const operationTypeNames = {
    [OperationType.FERTILIZE]: 'fertilize',
    [OperationType.HARVEST]: 'harvest',
    [OperationType.HAY_SILAGE]: 'silage',
    [OperationType.PLANT]: 'plant',
    [OperationType.SPRAY]: 'spray',
    [OperationType.TILL]: 'till',
    [OperationType.IRRIGATE]: 'irrigate',
};
export const getOperationTypeName = (type: OperationType) => {
    return i18next.t(
        type === OperationType.OTHER ? 'other' : `operation_type_${operationTypeNames[type]}`
    );
};

export const isOperationHarvesting = (operation: Operation) =>
    [OperationType.HARVEST, OperationType.HAY_SILAGE].includes(operation.type);
