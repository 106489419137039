import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess, loadingFarm } from 'farms/farm-loading-state';

export interface MembershipState {
    finishAt: Date | null;
    trialAvailable: boolean;
}

const initialState: MembershipState = {
    finishAt: null,
    trialAvailable: false,
};

const membershipSlice = createSlice({
    name: 'membership',
    initialState,
    reducers: {
        setMembership: (state, { payload }: PayloadAction<MembershipState>) => {
            state.finishAt = payload.finishAt;
            state.trialAvailable = payload.trialAvailable;
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(loadingFarm.toString(), () => initialState)
            .addCase(
                loadedFarmSuccess.toString(),
                (state, { payload }: PayloadAction<LoadedFarmSuccessPayload>) => {
                    state.finishAt = payload.membershipFinishAt;
                    state.trialAvailable = payload.trialAvailable;
                }
            ),
});

export const { setMembership } = membershipSlice.actions;
export const membershipReducer = membershipSlice.reducer;
