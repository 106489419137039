import { useTranslation } from 'react-i18next';
import FieldSelector from 'fields/sidebar/field-selector/FieldSelector';
import FieldSearchBar from 'fields/sidebar/FieldSearchBar';
import FieldSortAndFilter from 'fields/sidebar/FieldSortAndFilter';
import SidebarModule from 'sidebar/modules/SidebarModule';

const OperationFieldsSelection = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="non-scrolling">
                <SidebarModule editing>
                    <h3>{t('create_note_attach_field')}</h3>
                    <p>{t('create_note_attach_field_desc')}</p>
                    <div className="flex justify-between items-center">
                        <FieldSearchBar className="w-[40%]" />
                        <FieldSortAndFilter />
                    </div>
                </SidebarModule>
            </div>
            <div className="scrolling">
                <FieldSelector />
            </div>
        </>
    );
};

export default OperationFieldsSelection;
