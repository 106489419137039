import {
    deserialize,
    GeoFeature,
    GeoFeatureCollection,
    GeoPoint,
    Projection,
    serialize,
} from '@fieldmargin/webapp-geo';
import { isBefore, startOfDay, subDays, subMonths, subYears } from 'date-fns';
import { List, Record } from 'immutable';
import HistoryReading from 'sensors/HistoryReading';
import SensorHistory from 'sensors/SensorHistory';
import { canGraph } from 'sensors/sensors-util';

import type { MetricDTO } from '../Metric';
import type Metric from '../Metric';
import { deserializeMetric, serializeMetric } from '../Metric';
import type Reading from '../Reading';
import { deserializeReading } from '../Reading';

import type ManualSensorReadingGroup from './ManualSensorReadingGroup';

class ManualSensor extends Record({
    id: null as string | null, // null when a new one is created
    name: '',
    location: GeoPoint(),
    updatedAt: null as Date | null,
    latestReadings: List<Reading>(),
    metrics: List<Metric>(),
    active: true, // Manual sensors are always active
    readingGroups: null as List<ManualSensorReadingGroup> | null,
}) {
    getName() {
        return this.name;
    }
    getLocation() {
        return this.location;
    }
    getGeoJson() {
        return GeoFeatureCollection({
            features: List([GeoFeature({ id: `sensor-${this.id}`, geometry: this.location })]),
        });
    }
    hasGraphableMetrics() {
        if (!this.readingGroups || this.readingGroups.size < 2) {
            return false;
        }

        return this.metrics.reduce((hasGraphableMetrics, metric) => {
            return hasGraphableMetrics || canGraph(metric.type);
        }, false);
    }

    createSensorHistory(selectedPeriod: string | null, selectedMetricId: string | null) {
        let start: Date | undefined;

        switch (selectedPeriod) {
            case 'THREE_DAY':
                start = subDays(new Date(), 3);
                break;
            case 'WEEK':
                start = subDays(new Date(), 7);
                break;
            case 'MONTH':
                start = subMonths(new Date(), 1);
                break;
            case 'YEAR':
                start = subYears(new Date(), 1);
                break;
            default:
                start = undefined;
        }

        if (start) {
            start = startOfDay(start);
        }

        const historyReadings = this.readingGroups
            ? this.readingGroups
                  .map((readingGroup) => {
                      if (start && isBefore(readingGroup.timestamp, start)) {
                          return null;
                      }
                      const reading = readingGroup.readings.find(
                          (reading) => reading.metricId === selectedMetricId
                      );
                      if (reading && reading.value !== null) {
                          return HistoryReading({
                              timestamp: readingGroup.timestamp,
                              value: reading.value,
                          });
                      }
                      return null;
                  })
                  .filter((i) => i)
            : List();
        return SensorHistory({
            period: selectedPeriod || undefined,
            readings: historyReadings,
        });
    }
}

export interface ManualSensorResponseDTO {
    farmUUID: string;
    id: string;
    latestReadings: MetricDTO[];
    location: any; // GeoPoint like
    name: string;
    updatedAt: number | null;
}
export interface ManualSensorRequestDTO {
    farmUUID: string;
    id: string | null;
    location: any; // GeoPoint like
    name: string;
    metrics: MetricDTO[];
}

export const deserializeManualSensor = (json: ManualSensorResponseDTO) =>
    new ManualSensor({
        id: json.id,
        name: json.name,
        location: deserialize(json.location, Projection.LNG_LAT) as GeoPoint,
        updatedAt: json.updatedAt ? new Date(json.updatedAt) : null,
        latestReadings: List(
            json.latestReadings.map((metric) =>
                deserializeReading(
                    {
                        metricId: metric.id as string,
                        value: metric.value as string,
                    },
                    metric
                )
            )
        ),
        metrics: List(json.latestReadings ? json.latestReadings.map(deserializeMetric) : []),
    });

export const serializeManualSensor = (
    farmUuid: string,
    manualSensor: ManualSensor
): ManualSensorRequestDTO => ({
    farmUUID: farmUuid,
    id: manualSensor.id ? manualSensor.id : null,
    name: manualSensor.name,
    location: serialize(manualSensor.location),
    metrics: manualSensor.metrics.map(serializeMetric).toArray() as MetricDTO[],
});

export default ManualSensor;
