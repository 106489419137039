import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import DancingWheat from '@fieldmargin/webapp-styling/components/dancing-wheat';
import FarmError from 'components/farm/FarmError';
import { addFarm } from 'farms/farms-state';
import { removeInvite } from 'farms/invites/farm-invites-state';
import FarmInvitationMap from 'farms/invites/FarmInvitationMap';
import type FarmInvite from 'farms/invites/FarmInvite';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import FieldmarginLogo from 'top-nav/FieldmarginLogo';
import HelpNavItem from 'top-nav/HelpNavItem';
import TopNav from 'top-nav/TopNav';

import EnvelopeSvg from './envelope.svg';
import { acceptInvite, declineInvite } from './invite-save';

import './FarmInvitations.css';

interface FarmInvitationProps {
    invites?: FarmInvite[];
    fetchFarmInvitesPending: boolean;
    removeInvite: typeof removeInvite;
    addFarm: typeof addFarm;
}

const FarmInvitation = ({
    invites,
    fetchFarmInvitesPending,
    removeInvite,
    addFarm,
}: FarmInvitationProps) => {
    const navigate = useNavigate();

    const [farmOverUserLimit, setFarmOverUserLimit] = useState(false);
    const { pending: acceptPending, setPromise: setAcceptPromise } = usePromise<
        Farm | 'over-user-limit'
    >((response) =>
        response === 'over-user-limit'
            ? setFarmOverUserLimit(true)
            : navigate(`/farms/${response.uuid}/notes`, { replace: true })
    );
    const { pending: declinePending, setPromise: setDeclinePromise } = usePromise(
        () => farmOverUserLimit && setFarmOverUserLimit(false)
    );

    if (!invites || invites.length === 0) {
        return <FarmError />;
    }

    if (fetchFarmInvitesPending) {
        return (
            <div style={{ height: '100vh' }}>
                <DancingWheat />
            </div>
        );
    }

    const invite = invites[0];
    const inviter = invite.inviter;
    const inviterName = inviter ? `${inviter.firstName} ${inviter.lastName}` : 'Unknown farmer';

    const handleDecline = () => {
        setDeclinePromise(declineInvite(invite, removeInvite));
    };

    const handleAccept = () => {
        setAcceptPromise(acceptInvite(invite, addFarm, removeInvite));
    };

    return (
        <div className="FarmInvitation">
            <TopNav>
                <FieldmarginLogo />
                <HelpNavItem className="ml-auto" />
            </TopNav>
            <div className="farm-invitation-wrapper">
                <div className="farm-invitation-body">
                    <img
                        src={EnvelopeSvg}
                        className="envelope"
                        alt="An icon showing a closed envelope"
                    />
                    <h2>You have a pending invitation to a farm.</h2>
                    <p>{`${inviterName} invited you to join ${invite.farm.name}`}</p>
                    {farmOverUserLimit ? (
                        <>
                            <p className="mx-4">
                                This farm has exceeded the maximum team size. Please ask the farm
                                owner to upgrade in order to accept this invite.
                            </p>
                            <div className="modal-footer">
                                <Button
                                    disabled={acceptPending || declinePending}
                                    onClick={handleDecline}
                                >
                                    {declinePending ? 'Declining' : 'Decline & continue'}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="invitation-map">
                                <FarmInvitationMap farm={invite.farm} maxZoom={16} />
                            </div>
                            <div className="modal-footer">
                                <div className="button-container">
                                    <Button
                                        disabled={acceptPending || declinePending}
                                        className="invite-button"
                                        onClick={handleAccept}
                                    >
                                        {acceptPending ? 'Accepting' : 'Accept'}
                                    </Button>
                                    <Button
                                        disabled={acceptPending || declinePending}
                                        variant="danger-outline"
                                        className="invite-button"
                                        onClick={handleDecline}
                                    >
                                        {declinePending ? 'Declining' : 'Decline'}
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        invites: state.farmInvites.invites,
        fetchFarmInvitesPending: state.farmInvites.fetchFarmInvitesPending,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                removeInvite,
                addFarm,
            },
            dispatch
        )
)(FarmInvitation);
