import type { PropsWithChildren } from 'react';
import { useDateFormat } from 'hooks/useDateFormat';

interface ItemHistoryDayProps
    extends PropsWithChildren<{
        date: Date;
    }> {}

const ItemHistoryDay = ({ date, children }: ItemHistoryDayProps) => {
    const { format } = useDateFormat();
    return (
        <>
            <p className="ItemHistoryDate">{format(date, 'do MMMM yyyy')}</p>
            {children}
        </>
    );
};

export default ItemHistoryDay;
