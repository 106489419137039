import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type { InputReportSummary } from 'inputs/InputReportSummary';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import { selectUserAreaMeasurementUnit } from 'users/user-state';

import { getYearInputSummaryApi } from '../reporting-api';

import type { InputSummarySchema } from './useInputSummaryContext';

interface InputSummaryProps {
    farm: Farm;
    year: number;
    areaMeasurementUnit: MeasurementUnit;
}

const InputSummary = ({ farm, year, areaMeasurementUnit }: InputSummaryProps) => {
    const [reportData, setReportData] = useState<InputReportSummary[]>();
    const [filter, setFilter] = useState('all');
    const { pending, error, setPromise } = usePromise<InputReportSummary[]>(setReportData);

    const refreshReport = () => {
        farm.plan.reportingLevel !== 'NO_REPORTING' &&
            setPromise(getYearInputSummaryApi(farm.uuid, year, null));
    };

    // On mount we'll load the report with the "all" filter.
    useEffect(() => {
        refreshReport();
    }, []);

    const handleFilterChange = (filter: string) => {
        setPromise(
            getYearInputSummaryApi(farm.uuid, year, filter === 'all' ? null : filter.toUpperCase())
        );
        setFilter(filter);
    };

    return (
        <Outlet
            context={
                {
                    farm,
                    year,
                    pending,
                    error,
                    reportData,
                    filter,
                    onFilterChange: handleFilterChange,
                    areaMeasurementUnit: areaMeasurementUnit,
                    onSaveInputCost: refreshReport,
                } satisfies InputSummarySchema
            }
        />
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        year: state.yearsState.currentYear,
        areaMeasurementUnit: selectUserAreaMeasurementUnit(state),
    }),
    (dispatch) => bindActionCreators({ changeModal }, dispatch)
)(InputSummary);
