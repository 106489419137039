import { useTranslation } from 'react-i18next';
import type { FieldProps } from 'informed';
import { TextArea } from 'informed';
import SidebarModule from 'sidebar/modules/SidebarModule';

import './NoteNewComment.css';

interface NoteNewCommentProps extends FieldProps<any, any> {
    field: string;
}

const NoteNewComment = ({ field, ...props }: NoteNewCommentProps) => {
    const { t } = useTranslation();
    return (
        <SidebarModule className="NoteNewComment" editing={true}>
            <div className="float-right text-xs text-gray-450">{t('create_note_optional')}</div>
            <h4>{t('description')}</h4>
            <div className="form-field">
                <TextArea
                    id={field}
                    field={field}
                    validateOnBlur
                    validateOnChange
                    autoComplete="off"
                    {...props}
                />
            </div>
        </SidebarModule>
    );
};

export default NoteNewComment;
