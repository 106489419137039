import { useOutletContext } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { MeasurementUnit } from 'lib/MeasurementUnit';

import type { InputCosts } from './InputCost';

export interface InputCostsContextSchema {
    inputCosts: InputCosts;
    measurementUnit: MeasurementUnit;
    farm: Farm;
}

export const useInputCostsContext = () => {
    return useOutletContext<InputCostsContextSchema>();
};
