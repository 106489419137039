import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { setEditingData, startAutoBoundary, stopEditing } from 'farm-editing/farm-editing-state';
import { useEditSelectedFields } from 'hooks/useEditSelectedFields';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import type { AutoBoundaryStepsSchema } from 'types';

import AutoBoundaryEditBoundaries from './AutoBoundaryEditBoundaries';
import AutoBoundaryNameForm from './AutoBoundaryNameForm';
import AutoBoundarySelectArea from './AutoBoundarySelectArea';
import AutoBoundarySelectFields from './AutoBoundarySelectFields';

interface AutoBoundaryContainerProps {
    // From redux
    editingGeoFeatureCollection: GeoFeatureCollection | null;
    startAutoBoundary: typeof startAutoBoundary;
    stopEditing: typeof stopEditing;
    setEditingData: typeof setEditingData;
}

const AutoBoundaryContainer = ({
    editingGeoFeatureCollection,
    startAutoBoundary,
    stopEditing,
    setEditingData,
}: AutoBoundaryContainerProps) => {
    const { handlerEditSelectedFields } = useEditSelectedFields();
    const [step, setStep] = useState<AutoBoundaryStepsSchema>('select-area');

    useEffect(() => {
        startAutoBoundary();
        return () => {
            stopEditing();
        };
    }, []);

    const handleSelectAreaDone = () => {
        setStep('select-fields');
    };

    const handleSelectFieldsDone = () => {
        handlerEditSelectedFields();
        setStep('edit-boundaries');
    };

    const handleEditBoundariesDone = () => {
        setEditingData({});
        setStep('name-fields');
    };

    return (
        <div className="scrollable">
            {step === 'select-area' && <AutoBoundarySelectArea onDone={handleSelectAreaDone} />}
            {step === 'select-fields' && (
                <AutoBoundarySelectFields onDone={handleSelectFieldsDone} />
            )}
            {step === 'edit-boundaries' && (
                <AutoBoundaryEditBoundaries onDone={handleEditBoundariesDone} />
            )}
            {step === 'name-fields' && editingGeoFeatureCollection !== null && (
                <AutoBoundaryNameForm />
            )}
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                startAutoBoundary,
                stopEditing,
                setEditingData,
            },
            dispatch
        )
)(AutoBoundaryContainer);
