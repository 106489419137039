import { useRef, useState } from 'react';
import Button from '@fieldmargin/webapp-styling/components/button';
import { cutHoleInField, reduceFieldByMarginMeters } from 'fields/sub-fields-manipulations';
import { selectSelectedSubFields } from 'fields/sub-fields-selectors';
import { updateSubFieldAndAddNew } from 'fields/sub-fields-state';
import type { TurfFns } from 'lib/turf/turf-utils';
import { isNumericPositive } from 'lib/util/number';
import { useAppDispatch, useAppSelector } from 'system/store';
import ErrorMessage from 'view/ErrorMessage';
import { selectCurrentYear } from 'years/years-state';

import { createSubField } from '../SubField';

interface SubFieldMarginProps {
    onDone: VoidFunction;
    turf: TurfFns;
}

type SubFieldMarginError = 'unknown' | 'too-big' | 'too-small' | 'split-field';

const errorMsgs: Record<SubFieldMarginError, string> = {
    unknown: 'Something went wrong adding this margin, please try again.',
    'too-big': 'Cannot create a margin this big',
    'too-small': 'Cannot create a margin because it would leave a field with no area',
    'split-field': 'Cannot add a margin of this size because it would split the sub field',
};

const SubFieldMargin = ({ onDone, turf }: SubFieldMarginProps) => {
    const dispatch = useAppDispatch();
    // Margin only operates on a single sub field, so choose the first one.
    // Option should be disabled in menu if more than one is selected but good to handle this
    // just in case.
    const subField = useAppSelector(selectSelectedSubFields).get(0);
    const year = useAppSelector(selectCurrentYear);
    const [error, setError] = useState<SubFieldMarginError>();
    const inputRef = useRef<HTMLInputElement>(null);

    if (!subField || !subField.field.geoJson) {
        return null;
    }

    const handleApply = () => {
        try {
            setError(undefined);
            if (inputRef.current !== null && isNumericPositive(inputRef.current.value)) {
                const margin = parseInt(inputRef.current.value, 10);
                if (margin === 0) {
                    onDone();
                    return;
                }

                const updatedField = reduceFieldByMarginMeters(subField.field, margin, turf);
                const marginField = cutHoleInField(subField.field, updatedField, turf);

                dispatch(
                    updateSubFieldAndAddNew({
                        existingSubField: subField.set('field', updatedField),
                        newSubField: createSubField(marginField, year),
                    })
                );
            }
            onDone();
        } catch (e) {
            switch (e.message) {
                case 'buffer-no-geometry':
                    setError('too-big');
                    break;
                case 'buffer-multi-polygon':
                    setError('split-field');
                    break;
                case 'buffer-no-area':
                    setError('too-small');
                    break;
                case 'difference-no-feature':
                    onDone();
                    break;
                default:
                    setError('unknown');
                    throw new Error(e);
            }
        }
    };

    return (
        <div>
            <p className="mb-2">Add a margin in the inner boundary of the sub-field</p>
            <div className="flex">
                <div>
                    <label htmlFor="margin" className="block font-medium">
                        Margin width
                    </label>
                    <input
                        id="margin"
                        ref={inputRef}
                        type="text"
                        className="simple-text-input w-1/2 inline mr-2"
                    />
                    <span>m</span>
                </div>
                <Button
                    variant="danger-outline"
                    small
                    onClick={onDone}
                    className="self-end mr-4 mb-1"
                >
                    Cancel
                </Button>
                <Button small onClick={handleApply} className="self-end mb-1">
                    Apply
                </Button>
            </div>
            {error !== undefined && (
                <ErrorMessage className="mt-2">{errorMsgs[error]}</ErrorMessage>
            )}
        </div>
    );
};

export default SubFieldMargin;
