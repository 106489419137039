import type { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import { useDocumentEventListener } from 'lib/hooks';

interface SidebarHeaderMenuProps {
    children: ReactNode;
    open: boolean;
    onToggleOpen: VoidFunction;
}

const SidebarHeaderMenu = ({ children, open, onToggleOpen }: SidebarHeaderMenuProps) => {
    const { t } = useTranslation();
    useDocumentEventListener(
        'keyup',
        (e: KeyboardEvent) => {
            if (open && e.code === 'Escape') {
                onToggleOpen();
            }
        },
        [open, onToggleOpen]
    );

    const onToggle = (e: MouseEvent) => {
        e.preventDefault();
        onToggleOpen();
    };

    const menu = open ? <div className="SidebarHeaderMenu">{children}</div> : null;

    return (
        <>
            <button
                onClick={onToggle}
                className="bare-btn SidebarHeaderLink w-1/4"
                aria-expanded={open}
            >
                {icon('more', 'dark-blue', 'mr-2')} {t('more')}
            </button>
            {menu}
        </>
    );
};

export default SidebarHeaderMenu;
