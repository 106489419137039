import { ToggleFilters } from 'components/filters/toggle-filters';
import { SearchBar } from 'components/search-bar';
import SidebarModule from 'sidebar/modules/SidebarModule';
import { notesSearchState, updateNotesSearch } from 'store/slices/notes.slice';
import { useAppDispatch, useAppSelector } from 'system/store';

import styles from './NoteListSearch.module.css';

const NoteListSearch = () => {
    const dispatch = useAppDispatch();
    const value = useAppSelector(notesSearchState);

    const onSearchHanlder = (value: string) => {
        dispatch(updateNotesSearch(value));
    };

    return (
        <SidebarModule editing className={styles.searchButtonContainer}>
            <SearchBar
                searchFunction={onSearchHanlder}
                value={value}
                placeholderI18nKey="activity_list_search"
                className={styles.search}
            />

            <ToggleFilters />
        </SidebarModule>
    );
};

export { NoteListSearch };
