import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { selectCurrentFarm } from 'farms/farms-state';
import { Form } from 'informed';
import { FieldColours } from 'lib/colours';
import { pipe } from 'ramda';
import type { AppState } from 'system/store';
import type { UnaryPromiseFunction } from 'system/types';
import ErrorMessage from 'view/ErrorMessage';
import ColourSelectorField from 'view/form/ColourSelectorField';
import OptionalLabel from 'view/form/OptionalLabel';
import TextInputFormField from 'view/form/TextInputFormField';
import { required } from 'view/form/validations';

import CropTypeFormField from './CropTypeFormField';

interface FieldUsageFormProps {
    farm: Farm;
    initialValues?: FieldUsageFormValues;
    onCancel: VoidFunction;
    onSubmit: UnaryPromiseFunction<FieldUsageFormValues>;
}

export interface FieldUsageFormValues {
    cropCode: string;
    cropName: string;
    description: string;
    colour: string;
}

const FieldUsageForm = ({ farm, initialValues, onCancel, onSubmit }: FieldUsageFormProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise();

    const validateCropType = required(t('crop_type_validation'));
    const validateColour = required(t('colour_validation'));

    const handleSubmit = pipe<[FieldUsageFormValues], Promise<any>, any>(onSubmit, setPromise);

    return (
        <Form<FieldUsageFormValues>
            onSubmit={handleSubmit}
            className="FieldUsageForm stacked-form"
            initialValues={initialValues}
        >
            {({ formState }) => (
                <>
                    <CropTypeFormField
                        farm={farm}
                        field="crop"
                        validate={validateCropType}
                        initialValue={initialValues?.cropName}
                    />
                    <TextInputFormField
                        field="description"
                        label={
                            <>
                                <span className="block mb-1">
                                    {t('description')} <OptionalLabel />
                                </span>
                                <span className="block text-sm text-gray-550">
                                    {t('field_usages_description_label')}
                                </span>
                            </>
                        }
                        error={formState.errors ? formState.errors.description : null}
                    />
                    <ColourSelectorField
                        field="colour"
                        label="Colour"
                        validate={validateColour}
                        colours={FieldColours}
                    />
                    {error && <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>}
                    <div className="pb-5 flex justify-between">
                        <Button
                            className="flex-grow mr-4"
                            variant="danger-outline"
                            onClick={onCancel}
                        >
                            {t('cancel')}
                        </Button>
                        <SubmitButton disabled={pending} className="flex-grow">
                            {pending ? t('saving') : t('save')}
                        </SubmitButton>
                    </div>
                </>
            )}
        </Form>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
}))(FieldUsageForm);
