import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise, useToggle } from '@fieldmargin/webapp-state';
import icon from 'components/icons/icon';
import { stopEditing } from 'farm-editing/farm-editing-state';
import { startEditingSubFields } from 'farm-editing/start-editing-reducer';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { getYearFieldUsageUuid } from 'fields/Field';
import { selectField, selectFieldSubFieldsForCurrentYear } from 'fields/fields-selectors';
import { updateAndRemoveFields } from 'fields/fields-state';
import { resetSubFieldColourIndex } from 'fields/sub-field-colours';
import { syncSubFields } from 'fields/sub-field-utils';
import {
    startSubFieldsFresh,
    startSubFieldsFromExisting,
    stopSubFields,
    SubFieldTool,
} from 'fields/sub-fields-state';
import withPageField from 'fields/withPageField';
import { List, Set } from 'immutable';
import { Form } from 'informed';
import { preventDefaultAnd } from 'lib/dom';
import { useShowHintOnMount } from 'lib/firebase/firebase-hints-api';
import { useZoomToFeatureOnMount } from 'lib/hooks';
import { addOrRemoveInSet } from 'lib/immutil';
import { selectCurrentYearNotesForField } from 'notes/notes-selectors';
import { selectCurrentYearOperationsForField } from 'operations/operations-selectors';
import { isNil, unless } from 'ramda';
import { bindActionCreators } from 'redux';
import CreateHeader from 'sidebar/header/CreateHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import { useAppDispatch } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

import type SubField from '../SubField';
import { convertFieldToSubField, resetSubFieldId } from '../SubField';

import SubFieldsDataCopy from './data-copy/SubFieldsDataCopy';
import SubFieldsHelp from './help/SubFieldsHelp';
import SubFieldsList from './SubFieldsList';
import SubFieldsMainActions from './SubFieldsMainActions';
import SubFieldsStartFresh from './SubFieldsStartFresh';

interface SubFieldsContainerProps {
    farm: Farm;
    year: number;
    parentField?: Field;
    existingSubFields: List<Field>;
    hasOperations: boolean;
    hasNotes: boolean;

    subFields: List<SubField>;
    subFieldHistory: List<List<SubField>>;
    selectedTool: SubFieldTool;

    startSubFieldsFresh: typeof startSubFieldsFresh;
    updateAndRemoveFields: typeof updateAndRemoveFields;
}

const SubFieldsContainer = ({
    farm,
    year,
    parentField,
    existingSubFields,
    hasNotes,
    hasOperations,
    subFields,
    subFieldHistory,
    selectedTool,
    startSubFieldsFresh,
    updateAndRemoveFields,
}: SubFieldsContainerProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useZoomToFeatureOnMount(parentField?.geoJson ?? undefined);
    useStartSubFieldEditing(existingSubFields, parentField);
    const { pending, error, setPromise } = usePromise<{ updated: Field[]; deleted: string[] }>(
        ({ updated, deleted }) => {
            updateAndRemoveFields({ update: List(updated), remove: List(deleted) });
            navigate(`/farms/${farm.uuid}/fields/${parentField ? parentField.uuid : ''}`);
        }
    );
    const [formErrors, setFormErrors] = useState<Set<string>>(Set());
    const [showHelpModal, toggleShowHelpModal] = useToggle(false);
    const [showCopyModal, toggleShowCopyModal] = useToggle(false);

    useShowHintOnMount('subFieldHelp', toggleShowHelpModal);

    if (parentField === undefined || (!farm.plan.subFields && !farm.freeTrial)) {
        return <Navigate to={`farms/${farm.uuid}/fields`} />;
    }

    const handleSubFieldStartFresh = preventDefaultAnd(() => startSubFieldsFresh(parentField));

    const handleHelpClick = preventDefaultAnd(toggleShowHelpModal);

    const handleSubmit = () => {
        if (formErrors.size === 0 && parentField) {
            const copyData =
                existingSubFields.size === 0 &&
                (getYearFieldUsageUuid(year, parentField) !== undefined ||
                    hasNotes ||
                    hasOperations);
            copyData
                ? toggleShowCopyModal()
                : setPromise(syncSubFields(farm.uuid, existingSubFields, subFields));
        }
    };

    const handleToggleError = (id: string) => {
        setFormErrors(addOrRemoveInSet(formErrors, id));
    };

    const handleRemoveSubField = (id: string) => {
        if (formErrors.contains(id)) {
            setFormErrors(formErrors.filter((errorId) => errorId !== id));
        }
    };

    return (
        <>
            {showHelpModal && <SubFieldsHelp onClose={toggleShowHelpModal} />}
            {showCopyModal && (
                <SubFieldsDataCopy parentField={parentField} onClose={toggleShowCopyModal} />
            )}
            <Form onSubmit={handleSubmit} className="scrollable">
                <div className="non-scrolling">
                    <CreateHeader
                        isSaving={pending}
                        backPath={`/farms/${farm.uuid}/fields/${parentField.uuid}`}
                    />
                    {error && (
                        <SidebarError
                            title={t('failed_to_save')}
                            message={t('something_went_wrong')}
                        />
                    )}
                </div>
                <div className="scrolling">
                    <SidebarModule editing>
                        <h2 className="mb-0 flex items-center">
                            {t('field_details_subfields_add')}{' '}
                            <button className="bare-btn ml-4" onClick={handleHelpClick}>
                                {icon('info', 'black', 'w-4')}
                            </button>
                        </h2>
                    </SidebarModule>
                    <SubFieldsMainActions
                        year={year}
                        parentField={parentField}
                        drawingNew={selectedTool === SubFieldTool.NEW}
                        history={subFieldHistory}
                    />
                    <SubFieldsStartFresh handleStartFresh={handleSubFieldStartFresh} />
                    <SubFieldsList
                        parentField={parentField}
                        onToggleError={handleToggleError}
                        onRemoveSubField={handleRemoveSubField}
                    />
                </div>
            </Form>
        </>
    );
};

export default withPageField(
    connect(
        (state: AppState, { field }: { field?: Field }) => ({
            farm: selectCurrentFarm(state),
            year: selectCurrentYear(state),
            parentField: unless<string | undefined, Field | undefined>(
                isNil,
                (parentField) => selectField(state, parentField as string),
                field?.uuid
            ),
            existingSubFields: selectFieldSubFieldsForCurrentYear(state, field?.uuid),
            hasOperations: selectCurrentYearOperationsForField(state, field?.uuid).size > 0,
            hasNotes: selectCurrentYearNotesForField(state, field?.uuid).size > 0,

            subFields: state.subFieldsState.subFields,
            subFieldHistory: state.subFieldsState.history,
            selectedTool: state.subFieldsState.selectedTool,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    startSubFieldsFresh,
                    updateAndRemoveFields,
                },
                dispatch
            )
    )(SubFieldsContainer)
);

/**
 * Sets up state for editing sub fields whether there are existing sub fields or not.
 */
const useStartSubFieldEditing = (existingSubFields: List<Field>, parentField?: Field) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (existingSubFields && parentField) {
            dispatch(startEditingSubFields());
            const subFields = existingSubFields.map<SubField>(convertFieldToSubField);
            if (subFields.size > 0) {
                dispatch(startSubFieldsFromExisting(subFields));
            } else {
                dispatch(startSubFieldsFresh(parentField));
            }
        }

        // Clean up on dismount.
        return () => {
            dispatch(stopSubFields());
            dispatch(stopEditing());
            resetSubFieldId();
            resetSubFieldColourIndex();
        };
    }, [parentField, existingSubFields]);
};
