interface VegetablesIconProps {
    className?: string;
    fill?: string;
}

const VegetablesIcon = ({ className, fill = '#4da4da' }: VegetablesIconProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 56 60"
            className={className}
        >
            <g id="Group_261" transform="translate(-768.534 -1908.236)">
                <g id="noun_Carrot_1436124" transform="translate(789.385 1910.866)">
                    <path
                        id="Path_204"
                        style={{ fill }}
                        d="M30.2,10.8c-0.5-0.5-1.3-0.5-1.7,0c0,0,0,0,0,0L24.4,15c0.2-1.7,0-3.4-1.3-4.7
			c-0.5-0.5-1.3-0.5-1.7,0c-0.5,0.5-0.5,1.3,0,1.7l0,0c1.2,1.2,0.5,3.8-0.2,5.6c-1.5-1-4-2.2-7-1.2C9.8,17.9-1.6,34.1-4.3,41.3
			c-0.7,1.9-0.8,3.3-0.3,4.2c0.1,0.2,0.2,0.3,0.4,0.4c0.4,0.2,0.9,0.4,1.4,0.4c0.9,0,1.8-0.2,2.7-0.6c7-2.6,23.3-14.3,24.7-18.6
			c1-3-0.2-5.5-1.2-7c1.7-0.7,4.3-1.4,5.5-0.2c0.5,0.5,1.3,0.5,1.7,0c0.5-0.5,0.5-1.3,0-1.7c-1.3-1.3-3-1.5-4.7-1.3l4.1-4.2
			C30.7,12.1,30.7,11.3,30.2,10.8z M22.3,26.3c-0.9,2.7-12.7,11.9-21,16.1c-1.2,0.7-2.4,1.1-3.7,1.4c0.3-1.3,0.7-2.6,1.4-3.8
			c0.9-1.7,0.9-2.4,2.2-4.3L2,36.5c0.5,0.5,1.3,0.5,1.7,0c0.5-0.5,0.5-1.3,0-1.7l-1.2-1.2c0.7-1.1,1.4-2.3,2.2-3.4L6.6,32
			c0.5,0.5,1.3,0.5,1.7,0c0.5-0.5,0.5-1.3,0-1.7l-2.2-2.2C7,27,7.8,26,8.6,25l2.5,2.5c0.5,0.5,1.3,0.5,1.7,0c0.5-0.5,0.5-1.3,0-1.7
			l0,0l-2.6-2.6c0,0-0.1,0-0.1-0.1c2.1-2.4,3.8-4.1,4.7-4.4c0.5-0.2,1-0.2,1.4-0.2c2.4,0,4.2,1.8,4.3,1.9
			C20.8,20.6,23.3,23.3,22.3,26.3L22.3,26.3z"
                    />
                </g>
                <path
                    id="Subtraction_1"
                    style={{ fill }}
                    d="M784.9,1949.5c-6.8,0-12.4-5.2-12.4-11.6c0-6.4,5.5-11.6,12.4-11.6c0.8,0,1.7,0.1,2.5,0.3
		c0-1.6,0.6-5.4,6.3-7.2c0.1,0,0.2-0.1,0.3-0.1c0.5,0,0.8,0.3,1,0.7c0.2,0.5-0.1,1.1-0.7,1.3c0,0,0,0,0,0c-1.9,0.6-3.3,1.6-4.1,2.8
		c-0.5,0.7-0.7,1.6-0.7,2.4c0.8-0.2,1.7-0.3,2.5-0.3c3.5,0,6.9,1.4,9.4,4l-1.7,1.2c-0.9-0.9-1.9-1.6-3-2.2c-1.3,0-2.1,0.3-2.6,1
		c-0.8,1-0.4,2.6-0.4,2.6c0.1,0.4-0.1,0.9-0.4,1.1c-0.4,0.2-0.9,0.2-1.2-0.1c-0.9-0.8-2-1.2-3.2-1.2c-1.1,0-2.2,0.5-3.1,1.2
		c-0.2,0.2-0.4,0.3-0.7,0.3c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.6-0.5-0.6-0.9c0.1-1.1-0.3-2.1-1-2.9c-0.7-0.6-1.6-0.9-2.5-0.9
		c-0.1,0-0.1,0-0.1,0c-3.4,1.5-5.6,4.8-5.7,8.5c0,5.2,4.6,9.5,10.3,9.5c1,0,2.1-0.1,3.1-0.4l-0.5,0.7l-0.9,1.7
		C786,1949.5,785.4,1949.5,784.9,1949.5z M784.9,1928.5c-0.3,0-0.6,0-1,0c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2
		c0.8,0.7,1.3,1.7,1.5,2.7c0.9-0.5,1.9-0.7,3-0.7c0.9,0,1.8,0.2,2.7,0.6c0.1-0.8,0.4-1.5,0.9-2.2c0.2-0.3,0.4-0.5,0.7-0.7
		c-0.3,0-0.7-0.1-1.1-0.1c-1.1,0-2.2,0.2-3.2,0.5c-0.2,0.1-0.4,0.1-0.6,0C787.1,1928.7,786,1928.5,784.9,1928.5z"
                />
            </g>
        </svg>
    );
};

export default VegetablesIcon;
