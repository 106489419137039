import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { selectFieldsWithFullNames } from 'fields/fields-selectors';
import type Herd from 'herd/Herd';
import { selectIsHerdLocked } from 'herd/herd-selectors';
import type { List } from 'immutable';
import type { AppState } from 'system/store';
import RememberingLink from 'view/RememberingLink';

import { selectHerdField } from '../herd-ui-utils';

import HerdPreviewDetails from './HerdPreviewDetails';

interface HerdPreviewProps {
    // From redux
    farm: Farm;
    fields: List<Field>;
    isLocked: boolean;

    // From parent
    herd: Herd;
}

const HerdPreview = ({ farm, fields, isLocked, herd }: HerdPreviewProps) => {
    const { t } = useTranslation();
    const herdField = selectHerdField(fields, herd);

    return (
        <div className={c('bordered-b pr-5', { 'opacity-50': herd.archived })}>
            <div className="flex gap-3">
                <RememberingLink
                    key={herd.uuid}
                    to={`/farms/${farm.uuid}/livestock/${herd.uuid}`}
                    className="flex-grow focus ring-inset block"
                >
                    <HerdPreviewDetails herd={herd} herdField={herdField} isLocked={isLocked} />
                </RememberingLink>
                <div className="py-2 text-center">
                    <span className="block mb-2">{herd.size}</span>
                    {!isLocked && !herd.archived && (
                        <Button
                            as={Link}
                            to={`/farms/${farm.uuid}/livestock/${herd.uuid}/move`}
                            onClick={(e) => e.stopPropagation()}
                            small
                            className="px-2"
                        >
                            {t('livestock_details_move')}
                        </Button>
                    )}
                    {isLocked && icon('padlock', 'blue')}
                </div>
                <div className="self-center">
                    <i className="ion-chevron-right" />
                </div>
            </div>
        </div>
    );
};

export default connect((state: AppState, ownProps: { herd: Herd }) => ({
    farm: selectCurrentFarm(state),
    fields: selectFieldsWithFullNames(state),
    isLocked: selectIsHerdLocked(state, ownProps.herd.uuid),
}))(HerdPreview);
