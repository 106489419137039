import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import { useOutOfDatePromise } from 'lib/hooks';
import { getFarmUserDisplayName } from 'lib/util/names';
import EditableActions from 'sidebar/modules/common/EditableActions';
import EditButton from 'sidebar/modules/common/EditButton';
import DueDate from 'sidebar/modules/date/DueDate';
import type { Nullable } from 'system/types';
import DateAndTimeField from 'view/form/hook/DateAndTimeField';

interface OperationDateAndTimeProps {
    label: string;
    value: Nullable<Date>;
    onSave: (date: Date | null) => Promise<any>;
    required?: boolean;
    openDirection?: 'top' | 'bottom';
    byUser?: FarmUser;
}

interface FormValues {
    date: Date | null;
}

const OperationDateAndTime = ({
    label,
    value,
    onSave,
    required = false,
    openDirection = 'top',
    byUser,
}: OperationDateAndTimeProps) => {
    const { t } = useTranslation();
    const [editing, toggleEditing] = useToggle(false);
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(toggleEditing);
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            date: value,
        },
    });

    const handleSubmit = ({ date }: FormValues) => {
        setPromise(onSave(date));
    };

    return editing ? (
        <FormProvider {...methods}>
            <form className="py-4 bordered-b" onSubmit={methods.handleSubmit(handleSubmit)}>
                <h4 className="mb-2">{label}</h4>
                <DateAndTimeField
                    field="date"
                    allowDelete={!required}
                    openDirection={openDirection}
                />
                <EditableActions
                    disabled={pending}
                    error={error}
                    outOfDate={outOfDate}
                    setEditing={toggleEditing}
                />
            </form>
        </FormProvider>
    ) : (
        <div className="flex gap-2 items-start py-4 bordered-b">
            <h4 className="mb-0 w-32">{label}</h4>
            {value !== null ? (
                <>
                    <DueDate date={value} />
                    {byUser !== undefined ? `${t('by')} ${getFarmUserDisplayName(byUser)}` : ''}
                </>
            ) : (
                <div className="not-set">{t('no_due_date')}</div>
            )}
            <EditButton setEditing={toggleEditing} className="ml-auto font-bold float-none" />
        </div>
    );
};

export default OperationDateAndTime;
