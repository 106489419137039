import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { deserialize, Projection, reproject } from '@fieldmargin/webapp-geo';
import type { Controller } from '@fieldmargin/webapp-ol-map';
import {
    editingLineStyles,
    editingPointImage,
    hexToRgba,
    syncCollection,
} from '@fieldmargin/webapp-ol-map';
import Collection from 'ol/Collection';
import type { Coordinate } from 'ol/coordinate';
import { doubleClick } from 'ol/events/condition';
import type { FeatureLike } from 'ol/Feature';
import type Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import type { Geometry } from 'ol/geom';
import MultiPoint from 'ol/geom/MultiPoint';
import type Polygon from 'ol/geom/Polygon';
import Modify from 'ol/interaction/Modify';
import Snap from 'ol/interaction/Snap';
import VectorLayer from 'ol/layer/Vector';
import type OLMap from 'ol/Map';
import RenderFeature, { toFeature } from 'ol/render/Feature';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

const geoJson = new GeoJSON();

class SubFieldsController implements Controller<GeoFeature[]> {
    private map: OLMap;
    private dataSource: Collection<any>;
    private editingDataSource: Collection<any>;
    private dataLayer: VectorLayer<VectorSource<Feature<Geometry>>>;
    private editingDataLayer: VectorLayer<VectorSource<Feature<Geometry>>>;
    private modify: Modify;
    private snap: Snap;
    private onFeatureEdit: (geoFeature: GeoFeature) => void;

    constructor(zIndex: number, onFeatureEdit: (geoFeature: GeoFeature) => void) {
        this.onFeatureEdit = onFeatureEdit;

        this.dataSource = new Collection();
        this.editingDataSource = new Collection();
        this.dataLayer = new VectorLayer({
            source: new VectorSource({
                features: this.dataSource,
            }),
            zIndex,
            style: (feature) => this._styleFeature(feature),
        });
        this.editingDataLayer = new VectorLayer({
            source: new VectorSource({
                features: this.editingDataSource,
            }),
            zIndex,
            style: (feature) => this._styleEditingFeature(feature),
        });
        this.modify = new Modify({
            features: this.editingDataSource,
            deleteCondition: doubleClick,
        });
        this.modify.on('modifyend', (e) => {
            e.features.forEach((feature) => {
                let geoFeature = deserialize(
                    JSON.parse(
                        geoJson.writeFeature(
                            feature instanceof RenderFeature ? toFeature(feature) : feature
                        )
                    ),
                    Projection.WEB_MERCATOR
                ) as GeoFeature;
                geoFeature = reproject(geoFeature, Projection.LNG_LAT).set(
                    'id',
                    feature.getId() ?? ''
                );
                this.onFeatureEdit(geoFeature);
            });
        });

        this.snap = new Snap({
            source: new VectorSource({
                features: this.dataSource,
            }),
        });
    }

    build(map: OLMap) {
        this.map = map;
        this.map.addLayer(this.dataLayer);
        this.map.addLayer(this.editingDataLayer);
        this.map.addInteraction(this.modify);
        this.map.addInteraction(this.snap);
    }

    _styleFeature(feature: FeatureLike) {
        const type = feature.getGeometry()?.getType();
        const props = feature.getProperties();

        if (type !== 'Polygon') {
            throw new Error(`Cannot style type ${type}`);
        }

        const strokeStyle = props.passiveEditMode
            ? editingLineStyles
            : [
                  new Style({
                      stroke: new Stroke({
                          color: hexToRgba(props.colour, props.strokeOpacity),
                          width: props.strokeWeight,
                          lineDash: props.lineDash || undefined,
                      }),
                  }),
              ];

        return [
            new Style({
                fill: new Fill({
                    color: hexToRgba(props.colour, props.fillOpacity),
                }),
                text: new Text({
                    font: `bold 12px sans-serif`,
                    fill: new Fill({ color: '#333' }),
                    stroke: new Stroke({ color: '#fff', width: 3 }),
                    text: props.label,
                    overflow: true,
                }),
            }),
            ...strokeStyle,
        ];
    }

    _styleEditingFeature(feature: FeatureLike) {
        const type = feature.getGeometry()?.getType();
        const props = feature.getProperties();

        if (type !== 'Polygon') {
            throw new Error(`Cannot style type ${type}`);
        }

        return [
            new Style({
                fill: new Fill({
                    color: hexToRgba(props.colour, props.fillOpacity),
                }),
                text: new Text({
                    font: `bold 12px sans-serif`,
                    fill: new Fill({ color: '#333' }),
                    stroke: new Stroke({ color: '#fff', width: 3 }),
                    text: props.label,
                    overflow: true,
                }),
            }),
            ...editingLineStyles,
            new Style({
                image: editingPointImage,
                geometry: function (feature: Feature<Polygon>) {
                    const lines = feature.getGeometry()?.getCoordinates();
                    let allPoints = [] as Coordinate[];
                    lines?.forEach((points) => {
                        allPoints = allPoints.concat(points);
                    });
                    return new MultiPoint(allPoints);
                },
            }),
        ];
    }

    update(geoFeatures: GeoFeature[]) {
        syncCollection(
            geoFeatures.filter((geoFeature) => !geoFeature.properties.get('editable')),
            this.dataSource
        );
        syncCollection(
            geoFeatures.filter((geoFeature) => geoFeature.properties.get('editable')),
            this.editingDataSource
        );
    }
}

export default SubFieldsController;
