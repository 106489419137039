import type { GeoPosition } from '@fieldmargin/webapp-geo';
import type { List } from 'immutable';
import { Record } from 'immutable';

import type { Place } from './create-farm-api';

const CreateFarmState = Record({
    userLocation: undefined as GeoPosition | undefined,
    farmLocation: undefined as GeoPosition | undefined,
    searchedPlaces: undefined as List<Place> | undefined,
    selectedPlaceId: undefined as string | undefined,
    userGeoPosition: undefined as GeoPosition | undefined,
    farmLocationFieldError: false,
});
interface CreateFarmState extends ReturnType<typeof CreateFarmState> {}
export default CreateFarmState;
