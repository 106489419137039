import { useTranslation } from 'react-i18next';
import type Herd from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import { useDateFormat } from 'hooks/useDateFormat';
import { prop } from 'ramda';

import type { HerdHistoryMerge } from './herd-history-generator';

interface HerdHistoryMergeItemProps {
    herd: Herd;
    historyItem: HerdHistoryMerge;
}

const HerdHistoryMergeItem = ({ herd, historyItem }: HerdHistoryMergeItemProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    const merged = [herd.name, ...historyItem.item.map(prop('name'))].join(', ');
    return (
        <li className="p-0 pb-5">
            <strong className="mb-2 block text-xxs text-gray-500">
                {format(historyItem.date, 'do MMMM yyyy')}
            </strong>
            <span className="flex">
                {herdIcon(herd.type, 'blue')}
                <span className="ml-3">
                    <strong className="block">
                        {t('livestock_details_history_merged', { sprintf: [merged] })}
                    </strong>
                </span>
            </span>
        </li>
    );
};

export default HerdHistoryMergeItem;
