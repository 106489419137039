import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import type { ChartData } from 'chart.js';
import { LazyBarChart } from 'components/chart/LazyBarChart';
import { renderMeasurementUnitShort } from 'lib/MeasurementUnit';
import { getUnitShortName } from 'lib/unit';
import { shortenText } from 'lib/util/text';
import { isString } from 'lodash';
import type OutputReportSummary from 'outputs/OutputReportSummary';

import { useYeldReportingContext } from './useYeldReportingContext';

const YieldReportGraph = () => {
    const { t, i18n } = useTranslation();
    const { reportData, areaMeasurementUnit } = useYeldReportingContext();

    return (
        <div className="mt-8">
            <Suspense fallback={<div>Loading...</div>}>
                {reportData &&
                    reportData.map((outputReportSummary) => (
                        <div
                            key={outputReportSummary.outputUuid}
                            className="relative mb-8"
                            style={{ height: Math.max(outputReportSummary.fields.size * 60, 200) }}
                        >
                            <LazyBarChart
                                direction="horizontal"
                                lang={i18n.language}
                                style={{ display: 'block' }}
                                data={getGraphData(outputReportSummary)}
                                options={{
                                    locale: i18n.language,
                                    maintainAspectRatio: false,
                                    layout: {
                                        padding: {
                                            right: 50,
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        title: {
                                            display: true,
                                            text: `${t('output')}: ${
                                                outputReportSummary.outputName
                                            }`,
                                            padding: {
                                                bottom: 32,
                                            },
                                            font: {
                                                size: 16,
                                                family: 'Montserrat',
                                                weight: 'normal',
                                            },
                                        },
                                        datalabels: {
                                            anchor: 'end',
                                            align: 'end',
                                            formatter: (value) =>
                                                new Intl.NumberFormat(window.navigator.language, {
                                                    maximumSignificantDigits: 4,
                                                }).format(value),
                                        },
                                    },
                                    scales: {
                                        y: {
                                            afterSetDimensions: (axes) => {
                                                axes.paddingLeft = 145;
                                            },
                                        },
                                        x: {
                                            title: {
                                                display: true,
                                                text: `${t('yield')} ${getUnitShortName(
                                                    outputReportSummary.outputUnit
                                                )}/${renderMeasurementUnitShort(areaMeasurementUnit)}`,
                                                font: {
                                                    size: 16,
                                                },
                                            },
                                            ticks: {
                                                callback(tickValue) {
                                                    return isString(tickValue)
                                                        ? tickValue
                                                        : new Intl.NumberFormat(
                                                              window.navigator.language
                                                          ).format(tickValue);
                                                },
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    ))}
            </Suspense>
        </div>
    );
};

const getGraphData = (reportSummary: OutputReportSummary): ChartData<'bar'> => ({
    labels: reportSummary.fields.map((field) => shortenText(20, field.fieldName)).toArray(),
    datasets: [
        {
            data: reportSummary.fields.map((field) => field.yieldRate).toArray(),
            backgroundColor: '#4da4da',
            hoverBackgroundColor: '#4da4da',
            barThickness: 20,
            categoryPercentage: 0,
            barPercentage: 0,
        },
    ],
});

export default YieldReportGraph;
