import OnboardingTooltip from 'onboarding/OnboardingTooltip';

interface FieldUsageOnboardingProps {
    onSkip: VoidFunction;
}

const FieldUsageOnboarding = ({ onSkip }: FieldUsageOnboardingProps) => (
    <OnboardingTooltip
        style={{ top: 335, left: 700 }}
        onSkip={onSkip}
        link="http://support.fieldmargin.com/en/articles/1869616-how-to-set-a-field-usage"
    >
        <p>
            Add a Field Usage
            <br />
            Click "CREATE NEW"
        </p>
    </OnboardingTooltip>
);
export default FieldUsageOnboarding;
