import { createSelector } from '@reduxjs/toolkit';
import type { CustomMapSpec } from 'farm-map/types';
import type MapGroup from 'maps/farm/MapGroup';
import type { AppState } from 'system/store';
import { VisibilityOptions } from 'system/types';

export const selectIntegrationMaps = createSelector(
    (state: AppState) => state.farmEditingState.layerVisibility.visibleMapGroups,
    (state: AppState) => state.farmEditingState.layerVisibility.hiddenMapIntegrations,
    (state: AppState) => state.mapsState.mapGroupsByIntegration,
    (visibleMapGroups, hiddenMapIntegrations, mapGroupsByIntegration): CustomMapSpec[] => {
        if (!visibleMapGroups || !mapGroupsByIntegration) {
            return [];
        }

        return visibleMapGroups.toArray().reduce(
            (customMaps, key) => {
                let mapGroup: MapGroup | undefined = undefined;
                const integrationMaps = mapGroupsByIntegration[key.farmIntegrationUuid];
                if (integrationMaps) {
                    mapGroup = integrationMaps.find((mg) => mg.uuid === key.mapUuid);
                }

                if (
                    key.visibility === VisibilityOptions.OFF ||
                    !mapGroup ||
                    hiddenMapIntegrations.has(mapGroup.farmIntegrationUuid)
                ) {
                    return customMaps;
                }

                if (mapGroup.tiles) {
                    return [
                        ...customMaps,
                        {
                            id: mapGroup.uuid,
                            tiles: mapGroup.tiles,
                            opacity: key.visibility === VisibilityOptions.ON ? 1 : 0.5,
                            zIndex: 0,
                        },
                    ];
                } else {
                    return customMaps.concat(
                        mapGroup.maps.map((m) => ({
                            id: m.uuid,
                            tiles: m.tiles,
                            opacity: key.visibility === VisibilityOptions.ON ? 1 : 0.5,
                            zIndex: 0,
                        }))
                    );
                }
            },
            <CustomMapSpec[]>[]
        );
    }
);
