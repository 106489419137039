import type { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { useApplicationLayoutContext } from '@notes-web/layout';
import type { ChatNotification } from 'chat/chat-state';
import FarmChatHistory from 'chat/farm-chat/FarmChatHistory';
import FarmChatNotification from 'chat/farm-chat/FarmChatNotification';
import FarmChatPanel from 'chat/farm-chat/FarmChatPanel';
import { selectCurrentFarm } from 'farms/farms-state';
import type { AppState } from 'system/store';

import './FarmOverview.css';

interface FarmOverviewFullScreenProps extends PropsWithChildren {
    farmChatOpen: boolean;
    historyOpen: boolean;
    chatNotification?: ChatNotification;
}

const FarmOverviewFullScreenProps = ({
    farmChatOpen,
    historyOpen,
    chatNotification,
    children,
}: FarmOverviewFullScreenProps) => {
    const { Navigation } = useApplicationLayoutContext();
    return (
        <div className="FarmOverview full-screen">
            <div className="farm">
                {Navigation && <Navigation />}

                <div className="scrollable">
                    <div className="content">{children}</div>
                </div>
                {farmChatOpen && (
                    <div className="chat-container">
                        {historyOpen ? <FarmChatHistory /> : <FarmChatPanel />}
                    </div>
                )}
                {!farmChatOpen && chatNotification !== undefined && <FarmChatNotification />}
            </div>
        </div>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    farmChatOpen: state.uiState.farmChatOpen,
    historyOpen: state.chatState.historyOpen,
    chatNotification: state.chatState.notification,
}))(FarmOverviewFullScreenProps);
