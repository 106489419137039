export enum DiscussionItemType {
    NOTE = 'NOTE',
}

export interface Discussion {
    id: string;
    createdDate: Date;
    createdByUserId: number;
    comment: string;
}

export interface DiscussionDTO {
    id: string;
    timestamp: number;
    createdByUserId: number;
    comment: string;
}

export const deserializeDiscussion = (json: DiscussionDTO): Discussion => ({
    ...json,
    createdDate: new Date(json.timestamp),
});
