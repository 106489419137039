import { useTranslation } from 'react-i18next';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';

import drawImg from './images/draw.png';
import startFreshImg1 from './images/startFresh1.png';
import startFreshImg2 from './images/startFresh2.png';
import undoImg from './images/undo.png';
import SubFieldsHelpDiagram from './SubFieldsHelpDiagram';

const SubFieldsHelpOther = () => {
    const { t } = useTranslation();
    return (
        <div style={{ width: 700 }}>
            <h2 className="text-center text-sm">{t('sub_fields_help_other_title')}</h2>
            <div className="flex text-center">
                <SubFieldsHelpDiagram src={drawImg} alt="Image showing a newly drawn sub-field">
                    <MarkdownNoPara>{t('sub_fields_help_other1')}</MarkdownNoPara>
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram src={undoImg} alt="Image showing a single sub-field">
                    <MarkdownNoPara>{t('sub_fields_help_other2')}</MarkdownNoPara>
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram
                    src={startFreshImg1}
                    alt="Image showing multiple sub-fields and a Start Fresh button"
                >
                    <MarkdownNoPara>{t('sub_fields_help_other3')}</MarkdownNoPara>
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram
                    src={startFreshImg2}
                    alt="Image showing start fresh confirmation modal"
                >
                    <MarkdownNoPara>{t('sub_fields_help_other4')}</MarkdownNoPara>
                </SubFieldsHelpDiagram>
            </div>
        </div>
    );
};

export default SubFieldsHelpOther;
