import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { useCurrentOperation } from 'hooks/useCurrentOperation';
import type { Set } from 'immutable';
import { saveOperationRecordings } from 'operations/operations-save-helper';
import { setOperation } from 'operations/operations-state';
import type Recording from 'operations/Recording';
import { useAppDispatch } from 'system/store';

const useSaveOperation = () => {
    const dispatch = useAppDispatch();
    const { currentFarm } = useCurrentFarm();
    const { currentOperation } = useCurrentOperation();

    const handleSaveOperation = async (recordings: Set<Recording>) => {
        if (!currentOperation) return;

        const savedOperation = await saveOperationRecordings(
            currentFarm.uuid,
            currentOperation,
            recordings
        );

        dispatch(setOperation(savedOperation));
    };
    return {
        handleSaveOperation,
    };
};

export { useSaveOperation };
