import { useEffect, useRef } from 'react';
import type { GeoFeature } from '@fieldmargin/webapp-geo';
import type { FarmMapFeature } from 'farm-map/types';
import { toggleSubFieldSelectedFromMap } from 'fields/sub-fields-state';
import { useActiveSection } from 'hooks/useActiveSection';
import { useAction } from 'lib/hooks';
import { isNumber } from 'lodash';
import { useAppSelector } from 'system/store';
import type { ActiveSection } from 'system/url-util';

/**
 * Possible scenarios
 * 1. User clicks on a sub-field: Toggle the sub-field.
 * 2. User clicks on a non-sub-field feature whilst editing sub-fields: Do nothing.
 */
export const useFarmMapSubFieldClick = () => {
    const activeSection = useActiveSection();
    const activeSectionRef = useRef<ActiveSection>(activeSection);
    useEffect(() => {
        activeSectionRef.current = activeSection;
    }, [activeSection]);

    const toggleSubField = useAction(toggleSubFieldSelectedFromMap);
    const subFields = useAppSelector((state) => state.subFieldsState.subFields.toArray());
    const subFieldsRef = useRef(subFields);
    useEffect(() => {
        subFieldsRef.current = subFields;
    }, [subFields]);

    return (feature: FarmMapFeature) => {
        if (feature.properties.type !== 'SUB-FIELD') {
            return activeSectionRef.current.sub === 'sub-fields';
        }

        const subField = subFieldsRef.current.find(
            (subField) => subField.id === feature.properties.uuid
        );
        const id = subField?.field.geoJson?.features.first<GeoFeature>()?.id;
        if (id !== undefined && !isNumber(id)) {
            toggleSubField(id);
        }
        return true;
    };
};
