import { connect } from 'react-redux';
import { FarmUserRole } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import { selectCanCreateMoreFields } from 'fields/fields-selectors';
import type { AppState } from 'system/store';
import { selectCurrentFarmUser, selectShouldBlockInviting } from 'team/farm-users-state';

import FieldAndTeamLimitPromptModal from './FieldAndTeamLimitModal';
import FieldLimitPromptModal from './FieldLimitPromptModal';
import TeamLimitPromptModal from './TeamLimitPromptModal';

interface FarmLimitModalsProps {
    // From redux
    shouldShowFieldLimit: boolean;
    shouldShowTeamLimit: boolean;
}

// Modals that are shown to users hitting limits (normally on the free plan) prompting them to upgrade
const FarmLimitModals = ({ shouldShowFieldLimit, shouldShowTeamLimit }: FarmLimitModalsProps) => {
    const Modal =
        shouldShowFieldLimit && shouldShowTeamLimit
            ? FieldAndTeamLimitPromptModal
            : shouldShowFieldLimit
              ? FieldLimitPromptModal
              : shouldShowTeamLimit
                ? TeamLimitPromptModal
                : null;

    const [showModal, toggleModal] = useToggle(Modal !== null);

    return <>{Modal !== null && showModal && <Modal onClose={toggleModal} />}</>;
};

export default connect((state: AppState) => ({
    shouldShowFieldLimit: !selectCanCreateMoreFields(state),
    shouldShowTeamLimit:
        selectCurrentFarmUser(state).role === FarmUserRole.OWNER &&
        selectShouldBlockInviting(state),
}))(FarmLimitModals);
