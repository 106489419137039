import { geojson } from '@fieldmargin/webapp-ol-map';
import noteIconArchived from 'components/maps/openlayers/markers/map-note-marker-white-archived.png';
import noteIconEmpty from 'components/maps/openlayers/markers/map-note-marker-white-empty.png';
import noteIconTask from 'components/maps/openlayers/markers/map-note-marker-white-task.png';
import noteIconText from 'components/maps/openlayers/markers/map-note-marker-white-text.png';
import type { Feature } from 'geojson';
import Collection from 'ol/Collection';
import type { FeatureLike } from 'ol/Feature';
import type OLFeature from 'ol/Feature';
import type { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import type OLMap from 'ol/Map';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

class NoteMarkersController implements geojson.Controller<Feature[]> {
    private map: OLMap;
    private dataSource: Collection<OLFeature>;
    private clusterSource: Cluster;
    private dataLayer: VectorLayer<VectorSource<OLFeature<Geometry>>>;

    constructor(zIndex: number) {
        this.dataSource = new Collection();
        this.clusterSource = new Cluster({
            distance: 25, // pixels
            source: new VectorSource({
                features: this.dataSource,
            }),
        });
        this.dataLayer = new VectorLayer({
            source: this.clusterSource,
            zIndex,
            style: (feature) => this.styleFeature(feature),
        });
    }

    public build(map: OLMap) {
        this.map = map;
        this.map.addLayer(this.dataLayer);
    }

    public update(features: Feature[]) {
        geojson.syncCollection(features, this.dataSource);
    }

    protected styleFeature(feature: FeatureLike) {
        const size = feature.get('features').length;
        let text = '';
        let icon = '';
        if (size > 1) {
            text = size.toString();
            icon = noteIconEmpty;
        } else {
            const noteMarker = feature.get('features')[0].get('noteMarker');
            if (noteMarker === 'archived') {
                icon = noteIconArchived;
            } else if (noteMarker === 'task') {
                icon = noteIconTask;
            } else {
                icon = noteIconText;
            }
        }

        return new Style({
            image: new Icon({
                src: icon,
                scale: 0.5,
                anchor: [0.5, 1],
            }),
            text: new Text({
                text: text,
                font: 'bold 12px sans-serif',
                fill: new Fill({
                    color: '#000',
                }),
                offsetY: -32,
            }),
        });
    }
}

export default NoteMarkersController;
