import { selectYearTotalFieldArea, selectYearTotalFieldAreaActive } from 'fields/fields-selectors';
import { useAppSelector } from 'system/store';

const useYearTotalFieldArea = () => {
    const totalArea = useAppSelector(selectYearTotalFieldArea);
    const totalActiveArea = useAppSelector(selectYearTotalFieldAreaActive);

    return {
        totalArea,
        totalActiveArea,
    };
};

export { useYearTotalFieldArea };
