import { useTranslation } from 'react-i18next';

import sliceImg1 from './images/slice1.png';
import sliceImg2 from './images/slice2.png';
import sliceImg3 from './images/slice3.png';
import sliceImg4 from './images/slice4.png';
import SubFieldsHelpDiagram from './SubFieldsHelpDiagram';

const SubFieldsHelpSlice = () => {
    const { t } = useTranslation();
    return (
        <div style={{ width: 700 }}>
            <h2 className="text-center text-sm">{t('sub_fields_help_slice_title')}</h2>
            <div className="flex text-center">
                <SubFieldsHelpDiagram src={sliceImg1} alt="Image showing a selected sub-field">
                    {t('sub_fields_help_slice1')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram
                    src={sliceImg2}
                    alt="Image showing the slice tool being selected"
                >
                    {t('sub_fields_help_slice2')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram
                    src={sliceImg3}
                    alt="Image showing a sub-field being divided by a line"
                >
                    {t('sub_fields_help_slice3')}
                </SubFieldsHelpDiagram>
                <SubFieldsHelpDiagram
                    src={sliceImg4}
                    alt="Image showing 2 sub-fields with names 1 and 2"
                >
                    {t('sub_fields_help_slice4')}
                </SubFieldsHelpDiagram>
            </div>
        </div>
    );
};

export default SubFieldsHelpSlice;
