import { usePageParams } from 'hooks/usePageParamas';
import { isCreator } from 'lib/owner';
import { selectNote } from 'notes/notes-selectors';
import { useAppSelector } from 'system/store';
import { selectIsFarmOwner } from 'team/farm-users-state';
import { selectUserId } from 'users/user-state';

const useCurrentNote = () => {
    const { noteUuid } = usePageParams();

    const currentNote = useAppSelector((state) => selectNote(state, noteUuid || ''));

    const canDeleteNote = useAppSelector(
        (state) =>
            selectIsFarmOwner(state) || (currentNote && isCreator(currentNote, selectUserId(state)))
    );
    return { currentNote, canDeleteNote };
};

export { useCurrentNote };
