import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from '@fieldmargin/webapp-styling/components/pricing/pricing-utils';
import { getInputTypeI18nKey } from 'inputs/InputType';
import { capitaliseWord } from 'lib/util/text';
import Num from 'view/Num';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import { useGrossMarginUsageReportContext } from './useGrossMarginUsageReportContext';

const GrossMarginByUsageTable = () => {
    const { t } = useTranslation();
    const { farm, reportData } = useGrossMarginUsageReportContext();
    const currency = getCurrencySymbol(farm.costingsCurrency || 'GBP');

    return (
        <table className="ReportingTable table-fixed">
            <thead>
                <tr>
                    <th>{t('usage')}</th>
                    <th>
                        {t('total')} ({currency})
                    </th>
                    <th>
                        {capitaliseWord(t('per'))} <ShortAreaUnit /> ({currency}/<ShortAreaUnit />)
                    </th>
                </tr>
            </thead>
            <tbody>
                {reportData.map((datum, key) => (
                    <Fragment key={key}>
                        <tr>
                            <td colSpan={3} className="bg-gray-200">
                                <strong>{datum.name}</strong>
                            </td>
                        </tr>
                        {datum.outputs && (
                            <>
                                <tr>
                                    <td colSpan={3}>
                                        <strong>{t('outputs_title')}</strong>
                                    </td>
                                </tr>
                                {datum.outputs.map((output, outputKey) => (
                                    <tr key={outputKey}>
                                        <td>{output.type}</td>
                                        <td>
                                            <Num value={output.totalCost} />
                                        </td>
                                        <td>
                                            <Num value={output.cost} />
                                        </td>
                                    </tr>
                                ))}
                                {datum.totalOutput && (
                                    <tr>
                                        <td>
                                            <strong>{t('total')}</strong>
                                        </td>
                                        <td>
                                            <strong>
                                                <Num value={datum.totalOutput.totalCost} />
                                            </strong>
                                        </td>
                                        <td>
                                            <strong>
                                                <Num value={datum.totalOutput.cost} />
                                            </strong>
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                        {datum.inputs && (
                            <>
                                <tr>
                                    <td colSpan={3}>
                                        <strong>{t('inputs_title')}</strong>
                                    </td>
                                </tr>
                                {datum.inputs.map((input, inputKey) => (
                                    <tr key={inputKey}>
                                        <td>{t(getInputTypeI18nKey(input.type))}</td>
                                        <td>
                                            <Num value={input.totalCost} />
                                        </td>
                                        <td>
                                            <Num value={input.cost} />
                                        </td>
                                    </tr>
                                ))}
                                {datum.totalInput && (
                                    <tr>
                                        <td>
                                            <strong>{t('total')}</strong>
                                        </td>
                                        <td>
                                            <strong>
                                                <Num value={datum.totalInput.totalCost} />
                                            </strong>
                                        </td>
                                        <td>
                                            <strong>
                                                <Num value={datum.totalInput.cost} />
                                            </strong>
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                        {datum.margin && (
                            <tr>
                                <td>
                                    <strong>{t('gross_margin_title')}</strong>
                                </td>
                                <td>
                                    <strong>
                                        <Num value={datum.margin.totalCost} />
                                    </strong>
                                </td>
                                <td>
                                    <strong>
                                        <Num value={datum.margin.cost} />
                                    </strong>
                                </td>
                            </tr>
                        )}
                    </Fragment>
                ))}
            </tbody>
        </table>
    );
};

export default GrossMarginByUsageTable;
