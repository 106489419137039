import { useFormContext } from 'react-hook-form';
import { uniqueId } from 'lodash';
import type { SingleParamVoidFunction } from 'system/types';
import ErrorMessage from 'view/ErrorMessage';
import type { BadgeItemWithIdLike } from 'view/molecules/badge-item/BadgeItem';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';

import 'molecules/fancy-radio.scss';

interface BadgeRadioGroupProps {
    field: string;
    options: BadgeItemWithIdLike[];
    allowNull?: boolean;
    onChange?: SingleParamVoidFunction<BadgeItemWithIdLike>;
}

const BadgeRadioGroup = ({ field, options, allowNull = false, onChange }: BadgeRadioGroupProps) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const handleChange = (id: string) => {
        if (!onChange) {
            return;
        }

        const item = options.find((item) => item.id === id);
        item !== undefined && onChange(item);
    };

    return (
        <div className="relative">
            {allowNull && (
                <div className="flex items-center mb-3" key="unset">
                    <div className="relative">
                        <input
                            type="radio"
                            {...register(field)}
                            className="fancy-radio"
                            id="not-set"
                            value={undefined}
                        />
                        <label className="h-5" htmlFor="not-set" />
                    </div>
                    <label className="italic text-gray-500" htmlFor="not-set">
                        -- Not set --
                    </label>
                </div>
            )}
            {options.map((item) => {
                const inputId = uniqueId('badge-item-');
                return (
                    <div className="flex items-center mb-3" key={item.id}>
                        <div className="relative">
                            <input
                                type="radio"
                                className="fancy-radio"
                                {...register(field, {
                                    required: allowNull ? false : 'Required',
                                    onChange: (e) => handleChange(e.target.value),
                                })}
                                id={inputId}
                                value={item.id}
                            />
                            <label className="h-5" htmlFor={inputId} />
                        </div>
                        <label htmlFor={inputId}>
                            <BadgeItem item={item} />
                        </label>
                    </div>
                );
            })}
            {errors[field]?.message !== undefined && (
                <ErrorMessage>{errors[field]?.message?.toString()}</ErrorMessage>
            )}
        </div>
    );
};

export default BadgeRadioGroup;
