import type { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '@fieldmargin/webapp-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type Herd from 'herd/Herd';
import { mergeHerds } from 'herd/herd-api';
import { setHerds } from 'herd/herd-state';
import { useAction } from 'lib/hooks';
import { always, prop } from 'ramda';
import { useAppSelector } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { selectCurrentYear } from 'years/years-state';

interface HerdMergeDetailsFormValues {
    name: string;
    colour: string;
    size: number;
}

export const useHerdMergeForm = (
    herd: Herd,
    herds: Herd[],
    selectedHerds: string[]
): {
    methods: UseFormReturn<HerdMergeDetailsFormValues>;
    handleSubmit: SingleParamVoidFunction<HerdMergeDetailsFormValues>;
    pending: boolean;
    error: boolean;
} => {
    const farm = useAppSelector(selectCurrentFarm);
    const year = useAppSelector(selectCurrentYear);

    const navigate = useNavigate();
    const setHerdsAction = useAction(setHerds);

    const { pending, error, setPromise } = usePromise<Herd>((updatedHerd) => {
        const archivedHerds = herds.map((h) => ({ ...h, archived: true }));
        setHerdsAction([updatedHerd, ...archivedHerds]);
        navigate(`/farms/${farm.uuid}/livestock/${herd.uuid}`);
    });
    const methods = useForm<HerdMergeDetailsFormValues>({
        defaultValues: {
            name: [herd.name, ...herds.map(prop('name'))].join(' '),
            colour: herd.colour,
            size: herds.reduce((acc, h) => acc + h.size, herd.size),
        },
    });

    const handleSubmit = (values: HerdMergeDetailsFormValues) => {
        const destinationHerd = {
            ...herd,
            name: values.name,
            size: values.size,
            colour: values.colour,
        };
        setPromise(
            mergeHerds(farm.uuid, year, destinationHerd, selectedHerds).then(
                always(destinationHerd)
            )
        );
    };

    return { methods, handleSubmit, pending, error };
};
