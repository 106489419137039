import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { AxiosProgressEvent } from 'axios';
import { ChatFile } from 'chat/chat-models';
import type { LegacyMediaDTO } from 'media/Media';
import type { SingleParamVoidFunction } from 'system/types';

export const uploadChatFileApi = (
    farmUuid: string,
    messageUuid: string,
    file: File,
    onProgressCb: SingleParamVoidFunction<AxiosProgressEvent>
) => {
    const data = new FormData();
    data.append('file', file);

    return authorizedRequest<LegacyMediaDTO>({
        method: 'post',
        url: `/notes-api/v2/farms/${farmUuid}/messageGroups/general/media/`,
        params: {
            messageUUID: messageUuid,
        },
        data,
        onUploadProgress: onProgressCb,
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then(
        (mediaDto) =>
            new ChatFile({
                id: mediaDto.id,
                mediaType: mediaDto.mediaType,
                mediaSize: mediaDto.size,
                fileName: mediaDto.fileName,
            })
    );
};
