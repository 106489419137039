import type { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { useUpgradeLink } from 'hooks/useUpgradeLink';
import { trackEvent } from 'utils/trackEvent';

import styles from './LinkUpgradePlan.module.css';

interface LinkUpgradePlanProps extends PropsWithChildren {
    className?: string;
}

function LinkUpgradePlan({ children, className }: LinkUpgradePlanProps) {
    const { currentFarm } = useCurrentFarm();
    const { upgradeLink } = useUpgradeLink();

    const onClickUpgrade = () => {
        trackEvent('Input warning limit upgrade clicked', { farmUuid: currentFarm.uuid });
    };

    return (
        <Link
            className={clsx(styles.upgradeLink, className)}
            to={upgradeLink}
            onClick={onClickUpgrade}
        >
            {children}
        </Link>
    );
}

export { LinkUpgradePlan };
