import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import type FieldUsage from 'fields/FieldUsage';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';

import { useCreateFieldUsage } from './field-usage-hooks';
import type { FieldUsageFormValues } from './FieldUsageForm';
import FieldUsageForm from './FieldUsageForm';

interface AddFieldUsageProps {
    onBack: VoidFunction;
    onSave: (fieldUsage: FieldUsage) => void;
}

const AddFieldUsage = ({ onBack, onSave }: AddFieldUsageProps) => {
    const { t } = useTranslation();
    const createFieldUsage = useCreateFieldUsage();

    const handleSubmit = (values: FieldUsageFormValues) =>
        createFieldUsage(values.cropName, values.cropCode, values.description, values.colour).then(
            onSave
        );

    return (
        <div className="AddFieldUsage scrollable">
            <div className="non-scrolling">
                <SidebarHeader>
                    <SidebarHeaderLink
                        onClick={(e) => {
                            e.preventDefault();
                            onBack();
                        }}
                        to="#"
                    >
                        {icon('back', 'dark-blue', 'mr-2')} {t('back')}
                    </SidebarHeaderLink>
                </SidebarHeader>
            </div>
            <div className="scrolling">
                <SidebarModule editing={true}>
                    <FieldUsageForm onCancel={onBack} onSubmit={handleSubmit} />
                </SidebarModule>
            </div>
        </div>
    );
};

export default AddFieldUsage;
