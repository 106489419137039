import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import icon from 'components/icons/icon';
import { getUsageName, hasNoCropCode } from 'fields/FieldUsage';
import { List } from 'immutable';
import type Input from 'inputs/Input';
import { joinNicely } from 'lib/util/text';
import { uniq } from 'ramda';
import type { FieldAndCurrentUsage } from 'system/types';
import ClickableRow from 'view/a11y/ClickableRow';
import ErrorMessage from 'view/ErrorMessage';

import type { PesticideCheckResults } from './PesticideCheck';
import {
    addFieldAndUsageToFieldGroups,
    getTotalChecksByStatus,
    PesticideCheckStatus,
} from './PesticideCheck';
import PesticideCheckResult from './PesticideCheckResult';

interface PesticideCheckResultsTableProps {
    input: Input;
    fields: FieldAndCurrentUsage[];
    startOpen?: boolean;
    pesticideChecks: PesticideCheckResults;
}

const PesticideCheckResultsTable = ({
    input,
    fields,
    startOpen = false,
    pesticideChecks,
}: PesticideCheckResultsTableProps) => {
    const { t } = useTranslation();
    const [open, toggleOpen] = useToggle(startOpen);

    const totalFails = getTotalChecksByStatus(pesticideChecks, PesticideCheckStatus.FAIL);
    const totalWarnings =
        getTotalChecksByStatus(pesticideChecks, PesticideCheckStatus.WARNING) +
        getTotalChecksByStatus(pesticideChecks, PesticideCheckStatus.UNKNOWN);
    const totalPasses = getTotalChecksByStatus(pesticideChecks, PesticideCheckStatus.PASS);

    const fieldsCropChecks = (
        pesticideChecks.fieldsCropChecks !== null
            ? addFieldAndUsageToFieldGroups(pesticideChecks.fieldsCropChecks, fields)
            : []
    ).filter((fieldsCropChecks) => fieldsCropChecks.checks.length > 0);

    const fieldsWithoutCrop = fields.filter((field) => hasNoCropCode(field.currentUsage));

    return (
        <table className="PesticideCheckResultsTable">
            <thead>
                <ClickableRow onClick={toggleOpen}>
                    <th colSpan={2}>
                        <div className="PesticideCheckResultsInputHeader">
                            <span>
                                {input.name} {input.pesticideData?.registrationNumber}
                            </span>
                            <span className="PesticideCheckFail">
                                {icon('edit-remove', 'red')}{' '}
                                {t('pesticide_check_result_result_filter_fail')} ({totalFails})
                            </span>
                            <span className="PesticideCheckWarning">
                                {icon('error-alert', 'orange', 'w-6')}{' '}
                                {t('pesticide_check_result_result_filter_warnings')} (
                                {totalWarnings})
                            </span>
                            <span className="PesticideCheckPass">
                                {icon('tick', 'green')}{' '}
                                {t('pesticide_check_result_result_filter_no_error')} ({totalPasses})
                            </span>
                            <i className={open ? 'ion-chevron-down' : 'ion-chevron-up'} />
                        </div>
                    </th>
                </ClickableRow>
            </thead>
            {open && (
                <tbody>
                    {fieldsCropChecks.map((fieldGroup, i) => {
                        return (
                            <Fragment key={i}>
                                <tr className="PesticideCheckResultsTableFieldNames">
                                    <td colSpan={2}>
                                        {joinNicely(
                                            List(
                                                fieldGroup.fields
                                                    .filter((field) => field !== undefined)
                                                    .map((field) => field!.field.name)
                                            )
                                        )}
                                    </td>
                                </tr>
                                {fieldGroup.checks.map((check, i) => (
                                    <PesticideCheckResult
                                        key={i}
                                        input={input}
                                        crop={joinNicely(
                                            List(
                                                uniq(
                                                    fieldGroup.fields.map((field) =>
                                                        getUsageName(field!.currentUsage)
                                                    )
                                                )
                                            )
                                        )}
                                        check={check}
                                    />
                                ))}
                            </Fragment>
                        );
                    })}
                    {fieldsWithoutCrop.length > 0 && (
                        <>
                            <tr className="PesticideCheckResultsTableFieldNames">
                                <td colSpan={2}>
                                    {joinNicely(
                                        List(fieldsWithoutCrop.map((field) => field.field.name))
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <ErrorMessage className="PesticideCheckInvalidMsg">
                                        {t('pesticide_check_invalid_fields')}
                                    </ErrorMessage>
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            )}
        </table>
    );
};

export default PesticideCheckResultsTable;
