import type { List } from 'immutable';
import { Record } from 'immutable';
import { DEFAULT_COLOUR } from 'lib/colours';
import { isEqual } from 'lodash';
import { trackEvent } from 'utils/trackEvent';

class FeatureType extends Record({
    uuid: '',
    name: '',
    colour: '',
    archived: false,
}) {}

export default FeatureType;

export interface FeatureTypeDTO {
    uuid: string;
    name: string;
    colour: string;
    archived?: boolean;
}

export const featureTypeToDiff = (
    featureType: FeatureType,
    data: { name: string; colour: string }
): Partial<FeatureTypeDTO> =>
    ['name', 'colour'].reduce((diff, key) => {
        if (!isEqual(featureType[key], data[key])) diff[key] = data[key];
        return diff;
    }, {});

export const deserializeFeatureType = (json: FeatureTypeDTO): FeatureType => {
    return new FeatureType({
        uuid: json.uuid,
        name: json.name,
        colour: `#${json.colour.replace('#', '')}`,
        archived: json.archived === undefined ? false : json.archived,
    });
};

export const findDefaultFeatureType = (featureTypes: List<FeatureType>): FeatureType | null =>
    featureTypes.size >= 1 ? (featureTypes.get(0) as FeatureType) : null;

export const asTypeItem = (featureType: FeatureType) => ({
    id: featureType.uuid,
    name: featureType.name,
    colour: featureType.colour ? featureType.colour : DEFAULT_COLOUR,
    archived: featureType.archived,
});

export const trackFeatureTypeChange = (msg: string) => (featureType: FeatureType) => {
    trackEvent(`Feature type ${msg}`, {
        featureTypeId: featureType.uuid,
    });
    return featureType;
};
