import type { PropsWithChildren } from 'react';
import classNames from 'classnames';

import './SmallPill.css';

const SmallPill = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
    return <div className={classNames('SmallPill', className)}>{children}</div>;
};

export default SmallPill;
