import { useTranslation } from 'react-i18next';
import c from 'classnames';
import SidebarModule from 'sidebar/modules/SidebarModule';
import { SidebarToastContext } from 'sidebar/Sidebar';
import InviteForm from 'team/view/InviteForm';

interface TeamInviteProps {
    className?: string;
}

const TeamInvite = ({ className }: TeamInviteProps) => {
    const { t } = useTranslation();
    return (
        <SidebarModule className={c('TeamInvite', className)}>
            <h3>{t('farm_settings_invite')}</h3>
            <SidebarToastContext.Consumer>
                {({ showSidebarToast }) => (
                    <InviteForm
                        showToast={(message: string, level?: string) =>
                            showSidebarToast({ message, level: level || 'info' }, 3000)
                        }
                        openDirection="right"
                    />
                )}
            </SidebarToastContext.Consumer>
        </SidebarModule>
    );
};

export default TeamInvite;
