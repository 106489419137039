import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { GeoPosition } from '@fieldmargin/webapp-geo';
import { createLogger } from '@fieldmargin/webapp-reporting';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import Promise from 'bluebird';
import { List, Record } from 'immutable';
import config from 'lib/config';

const logger = createLogger('farms.create.create-farm-api');

interface GeoServiceLocationDTO {
    location: {
        latitude: number;
        longitude: number;
    };
}

export const getUserLocationApi = () =>
    authorizedRequest<GeoServiceLocationDTO>({
        url: '/geo/location',
    }).then((data) => GeoPosition({ y: data.location.latitude, x: data.location.longitude }));

export const Place = Record({
    id: '',
    name: '',
    address: '',
    position: GeoPosition(),
});
export interface Place extends ReturnType<typeof Place> {}

interface PlaceData {
    id: string;
    name: string;
    formatted_address: string;
    position: {
        lng: number;
        lat: number;
    };
}

export const searchForPlacesApi = (text: string) => {
    return Promise.resolve(
        axios({
            method: 'get',
            url: `${config.get('googlePlacesApi')}/search_for_places`,
            params: {
                text,
            },
        })
    )
        .then((response: AxiosResponse<PlaceData[]>) =>
            List(
                response.data.map((d) =>
                    Place({
                        id: d.id,
                        name: d.name,
                        address: d.formatted_address,
                        position: GeoPosition({ x: d.position.lng, y: d.position.lat }),
                    })
                )
            )
        )
        .tapCatch(logger.handleRequestError('Unable to search for locations'));
};
