import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type FieldmarginLogoProps = Omit<ComponentProps<typeof Link>, 'to'> & {
    to?: string;
};

const FieldmarginLogo = ({ to = '/', ...props }: FieldmarginLogoProps) => {
    const { t } = useTranslation();

    return (
        <Link className="focus ring-inset px-5 flex items-center" to={to} {...props}>
            <img className="h-8" src="/images/fm-logo-small.png" alt={t('fieldmargin_logo')} />
        </Link>
    );
};

export default FieldmarginLogo;
