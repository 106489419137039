import type { KeyboardEvent, UIEvent } from 'react';
import { add, converge, equals, or, pipe, prop } from 'ramda';
import type { UnaryBooleanFunction } from 'system/types';

const getBoundingClientRect = (element: HTMLElement) => element.getBoundingClientRect();

type NumberFromRect = (rect: DOMRect) => number;
const sumRectTopAndHeight = converge<number, [NumberFromRect, NumberFromRect]>(add, [
    prop('top'),
    prop('height'),
]);

/**
 * Returns the offset of an element from the top of the screen, including the height of
 * the element.
 */
export const getElementScreenOffsetTop = pipe(getBoundingClientRect, sumRectTopAndHeight);

/**
 * Returns the distance in pixels between the bottom of the element and the bottom of the browser
 * window.
 */
export const getElementDistanceFromBottom = (el: HTMLElement) =>
    window.innerHeight - getElementScreenOffsetTop(el);

export const preventDefault = (event?: UIEvent) => event?.preventDefault();

/**
 * Returns a function that when called with a UIEvent (keydown, click, etc.) will prevent the
 * default behavior of the event and then call the given function.
 */
export const preventDefaultAnd = (fn: (...args: any[]) => void) =>
    pipe<[UIEvent], void, void>(preventDefault, fn);

/**
 * Returns a function that will call preventDefault if the given predicate returns true.
 */
export const preventDefaultWhen = (predicate: () => boolean) => (event: UIEvent) =>
    predicate() && event.preventDefault();

export type StopPropagation<T extends UIEvent> = (event: T) => void;
export const stopPropagation = <T extends UIEvent>(event: T) => event.stopPropagation();

/**
 * Returns true when the given value is 'Enter' or 'Space'
 */
export const isSpaceOrEnter = converge<
    boolean,
    [UnaryBooleanFunction<string>, UnaryBooleanFunction<string>]
>(or, [equals('Enter'), equals('Space')]);

/**
 * Returns true when the given KeyboardEvent is an enter or a space press.
 * This is useful if you're making a usually non-clickable element clickable.
 */
export const eventIsSpaceOrEnterKeyPress = pipe<[KeyboardEvent<HTMLDivElement>], string, boolean>(
    prop('code'),
    isSpaceOrEnter
);
