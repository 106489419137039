import classNames from 'classnames';
import { getYearFieldUsageUuid } from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import { useDateFormat } from 'hooks/useDateFormat';
import type { List } from 'immutable';
import { capitaliseWord } from 'lib/util/text';
import type { FullOperationField } from 'operations/OperationField';
import { operationFieldHasWeatherData } from 'operations/OperationField';
import type Output from 'outputs/Output';
import { useAppSelector } from 'system/store';
import Num from 'view/Num';
import FormattedArea from 'view/units/FormattedArea';
import FormattedRate from 'view/units/FormattedRate';
import ShortUnit from 'view/units/ShortUnit';
import SpeedUnit from 'view/units/SpeedUnit';
import SpeedValue from 'view/units/SpeedValue';
import Temperature from 'view/units/Temperature';
import TemperatureUnit from 'view/units/TemperatureUnit';

interface OperationPrintSheetYieldRowProps {
    opField: FullOperationField;
    output: Output;
}

const OperationPrintSheetYieldRow = ({ opField, output }: OperationPrintSheetYieldRowProps) => {
    const { format } = useDateFormat();
    const year = useAppSelector((state) => state.yearsState.currentYear as number);
    const fieldUsages = useAppSelector(
        (state) => state.fieldUsageState.fieldUsages as List<FieldUsage>
    );
    const fieldUsageUuid = getYearFieldUsageUuid(year, opField.field);
    const fieldUsage = fieldUsages.find((fieldUsage) => fieldUsage.uuid === fieldUsageUuid);
    const hasWeather = operationFieldHasWeatherData(opField);

    return (
        <>
            <tr>
                <td>
                    <strong>{opField.field.name}</strong>
                </td>
                <td>{fieldUsage ? fieldUsage.name : 'None'}</td>
                <td>
                    <FormattedArea areaSqm={opField.areaSqm} withUnit />
                </td>
                <td>{output.name}</td>
                <td>
                    {opField.yieldTotal && opField.yieldRateHa ? (
                        <FormattedRate rate={opField.yieldRateHa} />
                    ) : (
                        0
                    )}{' '}
                    <ShortUnit unit={output.unit} />
                </td>
                <td>
                    <Num value={opField.yieldTotal ?? 0} sigFigs={4} />{' '}
                    <ShortUnit unit={output.unit} />
                </td>
            </tr>
            <tr className={classNames('border-solid border-0', { 'border-b': !hasWeather })}>
                <td colSpan={6}>
                    Completed date:{' '}
                    {opField.completedDate && format(opField.completedDate, 'do MMMM yyyy, HH:mm')}
                </td>
            </tr>
            {hasWeather && (
                <tr className="border-solid border-0 border-b">
                    <td colSpan={6}>
                        Weather: {opField.windDirection && `${opField.windDirection} `}
                        {opField.windSpeed && (
                            <>
                                <SpeedValue value={opField.windSpeed} />
                                <SpeedUnit />{' '}
                            </>
                        )}
                        {opField.temperature && (
                            <>
                                <Temperature celsius={opField.temperature} />
                                <TemperatureUnit />{' '}
                            </>
                        )}
                        {opField.weatherNote && capitaliseWord(opField.weatherNote)}
                    </td>
                </tr>
            )}
        </>
    );
};

export default OperationPrintSheetYieldRow;
