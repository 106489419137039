import c from 'classnames';

interface BubbleTagProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const BubbleTag = ({ children, className, ...props }: BubbleTagProps) => {
    return (
        <div
            className={c(
                'inline-flex items-center rounded-full bg-white bordered px-4 py-1 text-xs',
                className
            )}
            {...props}
        >
            {children}
        </div>
    );
};

export default BubbleTag;
