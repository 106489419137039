import type { ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import { useFieldState } from 'informed';
import EditButton from 'sidebar/modules/common/EditButton';
import DateAndTimeField from 'sidebar/modules/date/DateAndTimeField';
import DueDate from 'sidebar/modules/date/DueDate';
import SidebarModule from 'sidebar/modules/SidebarModule';

interface NewOperationDueDateProps {
    field: string;
}

const NewOperationDueDate = ({ field }: NewOperationDueDateProps) => {
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const fieldState = useFieldState<Date>(field);

    let content: ReactNode;
    if (fieldState.value) {
        content = <DueDate date={fieldState.value} />;
    } else {
        content = <div className="not-set">{t('no_due_date')}</div>;
    }

    return (
        <div className="NewOperationDueDate">
            <SidebarModule noBorder>
                {!editing && <EditButton setEditing={setEditing} />}
                <h4 className="mb-0">{t('create_task_due_date_title_set')}</h4>
            </SidebarModule>
            <SidebarModule className={c({ 'pt-0': !editing })} editing={editing}>
                {!editing && content}
                <div className="mb-4" style={{ display: editing ? 'block' : 'none' }}>
                    <DateAndTimeField field={field} openDirection="top" allowDelete={true} />
                </div>
                {editing && (
                    <div className="EditableActions clear-both">
                        <div className="actions">
                            <Button onClick={() => setEditing(false)}>{t('other_done')}</Button>
                        </div>
                    </div>
                )}
            </SidebarModule>
        </div>
    );
};

export default NewOperationDueDate;
