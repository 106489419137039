import { GeoPoint, GeoPosition } from '@fieldmargin/webapp-geo';
import { Record } from 'immutable';

const Vehicle = Record({
    id: '',
    vehicleName: '',
    displayName: '',
    timestamp: new Date(),
    location: GeoPoint(),
});
interface Vehicle extends ReturnType<typeof Vehicle> {}

export interface VehicleDTO {
    id: string;
    location: {
        lat: number;
        lng: number;
    };
    name: string;
    active: boolean;
}

export const deserializeVehicle = (json: { updated_at: string | number; vehicle: VehicleDTO }) => {
    const { updated_at: updatedAt, vehicle } = json;
    const { lat, lng } = vehicle.location;
    return Vehicle({
        id: vehicle.id,
        location: GeoPoint({ coordinates: GeoPosition({ y: lat, x: lng }) }),
        vehicleName: vehicle.name,
        timestamp: new Date(updatedAt),
    });
};

export default Vehicle;
