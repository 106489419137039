import type { FormEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToggle } from '@fieldmargin/webapp-state';
import { SidebarCard } from 'components/sidebar-card';
import { useOutOfDatePromise } from 'lib/hooks';
import type FullOperation from 'operations/FullOperation';
import { selectOutputsSortedByName } from 'outputs/outputs-state';
import EditableActions from 'sidebar/modules/common/EditableActions';
import EditButton from 'sidebar/modules/common/EditButton';

import OutputSelector from '../outputs/OutputSelector';

interface OperationOutputViewProps {
    fullOperation: FullOperation;
    onAddOutput: VoidFunction;
    onSave: (fullOperation: FullOperation) => Promise<void>;
}

const OperationOutputView = ({ fullOperation, onAddOutput, onSave }: OperationOutputViewProps) => {
    const { t } = useTranslation();
    const outputs = useSelector(selectOutputsSortedByName);
    const [editing, toggleEditing] = useToggle(false);

    const output = outputs.find((o) => o.uuid === fullOperation.summary.outputUuid);

    return (
        <SidebarCard.Root>
            {editing ? (
                <ChangeOutputForm
                    fullOperation={fullOperation}
                    onSave={onSave}
                    onAddOutput={onAddOutput}
                    toggleEditing={toggleEditing}
                />
            ) : (
                <div className="flex items-center">
                    <SidebarCard.Heading as="h4" className="mb-0 mr-2">
                        {t('output')}:
                    </SidebarCard.Heading>
                    <p className="mb-0">
                        {output !== undefined ? (
                            output.name
                        ) : (
                            <em className="text-gray-400">
                                {t('operation_details_add_output_hint')}
                            </em>
                        )}
                    </p>
                    <EditButton setEditing={toggleEditing} className="ml-auto font-bold" />
                </div>
            )}
        </SidebarCard.Root>
    );
};

export default OperationOutputView;

const ChangeOutputForm = ({
    fullOperation,
    onSave,
    toggleEditing,
    onAddOutput,
}: {
    fullOperation: FullOperation;
    onSave: (fullOperation: FullOperation) => Promise<void>;
    toggleEditing: VoidFunction;
    onAddOutput: VoidFunction;
}) => {
    const { t } = useTranslation();
    const [nextOutputUuid, setNextOutputUuid] = useState<string | undefined>(
        fullOperation.summary.outputUuid ?? undefined
    );
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(toggleEditing);

    const handleSave = (e: FormEvent) => {
        e.preventDefault();
        setPromise(onSave(fullOperation.setIn(['summary', 'outputUuid'], nextOutputUuid)));
    };

    return (
        <form onSubmit={handleSave}>
            <h4 className="mb-1">{t('output')}</h4>
            <p className="text-gray-500">
                <em>{t('operation_details_add_output_hint')}</em>
            </p>
            <OutputSelector
                selectedOutputUuid={nextOutputUuid}
                onAddOutput={onAddOutput}
                onSelectOutput={(output) => setNextOutputUuid(output.uuid)}
            />
            <EditableActions
                disabled={pending}
                error={error}
                outOfDate={outOfDate}
                setEditing={toggleEditing}
            />
        </form>
    );
};
