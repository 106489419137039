import type { FeatureStyleOptions } from '@fieldmargin/webapp-ol-map';
import { FeaturesController } from '@fieldmargin/webapp-ol-map';
import type Feature from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

import pointFeatureMarker from './markers/point-feature.png';

class PointFeatureController extends FeaturesController {
    constructor(zIndex: number) {
        super(zIndex, {});
    }

    protected styleFeature(feature: Feature) {
        const type = feature.getGeometry()?.getType();
        const props = {
            ...feature.getProperties(),
        } as FeatureStyleOptions;

        if (type === 'Point') {
            const image = new Icon({
                src: pointFeatureMarker,
                scale: props.pointScale ? props.pointScale : 0.1,
                anchor: [0.5, 1],
                color: props.colour ? props.colour : '#4da4da', // This is core-sky blue
                crossOrigin: 'anonymous',
            });
            return new Style({
                image,
                zIndex: props.zIndex,
                text:
                    props.label !== null && props.label !== undefined
                        ? new Text({
                              font: 'bold 12px sans-serif',
                              fill: new Fill({ color: '#333' }),
                              offsetY: 10,
                              stroke: new Stroke({ color: '#fff', width: 3 }),
                              text: props.label,
                              overflow: true,
                          })
                        : undefined,
            });
        }
        throw new Error(`Cannot style type ${type}`);
    }
}

export default PointFeatureController;
