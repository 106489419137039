import type { Extent, GeoFeature } from '@fieldmargin/webapp-geo';
import {
    concatExtents,
    deserializeLngLat,
    extentFromGeo,
    Projection,
    reprojectExtent,
} from '@fieldmargin/webapp-geo';
import type { MapData } from 'components/maps/openlayers/LegacyMapAdapter';
import { selectDrawingControllerProps } from 'farm-editing/farm-editing-state';
import { List } from 'immutable';
import { logReselect } from 'lib/util/reselect-util';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

import type { FarmMapProps } from './FarmMap';
import { selectTiledLayers } from './select-custom-maps';
import { selectNonPointFeatures, selectPointFeatures } from './select-features';
import {
    selectAutoBoundaryGeoFeatures,
    selectFields,
    selectFieldTooltipProps,
} from './select-fields';
import { selectHerdMarkers } from './select-herds';
import { selectItemFeatures, selectItemFields } from './select-item-attachments';
import { selectMeasureShapes } from './select-measure-shapes';
import { selectNoteMarkers, selectNotes } from './select-notes';
import { selectSensors } from './select-sensors';
import { selectSubFields } from './select-sub-fields';
import { selectTeamTracking } from './select-team-tracking';
import { selectVehicles } from './select-vehicles';

const selectInitials = createSelector(
    selectVehicles,
    selectTeamTracking,
    (vehicles, teamTracking): GeoFeature[] => {
        logReselect('selectInitials');
        return vehicles.concat(teamTracking);
    }
);

export const selectMapData = createSelector(
    (state: AppState) => state.farmEditingState.baseMap,
    selectDrawingControllerProps,
    selectSensors,
    selectInitials,
    selectTiledLayers,
    selectNoteMarkers,
    selectPointFeatures,
    (state, activeSection) => selectFieldTooltipProps(state, { activeSection }),
    selectHerdMarkers,
    (
        basemap,
        drawing,
        sensors,
        initials,
        tiledLayers,
        noteMarkers,
        pointFeatures,
        fieldTooltipProps,
        herdMarkers
    ): MapData => {
        logReselect('selectMapData');
        return {
            basemap,
            drawing,
            sensors,
            initials,
            tiledLayers,
            noteMarkers,
            herdMarkers,
            pointFeatures,
            fieldTooltipProps,
        };
    }
);

export const selectGeoFeaturesToRender = createSelector(
    ({ state, activeSection, selectedNoteUuid }: FarmMapProps) =>
        selectNotes(state, activeSection, selectedNoteUuid),
    ({ state, activeSection, selectedFeatureUuid }: FarmMapProps, dimShapes: boolean) =>
        selectNonPointFeatures(state, activeSection, selectedFeatureUuid, dimShapes),
    ({ state, activeSection, selectedFieldUuid }: FarmMapProps, dimShapes: boolean) =>
        selectFields(state, { activeSection, selectedFieldUuid, dimShapes }),
    ({ state }) => selectSubFields(state),
    ({ state, selectedOperationUuid, selectedNoteUuid, selectedHerdUuid }: FarmMapProps) =>
        selectItemFields(state, { selectedOperationUuid, selectedNoteUuid, selectedHerdUuid }),
    ({ state, selectedOperationUuid, selectedNoteUuid, selectedHerdUuid }: FarmMapProps) =>
        selectItemFeatures(state, { selectedOperationUuid, selectedNoteUuid, selectedHerdUuid }),
    ({ state, activeSection, selectedManualSensorId }) =>
        selectSensors(state, activeSection, { selectedManualSensorId }),
    ({ state, activeSection }) => selectTeamTracking(state, activeSection),
    ({ state, activeSection }) => selectVehicles(state, activeSection),
    ({ state }) => selectMeasureShapes(state),
    ({ state }) => selectAutoBoundaryGeoFeatures(state),
    (
        notes,
        features,
        fields,
        subFields,
        itemFields,
        itemFeatures,
        sensors,
        teamTracking,
        vehicles,
        measureShapes,
        autoBoundaryShapes
    ): List<GeoFeature> => {
        logReselect('selectGeoFeaturesToRender');

        return List<GeoFeature>().concat(
            notes,
            features,
            fields,
            subFields,
            // If this is above "fields" fields that are attached to an item will not be shown
            // in white. Effectively fields that are attached have two entries with this one
            // overwriting the one in "fields".
            itemFields,
            itemFeatures,
            sensors,
            teamTracking,
            vehicles,
            measureShapes,
            autoBoundaryShapes
        );
    }
);

export const selectFullExtent = createSelector(
    ({ farm }: FarmMapProps) => farm.geoJsonPoint,
    selectGeoFeaturesToRender,
    ({ state }: FarmMapProps) => selectTiledLayers(state),
    (farmCentre, geoFeatures, tiledLayers): Extent | null => {
        logReselect('selectFullExtent');

        const mapExtents = tiledLayers.map((tiledLayer) =>
            reprojectExtent(tiledLayer.extent, Projection.WEB_MERCATOR)
        );
        const geoFeatureExtents = geoFeatures
            .map((geoFeature) => extentFromGeo(geoFeature))
            .filter((e) => e)
            .map((e) => reprojectExtent(e as Extent, Projection.WEB_MERCATOR));

        const totalExtent = concatExtents(List(mapExtents).concat(geoFeatureExtents));
        if (totalExtent) {
            return totalExtent;
        }
        if (farmCentre) {
            const farmCentreGeoPoint = deserializeLngLat(farmCentre);
            return reprojectExtent(
                extentFromGeo(farmCentreGeoPoint) as Extent,
                Projection.WEB_MERCATOR
            );
        }

        return null;
    }
);
