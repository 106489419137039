import { useEffect, useRef } from 'react';
import type { Extent, GeoFeature, GeoPoint } from '@fieldmargin/webapp-geo';
import { deserializeLngLat } from '@fieldmargin/webapp-geo';
import type { FeaturesContollerData } from '@fieldmargin/webapp-ol-map';
import { BasemapController, FeaturesController, OpenLayersMap } from '@fieldmargin/webapp-ol-map';
import { selectCurrentFarm } from 'farms/farms-state';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { List } from 'immutable';
import { getMapKeyConfig } from 'lib/config';
import { uniqueId } from 'lodash';
import { getSatelliteBasemap } from 'system/basemaps/basemaps';
import { useAppSelector } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';

import { getFieldsExtent } from './rpa-utils';

interface RPAImportMapProps {
    fieldFeatures: List<GeoFeature>;
    onFeatureClick: SingleParamVoidFunction<string>;
    zoomExtent?: Extent;
}

const RPAImportMap = ({ fieldFeatures, onFeatureClick, zoomExtent }: RPAImportMapProps) => {
    const { currentFarm } = useCurrentFarm();
    const [hostElementId] = useRPAMap(
        deserializeLngLat(currentFarm.geoJsonPoint) as GeoPoint,
        fieldFeatures.toArray(),
        onFeatureClick,
        zoomExtent
    );
    return (
        <div className="mb-5" style={{ height: 600, width: 600 }}>
            <div id={hostElementId} style={{ width: '100%', height: '100%' }} />
        </div>
    );
};

export default RPAImportMap;

// This tracks map movements
let viewportActionId = 0;
const useRPAMap = (
    centre: GeoPoint,
    fields: GeoFeature[],
    featureClick: SingleParamVoidFunction<string>,
    zoomExtent?: Extent
) => {
    const basemap = getSatelliteBasemap();

    const farm = useAppSelector(selectCurrentFarm);
    const hostElementId = useRef(uniqueId('rpa-import-map-')).current;
    const mapRef = useRef<OpenLayersMap<{ basemap: string; fields: FeaturesContollerData }>>();

    useEffect(() => {
        if (mapRef.current === undefined) {
            mapRef.current = new OpenLayersMap(
                {
                    hostElementId,
                    viewport: {
                        zoom: 9,
                        maxZoom: 21,
                        centre,
                    },
                },
                {
                    basemap: new BasemapController([basemap], getMapKeyConfig(), 1, {
                        language: window.navigator?.language ?? 'en-GB',
                        country: farm.country,
                    }),
                    fields: new FeaturesController(101, {}),
                },
                { featureClick }
            );
            mapRef.current.update(
                {
                    basemap,
                    fields: { geoFeatures: fields, options: {} },
                },
                {
                    id: viewportActionId++,
                    position: {
                        extent: getFieldsExtent(List(fields)),
                        maxZoom: 16,
                    },
                }
            );
        } else {
            mapRef.current.update({
                basemap,
                fields: { geoFeatures: fields, options: {} },
            });
        }
    }, [fields]);

    useEffect(() => {
        if (zoomExtent !== undefined) {
            mapRef.current?.update(
                {
                    basemap,
                    fields: { geoFeatures: fields, options: {} },
                },
                {
                    id: viewportActionId++,
                    position: {
                        extent: zoomExtent,
                        maxZoom: 18,
                        padding: [50, 50, 50, 50],
                    },
                }
            );
        }
    }, [zoomExtent]);

    return [hostElementId];
};
