import type { ChartData, ChartDataset } from 'chart.js';
import type { Context } from 'chartjs-plugin-datalabels';
import type { TFunction } from 'i18next';
import { getInputTypeI18nKey, getSortedInputTypes, INPUT_TYPE_COLOURS } from 'inputs/InputType';
import { shortenText } from 'lib/util/text';
import pattern from 'patternomaly';

import type { InputCosts } from './InputCost';
import { InputCost } from './InputCost';

export const getInputCostsGraphData = (inputCosts: InputCosts, t: TFunction): ChartData<'bar'> => {
    const fieldInputCosts = inputCosts.fields.toArray();

    return {
        labels: fieldInputCosts.map((fieldInputCost) => shortenText(20, fieldInputCost.fieldName)),
        datasets: getSortedInputTypes().map((inputType) => {
            const colours = INPUT_TYPE_COLOURS[inputType];
            return {
                label: t(getInputTypeI18nKey(inputType)),
                data: fieldInputCosts.map(
                    (fieldInputCost) => fieldInputCost.costs.get(inputType, new InputCost()).cost
                ),
                borderColor: colours.pattern ? '#ccc' : colours.background,
                borderWidth: 1,
                backgroundColor:
                    colours.pattern !== undefined
                        ? pattern.draw(colours.pattern, '#fff', colours.background)
                        : colours.background,
                maxBarThickness: 30,
            };
        }),
    };
};

export const getRowTotal = (context: Context) =>
    new Intl.NumberFormat(window.navigator.language, { maximumFractionDigits: 2 }).format(
        context.chart.data.datasets
            .filter(
                (dataset: ChartDataset<'bar'>) =>
                    typeof dataset.data[context.dataIndex] === 'number'
            )
            .reduce(
                (total, dataset: ChartDataset<'bar'>) =>
                    total + (dataset.data[context.dataIndex] as number),
                0
            )
    );
