import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { setMapIntegrationVisibility } from 'farm-editing/farm-editing-state';
import type { Set } from 'immutable';
import type { FarmIntegration } from 'integrations/FarmIntegration';
import type { MapGroupsByIntegration } from 'maps/farm/maps-state';
import { selectSortedMapGroupsByIntegration } from 'maps/farm/maps-state';
import MapsListVegetation from 'maps/vegetation/sidebar/MapsListVegetation';
import EmptyState from 'sidebar/modules/EmptyState';
import SidebarTitle from 'sidebar/modules/SidebarTitle';
import { useAppDispatch, useAppSelector } from 'system/store';
import { changeModal } from 'system/ui-state';

import MapsListIntegration from './MapsListIntegration';

const MAP_UPLOADER_UUID = '1bab41dc-e188-4e5b-b6fb-b438fcdcf03f';

const MapsList = () => {
    const mapGroupsByIntegration = useAppSelector(selectSortedMapGroupsByIntegration);
    const farmIntegrations = useAppSelector((state) => state.integrationsState.farmIntegrations);
    const hiddenMapIntegrations = useAppSelector(
        (state) => state.farmEditingState.layerVisibility.hiddenMapIntegrations
    );
    if (!mapGroupsByIntegration) {
        return null;
    }

    return (
        <div className="MapsList scrollable">
            <div className="non-scrolling">
                <MapListHeader />
            </div>
            <div className="scrolling">
                <div>
                    {/*
                            // @ts-ignore - can't get redux and onboarding props to work */}
                    <MapsListVegetation />
                    <MapsListIntegrations
                        farmIntegrations={farmIntegrations}
                        mapGroupsByIntegration={mapGroupsByIntegration}
                        hiddenMapIntegrations={hiddenMapIntegrations}
                    />
                    <MapsListEmpty
                        farmIntegrations={farmIntegrations}
                        mapGroupsByIntegration={mapGroupsByIntegration}
                    />
                </div>
            </div>
        </div>
    );
};

export default MapsList;

const MapListHeader = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    return (
        <SidebarTitle title="Maps">
            <Button
                onClick={() => {
                    dispatch(
                        changeModal({
                            name: 'connections',
                            props: {
                                initialSection: 'maps',
                            },
                        })
                    );
                }}
                className="flex items-center"
            >
                {icon('add', 'white', 'mr-1')} {t('import')}
            </Button>
        </SidebarTitle>
    );
};

interface MapsListIntegrationsProps {
    farmIntegrations: FarmIntegration[] | null;
    mapGroupsByIntegration: MapGroupsByIntegration;
    hiddenMapIntegrations: Set<string>;
}
const MapsListIntegrations = ({
    farmIntegrations,
    mapGroupsByIntegration,
    hiddenMapIntegrations,
}: MapsListIntegrationsProps) => {
    const dispatch = useAppDispatch();

    if (
        !farmIntegrations ||
        !mapGroupsByIntegration ||
        Object.keys(mapGroupsByIntegration).length === 0
    ) {
        return null;
    }

    return (
        <>
            {Object.entries(mapGroupsByIntegration).map(([farmIntegrationUuid, mapGroups]) => {
                const farmIntegration = farmIntegrations.find(
                    (fi) => fi.uuid === farmIntegrationUuid
                );
                if (!farmIntegration) {
                    return null;
                }

                return (
                    <MapsListIntegration
                        key={farmIntegrationUuid}
                        farmIntegration={farmIntegration}
                        mapGroups={mapGroups}
                        visible={!hiddenMapIntegrations.has(farmIntegrationUuid)}
                        setVisible={(visible) => {
                            dispatch(setMapIntegrationVisibility({ farmIntegrationUuid, visible }));
                        }}
                    />
                );
            })}
        </>
    );
};

interface MapsListEmpty {
    farmIntegrations: FarmIntegration[] | null;
    mapGroupsByIntegration: MapGroupsByIntegration;
}
const MapsListEmpty = ({ farmIntegrations, mapGroupsByIntegration }: MapsListEmpty) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    if (!farmIntegrations || !mapGroupsByIntegration) {
        return null;
    }

    const farmIntegration = farmIntegrations.find((fi) => fi.integrationUUID === MAP_UPLOADER_UUID);
    if (farmIntegration) {
        // Have integration, skip this
        return null;
    }

    return (
        <div>
            <div className="flex items-center bg-gray-100 p-5 separator-bottom">
                <img
                    className="w-12 h-12 mr-5"
                    src="https://integration-assets.fieldmargin.com/assets/1bab41dc-e188-4e5b-b6fb-b438fcdcf03f/map_uploader_256.png"
                    alt="Map uploader logo"
                />
                <h4 className="mb-0 text-lg">{t('plan_info_title_map_uploader')}</h4>
            </div>
            <EmptyState header="Upload and store your maps">
                <p>{t('map_list_map_uploader_empty_desc')}</p>
                <ul>
                    <li>{t('map_list_map_uploader_empty_item1')}</li>
                    <li>{t('map_list_map_uploader_empty_item2')}</li>
                    <li>{t('map_list_map_uploader_empty_item3')}</li>
                    <li>{t('map_list_map_uploader_empty_item4')}</li>
                    <li>{t('map_list_map_uploader_empty_item5')}</li>
                </ul>
                <Button
                    onClick={() => {
                        dispatch(
                            changeModal({
                                name: 'connections',
                                props: {
                                    initialSection: 'maps',
                                },
                            })
                        );
                    }}
                >
                    {t('import_maps')}
                </Button>
            </EmptyState>
        </div>
    );
};
