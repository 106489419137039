import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OnboardingBase } from '@notes-web/components';

function Onboarding() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onContinue = () => navigate('../add-fields');

    const timeline = t('timeline', { returnObjects: true }) as unknown as ComponentProps<
        typeof OnboardingBase
    >['timeline'];

    return (
        <OnboardingBase
            title={t('onboarding.heading')}
            description={t('onboarding.description')}
            timeline={timeline}
            onContinue={onContinue}
        />
    );
}

export { Onboarding };
