import type { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import c from 'classnames';
import { selectCurrentFarm } from 'farms/farms-state';
import type { AppState } from 'system/store';

interface UpgradeLinkProps {
    farm: Farm;
    className?: string;
    children?: ReactNode;
    onClick?: VoidFunction;
}

const UpgradeLink = ({ farm, className, children, onClick = () => null }: UpgradeLinkProps) =>
    farm.demo ? null : (
        <Link to={`/farms/${farm.uuid}/billing/upgrade`} className={c(className)} onClick={onClick}>
            {children || 'Upgrade'}
        </Link>
    );

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
}))(UpgradeLink);
