import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import { OperationField } from 'components/operation-field';
import type FullOperation from 'operations/FullOperation';
import type { FullOperationField } from 'operations/OperationField';
import Num from 'view/Num';
import FormattedArea from 'view/units/FormattedArea';
import FormattedRate from 'view/units/FormattedRate';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import MarkCompleteCheckbox from '../MarkCompleteCheckbox';
import MarkCompleteModal from '../MarkCompleteModal';

import OperationFieldCompletedInfo from './OperationFieldCompletedInfo';

interface OperationFieldListItemWithYieldProps {
    farm: Farm;
    fullOperation: FullOperation;
    operationField: FullOperationField;
    onEditFields: VoidFunction;
}

const OperationFieldListItemWithYield = ({
    farm,
    fullOperation,
    operationField,
    onEditFields,
}: OperationFieldListItemWithYieldProps) => {
    const { t } = useTranslation();
    const [showCompleteModal, toggleShowCompleteModal] = useToggle(false);
    return (
        <OperationField.Item
            completed={operationField.completedDate !== undefined}
            fieldColor={operationField.fieldUsage?.colour}
        >
            {showCompleteModal && (
                <MarkCompleteModal
                    farm={farm}
                    fullOperation={fullOperation}
                    operationField={operationField}
                    onClose={toggleShowCompleteModal}
                    onEditFields={onEditFields}
                />
            )}

            <div className="flex-grow py-2">
                <div className="flex items-center">
                    <MarkCompleteCheckbox
                        farm={farm}
                        fullOperation={fullOperation}
                        operationField={operationField}
                        onChecked={toggleShowCompleteModal}
                        className="-mt-0.5 h-5"
                    />
                    <strong className="OperationFieldName">{operationField.field.name}</strong>
                    <span className="OperationFieldYieldRate">
                        {t('yield')}/<ShortAreaUnit />
                    </span>
                    <span className="OperationFieldYieldTotal">{t('total')}</span>
                </div>
                <div className="flex">
                    <span className="OperationFieldWorkArea">
                        <FormattedArea areaSqm={operationField.areaSqm} withUnit />
                    </span>
                    <span className="OperationFieldYieldRate">
                        {operationField.yieldRateHa !== undefined ? (
                            <FormattedRate rate={operationField.yieldRateHa} />
                        ) : (
                            '-'
                        )}
                    </span>
                    <span className="OperationFieldYieldTotal">
                        {operationField.yieldTotal !== undefined ? (
                            <Num value={operationField.yieldTotal} sigFigs={4} />
                        ) : (
                            '-'
                        )}
                    </span>
                </div>
                <OperationFieldCompletedInfo operationField={operationField} />
            </div>
        </OperationField.Item>
    );
};

export default OperationFieldListItemWithYield;
