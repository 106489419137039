import type { RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';
import SensorsList from 'sensors/sidebar/list/SensorsList';
import ManualSensorDeletion from 'sensors/sidebar/ManualSensorDeletion';
import ManualSensorDetails from 'sensors/sidebar/ManualSensorDetails';
import ManualSensorEdit from 'sensors/sidebar/ManualSensorEdit';
import ManualSensorNew from 'sensors/sidebar/ManualSensorNew';
import ManualSensorReadingGroupDeletion from 'sensors/sidebar/ManualSensorReadingGroupDeletion';
import SensorEdit from 'sensors/sidebar/SensorEdit';
import ErrorMessage from 'view/ErrorMessage';

export const dataRoutes: RouteObject = {
    path: APP_ROUTES.Data.Root,

    children: [
        {
            index: true,
            element: <SensorsList />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing sensors, please refresh the page.
                </ErrorMessage>
            ),
        },

        {
            path: APP_ROUTES.Data.EditSensor,
            element: <ManualSensorEdit />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when editing a monitoring site, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Data.EditSensorLocation,
            element: <SensorEdit />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when editing a sensor location, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Data.New,
            element: <ManualSensorNew />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when creating a monitoring site, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Data.SensorDetails,
            element: <ManualSensorDetails />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing a monitoring site, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Data.DeleteSensor,
            element: <ManualSensorDeletion />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when deleting a monitoring site, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Data.DeleteSensorGroup,
            element: <ManualSensorReadingGroupDeletion />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when deleting a monitoring site reading, please refresh the
                    page.
                </ErrorMessage>
            ),
        },
    ],
};
