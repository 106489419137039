import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import c from 'classnames';
import { AttachmentType } from 'farm-editing/attachments';
import {
    removeEditingAttachmentByType,
    startSelectingMultipleAttachments,
} from 'farm-editing/farm-editing-state';
import type Field from 'fields/Field';
import { getFieldWorkedArea, getYearFieldUsageUuid } from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import type { List } from 'immutable';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type { FullOperationField } from 'operations/OperationField';
import { bindActionCreators } from 'redux';
import PrimaryPlusButton from 'sidebar/modules/PrimaryPlusButton';
import { selectEditingFields } from 'sidebar/modules/shapes/shapes-util';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { useSetTutorialStep } from 'tutorials/tutorial-hooks';
import { selectUserAreaMeasurementUnit } from 'users/user-state';

import NewOperationFieldsTable from './NewOperationFieldsTable';
import NewOperationYieldTable from './NewOperationYieldTable';
import { isHarvestOperation } from './operation-new-utils';

interface NewOperationFieldsProps {
    // From parent
    onAdd: VoidFunction;
    operationType?: string;
    totalArea: number;

    // From redux
    year: number;
    editingFields: List<Field>;
    fieldUsages: List<FieldUsage>;
    areaMeasurementUnit: MeasurementUnit;
    startSelectingMultipleAttachments: typeof startSelectingMultipleAttachments;
    removeEditingFieldUuid: SingleParamVoidFunction<string>;
    activeTutorialStep: string | null;
}

const NewOperationFields = ({
    onAdd,
    operationType,
    totalArea,
    year,
    editingFields,
    fieldUsages,
    areaMeasurementUnit,
    startSelectingMultipleAttachments,
    removeEditingFieldUuid,
    activeTutorialStep,
}: NewOperationFieldsProps) => {
    const { t } = useTranslation();
    const setTutorialStep = useSetTutorialStep();
    const handleAddClick = useCallback(() => {
        onAdd();
        startSelectingMultipleAttachments(AttachmentType.FIELD);
        setTutorialStep(null);
    }, [onAdd, startSelectingMultipleAttachments, setTutorialStep]);
    return (
        <div
            className={c('NewOperationFields', { forefront: activeTutorialStep === 'add-fields' })}
        >
            <SidebarModule className="flex justify-between items-center">
                <h4 className="mb-0">{t('fields')}</h4>
                <PrimaryPlusButton onClick={handleAddClick}>
                    {t('operation_details_add_fields')}
                </PrimaryPlusButton>
            </SidebarModule>
            {!isHarvestOperation(operationType) ? (
                <NewOperationFieldsTable
                    operationFields={getEditingInfo(editingFields, fieldUsages, year)}
                    totalArea={totalArea}
                    areaMeasurementUnit={areaMeasurementUnit}
                    onRemoveField={removeEditingFieldUuid}
                />
            ) : (
                <NewOperationYieldTable
                    operationFields={getEditingInfo(editingFields, fieldUsages, year)}
                    totalArea={totalArea}
                    areaMeasurementUnit={areaMeasurementUnit}
                    onRemoveField={removeEditingFieldUuid}
                />
            )}
        </div>
    );
};

const getEditingInfo = (
    editingFields: List<Field>,
    fieldUsages: List<FieldUsage>,
    year: number
): List<FullOperationField> => {
    const items: List<FullOperationField> = editingFields.map((field) => {
        const fieldUsageUuid = getYearFieldUsageUuid(year, field);
        const fieldUsage = fieldUsages.find((fu) => fu.uuid === fieldUsageUuid);

        return {
            field,
            fieldUsage,
            areaSqm: getFieldWorkedArea(field, year),
        };
    });

    return items.sortBy(
        (operationField) =>
            `${
                operationField.fieldUsage?.name.toLowerCase() || ' '
            } / ${operationField.field.name.toLowerCase()}`
    );
};

export default connect(
    (state: AppState) => ({
        year: state.yearsState.currentYear,
        areaMeasurementUnit: selectUserAreaMeasurementUnit(state),
        editingFields: selectEditingFields(state),
        fieldUsages: state.fieldUsageState.fieldUsages,
        activeTutorialStep: state.tutorialsState.activeStep,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                startSelectingMultipleAttachments,
                removeEditingFieldUuid: removeEditingAttachmentByType(AttachmentType.FIELD),
            },
            dispatch
        )
)(NewOperationFields);
