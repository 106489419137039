import { useState } from 'react';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import type Field from 'fields/Field';
import type { List } from 'immutable';
import { notNil, uuidMatches } from 'lib/fp-helpers';
import Modal from 'modal/Modal';
import ErrorMessage from 'view/ErrorMessage';

interface BulkFieldEditDeleteWarningProps {
    onConfirm: VoidFunction;
    onCancel: VoidFunction;
    fields: List<Field>;
    toDelete: string[]; // list of field uuids
    pending: boolean;
    error: boolean;
}

const BulkFieldEditDeleteWarning = ({
    onCancel,
    onConfirm,
    fields,
    toDelete,
    pending,
    error,
}: BulkFieldEditDeleteWarningProps) => {
    const fieldsToDelete = toDelete.map((uuid) => fields.find(uuidMatches(uuid))).filter(notNil);
    const [doubleConfirm, toggleDoubleConfirm] = useToggle(false);
    const [deleteVal, setDeleteVal] = useState('');
    return (
        <Modal onClose={onCancel}>
            <div className="BulkFieldEditWarningModal">
                <h2>Are you sure you want to delete your fields?</h2>
                <p>
                    You are about to delete {fieldsToDelete.length} fields. They will be deleted for
                    all years on your farm. If you are not using them this year and want to keep
                    their records you can archive them.
                </p>
                <p className="mb-0">Do you want to delete:</p>
                <ul>
                    {fieldsToDelete.map((field) => (
                        <li key={field.uuid}>
                            <strong>{field.name}</strong>
                        </li>
                    ))}
                </ul>
                {!doubleConfirm && (
                    <div className="flex gap-4 mx-auto">
                        <Button variant="danger-outline" onClick={onCancel} disabled={pending}>
                            No, go back
                        </Button>
                        <Button onClick={toggleDoubleConfirm} disabled={pending}>
                            Yes, delete
                        </Button>
                    </div>
                )}
                {doubleConfirm && (
                    <div className="text-center">
                        <p>
                            Please type "DELETE" in the text box below to confirm you want to delete
                            these fields
                        </p>
                        <input
                            type="text"
                            value={deleteVal}
                            onChange={(e) => setDeleteVal(e.target.value)}
                            className="simple-text-input mx-auto mb-4"
                        />
                        <Button
                            onClick={onConfirm}
                            disabled={pending || deleteVal.toLowerCase() !== 'delete'}
                            variant="danger"
                        >
                            Confirm
                        </Button>
                    </div>
                )}
                {error && (
                    <ErrorMessage>
                        Something went wrong when saving your fields, please refresh and try again.
                    </ErrorMessage>
                )}
            </div>
        </Modal>
    );
};

export default BulkFieldEditDeleteWarning;
