import type { ChangeEvent } from 'react';

import i18n from '../../packages/app/src/i18n';

import styles from './LanguageChooser.module.css';

const LanguageChooser = () => {
    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        i18n.changeLanguage(event.target.value);
    };
    return (
        <div className={styles.languageChooser}>
            <label htmlFor="language-chooser">Language chooser</label>
            <select id="language-chooser" onChange={handleChange} defaultValue={i18n.language}>
                <option value="en">English</option>
                <option value="de">German</option>
                <option value="ro">Romanian</option>
            </select>
        </div>
    );
};

export default LanguageChooser;
