import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { deserialize, Projection } from '@fieldmargin/webapp-geo';
import type { FeatureDTO } from 'features/Feature';
import Feature, { deserializeFeature } from 'features/Feature';
import type { FeatureTypeDTO } from 'features/FeatureType';
import FeatureType, { deserializeFeatureType } from 'features/FeatureType';
import type { FeatureCollection } from 'geojson';
import { List } from 'immutable';
import { arrayToMapWithUuid, mapMap, possibleApiRequest } from 'lib/fp-helpers';
import type { NoteDTO } from 'notes/Note';
import { deserializeNote } from 'notes/Note';

export const getFeatures = (farmUuid: string) =>
    authorizedRequest<FeatureDTO[]>({
        url: `/notes-api/farms/${farmUuid}/features/`,
    }).then((features) => List(features.map(deserializeFeature)));

export const createFeature = (
    farmUuid: string,
    name: string,
    geoJson: FeatureCollection,
    featureTypeUuid: string
) =>
    authorizedRequest<string>({
        url: `/notes-api/farms/${farmUuid}/features/`,
        method: 'POST',
        data: { name, geoJson, featureTypeUuid },
    }).then(
        (uuid) =>
            new Feature({
                uuid,
                name,
                geoJson: deserialize(geoJson, Projection.LNG_LAT) as GeoFeatureCollection,
                featureTypeUuid,
            })
    );

export const updateFeature = (farmUuid: string, featureUuid: string, data: Partial<FeatureDTO>) =>
    possibleApiRequest<Partial<FeatureDTO>>(data, () =>
        authorizedRequest({
            url: `/notes-api/farms/${farmUuid}/features/${featureUuid}/`,
            method: 'PUT',
            data,
        }).then(() => data)
    );

export const deleteFeature = (farmUuid: string, feature: Feature) => {
    return authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/features/${feature.uuid}/`,
        method: 'DELETE',
    }).then(() => feature);
};

export const getFeatureTypes = (farmUuid: string) =>
    authorizedRequest<FeatureTypeDTO[]>({
        url: `/notes-api/farms/${farmUuid}/features/types/`,
    }).then((features) => List(features.map(deserializeFeatureType)));

export const createFeatureType = (farmUuid: string, name: string, colour: string) =>
    authorizedRequest<string>({
        url: `/notes-api/farms/${farmUuid}/features/types/`,
        method: 'POST',
        data: { name, colour },
    }).then((uuid) => new FeatureType({ uuid, name, colour }));

export const updateFeatureType = (
    farmUuid: string,
    featureTypeUuid: string,
    data: Partial<FeatureTypeDTO>
) =>
    possibleApiRequest<Partial<FeatureTypeDTO>>(data, () =>
        authorizedRequest({
            url: `/notes-api/farms/${farmUuid}/features/types/${featureTypeUuid}/`,
            method: 'PUT',
            data,
        }).then(() => data)
    );

export const toggleArchiveFeatureType = (
    farmUuid: string,
    featureTypeUuid: string,
    archived: boolean
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/features/types/${featureTypeUuid}/archive`,
        method: 'PUT',
        params: { archived },
    });

export const fetchFeatureNotes = (farmUuid: string, year: number, featureUuid: string) =>
    authorizedRequest<NoteDTO[]>({
        url: `/notes-api/farms/${farmUuid}/years/${year}/notes/features/${featureUuid}`,
    }).then((notes) => mapMap(arrayToMapWithUuid(notes), deserializeNote));
