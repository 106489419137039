import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import { uniqueId } from 'lodash';
import type FullOperation from 'operations/FullOperation';

import MarkAllCompleteModal from './MarkAllCompleteModal';

interface MarkAllCompleteProps {
    fullOperation: FullOperation;
    onEditFields: VoidFunction;
    disabled?: boolean;
    checked: boolean;
}

const MarkAllComplete = ({
    fullOperation,
    onEditFields,
    disabled = false,
    checked,
}: MarkAllCompleteProps) => {
    const { t } = useTranslation();
    const id = uniqueId('mark-all-complete-');
    const [modalOpen, toggleModalOpen] = useToggle(false);
    return (
        <>
            {modalOpen && (
                <MarkAllCompleteModal
                    fullOperation={fullOperation}
                    onClose={toggleModalOpen}
                    onEditFields={onEditFields}
                />
            )}
            <Checkbox
                id={id}
                checked={checked}
                onChange={toggleModalOpen}
                label={t('operation_details_field_complete_all')}
                disabled={disabled}
            />
        </>
    );
};

export default MarkAllComplete;
