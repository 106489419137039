import type { RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';
import VehicleTracking from 'vehicle-tracking/sidebar/VehicleTracking';
import ErrorMessage from 'view/ErrorMessage';

export const vehiclesRoutes: RouteObject = {
    path: APP_ROUTES.Vehicles.Root,

    errorElement: (
        <ErrorMessage className="p-5">
            Something went wrong when viewing vehicles, please refresh the page.
        </ErrorMessage>
    ),

    children: [
        {
            index: true,
            element: <VehicleTracking />,
        },
    ],
};
