import { Component } from 'react';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import type { Set } from 'immutable';
import BubbleTag from 'view/molecules/BubbleTag';

interface UserTagListProps {
    taggedUsers: Set<FarmUser>;
}

class UserTagList extends Component<UserTagListProps> {
    render() {
        const { taggedUsers } = this.props;

        if (taggedUsers.size) {
            const items = taggedUsers
                .sortBy((farmUser) => `${farmUser.firstName} ${farmUser.lastName}`)
                .map((farmUser) => {
                    return (
                        <BubbleTag key={farmUser.id} className="space-y-3 mr-3">
                            {farmUser.firstName} {farmUser.lastName}
                        </BubbleTag>
                    );
                });
            return <div>{items}</div>;
        }
        return <div className="not-set">Nobody has been tagged</div>;
    }
}

export default UserTagList;
