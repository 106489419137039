import { useTranslation } from 'react-i18next';
import type { Activity } from 'activity/activity-helper';
import { activityIsNote } from 'activity/activity-helper';
import { shortenText } from 'lib/util/text';
import type { Nullable } from 'system/types';
import { useFarmUserDisplayName } from 'team/farm-users-hooks';

import { useDateFormat } from './useDateFormat';
import { useUserId } from './useUserId';

const useLastActivitySummary = (activity: Activity) => {
    const { t } = useTranslation();
    const { formatDistanceToNow } = useDateFormat();
    const { userId: currentUserId } = useUserId();
    const getDisplayName = useFarmUserDisplayName();

    const lastActivity = activityIsNote(activity)
        ? activity.lastActivity
        : activity.summary.lastActivity;

    if (!lastActivity) {
        return { lastActivitySummary: null };
    }
    const { date, type, userId, metadata } = lastActivity;

    const displayName = userId === currentUserId ? t('note_list_you') : getDisplayName(userId);
    const timeAgo = formatDistanceToNow(date, { addSuffix: true });

    let lastActivitySummary: Nullable<string> = null;
    switch (type) {
        case 'created':
            lastActivitySummary = t('note_list_type_create', { sprintf: [displayName, timeAgo] });
            break;
        case 'comment':
            lastActivitySummary = metadata
                ? shortenText(40, `${displayName}: ${metadata}`)
                : t('note_list_type_discussion_unknown', { sprintf: [displayName] });
            break;
        case 'image':
            lastActivitySummary = t('note_list_type_photo', { sprintf: [displayName, timeAgo] });
            break;
        case 'file':
            lastActivitySummary = t('note_list_type_file', { sprintf: [displayName, timeAgo] });
            break;
        case 'completed':
            lastActivitySummary = t('note_list_type_complete', { sprintf: [displayName, timeAgo] });
            break;
        case 'todo':
            lastActivitySummary = t('note_list_type_todo', { sprintf: [displayName, timeAgo] });
            break;
        case 'operation_field':
            lastActivitySummary = t('note_list_type_field_complete', {
                sprintf: [displayName, metadata, timeAgo],
            });
            break;
    }

    return { lastActivitySummary };
};

export { useLastActivitySummary };
