import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { useRemoveFieldBoundary } from 'hooks/useRemoveFieldBoundary';
import ShapeBubbles from 'view/shapes/ShapeBubbles';

interface FieldBoundaryCreationDrawnProps {
    // From parent
    editingGeoFeatureCollection: GeoFeatureCollection | null;
    editing: boolean;
}

const FieldBoundaryBubble = ({
    editingGeoFeatureCollection,
    editing,
}: FieldBoundaryCreationDrawnProps) => {
    const { onRemoveBoundary } = useRemoveFieldBoundary();

    return (
        editingGeoFeatureCollection && (
            <div className="px-5 pb-2">
                <ShapeBubbles
                    geoFeatureCollection={editingGeoFeatureCollection}
                    onRemove={editing ? onRemoveBoundary : undefined}
                    includeEmptyMessage={false}
                    includePerimeter={!editing}
                />
            </div>
        )
    );
};

export default FieldBoundaryBubble;
