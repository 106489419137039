import type { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { GeoFeature } from '@fieldmargin/webapp-geo';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import icon from 'components/icons/icon';
import { highlightGeoFeatureId } from 'farm-editing/farm-editing-state';
import { useAutoBoundaryNameForm } from 'hooks/useAutoBoundaryNameForm';
import { useEditingFieldUuids } from 'hooks/useEditingFieldUuids';
import { useEditingGeoFeatureCollection } from 'hooks/useEditingGeoFeatureCollection';
import { usePageParams } from 'hooks/usePageParamas';
import { useCreateFieldTitleTracking } from 'hooks/useSegmentTracking';
import { Form, useFieldState } from 'informed';
import { preventDefaultAnd } from 'lib/dom';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { SingleParamVoidFunction } from 'system/types';
import UnsavedFormChangesChecker from 'unsaved-changes/UnsavedFormChangesChecker';
import NativeTextFormField from 'view/form/native/NativeTextFormField';
import TextInputFormField from 'view/form/TextInputFormField';
import { required } from 'view/form/validations';
import ReturningLinkButton from 'view/ReturningLinkButton';
import ZoomIcon from 'view/svg/ZoomIcon';

export interface AutoBoundaryNameFormValues {
    names: { [k: string]: string };
    ids?: { [k: string]: string };
}

const AutoBoundaryNameForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { editingFieldUuids } = useEditingFieldUuids();
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();
    const { farmUuid } = usePageParams();
    const {
        isPending,
        onSubmit,
        onZoom,
        onRemoveField,
        onAddField,
        onNameChange,
        onHighlightGeoFeature,
    } = useAutoBoundaryNameForm();

    const navigateToUsage = () =>
        navigate(`/farms/${farmUuid}/fields/usage`, {
            state: { ignoreUnsavedChanges: true },
        });

    const handleSubmit = (values: AutoBoundaryNameFormValues) => {
        onSubmit(values, () => navigateToUsage());
    };

    const handleZoomClick = (featureId: string | number) => {
        onZoom(featureId);
    };

    const handleRemoveClick = (featureId: string | number) => {
        onRemoveField(featureId);
    };
    const handleAddClick = (featureId: string | number) => {
        onAddField(featureId.toString());
    };

    const handleHighlightGeoFeature = (featureId: string | number) => {
        onHighlightGeoFeature(featureId.toString());
    };

    useCreateFieldTitleTracking();

    return (
        <Form className="scrollable" onSubmit={handleSubmit}>
            <UnsavedFormChangesChecker
                copy={[t('fields_create_discard1'), t('fields_create_discard2')]}
                initialValues={{ name: {} }}
            >
                <div className="non-scrolling">
                    <SidebarHeader className="px-5">
                        <ReturningLinkButton
                            to={`/farms/${farmUuid}/fields/new`}
                            variant="danger-outline"
                            small
                        >
                            {t('cancel')}
                        </ReturningLinkButton>
                        <SubmitButton disabled={isPending || editingFieldUuids.size === 0} small>
                            {t('save')}
                        </SubmitButton>
                    </SidebarHeader>
                </div>
                <div className="scrolling">
                    <SidebarModule editing>
                        <h4>{t('field_boundary_auto_edit_names_title')}</h4>
                        <div className="flex flex-col gap-4">
                            {editingGeoFeatureCollection?.features.map((feature, i) => (
                                <AutoBoundaryNameField
                                    key={feature.id}
                                    focusOnRender={i === 0}
                                    feature={feature}
                                    included={editingFieldUuids.includes(feature.id.toString())}
                                    onZoom={handleZoomClick}
                                    onRemove={handleRemoveClick}
                                    onAdd={handleAddClick}
                                    onFocus={handleHighlightGeoFeature}
                                    onBlur={highlightGeoFeatureId}
                                    onChange={onNameChange}
                                />
                            ))}
                        </div>
                    </SidebarModule>
                </div>
            </UnsavedFormChangesChecker>
        </Form>
    );
};

export default AutoBoundaryNameForm;

interface AutoBoundaryNameFieldProps {
    focusOnRender: boolean;
    feature: GeoFeature;
    included: boolean;
    onZoom: SingleParamVoidFunction<string | number>;
    onRemove: SingleParamVoidFunction<string | number>;
    onAdd: SingleParamVoidFunction<string | number>;
    onFocus: SingleParamVoidFunction<string | number>;
    onBlur: VoidFunction;
    onChange: (featureId: string | number, e: ChangeEvent<HTMLInputElement>) => void;
}
const AutoBoundaryNameField = ({
    focusOnRender,
    feature,
    included,
    onZoom,
    onRemove,
    onAdd,
    onFocus,
    onBlur,
    onChange,
}: AutoBoundaryNameFieldProps) => {
    const { t } = useTranslation();
    const fieldState = useFieldState<string>(`names.${feature.id}`);
    const handleToggleIncluded = preventDefaultAnd(() => {
        if (included) {
            onRemove(feature.id);
        } else {
            onAdd(feature.id);
        }
    });

    const nameRequired = required(t('field_create_name_validation'));

    return (
        <div className="flex gap-3 items-start">
            <button
                className="bare-btn focus mt-3"
                onClick={preventDefaultAnd(() => onZoom(feature.id))}
            >
                <div className="sr-only">Zoom to field</div>
                <ZoomIcon />
            </button>
            <div className="flex-grow flex items-start gap-3">
                {included ? (
                    <>
                        <TextInputFormField
                            placeholder={t('hint_create_field_name')}
                            field={`names.${feature.id}`}
                            focusOnRender={focusOnRender}
                            validateOnBlur
                            validateOnChange
                            validate={nameRequired}
                            onFocus={() => onFocus(feature.id)}
                            onBlur={() => onBlur()}
                            onChange={(e) => onChange(feature.id, e)}
                            className="w-2/3"
                            inputClassName="w-full placeholder:italic placeholder:text-gray-400"
                            error={fieldState.error}
                        />
                        <TextInputFormField
                            placeholder={t('field_id')}
                            field={`ids.${feature.id}`}
                            onFocus={() => onFocus(feature.id)}
                            onBlur={() => onBlur()}
                            className="w-1/3"
                            inputClassName="w-full placeholder:italic placeholder:text-gray-400"
                        />
                    </>
                ) : (
                    <>
                        <NativeTextFormField
                            id={`names.${feature.id}`}
                            value=""
                            onChange={() => {}}
                            disabled
                            className="w-2/3"
                            inputClassName="w-full"
                        />
                        <NativeTextFormField
                            id={`ids.${feature.id}`}
                            value=""
                            onChange={() => {}}
                            disabled
                            className="w-1/3"
                            inputClassName="w-full"
                        />
                    </>
                )}
            </div>
            <button className="bare-btn focus mt-2" onClick={handleToggleIncluded}>
                <div className="sr-only">{included ? 'Remove field' : 'Add field'}</div>
                {included ? icon('close', 'red') : icon('add', 'blue')}
            </button>
        </div>
    );
};
