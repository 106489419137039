import type { ComponentProps } from 'react';
import { clsx } from 'clsx';
import { ButtonBase } from 'components/button-base';
import { Heading } from 'components/heading';
import { PlusIcon } from 'icons/PlusIcon';
import ReturningLink from 'view/ReturningLink';

import styles from './SidebarCard.module.css';

function Root({ className, ...props }: ComponentProps<'div'>) {
    return <div className={clsx(styles.card, className)} {...props} />;
}

function Link({ className, ...props }: ComponentProps<typeof ReturningLink>) {
    return <ReturningLink className={clsx(styles.link, className)} {...props} />;
}

function Header({ ...props }: ComponentProps<'div'>) {
    return <div className={styles.header} {...props} />;
}

function SidebarHeading({ ...props }: ComponentProps<typeof Heading>) {
    return <Heading {...props} />;
}

function Buttons({ ...props }: ComponentProps<'div'>) {
    return <div className={styles.buttons} {...props} />;
}

function CardButton({ children, ...props }: ComponentProps<typeof ButtonBase.Root>) {
    return (
        <ButtonBase.Root className={styles.button} {...props}>
            {children}
        </ButtonBase.Root>
    );
}

function AddButton({ children, ...props }: ComponentProps<typeof CardButton>) {
    return (
        <CardButton {...props}>
            <ButtonBase.Icon className={styles.iconButton}>
                <PlusIcon />
            </ButtonBase.Icon>
            {children}
        </CardButton>
    );
}

Root.displayName = 'SidebarCard.Root';
Link.displayName = 'SidebarCard.Link';
Header.displayName = 'SidebarCard.Header';
SidebarHeading.displayName = 'SidebarCard.Heading';
Buttons.displayName = 'SidebarCard.Buttons';
CardButton.displayName = 'SidebarCard.Button';
AddButton.displayName = 'SidebarCard.AddButton';

export const SidebarCard = {
    Root,
    Link,
    Header,
    Heading: SidebarHeading,
    AddButton,
    Button: CardButton,
    Buttons,
};
