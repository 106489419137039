import { useContext, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import DancingWheat from '@fieldmargin/webapp-styling/components/dancing-wheat';
import { useApplicationLayoutContext } from '@notes-web/layout';
import { clsx } from 'clsx';
import { GlobalBaseMapControl } from 'components/maps/base-map/GlobalBaseMapControl';
import FullExtentButton from 'components/maps/full-extent/FullExtentButton';
import { NotesVisibilityControl } from 'components/notes-visibility-control';
import { setMapView } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm, selectCurrentFarmHasMapPrinting } from 'farms/farms-state';
import type Field from 'fields/Field';
import SubFieldMapMenu from 'fields/sub-fields/map/SubFieldMapMenu';
import type { List } from 'immutable';
import TilesMessageControl from 'maps/common/map-controls/TilesMessageControl';
import VegetationMapLegend from 'maps/vegetation/map-controls/VegetationMapLegend';
import { SentinelHubStyle } from 'maps/vegetation/sentinel-hub-api';
import type Note from 'notes/Note';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { UIContext } from 'system/ui-state';
import type { ActiveSection } from 'system/url-util';
import ErrorMessage from 'view/ErrorMessage';
import InfoMsg from 'view/InfoMsg';
import Arrow from 'view/svg/Arrow';
import CurrentWeather from 'weather/CurrentWeather';

import FarmMap from './map/FarmMap';

import './FarmMapContainer.css';
import styles from './FarmMapContainer.module.css';

interface FarmMapContainerProps {
    farm: Farm;
    activeSection: ActiveSection;
    selectedFeatureUuid?: string;
    fields?: List<Field>;
    selectedFieldUuid?: string;
    notes?: Map<string, Note>;
    selectedNoteUuid?: string;
    selectedOperationUuid?: string;
    selectedHerdUuid?: string;
    selectedManualSensorId?: string;
    isEditing: boolean;
    setMapView: typeof setMapView;
    showNDVILegend: boolean;
    tilesLoading: boolean;
    tilesError: boolean;
    hasPrinting: boolean;
}

const FarmMapContainer = ({
    farm,
    activeSection,
    selectedFeatureUuid,
    fields,
    selectedFieldUuid,
    notes,
    selectedNoteUuid,
    selectedOperationUuid,
    selectedHerdUuid,
    selectedManualSensorId,
    isEditing,
    setMapView,
    showNDVILegend,
    tilesLoading,
    tilesError,
    hasPrinting,
}: FarmMapContainerProps) => {
    const navigate = useNavigate();
    const { Map } = useApplicationLayoutContext();
    const { infoOpen } = useContext(UIContext);
    useEffect(() => {
        if (fields && fields.size === 0 && notes && notes.size === 0) {
            navigate(`/farms/${farm.uuid}/fields`);
        }
    }, []);

    const handleFullExtentClick = () => {
        setMapView({
            type: 'full-extent',
        });
    };

    if (!farm) {
        return (
            <div className="farm-view">
                <DancingWheat />
            </div>
        );
    }

    return (
        <div className={clsx('FarmMapContainer', 'openlayers', { 'no-printing': !hasPrinting })}>
            <ErrorBoundary
                fallback={
                    <ErrorMessage className="bg-white p-5 h-full w-full">
                        Something went wrong with the farm map, please refresh the page.
                    </ErrorMessage>
                }
            >
                <FarmMap
                    navigate={navigate}
                    activeSection={activeSection}
                    selectedFeatureUuid={selectedFeatureUuid}
                    selectedFieldUuid={selectedFieldUuid}
                    selectedNoteUuid={selectedNoteUuid}
                    selectedOperationUuid={selectedOperationUuid}
                    selectedHerdUuid={selectedHerdUuid}
                    selectedManualSensorId={selectedManualSensorId || ''}
                    isEditing={isEditing}
                />

                <div className={`${styles.mapLeftButtons} no-print`}>
                    <GlobalBaseMapControl />
                    <NotesVisibilityControl />
                </div>

                <FullExtentButton setFullExtent={handleFullExtentClick} />
                {showNDVILegend && <VegetationMapLegend />}
                <TilesMessageControl loading={tilesLoading} error={tilesError} />
                <div className={`${styles.mapRightButtons} no-print`}>
                    <div
                        className={clsx(
                            'no-print flex items-end xl:items-start flex-col-reverse xl:flex-row gap-5',
                            { 'forefront-abs': infoOpen }
                        )}
                    >
                        {infoOpen && (
                            <>
                                <InfoMsg className="-left-52 top-36 xl:-left-32 xl:top-0 xl:flex-col-reverse 2xl:top-4 2xl:-left-52 2xl:flex-row w-52">
                                    Measure tools to measure your farm
                                    <Arrow className="w-12 2xl:w-24 flip-y -rotate-90 relative -top-4 xl:top-0 2xl:-top-4" />
                                </InfoMsg>
                                <InfoMsg className="top-16 -left-44 xl:top-12 xl:left-40 xl:flex-col flex-row-reverse">
                                    <Arrow className="w-12 rotate-90 xl:rotate-0 flip-x ml-3 xl:mb-2" />
                                    Print your farm
                                </InfoMsg>
                            </>
                        )}

                        {Map && <Map.Button.Measure />}
                        {Map && <Map.Button.Print />}
                        <CurrentWeather className={infoOpen ? 'opacity-0' : undefined} />
                    </div>
                </div>

                {activeSection.sub === 'sub-fields' && <SubFieldMapMenu />}
            </ErrorBoundary>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        fields: state.fieldsState.fields,
        notes: state.notesState.notes,
        showNDVILegend:
            !!state.vegetationMapsState.visibleSentinelHubMap &&
            state.vegetationMapsState.visibleStyle === SentinelHubStyle.NDVI,

        tilesLoading: state.mapsState.tilesLoading,
        tilesError: state.mapsState.tilesError,
        hasPrinting: selectCurrentFarmHasMapPrinting(state),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setMapView,
            },
            dispatch
        )
)(FarmMapContainer);
