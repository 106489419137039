import type { Set } from 'immutable';
import { uuidMatches } from 'lib/fp-helpers';
import type Recording from 'operations/Recording';
import { always, when } from 'ramda';

import type { OperationNewStep } from './operation-new-utils';

export interface OperationNewState {
    isTitlePlaceholder: boolean;
    showBulkOutputModal: boolean;
    bulkCreatingOutputs: boolean;
    editingOperationRecordings: Set<Recording>;
    isAttachingRecordings: boolean;
    attachedOutputUuid?: string;
    addingOutput: boolean;
    step: OperationNewStep;
    totalArea: number;
}

export const operationNewReducer = (
    state: OperationNewState,
    action: { type: string; payload: any }
): OperationNewState => {
    switch (action.type) {
        case 'setIsTitlePlaceholder':
            return { ...state, isTitlePlaceholder: action.payload };
        case 'toggleBulkOutputModal':
            return { ...state, showBulkOutputModal: !state.showBulkOutputModal };
        case 'toggleBulkCreatingOutputs':
            return { ...state, bulkCreatingOutputs: !state.bulkCreatingOutputs };
        case 'setEditingOperationRecordings':
            return { ...state, editingOperationRecordings: action.payload };
        case 'removeOperationRecording':
            return {
                ...state,
                editingOperationRecordings: state.editingOperationRecordings.delete(action.payload),
            };
        case 'updateOperationRecording':
            return {
                ...state,
                editingOperationRecordings: state.editingOperationRecordings.map(
                    when<Recording, Recording>(
                        uuidMatches(action.payload.uuid),
                        always(action.payload)
                    )
                ),
            };
        case 'toggleAttachingRecordings':
            return { ...state, isAttachingRecordings: !state.isAttachingRecordings };
        case 'setAttachedOutputUuid':
            return { ...state, attachedOutputUuid: action.payload };
        case 'toggleAddingOutput':
            return { ...state, addingOutput: !state.addingOutput };
        case 'setStep':
            return { ...state, step: action.payload };
        case 'setTotalArea':
            return { ...state, totalArea: action.payload };
        default:
            return state;
    }
};

const createAction =
    <Payload>(type: string) =>
    (payload?: Payload) => ({ type, payload });

export const setIsTitlePlaceHolder = createAction<boolean>('setIsTitlePlaceholder');
export const toggleBulkOutputModal = createAction<undefined>('toggleBulkOutputModal');
export const toggleBulkCreatingOutputs = createAction<undefined>('toggleBulkCreatingOutputs');
export const setEditingOperationRecordings = createAction<Set<Recording>>(
    'setEditingOperationRecordings'
);
export const removeOperationRecording = createAction<Recording>('removeOperationRecording');
export const updateOperationRecording = createAction<Recording>('updateOperationRecording');
export const toggleAttachingRecordings = createAction<undefined>('toggleAttachingRecordings');
export const setAttachedOutputUuid = createAction<string>('setAttachedOutputUuid');
export const toggleAddingOutput = createAction<undefined>('toggleAddingOutput');
export const setStep = createAction<OperationNewStep>('setStep');
export const setTotalArea = createAction<number>('setTotalArea');
