import { useTranslation } from 'react-i18next';
import { OperationType } from 'operations/Operation';

const useTranslatedJobs = () => {
    const { t } = useTranslation();

    const toDoFilter = { label: t('tasks'), id: 'Tasks' };

    const notesFilter = { label: t('notes'), id: 'Notes' };

    const jobsData = [
        { label: t('all'), id: 'All' },
        { label: t('operation_type_fertilize'), id: OperationType.FERTILIZE },
        { label: t('operation_type_harvest'), id: OperationType.HARVEST },
        { label: t('operation_type_silage'), id: OperationType.HAY_SILAGE },
        { label: t('operation_type_plant'), id: OperationType.PLANT },
        { label: t('operation_type_spray'), id: OperationType.SPRAY },
        { label: t('operation_type_till'), id: OperationType.TILL },
        { label: t('operation_type_irrigate'), id: OperationType.IRRIGATE },
        { label: t('operation_type_other'), id: OperationType.OTHER },
    ];

    return {
        jobsData,
        notesFilter,
        toDoFilter,
        allTranslatedJobs: [...jobsData, notesFilter, toDoFilter],
    };
};

export { useTranslatedJobs };
