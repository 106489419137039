import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import { herdColours } from 'lib/colours';
import { preventDefaultAnd } from 'lib/dom';
import SidebarModule from 'sidebar/modules/SidebarModule';
import CollapsibleColourPickerField from 'view/form/hook/CollapsibleColourPickerField';
import TextInputField from 'view/form/hook/TextInputField';
import { required, requiredPositiveNumber } from 'view/form/validations';

interface HerdSplitFormFieldsProps {
    field: string;
    alternate: boolean;
    remove?: VoidFunction;
}

const HerdSplitFormFields = ({ field, alternate, remove }: HerdSplitFormFieldsProps) => {
    const { t } = useTranslation();

    const validateNameAndColour = required(t('register_required'));
    const validateAnimals = requiredPositiveNumber(t('validation_number'));

    return (
        <SidebarModule editing={!alternate}>
            {remove && (
                <div className="text-right">
                    <button className="bare-btn focus" onClick={preventDefaultAnd(remove)}>
                        <div className="sr-only">Remove group</div>
                        {icon('close', 'red')}
                    </button>
                </div>
            )}
            <div className="flex gap-5 mb-5">
                <TextInputField
                    label={<strong>{t('group_name')}</strong>}
                    field={`${field}.name`}
                    validate={validateNameAndColour}
                    className="w-2/3"
                    inputClassName="w-full"
                />
                <TextInputField
                    label={<strong>{t('livestock_details_count')}</strong>}
                    field={`${field}.number`}
                    validate={validateAnimals}
                />
            </div>
            <CollapsibleColourPickerField
                label={<strong>{t('label_select_color')}</strong>}
                field={`${field}.colour`}
                validate={validateNameAndColour}
                colours={herdColours}
            />
        </SidebarModule>
    );
};

export default HerdSplitFormFields;
