import classNames from 'classnames';

import type { FarmMapEvents } from './hooks/useCreateFarmMap';
import { useCreateFarmMap } from './hooks/useCreateFarmMap';
import type { UpdateFarmMapProps } from './hooks/useUpdateFarmMap';
import { useUpdateFarmMap } from './hooks/useUpdateFarmMap';

type NewFarmMapProps = UpdateFarmMapProps & { events: FarmMapEvents };

const NewFarmMap = ({ events, ...props }: NewFarmMapProps) => {
    const { mapRef, hostElementId } = useCreateFarmMap(events);
    useUpdateFarmMap(mapRef, props);

    return (
        <div className="NewFarmMap relative overflow-hidden h-content-full bg-gray-300">
            <div
                id={hostElementId}
                style={{ width: '100%', height: '100%' }}
                className={classNames({ retina: window.devicePixelRatio === 2 })}
            />
        </div>
    );
};

export default NewFarmMap;
