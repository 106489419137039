import { useMatch } from 'react-router-dom';
import { selectFeature } from 'features/features-selectors';
import { selectField } from 'fields/fields-selectors';
import { selectHerd } from 'herd/herd-selectors';
import { selectNote } from 'notes/notes-selectors';
import { selectOperation } from 'operations/operations-selectors';
import { ROUTES } from 'routes';
import { useAppSelector } from 'system/store';

/**
 * This will return the current note, operation or herd based on the current route.
 * It can be used by components above the route that would not otherwise be able to
 * get the page parameters using useParams.
 */
export const useCurrentItemOutsideRoute = () => {
    const fieldUuid = useMatch(ROUTES.field)?.params.fieldUuid;
    const field = useAppSelector((state) => selectField(state, fieldUuid ?? ''));
    const noteUuid = useMatch(ROUTES.note)?.params.noteUuid;
    const note = useAppSelector((state) => selectNote(state, noteUuid ?? ''));
    const operationUuid = useMatch(ROUTES.operation)?.params.operationUuid;
    const operation = useAppSelector((state) => selectOperation(state, operationUuid ?? ''));
    const featureUuid = useMatch(ROUTES.feature)?.params.featureUuid;
    const feature = useAppSelector((state) => selectFeature(state, featureUuid ?? ''));
    const herdUuid = useMatch(ROUTES.herd)?.params.herdUuid;
    const herd = useAppSelector((state) => selectHerd(state, herdUuid ?? ''));

    return { field, note, operation, feature, herd };
};
