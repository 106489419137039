interface ArrowProps extends React.SVGProps<SVGSVGElement> {}

const Arrow = (props: ArrowProps) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            {...props}
        >
            <path
                id="Path_3418"
                style={{ fill: 'white' }}
                d="M32.7,9.7l10.7,10.7l0.6-0.3c-0.3-1.1-0.7-2.2-1.3-3.1c-4.2-5-8.5-9.8-12.9-14.7
	c-1.6-1.8-2.9-1.8-3.9,0.2c-2.2,4.3-4.2,8.7-6.1,13.2c-0.3,0.8-0.2,1.6,0.3,2.3c1.1,0.8,1.9-0.2,2.4-1.1c1-1.7,1.8-3.4,2.7-5.1
	c0.6-1.2,1.4-2.3,2.5-3.2c0.5,2.2,0.9,4.4,1.2,6.6c0.7,11.8-1.4,23.5-6.1,34.3c-1.4,3.5-3.7,6.6-6.8,8.8c1.8,1,4.1,0.5,5.3-1.3
	c1.9-2.2,3.5-4.7,4.8-7.3c5.1-11.3,7.2-23.8,6.2-36.2c-0.1-1.3-0.2-2.5-0.3-3.8L32.7,9.7"
            />
        </svg>
    );
};

export default Arrow;
