import {
    getAccessToken,
    makeAuthorizationRequest,
    makeFreshAccessTokenRequest,
} from '@fieldmargin/webapp-auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AxiosError } from 'axios';
import configService from 'lib/config';
import { always } from 'ramda';
import type { Nullable } from 'system/types';

const buildBaseQuery = (apiRoot: string, token: Nullable<string>) =>
    fetchBaseQuery({
        baseUrl: apiRoot,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: async (args, api, extraOptions) => {
        let baseQuery = buildBaseQuery(configService.get('apiRoot'), getAccessToken());
        const result = await baseQuery(args, api, extraOptions);
        if (result.error?.status === 401) {
            // In this case the access token has probably expired so we'll try to refresh it,
            // if not we'll redirect the user to login again.
            try {
                await makeFreshAccessTokenRequest();
                baseQuery = buildBaseQuery(configService.get('apiRoot'), getAccessToken());
                return baseQuery(args, api, extraOptions);
            } catch (e) {
                if (e instanceof AxiosError && e.message === 'no-refresh-token') {
                    makeAuthorizationRequest();
                }
            }
        }
        return result;
    },
    endpoints: always({}),
    tagTypes: ['FARM_CONNECTION'],
});
