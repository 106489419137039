interface DataIconProps {
    className?: string;
    fill?: string;
}

const DataIcon = ({ className, fill = '#4da4da' }: DataIconProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 56 60"
            className={className}
        >
            <g>
                <path
                    id="Path_195_2_"
                    style={{ fill }}
                    d="M43.4,10.9h-8c-1,0-1.9,0.8-2,1.8v0.2c0,1,0.8,1.9,1.8,2h3.4l-7.8,6.8l-2.2-2.2
		c-0.7-0.8-2-0.8-2.7-0.1c0,0,0,0-0.1,0.1l0,0l-5.6,5.6c-0.8,0.7-0.8,2-0.1,2.7c0,0,0,0,0.1,0.1l0,0c0.7,0.8,2,0.8,2.7,0.1
		c0,0,0,0,0.1-0.1l0,0l4.2-4.2l2.2,2.2c0.7,0.8,2,0.8,2.7,0.1c0,0,0,0,0.1-0.1l0,0l9.2-8.2v3.2c0,1.1,0.9,2,2,2s2-0.9,2-2v-8
		c0-1-0.8-1.9-1.8-2H43.4z"
                />
                <path
                    id="Path_234_1_"
                    style={{ fill }}
                    d="M10.6,47.1V24c-0.1-1,0.7-1.8,1.8-1.9c0.1,0,0.2,0,0.2,0l0,0c1-0.1,1.9,0.7,2,1.7
		c0,0.1,0,0.2,0,0.2v23.1c0.1,1-0.7,1.8-1.8,1.9c-0.1,0-0.2,0-0.2,0l0,0c-1,0-1.9-0.8-2-1.7V47.1z"
                />
                <path
                    id="Path_235_6_"
                    style={{ fill }}
                    d="M19.6,47.1v-12c0-1,0.8-1.9,1.8-2h0.2c1,0,1.9,0.8,2,1.8v12.2c0,1-0.8,1.9-1.8,2h-0.2
		c-1,0-1.9-0.8-2-1.8V47.1z"
                />
                <path
                    id="Path_235_5_"
                    style={{ fill }}
                    d="M37.6,47.1v-10c0-1,0.8-1.9,1.8-2h0.2c1,0,1.9,0.8,2,1.8v10.2c0,1-0.8,1.9-1.8,2h-0.2
		c-1,0-1.9-0.8-2-1.8V47.1z"
                />
                <path
                    id="Path_235_4_"
                    style={{ fill }}
                    d="M28.6,47.1v-14c0-1,0.8-1.9,1.8-2h0.2c1,0,1.9,0.8,2,1.8v14.2c0,1-0.8,1.9-1.8,2h-0.2
		c-1,0-1.9-0.8-2-1.8V47.1z"
                />
            </g>
        </svg>
    );
};

export default DataIcon;
