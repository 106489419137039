import { connect } from 'react-redux';
import { SearchBar } from 'components/search-bar';
import { setFieldSearchString } from 'fields/fields-state';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';

interface FieldSearchBarProps {
    className?: string;
    // From redux
    fieldSearchString: string;
    setFieldSearchString: typeof setFieldSearchString;
}

const FieldSearchBar = ({
    className,
    fieldSearchString,
    setFieldSearchString,
}: FieldSearchBarProps) => {
    return (
        <SearchBar
            className={className}
            searchFunction={setFieldSearchString}
            value={fieldSearchString}
        />
    );
};

export default connect(
    (state: AppState) => ({
        fieldSearchString: state.fieldsState.fieldSearchString,
    }),
    (dispatch) => bindActionCreators({ setFieldSearchString }, dispatch)
)(FieldSearchBar);
