import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AttachmentType } from 'farm-editing/attachments';
import {
    setEditingAttachmentsByType,
    toggleEditingAttachmentByType,
} from 'farm-editing/farm-editing-state';
import type { FarmMapFeature } from 'farm-map/types';
import { selectCurrentFarm } from 'farms/farms-state';
import { Set } from 'immutable';
import { useAction } from 'lib/hooks';
import { useAppSelector } from 'system/store';
import type { Nullable } from 'system/types';

/**
 * Possible scenarios:
 * 1. No attachments are being selected and user clicks on a field: Navigate to the field.
 * 2. Multiple attachments are being selected and user clicks on a field: Toggle if the field is attached.
 * 3. Single attachment is being selected and user clicks on a field: Set field as the attachment.
 * 4. User clicks on a non-field feature & is selecting either multiple or single fields: Do nothing,
 *    but consider the click handled so that no other effects take place.
 * 5. User clicks on a non-field feature & is not selecting fields: Do nothing.
 */
export const useFarmMapFieldClick = () => {
    const navigate = useNavigate();
    const farmUuid = useAppSelector(selectCurrentFarm).uuid;

    // We have to use refs to store this state because otherwise the data is stale
    // in the returned function.
    const selectingMultipleState = useAppSelector(
        (state) => state.farmEditingState.selectingMultipleAttachmentType
    );
    const selectingMultipleRef = useRef<Nullable<AttachmentType>>(selectingMultipleState);

    const selectingSingleState = useAppSelector(
        (state) => state.farmEditingState.selectingSingleAttachmentType
    );
    const selectingSingleRef = useRef<Nullable<AttachmentType>>(selectingSingleState);

    const toggleFieldAttachment = useAction(toggleEditingAttachmentByType(AttachmentType.FIELD));
    const setFieldAttachment = useAction(setEditingAttachmentsByType(AttachmentType.FIELD));

    useEffect(() => {
        selectingMultipleRef.current = selectingMultipleState;
        selectingSingleRef.current = selectingSingleState;
    }, [selectingMultipleState, selectingSingleState]);

    return (feature: FarmMapFeature) => {
        if (
            selectingMultipleRef.current !== null &&
            selectingMultipleRef.current !== AttachmentType.FIELD
        ) {
            return false;
        }

        if (feature.properties.type !== 'FIELD') {
            return (
                selectingMultipleRef.current === AttachmentType.FIELD ||
                selectingSingleRef.current === AttachmentType.FIELD
            );
        }

        if (selectingMultipleRef.current) {
            toggleFieldAttachment(feature.properties.uuid);
            return true;
        }

        if (selectingSingleRef.current) {
            setFieldAttachment(Set.of(feature.properties.uuid));
            return true;
        }

        navigate(`/farms/${farmUuid}/fields/${feature.properties.uuid}`);
        return true;
    };
};
