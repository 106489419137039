import { useTranslation } from 'react-i18next';
import { Link, useMatch } from 'react-router-dom';
import { SidebarCard } from 'components/sidebar-card';
import TabNav from 'view/tab-nav/TabNav';
import TabNavItem from 'view/tab-nav/TabNavItem';

import './OperationDetailTabs.css';

const OperationDetailTabs = () => {
    const { t } = useTranslation();

    const indexRouteMatch = useMatch('/farms/:farmUuid/operations/:operationId');
    const commentRouteMatch = useMatch('/farms/:farmUuid/operations/:operationId/comments');

    return (
        <SidebarCard.Root className="OperationDetailTabs">
            <TabNav className="OperationDetailTabList centered">
                <TabNavItem
                    className="OperationDetailTabListItem"
                    activeClass="border-fm-sky"
                    active={indexRouteMatch !== null}
                >
                    <Link to="." className="OperationDetailTabsLink">
                        {t('activity_log_info')}
                    </Link>
                </TabNavItem>
                <TabNavItem
                    className="OperationDetailTabListItem"
                    activeClass="border-fm-sky"
                    active={commentRouteMatch !== null}
                >
                    <Link to="comments" className="OperationDetailTabsLink">
                        {t('activity_log_comments')}
                    </Link>
                </TabNavItem>
            </TabNav>
        </SidebarCard.Root>
    );
};

export default OperationDetailTabs;
