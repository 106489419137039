import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { isSameDay, isSameMonth, isSameYear } from 'date-fns';
import type { List } from 'immutable';
import type { SentinelHubMap } from 'maps/vegetation/sentinel-hub-api';

import 'react-calendar/dist/Calendar.css';
import './SentinelHubCalendar.css';

interface SentinelHubCalendarProps {
    selected?: SentinelHubMap;
    options: List<SentinelHubMap>;
    onSelect: (pmg?: SentinelHubMap) => void;
    className?: string;
}

const SentinelHubCalendar = ({
    selected,
    options,
    onSelect,
    className,
}: SentinelHubCalendarProps) => {
    const { t, i18n } = useTranslation();

    const styleTile = ({ date, view }: { date: Date; view: any }) => {
        if (view !== 'month') {
            return null;
        }
        const map = getMapForDate(view, options, date);
        if (map) {
            return map.cloudCover > 10 ? 'dot cloudy' : 'dot good';
        }
        return 'dot';
    };

    const tileDisabled = ({ date, view }) => !getMapForDate(view, options, date);

    const dates = options.sortBy((mg) => mg.timestamp).map((mg) => mg.timestamp);

    const handleDateSelect = (date: Date) => {
        const newSelected = options.find((mg) => isSameDay(mg.timestamp, date));
        if (newSelected) {
            if (selected === newSelected) {
                onSelect();
            } else {
                onSelect(newSelected);
            }
        }
    };

    return (
        <div className={c('SentinelHubCalendar', className)}>
            <Calendar
                locale={i18n.language}
                calendarType="iso8601"
                tileClassName={styleTile}
                tileDisabled={tileDisabled}
                showNeighboringMonth={false}
                minDate={dates.get(0)}
                maxDate={dates.get(-1)}
                onChange={handleDateSelect}
                value={selected !== undefined ? selected.timestamp : null}
            />
            <div className="calendar-key">
                <span className="key-item">
                    <span className="dot good" /> {t('farm_maps_clear')}
                </span>
                <span className="key-item">
                    <span className="dot cloudy" /> {t('farm_maps_cloudy')}
                </span>
            </div>
        </div>
    );
};

const getMapForDate = (view: string, pmgs: List<SentinelHubMap>, toFind: Date) => {
    if (view === 'century') {
        return pmgs.find(
            (pmg) =>
                Math.floor(pmg.timestamp.getFullYear() / 10) ===
                Math.floor(toFind.getFullYear() / 10)
        );
    }
    if (view === 'decade') {
        return pmgs.find((pmg) => isSameYear(pmg.timestamp, toFind));
    }
    if (view === 'year') {
        return pmgs.find((pmg) => isSameMonth(pmg.timestamp, toFind));
    }
    if (view === 'month') {
        return pmgs.find((pmg) => isSameDay(pmg.timestamp, toFind));
    }
    return null;
};

export default SentinelHubCalendar;
