import type { KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { useRef } from 'react';
import type { GeoPosition } from '@fieldmargin/webapp-geo';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { useTranslations } from 'hooks/useTranslations';
import type { List } from 'immutable';
import { browserHasGeoLocation, getUserCurrentGeoPosition } from 'lib/geo/geolocation';

import type { Place } from './create-farm-api';
import { searchForPlacesApi } from './create-farm-api';
import locateSvg from './locate.svg';

import './CreateFarmLocationSearch.scss';

interface CreateFarmLocationSearchProps {
    onLocationSearch: (places: List<Place>) => void;
    onGeoLocationLookup: (geoPosition: GeoPosition) => void;
    searchedPlaces?: List<Place>;
    selectedPlaceId?: string;
}

const CreateFarmLocationSearch = ({
    onLocationSearch,
    onGeoLocationLookup,
    searchedPlaces,
    selectedPlaceId,
}: CreateFarmLocationSearchProps) => {
    const { t } = useTranslations();
    const { error, setPromise } = usePromise<List<Place>>(onLocationSearch);
    const {
        pending: locationPending,
        error: locationError,
        setPromise: setUserLocationPromise,
    } = usePromise<GeoPosition>(onGeoLocationLookup);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleSearch = () => {
        if (inputRef.current && inputRef.current.value) {
            setPromise(searchForPlacesApi(inputRef.current.value));
        }
    };

    const handleSearchClick = (e: MouseEvent) => {
        e.preventDefault();
        handleSearch();
    };
    const handleSearchEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 27) {
            e.preventDefault();
            handleSearch();
        }
    };

    const handleGeoLocationLookup = (e?: MouseEvent) => {
        e && e.preventDefault();
        setUserLocationPromise(getUserCurrentGeoPosition());
    };

    let geolookupBtn: ReactNode = null;
    if (browserHasGeoLocation()) {
        geolookupBtn = (
            <>
                <Button
                    variant="outline"
                    className="relative"
                    onClick={handleGeoLocationLookup}
                    disabled={locationPending}
                >
                    <img src={locateSvg} className="locate-icon" /> {t('use_my_location')}
                </Button>
                {locationError && (
                    <p className="field-error">
                        {t('use_my_location_error', {
                            defaultValue: 'We were unable to get your location',
                        })}
                    </p>
                )}
            </>
        );
    }

    return (
        <div className="CreateFarmLocationSearch form-field">
            <label htmlFor="farm-location-search">
                {t('label_choose_farm_location', { defaultValue: 'Choose farm location' })}
            </label>
            <input
                ref={inputRef}
                className="simple-text-input"
                type="text"
                id="farm-location-search"
                placeholder={t('create_farm_farm_code_hint')}
                onKeyDown={handleSearchEnter}
            />
            <button className="search-icon" onClick={handleSearchClick}>
                {icon('search')}
            </button>
            <SearchResultMessage
                searchedPlaces={searchedPlaces}
                selectedPlaceId={selectedPlaceId}
                searchError={error}
            />
            <div className="separator">{t('or').toLowerCase()}</div>
            {geolookupBtn}
        </div>
    );
};

export default CreateFarmLocationSearch;

const SearchResultMessage = ({
    searchedPlaces,
    selectedPlaceId,
    searchError,
}: {
    searchedPlaces: List<Place> | undefined;
    selectedPlaceId: string | undefined;
    searchError: boolean;
}) => {
    const { t } = useTranslations();
    if (searchError) {
        return (
            <p className="field-error">
                {t('farm_location_search_error', {
                    defaultValue:
                        'There was an error trying to search. Please try again or select the location of your farm by clicking on the map.',
                })}
            </p>
        );
    }

    if (searchedPlaces) {
        if (searchedPlaces.size === 0) {
            return (
                <p className="search-msg">
                    {t('farm_location_search_no_results', {
                        defaultValue:
                            'No results found. Select the location of your farm by clicking on the map.',
                    })}
                </p>
            );
        }
        if (searchedPlaces.size === 1 || selectedPlaceId) {
            return (
                <p className="search-msg">
                    {t('farm_location_search_single_result', {
                        defaultValue:
                            'Click create farm if you are happy with your location or move it by clicking on the map.',
                    })}
                </p>
            );
        }
        return (
            <p className="search-msg">
                {t('farm_location_search_multiple_results', {
                    defaultValue:
                        'Select the point that represents your farm. You can set an alternative location by clicking on the map.',
                })}
            </p>
        );
    }

    return null;
};
