import { useState } from 'react';
import { useToggle } from '@fieldmargin/webapp-state';

export interface AddingUsageState {
    addingUsage: boolean;
    createdFieldUsageUuid?: string;
}

export const useAddingUsageState = (): [
    AddingUsageState,
    VoidFunction,
    (fieldUsageUuid: string) => void,
] => {
    const [addingUsage, toggleAddingUsage] = useToggle(false);
    const [createdFieldUsageUuid, setCreateFieldUsageUuid] = useState<string>();

    return [{ addingUsage, createdFieldUsageUuid }, toggleAddingUsage, setCreateFieldUsageUuid];
};
