import { clearTokens, unsafe_setAccessToken } from '@fieldmargin/webapp-auth';
import Button from '@fieldmargin/webapp-styling/components/button';
import Modal from 'modal/Modal';
import { useOnboarding } from 'onboarding/onboarding-state';
import { pipe } from 'ramda';

import LanguageChooser from './LanguageChooser';

interface AdminPanelProps {
    onClose: VoidFunction;
}

const AdminPanel = ({ onClose }: AdminPanelProps) => {
    const { startOnboarding } = useOnboarding();
    return (
        <Modal onClose={onClose}>
            <div className="bg-white p-6 flex flex-col gap-3">
                <h2 className="mb-1">Admin panel</h2>
                <LanguageChooser />
                <Button variant="outline" small onClick={pipe(onClose, startOnboarding.bind(null))}>
                    Start onboarding
                </Button>
                <Button
                    variant="danger-outline"
                    small
                    onClick={() => unsafe_setAccessToken('invalid')}
                >
                    Clear access token
                </Button>
                <Button variant="danger-outline" small onClick={clearTokens}>
                    Clear access & refresh tokens
                </Button>
            </div>
        </Modal>
    );
};

export default AdminPanel;
