import { clone, createGeoFeatureWithId, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { createAction } from '@fieldmargin/webapp-state';
import type Feature from 'features/Feature';
import type Field from 'fields/Field';
import type Herd from 'herd/Herd';
import { List, Set } from 'immutable';
import type Note from 'notes/Note';
import { getNoteAttachmentItems } from 'notes/Note';
import type FullOperation from 'operations/FullOperation';
import { getFullOperationAttachmentItems } from 'operations/FullOperation';
import type ManualSensor from 'sensors/manual/ManualSensor';
import type SensorSummary from 'sensors/SensorSummary';

import type { AttachmentItem } from './attachments';
import { AttachmentType } from './attachments';
import type { EditingData } from './farm-editing-state';
import type FarmEditingState from './farm-editing-state';
import { EditingType } from './farm-editing-state';

export const startNewNote = createAction<FarmEditingState, void>(
    'Farm editing: start new note',
    (state) => startEditingReducer(state, EditingType.NOTE, undefined)
);

export const startEditingNote = createAction<FarmEditingState, Note>(
    'Farm editing: start editing note',
    (state, note) =>
        startEditingReducer(
            state,
            EditingType.NOTE,
            { id: note.uuid },
            note.getGeoFeatureCollection(),
            getNoteAttachmentItems(note)
        )
);

export const startEditingOperation = createAction<FarmEditingState, FullOperation>(
    'Farm editing: start editing operation',
    (state, fullOperation) =>
        startEditingReducer(
            state,
            EditingType.OPERATION,
            {
                id: fullOperation.uuid,
            },
            undefined,
            getFullOperationAttachmentItems(fullOperation)
        )
);

export const startNewFeature = createAction<FarmEditingState, void>(
    'Farm editing: start new feature',
    (state) => startEditingReducer(state, EditingType.FEATURE)
);

export const startEditingFeature = createAction<FarmEditingState, Feature>(
    'Farm editing: start editing feature',
    (state, feature) =>
        startEditingReducer(
            state,
            EditingType.FEATURE,
            { id: feature.uuid },
            clone(feature.geoJson)
        )
);

export const startNewField = createAction<FarmEditingState, void>(
    'Farm editing: start new field',
    (state) => startEditingReducer(state, EditingType.FIELD)
);

export const startEditingField = createAction<FarmEditingState, Field>(
    'Farm editing: start editing field',
    (state, field) =>
        startEditingReducer(
            state,
            EditingType.FIELD,
            { id: field.uuid },
            field.geoJson ? clone(field.geoJson) : GeoFeatureCollection()
        )
);

export const startEditingSubFields = createAction<FarmEditingState, void>(
    'Farm editing: start sub fields',
    (state) => state.set('editingType', EditingType.SUB_FIELDS)
);

export const startEditingIntegrationSensor = createAction<FarmEditingState, SensorSummary>(
    'Farm editing: start editing integration sensor',
    (state, sensor) =>
        sensor.overrideLocation !== null
            ? startEditingReducer(
                  state,
                  EditingType.SENSOR,
                  { id: sensor.id },
                  GeoFeatureCollection({
                      features: List.of(createGeoFeatureWithId(sensor.overrideLocation)),
                  })
              )
            : startEditingReducer(state, EditingType.SENSOR, {
                  id: sensor.id,
              }).set('drawingTool', 'point')
);

export const startNewManualSensor = createAction<FarmEditingState, void>(
    'Farm editing: start new manual sensor',
    (state) => startEditingReducer(state, EditingType.MANUAL_SENSOR).set('drawingTool', 'point')
);

export const startEditingManualSensor = createAction<FarmEditingState, ManualSensor>(
    'Farm editing: start editing manual sensor',
    (state, manualSensor) =>
        manualSensor.id !== null
            ? startEditingReducer(
                  state,
                  EditingType.MANUAL_SENSOR,
                  { id: manualSensor.id },
                  manualSensor.getGeoJson()
              )
            : state
);

export const startMovingHerd = createAction<FarmEditingState, Herd>(
    'Farm editing: start moving herd',
    (state, herd) =>
        startEditingReducer(state, EditingType.HERD, {
            id: herd.uuid,
        }).set('selectingSingleAttachmentType', AttachmentType.FIELD)
);

const startEditingReducer = (
    state: FarmEditingState,
    type: EditingType,
    data?: EditingData,
    geoFeatureCollection?: GeoFeatureCollection,
    attachments?: Set<AttachmentItem>
) =>
    state.withMutations((s) =>
        s
            .set('editingType', type)
            .set('editingData', data || {})
            .set(
                'editingGeoFeatureCollection',
                geoFeatureCollection ?? s.editingGeoFeatureCollection ?? GeoFeatureCollection()
            )
            .set('editingAttachments', attachments ?? s.editingAttachments ?? Set())
    );

export const startEditingReducers = [
    startNewNote,
    startEditingNote,
    startEditingOperation,
    startNewFeature,
    startEditingFeature,
    startNewField,
    startEditingSubFields,
    startEditingField,
    startEditingIntegrationSensor,
    startNewManualSensor,
    startEditingManualSensor,
    startMovingHerd,
];
