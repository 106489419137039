import type { Farm } from '@fieldmargin/webapp-farms';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess } from 'farms/farm-loading-state';
import { List, Record } from 'immutable';
import { getUuid } from 'lib/fp-helpers';
import { listToMap, replaceOrAdd } from 'lib/immutil';
import { prop } from 'ramda';
import type { Action } from 'redux-actions';
import { createAction, handleActions } from 'redux-actions';
import { hectaresToSqm } from 'utils/conversion';

import type Field from './Field';
import { getFieldArea } from './Field';

export type FieldsSortBy = 'usage' | 'alphabetically';

export const setFieldSearchString = createAction('Fields: set field search string');
export const setFilterFieldsByMap = createAction('Fields: set filter fields by map');
export const setFieldsSortBy = createAction('Fields: set fields sort by');
export const setDeclutterFieldNames = createAction('Fields: set declutter field names');
export const setShowArchivedFields = createAction('Fields: set show archived fields');

export const setFields = createAction('Set fields');
export const setField = createAction('Set field');
export const removeField = createAction('Remove field');
export const updateAndRemoveFields = createAction('Update and remove fields');

export const FieldsState = Record({
    fields: null as List<Field> | null,

    fieldSearchString: '',
    filterFieldsByMap: false,
    declutterFieldNames: true,
    sortBy: 'usage' as FieldsSortBy,
    showArchivedFields: false,
});
export interface FieldsState extends ReturnType<typeof FieldsState> {}

const hasGeoJson = (field: Field) => field.geoJson !== null;
export const removeInvalidFields = (fields: List<Field>) => {
    const parentFieldMap = listToMap(fields, getUuid);
    return fields
        .filter(hasGeoJson)
        .filter((field) =>
            field.parentUuid === undefined ? true : parentFieldMap.has(field.parentUuid)
        );
};

/**
 * Sets the locked state for fields based on the farm plan limit.
 */
export const setFieldsLockedState = (farm: Farm, fields: List<Field>) => {
    if (farm.plan.fieldAreaHectareLimit === null) {
        return fields;
    }
    const sqmLimit = hectaresToSqm(farm.plan.fieldAreaHectareLimit);
    let fieldTotal = 0;
    return fields.sortBy(prop('createdDate')).reduce((fields, field) => {
        fieldTotal = fieldTotal + getFieldArea(field);
        return fields.push(field.set('locked', fieldTotal > sqmLimit));
    }, List<Field>());
};

export const fieldsReducer = handleActions<FieldsState, any>(
    {
        [loadedFarmSuccess.toString()]: (state, { payload }: Action<LoadedFarmSuccessPayload>) => {
            return state.set(
                'fields',
                setFieldsLockedState(payload.farm, removeInvalidFields(payload.fields))
            );
        },

        [setFieldSearchString.toString()]: (state, action: Action<string>) =>
            state.set('fieldSearchString', action.payload),
        [setFilterFieldsByMap.toString()]: (state, action: Action<boolean>) =>
            state.set('filterFieldsByMap', action.payload),
        [setFieldsSortBy.toString()]: (state, action: Action<FieldsSortBy>) =>
            state.set('sortBy', action.payload),
        [setDeclutterFieldNames.toString()]: (state, action: Action<boolean>) =>
            state.set('declutterFieldNames', action.payload),
        [setShowArchivedFields.toString()]: (state, action: Action<boolean>) =>
            state.set('showArchivedFields', action.payload),

        [setFields.toString()]: (state, { payload }: Action<Field[]>) =>
            payload.reduce((nextState, field) => {
                return nextState.update('fields', (fields: List<Field>) =>
                    replaceOrAdd(fields, field, (f) => f.uuid === field.uuid)
                );
            }, state),
        [setField.toString()]: (state, { payload: newField }: Action<Field>) =>
            state.update('fields', (fields: List<Field>) =>
                replaceOrAdd(fields, newField, (field) => field.uuid === newField.uuid)
            ),

        [removeField.toString()]: (state, { payload }: Action<Field>) =>
            state.fields
                ? state.update('fields', (fields: List<Field>) =>
                      // Remove the field itself and all sub fields for that field.
                      fields.filter(
                          (field) =>
                              field.uuid !== payload.uuid && field.parentUuid !== payload.uuid
                      )
                  )
                : state,

        [updateAndRemoveFields.toString()]: (
            state,
            { payload: { update, remove } }: Action<{ update: List<Field>; remove: List<string> }>
        ) =>
            state.fields
                ? state.update('fields', (fields: List<Field>) =>
                      update.reduce(
                          (nextFields, field) =>
                              replaceOrAdd(nextFields, field, (f) => f.uuid === field.uuid),
                          fields.filter((field) => !remove.contains(field.uuid))
                      )
                  )
                : state,
    },
    FieldsState()
);
