import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Basemap } from '@fieldmargin/webapp-ol-map';
import c from 'classnames';
import { baseMapLabels, getDisplayableBasemapTypes } from 'system/basemaps/basemaps';

interface BaseMapControlProps {
    onSelectBasemap: (basemap: string) => void;
    selectedBasemap: string;
    basicOnly?: boolean;
}

const BaseMapControl = ({
    onSelectBasemap,
    selectedBasemap,
    basicOnly = false,
}: BaseMapControlProps) => {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleSelectBasemap = (basemap: Basemap) => {
        onSelectBasemap(basemap);
        toggleMenu();
    };

    const items = getDisplayableBasemapTypes(basicOnly).map((basemap) => (
        <button
            key={basemap}
            onClick={() => handleSelectBasemap(basemap)}
            className="bare-btn focus ring-inset w-full h-10 flex items-center justify-center bordered-t text-xs"
        >
            {t(baseMapLabels[basemap])}
        </button>
    ));

    return (
        <div className="cursor-pointer" style={{ top: 20, left: 40 }}>
            <button
                className="bare-btn focus relative h-10 w-48 px-5 flex items-center justify-between text-sm text-white font-bold bg-fm-blue rounded"
                onClick={toggleMenu}
            >
                {t(baseMapLabels[selectedBasemap])}
                <i className={c(menuOpen ? 'ion-arrow-up-b' : 'ion-arrow-down-b')} />
            </button>
            {menuOpen && <div className="bg-white absolute z-10 w-full">{items}</div>}
        </div>
    );
};

export default BaseMapControl;
