import type { ReactNode } from 'react';
import Markdown from 'react-markdown';
import { useTranslations } from 'hooks/useTranslations';
import Modal from 'modal/Modal';
import { toUpper } from 'ramda';
import UpgradeButton from 'upgrade/UpgradeButton';
import ExternalLink from 'view/ExternalLink';

interface FeatureLockedModalProps {
    onClose: VoidFunction;
    header?: ReactNode;
    planList?: string[];
}

const FeatureLockedModal = ({
    onClose,
    header,
    planList = ['plus', 'pro'],
}: FeatureLockedModalProps) => {
    const { t, plural } = useTranslations();
    return (
        <Modal onClose={onClose}>
            <div
                className="FeatureLockedModal bg-white p-8 text-center flex flex-col"
                style={{ width: 500, height: 300 }}
            >
                <h2 className="text-xxl">{header ?? t('upgrade_to_access_feature')}</h2>
                <p className="text-xl text-fm-blue flex-grow">
                    {plural('available_with_plans', {
                        sprintf: [planListToString(planList.map(toUpper))],
                        count: planList.length,
                    })}
                    {'. '}
                    <Markdown components={{ a: ExternalLink, p: 'span' }}>
                        {t('upgrade_or_learn_more')}
                    </Markdown>
                </p>
                <UpgradeButton />
            </div>
        </Modal>
    );
};

const planListToString = (planList: string[]) =>
    planList.length === 1
        ? planList[0]
        : `${planList.slice(0, planList.length - 1).join(', ')} or ${planList.slice(
              planList.length - 1
          )}`;

export default FeatureLockedModal;
