import type { Farm } from '@fieldmargin/webapp-farms';
import type { Extent } from '@fieldmargin/webapp-geo';
import { extentIntersectsGeo } from '@fieldmargin/webapp-geo';
import type Field from 'fields/Field';
import type { Set } from 'immutable';
import { List } from 'immutable';
import type configService from 'lib/config';
import { extentIntersectsFieldBoundary } from 'lib/geo/geometry';
import { stringContains } from 'lib/util/text';
import Note from 'notes/Note';
import FullOperation from 'operations/FullOperation';
import type { OperationField } from 'operations/OperationField';
import { curry, either } from 'ramda';

export type Activity = Note | FullOperation;

export const activityIsOperation = (activity: Activity): activity is FullOperation =>
    activity instanceof FullOperation;
export const activityIsNote = (activity: Activity): activity is Note => activity instanceof Note;

const getActivityFarmUuid = (activity: Activity) =>
    activity instanceof Note ? activity.farmUuid : activity.summary.farmUuid;

export const getActivityYear = (activity: Activity) =>
    activity instanceof Note ? activity.year : activity.summary.year;

export const getActivityCreatedByUserId = (activity: Activity) =>
    activity instanceof Note ? activity.createdByUserId : activity.summary.createdByUserId;

export const getActivityReadStatus = (activity: Activity) =>
    activity instanceof Note ? activity.read : activity.summary.read;

export const getURL = (activity: Activity) => {
    return `/farms/${getActivityFarmUuid(activity)}/${
        activity instanceof Note ? 'notes' : 'operations'
    }/${activity.uuid}`;
};

export const getActivityMediaThumbUrl = (
    config: typeof configService,
    farm: Farm,
    activity: Activity,
    mediaId: string
) =>
    `${config.get('apiRoot')}/notes-api/v2/farms/${farm.uuid}/media/${
        activity instanceof Note ? 'notes' : 'operations'
    }/${activity.uuid}/${mediaId}/?maxLongSide=190&isSquare=true`;

export const isNote = (activity: Activity) => activity instanceof Note && !activity.task;

export const noteIsTodo = (note: Note) => note.task && note.completedDate === null;

/**
 * Returns true of false based on the overall status of the activity item being TODO.
 */
export const isTodo = (activity: Activity): boolean => {
    if (activity instanceof Note) {
        return noteIsTodo(activity);
    }
    return activity.summary.status === 'TODO';
};

/**
 * Returns true of false based on the overall status of the activity item being DONE or having a
 * completed date if it is a task.
 */
export const isComplete = (activity: Activity): boolean => {
    if (activity instanceof Note) {
        return activity.task && activity.completedDate !== null;
    }
    return activity.summary.status === 'DONE';
};

/**
 * For notes this returns true or false based on the status of the note.
 * For operations, it will return true if the overall status of the operation is true,
 * otherwise it will check if the given field uuid is complete.
 */
export const isCompleteOrFieldComplete = (activity: Activity, fieldUuid: string) => {
    const complete = isComplete(activity);
    if (complete) {
        return true;
    }

    if (activity instanceof FullOperation && activity.fields) {
        return !!activity.fields.find(
            (f) => f.fieldUuid === fieldUuid && f.completedDate !== undefined
        );
    }

    return false;
};

/**
 * For notes this returns true or false based on the status of the note.
 * For operations this will return true if the operation status is done or if 1all the given fields
 * are complete, false otherwise.
 */
export const isCompleteForAllFields = (activity: Activity, fieldUuids: Set<string>) => {
    const complete = isComplete(activity);
    if (complete) {
        return true;
    }
    if (activity instanceof FullOperation && activity.fields) {
        return activity.fields
            .filter((f) => fieldUuids.has(f.fieldUuid))
            .every((f) => f.completedDate !== undefined);
    }
    return false;
};

/**
 * Returns archived value for notes and always false for operations.
 */
export const isArchived = (activity: Activity): boolean => {
    return activity instanceof Note ? activity.archived : false;
};

export const getDueDate = (activity: Activity): Date | null =>
    activity instanceof Note ? activity.dueDate : activity.summary.dueDate;

export const getActivityCompletedDate = (activity: Activity): Date | null =>
    activity instanceof Note ? activity.completedDate : activity.summary.completedDate;

export const getActivityCompletedByUserId = (activity: Activity): number | null =>
    activity instanceof Note ? activity.completedByUserId : activity.summary.completedByUserId;

/**
 * For notes this returns the completed date of the note, or null if this is not set.
 * For operations this returns the completed date of the given field uuid or null if this is not set.
 */
export const getCompletedDateForField = (activity: Activity, fieldUuid: string): Date | null => {
    if (activity instanceof Note) {
        return activity.completedDate;
    }
    if (activity instanceof FullOperation && activity.fields) {
        const field = activity.fields.find((f) => f.fieldUuid === fieldUuid);
        return field && field.completedDate ? field.completedDate : null;
    }
    return null;
};

/**
 * For notes this returns the completed date of the note, or null if this is not set.
 * For operations this returns the completed date of the field that was completed last or null
 * if this is not set.
 */
export const getLastCompletedDate = (activity: Activity, fieldUuids: Set<string>) => {
    if (activity instanceof Note) {
        return activity.completedDate;
    }
    if (activity instanceof FullOperation && activity.fields) {
        const completedItems = activity.fields
            .filter((f) => fieldUuids.has(f.fieldUuid))
            .sortBy((f) => f.completedDate);
        return completedItems.size > 0
            ? completedItems.last<OperationField>().completedDate || null
            : null;
    }
    return null;
};

/**
 * Returns the userCreatedDate if this is set, otherwise default to the createdDate which comes
 * from the server.
 */
export const getCreatedDate = (activity: Activity) => {
    const { createdDate, userCreatedDate } =
        activity instanceof Note
            ? { createdDate: activity.createdDate, userCreatedDate: activity.userCreatedDate }
            : {
                  createdDate: activity.summary.createdDate,
                  userCreatedDate: activity.summary.userCreatedDate,
              };
    if (userCreatedDate) {
        return userCreatedDate;
    }
    return createdDate;
};

/**
 * For when you always want the server created date.
 */
export const getServerCreatedDate = (activity: Activity) =>
    activity instanceof Note ? activity.createdDate : activity.summary.createdDate;

/**
 * Returns the last activity date if last activity is set, otherwise returns the last
 * modified date.
 */
export const getLastActivityDate = (activity: Activity) => {
    const { lastActivity, lastModifiedDate } =
        activity instanceof Note
            ? { lastActivity: activity.lastActivity, lastModifiedDate: activity.lastModifiedDate }
            : {
                  lastActivity: activity.summary.lastActivity,
                  lastModifiedDate: activity.summary.lastModifiedDate,
              };

    if (lastActivity) {
        return lastActivity.date;
    }

    return lastModifiedDate;
};

/**
 * Returns a list of field objects that are associated to the activity or an empty list
 * if the activity has no field associations.
 */
export const getActivityFields = (activity: Activity, allFields: List<Field>) => {
    let fieldUuids: string[] | undefined = undefined;
    if (activity instanceof Note) {
        fieldUuids = activity.fieldUuids;
    }
    if (activity instanceof FullOperation && activity.fields) {
        fieldUuids = activity.fields.map((opField) => opField.fieldUuid).toArray();
    }
    if (fieldUuids) {
        return allFields.filter((field) => fieldUuids && fieldUuids.includes(field.uuid));
    }
    return List<Field>();
};

/**
 * Returns true if the note has an association with the given field, false otherwise
 */
export const noteHasField = (note: Note, fieldUuid: string): boolean => {
    return note.fieldUuids.includes(fieldUuid);
};

/**
 * Returns true if the note has an association with any of the given fields, false otherwise.
 */
export const noteHasAnyField = (note: Note, fieldUuids: Set<string>) => {
    return note.fieldUuids.some((fieldUuid) => fieldUuids.has(fieldUuid));
};

/**
 * Returns true if the activity has any of the given fields associated with it, false otherwise.
 */
export const hasAnyField = (activity: Activity, fieldUuids: Set<string>) => {
    if (activity instanceof Note) {
        return noteHasAnyField(activity, fieldUuids);
    }

    if (activity instanceof FullOperation) {
        return (
            activity.fields !== null &&
            activity.fields.filter((field) => fieldUuids.has(field.fieldUuid)).size > 0
        );
    }
};

/**
 * Returns true if the activity has an association with the given field, false otherwise
 */
export const hasField = (activity: Activity, fieldUuid: string) => {
    if (activity instanceof Note) {
        return noteHasField(activity, fieldUuid);
    }
    if (activity instanceof FullOperation) {
        return activity.fields && !!activity.fields.find((f) => f.fieldUuid === fieldUuid);
    }
    return false;
};

/**
 * For notes this returns true if the note has an association with any of the fields in the list,
 * false otherwise.
 * For operations this returns true if the operation has an association with any of the fields in
 * the list and any of them are incomplete.
 */
export const hasFieldOutstanding = (activity: Activity, fieldUuids: Set<string>) => {
    if (activity instanceof Note) {
        // Notes don't have fields as sub tasks so just check if the note has any of the fields
        return noteHasAnyField(activity, fieldUuids);
    }

    if (activity instanceof FullOperation && activity.fields) {
        const fields = activity.fields.filter((f) => fieldUuids.has(f.fieldUuid));
        const completedFields = fields.filter((field) => field.completedDate !== undefined);
        return fields.size > 0 && completedFields.size !== fields.size;
    }
    return false;
};

export const getActivityArchived = (activity: Activity) =>
    activity instanceof Note ? activity.archived : activity.summary.archived;
export const getActivityName = (activity: Activity) =>
    activity instanceof Note ? activity.name : activity.summary.name;
export const getActivityTaggedUserIds = (activity: Activity) =>
    activity instanceof Note ? activity.taggedUserIds : activity.summary.taggedUserIds;

export const groupActivitiesByStatus = (activities: List<Activity>) =>
    activities
        .groupBy((activity) => (getActivityArchived(activity) === true ? 'archived' : 'active'))
        .map((group) => group.toList())
        .toMap();

export const filterActivitiesBySearchString = (searchString: string, activities: List<Activity>) =>
    activities.filter((activity) => stringContains(getActivityName(activity), searchString));

// const hasTaggedUsers = (taggedUsers: Set<number>) => (activity: Activity) =>
//     [...getActivityTaggedUserIds(activity)].filter((userId) => taggedUsers.contains(userId))
//         .length > 0;

// export const filterActivitiesByTaggedUsers = (
//     taggedUsers: Set<number>,
//     activities: List<Activity>
// ) => (taggedUsers.size > 0 ? activities.filter(hasTaggedUsers(taggedUsers)) : activities);

const extentIntersectsActivityShapes = (extent: Extent) => (activity: Activity) =>
    activity instanceof Note && extentIntersectsGeo(extent, activity.getGeoFeatureCollection());

const extentIntersectsActivityFields =
    (extent: Extent, fields: List<Field>) => (activity: Activity) => {
        // Need to type guard this because ramda and immutable don't play well together.
        const intersectsField: (f: Field) => boolean = curry(extentIntersectsFieldBoundary)(extent);
        return getActivityFields(activity, fields).find(intersectsField) !== undefined;
    };

export const filterActivitiesByExtent = (
    extent: Extent,
    fields: List<Field>,
    activities: List<Activity>
) => {
    const intersectsNoteShapes = extentIntersectsActivityShapes(extent);
    const intersectsActivityFields = extentIntersectsActivityFields(extent, fields);
    const inView = either(intersectsNoteShapes, intersectsActivityFields);
    return activities.filter(inView);
};
