import { useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import type Herd from 'herd/Herd';
import withPageHerd from 'herd/withPageHerd';
import type { AppState } from 'system/store';
import ErrorMessage from 'view/ErrorMessage';

import type { HerdMergeSchema } from './useHerdMergeContext';

interface HerdMergeProps {
    farm: Farm;
    herd?: Herd;
}

const HerdMerge = ({ farm, herd }: HerdMergeProps) => {
    const [selectedHerds, setSelectedHerds] = useState<string[]>([]);

    if (herd === undefined) {
        return <Navigate to={`/farms/${farm.uuid}/livestock`} />;
    }

    return (
        <Outlet
            context={
                {
                    farm,
                    herd,
                    selectedHerds,
                    setSelectedHerds,
                } satisfies HerdMergeSchema
            }
        />
    );
};

const HerdMergeWithErrorBoundary = withErrorBoundary(HerdMerge, {
    fallback: <ErrorMessage className="p-5">Something went wrong when merging herds</ErrorMessage>,
});

const HerdMergeWithPageHerd = withPageHerd(HerdMergeWithErrorBoundary);

export default connect((state: AppState, props) => ({
    ...props,
    farm: selectCurrentFarm(state),
}))(HerdMergeWithPageHerd);
