import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import type { Farm } from '@fieldmargin/webapp-farms';
import LoadableContent from '@fieldmargin/webapp-styling/components/loadable-content';
import type { List, Set } from 'immutable';
import type Input from 'inputs/Input';
import { inputIsPesticide } from 'inputs/Input';
import { InputNutrientsList } from 'inputs/InputNutrients';
import { getInputTypeI18nKey, InputType } from 'inputs/InputType';
import { pagePesitcideApi, searchPesticideApi } from 'inputs/pesticides-api';
import { usePrepopulatedFertilisers } from 'inputs/preopulatedFertilisers';
import { useDebouncedSearch } from 'lib/hooks';
import { searchList, setEmptyOrIncludes } from 'lib/immutil';
import { useScrollingPagingState } from 'lib/paging';
import { prop } from 'ramda';
import EmptyState from 'sidebar/modules/EmptyState';
import type { CodedItem, SingleParamVoidFunction } from 'system/types';
import ShortUnit from 'view/units/ShortUnit';

import InputListButton from './InputListButton';
import inputsEmpty from './inputs-empty.png';
import PesticidesList from './PesticidesList';

interface InputListsProps {
    farm: Farm;
    hasAnyInputs: boolean;
    inputs: List<Input>;
    lockedInputs: List<string>;
    onInputSelect: SingleParamVoidFunction<Input>;
    onPesticideSelect: SingleParamVoidFunction<CodedItem>;
    onFertilizerSelect: SingleParamVoidFunction<Input>;
    searchTerm?: string;
    typeFilter: Set<string>;
}

const InputLists = ({
    farm,
    hasAnyInputs,
    inputs,
    lockedInputs,
    onInputSelect,
    onPesticideSelect,
    onFertilizerSelect,
    searchTerm,
    typeFilter,
}: InputListsProps) => {
    const [pagingPending, pagingError, pagingPesticides, onScroll] = useScrollingPagingState(
        farm.countryCapabilities.pesticideDB
            ? (page) => pagePesitcideApi(farm.uuid, farm.country, page)
            : () => Promise.resolve<CodedItem[]>([])
    );

    return (
        <div
            className="scrolling h-0"
            onScroll={farm.countryCapabilities.pesticideDB ? onScroll : () => {}}
        >
            <ManualInputList
                hasAnyInputs={hasAnyInputs}
                inputs={inputs}
                searchTerm={searchTerm}
                typeFilter={typeFilter}
                lockedInputs={lockedInputs}
                onInputSelect={onInputSelect}
            />
            {setEmptyOrIncludes(typeFilter, InputType.FERTILIZER) && (
                <PrepopulatedFertiliserList onSelect={onFertilizerSelect} searchTerm={searchTerm} />
            )}
            {farm.countryCapabilities.pesticideDB &&
                setEmptyOrIncludes(typeFilter, InputType.SPRAY) && (
                    <PesticideInputList
                        farm={farm}
                        searchTerm={searchTerm}
                        onPesticideSelect={onPesticideSelect}
                        pagingPesticides={pagingPesticides}
                        pagingPending={pagingPending}
                        pagingError={pagingError}
                    />
                )}
        </div>
    );
};

export default InputLists;

interface ManualInputListProps {
    hasAnyInputs: boolean;
    inputs: List<Input>;
    searchTerm?: string;
    typeFilter: Set<string>;
    lockedInputs: List<string>;
    onInputSelect: SingleParamVoidFunction<Input>;
}
const ManualInputList = ({
    hasAnyInputs,
    inputs,
    searchTerm = '',
    typeFilter,
    lockedInputs,
    onInputSelect,
}: ManualInputListProps) => {
    const { t } = useTranslation();
    return (
        <div className="mb-6 bordered-b">
            <h3 className="px-5">{t('inputs_your_farm')}</h3>
            {!hasAnyInputs && (
                <EmptyState header={t('inputs_empty').replace('\\n', ' ')} image={inputsEmpty}>
                    <Markdown>{t('inputs_empty_desc')}</Markdown>
                </EmptyState>
            )}
            {hasAnyInputs && inputs.size === 0 && (
                <p className="px-5 pb-3">{t('input_filter_none')}</p>
            )}
            {inputs.size > 0 &&
                searchList(inputs, prop('name'), searchTerm)
                    .filter((i) => setEmptyOrIncludes(typeFilter, i.type))
                    .map((input) => (
                        <InputListButton
                            key={input.uuid}
                            name={
                                <>
                                    {input.name} <InputNutrientsList input={input} />
                                </>
                            }
                            onClick={() => onInputSelect(input)}
                            hideShield={!inputIsPesticide(input)}
                            locked={lockedInputs.contains(input.uuid)}
                        >
                            <span>
                                {t(getInputTypeI18nKey(input.type))}
                                / <ShortUnit unit={input.unit} />{' '}
                            </span>
                        </InputListButton>
                    ))}
        </div>
    );
};

const PrepopulatedFertiliserList = ({
    onSelect,
    searchTerm,
}: {
    onSelect: SingleParamVoidFunction<Input>;
    searchTerm?: string;
}) => {
    const { t } = useTranslation();
    const [pending, error, fertilizers] = usePrepopulatedFertilisers(searchTerm);
    return (
        <>
            {!error && (
                <div className="mb-6 bordered-b">
                    <h3 className="px-5">{t('filter_inputs_fertilizers')}</h3>
                    <LoadableContent
                        loading={pending}
                        delay={300}
                        fallback={
                            <p>
                                <em>{t('loading')}</em>
                            </p>
                        }
                    >
                        {fertilizers.map((fertiliser) => (
                            <InputListButton
                                key={fertiliser.name}
                                name={
                                    <>
                                        {fertiliser.name} <InputNutrientsList input={fertiliser} />
                                    </>
                                }
                                onClick={() => onSelect(fertiliser)}
                                hideShield
                            >
                                {t('input_type_fertilizer')}
                            </InputListButton>
                        ))}
                    </LoadableContent>
                </div>
            )}
        </>
    );
};

interface PesticideInputListProps {
    farm: Farm;
    searchTerm?: string;
    onPesticideSelect: SingleParamVoidFunction<CodedItem>;
    pagingPesticides?: CodedItem[];
    pagingPending: boolean;
    pagingError: boolean;
}

const PesticideInputList = ({
    farm,
    searchTerm,
    onPesticideSelect,
    pagingError,
    pagingPending,
    pagingPesticides,
}: PesticideInputListProps) => {
    const [_, pesticides, pending, error] = useDebouncedSearch<CodedItem[]>(
        (search) => searchPesticideApi(farm.uuid, farm.country, search),
        searchTerm
    );

    return searchTerm !== '' ? (
        <PesticidesList
            pesticides={pesticides}
            pending={pending}
            error={error}
            onSelect={onPesticideSelect}
        />
    ) : (
        <PesticidesList
            pesticides={pagingPesticides}
            pending={pagingPending}
            error={pagingError}
            onSelect={onPesticideSelect}
        />
    );
};
