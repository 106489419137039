import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { createLogger } from '@fieldmargin/webapp-reporting';
import type { AxiosProgressEvent } from 'axios';
import Promise from 'bluebird';
import { List, Set } from 'immutable';
import { listToMap } from 'lib/immutil';
import type { CommentDTO } from 'lib/model/Comment';
import type Comment from 'lib/model/Comment';
import { deserializeComment, serializeComment } from 'lib/model/Comment';
import { handlePagingRequest } from 'lib/paging';
import type { LegacyMediaDTO } from 'media/Media';
import { deserializeLegacyMedia, LEGACY_MEDIA_TYPES } from 'media/Media';

import type { FullOperationDTO, OperationChangeDTO } from './FullOperation';
import type FullOperation from './FullOperation';
import { deserializeFullOperation, deserializeOperationToFullOperation } from './FullOperation';
import type { ReadOperationDTO } from './Operation';
import type Operation from './Operation';
import type { RecordingDTO } from './Recording';
import type Recording from './Recording';
import { deserializeRecording, serializeRecording } from './Recording';

const logger = createLogger('operations.operations-api');

export const fetchFullOperation = (farmUuid: string, fullOperation: FullOperation) => {
    return Promise.props({
        media: getOperationMediaApi(farmUuid, fullOperation.uuid),
        comments: getOperationCommentsApi(farmUuid, fullOperation.uuid),
        recordings: getOperationRecordingsApi(
            farmUuid,
            fullOperation.summary.year,
            fullOperation.uuid
        ),
    }).then(({ comments, media, recordings }) =>
        fullOperation.set('comments', comments).set('media', media).set('recordings', recordings)
    );
};

export const getOperationApi = (farmUuid: string, operationUuid: string) =>
    authorizedRequest<ReadOperationDTO>({
        url: `/notes-api/v2/farms/${farmUuid}/operations/${operationUuid}/`,
    }).then(deserializeOperationToFullOperation);

export const getFullOperationsApi = (farmUuid: string) =>
    handlePagingRequest<ReadOperationDTO>(`/notes-api/v2/farms/${farmUuid}/operations`)
        .then((items) =>
            listToMap(List(items).map(deserializeOperationToFullOperation), (o) => o.uuid)
        )
        .catch((e) => {
            logger.handleRequestError('Unable to fetch operations')(e);
            return Promise.reject(e);
        });

export const saveOperationApi = (
    farmUuid: string,
    year: number,
    operationChanges: OperationChangeDTO
) =>
    authorizedRequest<FullOperationDTO>({
        method: 'post',
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/save/`,
        data: operationChanges,
    }).then(deserializeFullOperation);

export const deleteOperationApi = (operation: Operation) =>
    authorizedRequest({
        method: 'delete',
        url: `/notes-api/v2/farms/${operation.farmUuid}/years/${operation.year}/operations/${operation.uuid}/`,
    }).then(() => operation);

export const markOperationReadApi = (operation: Operation) =>
    authorizedRequest({
        method: 'post',
        url: `/notes-api/v2/farms/${operation.farmUuid}/years/${operation.year}/operations/${operation.uuid}/markOperationRead/`,
    }).then(() => operation);

export const saveOperationFieldApi = (operation: Operation, fieldUuid: string, areaSqm: number) =>
    authorizedRequest({
        method: 'post',
        url: `/notes-api/v2/farms/${operation.farmUuid}/years/${operation.year}/operations/${operation.uuid}/fields/${fieldUuid}/`,
        params: {
            areaSQM: areaSqm,
        },
    }).then(() => ({ fieldUuid, areaSqm }));

export const deleteOperationFieldApi = (operation: Operation, fieldUuid: string) =>
    authorizedRequest({
        method: 'delete',
        url: `/notes-api/v2/farms/${operation.farmUuid}/years/${operation.year}/operations/${operation.uuid}/fields/${fieldUuid}/`,
    }).then(() => fieldUuid);

export const getOperationCommentsApi = (farmUuid: string, operationUuid: string) =>
    authorizedRequest<CommentDTO[]>({
        url: `/notes-api/v2/farms/${farmUuid}/discussions/operations/${operationUuid}/`,
    }).then((comments) => List(comments.map((comment) => deserializeComment(comment))));

export const saveOperationCommentApi = (operation: Operation, comment: Comment) =>
    authorizedRequest<CommentDTO>({
        method: 'post',
        url: `/notes-api/v2/farms/${operation.farmUuid}/discussions/operations/${operation.uuid}/`,
        data: serializeComment(comment),
    }).then(deserializeComment);

export const getOperationMediaApi = (farmUuid: string, operationUuid: string) =>
    authorizedRequest<LegacyMediaDTO[]>({
        url: `/notes-api/v2/farms/${farmUuid}/media/operations/${operationUuid}/`,
        params: { mediaTypes: LEGACY_MEDIA_TYPES },
    }).then((media) => List(media.map(deserializeLegacyMedia)));

export const uploadOperationMediaApi = (
    operation: Operation,
    file: any,
    onProgressCb: (progressEvent: AxiosProgressEvent) => void
) => {
    const data = new FormData();
    data.append('file', file);

    return authorizedRequest<LegacyMediaDTO>({
        method: 'post',
        url: `/notes-api/v2/farms/${operation.farmUuid}/media/operations/${operation.uuid}/`,
        data,
        onUploadProgress: onProgressCb,
        headers: { 'Content-Type': 'multipart/form-data' },
    }).then(deserializeLegacyMedia);
};

export const deleteOperationMediaApi = (operation: Operation, mediaId: string) =>
    authorizedRequest({
        method: 'delete',
        url: `/notes-api/v2/farms/${operation.farmUuid}/media/operations/${operation.uuid}/${mediaId}/`,
    }).then(() => mediaId);

export const getOperationRecordingsApi = (farmUuid: string, year: number, operationUuid: string) =>
    authorizedRequest<RecordingDTO[]>({
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/${operationUuid}/recordings/`,
    }).then((recordings) => Set(recordings.map(deserializeRecording)));

export const saveOperationRecordingApi = (operation: Operation, recording: Recording) =>
    authorizedRequest<RecordingDTO>({
        method: 'post',
        url: `/notes-api/v2/farms/${operation.farmUuid}/years/${operation.year}/operations/${operation.uuid}/recordings/`,
        data: serializeRecording(recording),
    }).then(deserializeRecording);

export const deleteOperationRecordingApi = (operation: Operation, recordingUuid: string) =>
    authorizedRequest({
        method: 'delete',
        url: `/notes-api/v2/farms/${operation.farmUuid}/years/${operation.year}/operations/${operation.uuid}/recordings/${recordingUuid}`,
    }).then(() => recordingUuid);
