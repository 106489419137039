import type { PropsWithChildren } from 'react';
import { Fragment, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';
import { useHover } from 'hooks/useHover';
import { usePageParams } from 'hooks/usePageParamas';

import type { TabBarIcons } from './tab-icons';
import tabIcons from './tab-icons';

import './Tab.css';

interface TabProps extends PropsWithChildren {
    path: string;
    icon: keyof TabBarIcons;
    isOnboardingAction?: boolean;
}

const Tab = ({ path, icon, isOnboardingAction = false, children }: TabProps) => {
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const { farmUuid, operationUuid } = usePageParams();
    const isOperation = path.includes('notes') && operationUuid;

    return (
        <NavLink
            to={`/farms/${farmUuid}${path}`}
            className={({ isActive }) =>
                clsx('Tab', { active: isActive || isOperation, forefront: isOnboardingAction })
            }
        >
            {({ isActive }) => (
                <Fragment>
                    {tabIcons(icon, isActive || isOperation ? 'white' : 'dark-blue')}
                    {isHover && <div className="tooltip">{children}</div>}
                    <span>{children}</span>
                </Fragment>
            )}
        </NavLink>
    );
};

export default Tab;
