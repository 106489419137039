import { useOutletContext } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { InputReportSummary } from 'inputs/InputReportSummary';
import type { MeasurementUnit } from 'lib/MeasurementUnit';

export interface InputSummarySchema {
    farm: Farm;
    year: number;
    pending: boolean;
    error: boolean;
    reportData: InputReportSummary[] | undefined;
    filter: string;
    onFilterChange: (filter: string) => void;
    areaMeasurementUnit: MeasurementUnit;
    onSaveInputCost: () => void;
}

const useInputSummaryContext = () => {
    return useOutletContext<InputSummarySchema>();
};

export { useInputSummaryContext };
