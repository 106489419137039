import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import { useDateFormat } from 'hooks/useDateFormat';
import type { AppState } from 'system/store';

import './DemoMessage.scss';

interface DemoMessageProps {
    farm: Farm;
}

const DemoMessage = ({ farm }: DemoMessageProps) => {
    const { formatDistanceToNow } = useDateFormat();
    return (
        <div className="DemoMessage separator-bottom">
            <p>
                This is a <strong>DEMO FARM</strong> so you can see how fieldmargin can be used to
                set up a farm. You can use it to try out the apps features.{' '}
                {farm.demoExpiryDate && (
                    <>
                        It will automatically be deleted in{' '}
                        {formatDistanceToNow(farm.demoExpiryDate)}.
                    </>
                )}
            </p>
        </div>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
}))(DemoMessage);
