import type { MouseEvent } from 'react';
import type MapGroup from 'maps/farm/MapGroup';
import { equals } from 'ramda';
import VisibilityButton from 'sidebar/visibility-toggle/VisibilityButton';
import { VisibilityOptions } from 'system/types';

interface MapViewOptionsProps {
    mapGroup: MapGroup;
    mapGroupVisibility: VisibilityOptions;
    setMapGroupVisibility: (data: { mapGroup: MapGroup; value: VisibilityOptions }) => void;
}

const isOn = equals(VisibilityOptions.ON);
const isOff = equals(VisibilityOptions.OFF);

const MapViewOptions = ({
    mapGroup,
    mapGroupVisibility,
    setMapGroupVisibility,
}: MapViewOptionsProps) => {
    const handleVisibilityClick = (e: MouseEvent) => {
        e.preventDefault();
        setMapGroupVisibility({
            mapGroup,
            value: isOff(mapGroupVisibility) ? VisibilityOptions.ON : VisibilityOptions.OFF,
        });
    };

    const handleOpacityClick = (e: MouseEvent) => {
        e.preventDefault();
        setMapGroupVisibility({
            mapGroup,
            value: isOn(mapGroupVisibility) ? VisibilityOptions.HALF : VisibilityOptions.ON,
        });
    };

    return (
        <div className="ml-8 mr-4 flex items-center h-8">
            {mapGroupVisibility !== VisibilityOptions.OFF && (
                <button
                    className="bare-btn text-fm-sky text-xxs font-bold h-6 p-1 pt-1.5 mr-3 focus"
                    onClick={handleOpacityClick}
                >
                    {mapGroupVisibility === 'half' ? '50%' : '100%'}
                </button>
            )}
            <VisibilityButton
                visible={!isOff(mapGroupVisibility)}
                onClick={handleVisibilityClick}
            />
        </div>
    );
};

export default MapViewOptions;
