import OutsidePage from 'auth/OutsidePage';

const UserDeleteSuccess = () => {
    return (
        <OutsidePage className="text-center">
            <h1 className="text-xl">Delete your account</h1>
            <p>Your account was deleted successfully.</p>
        </OutsidePage>
    );
};

export default UserDeleteSuccess;
