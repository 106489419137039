import type { Activity } from 'activity/activity-helper';
import type { List } from 'immutable';
import Note from 'notes/Note';
import FullOperation from 'operations/FullOperation';

export const filterActivitiesByJobType = (
    jobsType: string[],
    activities: List<Activity>
): List<Activity> => {
    return jobsType.length > 0
        ? activities.filter(
              (activity) =>
                  (activity instanceof Note || jobsType.includes(activity.summary.type)) &&
                  activity instanceof FullOperation
          )
        : activities;
};
