import { connect } from 'react-redux';
import { DiscussionItemType } from 'discussions/Discussion';
import { createDiscussion } from 'discussions/discussions-api';
import { addItemDiscussion } from 'discussions/discussions-state';
import { throttle } from 'lodash';
import { uploadMediaItem } from 'media/media-api';
import { addItemMediaItem } from 'media/media-state';
import type Note from 'notes/Note';
import { trackNoteLastActivity } from 'notes/Note';
import { setNote } from 'notes/note-actions';
import { bindActionCreators } from 'redux';
import ActivityLogCommentBox from 'sidebar/modules/activity-log/ActivityLogCommentBox';
import type { AppState } from 'system/store';
import { selectUserId } from 'users/user-state';

interface NoteActivityLogCommentBoxProps {
    note: Note;
    addItemDiscussion: typeof addItemDiscussion;
    addItemMediaItem: typeof addItemMediaItem;
    setNote: typeof setNote;
    userId: number;
}

const NoteActivityLogCommentBox = ({
    note,
    addItemDiscussion,
    addItemMediaItem,
    setNote,
    userId,
}: NoteActivityLogCommentBoxProps) => {
    const handleAddComment = (comment: string) =>
        createDiscussion(note.farmUuid, note.uuid, DiscussionItemType.NOTE, comment)
            .then((id) => ({
                id,
                createdDate: new Date(),
                createdByUserId: userId,
                comment,
            }))
            .then((discussion) => {
                addItemDiscussion({ uuid: note.uuid, discussion });
                setNote(trackNoteLastActivity(note, 'comment', userId, comment));
            });

    const handleFileUpload = (file: any, progressCb: (progress: number) => void) =>
        uploadMediaItem(
            note.farmUuid,
            note.uuid,
            'NOTE',
            userId,
            file,
            throttle((progressEvent) => {
                progressCb(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }, 500)
        ).then((media) => {
            addItemMediaItem({ uuid: note.uuid, media });
            setNote(
                trackNoteLastActivity(note, media.mediaType === 'image' ? 'image' : 'file', userId)
            );
            return media.fileName;
        });

    return <ActivityLogCommentBox addComment={handleAddComment} uploadFile={handleFileUpload} />;
};

export default connect(
    (state: AppState) => ({ userId: selectUserId(state) }),
    (dispatch) => bindActionCreators({ addItemDiscussion, addItemMediaItem, setNote }, dispatch)
)(NoteActivityLogCommentBox);
