import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import EditInputCostsButton from 'reporting/EditInputCostsButton';
import InputReportingEmpty from 'reporting/InputReportingEmpty';
import { getGetYearInputCostsReport } from 'reporting/reporting-api';
import ReportStatusFilter from 'reporting/ReportStatusFilter';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import ContactSupportLink from 'view/ContactSupportLink';
import ErrorMessage from 'view/ErrorMessage';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';

import type { InputCosts } from './InputCost';
import type { InputCostsContextSchema } from './useInputCostsContext';

interface InputCostsReportProps {
    farm: Farm;
    year: number;
    measurementUnit: MeasurementUnit;
}

const InputCostsReport = ({ farm, year, measurementUnit }: InputCostsReportProps) => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const isTable = state?.isTable;
    const [filter, setFilter] = useState('all');
    const [inputCosts, setInputCosts] = useState<InputCosts>();
    const { pending, error, setPromise } = usePromise<InputCosts>(setInputCosts);

    useEffect(() => {
        setPromise(
            getGetYearInputCostsReport(
                farm.uuid,
                year,
                measurementUnit,
                filter === 'all' ? undefined : filter
            )
        );
    }, [filter]);

    const handleFilterChange = (nextFilter: string) => {
        setFilter(nextFilter);
    };

    if (farm.plan.reportingLevel !== 'ALL_REPORTING') {
        return null;
    }

    const reportHasNoData = !pending && inputCosts !== undefined && inputCosts.fields.size === 0;

    return (
        <div className="relative">
            <Button
                as={Link}
                to={`/farms/${farm.uuid}/reporting`}
                className="absolute top-0 left-0 flex items-center gap-2"
                variant="subtle"
            >
                {icon('back')} {t('back')}
            </Button>
            <h1 className="uppercase text-2xl text-center">{t('input_cost_report_title')}</h1>
            <div className="flex items-center mb-4">
                <EditInputCostsButton farm={farm} />
                <ReportStatusFilter
                    filter={filter}
                    setFilter={handleFilterChange}
                    disabled={false}
                    className="ml-auto"
                />
                <Link
                    to={`/farms/${farm.uuid}/reporting/input-costs`}
                    state={{ isTable: false }}
                    className="block"
                >
                    {icon('graph', !isTable ? 'blue' : 'dark-blue')}
                </Link>

                <Link
                    to={`/farms/${farm.uuid}/reporting/input-costs/table`}
                    state={{ isTable: true }}
                    className="block ml-3"
                >
                    {icon('table', isTable ? 'blue' : 'dark-blue')}
                </Link>
            </div>
            {error && (
                <ErrorMessage>
                    <MarkdownNoPara components={{ a: ContactSupportLink }}>
                        {t('something_went_wrong_or_contact')}
                    </MarkdownNoPara>
                </ErrorMessage>
            )}
            {reportHasNoData && filter !== 'all' && (
                <p>
                    {t(
                        filter === 'todo'
                            ? 'input_summary_empty_incomplete'
                            : 'input_summary_empty_complete'
                    )}
                </p>
            )}
            {reportHasNoData && filter === 'all' && <InputReportingEmpty farm={farm} />}
            {pending && (
                <p>
                    <em>{t('reporting_loading')}</em>
                </p>
            )}
            {!reportHasNoData && !pending && inputCosts !== undefined && (
                <Outlet
                    context={
                        {
                            inputCosts,
                            measurementUnit,
                            farm,
                        } satisfies InputCostsContextSchema
                    }
                />
            )}
        </div>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    year: state.yearsState.currentYear,
    measurementUnit: selectUserAreaMeasurementUnit(state),
}))(InputCostsReport);
