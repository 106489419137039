import { Record } from 'immutable';

const Metric = Record({
    id: '',
    name: '',
    type: '',
    unit: null as string | null,
});
interface Metric extends ReturnType<typeof Metric> {}

export interface MetricDTO {
    id: string | null;
    name: string;
    type: string;
    unit: string | null;
    value?: string | null; // values from the server are always a string, if they are set
}

export const deserializeMetric = (json: MetricDTO) => {
    return Metric({
        id: json.id ? json.id : '',
        name: json.name,
        type: json.type,
        unit: json.unit,
    });
};

export const serializeMetric = (metric: Metric): MetricDTO => {
    return {
        id: metric.id ? metric.id : null,
        name: metric.name,
        type: metric.type,
        unit: metric.unit,
    };
};

const numericTypes = ['temperature', 'percentage', 'number'];
export const isNumericMetric = (metric: Metric) => numericTypes.indexOf(metric.type) > -1;

export const getMetricI18nKey = (type: string) =>
    type === 'temperature'
        ? 'temperature'
        : type === 'string'
          ? 'create_metric_type_text'
          : `create_metric_type_${type}`;

export default Metric;
