import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { useMarkHintSeenOnMount } from 'lib/firebase/firebase-hints-api';
import Modal from 'modal/Modal';

import boundaryImage from './boundary.png';
import detectButtonImage from './detect-button.png';
import drawAreaImage from './draw-area.png';
import nameFieldsImage from './name-fields.png';
import selectFieldsImage from './select-fields.png';

import './FieldBoundaryHelpModal.css';

interface FieldBoundaryHelpModalProps {
    onClose: VoidFunction;
}

const FieldBoundaryHelpModal = ({ onClose }: FieldBoundaryHelpModalProps) => {
    const { t } = useTranslation();
    useMarkHintSeenOnMount('field-creation');
    return (
        <Modal onClose={onClose}>
            <div className="FieldBoundaryHelpModal">
                <h2 className="uppercase mb-2">{t('auto_boundary_help_title')}</h2>
                <p className="text-lg">{t('auto_boundary_help_subtitle')}</p>
                <div className="flex gap-3">
                    <div className="FieldBoundaryHelpModalStep">
                        <img src={detectButtonImage} alt="Screenshot of detect boundary button" />
                        <Markdown>{t('auto_boundary_help_step1')}</Markdown>
                    </div>
                    <div className="FieldBoundaryHelpModalStep">
                        <img
                            src={drawAreaImage}
                            alt="Screenshot of an rectangular area drawn on a map"
                        />
                        <Markdown>{t('auto_boundary_help_step2')}</Markdown>
                    </div>
                    <div className="FieldBoundaryHelpModalStep">
                        <img src={selectFieldsImage} alt="Screenshot of selected fields" />
                        <Markdown>{t('auto_boundary_help_step3')}</Markdown>
                    </div>
                    <div className="FieldBoundaryHelpModalStep">
                        <img src={boundaryImage} alt="Screenshot of an editable boundary" />
                        <Markdown>{t('auto_boundary_help_step4')}</Markdown>
                    </div>
                    <div className="FieldBoundaryHelpModalStep">
                        <img src={nameFieldsImage} alt="placeholder" />
                        <Markdown>{t('auto_boundary_help_step5')}</Markdown>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FieldBoundaryHelpModal;
