/**
 * Common validation methods used across multiple forms.
 */

import i18next from 'i18next';
import { isNumeric, isNumericPositive } from 'lib/util/number';

export const required = (message: string) => (value: any) => (!value ? message : undefined);

export const booleanRequired = (message: string) => (value: any) =>
    value === undefined ? message : undefined;

export const requiredNumber = (message: string) => (value: any) =>
    value && isNumeric(value) ? undefined : message;

export const requiredPositiveNumber = (message: string) => (value: any) =>
    isNumericPositive(value) ? undefined : message;

export const validatePositiveNumber = (message?: string) => (value?: any) => {
    const returnMessage = message ?? true;
    return !value || isNumericPositive(value) ? undefined : returnMessage;
};
export const validatePositiveNumberNoMessage = (value: any) => !value || isNumericPositive(value);

export const validateNumber = (message?: string) => (value: any) =>
    value === undefined ? undefined : isNumeric(value) ? undefined : message;

export const validateEmail = (value: any) => {
    if (!value) {
        return undefined;
    }
    const atRegex = /^[^\s@]+@[^\s@]+/;
    if (!atRegex.test(value)) {
        return i18next.t('invalid_email_address_error_message');
    }
    const fullEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!fullEmailRegex.test(value)) {
        return i18next.t('invalid_email_address_error_message');
    }
    return undefined;
};

export const validateRequiredEmail = (message: string) => (value: any) => {
    if (!value) {
        return message;
    }
    return validateEmail(value);
};

export const validatePasswordBasic = (value: string) => {
    if (!value) {
        return 'Required';
    }
    return value.length < 7 ? 'Must be at least 7 characters' : undefined;
};
