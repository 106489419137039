import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { useApplicationLayoutContext } from '@notes-web/layout';
import type { ChatNotification } from 'chat/chat-state';
import FarmChatHistory from 'chat/farm-chat/FarmChatHistory';
import FarmChatNotification from 'chat/farm-chat/FarmChatNotification';
import FarmChatPanel from 'chat/farm-chat/FarmChatPanel';
import FarmMapLoader from 'farm-map/FarmMapLoader';
import { usePageParams } from 'hooks/usePageParamas';
import featureFlags from 'lib/feature-flags';
import PrintContainer from 'print/PrintContainer';
import Sidebar from 'sidebar/Sidebar';
import type { AppState } from 'system/store';
import { getActiveSectionFromPath } from 'system/url-util';

import FarmMapContainer from '../components/farm/FarmMapContainer';

import './FarmOverview.css';
import styles from './FarmOverview.module.css';

interface FarmOverviewProps {
    farmChatOpen: boolean;
    historyOpen: boolean;

    chatNotification?: ChatNotification;
    editingGeoFeatureCollection: GeoFeatureCollection;
}

const FarmOverview = ({
    farmChatOpen,
    historyOpen,
    chatNotification,
    editingGeoFeatureCollection,
}: FarmOverviewProps) => {
    const location = useLocation();
    const { Navigation } = useApplicationLayoutContext();
    const { herdUuid, noteUuid, operationUuid, featureUuid, fieldUuid, sensorId } = usePageParams();

    const activeSection = getActiveSectionFromPath(location.pathname);

    return (
        <div className="FarmOverview">
            <PrintContainer />

            <div className={styles.farmOverviewContainer}>
                {Navigation && <Navigation />}

                <Sidebar />

                {featureFlags.get('newFarmMap') ? (
                    <FarmMapLoader />
                ) : (
                    <FarmMapContainer
                        activeSection={activeSection}
                        selectedNoteUuid={noteUuid}
                        selectedOperationUuid={operationUuid}
                        selectedFeatureUuid={featureUuid}
                        // This is quite hacky; really we should have some regex in the matchPath
                        // call that handles this but I couldn't find out if this was possible
                        // with react router
                        selectedFieldUuid={
                            fieldUuid === 'usage' || fieldUuid === 'edit' ? undefined : fieldUuid
                        }
                        selectedHerdUuid={herdUuid}
                        selectedManualSensorId={sensorId}
                        isEditing={!!editingGeoFeatureCollection}
                    />
                )}
                {farmChatOpen && (
                    <div className="chat-container">
                        {historyOpen ? <FarmChatHistory /> : <FarmChatPanel />}
                    </div>
                )}
                {!farmChatOpen && chatNotification !== undefined && <FarmChatNotification />}
            </div>
        </div>
    );
};

export default connect((state: AppState) => ({
    editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,
    farmChatOpen: state.uiState.farmChatOpen,
    historyOpen: state.chatState.historyOpen,
    chatNotification: state.chatState.notification,
}))(FarmOverview);
