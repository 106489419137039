import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { GeoFeatureCollection, serialize } from '@fieldmargin/webapp-geo';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import { createAction, handleActions } from '@fieldmargin/webapp-state';
import { selectSubFields } from 'components/farm/map/select-sub-fields';
import type MapPosition from 'components/maps/model/MapPosition';
import type { MapViewSpec } from 'components/maps/model/MapView';
import { nextMapView } from 'components/maps/model/MapView';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess } from 'farms/farm-loading-state';
import type FeatureType from 'features/FeatureType';
import type FieldUsage from 'fields/FieldUsage';
import type { Feature } from 'geojson';
import { List, Record, Set } from 'immutable';
import { itemToGeoJsonItem } from 'lib/geo/geometry';
import LocalStorageHelper from 'lib/storage/LocalStorageHelper';
import type MapGroup from 'maps/farm/MapGroup';
import { createSelector } from 'reselect';
import { selectEditingFeatures, selectEditingFields } from 'sidebar/modules/shapes/shapes-util';
import { convertOldBasemap, getDisplayableBasemapTypes } from 'system/basemaps/basemaps';
import {
    setMapGroupVisibilityReducer,
    setMapIntegrationVisibilityReducer,
} from 'system/editing/farm-editing-maps';
import { loadLayerVisibility, persistLayerVisibility } from 'system/editing/farm-editing-persister';
import LayerVisibility from 'system/editing/LayerVisibility';
import type { AppDispatch, AppState } from 'system/store';
import { VisibilityOptions } from 'system/types';

import type { AttachmentItem } from './attachments';
import { AttachmentType } from './attachments';
import { startEditingReducers } from './start-editing-reducer';

export interface EditingData {
    id?: string;
}

export enum EditingType {
    FEATURE = 'feature',
    FIELD = 'field',
    SUB_FIELDS = 'sub-fields',
    AUTO_BOUNDARY = 'auto-boundary',
    MANUAL_SENSOR = 'manual-sensor',
    NOTE = 'note',
    OPERATION = 'operation',
    SENSOR = 'sensor',
    HERD = 'herd',
    MEASURE = 'measure',
}

const FarmEditingState = Record({
    drawingTool: null as DrawingTool | null,
    baseMap: 'satellite',
    layersPanelOpen: false,
    layerVisibility: LayerVisibility(),
    editingType: null as EditingType | null,
    editingData: {} as EditingData,
    // This is a copy of a GeoFeatureCollection from a note, field or feature, currently being edited
    editingGeoFeatureCollection: null as GeoFeatureCollection | null,
    highlightedGeoFeatureId: null as string | null,
    hoveredGeoFeatureId: null as string | null,
    // A custom message to be displayed when drawing on the map
    customHelpMessage: undefined as string | undefined,

    // These are in global state as they are needed by farm map as well as sidebar.

    // What type of attachment the user is selecting multiple of - null if not selecting multiple.
    selectingMultipleAttachmentType: null as AttachmentType | null,
    // What type of attachment the user is selecting one of - null if not selecting.
    selectingSingleAttachmentType: null as AttachmentType | null,

    editingAttachments: Set<AttachmentItem>(),

    mapView: nextMapView({
        type: 'full-extent',
    }),
    lastMapPosition: null as MapPosition | null,
});
interface FarmEditingState extends ReturnType<typeof FarmEditingState> {}
export default FarmEditingState;

const stopEditingReducer = (state: FarmEditingState) =>
    state.withMutations((s) =>
        s
            .set('editingType', null)
            .set('editingData', {})
            .set('editingGeoFeatureCollection', null)
            .set('selectingMultipleAttachmentType', null)
            .set('selectingSingleAttachmentType', null)
            .set('editingAttachments', Set())
            .set('drawingTool', null)
    );

const persistState = (state: FarmEditingState) => {
    persistLayerVisibility(state.layerVisibility);
    return state;
};

const toggleGroupVisibility = (
    state: FarmEditingState,
    itemsVisibilityKey: string,
    hiddenGroupsKey: string,
    groupId: string | number,
    allIds: List<string | number>,
    value: boolean
) => {
    // @ts-ignore
    if (state.layerVisibility.get(itemsVisibilityKey) !== VisibilityOptions.OFF) {
        // Items are on, toggle the single group
        return persistState(
            state.updateIn(['layerVisibility', hiddenGroupsKey], (hiddenGroups) => {
                if (value) {
                    return hiddenGroups.add(groupId);
                }
                return hiddenGroups.remove(groupId);
            })
        );
    }

    // Items are off, turn items on with just the single group
    return persistState(
        state
            .setIn(['layerVisibility', itemsVisibilityKey], VisibilityOptions.ON)
            .setIn(['layerVisibility', hiddenGroupsKey], Set(allIds).remove(groupId))
    );
};

export const selectDrawingTool = createAction<FarmEditingState, DrawingTool | null>(
    'Farm editing: select drawing tool',
    (state, drawingTool) => state.set('drawingTool', drawingTool)
);

export const toggleLayersPanel = createAction<FarmEditingState, boolean>(
    'Farm editing: toggle layers panel',
    (state, value) => state.set('layersPanelOpen', value)
);

export const toggleDisplayNotes = createAction<FarmEditingState, boolean>(
    'Farm editing: toggle display notes',
    (state, value) => persistState(state.setIn(['layerVisibility', 'displayNotes'], value))
);

export const setFieldsVisibility = createAction<FarmEditingState, string>(
    'Farm editing: set fields visibility',
    (state, value) => persistState(state.setIn(['layerVisibility', 'fieldsVisibility'], value))
);
export const setHerdsVisibility = createAction<FarmEditingState, string>(
    'Farm editing: set herds visibility',
    (state, value) => persistState(state.setIn(['layerVisibility', 'herdsVisibility'], value))
);
export const setFeaturesVisibility = createAction<FarmEditingState, string>(
    'Farm editing: set features visibility',
    (state, value) => persistState(state.setIn(['layerVisibility', 'featuresVisibility'], value))
);

export const toggleHiddenFeatureType = createAction<
    FarmEditingState,
    { featureTypeUuid: string; value: boolean; allFeatureTypes: List<FeatureType> }
>('Farm editing: toggle hidden feature type', (state, payload) => {
    return toggleGroupVisibility(
        state,
        'featuresVisibility',
        'hiddenFeatureTypes',
        payload.featureTypeUuid,
        payload.allFeatureTypes.map((ft) => ft.uuid),
        payload.value
    );
});

export const toggleHiddenFieldUsage = createAction<
    FarmEditingState,
    { fieldUsageUuid: string; value: boolean; allFieldUsages: List<FieldUsage> }
>('Farm editing: toggle hidden field usage', (state, payload) => {
    return toggleGroupVisibility(
        state,
        'fieldsVisibility',
        'hiddenFieldUsages',
        payload.fieldUsageUuid,
        payload.allFieldUsages.map((u) => u.uuid).push('none'),
        payload.value
    );
});

export const setMapIntegrationVisibility = createAction<
    FarmEditingState,
    { farmIntegrationUuid: string; visible: boolean }
>('Farm editing: set map integration visibility', (state, payload) =>
    persistState(setMapIntegrationVisibilityReducer(state, payload))
);

export const setMapGroupVisibility = createAction<
    FarmEditingState,
    { mapGroup: MapGroup; value: VisibilityOptions }
>('Farm editing: set map group visibility', (state, payload) =>
    persistState(setMapGroupVisibilityReducer(state, payload))
);

export const selectBaseMap = createAction<FarmEditingState, string>(
    'Farm editing: select base map',
    (state, payload) => {
        LocalStorageHelper.setItem('baseMap', payload);
        return state.set('baseMap', payload);
    }
);

export const stopEditing = createAction<FarmEditingState, any>(
    'Farm editing: stop editing',
    stopEditingReducer
);

export const setEditingData = createAction<FarmEditingState, EditingData>(
    'Farm editing: set editing data',
    (state, payload) => state.set('editingData', payload)
);

export const clearEditingGeoFeatureCollection = createAction<FarmEditingState, void>(
    'Farm editing: clear editing geo feature collection',
    (state) => state.set('editingGeoFeatureCollection', null)
);

export const addEditingGeoFeature = createAction<FarmEditingState, GeoFeature>(
    'Farm editing: add editing geo feature',
    (state, payload) =>
        state.editingGeoFeatureCollection
            ? state.updateIn(
                  ['editingGeoFeatureCollection', 'features'],
                  (features: List<GeoFeature>) => features.push(payload)
              )
            : state.set(
                  'editingGeoFeatureCollection',
                  GeoFeatureCollection({ features: List.of(payload) })
              )
);

export const updateEditingGeoFeature = createAction<FarmEditingState, GeoFeature>(
    'Farm editing: update editing geo feature',
    (state, payload) =>
        state.editingGeoFeatureCollection
            ? state.updateIn(
                  ['editingGeoFeatureCollection', 'features'],
                  (features: List<GeoFeature>) =>
                      features.map((geoFeature) =>
                          geoFeature.id === payload.id ? payload : geoFeature
                      )
              )
            : state.set(
                  'editingGeoFeatureCollection',
                  GeoFeatureCollection({ features: List.of(payload) })
              )
);

export const removeEditingGeoFeatureById = createAction<
    FarmEditingState,
    { id: string | number; nextDrawingTool: DrawingTool | null }
>('Farm editing: remove editing geo feature', (state, payload) => {
    if (!state.editingGeoFeatureCollection) {
        return state;
    }

    const newFeatures = state.editingGeoFeatureCollection.features.filter((geoFeature) => {
        return geoFeature.id !== payload.id;
    });
    return state
        .setIn(['editingGeoFeatureCollection', 'features'], newFeatures)
        .set('drawingTool', payload.nextDrawingTool);
});

export const removeEditingGeoFeaturesById = createAction<FarmEditingState, string[]>(
    'Farm editing: remove editing geo features',
    (state, payload) =>
        state.editingGeoFeatureCollection !== null
            ? state.updateIn(
                  ['editingGeoFeatureCollection', 'features'],
                  (features: List<GeoFeature>) =>
                      features.filter((geoFeature) => payload.includes(geoFeature.id.toString()))
              )
            : state
);

export const replaceEditingGeoFeatureCollection = createAction<
    FarmEditingState,
    GeoFeatureCollection
>('Farm editing: replace editing geo feature collection', (state, payload) =>
    state.set('editingGeoFeatureCollection', payload)
);

export const highlightGeoFeatureId = createAction<FarmEditingState, string | null>(
    'Farm editing: highlight geo feature id',
    (state, payload) => state.set('highlightedGeoFeatureId', payload)
);

export const hoverGeoFeatureId = createAction<FarmEditingState, string | number | undefined>(
    'Farm editing: hover geo feature id',
    (state, payload) => state.set('hoveredGeoFeatureId', payload?.toString() ?? null)
);

export const startSelectingMultipleAttachments = createAction<FarmEditingState, AttachmentType>(
    'Farm editing: start selecting multiple attachments',
    (state, payload) =>
        state.set('selectingMultipleAttachmentType', payload).set('drawingTool', null)
);

export const stopSelectingMultipleAttachments = createAction<FarmEditingState, void>(
    'Farm editing: stop selecting multiple attachments',
    (state) => state.set('selectingMultipleAttachmentType', null)
);

export const setEditingAttachments = createAction<FarmEditingState, Set<AttachmentItem>>(
    'Farm editing: set editing attachments',
    (state, payload) => state.set('editingAttachments', payload)
);
export const setEditingAttachmentsByType =
    (type: AttachmentType) => (ids: Set<string>) => (dispatch: AppDispatch) =>
        dispatch(setEditingAttachments(ids.map((id) => ({ id, type }))));

export const addEditingAttachment = createAction<FarmEditingState, AttachmentItem>(
    'Farm editing: add editing attachment',
    (state, payload) => state.set('editingAttachments', state.editingAttachments.add(payload))
);
export const addEditingAttachmentByType =
    (type: AttachmentType) => (id: string) => (dispatch: AppDispatch) =>
        dispatch(addEditingAttachment({ id, type }));

export const removeEditingAttachment = createAction<FarmEditingState, AttachmentItem>(
    'Farm editing: remove editing attachment',
    (state, payload) =>
        state.set(
            'editingAttachments',
            state.editingAttachments.filter(
                ({ id, type }) => id !== payload.id || type !== payload.type
            )
        )
);
export const removeEditingAttachmentByType =
    (type: AttachmentType) => (id: string) => (dispatch: AppDispatch) =>
        dispatch(removeEditingAttachment({ id, type }));

export const toggleEditingAttachment = createAction<FarmEditingState, AttachmentItem>(
    'Farm editing: toggle editing attachment',
    (state, payload) => {
        const currentIdx = state.editingAttachments.findKey(
            (attachment) => attachment.id === payload.id && attachment.type === payload.type
        );
        return state.set(
            'editingAttachments',
            currentIdx !== undefined
                ? state.editingAttachments.delete(currentIdx)
                : state.editingAttachments.add(payload)
        );
    }
);
export const toggleEditingAttachmentByType =
    (type: AttachmentType) => (id: string) => (dispatch: AppDispatch) =>
        dispatch(toggleEditingAttachment({ id, type }));

export const setMapView = createAction<FarmEditingState, MapViewSpec>(
    'Farm editing: set map view',
    (state, payload) => state.set('mapView', nextMapView(payload))
);

export const storeMapPosition = createAction<FarmEditingState, MapPosition>(
    'Farm editing: store map position',
    (state, payload) => state.set('lastMapPosition', payload)
);

export const startMeasuring = createAction<FarmEditingState, any>(
    'Farm editing: start measuring',
    (state) => state.set('editingType', EditingType.MEASURE)
);

export const startAutoBoundary = createAction<FarmEditingState, any>(
    'Farm editing: start auto boundary',
    (state) =>
        state
            .set('editingType', EditingType.AUTO_BOUNDARY)
            .set('selectingMultipleAttachmentType', AttachmentType.FIELD)
            .set('editingAttachments', Set())
            .set('editingGeoFeatureCollection', null)
            .set('drawingTool', 'box')
);

export const setCustomHelpMessage = createAction<FarmEditingState, string | undefined>(
    'Farm editing: set custom help message',
    (state, payload) => state.set('customHelpMessage', payload)
);

export const farmEditingReducer = handleActions<FarmEditingState>(
    FarmEditingState(),
    [
        selectDrawingTool,
        toggleLayersPanel,
        toggleDisplayNotes,
        setFieldsVisibility,
        setHerdsVisibility,
        setFeaturesVisibility,
        toggleHiddenFeatureType,
        toggleHiddenFieldUsage,
        setMapIntegrationVisibility,
        setMapGroupVisibility,
        selectBaseMap,
        stopEditing,
        setEditingData,
        clearEditingGeoFeatureCollection,
        addEditingGeoFeature,
        updateEditingGeoFeature,
        removeEditingGeoFeatureById,
        removeEditingGeoFeaturesById,
        replaceEditingGeoFeatureCollection,
        highlightGeoFeatureId,
        hoverGeoFeatureId,
        startSelectingMultipleAttachments,
        stopSelectingMultipleAttachments,
        setEditingAttachments,
        addEditingAttachment,
        removeEditingAttachment,
        toggleEditingAttachment,
        setMapView,
        storeMapPosition,
        startMeasuring,
        startAutoBoundary,
        setCustomHelpMessage,
    ].concat(startEditingReducers),
    {
        [loadedFarmSuccess.toString()]: (_, payload: LoadedFarmSuccessPayload) => {
            const farm = payload.farm;
            const layerVisibility = loadLayerVisibility(farm.uuid);
            const storedBasemap = LocalStorageHelper.getItem('baseMap');
            const baseMap =
                storedBasemap !== null
                    ? convertOldBasemap(storedBasemap)
                    : getDisplayableBasemapTypes()[0];
            return FarmEditingState({ layerVisibility, baseMap });
        },
    }
);

/**
 * Select features that the drawing tool should snap to.
 * For now this is only sub fields but it could be any other features in future.
 */
export const selectSnapFeatures = createSelector(selectSubFields, (subFields) => subFields);

export const selectDrawingControllerProps = createSelector(
    (state: AppState) => state.farmEditingState.drawingTool,
    selectSnapFeatures,
    (state: AppState) => state.farmEditingState.customHelpMessage,
    (drawingTool, snapFeatures, helpMessage) => ({
        drawingTool,
        snapFeatures: snapFeatures.toArray(),
        helpMessage,
    })
);

/**
 * Same as above but returns snap features as Feature[]
 */
export const selectGeoJsonDrawingControllerProps = createSelector(
    selectDrawingControllerProps,
    (drawingControllerProps) => ({
        ...drawingControllerProps,
        snapFeatures: drawingControllerProps.snapFeatures.map(
            (geoFeature) => serialize(geoFeature, { withProperties: true }) as Feature
        ),
    })
);

const DRAWING_ZOOM_LEVEL = 13;
export const selectDrawingBlocked = createSelector(
    (state: AppState) => state.farmEditingState.lastMapPosition,
    (lastMapPosition) => {
        if (lastMapPosition === null || lastMapPosition.zoom === null) {
            return false;
        }
        return lastMapPosition.zoom <= DRAWING_ZOOM_LEVEL;
    }
);

/**
 * Select whether the user is selecting fields, whether a single field or multiple ones.
 */
export const selectIsSelectingFields = createSelector(
    (state: AppState) =>
        state.farmEditingState.selectingMultipleAttachmentType === AttachmentType.FIELD,
    (state: AppState) =>
        state.farmEditingState.selectingSingleAttachmentType === AttachmentType.FIELD,
    (state: AppState) => state.farmEditingState.editingType === EditingType.AUTO_BOUNDARY,
    (selectingMultple, selectingSingle, isAutoBoundary) =>
        !isAutoBoundary && (selectingMultple || selectingSingle)
);

/**
 * Select whether the user is selecting herds
 */
export const selectIsSelectingHerds = createSelector(
    (state: AppState) => state.farmEditingState.selectingMultipleAttachmentType,
    (attachmentType) => attachmentType === AttachmentType.HERD
);

/**
 * Select whether the user is selecting features
 */
export const selectIsSelectingFeatures = createSelector(
    (state: AppState) => state.farmEditingState.selectingMultipleAttachmentType,
    (attachmentType) => attachmentType === AttachmentType.FEATURE
);

/**
 * Select editing attachment ids for a single type.
 */
export const selectEditingAttachmentsByType = createSelector(
    (_: AppState, attachmentType: AttachmentType) => attachmentType,
    (state: AppState) => state.farmEditingState.editingAttachments,
    (attachmentType, editingAttachments) =>
        editingAttachments.filter(({ type }) => type === attachmentType).map(({ id }) => id)
);

/**
 * Select editing attachment ids for a multiple type.
 */
export const selectEditingAttachmentsByTypes = createSelector(
    (_: AppState, attachmentTypes: AttachmentType[]) => attachmentTypes,
    (state: AppState) => state.farmEditingState.editingAttachments,
    (attachmentTypes, editingAttachments) =>
        editingAttachments.filter(({ type }) => attachmentTypes.includes(type)).map(({ id }) => id)
);

export const selectIsEditingAndNotSelecting = createSelector(
    (state: AppState) => state.farmEditingState.editingGeoFeatureCollection !== null,
    (state: AppState) => state.farmEditingState.selectingMultipleAttachmentType !== null,
    (state: AppState) => state.farmEditingState.selectingSingleAttachmentType !== null,
    (isEditing, isSelectingMultiple, isSelectingSingle) =>
        isEditing && !isSelectingMultiple && !isSelectingSingle
);

/**
 * Select GeoJsonItems for editing fields and features.
 */
export const selectEditingFieldAndFeaturesGeoJson = createSelector(
    selectEditingFields,
    selectEditingFeatures,
    (fields, features) => fields.concat(features).map(itemToGeoJsonItem)
);
