import type { PropsWithChildren } from 'react';

import styles from './FilterWrapper.module.css';

function Root({ children }: PropsWithChildren) {
    return <div className={styles.filterWrapperGroup}>{children}</div>;
}

function Label({ children }: PropsWithChildren) {
    return <div className={styles.filterWrapperLabel}>{children}</div>;
}

function Item({ children }: PropsWithChildren) {
    return <div className={styles.filterWrapperItem}>{children}</div>;
}

export const FilterWrapper = {
    Root,
    Label,
    Item,
};
