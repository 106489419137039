import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { format } from 'date-fns';
import { selectCurrentFarm, selectCurrentFarmHasMapPrinting } from 'farms/farms-state';
import type { AppState } from 'system/store';

import PrintLegend from './PrintLegend';
import PrintLogo from './PrintLogo';

import './PrintContainer.scss';

enum BasemapAttributionMap {
    azureSatellite = 'Microsoft',
    azureRoad = 'Microsoft',
    hereHybrid = 'HERE',
    roadmap = 'OpenStreetMap',
    ordnance = 'Microsoft',
}

interface PrintContainerProps {
    farm: Farm;
    year: number;
    hasPrinting: boolean;
    basemap: string;
}

const PrintContainer = ({ farm, year, hasPrinting, basemap }: PrintContainerProps) => (
    <div className="print-container">
        <PrintLogo />
        {!hasPrinting ? (
            <div className="PrintBlocking">
                <h1>Map printing is not available on our free plan</h1>
                <p>Please upgrade to our Essentials, Plus or Pro plan to access this feature.</p>
            </div>
        ) : (
            <>
                <div className="print-farm-name">
                    {farm.name} - {year}
                </div>
                <div className="print-footer">
                    {BasemapAttributionMap[basemap] && (
                        <span>
                            Reproduced with the permission of {BasemapAttributionMap[basemap]}
                        </span>
                    )}
                    <span>Printed on {format(new Date(), 'dd/MM/yyyy')}</span>
                </div>
                <PrintLegend />
            </>
        )}
    </div>
);
export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    year: state.yearsState.currentYear,
    hasPrinting: selectCurrentFarmHasMapPrinting(state),
    basemap: state.farmEditingState.baseMap,
}))(PrintContainer);
