import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';

interface SubFieldsUsagePreviousFinishProps {
    onClose: VoidFunction;
    usagesCopied: boolean;
}

const SubFieldsUsagePreviousFinish = ({
    onClose,
    usagesCopied,
}: SubFieldsUsagePreviousFinishProps) => {
    const { t } = useTranslation();
    return (
        <>
            <h2>{t('all_set_exclamation')}</h2>
            <p>
                {t(
                    usagesCopied
                        ? 'sub_fields_use_previous_finish_usages'
                        : 'sub_fields_use_previous_finish'
                )}
            </p>
            <Button onClick={onClose}>{t('activity_log_close')}</Button>
        </>
    );
};

export default SubFieldsUsagePreviousFinish;
