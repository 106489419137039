import { useEffect, useRef, useState } from 'react';
import { GeoPoint, GeoPosition } from '@fieldmargin/webapp-geo';
import type { FeaturesContollerData } from '@fieldmargin/webapp-ol-map';
import { BasemapController, Control, OpenLayersMap } from '@fieldmargin/webapp-ol-map';
import PointFeatureController from 'components/maps/openlayers/PointFeatureController';
import type { List } from 'immutable';
import { getMapKeyConfig } from 'lib/config';
import { notNil } from 'lib/fp-helpers';
import { uniqueId } from 'lodash';
import { getSatelliteBasemap } from 'system/basemaps/basemaps';
import BaseMapControl from 'view/openlayers/BaseMapControl';

import type { Place } from './create-farm-api';
import {
    getFarmLocationGeoFeature,
    getSearchedPlacesExtent,
    getSearchedPlacesGeoFeatures,
    getSearchedPlacesViewportAction,
} from './create-farm-utils';

import './CreateFarmMap.css';

const ukLocation = new GeoPoint({
    coordinates: GeoPosition({
        y: 54.5,
        x: -5.644488,
    }),
});

// Used to track viewport action ids so that multiple searches change the map.
let viewportActionId = 0;

interface CreateFarmMapProps {
    userLocation?: GeoPosition;
    onBasemapClick: (point: GeoPoint) => void;
    onFeatureClick: (placeId: string) => void;

    farmLocation?: GeoPosition;
    userGeoPosition?: GeoPosition;
    searchedPlaces?: List<Place>;
    selectedPlaceId?: string;
}

const CreateFarmMap = ({
    userLocation,
    onBasemapClick,
    onFeatureClick,
    farmLocation,
    userGeoPosition,
    selectedPlaceId,
    searchedPlaces,
}: CreateFarmMapProps) => {
    const [basemap, setBasemap] = useState<string>(getSatelliteBasemap());

    let defaultPoint = ukLocation;
    let defaultZoom = 6;
    if (userLocation) {
        defaultPoint = GeoPoint({
            coordinates: userLocation,
        });
        defaultZoom = 13;
    }

    const hostElementId = useRef(uniqueId('create-farm-map-')).current;
    const mapRef = useRef<OpenLayersMap<{ basemap: string; points: FeaturesContollerData }> | null>(
        null
    );
    useEffect(() => {
        mapRef.current = new OpenLayersMap(
            {
                hostElementId: hostElementId,
                viewport: {
                    zoom: defaultZoom,
                    maxZoom: 21,
                    centre: defaultPoint,
                },
                controls: [Control.ZOOM],
            },
            {
                basemap: new BasemapController([getSatelliteBasemap()], getMapKeyConfig(), 1, {
                    language: window.navigator?.language ?? 'en-GB',
                    country: 'GB',
                }),
                points: new PointFeatureController(101),
            },
            {
                basemapClick: onBasemapClick,
                featureClick: onFeatureClick,
            }
        );
        handleBasemapOrFarmLocationChange();
    }, []);

    /**
     * Update the map with the following
     *
     * - searched places as points
     * - zoom to the extent of the searched places
     *
     * This is a separate effect so that clicking on the basemap or selecting a point doesn't
     * cause the map to zoom when there are searched places.
     */
    useEffect(() => {
        const geoFeatures = getSearchedPlacesGeoFeatures(searchedPlaces);
        if (geoFeatures.length > 0 && searchedPlaces) {
            const viewportAction = getSearchedPlacesViewportAction(
                getSearchedPlacesExtent(searchedPlaces),
                viewportActionId++
            );
            mapRef.current?.update(
                { basemap, points: { geoFeatures, options: {} } },
                viewportAction
            );
        }
    }, [searchedPlaces]);

    /**
     * Update the map with the following:
     *
     * - correct basemap
     * - farm location as a point, this may also be a selected searched place
     * - searched places as points
     */
    const handleBasemapOrFarmLocationChange = () => {
        const points = [getFarmLocationGeoFeature(farmLocation)]
            .concat(getSearchedPlacesGeoFeatures(searchedPlaces, selectedPlaceId))
            .filter(notNil);
        mapRef.current?.update({ basemap, points: { geoFeatures: points, options: {} } });
    };
    useEffect(handleBasemapOrFarmLocationChange, [basemap, farmLocation, selectedPlaceId]);

    /**
     * Update the map when the user requests we use their geo position
     *
     * This is a separate effect so that clicking on the basemap or searching a point
     * doesn't cause the map to zoom to the user position.
     */
    useEffect(() => {
        if (userGeoPosition) {
            const geoFeatures = [getFarmLocationGeoFeature(farmLocation)].filter(notNil);
            mapRef.current?.update(
                { basemap, points: { geoFeatures, options: {} } },
                {
                    id: viewportActionId++,
                    position: {
                        centre: GeoPoint({ coordinates: userGeoPosition }),
                        zoom: 17,
                    },
                }
            );
        }
    }, [userGeoPosition]);

    /**
     * Update the map when the server has responded with the user location
     *
     * This is a separate effect so that clicking on the basemap or searching a point
     * doesn't cause the map to zoom to the user position.
     */
    useEffect(() => {
        if (userLocation) {
            const geoFeatures = [getFarmLocationGeoFeature(farmLocation)].filter(notNil);
            mapRef.current?.update(
                { basemap, points: { geoFeatures, options: {} } },
                {
                    id: viewportActionId++,
                    position: {
                        centre: GeoPoint({ coordinates: userLocation }),
                        zoom: 13,
                    },
                }
            );
        }
    }, [userLocation]);

    return (
        <div className="CreateFarmMap relative">
            <div id={hostElementId} style={{ width: '100%', height: '100%' }} />
            <BaseMapControl
                basicOnly={true}
                selectedBasemap={basemap}
                onSelectBasemap={setBasemap}
            />
        </div>
    );
};

export default CreateFarmMap;
