import type Field from 'fields/Field';
import type { List } from 'immutable';
import SidebarModule from 'sidebar/modules/SidebarModule';

import type SubField from '../SubField';

import SubFieldsDrawNew from './SubFieldsDrawNew';
import SubFieldsDrawNewPanel from './SubFieldsDrawNewPanel';
import SubFieldsUndo from './SubFieldsUndo';
import SubFieldsUsePrevious from './SubFieldsUsePrevious';

interface SubFieldsMainActionsProps {
    year: number;
    parentField: Field;
    drawingNew: boolean;
    history: List<List<SubField>>;
}

const SubFieldsMainActions = ({
    year,
    parentField,
    drawingNew,
    history,
}: SubFieldsMainActionsProps) => {
    return (
        <>
            <SidebarModule editing className="flex">
                <SubFieldsDrawNew />
                <SubFieldsUsePrevious parentField={parentField} />
                <SubFieldsUndo disabled={history.size === 0} />
            </SidebarModule>
            {drawingNew && <SubFieldsDrawNewPanel year={year} parentField={parentField} />}
        </>
    );
};

export default SubFieldsMainActions;
