import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { useDeleteFeature } from 'features/feature-hooks';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { usePageFeature } from 'hooks/usePageFeature';
import { isCreator } from 'lib/owner';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import { useAppSelector } from 'system/store';
import { selectIsFarmOwner } from 'team/farm-users-state';
import { selectUserId } from 'users/user-state';

const FeatureDeletion = () => {
    const { feature } = usePageFeature();
    const canDelete = useAppSelector(
        (state) => selectIsFarmOwner(state) || (feature && isCreator(feature, selectUserId(state)))
    );

    const { t } = useTranslation();
    const { currentFarm } = useCurrentFarm();
    const { deleteFeatureHandler, error, pending } = useDeleteFeature();

    return (
        feature && (
            <div className="scrollable">
                <div className="non-scrolling">
                    <SidebarHeader>
                        <SidebarHeaderLink
                            to={`/farms/${currentFarm.uuid}/features/${feature.uuid}`}
                        >
                            {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                        </SidebarHeaderLink>
                    </SidebarHeader>
                    {error && (
                        <SidebarError
                            title={t('failed_to_delete')}
                            message={t('something_went_wrong')}
                        />
                    )}
                </div>
                <div className="scrolling">
                    <SidebarModule editing>
                        <h2 className="mb-0">{feature.name}</h2>
                    </SidebarModule>
                    {canDelete ? (
                        <SidebarModule editing>
                            <p className="text-lg">{t('message_delete_confirmation_feature')}</p>
                            <Button
                                variant="danger"
                                onClick={deleteFeatureHandler}
                                disabled={pending}
                            >
                                {t(pending ? 'label_deleting' : 'label_delete')}
                            </Button>
                        </SidebarModule>
                    ) : (
                        <SidebarModule editing>
                            <p className="text-lg text-error-text">
                                {t('message_delete_unsupported')}
                            </p>
                        </SidebarModule>
                    )}
                </div>
            </div>
        )
    );
};

export default FeatureDeletion;
