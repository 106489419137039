import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import EmptyState from 'sidebar/modules/EmptyState';
import UpgradeButton from 'upgrade/UpgradeButton';

import { useFieldTabsContext } from '../useFieldTabsContext';

import fieldReportingEmpty from './field-reporting-empty.png';
import {
    filterOperationsByCompleteness,
    groupFieldOperationsByReportType,
} from './field-reporting-utils';
import FieldInputReporting from './FieldInputReporting';
import FieldOutputReporting from './FieldOutputReporting';
import FieldReportingFilter from './FieldReportingFilter';

const FieldReporting = () => {
    const { t } = useTranslation();
    const [filter, setFilter] = useState('all');
    const { farm, subFields, field, operations } = useFieldTabsContext();

    const {
        plan: { reportingLevel },
    } = farm;

    if (reportingLevel === 'NO_REPORTING') {
        return farm.demo ? null : (
            <div className="FieldInputReporting">
                <EmptyState image={fieldReportingEmpty}>
                    <Markdown>{t('field_reporting_upgrade')}</Markdown>
                    <p>
                        <UpgradeButton />
                    </p>
                </EmptyState>
            </div>
        );
    }

    const groupedOperations = groupFieldOperationsByReportType(field, subFields, operations);
    if (
        operations.size === 0 ||
        (groupedOperations.withInputs.size === 0 && groupedOperations.withYields.size === 0)
    ) {
        return (
            <EmptyState image={fieldReportingEmpty}>
                <Markdown>{t('field_reporting_empty')}</Markdown>
            </EmptyState>
        );
    }

    const fieldAndSubFieldUuids = subFields
        .map((sf) => sf.uuid)
        .unshift(field.uuid)
        .toSet();

    const yieldOperations =
        filter !== 'all'
            ? groupedOperations.withYields.filter((o) =>
                  filterOperationsByCompleteness(o, filter, fieldAndSubFieldUuids)
              )
            : groupedOperations.withYields;
    const inputOperations =
        filter !== 'all'
            ? groupedOperations.withInputs.filter((o) =>
                  filterOperationsByCompleteness(o, filter, fieldAndSubFieldUuids)
              )
            : groupedOperations.withInputs;

    return (
        <div>
            <div className="p-5 pb-0">
                <FieldReportingFilter filter={filter} setFilter={setFilter} />
            </div>
            {groupedOperations.withYields.size > 0 && (
                <FieldOutputReporting
                    fieldUuids={fieldAndSubFieldUuids}
                    operations={yieldOperations}
                    filter={filter}
                />
            )}
            {groupedOperations.withInputs.size > 0 && (
                <FieldInputReporting
                    field={field}
                    subFields={subFields}
                    operations={inputOperations}
                    filter={filter}
                />
            )}
        </div>
    );
};

export { FieldReporting };
