import type { ComponentProps, MouseEvent, PropsWithChildren } from 'react';
import { useContext } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { DropdownButtonContext } from './DropdownButton';

import styles from './DropdownButtonItem.module.css';

const Root = ({ children }: PropsWithChildren) => {
    return <li className={styles.dropdownButtonItem}>{children}</li>;
};

interface LinkItemProps extends LinkProps {
    disabled?: boolean;
}
const LinkItem = ({ children, disabled, className, onClick, ...props }: LinkItemProps) => {
    const toggleMenuOpen = useContext(DropdownButtonContext);

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        if (disabled) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            toggleMenuOpen();
            if (onClick) onClick(e);
        }
    };
    return (
        <Link
            {...props}
            className={clsx(styles.item, { [styles.disabled]: disabled }, className)}
            onClick={handleClick}
        >
            {children}
        </Link>
    );
};

interface AnchorItemProps extends ComponentProps<'a'> {
    disabled?: boolean;
}
const AnchorItem = ({ children, disabled, className, onClick, ...props }: AnchorItemProps) => {
    const toggleMenuOpen = useContext(DropdownButtonContext);

    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        if (disabled) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            toggleMenuOpen();
            if (onClick) onClick(e);
        }
    };
    return (
        <a
            {...props}
            className={clsx(styles.item, { [styles.disabled]: disabled }, className)}
            onClick={handleClick}
        >
            {children}
        </a>
    );
};

export const DropdownButtonItem = { Root, LinkItem, AnchorItem };
