import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import icon from 'components/icons/icon';
import { InputLimitPrompt } from 'components/input-prompt';
import { SearchBar } from 'components/search-bar';
import { selectCurrentFarm } from 'farms/farms-state';
import { useOperationDetailsSelector } from 'hooks/selectors';
import type { List } from 'immutable';
import { Set } from 'immutable';
import type { InputUnit } from 'inputs/Input';
import type Input from 'inputs/Input';
import { isOnOrOverInputLimit, shouldShowInputWarning } from 'inputs/input-utils';
import { selectActiveInputs, selectLockedInputs, setInput } from 'inputs/inputs-state';
import { getInputTypeI18nKey, getSortedInputTypes, InputType } from 'inputs/InputType';
import { micronutrientsToFormValues, nutrientsToFormValues } from 'inputs/Nutrients';
import { preventDefaultAnd } from 'lib/dom';
import { useToggleSet } from 'lib/hooks';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import Modal from 'modal/Modal';
import { setOperationRecordings } from 'operations/operations-state';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import PrimaryPlusButton from 'sidebar/modules/PrimaryPlusButton';
import { SidebarToastContext } from 'sidebar/Sidebar';
import type { AppState } from 'system/store';
import type { CodedItem } from 'system/types';
import UpgradeLink from 'upgrade/UpgradeLink';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import Dropdown from 'view/dropdown/Dropdown';
import CheckboxFilterItem from 'view/filters/CheckboxFilterItem';
import FeatureLockedModal from 'view/modal/FeatureLockedModal';

import { getRecordingAddedToastMessage } from '../details/recording/operation-recording-utils';

import InputLists from './InputLists';
import NewInputAndRecordingForm from './NewInputAndRecordingForm';
import NewPesticideRecordingForm from './NewPesticideRecordingForm';
import RecordingForm from './RecordingForm';

interface ReduxProps {
    // From redux
    farm: Farm;
    inputs: List<Input>;
    lockedInputs: List<string>; // List of locked input uuids
    activeInputs: List<Input>;
    areaMeasurementUnit: MeasurementUnit;
}

interface ParentProps {
    // From parent
    onBack: VoidFunction;
    totalArea: number;
    initialInputTypeFilter?: Set<string>;
    onSaveNewRecording: (selectedInput: Input, rate: number) => PromiseLike<any>;
    onSaveNewInputAndRecording: (inputValues: Partial<Input>, rate: number) => Promise<any>;
}

type InputsSelectorProps = ParentProps & ReduxProps;

const InputsSelector = ({
    // From parent
    onBack,
    totalArea,
    onSaveNewRecording,
    onSaveNewInputAndRecording,
    initialInputTypeFilter = Set(),

    // From redux
    farm,
    inputs,
    activeInputs,
    lockedInputs,
    areaMeasurementUnit,
}: InputsSelectorProps) => {
    const { t } = useTranslation();
    const { showSidebarToast } = useContext(SidebarToastContext);
    const [selectedInput, setSelectedInput] = useState<Input>();
    const [selectedPesticide, setSelectedPesticide] = useState<CodedItem>();
    const [selectedFertilizer, setSelectedFertilizer] = useState<Input>();
    const [addingNewInput, setAddingNewInput] = useState(false);
    const [inputFilterString, setInputFilterString] = useState('');
    const [inputTypeFilter, toggleFilterType] = useToggleSet(initialInputTypeFilter);
    const [showUpgradeModal, toggleShowUpgradeModal] = useToggle(false);
    const [showLimitModal, toggleShowLimitModal] = useToggle(false);
    const { isAttachingFuel } = useOperationDetailsSelector();

    const warningMsg = shouldShowInputWarning(farm, inputs) && (
        <div className="non-scrolling">
            <InputLimitPrompt />
        </div>
    );
    const limitMsg = isOnOrOverInputLimit(farm, inputs) && (
        <div className="non-scrolling">
            <InputLimitPrompt />
        </div>
    );

    const handlePesticideSelected = (pesticide: CodedItem) => {
        if (!farm.plan.pesticideDB) {
            !showUpgradeModal && toggleShowUpgradeModal();
            return;
        }

        if (isOnOrOverInputLimit(farm, inputs)) {
            !showLimitModal && toggleShowLimitModal();
            return;
        }

        setSelectedPesticide(pesticide);
    };

    const handleFertilizerSelect = (fertilizer: Input) => {
        if (isOnOrOverInputLimit(farm, inputs)) {
            !showLimitModal && toggleShowLimitModal();
            return;
        }

        setSelectedFertilizer(fertilizer);
        setAddingNewInput(true);
    };

    const handleAddAnother =
        (cb: VoidFunction) => (name: string, type: string, unit: InputUnit, rate: number) => {
            showSidebarToast(
                {
                    message: getRecordingAddedToastMessage(
                        name,
                        type,
                        unit,
                        rate,
                        areaMeasurementUnit
                    ),
                    level: 'info',
                },
                3000
            );
            cb();
        };

    const newButton = !isOnOrOverInputLimit(farm, inputs) && (
        <PrimaryPlusButton onClick={() => setAddingNewInput(true)}>
            <span className="max-w-[250px] truncate">
                {t('add_new_input')}
                {inputFilterString !== '' && ` "${inputFilterString}"`}
            </span>
        </PrimaryPlusButton>
    );

    if (selectedInput) {
        return (
            <RecordingForm
                farm={farm}
                input={selectedInput}
                totalArea={totalArea}
                measurementUnit={areaMeasurementUnit}
                onCancel={() => setSelectedInput(undefined)}
                onSave={onSaveNewRecording}
                onAddAnother={handleAddAnother(setSelectedInput.bind(null, undefined))}
                onDone={onBack}
            />
        );
    }

    if (selectedPesticide) {
        return (
            <NewPesticideRecordingForm
                farm={farm}
                totalArea={totalArea}
                measurementUnit={areaMeasurementUnit}
                onCancel={() => setSelectedPesticide(undefined)}
                onSave={onSaveNewInputAndRecording}
                pesticide={selectedPesticide}
                onAddAnother={handleAddAnother(setSelectedPesticide.bind(null, undefined))}
                onDone={onBack}
            />
        );
    }

    if (addingNewInput) {
        const initialValues =
            selectedFertilizer !== undefined
                ? {
                      name: selectedFertilizer.name,
                      unit: '',
                      type: selectedFertilizer.type,
                      nutrients: nutrientsToFormValues(selectedFertilizer.nutrients),
                      micronutrients: micronutrientsToFormValues(selectedFertilizer.micronutrients),
                  }
                : {
                      name: inputFilterString,
                      unit: '',
                      type: isAttachingFuel ? InputType.FUEL : '',
                  };

        const resetAddingNewState = () => {
            setAddingNewInput(false);
            setSelectedFertilizer(undefined);
        };

        return (
            <NewInputAndRecordingForm
                totalArea={totalArea}
                measurementUnit={areaMeasurementUnit}
                initialValues={initialValues}
                fertiliserUuid={selectedFertilizer?.fertiliserUuid}
                onCancel={resetAddingNewState}
                onSave={onSaveNewInputAndRecording}
                onAddAnother={handleAddAnother(resetAddingNewState)}
                onDone={onBack}
            />
        );
    }

    return (
        <div className="InputsSelector scrollable">
            {showUpgradeModal && (
                <FeatureLockedModal
                    onClose={toggleShowUpgradeModal}
                    planList={['essentials', 'plus', 'pro']}
                />
            )}
            {showLimitModal && (
                <Modal onClose={toggleShowLimitModal}>
                    <div
                        className="FeatureLockedModal bg-white p-8 text-center flex flex-col"
                        style={{ width: 500 }}
                    >
                        <h2 className="text-xl">{t('input_limit_reached')}</h2>
                        <p className="text-lg">
                            {t('pro_upgrade_prompt_inputs_title_below_limit', {
                                sprintf: [inputs.size, farm.plan.inputLimit],
                            })}
                            .{' '}
                            <UpgradeLink>
                                {t('pro_upgrade_subtitle_2').replace('\\n', ' ')}
                            </UpgradeLink>
                        </p>
                    </div>
                </Modal>
            )}
            <SidebarHeader className="pr-5">
                <SidebarHeaderLink to="#" onClick={preventDefaultAnd(onBack)}>
                    {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                </SidebarHeaderLink>
            </SidebarHeader>
            {limitMsg}
            <div className="flex justify-between p-6">
                <div className="flex flex-col items-center">
                    <SearchBar
                        value={inputFilterString}
                        searchFunction={setInputFilterString}
                        className="mb-4"
                    />
                    {newButton}
                </div>
                <Dropdown
                    className="relative h-10 w-52"
                    menuClassName="w-full"
                    title={
                        inputTypeFilter.size > 0 ? (
                            <span className="truncate">
                                {inputTypeFilter.map((i) => t(getInputTypeI18nKey(i))).join(', ')}
                            </span>
                        ) : (
                            <em>{t('filter_by_type')}</em>
                        )
                    }
                >
                    {getSortedInputTypes().map((item) => (
                        <CheckboxFilterItem
                            key={item}
                            id={item}
                            label={t(getInputTypeI18nKey(item))}
                            selected={inputTypeFilter.has(item)}
                            onChange={() => toggleFilterType(item)}
                        />
                    ))}
                </Dropdown>
            </div>
            <InputLists
                farm={farm}
                hasAnyInputs={inputs.size > 0}
                inputs={activeInputs}
                lockedInputs={lockedInputs}
                onInputSelect={setSelectedInput}
                onPesticideSelect={handlePesticideSelected}
                onFertilizerSelect={handleFertilizerSelect}
                searchTerm={inputFilterString}
                typeFilter={inputTypeFilter}
            />
            {warningMsg}
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        inputs: state.inputsState.inputs,
        lockedInputs: selectLockedInputs(state),
        activeInputs: selectActiveInputs(state),
        areaMeasurementUnit: selectUserAreaMeasurementUnit(state),
    }),
    (dispatch) => bindActionCreators({ setOperationRecordings, setInput }, dispatch)
)(InputsSelector);
