const RenderOSButton = ({ OS }) => {
    if (OS === 'iOS') {
        return (
            <div>
                <AppleButton />
            </div>
        );
    } else if (OS === 'AndroidOS') {
        // if android show android
        return (
            <div>
                <GoogleButton />
            </div>
        );
    } else {
        // if not recognised - show both
        return (
            <div>
                <AppleButton />
                <br />
                <GoogleButton />
            </div>
        );
    }
};

const AppleButton = () => (
    <a
        href="https://itunes.apple.com/gb/app/fieldmargin-your-farm-in-your/id1096607801?ls=1&amp;mt=8"
        target="_blank"
        rel="noopener noreferrer"
    >
        <img
            id="ios-store-mobile-one"
            src="/images/app-store@2x.png"
            alt="Download on the App Store"
        />
    </a>
);
const GoogleButton = () => (
    <a
        href="https://play.google.com/store/apps/details?id=com.fieldmargin"
        target="_blank"
        rel="noopener noreferrer"
    >
        <img id="android-store-mobile-one" src="/images/google-play-badge@3x.png" />
    </a>
);

export default RenderOSButton;
