import type { List } from 'immutable';
import { uniqueId } from 'lodash';
import type { BadgeItemWithIdLike } from 'view/molecules/badge-item/BadgeItem';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';

import 'molecules/fancy-radio.scss';

interface BadgeRadioFieldProps {
    items: List<BadgeItemWithIdLike>;
    selected: string;
    onChange: (id: string) => void;
}

const BadgeRadioField = ({ items, selected, onChange }: BadgeRadioFieldProps) => {
    const options = items.map((item) => {
        const inputId = uniqueId('badge-item-');
        return (
            <div className="mb-3 flex items-center" key={item.id}>
                <div className="relative">
                    <input
                        type="radio"
                        name="badge-radio"
                        className="fancy-radio"
                        checked={selected === item.id}
                        onChange={() => onChange(item.id)}
                        id={inputId}
                    />
                    <label className="h-5" htmlFor={inputId} />
                </div>
                <label htmlFor={inputId}>
                    <BadgeItem item={item} />
                </label>
            </div>
        );
    });

    return <div>{options}</div>;
};

export default BadgeRadioField;
