import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import icon from 'components/icons/icon';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import type { List, Set } from 'immutable';
import { useItemSelectedState } from 'lib/hooks';
import type FullOperation from 'operations/FullOperation';
import { selectOutputsSortedByName } from 'outputs/outputs-state';
import { useAppSelector } from 'system/store';
import Num from 'view/Num';
import RememberingLink from 'view/RememberingLink';
import FormattedRate from 'view/units/FormattedRate';
import ShortAreaUnit from 'view/units/ShortAreaUnit';
import ShortUnit from 'view/units/ShortUnit';

import { getOutputReport } from './field-reporting-utils';
import FieldReportTable from './FieldReportTable';

interface FieldOutputReportingProps {
    // From parent
    fieldUuids: Set<string>;
    operations: List<FullOperation>;
    filter: string;
}

const FieldOutputReporting = ({ fieldUuids, operations, filter }: FieldOutputReportingProps) => {
    const { t } = useTranslation();
    const { currentFarm } = useCurrentFarm();
    const outputs = useAppSelector(selectOutputsSortedByName);
    const [toggleOpen, isOpen] = useItemSelectedState();

    const rows = getOutputReport(operations, outputs, fieldUuids).map(
        ({ output, yieldRateHa, yieldTotal, operations }) => (
            <Fragment key={output.uuid}>
                <tr className="cursor-pointer" onClick={() => toggleOpen(output.uuid)}>
                    <td>
                        {output.name} {operations.size}x
                    </td>
                    <td>
                        <FormattedRate rate={yieldRateHa} /> <ShortUnit unit={output.unit} />
                    </td>
                    <td>
                        <Num value={yieldTotal} sigFigs={4} /> <ShortUnit unit={output.unit} />
                        <i
                            className={c(
                                'float-right ml-2 cursor-pointer',
                                isOpen(output.uuid) ? 'ion-chevron-up' : 'ion-chevron-down'
                            )}
                        />
                    </td>
                </tr>
                {isOpen(output.uuid) &&
                    operations.map((operation, i) => (
                        <tr className="bg-gray-200" key={i}>
                            <td>
                                <RememberingLink
                                    to={`/farms/${currentFarm.uuid}/operations/${operation.uuid}`}
                                >
                                    {icon('field-job', 'blue', 'mr-3')}
                                    <span className="truncate inline-block align-middle w-2/3">
                                        {operation.name}
                                    </span>
                                </RememberingLink>
                            </td>
                            <td>
                                <FormattedRate rate={operation.field.yieldRateHa ?? 0} />{' '}
                                <ShortUnit unit={output.unit} />
                            </td>
                            <td>
                                <Num value={operation.field.yieldTotal ?? 0} sigFigs={4} />{' '}
                                <ShortUnit unit={output.unit} />
                            </td>
                        </tr>
                    ))}
            </Fragment>
        )
    );

    const children =
        rows.size === 0 && filter !== 'all' ? (
            <p>
                {filter === 'todo'
                    ? t('field_reporting_output_empty_incomplete')
                    : t('field_reporting_output_empty_complete')}
            </p>
        ) : (
            <FieldReportTable
                headings={[
                    t('output'),
                    <>
                        {t('operation_details_output_rate')}
                        <ShortAreaUnit />
                    </>,
                    t('total'),
                ]}
            >
                {rows}
            </FieldReportTable>
        );
    return <div className="p-5 pb-0">{children}</div>;
};

export default FieldOutputReporting;
