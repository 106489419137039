import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from 'hooks/useDateFormat';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';

import type { PesticideCheckApplicationInterval } from './PesticideCheck';
import { PesticideCheckStatus } from './PesticideCheck';
import PesticideCheckResultIcon from './PesticideCheckResultIcon';

interface ApplicationIntervalResultProps {
    check: PesticideCheckApplicationInterval;
}

const ApplicationIntervalResult = ({ check }: ApplicationIntervalResultProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();

    let content: ReactNode = null;
    if (check.status === PesticideCheckStatus.UNKNOWN) {
        content = t('pesticide_check_application_interval_no_data');
    } else if (check.values.lastApplication === undefined) {
        content = t('pesticide_check_application_interval_no_applications', {
            sprintf: [check.values.applicationInterval],
        });
    } else {
        const lastApplication = format(new Date(check.values.lastApplication), 'dd MMM yyyy');
        content =
            check.status === PesticideCheckStatus.FAIL ? (
                <MarkdownNoPara>
                    {t('pesticide_check_application_interval_fail', {
                        sprintf: [lastApplication, check.values.applicationInterval],
                    })}
                </MarkdownNoPara>
            ) : (
                t('pesticide_check_application_interval_pass')
            );
    }

    return (
        <tr>
            <PesticideCheckResultIcon status={check.status} />
            <td>{content}</td>
        </tr>
    );
};

export default ApplicationIntervalResult;
