import type { ChangeEvent } from 'react';
import { useWatch } from 'react-hook-form';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import type { FullOperationField } from 'operations/OperationField';
import { isEmpty } from 'ramda';
import FarmUserFormField from 'view/form/FarmUserFormField';

interface CompleteOperationFieldProps {
    operationField: FullOperationField;
    onCompleteChange: (complete: boolean, completedByUserId?: string) => void;
}

const CompleteOperationField = ({
    operationField,
    onCompleteChange,
}: CompleteOperationFieldProps) => {
    const handleFieldCompleteChange = (e: ChangeEvent<HTMLInputElement>) => {
        onCompleteChange(e.target.checked);
    };

    const completedDate = useWatch({ name: `${operationField.field.uuid}.completedDate` });
    const isComplete = completedDate !== undefined && !isEmpty(completedDate);

    return (
        <div className="flex items-center">
            <Checkbox
                id={`${operationField.field.uuid}-completed`}
                checked={isComplete}
                className="relative h-5"
                onChange={handleFieldCompleteChange}
            />
            <FarmUserFormField
                field={`${operationField.field.uuid}.completedByUserId`}
                onChange={(userId) => onCompleteChange(true, userId)}
            />
        </div>
    );
};

export default CompleteOperationField;
