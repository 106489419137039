import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { OperationRecord } from 'components/operation-record';
import { SidebarCard } from 'components/sidebar-card';
import { useOperationDetailsActions } from 'hooks/actions';
import { useSaveOperation } from 'hooks/query';
import { useParsedFuelRecordings } from 'hooks/selectors';
import { useTotalFieldsArea } from 'hooks/selectors/src/useOperationTotalFieldsArea';
import { useCurrentOperation } from 'hooks/useCurrentOperation';

import OperationRecordingCard from '../recording/OperationRecordingCard';

function ButtonAddFuel() {
    const { t } = useTranslation();
    const { handleToggleAttachingFuel } = useOperationDetailsActions();

    return (
        <SidebarCard.AddButton onPress={handleToggleAttachingFuel}>
            {t('operation_details_add_fuel')}
        </SidebarCard.AddButton>
    );
}

function EditableOperationFuel() {
    const { t } = useTranslation();
    const { fuels } = useParsedFuelRecordings();
    const { currentOperation } = useCurrentOperation();
    const { totalFieldsArea } = useTotalFieldsArea();
    const { handleSaveOperation } = useSaveOperation();
    const hasFuel = fuels && fuels.size > 0 && currentOperation;

    return (
        <SidebarCard.Root>
            <SidebarCard.Header>
                <SidebarCard.Heading as="h4">{t('input_type_fuel')}</SidebarCard.Heading>
                <ButtonAddFuel />
            </SidebarCard.Header>

            {hasFuel && (
                <Fragment>
                    <OperationRecord.Header />
                    <OperationRecord.List>
                        {fuels.map((fuel) => (
                            <OperationRecordingCard
                                key={fuel.recording.uuid}
                                fullOperation={currentOperation}
                                operationRecording={fuel}
                                totalArea={totalFieldsArea}
                                onSave={handleSaveOperation}
                            />
                        ))}
                    </OperationRecord.List>
                </Fragment>
            )}
        </SidebarCard.Root>
    );
}

export { EditableOperationFuel };
