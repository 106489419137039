import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { selectHerdsFromUuids } from 'herd/herd-selectors';
import { herdColours } from 'lib/colours';
import CreateHeader from 'sidebar/header/CreateHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import { useAppSelector } from 'system/store';
import ColourPickerField from 'view/form/hook/ColourPickerField';
import TextInputField from 'view/form/hook/TextInputField';
import { required } from 'view/form/validations';

import { useHerdMergeForm } from './herd-merge-hooks';
import { useHerdMergeContext } from './useHerdMergeContext';

const HerdMergeDetails = () => {
    const { t } = useTranslation();
    const { selectedHerds, herd, farm } = useHerdMergeContext();
    const { herds } = useHerdMergeData(selectedHerds);
    const { methods, handleSubmit, pending, error } = useHerdMergeForm(herd, herds, selectedHerds);

    const validateName = required(t('register_required'));
    const validateColour = required(t('colour_validation'));

    return (
        <FormProvider {...methods}>
            <form className="scrollable" onSubmit={methods.handleSubmit(handleSubmit)}>
                <div className="non-scrolling">
                    <CreateHeader
                        backPath={`/farms/${farm.uuid}/livestock/${herd.uuid}/merge`}
                        isSaving={pending}
                    />
                    {error && (
                        <SidebarError
                            title={t('failed_to_save')}
                            message={t('something_went_wrong')}
                        />
                    )}
                </div>
                <div className="scrolling">
                    <SidebarModule editing>
                        <h2 className="mb-5">{t('merge_herds')}</h2>
                        <div className="flex gap-8 mb-5">
                            <TextInputField
                                field="name"
                                label={<strong>{t('livestock_create_name')}</strong>}
                                validate={validateName}
                                className="w-7/12"
                                inputClassName="w-full"
                            />
                            <TextInputField
                                field="size"
                                type="number"
                                label={<strong>{t('livestock_details_count')}</strong>}
                                disabled
                            />
                        </div>
                        <ColourPickerField
                            field="colour"
                            label={<strong>{t('label_select_color')}</strong>}
                            colours={herdColours}
                            validate={validateColour}
                        />
                    </SidebarModule>
                </div>
            </form>
        </FormProvider>
    );
};

const useHerdMergeData = (selectedHerds: string[]) => {
    const herds = useAppSelector((state) => selectHerdsFromUuids(state, selectedHerds));
    return { herds };
};

export default HerdMergeDetails;
