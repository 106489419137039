import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { List } from 'immutable';
import { always } from 'ramda';

import type { FieldUsageDTO, WriteFieldUsageDTO } from './FieldUsage';
import type FieldUsage from './FieldUsage';
import { deserializeFieldUsage } from './FieldUsage';

/**
 * Fetch all field usages from the server.
 */
export const getFieldUsages = (farmUuid: string): Promise<List<FieldUsage>> =>
    authorizedRequest<FieldUsageDTO[]>({
        url: `/notes-api/farms/${farmUuid}/field_usages/`,
    }).then((usages) => List(usages.map(deserializeFieldUsage)));

/**
 * Create a new field usage
 */
export const createFieldUsage = (farmUuid: string, data: WriteFieldUsageDTO) =>
    authorizedRequest<string>({
        url: `/notes-api/farms/${farmUuid}/field_usages/`,
        method: 'POST',
        data,
    }).then(
        (uuid): FieldUsage =>
            deserializeFieldUsage({
                uuid,
                farmUuid,
                name: data.name,
                cropCode: data.cropCode,
                description: data.description ?? '',
                colour: data.colour ?? '',
            })
    );

/**
 * Update a field usage
 */
export const updateFieldUsage = (
    farmUuid: string,
    fieldUsageUuid: string,
    data: Partial<WriteFieldUsageDTO>
) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/field_usages/${fieldUsageUuid}`,
        method: 'PUT',
        data,
    }).then(always(data));

/**
 * Toggle archive state of a field usage
 */
export const toggleFieldUsageArchived = (farmUuid: string, fieldUsage: FieldUsage) =>
    authorizedRequest({
        url: `/notes-api/farms/${farmUuid}/field_usages/${fieldUsage.uuid}/archive`,
        method: 'PUT',
        params: { archive: !fieldUsage.archived },
    }).then(always({ ...fieldUsage, archived: !fieldUsage.archived }));
