import { useContext } from 'react';
import { useSelector } from 'react-redux';
import type { User } from '@fieldmargin/webapp-auth';
import { findFarm } from '@fieldmargin/webapp-farms';
import { setFarm } from 'farms/farms-state';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { setMembership } from 'membership/membership-state';
import { LoadTimeContext } from 'system/load-time-state';
import type { AppState } from 'system/store';
import { useAppDispatch, useAppSelector } from 'system/store';
import { selectFarmOwners } from 'team/farm-users-state';
import FarmOverviewFullScreen from 'view/FarmOverviewFullScreen';

import PaymentApp from './PaymentApp';

const PaymentContainer = () => {
    const dispatch = useAppDispatch();
    const { currentFarm } = useCurrentFarm();
    const farmUsers = useAppSelector(selectFarmOwners);
    const user = useSelector<AppState, User>((state) => state.user.user as User);
    const trialAvailable = useSelector<AppState, boolean>(
        (state) => state.membershipState.trialAvailable
    );
    const loadTimeState = useContext(LoadTimeContext);

    const onMembershipUpdate = async (finishAt: Date, trialAvailable: boolean) => {
        dispatch(setMembership({ finishAt, trialAvailable }));
        const updatedFarm = await findFarm(currentFarm.uuid);
        dispatch(setFarm(updatedFarm));
    };

    const data = {
        farm: { ...currentFarm, owners: farmUsers },
        user,
        trialAvailable,
    };

    return (
        <FarmOverviewFullScreen>
            <PaymentApp data={data} events={{ onMembershipUpdate }} loadTimeState={loadTimeState} />
        </FarmOverviewFullScreen>
    );
};

export default PaymentContainer;
