import type { ComponentProps } from 'react';
import { prefersImperialUnits } from 'lib/MeasurementUnit';
import { useAppSelector } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import { hectaresRateToAcresRate } from 'utils/conversion';
import Num from 'view/Num';

interface FormattedRateProps {
    rate: number;
    noConversion?: boolean;
}

const FormattedRate = ({ rate, noConversion = false }: FormattedRateProps) => {
    const areaUnit = useAppSelector(selectUserAreaMeasurementUnit);
    const convertedRate =
        !noConversion && prefersImperialUnits(areaUnit) ? hectaresRateToAcresRate(rate) : rate;

    const numProps: ComponentProps<typeof Num> = {
        value: convertedRate,
        compact: convertedRate >= 10000,
    };

    if (convertedRate < 1) {
        numProps.places = 4;
    } else {
        numProps.sigFigs = 5;
    }

    return <Num {...numProps} />;
};

export default FormattedRate;
