import type { PropsWithChildren } from 'react';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import icon from 'components/icons/icon';
import { preventDefaultAnd } from 'lib/dom';

interface PrimaryPlusButtonProps
    extends PropsWithChildren<{
        onClick?: VoidFunction;
        className?: string;
        small?: boolean;
    }> {}

const PrimaryPlusButton = ({
    onClick,
    children,
    className,
    small = true,
}: PrimaryPlusButtonProps) => {
    const handleClick = onClick ? preventDefaultAnd(onClick) : undefined;
    return (
        <Button
            onClick={handleClick}
            small={small}
            className={c('flex items-center px-2 gap-3', className)}
        >
            {icon(
                'add',
                'white',
                'p-0 w-6 flex-grow-0 flex-shrink-0 border-2 border-solid border-white rounded-full'
            )}{' '}
            {children}
        </Button>
    );
};

export default PrimaryPlusButton;
