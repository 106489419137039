import { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm, FarmUser } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import { createFarmChatLog } from 'chat/chat-generator';
import {
    setPreviousScrollPos,
    toggleHistory,
    uploadFile,
    writeChatMessage,
    writeLastRead,
} from 'chat/chat-state';
import CommentBox from 'chat/chatlog/CommentBox';
import ChatMessage, { getChatFileUrl, getChatImageUrl } from 'chat/ChatMessage';
import { DiscussionList } from 'components/discussion-list';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type { TFunction } from 'i18next';
import { List } from 'immutable';
import configService from 'lib/config';
import { curry } from 'lodash';
import type MediaUploading from 'media/MediaUploading';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { changeModal, toggleFarmChat } from 'system/ui-state';
import { selectUserId } from 'users/user-state';
import { hasOwnProperty } from 'utils/hasOwnProperty';
import { v4 } from 'uuid';

import FarmChatInvite from './FarmChatInvite';

import './FarmChatPanel.scss';

interface FarmChatPanelProps {
    farm: Farm;
    farmUsers: FarmUser[];
    myUserId: number;
    messages: List<ChatMessage>;
    messagesFinished: boolean;
    lastReadUuid?: string;
    writeChatMessage: typeof writeChatMessage;
    filesUploading: List<MediaUploading>;
    uploadFile: typeof uploadFile;
    writeLastRead: typeof writeLastRead;
    toggleHistory: typeof toggleHistory;
    changeModal: typeof changeModal;
    toggleFarmChat: typeof toggleFarmChat;
    setPreviousScrollPos: SingleParamVoidFunction<number | null>;
    previousScrollPos?: number;
    t: TFunction;
}

class FarmChatPanel extends PureComponent<FarmChatPanelProps> {
    private scrollingPane: HTMLDivElement | null;

    constructor(props: FarmChatPanelProps) {
        super(props);
        this.handleAddComment = this.handleAddComment.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleDocClick = this.handleDocClick.bind(this);
    }
    render() {
        const { changeModal, toggleFarmChat, t } = this.props;
        return (
            <div data-farmchat className="farm-chat-panel scrollable">
                <div className="top-arrow" />
                <div className="non-scrolling chat-header">
                    <Button
                        className="invite-link"
                        small
                        onClick={(e) => {
                            e.preventDefault();
                            changeModal({
                                name: 'settings',
                                props: {
                                    initialSubTab: 'farm-users',
                                },
                            });
                        }}
                    >
                        {t('walkthrough_invite_action')}
                    </Button>
                    <Button
                        className="close-link"
                        variant="subtle"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleFarmChat();
                        }}
                    >
                        {icon('close')}
                    </Button>
                    <h3>{t('farm_chat_title')}</h3>
                </div>
                {this.renderChat()}
            </div>
        );
    }

    renderChat() {
        const {
            farm,
            messages,
            messagesFinished,
            filesUploading,
            changeModal,
            setPreviousScrollPos,
        } = this.props;

        const chatLog = createFarmChatLog(
            messages,
            curry(getChatImageUrl)(configService)(farm),
            curry(getChatFileUrl)(configService)(farm),
            (mediaUrl) => {
                changeModal({
                    name: 'chat-media',
                    props: { chatMediaUrl: mediaUrl },
                });
                this.scrollingPane && setPreviousScrollPos(this.scrollingPane.scrollTop);
            }
        );

        return [
            chatLog.dayChatGroups.size === 0 && <FarmChatInvite key="farm-chat-invite" />,
            <div
                className="scrolling"
                ref={(pane) => {
                    this.scrollingPane = pane;
                }}
                onScroll={this.handleScroll}
                key="discussion-list"
            >
                {!messagesFinished && <div className="loading-history-container" />}
                <DiscussionList chatLog={chatLog} />
            </div>,
            <div className="non-scrolling" key="comment-box">
                <CommentBox
                    onAddComment={this.handleAddComment}
                    addCommentPending={false}
                    addCommentError={false}
                    filesUploading={filesUploading}
                    onUploadFile={this.handleUploadFile}
                />
            </div>,
        ];
    }

    componentDidMount() {
        const { messages, previousScrollPos, setPreviousScrollPos } = this.props;
        if (messages && messages.size > 0) {
            this.writeLastRead();

            if (this.scrollingPane) {
                if (previousScrollPos) {
                    this.scrollingPane.scrollTop = previousScrollPos;
                } else {
                    this.scrollingPane.scrollTop = this.scrollingPane.scrollHeight;
                }
            }
        }
        if (previousScrollPos !== null) {
            setPreviousScrollPos(null);
        }

        document.addEventListener('click', this.handleDocClick, { capture: true });
    }

    componentDidUpdate(prevProps) {
        const { messages } = this.props;
        if (!messages || !messages.size) {
            return;
        }

        this.writeLastRead();

        // Scroll to the bottom if there's a new message or the user is discovering chat for the
        // first time.
        if (this.scrollingPane && !messages.equals(prevProps.messages)) {
            this.scrollingPane.scrollTop = this.scrollingPane.scrollHeight;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocClick, { capture: true });
    }

    handleDocClick(e: MouseEvent) {
        const { toggleFarmChat } = this.props;
        if (
            e.composedPath().filter((el: HTMLElement) => hasOwnProperty(el.dataset, 'farmchat'))
                .length < 1
        ) {
            toggleFarmChat();
        }
    }

    writeLastRead() {
        const { farm, myUserId, messages, lastReadUuid, writeLastRead } = this.props;
        if (lastReadUuid !== messages.last(ChatMessage()).uuid) {
            // A new message arrived while the panel was open, mark as read
            writeLastRead(farm.uuid, myUserId, messages.last());
        }
    }

    handleAddComment(comment) {
        const { farm, writeChatMessage, myUserId } = this.props;
        writeChatMessage({
            farmUuid: farm.uuid,
            message: ChatMessage({
                uuid: v4(),
                createdByUserId: myUserId,
                createdDate: new Date(),
                type: 'text',
                text: comment,
            }),
        });
    }

    handleUploadFile(file) {
        const { farm, myUserId, uploadFile } = this.props;
        uploadFile(
            farm.uuid,
            ChatMessage({
                uuid: v4(),
                createdByUserId: myUserId,
                createdDate: new Date(),
                type: 'image',
            }),
            file
        );
    }

    handleScroll() {
        // When the user scrolls to the top of messages switch to history
        const { messagesFinished, toggleHistory } = this.props;
        if (this.scrollingPane && this.scrollingPane.scrollTop < 20 && !messagesFinished) {
            toggleHistory();
        }
    }
}

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        farmUsers: state.farmUsersState.farmUsers || List<FarmUser>(),
        myUserId: selectUserId(state),
        messages: List(state.chatState.messages),
        messagesFinished: state.chatState.messagesFinished,
        lastReadUuid: state.chatState.lastReadUuid || undefined,
        filesUploading: List(state.chatState.filesUploading),
        previousScrollPos: state.chatState.previousScrollPos,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                writeChatMessage,
                uploadFile,
                writeLastRead,
                toggleHistory,
                changeModal,
                toggleFarmChat,
                setPreviousScrollPos,
            },
            dispatch
        )
)(withTranslation()(FarmChatPanel));
