import OnboardingTooltip from 'onboarding/OnboardingTooltip';

interface InputOnboardingProps {
    onSkip: VoidFunction;
}

const InputOnboarding = ({ onSkip }: InputOnboardingProps) => {
    return (
        <OnboardingTooltip
            style={{ top: 450, left: 680 }}
            className="OperationOnboarding"
            onSkip={onSkip}
            link="https://support.fieldmargin.com/en/articles/3448689-adding-field-job-inputs"
        >
            <p className="mb-2">
                Try adding inputs
                <br />
                <span className="text-sm">
                    Add seeds, spray, fertilisers or pesticides from the pesticide database.
                </span>
            </p>
        </OnboardingTooltip>
    );
};

export default InputOnboarding;
