import type { Activity } from 'activity/activity-helper';
import { isTodo } from 'activity/activity-helper';
import type { List } from 'immutable';

export const filterActivitiesByTodo = (
    isTodoActive: boolean,
    activities: List<Activity>
): List<Activity> => {
    return isTodoActive ? activities.filter((activity) => isTodo(activity)) : activities;
};
