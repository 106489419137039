import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { GeoPolygon } from '@fieldmargin/webapp-geo';
import { GeoFeature } from '@fieldmargin/webapp-geo';
import { deserializeLngLat, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { useGetJDFarmsQuery, useLazyGetJDFieldsQuery } from '@notes-web/api';
import { skipToken } from '@reduxjs/toolkit/query';
import { ButtonBase } from 'components/button-base';
import { Checkbox } from 'components/checkbox';
import { SidebarCard } from 'components/sidebar-card';
import type { WriteFieldProps } from 'fields/Field';
import { createFields } from 'fields/fields-api';
import { setFields } from 'fields/fields-state';
import { usePageParams } from 'hooks/usePageParamas';
import { List } from 'immutable';
import { useAppDispatch } from 'system/store';

import { Sidebar } from '../Sidebar';
import { Spinner } from '../spinner';

import { JohnDeereSelect } from './JohnDeereSelect';

import styles from './JohnDeereImport.module.css';

function JohnDeereImport() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { farmUuid } = usePageParams();
    const { data: farms, isLoading: isLoadingFarms } = useGetJDFarmsQuery(
        farmUuid ? { farmUuid } : skipToken
    );
    const [
        triggerJDFields,
        { data: fields, isLoading: isLoadingFields, isFetching: isFetchingFields },
    ] = useLazyGetJDFieldsQuery();

    const [selectedFarm, setSelectedFarm] = useState<NonNullable<typeof farms>[number] | null>(
        null
    );
    const [selectedFieldIds, setSelectedFieldIds] = useState<string[] | undefined>(undefined);

    const selectedFields = fields
        ?.filter((field) => selectedFieldIds?.includes(field.id))
        .map<WriteFieldProps>((field) => ({
            name: field.name,
            fieldId: '',
            geoJson: GeoFeatureCollection({
                features: List.of(
                    GeoFeature({
                        geometry: deserializeLngLat(field.boundary) as GeoPolygon,
                    })
                ),
            }),
        }));

    const hasSelectedFields = !selectedFields?.length;

    const onItem = (item: typeof selectedFarm) => {
        setSelectedFarm(item);
        setSelectedFieldIds(undefined);
    };

    const onConnect = () => {
        if (!farmUuid || !selectedFarm) return;

        triggerJDFields({
            farmUuid,
            jdFarmId: selectedFarm.id,
        });
    };

    const onImport = async () => {
        if (!farmUuid || !selectedFarm || !selectedFieldIds || hasSelectedFields) return;

        const fields = await createFields(farmUuid, selectedFields);
        dispatch(setFields(fields));

        navigate('../../fields');
    };

    const onSelectAll = () => {
        const allFieldsIds = fields?.map((field) => field.id);
        setSelectedFieldIds(allFieldsIds);
    };

    const onDeselectAll = () => {
        setSelectedFieldIds([]);
    };

    const selectedItem = isLoadingFarms ? t('loading') : (selectedFarm?.name ?? t('select'));
    const onPress = selectedFieldIds ? onImport : onConnect;

    const buttonDisabled = !selectedFarm || (fields && !selectedFieldIds);

    const buttonLabel = fields && selectedFields ? t('import_fields') : t('connect');

    return (
        <Fragment>
            <Sidebar.Card>
                <Sidebar.Heading>{t('jd_connect_org_heading')}</Sidebar.Heading>
            </Sidebar.Card>

            <Sidebar.Card>
                <div className={styles.selectGroup}>
                    <strong>{t('farm')}</strong>

                    <JohnDeereSelect
                        key={selectedItem}
                        label={selectedItem}
                        data={farms}
                        onSelect={({ item }) => onItem(item)}
                    />
                </div>
            </Sidebar.Card>

            {(!fields?.length && isLoadingFields) || isFetchingFields ? (
                <SidebarCard.Root className={styles.loadingCard}>
                    <Spinner size={18} color="var(--color-text-450)" />
                </SidebarCard.Root>
            ) : (
                !!fields?.length && (
                    <SidebarCard.Root className={styles.sidebar}>
                        <Checkbox.Group value={selectedFieldIds} onChange={setSelectedFieldIds}>
                            {fields.map((field) => (
                                <Checkbox.Input key={field.id} value={field.id}>
                                    {field.name}
                                </Checkbox.Input>
                            ))}
                        </Checkbox.Group>
                        <div className={styles.buttonsGroup}>
                            <ButtonBase.Root variant="outline-primary" onPress={onSelectAll}>
                                {t('select_all')}
                            </ButtonBase.Root>
                            <ButtonBase.Root
                                variant="outline-primary"
                                onPress={onDeselectAll}
                                isDisabled={!selectedFieldIds?.length}
                            >
                                {t('label_deselect_all')}
                            </ButtonBase.Root>
                        </div>
                    </SidebarCard.Root>
                )
            )}

            <Sidebar.Card>
                <ButtonBase.Root variant="primary" isDisabled={buttonDisabled} onPress={onPress}>
                    {isLoadingFields ? (
                        <ButtonBase.Icon>
                            <Spinner size={18} color="rgba(255, 255, 255, 1)" />
                        </ButtonBase.Icon>
                    ) : (
                        buttonLabel
                    )}
                </ButtonBase.Root>
            </Sidebar.Card>
        </Fragment>
    );
}

export { JohnDeereImport };
