import SidebarModule from 'sidebar/modules/SidebarModule';
import TitleText from 'sidebar/modules/title/TitleText';

interface NewOperationTitleProps {
    field: string;
    label: string;
    className?: string;
    onChange?: (value: string | undefined) => void;
    placeholder?: string;
}

const NewOperationTitle = ({
    field,
    label,
    className,
    onChange,
    placeholder,
}: NewOperationTitleProps) => {
    return (
        <>
            <SidebarModule className="Title">
                <h4 className="mb-0">{label}</h4>
            </SidebarModule>
            <SidebarModule className="Title" editing={true}>
                <TitleText
                    field={field}
                    className={className}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            </SidebarModule>
        </>
    );
};

export default NewOperationTitle;
