import { analytics } from '@notes-web/analytics';
import { useOperationDetailsActions } from 'hooks/actions';
import { useOperationDetailsSelector } from 'hooks/selectors';
import { getInputTypeForOperation } from 'inputs/Input';
import { InputType } from 'inputs/InputType';
import { maybeSetOf } from 'lib/immutil';
import type FullOperation from 'operations/FullOperation';
import InputsSelector from 'operations/sidebar/recordings/InputsSelector';

import { useSaveRecording } from './operation-recording-utils';

interface EditableInputsSelectorProps {
    fullOperation: FullOperation;
    totalArea: number;
}

const EditableInputsSelector = ({ fullOperation, totalArea }: EditableInputsSelectorProps) => {
    analytics.track('add_input', {
        farmUuid: fullOperation.summary.farmUuid,
        jobUuid: fullOperation.uuid,
    });

    const { handleToggleAttachingRecordings, handleToggleAttachingFuel } =
        useOperationDetailsActions();
    const { isAttachingRecordings } = useOperationDetailsSelector();
    const { handleSaveRecording, handleSaveNewInputAndRecording } = useSaveRecording(fullOperation);

    const inputTypeFilter = isAttachingRecordings
        ? getInputTypeForOperation(fullOperation.summary.type)
        : InputType.FUEL;

    const onBack = isAttachingRecordings
        ? handleToggleAttachingRecordings
        : handleToggleAttachingFuel;

    return (
        <InputsSelector
            onSaveNewRecording={handleSaveRecording}
            onSaveNewInputAndRecording={handleSaveNewInputAndRecording}
            onBack={onBack}
            totalArea={totalArea}
            initialInputTypeFilter={maybeSetOf(inputTypeFilter)}
        />
    );
};

export default EditableInputsSelector;
