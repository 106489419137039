import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { clone } from '@fieldmargin/webapp-geo';
import { Promise } from 'bluebird';
import { List } from 'immutable';
import { arrayToMapWithUuid, getUuid, mapSet } from 'lib/fp-helpers';
import { arrayToList } from 'lib/immutil';
import type Note from 'notes/Note';
import { addFieldsToNote, removeFieldsFromNote } from 'notes/notes-api_new';
import type FullOperation from 'operations/FullOperation';
import { groupBy, prop } from 'ramda';

import type SubField from './sub-fields/SubField';
import type Field from './Field';
import { getFieldArea, getYearFieldUsageUuid, mergeSubFieldAndWriteProps } from './Field';
import { createSubField, deleteField, updateSubField } from './fields-api';

/**
 * Takes the given field and turns it into a field that can be used for a sub field.
 */
export const copyFieldForSubField = (field: Field, name: string, year: number) =>
    field.withMutations((transField) => {
        transField.set('name', name);
        transField.set('uuid', '');
        transField.set('version', -1);
        transField.set('parentUuid', field.parentUuid ? field.parentUuid : field.uuid);
        transField.set('year', year);
        transField.set('yearFieldUsages', new Map());
        if (field.geoJson) {
            transField.setIn(['geoJson'], clone(field.geoJson));
        }
    });

/**
 * Takes a field that was a sub-field in a previous year and converts it to a sub-field for the
 * new year. Note this does not change the parent of the sub-field.
 */
export const copyPreviousSubFieldField = (field: Field, newYear: number, copyUsages: boolean) =>
    field.withMutations((transField) => {
        const currentYear = transField.year;
        transField.set('uuid', '');
        transField.set('year', newYear);
        transField.set('version', -1);

        if (copyUsages) {
            const previousUsage =
                currentYear !== undefined
                    ? transField.yearFieldUsages.get(currentYear.toString())
                    : undefined;
            if (previousUsage) {
                transField.set(
                    'yearFieldUsages',
                    mapSet(transField.yearFieldUsages, newYear.toString(), previousUsage)
                );
            }
        }
    });

/**
 * Save sub fields to the server and return a new list of sub fields with the `field` property
 * updated as the saved version.
 */
export const createSubFields = async (farmUuid: string, subFields: List<SubField>) => {
    return Promise.all(
        subFields.map((subField) => {
            const year = subField.field.year ?? new Date().getFullYear();
            return createSubField(farmUuid, {
                name: subField.field.name,
                geoJson: subField.field.geoJson as GeoFeatureCollection,
                year,
                parentUuid: subField.field.parentUuid as string,
                fieldUsage: { uuid: getYearFieldUsageUuid(year, subField.field) },
            }).then((uuid) => subField.setIn(['field', 'uuid'], uuid));
        })
    ).then(arrayToList);
};

/**
 * Syncs sub fields with the server.
 * Sub fields are either created, updated or deleted.
 */
export const syncSubFields = async (
    farmUuid: string,
    existingSubFieldFields: List<Field>,
    subFields: List<SubField>
) => {
    const savingSubFieldUuids = subFields.map((subField) => subField.field.uuid);

    const { toCreate, toUpdate } = groupBy(
        (subField) => (subField.field.uuid !== '' ? 'toUpdate' : 'toCreate'),
        subFields.toArray()
    );
    const toDelete = existingSubFieldFields.filter(
        (field) => !savingSubFieldUuids.includes(field.uuid)
    );

    const created =
        toCreate !== undefined
            ? (await createSubFields(farmUuid, List(toCreate))).toArray().map(prop('field'))
            : [];
    const updated =
        toUpdate !== undefined
            ? await Promise.all(
                  toUpdate.map((subField) =>
                      updateSubField(farmUuid, subField.field.uuid, {
                          name: subField.field.name,
                          geoJson: subField.field.geoJson as GeoFeatureCollection,
                          year: subField.field.year,
                          parentUuid: subField.field.parentUuid,
                          fieldUsage: {
                              uuid: getYearFieldUsageUuid(
                                  subField.field.year as number,
                                  subField.field
                              ),
                          },
                      }).then((dto) => mergeSubFieldAndWriteProps(subField.field, dto))
                  )
              )
            : [];
    const deleted = await Promise.all(toDelete.map((field) => deleteField(farmUuid, field))).map(
        getUuid
    );
    return { updated: created.concat(updated), deleted };
};

/**
 * Attach the sub fields to the operation and remove the parent field association.
 */
export const copySubFieldsToOperation = (
    parentField: Field,
    subFields: List<SubField>,
    fullOperation: FullOperation
) => {
    const newOperationFields = subFields.map((subField) => ({
        fieldUuid: subField.field.uuid,
        areaSqm: getFieldArea(subField.field),
    }));
    const parentOpField = fullOperation.fields?.find(
        (opField) => opField.fieldUuid === parentField.uuid
    );
    const withCompletedData = parentOpField
        ? newOperationFields.map((opField) => ({
              ...opField,
              completedDate: parentOpField.completedDate,
              completedByUserId: parentOpField.completedByUserId,
          }))
        : newOperationFields;

    return fullOperation.update('fields', (fields) =>
        fields
            ? fields.filter((f) => f.fieldUuid !== parentField.uuid).concat(withCompletedData)
            : fields
    );
};

/**
 * Attach the sub fields to the notes and remove the parent field association.
 */
export const copySubFieldsToNotes = (
    farmUuid: string,
    year: number,
    parentField: Field,
    subFields: List<SubField>,
    notes: Map<string, Note>
) => {
    const subFieldUuids = subFields.map((subField) => subField.field.uuid).toArray();
    return Promise.all(
        [...notes].map(([, note]) =>
            Promise.all([
                removeFieldsFromNote(farmUuid, year, note.uuid, [parentField.uuid]),
                addFieldsToNote(farmUuid, year, note.uuid, subFieldUuids),
            ]).then(() =>
                note.update('fieldUuids', (fieldUuids) =>
                    fieldUuids
                        .concat(subFieldUuids)
                        .filter((fieldUuid) => parentField.uuid !== fieldUuid)
                )
            )
        )
    ).then(arrayToMapWithUuid);
};

/**
 * Copy field usages from the source year to the destination year, ignoring sub fields.
 */
export const copyPreviousYearUsages = (
    fields: List<Field>,
    sourceYear: number,
    destinationYear: number
) =>
    // Remove any fields that have a usage for the current year; we don't want to overwrite data.
    // Also remove sub fields - they won't apply to the destination year.
    fields
        .filter(
            (field) =>
                field.yearFieldUsages.get(sourceYear.toString()) !== undefined &&
                field.year === undefined &&
                field.parentUuid === undefined
        )
        .map((field) => {
            const previousUsage = field.yearFieldUsages.get(sourceYear.toString());
            if (previousUsage !== undefined) {
                return field.set(
                    'yearFieldUsages',
                    mapSet(field.yearFieldUsages, destinationYear.toString(), previousUsage)
                );
            }
            return field;
        });
