import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import type Herd from 'herd/Herd';
import SidebarModule from 'sidebar/modules/SidebarModule';
import RememberingLinkButton from 'view/RememberingLinkButton';

import HerdAddNote from './HerdAddNotes';

interface HerdActionsProps {
    farm: Farm;
    herd: Herd;
}

const HerdActions = ({ farm, herd }: HerdActionsProps) => {
    const { t } = useTranslation();
    return (
        <SidebarModule className="flex flex-wrap gap-5">
            <HerdAddNote farm={farm} herd={herd} />
            <RememberingLinkButton to={`/farms/${farm.uuid}/livestock/${herd.uuid}/split`}>
                {t('livestock_split')}
            </RememberingLinkButton>
            <RememberingLinkButton to={`/farms/${farm.uuid}/livestock/${herd.uuid}/merge`}>
                {t('merge_herds')}
            </RememberingLinkButton>
        </SidebarModule>
    );
};

export default HerdActions;
