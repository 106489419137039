import { selectField } from 'fields/fields-selectors';
import { usePageParams } from 'hooks/usePageParamas';
import { useAppSelector } from 'system/store';

const useCurrentField = () => {
    const { fieldUuid } = usePageParams();

    const currentField = useAppSelector((state) => selectField(state, fieldUuid ?? ''));

    return { currentField };
};

export { useCurrentField };
