import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { List } from 'immutable';
import { compareString } from 'lib/util/text';

export interface RPAState {
    features?: List<GeoFeature>;
    selected: List<string>;
    ignored: List<string>;
    failed: List<string>;
    highlighted?: string;
}

export const rpaReducer = (state: RPAState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case 'setFeatures':
            return {
                features: action.payload.sort((a: GeoFeature, b: GeoFeature) =>
                    compareString(
                        a.properties.get('label') as string,
                        b.properties.get('label') as string
                    )
                ),
                selected: action.payload.map((geoFeature: GeoFeature) => geoFeature.id.toString()),
                ignored: List(),
                failed: List(),
            };
        case 'featureClick':
            if (state.features) {
                const selectedFeatureId = state.selected.find((id) => id === action.payload);
                if (selectedFeatureId) {
                    return {
                        ...state,
                        selected: state.selected.filter((id) => id !== selectedFeatureId),
                        ignored: state.ignored.push(selectedFeatureId),
                    };
                }
                const ignoredFeatureId = state.ignored.find((id) => id === action.payload);
                if (ignoredFeatureId) {
                    return {
                        ...state,
                        selected: state.selected.push(ignoredFeatureId),
                        ignored: state.ignored.filter((id) => id !== ignoredFeatureId),
                    };
                }
                return state;
            }
            return state;
        case 'setFeatureLabel': {
            if (!state.features) {
                return state;
            }
            const features = state.features.map((geoFeature) =>
                geoFeature.id.toString() === action.payload.id
                    ? geoFeature.setIn(['properties', 'label'], action.payload.label)
                    : geoFeature
            );
            return {
                ...state,
                features,
            };
        }

        case 'setFailed':
            if (state.features) {
                const filtered = state.features.filter(
                    (f) => !action.payload.contains(f.properties.get('label'))
                );
                const ids = filtered.map((geoFeature) => geoFeature.id.toString());
                return {
                    features: filtered,
                    selected: ids,
                    ignored: List(),
                    failed: ids,
                };
            }
            return state;
        case 'highlightFeature':
            return { ...state, highlighted: action.payload };
        default:
            throw new Error(`Unknown RPAConnectionDetails action: ${action.type}`);
    }
};
