import type { RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';
import HerdDetails from 'herd/sidebar/details/HerdDetails';
import HerdDelete from 'herd/sidebar/HerdDelete';
import HerdEdit from 'herd/sidebar/HerdEdit';
import HerdLocationDeletion from 'herd/sidebar/HerdLocationDeletion';
import HerdNew from 'herd/sidebar/HerdNew';
import HerdList from 'herd/sidebar/list/HerdList';
import HerdMerge from 'herd/sidebar/merge/HerdMerge';
import HerdMergeDetails from 'herd/sidebar/merge/HerdMergeDetails';
import HerdMergeSelectHerds from 'herd/sidebar/merge/HerdMergeSelectHerds';
import HerdMove from 'herd/sidebar/move/HerdMove';
import HerdSplit from 'herd/sidebar/split/HerdSplit';
import ErrorMessage from 'view/ErrorMessage';

export const livestockRoutes: RouteObject = {
    path: APP_ROUTES.Livestock.Root,

    children: [
        {
            index: true,
            element: <HerdList />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing herds, please refresh the page.
                </ErrorMessage>
            ),
        },

        {
            path: APP_ROUTES.Livestock.New,
            element: <HerdNew />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when creating a herd, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Livestock.Details,
            element: <HerdDetails />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing a herd, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Livestock.Edit,
            element: <HerdEdit />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when editing a herd, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Livestock.Move,
            element: <HerdMove />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when moving a herd, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Livestock.Split,
            element: <HerdSplit />,
        },
        {
            path: APP_ROUTES.Livestock.Merge,
            element: <HerdMerge />,

            children: [
                {
                    index: true,
                    element: <HerdMergeSelectHerds />,
                },
                {
                    path: APP_ROUTES.Livestock.MergeDetails,
                    element: <HerdMergeDetails />,
                },
            ],
        },
        {
            path: APP_ROUTES.Livestock.Delete,
            element: <HerdDelete />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when deleting a herd, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Livestock.DeleteHerdLocation,
            element: <HerdLocationDeletion />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when deleting a herd location, please refresh the page.
                </ErrorMessage>
            ),
        },
    ],
};
