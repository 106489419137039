import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import icon from 'components/icons/icon';
import type Herd from 'herd/Herd';
import { List } from 'immutable';
import { useAppSelector } from 'system/store';

import { selectHerdField } from '../herd-ui-utils';
import HerdPreviewDetails from '../list/HerdPreviewDetails';

interface HerdSelectorHerdProps {
    herd: Herd;
    selected: boolean;
    locked: boolean;
    onChange: (herdUuid: string) => void;
}

const HerdSelectorHerd = ({ herd, selected, locked, onChange }: HerdSelectorHerdProps) => {
    const fields = useAppSelector((state) => state.fieldsState.fields) ?? List();
    const herdField = selectHerdField(fields, herd);

    const handleChange = () => {
        !locked && onChange(herd.uuid);
    };

    return (
        <div className="flex pr-5 bordered-b cursor-pointer">
            <div className="pl-2 pr-1 py-2 relative">
                <Checkbox
                    id={herd.uuid}
                    checked={selected}
                    onChange={handleChange}
                    disabled={locked}
                />
            </div>
            <div key={herd.uuid} className="flex-grow" onClick={handleChange}>
                <HerdPreviewDetails herd={herd} herdField={herdField} isLocked={locked} />
            </div>
            <div className="py-2 text-center" onClick={handleChange}>
                <span className="block mb-2">{herd.size}</span>
                {locked && icon('padlock', 'blue')}
            </div>
        </div>
    );
};

export default HerdSelectorHerd;
