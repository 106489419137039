import { createRoot } from 'react-dom/client';
import { FieldmarginApp } from '@notes-web/fieldmargin';
import { initializeFirebase } from 'lib/firebase/firebase-connection';

// Initialize Firebase
initializeFirebase();

createRoot(document.getElementById('root')!).render(
    // <StrictMode>
    <FieldmarginApp />
    // </StrictMode>
);
