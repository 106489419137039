import type { ComponentProps } from 'react';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import type { AppState } from 'system/store';

type FarmLinkProps = ComponentProps<typeof Link>;

const FarmLink = forwardRef<HTMLAnchorElement, FarmLinkProps>(({ to, children, ...props }, ref) => {
    const farm = useSelector<AppState, Farm>(selectCurrentFarm);

    const toWithFarm = `/farms/${farm.uuid}${to}`;

    return (
        <Link ref={ref} to={toWithFarm} {...props}>
            {children}
        </Link>
    );
});

export default FarmLink;
