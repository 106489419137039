import Modal from 'modal/Modal';
import UpgradeButton from 'upgrade/UpgradeButton';
import UpgradeLink from 'upgrade/UpgradeLink';
import PadlockIcon from 'view/svg/PadlockIcon';

import './FarmUpgradePromptModal.css';

interface TeamLimitPromptModalProps {
    onClose: VoidFunction;
}

const TeamLimitPromptModal = ({ onClose }: TeamLimitPromptModalProps) => {
    return (
        <Modal onClose={onClose}>
            <div className="FarmUpgradePromptModal">
                <PadlockIcon fill="#ce1d18" className="w-12" />
                <h2 className="text-xxl">Upgrade needed</h2>
                <p>
                    Your farm team is <strong>over the number of people</strong> included in the
                    free plan. Some users will have their access restricted.
                </p>
                <p>
                    <UpgradeLink /> for unlimited team members.
                </p>
                <UpgradeButton />
            </div>
        </Modal>
    );
};

export default TeamLimitPromptModal;
