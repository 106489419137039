import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import c from 'classnames';
import { getFarmUserName } from 'lib/util/names';
import { uniqueId } from 'lodash';
import { useAppSelector } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { selectDisplayableFarmUsers } from 'team/farm-users-state';
import ErrorMessage from 'view/ErrorMessage';
import FarmUserCombobox from 'view/FarmUserCombobox';

interface FarmUserFormFieldProps {
    field: string;
    label?: ReactNode;
    validate?: (v: string) => any;
    className?: string;
    inputClassName?: string;
    onChange?: SingleParamVoidFunction<string>;
}

const FarmUserFormField = ({
    field,
    label,
    validate,
    className,
    inputClassName,
    onChange,
}: FarmUserFormFieldProps) => {
    const id = uniqueId('farm-user-search-');
    const farmUsers = useAppSelector(selectDisplayableFarmUsers);

    const watch = useWatch({ name: field });

    const [search, setSearch] = useState('');

    useEffect(() => {
        const user = farmUsers.find((farmUser) => farmUser.id.toString() === watch);
        setSearch(user === undefined ? '' : getFarmUserName(user));
    }, [watch]);

    return (
        <div className={c('form-field', className)}>
            {label && <label htmlFor={id}>{label}</label>}
            <Controller
                name={field}
                rules={{ validate }}
                render={({ field, fieldState }) => (
                    <>
                        <FarmUserCombobox
                            id={id}
                            search={search}
                            onSearch={(value) => {
                                setSearch(value);
                            }}
                            onSelected={(value) => {
                                setSearch(getFarmUserName(value));
                                field.onChange(value.id.toString());
                                onChange && onChange(value.id.toString());
                            }}
                            className={c(inputClassName, { 'has-error': fieldState.error })}
                        />
                        {fieldState.error && (
                            <ErrorMessage>{fieldState.error.message}</ErrorMessage>
                        )}
                    </>
                )}
            />
        </div>
    );
};

export default FarmUserFormField;
