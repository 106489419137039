import { getUserAccount } from 'users/user-state';
import { trackEvent } from 'utils/trackEvent';

export const afterAuth = () => {
    const params = new URLSearchParams(window.location.search);
    const flow = params.get('login_flow');
    if (flow === 'sign_up') {
        getUserAccount().then((user) => {
            if (user !== null) {
                trackEvent('User registered', { email: user.email }, {}, () => {
                    window.location.href = '/';
                });
            }
        });
    } else {
        window.location.href = '/';
    }
};
