import { useFormContext } from 'react-hook-form';
import type { InputReportSummary } from 'inputs/InputReportSummary';
import NumberInputField from 'view/form/hook/NumberInputField';

import { calculateUnitCostFromTotals } from '../input-summary-utils';

import type { EditInputCostsFormValues } from './EditInputCostsForm';

interface EditInputCostTotalFieldProps {
    inputReportSummary: InputReportSummary;
}

const EditInputCostTotalField = ({ inputReportSummary }: EditInputCostTotalFieldProps) => {
    const field = `${inputReportSummary.uuid}.total`;
    const formApi = useFormContext<EditInputCostsFormValues>();

    const handleChange = (value: number) => {
        if (value !== undefined && !isNaN(value)) {
            formApi.setValue(
                `${inputReportSummary.uuid}.unit`,
                calculateUnitCostFromTotals(inputReportSummary.sumTotals, value)
            );
        }
    };

    return (
        <NumberInputField
            field={field}
            inputClassName="h-8 w-24"
            minValue={0}
            disabled={inputReportSummary.sumTotals === 0}
            onChange={handleChange}
        />
    );
};

export default EditInputCostTotalField;
