import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useContext } from 'react';
import c from 'classnames';
import { useDocumentEventListener } from 'lib/hooks';
import { UIContext } from 'system/ui-state';

interface InfoBackgroundProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const InfoBackground = ({ className }: InfoBackgroundProps) => {
    const { toggleInfo } = useContext(UIContext);
    useDocumentEventListener('click', () => {
        toggleInfo();
    });
    return <div className={c('bg-fm-blue bg-opacity-90 absolute z-20 top-0 left-0', className)} />;
};

export default InfoBackground;
