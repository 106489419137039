import { useOutletContext } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { MeasurementUnit } from 'lib/MeasurementUnit';

export interface GrossMarginContextSchema {
    farm: Farm;
    year: number;
    measurementUnit: MeasurementUnit;
}

export const useGrossMarginContext = () => {
    return useOutletContext<GrossMarginContextSchema>();
};
