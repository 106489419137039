import type { ReactNode } from 'react';
import { clsx } from 'clsx';

import './SidebarHeader.css';

interface SidebarHeaderProps {
    children?: ReactNode;
    className?: string;
}

const SidebarHeader = ({ children, className }: SidebarHeaderProps) => {
    return <div className={clsx('SidebarHeader', className)}>{children}</div>;
};

export default SidebarHeader;
