import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { Form } from 'informed';
import type { InputUnit } from 'inputs/Input';
import type Input from 'inputs/Input';
import {
    getActiveInputMicronutrients,
    getActiveInputNutrients,
    inputIsPesticide,
} from 'inputs/Input';
import { InputNutrientsList } from 'inputs/InputNutrients';
import type { MicronutrientFormValues, NutrientFormValues } from 'inputs/Nutrients';
import type { PesticideData } from 'inputs/Pesticide';
import { getPesticideData } from 'inputs/pesticides-api';
import { defaultToZero } from 'lib/fp-helpers';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { getUnitShortName } from 'lib/unit';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import ErrorMessage from 'view/ErrorMessage';
import VerifiedShield from 'view/shield/VerifiedShield';

import RecordingFormFields from './RecordingFormFields';
import RecordingPesticideData from './RecordingPesticideData';

interface RecordingFormProps {
    farm: Farm;
    input: Input;
    totalArea: number;
    measurementUnit: MeasurementUnit;

    onCancel: VoidFunction;
    onSave: (input: Input, rate: number) => PromiseLike<void>;
    onAddAnother: (name: string, type: string, unit: InputUnit, rate: number) => void;
    onDone: VoidFunction;
}

interface FormValues {
    rate: number;
    total: number;
    nutrientRates?: NutrientFormValues;
    micronutrientRates?: MicronutrientFormValues;
    nutrientTotals?: NutrientFormValues;
    micronutrientTotals?: MicronutrientFormValues;
}

const RecordingForm = ({
    farm,
    input,
    totalArea,
    measurementUnit,

    onCancel,
    onSave,
    onAddAnother,
    onDone,
}: RecordingFormProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise(
        ({ rate, setAnother }: { rate: number; setAnother: boolean }) => {
            setAnother ? onAddAnother(input.name, input.type, input.unit, rate) : onDone();
        }
    );

    const isPesticide = inputIsPesticide(input);
    const [pesticideData, setPesticideData] = useState<PesticideData>();
    const { pending: dataPending, setPromise: setDataPromise } =
        usePromise<PesticideData>(setPesticideData);

    useEffect(() => {
        input.pesticideData !== undefined &&
            setDataPromise(getPesticideData(farm.uuid, input.pesticideData.code));
    }, [farm, input]);

    const handleSubmit = (values: FormValues, setAnother: boolean) => {
        if (values.rate >= 0) {
            const rateNum = defaultToZero(values.rate);
            setPromise(onSave(input, rateNum).then(() => ({ rate: rateNum, setAnother })));
        }
    };

    return (
        <Form<FormValues> className="RecordingForm h-content-full overflow-auto">
            {({ formState, formApi }) => (
                <>
                    <SidebarHeader className="px-5">
                        <Button onClick={onCancel} variant="danger-outline" small>
                            {t('cancel')}
                        </Button>
                    </SidebarHeader>
                    <h2 className="p-5 pb-0 mb-0">
                        {input.name} <InputNutrientsList input={input} />{' '}
                        {isPesticide && (
                            <VerifiedShield className="ml-2 inline-block align-middle" />
                        )}
                    </h2>
                    {isPesticide && (
                        <RecordingPesticideData
                            pending={dataPending}
                            pesticideData={pesticideData}
                            unit={getUnitShortName(input.unit)}
                        />
                    )}
                    <RecordingFormFields
                        inputUnit={input.unit}
                        inputType={input.type}
                        totalArea={totalArea}
                        measurementUnit={measurementUnit}
                        nutrients={
                            getActiveInputNutrients(input).toArray().length > 0
                                ? input.nutrients
                                : undefined
                        }
                        micronutrients={
                            getActiveInputMicronutrients(input).toArray().length > 0
                                ? input.micronutrients
                                : undefined
                        }
                    />
                    {error && (
                        <ErrorMessage className="px-5 pt-5">
                            {t('something_went_wrong')}
                        </ErrorMessage>
                    )}
                    <div className="p-5">
                        <SubmitButton
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                formApi.validate();
                                if (!formState.invalid) {
                                    handleSubmit(formState.values, false);
                                }
                            }}
                            disabled={pending}
                            className="mr-4"
                        >
                            {t('input_rate_set')}
                        </SubmitButton>
                        <SubmitButton
                            variant="outline"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                formApi.validate();
                                if (!formState.invalid) {
                                    handleSubmit(formState.values, true);
                                }
                            }}
                            disabled={pending}
                        >
                            {t('input_rate_set_add')}
                        </SubmitButton>
                    </div>
                </>
            )}
        </Form>
    );
};

export default RecordingForm;
