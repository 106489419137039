import type { Reducer } from 'react';
import { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import type { Farm, FarmUser } from '@fieldmargin/webapp-farms';
import { farmHasCropCheck } from '@fieldmargin/webapp-farms';
import { usePrevious, usePromise } from '@fieldmargin/webapp-state';
import { AttachmentType } from 'farm-editing/attachments';
import {
    selectEditingAttachmentsByType,
    selectIsSelectingFields,
    stopEditing,
} from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import type { List } from 'immutable';
import { Set } from 'immutable';
import type { FormApi, FormState } from 'informed';
import { Form } from 'informed';
import { areaAsSqm } from 'lib/geo/maths';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { useOnboarding } from 'onboarding/onboarding-state';
import { getOperationTypeName, OperationType } from 'operations/Operation';
import { saveNewOperation } from 'operations/operations-save-helper';
import { setOperation } from 'operations/operations-state';
import type Recording from 'operations/Recording';
import type Output from 'outputs/Output';
import { pipe } from 'ramda';
import { bindActionCreators } from 'redux';
import CreateHeader from 'sidebar/header/CreateHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import { selectCurrentFarmUser, selectDisplayableFarmUsers } from 'team/farm-users-state';
import {
    useFinishTutorialOnMount,
    useSetTutorialStep,
    useSetTutorialStepOnMount,
} from 'tutorials/tutorial-hooks';
import { TutorialTypes } from 'tutorials/TutorialTypes';
import UnsavedFormChangesChecker from 'unsaved-changes/UnsavedFormChangesChecker';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import { trackEvent } from 'utils/trackEvent';
import Fog from 'view/Fog';

import PesticidesCheckButton from '../details/recording/PesticidesCheckButton';
import BulkOutputCreation from '../outputs/BulkOutputCreation';
import BulkOutputModal from '../outputs/BulkOutputModal';
import NewOutputForm from '../outputs/NewOutputForm';

import NewOperationFieldsSelection from './fields/NewOperationFieldsSelection';
import NewInputsSelector from './recordings/NewInputsSelector';
import NewOperationDueDate from './NewOperationDueDate';
import NewOperationFields from './NewOperationFields';
import NewOperationRecordings from './NewOperationRecordings';
import NewOperationTaggedUsers from './NewOperationTaggedUsers';
import NewOperationTitle from './NewOperationTitle';
import NewOperationType from './NewOperationType';
import NewOperationYear from './NewOperationYear';
import NewOperationYield from './NewOperationYield';
import type { OperationNewState } from './operation-new-state';
import {
    operationNewReducer,
    removeOperationRecording,
    setAttachedOutputUuid,
    setEditingOperationRecordings,
    setIsTitlePlaceHolder,
    setStep,
    setTotalArea,
    toggleAddingOutput,
    toggleAttachingRecordings,
    toggleBulkCreatingOutputs,
    toggleBulkOutputModal,
    updateOperationRecording,
} from './operation-new-state';
import { getNextStep, getStartingStep, isHarvestOperation } from './operation-new-utils';

import styles from './OperationNew.module.css';

interface OperationNewProps {
    farm: Farm;
    year: number;
    setOperation: typeof setOperation;
    myFarmUser: FarmUser;
    editingFieldUuids: Set<string>;
    fields: List<Field>;
    stopEditing: VoidFunction;
    isAttachingFieldsToActivity: boolean;
    areaMeasurementUnit: MeasurementUnit;
    farmUsers: FarmUser[];
    hasAnyOutputs: boolean;
    hasFieldUsages: boolean;
}

export interface OperationNewFormValues {
    // undefined initially
    name: string | undefined;
    operationType?: string;
    fields: {
        [k: string]: {
            workArea: number;
            yieldRate?: number;
            yieldTotal?: number;
        };
    };
    // These two properties are not stored but need to be form values in order to set correct
    // yield rates and totals per field.
    totalYieldRate?: number;
    totalYield?: number;
    recordings: {
        // Recording UUID -> rate as a string for form purposes
        [k: string]: {
            rate: number;
            total: number;
        };
    };
    taggedUsers: Set<FarmUser>;
    dueDate: Date | null;
    year: string;
}

enum OperationNewStep {
    TYPE = 'type',
    OUTPUT = 'output',
    FIELDS = 'fields',
    REST = 'rest',
}

const OperationNew = ({
    farm,
    year,
    setOperation,
    myFarmUser,
    editingFieldUuids,
    fields,
    stopEditing,
    isAttachingFieldsToActivity,
    areaMeasurementUnit,
    farmUsers,
    hasAnyOutputs,
    hasFieldUsages,
}: OperationNewProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { nextOnboarding } = useOnboarding();
    const { pending, error, setPromise } = usePromise();

    const [state, dispatch] = useReducer<Reducer<OperationNewState, any>>(operationNewReducer, {
        isTitlePlaceholder: true,
        showBulkOutputModal: false,
        bulkCreatingOutputs: false,
        editingOperationRecordings: Set<Recording>(),
        isAttachingRecordings: false,
        attachedOutputUuid: undefined,
        addingOutput: false,
        step: getStartingStep(location),
        totalArea: 0,
    });

    const formApi = useRef<FormApi<OperationNewFormValues>>();

    useFinishTutorialOnMount(TutorialTypes.FIELD_JOB);
    useSetTutorialStepOnMount('choose-type');
    const setTutorialStep = useSetTutorialStep();

    useEffect(() => {
        return () => {
            stopEditing();
        };
    }, []);

    useEffect(() => {
        if (state.step === OperationNewStep.REST) {
            nextOnboarding('inputs');
        }
    }, [state.step]);

    const prevAttachingFields = usePrevious<boolean>(isAttachingFieldsToActivity);
    useEffect(() => {
        if (!isAttachingFieldsToActivity && prevAttachingFields) {
            setTutorialStep('add-inputs');
        }
    }, [isAttachingFieldsToActivity]);

    const handleSave = async (values: OperationNewFormValues) => {
        const savePromise = saveNewOperation(
            farm,
            myFarmUser,
            editingFieldUuids,
            fields,
            state.editingOperationRecordings,
            values,
            areaMeasurementUnit,
            state.attachedOutputUuid
        );
        setPromise(savePromise);
        const savedFullOperation = await savePromise;

        setOperation(savedFullOperation);
        trackEvent('Field operation created', { farmUuid: farm.uuid });
        navigate(`/farms/${farm.uuid}/operations/${savedFullOperation.uuid}`, {
            state: { ignoreUnsavedChanges: true },
        });
    };

    const handleOutputCreated = (output: Output) => {
        dispatch(toggleAddingOutput());
        handleOutputChange(output);
    };

    const handleAreaChange = (formState: FormState<OperationNewFormValues>) => {
        if (formState.values && formState.values.fields) {
            const newTotal = Object.values(formState.values.fields).reduce(
                (total, { workArea }) => total + areaAsSqm(workArea, areaMeasurementUnit),
                0
            );
            if (newTotal !== state.totalArea) {
                dispatch(setTotalArea(newTotal));
            }
        } else {
            state.totalArea > 0 && dispatch(setTotalArea(0));
        }
    };

    const setNextStep = () => {
        dispatch(
            setStep(
                getNextStep(
                    state.step,
                    editingFieldUuids,
                    formApi.current?.getValue('operationType')
                )
            )
        );
    };

    const handleOperationTypeChange = (type: OperationType) => {
        if (type !== OperationType.OTHER.toString() && state.isTitlePlaceholder) {
            formApi.current?.setValue('name', getOperationTypeName(OperationType[type]));
        }
        if (isHarvestOperation(type) && !hasAnyOutputs && hasFieldUsages) {
            dispatch(toggleBulkOutputModal());
        }
        setNextStep();
    };

    const handleOutputChange = (output: Output) => {
        dispatch(setAttachedOutputUuid(output.uuid));
        if (state.isTitlePlaceholder) {
            formApi.current?.setValue(
                'name',
                getOperationTypeName(formApi.current?.getValue('operationType')) + ' ' + output.name
            );
        }
        setNextStep();
    };

    return (
        <>
            {state.bulkCreatingOutputs && (
                <BulkOutputCreation onBack={pipe(toggleBulkCreatingOutputs, dispatch)} />
            )}
            {state.showBulkOutputModal && (
                <BulkOutputModal
                    onClose={pipe(toggleBulkOutputModal, dispatch)}
                    onCreate={pipe(toggleBulkCreatingOutputs, dispatch)}
                />
            )}
            {state.isAttachingRecordings && (
                <NewInputsSelector
                    onBack={pipe(toggleAttachingRecordings, dispatch)}
                    totalArea={state.totalArea}
                    editingOperationRecordings={state.editingOperationRecordings}
                    setEditingOperationRecordings={pipe(setEditingOperationRecordings, dispatch)}
                    operationType={formApi.current?.getValue('operationType')}
                />
            )}
            {state.addingOutput && <NewOutputForm onDone={handleOutputCreated} />}
            {isAttachingFieldsToActivity && <NewOperationFieldsSelection />}
            <div className={styles.operationNew}>
                <Form<OperationNewFormValues>
                    onSubmit={handleSave}
                    initialValues={{
                        name: undefined,
                        operationType: location.state?.operationType,
                        fields: {},
                        recordings: {},
                        taggedUsers: Set([myFarmUser]),
                        dueDate: null,
                        year: year.toString(),
                    }}
                    onChange={handleAreaChange}
                    className="scrollable"
                    getApi={(api) => (formApi.current = api)}
                >
                    {({ formState }) => (
                        <UnsavedFormChangesChecker
                            copy={[t('field_job_discard_1'), t('field_job_discard_2')]}
                            initialValues={{
                                operationType: OperationType.OTHER.toString(),
                                taggedUsers: Set(),
                                dueDate: null,
                                year: new Date().getFullYear().toString(),
                            }}
                        >
                            <div className="non-scrolling">
                                <CreateHeader
                                    isSaving={pending}
                                    backPath={`/farms/${farm.uuid}/notes/`}
                                    backState={{ ignoreUnsavedChanges: true }}
                                />
                                {error && (
                                    <SidebarError
                                        title={t('failed_to_save')}
                                        message={t('something_went_wrong')}
                                    />
                                )}
                            </div>
                            <div className="scrolling">
                                <NewOperationType
                                    field="operationType"
                                    onChange={handleOperationTypeChange}
                                    editingInitially={location.state?.operationType === undefined}
                                />
                                {isHarvestOperation(formState.values.operationType) && (
                                    <NewOperationYield
                                        farm={farm}
                                        attachedOutputUuid={state.attachedOutputUuid}
                                        onSelectOutput={handleOutputChange}
                                        onAddOutput={pipe(toggleAddingOutput, dispatch)}
                                    />
                                )}
                                <div className="relative">
                                    {(state.step === OperationNewStep.TYPE ||
                                        state.step === OperationNewStep.OUTPUT) && <Fog />}
                                    <NewOperationTitle
                                        field="name"
                                        label={t('title')}
                                        className={
                                            state.isTitlePlaceholder ? 'placeholder' : undefined
                                        }
                                        onChange={() => dispatch(setIsTitlePlaceHolder(false))}
                                        placeholder={t('create_operation_title_hint')}
                                    />
                                    <NewOperationFields
                                        onAdd={setNextStep}
                                        operationType={formState.values.operationType}
                                        totalArea={state.totalArea}
                                    />
                                    <div className="relative">
                                        {state.step === OperationNewStep.FIELDS && <Fog />}
                                        {!isHarvestOperation(formState.values.operationType) && (
                                            <>
                                                <NewOperationRecordings
                                                    editingOperationRecordings={
                                                        state.editingOperationRecordings
                                                    }
                                                    onRemoveRecording={pipe(
                                                        removeOperationRecording,
                                                        dispatch
                                                    )}
                                                    onChangeRecording={pipe(
                                                        updateOperationRecording,
                                                        dispatch
                                                    )}
                                                    totalArea={state.totalArea}
                                                    startAttachingRecordings={pipe(
                                                        toggleAttachingRecordings,
                                                        dispatch
                                                    )}
                                                />
                                                {formState.values.operationType ===
                                                    OperationType.SPRAY &&
                                                    farmHasCropCheck(farm) && (
                                                        <SidebarModule
                                                            editing
                                                            className="flex justify-end"
                                                        >
                                                            <PesticidesCheckButton
                                                                fieldUuids={editingFieldUuids}
                                                                recordings={
                                                                    state.editingOperationRecordings
                                                                }
                                                            />
                                                        </SidebarModule>
                                                    )}
                                            </>
                                        )}
                                        <NewOperationTaggedUsers
                                            field="taggedUsers"
                                            farmUsers={farmUsers}
                                        />
                                        <NewOperationDueDate field="dueDate" />
                                        <NewOperationYear field="year" />
                                    </div>
                                </div>
                            </div>
                        </UnsavedFormChangesChecker>
                    )}
                </Form>
            </div>
        </>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        year: state.yearsState.currentYear,
        myFarmUser: selectCurrentFarmUser(state),
        editingFieldUuids: selectEditingAttachmentsByType(state, AttachmentType.FIELD),
        fields: state.fieldsState.fields,
        isAttachingFieldsToActivity: selectIsSelectingFields(state),
        areaMeasurementUnit: selectUserAreaMeasurementUnit(state),
        farmUsers: selectDisplayableFarmUsers(state),
        hasAnyOutputs: state.outputsState.outputs.size > 0,
        hasFieldUsages:
            state.fieldUsageState.fieldUsages !== null &&
            state.fieldUsageState.fieldUsages.size > 0,
    }),
    (dispatch) => bindActionCreators({ setOperation, stopEditing }, dispatch)
)(OperationNew);
