import { useTranslation } from 'react-i18next';
import { dateTypesState, updateFilterByDateTypes } from 'store/slices/notes.slice';
import { useAppDispatch, useAppSelector } from 'system/store';
import type { FilterDateType } from 'types';

import { FilterSelect } from '../FilterSelect';

interface DateType {
    label: string;
    type: FilterDateType;
}

function FilterDateTypeButton({ children }) {
    return <FilterSelect.ButtonChevron>{children}</FilterSelect.ButtonChevron>;
}

function FilterDateTypes() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const dateTypeValues = useAppSelector(dateTypesState);

    const onChangeDateType = (value: Array<FilterDateType>) => {
        dispatch(updateFilterByDateTypes(value));
    };

    const dateTypes: Array<DateType> = [
        { label: t('activity_log_created_date'), type: 'createdDate' },
        { label: t('create_task_due_date_title_set'), type: 'dueDate' },
        { label: t('activity_log_completed_date_title'), type: 'completedDate' },
    ];

    const selectedDateType =
        dateTypes.find((item) => item.type === dateTypeValues[0])?.label ?? t('date_type');
    const selectedDateTypeLabel = `${selectedDateType}${dateTypeValues.length < 2 ? '' : ` (+${dateTypeValues.length - 1})`}`;

    return (
        <FilterSelect.Root>
            <FilterDateTypeButton>{selectedDateTypeLabel}</FilterDateTypeButton>
            <FilterSelect.Popover>
                <FilterSelect.Group value={dateTypeValues} onChange={onChangeDateType}>
                    {dateTypes.map((dateType) => (
                        <FilterSelect.Checkbox key={dateType.type} value={dateType.type}>
                            {dateType.label}
                        </FilterSelect.Checkbox>
                    ))}
                </FilterSelect.Group>
            </FilterSelect.Popover>
        </FilterSelect.Root>
    );
}

export { FilterDateTypes };
