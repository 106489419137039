import type { CSSProperties, ReactNode } from 'react';
import c from 'classnames';

import { useOnboarding } from './onboarding-state';

import './OnboardingTooltip.css';

interface OnboardingTooltipProps {
    children: ReactNode;
    onSkip: VoidFunction;
    skipText?: string;
    onWrapperClick?: VoidFunction;
    link?: string;
    className?: string;
    style?: CSSProperties;
}

const OnboardingTooltip = ({
    children,
    onSkip,
    skipText = 'Skip tutorial',
    onWrapperClick,
    link,
    className,
    style = {},
}: OnboardingTooltipProps) => {
    const { activeStep, skipOnboarding } = useOnboarding();

    const handleWrapperClick = () => {
        onWrapperClick && onWrapperClick();
        activeStep === 'tutorials' && skipOnboarding();
    };
    return (
        <div
            className={c('OnboardingTooltip modal-bg top-0', className)}
            onClick={handleWrapperClick}
        >
            <div className="tooltip" style={style}>
                {children}
                <div className="actions">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onSkip();
                        }}
                    >
                        {skipText}
                    </a>
                    {link && (
                        <a
                            href={link}
                            onClick={(e) => e.stopPropagation()}
                            rel="noopener noreferer noreferrer"
                            target="_blank"
                            className="learn"
                        >
                            What's this?
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};
export default OnboardingTooltip;
