import {
    deserialize,
    deserializeLngLat,
    GeoFeatureCollection,
    Projection,
} from '@fieldmargin/webapp-geo';
import type { FeatureCollection } from 'geojson';
import { Record } from 'immutable';
import type { Sizes } from 'lib/geo/shape-size-cacher';
import { getSizesOfGeometry } from 'lib/geo/shape-size-cacher';
import { trackEvent } from 'utils/trackEvent';

class Feature extends Record({
    uuid: '',
    name: '',
    version: -1,
    geoJson: GeoFeatureCollection(),
    createdByUserId: -1,
    /** @deprecated use featuretypeUuid instead */
    featureTypeId: -1,
    featureTypeUuid: '',
}) {}
export default Feature;

export interface FeatureDTO {
    uuid: string;
    name: string;
    version: number;
    createdByUserId?: number;
    /** @deprecated use featuretypeUuid instead */
    featureTypeId: number;
    featureTypeUuid: string;
    geoJson: FeatureCollection;
}

export const deserializeFeature = (json: FeatureDTO): Feature => {
    return new Feature({
        uuid: json.uuid,
        name: json.name,
        version: json.version,
        geoJson: deserialize(json.geoJson, Projection.LNG_LAT) as GeoFeatureCollection,
        createdByUserId: json.createdByUserId,
        featureTypeId: json.featureTypeId,
        featureTypeUuid: json.featureTypeUuid,
    });
};

export const getNiceFeatureArea = (feature: Feature): Sizes =>
    getSizesOfGeometry(feature.uuid, feature.geoJson);

export const trackFeatureChange = (msg: string) => (feature: Feature) => {
    trackEvent(`Feature ${msg}`, {
        featureUuid: feature.uuid,
    });
    return feature;
};

export const mergeFeatureAndWriteProps = (
    feature: Feature,
    update: Partial<FeatureDTO>
): Feature => {
    const updatedFeature = feature.withMutations((transFeature) => {
        if (update.name) {
            transFeature.set('name', update.name);
        }
        if (update.featureTypeUuid) {
            transFeature.set('featureTypeUuid', update.featureTypeUuid);
        }
        if (update.version) {
            transFeature.set('version', update.version);
        }
        if (update.geoJson) {
            transFeature.set('geoJson', deserializeLngLat(update.geoJson) as GeoFeatureCollection);
        }
    });
    return updatedFeature;
};
