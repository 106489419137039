import type { ComponentProps } from 'react';

function ChevronIcon({ fill = 'currentColor', ...props }: ComponentProps<'svg'>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                strokeLinejoin: 'round',
                strokeMiterlimit: 2,
            }}
            viewBox="0 0 7 12"
            {...props}
        >
            <path
                d="m2.071 6.999 4.288 4.288a.676.676 0 0 1 .211.51.727.727 0 0 1-.226.511.7.7 0 0 1-.51.219.7.7 0 0 1-.511-.219L.831 7.831a1.18 1.18 0 0 1-.35-.832 1.18 1.18 0 0 1 .35-.831l4.492-4.491a.683.683 0 0 1 .518-.212c.199.005.372.08.518.226a.703.703 0 0 1 .218.511.702.702 0 0 1-.218.51L2.071 6.999Z"
                fill={fill}
            />
        </svg>
    );
}
export { ChevronIcon };
