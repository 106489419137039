import type { ComponentProps } from 'react';

function CalendarIcon({ fill = 'currentColor', ...props }: ComponentProps<'svg'>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 16" {...props}>
            <path
                fill={fill}
                d="M1.75 15.5c-.413 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 .25 14V3.5c0-.413.147-.766.44-1.06.294-.293.647-.44 1.06-.44h.75V.5H4V2h6V.5h1.5V2h.75c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06V14c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44H1.75Zm0-1.5h10.5V6.5H1.75V14Zm0-9h10.5V3.5H1.75V5ZM7 9.5a.726.726 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.39.216-.534A.726.726 0 0 1 7 8c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.39-.216.534A.726.726 0 0 1 7 9.5Zm-3 0a.726.726 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.39.216-.534A.726.726 0 0 1 4 8c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.39-.216.534A.726.726 0 0 1 4 9.5Zm6 0a.726.726 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.39.216-.534A.726.726 0 0 1 10 8c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.39-.216.534A.726.726 0 0 1 10 9.5Zm-3 3a.726.726 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.39.216-.534A.726.726 0 0 1 7 11c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.39-.216.534A.726.726 0 0 1 7 12.5Zm-3 0a.726.726 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.39.216-.534A.726.726 0 0 1 4 11c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.39-.216.534A.726.726 0 0 1 4 12.5Zm6 0a.726.726 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.39.216-.534A.726.726 0 0 1 10 11c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.39-.216.534A.726.726 0 0 1 10 12.5Z"
            />
        </svg>
    );
}

export { CalendarIcon };
