import type { TiledLayerSpec } from '@fieldmargin/webapp-ol-map';
import { List } from 'immutable';
import { logReselect } from 'lib/util/reselect-util';
import WmsTiles from 'maps/common/WmsTiles';
import XyzTiles from 'maps/common/XyzTiles';
import type MapGroup from 'maps/farm/MapGroup';
import { VegetationMapsVisibility } from 'maps/vegetation/vegetation-maps-state';
import { selectSentinelHubTileSets } from 'maps/vegetation/vegetation-maps-util';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import { VisibilityOptions } from 'system/types';

export interface CustomMapSpec {
    id: string;
    tiles: XyzTiles | WmsTiles;
    opacity: number;
    zIndex: number;
}

const selectSentinelHubMaps = createSelector(
    (state: AppState) => state.vegetationMapsState.visibleSentinelHubMap,
    (state: AppState) => state.vegetationMapsState.visibleStyle,
    selectSentinelHubTileSets,
    (visibleSentinelHubMap, visibleStyle, sentinelHubTileSets): List<CustomMapSpec> => {
        logReselect('selectSentinelHubMaps');
        if (!visibleSentinelHubMap || !sentinelHubTileSets) {
            return List();
        }

        return sentinelHubTileSets.map((tiles, i) => ({
            id: `${visibleSentinelHubMap.id}_${visibleStyle}_${i}`,
            tiles: tiles,
            opacity: 1,
            zIndex: 0,
        }));
    }
);

const selectIntegrationMaps = createSelector(
    (state: AppState) => state.farmEditingState.layerVisibility.visibleMapGroups,
    (state: AppState) => state.farmEditingState.layerVisibility.hiddenMapIntegrations,
    (state: AppState) => state.mapsState.mapGroupsByIntegration,
    (visibleMapGroups, hiddenMapIntegrations, mapGroupsByIntegration): List<CustomMapSpec> => {
        logReselect('selectIntegrationMaps');
        if (!visibleMapGroups || !mapGroupsByIntegration) {
            return List();
        }

        let customMaps = List<CustomMapSpec>();

        visibleMapGroups.forEach((key) => {
            let mapGroup = undefined as MapGroup | undefined;
            const integrationMaps = mapGroupsByIntegration[key.farmIntegrationUuid];
            if (integrationMaps) {
                mapGroup = integrationMaps.find((mg) => mg.uuid === key.mapUuid);
            }

            if (
                key.visibility === VisibilityOptions.OFF ||
                !mapGroup ||
                hiddenMapIntegrations.has(mapGroup.farmIntegrationUuid)
            ) {
                return null;
            }

            if (mapGroup.tiles) {
                customMaps = customMaps.push({
                    id: mapGroup.uuid,
                    tiles: mapGroup.tiles,
                    opacity: key.visibility === VisibilityOptions.ON ? 1 : 0.5,
                    zIndex: 0,
                });
            } else {
                customMaps = customMaps.concat(
                    mapGroup.maps.map((m) => ({
                        id: m.uuid,
                        tiles: m.tiles,
                        opacity: key.visibility === VisibilityOptions.ON ? 1 : 0.5,
                        zIndex: 0,
                    }))
                );
            }
        });

        return customMaps;
    }
);

const selectCustomMaps = createSelector(
    (state: AppState) => state.vegetationMapsState.visibility,
    selectSentinelHubMaps,
    selectIntegrationMaps,
    (vegetationMapsVisibility, sentinelHubMaps, integrationMaps): List<CustomMapSpec> => {
        logReselect('selectCustomMaps');
        let customMaps = List();

        if (vegetationMapsVisibility === VegetationMapsVisibility.ON) {
            customMaps = customMaps.concat(sentinelHubMaps);
        }

        customMaps = customMaps.concat(integrationMaps);

        let i = 0;
        customMaps.forEach((spec) => {
            spec.zIndex = i++;
        });

        return customMaps;
    }
);

export const selectTiledLayers = createSelector(
    selectCustomMaps,
    (customMaps): TiledLayerSpec[] => {
        logReselect('selectTiledLayers');
        if (!customMaps) {
            return [];
        }
        return customMaps
            .map((customMap): TiledLayerSpec => {
                return {
                    id: customMap.id,
                    extent: customMap.tiles.extent,
                    xyzTiles:
                        customMap.tiles instanceof XyzTiles
                            ? {
                                  urlTemplates: customMap.tiles.urlTemplates,
                                  minZoom: customMap.tiles.minZoom,
                                  maxZoom: customMap.tiles.maxZoom,
                              }
                            : undefined,
                    wmsTiles:
                        customMap.tiles instanceof WmsTiles
                            ? {
                                  url: customMap.tiles.url,
                                  params: customMap.tiles.params,
                                  tileGrid: {
                                      minZoom: 15,
                                      maxZoom: 15,
                                      tileSize: [512, 515],
                                  },
                              }
                            : undefined,
                    opacity: customMap.opacity,
                    zIndex: customMap.zIndex,
                };
            })
            .toArray();
    }
);
