import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { getZIndex } from 'components/maps/model/MapLayerIndex';
import type { FieldToolTipControllerProps } from 'components/maps/openlayers/FieldTooltipController';
import { AttachmentType } from 'farm-editing/attachments';
import {
    EditingType,
    selectEditingAttachmentsByType,
    selectIsSelectingFields,
} from 'farm-editing/farm-editing-state';
import type Field from 'fields/Field';
import { getFieldNameWithArea, getYearFieldUsageUuidOrNone, isSubField } from 'fields/Field';
import { selectFieldUsagesWithNotSet } from 'fields/field-usage-selectors';
import {
    selectCurrentYearFieldsWithPreviousUsages,
    selectFieldsWithFullNames,
    selectFilteredFieldsWithParents,
} from 'fields/fields-selectors';
import type FieldUsage from 'fields/FieldUsage';
import type { Set } from 'immutable';
import { List, Map } from 'immutable';
import { DEFAULT_COLOUR } from 'lib/colours';
import { allTrue, anyTrue, uuidMatches } from 'lib/fp-helpers';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { logReselect } from 'lib/util/reselect-util';
import { selectIsVegetationMapShowing } from 'maps/vegetation/vegetation-maps-util';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import { VisibilityOptions } from 'system/types';
import type { ActiveSection } from 'system/url-util';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import { selectCurrentYear } from 'years/years-state';

interface SelectFieldsParams {
    activeSection: ActiveSection;
    selectedFieldUuid?: string;
    dimShapes?: boolean;
}

export interface FieldViewOptions {
    isOnFieldsPage: boolean;
    isSettingBulkUsage: boolean;
    isUsingAutoBoundary: boolean;
    selectedFieldUuid?: string;
    showArchivedFields: boolean;
    fieldsVisibility: VisibilityOptions;
    hiddenFieldUsages: Set<string | null>;
    highlightedGeoFeatureId: string | null;
    dimShapes: boolean;
    isSelectingFields: boolean;
    isVegetationMapShowing: boolean;
}

/**
 * Select data that represents to the current view the user has in the app related to fields.
 */
export const selectFieldViewOptions = createSelector(
    (_: AppState, { activeSection }: SelectFieldsParams) =>
        activeSection.main === 'fields' && activeSection.sub !== 'sub-fields',
    (_: AppState, { activeSection }: SelectFieldsParams) => activeSection.sub === 'usage',
    (state: AppState) => state.farmEditingState.editingType === EditingType.AUTO_BOUNDARY,
    (_: AppState, { selectedFieldUuid }: SelectFieldsParams) => selectedFieldUuid,
    (state: AppState) => state.fieldsState.showArchivedFields,
    (_: AppState, { dimShapes = false }: SelectFieldsParams) => dimShapes,
    (state: AppState) => state.farmEditingState.layerVisibility.fieldsVisibility,
    (state: AppState) => state.farmEditingState.layerVisibility.hiddenFieldUsages,
    (state: AppState) => state.farmEditingState.highlightedGeoFeatureId,
    (state: AppState) => selectIsSelectingFields(state),
    selectIsVegetationMapShowing,
    (
        isOnFieldsPage,
        isSettingBulkUsage,
        isUsingAutoBoundary,
        selectedFieldUuid,
        showArchivedFields,
        dimShapes,
        fieldsVisibility,
        hiddenFieldUsages,
        highlightedGeoFeatureId,
        isSelectingFields,
        isVegetationMapShowing
    ) => ({
        isOnFieldsPage,
        isSettingBulkUsage,
        isUsingAutoBoundary,
        selectedFieldUuid,
        showArchivedFields,
        fieldsVisibility,
        hiddenFieldUsages,
        highlightedGeoFeatureId,
        dimShapes,
        isSelectingFields,
        isVegetationMapShowing,
    })
);

/**
 * Filter out fields that should not be shown on the map.
 * If a user is selecting fields all unarchived fields are returned.
 * No fields are returned if the user has set field visibility to off.
 * Archived fields are included if the show archived fields filter is active.
 * No fields are returned if the user is creating fields using auto boundary.
 * Otherwise fields are returned if they have a boundary & their usage is not hidden.
 */
export const filterValidMapFields = (
    year: number,
    fields: List<Field>,
    viewOptions: FieldViewOptions
) => {
    if (viewOptions.isSelectingFields) {
        const parentUuids = fields.map((f) => f.parentUuid).toSet();
        const filtered = fields.filter((f) => !parentUuids.contains(f.uuid));
        return viewOptions.showArchivedFields ? filtered : filtered.filter((f) => !f.archived);
    }

    if (viewOptions.fieldsVisibility === VisibilityOptions.OFF || viewOptions.isUsingAutoBoundary) {
        return List<Field>();
    }

    const filtered = fields.filter((field) => {
        const hasBoundary = field.geoJson !== null;
        const fieldUsageVisible = !viewOptions.hiddenFieldUsages.contains(
            getYearFieldUsageUuidOrNone(year, field)
        );
        return hasBoundary && fieldUsageVisible;
    });
    return viewOptions.showArchivedFields ? filtered : filtered.filter((f) => !f.archived);
};

/**
 * Select fields that should be shown on the map.
 */
const selectMapFields = createSelector(
    selectCurrentYear,
    selectFilteredFieldsWithParents,
    selectFieldViewOptions,
    filterValidMapFields
);

/**
 * Select map fields and convert their boundaries to a list of GeoFeatures.
 */
const selectMapFieldGeoFeatures = createSelector(
    selectCurrentYear,
    selectMapFields,
    selectFieldViewOptions,
    (state) => state.fieldUsageState.fieldUsages,
    (state) => state.farmEditingState.editingData.id,
    (state) => state.farmEditingState.editingGeoFeatureCollection,
    selectUserAreaMeasurementUnit,
    (
        year,
        mapFields,
        viewOptions,
        fieldUsages,
        editingDataId,
        editingGeoFeatureCollection,
        areaMeasurementUnit
    ): List<GeoFeature> => {
        if (fieldUsages === null) {
            return List<GeoFeature>();
        }

        const parentUuids = mapFields
            .filter((field) => field.parentUuid !== undefined)
            .map((field) => field.parentUuid);

        return mapFields.reduce((geoFeatures, field) => {
            const isEditing = field.uuid === editingDataId && editingGeoFeatureCollection !== null;
            return geoFeatures.concat(
                isEditing
                    ? editingFieldtoGeoFeatures(editingGeoFeatureCollection)
                    : fieldToGeoFeatures(field, {
                          year,
                          areaMeasurementUnit,
                          fieldUsages,
                          hasSubFieldsInYear: parentUuids.contains(field.uuid),
                          viewOptions,
                      })
            );
        }, List<GeoFeature>());
    }
);

/**
 * Select geo features for new fields that are being created.
 * This will return zero geo features if the user is not creating a new field.
 */
const selectNewFieldGeoFeatures = createSelector(
    (state: AppState) => state.farmEditingState.editingGeoFeatureCollection,
    (state: AppState) =>
        state.farmEditingState.editingType === EditingType.FIELD &&
        state.farmEditingState.editingData.id === undefined,
    (editingGeoFeatureCollection, isCreatingNewField) => {
        return editingGeoFeatureCollection !== null && isCreatingNewField
            ? editingFieldtoGeoFeatures(editingGeoFeatureCollection)
            : List<GeoFeature>();
    }
);

/**
 * - Fields are always shown regardless of the active section
 * - Fields without a boundary are not shown
 * - Fields that are archived are not shown
 * - No fields are shown if the user has turned off visibility
 * - If the user has turned off visibility for a particular field usage, fields within that
 *   will not be shown.
 * - If a note or feature is selected or being created/edited, fields will be dimmed
 * - If a or field is selected or being edited or created then the other fields will be dimmed
 *
 * @param state AppState
 * @param { ActiveSection, selectedFieldUuid?, dimShapes}
 */
export const selectFields = createSelector(
    selectMapFieldGeoFeatures,
    selectNewFieldGeoFeatures,
    (visibleFieldGeoFeatures, newFieldGeoFeatures): List<GeoFeature> => {
        logReselect('selectFields');
        return visibleFieldGeoFeatures.concat(newFieldGeoFeatures);
    }
);

/**
 * Select props for the FieldTooltipController.
 */
export const selectFieldTooltipProps = createSelector(
    selectCurrentYear,
    selectFieldViewOptions,
    (state: AppState) => state.farmEditingState.hoveredGeoFeatureId,
    selectFieldsWithFullNames,
    selectFieldUsagesWithNotSet,
    selectCurrentYearFieldsWithPreviousUsages,
    selectUserAreaMeasurementUnit,
    (
        currentYear,
        viewOptions,
        hoveredGeoFeatureId,
        fields,
        fieldUsages,
        previousFieldUsages,
        areaMeasurementUnit
    ) => {
        const props: FieldToolTipControllerProps = {
            field: null,
            isSelectingFieldUsageFields: viewOptions.isSettingBulkUsage,
            areaMeasurementUnit,
            fieldUsages,
            previousUsages: List<FieldUsage>(),
            currentYear,
        };
        if (hoveredGeoFeatureId === null) {
            return props;
        }
        const selectedField = fields.find(
            (field) => field.geoJson?.features.first<GeoFeature>()?.id === hoveredGeoFeatureId
        );

        if (selectedField) {
            props.field = selectedField;
            props.previousUsages = previousFieldUsages.get(selectedField.uuid) as List<FieldUsage>;
        }

        return props;
    }
);

/**
 * Converts a field into a list of geo features.
 * This is a list because the geoJson property of the field is a GeoFeatureCollection.
 * In reality this will be a list of one geo feature because the field only has a single boundary.
 */
export const fieldToGeoFeatures = (
    field: Field,
    opts: {
        year: number;
        areaMeasurementUnit: MeasurementUnit;
        fieldUsages: List<FieldUsage>;
        hasSubFieldsInYear: boolean;
        viewOptions: FieldViewOptions;
    }
) => {
    const fieldUsageUuid = getYearFieldUsageUuidOrNone(opts.year, field);
    const fieldUsageColour = field.locked
        ? DEFAULT_COLOUR
        : (opts.fieldUsages.find(uuidMatches(fieldUsageUuid))?.colour ?? DEFAULT_COLOUR);
    const isSelected = isFieldSelected(
        field,
        opts.viewOptions.selectedFieldUuid,
        opts.hasSubFieldsInYear
    );
    const isDimmed = opts.viewOptions.dimShapes;
    const geoFeatureCollection = field.geoJson ?? GeoFeatureCollection();

    const label =
        !field.locked &&
        shouldShowMapFieldLabel(opts.viewOptions, isSelected, isDimmed, isSubField(field))
            ? getFieldNameWithArea(field, opts.year, opts.areaMeasurementUnit)
            : undefined;

    const isFilled = shouldMapFieldBeFilled(opts.viewOptions, opts.hasSubFieldsInYear);
    const hasStroke = shouldMapFieldHaveStroke(
        isFilled,
        opts.viewOptions.fieldsVisibility,
        opts.viewOptions.isVegetationMapShowing
    );

    return geoFeatureCollection.features.map((geoFeature) => {
        const isHighlighted = geoFeature.id === opts.viewOptions.highlightedGeoFeatureId;
        return geoFeature.set(
            'properties',
            Map({
                type: 'field',
                editable: false,
                colour: fieldUsageColour,
                pointScale: isHighlighted ? 9 : 6,
                strokeWeight: isHighlighted ? 3 : 2,
                strokeOpacity: hasStroke ? (isDimmed ? 0.5 : 1) : 0,
                fillOpacity: isFilled ? (isDimmed ? 0.2 : 0.4) : 0,
                zIndex: getZIndex(
                    opts.viewOptions.isOnFieldsPage,
                    'field',
                    geoFeature.geometry.type
                ),
                label,
            })
        );
    });
};

/**
 * A field is selected if:
 * - The selected field uuid matches the field uuid and the field does not have sub fields
 *   in the current year.
 * - Or there is a selected parent and it matches the parent of the field
 */
export const isFieldSelected = (
    field: Field,
    selectedFieldUuid: string | undefined,
    hasSubFieldsInYear: boolean
) =>
    anyTrue([
        allTrue([selectedFieldUuid === field.uuid, !hasSubFieldsInYear]),
        allTrue([selectedFieldUuid !== undefined, selectedFieldUuid === field.parentUuid]),
    ]);

/**
 * Show label if:
 * - field is selected
 * - user is selecting fields and the field is not a sub-field
 * - user is setting bulk field usages
 * - user is on the fields page, hasn't selected a field and the field is not a sub field
 */
export const shouldShowMapFieldLabel = (
    viewOptions: FieldViewOptions,
    isSelected: boolean,
    isDimmed: boolean,
    isSubField: boolean
) =>
    anyTrue([
        isSelected,
        viewOptions.isSelectingFields,
        viewOptions.isSettingBulkUsage,
        allTrue([viewOptions.isOnFieldsPage, !isDimmed, !isSubField]),
    ]);

/**
 * Field is filled if:
 * - user is selecting fields & the field is not a parent field for this year
 * - field visibility is on and no vegetation map is showing and the field does not
 *   have sub fields in this year
 */
export const shouldMapFieldBeFilled = (
    { isSelectingFields, fieldsVisibility, isVegetationMapShowing }: FieldViewOptions,
    fieldHasSubFieldsInYear: boolean
) =>
    anyTrue([
        allTrue([isSelectingFields, !fieldHasSubFieldsInYear]),
        allTrue([
            fieldsVisibility === VisibilityOptions.ON,
            !isVegetationMapShowing,
            !fieldHasSubFieldsInYear,
        ]),
    ]);

export const shouldMapFieldHaveStroke = (
    filled: boolean,
    visibilityOption: VisibilityOptions,
    vegetationMapShowing: boolean
) =>
    allTrue([
        visibilityOption !== VisibilityOptions.OFF,
        anyTrue([filled, visibilityOption === VisibilityOptions.BORDER, vegetationMapShowing]),
    ]);

/**
 * Convert the editing geo feature collection representing the field boundary into a
 * list of geo features.
 * This can assume certain things because it should only be called when a field is being
 * created or edited.
 */
export const editingFieldtoGeoFeatures = (geoFeatureCollection: GeoFeatureCollection) =>
    geoFeatureCollection.features.map((geoFeature) =>
        geoFeature.set(
            'properties',
            Map({
                type: 'field',
                editable: true,
                pointScale: 6,
                strokeWeight: 2,
                strokeOpacity: 1,
                fillOpacity: 0.4,
                // Assume that fields page is active
                zIndex: getZIndex(true, 'field', geoFeature.geometry.type),
            })
        )
    );

/**
 * Select the boundaries that were returned by the auto boundary API.
 * Styling is different for boundaries that have been selected and those that have not.
 */
export const selectAutoBoundaryGeoFeatures = createSelector(
    (state: AppState) => state.farmEditingState.editingType,
    (state: AppState) => state.farmEditingState.editingData,
    (state: AppState) => state.farmEditingState.editingGeoFeatureCollection,
    (state: AppState) => selectEditingAttachmentsByType(state, AttachmentType.FIELD),
    (state: AppState) => state.farmEditingState.highlightedGeoFeatureId,
    (
        editingType,
        editingData,
        editingGeoFeatureCollection,
        editingFieldUuids,
        highlightedGeoFeatureId
    ) => {
        if (editingType !== EditingType.AUTO_BOUNDARY || editingGeoFeatureCollection === null) {
            return List();
        }

        return editingGeoFeatureCollection.features.map((geoFeature) => {
            const isSelected = editingFieldUuids.has(geoFeature.id.toString());
            const isHighlighted = geoFeature.id === highlightedGeoFeatureId;
            const colour = isHighlighted ? '#4da4da' : isSelected ? '#ffffff' : DEFAULT_COLOUR;
            return geoFeature.set(
                'properties',
                Map({
                    editable: editingData.id === 'edit-boundaries',
                    type: 'auto-boundary',
                    colour,
                    pointScale: 6,
                    strokeWeight: 2,
                    strokeOpacity: 1,
                    fillOpacity: 0.4,
                    zIndex: getZIndex(true, 'field', geoFeature.geometry.type),
                    label: geoFeature.properties.get('label'),
                })
            );
        });
    }
);
