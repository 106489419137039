import type { CodedItem } from 'system/types';

import type { InputUnit } from './Input';

interface PesticideActive extends CodedItem {
    concentration: string;
    concentration_unit: CodedItem;
}

interface PesticideName {
    name: string;
    lang_code: string;
}

export interface PesticideData {
    code: string;
    names: PesticideName[];
    registrationNumber: string;
    actives: PesticideActive[];
    productCategory: CodedItem;
    doseUnits: InputUnit[];
}

export const pesticideDataAppearsTheSame = (pesticideA: PesticideData, pesticideB: PesticideData) =>
    pesticideA.code === pesticideB.code &&
    pesticideA.registrationNumber === pesticideB.registrationNumber;
