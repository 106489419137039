import type { PropsWithChildren, ReactNode } from 'react';
import { useToggle } from '@fieldmargin/webapp-state';
import { clsx } from 'clsx';
import { preventDefaultAnd } from 'lib/dom';
import { useDocumentEventListener } from 'lib/hooks';

type DropdownProps = PropsWithChildren<{
    title: ReactNode;
    className?: string;
    menuClassName?: string;
}>;

export const Dropdown = ({ children, title, className, menuClassName }: DropdownProps) => {
    const [open, toggleOpen] = useToggle(false);

    useDocumentEventListener(
        'click',
        (e) => {
            if (
                e
                    .composedPath()
                    .filter((el: HTMLElement) => el.classList?.contains('CheckboxFilter'))
                    .length === 0 &&
                open
            ) {
                toggleOpen();
            }
        },
        [open]
    );

    return (
        <div className={clsx('CheckboxFilter', className)}>
            <button
                className="bg-white text-gray-400 bordered rounded px-2 py-1 h-full w-full flex justify-between items-center focus cursor-pointer"
                onClick={preventDefaultAnd(toggleOpen)}
            >
                {title}
                <i className={clsx('ml-2', open ? 'ion-chevron-up' : 'ion-chevron-down')} />
            </button>
            {open && (
                <div
                    className={clsx(
                        'w-full bordered round-bl rounded-br rounded-tr absolute top-full left-0 bg-white z-10 max-h-80 overflow-auto text-fm-blue',
                        menuClassName
                    )}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

export default Dropdown;

export const DropdownItem = ({ children }: PropsWithChildren) => {
    return <div className="separator-bottom p-2 last:border-0">{children}</div>;
};
