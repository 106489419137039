import { useState } from 'react';

import SidebarModule from '../SidebarModule';

import TitleForm from './TitleForm';
import TitleView from './TitleView';

import './Title.css';

interface EditableTitleProps {
    label: string;
    value: string;
    onSave: (newTitle: string) => Promise<any>;
    children?: React.ReactNode;
}

const EditableTitle = ({ label, value, onSave, children }: EditableTitleProps) => {
    const [editing, setEditing] = useState(false);

    if (editing) {
        return (
            <SidebarModule className="Title" editing={true}>
                <TitleForm label={label} value={value} onSave={onSave} setEditing={setEditing} />
            </SidebarModule>
        );
    }

    return (
        <SidebarModule className="Title">
            <TitleView value={value} setEditing={setEditing} children={children} />
        </SidebarModule>
    );
};

export default EditableTitle;
