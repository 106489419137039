import type { ReactNode } from 'react';
import icon from 'components/icons/icon';

import { PesticideCheckStatus } from './PesticideCheck';

interface PesticideCheckResultIconProps {
    status: PesticideCheckStatus;
}

const PesticideCheckResultIcon = ({ status }: PesticideCheckResultIconProps) => {
    let i: ReactNode = null;
    switch (status) {
        case PesticideCheckStatus.FAIL:
            i = icon('edit-remove', 'red');
            break;
        case PesticideCheckStatus.PASS:
            i = icon('tick', 'green');
            break;
        case PesticideCheckStatus.WARNING:
            i = icon('error-alert', 'orange');
            break;
        case PesticideCheckStatus.UNKNOWN:
            i = icon('error-alert', 'grey');
            break;
        default:
    }

    return i !== null ? <td className="PesticideCheckResultsTableResultIcon">{i}</td> : null;
};

export default PesticideCheckResultIcon;
