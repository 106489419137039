import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import { useFieldState } from 'informed';
import { bindActionCreators } from 'redux';
import type Metric from 'sensors/Metric';
import { changeModal } from 'system/ui-state';
import TextInputFormField from 'view/form/TextInputFormField';
import { required } from 'view/form/validations';

import MetricFormStatic from './MetricFormStatic';

const validateMetricName = required('Your metric needs a name');

interface EditMetricFormFieldProps {
    // From parent
    field: string;
    metric: Metric;
    onRemove: (metricId: string) => void;

    // From redux
    changeModal: typeof changeModal;
}

const EditMetricFormField = ({
    field,
    metric,
    onRemove,
    changeModal,
}: EditMetricFormFieldProps) => {
    const { t } = useTranslation();
    const fieldState = useFieldState<string>(`${field}.${metric.id}`);
    const [editing, setEditing] = useState(false);

    const handleRemoveClick = () => {
        changeModal({
            name: 'choice',
            props: {
                content: (
                    <>
                        <h3>{t('are_you_sure')}</h3>
                        <p>{t('deleting_this_metric_removes_data')}</p>
                    </>
                ),
                onConfirm: () => onRemove(metric.id),
                confirmText: t('label_continue'),
            },
        });
    };

    return (
        <>
            {!editing && (
                <MetricFormStatic
                    name={fieldState.value}
                    type={metric.type}
                    unit={metric.unit ?? undefined}
                    onEdit={() => setEditing(true)}
                    onRemove={handleRemoveClick}
                />
            )}
            <div className={c('px-5 mb-4', { hidden: !editing })}>
                <div className="edit-fields">
                    <TextInputFormField
                        field={`${field}.${metric.id}`}
                        label={t('name')}
                        validate={validateMetricName}
                        error={fieldState.error}
                        inputClassName="w-full"
                        className="mb-3"
                    />
                    <Button
                        variant="outline"
                        onClick={(e) => {
                            e && e.preventDefault();
                            setEditing(false);
                        }}
                    >
                        {t('other_done')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default connect(
    () => ({}),
    (dispatch) => bindActionCreators({ changeModal }, dispatch)
)(EditMetricFormField);
