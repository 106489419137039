import { useTranslation } from 'react-i18next';

import type { PesticideCheckLatestApplication } from './PesticideCheck';
import { PesticideCheckStatus } from './PesticideCheck';
import PesticideCheckResultIcon from './PesticideCheckResultIcon';

interface LatestApplicationResultProps {
    check: PesticideCheckLatestApplication;
}

const LatestApplicationResult = ({ check }: LatestApplicationResultProps) => {
    const { t } = useTranslation();
    return (
        <tr>
            <PesticideCheckResultIcon status={check.status} />
            <td>
                {check.status !== PesticideCheckStatus.UNKNOWN
                    ? t('pesticide_check_latest_application', {
                          sprintf: [check.values?.latestApplication],
                      })
                    : t('pesticide_check_latest_application_no_data')}
            </td>
        </tr>
    );
};

export default LatestApplicationResult;
