import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';
import { useCurrentPageLocation } from 'hooks/useCurrentPageLocation';
import type { OperationActionType } from 'types';

const removeLastAction = (str: string) => {
    const actions = Object.values(APP_ROUTES.Operations);
    const params = str.split('/');
    if (actions.includes(params[params.length - 1] as OperationActionType)) {
        params.pop();
    }
    return params.join('/');
};

const toggleUrlAction = (currentPage: string, action: OperationActionType) => {
    const baseLocation = removeLastAction(currentPage);
    const currentAction = currentPage.split('/').pop();

    if (currentAction === action) {
        return baseLocation;
    }
    return `${baseLocation}/${action}`;
};

const useOperationDetailsActions = () => {
    const { currentPageLocation } = useCurrentPageLocation();
    const navigate = useNavigate();

    const navigateCurrentOperation = () => {
        const baseLocation = removeLastAction(currentPageLocation);
        navigate(baseLocation);
    };

    const navigateToOperation = (operation: OperationActionType) => {
        const newLocation = toggleUrlAction(currentPageLocation, operation);

        navigate(newLocation);
    };

    const handleToggleAttachingRecordings = () => {
        navigateToOperation(APP_ROUTES.Operations.AddRecordings);
    };

    const handleToggleAttachingFuel = () => {
        navigateToOperation(APP_ROUTES.Operations.AddFuel);
    };

    const handleToggleAddingOutput = () => {
        navigateToOperation(APP_ROUTES.Operations.AddOutput);
    };

    const handleToggleEditingFields = () => {
        navigateToOperation(APP_ROUTES.Operations.EditFields);
    };

    const handleToggleAddFields = () => {
        navigateToOperation(APP_ROUTES.Operations.AddFields);
    };

    return {
        handleToggleAttachingRecordings,
        handleToggleAttachingFuel,
        handleToggleAddingOutput,
        handleToggleEditingFields,
        handleToggleAddFields,
        navigateCurrentOperation,
    };
};

export { useOperationDetailsActions };
