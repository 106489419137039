import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';

import AreaShapeBubble from './AreaShapeBubble';
import LineShapeBubble from './LineShapeBubble';
import PerimeterShapeBubble from './PerimeterShapeBubble';
import PointShapeBubbble from './PointShapeBubble';

interface ShapeBubblesProps {
    geoFeatureCollection: GeoFeatureCollection;
    onRemove?: (geoFeatureId: number | string) => void;
    onHoverGeoFeature?: (geoFeatureId: number | string | null) => void;
    includeEmptyMessage?: boolean;
    emptyMessage?: string;
    includePerimeter?: boolean;
}

const ShapeBubbles = ({
    geoFeatureCollection,
    onRemove,
    onHoverGeoFeature,
    includeEmptyMessage = true,
    emptyMessage,
    includePerimeter,
}: ShapeBubblesProps) => {
    const { t } = useTranslation();
    if (geoFeatureCollection.features.size === 0 && !includeEmptyMessage) {
        return null;
    }

    const points = geoFeatureCollection.features.filter(
        (geoFeature) => geoFeature.geometry.type === 'Point'
    );
    const polygons = geoFeatureCollection.features.filter(
        (geoFeature) => geoFeature.geometry.type === 'Polygon'
    );
    const lines = geoFeatureCollection.features.filter(
        (geoFeature) => geoFeature.geometry.type === 'LineString'
    );

    const commonProps = {
        onHoverGeoFeature,
        onRemove,
    };

    return (
        <>
            {geoFeatureCollection.features.size > 0 ? (
                <div className="flex items-start flex-wrap gap-3">
                    {points.map((geoFeature, index) => (
                        <PointShapeBubbble
                            key={geoFeature.id}
                            index={points.size > 1 ? index + 1 : undefined}
                            geoFeature={geoFeature}
                            {...commonProps}
                        />
                    ))}
                    {polygons.reduce((items, geoFeature, index) => {
                        items.push(
                            <AreaShapeBubble
                                key={geoFeature.id}
                                index={polygons.size > 1 ? index + 1 : undefined}
                                geoFeature={geoFeature}
                                {...commonProps}
                            />
                        );
                        if (includePerimeter) {
                            items.push(
                                <PerimeterShapeBubble
                                    key={`${geoFeature.id}-perimeter`}
                                    index={polygons.size > 1 ? index + 1 : undefined}
                                    geoFeature={geoFeature}
                                    {...commonProps}
                                />
                            );
                        }
                        return items;
                    }, [] as ReactNode[])}
                    {lines.map((geoFeature, index) => (
                        <LineShapeBubble
                            key={geoFeature.id}
                            index={lines.size > 1 ? index + 1 : undefined}
                            geoFeature={geoFeature}
                            {...commonProps}
                        />
                    ))}
                </div>
            ) : (
                <p className="pb-2 text-gray-550 mb-0">
                    <em>{emptyMessage ?? t('no_location_set')}</em>
                </p>
            )}
        </>
    );
};

export default ShapeBubbles;
