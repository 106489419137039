import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { Activity } from 'activity/activity-helper';
import type Field from 'fields/Field';
import type { HerdLocation } from 'herd/Herd';
import type { List } from 'immutable';
import FullOperation from 'operations/FullOperation';
import { loadOperationsRecordings } from 'operations/operations-state';
import ItemTab from 'sidebar/modules/tabs/ItemTab';
import ItemTabPanel from 'sidebar/modules/tabs/ItemTabPanel';
import { useAppDispatch } from 'system/store';

import ItemTabList from '../../../sidebar/modules/tabs/ItemTabList';

import type { FieldTabsSchema } from './useFieldTabsContext';

interface FieldTabsProps {
    farm: Farm;
    year: number;
    field: Field;
    subFields: List<Field>;
    todoActivities: List<Activity>;
    fieldHistory: List<Activity>;
    herdLocations?: List<HerdLocation>;
}

const FieldTabs = ({
    farm,
    year,
    field,
    subFields,
    todoActivities,
    fieldHistory,
    herdLocations,
}: FieldTabsProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const operations = fieldHistory.filter(
        (activity) => activity instanceof FullOperation && activity.summary.year === year
    ) as List<FullOperation>;

    useEffect(() => {
        dispatch(
            loadOperationsRecordings(
                farm,
                year,
                fieldHistory.filter(
                    (activity) => activity instanceof FullOperation
                ) as List<FullOperation>
            )
        );
    }, [fieldHistory]);

    return (
        <>
            <ItemTabList>
                <ItemTab to="">{t('field_details_todo')}</ItemTab>
                <ItemTab to="history">{t('field_history_label')}</ItemTab>
                <ItemTab to="reports">{t('reports')}</ItemTab>
            </ItemTabList>
            <ItemTabPanel>
                <Outlet
                    context={
                        {
                            todoActivities,
                            farm,
                            field,
                            herdLocations,
                            subFields,
                            operations,
                        } satisfies FieldTabsSchema
                    }
                />
            </ItemTabPanel>
        </>
    );
};

export default FieldTabs;
