import { NoResults } from 'components/no-results';
import type Feature from 'features/Feature';
import { useTranslations } from 'hooks/useTranslations';
import type { List } from 'immutable';

interface FeatureListFilterMessageProps {
    filteredFeatures: List<Feature>;
    featureSearchString?: string;
}

const FeatureListFilterMessage = ({
    filteredFeatures,
    featureSearchString,
}: FeatureListFilterMessageProps) => {
    const { t, plural } = useTranslations();

    if (filteredFeatures.size === 0) {
        return <NoResults>{t('no_items_found_matching_filters')}</NoResults>;
    }

    if (featureSearchString) {
        const message = plural('feature_list_filter_count', {
            count: filteredFeatures.size,
            sprintf: [filteredFeatures.size, featureSearchString],
        });
        return <NoResults>{message}</NoResults>;
    }
    return null;
};

export default FeatureListFilterMessage;
