import type { ComponentProps, ElementRef } from 'react';
import { forwardRef } from 'react';

type HeadingTags = 'h1' | 'h2' | 'h3' | 'h4';
const DEFAULT_TAG: HeadingTags = 'h1';

type HeadingProps = ComponentProps<HeadingTags> & { as?: HeadingTags };

const Heading = forwardRef<ElementRef<typeof DEFAULT_TAG>, HeadingProps>((props, forwardedRef) => {
    const { as: Component = DEFAULT_TAG, ...rest } = props;

    return <Component ref={forwardedRef} {...rest} />;
});

Heading.displayName = 'Heading';

export { Heading };
