import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type { AppState } from 'system/store';
import UpgradeLink from 'upgrade/UpgradeLink';

import './FarmHistoryWarning.scss';

interface FarmHistoryWarningProps {
    historyLimit: number | null;
}

const FarmHistoryWarning = ({ historyLimit }: FarmHistoryWarningProps) => {
    const { t } = useTranslation();

    if (!historyLimit) {
        // Shouldn't happen but better safe than sorry
        return null;
    }

    return (
        <div className="FarmHistoryWarning">
            {icon('padlock', 'red')}
            <p>
                {t('pro_upgrade_prompt_field_title_over_limit', { sprintf: [historyLimit] })}
                <UpgradeLink>{t('pro_upgrade_prompt_history_notes_over_limit')}</UpgradeLink>
            </p>
        </div>
    );
};

export default connect((state: AppState) => ({
    historyLimit: selectCurrentFarm(state).plan.historyLimitDays,
}))(FarmHistoryWarning);
