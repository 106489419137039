import type { MouseEvent, PropsWithChildren } from 'react';

interface ContactSupportLinkProps extends PropsWithChildren {
    className?: string;
}

const ContactSupportLink = ({ children, className }: ContactSupportLinkProps) => {
    const handleClick = (e: MouseEvent) => {
        if (window.Intercom) {
            e.preventDefault();
            window.Intercom('showNewMessage');
        }
    };
    return (
        <a href="mailto:support@fieldmargin.com" onClick={handleClick} className={className}>
            {children}
        </a>
    );
};

export default ContactSupportLink;
