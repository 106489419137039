import type { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getHerdTypeI18NKey, HerdType } from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { SingleParamVoidFunction } from 'system/types';
import { required } from 'view/form/validations';

import type { HerdFormValues } from '../HerdForm';

import './HerdTypeField.css';

interface HerdTypeFieldProps {
    onChange?: SingleParamVoidFunction<string>;
}

const HerdTypeField = ({ onChange }: HerdTypeFieldProps) => {
    const { t } = useTranslation();
    const { register, watch, getFieldState } = useFormContext<HerdFormValues>();
    const fieldState = getFieldState('type');
    const value = watch('type');

    const validateRequired = required(t('register_required'));

    return (
        <SidebarModule className="HerdTypeField" editing>
            <h4>{t('livestock_create_type')}</h4>
            <div className="flex flex-wrap">
                {Object.keys(HerdType).map((herdType) => (
                    <div className="HerdType" key={herdType}>
                        <input
                            type="radio"
                            {...register('type', {
                                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                                    onChange && onChange(e.target.value),
                                validate: validateRequired,
                            })}
                            value={herdType}
                            id={herdType}
                        />
                        <label htmlFor={HerdType[herdType]}>
                            <div className="HerdIcon">
                                {herdIcon(
                                    HerdType[herdType],
                                    value === HerdType[herdType] ? 'white' : 'blue'
                                )}
                            </div>
                            <span>{t(getHerdTypeI18NKey(herdType))}</span>
                        </label>
                    </div>
                ))}
            </div>
            {fieldState.error && <div className="field-error">{fieldState.error.message}</div>}
        </SidebarModule>
    );
};

export default HerdTypeField;
