import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import type { Set } from 'immutable';
import { Form } from 'informed';
import { useOutOfDatePromise } from 'lib/hooks';

import EditableActions from '../common/EditableActions';
import EditButton from '../common/EditButton';
import SidebarModule from '../SidebarModule';

import UserTagsField from './TaggedUsersField';
import UserTagList from './UserTagList';

interface EditableTaggedUsersProps {
    value: Set<FarmUser>;
    farmUsers: FarmUser[];
    onSave: (value: Set<FarmUser>) => Promise<any>;
}

interface FormValues {
    taggedUsers: Set<FarmUser>;
}

const EditableTaggedUsers = ({ value, farmUsers, onSave }: EditableTaggedUsersProps) => {
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(() => setEditing(false));

    const handleSubmit = (values: FormValues) => {
        setPromise(onSave(values.taggedUsers));
    };

    if (editing) {
        return (
            <SidebarModule className="EditableTaggedUsers relative" editing={true}>
                <Form<FormValues> initialValues={{ taggedUsers: value }} onSubmit={handleSubmit}>
                    <h4>{t('activity_log_tagged')}</h4>
                    <UserTagsField farmUsers={farmUsers} />
                    <EditableActions
                        disabled={pending}
                        error={error}
                        outOfDate={outOfDate}
                        setEditing={setEditing}
                    />
                </Form>
            </SidebarModule>
        );
    }

    return (
        <SidebarModule className="EditableTaggedUsers">
            <EditButton setEditing={setEditing} />
            <h4>{t('activity_log_tagged')}</h4>
            <UserTagList taggedUsers={value} />
        </SidebarModule>
    );
};

export default EditableTaggedUsers;
