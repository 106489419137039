import { connect } from 'react-redux';
import { setFeaturesVisibility } from 'farm-editing/farm-editing-state';
import { bindActionCreators } from 'redux';
import VisibilityToggle, { OnOffBorderOptions } from 'sidebar/visibility-toggle/VisibilityToggle';
import type { AppState } from 'system/store';

interface FeatureListVisibilityProps {
    featuresVisibility: string;
    setFeaturesVisibility: typeof setFeaturesVisibility;
}

const FeatureListVisibility = ({
    featuresVisibility,
    setFeaturesVisibility,
}: FeatureListVisibilityProps) => {
    return (
        <div className="feature-list-visibility">
            <VisibilityToggle
                options={OnOffBorderOptions}
                active={featuresVisibility}
                onSelect={(value) => setFeaturesVisibility(value)}
            />
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        featuresVisibility: state.farmEditingState.layerVisibility.featuresVisibility,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setFeaturesVisibility,
            },
            dispatch
        )
)(FeatureListVisibility);
