import type { ReactNode } from 'react';
import c from 'classnames';

interface EmptyStateProps {
    header?: string;
    children?: ReactNode;
    image?: any;
    className?: string;
}

const EmptyState = ({ header, children, image, className }: EmptyStateProps) => (
    <div className={c('EmptyState p-6 text-base', className)}>
        {header && <h2 className="font-medium">{header}</h2>}
        {children}
        {image && <img src={image} alt="" />}
    </div>
);

export default EmptyState;
