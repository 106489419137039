import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import icon from 'components/icons/icon';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import { selectShouldBlockInviting } from 'team/farm-users-state';
import TeamInviteBlockedModal from 'team/view/TeamInviteBlockedModal';
import { finishTutorial } from 'tutorials/tutorials-state';
import { TutorialTypes } from 'tutorials/TutorialTypes';

interface TeamInviteButtonProps {
    // From parent
    tutorialActive: boolean;
    // From redux
    finishTutorial: typeof finishTutorial;
    changeModal: typeof changeModal;
    blockInviting: boolean;
}

const TeamInviteButton = ({
    tutorialActive,
    finishTutorial,
    changeModal,
    blockInviting,
}: TeamInviteButtonProps) => {
    const { t } = useTranslation();
    const [showBlockModal, toggleBlockModal] = useToggle(false);

    const handleClick = () => {
        tutorialActive && finishTutorial(TutorialTypes.FARM_SHARE);
        if (blockInviting) {
            toggleBlockModal();
        } else {
            changeModal({
                name: 'settings',
                props: {
                    initialSubTab: 'farm-users',
                },
            });
        }
    };
    return (
        <>
            {showBlockModal && <TeamInviteBlockedModal onClose={toggleBlockModal} />}
            <Button
                className={c('flex items-center', {
                    forefront: tutorialActive,
                })}
                onClick={handleClick}
            >
                {icon('add', 'white', 'mr-1')} {t('walkthrough_invite_action')}
            </Button>
        </>
    );
};

export default connect(
    (state: AppState) => ({
        blockInviting: selectShouldBlockInviting(state),
    }),
    (dispatch) => bindActionCreators({ finishTutorial, changeModal }, dispatch)
)(TeamInviteButton);
