import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import { AttachmentType } from 'farm-editing/attachments';
import {
    selectEditingAttachmentsByType,
    startSelectingMultipleAttachments,
    toggleEditingAttachmentByType,
} from 'farm-editing/farm-editing-state';
import { HerdType } from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import SidebarModule from 'sidebar/modules/SidebarModule';
import { useAppDispatch, useAppSelector } from 'system/store';

import NoteHerdBubbles from '../NoteHerdBubbles';

const toggleEditingHerdUuid = toggleEditingAttachmentByType(AttachmentType.HERD);

const NoteNewHerd = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const noteHerds = useAppSelector((state) =>
        selectEditingAttachmentsByType(state, AttachmentType.HERD)
    );

    const handleAttach = (e?: MouseEvent) => {
        e && e.preventDefault();
        dispatch(startSelectingMultipleAttachments(AttachmentType.HERD));
    };

    const handleRemoveNoteHerd = (herdUuid: string) => {
        dispatch(toggleEditingHerdUuid(herdUuid));
    };

    return (
        <SidebarModule editing>
            <h4>{t('herd')}</h4>
            <Button
                onClick={handleAttach}
                variant="outline"
                className={c('flex items-center', { 'mb-4': noteHerds.size > 0 })}
            >
                {herdIcon(HerdType.SHEEP, 'blue', 'mr-3')} {t('create_note_attach_herd')}
            </Button>
            <NoteHerdBubbles noteHerds={noteHerds} onRemove={handleRemoveNoteHerd} />
        </SidebarModule>
    );
};

export default NoteNewHerd;
