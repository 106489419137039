import { useAppSelector } from 'system/store';

export const useEditingGeoFeatureCollection = () => {
    const editingGeoFeatureCollection = useAppSelector(
        (state) => state.farmEditingState.editingGeoFeatureCollection
    );

    return {
        editingGeoFeatureCollection,
    };
};
