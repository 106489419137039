import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import Modal from 'modal/Modal';

interface RPANameWarningModalProps {
    onClose: VoidFunction;
    onConfirm: VoidFunction;
    pending: boolean;
}

const RPANameWarningModal = ({ onClose, onConfirm, pending }: RPANameWarningModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal onClose={onClose}>
            <div className="bg-white py-10 px-12 text-center" style={{ width: 500 }}>
                <p className="text-xl">{t('rpa_fields_missing_name')}</p>
                <div className="flex gap-4 justify-center">
                    <Button variant="outline" onClick={onClose} disabled={pending}>
                        {t('set_names')}
                    </Button>
                    <Button onClick={onConfirm} disabled={pending}>
                        {t('label_continue')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default RPANameWarningModal;
