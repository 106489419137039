import { useMatch } from 'react-router-dom';
import { isComplete } from 'activity/activity-helper';
import type {
    FarmMapFeature,
    FarmMapFeatureProperties,
    FarmMapNoteFeatureProps,
} from 'farm-map/types';
import type { Feature, Geometry, Point } from 'geojson';
import { useActiveSection } from 'hooks/useActiveSection';
import { getUuid } from 'lib/fp-helpers';
import type Note from 'notes/Note';
import { selectFilteredNoteSummaries } from 'notes/notes-filter';
import { uniqBy } from 'ramda';
import { useAppSelector } from 'system/store';
import type { ActiveSection } from 'system/url-util';

export type FarmMapNoteFeature = Feature<Geometry, FarmMapNoteFeatureProps>;

/**
 * - Notes are only shown when the active section is 'notes'
 * - No notes are shown if none have been loaded
 *   This prevents locked notes from being shown when the user has no access to them
 * - No notes are shown if the user has turned off visibility
 * - Archived notes are only shown if the note is being viewed
 * - Completed tasks are never shown
 * - If a note is selected no other notes are shown
 * - If a note is being editing neither it nor any other notes are shown.
 */
export const useFarmMapNotes = (features: FarmMapFeature[]) => {
    const activeSection = useActiveSection();
    const editingId = useAppSelector((state) => state.farmEditingState.editingData.id);
    const displayNotes = useAppSelector(
        (state) => state.farmEditingState.layerVisibility.displayNotes
    );
    const notes = useAppSelector(selectFilteredNoteSummaries).toArray();
    const noteUuid = useMatch('/farms/:farmUuid/notes/:noteUuid/*')?.params.noteUuid;
    return getNoteFeaturesAndMarkers(features, {
        notes,
        activeSection,
        displayNotes,
        noteUuid,
        editingId,
    });
};

export const getNoteFeaturesAndMarkers = (
    features: FarmMapFeature[],
    opts: {
        notes: Note[];
        activeSection: ActiveSection;
        displayNotes: boolean;
        noteUuid?: string;
        editingId?: string;
    }
) => {
    if (
        opts.activeSection.main !== 'notes' ||
        !opts.displayNotes ||
        opts.notes.length === 0 ||
        opts.editingId !== undefined
    ) {
        return { noteFeatures: [], noteMarkers: [] };
    }

    const noteUuids = opts.notes.filter((note) => !isComplete(note)).map(getUuid);

    const noteFeatures = features
        .filter(featureIsNote)
        .filter((feature) => noteUuids.includes(feature.properties.uuid))
        .map(styleProps);

    // Note markers are only positioned on the first geometry for a note.
    const noteMarkers = uniqBy((feature) => feature.properties.uuid, noteFeatures)
        .map((feature) => ({
            ...feature,
            geometry: getFeatureMarker(feature),
            properties: {
                ...feature.properties,
                noteMarker: feature.properties.isTask ? 'task' : 'text',
            },
        }))
        .filter((marker) => marker.geometry !== undefined) as Feature<
        Point,
        FarmMapFeatureProperties
    >[];

    if (opts.noteUuid !== undefined) {
        return {
            noteFeatures: features.filter((feature) => feature.properties.uuid === opts.noteUuid),
            noteMarkers: noteMarkers.filter((marker) => marker.properties.uuid === opts.noteUuid),
        };
    }

    return { noteFeatures, noteMarkers };
};

const featureIsNote = (feature: FarmMapFeature): feature is FarmMapNoteFeature =>
    feature.properties.type === 'NOTE';

const getFeatureMarker = (feature: FarmMapNoteFeature): Point | null => {
    switch (feature.geometry.type) {
        case 'Point':
            return feature.geometry;
        case 'LineString':
            return { type: 'Point', coordinates: feature.geometry.coordinates[0] };
        case 'Polygon':
            return { type: 'Point', coordinates: feature.geometry.coordinates[0][0] };
        default:
            return null;
    }
};

const styleProps = (feature: FarmMapNoteFeature): FarmMapNoteFeature => ({
    ...feature,
    properties: {
        ...feature.properties,
        colour: '#ffffff',
        pointScale: 6,
        pointOpacity: 0.6,
        strokeWeight: 3,
        lineEndCircles: true,
    },
});
