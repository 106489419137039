import { notNil } from 'lib/fp-helpers';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { prefersImperialUnits } from 'lib/MeasurementUnit';
import {
    convertTemperatureForStorage,
    convertUserIdForStorage,
    convertWindSpeedForStorage,
} from 'lib/storage-conversion';
import type FullOperation from 'operations/FullOperation';
import type { FullOperationField, OperationField } from 'operations/OperationField';
import { when } from 'ramda';
import { converter } from 'utils/conversion';

import type { MarkCompleteFormFields } from './MarkCompleteForm';

export interface MarkCompleteData {
    completedDate: Date;
    completedByUserId: number;
    startDate?: Date;
    windSpeed?: number;
    windDirection?: string;
    temperature?: number;
    weatherNote?: string;
}

export const sanitiseMarkCompleteFormData = (
    measurementUnits: {
        length: MeasurementUnit;
        temperature: string;
    },
    values: MarkCompleteFormFields
): MarkCompleteData => {
    return {
        completedByUserId: convertUserIdForStorage(values.completedByUserId) as number,
        completedDate: values.completedDate,
        startDate: values.startDate,
        windSpeed: convertWindSpeedForStorage(values.windSpeed, measurementUnits.length),
        windDirection: values.windDirection,
        temperature: convertTemperatureForStorage(values.temperature, measurementUnits.temperature),
        weatherNote: values.weatherNote,
    };
};

export const getMarkCompleteInitialValues = (
    operationField: FullOperationField,
    fullOperation: FullOperation,
    userId: number,
    lengthMeasurementUnit: MeasurementUnit,
    temperatureUnit: string
) => {
    const lastCompletedField = fullOperation.fields
        ?.filter((operationField) => operationField.completedDate !== undefined)
        .sortBy((operationField) => operationField.completedDate)
        .reverse()
        .first<OperationField>();

    const windSpeedInKph = operationField.windSpeed ?? lastCompletedField?.windSpeed;
    const tempInCelsium = operationField.temperature ?? lastCompletedField?.temperature;

    const maybeConvertWindSpeedToImperial = (windSpeed: number) =>
        prefersImperialUnits(lengthMeasurementUnit)
            ? converter.convertKilometresPerHourToMilesPerHour(windSpeed)
            : windSpeed;

    const maybeConvertTempToImperial = (temperture: number) =>
        temperatureUnit === 'FAHRENHEIT'
            ? converter.convertCelsiusToFahrenheit(temperture)
            : temperture;

    return {
        completedDate: new Date(),
        completedByUserId: userId.toString(),
        windSpeed: when<number | undefined, number>(
            notNil,
            maybeConvertWindSpeedToImperial,
            windSpeedInKph
        ),
        windDirection: operationField.windDirection ?? lastCompletedField?.windDirection,
        temperature: when<number | undefined, number>(
            notNil,
            maybeConvertTempToImperial,
            tempInCelsium
        ),
        weatherNote: operationField.weatherNote ?? lastCompletedField?.weatherNote,
        startDate: operationField.startDate,
    };
};

export const setOperationFieldComplete = (
    operationField: OperationField,
    data: MarkCompleteData
): OperationField => ({
    ...operationField,
    ...data,
});

export const setOperationFieldIncomplete = (operationField: OperationField): OperationField => ({
    ...operationField,
    completedDate: undefined,
    completedByUserId: undefined,
});

export const setAllFieldsComplete = (fullOperation: FullOperation, data: MarkCompleteData) =>
    fullOperation.update('fields', (fields) =>
        fields !== null
            ? fields.map((operationField) => ({
                  ...operationField,
                  ...data,
              }))
            : null
    );
