import type { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { HerdType } from 'herd/Herd';
import { getHerdTypeI18NKey } from 'herd/Herd';
import { herdColours } from 'lib/colours';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import UnsavedHookFormChangesChecker from 'unsaved-changes/UnsavedHookFormChangesChecker';
import ColourPickerField from 'view/form/hook/ColourPickerField';
import TextInputField from 'view/form/hook/TextInputField';
import { required, requiredPositiveNumber } from 'view/form/validations';

import HerdTypeField from './herd-type/HerdTypeField';
import { nameShouldUpdate } from './herd-ui-utils';

interface HerdFormProps {
    onSubmit: (values: HerdFormValues) => void;
    initialValues?: Omit<HerdFormValues, 'type'>;
    header: ReactNode;
    error: boolean;
    outOfDate?: boolean;
}

export interface HerdFormValues {
    type: HerdType;
    name: string;
    colour: string;
    size: string;
}

const HerdForm = ({ onSubmit, initialValues, header, error, outOfDate = false }: HerdFormProps) => {
    const { t } = useTranslation();

    const methods = useForm<HerdFormValues>({
        mode: 'onChange',
        defaultValues: initialValues,
    });
    const nameValue = methods.watch('name');

    const validateRequired = required(t('register_required'));
    const validateAnimals = requiredPositiveNumber(t('validation_number'));

    return (
        <FormProvider {...methods}>
            <form className="scrollable" onSubmit={methods.handleSubmit(onSubmit)}>
                <UnsavedHookFormChangesChecker
                    copy={[t('herd_create_not_saved1'), t('herd_create_not_saved2')]}
                >
                    <div className="non-scrolling">
                        {header}
                        {error && (
                            <SidebarError
                                title={t('failed_to_save')}
                                message={t('something_went_wrong')}
                            />
                        )}
                        {outOfDate && (
                            <SidebarError
                                title={t('failed_to_save')}
                                message={t('out_of_date_message')}
                            />
                        )}
                    </div>
                    <div className="scrolling">
                        <HerdTypeField
                            onChange={(value) =>
                                nameShouldUpdate(nameValue, t) &&
                                methods.setValue('name', t(getHerdTypeI18NKey(value)))
                            }
                        />
                        <SidebarModule editing className="flex justify-between">
                            <div className="w-7/12 mr-8">
                                <TextInputField
                                    field="name"
                                    label={<strong>{t('livestock_create_name')}</strong>}
                                    validate={validateRequired}
                                    inputClassName="w-full"
                                />
                            </div>
                            <div className="w-5/12">
                                <TextInputField
                                    field="size"
                                    label={<strong>{t('livestock_details_count')}</strong>}
                                    validate={validateAnimals}
                                    inputClassName="w-full"
                                />
                            </div>
                        </SidebarModule>
                        <SidebarModule editing>
                            <ColourPickerField
                                field="colour"
                                label={<strong>{t('label_select_color')}</strong>}
                                validate={validateRequired}
                                colours={herdColours}
                            />
                        </SidebarModule>
                    </div>
                </UnsavedHookFormChangesChecker>
            </form>
        </FormProvider>
    );
};

export default HerdForm;
