import { Link } from 'react-router-dom';
import Button from '@fieldmargin/webapp-styling/components/button';

const DemoUserMessage = () => {
    return (
        <h2 className="w-auto text-white text-base font-medium self-stretch mb-0 flex items-center">
            This is a demo farm{' '}
            <Button as={Link} to="/create-farm" className="ml-4" small>
                Add my farm
            </Button>
        </h2>
    );
};

export default DemoUserMessage;
