import styles from './DancingWheat.module.css';

const DancingWheat = () => {
    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.bars}>
                    <div className={styles.bar} style={{ left: 0 }} />
                    <div className={styles.bar} style={{ left: '21%' }} />
                    <div className={styles.bar} style={{ left: '42%' }} />
                    <div className={styles.bar} style={{ left: '63%' }} />
                    <div className={styles.bar} style={{ left: '84%' }} />
                </div>
            </div>
        </div>
    );
};

export { DancingWheat };
