import type { Farm } from '@fieldmargin/webapp-farms';
import type Field from 'fields/Field';
import {
    getFieldWorkedArea,
    isSubField,
    mergeFieldAndWriteProps,
    mergeSubFieldAndWriteProps,
} from 'fields/Field';
import { deleteField, toggleFieldArchived, updateField, updateSubField } from 'fields/fields-api';
import type { List } from 'immutable';
import { uuidMatches } from 'lib/fp-helpers';

import type { BulkFieldEditFieldValues } from './BulkFieldEdit';

export const saveBulkEditField = (
    fieldUuid: string,
    { name, id, workArea, archived }: BulkFieldEditFieldValues,
    farm: Farm,
    fields: List<Field>,
    areaAsSqm: (area: number) => number,
    year: number
) => {
    const field = fields.find(uuidMatches(fieldUuid));
    const workAreaAsSqm = areaAsSqm(workArea);
    if (
        field === undefined ||
        (field.name === name &&
            field.fieldId === id &&
            getFieldWorkedArea(field, year) === workAreaAsSqm &&
            field.archived === archived)
    ) {
        return null;
    }
    return isSubField(field)
        ? updateSubField(farm.uuid, field.uuid, {
              name,
              year,
              workedArea: workAreaAsSqm,
          })
              .then((props) => mergeSubFieldAndWriteProps(field, props))
              .then((field) => maybeToggleFieldArchived(farm.uuid, field, archived))
        : updateField(farm.uuid, field.uuid, {
              name,
              fieldId: id,
              yearWorkedArea: { year, workedArea: workAreaAsSqm },
          })
              .then((props) => mergeFieldAndWriteProps(field, props))
              .then((field) => maybeToggleFieldArchived(farm.uuid, field, archived));
};

const maybeToggleFieldArchived = (farmUuid: string, field: Field, archived: boolean) =>
    field.archived !== archived
        ? toggleFieldArchived(farmUuid, field.uuid, archived).then(() =>
              field.set('archived', archived)
          )
        : field;

export const deleteBulkEditField = (fieldUuid: string, farm: Farm, fields: List<Field>) => {
    const field = fields.find(uuidMatches(fieldUuid));
    if (field === undefined) {
        return null;
    }
    return deleteField(farm.uuid, field);
};
