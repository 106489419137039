import { useEffect } from 'react';
import { completeAuthorizationRequest } from '@fieldmargin/webapp-auth';
import PageLoading from 'components/PageLoading';

const AuthorizationCompletion = () => {
    useEffect(() => {
        completeAuthorizationRequest();
    }, []);

    return <PageLoading />;
};

export default AuthorizationCompletion;
