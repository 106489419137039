import type { Farm } from '@fieldmargin/webapp-farms';
import { analytics } from '@notes-web/analytics';
import Modal from 'modal/Modal';
import UpgradeButton from 'upgrade/UpgradeButton';

import './PesticideCheckUpgradeModal.css';

interface PesticideCheckUpgradeModalProps {
    farm: Farm;
    onClose: VoidFunction;
}

const PesticideCheckUpgradeModal = ({ farm, onClose }: PesticideCheckUpgradeModalProps) => {
    analytics.track('Pesticide check upgrade modal seen', { farmUuid: farm.uuid });
    return (
        <Modal onClose={onClose}>
            <div className="PesticideCheckUpgradeModal">
                <h2>Upgrade needed</h2>
                <p>
                    Check pesticides performs checks on the pesticides you have added to a field job
                    and provides warnings if you have planned a treatment which doesn’t follow the
                    label guidance. It checks:
                </p>
                <ul>
                    <li>If the pesticide is permitted for use on your field’s crop</li>
                    <li>Pesticide expiry date</li>
                    <li>
                        If your planned rate is within the maximum rate per treatment and for the
                        season
                    </li>
                    <li>Latest spray date</li>
                    <li>Withholding period</li>
                </ul>
                <p>This is included with the Pro plan</p>
                <UpgradeButton className="w-full mb-5" />
                <p className="text-center">
                    <a
                        href="https://fieldmargin.com/pricing"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="text-fm-sky"
                    >
                        Learn more
                    </a>
                </p>
            </div>
        </Modal>
    );
};

export default PesticideCheckUpgradeModal;
