import { GeoFeature, GeoPoint } from '@fieldmargin/webapp-geo';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import { getInitials } from 'lib/util/names';
import { logReselect } from 'lib/util/reselect-util';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import type { ActiveSection } from 'system/url-util';
import { selectDisplayableFarmUsers } from 'team/farm-users-state';

/**
 * - Team tracking locations will only be shown if the active section is 'team'
 * - Only users that have location tracking enabled will be shown
 */
export const selectTeamTracking = createSelector(
    (_state: AppState, activeSection: ActiveSection) => activeSection.main === 'team',
    selectDisplayableFarmUsers,
    (state: AppState) => state.farmUsersState.locations,
    (isActiveSection, farmUsers, locations): GeoFeature[] => {
        logReselect('selectTeamTracking');
        if (isActiveSection && locations) {
            const farmUsersWithLocations = farmUsers.filter((fu) => locations[fu.id.toString()]);
            let zIndexCount = farmUsersWithLocations.length;
            return farmUsersWithLocations.map((fu) =>
                GeoFeature({
                    id: `farm-user-${fu.id}`,
                    geometry: GeoPoint({
                        coordinates: locations[fu.id.toString()].position,
                    }),
                    properties: OLFeatureProperties({
                        type: 'user-location',
                        label: getInitials(fu),
                        updateGeometry: true,
                        zIndex: zIndexCount--,
                    }),
                })
            );
        }
        return [];
    }
);
