export type UserPreferenceMeasurementUnit = 'METRIC' | 'IMPERIAL';
export type UserPreferenceTemperatureUnit = 'CELSIUS' | 'FAHRENHEIT';

export interface UserPreferences {
    lengthMeasurementUnits: UserPreferenceMeasurementUnit;
    areaMeasurementUnits: UserPreferenceMeasurementUnit;
    temperatureMeasurementUnit: UserPreferenceTemperatureUnit;
}

export const deserializeUserPreferences = (json: Partial<UserPreferences>): UserPreferences => ({
    lengthMeasurementUnits: json.lengthMeasurementUnits ? json.lengthMeasurementUnits : 'METRIC',
    areaMeasurementUnits: json.areaMeasurementUnits ? json.areaMeasurementUnits : 'METRIC',
    temperatureMeasurementUnit:
        json.temperatureMeasurementUnit !== undefined ? json.temperatureMeasurementUnit : 'CELSIUS',
});

export default UserPreferences;
