import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import { useAppSelector } from 'system/store';
import { UIContext } from 'system/ui-state';

import TopNavItem from './TopNavItem';

const InfoNavItem = () => {
    const { t } = useTranslation();
    const farm = useAppSelector(selectCurrentFarm);
    const { toggleInfo } = useContext(UIContext);
    const handleClick = () => {
        if (farm === undefined || !farm.plan.blocked) {
            toggleInfo();
        }
    };
    return (
        <TopNavItem className="forefront" onClick={handleClick}>
            {icon('info', 'white', 'w-5')}
            <div className="sr-only">{t('activity_log_info')}</div>
        </TopNavItem>
    );
};

export default InfoNavItem;
