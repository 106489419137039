import type { PropsWithChildren } from 'react';

interface ExternalLinkProps extends PropsWithChildren {
    href: string;
    className?: string;
}

const ExternalLink = ({ href, className, children }: ExternalLinkProps) => {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
            {children}
        </a>
    );
};

export default ExternalLink;
