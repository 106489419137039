import { activityIsOperation } from 'activity/activity-helper';
import type { List } from 'immutable';
import { selectLimitedActivities } from 'notes/notes-filter';
import type FullOperation from 'operations/FullOperation';
import { useAppSelector } from 'system/store';

const useOperationsSelector = (reportUuid: string) => {
    const operations = useAppSelector(selectLimitedActivities).filter(
        (activity) =>
            activityIsOperation(activity) &&
            activity.fields?.map((opField) => opField.fieldUuid).includes(reportUuid)
    ) as List<FullOperation>;

    return {
        operations,
    };
};

export { useOperationsSelector };
