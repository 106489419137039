import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import icon from 'components/icons/icon';
import { useDocumentEventListener } from 'lib/hooks';
import { uniqueId } from 'lodash';

const TakeOverModal = ({ children, onClose }: PropsWithChildren<{ onClose: VoidFunction }>) => {
    const [el, setEl] = useState<HTMLDivElement>();

    const handleCloseOnEsc = (e: KeyboardEvent) => {
        e.stopPropagation();
        // 27 is the value for the escape key
        if (e.keyCode === 27) {
            onClose();
        }
    };

    useDocumentEventListener('keyup', handleCloseOnEsc);

    useEffect(() => {
        const div = document.createElement('div');
        div.id = uniqueId('takeover-modal-');
        setEl(div);
    }, []);

    useEffect(() => {
        if (el !== undefined) document.body.appendChild(el);

        return () => {
            if (el !== undefined) document.body.removeChild(el);
        };
    }, [el]);

    return el !== undefined
        ? createPortal(
              <div className="bg-white p-6 w-screen h-content-full mt-nav-bar absolute top-0 z-10 overflow-hidden">
                  {children}
                  <button className="bare-btn absolute top-6 right-6 w-6" onClick={onClose}>
                      {icon('close', 'dark-blue', 'h-8')}
                  </button>
              </div>,
              el
          )
        : null;
};

export default TakeOverModal;
