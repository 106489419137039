import { connect } from 'react-redux';
import { toggleHiddenFeatureType } from 'farm-editing/farm-editing-state';
import type { FeatureTypeWithFeatures } from 'features/features-selectors';
import type FeatureType from 'features/FeatureType';
import { asTypeItem } from 'features/FeatureType';
import type { List, Set } from 'immutable';
import { getItemsSizes } from 'lib/geo/maths';
import { bindActionCreators } from 'redux';
import TypeGroupHeader from 'sidebar/modules/TypeGroupHeader';
import type LayerVisibility from 'system/editing/LayerVisibility';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';
import { VisibilityOptions } from 'system/types';

import FeaturePreview from './FeaturePreview';

interface FeatureListFeaturesByTypeProps {
    // From parent
    featureTypes: List<FeatureType>;
    filteredFeaturesByType: List<FeatureTypeWithFeatures>;
    closedFeatureTypes: Set<string>;
    onFeatureTypeClick: SingleParamVoidFunction<string>;

    // From Redux
    layerVisibility: LayerVisibility;
    toggleHiddenFeatureType: typeof toggleHiddenFeatureType;
}

const FeatureListFeaturesByType = ({
    featureTypes,
    filteredFeaturesByType,
    closedFeatureTypes,
    onFeatureTypeClick,
    layerVisibility,
    toggleHiddenFeatureType,
}: FeatureListFeaturesByTypeProps) => {
    const handleVisibilityClick = (uuid: string) => {
        toggleHiddenFeatureType({
            featureTypeUuid: uuid,
            value: !layerVisibility.hiddenFeatureTypes.contains(uuid),
            allFeatureTypes: featureTypes,
        });
    };

    const featureTypeItems = filteredFeaturesByType.map(({ featureType, features }) => {
        const isOpen = !closedFeatureTypes.contains(featureType.uuid);
        const featureItems = features.map((feature) => (
            <FeaturePreview key={feature.uuid} feature={feature} />
        ));

        const isItemVisible = !layerVisibility.hiddenFeatureTypes.contains(featureType.uuid);
        const isVisible =
            isItemVisible && layerVisibility.featuresVisibility !== VisibilityOptions.OFF;

        return (
            <div key={featureType.uuid} className="bordered-t">
                <TypeGroupHeader
                    typeItem={asTypeItem(featureType)}
                    sizes={getItemsSizes(features.toArray())}
                    isVisible={isVisible}
                    onVisibilityClick={handleVisibilityClick}
                    isOpen={isOpen}
                    onClick={onFeatureTypeClick}
                >
                    {featureItems}
                </TypeGroupHeader>
            </div>
        );
    });

    return <div>{featureTypeItems}</div>;
};

export default connect(
    (state: AppState) => ({
        layerVisibility: state.farmEditingState.layerVisibility,
    }),
    (dispatch) => bindActionCreators({ toggleHiddenFeatureType }, dispatch)
)(FeatureListFeaturesByType);
