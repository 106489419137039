import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import type { FieldUsageWithFields } from 'fields/fields-selectors';
import { selectYearFieldUsagesWithFields } from 'fields/fields-selectors';
import { getUsageName } from 'fields/FieldUsage';
import { getUuid } from 'lib/fp-helpers';
import { useOutsideComponentClickListener } from 'lib/hooks';
import { addOrRemoveInArray } from 'lib/immutil';
import { compareName } from 'lib/util/text';
import { uniqueId } from 'lodash';
import { prop, uniq } from 'ramda';
import type { AppState } from 'system/store';
import type { SingleParamVoidFunction } from 'system/types';

interface YearFieldsByUsageFilterProps {
    // From redux
    fieldUsageFields: FieldUsageWithFields[];
    // From parent
    selectedFieldUuids: string[];
    onApply: SingleParamVoidFunction<string[]>;
}

const YearFieldsByUsageFilter = ({
    fieldUsageFields,
    selectedFieldUuids,
    onApply,
}: YearFieldsByUsageFilterProps) => {
    const { t } = useTranslation();
    const id = useRef(uniqueId('year-fields-by-usage-filter-'));
    const [open, toggleOpen] = useToggle(false);
    const allFields = fieldUsageFields.flatMap((usage) => usage.fields.toArray());
    const selectedFields = allFields.filter((field) => selectedFieldUuids.includes(field.uuid));

    useOutsideComponentClickListener(id.current, () => open && toggleOpen(), [open]);

    const handleApply = (nextSelectedFields: string[]) => {
        onApply(nextSelectedFields);
        toggleOpen();
    };

    return (
        <div className="relative" data-elementid={id.current}>
            <h4 className="font-medium">{t('field_filter')}</h4>
            <button
                className="bare-btn bordered p-2 rounded-sm w-80 h-10 flex justify-between cursor-pointer focus"
                onClick={toggleOpen}
            >
                <span className="truncate pr-1">
                    {selectedFields.length > 0 ? (
                        selectedFields.sort(compareName).map(prop('name')).join(', ')
                    ) : (
                        <em className="text-gray-400">{t('field_selector_no_selected')}</em>
                    )}
                </span>
                <i className="ion-chevron-down ml-1" />
            </button>
            {open && (
                <FilterList
                    selectedFields={selectedFieldUuids}
                    onApply={handleApply}
                    fieldUsageFields={fieldUsageFields}
                />
            )}
        </div>
    );
};

export default connect((state: AppState) => ({
    fieldUsageFields: selectYearFieldUsagesWithFields(state),
}))(YearFieldsByUsageFilter);

interface FilterListProps {
    selectedFields: string[];
    onApply: SingleParamVoidFunction<string[]>;
    fieldUsageFields: FieldUsageWithFields[];
}
const FilterList = ({ selectedFields, onApply, fieldUsageFields }: FilterListProps) => {
    const { t } = useTranslation();
    const [nextSelectedFields, setNextSelectedFields] = useState<string[]>(selectedFields);

    const handleChange = (fieldUuid: string) => {
        setNextSelectedFields(addOrRemoveInArray(nextSelectedFields, fieldUuid));
    };

    const handleUsageChange = (index: number) => {
        const fields = fieldUsageFields[index].fields.toArray();
        const fieldUuids = fields.map(getUuid);
        const allSelected = fields.every((field) => nextSelectedFields.includes(field.uuid));
        if (!allSelected) {
            setNextSelectedFields(uniq(nextSelectedFields.concat(fieldUuids)));
        } else {
            setNextSelectedFields(nextSelectedFields.filter((uuid) => !fieldUuids.includes(uuid)));
        }
    };

    return (
        <div className="bordered bg-white shadow rounded-sm absolute z-10 top-full w-full">
            <div className="flex justify-between p-2">
                <Button small className="px-2" onClick={() => onApply(nextSelectedFields)}>
                    {t('label_apply')}
                </Button>
                <Button small className="px-2" variant="outline" onClick={() => onApply([])}>
                    {t('label_clear')}
                </Button>
            </div>
            <ol className="list-none p-0 overflow-auto max-h-80">
                {fieldUsageFields.map(({ fieldUsage, fields }, index) => (
                    <Fragment key={fieldUsage.uuid}>
                        <li className="bordered-t p-2 relative flex">
                            <div
                                className="h-5 w-5 rounded mr-3"
                                style={{
                                    backgroundColor: fieldUsage.colour,
                                }}
                            />
                            <Checkbox
                                id={fieldUsage.uuid}
                                checked={fields.every((field) =>
                                    nextSelectedFields.includes(field.uuid)
                                )}
                                onChange={handleUsageChange.bind(null, index)}
                                label={<strong>{getUsageName(fieldUsage)}</strong>}
                            />
                        </li>
                        {fields.map((field) => (
                            <li className="bordered-t p-2 pl-10 relative" key={field.uuid}>
                                <Checkbox
                                    id={field.uuid}
                                    checked={nextSelectedFields.includes(field.uuid)}
                                    onChange={handleChange.bind(null, field.uuid)}
                                    label={field.name}
                                />
                            </li>
                        ))}
                    </Fragment>
                ))}
            </ol>
        </div>
    );
};
