import { useTranslation } from 'react-i18next';
import { differenceInCalendarDays, startOfDay } from 'date-fns';

import { useDateFormat } from './useDateFormat';

type DueDateLevel = 'urgent' | 'warn' | '';

const useDueDateWords = (date: Date): [DueDateLevel, string] => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    const dueDay = startOfDay(date);
    const today = startOfDay(Date.now());
    const diff = differenceInCalendarDays(dueDay, today);

    if (diff < -7) {
        return dueDay.getFullYear() !== today.getFullYear()
            ? ['urgent', t('create_task_due', { sprintf: [format(dueDay, 'd MMM yyyy')] })]
            : ['urgent', t('create_task_due', { sprintf: [format(dueDay, 'd MMM')] })];
    }
    if (diff < -1) {
        return ['urgent', t('due_days_ago', { sprintf: [-diff] })];
    }
    if (diff === -1) {
        return ['urgent', t('due_yesterday')];
    }
    if (diff === 0) {
        return ['urgent', t('due_today')];
    }
    if (diff === 1) {
        return ['warn', t('due_tomorrow')];
    }
    if (diff <= 7) {
        return ['', t('due_in_days', { sprintf: [diff] })];
    }
    if (dueDay.getFullYear() === today.getFullYear()) {
        return ['', t('create_task_due', { sprintf: [format(dueDay, 'd MMM')] })];
    }
    return ['', t('create_task_due', { sprintf: [format(dueDay, 'd MMM yyyy')] })];
};

export { useDueDateWords };
