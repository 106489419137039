import classNames from 'classnames';
import type Field from 'fields/Field';
import { getFieldWorkedArea } from 'fields/Field';
import { useCurrentYearSelector } from 'hooks/selectors';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { useSegmentTracking } from 'hooks/useSegmentTracking';
import SizeBubble from 'view/molecules/size-bubble/SizeBubble';
import RememberingLink from 'view/RememberingLink';
import PadlockIcon from 'view/svg/PadlockIcon';
import FormattedArea from 'view/units/FormattedArea';

import './FieldPreview.css';

interface FieldPreviewProps {
    field: Field;
}

const FieldPreview = ({ field }: FieldPreviewProps) => {
    const { viewField } = useSegmentTracking();
    const { currentFarm } = useCurrentFarm();
    const { currentYear } = useCurrentYearSelector();
    const area = getFieldWorkedArea(field, currentYear);

    return (
        <div
            className={classNames('FieldPreview', {
                FieldPreviewLocked: field.locked,
                FieldPreviewArchived: field.archived,
            })}
            onClick={viewField}
        >
            <RememberingLink
                to={`/farms/${currentFarm.uuid}/fields/${field.uuid}`}
                className="FieldPreviewLink focus"
            >
                {field.locked && <PadlockIcon className="FieldPreviewPadlock" />}
                <div className="FieldPreviewName">
                    <span>{field.name}</span>
                    {field.fieldId !== '' && (
                        <span className="FieldPreviewId">{field.fieldId}</span>
                    )}
                </div>
                <div className="FieldPreviewArea">
                    <SizeBubble className="mx-5">
                        <FormattedArea areaSqm={area} withUnit />
                    </SizeBubble>
                    <i className="ion-chevron-right" />
                </div>
            </RememberingLink>
        </div>
    );
};

export default FieldPreview;
