import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { selectDrawingBlocked } from 'farm-editing/farm-editing-state';
import { useDrawSelectTool } from 'hooks/useDrawSelectTool';
import type { AppState } from 'system/store';
import DrawingBlockedMessage from 'view/shapes/DrawingBlockedMessage';
import ShapeControl from 'view/shapes/ShapeControl';

import FieldBoundaryAuto from './FieldBoundaryAuto';

import styles from './FieldBoundaryDraw.module.css';

interface FieldBoundaryCreationActionsProps {
    includeAutoBoundary?: boolean;
    drawingBlocked: boolean;
}

const FieldBoundaryDraw = ({
    drawingBlocked,
    includeAutoBoundary = false,
}: FieldBoundaryCreationActionsProps) => {
    const { t } = useTranslation();
    const { selectTool, drawingTool } = useDrawSelectTool();

    return (
        <div className={styles.wrapper}>
            {drawingBlocked && <DrawingBlockedMessage className={styles.drawingBlockedMessage} />}

            {includeAutoBoundary && (
                <>
                    <h4>{t('field_boundary_auto_title')}</h4>
                    <FieldBoundaryAuto disabled={drawingBlocked} />
                    <h4>{t('field_boundary_auto_draw')}</h4>
                </>
            )}

            <p className={styles.drawSelectShapeInfo}>{t('draw_select_shape')}</p>

            <div className={styles.shapeControlButtonsGrup}>
                <ShapeControl
                    tool="area"
                    selected={drawingTool === 'area'}
                    onClick={() => selectTool('area')}
                    className={styles.buttonShapeControl}
                    disabled={drawingBlocked}
                />
                <ShapeControl
                    tool="circle"
                    selected={drawingTool === 'circle'}
                    onClick={() => selectTool('circle')}
                    className={styles.buttonShapeControl}
                    disabled={drawingBlocked}
                />
            </div>
        </div>
    );
};

export default connect((state: AppState) => ({
    drawingBlocked: selectDrawingBlocked(state),
}))(FieldBoundaryDraw);
