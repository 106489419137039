import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { createLogger } from '@fieldmargin/webapp-reporting';

const logger = createLogger('auth.account-api');

export const deleteAccountApi = () =>
    authorizedRequest({
        method: 'delete',
        url: '/auth-api/account/me',
    });

export const fetchIntercomUserHashApi = () =>
    authorizedRequest<{ userHash: string }>({
        url: '/auth-api/intercom/user-hash',
        params: { client: 'notes-app-web' },
    }).then(({ userHash }) => userHash);

export const changePassword = (oldPassword: string, newPassword: string) => {
    return Promise.resolve(
        authorizedRequest({
            method: 'post',
            url: `/auth-api/account/change_password`,
            data: { oldPassword, newPassword },
        })
    ).catch((err) => {
        let reason = 'Unable to change password, please try again';
        if (err.response && err.response.data.error === 'password-save') {
            reason = 'Cannot use previous password';
        } else if (err.response.data.error === 'old-password-invalid') {
            reason = 'Incorrect old password';
        } else if (
            err.response &&
            err.response.data.error_messages &&
            err.response.data.error_messages.newPassword === 'size must be between 7 and 100'
        ) {
            // this error does not come with a "error" field - so have to use error messages
            reason = 'Password must be a minimum of 7 characters';
        }

        // not a user error
        if (reason === 'Unable to change password, please try again') {
            logger.handleRequestError('Unable to change password')(err);
        }
        return Promise.reject(new Error(reason));
    });
};
