import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeature, GeoFeatureCollection, GeoPoint } from '@fieldmargin/webapp-geo';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { stopEditing } from 'farm-editing/farm-editing-state';
import { startNewManualSensor } from 'farm-editing/start-editing-reducer';
import { selectCurrentFarm } from 'farms/farms-state';
import { List, Set } from 'immutable';
import { ArrayField, Form } from 'informed';
import { defaultToZero } from 'lib/fp-helpers';
import { addOrRemoveInSet } from 'lib/immutil';
import { bindActionCreators } from 'redux';
import { createManualSensorApi } from 'sensors/manual/manual-sensors-api';
import { addManualSensor } from 'sensors/manual/manual-sensors-state';
import ManualSensor from 'sensors/manual/ManualSensor';
import Metric from 'sensors/Metric';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import EditableShapeInformation from 'sidebar/modules/shapes/components/EditableShapeInformation';
import ShapeAndFieldsField from 'sidebar/modules/shapes/ShapeAndFieldsField';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import { useFinishTutorialOnMount } from 'tutorials/tutorial-hooks';
import { TutorialTypes } from 'tutorials/TutorialTypes';
import { trackEvent } from 'utils/trackEvent';
import Fog from 'view/Fog';
import TextInputFormField from 'view/form/TextInputFormField';
import { required } from 'view/form/validations';

import MetricFormContainer from './form/MetricFormContainer';

const validateName = required('Your monitoring site needs a name');

interface ManualSensorNewProps {
    // From redux
    farm: Farm;
    startNewManualSensor: typeof startNewManualSensor;
    stopEditing: VoidFunction;
    editingGeoFeatureCollection: GeoFeatureCollection | null;
    addManualSensor: typeof addManualSensor;
}

interface FormValues {
    name: string;
    metrics: { metricName: string; metricType: string; metricUnit?: string }[];
}

enum ManualSensorNewSteps {
    NAME,
    LOCATION,
    METRICS,
}

const ManualSensorNew = ({
    farm,
    startNewManualSensor,
    stopEditing,
    editingGeoFeatureCollection,
    addManualSensor,
}: ManualSensorNewProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useFinishTutorialOnMount(TutorialTypes.DATA);
    useEffect(() => {
        startNewManualSensor();
        return stopEditing;
    }, []);

    const { pending, error, setPromise } = usePromise<ManualSensor>((manualSensor) => {
        addManualSensor(manualSensor);
        trackEvent('Manual sensor created', {
            farmUuid: farm.uuid,
        });
        navigate(`/farms/${farm.uuid}/data/${manualSensor.id}`);
    });

    const [step, setStep] = useState(ManualSensorNewSteps.NAME);
    const [addedMetrics, setAddedMetrics] = useState<Set<string>>(Set());

    const locationSet = defaultToZero(editingGeoFeatureCollection?.features.size) > 0;

    const handleNameChange = () => {
        if (!locationSet) {
            setStep(ManualSensorNewSteps.LOCATION);
        }
    };
    useEffect(() => {
        if (locationSet) {
            setStep(ManualSensorNewSteps.METRICS);
        }
    }, [locationSet]);

    const handleSubmit = (values: FormValues) => {
        if (locationSet) {
            const metrics = List(
                values.metrics.map((metricValues) => {
                    return Metric({
                        name: metricValues.metricName,
                        type: metricValues.metricType,
                        unit: metricValues.metricUnit,
                    });
                })
            );

            const manualSensor = new ManualSensor({
                name: values.name,
                location: editingGeoFeatureCollection?.features.first<GeoFeature>()
                    .geometry as GeoPoint,
                metrics,
            });

            setPromise(createManualSensorApi(farm.uuid, manualSensor));
        }
    };

    return (
        <Form<FormValues>
            className="scrollable"
            onSubmit={handleSubmit}
            initialValues={{
                name: '',
                metrics: [{ metricName: '', metricType: '' }],
            }}
        >
            {({ formState }) => (
                <>
                    <div className="non-scrolling">
                        <SidebarHeader className="px-5">
                            <Button
                                as={Link}
                                to={`/farms/${farm.uuid}/data`}
                                variant="danger-outline"
                                small
                            >
                                {t('cancel')}
                            </Button>
                            <SubmitButton
                                disabled={
                                    pending ||
                                    formState.values.name === '' ||
                                    formState.values.metrics?.[0]?.metricName === '' ||
                                    formState.values.metrics?.[0]?.metricType === '' ||
                                    editingGeoFeatureCollection?.features.size === 0
                                }
                                small
                            >
                                {t(pending ? 'creating' : 'create')}
                            </SubmitButton>
                        </SidebarHeader>
                    </div>
                    <div className="scrolling">
                        <SidebarModule>
                            <h4>{t('create_manual_sensor_title_prompt')}</h4>
                            <TextInputFormField
                                field="name"
                                placeholder={t('create_manual_sensor_title_hint_web')}
                                validate={validateName}
                                error={formState.errors.name}
                                inputClassName="w-full placeholder:italic"
                                onChange={handleNameChange}
                            />
                        </SidebarModule>
                        <SidebarModule className="relative">
                            {step === ManualSensorNewSteps.NAME && <Fog />}
                            <EditableShapeInformation
                                label={t('sensors_set_location')}
                                tools={List.of<DrawingTool>('point')}
                                maxShapes={1}
                            />
                            <ShapeAndFieldsField
                                shapes={editingGeoFeatureCollection ?? undefined}
                            />
                        </SidebarModule>
                        <div className="relative">
                            {step !== ManualSensorNewSteps.METRICS && <Fog />}
                            <SidebarModule editing noBorder className="pb-2">
                                <h4>{t('create_note_title')}</h4>
                                <p>{t('sensors_new_metric_description')}</p>
                            </SidebarModule>
                            <ArrayField field="metrics">
                                {({ add }) => (
                                    <div className="flex flex-col">
                                        {/* @ts-ignore ArrayField does not have Items as a property in the informed type defs */}
                                        <ArrayField.Items>
                                            {({ remove, field, index }) => (
                                                <MetricFormContainer
                                                    addedMetrics={addedMetrics}
                                                    add={(id: string) => {
                                                        setAddedMetrics(
                                                            addOrRemoveInSet(addedMetrics, id)
                                                        );
                                                        add();
                                                    }}
                                                    remove={(id: string) => {
                                                        setAddedMetrics(
                                                            addOrRemoveInSet(addedMetrics, id)
                                                        );
                                                        remove();
                                                    }}
                                                    field={field}
                                                    required={index === 0}
                                                />
                                            )}
                                            {/* @ts-ignore */}
                                        </ArrayField.Items>
                                    </div>
                                )}
                            </ArrayField>
                        </div>
                    </div>
                    {error && (
                        <div className="non-scrolling">
                            <SidebarError
                                title="Failed to save"
                                message="Sorry, we were unable to save your monitoring site."
                            />
                        </div>
                    )}
                </>
            )}
        </Form>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                startNewManualSensor,
                stopEditing,
                addManualSensor,
            },
            dispatch
        )
)(ManualSensorNew);
