interface GrapesIconProps {
    className?: string;
    fill?: string;
}

const GrapesIcon = ({ className, fill = '#4da4da' }: GrapesIconProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <g transform="matrix(0.719, 0.695, -0.695, 0.719, 0.212, -74.467)">
                <g transform="translate(73.638 32.41)">
                    <path
                        id="Path_3404"
                        style={{ fill }}
                        d="M43.7,26.3c0-4.1-2.8-7.6-6.7-8.6c1.3-4.7-1.4-9.7-6.2-11C28.5,6,26,6.3,23.9,7.5V6.3
			c0-4.1,1.6-8.1,4.5-11c0.7-0.8,0.6-2-0.2-2.7c-0.7-0.6-1.8-0.6-2.5,0c-2.7,2.7-4.5,6-5.3,9.7C15.8-0.3,10.1-1,4.8,0.4
			C-1.6,2.1-6.8,6.7-9.3,12.8c-0.3,0.8-0.1,1.7,0.6,2.3c3.5,2.7,7.7,4.2,12.1,4.4c-3.7,3.2-4.2,8.8-1,12.5c1.2,1.4,2.8,2.4,4.6,2.8
			c-1.3,4.7,1.4,9.7,6.2,11c0.1,0,0.1,0,0.2,0.1c0,0,0,0.1,0,0.1c-1.3,4.8,1.5,9.6,6.3,10.9c4.8,1.3,9.6-1.5,10.9-6.3
			c0.4-1.6,0.4-3.2,0-4.7c4.8-1.2,7.6-6.1,6.4-10.8c0-0.1,0-0.1-0.1-0.2C40.9,33.9,43.7,30.3,43.7,26.3z M10.7,13.9
			c0.7-2.7,3.5-4.3,6.2-3.6c0,0,0,0,0,0c1.2,0.3,2.3,1.1,2.9,2.2c-0.5,1.7-0.6,3.5-0.1,5.2c-1.6,0.4-3,1.2-4.2,2.4
			c-1.2-1.3-2.8-2.1-4.5-2.5C10.6,16.4,10.4,15.1,10.7,13.9L10.7,13.9z M17.6,28.8c-1.4-2.4-0.6-5.6,1.9-7c2.4-1.4,5.6-0.6,7,1.9
			c1.4,2.4,0.6,5.6-1.9,7c0,0,0,0,0,0C22.1,32.1,19,31.2,17.6,28.8z M28.4,10.1c2.8-0.1,5.1,2.1,5.2,5c0.1,2.8-2.1,5.1-5,5.2l0,0
			c-1.3-1.4-2.9-2.4-4.8-2.8c-1.3-2.5-0.3-5.6,2.2-6.8C26.8,10.3,27.6,10.1,28.4,10.1L28.4,10.1z M5.8,4.1c4.7-1.2,9.7-0.4,13.8,2.2
			c-0.1,0.3-0.3,0.5-0.4,0.7c-4.5-1.9-9.8,0.1-11.7,4.7c-0.5,1.2-0.8,2.5-0.7,3.8c-4.1,0.6-8.3-0.4-11.8-2.6
			C-2.9,8.6,1.1,5.3,5.8,4.1z M4.2,26.3c0-2.8,2.3-5.1,5.1-5.1c1.7,0,3.4,0.9,4.3,2.3c-0.6,1.8-0.6,3.7,0,5.5c-1.5,2.4-4.7,3-7,1.5
			C5.1,29.6,4.2,28,4.2,26.3z M10.7,38.6c-0.3-1.2-0.2-2.5,0.4-3.6c1.7-0.4,3.3-1.2,4.5-2.5c1.1,1.2,2.6,2,4.2,2.4
			c-0.5,1.7-0.4,3.5,0.1,5.2c-1.5,2.4-4.7,3-7,1.5C11.8,40.9,11.1,39.8,10.7,38.6z M27,49.6c-0.7,2.7-3.5,4.3-6.2,3.6
			c-2.7-0.7-4.3-3.5-3.6-6.2c0.1-0.4,0.2-0.7,0.4-1c0.2,0,0.3-0.1,0.5-0.1c1.5-0.4,2.9-1.2,4.1-2.4c1.2,1.2,2.8,2.1,4.5,2.5
			C27.1,47.1,27.3,48.4,27,49.6L27,49.6z M33.3,38.6c-0.7,2.7-3.5,4.3-6.2,3.6s-4.3-3.5-3.6-6.2c0.1-0.4,0.2-0.7,0.4-1.1
			c1.9-0.4,3.5-1.3,4.8-2.8c2.8,0.1,5,2.5,4.8,5.3C33.5,37.9,33.4,38.3,33.3,38.6L33.3,38.6z M35,31.3c-1.2-1.3-2.7-2.2-4.4-2.7
			c0,0,0-0.1,0-0.1c0.4-1.5,0.4-3.2,0-4.7c1.7-0.4,3.2-1.4,4.4-2.7c2.8,0.1,5,2.5,4.8,5.3C39.7,29.1,37.7,31.2,35,31.3L35,31.3z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default GrapesIcon;
