import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import { Option, Select } from 'informed';
import { preventDefaultAnd } from 'lib/dom';
import { always } from 'ramda';
import SidebarModule from 'sidebar/modules/SidebarModule';
import ErrorMessage from 'view/ErrorMessage';
import OptionalLabel from 'view/form/OptionalLabel';
import TextInputFormField from 'view/form/TextInputFormField';
import { required } from 'view/form/validations';
import TemperatureUnit from 'view/units/TemperatureUnit';

const validateMetricName = required('Your metric needs a name');
const validateMetricType = required('Your metric needs a type');

interface MetricFormFieldsProps {
    field: string;
    hidden: boolean;
    added: boolean;
    required: boolean;

    type: string;
    error: boolean;
    onAdd: VoidFunction;
    onCancelEdit: VoidFunction;
    onSaveEdit: VoidFunction;
}

const MetricFormFields = ({
    field,
    hidden,
    added,
    required,
    type,
    error,
    onAdd,
    onCancelEdit,
    onSaveEdit,
}: MetricFormFieldsProps) => {
    const { t } = useTranslation();
    const handleAdd = preventDefaultAnd(onAdd);
    const handleCancelEdit = preventDefaultAnd(onCancelEdit);
    const handleSaveEdit = preventDefaultAnd(onSaveEdit);
    const shouldValidate = required || added;
    return (
        <SidebarModule editing={!added} noBorder className={c('pt-1 bordered-b', { hidden })}>
            <div className="flex flex-wrap gap-2 items-end">
                <TextInputFormField
                    field={`${field}.metricName`}
                    validate={shouldValidate ? validateMetricName : always(undefined)}
                    label={t('name')}
                    inputClassName="w-40"
                />
                <div>
                    <label htmlFor={`${field}.metricType`}>{t('type')}</label>
                    <Select
                        id={`${field}.metricType`}
                        field={`${field}.metricType`}
                        validate={shouldValidate ? validateMetricType : always(undefined)}
                        validateOnChange
                        validateOnBlur
                        className="bordered p-2 rounded-sm h-10 focus"
                    >
                        <Option value="">{t('choose_one')}</Option>
                        <Option value="temperature">{t('temperature')}</Option>
                        <Option value="percentage">{t('create_metric_type_percentage')}</Option>
                        <Option value="number">{t('create_metric_type_number')}</Option>
                        <Option value="string">{t('create_metric_type_text')}</Option>
                    </Select>
                </div>
                {type === 'number' || type === 'string' ? (
                    <TextInputFormField
                        field={`${field}.metricUnit`}
                        label={t('unit')}
                        optional
                        inputClassName="w-32"
                    />
                ) : (
                    <div>
                        <label>
                            {t('unit')} <OptionalLabel />
                        </label>
                        <p className="w-32 simple-text-input mb-0 leading-10">
                            {type === 'temperature' && <TemperatureUnit />}
                            {type === 'percentage' && '%'}
                        </p>
                    </div>
                )}
                {!added && (
                    <Button variant="outline" onClick={handleAdd}>
                        {t('sensors_new_metric_add')}
                    </Button>
                )}
            </div>
            {error && (
                <ErrorMessage className="mt-3">
                    {t('sensors_new_metric_add_validation')}
                </ErrorMessage>
            )}
            {added && (
                <div className="flex gap-4 mt-4">
                    <Button onClick={handleCancelEdit} variant="danger-outline" small>
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleSaveEdit} variant="outline" small>
                        {t('save')}
                    </Button>
                </div>
            )}
        </SidebarModule>
    );
};

export default MetricFormFields;
