export const APP_ROUTES = {
    Root: '/',

    Account: {
        Root: 'account',
        Delete: 'delete',
        Success: 'success',
    },

    Auth: {
        Root: 'auth',
        Complete: 'complete',
    },

    Billing: {
        Root: 'billing/*',
    },

    CreateFarm: {
        Root: 'create-farm',
    },

    Flags: {
        Root: 'flags',
    },

    Operations: {
        Root: 'operations',
        New: 'new',
        Delete: 'delete',
        AddFuel: 'add-fuel',
        Operation: ':operationUuid',
        AddFields: 'add-fields',
        EditFields: 'edit-fields',
        AddRecordings: 'add-recording',
        AddOutput: 'add-output',
        OperationComments: 'comments',
        Print: 'operations/:operationUuid/job-sheet',
    },

    Notes: {
        Root: 'notes',
        New: 'new',
        Note: ':noteUuid',
        Print: 'notes/:noteUuid/print',
        Deleted: 'delete',
    },

    Farms: {
        Root: 'farms',
        Farm: 'farms/:farmUuid',
    },

    Fields: {
        Root: 'fields',
        New: 'new',
        AutoBoundary: 'new/auto',
        Usage: 'usage',
        BoundaryUploader: 'boundary-uploader',
        RPA: 'rpa',
        Edit: 'edit',
        JohnDeere: 'john-deere',
        Field: ':fieldUuid',
        History: 'history',
        Reports: 'reports',
        Delete: 'delete',
        Print: 'fields/:fieldUuid/print',
        SubFields: 'sub-fields',
        DownloadCompletion: 'completion',
    },

    Features: {
        Root: 'features',
        New: 'new',
        Delete: 'delete',
        Feature: ':featureUuid',
        History: 'history',
    },

    Join: {
        Root: 'join',
    },

    Map: {
        Root: 'map',
    },

    Maps: {
        Root: 'maps',
        Group: ':mapGroupUuid',
        GroupDelete: ':mapGroupUuid/delete',
    },

    Team: { Root: 'team' },

    Vehicles: { Root: 'vehicles' },

    Data: {
        Root: 'data',
        EditSensor: ':sensorId/edit',
        EditSensorLocation: ':sensorId/edit-location',
        New: 'new',
        SensorDetails: ':sensorId',
        DeleteSensor: ':sensorId/delete',
        DeleteSensorGroup: ':sensorId/readings/:readingGroupId/delete',
    },

    Livestock: {
        Root: 'livestock',
        New: 'new',
        Details: ':herdUuid',
        Edit: ':herdUuid/edit',
        Move: ':herdUuid/move',
        Split: ':herdUuid/split',
        Merge: ':herdUuid/merge',
        MergeDetails: 'details',
        Delete: ':herdUuid/delete',
        DeleteHerdLocation: ':herdUuid/delete-move/:herdLocationUuid',
        History: 'livestock/:herdUuid/history',
    },

    Organisations: {
        Root: 'organisations',
        Organisation: ':organisationUuid',
        Settings: 'settings',
    },

    Reporting: {
        Root: 'reporting',
        Input: 'input',
        InputSummary: 'input-summary',
        FieldInputSummary: 'field-input-summary',
        InputCosts: 'input-costs',
        InputCostsTable: 'table',
        EditCosts: 'edit-costs',
        Yeld: 'yield',
        Nutrients: 'nutrients',
        Graph: 'graph',

        GrossMargin: 'gross-margin',
        GrossMarginUsage: 'usage',
        GrossMarginUsageTable: 'table',
    },

    Onboarding: {
        Root: 'onboarding',
    },

    AddFields: {
        Root: 'add-fields',
    },

    Boundaries: {
        Root: 'boundaries',
        Auto: 'auto',
        Edit: 'edit',
        Select: 'select',
        Draw: 'draw',
        Save: 'save',
    },
} as const;
