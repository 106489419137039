import { useTranslation } from 'react-i18next';
import {
    activeJobsFilerSelector,
    countJobsFilter,
    jobsState,
    notesFilterState,
    todoFilterState,
    toggleFilterNotes,
    toggleFilterTodo,
    updateFilterByJobs,
} from 'store/slices/notes.slice';
import { useAppDispatch, useAppSelector } from 'system/store';

import { FilterSelect } from '../FilterSelect';

import { useTranslatedJobs } from './useTranslatedJobs';

function CheckboxTodo() {
    const dispatch = useAppDispatch();
    const { toDoFilter } = useTranslatedJobs();
    const isTodo = useAppSelector(todoFilterState);

    const onChangeCheckbox = () => {
        dispatch(toggleFilterTodo());
    };

    return (
        <FilterSelect.Checkbox isSelected={isTodo} onChange={onChangeCheckbox}>
            {toDoFilter.label}
        </FilterSelect.Checkbox>
    );
}

function CheckboxNotes() {
    const dispatch = useAppDispatch();
    const { notesFilter } = useTranslatedJobs();
    const areNotes = useAppSelector(notesFilterState);

    const onChangeCheckbox = () => {
        dispatch(toggleFilterNotes());
    };

    return (
        <FilterSelect.Checkbox isSelected={areNotes} onChange={onChangeCheckbox}>
            {notesFilter.label}
        </FilterSelect.Checkbox>
    );
}

function CheckboxesJobs() {
    const dispatch = useAppDispatch();
    const jobs = useAppSelector(jobsState);
    const onChangeCheckbox = (value: string[]) => {
        dispatch(updateFilterByJobs(value));
    };
    const { jobsData } = useTranslatedJobs();

    return (
        <FilterSelect.Group value={jobs} onChange={onChangeCheckbox}>
            {jobsData.map((job) => (
                <FilterSelect.Checkbox key={job.id} value={job.id}>
                    {job.label}
                </FilterSelect.Checkbox>
            ))}
        </FilterSelect.Group>
    );
}

function FieldJobButton() {
    const { t } = useTranslation();
    const { allTranslatedJobs } = useTranslatedJobs();

    const count = useAppSelector(countJobsFilter);
    const activeJobs = useAppSelector(activeJobsFilerSelector);
    const activeJob = allTranslatedJobs.find((job) => job.id === activeJobs[0])?.label;

    const label = activeJob || t('operation_details_type');

    const buttonLabel = `${label} ${count > 1 ? `(+${count - 1})` : ''}`;

    return <FilterSelect.ButtonChevron>{buttonLabel}</FilterSelect.ButtonChevron>;
}

function FilterByJobs() {
    const { t } = useTranslation();

    return (
        <FilterSelect.Root>
            <FieldJobButton />
            <FilterSelect.Popover>
                <div>
                    <CheckboxTodo />
                    <CheckboxNotes />
                </div>
                <span>{t('field_job')}</span>
                <CheckboxesJobs />
            </FilterSelect.Popover>
        </FilterSelect.Root>
    );
}

export { FilterByJobs };
