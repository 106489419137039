import { useEffect, useRef } from 'react';
import type { User } from '@fieldmargin/webapp-auth';
import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePrevious } from '@fieldmargin/webapp-state';
import { getPaymentWebConfig } from 'lib/config';
import type { LoadTimeState } from 'system/load-time-state';
interface PaymentAppProps {
    data: { farm: Farm; user: User; trialAvailable: boolean };
    events: any;
    loadTimeState: LoadTimeState;
}

const PaymentApp = ({ data, events, loadTimeState }: PaymentAppProps) => {
    const hostEl = useRef<HTMLDivElement | null>(null);
    const prevFarm = usePrevious(data.farm);

    useEffect(() => {
        const ableToStart = hostEl.current !== null && window.FMPayment !== undefined;
        const farmDoesNotMatch = prevFarm === undefined || prevFarm.uuid !== data.farm.uuid;

        if (ableToStart && farmDoesNotMatch) {
            window.FMPayment?.unmount();
            window.FMPayment?.init({
                data,
                configSpec: getPaymentWebConfig(),
                requester: authorizedRequest,
                hostEl: hostEl.current as HTMLDivElement,
                events,
                loadTimeState,
            });
        }
    }, [data, prevFarm]);

    useEffect(() => {
        return () => {
            window.FMPayment?.unmount();
        };
    }, []);

    return <div id="payment-app" className="payment-app bg-white h-content-full" ref={hostEl} />;
};

export default PaymentApp;
