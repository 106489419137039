import type { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Extent, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { concatExtent, extentFromGeo } from '@fieldmargin/webapp-geo';
import type { DrawingTool } from '@fieldmargin/webapp-ol-map';
import Button from '@fieldmargin/webapp-styling/components/button';
import { removeEditingGeoFeatureById } from 'farm-editing/farm-editing-state';
import type { List } from 'immutable';
import FeatureFlags from 'lib/feature-flags';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';
import ShapeBubbles from 'view/shapes/ShapeBubbles';
import ShapeControls from 'view/shapes/ShapeControls';

import ViewOnMap from './ViewOnMap';

interface EditableShapeInformationProps {
    // From redux
    editingGeoFeatureCollection: GeoFeatureCollection | null;
    removeEditingGeoFeatureById: typeof removeEditingGeoFeatureById;
    changeModal: typeof changeModal;

    // From parent
    tools?: List<DrawingTool>;
    maxShapes?: number;
    extraExtent?: Extent | null;
    label: string;
    children?: ReactNode | null;
    className?: string;
    fieldsAttachable?: boolean;
    includeEmptyMessage?: boolean;
}

const EditableShapeInformation = ({
    editingGeoFeatureCollection,
    removeEditingGeoFeatureById,
    changeModal,
    tools,
    maxShapes,
    extraExtent,
    label,
    children,
    className,
    fieldsAttachable,
    includeEmptyMessage,
}: EditableShapeInformationProps) => {
    const { t } = useTranslation();
    if (!editingGeoFeatureCollection) {
        // This happens just as an edit screen is loaded, before the edit state has been set
        return null;
    }

    const handleEditJson = (e: MouseEvent) => {
        e.preventDefault();
        changeModal({ name: 'shape-edit', props: {} });
    };

    const handleRemove = (id: string) => {
        // If there is only one tool, we select it by default
        const nextDrawingTool = tools && tools.size === 1 ? tools.get(0, 'area') : null;
        removeEditingGeoFeatureById({ id, nextDrawingTool });
    };

    let viewExtent = extentFromGeo(editingGeoFeatureCollection);
    if (extraExtent) {
        viewExtent = viewExtent ? concatExtent(viewExtent, extraExtent) : extraExtent;
    }
    return (
        <div className={className}>
            <h4>{label || t('draw_location')}</h4>
            <ShapeControls
                tools={tools}
                maxShapes={maxShapes}
                fieldsAttachable={fieldsAttachable}
            />
            <div>
                <>
                    <ShapeBubbles
                        geoFeatureCollection={editingGeoFeatureCollection}
                        onRemove={handleRemove}
                        includeEmptyMessage={includeEmptyMessage}
                    />
                    {viewExtent && <ViewOnMap extent={viewExtent} className="my-3" />}
                    {FeatureFlags.get('adminTools') && (
                        <div>
                            <Button
                                variant="secondary"
                                onClick={handleEditJson}
                                className="mb-3"
                                small
                            >
                                Edit as JSON
                            </Button>
                        </div>
                    )}
                    {children}
                </>
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,
    }),
    (dispatch) => bindActionCreators({ removeEditingGeoFeatureById, changeModal }, dispatch)
)(EditableShapeInformation);
