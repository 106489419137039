import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { useCurrentYearSelector, useMesurementUnitSelector } from 'hooks/selectors';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import type { FieldInputReportSummary } from 'inputs/InputReportSummary';
import { compareName } from 'lib/util/text';
import EditInputCostsButton from 'reporting/EditInputCostsButton';
import ExportReportButton from 'reporting/ExportReportButton';
import {
    getFieldsYearInputSummaryApi,
    getFieldsYearInputSummaryDownloadLink,
} from 'reporting/reporting-api';
import ReportStatusFilter from 'reporting/ReportStatusFilter';
import YearFieldsByUsageFilter from 'reporting/YearFieldsByUsageFilter';

import FieldInputReportTable from './FieldInputReportTable';

function FieldInputReport() {
    const { t } = useTranslation();
    const { currentFarm } = useCurrentFarm();
    const { currentYear } = useCurrentYearSelector();
    const { areaMeasurementUnit } = useMesurementUnitSelector();

    const [selectedFields, setSelectedFields] = useState<string[]>([]);
    const [statusFilter, setStatusFilter] = useState('');
    const [reports, setReports] = useState<FieldInputReportSummary[]>([]);
    const getReport = usePromise<FieldInputReportSummary[]>(setReports);

    useEffect(() => {
        getReport.setPromise(
            getFieldsYearInputSummaryApi(
                currentFarm.uuid,
                selectedFields,
                currentYear,
                statusFilter === 'all' ? null : statusFilter.toUpperCase(),
                areaMeasurementUnit
            )
        );
    }, [statusFilter, selectedFields, currentFarm, currentYear, areaMeasurementUnit]);

    return (
        <div className="relative">
            <Button
                as={Link}
                to={`/farms/${currentFarm.uuid}/reporting`}
                className="absolute top-0 left-0 flex items-center gap-3"
                variant="subtle"
            >
                {icon('back')} {t('back')}
            </Button>
            <h1 className="uppercase text-center text-2xl">{t('field_input_report_title')}</h1>
            <div className="flex items-end gap-6 mb-6">
                <YearFieldsByUsageFilter
                    onApply={setSelectedFields}
                    selectedFieldUuids={selectedFields}
                />
                <EditInputCostsButton farm={currentFarm} />
                <ReportStatusFilter
                    filter={statusFilter}
                    setFilter={setStatusFilter}
                    className="ml-auto"
                />
                <ExportReportButton
                    filename="field-input-report"
                    downloadLink={getFieldsYearInputSummaryDownloadLink(
                        currentFarm.uuid,
                        selectedFields,
                        currentYear,
                        statusFilter,
                        areaMeasurementUnit
                    )}
                />
            </div>
            {selectedFields.length === 0 && (
                <p className="text-base">{t('field_input_report_no_fields')}</p>
            )}
            {selectedFields.length > 0 &&
                (getReport.notYetFetched ? (
                    <p>
                        <em>{t('reporting_loading')}</em>
                    </p>
                ) : reports.length === 0 ? (
                    <p>{t('reporting_no_data')}</p>
                ) : (
                    reports
                        .sort(compareName)
                        .map((report) => (
                            <FieldInputReportTable
                                key={report.uuid}
                                farm={currentFarm}
                                year={currentYear}
                                report={report}
                            />
                        ))
                ))}
        </div>
    );
}

export { FieldInputReport };
