import { useCurrentItemOutsideRoute } from 'hooks/useCurrentItemOutsideRoute';

export const useItemAttachmentUuids = (): string[] => {
    const { note, operation, herd } = useCurrentItemOutsideRoute();

    if (note !== undefined) {
        return note.fieldUuids.concat(note.featureUuids);
    }

    if (operation !== undefined) {
        return operation.fields?.map((opField) => opField.fieldUuid).toArray() ?? [];
    }

    if (herd !== undefined) {
        return [herd.herdLocation?.fieldUuid ?? ''];
    }
    return [];
};
