import type { ComponentProps, CSSProperties } from 'react';
import { DEFAULT_COLOUR } from 'lib/colours';

import styles from './OperationField.module.css';

interface ItemProps extends ComponentProps<'li'> {
    completed: boolean;
    fieldColor: string | undefined;
}

function List({ ...props }: ComponentProps<'ol'>) {
    return <ol className={styles.ol} {...props} />;
}

function Item({ completed, fieldColor, ...props }: ItemProps) {
    return (
        <li
            className={styles.li}
            data-completed={completed}
            style={{ '--usage-marker': fieldColor ?? DEFAULT_COLOUR } as CSSProperties}
            {...props}
        />
    );
}

List.displayName = 'OperationField.List';
Item.displayName = 'OperationField.Item';

export const OperationField = {
    List,
    Item,
};
