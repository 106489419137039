import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import icon from 'components/icons/icon';
import type Field from 'fields/Field';
import type { List } from 'immutable';
import { getSizesOfGeometry } from 'lib/geo/shape-size-cacher';
import { compareName } from 'lib/util/text';
import BubbleTag from 'view/molecules/BubbleTag';
import FormattedArea from 'view/units/FormattedArea';

interface NoteFieldBubblesProps {
    fields: List<Field>;
    onRemove?: (fieldUuid: string) => void;
    children?: ReactNode;
}

const NoteFieldBubbles = ({ fields, onRemove, children }: NoteFieldBubblesProps) => {
    const { t } = useTranslation();
    let totalArea = 0;
    const items = fields.sort(compareName).map((field) => {
        if (field.geoJson) {
            const sizes = getSizesOfGeometry(field.uuid, field.geoJson);
            if (sizes.area > 0) {
                totalArea += sizes.area;
            }
            return (
                <BubbleTag key={field.uuid} className="max-w-full">
                    <span className="truncate">{field.name}</span>
                    <span className="flex-shrink-0">
                        : <FormattedArea areaSqm={sizes.area} withUnit />
                    </span>
                    {onRemove && (
                        <div
                            className="ml-3 cursor-pointer flex-shrink-0"
                            onClick={() => {
                                onRemove(field.uuid);
                            }}
                        >
                            {icon('edit-remove', 'red', undefined, { marginTop: 0 })}
                        </div>
                    )}
                </BubbleTag>
            );
        }
    });

    return (
        <div className="mb-3">
            <p className="mb-2">
                {t('create_note_attached_fields')}
                {totalArea > 0 && (
                    <>
                        {' '}
                        <FormattedArea areaSqm={totalArea} withUnit />
                    </>
                )}
                :
            </p>
            <div
                className={c(
                    'flex items-start flex-wrap gap-3',
                    children !== undefined ? 'mb-3' : ''
                )}
            >
                {items}
            </div>
            {children}
        </div>
    );
};

export default NoteFieldBubbles;
