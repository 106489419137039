import { createAction, handleActions } from '@fieldmargin/webapp-state';
import { mapSet } from 'lib/fp-helpers';
import type Media from 'media/Media';
import type Note from 'notes/Note';
import { addNewNote } from 'notes/note-actions';

import type { Discussion } from './Discussion';

export type DiscussionsState = Map<string, Discussion[]>;

export const setItemDiscussions = createAction<
    DiscussionsState,
    { uuid: string; discussions: Discussion[] }
>('setItemDiscussions', (state, { uuid, discussions }) => mapSet(state, uuid, discussions));

export const addItemDiscussion = createAction<
    DiscussionsState,
    { uuid: string; discussion: Discussion }
>('addItemDicussion', (state, { uuid, discussion }) => {
    const items = state.get(uuid);
    return items === undefined
        ? state.set(uuid, [discussion])
        : mapSet(state, uuid, [...items, discussion]);
});

export const discussionsReducer = handleActions<DiscussionsState>(
    new Map(),
    [setItemDiscussions, addItemDiscussion],
    {
        [addNewNote.toString()]: (
            state: DiscussionsState,
            payload: { note: Note; discussion?: Discussion; media?: Media[] }
        ) =>
            mapSet(
                state,
                payload.note.uuid,
                payload.discussion !== undefined ? [payload.discussion] : []
            ),
    }
);
