import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import { usePageParams } from 'hooks/usePageParamas';
import { useSegmentTracking } from 'hooks/useSegmentTracking';
import type { List } from 'immutable';
import { fetchRpaParcels } from 'integrations/integrations-api';
import { trackEvent } from 'utils/trackEvent';
import ErrorMessage from 'view/ErrorMessage';
import ExternalLink from 'view/ExternalLink';

interface ImportRpaParcelsProps {
    setRpaFeatures: (features: List<GeoFeature> | undefined) => void;
}

const ImportRpaParcels = ({ setRpaFeatures }: ImportRpaParcelsProps) => {
    const { t } = useTranslation();
    const { farmUuid } = usePageParams();
    const { rpaNameFields } = useSegmentTracking();
    const [agreedTerms, setAgreedTerms] = useState(false);
    const { pending, error, setPromise } = usePromise<List<GeoFeature> | undefined>(
        (rpaFeatures) => {
            if (rpaFeatures && rpaFeatures.size === 0) {
                setNoFeatures(true);
            } else {
                setRpaFeatures(rpaFeatures);
            }
        }
    );
    const [noFeatures, setNoFeatures] = useState(false);
    const sbiRef = useRef<HTMLInputElement>(null);

    const fetchParcels = () => {
        if (sbiRef.current) {
            setNoFeatures(false);
            trackEvent('RPA land parcels imported', { farmUuid });
            setPromise(fetchRpaParcels(sbiRef.current.value));
            rpaNameFields();
        }
    };

    let rpaLink = 'https://environment.data.gov.uk/rpa';
    if (sbiRef.current && sbiRef.current.value) {
        rpaLink += `/terms/${sbiRef.current.value}`;
    }

    return (
        <div className="ImportRpaParcels">
            <p>{t('rpa_import_desc')}</p>
            <div className="mb-5">
                <label>{t('rpa_sbi_number')}:</label>
                <input type="text" id="sbi" ref={sbiRef} className="simple-text-input" />
            </div>
            <div className="mb-5">
                <div className="mb-3">
                    <input
                        type="radio"
                        name="user-type"
                        value="farm"
                        defaultChecked={true}
                        id="user-type-farm"
                        className="fancy-radio"
                    />{' '}
                    <label htmlFor="user-type-farm">{t('rpa_declaration_farm_owner')}</label>
                </div>
                <div className="mb-8">
                    <input
                        type="radio"
                        name="user-type"
                        value="agent"
                        id="user-type-agent"
                        className="fancy-radio"
                        onChange={() => window.open(rpaLink)}
                    />{' '}
                    <label htmlFor="user-type-agent">{t('rpa_declaration_agent')}</label>
                    <small className="block mt-3 w-2/3">{t('rpa_declaration_agent_desc')}</small>
                </div>
                <div>
                    <Checkbox
                        checked={agreedTerms}
                        onChange={() => setAgreedTerms(!agreedTerms)}
                        id="agree-terms"
                        label={
                            <Markdown
                                components={{
                                    a: (props: any) => (
                                        <ExternalLink
                                            {...props}
                                            className="text-fm-sky underline"
                                        />
                                    ),
                                }}
                            >
                                {t('rpa_terms', { sprintf: [rpaLink] })}
                            </Markdown>
                        }
                    />{' '}
                </div>
            </div>
            {error && <ErrorMessage>{t('rpa_sbi_error')}</ErrorMessage>}
            {noFeatures && <p className="mb-4 font-bold">{t('rpa_sbi_no_boundaries')}</p>}
            <Button onClick={fetchParcels} disabled={pending || !agreedTerms}>
                {pending ? t('importing') : t('rpa_fetch_boundaries')}
            </Button>
        </div>
    );
};

export default ImportRpaParcels;
