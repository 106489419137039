import { useTranslation } from 'react-i18next';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import type Herd from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import { useDateFormat } from 'hooks/useDateFormat';
import { getDisplayName } from 'lib/util/names';

import type { HerdHistoryRemove } from './herd-history-generator';

interface HerdHistoryRemoveItemProps {
    herd: Herd;
    historyItem: HerdHistoryRemove;
    farmUsers: FarmUser[];
}

const HerdHistoryRemoveItem = ({ herd, historyItem, farmUsers }: HerdHistoryRemoveItemProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    return (
        <li className="p-0 pb-5">
            <strong className="mb-2 block text-xxs text-gray-500">
                {format(historyItem.date, 'do MMMM yyyy')}
            </strong>
            <span className="flex">
                {herdIcon(herd.type, 'gray')}
                <span className="ml-3">
                    <strong className="block">
                        {t('livestock_details_history_removed', {
                            sprintf: [
                                historyItem.item.herdLocation.size,
                                historyItem.item.fieldName
                                    ? historyItem.item.fieldName
                                    : t('livestock_details_location_no_location'),
                            ],
                        })}
                    </strong>
                    {historyItem.item.herdLocation.durationHours !== undefined && (
                        <span className="block text-gray-500">
                            {t('livestock_details_history_grazed', {
                                sprintf: [
                                    Math.round(historyItem.item.herdLocation.durationHours / 24),
                                ],
                            })}
                        </span>
                    )}
                    <span className="block text-xxs text-gray-500">
                        {t('created_by')}{' '}
                        {getDisplayName(farmUsers, historyItem.item.herdLocation.createdByUserId)}
                    </span>
                </span>
            </span>
        </li>
    );
};

export default HerdHistoryRemoveItem;
