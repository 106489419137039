import { geojson, serialize } from '@fieldmargin/webapp-geo';
import type { FarmMapSubFieldProps } from 'farm-map/types';
import type SubField from 'fields/sub-fields/SubField';
import type { SubFieldTool } from 'fields/sub-fields-state';
import type { Feature, Geometry, Polygon } from 'geojson';
import { useAppSelector } from 'system/store';

export type FarmMapSubFieldFeature = Feature<Geometry, FarmMapSubFieldProps>;

export const useFarmMapSubFields = () => {
    const subFields = useAppSelector((state) => state.subFieldsState.subFields.toArray());
    const selectedTool = useAppSelector((state) => state.subFieldsState.selectedTool);
    const selectedSubFields = useAppSelector((state) =>
        state.subFieldsState.selectedSubFields.toArray()
    );

    return getFarmMapSubFields(subFields, selectedTool, selectedSubFields);
};

export const getFarmMapSubFields = (
    subFields: SubField[],
    selectedTool: SubFieldTool | undefined,
    selectedSubFields: string[]
): FarmMapSubFieldFeature[] => {
    return subFields
        .filter((subField) => subField.field.geoJson !== null)
        .map((subField) =>
            subField.field
                .geoJson!.features.map(
                    (geoFeature) =>
                        geojson.createFeatureWithId({
                            type: 'Feature',
                            geometry: serialize(geoFeature.geometry) as Polygon,
                            properties: {
                                type: 'SUB-FIELD',
                                uuid: subField.id,
                                name: subField.field.name,
                                editable:
                                    selectedTool === undefined &&
                                    selectedSubFields.length === 1 &&
                                    selectedSubFields.includes(subField.id),
                                passiveEditMode:
                                    selectedTool !== undefined &&
                                    selectedSubFields.includes(subField.id),
                                colour: subField.colour,
                                strokeWeight: 3,
                                strokeOpacity: 1,
                                fillOpacity: 0.75,
                            },
                        }) as FarmMapSubFieldFeature
                )
                .toArray()
        )
        .flat();
};
