import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import c from 'classnames';
import type { HerdType } from 'herd/Herd';
import type Herd from 'herd/Herd';
import { getHerdTypeI18NKey } from 'herd/Herd';
import { herdIcon } from 'herd/herd-icons';
import SidebarModule from 'sidebar/modules/SidebarModule';

import HerdPreview from './HerdPreview';

interface HerdTypeHerdsListProps {
    type: HerdType;
    herds: Herd[];
    archived?: boolean;
}

const HerdTypeHerdsList = ({ type, herds, archived = false }: HerdTypeHerdsListProps) => {
    const { t } = useTranslation();
    const [open, toggleOpen] = useToggle(true);

    return (
        <>
            <SidebarModule
                editing
                className={c('flex justify-between cursor-pointer', { 'text-gray-400': archived })}
                onClick={toggleOpen}
            >
                <strong className="flex-grow">
                    <span className="mr-2">{herdIcon(type, archived ? 'gray' : 'dark-blue')}</span>{' '}
                    {t(getHerdTypeI18NKey(type))}
                </strong>
                <strong>{herds.reduce((total, herd) => total + herd.size, 0)}</strong>
                <div className="w-1/12 text-right text-black">
                    <i className={open ? 'ion-arrow-down-b' : 'ion-arrow-right-b'} />
                </div>
            </SidebarModule>
            {open && herds.map((herd) => <HerdPreview key={herd.uuid} herd={herd} />)}
        </>
    );
};

export default HerdTypeHerdsList;
