import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

const TipBox = ({ children }: PropsWithChildren) => {
    const { t } = useTranslation();
    return (
        <div className="text-left">
            <div className="inline-block py-1 px-4 bordered-tlr rounded-t text-fm-sky bg-white relative top-px">
                <h4 className="mb-0">{t('label_tip', { defaultValue: 'Tip' })}</h4>
            </div>
            <div className="text-center bordered rounded-b rounded-r px-6 py-4">{children}</div>
        </div>
    );
};

export default TipBox;
