import type Field from 'fields/Field';
import { getFieldWorkedArea } from 'fields/Field';
import { useCurrentYearSelector } from 'hooks/selectors';
import { useTranslations } from 'hooks/useTranslations';
import type { List, Set } from 'immutable';
import { getUuid } from 'lib/fp-helpers';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { SingleParamVoidFunction } from 'system/types';
import Fog from 'view/Fog';
import SizeBubble from 'view/molecules/size-bubble/SizeBubble';
import FormattedArea from 'view/units/FormattedArea';

import FieldSelectorWithPreviousUsages from '../field-selector/FieldSelectorWithPreviousUsages';

import { BulkUsageStep } from './BulkUsageContainer';

interface FieldSectionProps {
    step: BulkUsageStep;
    selectedFields: List<Field>;

    setSelectedFields: SingleParamVoidFunction<Set<string>>;
}

const FieldSection = ({ step, selectedFields, setSelectedFields }: FieldSectionProps) => {
    const { t, plural } = useTranslations();
    const { currentYear } = useCurrentYearSelector();

    return (
        <div className="relative">
            {step === BulkUsageStep.SELECT_USAGE && <Fog />}
            <SidebarModule className="relative" editing={true}>
                <h4>{t('fields')}</h4>
                {selectedFields.size === 0 ? (
                    <p>{t('bulk_usage_field_desc')}</p>
                ) : (
                    <div className="totals">
                        <span className="count mr-2">
                            {plural('bulk_usage_fields_selected', {
                                count: selectedFields.size,
                                sprintf: [selectedFields.size],
                            })}
                        </span>
                        <SizeBubble total>
                            <strong>
                                <FormattedArea
                                    areaSqm={selectedFields.reduce(
                                        (total, field) =>
                                            getFieldWorkedArea(field, currentYear) + total,
                                        0
                                    )}
                                    withUnit
                                />
                            </strong>
                        </SizeBubble>
                    </div>
                )}
            </SidebarModule>
            <FieldSelectorWithPreviousUsages
                selected={selectedFields.map(getUuid).toSet()}
                setSelected={setSelectedFields}
            />
        </div>
    );
};

export default FieldSection;
