import type { MouseEvent, ReactNode } from 'react';
import c from 'classnames';
import { type Sizes, sizesHasData } from 'lib/geo/shape-size-cacher';
import VisibilityButton from 'sidebar/visibility-toggle/VisibilityButton';
import ClickableDiv from 'view/a11y/ClickableDiv';
import SizeBubble from 'view/molecules/size-bubble/SizeBubble';
import FormattedGeoData from 'view/units/FormattedGeoData';

interface TypeGroupHeaderProps {
    // From parent
    typeItem: {
        id: string;
        colour: string;
        name: string;
    };
    sizes: Sizes;
    isVisible: boolean;
    onVisibilityClick: (id: string) => void;
    isOpen: boolean;
    onClick: (id: string) => void;
    children?: ReactNode;
    className?: string;
}

const TypeGroupHeader = ({
    typeItem,
    sizes,
    isVisible,
    onVisibilityClick,
    isOpen,
    onClick,
    children,
    className,
}: TypeGroupHeaderProps) => {
    const handleVisibilityClick = (e: MouseEvent) => {
        e.stopPropagation();
        onVisibilityClick(typeItem.id);
    };

    const handleClick = () => {
        onClick(typeItem.id);
    };

    return (
        <div>
            <ClickableDiv
                onClick={handleClick}
                className={c(
                    'list-none flex items-center text-left pr-5 cursor-pointer focus ring-inset',
                    className
                )}
            >
                <div
                    className="w-2 self-stretch mr-3"
                    style={{
                        backgroundColor: typeItem.colour,
                    }}
                />
                <VisibilityButton
                    visible={isVisible}
                    onClick={handleVisibilityClick}
                    className="w-5"
                />
                <strong className="ml-2 flex-grow py-4">{typeItem.name}</strong>
                {sizesHasData(sizes) && (
                    <SizeBubble className="mr-4">
                        <strong>
                            <FormattedGeoData sizes={sizes} />
                        </strong>
                    </SizeBubble>
                )}
                <i className={isOpen ? 'ion-arrow-down-b' : 'ion-arrow-right-b'} />
            </ClickableDiv>
            <div className={!isOpen ? 'hidden' : undefined}>{children}</div>
        </div>
    );
};

export default TypeGroupHeader;
