import { useTranslation } from 'react-i18next';
import { usePromise } from '@fieldmargin/webapp-state';
import icon from 'components/icons/icon';
import type Field from 'fields/Field';
import SidebarHeaderMenuItem from 'sidebar/modules/header/SidebarHeaderMenuItem';

import { useSetFieldArchived } from '../field-hooks';

interface FieldArchiveButtonProps {
    field: Field;
    onDone: VoidFunction;
}

const FieldArchiveButton = ({ field, onDone }: FieldArchiveButtonProps) => {
    const { t } = useTranslation();
    const toggleFieldArchived = useSetFieldArchived(field);
    const { pending, setPromise } = usePromise(onDone);

    const handleToggleArchive = () => setPromise(toggleFieldArchived());

    return (
        <SidebarHeaderMenuItem>
            <button className="bare-btn w-full" onClick={handleToggleArchive} disabled={pending}>
                {icon('archive', 'dark-blue', 'mr-3')}{' '}
                {field.archived ? t('unarchive') : t('archive')}
            </button>
        </SidebarHeaderMenuItem>
    );
};

export default FieldArchiveButton;
