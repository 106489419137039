import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { serialize } from '@fieldmargin/webapp-geo';
import shpWrite from '@mapbox/shp-write';
import type Field from 'fields/Field';
import saveAs from 'file-saver';
import type { FeatureCollection } from 'geojson';
import type { List } from 'immutable';
import tokml from 'tokml';

import { useFieldsSelector } from './selectors';
import { useCurrentFarm } from './useCurrentFarm';

const fieldsToGeoJson = (fields: List<Field> | null): FeatureCollection | null => {
    if (!fields) {
        return null;
    }

    return fields.reduce<FeatureCollection>(
        (collection, field) => {
            if (field.geoJson) {
                const json: GeoFeatureCollection = serialize(field.geoJson) as GeoFeatureCollection;
                const feature: any = json.features[0];
                delete feature.id; // This is the feature id, not the field UUID
                feature.properties.name = field.name;
                collection.features.push(feature);
            }
            return collection;
        },
        { type: 'FeatureCollection', features: [] }
    );
};

const useDownloadFieldBoundry = () => {
    const { currentFarm } = useCurrentFarm();
    const { fields } = useFieldsSelector();
    const geoJson = fieldsToGeoJson(fields);

    const handleShpDownload = () => {
        if (geoJson) {
            shpWrite.download(geoJson, {
                folder: currentFarm.name,
                types: {
                    polygon: 'fields',
                },
                compression: 'DEFLATE',
                outputType: 'blob',
            });
        }
    };
    const handleKmlDownload = () => {
        if (geoJson) {
            const kml = tokml(geoJson, {
                name: 'name',
                documentName: currentFarm.name,
            });
            saveAs(
                new Blob([kml], {
                    type: 'text/plain;charset=utf-8',
                }),
                `${currentFarm.name}.kml`
            );
        }
    };

    const handleGeoJsonDownload = () => {
        if (geoJson) {
            saveAs(
                new Blob([JSON.stringify(geoJson)], { type: 'application/json' }),
                `${currentFarm.name}.geojson`
            );
        }
    };

    return {
        handleGeoJsonDownload,
        handleKmlDownload,
        handleShpDownload,
        isGeoJson: geoJson !== null,
    };
};

export { useDownloadFieldBoundry };
