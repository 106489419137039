import { createAjaxAction } from '@fieldmargin/webapp-state';
import Bluebird from 'bluebird';
import { loadingFarm } from 'farms/farm-loading-state';
import { List, Map, Record } from 'immutable';
import { listToMap, replaceOrAdd, updateInList } from 'lib/immutil';
import type { Action } from 'redux-actions';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { fetchManualSensorsLatestApi } from 'sensors/manual/manual-sensors-api';
import type Reading from 'sensors/Reading';
import type { AppState } from 'system/store';

import type ManualSensor from './ManualSensor';
import type ManualSensorReadingGroup from './ManualSensorReadingGroup';

export const fetchManualSensors = createAjaxAction(
    'fetchManualSensors',
    (farmUuid: string) => Bluebird.resolve(fetchManualSensorsLatestApi(farmUuid)),
    {
        success: (state: ManualSensorsState, payload: List<ManualSensor>) =>
            state.set('sensors', payload),
    }
);

export const addManualSensor = createAction('Manual sensor: Add manual sensor');
export const updateManualSensor = createAction('Manual sensor: Update manual sensor');
export const removeManualSensor = createAction('Manual sensor: remove');

export const addManualSensorReadingGroup = createAction('Manual Sensor: Add reading group');
export const updateManualSensorReadingGroup = createAction('Manual Sensor: Update reading group');
export const removeManualSensorReadingGroup = createAction('Manual Sensor: Remove reading group');

export const ManualSensorsState = Record({
    sensors: null as List<ManualSensor> | null,
    fetchManualSensorsPending: false,
    fetchManualSensorsError: false,
});
export interface ManualSensorsState extends ReturnType<typeof ManualSensorsState> {}

export const selectManualSensor = createSelector(
    (_: AppState, manualSensorId: string) => manualSensorId,
    (state: AppState) => state.manualSensorsState.sensors,
    (manualSensorId, manualSensors) =>
        manualSensors?.find((manualSensor) => manualSensor.id === manualSensorId)
);

export const manualSensorsReducer = handleActions(
    {
        [loadingFarm.toString()]: () => ManualSensorsState(),

        [addManualSensor.toString()]: (state, { payload }: Action<ManualSensor>) =>
            state.set('sensors', state.sensors ? state.sensors.push(payload) : List([payload])),

        [updateManualSensor.toString()]: (state, { payload }: Action<ManualSensor>) =>
            state.set(
                'sensors',
                replaceOrAdd<ManualSensor>(
                    state.sensors ? state.sensors : List(),
                    payload,
                    (s) => s.id === payload.id
                )
            ),

        [removeManualSensor.toString()]: (state, action: Action<string>) =>
            state.set(
                'sensors',
                state.sensors ? state.sensors.filter((s) => s.id !== action.payload) : null
            ),

        [addManualSensorReadingGroup.toString()]: (
            state,
            { payload }: Action<ManualSensorReadingGroup>
        ) =>
            state.set(
                'sensors',
                updateInList<ManualSensor>(
                    state.sensors ? state.sensors : List(),
                    (s) => s.id === payload.sensorId,
                    (s) => {
                        const readingsMap = listToMap(payload.readings, (r) => r.metricId);
                        const newReadings = s.latestReadings.map((reading) => {
                            if (readingsMap.has(reading.metricId)) {
                                return reading.set(
                                    'value',
                                    (readingsMap.get(reading.metricId) as Reading).value
                                );
                                s;
                            }
                            return reading.set('value', null);
                        });
                        return s
                            .set('updatedAt', payload.timestamp)
                            .set('latestReadings', newReadings);
                    }
                )
            ),

        [updateManualSensorReadingGroup.toString()]: (
            state,
            { payload }: Action<ManualSensorReadingGroup>
        ) =>
            state.set(
                'sensors',
                updateInList(
                    state.sensors ? state.sensors : List<ManualSensor>(),
                    (s) => s.id === payload.sensorId,
                    (manualSensor) =>
                        manualSensor.update('readingGroups', (readingGroups) =>
                            (readingGroups ?? List<ManualSensorReadingGroup>()).map(
                                (readingGroup) =>
                                    readingGroup.id === payload.id ? payload : readingGroup
                            )
                        )
                )
            ),

        [removeManualSensorReadingGroup.toString()]: (
            state,
            {
                payload: { manualSensorId, readingGroupId },
            }: Action<{ manualSensorId: string; readingGroupId: string }>
        ) => {
            // Here we need to remove the reading group from the sensor and also update the
            // metrics with the correct latest values. These would change if the user deleted the
            // last reading group.
            return state.set(
                'sensors',
                updateInList<ManualSensor>(
                    state.sensors ? state.sensors : List(),
                    (manualSensor) => manualSensor.id === manualSensorId,
                    (manualSensor) => {
                        const updatedManualSensor = manualSensor.set(
                            'readingGroups',
                            manualSensor.readingGroups
                                ? manualSensor.readingGroups.filter(
                                      (rg) => rg.id !== readingGroupId
                                  )
                                : List()
                        );
                        let nextLatestValues = Map();
                        let nextUpdatedAt: Date | null = null;
                        const readingGroups =
                            updatedManualSensor.readingGroups as List<ManualSensorReadingGroup>;
                        if (readingGroups.size > 0) {
                            const latestReadingGroup = readingGroups
                                .sortBy((rg) => rg.timestamp.valueOf())
                                .reverse()
                                .get(0) as ManualSensorReadingGroup;

                            nextLatestValues = latestReadingGroup.readings.reduce(
                                (readings, reading) =>
                                    readings.set(reading.metricId, reading.value),
                                Map<string, string | number | null>()
                            );
                            nextUpdatedAt = latestReadingGroup.timestamp;
                        }
                        return updatedManualSensor
                            .set(
                                'latestReadings',
                                updatedManualSensor.latestReadings.map((reading) =>
                                    nextLatestValues.has(reading.metricId)
                                        ? reading.set(
                                              'value',
                                              nextLatestValues.get(reading.metricId) as
                                                  | string
                                                  | number
                                                  | null
                                          )
                                        : reading
                                )
                            )
                            .set('updatedAt', nextUpdatedAt);
                    }
                )
            );
        },

        ...fetchManualSensors.reducers,
    },
    ManualSensorsState()
);
