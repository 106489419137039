import { useTranslation } from 'react-i18next';
import { LazyBarChart } from 'components/chart/LazyBarChart';
import { renderMeasurementUnitShort } from 'lib/MeasurementUnit';

import { getInputCostsGraphData, getRowTotal } from './input-costs-util';
import { useInputCostsContext } from './useInputCostsContext';

const InputCostsGraph = () => {
    const { t, i18n } = useTranslation();
    const { inputCosts, measurementUnit } = useInputCostsContext();

    return (
        <div className="relative" style={{ height: Math.max(inputCosts.fields.size * 40, 200) }}>
            <LazyBarChart
                direction="horizontal"
                style={{ display: 'block' }}
                data={getInputCostsGraphData(inputCosts, t)}
                options={{
                    locale: i18n.language,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            right: 50,
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: (context) => context.datasetIndex === 3,
                            formatter: (_, context) => getRowTotal(context),
                            anchor: 'end',
                            align: 'end',
                        },
                    },
                    scales: {
                        x: {
                            stacked: true,
                            title: {
                                display: true,
                                text: `${t('cost')}/${renderMeasurementUnitShort(measurementUnit)}`,
                            },
                        },
                        y: {
                            stacked: true,
                        },
                    },
                }}
            />
        </div>
    );
};

export default InputCostsGraph;
