import { Extent, extent, Projection } from '@fieldmargin/webapp-geo';
import type { geojson } from '@fieldmargin/webapp-ol-map';
import MapPosition from 'components/maps/model/MapPosition';
import type { FarmMapFeature } from 'farm-map/types';
import { useAppSelector } from 'system/store';

export const useFarmMapMapView = (
    features: FarmMapFeature[]
): geojson.ViewportAction | undefined => {
    const mapView = useAppSelector((state) => state.farmEditingState.mapView);

    if (mapView.type === 'full-extent') {
        const fullExtent = extent.extentFromGeoJson(
            {
                type: 'FeatureCollection',
                features,
            },
            Projection.LNG_LAT
        );
        return fullExtent !== null
            ? {
                  id: mapView.id,
                  position: {
                      extent: fullExtent,
                      maxZoom: 18,
                      padding: [50, 50, 50, 50],
                  },
              }
            : undefined;
    }
    if (mapView.type === 'extent' && mapView.payload instanceof Extent) {
        return {
            id: mapView.id,
            position: {
                extent: {
                    minX: mapView.payload.minX,
                    minY: mapView.payload.minY,
                    maxX: mapView.payload.maxX,
                    maxY: mapView.payload.maxY,
                    projection: mapView.payload.projection,
                },
                maxZoom: 18,
                padding: [50, 50, 50, 50],
            },
        };
    }
    if (
        mapView.type === 'position' &&
        mapView.payload instanceof MapPosition &&
        mapView.payload.centre !== null &&
        mapView.payload.zoom !== null
    ) {
        return {
            id: mapView.id,
            position: {
                centre: {
                    type: 'Point',
                    coordinates: [mapView.payload.centre.x, mapView.payload.centre.y],
                },
                zoom: mapView.payload.zoom,
            },
        };
    }
    return;
};
