import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useOnMount } from '@fieldmargin/webapp-state';
import getSymbolFromCurrency from 'currency-symbol-map';
import FieldReportTable from 'fields/sidebar/details/reporting/FieldReportTable';
import { useOperationsSelector } from 'hooks/selectors';
import { useCurrentYearFieldInputReportOperations } from 'hooks/useCurrentYearFieldInputReportOperations';
import type { FieldInputReportSummary } from 'inputs/InputReportSummary';
import { INPUT_TYPE_ORDER } from 'inputs/InputType';
import { capitaliseWord, compareName } from 'lib/util/text';
import { loadOperationsRecordings } from 'operations/operations-state';
import { groupBy } from 'ramda';
import { useAppDispatch } from 'system/store';
import { hectaresToSqm } from 'utils/conversion';
import FormattedArea from 'view/units/FormattedArea';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import { FieldInputReportInputRow } from './FIeldInputReportInputRow';

interface FieldInputReportTableProps {
    farm: Farm;
    year: number;
    report: FieldInputReportSummary;
}

const FieldInputReportTable = ({ farm, year, report }: FieldInputReportTableProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const isMount = useOnMount();
    const { operations } = useOperationsSelector(report.uuid);
    const { currentYearFieldInputReport } = useCurrentYearFieldInputReportOperations(operations);

    const inputGroups = groupBy((input) => input.inputType, report.inputs);

    useEffect(() => {
        if (isMount && operations.size > 0)
            dispatch(loadOperationsRecordings(farm, year, operations));
    }, [isMount]);

    return (
        <>
            <h3 className="mb-0">
                {report.name}{' '}
                <span className="text-sm font-medium">
                    <FormattedArea areaSqm={hectaresToSqm(report.area)} /> <ShortAreaUnit />
                </span>
            </h3>
            <p className="mb-1">{report.fieldUsageName ?? t('layers_fields_with_no_usage')}</p>
            <FieldReportTable
                headings={[
                    t('field_details_input'),
                    t('type'),
                    t('input_reg_number'),
                    t('label_active_ingredients'),
                    t('applications'),
                    <>
                        {t('operation_details_input_rate_empty')}
                        <ShortAreaUnit />
                    </>,
                    t('total'),
                    <>
                        {t('cost')}/
                        <ShortAreaUnit /> ({getSymbolFromCurrency(farm.costingsCurrency ?? 'GBP')})
                    </>,
                    <>
                        {t('reporting_total_cost')} (
                        {getSymbolFromCurrency(farm.costingsCurrency ?? 'GBP')})
                    </>,
                ]}
                className="mb-6"
                firstHeaderClass="w-1/5"
            >
                {Object.entries(inputGroups)
                    .sort(([a], [b]) => INPUT_TYPE_ORDER[a] - INPUT_TYPE_ORDER[b])
                    .map(([inputType, inputs]) => (
                        <Fragment key={inputType}>
                            <tr>
                                <th className="type" colSpan={9}>
                                    {capitaliseWord(inputType.toString())}
                                </th>
                            </tr>
                            {inputs.sort(compareName).map((input) => (
                                <FieldInputReportInputRow
                                    key={input.uuid}
                                    fieldUuid={report.uuid}
                                    input={input}
                                    operations={currentYearFieldInputReport(input.uuid)}
                                />
                            ))}
                        </Fragment>
                    ))}
            </FieldReportTable>
        </>
    );
};

export default FieldInputReportTable;
