import { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { calcArea } from '@fieldmargin/webapp-geo';
import { useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import { clsx } from 'clsx';
import { clearEditingGeoFeatureCollection } from 'farm-editing/farm-editing-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { selectTotalFieldArea } from 'fields/fields-selectors';
import type FieldUsage from 'fields/FieldUsage';
import FieldAreaLimitModal from 'fields/modal/FieldAreaLimitModal';
import AddFieldUsage from 'fields/usage/AddFieldUsage';
import { useAddingUsageState } from 'fields/usage/adding-usage-state';
import { useCreateFieldTracking } from 'hooks/useSegmentTracking';
import { getOnboardingClassName, useOnboarding } from 'onboarding/onboarding-state';
import { bindActionCreators } from 'redux';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import { SidebarToastContext } from 'sidebar/Sidebar';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import { useFinishTutorialOnMount } from 'tutorials/tutorial-hooks';
import { TutorialTypes } from 'tutorials/TutorialTypes';
import UnsavedHookFormChangesChecker from 'unsaved-changes/UnsavedHookFormChangesChecker';
import { hectaresToSqm } from 'utils/conversion';
import Fog from 'view/Fog';

import { useCreateField } from '../field-hooks';

import FieldNewBoundary from './FieldNewBoundary';
import FieldNewTitle from './FieldNewTitle';
import FieldNewUsage from './FieldNewUsage';

interface FieldNewProps {
    farm: Farm;
    editingGeoFeatureCollection: GeoFeatureCollection | null;
    totalFieldArea: number;
    clearEditingGeoFeatureCollection: typeof clearEditingGeoFeatureCollection;
}

export interface CreateFieldFormValues {
    name: string;
    id: string;
    fieldUsageUuid: string;
    shapes: {
        shapes: GeoFeatureCollection;
        fields: string[];
    };
    foo: any;
}

enum FieldNewStep {
    BOUNDARY,
    NAME,
    REST,
}

const FieldNew = ({
    farm,
    editingGeoFeatureCollection,
    totalFieldArea,
    clearEditingGeoFeatureCollection,
}: FieldNewProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { nextOnboarding, activeStep } = useOnboarding();
    const { showSidebarToast } = useContext(SidebarToastContext);

    const methods = useForm<CreateFieldFormValues>({
        mode: 'onChange',
    });

    const { isPending, isError, onCreateField } = useCreateField();

    const [{ addingUsage, createdFieldUsageUuid }, toggleAddingUsage, setCreatedFieldUsageUuid] =
        useAddingUsageState();

    // console.log(fieldUsageUuid)
    useFinishTutorialOnMount(TutorialTypes.FIELD);

    const [step, setStep] = useState(FieldNewStep.BOUNDARY);
    const [showUpgradeModal, toggleShowUpgradeModal] = useToggle(false);

    const timeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (createdFieldUsageUuid !== undefined) {
            methods.setValue('fieldUsageUuid', createdFieldUsageUuid);
        }
    }, [createdFieldUsageUuid]);

    useEffect(() => {
        if (step === FieldNewStep.BOUNDARY && editingGeoFeatureCollection?.features.size) {
            nextOnboarding('fieldName');
            setStep(FieldNewStep.NAME);
        }
    }, [editingGeoFeatureCollection]);

    useEffect(() => {
        if (addingUsage && timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }, [addingUsage]);

    const handleSave = (values: CreateFieldFormValues) => {
        if (editingGeoFeatureCollection !== null) {
            onCreateField({
                name: values.name,
                fieldId: values.id,
                geoJson: editingGeoFeatureCollection,
                fieldUsageUuid: values.fieldUsageUuid,
                onSave: (field) =>
                    navigate(`/farms/${farm.uuid}/fields/${field.uuid}`, {
                        state: { ignoreUnsavedChanges: true },
                    }),
            });
        }
    };

    const handleSaveAndResetForm = (values: CreateFieldFormValues) => {
        if (editingGeoFeatureCollection === null) {
            return;
        }

        if (
            farm.plan.fieldAreaHectareLimit !== null &&
            totalFieldArea + calcArea(editingGeoFeatureCollection) >
                hectaresToSqm(farm.plan.fieldAreaHectareLimit)
        ) {
            toggleShowUpgradeModal();
            return;
        }

        onCreateField({
            name: values.name,
            fieldId: values.id,
            geoJson: editingGeoFeatureCollection,
            fieldUsageUuid: values.fieldUsageUuid,
            onSave: (field) => {
                methods.setFocus('name');
                methods.reset();
                clearEditingGeoFeatureCollection();
                setStep(FieldNewStep.BOUNDARY);
                showSidebarToast(
                    {
                        message: t('field_created_web', { sprintf: [field.name] }),
                        level: 'info',
                    },
                    2000
                );
            },
        });
    };

    const handleTitleChange = () => {
        setStep(FieldNewStep.REST);
        nextOnboarding();
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            nextOnboarding('fieldUsage');
        }, 1500);
    };

    const handleCreateNewUsageClick = () => {
        nextOnboarding();
        toggleAddingUsage();
    };

    const handleSaveUsage = (fieldUsage: FieldUsage) => {
        setCreatedFieldUsageUuid(fieldUsage.uuid);
        toggleAddingUsage();
    };

    const saveDisabled = isPending || step !== FieldNewStep.REST;

    useCreateFieldTracking();

    return (
        <>
            {showUpgradeModal && <FieldAreaLimitModal onClose={toggleShowUpgradeModal} />}
            {addingUsage && <AddFieldUsage onBack={toggleAddingUsage} onSave={handleSaveUsage} />}
            <FormProvider {...methods}>
                <UnsavedHookFormChangesChecker
                    copy={[t('field_create_discard1'), t('field_create_discard2')]}
                >
                    <form className={clsx('FieldNew', 'scrollable', { hidden: addingUsage })}>
                        <div className="non-scrolling">
                            <SidebarHeader className="px-5 py-3.5 h-auto items-start">
                                <Button
                                    as={Link}
                                    to={`/farms/${farm.uuid}/fields/`}
                                    state={{ ignoreUnsavedChanges: true }}
                                    variant="danger-outline"
                                    small
                                >
                                    {t('cancel')}
                                </Button>
                                <div className="flex flex-wrap gap-4 justify-end">
                                    <Button
                                        variant="outline"
                                        disabled={saveDisabled}
                                        onClick={methods.handleSubmit(handleSaveAndResetForm)}
                                        small
                                    >
                                        {t('save_amp_create_another')}
                                    </Button>
                                    <Button
                                        disabled={saveDisabled}
                                        small
                                        onClick={methods.handleSubmit(handleSave)}
                                    >
                                        {isPending ? t('saving') : t('save')}
                                    </Button>
                                </div>
                            </SidebarHeader>
                            {isError && (
                                <SidebarError
                                    title={t('failed_to_save')}
                                    message={t('something_went_wrong')}
                                />
                            )}
                        </div>
                        <div className="scrolling">
                            <FieldNewBoundary />
                            <FieldNewTitle
                                fog={step === FieldNewStep.BOUNDARY}
                                onChange={handleTitleChange}
                            />
                            <div
                                className={clsx(
                                    getOnboardingClassName('fieldUsage', activeStep),
                                    'relative'
                                )}
                            >
                                {step !== FieldNewStep.REST && <Fog />}
                                <FieldNewUsage onCreate={handleCreateNewUsageClick} />
                            </div>
                        </div>
                    </form>
                </UnsavedHookFormChangesChecker>
            </FormProvider>
        </>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,
        totalFieldArea: selectTotalFieldArea(state),
    }),
    (dispatch) => bindActionCreators({ clearEditingGeoFeatureCollection }, dispatch)
)(FieldNew);
