import { GeoPosition } from '@fieldmargin/webapp-geo';
import Promise from 'bluebird';

export const browserHasGeoLocation = () => !!navigator.geolocation;

/**
 * Uses the Web GeoLocation API to request and return a promise that resolves with the
 * users current location as a GeoPosition object or throws an error if the location
 * cannot be returned, user does not grant permission to access location or this has
 * been called when the browser does not support GeoLocation.
 */
export const getUserCurrentGeoPosition = async () => {
    if (!browserHasGeoLocation()) {
        return Promise.reject(new Error('No geolocation api'));
    }

    return new Promise<GeoPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                resolve(
                    GeoPosition({
                        x: position.coords.longitude,
                        y: position.coords.latitude,
                    })
                );
            },
            (error) => {
                reject(new Error(error.message));
            }
        );
    });
};

/**
 * Returns the preferred country of the browser language, defaulting to GB if none can be found.
 */
export const getBrowserLanguageCountry = () => {
    const parts = navigator.language.split('-');
    return parts[1] ? parts[1].toLowerCase() : 'gb';
};
