import { useState } from 'react';
import { connect } from 'react-redux';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { serialize } from '@fieldmargin/webapp-geo';
import type { AttachmentItem } from 'farm-editing/attachments';
import { attachmentTypeIsFeature, attachmentTypeIsField } from 'farm-editing/attachments';
import { selectEditingFieldAndFeaturesGeoJson, stopEditing } from 'farm-editing/farm-editing-state';
import { startEditingNote } from 'farm-editing/start-editing-reducer';
import type Feature from 'features/Feature';
import type Field from 'fields/Field';
import { selectFieldsWithFullNames } from 'fields/fields-selectors';
import type { Set } from 'immutable';
import { List } from 'immutable';
import { Form } from 'informed';
import { itemToGeoJsonItem } from 'lib/geo/geometry';
import { useOutOfDatePromise } from 'lib/hooks';
import type Note from 'notes/Note';
import { useUpdateNote, useUpdateNoteFeatures, useUpdateNoteFields } from 'notes/note-hooks';
import { defaultTo, prop } from 'ramda';
import { bindActionCreators } from 'redux';
import EditableActions from 'sidebar/modules/common/EditableActions';
import EditButton from 'sidebar/modules/common/EditButton';
import EditableShapeInformation from 'sidebar/modules/shapes/components/EditableShapeInformation';
import ShapeInformation from 'sidebar/modules/shapes/components/ShapeInformation';
import { calculateTotalExtent } from 'sidebar/modules/shapes/shapes-util';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import type { GeoJsonItem } from 'system/types';

import NoteFeaturesEdit from '../NoteFeaturesEdit';

import NoteFeaturesBubbles from './NoteFeaturesBubbles';
import NoteFieldsBubbles from './NoteFieldsBubbles';
import NoteFieldsEdit from './NoteFieldsEdit';

interface EditableNoteLocationProps {
    // From parent
    note: Note;

    // From redux
    attachedFields: List<Field>;
    attachedFeatures: List<Feature>;
    editingShapes: GeoFeatureCollection | null;
    editingAttachments: Set<AttachmentItem>;
    editingAttachmentGeoJson: List<GeoJsonItem>;
    startEditingNote: typeof startEditingNote;
    stopEditing: typeof stopEditing;
}

const EditableNoteLocation = ({
    note,
    attachedFields,
    attachedFeatures,
    startEditingNote,
    stopEditing,
    editingShapes,
    editingAttachments,
    editingAttachmentGeoJson,
}: EditableNoteLocationProps) => {
    const [editing, setEditing] = useState(false);
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(() => {
        setEditing(false);
        stopEditing();
    });
    const updateNote = useUpdateNote(note);
    const updateNoteFields = useUpdateNoteFields();
    const updateNoteFeatures = useUpdateNoteFeatures();

    const handleSave = () => {
        const editingFieldUuids = editingAttachments.filter(attachmentTypeIsField);
        const editingFeatureUuids = editingAttachments.filter(attachmentTypeIsFeature);
        if (editingShapes !== null) {
            setPromise(
                updateNote({ geoJson: serialize(editingShapes) })
                    .then((note) =>
                        updateNoteFields(note, editingFieldUuids.map(prop('id')).toArray())
                    )
                    .then((note) =>
                        updateNoteFeatures(note, editingFeatureUuids.map(prop('id')).toArray())
                    )
            );
        } else {
            setEditing(false);
        }
    };

    return (
        <SidebarModule editing={editing}>
            {!editing ? (
                <>
                    <EditButton
                        setEditing={(value) => {
                            setEditing(value);
                            startEditingNote(note);
                        }}
                    />
                    <ShapeInformation
                        geoFeatureCollection={note.getGeoFeatureCollection()}
                        extent={calculateTotalExtent(
                            note.getGeoFeatureCollection(),
                            attachedFields.concat(attachedFeatures).map(itemToGeoJsonItem)
                        )}
                        includeEmptyMessage={attachedFields.size === 0}
                    >
                        {attachedFields.size > 0 && <NoteFieldsBubbles fields={attachedFields} />}
                        {attachedFeatures.size > 0 && (
                            <NoteFeaturesBubbles features={attachedFeatures} />
                        )}
                    </ShapeInformation>
                </>
            ) : (
                <Form onSubmit={handleSave}>
                    <EditableShapeInformation
                        label="Location"
                        extraExtent={calculateTotalExtent(editingShapes, editingAttachmentGeoJson)}
                        includeEmptyMessage={editingAttachments.size === 0}
                    >
                        <NoteFieldsEdit />
                        <NoteFeaturesEdit />
                    </EditableShapeInformation>
                    <EditableActions
                        disabled={pending}
                        error={error}
                        outOfDate={outOfDate}
                        setEditing={(value) => {
                            if (!value) {
                                stopEditing();
                            }
                            setEditing(value);
                        }}
                    />
                </Form>
            )}
        </SidebarModule>
    );
};

export default connect(
    (state: AppState, props: { note: Note }) => ({
        attachedFields: selectFieldsWithFullNames(state).filter((field) =>
            props.note.fieldUuids?.includes(field.uuid)
        ),
        attachedFeatures: defaultTo(
            List<Feature>(),
            state.featuresState.features?.filter((feature) =>
                props.note.featureUuids?.includes(feature.uuid)
            )
        ),
        editingShapes: state.farmEditingState.editingGeoFeatureCollection,
        editingAttachments: state.farmEditingState.editingAttachments,
        editingAttachmentGeoJson: selectEditingFieldAndFeaturesGeoJson(state),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                startEditingNote,
                stopEditing,
            },
            dispatch
        )
)(EditableNoteLocation);
