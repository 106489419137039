import OnboardingTooltip from 'onboarding/OnboardingTooltip';

interface FieldNameOnboardingProps {
    onSkip: VoidFunction;
}

const FieldNameOnboarding = ({ onSkip }: FieldNameOnboardingProps) => {
    return (
        <OnboardingTooltip
            style={{ top: 255, left: 700 }}
            onSkip={onSkip}
            link="https://support.fieldmargin.com/en/articles/1869615-how-to-add-a-field"
        >
            <p>
                Add your Field name
                <br />
                Give a name to your field
            </p>
        </OnboardingTooltip>
    );
};

export default FieldNameOnboarding;
