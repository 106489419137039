import type { ReactElement } from 'react';
import { cloneElement } from 'react';
import c from 'classnames';

interface SidebarHeaderMenuItemProps {
    children: ReactElement;
}

const SidebarHeaderMenuItem = ({ children }: SidebarHeaderMenuItemProps) => {
    const el = cloneElement(children, {
        className: c('SidebarHeaderMenuItem focus', children.props.className),
    });
    return el;
};

export default SidebarHeaderMenuItem;
