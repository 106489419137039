import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import { usePrevious } from '@fieldmargin/webapp-state';
import MapPosition from 'components/maps/model/MapPosition';
import { setMapView } from 'farm-editing/farm-editing-state';
import { useDateFormat } from 'hooks/useDateFormat';
import { isFarmOwner } from 'lib/owner';
import { getFarmUserDisplayName, getInitials } from 'lib/util/names';
import { bindActionCreators } from 'redux';
import InitialBadge from 'sidebar/modules/initial-badge/InitialBadge';
import type FarmUserLocation from 'team/FarmUserLocation';

const ExistingTeamMember = ({
    farmUser,
    location,
    setMapView,
}: {
    farmUser: FarmUser;
    location: FarmUserLocation | null;
    setMapView: (opts: any) => void;
}) => {
    const { t } = useTranslation();
    const { formatDistanceToNow } = useDateFormat();
    const [lastSeen, setLastSeen] = useState(
        location ? formatDistanceToNow(location.lastUpdated) : null
    );
    const previousLocation = usePrevious(location);

    useEffect(() => {
        if (
            location &&
            (!previousLocation || location.lastUpdated !== previousLocation.lastUpdated)
        ) {
            setLastSeen(formatDistanceToNow(location.lastUpdated));
        }

        const interval = setInterval(() => {
            if (location) {
                setLastSeen(formatDistanceToNow(location.lastUpdated));
            }
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, [farmUser, location]);

    const handleViewClick = (e: MouseEvent) => {
        e.preventDefault();
        if (!location) {
            return;
        }
        setMapView({
            type: 'position',
            payload: MapPosition({
                centre: location.position,
                zoom: 18,
            }),
        });
    };
    return (
        <>
            <InitialBadge initial={getInitials(farmUser)} inactive={!location} />
            <span className="info">
                {getFarmUserDisplayName(farmUser)}
                {isFarmOwner(farmUser) && (
                    <>
                        {' '}
                        - <span className="admin">{t('admin')}</span>
                    </>
                )}
                <div className="location">
                    {location
                        ? t('team_tracking_user_last_seen', { sprintf: [lastSeen] })
                        : t('permission_location_title')}
                </div>
            </span>
            {location && (
                <a href="#" className="view-link" onClick={handleViewClick}>
                    {t('operation_details_view_on_map')}
                </a>
            )}
        </>
    );
};

export default connect(
    () => ({}),
    (dispatch) => bindActionCreators({ setMapView }, dispatch)
)(ExistingTeamMember);
