import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import type Input from 'inputs/Input';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { renderMeasurementUnitShort } from 'lib/MeasurementUnit';
import { getUnitShortName } from 'lib/unit';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import ShortInputRate from 'view/units/ShortInputRate';

import type { PesticideCheckMaxDoseTreatment } from './PesticideCheck';
import { PesticideCheckStatus } from './PesticideCheck';
import PesticideCheckResultIcon from './PesticideCheckResultIcon';

interface MaxDoseTreatmentResultProps {
    // From parent
    input: Input;
    check: PesticideCheckMaxDoseTreatment;

    // From redux
    areaUnit: MeasurementUnit;
}

const MaxDoseTreatmentResult = ({ input, check, areaUnit }: MaxDoseTreatmentResultProps) => {
    if (check.status === PesticideCheckStatus.UNKNOWN) {
        return (
            <tr>
                <PesticideCheckResultIcon status={check.status} />
                <td>
                    <Trans
                        i18nKey="pesticide_check_max_dose_no_data"
                        components={{ rate: <ShortInputRate input={input} /> }}
                        tOptions={{ sprintf: [check.values.actualDose] }}
                    >
                        Your planned dose is {{ actualDose: check.values.actualDose }}{' '}
                        <ShortInputRate input={input} />. Please check the label for maximum dose.
                    </Trans>
                </td>
            </tr>
        );
    }

    // If we have a unit mismatch we need to show a different message
    const inputUnit = `${getUnitShortName(input.unit)}/${renderMeasurementUnitShort(
        areaUnit
    )}`.toLowerCase();
    const checkUnit = check.values?.doseUnits.toLowerCase();
    const status = inputUnit !== checkUnit ? PesticideCheckStatus.WARNING : check.status;

    return (
        <tr>
            <PesticideCheckResultIcon status={status} />
            <td>
                {inputUnit !== checkUnit ? (
                    <Trans
                        i18nKey="pesticide_check_max_dose_unit_mismatch"
                        components={{ rate: <ShortInputRate input={input} /> }}
                        tOptions={{
                            sprintf: [
                                check.values.actualDose,
                                check.values.maxDose,
                                checkUnit !== '-' ? checkUnit.toUpperCase() : null,
                            ],
                        }}
                    >
                        Planned rate is {{ actualDose: check.values.actualDose }}{' '}
                        <ShortInputRate input={input} />. The maximum dose is{' '}
                        {{ maxDose: check.values.maxDose }}{' '}
                        {{ unit: checkUnit !== '-' ? checkUnit.toUpperCase() : null }}
                    </Trans>
                ) : check.status === PesticideCheckStatus.FAIL ? (
                    <Fail check={check} input={input} />
                ) : (
                    <Pass check={check} input={input} />
                )}
            </td>
        </tr>
    );
};

export default connect((state: AppState) => ({
    areaUnit: selectUserAreaMeasurementUnit(state),
}))(MaxDoseTreatmentResult);

const Pass = ({ check, input }: { check: PesticideCheckMaxDoseTreatment; input: Input }) =>
    check.values.minDose !== undefined ? (
        <Trans
            i18nKey="pesticide_check_max_dose_pass_range"
            components={{ rate: <ShortInputRate input={input} /> }}
            tOptions={{
                sprintf: [check.values.actualDose, check.values.minDose, check.values.maxDose],
            }}
        >
            Planned rate of {check.values.actualDose} <ShortInputRate input={input} /> is within{' '}
            {check.values.minDose}-{check.values.maxDose} <ShortInputRate input={input} /> range
        </Trans>
    ) : (
        <Trans
            i18nKey="pesticide_check_max_dose_pass"
            components={{ rate: <ShortInputRate input={input} /> }}
            tOptions={{ sprintf: [check.values.actualDose, check.values.maxDose] }}
        >
            Planned rate of {check.values.actualDose} <ShortInputRate input={input} /> less than{' '}
            {check.values.maxDose} <ShortInputRate input={input} /> max dose
        </Trans>
    );

const Fail = ({ check, input }: { check: PesticideCheckMaxDoseTreatment; input: Input }) =>
    check.values.minDose !== undefined ? (
        <Trans
            i18nKey="pesticide_check_max_dose_fail_range"
            components={{ rate: <ShortInputRate input={input} />, strong: <strong /> }}
            tOptions={{
                sprintf: [check.values.actualDose, check.values.minDose, check.values.maxDose],
            }}
        >
            <strong>Dose outside range:</strong> planned rate of {check.values.actualDose}{' '}
            <ShortInputRate input={input} /> is outside {check.values.minDose} -{' '}
            {check.values.maxDose} <ShortInputRate input={input} /> range
        </Trans>
    ) : (
        <Trans
            i18nKey="pesticide_check_max_dose_fail"
            components={{ rate: <ShortInputRate input={input} />, strong: <strong /> }}
            tOptions={{ sprintf: [check.values.actualDose, check.values.maxDose] }}
        >
            <strong>Max dose exceeded:</strong> planned rate of {check.values.actualDose}{' '}
            <ShortInputRate input={input} /> over the {check.values.maxDose}{' '}
            <ShortInputRate input={input} /> max dose
        </Trans>
    );
