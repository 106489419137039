import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';
import OrganisationManagement from 'organisation/OrganisationManagement';

export const organisationsRoutes: RouteObject = {
    path: APP_ROUTES.Organisations.Root,
    element: <Outlet />,
    children: [
        {
            path: APP_ROUTES.Organisations.Organisation,
            element: <Outlet />,
            children: [
                {
                    path: APP_ROUTES.Organisations.Settings,
                    element: <OrganisationManagement />,
                },
            ],
        },
    ],
};
