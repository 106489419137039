import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from 'components/button-base';
import { useNavigateBack } from 'hooks/useNavigateBack';

import { ChevronIcon } from '../../../../icons';

import styles from './ButtonBack.module.css';

interface ButtonBackProps {
    onBack?: () => void;
}

export function ButtonBackBase({ children, ...props }: ComponentProps<typeof ButtonBase.Root>) {
    return (
        <ButtonBase.Root className={styles.buttonBack} {...props}>
            <span className={styles.buttonBackIcon}>
                <ChevronIcon />
            </span>
            <span>{children}</span>
        </ButtonBase.Root>
    );
}

function ButtonBack({ onBack }: ButtonBackProps) {
    const { t } = useTranslation();
    const { onNavigateBackHandler } = useNavigateBack();

    const onPressHandler = () => {
        onNavigateBackHandler();
        onBack?.();
    };

    return <ButtonBackBase onPress={onPressHandler}>{t('back')}</ButtonBackBase>;
}

export { ButtonBack };
