import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import icon from 'components/icons/icon';
import type Note from 'notes/Note';
import { useCreateTask, useToggleNoteArchived } from 'notes/note-hooks';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderDeleteLink from 'sidebar/modules/header/SidebarHeaderDeleteLink';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarHeaderMenu from 'sidebar/modules/header/SidebarHeaderMenu';
import SidebarHeaderMenuItem from 'sidebar/modules/header/SidebarHeaderMenuItem';
import FarmLink from 'view/FarmLink';
import Fog from 'view/Fog';

interface NoteDetailsHeaderProps {
    farm: Farm;
    note: Note;
    menuOpen: boolean;
    onToggleHeaderMenu: VoidFunction;
}

const NoteDetailsHeader = memo(
    ({ farm, note, menuOpen, onToggleHeaderMenu }: NoteDetailsHeaderProps) => {
        const { t } = useTranslation();
        const createTask = useCreateTask(note);
        const toggleNoteArchived = useToggleNoteArchived(note);

        const convertToTask = () => createTask().then(onToggleHeaderMenu);
        const toggleArchived = () => {
            toggleNoteArchived();
            onToggleHeaderMenu();
        };

        return (
            <SidebarHeader className="relative">
                {menuOpen && <Fog blue className="w-3/4 -bottom-px" onClick={onToggleHeaderMenu} />}
                <SidebarHeaderLink to={`/farms/${farm.uuid}/notes`} returning>
                    {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                </SidebarHeaderLink>
                <SidebarHeaderMenu open={menuOpen} onToggleOpen={onToggleHeaderMenu}>
                    {!note.task && (
                        <>
                            <SidebarHeaderMenuItem>
                                <button
                                    className="bare-btn w-full whitespace-normal text-left"
                                    onClick={convertToTask}
                                >
                                    {icon('edit', 'dark-blue', 'mr-3 self-start')}{' '}
                                    {t('note_to_task')}
                                </button>
                            </SidebarHeaderMenuItem>{' '}
                            <SidebarHeaderMenuItem>
                                <button
                                    className="bare-btn w-full whitespace-normal text-left"
                                    onClick={toggleArchived}
                                >
                                    {icon('archive', 'dark-blue', 'mr-3 self-start')}{' '}
                                    {note.archived ? t('unarchive') : t('archive')}
                                </button>
                            </SidebarHeaderMenuItem>
                        </>
                    )}
                    <SidebarHeaderMenuItem>
                        <FarmLink to={`/notes/${note.uuid}/print`}>
                            {icon('print', 'dark-blue', 'mr-3')} {t('print')}
                        </FarmLink>
                    </SidebarHeaderMenuItem>
                    <SidebarHeaderDeleteLink
                        item={note}
                        to={`/farms/${farm.uuid}/notes/${note.uuid}/delete`}
                    />
                </SidebarHeaderMenu>
            </SidebarHeader>
        );
    }
);

export default NoteDetailsHeader;
