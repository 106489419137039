import { FilterDatesRange } from './FilterDatesRange';
import { FilterDateTypes } from './FilterDateTypes';

import styles from './FilterTimeRange.module.css';

function FilterTimeRange() {
    return (
        <div className={styles.wrapper}>
            <FilterDateTypes />
            <FilterDatesRange />
        </div>
    );
}

export { FilterTimeRange };
