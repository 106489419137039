import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@fieldmargin/webapp-styling/components/button';
import type { List } from 'immutable';
import type { SentinelHubMap } from 'maps/vegetation/sentinel-hub-api';
import { SentinelHubStyle } from 'maps/vegetation/sentinel-hub-api';
import { pickSentinelHubMap, pickStyle } from 'maps/vegetation/vegetation-maps-state';
import { bindActionCreators } from 'redux';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';

import SentinelHubCalendar from '../common/SentinelHubCalendar';

import styles from './SentinelHubMapPicker.module.css';

interface SentinelHubMapPickerProps {
    sentinelHubMaps: List<SentinelHubMap> | null;
    visibleSentinelHubMap?: SentinelHubMap;
    pickSentinelHubMap: (shm: SentinelHubMap) => void;

    visibleStyle: SentinelHubStyle;
    pickStyle: (s: SentinelHubStyle) => void;

    changeModal: (a: any) => void;
}

const sentinelHubStyles: [SentinelHubStyle, string][] = [
    [SentinelHubStyle.NDVI, 'farm_maps_ndvi'],
    [SentinelHubStyle.RGB, 'farm_maps_rgb'],
];

const SentinelHubMapPicker = ({
    sentinelHubMaps,
    visibleSentinelHubMap,
    pickSentinelHubMap,
    visibleStyle,
    pickStyle,
    changeModal,
}: SentinelHubMapPickerProps) => {
    const { t } = useTranslation();

    if (!sentinelHubMaps) {
        return null;
    }

    const label = visibleSentinelHubMap
        ? t('field_health_cloud_cover', { sprintf: [Math.round(visibleSentinelHubMap.cloudCover)] })
        : t('select_date');

    return (
        <SidebarModule editing className="flex gap-4">
            <SentinelHubCalendar
                options={sentinelHubMaps}
                selected={visibleSentinelHubMap}
                onSelect={pickSentinelHubMap}
            />
            <div className="flex flex-col">
                <div className="bordered rounded p-4 mt-16">
                    <p className="mb-1">
                        <strong>{t('sensors_date')}:</strong>
                    </p>
                    <p>{label}</p>
                    <p className="mb-1">
                        <strong>{t('style')}:</strong>
                    </p>
                    {sentinelHubStyles.map(([value, i18nKey]) => (
                        <div key={`radio-${value}`} className="inline-block mr-6">
                            <input
                                className="fancy-radio"
                                type="radio"
                                name="sentinel-hub-style"
                                value={value}
                                id={`radio-${value}`}
                                checked={visibleStyle === value}
                                onChange={() => pickStyle(value)}
                            />{' '}
                            <label htmlFor={`radio-${value}`}>{t(i18nKey)}</label>
                        </div>
                    ))}
                </div>
                <div className="mt-auto">
                    <Button
                        variant="outline"
                        onClick={() => changeModal({ name: 'vegetation', props: {} })}
                        wide
                        className={styles.compareButton}
                    >
                        {t('field_health_compare')}
                    </Button>
                </div>
            </div>
        </SidebarModule>
    );
};

export default connect(
    (state: AppState) => ({
        sentinelHubMaps: state.vegetationMapsState.sentinelHubMaps,
        visibleSentinelHubMap: state.vegetationMapsState.visibleSentinelHubMap,
        visibleStyle: state.vegetationMapsState.visibleStyle,
    }),
    (dispatch) => bindActionCreators({ pickSentinelHubMap, pickStyle, changeModal }, dispatch)
)(SentinelHubMapPicker);
