import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromise } from '@fieldmargin/webapp-state';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import c from 'classnames';
import icon from 'components/icons/icon';
import type FieldUsage from 'fields/FieldUsage';
import { getUsageName } from 'fields/FieldUsage';
import type { List, Map, Set } from 'immutable';
import { Form, Option, Select } from 'informed';
import { OutputUnit } from 'outputs/Output';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarError from 'sidebar/SidebarError';
import TextInputFormField from 'view/form/TextInputFormField';
import { required } from 'view/form/validations';

interface BulkOutputCreationFormProps {
    fieldUsages: List<FieldUsage>;
    selectedUsages: Set<string>;
    selectedUsageUnits: Map<string, OutputUnit>;
    onSave: (values: { name: string; unit: OutputUnit }[]) => Promise<any>;
    onBack: VoidFunction;
}

interface FormValues {
    outputs: {
        [k: string]: {
            name: string;
            unit?: OutputUnit;
        };
    };
}

const BulkOutputCreationForm = ({
    fieldUsages,
    selectedUsages,
    selectedUsageUnits,
    onSave,
    onBack,
}: BulkOutputCreationFormProps) => {
    const { t } = useTranslation();
    const { pending, error } = usePromise<void>();

    const handleSubmit = (values: FormValues) => {
        onSave(Object.values(values.outputs) as { name: string; unit: OutputUnit }[]);
    };

    const handleBack = (e: MouseEvent) => {
        e.preventDefault();
        onBack();
    };

    const filteredFieldUsages = fieldUsages.filter((fieldUsage) =>
        selectedUsages.contains(fieldUsage.uuid)
    );

    const initialValues = {
        outputs: filteredFieldUsages.reduce(
            (map, fieldUsage) => {
                map[fieldUsage.uuid] = {
                    name: getUsageName(fieldUsage),
                    unit: selectedUsageUnits.get(fieldUsage.uuid),
                };
                return map;
            },
            {} as { [k: string]: { name: string; unit?: OutputUnit } }
        ),
    };

    return (
        <Form<FormValues>
            onSubmit={handleSubmit}
            initialValues={initialValues}
            className="scrollable"
        >
            {({ formState }) => (
                <>
                    <SidebarHeader className="pr-5 mb-4">
                        <SidebarHeaderLink to="#" onClick={handleBack}>
                            {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                        </SidebarHeaderLink>
                        <SubmitButton disabled={pending}>{t('save')}</SubmitButton>
                    </SidebarHeader>
                    {error && (
                        <SidebarError title={t('failed')} message={t('something_went_wrong')} />
                    )}
                    <div className="scrolling">
                        <p className="px-5">{t('bulk_output_creation_stage_2')}</p>
                        <table className="table-fixed border-collapse w-full">
                            <thead>
                                <tr className="bordered-b">
                                    <th className="px-5 py-1 w-[400px] text-left">
                                        {t('output_name')}
                                    </th>
                                    <th className="px-5 py-1 text-left">{t('unit')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fieldUsages
                                    .filter((fu) => selectedUsages.has(fu.uuid))
                                    .map((fieldUsage) => (
                                        <tr key={fieldUsage.uuid} className="bordered-b">
                                            <td className="px-5 py-2 relative">
                                                <TextInputFormField
                                                    field={`outputs.${fieldUsage.uuid}.name`}
                                                    validate={required(t('register_required'))}
                                                    validateOnChange
                                                    validateOnBlur
                                                    inputClassName={c({
                                                        'has-error': formState.errors.outputs
                                                            ? formState.errors.outputs[
                                                                  fieldUsage.uuid
                                                              ]?.name
                                                            : false,
                                                    })}
                                                />
                                            </td>
                                            <td className="px-5 py-2">
                                                <Select
                                                    field={`outputs.${fieldUsage.uuid}.unit`}
                                                    className={c(
                                                        'bordered p-2 rounded-sm w-full h-10 focus',
                                                        {
                                                            'error-border': formState.errors.outputs
                                                                ? formState.errors.outputs[
                                                                      fieldUsage.uuid
                                                                  ]?.unit
                                                                : false,
                                                        }
                                                    )}
                                                    validate={required(t('register_required'))}
                                                    validateOnChange
                                                >
                                                    <Option value={undefined}>
                                                        {t('choose_one')}...
                                                    </Option>
                                                    {Object.keys(OutputUnit).map(
                                                        (outputUnit, i) => (
                                                            <Option
                                                                key={i}
                                                                value={OutputUnit[outputUnit]}
                                                            >
                                                                {t(
                                                                    `unit_type_${outputUnit.toLowerCase()}`
                                                                )}
                                                            </Option>
                                                        )
                                                    )}
                                                </Select>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </Form>
    );
};

export default BulkOutputCreationForm;
