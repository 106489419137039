import { useEffect, useRef } from 'react';
import { geojson } from '@fieldmargin/webapp-ol-map';
import MapLayerIndex from 'components/maps/model/MapLayerIndex';
import type { FieldToolTipControllerProps } from 'farm-map/controllers/FieldTooltipController';
import FieldTooltipController from 'farm-map/controllers/FieldTooltipController';
import HerdMarkersController from 'farm-map/controllers/HerdMarkersController';
import InitialsController from 'farm-map/controllers/InitialsController';
import NoteMarkersController from 'farm-map/controllers/NoteMarkersController';
import PassiveEditController from 'farm-map/controllers/PassiveEditController';
import PointFeatureController from 'farm-map/controllers/PointFeatureController';
import SensorsController from 'farm-map/controllers/SensorsController';
import SubFieldsController from 'farm-map/controllers/SubFieldsController';
import type { Feature } from 'geojson';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { getMapKeyConfig } from 'lib/config';
import { formatAreaNicely, formatLengthNicely } from 'lib/geo/maths';
import { uniqueId } from 'lodash';
import { getAllowedBasemapTypes } from 'system/basemaps/basemaps';
import { useAppSelector } from 'system/store';
import { selectUserAreaMeasurementUnit, selectUserLengthMeasurementUnit } from 'users/user-state';

export interface FarmMapProps {
    basemap: string;
    tiledLayers: geojson.TiledLayerSpec[];
    mainFeatures: geojson.FeaturesContollerData;
    drawing: geojson.DrawingControllerProps;
    editing: Feature[];
    editingAttachments: geojson.FeaturesContollerData;
    fields: geojson.FeaturesContollerData;
    fieldTooltip: FieldToolTipControllerProps;
    noteMarkers: Feature[];
    initials: geojson.FeaturesContollerData;
    sensors: geojson.FeaturesContollerData;
    pointFeatures: geojson.FeaturesContollerData;
    herdMarkers: Feature[];
    subFields: Feature[];
}

export interface FarmMapEvents {
    onFeatureHover: (featureId: string | number | undefined) => any;
    onFeatureClick: (featureId: string | number | undefined) => any;
    onFeatureAdded: (feature: Feature) => any;
    onFeatureUpdated: (feature: Feature) => any;
    onMapMove: (position: geojson.ViewportPosition) => any;
}

export const useCreateFarmMap = (events: FarmMapEvents) => {
    const { currentFarm } = useCurrentFarm();
    const hostElementId = useRef(uniqueId('farm-map-')).current;
    const mapRef = useRef<geojson.OpenLayersMap<FarmMapProps> | null>(null);
    const lengthUnits = useAppSelector(selectUserLengthMeasurementUnit);
    const areaUnits = useAppSelector(selectUserAreaMeasurementUnit);
    const sizeFormatter = (size: number, type: 'length' | 'area') => {
        if (type === 'length') {
            return formatLengthNicely(size, lengthUnits);
        }
        return formatAreaNicely(size, areaUnits);
    };

    useEffect(() => {
        mapRef.current = new geojson.OpenLayersMap(
            {
                hostElementId,
                viewport: {
                    zoom: 15,
                    maxZoom: 21,
                    centre: currentFarm.geoJsonPoint,
                },
                controls: [
                    geojson.ControlType.ATTRIBUTION,
                    geojson.ControlType.SCALE,
                    geojson.ControlType.ZOOM,
                ],
            },
            {
                basemap: new geojson.BasemapController(
                    getAllowedBasemapTypes(false),
                    getMapKeyConfig(),
                    1,
                    {
                        language: window.navigator?.language ?? 'en-GB',
                        country: currentFarm.country,
                    }
                ),
                tiledLayers: new geojson.TiledLayersController(
                    MapLayerIndex.LAYER_CUSTOM_START,
                    MapLayerIndex.LAYER_CUSTOM_END
                ),
                mainFeatures: new geojson.FeaturesController(MapLayerIndex.LAYER_MAIN, {}),
                fields: new geojson.FeaturesController(MapLayerIndex.LAYER_FIELDS, {}),
                drawing: new geojson.DrawingController(sizeFormatter, events.onFeatureAdded),
                editing: new geojson.EditingController(
                    MapLayerIndex.LAYER_EDITING,
                    sizeFormatter,
                    events.onFeatureUpdated
                ),
                editingAttachments: new PassiveEditController(MapLayerIndex.LAYER_PASSIVE_EDIT),
                fieldTooltip: new FieldTooltipController(events.onFeatureHover),
                noteMarkers: new NoteMarkersController(MapLayerIndex.LAYER_NOTES),
                pointFeatures: new PointFeatureController(MapLayerIndex.LAYER_POINT_FEATURES),
                initials: new InitialsController(MapLayerIndex.LAYER_VEHICLES),
                sensors: new SensorsController(MapLayerIndex.LAYER_SENSORS),
                herdMarkers: new HerdMarkersController(MapLayerIndex.LAYER_HERDS),
                subFields: new SubFieldsController(
                    MapLayerIndex.LAYER_SUB_FIELDS,
                    events.onFeatureUpdated
                ),
            },
            {
                featureClick: (id) => {
                    events.onFeatureClick(id);
                },
                viewportMove: events.onMapMove,
            }
        );
        // @ts-ignore - TODO expose this method on the OpenLayersMap in webapp-ol-map
        mapRef.current.map.updateSize();
    }, []);

    return { mapRef, hostElementId };
};
