import type { GeoPosition } from '@fieldmargin/webapp-geo';
import { geoPositionFromArray } from '@fieldmargin/webapp-geo';
import type { Timestamp } from 'firebase/firestore';

export interface FarmUserLocationDTO {
    longitude: number;
    latitude: number;
    updatedDate: Timestamp;
}
export default interface FarmUserLocation {
    position: GeoPosition;
    lastUpdated: Date;
}
export const deserializeFarmUserLocation = (json: FarmUserLocationDTO): FarmUserLocation => ({
    position: geoPositionFromArray([json.longitude, json.latitude]),
    lastUpdated: json.updatedDate.toDate(),
});
