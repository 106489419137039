import { Fragment } from 'react';
import { connect } from 'react-redux';
import { getFieldArea, getFieldWorkedArea } from 'fields/Field';
import type { FieldUsageWithFields } from 'fields/fields-selectors';
import { selectMaybeVisibleMaybeArchivedFieldUsagesWithFieldsGroupedByArchived } from 'fields/fields-selectors';
import { getUsageName } from 'fields/FieldUsage';
import type { List } from 'immutable';
import { usePersistedItemSelectedState } from 'lib/hooks';
import type { AppState } from 'system/store';
import ClickableRow from 'view/a11y/ClickableRow';
import SizeBubble from 'view/molecules/size-bubble/SizeBubble';
import FormattedArea from 'view/units/FormattedArea';
import { selectCurrentYear } from 'years/years-state';

import FieldUsageVisibilityButton from '../FieldUsageVisibilityButton';

import BulkFieldEditArchivedHeader from './BulkFieldEditArchivedHeader';
import FieldEditRow from './FieldEditRow';

import './FieldEditByUsage.css';

interface FieldEditByUsageProps {
    year: number;
    fieldUsagesWithFields: {
        active: List<FieldUsageWithFields>;
        archived: List<FieldUsageWithFields>;
    };
}

const FieldEditByUsage = ({ year, fieldUsagesWithFields }: FieldEditByUsageProps) => {
    const [toggleClosedFieldUsage, closedFieldUsageIncludes] =
        usePersistedItemSelectedState('closedFieldUsages');

    return (
        <>
            {fieldUsagesWithFields.active.map(({ fieldUsage, fields }) => (
                <Fragment key={fieldUsage.uuid}>
                    <ClickableRow
                        onClick={toggleClosedFieldUsage.bind(null, fieldUsage.uuid)}
                        className="FieldUsageRow"
                    >
                        <td>
                            <div className="flex items-center gap-3 text-left">
                                <div
                                    className="w-2 self-stretch"
                                    style={{
                                        backgroundColor: fieldUsage.colour,
                                    }}
                                />
                                <FieldUsageVisibilityButton
                                    fieldUsage={fieldUsage}
                                    className="w-5"
                                />
                                <strong className="flex-grow py-4">
                                    {getUsageName(fieldUsage)}
                                </strong>
                            </div>
                        </td>
                        <td>
                            <SizeBubble>
                                <strong>
                                    <FormattedArea
                                        areaSqm={fields.reduce(
                                            (total, field) => total + getFieldArea(field),
                                            0
                                        )}
                                        withUnit
                                    />
                                </strong>
                            </SizeBubble>
                        </td>
                        <td>
                            <SizeBubble>
                                <strong>
                                    <FormattedArea
                                        areaSqm={fields.reduce(
                                            (total, field) =>
                                                total + getFieldWorkedArea(field, year),
                                            0
                                        )}
                                        withUnit
                                    />
                                </strong>
                            </SizeBubble>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <i
                                className={
                                    closedFieldUsageIncludes(fieldUsage.uuid)
                                        ? 'ion-arrow-right-b'
                                        : 'ion-arrow-down-b'
                                }
                            />
                        </td>
                    </ClickableRow>
                    {fields.map((field) => (
                        <FieldEditRow
                            key={field.uuid}
                            field={field}
                            hidden={closedFieldUsageIncludes(fieldUsage.uuid)}
                        />
                    ))}
                </Fragment>
            ))}
            {fieldUsagesWithFields.archived.size > 0 && <BulkFieldEditArchivedHeader />}
            {fieldUsagesWithFields.archived.map(({ fieldUsage, fields }) => (
                <Fragment key={fieldUsage.uuid}>
                    <ClickableRow
                        onClick={toggleClosedFieldUsage.bind(null, fieldUsage.uuid)}
                        className="FieldUsageRow"
                    >
                        <td>
                            <div className="flex items-center gap-3 text-left">
                                <div
                                    className="w-2 self-stretch"
                                    style={{
                                        backgroundColor: fieldUsage.colour,
                                    }}
                                />
                                <FieldUsageVisibilityButton
                                    fieldUsage={fieldUsage}
                                    className="w-5"
                                />
                                <strong className="flex-grow py-4">
                                    {getUsageName(fieldUsage)}
                                </strong>
                            </div>
                        </td>
                        <td>
                            <SizeBubble>
                                <strong>
                                    <FormattedArea
                                        areaSqm={fields.reduce(
                                            (total, field) => total + getFieldArea(field),
                                            0
                                        )}
                                        withUnit
                                    />
                                </strong>
                            </SizeBubble>
                        </td>
                        <td>
                            <SizeBubble>
                                <strong>
                                    <FormattedArea
                                        areaSqm={fields.reduce(
                                            (total, field) =>
                                                total + getFieldWorkedArea(field, year),
                                            0
                                        )}
                                        withUnit
                                    />
                                </strong>
                            </SizeBubble>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>
                            <i
                                className={
                                    closedFieldUsageIncludes(fieldUsage.uuid)
                                        ? 'ion-arrow-right-b'
                                        : 'ion-arrow-down-b'
                                }
                            />
                        </td>
                    </ClickableRow>
                    {fields.map((field) => (
                        <FieldEditRow
                            key={field.uuid}
                            field={field}
                            hidden={closedFieldUsageIncludes(fieldUsage.uuid)}
                        />
                    ))}
                </Fragment>
            ))}
        </>
    );
};

export default connect((state: AppState) => ({
    year: selectCurrentYear(state),
    fieldUsagesWithFields:
        selectMaybeVisibleMaybeArchivedFieldUsagesWithFieldsGroupedByArchived(state),
}))(FieldEditByUsage);
