import { AttachmentType } from 'farm-editing/attachments';
import { selectEditingAttachmentsByType } from 'farm-editing/farm-editing-state';
import { useAppSelector } from 'system/store';

const useEditingFieldUuids = () => {
    const editingFieldUuids = useAppSelector((state) =>
        selectEditingAttachmentsByType(state, AttachmentType.FIELD)
    );
    return {
        editingFieldUuids,
    };
};

export { useEditingFieldUuids };
