import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import c from 'classnames';
import { useAppDispatch } from 'system/store';
import { changeModal } from 'system/ui-state';

import helpIcon from './help.svg';
import ListDropdown from './ListDropdown';
import ListDropdownItem from './ListDropdownItem';
import TopNavItem from './TopNavItem';

const HelpNavItem = ({
    className,
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [open, toggleOpen] = useToggle(false);
    const handleContact = () => {
        toggleOpen();
        if (window.Intercom) {
            window.Intercom('showNewMessage');
        } else {
            dispatch(
                changeModal({
                    name: 'feedback',
                    props: {},
                })
            );
        }
    };

    const handleClick = () => {
        toggleOpen();
    };
    return (
        <div className={c('relative flex', className)}>
            <TopNavItem onClick={handleClick} data-dropdown="help">
                <img src={helpIcon} alt="A question mark icon" />
                <div className="sr-only">{t('hamburger_menu_help')}</div>
            </TopNavItem>
            {open && (
                <ListDropdown onClose={toggleOpen} className="right-0 left-auto" name="help">
                    <ListDropdownItem>
                        <a
                            href="http://support.fieldmargin.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ListDropdownItem"
                        >
                            {t('hamburger_menu_help')}
                        </a>
                    </ListDropdownItem>
                    <ListDropdownItem>
                        <button className="bare-btn ListDropdownItem" onClick={handleContact}>
                            {t('get_in_touch')}
                        </button>
                    </ListDropdownItem>
                </ListDropdown>
            )}
        </div>
    );
};

export default HelpNavItem;
