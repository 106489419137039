import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import type Field from 'fields/Field';
import { List } from 'immutable';
import { getUuid } from 'lib/fp-helpers';
import type FullOperation from 'operations/FullOperation';
import { selectOperationFieldCompleteCount } from 'operations/FullOperation';
import type { AppState } from 'system/store';

import './OperationCompletionCount.css';

interface OperationCompletionCountProps {
    // From parents
    fullOperation: FullOperation;
    className?: string;

    // From redux
    fieldUuids: List<string>;
}

const OperationCompletionCount = ({
    fullOperation,
    className,
    fieldUuids,
}: OperationCompletionCountProps) => {
    const { t } = useTranslation();

    if (!fullOperation.fields || fullOperation.fields.size === 0) {
        return null;
    }

    const fields = fullOperation.fields.filter((opField) => fieldUuids.includes(opField.fieldUuid));

    return (
        <p className={classNames('OperationCompleteCount', className)}>
            {t('completed')}:{' '}
            <strong>
                {selectOperationFieldCompleteCount(fields)}/{fields.size}
            </strong>
        </p>
    );
};

export default connect((state: AppState) => ({
    fieldUuids: state.fieldsState.fields?.map(getUuid) ?? List<Field>(),
}))(OperationCompletionCount);
