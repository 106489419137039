import type Input from 'inputs/Input';
import type { InputReportSummary } from 'inputs/InputReportSummary';
import type { InputCostDTO } from 'inputs/inputs-api';
import { parseNumericOrZero } from 'lib/util/number';

import type { EditInputCostsFormValues } from './EditInputCostsForm';

export const inputToReportSummary = (input: Input, costs: Record<string, string>) =>
    ({
        uuid: input.uuid,
        name: input.name,
        inputType: input.type,
        inputUnit: input.unit,
        sumArea: 0,
        sumTotals: 0,
        unitCost: parseNumericOrZero(costs[input.uuid] ?? ''),
    }) as InputReportSummary;

export const getDefaultEditCostFormValues = (
    reportData: InputReportSummary[],
    costs: Record<string, string>
) =>
    reportData.reduce((map, data) => {
        const unit = parseNumericOrZero(costs[data.uuid] ?? '');
        const total = unit * data.sumTotals;
        map[data.uuid] = {
            unit: unit === 0 ? NaN : unit,
            total: total === 0 ? NaN : total,
        };
        return map;
    }, {} as EditInputCostsFormValues);

export const formValuesToCostDTOs = (
    values: EditInputCostsFormValues,
    year: number
): InputCostDTO[] =>
    Object.entries(values).map(([inputUuid, { unit }]) => ({
        operationInputUUID: inputUuid,
        unitCost: !isNaN(unit) ? unit : null,
        year,
    }));
