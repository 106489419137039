import type { Farm } from '@fieldmargin/webapp-farms';
import type { Extent, GeoFeature, GeoPosition } from '@fieldmargin/webapp-geo';
import { deserializeLngLat, GeoPoint, Projection, reprojectExtent } from '@fieldmargin/webapp-geo';
import type {
    DrawingControllerProps,
    FeaturesContollerData,
    TiledLayerSpec,
    ViewportAction,
} from '@fieldmargin/webapp-ol-map';
import {
    allBasemaps,
    BasemapController,
    Control,
    DrawingController,
    EditingController,
    FeaturesController,
    OpenLayersMap,
    TiledLayersController,
} from '@fieldmargin/webapp-ol-map';
import type { Point } from 'geojson';
import type { Map } from 'immutable';
import { getMapKeyConfig } from 'lib/config';
import { formatAreaNicely, formatLengthNicely } from 'lib/geo/maths';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { logReselect } from 'lib/util/reselect-util';
import { createSelector } from 'reselect';

import MapLayerIndex from '../model/MapLayerIndex';
import MapPosition from '../model/MapPosition';
import type MapView from '../model/MapView';

import type { FieldToolTipControllerProps } from './FieldTooltipController';
import FieldTooltipController from './FieldTooltipController';
import HerdMarkersController from './HerdMarkersController';
import InitialsController from './InitialsController';
import NoteMarkersController from './NoteMarkersController';
import PassiveEditController from './PassiveEditController';
import PointFeatureController from './PointFeatureController';
import SensorsController from './SensorsController';
import SubFieldsController from './SubFieldsController';

interface LegacyProps {
    farm: Farm;
    mapData: MapData;

    defaultPoint: Point;
    defaultZoom?: number;
    maxZoom?: number;
    baseMap?: string;
    mapView?: MapView;
    geoFeatureMap: Map<string, GeoFeature>;
    onClusterClick: (features: any[]) => boolean;
    onGeoFeatureClick: (id: string, type: string) => void;
    onGeoFeatureHover: (id: string | number | undefined) => void;
    onGeoFeatureAdd: (added: GeoFeature) => void;
    onGeoFeatureUpdate: (updated: GeoFeature, type?: string) => void;
    onMapClick: (position: GeoPosition) => void;
    onMapMove: (position: MapPosition) => void;
    onTiledLayersLoading?: (loadingLayers: string[]) => void;
    onTiledLayersError?: (errorLayers: string[]) => void;
    lengthUnits: MeasurementUnit;
    areaUnits: MeasurementUnit;
    declutterFieldNames: boolean;
}

export interface OLMDef {
    basemap: string;
    tiledLayers: TiledLayerSpec[];
    mainFeatures: FeaturesContollerData;
    drawing: DrawingControllerProps;
    editing: GeoFeature[];
    editingFields: FeaturesContollerData;
    fields: FeaturesContollerData;
    fieldTooltip: FieldToolTipControllerProps;
    noteMarkers: GeoFeature[];
    initials: FeaturesContollerData;
    sensors: FeaturesContollerData;
    pointFeatures: FeaturesContollerData;
    herdMarkers: GeoFeature[];
    subFields: GeoFeature[];
}

export interface MapData {
    basemap: string;
    tiledLayers: TiledLayerSpec[];
    drawing: DrawingControllerProps;
    sensors: GeoFeature[];
    initials: GeoFeature[];
    noteMarkers: GeoFeature[];
    fieldTooltipProps: FieldToolTipControllerProps;
    pointFeatures: GeoFeature[];
    herdMarkers: GeoFeature[];
}

const DEFAULT_ZOOM = 15;
const DEFAULT_MAX_ZOOM = 21;

class LegacyMapAdapter {
    private olm: OpenLayersMap<OLMDef>;
    private props: LegacyProps;

    constructor(hostElementId: string, props: LegacyProps) {
        const sizeFormatter = (size: number, type: 'length' | 'area') => {
            if (type === 'length') {
                return formatLengthNicely(size, props.lengthUnits);
            }
            return formatAreaNicely(size, props.areaUnits);
        };

        this.olm = new OpenLayersMap(
            {
                hostElementId,
                viewport: {
                    centre: deserializeLngLat(props.defaultPoint) as GeoPoint,
                    zoom: props.defaultZoom || DEFAULT_ZOOM,
                    maxZoom: props.maxZoom || DEFAULT_MAX_ZOOM,
                },
                controls: [Control.ATTRIBUTION, Control.SCALE, Control.ZOOM],
            },
            {
                basemap: new BasemapController(allBasemaps, getMapKeyConfig(), 1, {
                    language: window.navigator?.language ?? 'en-GB',
                    country: props.farm.country,
                }),
                tiledLayers: new TiledLayersController(
                    MapLayerIndex.LAYER_CUSTOM_START,
                    MapLayerIndex.LAYER_CUSTOM_END,
                    (loadingLayers) => {
                        if (this.props.onTiledLayersLoading) {
                            this.props.onTiledLayersLoading(loadingLayers);
                        }
                    },
                    (errorLayers) => {
                        if (this.props.onTiledLayersError) {
                            this.props.onTiledLayersError(errorLayers);
                        }
                    }
                ),
                mainFeatures: new FeaturesController(MapLayerIndex.LAYER_MAIN, {}),
                fields: new FeaturesController(
                    MapLayerIndex.LAYER_FIELDS,
                    {},
                    { declutter: props.declutterFieldNames }
                ),
                drawing: new DrawingController(sizeFormatter, (geoFeature: GeoFeature) => {
                    if (this.props.onGeoFeatureAdd) {
                        this.props.onGeoFeatureAdd(geoFeature);
                    }
                }),
                editing: new EditingController(
                    MapLayerIndex.LAYER_EDITING,
                    sizeFormatter,
                    (geoFeature: GeoFeature) => {
                        if (this.props.onGeoFeatureUpdate) {
                            this.props.onGeoFeatureUpdate(geoFeature);
                        }
                    }
                ),
                editingFields: new PassiveEditController(MapLayerIndex.LAYER_PASSIVE_EDIT),
                fieldTooltip: new FieldTooltipController(props.onGeoFeatureHover),
                noteMarkers: new NoteMarkersController(MapLayerIndex.LAYER_NOTES),
                pointFeatures: new PointFeatureController(MapLayerIndex.LAYER_POINT_FEATURES),
                initials: new InitialsController(MapLayerIndex.LAYER_VEHICLES),
                sensors: new SensorsController(MapLayerIndex.LAYER_SENSORS),
                herdMarkers: new HerdMarkersController(MapLayerIndex.LAYER_HERDS),
                subFields: new SubFieldsController(
                    MapLayerIndex.LAYER_SUB_FIELDS,
                    (geoFeature: GeoFeature) => {
                        if (this.props.onGeoFeatureUpdate) {
                            this.props.onGeoFeatureUpdate(geoFeature, 'sub-field');
                        }
                    }
                ),
            },
            {
                featureClick: (id, type) => {
                    if (this.props.onGeoFeatureClick) {
                        this.props.onGeoFeatureClick(id, type);
                    }
                },
                clusterClick: (features) => {
                    if (this.props.onClusterClick) {
                        return this.props.onClusterClick(features);
                    }
                    return false;
                },
                basemapClick: (geoPoint) => {
                    if (this.props.onMapClick) {
                        this.props.onMapClick(geoPoint.coordinates);
                    }
                },
                viewportMove: (position) => {
                    if (this.props.onMapMove) {
                        this.props.onMapMove(
                            MapPosition({
                                centre: position.centre.coordinates,
                                zoom: position.zoom,
                                extent: reprojectExtent(position.extent, Projection.LNG_LAT),
                            })
                        );
                    }
                },
            }
        );
    }

    update(props: LegacyProps, resize = false) {
        this.props = props;

        this.olm.update({
            ...selectData(props),
            ...props.mapData,
            initials: { geoFeatures: props.mapData.initials, options: {} },
            sensors: { geoFeatures: props.mapData.sensors, options: {} },
            pointFeatures: { geoFeatures: props.mapData.pointFeatures, options: {} },
        } as OLMDef);

        if (resize) {
            // @ts-ignore - TODO expose this method on the OpenLayersMap in webapp-geo
            this.olm.map.updateSize();
        }

        let viewportAction = undefined as ViewportAction | undefined;
        if (props.mapView) {
            if (props.mapView.type === 'extent') {
                viewportAction = {
                    id: props.mapView.id,
                    position: {
                        extent: props.mapView.payload as Extent,
                        maxZoom: 18,
                        padding: [50, 50, 50, 50],
                    },
                };
            }
            if (props.mapView.type === 'position') {
                const payload = props.mapView.payload as MapPosition;
                if (payload.zoom && payload.centre) {
                    viewportAction = {
                        id: props.mapView.id,
                        position: {
                            centre: GeoPoint({
                                projection: Projection.LNG_LAT,
                                coordinates: payload.centre,
                            }),
                            zoom: payload.zoom,
                        },
                    };
                }
            }
        }

        if (viewportAction) {
            this.olm.updateViewport(viewportAction);
        }
    }
}

const selectMainFeatures = createSelector(
    (props: LegacyProps) => props.geoFeatureMap,
    (geoFeatureMap): FeaturesContollerData => {
        logReselect('LegacyMapAdapter.selectMainFeatures');
        return {
            geoFeatures: [...geoFeatureMap.values()].filter(
                (geoFeature) =>
                    !geoFeature.properties.get('editable') &&
                    !geoFeature.properties.get('hideShapes') &&
                    !geoFeature.properties.get('passiveEditMode') &&
                    geoFeature.properties.get('type') !== 'field' &&
                    geoFeature.properties.get('type') !== 'sub-field'
            ),
            options: {},
        };
    }
);

const selectFieldFeatures = createSelector(
    (props: LegacyProps) => props.geoFeatureMap,
    (props: LegacyProps) => props.declutterFieldNames,
    (geoFeatureMap, declutter): FeaturesContollerData => {
        return {
            geoFeatures: [...geoFeatureMap.values()].filter(
                (geoFeature) =>
                    !geoFeature.properties.get('editable') &&
                    geoFeature.properties.get('type') === 'field'
            ),
            options: { declutter },
        };
    }
);

const selectEditingFeatures = createSelector(
    (props: LegacyProps) => props.geoFeatureMap,
    (geoFeatureMap): GeoFeature[] => {
        logReselect('LegacyMapAdapter.selectEditingFeatures');
        return [...geoFeatureMap.values()].filter(
            (geoFeature) =>
                geoFeature.properties.get('type') !== 'sub-field' &&
                geoFeature.properties.get('editable')
        );
    }
);

const selectEditingFields = createSelector(
    (props: LegacyProps) => props.geoFeatureMap,
    (geoFeatureMap): FeaturesContollerData => {
        logReselect('LegacyMapAdapter.selectEditingFields');
        return {
            geoFeatures: [...geoFeatureMap.values()].filter(
                (geoFeature) =>
                    geoFeature.properties.get('type') !== 'sub-field' &&
                    geoFeature.properties.get('passiveEditMode')
            ),
            options: {},
        };
    }
);

const selectSubFields = createSelector(
    (props: LegacyProps) => props.geoFeatureMap,
    (geoFeatureMap): GeoFeature[] => {
        return [...geoFeatureMap.values()].filter(
            (geoFeature) => geoFeature.properties.get('type') === 'sub-field'
        );
    }
);

const selectData = createSelector(
    selectMainFeatures,
    selectEditingFeatures,
    selectEditingFields,
    selectFieldFeatures,
    selectSubFields,
    (props: LegacyProps) => props.mapData.fieldTooltipProps,
    (
        mainFeatures,
        editingFeatures,
        editingFields,
        fields,
        subFields,
        fieldTooltipProps
    ): Partial<OLMDef> => {
        logReselect('LegacyMapAdapter.selectData');
        return {
            mainFeatures,
            fields,
            editing: editingFeatures,
            editingFields,
            fieldTooltip: fieldTooltipProps,
            subFields,
        };
    }
);

export default LegacyMapAdapter;
