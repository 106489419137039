import type FullOperation from 'operations/FullOperation';
import ActionMenu from 'sidebar/modules/action-menu/ActionMenu';
import ActionMenuDeleteItem from 'sidebar/modules/action-menu/ActionMenuDeleteItem';

import OperationPrintButton from './OperationPrintButton';

interface OperationActionMenuProps {
    fullOperation: FullOperation;
}

const OperationActionMenu = ({ fullOperation }: OperationActionMenuProps) => {
    return (
        <ActionMenu className="ml-auto">
            <OperationPrintButton fullOperation={fullOperation} />
            <ActionMenuDeleteItem
                item={fullOperation.summary}
                to={`/operations/${fullOperation.uuid}/delete`}
            />
        </ActionMenu>
    );
};

export default OperationActionMenu;
