import { useParams } from 'react-router-dom';
import { useAppSelector } from 'system/store';

import { selectField } from './fields-selectors';

/**
 * A HOC that adds a field prop to the given component.
 * The field prop is either a Field object or undefined.
 */
const withPageField =
    <Props,>(Component: React.ComponentType<Props>) =>
    (props: Props) => {
        const fieldUuid = useParams<{ fieldUuid: string }>().fieldUuid;
        const field =
            fieldUuid !== undefined
                ? useAppSelector((state) => selectField(state, fieldUuid))
                : undefined;

        return <Component {...props} field={field} />;
    };

export default withPageField;
