import { useTranslation } from 'react-i18next';
import { format, formatDistanceStrict, formatDistanceToNow, formatDuration } from 'date-fns';
import { cs, de, enGB, fr, pl, ro, sk, uk } from 'date-fns/locale';
import { partialRight } from 'ramda';

type FormatDistanceToNowParams = Parameters<typeof formatDistanceToNow>;
type FormatDistanceStrictParams = Parameters<typeof formatDistanceStrict>;
type FormatDurationParams = Parameters<typeof formatDuration>;

const useDateFormat = () => {
    const { i18n } = useTranslation();
    const locale = getLocale(i18n.language);
    const formatFn = partialRight(format, [{ locale }]);
    const formatDistanceToNowFn = (
        date: FormatDistanceToNowParams[0],
        opts?: FormatDistanceToNowParams[1]
    ) => formatDistanceToNow(date, { ...opts, locale });

    const formatDistanceStrictFn = (
        date: FormatDistanceStrictParams[0],
        baseDate: FormatDistanceStrictParams[1],
        opts?: FormatDistanceStrictParams[2]
    ) => formatDistanceStrict(date, baseDate, { ...opts, locale });

    const formatDurationFn = (date: FormatDurationParams[0], opts?: FormatDurationParams[1]) =>
        formatDuration(date, { ...opts, locale });

    return {
        format: formatFn,
        formatDistanceToNow: formatDistanceToNowFn,
        formatDistanceStrict: formatDistanceStrictFn,
        formatDuration: formatDurationFn,
    };
};

const getLocale = (language: string) => {
    switch (language) {
        case 'de':
            return de;
        case 'cs':
            return cs;
        case 'fr':
            return fr;
        case 'pl':
            return pl;
        case 'ro':
            return ro;
        case 'sk':
            return sk;
        case 'uk':
            return uk;
        default:
            return enGB;
    }
};

export { useDateFormat };
