import type { Extent, GeoPolygon } from '@fieldmargin/webapp-geo';
import { extentFromGeo, Projection, reprojectExtent, toWKT } from '@fieldmargin/webapp-geo';
import type { WmsTilesSpec } from '@fieldmargin/webapp-ol-map';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { format, subDays } from 'date-fns';
import { List } from 'immutable';
import config from 'lib/config';
import { dedupeList } from 'lib/immutil';

import WmsTiles from '../common/WmsTiles';

interface SentinelHubFeatures {
    type: 'FeatureCollection';
    features: [
        {
            type: 'Feature';
            geometry: any; // MultiPolygon in EPSG:3857
            properties: {
                id: string; // 'S2B_OPER_MSI_L1C_TL_SGS__20190225T151114_A010301_T33TVM_N02.07';
                date: string; // '2019-02-25';
                time: string; // '10:09:44';
                path: string; // 's3://sentinel-s2-l1c/tiles/33/T/VM/2019/2/25/0';
                crs: string; // 'EPSG:32633';
                mbr: string; // '399960,5090220 509760,5200020';
                cloudCoverPercentage: number; // 67.58;
            };
        },
    ];
}

export interface SentinelHubMap {
    id: string;
    timestamp: Date;
    cloudCover: number; // percentage, 0 to 100
}

export enum SentinelHubStyle {
    RGB = 'TRUE',
    NDVI = 'NDVI',
}

export const getSentinelHubMapsForAreaApi = (extent: Extent, isTrial: boolean) => {
    return Promise.resolve(findAllMaps(extent, isTrial));
};

const findAllMaps = async (extent: Extent, isTrial: boolean) => {
    const e = reprojectExtent(extent, Projection.WEB_MERCATOR);
    const bbox = `${e.minX},${e.minY},${e.maxX},${e.maxY}`;

    let requestCount = 0;
    let results = List<SentinelHubMap>();
    let done = false;

    while (!done) {
        const endDate = results.size
            ? subDays((results.last() as SentinelHubMap).timestamp, 1)
            : new Date();
        const found = await searchSentinelHub(bbox, endDate, isTrial);
        results = results.concat(found);

        done = ++requestCount >= 10 || found.size === 0;
    }

    const notCloudy = results.filter((shm) => shm.cloudCover < 50);
    const uniqueNotCloudy = dedupeList(notCloudy, (shm) => shm.timestamp.toISOString());

    return uniqueNotCloudy;
};

const searchSentinelHub = async (bbox: string, endDate: Date, isTrial: boolean) => {
    const time = `${format(endDate, 'yyyy-MM-dd')}`;
    return axios({
        method: 'get',
        url: config.get(isTrial ? 'sentinelHubWfsApiTrial' : 'sentinelHubWfsApiPaid'),
        params: {
            request: 'GetFeature',
            typenames: 'S2.TILE',
            outputformat: 'application/json',
            bbox,
            time,
            maxfeatures: 100,
        },
    }).then((response: AxiosResponse<SentinelHubFeatures>) => {
        return List(response.data.features).map((feature) => {
            const props = feature.properties;
            return {
                id: props.id,
                timestamp: new Date(`${props.date}T12:00:00.000`),
                cloudCover: props.cloudCoverPercentage,
            } as SentinelHubMap;
        });
    });
};

export const getTilesForSentinelHubMap = (
    shm: SentinelHubMap,
    extent: Extent,
    style: SentinelHubStyle,
    isTrial: boolean
) => {
    return WmsTiles({
        url: config.get(isTrial ? 'sentinelHubWmsApiTrial' : 'sentinelHubWmsApiPaid'),
        extent,
        params: {
            layers: [style],
            maxcc: 100,
            time: format(shm.timestamp, 'yyyy-MM-dd/yyyy-MM-dd'),
            transparent: false,
        },
    });
};

export const getClippedTilesForSentinelHubMap = (
    shm: SentinelHubMap,
    polygon: GeoPolygon,
    style: SentinelHubStyle,
    isTrial: boolean
) => {
    return WmsTiles({
        id: shm.id,
        url: config.get(isTrial ? 'sentinelHubWmsApiTrial' : 'sentinelHubWmsApiPaid'),
        extent: extentFromGeo(polygon) as Extent,
        params: {
            layers: [style],
            maxcc: 100,
            time: format(shm.timestamp, 'yyyy-MM-dd/yyyy-MM-dd'),
            transparent: true,
            geometry: toWKT(polygon),
        },
    });
};

export const getNewTilesForSentinelHubMap = (
    shm: SentinelHubMap,
    polygon: GeoPolygon,
    style: SentinelHubStyle,
    isTrial: boolean
): WmsTilesSpec => {
    return {
        url: config.get(isTrial ? 'sentinelHubWmsApiTrial' : 'sentinelHubWmsApiPaid'),
        params: {
            layers: [style],
            maxcc: 100,
            time: format(shm.timestamp, 'yyyy-MM-dd/yyyy-MM-dd'),
            transparent: true,
            geometry: toWKT(polygon),
        },
        tileGrid: {
            minZoom: 15,
            maxZoom: 15,
            tileSize: [512, 512],
        },
    };
};
