import icon from 'components/icons/icon';

const OnboardingEndDone = () => {
    return (
        <>
            <h2>All done</h2>
            <p>
                You can now explore the web app or you can click in the{' '}
                {icon('info', 'black', 'w-4 mb-1')} at the top right corner to see where everything
                is.
            </p>
        </>
    );
};

export default OnboardingEndDone;
