import { useMetricUnit } from 'hooks/useMetricUnit';
import { isString } from 'lodash';
import Num from 'view/Num';
import Temperature from 'view/units/Temperature';

interface FormattedMetricProps {
    metricType: string;
    value?: number | string;
    unit: string | null;
}

const FormattedMetric = ({ metricType, value, unit }: FormattedMetricProps) => {
    const { getMetricUnit } = useMetricUnit();

    if (value === undefined) {
        return <>--</>;
    }

    const unitStr = getMetricUnit(metricType, unit);

    if (isString(value)) {
        return <>{`${value} ${unitStr}`.trim()}</>;
    }

    if (metricType === 'temperature') {
        return (
            <>
                <Temperature celsius={value} /> {unitStr}
            </>
        );
    }

    return (
        <>
            <Num value={value} places={4} /> {unitStr}
        </>
    );
};

export default FormattedMetric;
