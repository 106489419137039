import type Input from 'inputs/Input';

import ApplicationIntervalResult from './ApplicationIntervalResult';
import ExpiryDateResult from './ExpiryDateResult';
import HarvestIntervalResult from './HarvestIntervalResult';
import LatestApplicationResult from './LatestApplicationResult';
import MaxDoseSeasonResult from './MaxDoseSeasonResult';
import MaxDoseTreatmentResult from './MaxDoseTreatmentResult';
import MaxTreatmentsResult from './MaxTreatmentsResult';
import type { PesticideCheck } from './PesticideCheck';
import { PesticideCheckType } from './PesticideCheck';
import UsePermittedResult from './UsePermittedResult';
import WithholdingPeriodResult from './WithholdingPeriodResult';

interface PesticideCheckResultProps {
    input: Input;
    crop?: string;
    check: PesticideCheck;
}

const PesticideCheckResult = ({ input, crop, check }: PesticideCheckResultProps) => {
    const Component = resultTypeMap[check.type];

    if (Component === undefined) {
        return null;
    }

    // @ts-ignore for some reason the check prop thinks it can be "never" here when it can't
    return <Component input={input} crop={crop} check={check} />;
};

export default PesticideCheckResult;

const resultTypeMap = {
    [PesticideCheckType.EXPIRY_DATE]: ExpiryDateResult,
    [PesticideCheckType.USE_PERMITTED]: UsePermittedResult,
    [PesticideCheckType.MAX_DOSE_TREATMENT]: MaxDoseTreatmentResult,
    [PesticideCheckType.MAX_DOSE_SEASON]: MaxDoseSeasonResult,
    [PesticideCheckType.MAX_TREATMENTS]: MaxTreatmentsResult,
    [PesticideCheckType.APPLICATION_INTERVAL]: ApplicationIntervalResult,
    [PesticideCheckType.WITHHOLDING_PERIOD]: WithholdingPeriodResult,
    [PesticideCheckType.HARVEST_INTERVAL]: HarvestIntervalResult,
    [PesticideCheckType.LATEST_APPLICATION]: LatestApplicationResult,
};
