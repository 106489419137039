import { useFormContext } from 'react-hook-form';
import { getOperationTypeName, OperationType } from 'operations/Operation';

import './OperationTypeFormField.css';

const OperationTypeFormField = () => {
    const { register } = useFormContext<{ type: OperationType }>();
    return (
        <ul className="OperationTypeFormField">
            {Object.entries(OperationType).map(([_, operationType]) => (
                <li key={operationType} className="p-0">
                    <div className="pt-1 float-left">
                        <input
                            {...register('type')}
                            type="radio"
                            className="fancy-radio"
                            id={operationType}
                            value={operationType}
                        />
                        <label htmlFor={operationType} />
                    </div>
                    <label htmlFor={operationType}>{getOperationTypeName(operationType)}</label>
                </li>
            ))}
        </ul>
    );
};

export default OperationTypeFormField;
