import { useState } from 'react';
import { selectCurrentFarm } from 'farms/farms-state';
import { selectSortedFieldUsages } from 'fields/field-usage-selectors';
import { Map, Set } from 'immutable';
import { addOrRemoveInSet } from 'lib/immutil';
import { flow } from 'lodash';
import type { OutputUnit } from 'outputs/Output';
import Output from 'outputs/Output';
import { saveOutputApi } from 'outputs/outputs-api';
import { addOutputs } from 'outputs/outputs-state';
import { useAppDispatch, useAppSelector } from 'system/store';

import BulkOutputCreationUsageSelection from './BulkOutpuCreationUsageSelection';
import BulkOutputCreationForm from './BulkOutputCreationForm';

interface BulkOutputCreationProps {
    onBack: VoidFunction;
}

enum BulkOutputCreationStep {
    USAGES,
    FORM,
}

const BulkOutputCreation = ({ onBack }: BulkOutputCreationProps) => {
    const dispatch = useAppDispatch();
    const farm = useAppSelector(selectCurrentFarm);
    const fieldUsages = useAppSelector(selectSortedFieldUsages);
    const [step, setStep] = useState<BulkOutputCreationStep>(BulkOutputCreationStep.USAGES);
    const [selectedUsages, setSelectedUsages] = useState<Set<string>>(Set());
    const [selectedUsageUnits, setSelectedUsageUnits] = useState<Map<string, OutputUnit>>(Map());

    const handleFieldUsageCheck = (uuid: string) => {
        setSelectedUsages(addOrRemoveInSet(selectedUsages, uuid));
    };

    const handleUnitChange = (uuid: string, unit?: OutputUnit) => {
        setSelectedUsageUnits(
            unit !== undefined
                ? selectedUsageUnits.set(uuid, unit)
                : selectedUsageUnits.delete(uuid)
        );
        if (!selectedUsages.has(uuid)) {
            setSelectedUsages(selectedUsages.add(uuid));
        }
    };

    const handleSave = (values: { name: string; unit: OutputUnit }[]) =>
        Promise.all(values.map((o) => saveOutputApi(farm.uuid, new Output(o))))
            .then(flow(addOutputs, dispatch))
            .then(onBack);

    return (
        <>
            {step === BulkOutputCreationStep.USAGES && (
                <BulkOutputCreationUsageSelection
                    fieldUsages={fieldUsages}
                    selectedUsages={selectedUsages}
                    selectedUsageUnits={selectedUsageUnits}
                    onFieldUsageCheck={handleFieldUsageCheck}
                    onUnitChange={handleUnitChange}
                    onBack={onBack}
                    onNext={() => setStep(BulkOutputCreationStep.FORM)}
                />
            )}
            {step === BulkOutputCreationStep.FORM && (
                <BulkOutputCreationForm
                    fieldUsages={fieldUsages}
                    selectedUsages={selectedUsages}
                    selectedUsageUnits={selectedUsageUnits}
                    onSave={handleSave}
                    onBack={() => setStep(BulkOutputCreationStep.USAGES)}
                />
            )}
        </>
    );
};

export default BulkOutputCreation;
