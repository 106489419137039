import { useState } from 'react';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { useDocumentEventListener } from 'lib/hooks';

import DatePicker from './DatePicker';
import TimePicker from './TimePicker';

interface DateAndTimePickerProps {
    value: Date | null;
    allowDelete?: boolean;
    openDirection: 'top' | 'bottom';
    onChange: (value: Date | null) => void;
    maxDate?: Date;
    disabled?: boolean;
    includeTimeDone?: boolean;
    classWithDate?: string;
    withoutIcon?: boolean;
    dateFormat?: string;
}

const DateAndTimePicker = ({
    value,
    allowDelete = false,
    openDirection,
    onChange,
    maxDate,
    disabled,
    includeTimeDone = true,
    classWithDate,
    withoutIcon = false,
    dateFormat,
}: DateAndTimePickerProps) => {
    const [dateOpen, setDateOpen] = useState(false);
    const [timeOpen, setTimeOpen] = useState(false);

    const handleChange = (date: Date | null) => {
        onChange(date);
        setDateOpen(false);
        setTimeOpen(false);
    };

    useDocumentEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setDateOpen(false);
            setTimeOpen(false);
        }
    });

    return (
        <div className="flex gap-2">
            <DatePicker
                value={value}
                onChange={handleChange}
                open={dateOpen}
                openDirection={openDirection}
                onOpen={(value: boolean) => {
                    setDateOpen(value);
                    setTimeOpen(false);
                }}
                maxDate={maxDate}
                disabled={disabled}
                classWithDate={classWithDate}
                withoutIcon={withoutIcon}
                dateFormat={dateFormat}
            />
            <TimePicker
                value={value === null ? undefined : value}
                onChange={handleChange}
                open={timeOpen}
                openDirection={openDirection}
                onOpen={(value: boolean) => {
                    setDateOpen(false);
                    setTimeOpen(value);
                }}
                disabled={disabled}
                includeDone={includeTimeDone}
                classWithDate={classWithDate}
                withoutIcon={withoutIcon}
            />
            {allowDelete && value && (
                <Button variant="subtle" className="ml-auto" onClick={() => onChange(null)}>
                    {icon('edit-remove', 'red')}
                </Button>
            )}
        </div>
    );
};

export default DateAndTimePicker;
