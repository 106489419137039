import type { GeoFeature, GeoFeatureCollection, GeoObject } from '@fieldmargin/webapp-geo';
import { MeasurementUnit } from 'lib/MeasurementUnit';
import { roundToSf } from 'lib/util/number';
import { pluralise } from 'lib/util/text';
import type { GeoJsonItem } from 'system/types';
import { converter, hectaresToSqm, sqmToHectares } from 'utils/conversion';

import type { Sizes } from './shape-size-cacher';
import { getSizesOfGeometry } from './shape-size-cacher';

export const countPointsInGeometry = (geometry: GeoObject): number => {
    if (geometry.type === 'FeatureCollection') {
        return (geometry as GeoFeatureCollection).features.reduce(
            (total, feature) => total + countPointsInGeometry(feature),
            0
        );
    }
    if (geometry.type === 'Feature') {
        geometry = (geometry as GeoFeature).geometry;
    }
    if (geometry.type === 'Point') {
        return 1;
    }
    return 0;
};

export const formatLengthNicely = (value: number, unit: MeasurementUnit) => {
    let displayedUnit = '';
    let retValue = value;
    if (unit.toLowerCase() === MeasurementUnit.METRIC) {
        displayedUnit = 'm';
        if (value > 1000) {
            displayedUnit = 'km';
            retValue = converter.convertMetresToKilometres(value);
        }
    } else if (unit.toLowerCase() === MeasurementUnit.IMPERIAL) {
        displayedUnit = 'yd';
        // metres to yards
        retValue = converter.convertMetresToYards(value);
        if (retValue > 1760) {
            retValue = converter.convertMetresToMiles(value);
            displayedUnit = pluralise(roundToSf(retValue, 4), 'mile', 'miles');
        }
    }
    if (retValue < 0.0001) {
        return `0 ${displayedUnit}`;
    }
    const formatter = new Intl.NumberFormat(window.navigator.language, {
        maximumSignificantDigits: 4,
    });
    return `${formatter.format(retValue)} ${displayedUnit}`;
};

export const formatAreaNicely = (value: number, unit: MeasurementUnit) => {
    let displayedUnit = '';
    let retValue = value;
    if (unit.toLowerCase() === MeasurementUnit.METRIC) {
        displayedUnit = 'ha';
        retValue = sqmToHectares(value);
    } else if (unit.toLowerCase() === MeasurementUnit.IMPERIAL) {
        displayedUnit = 'ac';
        retValue = converter.convertSquareMetresToAcres(value);
    }
    if (retValue < 0.0001) {
        return `0 ${displayedUnit}`;
    }
    const formatter = new Intl.NumberFormat(window.navigator.language, {
        maximumSignificantDigits: 4,
    });
    return `${formatter.format(retValue)} ${displayedUnit}`;
};

export const formatArea = (sqm: number, unit: MeasurementUnit) => {
    let value: number;
    if (unit.toLowerCase() === MeasurementUnit.METRIC) {
        value = sqmToHectares(sqm);
    } else {
        value = converter.convertSquareMetresToAcres(sqm);
    }
    return roundToSf(value, 4);
};

export const areaAsSqm = (area: number, unit: MeasurementUnit) => {
    let value: number;
    if (unit.toLowerCase() === MeasurementUnit.METRIC) {
        value = hectaresToSqm(area);
    } else {
        value = converter.convertAcresToSquareMetres(area);
    }
    return value;
};

export const getItemsSizes = (items: GeoJsonItem[]): Sizes =>
    items.reduce(
        (sizes, item) => {
            const itemSizes = getSizesOfGeometry(item.uuid, item.geoJson);
            return {
                points: sizes.points + itemSizes.points,
                length: sizes.length + itemSizes.length,
                area: sizes.area + itemSizes.area,
            };
        },
        { points: 0, area: 0, length: 0 }
    );
