import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import TipBox from 'view/TipBox';

function CreateFarmTipBox() {
    const { t } = useTranslation();

    return (
        <TipBox>
            <p className="text-lg">
                <strong>
                    {t('create_farm_tip1', {
                        defaultValue:
                            'After you set up location you can upload your farm boundaries',
                    })}
                </strong>
            </p>
            <Markdown>
                {t('create_farm_tip2', {
                    defaultValue: 'Just go to **Settings > Connections**',
                })}
            </Markdown>
        </TipBox>
    );
}

export { CreateFarmTipBox };
