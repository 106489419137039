import type { ComponentProps, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/icon';
import icon from 'components/icons/icon';
import { LinkUpgradePlan } from 'components/link-upgrade-plan';
import { useBoolean } from 'hooks/useBoolean';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useInputUpgradeLimit } from 'hooks/useInputUpgradeLimit';
import { useTranslations } from 'hooks/useTranslations';
import { UnlockIcon } from 'icons/UnlockIcon';

import styles from './InputLimitPrompt.module.css';

function LinkUnlock() {
    const { t } = useTranslation();

    return (
        <LinkUpgradePlan className={styles.link}>
            <Icon>
                <UnlockIcon />
            </Icon>
            <span>{t('unlock_unlimited_inputs')}</span>
        </LinkUpgradePlan>
    );
}

function ButtonClose({ ...props }: ComponentProps<'button'>) {
    return (
        <button className={`${styles.buttonClose} bare-btn focus dismiss`} {...props}>
            {icon('close', 'dark-blue')}
        </button>
    );
}

function InputPromptWrapper({ children }: PropsWithChildren) {
    const { isInputLimitHit } = useInputUpgradeLimit();

    return (
        <div data-input-limit={isInputLimitHit} className={styles.wrapper}>
            {children}
        </div>
    );
}

const InputLimitPrompt = () => {
    const { t, plural } = useTranslations();
    const { value, setFalse } = useBoolean(true);
    const { currentUser } = useCurrentUser();
    const { isInputLimitHit, remainingCount } = useInputUpgradeLimit();

    const name = currentUser?.firstName;

    const warningMessageMain = !isInputLimitHit
        ? plural('warning_inputs_limit_main', {
              count: remainingCount,
              sprintf: [name, remainingCount],
          })
        : t('warning_inputs_limit_main_limit_hit', {
              sprintf: [name],
          });

    const warningMessageDescription = t('warning_inputs_limit_description');

    if (!value) {
        return null;
    }

    return (
        <InputPromptWrapper>
            <span className={styles.messageContainer}>
                <strong className={styles.heading}>{warningMessageMain}</strong>
                <span className={styles.description}>{warningMessageDescription}</span>
                <LinkUnlock />
            </span>
            {!isInputLimitHit && <ButtonClose onClick={setFalse} />}
        </InputPromptWrapper>
    );
};

export { InputLimitPrompt };
