import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Extent, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { extentFromGeo } from '@fieldmargin/webapp-geo';
import c from 'classnames';
import { highlightGeoFeatureId } from 'farm-editing/farm-editing-state';
import { bindActionCreators } from 'redux';
import ShapeBubbles from 'view/shapes/ShapeBubbles';

import ViewOnMap from './ViewOnMap';

interface ShapeInformationProps {
    // From parents
    geoFeatureCollection: GeoFeatureCollection;
    extent?: Extent | null;
    hideViewOnMap?: boolean;
    includePerimeter?: boolean;
    children?: any;
    includeEmptyMessage?: boolean;

    // From redux
    highlightGeoFeatureId: (geoFeatureid: string) => void;
}

const ShapeInformation = ({
    highlightGeoFeatureId,
    geoFeatureCollection,
    extent,
    hideViewOnMap = false,
    includePerimeter = false,
    children,
    includeEmptyMessage = true,
}: ShapeInformationProps) => {
    const { t } = useTranslation();
    const viewOnMapExtent = extent ? extent : extentFromGeo(geoFeatureCollection);
    const hasSingleFeature = geoFeatureCollection.features.size === 1;

    return (
        <div>
            <h4>{t('location')}</h4>
            <div
                className={c({
                    'flex items-center gap-3 mb-3': hasSingleFeature,
                })}
            >
                <ShapeBubbles
                    geoFeatureCollection={geoFeatureCollection}
                    onHoverGeoFeature={(id: string) => highlightGeoFeatureId(id)}
                    includePerimeter={includePerimeter}
                    includeEmptyMessage={includeEmptyMessage}
                />
                {!hideViewOnMap && viewOnMapExtent && (
                    <ViewOnMap
                        extent={viewOnMapExtent}
                        className={c({ 'my-3': !hasSingleFeature })}
                    />
                )}
            </div>
            {children}
        </div>
    );
};

export default connect(
    () => ({}),
    (dispatch) => bindActionCreators({ highlightGeoFeatureId }, dispatch)
)(ShapeInformation);
