interface PadlockIconProps {
    className?: string;
    fill?: string;
}

const PadlockIcon = ({ className, fill = '#4da4da' }: PadlockIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 56 60"
            className={className}
        >
            <g style={{ fill }}>
                <g>
                    <g>
                        <path
                            d="M41.8,23.5h-3.4v-7.9C38.3,10,33.7,5.5,28,5.5S17.7,10,17.7,15.6v7.9h-3.4c-3.2,0-5.7,2.6-5.7,5.7v19.5
						c0,3.2,2.6,5.7,5.7,5.7h27.6c3.2,0,5.7-2.6,5.7-5.7V29.2C47.5,26.1,44.9,23.5,41.8,23.5z M22.1,15.3c0-3.2,2.7-5.8,5.9-5.8
						s5.9,2.6,5.9,5.8v8.2H22.1V15.3L22.1,15.3z M43.4,48.6c0,1.1-0.9,1.9-1.9,1.9H14.4c-1.1,0-1.9-0.9-1.9-1.9V29.4
						c0-1.1,0.9-1.9,1.9-1.9h27.2c1.1,0,1.9,0.9,1.9,1.9C43.4,29.4,43.4,48.6,43.4,48.6z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            d="M28,32.2c-2.2,0-3.9,1.8-3.9,3.9c0,1.3,0.6,2.5,1.8,3.3v4.6c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1v-4.6
						c1.1-0.6,1.8-1.9,1.8-3.3C31.9,34,30.2,32.2,28,32.2z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default PadlockIcon;
