import { FeaturesController } from '@fieldmargin/webapp-ol-map';
import type Feature from 'ol/Feature';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

class InitialsController extends FeaturesController {
    constructor(zIndex: number) {
        super(zIndex, {});
    }

    protected styleFeature(feature: Feature) {
        let colour = '#bff3bf';
        if (feature.get('colour') !== '#fff') {
            colour = feature.get('colour');
        }
        const image = new Circle({
            radius: 17,
            fill: new Fill({ color: colour }),
            stroke: new Stroke({ color: 'white', width: 2 }),
        });
        return new Style({
            image: image,
            text: new Text({
                text: feature.get('label'),
                font: 'bold 14px sans-serif',
                fill: new Fill({
                    color: '#2c3e50',
                }),
            }),
            zIndex: feature.get('zIndex'),
        });
    }
}

export default InitialsController;
