import { useOperationFieldsSelector } from './useOperationFieldsSelector';

const useOperationTotalFieldsArea = () => {
    const { operationFields } = useOperationFieldsSelector();
    const totalFieldsArea = operationFields.reduce((total, opField) => total + opField.areaSqm, 0);

    return { totalFieldsArea };
};

export { useOperationTotalFieldsArea as useTotalFieldsArea };
