import { useToggle } from '@fieldmargin/webapp-state';
import { SidebarCard } from 'components/sidebar-card';
import type FullOperation from 'operations/FullOperation';
import type { OperationType } from 'operations/Operation';
import type Operation from 'operations/Operation';
import { getOperationTypeName } from 'operations/Operation';
import EditButton from 'sidebar/modules/common/EditButton';

import OperationActionMenu from './OperationActionMenu';
import OperationDetailsTitleAndTypeForm from './OperationDetailsTitleAndTypeForm';

import styles from './OperationDetailsTitleAndType.module.css';

interface OperationDetailsTitleAndTypeProps {
    fullOperation: FullOperation;
    onSave: (fullOperation: FullOperation) => Promise<void>;
}

const OperationDetailsTitleAndType = ({
    fullOperation,
    onSave,
}: OperationDetailsTitleAndTypeProps) => {
    const [editing, toggleEditing] = useToggle(false);

    const handleSave = (newName: string, newType: OperationType) =>
        onSave(
            fullOperation.updateIn(['summary'], (operation: Operation) =>
                operation.set('name', newName).set('type', newType)
            )
        );

    const operationTypeName = getOperationTypeName(fullOperation.summary.type);
    const typeIsName = operationTypeName.toLowerCase() === fullOperation.summary.name.toLowerCase();

    return !editing ? (
        <SidebarCard.Root>
            <SidebarCard.Header>
                <SidebarCard.Heading as="h2">{operationTypeName}</SidebarCard.Heading>
                {!typeIsName && <span>/ {fullOperation.summary.name}</span>}

                <EditButton className={styles.editButton} setEditing={toggleEditing} />
                <OperationActionMenu fullOperation={fullOperation} />
            </SidebarCard.Header>
        </SidebarCard.Root>
    ) : (
        <OperationDetailsTitleAndTypeForm
            fullOperation={fullOperation}
            onSave={handleSave}
            toggleEditing={toggleEditing}
        />
    );
};

export default OperationDetailsTitleAndType;
