import { createBrowserRouter, Navigate, type RouteObject, RouterProvider } from 'react-router-dom';
import { App, FieldmarginAuthenticatedContainer } from '@notes-web/app';
import { APP_ROUTES } from '@notes-web/types';
import AuthorizationCompletion from 'auth/AurthorizationCompletion';
import UserDeleteSuccess from 'auth/general/UserDeleteSuccess';
import FeatureFlagsPage from 'components/feature-flags/FeatureFlagsPage';

import { TrackPage } from '../components/TrackPage';

import { accountRoutes } from './acount.routes';
import { authRoutes } from './auth.routes';
import { createFarmRoutes } from './create-farm';
import { farmsRoutes } from './farm.routes';
import { fieldsRoutes } from './fields-redirect.routes';
import { flagsRoutes } from './flags.routes';
import { joinRoutes } from './join.routes';
import { mapRoutes } from './map.routes';
import { organisationsRoutes } from './organisations.routes';

const rootRoutes: RouteObject[] = [
    {
        path: APP_ROUTES.Root,
        element: <TrackPage />,
        children: [
            {
                path: APP_ROUTES.Root,
                element: <App />,
                children: [
                    {
                        path: 'auth/complete',
                        element: <AuthorizationCompletion />,
                    },
                    {
                        path: 'account/delete/success',
                        element: <UserDeleteSuccess />,
                    },
                    {
                        path: 'flags',
                        element: <FeatureFlagsPage />,
                    },
                    {
                        children: [
                            {
                                path: APP_ROUTES.Root,
                                element: <FieldmarginAuthenticatedContainer />,

                                children: [
                                    joinRoutes,
                                    accountRoutes,
                                    authRoutes,
                                    flagsRoutes,
                                    fieldsRoutes,
                                    mapRoutes,
                                    organisationsRoutes,
                                    createFarmRoutes,
                                    ...farmsRoutes,

                                    // Catch-all route to handle unmatched routes within AuthenticatedContainer
                                    {
                                        path: '*',
                                        element: <Navigate to={APP_ROUTES.Root} replace />,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

const router = createBrowserRouter(rootRoutes);

export function AppRouterFieldmargin() {
    return <RouterProvider router={router} />;
}
