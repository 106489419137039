import type { PropsWithChildren } from 'react';
import c from 'classnames';

const Notice = ({
    children,
    className,
    variant = 'info',
}: PropsWithChildren<{ className?: string; variant?: string }>) => {
    return (
        <div className={c('p-5 text-center text-fm-blue', `bg-${variant}`, className)}>
            {children}
        </div>
    );
};

export default Notice;
