import type { MouseEvent } from 'react';
import { useEffect, useRef } from 'react';
import c from 'classnames';
import startOfHour from 'date-fns/startOfHour';
import { useDateFormat } from 'hooks/useDateFormat';
import { List } from 'immutable';
import type { SingleParamVoidFunction } from 'system/types';

const times = List([
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
]);

interface TimePickerTimeListProps {
    onSelectTime: SingleParamVoidFunction<string>;
    openDirection: 'top' | 'bottom';
    value?: Date;
}

const TimePickerTimeList = ({ onSelectTime, openDirection, value }: TimePickerTimeListProps) => {
    const { format } = useDateFormat();
    const timeOptions = useRef<HTMLUListElement | null>(null);
    const currentTime = format(value || startOfHour(new Date()), 'HH:mm');

    const handleSelectTime = (time: string) => (e?: MouseEvent) => {
        e && e.preventDefault();
        onSelectTime(time);
    };

    useEffect(() => {
        // Scroll to the position of the given value, or the start of the current hour if there
        // is no value.
        if (timeOptions.current) {
            const formattedTime = format(value || startOfHour(new Date()), 'HH:mm');
            const index = times.findIndex((s) => s >= formattedTime);
            timeOptions.current.scrollTop = Math.max(0, index - 3) * 32; // 32 is the line height of the time option item
        }
    }, [value]);

    return (
        <ul
            className={c(
                'absolute z-20 left-0 w-24 h-52 overflow-y-scroll bg-white bordered list-none pl-0',
                openDirection === 'top' ? 'bottom-11' : 'top-11'
            )}
            ref={timeOptions}
        >
            {times.map((time, i) => (
                <li key={i} className="pl-0">
                    <button
                        className={c(
                            'bare-btn w-full text-left leading-8 px-4 hover:bg-yellow-50 focus ring-inset',
                            {
                                'bg-gray-300': currentTime === time,
                            }
                        )}
                        onClick={handleSelectTime(time)}
                    >
                        {time}
                    </button>
                </li>
            ))}
        </ul>
    );
};

export default TimePickerTimeList;
