import { MeasurementUnit } from 'lib/MeasurementUnit';
import { pluralise } from 'lib/util/text';
import { useAppSelector } from 'system/store';
import { selectUserLengthMeasurementUnit } from 'users/user-state';
import { converter } from 'utils/conversion';
import Num from 'view/Num';

interface FormattedLineProps {
    lengthMetres: number;
    withUnit?: boolean;
}

const FormattedLine = ({ lengthMetres, withUnit = false }: FormattedLineProps) => {
    const lengthUnit = useAppSelector(selectUserLengthMeasurementUnit);
    let displayedUnit = 'm';
    let length = lengthMetres;
    if (lengthUnit === MeasurementUnit.METRIC) {
        if (lengthMetres > 1000) {
            displayedUnit = 'km';
            length = converter.convertMetresToKilometres(lengthMetres);
        }
    } else {
        displayedUnit = 'yd';
        length = converter.convertMetresToYards(lengthMetres);
        // 1760 yards in a mile
        if (length > 1760) {
            length = converter.convertMetresToMiles(lengthMetres);
            displayedUnit = pluralise(length, 'mile', 'miles');
        }
    }

    if (length < 0.0001) {
        length = 0;
    }

    return (
        <>
            <Num value={length} sigFigs={4} />
            {withUnit && <> {displayedUnit}</>}
        </>
    );
};

export default FormattedLine;
