import Checkbox from '@fieldmargin/webapp-styling/components/checkbox';
import classNames from 'classnames';
import { Set } from 'immutable';
import { preventDefaultAnd } from 'lib/dom';
import { getUuid } from 'lib/fp-helpers';
import { getItemsSizes } from 'lib/geo/maths';
import type { GeoJsonItem, SingleParamVoidFunction } from 'system/types';
import type { BadgeItemWithIdLike } from 'view/molecules/badge-item/BadgeItem';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';
import SizeBubble from 'view/molecules/size-bubble/SizeBubble';
import FormattedGeoData from 'view/units/FormattedGeoData';

interface GroupSelectProps {
    // From parent
    group: BadgeItemWithIdLike;
    items: GeoJsonItem[];
    selectedItems: Set<string>;
    onToggleChecked: SingleParamVoidFunction<Set<string>>;
    onToggleOpened: VoidFunction;
    isOpen: boolean;
}

const GroupSelect = ({
    group,
    items,
    selectedItems,
    onToggleChecked,
    onToggleOpened,
    isOpen,
}: GroupSelectProps) => {
    const sizes = getItemsSizes(items);

    const itemUuids = Set(items.map(getUuid));
    const allItemsSelected = itemUuids.isSubset(selectedItems);

    const handleToggleOpen = preventDefaultAnd(onToggleOpened);

    const handleToggleChecked = () => {
        onToggleChecked(
            allItemsSelected ? selectedItems.subtract(itemUuids) : selectedItems.concat(itemUuids)
        );
    };

    return (
        <div className="GroupSelect flex items-center relative bordered-b px-5 py-4">
            <div className="flex-grow truncate mr-2">
                <Checkbox
                    id={group.id}
                    checked={allItemsSelected}
                    onChange={handleToggleChecked}
                    label={<BadgeItem item={group} className="font-bold" />}
                />
            </div>
            <SizeBubble>
                <strong>
                    <FormattedGeoData sizes={sizes} />
                </strong>
            </SizeBubble>
            <button className="bare-btn ml-2 focus" onClick={handleToggleOpen}>
                <span className="sr-only">Toggle showing items</span>
                <i
                    className={classNames(
                        'open-toggle',
                        isOpen ? 'ion-arrow-down-b' : 'ion-arrow-right-b'
                    )}
                />
            </button>
        </div>
    );
};

export default GroupSelect;
