interface NoteIconProps {
    className?: string;
    fill?: string;
}

const NoteIcon = ({ className, fill = '#4da4da' }: NoteIconProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <g id="Group_635" transform="translate(-318.6 -8)">
                <g id="note_fj" transform="translate(318.6 14.209) rotate(-15.009)">
                    <g id="Group_572">
                        <g id="Group_571" transform="translate(0 0)">
                            <path
                                id="Path_664"
                                style={{ fill }}
                                d="M33.6,14.9l-2.9,2.9l-7.3-7.3l2.9-2.9C27,7,28,7,28.7,7.6l5,5C34.2,13.2,34.2,14.2,33.6,14.9
					z M8.6,40.7l-7.4-7.4l19.1-19.3l7.4,7.4L8.6,40.7z M-1.5,38.1l4.4,4.6l-6.2,1.7L-1.5,38.1L-1.5,38.1z M37.8,9.7L32.2,4
					c-2.3-2.3-6-2.3-8.3,0L-5.2,32.8c-0.3,0.3-0.5,0.7-0.7,0.9l-4.3,15.7c-0.2,0.7,0,1.5,0.6,2.1c0.6,0.5,1.3,0.7,2.1,0.6l15.6-4.4
					c0.3,0,0.6-0.2,0.9-0.6l28.8-28.8C40.1,15.9,40.1,12.1,37.8,9.7z"
                            />
                        </g>
                    </g>
                </g>
                <g id="close" transform="translate(343.626 23) rotate(45)">
                    <path
                        id="Rounded_Rectangle_3_copy_31"
                        style={{ fill, stroke: fill, strokeWidth: 0.7 }}
                        d="M43.5,0.9l-7.3,7.4l7.5,7.5c0.8,0.8,0.8,2,0,2.8c-0.8,0.8-2,0.8-2.8,0
			L33.4,11L26,18.4c-0.7,0.8-1.9,0.8-2.7,0.1c0,0-0.1-0.1-0.1-0.1c-0.8-0.7-0.8-1.9-0.1-2.7c0,0,0.1-0.1,0.1-0.1l7.4-7.4L23.2,1
			c-0.8-0.7-0.8-1.9-0.1-2.7c0,0,0.1-0.1,0.1-0.1c0.7-0.8,1.9-0.8,2.7-0.1c0,0,0.1,0.1,0.1,0.1l0,0l7.4,7.4l7.4-7.4
			c0.7-0.8,1.9-0.8,2.7-0.1c0,0,0.1,0.1,0.1,0.1C44.2-1,44.2,0.2,43.5,0.9z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default NoteIcon;
