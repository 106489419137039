import type { HTMLProps } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';

import OptionalLabel from '../OptionalLabel';

interface SelectFormFieldProps extends HTMLProps<HTMLSelectElement> {
    field: string;
    labelClassName?: string;
    optional?: boolean;
    validate?: (value: any) => string | true | undefined;
}

const SelectFormField = ({
    field,
    label,
    labelClassName,
    className,
    optional = false,
    validate,
    onChange,
    children,
}: SelectFormFieldProps) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <div className={classNames('form-field', className)}>
            {label && (
                <label htmlFor={field} className={classNames(labelClassName)}>
                    {label}
                    {optional && (
                        <>
                            {' '}
                            <OptionalLabel />
                        </>
                    )}
                </label>
            )}
            <select {...register(field, { validate, onChange })} className="fancy-select focus">
                {children}
            </select>
            <ErrorMessage
                errors={errors}
                name={field}
                render={({ message }) => <div className="field-error">{message}</div>}
            />
        </div>
    );
};

export default SelectFormField;
