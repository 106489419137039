import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess, loadingFarm } from 'farms/farm-loading-state';
import { identity } from 'ramda';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import { selectUserId } from 'users/user-state';

import type { OrganisationInvite } from './organisation-api';

export interface Organisation {
    uuid: string;
    name: string;
}

export interface OrganisationUser {
    userId: number;
    role: string;
    active: boolean;
}

export interface OrganisationState {
    organisation?: Organisation;
    organisationUsers?: OrganisationUser[];
    invites?: OrganisationInvite[];
}

const initialState: OrganisationState = {};

const organisationSlice = createSlice({
    name: 'organisation',
    initialState,
    reducers: {
        setOrganisation: (state, { payload }: PayloadAction<Organisation>) => {
            state.organisation = payload;
        },
        setOrganisationInvites: (state, { payload }: PayloadAction<OrganisationInvite[]>) => {
            state.invites = payload;
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(loadingFarm.toString(), identity)
            .addCase(
                loadedFarmSuccess.toString(),
                (state, { payload }: PayloadAction<LoadedFarmSuccessPayload>) => {
                    state.organisation = payload.organisation;
                    state.organisationUsers = payload.organisationUsers;
                }
            ),
});

export const { setOrganisation, setOrganisationInvites } = organisationSlice.actions;
export const organisationReducer = organisationSlice.reducer;

export const selectUserOwnsOrganisation = createSelector(
    selectUserId,
    (state: AppState) => state.organisationState.organisationUsers,
    (userId, organisationUsers) =>
        organisationUsers?.find(
            (user) => user.userId === userId && user.role === 'OWNER' && user.active
        )
);
