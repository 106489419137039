import type { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clsx } from 'clsx';
import icon from 'components/icons/icon';
import type Field from 'fields/Field';
import { getFieldArea, isSubField } from 'fields/Field';
import { selectParentFields } from 'fields/fields-selectors';
import type { AppState } from 'system/store';
import NumberInputField from 'view/form/hook/NumberInputField';
import { required } from 'view/form/validations';
import FormattedArea from 'view/units/FormattedArea';

import type { BulkFieldEditFormValues } from './BulkFieldEdit';
import FieldEditDeleteCheckbox from './FieldEditDeleteCheckbox';

const validateRequired = required('Required');

interface FieldEditRowProps
    extends PropsWithChildren<{
        // From parent
        field: Field;
        hidden?: boolean;

        // From redux
        parentField?: Field;
    }> {}

const FieldEditRow = ({ field, hidden = false, parentField, children }: FieldEditRowProps) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors },
        watch,
    } = useFormContext<BulkFieldEditFormValues>();

    const archived = watch().fields[field.uuid]?.archived;

    return (
        <tr
            className={clsx('FormFields', {
                SubField: parentField !== undefined,
                hidden,
                archived,
            })}
        >
            <td>
                {children}
                <div className="NameInput">
                    {parentField !== undefined && <span>{parentField.name}</span>}
                    <input
                        type="text"
                        {...register(`fields.${field.uuid}.name`, {
                            validate: validateRequired,
                        })}
                        className={clsx('simple-text-input h-8 w-full', {
                            'has-error': errors.fields?.[field.uuid]?.name,
                        })}
                        autoComplete="off"
                    />
                    {!isSubField(field) && (
                        <input
                            type="text"
                            {...register(`fields.${field.uuid}.id`)}
                            placeholder={t('field_id')}
                            className="simple-text-input h-8 mt-2 w-full"
                            autoComplete="off"
                        />
                    )}
                </div>
            </td>
            <td>
                <FormattedArea areaSqm={getFieldArea(field)} withUnit />
            </td>
            <td>
                <NumberInputField
                    field={`fields.${field.uuid}.workArea`}
                    minValue={0}
                    inputClassName="h-8 w-20'"
                />
            </td>
            <td>
                <label
                    htmlFor={`fields.${field.uuid}.archived`}
                    className="cursor-pointer relative"
                >
                    {icon('archive', archived ? 'grey' : 'dark-blue')}
                    <input
                        type="checkbox"
                        id={`fields.${field.uuid}.archived`}
                        {...register(`fields.${field.uuid}.archived`)}
                        className="opacity-0 absolute"
                    />
                </label>
            </td>
            <td>
                <FieldEditDeleteCheckbox field={field} />
            </td>
            <td>&nbsp;</td>
        </tr>
    );
};

export default connect((state: AppState, { field }: Pick<FieldEditRowProps, 'field'>) => ({
    parentField: isSubField(field)
        ? selectParentFields(state).find((f) => f.uuid === field.parentUuid)
        : undefined,
}))(FieldEditRow);
