import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getDoc, setDoc } from 'firebase/firestore';
import type { AppState } from 'system/store';
import { selectUserId } from 'users/user-state';

import { getUserHintsRef } from './firebase-connection';

/**
 * Checks if a user has seen the given hint before.
 * Returns true if the user has seen the hint, false otherwise
 */
export const isUserHintSet = async (userId: number, hint: string) => {
    const hintsRef = getUserHintsRef(userId);

    const hintDoc = await getDoc(hintsRef);
    if (!hintDoc.exists) {
        return false;
    }
    const hintData = hintDoc.data();

    if (!hintData) {
        return false;
    }

    return hintData[hint] === true;
};

/**
 * Mark that a user has seen the given hint
 */
export const setUserHint = async (userId: number, hint: string) => {
    const hintsRef = getUserHintsRef(userId);
    // Merge to avoid overwriting other properties
    return await setDoc(hintsRef, { [hint]: true }, { merge: true });
};

/**
 * This hooks allows the component to show a hint on mount if the user has not seen it before.
 * If a delay argument is given the show function will not be called until after that amount of
 * time (ms).
 */
export const useShowHintOnMount = (hintName: string, show: VoidFunction, delay?: number) => {
    const userId = useSelector<AppState, number>(selectUserId);
    const timeout = useRef<any>();

    useEffect(() => {
        const handleShow = async () => {
            if (!(await isUserHintSet(userId, hintName))) {
                delay ? (timeout.current = setTimeout(show, delay)) : show();
            }
        };
        handleShow();

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, []);
};

/**
 * Mark that the user has seen the hint when the component mounts.
 */
export const useMarkHintSeenOnMount = (hintName: string) => {
    const userId = useSelector<AppState, number>(selectUserId);
    useEffect(() => {
        setUserHint(userId, hintName);
    }, []);
};
