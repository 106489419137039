import type { GeoFeature, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import MapLayerIndex from 'components/maps/model/MapLayerIndex';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import { AttachmentType } from 'farm-editing/attachments';
import {
    EditingType,
    selectEditingAttachmentsByType,
    selectIsSelectingFields,
} from 'farm-editing/farm-editing-state';
import type Field from 'fields/Field';
import { selectFilteredFieldsIncludingArchived } from 'fields/fields-selectors';
import { selectHerd } from 'herd/herd-selectors';
import { List, Set } from 'immutable';
import { logReselect } from 'lib/util/reselect-util';
import { selectNote } from 'notes/notes-selectors';
import { selectOperation } from 'operations/operations-selectors';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

const itemTypes = List.of('note', 'operation', 'herd');

type SelectedItemUuids = {
    selectedOperationUuid?: string;
    selectedNoteUuid?: string;
    selectedHerdUuid?: string;
};

const selectItemFieldUuids = createSelector(
    (state: AppState) => state.farmEditingState.editingType,
    (state: AppState) => selectIsSelectingFields(state),
    (state: AppState) => selectEditingAttachmentsByType(state, AttachmentType.FIELD),
    (state: AppState, { selectedNoteUuid }: SelectedItemUuids) =>
        selectedNoteUuid !== undefined ? selectNote(state, selectedNoteUuid) : undefined,
    (state: AppState, { selectedOperationUuid }: SelectedItemUuids) =>
        selectedOperationUuid !== undefined
            ? selectOperation(state, selectedOperationUuid)
            : undefined,
    (state: AppState, { selectedHerdUuid }: SelectedItemUuids) =>
        selectedHerdUuid !== undefined ? selectHerd(state, selectedHerdUuid) : undefined,
    (
        editingType,
        isSelectingFields,
        editingFieldUuids,
        selectedNote,
        selectedOperation,
        selectedHerd
    ) => {
        logReselect('selectItems');
        if ((itemTypes.contains(editingType || '') || isSelectingFields) && editingFieldUuids) {
            return editingFieldUuids;
        }
        if (selectedNote) {
            return Set(selectedNote.fieldUuids);
        }
        if (selectedOperation && selectedOperation.fields) {
            return selectedOperation.fields.map((opField) => opField.fieldUuid);
        }

        if (selectedHerd && selectedHerd.herdLocation) {
            return Set.of(selectedHerd.herdLocation.fieldUuid);
        }
        return Set();
    }
);

/**
 * - Item fields will only be shown when the user is viewing an item that has fields attached
 *   or the user is attaching fields to an item.
 * - Only fields that are attached to the item will be returned.
 */
export const selectItemFields = createSelector(
    selectFilteredFieldsIncludingArchived,
    selectItemFieldUuids,
    (state: AppState) => state.farmEditingState.editingType,
    (fields: List<Field>, activityFieldsUuids: Set<string>, editingType): List<GeoFeature> => {
        logReselect('selectItemFields');
        if (activityFieldsUuids.size === 0) {
            return List();
        }

        return fields
            .filter((field) => activityFieldsUuids.has(field.uuid) && field.geoJson !== null)
            .flatMap((field) => {
                return (field.geoJson as GeoFeatureCollection).features.map((geoFeature) => {
                    return geoFeature.set(
                        'properties',
                        OLFeatureProperties({
                            type: 'field',
                            passiveEditMode:
                                editingType === EditingType.NOTE ||
                                editingType === EditingType.OPERATION,
                            colour: '#ffffff',
                            pointScale: 6,
                            strokeWeight: 2,
                            strokeOpacity: 1,
                            fillOpacity: 0.4,
                            zIndex: MapLayerIndex.NOTE_FIELD_POLYGON,
                            label: field.name,
                        })
                    );
                });
            });
    }
);

/**
 * Returns the uuids of features that are attached to the selected item.
 * Currently only notes have features attached.
 */
const selectItemFeatureUuids = createSelector(
    (state: AppState, { selectedNoteUuid }: SelectedItemUuids) =>
        selectedNoteUuid !== undefined ? selectNote(state, selectedNoteUuid) : undefined,
    (state: AppState) => state.farmEditingState.editingType,
    (state: AppState) => selectEditingAttachmentsByType(state, AttachmentType.FEATURE),
    (note, editingType, editingFeatureUuids) =>
        editingType === EditingType.NOTE
            ? editingFeatureUuids
            : note !== undefined
              ? Set(note.featureUuids)
              : Set()
);

/**
 * - Item features will only be shown when the user is viewing an item that has features attached
 * - Only features that are attached to the item will be returned.
 */
export const selectItemFeatures = createSelector(
    (state: AppState) => state.farmEditingState.editingType,
    selectItemFeatureUuids,
    (state: AppState) => state.featuresState.features || List(),
    (editingType, featureUuids, features) =>
        features
            .filter((feature) => featureUuids.has(feature.uuid))
            .flatMap((feature) => {
                return (feature.geoJson as GeoFeatureCollection).features.map((geoFeature) => {
                    return geoFeature.set(
                        'properties',
                        OLFeatureProperties({
                            type: 'feature',
                            passiveEditMode: editingType === EditingType.NOTE,
                            colour: '#ffffff',
                            pointScale: 6,
                            strokeWeight: 2,
                            strokeOpacity: 1,
                            fillOpacity: 0.4,
                            zIndex: MapLayerIndex.NOTE_FIELD_POLYGON,
                            label: feature.name,
                        })
                    );
                });
            })
);
