import { useSelector } from 'react-redux';
import { usePageParams } from 'hooks/usePageParamas';
import type { AppState } from 'system/store';

import type MapGroup from './MapGroup';
import { selectMapGroup } from './maps-state';

const withPageMapGroup =
    <Props,>(Component: React.ComponentType<Props>) =>
    (props: Props) => {
        const { mapGroupUuid } = usePageParams();
        const mapGroup = useSelector<AppState, MapGroup | undefined>((state) =>
            selectMapGroup(state, mapGroupUuid ?? '')
        );

        return <Component {...props} mapGroup={mapGroup} />;
    };

export default withPageMapGroup;
