import { useContext } from 'react';

import { ComboboxContext } from './Combobox';

interface ComboboxItemProps {
    item: string;
    onSelect: (item: string) => void;
}

const ComboboxItem = ({ item, onSelect }: ComboboxItemProps) => {
    const { deactivate } = useContext(ComboboxContext);
    const handleClick = () => {
        onSelect(item);
        deactivate();
    };
    return (
        <li
            data-combobox
            className="p-3 bordered-b bg-white hover:bg-gray-100 cursor-pointer text-left"
            onClick={handleClick}
            role="option"
        >
            {item}
        </li>
    );
};

export default ComboboxItem;
