import { useTranslation } from 'react-i18next';

import SidebarModule from '../SidebarModule';

import DateAndTimeField from './DateAndTimeField';

interface NewDateAndTimeProps {
    field: string;
    label: string;
    required: boolean;
    openDirection?: 'top' | 'bottom';
}

const NewDateAndTime = ({ field, label, required, openDirection = 'top' }: NewDateAndTimeProps) => {
    const { t } = useTranslation();
    return (
        <SidebarModule className="NewDateAndTime" editing={true}>
            {!required && (
                <div className="float-right text-xs text-gray-450">{t('create_note_optional')}</div>
            )}
            <h4>{label}</h4>
            <DateAndTimeField field={field} openDirection={openDirection} allowDelete={true} />
        </SidebarModule>
    );
};

export default NewDateAndTime;
