import type { ReactNode } from 'react';
import {
    Checkbox as _Checkbox,
    CheckboxGroup,
    type CheckboxGroupProps,
    type CheckboxProps,
} from 'react-aria-components';

import styles from './checkbox.module.css';

interface GroupProps extends Omit<CheckboxGroupProps, 'children'> {
    children?: ReactNode;
}

function Group({ children, ...props }: GroupProps) {
    return <CheckboxGroup {...props}>{children}</CheckboxGroup>;
}

function Input({ children, ...props }: CheckboxProps) {
    return (
        <_Checkbox className={styles.checkboxContainer} {...props}>
            {({ isIndeterminate }) => (
                <>
                    <div className={styles.checkbox}>
                        <svg viewBox="0 0 18 18" aria-hidden="true">
                            {isIndeterminate ? (
                                <rect x={1} y={7.5} width={15} height={3} />
                            ) : (
                                <polyline points="1 9 7 14 15 4" />
                            )}
                        </svg>
                    </div>
                    {children}
                </>
            )}
        </_Checkbox>
    );
}

export const Checkbox = {
    Group,
    Input,
};
