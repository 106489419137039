import { connect } from 'react-redux';
import classNames from 'classnames';
import { useDateFormat } from 'hooks/useDateFormat';
import { defaultToZero } from 'lib/fp-helpers';
import { getDisplayName } from 'lib/util/names';
import type { FullOperationField } from 'operations/OperationField';
import type { AppState } from 'system/store';
import { selectDisplayableFarmUsers } from 'team/farm-users-state';
import SpeedUnit from 'view/units/SpeedUnit';
import SpeedValue from 'view/units/SpeedValue';
import Temperature from 'view/units/Temperature';
import TemperatureUnit from 'view/units/TemperatureUnit';
import WeatherNoteIcon from 'view/WeatherNoteIcon';

import './OperationFieldCompletedInfo.css';

interface OperationFieldCompletedInfoProps {
    operationField: FullOperationField;
    completedByName: string;
}

type ParentProps = Pick<OperationFieldCompletedInfoProps, 'operationField'>;

const OperationFieldCompletedInfo = ({
    operationField,
    completedByName,
}: OperationFieldCompletedInfoProps) => {
    const { format } = useDateFormat();
    if (
        operationField.completedDate === undefined ||
        operationField.completedByUserId === undefined
    ) {
        return undefined;
    }
    return (
        <div
            className={classNames('OperationFieldCompletedInfo', {
                completed: operationField.completedDate !== undefined,
            })}
        >
            {completedByName}
            {' | '}
            {format(operationField.completedDate, "dd/MM/yyyy '@' HH:mm")}
            {operationField.windDirection && ` | ${operationField.windDirection}`}
            {operationField.windDirection && operationField.windSpeed !== undefined && ' @'}
            {operationField.windSpeed !== undefined && (
                <>
                    {' '}
                    <SpeedValue value={operationField.windSpeed} /> <SpeedUnit />
                </>
            )}
            {operationField.windSpeed !== undefined &&
                operationField.temperature !== undefined &&
                ' &'}
            {operationField.temperature !== undefined && (
                <>
                    {' '}
                    <Temperature celsius={operationField.temperature} />
                    <TemperatureUnit />
                </>
            )}
            {operationField.weatherNote && (
                <>
                    {' '}
                    <WeatherNoteIcon note={operationField.weatherNote} />
                </>
            )}
        </div>
    );
};

export default connect((state: AppState, props: ParentProps) => ({
    ...props,
    completedByName: getDisplayName(
        selectDisplayableFarmUsers(state),
        defaultToZero(props.operationField.completedByUserId)
    ),
}))(OperationFieldCompletedInfo);
