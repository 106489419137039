import { useTranslation } from 'react-i18next';
import type { List } from 'immutable';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type { FullOperationField } from 'operations/OperationField';
import {
    calculateOperationTotalYieldRate,
    calculateOperationTotalYields,
} from 'operations/sidebar/details/yields/yield-utils';
import { useAppSelector } from 'system/store';
import Num from 'view/Num';
import FormattedArea from 'view/units/FormattedArea';
import ShortAreaUnit from 'view/units/ShortAreaUnit';
import ShortUnit from 'view/units/ShortUnit';

import OperationPrintSheetYieldRow from './OperationPrintSheetYieldRow';

interface OperationPrintSheetYieldTableProps {
    outputUuid: string;
    operationFields: List<FullOperationField>;
    measurementUnit: MeasurementUnit;
}

const OperationPrintSheetYieldTable = ({
    outputUuid,
    operationFields,
    measurementUnit,
}: OperationPrintSheetYieldTableProps) => {
    const { t } = useTranslation();
    const outputs = useAppSelector((state) => state.outputsState.outputs);
    const output = outputs.find((output) => output.uuid === outputUuid);

    if (!output) {
        return null;
    }

    const totalArea = operationFields.reduce((total, { areaSqm }) => total + areaSqm, 0);

    return (
        <table className="OperationPrintSheetTable">
            <thead>
                <tr>
                    <th>{t('field')}</th>
                    <th>{t('usage')}</th>
                    <th>{t('area')}</th>
                    <th>{t('output')}</th>
                    <th>
                        {t('operation_details_output_rate')}
                        <ShortAreaUnit />
                    </th>
                    <th>{t('total')}</th>
                </tr>
            </thead>
            <tbody>
                {operationFields.map((opField) => (
                    <OperationPrintSheetYieldRow
                        key={opField.field.uuid}
                        opField={opField}
                        output={output}
                    />
                ))}
                <tr>
                    <td colSpan={3}>
                        {t('field_work_area_total')}: <FormattedArea areaSqm={totalArea} withUnit />
                    </td>
                    <td colSpan={3}>
                        {t('label_total_outputs', { defaultValue: 'Total outputs' })}
                    </td>
                </tr>
                <tr>
                    <td colSpan={3} />
                    <td>{output.name}</td>
                    <td>
                        <Num
                            value={calculateOperationTotalYieldRate(
                                operationFields,
                                measurementUnit
                            )}
                            sigFigs={4}
                        />{' '}
                        <ShortUnit unit={output.unit} />
                    </td>
                    <td>
                        <Num value={calculateOperationTotalYields(operationFields)} sigFigs={4} />{' '}
                        <ShortUnit unit={output.unit} />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default OperationPrintSheetYieldTable;
