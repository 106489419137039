import { useOutletContext } from 'react-router-dom';
import type { List } from 'immutable';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type OutputReportSummary from 'outputs/OutputReportSummary';

export interface YeldReportingOuletSchema {
    reportData: List<OutputReportSummary>;
    areaMeasurementUnit: MeasurementUnit;
}

export function useYeldReportingContext() {
    return useOutletContext<YeldReportingOuletSchema>();
}
