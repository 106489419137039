import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { usePromise } from '@fieldmargin/webapp-state';

/**
 * This hook handles fetching and downloading a file from the server.
 * File downloads are handled this way because we need to send the bearer token when making
 * requests to the API.
 */

interface UseFileDownloadResult {
    pending: boolean;
    error: boolean;
    fetchFile: (url: string, filename: string) => void;
}

const useFileDownload = (): UseFileDownloadResult => {
    const { pending, error, setPromise } = usePromise<{ blob: Blob; filename: string }>(
        ({ blob, filename }) => {
            const file = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = file;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    );

    const fetchFile = (url: string, filename: string) => {
        setPromise(
            authorizedRequest<Blob>({
                url,
                responseType: 'blob',
            }).then((blob) => ({ blob, filename }))
        );
    };

    return { pending, error, fetchFile };
};

export { useFileDownload };
