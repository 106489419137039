import type { Extent, GeoFeature } from '@fieldmargin/webapp-geo';
import { concatExtents, extentFromGeo, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import Promise from 'bluebird';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import { createField } from 'fields/fields-api';
import { List } from 'immutable';

import type { RPAImportFormValues } from './RPAImportForm';

export const getFieldsExtent = (fields: List<GeoFeature>) =>
    concatExtents(
        fields.map((field) => extentFromGeo(field.geometry)).filter((e) => e) as List<Extent>
    ) as Extent;

export const styleRpaFeature = (geoFeature: GeoFeature, highlighted: boolean = false) =>
    geoFeature.set(
        'properties',
        OLFeatureProperties({
            type: 'field',
            colour: highlighted ? '#ffffff' : '#4da4da',
            strokeWeight: '2',
            strokeOpacity: '1',
            fillOpacity: '0.4',
            zIndex: '1',
            label: geoFeature.properties.get('label'),
        })
    );

export const styleIgnoredRpaFeature = (geoFeature: GeoFeature) =>
    geoFeature.set(
        'properties',
        OLFeatureProperties({
            type: 'field',
            strokeWeight: '2',
            strokeOpacity: '1',
            fillOpacity: '0',
            zIndex: '1',
            label: geoFeature.properties.get('label'),
            lineDash: [6, 12],
        })
    );

export const saveRpaFeatures = (
    farmUuid: string,
    features: List<GeoFeature>,
    values: RPAImportFormValues
) =>
    Promise.all(
        features.map((feature) => {
            // If the user has left the name field blank, use the id as the name instead.
            // The id is default set to the parcel and sheet id from the RPA import.
            const { name, id } = values.fields[feature.id];
            return Promise.resolve(
                createField(farmUuid, {
                    name: name === '' ? id : name,
                    fieldId: name === '' ? '' : id,
                    geoJson: GeoFeatureCollection({ features: List([feature]) }),
                })
            ).reflect();
        })
    ).then((results) =>
        results
            .filter((inspection) => inspection.isFulfilled())
            .map((inspection) => inspection.value())
    );
