import { useTranslation } from 'react-i18next';
import { useToggle } from '@fieldmargin/webapp-state';
import type Field from 'fields/Field';
import { Form } from 'informed';
import { useOutOfDatePromise } from 'lib/hooks';
import EditableActions from 'sidebar/modules/common/EditableActions';
import EditButton from 'sidebar/modules/common/EditButton';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { UnaryPromiseFunction } from 'system/types';
import TextareaFormField from 'view/form/TextareaFormField';

interface EditableFieldDescriptionProps {
    field: Field;
    onSave: UnaryPromiseFunction<string>;
}

const EditableFieldDescription = ({ field, onSave }: EditableFieldDescriptionProps) => {
    const { t } = useTranslation();
    const [editing, setEditing] = useToggle(false);
    return (
        <SidebarModule editing={editing} className={field.archived ? 'archived' : ''}>
            {!editing && <EditButton setEditing={setEditing} disabled={field.archived} />}
            <h4>{t('description')}</h4>
            {!editing && (
                <>
                    {field.description !== '' ? (
                        <span className="whitespace-pre-line">{field.description}</span>
                    ) : (
                        <em className="not-set">{t('field_description_hint')}</em>
                    )}
                </>
            )}

            {editing && (
                <EditableDescriptionForm
                    description={field.description}
                    onSave={onSave}
                    setEditing={setEditing}
                />
            )}
        </SidebarModule>
    );
};

export default EditableFieldDescription;

interface FormValues {
    description: string;
}
const EditableDescriptionForm = ({
    description,
    onSave,
    setEditing,
}: {
    description: string;
    onSave: (desc: string) => Promise<any>;
    setEditing: VoidFunction;
}) => {
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(setEditing);

    const handleSubmit = (values: FormValues) => {
        setPromise(onSave(values.description));
    };

    return (
        <Form<FormValues> onSubmit={handleSubmit} initialValues={{ description }}>
            <TextareaFormField field="description" />
            <EditableActions
                disabled={pending}
                outOfDate={outOfDate}
                error={error}
                setEditing={setEditing}
            />
        </Form>
    );
};
