import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import c from 'classnames';

const ItemTab = ({ children, ...props }: NavLinkProps) => {
    return (
        <li className="p-0 flex-grow">
            <NavLink
                {...props}
                className={({ isActive }) =>
                    c(
                        'no-underline block w-full h-full py-3 px-5 font-bold text-sm focus ring-inset',
                        isActive ? 'bg-white text-fm-sky' : 'bg-gray-100'
                    )
                }
                end
            >
                {children}
            </NavLink>
        </li>
    );
};

export default ItemTab;
