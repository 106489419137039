import type { Activity } from 'activity/activity-helper';
import { getDueDate, isTodo } from 'activity/activity-helper';

import DueDateWords from '../DueDateWords';

interface NotePreviewDueDateProps {
    activity: Activity;
}

const NotePreviewDueDate = ({ activity }: NotePreviewDueDateProps) => {
    const dueDate = getDueDate(activity);
    return isTodo(activity) && dueDate !== null ? <DueDateWords date={dueDate} /> : null;
};

export default NotePreviewDueDate;
