import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

interface OperationRecordingsTableProps {
    children: ReactNode;
    editing?: boolean;
    className?: string;
}

const OperationRecordingsTable = ({
    children,
    editing,
    className,
}: OperationRecordingsTableProps) => {
    const { t } = useTranslation();
    return (
        <table className={c('OperationTable', { editing }, className)}>
            <thead>
                <tr className="spaced">
                    <th className="input-name">{t('name')}</th>
                    <th className="reg-number" />
                    <th className="input-type" />
                    <th className="recording-rate">
                        {t('operation_details_input_rate_empty')}
                        <ShortAreaUnit />
                    </th>
                    <th className="recording-total">{t('total')}</th>
                    {editing && <th className="remove" />}
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
};

export default OperationRecordingsTable;
