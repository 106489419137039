import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import { getFieldArea } from 'fields/Field';
import type { List } from 'immutable';
import { formatArea } from 'lib/geo/maths';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type { FullOperationField } from 'operations/OperationField';
import FormattedArea from 'view/units/FormattedArea';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import WorkAreaField from '../edit-fields/WorkAreaField';
import OperationFieldsTable from '../OperationFieldsTable';
import OperationFieldUsageMarker from '../OperationFieldUsageMarker';

interface NewOperationFieldsTable {
    operationFields: List<FullOperationField>;
    totalArea: number;
    areaMeasurementUnit: MeasurementUnit;
    onRemoveField: (fieldUuid: string) => void;
}

const NewOperationFieldsTable = ({
    operationFields,
    totalArea,
    areaMeasurementUnit,
    onRemoveField,
}: NewOperationFieldsTable) => {
    const { t } = useTranslation();

    let fieldRows: ReactNode = (
        <tr className="spaced">
            <td>&mdash;</td>
            <td className="text-center">&mdash;</td>
            <td className="text-center">&mdash;</td>
            <td />
        </tr>
    );

    if (operationFields.size > 0) {
        fieldRows = operationFields.map((operationField) => {
            return (
                <tr key={operationField.field.uuid}>
                    <td className="field-name">
                        <div className="flex items-center h-12">
                            <OperationFieldUsageMarker fieldUsage={operationField.fieldUsage} />
                            <div className="w-3/4 py-2 flex flex-wrap">
                                <div className="w-5/6">
                                    <strong className="block truncate">
                                        {operationField.field.name}
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="field-area">
                        <FormattedArea areaSqm={getFieldArea(operationField.field)} />
                    </td>
                    <td className="work-area">
                        <WorkAreaField
                            field={`fields.${operationField.field.uuid}.workArea`}
                            initialArea={formatArea(operationField.areaSqm, areaMeasurementUnit)}
                            className="w-full"
                        />
                    </td>
                    <td className="remove">
                        <button
                            className="bare-btn bg-white w-4"
                            type="button"
                            onClick={() => onRemoveField(operationField.field.uuid)}
                        >
                            {icon('edit-remove', 'red')}
                        </button>
                    </td>
                </tr>
            );
        });
    }

    return (
        <OperationFieldsTable editing>
            {fieldRows}
            <tr className="totals spaced">
                <td>
                    <strong>
                        {t('total')} (<ShortAreaUnit />)
                    </strong>
                </td>
                <td className="text-center">
                    <strong>
                        <FormattedArea
                            areaSqm={operationFields.reduce(
                                (total, opField) => total + getFieldArea(opField.field),
                                0
                            )}
                        />
                    </strong>
                </td>
                <td className="text-center">
                    <strong>
                        <FormattedArea areaSqm={totalArea} />
                    </strong>
                </td>
                <td />
            </tr>
        </OperationFieldsTable>
    );
};

export default NewOperationFieldsTable;
