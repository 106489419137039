import { createLogger } from '@fieldmargin/webapp-reporting';
import type { updateLastRead } from 'chat/chat-state';
import { FARM_CHAT_PAGE_SIZE } from 'chat/chat-state';
import type ChatMessage from 'chat/ChatMessage';
import type { DocumentChangeType, DocumentSnapshot } from 'firebase/firestore';
import { limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import type { List } from 'immutable';
import { Map } from 'immutable';
import { flow } from 'lodash';
import { pipe } from 'ramda';

import {
    getFarmUserRef,
    getMessagesRef,
    getUserLocationsRef,
    getUserOnboardingRef,
} from './firebase-connection';
import type { TeamTrackingChanges } from './snapshot-parsers';
import { parseFarmChatSnapshot, parseTeamTrackingSnapshot } from './snapshot-parsers';

const logger = createLogger('lib.firebase.FirebaseEventHandler');

class FirebaseEventHandler {
    private unsubscribeUserFn: VoidFunction;
    private unsubscribeMessagesFn: VoidFunction;
    private unsubscribeTeamTrackingFn: VoidFunction;
    private unsubscribeOnboardingFn: VoidFunction;

    constructor() {
        this.unsubscribeUserFn = () => {};
        this.unsubscribeMessagesFn = () => {};
        this.unsubscribeTeamTrackingFn = () => {};
        this.unsubscribeOnboardingFn = () => {};
    }

    unsubscribeAll() {
        this.unsubscribeFarmChat();
        this.unsubscribeTeamTracking();
        this.unsubscribeOnboarding();
    }

    unsubscribeFarmChat() {
        this.unsubscribeUserFn();
        this.unsubscribeMessagesFn();
    }

    subscribeToFarmChat(
        farmUuid: string,
        userId: number,
        handleLastRead: typeof updateLastRead,
        handleMessages: (
            changes: List<{
                type: DocumentChangeType;
                message: ChatMessage | undefined;
            }>
        ) => void
    ) {
        this.unsubscribeFarmChat();

        this.unsubscribeUserFn = onSnapshot(
            getFarmUserRef(farmUuid, userId),
            (doc) => {
                const data = doc.data();
                if (data?.lastReadUUID) {
                    handleLastRead(data.lastReadUUID);
                }
            },
            (error) => {
                logger.error('Error querying firebase for last read uuid', error);
            }
        );

        const latestQuery = query(
            getMessagesRef(farmUuid),
            orderBy('createdDate', 'desc'),
            limit(FARM_CHAT_PAGE_SIZE)
        );

        this.unsubscribeMessagesFn = onSnapshot(
            latestQuery,
            flow(parseFarmChatSnapshot, handleMessages),
            (error) => {
                logger.error('Error querying Firebase for messages', error);
            }
        );
    }

    unsubscribeTeamTracking() {
        this.unsubscribeTeamTrackingFn();
    }

    subscribeTeamTracking(
        farmUuid: string,
        handleLocationChanges: (changes: TeamTrackingChanges) => void
    ) {
        this.unsubscribeTeamTracking();

        this.unsubscribeTeamTrackingFn = onSnapshot(
            getUserLocationsRef(farmUuid),
            pipe(parseTeamTrackingSnapshot, handleLocationChanges),
            (error) => logger.error('Error querying firebase for user locations', error)
        );
    }

    unsubscribeOnboarding() {
        this.unsubscribeOnboardingFn();
    }

    subscribeOnboarding(
        userId: number,
        updateTutorials: (tutorials: Map<string, boolean>) => void
    ) {
        this.unsubscribeOnboarding();

        this.unsubscribeOnboardingFn = onSnapshot(
            getUserOnboardingRef(userId),
            (snapshot: DocumentSnapshot<{ [k: string]: boolean }>) => {
                const data = snapshot.data();
                if (data) {
                    updateTutorials(Map(data));
                }
            },
            (error) => logger.error('Error querying firebase for onboarding', error)
        );
    }
}

export default new FirebaseEventHandler();
