import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useConnectJDOrgMutation, useJdOrganizationsQuery } from '@notes-web/api';
import { ButtonBase } from 'components/button-base';
import { usePageParams } from 'hooks/usePageParamas';

import { VITE_APP_NAME } from '../../../../../env';
import { Sidebar } from '../Sidebar';

import { JohnDeereSelect } from './JohnDeereSelect';

function JohnDeereConnectOrganisation() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { farmUuid } = usePageParams();
    const { data: jdOrganisations, isLoading: isLoadingOrganizations } = useJdOrganizationsQuery();

    const [triggerConnect] = useConnectJDOrgMutation();
    const [selectedOrgIndex, setSelectedOrgIndex] = useState<null | number>(null);
    const selectedOrg =
        selectedOrgIndex !== null && jdOrganisations ? jdOrganisations[selectedOrgIndex] : null;

    const selectLabel = isLoadingOrganizations ? t('loading') : (selectedOrg?.name ?? t('select'));

    const onSelectedOrg = (index: number) => {
        setSelectedOrgIndex(index);
    };

    const onConnectOrganisation = () => {
        if (!selectedOrg || !farmUuid) return;

        triggerConnect({
            farmUuid,
            jdOrganizationId: selectedOrg.id,
        }).then((data) => {
            if (data) {
                navigate('../overview');
            }
        });
    };

    return (
        <Fragment>
            <Sidebar.Card>
                <Sidebar.Heading>{t('jd_connect_org_heading')}</Sidebar.Heading>
                <Sidebar.Description>
                    {t('jd_connect_org_description', { sprintf: [VITE_APP_NAME] })}
                </Sidebar.Description>
            </Sidebar.Card>

            <Sidebar.Card>
                <JohnDeereSelect
                    key={selectLabel}
                    label={selectLabel}
                    data={jdOrganisations}
                    onSelect={({ index }) => onSelectedOrg(index)}
                />
            </Sidebar.Card>

            <Sidebar.Card>
                <ButtonBase.Root
                    variant="primary"
                    isDisabled={selectedOrgIndex === null}
                    onPress={onConnectOrganisation}
                >
                    {t('connect')}
                </ButtonBase.Root>
            </Sidebar.Card>
        </Fragment>
    );
}

export { JohnDeereConnectOrganisation };
