import type { ComponentProps } from 'react';

function FilterIcon({ fill = 'currentColor', ...props }: ComponentProps<'svg'>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" fill="none" {...props}>
            <path
                fill={fill}
                d="M7.625 16.375v-5.25h1.75v1.75h7v1.75h-7v1.75h-1.75Zm-7-1.75v-1.75h5.25v1.75H.625Zm3.5-3.5v-1.75h-3.5v-1.75h3.5v-1.75h1.75v5.25h-1.75Zm3.5-1.75v-1.75h8.75v1.75h-8.75Zm3.5-3.5V.625h1.75v1.75h3.5v1.75h-3.5v1.75h-1.75Zm-10.5-1.75v-1.75h8.75v1.75H.625Z"
            />
        </svg>
    );
}

export { FilterIcon };
