import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { useOutOfDatePromise } from 'lib/hooks';
import { pipe } from 'ramda';
import { useAppSelector } from 'system/store';
import {
    selectUserId,
    selectUserLengthMeasurementUnit,
    selectUserTemperatureUnit,
} from 'users/user-state';
import ErrorMessage from 'view/ErrorMessage';
import FarmUserFormField from 'view/form/FarmUserFormField';
import DateAndTimeField from 'view/form/hook/DateAndTimeField';
import NumberInputField from 'view/form/hook/NumberInputField';
import OptionalLabel from 'view/form/OptionalLabel';
import { required } from 'view/form/validations';
import WeatherNoteFormField from 'view/form/WeatherNoteFormField';
import WindDirectionFormField from 'view/form/WindDirectionFormField';
import OutOfDateErrorMessage from 'view/OutOfDateErrorMessage';
import SpeedUnit from 'view/units/SpeedUnit';
import TemperatureUnit from 'view/units/TemperatureUnit';

import type { MarkCompleteData } from './mark-complete-utils';
import { sanitiseMarkCompleteFormData } from './mark-complete-utils';

interface MarkCompleteFormProps {
    onClose: VoidFunction;
    onSubmit: (values: MarkCompleteData) => Promise<any>;
    initialValues?: MarkCompleteFormFields;
}

export interface MarkCompleteFormFields {
    startDate?: Date;
    completedDate: Date;
    completedByUserId: string;
    windSpeed?: number;
    windDirection?: string;
    temperature?: number;
    weatherNote?: string;
}

const MarkCompleteForm = ({ onClose, onSubmit, initialValues }: MarkCompleteFormProps) => {
    const { t } = useTranslation();
    const userId = useAppSelector(selectUserId);

    const lengthMeasurementUnit = useAppSelector(selectUserLengthMeasurementUnit);
    const temperatureUnit = useAppSelector(selectUserTemperatureUnit);
    const [pending, outOfDate, error, setPromise] = useOutOfDatePromise(onClose);

    const methods = useForm<MarkCompleteFormFields>({
        mode: 'onChange',
        defaultValues: initialValues ?? {
            completedDate: new Date(),
            completedByUserId: userId.toString(),
        },
    });

    const sanitiseFormValues = sanitiseMarkCompleteFormData.bind(null, {
        length: lengthMeasurementUnit,
        temperature: temperatureUnit,
    });

    const handleSubmit = pipe<[MarkCompleteFormFields], MarkCompleteData, Promise<void>, void>(
        sanitiseFormValues,
        onSubmit,
        setPromise
    );

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)} className="text-left">
                <div className="flex items-center">
                    <div className="h-24 w-1/2">
                        <label>
                            <strong>{t('start_date')}</strong> <OptionalLabel />
                        </label>
                        <DateAndTimeField
                            field="startDate"
                            openDirection="bottom"
                            className="absolute"
                        />
                    </div>
                    <div className="h-24 w-1/2">
                        <label>
                            <strong>{t('activity_log_completed_date_title')}</strong>
                        </label>
                        <DateAndTimeField
                            field="completedDate"
                            openDirection="bottom"
                            className="absolute"
                            classWithDate="bg-white"
                        />
                    </div>
                </div>
                <div className="flex items-center flex-wrap mb-6">
                    <h3 className="w-full">
                        {t('weather')} <OptionalLabel />
                    </h3>
                    <div className="mr-6">
                        <label htmlFor="windSpeed">{t('wind_speed')}</label>
                        <div className="flex items-center">
                            <NumberInputField
                                field="windSpeed"
                                className="mr-2"
                                inputClassName="w-16"
                            />
                            <span>
                                <SpeedUnit />
                            </span>
                        </div>
                    </div>
                    <div className="mr-6">
                        <label htmlFor="windDirection">{t('direction')}</label>
                        <WindDirectionFormField field="windDirection" />
                    </div>
                    <div className="mr-6">
                        <label htmlFor="temperature">{t('temperature')}</label>
                        <div className="flex items-center">
                            <NumberInputField
                                field="temperature"
                                className="mr-2"
                                inputClassName="w-16"
                            />
                            <span>
                                <TemperatureUnit />
                            </span>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="weatherNote">{t('weather')}</label>
                        <WeatherNoteFormField field="weatherNote" />
                    </div>
                </div>
                <div className="h-24">
                    <FarmUserFormField
                        field="completedByUserId"
                        label={<strong>{t('who_completed_the_field_job')}</strong>}
                        validate={required(t('register_required'))}
                        className="absolute w-3/4"
                        inputClassName="w-full"
                    />
                </div>

                <div className="flex justify-center">
                    <Button
                        variant="danger-outline"
                        className="mr-4"
                        onClick={onClose}
                        disabled={pending}
                    >
                        {t('cancel')}
                    </Button>
                    <SubmitButton disabled={pending}>{t('save')}</SubmitButton>
                </div>
                {outOfDate && <OutOfDateErrorMessage className="mt-6" />}
                {error && <ErrorMessage className="mt-6">{t('something_went_wrong')}</ErrorMessage>}
            </form>
        </FormProvider>
    );
};

export default MarkCompleteForm;
