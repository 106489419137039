import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { farmHasNoSub } from '@fieldmargin/webapp-farms';
import c from 'classnames';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type { AppState } from 'system/store';
import { selectDisplayableFarmUsers } from 'team/farm-users-state';
import UpgradeLink from 'upgrade/UpgradeLink';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';

interface TeamUserCountMessageProps {
    farm: Farm;
    userCount: number;
}

const TeamUserCountMessage = ({ farm, userCount }: TeamUserCountMessageProps) => {
    const { t } = useTranslation();
    const isWarning = farm.plan.userLimit > userCount;
    return (
        <div className={c('p-4 text-sm relative', isWarning ? 'bg-warn' : 'bg-error-bg-pale')}>
            {isWarning
                ? icon('error-alert', 'orange', 'absolute w-10 h-10', { top: '33%' })
                : icon('padlock', 'red', 'absolute w-10 h-10', { top: '33%' })}
            <UpgradeLink className="block focus">
                <span className="block text-center mx-auto max-w-3/4">
                    {farmHasNoSub(farm) ? (
                        <>
                            <MarkdownNoPara>
                                {t('free_plan_limit_warning_first_line', {
                                    sprintf: [userCount, farm.plan.userLimit],
                                })}
                            </MarkdownNoPara>{' '}
                            {t('upgrade_for_more_members')}
                        </>
                    ) : (
                        <>
                            <MarkdownNoPara>
                                {t('paid_plan_limit_warning_first_line', {
                                    sprintf: [userCount, farm.plan.userLimit],
                                })}
                            </MarkdownNoPara>{' '}
                            {t('contact_support_larger_teams')}
                        </>
                    )}
                </span>
            </UpgradeLink>
        </div>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    userCount: selectDisplayableFarmUsers(state).length,
}))(TeamUserCountMessage);
