import { useState } from 'react';
import { useSelector } from 'react-redux';
import c from 'classnames';
import { selectDisplayableFieldUsages } from 'fields/field-usage-selectors';
import type FieldUsage from 'fields/FieldUsage';
import { getUsageName } from 'fields/FieldUsage';
import type { FieldProps } from 'informed';
import { useFormApi } from 'informed';
import Combobox from 'view/combobox/Combobox';
import ComboboxItem from 'view/combobox/ComboboxItem';
import HiddenFormField from 'view/form/HiddenFormField';

interface OutputNameFieldProps extends FieldProps<string, any> {
    label: string;
    labelClassName?: string;
    initialSearch?: string;
}

const OutputNameField = ({
    field,
    label,
    initialSearch = '',
    className,
    labelClassName,
    validate,
}: OutputNameFieldProps) => {
    const fieldUsages = useSelector(selectDisplayableFieldUsages);
    const formApi = useFormApi();
    const [search, setSearch] = useState(initialSearch);

    const handleSearch = (value: string) => {
        setSearch(value);
        formApi.setValue(field, value);
        formApi.validate();
    };

    const handleItemSelect = (fieldUsage: FieldUsage) => () => {
        handleSearch(getUsageName(fieldUsage));
    };

    const fieldError = formApi.getError(field);

    const filteredUsages = fieldUsages.filter(
        (fieldUsage) => getUsageName(fieldUsage).toLowerCase().indexOf(search.toLowerCase()) >= 0
    );

    return (
        <div className={c('form-field', className)}>
            <HiddenFormField field={field} validate={validate} />
            <label htmlFor="output-name" className={labelClassName}>
                {label}
            </label>
            <Combobox
                id="output-name"
                search={search}
                onSearch={handleSearch}
                error={fieldError}
                className="w-full"
            >
                {search !== '' && filteredUsages.size > 0
                    ? filteredUsages.map((fieldUsage) => (
                          <ComboboxItem
                              key={fieldUsage.uuid}
                              item={getUsageName(fieldUsage)}
                              onSelect={handleItemSelect(fieldUsage)}
                          />
                      ))
                    : undefined}
            </Combobox>
            {fieldError && <div className="field-error">{fieldError}</div>}
        </div>
    );
};

export default OutputNameField;
