import { selectFieldTooltipProps } from 'farm-map/selectors/selectFieldTooltipProps';
import type { FarmMapFeature } from 'farm-map/types';
import { useActiveSection } from 'hooks/useActiveSection';
import { useAppSelector } from 'system/store';

export const useFarmMapFieldTooltips = (features: FarmMapFeature[]) => {
    const activeSection = useActiveSection();
    return useAppSelector((state) =>
        selectFieldTooltipProps(state, {
            isSettingBulkUsage: activeSection.sub === 'usage',
            features,
        })
    );
};
