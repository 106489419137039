import { FMUnitConverter } from '@fieldmargin/shared';

const converter = new FMUnitConverter();

const hectaresToSqm = (hectares: number) => toFixed(hectares * 10000, 6);

const sqmToHectares = (sqm: number) => toFixed(sqm / 10000, 6);

const acresRateToHectaresRate = (acresRate: number) => toFixed(acresRate * 2.47105381, 6);
const hectaresRateToAcresRate = (hectaresRate: number) => toFixed(hectaresRate / 2.47105381, 6);

const toFixed = (value: number, precision: number) => {
    const power = Math.pow(10, precision);
    return Math.round(value * power) / power;
};

export {
    converter,
    hectaresToSqm,
    sqmToHectares,
    acresRateToHectaresRate,
    hectaresRateToAcresRate,
    toFixed,
};
