import { GeoFeature } from '@fieldmargin/webapp-geo';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import { logReselect } from 'lib/util/reselect-util';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import type { ActiveSection } from 'system/url-util';

/**
 * - Vehicles will only be shown when the active section is 'vehicles'
 * - Active and inactive vehicles are split and dispayed in different colours
 */
export const selectVehicles = createSelector(
    (_state: AppState, activeSection: ActiveSection) => activeSection.main === 'vehicles',
    (state: AppState) => state.vehicleTrackingState.activeVehicles,
    (state: AppState) => state.vehicleTrackingState.inactiveVehicles,
    (isActiveSection, activeVehicles, inactiveVehicles): GeoFeature[] => {
        logReselect('selectVehicles');
        if (!isActiveSection || !activeVehicles || !inactiveVehicles) {
            return [];
        }

        let zIndexCount = activeVehicles.size + inactiveVehicles.size;

        const activeVehicleFeatures = activeVehicles.map((vehicle) => {
            return GeoFeature({
                id: `vehicle-${vehicle.displayName}`,
                geometry: vehicle.location,
                properties: OLFeatureProperties({
                    type: 'vehicle',
                    label: vehicle.displayName,
                    updateGeometry: true,
                    zIndex: zIndexCount--,
                }),
            });
        });
        const inactiveVehicleFeatures = inactiveVehicles.map((vehicle) => {
            return GeoFeature({
                id: `vehicle-${vehicle.displayName}`,
                geometry: vehicle.location,
                properties: OLFeatureProperties({
                    type: 'vehicle',
                    label: vehicle.displayName,
                    updateGeometry: true,
                    colour: '#ededed',
                    zIndex: zIndexCount--,
                }),
            });
        });

        return activeVehicleFeatures.concat(inactiveVehicleFeatures).toArray();
    }
);
