import type { ReactNode } from 'react';
import { useWatch } from 'react-hook-form';
import { colourList } from 'lib/colours';

import type { ColourPickerControllerProps } from './ColourPickerController';
import ColourPickerController from './ColourPickerController';

import './CollapsibleColourPicker.css';

interface CollapsibleColourPickerFieldProps extends ColourPickerControllerProps {
    label: ReactNode;
}

const CollapsibleColourPickerField = ({
    label,
    field,
    validate,
    colours,
}: CollapsibleColourPickerFieldProps) => {
    const value = useWatch({ name: field });
    const colour = colourList.find((colour) => colour.colour === value)?.name;
    return (
        <details className="CollapsibleColourPicker">
            <summary className="flex items-center gap-2 focus">
                {label}
                <span className="w-6 h-6 inline-block rounded" style={{ backgroundColor: value }} />
                {colour}
            </summary>
            <ColourPickerController field={field} validate={validate} colours={colours} />
        </details>
    );
};

export default CollapsibleColourPickerField;
