import type { Farm, FarmUser } from '@fieldmargin/webapp-farms';
import { findFarmUsers } from '@fieldmargin/webapp-farms';
import type { PendingFarmInvite } from '@fieldmargin/webapp-farms/dist/PendingFarmInvite';
import BluebirdPromise from 'bluebird';
import type Feature from 'features/Feature';
import { getFeatures, getFeatureTypes } from 'features/features-api';
import type FeatureType from 'features/FeatureType';
import type Field from 'fields/Field';
import { getFieldUsages } from 'fields/field-usages-api';
import { getFields } from 'fields/fields-api';
import type FieldUsage from 'fields/FieldUsage';
import type Herd from 'herd/Herd';
import { fetchHerds } from 'herd/herd-api';
import type { Map } from 'immutable';
import type { List } from 'immutable';
import type Input from 'inputs/Input';
import { getInputsApi } from 'inputs/inputs-api';
import type { FarmIntegration } from 'integrations/FarmIntegration';
import { getFarmIntegrationsApi } from 'integrations/integrations-api';
import { fetchOr } from 'lib/fp-helpers';
import type MapGroup from 'maps/farm/MapGroup';
import { getFieldMapsApi } from 'maps/farm/maps-api';
import { getFarmMembershipFinishDate, trialAvailable } from 'membership/payment-api';
import type FullOperation from 'operations/FullOperation';
import { getFullOperationsApi } from 'operations/operations-api';
import { fetchOrganisation, fetchOrganisationUsers } from 'organisation/organisation-api';
import type { Organisation, OrganisationUser } from 'organisation/organisation-state';
import type Output from 'outputs/Output';
import { getOutputsApi } from 'outputs/outputs-api';
import { getPendingFarmInvitesApi } from 'users/users-api';
import { fetchFarmYears } from 'years/years-api';
import { getDefaultYear } from 'years/years-utils';

export const loadingFarm = (payload: Farm) => ({ type: 'Farm loading', payload });
loadingFarm.toString = () => 'Farm loading';
export const loadedFarmSuccess = (payload: LoadedFarmSuccessPayload) => ({
    type: 'Farm loaded success',
    payload,
});
loadedFarmSuccess.toString = () => 'Farm loaded success';

export interface LoadedFarmSuccessPayload {
    farm: Farm;
    farmUsers: FarmUser[];
    pendingFarmUserInvites: PendingFarmInvite[];
    years: List<number>;
    operations: Map<string, FullOperation>;
    inputs: List<Input>;
    outputs: List<Output>;
    fields: List<Field>;
    fieldUsages: List<FieldUsage>;
    features: List<Feature>;
    featureTypes: List<FeatureType>;
    mapGroups: MapGroup[];
    herds: Herd[];
    farmIntegrations: FarmIntegration[];
    membershipFinishAt: Date | null;
    trialAvailable: boolean;
    organisation?: Organisation;
    organisationUsers?: OrganisationUser[];
}

export const loadFarm = async (farm: Farm): Promise<LoadedFarmSuccessPayload> => {
    const props = await BluebirdPromise.props<Omit<LoadedFarmSuccessPayload, 'herds'>>({
        farm,
        farmUsers: findFarmUsers(farm.uuid),
        years: fetchFarmYears(farm.uuid),
        operations: getFullOperationsApi(farm.uuid),
        inputs: getInputsApi(farm.uuid),
        outputs: getOutputsApi(farm.uuid),
        features: getFeatures(farm.uuid),
        featureTypes: getFeatureTypes(farm.uuid),
        fields: getFields(farm.uuid),
        fieldUsages: getFieldUsages(farm.uuid),

        // It doesn't matter if these fail, we can use default values and
        // the app can still function.
        pendingFarmUserInvites: fetchOr(getPendingFarmInvitesApi(farm.uuid), []),
        mapGroups: fetchOr(getFieldMapsApi(farm.uuid), []),
        farmIntegrations: fetchOr(getFarmIntegrationsApi(farm.uuid), []),
        membershipFinishAt: fetchOr(getFarmMembershipFinishDate(farm.uuid), null),
        trialAvailable: fetchOr(trialAvailable(), false),
        organisation:
            farm.organisationUuid !== undefined
                ? fetchOr(fetchOrganisation(farm.organisationUuid), undefined)
                : undefined,
        organisationUsers:
            farm.organisationUuid !== undefined
                ? fetchOr(fetchOrganisationUsers(farm.organisationUuid), undefined)
                : undefined,
    });

    const defaultYear = getDefaultYear(farm.uuid, props.years);
    const herds = await fetchHerds(farm.uuid, defaultYear);
    return { ...props, herds };
};
