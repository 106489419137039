import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import c from 'classnames';

interface DateButtonProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    disabled?: boolean;
    classWithDate?: string;
}

const DateButton = ({ children, className, ...props }: DateButtonProps) => {
    const hasBgClass = className !== undefined && /bg-/.test(className);
    return (
        <button
            className={c(
                'bare-btn float-left px-3 h-11 bordered rounded focus flex items-center',
                className,
                !hasBgClass ? (props.disabled ? 'bg-gray-50' : 'bg-yellow-50') : ''
            )}
            {...props}
        >
            {children}
        </button>
    );
};

export default DateButton;
