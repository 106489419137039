import type { Activity } from 'activity/activity-helper';
import { getActivityTaggedUserIds } from 'activity/activity-helper';
import type { List } from 'immutable';

const hasTaggedUsers = (taggedUsers: number[]) => (activity: Activity) =>
    [...getActivityTaggedUserIds(activity)].filter((userId) => taggedUsers.includes(userId))
        .length > 0;

export const filterActivitiesByTaggedUsers = (taggedUsers: number[], activities: List<Activity>) =>
    taggedUsers.length > 0 ? activities.filter(hasTaggedUsers(taggedUsers)) : activities;
