import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { UIContext } from 'system/ui-state';
import InfoMsg from 'view/InfoMsg';
import Arrow from 'view/svg/Arrow';

import ExtentSvg from './extent.svg';

import './FullExtentButton.css';

interface FullExtentButtonProps {
    setFullExtent: VoidFunction;
}

const FullExtentButton = ({ setFullExtent }: FullExtentButtonProps) => {
    const { t } = useTranslation();
    const { infoOpen } = useContext(UIContext);
    return (
        <button
            className={c('FullExtentButton bare-btn no-print focus', { 'forefront-abs': infoOpen })}
            onClick={setFullExtent}
        >
            {infoOpen && (
                <InfoMsg className="-top-8 -right-44 flex-col">
                    {t('info_full_extent', { defaultValue: 'Your farm location' })}
                    <Arrow className="w-12 self-start rotate-90 flip-y" />
                </InfoMsg>
            )}
            <img
                src={ExtentSvg}
                alt="Four arrows, one pointing top left, one top right, one bottom right and the last bottom left"
            />
        </button>
    );
};

export default FullExtentButton;
