import { Fragment, type PropsWithChildren } from 'react';

import styles from './Timeline.module.css';

export function TimelineRoot({ children }: PropsWithChildren) {
    return <div className={styles.timeline}>{children}</div>;
}

export function TimelineStep({ children }: PropsWithChildren) {
    return (
        <div className={styles.timelineStep}>
            <span data-area="count" className={styles.steperCount}>
                {children}
            </span>
        </div>
    );
}

export function TimelineContent({ children }: PropsWithChildren) {
    return <div className={styles.timelineContent}>{children}</div>;
}

export function TimelineHeading({ children }: PropsWithChildren) {
    return <div className={styles.timelineHeading}>{children}</div>;
}

export function TimelineDescription({ children }: PropsWithChildren) {
    return <div>{children}</div>;
}

export function TimelineList({ children }: PropsWithChildren) {
    return <ul>{children}</ul>;
}

export function TimelineListItem({ children }: PropsWithChildren) {
    return <li>{children}</li>;
}

interface TimelineProps {
    data: {
        heading: string;
        description?: string;
        list?: string[];
    }[];
}

export function TimelineData({ data }: TimelineProps) {
    return (
        <TimelineRoot>
            {data.map((item, index) => (
                <Fragment key={index}>
                    <TimelineStep>{index + 1}</TimelineStep>
                    <TimelineContent>
                        <TimelineHeading>{item.heading}</TimelineHeading>
                        {item.description && (
                            <TimelineDescription>{item.description}</TimelineDescription>
                        )}
                        {item.list && (
                            <TimelineList>
                                {item.list.map((listItem, index) => (
                                    <TimelineListItem key={index}>{listItem}</TimelineListItem>
                                ))}
                            </TimelineList>
                        )}
                    </TimelineContent>
                </Fragment>
            ))}
        </TimelineRoot>
    );
}
