import { useState } from 'react';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import type Field from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import type { List } from 'immutable';
import { Set } from 'immutable';
import Modal from 'modal/Modal';
import {
    selectInvalidFieldsForPesticideChecking,
    selectInvalidFieldUsagesForPesticideChecking,
} from 'operations/operations-selectors';
import type { AppState } from 'system/store';
import type { CodedItem, SingleParamVoidFunction } from 'system/types';
import Steps, { Step } from 'view/Steps';

import type { PesticideCheckFilter } from '../details/recording/pesticide-check-state';

import type { PesticideCheckResponseFilters } from './PesticideCheck';
import PesticideCheckDisclaimer from './PesticideCheckDisclaimer';
import PesticideCheckPestAndStage from './PesticideCheckPestAndStage';
import PesticideCheckUsages from './PesticideCheckUsages';

import './PesticideCheckModal.css';

interface PesticideCheckModalProps {
    // From parent
    farm: Farm;
    onClose: VoidFunction;
    onDone: VoidFunction;
    fetchPesticideCheck: VoidFunction;
    pending: boolean;
    filterValues: PesticideCheckFilter;
    filters?: PesticideCheckResponseFilters;
    setPest: SingleParamVoidFunction<string>;
    setCropStage: SingleParamVoidFunction<string>;
    setCrop: SingleParamVoidFunction<CodedItem>;

    // From redux
    invalidFields: List<Field>;
    invalidFieldUsages: List<FieldUsage>;
}

enum PesticideCheckStep {
    DISCLAIMER,
    USAGES,
    PEST_STAGE,
}

const PesticideCheckModal = ({
    farm,
    onClose,
    onDone,
    invalidFields,
    invalidFieldUsages,
    pending,
    fetchPesticideCheck,
    filterValues,
    filters,
    setPest,
    setCropStage,
    setCrop,
}: PesticideCheckModalProps) => {
    // This is kept in state rather than derived from props because we don't want the number of
    // steps in the modal to change once the invalid fields have been fixed.
    const [numSteps] = useState(invalidFields.size > 0 ? 3 : 2);
    const [step, setStep] = useState(PesticideCheckStep.DISCLAIMER);

    const handleDisclaimerAgree = () => {
        numSteps === 3 ? setStep(PesticideCheckStep.USAGES) : setPestStage();
    };

    const setPestStage = () => {
        fetchPesticideCheck();
        setStep(PesticideCheckStep.PEST_STAGE);
    };

    return (
        <Modal onClose={onClose} noScroll>
            <div className="PesticideCheckModal">
                <Steps>
                    <Step
                        selected={step === PesticideCheckStep.DISCLAIMER}
                        done={step !== PesticideCheckStep.DISCLAIMER}
                    >
                        1
                    </Step>
                    {numSteps === 3 ? (
                        <Step
                            selected={step === PesticideCheckStep.USAGES}
                            done={step === PesticideCheckStep.PEST_STAGE}
                        >
                            2
                        </Step>
                    ) : null}
                    <Step selected={step === PesticideCheckStep.PEST_STAGE}>{numSteps}</Step>
                </Steps>
                {step === PesticideCheckStep.DISCLAIMER && (
                    <PesticideCheckDisclaimer farm={farm} onAgree={handleDisclaimerAgree} />
                )}
                {step === PesticideCheckStep.USAGES && (
                    <PesticideCheckUsages
                        farm={farm}
                        onBack={setStep.bind(null, PesticideCheckStep.DISCLAIMER)}
                        onNext={setPestStage}
                        invalidFields={invalidFields}
                        invalidFieldUsages={invalidFieldUsages}
                    />
                )}
                {step === PesticideCheckStep.PEST_STAGE && (
                    <PesticideCheckPestAndStage
                        farm={farm}
                        filterValues={filterValues}
                        filters={filters}
                        setPest={setPest}
                        setCropStage={setCropStage}
                        setCrop={setCrop}
                        onBack={setStep.bind(
                            null,
                            numSteps === 3
                                ? PesticideCheckStep.USAGES
                                : PesticideCheckStep.DISCLAIMER
                        )}
                        onNext={onDone}
                        pendingData={pending}
                    />
                )}
            </div>
        </Modal>
    );
};

export default connect((state: AppState, { fieldUuids = Set() }: { fieldUuids?: Set<string> }) => {
    return {
        invalidFields: selectInvalidFieldsForPesticideChecking(state, fieldUuids),
        invalidFieldUsages: selectInvalidFieldUsagesForPesticideChecking(state, fieldUuids),
    };
})(PesticideCheckModal);
