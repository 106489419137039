import { useNavigate } from 'react-router-dom';
import { usePageParams } from 'hooks/usePageParamas';
import { RPAImportContainerBase } from 'rpa/RPAImportContainerBase';

function RPAImportContainer() {
    const navigate = useNavigate();
    const { farmUuid } = usePageParams();

    const onNavigateBack = () => {
        navigate(`/farms/${farmUuid}/fields`);
    };

    const onFinishHandler = () => {
        navigate(`/farms/${farmUuid}/fields/usage`);
    };

    return <RPAImportContainerBase onBack={onNavigateBack} onFinish={onFinishHandler} />;
}

export { RPAImportContainer };
