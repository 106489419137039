import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useDateFormat } from 'hooks/useDateFormat';
import { MeasurementUnit } from 'lib/MeasurementUnit';
import { formatPercentage } from 'lib/util/number';
import type { AppState } from 'system/store';
import { selectUserLengthMeasurementUnit } from 'users/user-state';
import { converter } from 'utils/conversion';
import Num from 'view/Num';
import ShortDistanceUnit from 'view/units/ShortDistanceUnit';
import Temperature from 'view/units/Temperature';
import TemperatureUnit from 'view/units/TemperatureUnit';
import type { DayForecast, HourForecast } from 'weather/Weather';

import WeatherHourView from './WeatherHourView';

interface WeatherDayViewProps {
    dayForecast: DayForecast;
    hourForecasts: HourForecast[];

    // From redux
    lengthMeasurementUnit: MeasurementUnit;
}

const WeatherDayView = ({
    dayForecast,
    hourForecasts,
    lengthMeasurementUnit,
}: WeatherDayViewProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    const dewPoint =
        hourForecasts.reduce((total, hourForecast) => total + hourForecast.temperatureDewPoint, 0) /
        hourForecasts.length;
    const visibility =
        hourForecasts.reduce(
            (total, hourForecast) =>
                total + converter.convertMetresToKilometres(hourForecast.visibility),
            0
        ) / hourForecasts.length;

    return (
        <div className="p-6 flex flex-col flex-grow overflow-auto">
            <div className="flex gap-8 mb-6 font-light">
                {dayForecast.cloudCover && (
                    <span>
                        {t('label_cloud_cover', { defaultValue: 'Cloud cover' })}:{' '}
                        {formatPercentage(dayForecast.cloudCover)}
                    </span>
                )}
                {dayForecast.humidity && (
                    <span>
                        {t('label_humidity', { defaultValue: 'Humidity' })}:{' '}
                        {formatPercentage(dayForecast.humidity)}
                    </span>
                )}
                <span>
                    {t('label_dew_point', { defaultValue: 'Dew point' })}:{' '}
                    <Temperature celsius={dewPoint} round /> <TemperatureUnit />
                </span>
                <span>
                    {t('visibility')}:{' '}
                    {visibility > 5 ? (
                        t('label_good', { defaultValue: 'Good' })
                    ) : (
                        <>
                            <Num
                                value={
                                    lengthMeasurementUnit === MeasurementUnit.IMPERIAL
                                        ? converter.convertMetresToMiles(visibility)
                                        : visibility
                                }
                                places={0}
                            />{' '}
                            <ShortDistanceUnit />
                        </>
                    )}
                </span>
                {dayForecast.sunrise && (
                    <span>
                        {t('label_sunrise', { defaultValue: 'Sunrise' })}:{' '}
                        {format(dayForecast.sunrise, 'h:mm a')}
                    </span>
                )}
                {dayForecast.sunset && (
                    <span>
                        {t('label_sunset', { defaultValue: 'Sunset' })}:{' '}
                        {format(dayForecast.sunset, 'h:mm a')}
                    </span>
                )}
            </div>
            <WeatherHourView hourForecasts={hourForecasts} />
        </div>
    );
};

export default connect((state: AppState) => ({
    lengthMeasurementUnit: selectUserLengthMeasurementUnit(state),
}))(WeatherDayView);
