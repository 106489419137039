import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { useFormApi } from 'informed';
import { InputNutrientSymbol } from 'inputs/InputNutrients';
import { InputType } from 'inputs/InputType';
import type { MicronutrientFormValues, Micronutrients, NutrientFormValues } from 'inputs/Nutrients';
import type Nutrients from 'inputs/Nutrients';
import { formatArea } from 'lib/geo/maths';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { renderMeasurementUnitShort } from 'lib/MeasurementUnit';
import type { SingleParamVoidFunction } from 'system/types';
import PulsingNumberInput from 'view/form/PulsingNumberInput';
import FormattedArea from 'view/units/FormattedArea';
import ShortUnit from 'view/units/ShortUnit';

import {
    calculateRecordingFormValuesFromMicronutrientRate,
    calculateRecordingFormValuesFromMicronutrientTotal,
    calculateRecordingFormValuesFromNutrientRate,
    calculateRecordingFormValuesFromNutrientTotal,
    calculateRecordingFormValuesFromRate,
    calculateRecordingFormValuesFromTotal,
} from '../details/recording/operation-recording-utils';

interface RecordingFormFieldsProps {
    inputUnit: string;
    inputType: string;
    totalArea: number;
    measurementUnit: MeasurementUnit;
    nutrients?: Nutrients;
    micronutrients?: Micronutrients;
}

interface FormValues {
    rate: string;
    total: string;
    nutrients?: NutrientFormValues;
    micronutrients?: MicronutrientFormValues;
}

const RecordingFormFields = ({
    inputUnit,
    inputType,
    totalArea,
    measurementUnit,
    nutrients,
    micronutrients,
}: RecordingFormFieldsProps) => {
    const { t } = useTranslation();
    const formApi = useFormApi<FormValues>();
    const rateError = formApi.getError('rate');
    const totalError = formApi.getError('total');
    const totalAreaFormatted = formatArea(totalArea, measurementUnit);

    const isFertilizer = inputType === InputType.FERTILIZER.toString();

    const calculateFormValuesFromRate = calculateRecordingFormValuesFromRate(
        totalAreaFormatted,
        nutrients,
        micronutrients
    );
    const calculateFormValuesFromTotal = calculateRecordingFormValuesFromTotal(
        totalAreaFormatted,
        nutrients,
        micronutrients
    );
    const calculateFormValuesFromNutrientRate = calculateRecordingFormValuesFromNutrientRate(
        totalAreaFormatted,
        nutrients,
        micronutrients
    );
    const calculateFormValuesFromNutrientTotal = calculateRecordingFormValuesFromNutrientTotal(
        totalAreaFormatted,
        nutrients,
        micronutrients
    );
    const calculateFormValuesFromMicronutrientRate =
        calculateRecordingFormValuesFromMicronutrientRate(
            totalAreaFormatted,
            nutrients,
            micronutrients
        );
    const calculateFormValuesFromMicronutrientTotal =
        calculateRecordingFormValuesFromMicronutrientTotal(
            totalAreaFormatted,
            nutrients,
            micronutrients
        );

    const handleBaseRateChange = (value: number) => {
        formApi.setValues(calculateFormValuesFromRate(value) as FormValues);
    };

    const handleBaseTotalChange = (total: number) => {
        formApi.setValues(calculateFormValuesFromTotal(total) as FormValues);
    };

    const handleNutrientRateChange = (nutrient: string) => (rateValue: number) => {
        formApi.setValues(calculateFormValuesFromNutrientRate(nutrient, rateValue) as FormValues);
    };

    const handleNutrientTotalChange = (nutrient: string) => (totalValue: number) => {
        formApi.setValues(calculateFormValuesFromNutrientTotal(nutrient, totalValue) as FormValues);
    };

    const handleMicronutrientRateChange = (micronutrient: string) => (rateValue: number) => {
        formApi.setValues(
            calculateFormValuesFromMicronutrientRate(micronutrient, rateValue) as FormValues
        );
    };

    const handleMicronutrientTotalChange = (micronutrient: string) => (totalValue: number) => {
        formApi.setValues(
            calculateFormValuesFromMicronutrientTotal(micronutrient, totalValue) as FormValues
        );
    };

    return (
        <div className="RecordingFormFields">
            <div className="flex gap-6 p-5 bordered-b">
                <label className="uppercase text-sm font-bold mb-0">
                    {t('input_rate_field_job_area')}
                </label>
                <span>
                    <FormattedArea areaSqm={totalArea} withUnit />
                </span>
            </div>
            <RateAndTotalFields
                rateLabel={t('application_rate_per')}
                rateField="rate"
                totalLabel={t('total')}
                totalField="total"
                measurementUnit={measurementUnit}
                onRateChange={handleBaseRateChange}
                onTotalChange={handleBaseTotalChange}
                inputUnit={inputUnit}
            />
            {isFertilizer && (
                <>
                    {nutrients !== undefined &&
                        Object.keys(nutrients.toObject())
                            .filter((nutrient) => nutrients.get(nutrient, 0) > 0)
                            .map((nutrient) => (
                                <RateAndTotalFields
                                    key={nutrient}
                                    className="bg-gray-100"
                                    rateLabel={
                                        <>
                                            <InputNutrientSymbol nutrient={nutrient} /> {t('per')}
                                        </>
                                    }
                                    rateField={`nutrientRates.${nutrient}`}
                                    totalLabel={
                                        <>
                                            {t('total')} <InputNutrientSymbol nutrient={nutrient} />
                                        </>
                                    }
                                    totalField={`nutrientTotals.${nutrient}`}
                                    measurementUnit={measurementUnit}
                                    onRateChange={handleNutrientRateChange(nutrient)}
                                    onTotalChange={handleNutrientTotalChange(nutrient)}
                                    inputUnit={inputUnit}
                                />
                            ))}
                    {micronutrients !== undefined &&
                        Object.keys(micronutrients.toObject())
                            .filter((nutrient) => micronutrients.get(nutrient, 0) > 0)
                            .map((nutrient) => (
                                <RateAndTotalFields
                                    key={nutrient}
                                    className="bg-gray-100"
                                    rateLabel={
                                        <>
                                            <InputNutrientSymbol nutrient={nutrient} /> {t('per')}
                                        </>
                                    }
                                    rateField={`micronutrientRates.${nutrient}`}
                                    totalLabel={
                                        <>
                                            {t('total')} <InputNutrientSymbol nutrient={nutrient} />
                                        </>
                                    }
                                    totalField={`micronutrientTotals.${nutrient}`}
                                    measurementUnit={measurementUnit}
                                    onRateChange={handleMicronutrientRateChange(nutrient)}
                                    onTotalChange={handleMicronutrientTotalChange(nutrient)}
                                    inputUnit={inputUnit}
                                />
                            ))}
                </>
            )}
            {(rateError || totalError) && (
                <div className="text-error-text p-5">{t('input_numeric_validation')}</div>
            )}
        </div>
    );
};

export default RecordingFormFields;

interface RateAndTotalFieldsProps {
    rateLabel: ReactNode;
    rateField: string;
    totalLabel: ReactNode;
    totalField: string;
    measurementUnit: MeasurementUnit;
    onRateChange: SingleParamVoidFunction<number>;
    onTotalChange: SingleParamVoidFunction<number>;
    inputUnit: string;
    className?: string;
}
const RateAndTotalFields = ({
    rateLabel,
    rateField,
    totalLabel,
    totalField,
    measurementUnit,
    onRateChange,
    onTotalChange,
    inputUnit,
    className,
}: RateAndTotalFieldsProps) => {
    return (
        <div className={c('p-5 bordered-b flex justify-between', className)}>
            <div className="flex items-center gap-2">
                <label htmlFor={rateField} className="font-bold mb-0 leading-5 w-24">
                    {rateLabel} {renderMeasurementUnitShort(measurementUnit)}
                </label>
                <PulsingNumberInput
                    inputClassName="w-24"
                    field={rateField}
                    id={rateField}
                    onChange={onRateChange}
                />
                <span className="unit">
                    <ShortUnit unit={inputUnit} />
                </span>
            </div>
            <div className="flex items-center gap-2">
                <label htmlFor={totalField} className="uppercase font-bold mb-0">
                    {totalLabel}
                </label>
                <PulsingNumberInput
                    inputClassName="w-24"
                    field={totalField}
                    id={totalField}
                    onChange={onTotalChange}
                />
                <span className="unit">
                    <ShortUnit unit={inputUnit} />
                </span>
            </div>
        </div>
    );
};
