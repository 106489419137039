import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import c from 'classnames';
import icon from 'components/icons/icon';
import { useDocumentEventListener } from 'lib/hooks';
import { uniqueId } from 'lodash';
import { hasOwnProperty } from 'utils/hasOwnProperty';

import './Modal.css';

interface CommonProps extends PropsWithChildren {
    className?: string;
    // if set to true the modal will not have CSS set to enable the body to scroll.
    noScroll?: boolean;
}

type ConditionalProps =
    | {
          onClose: VoidFunction;
          allowClose?: true;
      }
    | {
          onClose?: never;
          allowClose: false;
      };

type ModalProps = CommonProps & ConditionalProps;

const Modal = ({
    children,
    onClose,
    className,
    allowClose = true,
    noScroll = false,
}: ModalProps) => {
    const [el, setEl] = useState<HTMLDivElement>();

    const handleCloseOnEsc = (e: KeyboardEvent) => {
        e.stopPropagation();
        if (allowClose && e.key === 'Escape') {
            onClose !== undefined && onClose();
        }
    };

    const handleCloseOnClick = (e: MouseEvent) => {
        const isInModal =
            e.composedPath().filter((el: HTMLElement) => hasOwnProperty(el.dataset, 'modalcontent'))
                .length > 0;
        if (allowClose && e.target !== null && !isInModal) {
            onClose !== undefined && onClose();
        }
    };

    useDocumentEventListener('keyup', handleCloseOnEsc);

    useDocumentEventListener('click', handleCloseOnClick);

    useEffect(() => {
        const div = document.createElement('div');
        div.id = uniqueId('modal-');
        setEl(div);
    }, []);

    useEffect(() => {
        if (el !== undefined) document.body.appendChild(el);

        return () => {
            if (el !== undefined) document.body.removeChild(el);
        };
    }, [el]);

    return el !== undefined
        ? createPortal(
              <div className={c('Modal modal-bg', className)}>
                  <div className="relative" data-modalcontent>
                      <div
                          className={c('ModalContent', {
                              'with-scroll': !noScroll,
                          })}
                      >
                          {children}
                      </div>
                      {allowClose && (
                          <button className="ModalClose bare-btn" onClick={onClose}>
                              {icon('close', 'white', 'h-8')}
                          </button>
                      )}
                  </div>
              </div>,
              el
          )
        : null;
};

export default Modal;
