import { useTranslation } from 'react-i18next';
import { getDueDate } from 'activity/activity-helper';
import { compareAsc } from 'date-fns';

import { useFieldTabsContext } from '../useFieldTabsContext';

import FieldTodoItem from './FieldTodoItem';

const FieldTodoItems = () => {
    const { t } = useTranslation();
    const { todoActivities } = useFieldTabsContext();

    return (
        <div className="p-5">
            {todoActivities.size === 0 ? (
                <p>{t('field_todo_empty')}</p>
            ) : (
                <>
                    {todoActivities
                        .sortBy(
                            (i) => getDueDate(i),
                            (a, b) => {
                                if (!a) {
                                    return 1;
                                }
                                if (!b) {
                                    return -1;
                                }
                                return compareAsc(a, b);
                            }
                        )
                        .map((activity, idx) => (
                            <FieldTodoItem key={idx} activity={activity} />
                        ))}
                </>
            )}
        </div>
    );
};

export default FieldTodoItems;
