import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { usePromise } from '@fieldmargin/webapp-state';
import { isComplete } from 'activity/activity-helper';
import icon from 'components/icons/icon';
import type Note from 'notes/Note';
import { useCompleteTask, useUpdateNote, useUpdateTask } from 'notes/note-hooks';
import MarkCompleteButton from 'sidebar/modules/mark-complete/MarkCompleteButton';
import EditableTitle from 'sidebar/modules/title/EditableTitle';
import type { AppState } from 'system/store';
import { selectUserId } from 'users/user-state';

interface EditableNoteTitleProps {
    // From parent
    note: Note;

    // From redux
    userId: number;
}
const EditableNoteTitle = ({ note, userId }: EditableNoteTitleProps) => {
    const { t } = useTranslation();
    const { pending, setPromise } = usePromise();
    const updateNote = useUpdateNote(note);
    const updateTask = useUpdateTask(note);
    const completeTask = useCompleteTask(note);

    const handleSaveTitle = (name: string) => {
        return updateNote({ name });
    };

    const handleToggleComplete = () => {
        setPromise(
            note.completedDate === null
                ? completeTask({ completedDate: new Date(), completedByUserId: userId })
                : updateTask({ setCompletedNull: true })
        );
    };

    return (
        <EditableTitle label="Title" value={note.name} onSave={handleSaveTitle}>
            {note.task && (
                <div style={{ marginTop: 10 }}>
                    <MarkCompleteButton
                        complete={isComplete(note)}
                        pending={pending}
                        onToggle={handleToggleComplete}
                    />
                </div>
            )}
            {note.archived && (
                <div className="text-xs text-gray-450" style={{ marginTop: 10 }}>
                    {icon('archive', 'grey', 'mr-1')}

                    {t('archived')}
                </div>
            )}
        </EditableTitle>
    );
};

export default connect((state: AppState) => ({
    userId: selectUserId(state),
}))(EditableNoteTitle);
