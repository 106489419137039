import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import { useApplicationLayoutContext } from '@notes-web/layout';
import { updateChat, updateLastRead } from 'chat/chat-state';
import FarmError from 'components/farm/FarmError';
import PageLoading from 'components/PageLoading';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess, loadFarm, loadingFarm } from 'farms/farm-loading-state';
import { selectCurrentFarm, selectFarms } from 'farms/farms-state';
import { usePageParams } from 'hooks/usePageParamas';
import type { List } from 'immutable';
import FirebaseEventHandler from 'lib/firebase/FirebaseEventHandler';
import type { FarmChatChange } from 'lib/firebase/snapshot-parsers';
import LocalStorageHelper from 'lib/storage/LocalStorageHelper';
import { withOnboarding } from 'onboarding/onboarding-state';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { getActiveSectionFromPath } from 'system/url-util';
import { updateTutorials } from 'tutorials/tutorials-state';
import { selectUserId } from 'users/user-state';

import styles from './FarmLoader.module.css';

interface FarmLoaderProps extends PropsWithChildren {
    farms: List<Farm>;
    myUserId: number;

    loadingFarm: typeof loadingFarm;
    loadedFarmSuccess: typeof loadedFarmSuccess;

    currentFarm: Farm;

    updateLastRead: typeof updateLastRead;
    updateChat: (changes: List<FarmChatChange>) => void;
    updateTutorials: typeof updateTutorials;
}

const FarmLoader = ({
    children,
    farms,
    myUserId,
    loadingFarm,
    loadedFarmSuccess,
    currentFarm,
    updateLastRead,
    updateChat,
    updateTutorials,
}: FarmLoaderProps) => {
    const navigate = useNavigate();
    const { Header } = useApplicationLayoutContext();

    const { farmUuid } = usePageParams();
    const { pathname } = useLocation();
    const activeSection = getActiveSectionFromPath(pathname);

    const { error, setPromise } = usePromise<LoadedFarmSuccessPayload>((payload) => {
        loadedFarmSuccess(payload);
        LocalStorageHelper.setItem('lastFarmUuid', payload.farm.uuid);
        FirebaseEventHandler.subscribeToFarmChat(
            payload.farm.uuid,
            myUserId,
            updateLastRead,
            updateChat
        );
        FirebaseEventHandler.subscribeOnboarding(myUserId, updateTutorials);
    });

    useEffect(() => {
        const desiredFarm = farms.find((farm) => farm.uuid === farmUuid);
        if (!desiredFarm) {
            navigate('/', { replace: true });
            document.title = 'fieldmargin';
        }
        if (desiredFarm && (!currentFarm || currentFarm.uuid !== desiredFarm.uuid)) {
            loadingFarm(desiredFarm);
            document.title = `${desiredFarm.name} - fieldmargin`;
            setPromise(loadFarm(desiredFarm));
        }

        return () => {
            document.title = 'fieldmargin';
            FirebaseEventHandler.unsubscribeAll();
        };
    }, [farmUuid]);

    if (error) {
        return <FarmError />;
    }

    if (!currentFarm || farmUuid !== currentFarm.uuid) {
        return <PageLoading />;
    }

    return (
        <div className={styles.farmLoader}>
            <Header activeSection={activeSection} />
            <Outlet />
            {children}
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farms: selectFarms(state),
        myUserId: selectUserId(state),
        currentFarm: selectCurrentFarm(state),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                loadingFarm,
                loadedFarmSuccess,
                updateLastRead,
                updateChat,
                updateTutorials,
            },
            dispatch
        )
)(withOnboarding(FarmLoader));
