import type { ComponentProps } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';

interface FilterCalendarProps extends ComponentProps<typeof Calendar> {
    value: Date | undefined;
    onChange: (newValue: Date) => void;
}

function FilterCalendar({ value, onChange, ...props }: FilterCalendarProps) {
    const { i18n } = useTranslation();

    return (
        <Calendar
            locale={i18n.language}
            calendarType="iso8601"
            value={value}
            onChange={onChange}
            {...props}
        />
    );
}

export { FilterCalendar };
