import type { ComponentProps } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import { getYearFieldUsageUuid } from 'fields/Field';
import { updateAndRemoveFields } from 'fields/fields-state';
import type SubField from 'fields/sub-fields/SubField';
import type { Map as ImmutableMap } from 'immutable';
import { List, Set } from 'immutable';
import Modal from 'modal/Modal';
import type Note from 'notes/Note';
import { selectCurrentYearNotesForField } from 'notes/notes-selectors';
import { updateNotes } from 'notes/notes-state';
import type FullOperation from 'operations/FullOperation';
import { selectCurrentYearOperationsForField } from 'operations/operations-selectors';
import { updateOperations } from 'operations/operations-state';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import ErrorMessage from 'view/ErrorMessage';
import { selectCurrentYear } from 'years/years-state';

import {
    getNextStep,
    getPreviousStep,
    getStartingStep,
    saveSubFieldsCopyData,
    SubFieldsDataCopyStep,
} from './sub-fields-data-copy-utils';
import SubFieldsDataCopyNotes from './SubFieldsDataCopyNotes';
import SubFieldsDataCopyOperations from './SubFieldsDataCopyOperations';
import SubFieldsDataCopyUsage from './SubFieldsDataCopyUsage';

interface ParentProps {
    parentField: Field;
    onClose: VoidFunction;
}

interface ReduxProps {
    farm: Farm;
    year: number;
    subFields: List<SubField>;
    fieldOperations: ImmutableMap<string, FullOperation>;
    fieldNotes: Map<string, Note>;

    updateAndRemoveFields: typeof updateAndRemoveFields;
    updateOperations: typeof updateOperations;
    updateNotes: typeof updateNotes;
}

type SubFieldsDataCopyBaseProps = ParentProps & ReduxProps;

const SubFieldsDataCopyBase = ({
    farm,
    year,
    subFields,
    fieldOperations,
    fieldNotes,
    updateAndRemoveFields,
    updateOperations,
    updateNotes,
    parentField,
    onClose,
}: SubFieldsDataCopyBaseProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const yearFieldUsageUuid = getYearFieldUsageUuid(year, parentField);

    const [step, setStep] = useState(getStartingStep(yearFieldUsageUuid, fieldOperations));

    const [copyUsagesTo, setCopyUsagesTo] = useState(Set<string>());
    const [copyOperationsTo, setCopyOperationsTo] = useState(Set<string>());
    const [copyNotesTo, setCopyNotesTo] = useState(Set<string>());

    const { pending, error, setPromise } = usePromise<{
        subFields: List<SubField>;
        operations: ImmutableMap<string, FullOperation>;
        notes: Map<string, Note>;
    }>(({ subFields, operations, notes }) => {
        updateAndRemoveFields({ update: subFields.map((sf) => sf.field), remove: List() });
        updateOperations(operations);
        updateNotes(notes);
        onClose();
        navigate(`/farms/${farm.uuid}/fields/${parentField.uuid}`);
    });

    const handleSave = () => {
        setPromise(
            saveSubFieldsCopyData(
                farm,
                year,
                parentField,
                yearFieldUsageUuid,
                subFields,
                fieldOperations,
                fieldNotes,
                copyUsagesTo,
                copyOperationsTo,
                copyNotesTo
            )
        );
    };

    const handleNext = () => {
        const nextStep = getNextStep(step, fieldOperations, fieldNotes);
        nextStep !== undefined ? setStep(nextStep) : handleSave();
    };

    const handleBack = () => {
        const previousStep = getPreviousStep(step, yearFieldUsageUuid, fieldOperations);
        previousStep !== undefined ? setStep(previousStep) : onClose();
    };

    const handleSkip = () => {
        step === SubFieldsDataCopyStep.USAGE && setCopyUsagesTo(Set());
        step === SubFieldsDataCopyStep.OPERATIONS && setCopyOperationsTo(Set());
        step === SubFieldsDataCopyStep.NOTES && setCopyNotesTo(Set());
        handleNext();
    };

    const isFirstStep = getPreviousStep(step, yearFieldUsageUuid, fieldOperations) === undefined;
    const isLastStep = getNextStep(step, fieldOperations, fieldNotes) === undefined;

    const numberOfSteps = [
        yearFieldUsageUuid !== undefined,
        fieldOperations.size > 0,
        fieldNotes.size > 0,
    ].reduce((acc, val) => (val ? acc + 1 : acc), 0);

    return (
        <Modal onClose={onClose}>
            <div className="bg-white p-8 flex flex-col" style={{ width: 700, height: 520 }}>
                {step === SubFieldsDataCopyStep.USAGE && (
                    <SubFieldsDataCopyUsage
                        parentField={parentField}
                        subFields={subFields}
                        setSelected={setCopyUsagesTo}
                        selected={copyUsagesTo}
                        stepNumber={1}
                        numberOfSteps={numberOfSteps}
                    />
                )}
                {step === SubFieldsDataCopyStep.OPERATIONS && (
                    <SubFieldsDataCopyOperations
                        parentField={parentField}
                        subFields={subFields}
                        setSelected={setCopyOperationsTo}
                        selected={copyOperationsTo}
                        stepNumber={yearFieldUsageUuid !== undefined ? 2 : 1}
                        numberOfSteps={numberOfSteps}
                    />
                )}
                {step === SubFieldsDataCopyStep.NOTES && (
                    <SubFieldsDataCopyNotes
                        parentField={parentField}
                        subFields={subFields}
                        setSelected={setCopyNotesTo}
                        selected={copyNotesTo}
                        stepNumber={
                            yearFieldUsageUuid !== undefined && fieldOperations.size > 0
                                ? 3
                                : yearFieldUsageUuid !== undefined || fieldOperations.size > 0
                                  ? 2
                                  : 1
                        }
                        numberOfSteps={numberOfSteps}
                    />
                )}
                <div className="mt-auto grid grid-cols-3 justify-center">
                    <div className="flex gap-4 justify-center col-start-2">
                        {!isFirstStep && (
                            <Button variant="outline" onClick={handleBack}>
                                {t('back')}
                            </Button>
                        )}
                        <Button onClick={handleNext} disabled={pending}>
                            {isLastStep ? t('save') : t('next')}
                        </Button>
                    </div>
                    <Button
                        onClick={handleSkip}
                        disabled={pending}
                        variant="outline"
                        className="ml-auto"
                    >
                        {t('skip')}
                    </Button>
                </div>
                {error && <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>}
            </div>
        </Modal>
    );
};

function SubFieldsDataCopy({ ...props }: ComponentProps<typeof SubFieldsDataCopyBase>) {
    // There's nothing to do here if there is no usage or notes/operations to copy.
    const yearFieldUsageUuid = getYearFieldUsageUuid(props.year, props.parentField);

    if (
        yearFieldUsageUuid === undefined &&
        props.fieldOperations.size === 0 &&
        props.fieldNotes.size === 0
    ) {
        props.onClose();
        return null;
    } else {
        return <SubFieldsDataCopyBase {...props} />;
    }
}

export default connect(
    (state: AppState, props: ParentProps) => ({
        farm: selectCurrentFarm(state),
        year: selectCurrentYear(state),
        subFields: state.subFieldsState.subFields,
        fieldOperations: selectCurrentYearOperationsForField(state, props.parentField.uuid),
        fieldNotes: selectCurrentYearNotesForField(state, props.parentField.uuid),
    }),
    (dispatch) =>
        bindActionCreators({ updateAndRemoveFields, updateOperations, updateNotes }, dispatch)
)(SubFieldsDataCopy);
