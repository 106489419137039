import { toggleDisplayNotes } from 'farm-editing/farm-editing-state';
import { useNotesPageLocation } from 'hooks/useNotesPageLocation';
import VisibilityToggle from 'sidebar/visibility-toggle/VisibilityToggle';
import { useAppDispatch, useAppSelector } from 'system/store';

import styles from './NotesVisibilityControl.module.css';

function NotesVisibilityControl() {
    const { isNotesPage } = useNotesPageLocation();

    const { displayNotes } = useAppSelector((state) => state.farmEditingState.layerVisibility);
    const dispatch = useAppDispatch();

    const onSelectHandler = (value: string) => {
        dispatch(toggleDisplayNotes(value === 'on'));
    };
    return (
        isNotesPage && (
            <div className={styles.visibilityToggle}>
                <VisibilityToggle active={displayNotes ? 'on' : 'off'} onSelect={onSelectHandler} />
            </div>
        )
    );
}

export { NotesVisibilityControl };
