import type { RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '@notes-web/types';
import MapsList from 'maps/common/sidebar/list/MapsList';
import { MapGroupDeletion } from 'maps/farm/sidebar/delete/MapGroupDeletion';
import MapGroupDetails from 'maps/farm/sidebar/details/MapGroupDetails';
import ErrorMessage from 'view/ErrorMessage';

export const mapsRoutes: RouteObject = {
    path: APP_ROUTES.Maps.Root,

    children: [
        {
            index: true,
            element: <MapsList />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing maps, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Maps.Group,
            element: <MapGroupDetails />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when viewing map details, please refresh the page.
                </ErrorMessage>
            ),
        },
        {
            path: APP_ROUTES.Maps.GroupDelete,
            element: <MapGroupDeletion />,
            errorElement: (
                <ErrorMessage className="p-5">
                    Something went wrong when deleting a map, please refresh the page.
                </ErrorMessage>
            ),
        },
    ],
};
