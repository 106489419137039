import type { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { toggleHiddenFieldUsage } from 'farm-editing/farm-editing-state';
import type FieldUsage from 'fields/FieldUsage';
import type { List } from 'immutable';
import { bindActionCreators } from 'redux';
import VisibilityButton from 'sidebar/visibility-toggle/VisibilityButton';
import type LayerVisibility from 'system/editing/LayerVisibility';
import type { AppState } from 'system/store';

interface FieldUsageVisibilityButtonProps {
    // From parent
    fieldUsage: FieldUsage;
    className?: string;
    // From redux
    layerVisibility: LayerVisibility;
    toggleHiddenFieldUsage: typeof toggleHiddenFieldUsage;
    fieldUsages: List<FieldUsage>;
}

const FieldUsageVisibilityButton = ({
    fieldUsage,
    className,
    layerVisibility,
    toggleHiddenFieldUsage,
    fieldUsages,
}: FieldUsageVisibilityButtonProps) => {
    const handleVisibilityClick = (e?: MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();
        toggleHiddenFieldUsage({
            fieldUsageUuid: fieldUsage.uuid,
            value: !layerVisibility.hiddenFieldUsages.contains(fieldUsage.uuid),
            allFieldUsages: fieldUsages,
        });
    };
    const isVisible =
        !layerVisibility.hiddenFieldUsages.contains(fieldUsage.uuid) &&
        layerVisibility.fieldsVisibility !== 'off';
    return (
        <VisibilityButton
            onClick={handleVisibilityClick}
            visible={isVisible}
            className={className}
        />
    );
};

export default connect(
    (state: AppState) => ({
        layerVisibility: state.farmEditingState.layerVisibility,
        fieldUsages: state.fieldUsageState.fieldUsages,
    }),
    (dispatch) => bindActionCreators({ toggleHiddenFieldUsage }, dispatch)
)(FieldUsageVisibilityButton);
