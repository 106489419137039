import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import { selectCurrentFarm } from 'farms/farms-state';
import type Herd from 'herd/Herd';
import { createNewHerd, HerdType } from 'herd/Herd';
import { saveHerd } from 'herd/herd-api';
import { selectIsAllowedToAddHerds } from 'herd/herd-selectors';
import { setHerd } from 'herd/herd-state';
import { colourMap } from 'lib/colours';
import { bindActionCreators } from 'redux';
import CreateHeader from 'sidebar/header/CreateHeader';
import type { AppState } from 'system/store';
import { useFinishTutorialOnMount } from 'tutorials/tutorial-hooks';
import { TutorialTypes } from 'tutorials/TutorialTypes';
import { trackEvent } from 'utils/trackEvent';

import type { HerdFormValues } from './HerdForm';
import HerdForm from './HerdForm';

interface HerdNewProps {
    farm: Farm;
    year: number;
    setHerd: typeof setHerd;
    isAllowedToAddHerd: boolean;
}

const HerdNew = ({ farm, year, setHerd, isAllowedToAddHerd }: HerdNewProps) => {
    const navigate = useNavigate();
    const { pending, error, setPromise } = usePromise<Herd>((herd) => {
        setHerd(herd);
        trackEvent('Herd created', { farmUuid: farm.uuid, herdType: herd.type.toString() });
        navigate(`/farms/${farm.uuid}/livestock/${herd.uuid}`, {
            state: { ignoreUnsavedChanges: true },
        });
    });

    useFinishTutorialOnMount(TutorialTypes.HERDS);

    const handleSubmit = (values: HerdFormValues) => {
        if (isAllowedToAddHerd) {
            const newHerd = createNewHerd({
                farmUuid: farm.uuid,
                name: values.name,
                type: values.type ?? HerdType.CATTLE,
                colour: values.colour,
                size: parseInt(values.size, 10),
            });
            setPromise(saveHerd(year, newHerd));
        }
    };

    const initialValues = {
        name: '',
        colour: colourMap.red.colour,
        size: '',
    };

    return (
        <HerdForm
            onSubmit={handleSubmit}
            initialValues={initialValues}
            header={
                <CreateHeader
                    isSaving={pending}
                    backPath={`/farms/${farm.uuid}/livestock`}
                    backState={{
                        ignoreUnsavedChanges: true,
                    }}
                />
            }
            error={error}
        />
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        year: state.yearsState.currentYear,
        isAllowedToAddHerd: selectIsAllowedToAddHerds(state),
    }),
    (dispatch) => bindActionCreators({ setHerd }, dispatch)
)(HerdNew);
