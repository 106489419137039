import { useRef, useState } from 'react';
import c from 'classnames';
import type { Set } from 'immutable';
import { useFieldApi } from 'informed';
import { uniqueId } from 'lodash';
import type { SingleParamVoidFunction } from 'system/types';
import { required } from 'view/form/validations';

import MetricFormFields from './MetricFormFields';
import MetricFormStatic from './MetricFormStatic';

const validateMetricName = required('Your metric needs a name');
const validateMetricType = required('Your metric needs a type');

interface MetricFormContainer {
    add: SingleParamVoidFunction<string>;
    remove: SingleParamVoidFunction<string>;
    field: string;
    required: boolean;
    addedMetrics: Set<string>;
    className?: string;
}

const MetricFormContainer = ({
    add,
    remove,
    field,
    required,
    addedMetrics,
    className,
}: MetricFormContainer) => {
    const nameField = useFieldApi<string>(`${field}.metricName`);
    const typeField = useFieldApi<string>(`${field}.metricType`);
    const unitField = useFieldApi<string>(`${field}.metricUnit`);

    const [editing, setEditing] = useState(true);
    const idRef = useRef<string>(uniqueId('metric-form-field-'));

    // When editing the user has the option to cancel, so we need to store the
    // original values for this so we can reset them if they cancel.
    const [prevValues, setPrevValues] = useState<{
        metricName: string;
        metricType: string;
        metricUnit?: string;
    }>();

    const handleAdd = () => {
        // Can only add this metric if it's valid
        if (!nameField.getValue() || !typeField.getValue()) {
            nameField.setError(validateMetricName(undefined));
            typeField.setError(validateMetricType(undefined));
            return;
        }
        add(idRef.current);
        setEditing(false);
    };

    const handleRemove = () => {
        remove(idRef.current);
    };

    const handleEdit = () => {
        setPrevValues({
            metricName: nameField.getValue(),
            metricType: typeField.getValue(),
            metricUnit: unitField.getValue(),
        });
        setEditing(true);
    };

    const handleCancelEdit = () => {
        setEditing(false);
        if (prevValues) {
            nameField.setValue(prevValues.metricName);
            typeField.setValue(prevValues.metricType);
            if (prevValues.metricUnit) {
                unitField.setValue(prevValues.metricUnit);
            }
        }
    };
    const handleSaveEdit = () => {
        // Can only save if the metric is valid
        if (!nameField.getError() && !typeField.getError()) {
            setEditing(false);
            setPrevValues(undefined);
        }
    };

    const hasBeenAdded = addedMetrics.has(idRef.current);
    const hasError = nameField.getError() || typeField.getError();

    return (
        <div className={c(hasBeenAdded ? 'order-2' : 'order-1', className)}>
            {!editing && (
                <MetricFormStatic
                    name={nameField.getValue()}
                    type={typeField.getValue()}
                    unit={unitField.getValue()}
                    onEdit={handleEdit}
                    onRemove={handleRemove}
                />
            )}
            <MetricFormFields
                field={field}
                hidden={!editing}
                added={hasBeenAdded}
                required={required}
                type={typeField.getValue()}
                error={hasError}
                onAdd={handleAdd}
                onCancelEdit={handleCancelEdit}
                onSaveEdit={handleSaveEdit}
            />
        </div>
    );
};

export default MetricFormContainer;
