import type { ChangeEvent } from 'react';
import { useState } from 'react';
import type Field from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import type { Map } from 'immutable';
import { List } from 'immutable';
import Modal from 'modal/Modal';

import SubFieldsUsagePreviousFinish from './SubFieldsUsagePreviousFinish';
import SubFieldsUsePreviousUsageChoice from './SubFieldsUsePreviousUsageChoice';
import SubFieldsUsePreviousYearChoice from './SubFieldsUsePreviousYearChoice';

interface SubFieldsUsePreviousModalProps {
    onClose: VoidFunction;
    onDone: (subFields: List<Field>, copyUsages: boolean) => void;
    parentField: Field;
    previousSubFields: Map<string, List<Field>>;
    fieldUsages: List<FieldUsage>;
}

interface SubFieldsUsePreviousState {
    step: string;
    selectedYear?: number;
    usagesCopied: boolean;
}

const SubFieldsUsePreviousModal = ({
    onClose,
    onDone,
    parentField,
    previousSubFields,
    fieldUsages,
}: SubFieldsUsePreviousModalProps) => {
    const years = previousSubFields.keySeq().sort();

    const [state, setState] = useState<SubFieldsUsePreviousState>({
        step: 'year',
        selectedYear: undefined,
        usagesCopied: false,
    });

    const subFields = state.selectedYear
        ? previousSubFields.get(state.selectedYear.toString(), List<Field>())
        : undefined;

    const handleYearChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setState({ ...state, selectedYear: value === '' ? undefined : parseInt(value, 10) });
    };

    const handleUsageChoice = (copyUsages: boolean) => {
        if (subFields !== undefined) {
            onDone(subFields, copyUsages);
        }
        setState({ ...state, step: 'finish', usagesCopied: copyUsages });
    };

    return (
        <Modal onClose={onClose}>
            <div
                className="bg-white p-8 text-center flex flex-col align-center"
                style={{ width: 550, height: 700 }}
            >
                {state.step === 'year' && (
                    <SubFieldsUsePreviousYearChoice
                        parentField={parentField}
                        subFields={subFields}
                        years={years.toList()}
                        selectedYear={state.selectedYear}
                        onYearChange={handleYearChange}
                        fieldUsages={fieldUsages}
                        onClose={onClose}
                        onUse={() => setState({ ...state, step: 'usage' })}
                    />
                )}
                {state.step === 'usage' && (
                    <SubFieldsUsePreviousUsageChoice
                        onBack={() => setState({ ...state, step: 'year' })}
                        onChoose={handleUsageChoice}
                    />
                )}
                {state.step === 'finish' && (
                    <SubFieldsUsagePreviousFinish
                        onClose={onClose}
                        usagesCopied={state.usagesCopied}
                    />
                )}
            </div>
        </Modal>
    );
};

export default SubFieldsUsePreviousModal;
