import type { ComponentProps } from 'react';
import { clsx } from 'clsx';

import styles from './Box.module.css';

function Box({ className, ...props }: ComponentProps<'div'>) {
    return <div className={clsx(styles.box, className)} {...props} />;
}

export { Box };
