import type { GeoPolygon } from '@fieldmargin/webapp-geo';
import { GeoFeature } from '@fieldmargin/webapp-geo';
import OLFeatureProperties from 'components/maps/openlayers/OLFeatureProperties';
import { AttachmentType } from 'farm-editing/attachments';
import { selectEditingAttachmentsByType } from 'farm-editing/farm-editing-state';
import type Field from 'fields/Field';
import type Herd from 'herd/Herd';
import { selectHerdsFilteredBySearchString } from 'herd/herd-selectors';
import { selectHerdField } from 'herd/sidebar/herd-ui-utils';
import { notNil } from 'lib/fp-helpers';
import { getFirstFeatureGeometryFromCollection, getInteriorPoint } from 'lib/geo/geometry';
import { uniqueId } from 'lodash';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import { VisibilityOptions } from 'system/types';
import type { ActiveSection } from 'system/url-util';

/**
 * Herds are shown on any activeSection.
 * No herds are shown in the user is editing sub-fields.
 * No herds are show if the user has turned off visibility.
 * If the user has searched herds, only those matching the search string will be shown.
 * If a herd is selected the other herd markers will be dimmed.
 */
export const selectHerdMarkers = createSelector(
    (_state: AppState, activeSection: ActiveSection) => activeSection,
    (state: AppState) => state.fieldsState.fields,
    selectHerdsFilteredBySearchString,
    (
        _state: AppState,
        _activeSection: ActiveSection,
        { selectedHerdUuid }: { selectedHerdUuid?: string }
    ) => selectedHerdUuid,
    (state: AppState) => state.farmEditingState.layerVisibility.herdsVisibility,
    (state: AppState) => state.farmEditingState.editingType,
    (state: AppState) => selectEditingAttachmentsByType(state, AttachmentType.FIELD),
    (
        activeSection,
        fields,
        herds,
        selectedHerdUuid,
        herdVisbility,
        editingType,
        editingFieldUuids
    ): GeoFeature[] => {
        if (
            activeSection.sub === 'sub-fields' ||
            !herds ||
            !fields ||
            herdVisbility === VisibilityOptions.OFF
        ) {
            return [];
        }

        const editingField =
            editingType === 'herd'
                ? fields.find((field) => field.uuid === editingFieldUuids.first())
                : undefined;

        return herds
            .filter((herd) => !herd.archived)
            .map((herd) => {
                if (herd.uuid === selectedHerdUuid && editingField) {
                    return herdToGeoFeature(herd, editingField, selectedHerdUuid);
                }

                const field = selectHerdField(fields, herd);
                if (field) {
                    return herdToGeoFeature(herd, field, selectedHerdUuid);
                }
                return null;
            })
            .filter(notNil);
    }
);

const herdToGeoFeature = (herd: Herd, field: Field, selectedHerdUuid?: string) => {
    if (!field.geoJson) {
        return null;
    }

    const point = getInteriorPoint(
        getFirstFeatureGeometryFromCollection(field.geoJson) as GeoPolygon
    );

    return GeoFeature({
        id: uniqueId(`${herd.uuid}:`),
        geometry: point,
        properties: OLFeatureProperties({
            type: 'herd',
            colour: herd.colour,
            label: herd.type.toString(),
            pointOpacity: !selectedHerdUuid ? 1 : selectedHerdUuid === herd.uuid ? 1 : 0.5,
        }),
    });
};
