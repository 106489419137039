import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { createLogger } from '@fieldmargin/webapp-reporting';
import Promise from 'bluebird';
import ChatMessage from 'chat/ChatMessage';
import type { FirebaseApp } from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import type { DocumentSnapshot, Firestore } from 'firebase/firestore';
import { collection, doc, getFirestore } from 'firebase/firestore';
import type { RemoteConfig } from 'firebase/remote-config';
import { fetchAndActivate, getNumber, getRemoteConfig, getString } from 'firebase/remote-config';
import config from 'lib/config';

const logger = createLogger('lib.firebase.firebase-connection');

let firebaseApp: FirebaseApp;
let db: Firestore;
let remoteConfig: RemoteConfig;

export enum RemoteConfigKeys {
    MAX_FREE_FARMS = 'max_free_farms',
    WEB_MAPS = 'web_maps',
}
const defaultRemoteConfig = {
    [RemoteConfigKeys.MAX_FREE_FARMS]: 3,
    [RemoteConfigKeys.WEB_MAPS]: 'google',
};

export const initializeFirebase = () => {
    const firebaseConfig = {
        apiKey: config.get('firebaseApiKey'),
        appId: config.get('firebaseAppId'),
        authDomain: config.get('firebaseAuthDomain'),
        databaseURL: config.get('firebaseDatabaseURL'),
        projectId: config.get('firebaseProjectId'),
        storageBucket: config.get('firebaseStorageBucket'),
        messagingSenderId: config.get('firebaseMessagingSenderId'),
    };

    firebaseApp = initializeApp(firebaseConfig);
    db = getFirestore(firebaseApp);

    // Set up remote config - we won't refetch these values because unlike mobile apps
    // web users will likely start a new session each time.
    remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
    remoteConfig.defaultConfig = defaultRemoteConfig;
    fetchAndActivate(remoteConfig);
};

export const fetchFirebaseTokenAndInit = () =>
    authorizedRequest<string>({
        url: `${config.get('apiRoot')}/auth-api/google/firebase/token`,
    })
        .then(initConnection)
        .catch((err) => {
            logger.handleRequestError('Unable to fetch firebase token')(err);
            return Promise.reject(new Error('Unable to fetch firebase token'));
        });

export const initConnection = (firebaseToken: string) => {
    // Sign out first in case there's a new token. Calling signInWithCustomToken alone isn't
    // enough to reauthenticate.
    const auth = getAuth(firebaseApp);
    return Promise.resolve(signOut(auth))
        .then(() => signInWithCustomToken(auth, firebaseToken))
        .tapCatch(logger.handleRequestError('Unable to initialize firebase'));
};

export const refreshFirebaseToken = () => fetchFirebaseTokenAndInit();

export const messageFromDoc = (doc: DocumentSnapshot): ChatMessage | undefined => {
    const data = doc.data();
    if (data) {
        return ChatMessage({
            ...data,
            fileUuid: data.fileUUID,
            createdDate: data.createdDate.toDate(),
        });
    }
};

const getFarmRef = (farmUuid: string) => {
    if (!firebaseApp || !db) {
        throw new Error('Firebase not initialized');
    }
    return doc(collection(db, 'farms'), farmUuid);
};

const getChannelRef = (farmUuid: string) =>
    doc(collection(getFarmRef(farmUuid), 'messageGroups'), 'general');

export const getMessagesRef = (farmUuid: string) => collection(getChannelRef(farmUuid), 'messages');

export const getFarmUserRef = (farmUuid: string, myUserId: number) =>
    doc(collection(getChannelRef(farmUuid), 'users'), myUserId.toString());

export const getMessageRef = (farmUuid: string, messageUuid: string) =>
    doc(getMessagesRef(farmUuid), messageUuid);

export const getUserLocationsRef = (farmUuid: string) =>
    collection(getFarmRef(farmUuid), 'userLocations');

export const getUserRef = (userId: number) => {
    if (!firebaseApp || !db) {
        throw new Error('Firebase not initialized when connecting to user collection');
    }

    return doc(collection(db, 'users'), userId.toString());
};

export const getUserOnboardingRef = (userId: number) =>
    doc(collection(getUserRef(userId), 'meta'), 'onboarding');

export const getUserHintsRef = (userId: number) =>
    doc(collection(getUserRef(userId), 'meta'), 'hints');

export const getFarmYearRef = (farmUuid: string, year: number) =>
    doc(collection(getFarmRef(farmUuid), 'years'), year.toString());

export const getRemoteConfigStringValue = (key: RemoteConfigKeys) =>
    remoteConfig !== undefined
        ? getString(remoteConfig, key)
        : (defaultRemoteConfig[key] as string);

export const getRemoteConfigNumberValue = (key: RemoteConfigKeys) =>
    remoteConfig !== undefined
        ? getNumber(remoteConfig, key)
        : (defaultRemoteConfig[key] as number);
