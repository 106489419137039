import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm, FarmUser } from '@fieldmargin/webapp-farms';
import { selectCurrentFarm } from 'farms/farms-state';
import FirebaseEventHandler from 'lib/firebase/FirebaseEventHandler';
import { bindActionCreators } from 'redux';
import DemoMessage from 'sidebar/demo/DemoMessage';
import SidebarTitle from 'sidebar/modules/SidebarTitle';
import type { AppState } from 'system/store';
import {
    selectDisplayableFarmUsersAndPendingInvitesSortedByOwnerAndFirstName,
    setFarmUserLocations,
} from 'team/farm-users-state';
import { TutorialTypes } from 'tutorials/TutorialTypes';

import TeamInvite from './TeamInvite';
import TeamInviteButton from './TeamInviteButton';
import TeamList from './TeamList';

interface TeamContainerProps {
    farm: Farm;
    farmUsers: FarmUser[];
    activeTutorial: TutorialTypes;
    setFarmUserLocations: typeof setFarmUserLocations;
}

const TeamContainer = ({
    farm,
    farmUsers,
    setFarmUserLocations,
    activeTutorial,
}: TeamContainerProps) => {
    const { t } = useTranslation();
    useEffect(() => {
        FirebaseEventHandler.subscribeTeamTracking(farm.uuid, setFarmUserLocations);
        return () => {
            FirebaseEventHandler.unsubscribeTeamTracking();
        };
    }, []);

    const tutorialActive = activeTutorial === TutorialTypes.FARM_SHARE;

    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarTitle title={t('team')}>
                    {farmUsers.length > 1 && <TeamInviteButton tutorialActive={tutorialActive} />}
                </SidebarTitle>
                {farm.demo && <DemoMessage />}
            </div>
            <div className="scrolling">
                {farmUsers.length === 1 && (
                    <TeamInvite className={tutorialActive ? 'forefront' : undefined} />
                )}
                <TeamList />
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        farmUsers: selectDisplayableFarmUsersAndPendingInvitesSortedByOwnerAndFirstName(state),
        activeTutorial: state.tutorialsState.activeTutorial,
    }),
    (dispatch) => bindActionCreators({ setFarmUserLocations }, dispatch)
)(TeamContainer);
