import './OperationCompletionCircle.css';

interface OperationCompletionCircleProps {
    dashArray: string;
}

interface ParentProps {
    // This value is between 0-100.
    percentageComplete: number;
}

// See https://www.30secondsofcode.org/css/s/circular-progress-bar/#:~:text=As%20you%20can%20see%2C%20the,indicator%20starts%20at%20the%20top.
// for details on how this works.
const SIZE = 36;
const HALF_SIZE = SIZE / 2;
const STROKE = 6;
const RADIUS = (SIZE - STROKE) / 2;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

const OperationCompletionCircle = ({ dashArray }: OperationCompletionCircleProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={SIZE}
            height={SIZE}
            viewBox={`0 0 ${SIZE} ${SIZE}`}
            fill="none"
            className="OperationCompletionCircle"
        >
            <circle
                cx={HALF_SIZE}
                cy={HALF_SIZE}
                r={RADIUS}
                stroke="#D4D4D4"
                strokeWidth={STROKE}
                fill="none"
            />
            <circle
                cx={HALF_SIZE}
                cy={HALF_SIZE}
                r={RADIUS}
                stroke="#4DA4DA"
                strokeWidth={STROKE}
                strokeDasharray={dashArray}
                fill="none"
            />
        </svg>
    );
};

const prepProps = ({ percentageComplete }: ParentProps) => {
    const completeAmount = (percentageComplete / 100) * CIRCUMFERENCE;
    const dashArray = `${completeAmount} ${CIRCUMFERENCE - completeAmount}`;
    return { dashArray };
};

export default (props: ParentProps) => OperationCompletionCircle(prepProps(props));
