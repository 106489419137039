import type ChatMessage from 'chat/ChatMessage';
import type { DocumentChangeType, DocumentData, QuerySnapshot } from 'firebase/firestore';
import { List } from 'immutable';
import type { FarmUserLocationDTO } from 'team/FarmUserLocation';
import type FarmUserLocation from 'team/FarmUserLocation';
import { deserializeFarmUserLocation } from 'team/FarmUserLocation';

import { messageFromDoc } from './firebase-connection';

export interface FarmChatChange {
    type: DocumentChangeType;
    message?: ChatMessage;
}
export const parseFarmChatSnapshot = (snapshot: QuerySnapshot<DocumentData>) =>
    List<FarmChatChange>(
        snapshot
            .docChanges()
            .reverse()
            .map((change) => {
                return {
                    type: change.type,
                    message: messageFromDoc(change.doc),
                };
            })
    );

export interface TeamTrackingChanges {
    newOrUpdated: Record<string, FarmUserLocation>;
    removed: number[];
}
export const parseTeamTrackingSnapshot = (snapshot: QuerySnapshot<DocumentData>) =>
    snapshot.docChanges().reduce(
        (changeMap, change) => {
            const userId = parseInt(change.doc.id, 10);
            if (change.type === 'removed') {
                changeMap.removed = [...changeMap.removed, userId];
                return changeMap;
            }
            changeMap.newOrUpdated = {
                ...changeMap.newOrUpdated,
                [change.doc.id]: deserializeFarmUserLocation(
                    change.doc.data() as FarmUserLocationDTO
                ),
            };
            return changeMap;
        },
        { newOrUpdated: {}, removed: [] } as TeamTrackingChanges
    );
