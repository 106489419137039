import type { geojson } from '@fieldmargin/webapp-ol-map';
import MapLayerIndex from 'components/maps/model/MapLayerIndex';
import { AttachmentType } from 'farm-editing/attachments';
import {
    EditingType,
    selectEditingAttachmentsByType,
    selectIsSelectingFields,
} from 'farm-editing/farm-editing-state';
import type { FarmMapFeature, FarmMapFieldFeatureProps } from 'farm-map/types';
import { getFieldNameWithArea } from 'fields/Field';
import { selectFieldsWithFullNames } from 'fields/fields-selectors';
import type { Feature, Polygon } from 'geojson';
import { useActiveSection } from 'hooks/useActiveSection';
import { useCurrentItemOutsideRoute } from 'hooks/useCurrentItemOutsideRoute';
import { DEFAULT_COLOUR } from 'lib/colours';
import { notNil, uuidMatches } from 'lib/fp-helpers';
import { selectIsVegetationMapShowing } from 'maps/vegetation/vegetation-maps-util';
import { useAppSelector } from 'system/store';
import { VisibilityOptions } from 'system/types';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import { selectCurrentYear } from 'years/years-state';

import { useItemAttachmentUuids } from './useItemAttachmentUuids';

export type FarmMapFieldFeature = Feature<Polygon, FarmMapFieldFeatureProps>;

/**
 * - Fields are always shown regardless of the active section
 * - Fields that are archived are shown if the show archived filter is true
 * - No fields are shown if the user has turned off visibility
 * - If the user has turned off visibility for a particular field usage, fields within that
 *   will not be shown
 * - No fields are shown if auto boundary is being used
 * - If a field being edited it is not shown.
 * - If an item with the field attached is being viewed it is not shown
 */
export const useFarmMapFields = (features: FarmMapFeature[]): geojson.FeaturesContollerData => {
    const activeSection = useActiveSection();
    const selectedItems = useCurrentItemOutsideRoute();
    const currentField = selectedItems.field;
    const fieldVisibility = useAppSelector(
        (state) => state.farmEditingState.layerVisibility.fieldsVisibility
    );
    const isFieldHealthVisible = useAppSelector(selectIsVegetationMapShowing);
    const hiddenFieldUsages = useAppSelector((state) =>
        state.farmEditingState.layerVisibility.hiddenFieldUsages.toArray()
    );
    const isUsingAutoBoundary = useAppSelector(
        (state) => state.farmEditingState.editingType === EditingType.AUTO_BOUNDARY
    );
    const declutter = useAppSelector((state) => state.fieldsState.declutterFieldNames);
    const editingId = useAppSelector((state) => state.farmEditingState.editingData.id);
    const editingFieldUuids = useAppSelector((state) =>
        selectEditingAttachmentsByType(state, AttachmentType.FIELD)
    ).toArray();
    const itemFieldUuids = useItemAttachmentUuids();
    const isSelectingFields = useAppSelector(selectIsSelectingFields);

    const year = useAppSelector(selectCurrentYear);
    const fields = useAppSelector(selectFieldsWithFullNames);
    const areaUnit = useAppSelector(selectUserAreaMeasurementUnit);

    const fieldFeatures = features
        .filter((feature) => feature.properties.type === 'FIELD')
        .map((feature) => {
            const field = fields?.find(uuidMatches(feature.properties.uuid));
            return field !== undefined
                ? {
                      ...feature,
                      properties: {
                          ...feature.properties,
                          name: getFieldNameWithArea(field, year, areaUnit),
                      },
                  }
                : feature;
        });

    const featureUuids = fieldFeatures.map((feature) => feature.properties.uuid);
    const showLabelsFor = activeSection.main === 'fields' || isSelectingFields ? featureUuids : [];
    const nonFieldItemSelected = Object.entries(selectedItems).some(
        ([key, value]) => key !== 'field' && notNil(value)
    );
    const dimFields = nonFieldItemSelected
        ? featureUuids
        : currentField !== undefined
          ? featureUuids.filter((uuid) => uuid !== currentField.uuid)
          : [];

    const ignoreUuids =
        editingFieldUuids.length > 0
            ? editingFieldUuids.concat(editingId !== undefined ? [editingId] : [])
            : itemFieldUuids;

    if (activeSection.sub === 'sub-fields' && currentField !== undefined) {
        ignoreUuids.push(currentField.uuid);
    }

    return {
        features: getFieldFeatures(fieldFeatures, {
            displayFields: fieldVisibility !== VisibilityOptions.OFF && !isUsingAutoBoundary,
            hiddenFieldUsages,
            ignoreUuids,
            showLabelsFor,
            dimFields,
            fillFields: fieldVisibility === VisibilityOptions.ON && !isFieldHealthVisible,
        }),
        options: { declutter },
    };
};

export const getFieldFeatures = (
    features: FarmMapFeature[],
    opts: {
        displayFields: boolean;
        hiddenFieldUsages: (string | null)[];
        ignoreUuids?: string[];
        showLabelsFor: string[];
        dimFields: string[];
        fillFields: boolean;
    }
): FarmMapFieldFeature[] => {
    if (!opts.displayFields) {
        return [];
    }

    const fieldFeatures = features.filter(
        (feature): feature is FarmMapFieldFeature =>
            feature.properties.type === 'FIELD' &&
            !opts.hiddenFieldUsages.includes(feature.properties.fieldUsageUuid) &&
            !opts.ignoreUuids?.includes(feature.properties.uuid)
    );
    return fieldFeatures.map((feature) =>
        styleProps(
            feature,
            opts.showLabelsFor.includes(feature.properties.uuid),
            opts.dimFields.includes(feature.properties.uuid),
            opts.fillFields
        )
    );
};

const styleProps = (
    feature: FarmMapFieldFeature,
    displayLabel: boolean,
    dim: boolean,
    fillFields: boolean
): FarmMapFieldFeature => ({
    ...feature,
    properties: {
        ...feature.properties,
        colour: feature.properties.colour === '' ? DEFAULT_COLOUR : feature.properties.colour,
        label: displayLabel ? feature.properties.name : undefined,
        pointScale: 6,
        strokeOpacity: dim ? 0.5 : 1,
        strokeWeight: 2,
        fillOpacity: fillFields ? (dim ? 0.2 : 0.4) : 0,
        zIndex: MapLayerIndex.FIELD_POLYGON,
    },
});
