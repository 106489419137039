import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { dismissNotification, selectUnreadMessagesCount } from 'chat/chat-state';
import c from 'classnames';
import { selectCurrentFarm } from 'farms/farms-state';
import { useAppDispatch, useAppSelector } from 'system/store';
import { toggleFarmChat, UIContext } from 'system/ui-state';
import InfoMsg from 'view/InfoMsg';
import Arrow from 'view/svg/Arrow';

import farmChatIcon from './farm-chat.svg';
import TopNavItem from './TopNavItem';

const FarmChatNavItem = () => {
    const dispatch = useAppDispatch();
    const farm = useAppSelector(selectCurrentFarm);
    const farmChatOpen = useAppSelector((state) => state.uiState.farmChatOpen);
    const unreadMessagesCount = useAppSelector(selectUnreadMessagesCount);
    const chatNotification = useAppSelector((state) => state.chatState.notification);
    const { t } = useTranslation();

    const { infoOpen } = useContext(UIContext);

    const handleClick = () => {
        if (!farm.plan.blocked) {
            dispatch(toggleFarmChat());
            if (chatNotification !== undefined) {
                dispatch(dismissNotification(chatNotification));
            }
        }
    };

    return (
        <TopNavItem
            className={c('relative', { forefront: infoOpen })}
            onClick={handleClick}
            data-farmchat
        >
            {infoOpen && (
                <InfoMsg className="top-4 -left-36 flex-col">
                    <Arrow className="w-8 rotate-90 flip-x relative left-8" />
                    {t('info_farm_chat')}
                </InfoMsg>
            )}
            {!farmChatOpen && (
                <div className="absolute top-3 left-7 bg-red-600 rounded text-xs font-bold px-1 text-white">
                    {unreadMessagesCount}
                </div>
            )}
            <img
                src={farmChatIcon}
                alt="Two speech bubbles overlapping with the left bubble in the forefront"
            />
            <div className="sr-only">{t('farm_chat_title')}</div>
        </TopNavItem>
    );
};

export default FarmChatNavItem;
