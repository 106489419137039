import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MapPosition from 'components/maps/model/MapPosition';
import { setMapView } from 'farm-editing/farm-editing-state';
import { useDateFormat } from 'hooks/useDateFormat';
import { bindActionCreators } from 'redux';
import InitialBadge from 'sidebar/modules/initial-badge/InitialBadge';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type Vehicle from 'vehicle-tracking/Vehicle';

interface VehicleItemProps {
    // From parents
    vehicle: Vehicle;
    active?: boolean;

    // From redux
    setMapView: typeof setMapView;
}

const VehicleItem = ({ vehicle, active = false, setMapView }: VehicleItemProps) => {
    const { t } = useTranslation();
    const { formatDistanceToNow } = useDateFormat();
    const handleViewClick = () => {
        setMapView({
            type: 'position',
            payload: MapPosition({
                centre: vehicle.location.coordinates,
                zoom: 18,
            }),
        });
    };
    return (
        <SidebarModule editing className="flex items-center">
            <InitialBadge initial={vehicle.displayName} inactive={!active} className="mr-4" />
            <div className="w-2/3 mr-8 flex-grow">
                <span className="block mb-2 truncate">{vehicle.vehicleName}</span>
                <span className="block text-gray-500 text-xxs font-medium">
                    {t('vehicle_last_updated', {
                        sprintf: [formatDistanceToNow(vehicle.timestamp)],
                    })}
                </span>
            </div>
            <button className="bare-btn underline text-xs focus" onClick={handleViewClick}>
                {t('operation_details_view_on_map')}
            </button>
        </SidebarModule>
    );
};

export default connect(
    () => ({}),
    (dispatch) => bindActionCreators({ setMapView }, dispatch)
)(VehicleItem);
