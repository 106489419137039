import type { CSSProperties, PropsWithChildren } from 'react';
import { clsx } from 'clsx';

import styles from './Icon.module.css';

interface IconProps extends PropsWithChildren {
    className?: string;
    style?: CSSProperties;
    variant?: '16' | '18';
}

function Icon({ children, className, variant = '16', style }: Readonly<IconProps>) {
    return (
        <span
            className={clsx(styles.icon, className)}
            aria-hidden="true"
            role="img"
            style={{ ...style, ['--icon-size']: `${variant}px` } as CSSProperties}
        >
            {children}
        </span>
    );
}

export { Icon };
