import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import classNames from 'classnames';
import icon from 'components/icons/icon';
import SidebarTitle from 'sidebar/modules/SidebarTitle';
import { useAppSelector } from 'system/store';
import type { Nullable } from 'system/types';
import type { TutorialTypeSchema } from 'tutorials/TutorialTypes';
import { TutorialTypes } from 'tutorials/TutorialTypes';
import FarmLink from 'view/FarmLink';

interface NoteListTitleProps {
    // From redux
    activeTutorial: Nullable<TutorialTypeSchema>;
}

const NoteListTitle = ({ activeTutorial }: NoteListTitleProps) => {
    const { t } = useTranslation();
    const tutorialTypes: Array<TutorialTypeSchema> = [
        TutorialTypes.FIELD_JOB,
        TutorialTypes.INPUTS,
    ];
    const isActiveTutorial = activeTutorial !== null && tutorialTypes.includes(activeTutorial);

    return (
        <SidebarTitle title={t('dashboard_activity')}>
            <Button
                as={FarmLink}
                variant="outline"
                to={`/notes/new`}
                className={classNames('flex items-center gap-2 mr-3', {
                    forefront: activeTutorial === TutorialTypes.SCOUTING,
                })}
            >
                {icon('add', 'blue')} {t('activity_log_title')}
            </Button>
            <Button
                as={FarmLink}
                to={`/operations/new`}
                className={classNames('flex items-center gap-2', {
                    forefront: isActiveTutorial,
                })}
            >
                {icon('add', 'white')} {t('field_job')}
            </Button>
        </SidebarTitle>
    );
};

export default () => {
    const activeTutorial = useAppSelector((state) => state.tutorialsState.activeTutorial);
    return <NoteListTitle activeTutorial={activeTutorial} />;
};
