import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import c from 'classnames';
import { Set } from 'immutable';
import { useFieldState } from 'informed';
import EditButton from 'sidebar/modules/common/EditButton';
import SidebarModule from 'sidebar/modules/SidebarModule';
import TaggedUsersField from 'sidebar/modules/tagged-users/TaggedUsersField';
import UserTagList from 'sidebar/modules/tagged-users/UserTagList';

interface NewOperationTaggedUsersProps {
    field: string;
    farmUsers: FarmUser[];
}

const NewOperationTaggedUsers = ({ field, farmUsers }: NewOperationTaggedUsersProps) => {
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const fieldState = useFieldState<Set<FarmUser>>(field);
    return (
        <div className="NewOperationTaggedUsers relative">
            <SidebarModule noBorder>
                {!editing && <EditButton setEditing={setEditing} />}
                <h4 className="mb-0">{t('activity_log_tagged')}</h4>
            </SidebarModule>
            <SidebarModule className={c({ 'pt-0': !editing })} editing={editing}>
                {!editing && (
                    <UserTagList taggedUsers={fieldState.value ? fieldState.value : Set()} />
                )}
                <div style={{ display: editing ? 'block' : 'none' }}>
                    <TaggedUsersField farmUsers={farmUsers} />
                </div>
                {editing && (
                    <div className="EditableActions">
                        <div className="actions">
                            <Button onClick={() => setEditing(false)}>{t('other_done')}</Button>
                        </div>
                    </div>
                )}
            </SidebarModule>
        </div>
    );
};

export default NewOperationTaggedUsers;
