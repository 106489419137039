import type { GeoPoint } from '@fieldmargin/webapp-geo';
import { deserialize, Projection } from '@fieldmargin/webapp-geo';
import { List, Record } from 'immutable';

import type { MetricDTO } from './Metric';
import type Metric from './Metric';
import { deserializeMetric } from './Metric';
import type { ReadingDTO } from './Reading';
import type Reading from './Reading';
import { deserializeReading } from './Reading';

class SensorSummary extends Record({
    id: '',
    farmIntegrationUuid: '',
    upstreamId: '',
    upstreamName: '',
    upstreamLocation: null as GeoPoint | null,
    overrideName: null as string | null,
    overrideLocation: null as GeoPoint | null,
    active: false,
    latestReadings: null as List<Reading> | null,
    metrics: null as List<Metric> | null,
    updatedAt: null as Date | null,
}) {
    getName() {
        return this.overrideName || this.upstreamName;
    }
    getLocation() {
        return this.overrideLocation ? this.overrideLocation : (this.upstreamLocation as GeoPoint);
    }
}

const parseLocation = (location) =>
    location ? (deserialize(location, Projection.LNG_LAT) as GeoPoint) : null;

export interface SensorSummaryDTO {
    id: string;
    farmIntegrationUUID: string;
    upstreamId: string;
    upstreamLocation: any; // GeoPoint or null
    overrideName: string | null;
    overrideLocation: any; // GeoPoint or null
    latestReadings: MetricDTO[];
    updatedAt: number | null;
}

export const deserializeSensorSummary = (json: SensorSummaryDTO) => {
    return new SensorSummary({
        ...json,
        farmIntegrationUuid: json.farmIntegrationUUID,
        upstreamLocation: parseLocation(json.upstreamLocation),
        overrideLocation: parseLocation(json.overrideLocation),
        latestReadings: List(
            json.latestReadings.map((metric) =>
                deserializeReading(
                    {
                        metricId: metric.id,
                        value: metric.value,
                    } as ReadingDTO,
                    metric
                )
            )
        ),
        metrics: List(json.latestReadings.map(deserializeMetric)),
        updatedAt: json.updatedAt ? new Date(json.updatedAt) : null,
    });
};

export default SensorSummary;
