import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import LoadableContent from '@fieldmargin/webapp-styling/components/loadable-content';
import { selectCurrentFarm } from 'farms/farms-state';
import type Field from 'fields/Field';
import type { List } from 'immutable';
import type { FieldInputReportSummary } from 'inputs/InputReportSummary';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type FullOperation from 'operations/FullOperation';
import { loadOperationsRecordings } from 'operations/operations-state';
import { bindActionCreators } from 'redux';
import { getFieldsYearInputSummaryApi } from 'reporting/reporting-api';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import ErrorMessage from 'view/ErrorMessage';

import { loadFieldInputReport } from './field-reporting-utils';
import FieldInputTable from './FieldInputTable';

interface FieldInputReportingProps {
    // From parent
    field: Field;
    subFields: List<Field>;
    operations: List<FullOperation>;
    filter: string;

    // From redux
    farm: Farm;
    year: number;
    areaMeasurementUnit: MeasurementUnit;
    loadOperationsRecordings: typeof loadOperationsRecordings;
}

const FieldInputReporting = ({
    field,
    subFields,
    operations,
    filter,
    farm,
    year,
    areaMeasurementUnit,
    loadOperationsRecordings,
}: FieldInputReportingProps) => {
    const { t } = useTranslation();
    const [report, setReport] = useState<FieldInputReportSummary | null>(null);
    const { pending, error, setPromise } = usePromise<FieldInputReportSummary[]>(
        (inputReportSummaries) => {
            inputReportSummaries.length > 0 && setReport(inputReportSummaries[0]);
        }
    );

    useEffect(() => {
        setPromise(
            loadFieldInputReport(
                farm,
                year,
                field,
                filter === 'all' ? null : filter.toUpperCase(),
                operations,
                loadOperationsRecordings,
                areaMeasurementUnit
            )
        );
    }, [field]);

    useEffect(() => {
        setPromise(
            getFieldsYearInputSummaryApi(
                farm.uuid,
                [field.uuid],
                year,
                filter === 'all' ? null : filter.toUpperCase(),
                areaMeasurementUnit
            )
        );
    }, [filter, areaMeasurementUnit]);

    if (!pending && error) {
        return (
            <div className="FieldInputReporting">
                <ErrorMessage className="px-5 mt-5">{t('something_went_wrong')}</ErrorMessage>
            </div>
        );
    }

    return (
        <div className="FieldInputReporting">
            <LoadableContent
                loading={report === null || pending}
                delay={300}
                fallback={
                    <div className="p-5">
                        <p>
                            <em>{t('loading')}</em>
                        </p>
                    </div>
                }
            >
                {report === null ? null : report && report.inputs.length === 0 ? (
                    <p className="p-5">
                        {t(
                            filter === 'todo'
                                ? 'field_reporting_input_empty_incomplete'
                                : 'field_reporting_input_empty_complete'
                        )}
                    </p>
                ) : (
                    <div className="p-5">
                        <FieldInputTable
                            farm={farm}
                            fieldUuids={subFields
                                .map((f) => f.uuid)
                                .unshift(field.uuid)
                                .toSet()}
                            fieldInputReportSummary={report}
                            operations={operations}
                        />
                    </div>
                )}
            </LoadableContent>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        year: state.yearsState.currentYear,
        areaMeasurementUnit: selectUserAreaMeasurementUnit(state),
    }),
    (dispatch) => bindActionCreators({ loadOperationsRecordings }, dispatch)
)(FieldInputReporting);
