import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type CropType from 'fields/CropType';
import {
    deserializePesticideCheck,
    type PesticideCheckFieldDTO,
    type PesticideCheckRecordingDTO,
    type PesticideCheckResponseDTO,
} from 'operations/sidebar/pesticide-checking/PesticideCheck';
import { identity, replace, useWith as ramdaUseWith } from 'ramda';
import type { CodedItem } from 'system/types';

import type { PesticideData } from './Pesticide';

export const searchCropTypes = (farmUuid: string, name: string) =>
    authorizedRequest<CropType[]>({
        url: `/pesticide-db/farms/${farmUuid}/crops/search`,
        params: { name },
    });

export const searchPesticideApi = ramdaUseWith(
    (farmUuid: string, farmCountry: string, name: string) =>
        name === ''
            ? Promise.resolve<CodedItem[]>([])
            : authorizedRequest<CodedItem[]>({
                  url: `/pesticide-db/farms/${farmUuid}/products/search/country/${farmCountry}`,
                  params: { name },
              }),
    [identity, identity, replace(/\.|\/|\\|%/g, '')]
);

export const pagePesitcideApi = (farmUuid: string, farmCountry: string, page: number) =>
    authorizedRequest<CodedItem[]>({
        url: `/pesticide-db/farms/${farmUuid}/products/search/country/${farmCountry}/page/${page}`,
    });

export const getPesticideData = (farmUuid: string, pesticideCode: string) =>
    authorizedRequest<PesticideData>({
        url: `/pesticide-db/farms/${farmUuid}/products/${pesticideCode}`,
    });

export const getCropCheckData = (
    farmUuid: string,
    year: number,
    recordings: PesticideCheckRecordingDTO[],
    fields: PesticideCheckFieldDTO[],
    cropPrincipleCodes: string[],
    operationUuid?: string
) =>
    authorizedRequest<PesticideCheckResponseDTO>({
        url: `/crop-check/farms/${farmUuid}/years/${year}/operations/crop-check`,
        method: 'POST',
        data: {
            recordings,
            fields,
            cropPrincipleCodes,
            operationUuid,
        },
    }).then(deserializePesticideCheck);
