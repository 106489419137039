import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import { flatten, values } from 'ramda';
import type { ChangeEventVoidFunction, CodedItem, SingleParamVoidFunction } from 'system/types';
import { trackEvent } from 'utils/trackEvent';
import NativeSelectFormField from 'view/form/native/NativeSelectFormField';

interface PesticideCheckCropFilterProps {
    farm: Farm;
    crops: Record<string, CodedItem[]>;
    setCrop: SingleParamVoidFunction<CodedItem | undefined>;
    selected?: CodedItem;
    includeNoValue?: boolean;
    disabled?: boolean;
}

const PesticideCheckCropFilter = ({
    farm,
    crops,
    setCrop,
    selected,
    includeNoValue = false,
    disabled = false,
}: PesticideCheckCropFilterProps) => {
    const { t } = useTranslation();
    const cropItems = flatten(values(crops));
    const handleChange: ChangeEventVoidFunction<HTMLSelectElement> = (e) => {
        trackEvent('Pesticide check crop filter used', { farmUuid: farm.uuid });
        setCrop(cropItems.find((crop) => crop.code === e.target.value));
    };
    return (
        <NativeSelectFormField disabled={disabled} value={selected?.code} onChange={handleChange}>
            {includeNoValue && <option value={undefined}>{t('none_selected')}</option>}
            {cropItems.length > 0 &&
                cropItems.map((crop) => (
                    <option key={crop.code} value={crop.code}>
                        {crop.name}
                    </option>
                ))}
        </NativeSelectFormField>
    );
};

export default PesticideCheckCropFilter;
