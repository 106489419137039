import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useFileDownload } from 'hooks/useFileDownload';
import { getManualSensorDownloadLink } from 'sensors/manual/manual-sensors-api';
import type ManualSensor from 'sensors/manual/ManualSensor';
import SidebarHeaderMenuItem from 'sidebar/modules/header/SidebarHeaderMenuItem';
import DownloadIcon from 'view/svg/DownloadIcon';

interface ManualSensorExportProps {
    farm: Farm;
    manualSensor: ManualSensor;
    fileType: 'CSV' | 'XLS';
}

const ManualSensorExport = ({ farm, manualSensor, fileType }: ManualSensorExportProps) => {
    const { t } = useTranslation();
    const { pending, fetchFile } = useFileDownload();
    return (
        <SidebarHeaderMenuItem>
            <button
                onClick={() =>
                    fetchFile(
                        getManualSensorDownloadLink(farm.uuid, manualSensor.id as string, fileType),
                        `${manualSensor.name}.${fileType.toLowerCase()}`
                    )
                }
                disabled={pending}
                className="bare-btn w-full whitespace-normal text-left"
            >
                <DownloadIcon className="h-6 w-5 mr-3" fill="#2c3e50" />{' '}
                {t(pending ? 'exporting' : 'export')} {fileType}
            </button>
        </SidebarHeaderMenuItem>
    );
};

export default ManualSensorExport;
