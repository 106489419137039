import { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import Button from '@fieldmargin/webapp-styling/components/button';
import { bindActionCreators } from 'redux';
import { changeModal } from 'system/ui-state';

import './FarmChatInvite.scss';

interface FarmChatInviteProps {
    changeModal: typeof changeModal;
}

const FarmChatInvite = memo(({ changeModal }: FarmChatInviteProps) => {
    const handleInvite = useCallback(() => {
        changeModal({
            name: 'settings',
            props: {
                initialSubTab: 'farm-users',
            },
        });
    }, [changeModal]);
    return (
        <div className="farm-chat-invite">
            <h2>Get your farm talking</h2>
            <p>
                This is a place for everyone on your farm to chat and share information. Use the
                comment bar at the bottom to communicate with your team, attach photos.
            </p>
            <p>Use the “Invite” button to add your team</p>

            <Button className="invite-link" onClick={handleInvite}>
                Invite
            </Button>
        </div>
    );
});

export default connect(
    () => ({}),
    (dispatch) =>
        bindActionCreators(
            {
                changeModal,
            },
            dispatch
        )
)(FarmChatInvite);
