import React from 'react';
import { createRoot } from 'react-dom/client';
import type { Extent, GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { extentFromGeo, Projection, reproject } from '@fieldmargin/webapp-geo';
import type { Controller } from '@fieldmargin/webapp-ol-map';
import type Field from 'fields/Field';
import type FieldUsage from 'fields/FieldUsage';
import type { List } from 'immutable';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import type OLMap from 'ol/Map';
import Overlay from 'ol/Overlay';

import FieldTooltip from './FieldTooltip';

export type GeoFeatureHoverFn = (id: string | number | undefined) => void;

export interface FieldToolTipControllerProps {
    field: Field | null;
    areaMeasurementUnit: MeasurementUnit;
    isSelectingFieldUsageFields: boolean;
    fieldUsages: List<FieldUsage>;
    previousUsages: List<FieldUsage>;
    currentYear: number;
}

class FieldTooltipController implements Controller<FieldToolTipControllerProps> {
    private map: OLMap;
    private onGeoFeatureHover: GeoFeatureHoverFn;
    private lastHoveredGeoFeatureId?: string | number | undefined;
    private lastTooltipOverlay?: Overlay;

    constructor(onGeoFeatureHover: GeoFeatureHoverFn) {
        this.onGeoFeatureHover = onGeoFeatureHover;

        this.handleHoverEvent = this.handleHoverEvent.bind(this);
        this.removeLastTooltipOverlay = this.removeLastTooltipOverlay.bind(this);
    }

    build(map: OLMap) {
        this.map = map;
    }

    update(props: FieldToolTipControllerProps) {
        const {
            field,
            isSelectingFieldUsageFields,
            areaMeasurementUnit,
            fieldUsages,
            previousUsages,
            currentYear,
        } = props;
        this.setupHoverEventHandler(props);

        if (isSelectingFieldUsageFields && field) {
            if (this.lastTooltipOverlay && this.lastTooltipOverlay.getId() !== field.uuid) {
                // In this case the cursor has moved from one field to another.
                this.removeLastTooltipOverlay();
            } else if (this.lastTooltipOverlay && this.lastTooltipOverlay.getId() === field.uuid) {
                // The current tooltip is already showing for this field.
                return;
            }

            const el = document.createElement('div');
            el.classList.add('ol-field-tooltip');
            const root = createRoot(el);
            root.render(
                React.createElement(FieldTooltip, {
                    field,
                    areaMeasurementUnit,
                    fieldUsages,
                    previousUsages,
                    currentYear,
                })
            );
            // We need the extent for the field to enable us to position the tooltip correctly.
            const extent = extentFromGeo(
                reproject(field.geoJson as GeoFeatureCollection, Projection.WEB_MERCATOR)
            ) as Extent;

            const overlay = new Overlay({
                id: field.uuid,
                element: el,
                // This positions the tooltip in the middle of the feature along the X axis
                // and just below the feature along the Y axis.
                offset: [0, 90],
                position: [(extent.minX + extent.maxX) / 2, extent.minY],
                positioning: 'center-center',
            });
            this.map.addOverlay(overlay);
            this.lastTooltipOverlay = overlay;
        } else {
            // In this case the user has stopped selecting fields for usage or moved the cursor
            // out of a field.
            this.removeLastTooltipOverlay();
        }
    }

    /**
     * This function is called on `pointermove` and works out whether or not there is a feature
     * at the given position.
     */
    private handleHoverEvent(event) {
        if (event.dragging) {
            return;
        }

        if (this.onGeoFeatureHover) {
            let featureId: string | number | undefined = undefined;
            const feature = this.map.forEachFeatureAtPixel(event.pixel, (feature) => feature, {
                hitTolerance: 10,
            });
            if (feature && feature.getId()) {
                featureId = feature.getId();
            }
            if (featureId !== this.lastHoveredGeoFeatureId) {
                this.onGeoFeatureHover(featureId);
                this.lastHoveredGeoFeatureId = featureId;
            }
        }
    }

    private removeLastTooltipOverlay() {
        if (this.map) {
            if (this.lastTooltipOverlay !== undefined) {
                this.map.removeOverlay(this.lastTooltipOverlay);
                this.lastTooltipOverlay = undefined;
            }
        }
    }

    /**
     * Adds or removes the necessary events for showing & hiding the tooltip.
     * `pointermove` event is used to track the position of the cursor
     * `mouseout` ensures that tooltips are hidden when the user moves the cursor off the map
     */
    private setupHoverEventHandler(props: FieldToolTipControllerProps) {
        if (props.isSelectingFieldUsageFields) {
            this.map.on('pointermove', this.handleHoverEvent);
            this.map.getViewport().addEventListener('mouseout', this.removeLastTooltipOverlay);
        } else {
            this.map.un('pointermove', this.handleHoverEvent);
            this.map.getViewport().removeEventListener('mouseout', this.removeLastTooltipOverlay);
        }
    }
}

export default FieldTooltipController;
