import { useState } from 'react';
import { includes } from 'lodash';
import Modal from 'modal/Modal';

import type { OnboardingTutorial } from './onboarding-api';
import { useSaveOnboardingTutorials } from './onboarding-hooks';
import { useOnboarding } from './onboarding-state';
import OnboardingEndChoices from './OnboardingEndChoices';
import OnboardingEndDone from './OnboardingEndDone';

interface OnboardingEndProps {
    onSkip: VoidFunction;
}

const OnboardingEnd = ({ onSkip }: OnboardingEndProps) => {
    const { skipOnboarding } = useOnboarding();
    const [step, setStep] = useState('choices');
    const [selectedTutorials, setSelectedTutorials] = useState<OnboardingTutorial[]>([]);

    const saveOnboardingTutorials = useSaveOnboardingTutorials(skipOnboarding);

    const toggleTutorial = (tutorial: OnboardingTutorial) => () =>
        setSelectedTutorials(
            includes(selectedTutorials, tutorial)
                ? selectedTutorials.filter((i) => i !== tutorial)
                : [...selectedTutorials, tutorial]
        );

    const handleNext = async () => {
        if (selectedTutorials.length > 0) {
            return saveOnboardingTutorials(selectedTutorials);
        } else {
            setStep('done');
        }
    };

    return (
        <Modal onClose={onSkip}>
            <div className="bg-white p-6 text-center rounded" style={{ width: 700 }}>
                {step === 'choices' && (
                    <OnboardingEndChoices
                        onSkip={() => setStep('done')}
                        onNext={handleNext}
                        selectedTutorials={selectedTutorials}
                        toggleTutorial={toggleTutorial}
                    />
                )}
                {step === 'done' && <OnboardingEndDone />}
            </div>
        </Modal>
    );
};

export default OnboardingEnd;
