import type { UIEvent } from 'react';
import { useEffect, useState } from 'react';
import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { usePromise } from '@fieldmargin/webapp-state';
import type { SingleParamVoidFunction } from 'system/types';

interface PagedResponse<T> {
    content: T[];
    totalPages: number;
}

/**
 * This handles loading data for fieldmargin paging requests.
 * It will continue to request data until all pages have been loaded
 * and will then return the items as an array.
 * Note: The URL provided should not include the `page` parameter.
 */
export const handlePagingRequest = async <T>(url: string) => {
    let items: T[] = [];
    let pageCount = 0;
    let total = 1;
    while (pageCount < total) {
        const { content, totalPages } = await authorizedRequest<PagedResponse<T>>({
            url: `${url}/page/${pageCount}/`,
        });
        items = items.concat(content);
        pageCount = pageCount + 1;
        total = totalPages;
    }

    return items;
};

export const useScrollingPagingState = <Item>(
    fn: (page: number) => Promise<Item[]>
): [boolean, boolean, Item[] | undefined, SingleParamVoidFunction<UIEvent>] => {
    const [complete, setComplete] = useState(false);
    const [items, setItems] = useState<Item[]>();
    const [page, setPage] = useState(0);

    const { pending, error, setPromise } = usePromise<Item[]>((results) => {
        if (results.length === 0) {
            setComplete(true);
        } else {
            setItems(items !== undefined ? items.concat(results) : results);
        }
    });

    useEffect(() => {
        setPromise(fn(page));
    }, []);

    const onScroll = (e: UIEvent) => {
        if (complete) {
            return;
        }

        const target = e.target as Element;
        // scrollTop will never equal scrollHeight without including the clientHeight
        const totalScroll = target.clientHeight + target.scrollTop;
        const nearBottom = target.scrollHeight - totalScroll < 200;

        if (nearBottom && !pending) {
            setPage(page + 1);
            setPromise(fn(page + 1));
        }
    };

    return [pending, error, items, onScroll];
};
