import { filterMap } from 'lib/fp-helpers';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';
import { selectCurrentYear } from 'years/years-state';

import type Note from './Note';

export const selectNote = createSelector(
    (_state: AppState, noteUuid: string) => noteUuid,
    (state: AppState) => state.notesState.notes,
    (noteUuid, notes) => notes?.get(noteUuid)
);

export const selectCurrentYearNotesForField = createSelector(
    (_state: AppState, fieldUuid?: string) => fieldUuid,
    (state: AppState) => state.notesState.notes,
    (state: AppState) => selectCurrentYear(state),
    (fieldUuid, notes, year) =>
        notes === null
            ? new Map<string, Note>()
            : filterMap(
                  notes,
                  (note) => note.year === year && note.fieldUuids.includes(fieldUuid ?? '')
              )
);
