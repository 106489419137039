import { useTranslation } from 'react-i18next';
import { OperationRecord } from 'components/operation-record';
import type Input from 'inputs/Input';
import { joinItemNames } from 'lib/util/text';

interface OperationRecordingCardSprayInfoProps {
    input: Input;
}

const OperationRecordingCardSprayInfo = ({ input }: OperationRecordingCardSprayInfoProps) => {
    const { t } = useTranslation();
    return (
        input.pesticideData !== undefined && (
            <OperationRecord.Table>
                <OperationRecord.TableBody>
                    {input.subType !== undefined && (
                        <OperationRecord.TableRow>
                            <OperationRecord.TableHeaderCell>
                                {t('type')}
                            </OperationRecord.TableHeaderCell>
                            <OperationRecord.TableCell>{input.subType}</OperationRecord.TableCell>
                        </OperationRecord.TableRow>
                    )}
                    <OperationRecord.TableRow>
                        <OperationRecord.TableHeaderCell>
                            {t('input_reg_number')}
                        </OperationRecord.TableHeaderCell>
                        <OperationRecord.TableCell>
                            {input.pesticideData.registrationNumber}
                        </OperationRecord.TableCell>
                    </OperationRecord.TableRow>

                    <OperationRecord.TableRow>
                        <OperationRecord.TableHeaderCell>
                            {t('label_active_ingredients')}
                        </OperationRecord.TableHeaderCell>
                        <OperationRecord.TableCell>
                            {joinItemNames(input.pesticideData.actives)}
                        </OperationRecord.TableCell>
                    </OperationRecord.TableRow>
                </OperationRecord.TableBody>
            </OperationRecord.Table>
        )
    );
};

export default OperationRecordingCardSprayInfo;
