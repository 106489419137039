import type { List } from 'immutable';
import { Set } from 'immutable';
import { areaAsSqm, formatArea } from 'lib/geo/maths';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { prefersImperialUnits } from 'lib/MeasurementUnit';
import {
    convertRateForStorage,
    convertTemperatureForStorage,
    convertUserIdForStorage,
    convertWindSpeedForStorage,
} from 'lib/storage-conversion';
import { isString } from 'lodash';
import type { FullOperationField, OperationField } from 'operations/OperationField';
import { converter, hectaresRateToAcresRate } from 'utils/conversion';

import type { EditOperationFieldsFormValues } from './EditOperationFields';

export const getInitialFormValues = (
    operationFields: List<FullOperationField>,
    areaMeasurementUnit: MeasurementUnit,
    lengthMeasurementUnit: MeasurementUnit,
    temperatureUnit: string
): EditOperationFieldsFormValues =>
    operationFields.reduce((map, operationField) => {
        map[operationField.field.uuid] = {
            workArea: formatArea(operationField.areaSqm, areaMeasurementUnit),
            yieldRate: prefersImperialUnits(areaMeasurementUnit)
                ? hectaresRateToAcresRate(operationField.yieldRateHa ?? 0)
                : operationField.yieldRateHa,
            yieldTotal: operationField.yieldTotal,
            completedDate: operationField.completedDate,
            completedByUserId: operationField.completedByUserId?.toString(),
            startDate: operationField.startDate,
            windSpeed:
                operationField.windSpeed !== undefined
                    ? prefersImperialUnits(lengthMeasurementUnit)
                        ? converter.convertKilometresPerHourToMilesPerHour(operationField.windSpeed)
                        : operationField.windSpeed
                    : undefined,
            windDirection: operationField.windDirection?.toString(),
            temperature:
                operationField.temperature !== undefined
                    ? temperatureUnit === 'FAHRENHEIT'
                        ? converter.convertCelsiusToFahrenheit(operationField.temperature)
                        : operationField.temperature
                    : undefined,
            weatherNote: operationField.weatherNote?.toString(),
        };
        return map;
    }, {} as EditOperationFieldsFormValues);

export const sanitiseFormValues = (
    values: EditOperationFieldsFormValues,
    removed: Set<string>,
    areaMeasurementUnit: MeasurementUnit,
    lengthMeasurementUnit: MeasurementUnit,
    temperatureUnitL: string
): Set<OperationField> =>
    Set<OperationField>(
        Object.keys(values)
            .filter((fieldUuid) => !removed.contains(fieldUuid))
            .map((fieldUuid) => {
                const fieldValues = values[fieldUuid];
                return {
                    fieldUuid,
                    areaSqm: areaAsSqm(fieldValues.workArea, areaMeasurementUnit),
                    yieldRateHa: convertRateForStorage(fieldValues.yieldRate, areaMeasurementUnit),
                    yieldTotal: fieldValues.yieldTotal,
                    completedDate: isString(fieldValues.completedDate)
                        ? undefined
                        : fieldValues.completedDate,
                    completedByUserId: convertUserIdForStorage(fieldValues.completedByUserId),
                    startDate: fieldValues.startDate,
                    windSpeed: convertWindSpeedForStorage(
                        fieldValues.windSpeed,
                        lengthMeasurementUnit
                    ),
                    windDirection: fieldValues.windDirection,
                    temperature: convertTemperatureForStorage(
                        fieldValues.temperature,
                        temperatureUnitL
                    ),
                    weatherNote: fieldValues.weatherNote,
                };
            })
    );

export const shouldTrackCompletion = (
    previousFields: Set<OperationField>,
    fields: Set<OperationField>
) => {
    const previousCompletedFields = previousFields
        .filter((opField) => opField.completedDate !== undefined)
        .map((opField) => opField.fieldUuid);

    return (
        fields.filter(
            (opField) =>
                opField.completedDate !== undefined &&
                !previousCompletedFields.contains(opField.fieldUuid)
        ).size > 0
    );
};
