import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { usePromise, useToggle } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import type { InputCostDTO } from 'inputs/inputs-api';
import { getInputCosts, saveInputCosts } from 'inputs/inputs-api';
import { selectActiveInputs } from 'inputs/inputs-state';
import { getUuid } from 'lib/fp-helpers';
import { numberToStringOrEmpty } from 'lib/util/text';
import { useAppSelector } from 'system/store';
import { trackEvent } from 'utils/trackEvent';
import ErrorMessage from 'view/ErrorMessage';

import { useInputSummaryContext } from '../useInputSummaryContext';

import { formValuesToCostDTOs, inputToReportSummary } from './edit-costs-utils';
import EditInputCostsForm from './EditInputCostsForm';

interface FormValues {
    // Input UUID to values
    [k: string]: {
        unit: number;
        total: number;
    };
}

const EditInputCosts = () => {
    const { t } = useTranslation();
    const { farm, reportData, year, onSaveInputCost } = useInputSummaryContext();
    const navigate = useNavigate();
    const returnTo =
        useAppSelector((state) => state.uiState.returnTo) ??
        `/farms/${farm.uuid}/reporting/input-summary`;
    const inputs = useAppSelector(selectActiveInputs).toArray();
    const [includeAll, toggleIncludeAll] = useToggle(false);
    const [costs, setCosts] = useState<Record<string, string>>();
    const { pending, error, setPromise } = usePromise<InputCostDTO[]>((data) =>
        setCosts(
            data.reduce(
                (map, datum) => ({
                    ...map,
                    [datum.operationInputUUID]: numberToStringOrEmpty(datum.unitCost),
                }),
                {}
            )
        )
    );

    useEffect(() => {
        if (reportData !== undefined) setPromise(getInputCosts(farm.uuid, year));
    }, [reportData]);

    if (reportData === undefined || farm.plan.reportingLevel !== 'ALL_REPORTING') {
        return null;
    }

    if (error) {
        return (
            <div>
                <Button as={Link} to={returnTo} className="absolute top-0 left-0" variant="subtle">
                    {'< Back'}
                </Button>
                <h1 className="uppercase text-center text-2xl">{t('label_edit_costs')}</h1>
                <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>
            </div>
        );
    }

    if (pending || costs === undefined) {
        return (
            <div>
                <Button
                    as={Link}
                    to={returnTo}
                    className="absolute top-0 left-0 flex items-center gap-2"
                    variant="subtle"
                >
                    {icon('back')} {t('back')}
                </Button>
                <h1 className="uppercase text-center text-2xl">{t('label_edit_costs')}</h1>
                <p>
                    <em>{t('loading')}</em>
                </p>
            </div>
        );
    }

    const handleSubmit = (values: FormValues) =>
        saveInputCosts(farm.uuid, year, formValuesToCostDTOs(values, year));

    const postSave = () => {
        trackEvent('Input costs set', { farmUuid: farm.uuid, year });
        navigate(returnTo, { state: { ignoreUnsavedChanges: true } });
        onSaveInputCost();
    };

    const reportDataInputs = reportData.map(getUuid);
    const allReportData = includeAll
        ? reportData.concat(
              inputs
                  .filter((input) => !reportDataInputs.includes(input.uuid))
                  .map((input) => inputToReportSummary(input, costs))
          )
        : reportData;

    return (
        <EditInputCostsForm
            farm={farm}
            includeAll={includeAll}
            toggleIncludeAll={toggleIncludeAll}
            reportData={allReportData}
            costs={costs}
            onSubmit={handleSubmit}
            postSave={postSave}
            returnTo={returnTo}
        />
    );
};

export default EditInputCosts;
