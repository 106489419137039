import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import EmptyState from 'sidebar/modules/EmptyState';

import activityEmpty from './activity-empty.png';

interface NoteListEmptyMessageProps {
    hasAnyActivities: boolean;
}

/**
 * Shows the empty state message for the note list.
 * Two possible messages are shown:
 * 	- no activities at all
 *  - no activities for the selected year
 */
const NoteListEmptyMessage = ({ hasAnyActivities }: NoteListEmptyMessageProps) => {
    const { t } = useTranslation();
    return hasAnyActivities ? (
        <EmptyState header={t('note_list_empty_year_title_web')} image={activityEmpty}>
            <p>{t('note_list_empty_year_web')}</p>
            <p>{t('note_list_empty_year_web2')}</p>
        </EmptyState>
    ) : (
        <EmptyState header={t('note_list_empty_title_web')} image={activityEmpty}>
            <Markdown>{t('note_list_empty_web')}</Markdown>
        </EmptyState>
    );
};

export default NoteListEmptyMessage;
