import type { PropsWithChildren } from 'react';

interface SidebarTitleProps
    extends PropsWithChildren<{
        title: string;
    }> {}

const SidebarTitle = ({ title, children }: SidebarTitleProps) => {
    return (
        <div className="flex items-center justify-between p-6 bordered-b">
            <h2 className="text-2xl text-black mb-0">{title}</h2>
            {children && <div className="flex items-center">{children}</div>}
        </div>
    );
};

export default SidebarTitle;
