import type { ChartData } from 'chart.js';
import type Field from 'fields/Field';
import { getFieldWorkedArea, getYearFieldUsageUuid } from 'fields/Field';
import { getUsageName } from 'fields/FieldUsage';
import type { List } from 'immutable';
import { DEFAULT_COLOUR } from 'lib/colours';
import { roundToDp } from 'lib/util/number';

import type { UsageReportDatum } from './UsageReporting';

/**
 * Calculates the total area for the fields that have the given usage in the given year.
 * The area is returned in SQM so should be used with an appropriate formatter when displaying.
 */
export const calculateUsageArea = (
    usageUuid: string | undefined,
    year: number,
    fields: List<Field>
) =>
    fields
        .filter((field) => getYearFieldUsageUuid(year, field) === usageUuid)
        .reduce((area, field) => area + getFieldWorkedArea(field, year), 0);

/**
 * Converts usage report data into the correct format for use with pie charts.
 */
export const usageDataToChartData = (usageData: UsageReportDatum[]): ChartData<'doughnut'> => {
    return {
        labels: usageData.map((datum) => getUsageName(datum.fieldUsage)),
        datasets: [
            usageData.reduce(
                (data, datum) => {
                    data.data.push(roundToDp(datum.percentage, 0));
                    data.backgroundColor.push(
                        datum.fieldUsage.uuid === 'none' ? DEFAULT_COLOUR : datum.fieldUsage.colour
                    );
                    return data;
                },
                {
                    label: 'Usage report',
                    data: [],
                    backgroundColor: [],
                    hoverOffset: 4,
                } as {
                    label: string;
                    data: number[];
                    backgroundColor: string[];
                    hoverOffset: number;
                }
            ),
        ],
    };
};
