/// <reference types="vite/client" />

import { z } from 'zod';

const schema = z.object({
    // APP
    VITE_APP_NAME: z.union([z.literal('agreena'), z.literal('fieldmargin')]),
    VITE_PAYMENT_WEB_ROOT: z.string().url('VITE_PAYMENT_WEB_ROOT is required'),
    VITE_BOUNDARY_UPLOADER_ROOT: z.string().url('VITE_BOUNDARY_UPLOADER_ROOT'),
    VITE_APP_AUTH_API_ROOT: z.string().url('VITE_APP_AUTH_API_ROOT is required'),
    VITE_APP_API_ROOT: z.string().url('VITE_APP_AUTH_API_ROOT is required'),
    VITE_APP_MEDIA_API_ROOT: z.string().url('VITE_APP_MEDIA_API_ROOT is required'),
    VITE_APP_AUTHORIZATION_BASIC: z.string().min(1, 'VITE_APP_AUTHORIZATION_BASIC is required'),
    VITE_APP_ORG_MANAGEMENT_URL: z.string().url('VITE_APP_ORG_MANAGEMENT_URL is required'),

    // SENTRY
    VITE_SENTRY_DNS_KEY: z.string().url('VITE_SENTRY_DNS_KEY is required'),
    VITE_SENTRY_RELEASE: z.string().min(0, 'VITE_SENTRY_RELEASE is required'),
    VITE_SENTRY_ENVIRONMENT: z.string().min(0, 'VITE_SENTRY_ENVIRONMENT is required'),
    VITE_SENTRY_TRACES_SAMPLE_RATE: z.string().min(0, 'VITE_SENTRY_TRACES_SAMPLE_RATE is required'),

    // SEGMENT
    VITE_SEGMENT_KEY: z.string().min(1, 'VITE_SEGMENT_KEY is required'),
    VITE_SEGMENT_PREFIX: z.string().min(0, 'VITE_SEGMENT_PREFIX is required'),

    // SENTINEL HUB
    VITE_SENTINEL_HUB_WFS_API_TRIAL: z.string().url('VITE_SENTINEL_HUB_WFS_API_TRIAL is required'),
    VITE_SENTINEL_HUB_WMS_API_TRIAL: z.string().url('VITE_SENTINEL_HUB_WMS_API_TRIAL is required'),
    VITE_SENTINEL_HUB_WFS_API_PAID: z.string().url('VITE_SENTINEL_HUB_WFS_API_PAID is required'),
    VITE_SENTINEL_HUB_WMS_API_PAID: z.string().url('VITE_SENTINEL_HUB_WMS_API_PAID is required'),

    // FIREBASE
    VITE_FIREBASE_API_KEY: z.string().min(1, 'VITE_FIREBASE_API_KEY is required'),
    VITE_FIREBASE_APP_ID: z.string().min(1, 'VITE_FIREBASE_APP_ID is required'),
    VITE_FIREBASE_AUTH_DOMAIN: z.string().min(1, 'VITE_FIREBASE_AUTH_DOMAIN is required'),
    VITE_FIREBASE_DATABASE_URL: z.string().url('VITE_FIREBASE_DATABASE_URL is required'),
    VITE_FIREBASE_PROJECT_ID: z.string().min(1, 'VITE_FIREBASE_PROJECT_ID is required'),
    VITE_FIREBASE_STORAGE_BUCKET: z.string().min(1, 'VITE_FIREBASE_STORAGE_BUCKET is required'),
    VITE_FIREBASE_MESSAGING_SENDER_ID: z
        .string()
        .min(1, 'VITE_FIREBASE_MESSAGING_SENDER_ID is required'),

    // MAPS
    VITE_GOOGLE_MAPS_KEY: z.string().min(1, 'VITE_GOOGLE_MAPS_KEY is required'),
    VITE_AZURE_MAPS_KEY: z.string().min(1, 'VITE_AZURE_MAPS_KEY is required'),
    VITE_MAPBOX_ACCESS_TOKEN: z.string().min(1, 'VITE_MAPBOX_ACCESS_TOKEN is required'),
    VITE_HERE_API_KEY: z.string().min(1, 'VITE_HERE_API_KEY is required'),

    // PAYMENT WEB
    VITE_PAYMENT_WEB_MARKETING_ROOT: z.string().url('VITE_PAYMENT_WEB_MARKETING_ROOT is required'),
    VITE_PAYMENT_WEB_STRIPE_API_KEY: z
        .string()
        .min(1, 'VITE_PAYMENT_WEB_STRIPE_API_KEY is required'),
    VITE_PAYMENT_WEB_APP_STORE_LINK: z.string().url('VITE_PAYMENT_WEB_APP_STORE_LINK is required'),
    VITE_PAYMENT_WEB_PLAY_STORE_LINK: z
        .string()
        .url('VITE_PAYMENT_WEB_PLAY_STORE_LINK is required'),
    VITE_PAYMENT_WEB_CANCEL_COUPON_MONTH: z
        .string()
        .min(1, 'VITE_PAYMENT_WEB_CANCEL_COUPON_MONTH is required'),
    VITE_PAYMENT_WEB_CANCEL_COUPON_ANNUAL: z
        .string()
        .min(1, 'VITE_PAYMENT_WEB_CANCEL_COUPON_ANNUAL is required'),

    // GOOGLE
    VITE_GOOGLE_PLACES_API: z.string().url('VITE_GOOGLE_PLACES_API is required'),

    // INTEGRATIONS
    VITE_INTEGRATION_URL_SUFFIX: z.string(),
});

const parsedEnv = schema.parse(import.meta.env ?? process.env);

export type Env = z.infer<typeof schema>;

export const {
    VITE_APP_NAME,
    VITE_PAYMENT_WEB_ROOT,
    VITE_BOUNDARY_UPLOADER_ROOT,
    VITE_APP_AUTH_API_ROOT,
    VITE_APP_API_ROOT,
    VITE_APP_MEDIA_API_ROOT,
    VITE_APP_AUTHORIZATION_BASIC,
    VITE_APP_ORG_MANAGEMENT_URL,
    VITE_SENTRY_DNS_KEY,
    VITE_SENTRY_RELEASE,
    VITE_SENTRY_ENVIRONMENT,
    VITE_SENTRY_TRACES_SAMPLE_RATE,
    VITE_SEGMENT_KEY,
    VITE_SEGMENT_PREFIX,
    VITE_SENTINEL_HUB_WFS_API_TRIAL,
    VITE_SENTINEL_HUB_WMS_API_TRIAL,
    VITE_SENTINEL_HUB_WFS_API_PAID,
    VITE_SENTINEL_HUB_WMS_API_PAID,
    VITE_FIREBASE_API_KEY,
    VITE_FIREBASE_APP_ID,
    VITE_FIREBASE_AUTH_DOMAIN,
    VITE_FIREBASE_DATABASE_URL,
    VITE_FIREBASE_PROJECT_ID,
    VITE_FIREBASE_STORAGE_BUCKET,
    VITE_FIREBASE_MESSAGING_SENDER_ID,
    VITE_GOOGLE_MAPS_KEY,
    VITE_AZURE_MAPS_KEY,
    VITE_MAPBOX_ACCESS_TOKEN,
    VITE_HERE_API_KEY,
    VITE_PAYMENT_WEB_MARKETING_ROOT,
    VITE_PAYMENT_WEB_STRIPE_API_KEY,
    VITE_PAYMENT_WEB_APP_STORE_LINK,
    VITE_PAYMENT_WEB_PLAY_STORE_LINK,
    VITE_PAYMENT_WEB_CANCEL_COUPON_MONTH,
    VITE_PAYMENT_WEB_CANCEL_COUPON_ANNUAL,
    VITE_GOOGLE_PLACES_API,
    VITE_INTEGRATION_URL_SUFFIX,
} = parsedEnv;
