import type { ComponentProps } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type { HerdLocation } from 'herd/Herd';
import { useCurrentField } from 'hooks/selectors';
import { List } from 'immutable';
import { printOut } from 'lib/print';
import type { AppState } from 'system/store';
import UpgradeButton from 'upgrade/UpgradeButton';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';
import ShapeBubbles from 'view/shapes/ShapeBubbles';
import fmLogo from 'view/system/logo/fm-logo.svg';

import FieldHistoryDay from './history/FieldHistoryDay';
import { useFieldHistory } from './sidebar/details/history/field-history-generator';
import type Field from './Field';
import { getYearFieldUsageUuid } from './Field';
import { fetchFieldHerdLocations } from './fields-api';
import type FieldUsage from './FieldUsage';
import { asBadge } from './FieldUsage';

interface FieldHistoryPageBaseProps {
    farm: Farm;
    year: number;
    field: Field;
    fieldUsages: List<FieldUsage>;
}

const FieldHistoryPageBase = ({ farm, year, field, fieldUsages }: FieldHistoryPageBaseProps) => {
    const { t } = useTranslation();
    const [fieldHerdLocations, setFieldHerdLocations] = useState<List<HerdLocation>>();
    const { pending, setPromise } = usePromise<List<HerdLocation>>(setFieldHerdLocations);

    const historyByDay = useFieldHistory(field, fieldHerdLocations);

    const fieldUsageUuid = getYearFieldUsageUuid(year, field);
    const fieldUsage = fieldUsages.find((fu) => fu.uuid === fieldUsageUuid);

    useEffect(() => {
        farm.plan.domainPrinting &&
            setPromise(fetchFieldHerdLocations(farm.uuid, year, field.uuid).catch(() => List()));
    }, [farm]);

    return (
        <div className="bg-white p-8 h-content-full print:h-auto w-screen overflow-auto">
            <div className="no-print flex items-center">
                <Link
                    className="flex items-center font-bold mr-8"
                    to={`/farms/${farm.uuid}/fields/${field.uuid}/history`}
                >
                    {icon('back', 'dark-blue', 'mr-2')} {t('back')}
                </Link>
                <Button onClick={() => printOut()} className="flex items-center">
                    {icon('print', 'white', 'mr-3')} {t('print')}
                </Button>
            </div>
            {!farm.plan.domainPrinting ? (
                <>
                    <p className="mt-4">
                        {t('field_history_printing_upgrade', {
                            defaultValue: 'Field history printing is not allowed on the %s plan.',
                            sprintf: [farm.plan.displayName],
                        })}
                    </p>
                    <p>
                        <UpgradeButton />
                    </p>
                </>
            ) : (
                <>
                    <img
                        alt="fieldmargin logo"
                        className="h-8 hidden print:block print:mb-4"
                        src={fmLogo}
                    />
                    <h1 className="text-lg bordered-b pb-4">{field.name}</h1>

                    {fieldUsage && (
                        <div className="pb-4 mb-3 bordered-b">
                            <h2 className="text-base mb-1">{t('field_usage_title')}</h2>
                            <BadgeItem item={asBadge(fieldUsage)} />
                        </div>
                    )}
                    {field.geoJson !== null && (
                        <div className="pb-2 mb-3 bordered-b">
                            <h2 className="text-base mb-1">{t('area')}</h2>
                            <ShapeBubbles geoFeatureCollection={field.geoJson} />
                        </div>
                    )}
                    {pending && (
                        <p>
                            <em>{t('loading')}</em>
                        </p>
                    )}
                    {historyByDay && (
                        <>
                            <h2 className="text-base mb-1">{t('field_details_history')}</h2>
                            {historyByDay.map((history, index) => (
                                <FieldHistoryDay key={index} field={field} history={history} />
                            ))}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

function FieldHistoryPage({
    ...props
}: Omit<ComponentProps<typeof FieldHistoryPageBase>, 'field'>) {
    const { currentField } = useCurrentField();

    if (!currentField) {
        return <Navigate to={`/farms/${props.farm.uuid}/fields`} replace />;
    } else {
        return <FieldHistoryPageBase {...props} field={currentField} />;
    }
}

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    year: state.yearsState.currentYear,
    fieldUsages: state.fieldUsageState.fieldUsages,
}))(FieldHistoryPage);
