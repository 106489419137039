import OperationActivityLogCommentBox from './OperationActivityLogCommentBox';

function OperationCommentInput() {
    return (
        <div className="non-scrolling">
            <OperationActivityLogCommentBox />
        </div>
    );
}
export { OperationCommentInput };
