import c from 'classnames';
import { useField } from 'informed';
import type { Colour } from 'lib/colours';

import ColourPicker from './ColourPicker';

interface ColourSelectorFieldProps {
    field: string;
    label?: string;
    className?: string;
    validate: (value: any) => string | undefined;
    colours?: Colour[];
}

const ColourSelectorField = ({
    field,
    label,
    className,
    validate,
    colours,
}: ColourSelectorFieldProps) => {
    const { fieldState, fieldApi } = useField({ field, validate });
    return (
        <div>
            <div className={c('form-field', className)}>
                {label && <label className="font-bold">{label}</label>}
                <ColourPicker
                    selectedColour={fieldState.value}
                    onChange={(value) => fieldApi.setValue(value)}
                    colours={colours}
                />
                {fieldState.error && <div className="field-error">{fieldState.error}</div>}
            </div>
        </div>
    );
};

export default ColourSelectorField;
