import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePromise } from '@fieldmargin/webapp-state';
import { useMetricUnit } from 'hooks/useMetricUnit';
import type ManualSensor from 'sensors/manual/ManualSensor';
import { isNumericMetric } from 'sensors/Metric';
import EditableActions from 'sidebar/modules/common/EditableActions';
import SidebarModule from 'sidebar/modules/SidebarModule';
import ErrorMessage from 'view/ErrorMessage';
import DateAndTimeField from 'view/form/hook/DateAndTimeField';
import NumberInputField from 'view/form/hook/NumberInputField';
import TextInputField from 'view/form/hook/TextInputField';
import { required, validateNumber } from 'view/form/validations';

interface ManualSensorReadingGroupFormProps {
    manualSensor: ManualSensor;
    onCancel: VoidFunction;
    onSave: (values: ManualSensorReadingGroupFormValues) => Promise<any>;
    initialValues?: ManualSensorReadingGroupFormValues;
}

export interface ManualSensorReadingGroupFormValues {
    date: Date;
    metrics: {
        [k: string]: string | number; // Map of metric id to value.
    };
    formError?: never;
}

const ManualSensorReadingGroupForm = ({
    manualSensor,
    onCancel,
    onSave,
    initialValues,
}: ManualSensorReadingGroupFormProps) => {
    const { t } = useTranslation();
    const { getMetricUnit } = useMetricUnit();
    const { pending, error, setPromise } = usePromise();
    const handleSubmit = (values: ManualSensorReadingGroupFormValues) => {
        setPromise(onSave(values));
    };

    const validateDate = required(t('sensor_date_validation'));
    const validateNumericMetric = validateNumber(t('validation_number'));
    const validateForm = (values: ManualSensorReadingGroupFormValues) => {
        return Object.values(values.metrics).every((v) => v === undefined || v === '')
            ? {
                  values,
                  errors: {
                      formError: { message: t('sensor_reading_metric_validation') },
                  },
              }
            : { values, errors: {} };
    };

    const methods = useForm<ManualSensorReadingGroupFormValues>({
        defaultValues: initialValues,
        resolver: validateForm,
    });

    return (
        <SidebarModule editing>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <h4>{t('sensors_date')}</h4>
                    <DateAndTimeField
                        field="date"
                        openDirection="bottom"
                        validate={validateDate}
                        allowDelete={false}
                        initialValue={initialValues?.date ?? new Date()}
                        className="mb-4"
                    />
                    <div className="grid grid-cols-3 gap-3 items-start">
                        {manualSensor.metrics.map((metric) => (
                            <div key={metric.id}>
                                <div className="flex gap-2 items-end">
                                    {isNumericMetric(metric) ? (
                                        <div className="form-field">
                                            <label htmlFor={`metrics.${metric.id}`}>
                                                <strong>{metric.name}</strong>
                                            </label>
                                            <NumberInputField
                                                field={`metrics.${metric.id}`}
                                                inputClassName="w-32"
                                            />
                                        </div>
                                    ) : (
                                        <TextInputField
                                            label={<strong>{metric.name}</strong>}
                                            field={`metrics.${metric.id}`}
                                            validate={
                                                isNumericMetric(metric)
                                                    ? validateNumericMetric
                                                    : undefined
                                            }
                                            inputClassName="w-32"
                                        />
                                    )}
                                    <strong className="mb-3">
                                        {getMetricUnit(metric.type, metric.unit)}
                                    </strong>
                                </div>
                                {methods.formState.errors?.metrics?.[metric.id] && (
                                    <ErrorMessage className="mt-1">
                                        {methods.formState.errors.metrics[metric.id]?.message}
                                    </ErrorMessage>
                                )}
                            </div>
                        ))}
                    </div>
                    {methods.formState.errors.formError && (
                        <ErrorMessage className="mt-4">
                            {methods.formState.errors.formError?.message}
                        </ErrorMessage>
                    )}
                    <EditableActions disabled={pending} error={error} setEditing={onCancel} />
                </form>
            </FormProvider>
        </SidebarModule>
    );
};

export default ManualSensorReadingGroupForm;
