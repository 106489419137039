import type { PropsWithChildren } from 'react';
import c from 'classnames';
import Temperature from 'view/units/Temperature';
import TemperatureUnit from 'view/units/TemperatureUnit';
import type { DayForecast } from 'weather/Weather';

import WeatherRain from './WeatherRain';
import WeatherWind from './WeatherWind';

import './WeatherTab.css';

interface WeatherTabProps extends PropsWithChildren {
    onClick: VoidFunction;
    selected: boolean;
    dayForecast: DayForecast;
}

const WeatherTab = ({ onClick, selected, dayForecast, children }: WeatherTabProps) => {
    return (
        <button
            className={c('WeatherTab bare-btn focus', { WeatherTabSelected: selected })}
            onClick={onClick}
        >
            <h3 className="mb-0">{children}</h3>
            <div className="flex items-end justify-center mb-2">
                <WeatherRain forecast={dayForecast} />
                {dayForecast.windSpeed !== undefined && dayForecast.windDirection !== undefined && (
                    <WeatherWind forecast={dayForecast} />
                )}
            </div>
            <div className="text-xl">
                <span className="text-xxl">
                    <Temperature celsius={dayForecast.temperatureMax} round />
                </span>{' '}
                /{' '}
                <span>
                    <Temperature celsius={dayForecast.temperatureMin} round />
                </span>{' '}
                <TemperatureUnit />
            </div>
        </button>
    );
};

export default WeatherTab;
