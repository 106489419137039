// Web tracking events
// https://docs.google.com/spreadsheets/d/1424qcCaNpDVXbUxqzIF8GnGReNw1ba71tYjQWQcPXz0/edit?gid=1952512575#gid=1952512575

import { useEffect } from 'react';
import { pageTracking } from 'utils/trackEvent';

const TRACKING_CATGORY = {
    note: 'Note',
    operation: 'Operation',
    field: 'Field',
    livestock: 'Livestock',
    feature: 'Feature',
    map: 'Map',
    team: 'Team',
    vehicles: 'Vehicles',
    data: 'Data',
    reporting: 'Reporting',
    billing: 'Billing',
};

const TRACKING_NAME = {
    create_farm: 'create_farm',
    field_list: 'field_list',
    create_field: 'create_field',
    select_autoboundary_area: 'select_autoboundary_area',
    select_boundaries: 'select_boundaries',
    edit_boundaries: 'edit_boundaries',
    create_field_title: 'create_field_title',
    set_usages: 'set_usages',
    create_usage: 'create_usage',
    view_field: 'view_field',
    edit_subfields: 'edit_subfields',
    rpa_import_fields: 'rpa_import_fields',
    rpa_name_fields: 'rpa_name_fields',
} as const;

const tracking = (name: keyof typeof TRACKING_NAME, obj?: Record<string, string>) => {
    pageTracking(TRACKING_CATGORY.field, name, obj);
};

export const useSegmentTracking = () => {
    return {
        detectBoundary: (farmUuid: string) => {
            pageTracking('', 'Search Autoboundaries clicked', { farmUuid });
        },
        drawField: (farmUuid: string, type: string) => {
            pageTracking('', 'Draw Field manually clicked', { farmUuid, buttonType: type });
        },
        rpaImportFields: () => {
            tracking(TRACKING_NAME.rpa_import_fields);
        },
        rpaNameFields: () => {
            tracking(TRACKING_NAME.rpa_name_fields);
        },
        createFarm: () => {
            pageTracking('', TRACKING_NAME.create_farm);
        },
        fieldList: () => {
            tracking(TRACKING_NAME.field_list);
        },
        createField: () => {
            tracking(TRACKING_NAME.create_field);
        },
        selectAutoBoundaryArea: () => {
            tracking(TRACKING_NAME.select_autoboundary_area, {
                comment: 'Select area for autoboundaries',
            });
        },
        selectBoundaries: () => {
            tracking(TRACKING_NAME.select_boundaries, {
                comment: 'Choose from returned boundaries',
            });
        },
        editBoundaries: () => {
            tracking(TRACKING_NAME.edit_boundaries, {
                comment: 'Edit selected boundaries',
            });
        },
        createFieldTitle: () => {
            tracking(TRACKING_NAME.create_field_title, {
                comment: 'Name selected autoboundaries',
            });
        },
        setUsages: () => {
            tracking(TRACKING_NAME.set_usages);
        },
        createUsage: () => {
            tracking(TRACKING_NAME.create_usage);
        },
        viewField: () => {
            tracking(TRACKING_NAME.view_field);
        },
        editSubfields: () => {
            tracking(TRACKING_NAME.edit_subfields);
        },
        dataExport: (name: string, format: string) => {
            pageTracking('', `Data export ${name} clicked `, { format });
        },
    };
};

const useFieldListTracking = () => {
    const { fieldList } = useSegmentTracking();

    useEffect(() => {
        fieldList();
    }, []);
};

const useSelectAutoBoundaryAreaTracking = () => {
    const { selectAutoBoundaryArea } = useSegmentTracking();

    useEffect(() => {
        selectAutoBoundaryArea();
    }, []);
};

const useSelectBoundariesTracking = () => {
    const { selectBoundaries } = useSegmentTracking();

    useEffect(() => {
        selectBoundaries();
    }, []);
};

const useEditBoundariesTracking = () => {
    const { editBoundaries } = useSegmentTracking();

    useEffect(() => {
        editBoundaries();
    }, []);
};

const useCreateFieldTitleTracking = () => {
    const { createFieldTitle } = useSegmentTracking();

    useEffect(() => {
        createFieldTitle();
    }, []);
};

const useSetUsagesTracking = () => {
    const { setUsages } = useSegmentTracking();

    useEffect(() => {
        setUsages();
    }, []);
};

const useCreateUsageTracking = () => {
    const { createUsage } = useSegmentTracking();

    useEffect(() => {
        createUsage();
    }, []);
};

const useViewFieldTracking = () => {
    const { viewField } = useSegmentTracking();

    useEffect(() => {
        viewField();
    }, []);
};

const useEditSubfieldsTracking = () => {
    const { editSubfields } = useSegmentTracking();

    useEffect(() => {
        editSubfields();
    }, []);
};

const useCreateFieldTracking = () => {
    const { createField } = useSegmentTracking();

    useEffect(() => {
        createField();
    }, []);
};

const useCreateFarmTracking = () => {
    const { createFarm } = useSegmentTracking();

    useEffect(() => {
        createFarm();
    }, []);
};

export {
    useFieldListTracking,
    useSelectAutoBoundaryAreaTracking,
    useSelectBoundariesTracking,
    useEditBoundariesTracking,
    useCreateFieldTitleTracking,
    useSetUsagesTracking,
    useCreateUsageTracking,
    useViewFieldTracking,
    useEditSubfieldsTracking,
    useCreateFieldTracking,
    useCreateFarmTracking,
};
