import type { Farm, FarmUser, FarmUserDTO, ReadFarmDTO } from '@fieldmargin/webapp-farms';
import { deserializeFarm, deserializeFarmUser } from '@fieldmargin/webapp-farms';

interface FarmInvite {
    uuid: string; // Needs to be sent to server when accepting/declining
    farm: Farm;
    inviter?: FarmUser;
}

export interface FarmInviteDTO {
    uuid: string;
    farm: ReadFarmDTO;
    farmUserDTOs: FarmUserDTO[];
    inviterUserId: number;
}

export const deserializeFarmInvite = (json: FarmInviteDTO): FarmInvite => {
    const inviter = json.farmUserDTOs.find((farmUser) => farmUser.id === json.inviterUserId);
    return {
        uuid: json.uuid,
        farm: deserializeFarm(json.farm),
        inviter: inviter !== undefined ? deserializeFarmUser(inviter) : undefined,
    };
};

export default FarmInvite;
