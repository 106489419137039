import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { AppState } from 'system/store';

import { selectSensorById } from './sensors-state';
import type SensorSummary from './SensorSummary';

const withPageSensor =
    <Props,>(Component: React.ComponentType<Props>) =>
    (props: Props) => {
        const sensorId = useParams<{ sensorId: string }>().sensorId;
        const sensor =
            sensorId !== undefined
                ? useSelector<AppState, SensorSummary | undefined>((state) =>
                      selectSensorById(state, sensorId)
                  )
                : undefined;

        return <Component {...props} sensor={sensor} />;
    };

export default withPageSensor;
