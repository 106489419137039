import { useTranslation } from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import { useItemSelectedState } from 'lib/hooks';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import { useYeldReportingContext } from './useYeldReportingContext';
import YieldReportOutputRow from './YieldReportOutputRow';

const YieldReportTable = () => {
    const { t } = useTranslation();
    const { currentFarm } = useCurrentFarm();
    const { reportData } = useYeldReportingContext();
    const [toggleOpen, isOpen] = useItemSelectedState(reportData.map((osr) => osr.outputUuid));

    return (
        <table className="ReportingTable table-fixed">
            <thead>
                <tr>
                    <th>{t('output')}</th>
                    <th>
                        {t('operation_details_output_rate')}/
                        <ShortAreaUnit />
                    </th>
                    <th>{t('total')}</th>
                    <th>
                        {t('label_income')}/
                        <ShortAreaUnit /> (
                        {getSymbolFromCurrency(currentFarm.costingsCurrency || 'GBP')})
                    </th>
                    <th>
                        {t('label_total_income')} (
                        {getSymbolFromCurrency(currentFarm.costingsCurrency || 'GBP')})
                    </th>
                </tr>
            </thead>
            <tbody>
                {reportData.map((outputReportSummary) => (
                    <YieldReportOutputRow
                        key={outputReportSummary.outputUuid}
                        outputReportSummary={outputReportSummary}
                        isOpen={isOpen(outputReportSummary.outputUuid)}
                        toggleOpen={() => toggleOpen(outputReportSummary.outputUuid)}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default YieldReportTable;
