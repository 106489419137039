import { List, Record } from 'immutable';

import type HistoryReading from './HistoryReading';

const SensorHistory = Record({
    farmIntegrationUuid: '',
    period: '',
    readings: List<HistoryReading>(),
});
interface SensorHistory extends ReturnType<typeof SensorHistory> {}

export default SensorHistory;
