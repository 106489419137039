import type { ReactNode } from 'react';

interface SubFieldsHelpDiagramProps {
    src: any;
    alt: string;
    children: ReactNode;
}

const SubFieldsHelpDiagram = ({ src, alt, children }: SubFieldsHelpDiagramProps) => {
    return (
        <div className="flex flex-col mr-3 w-1/4">
            <img className="mb-3" src={src} alt={alt} />
            <span className="text-xs">{children}</span>
        </div>
    );
};

export default SubFieldsHelpDiagram;
