import { usePageParams } from 'hooks/usePageParamas';
import { selectManualSensor } from 'sensors/manual/manual-sensors-state';
import { useAppSelector } from 'system/store';

const useCurrentManualSensor = () => {
    const { sensorId } = usePageParams();
    const currentManualSensor = useAppSelector((state) =>
        selectManualSensor(state, sensorId ?? '')
    );
    return { currentManualSensor };
};

export { useCurrentManualSensor };
