import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import { getFieldArea } from 'fields/Field';
import type { List } from 'immutable';
import { useFormApi, useFormState } from 'informed';
import { defaultToZero } from 'lib/fp-helpers';
import { formatArea } from 'lib/geo/maths';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { set, update } from 'lodash';
import type { FullOperationField } from 'operations/OperationField';
import type { SingleParamVoidFunction } from 'system/types';
import PulsingNumberInput from 'view/form/PulsingNumberInput';
import FormattedArea from 'view/units/FormattedArea';
import ShortAreaUnit from 'view/units/ShortAreaUnit';

import { calculateYieldRate, calculateYieldTotal } from '../details/yields/yield-utils';
import WorkAreaField from '../edit-fields/WorkAreaField';
import OperationFieldUsageMarker from '../OperationFieldUsageMarker';

import YieldTotalWarningModal from './modal/YieldTotalWarningModal';
import { calculateYieldTotals, useShowYieldTotalChangeWarning } from './operation-new-utils';
import type { OperationNewFormValues } from './OperationNew';

interface NewOperationYieldTableProps {
    operationFields: List<FullOperationField>;
    totalArea: number;
    areaMeasurementUnit: MeasurementUnit;
    onRemoveField: SingleParamVoidFunction<string>;
}

const NewOperationYieldTable = ({
    operationFields,
    totalArea,
    areaMeasurementUnit,
    onRemoveField,
}: NewOperationYieldTableProps) => {
    const { t } = useTranslation();

    const formState = useFormState<OperationNewFormValues>();
    const formApi = useFormApi<OperationNewFormValues>();

    const [
        showingTotalWarningModal,
        showTotalWarningModal,
        hideTotalWarningModal,
        showingTooltip,
        showTooltip,
        hideTooltip,
    ] = useShowYieldTotalChangeWarning();

    const handleYieldRateChange =
        (operationField: FullOperationField) => (formYieldRate: number) => {
            const fieldUuid = operationField.field.uuid;
            const yieldRate = defaultToZero(formYieldRate);
            const workArea = defaultToZero(formState.values.fields?.[fieldUuid].workArea);
            const yieldTotal = calculateYieldTotal(workArea, yieldRate);

            const { totalYield, totalYieldRate } = calculateYieldTotals(
                set(
                    set({ ...formState.values }, `fields.${fieldUuid}.yieldRate`, yieldRate),
                    `fields.${fieldUuid}.yieldTotal`,
                    yieldTotal
                )
            );

            formApi.setValue(`fields.${operationField.field.uuid}.yieldTotal`, yieldTotal);
            formApi.setValue('totalYieldRate', totalYieldRate);
            formApi.setValue('totalYield', totalYield);
        };

    const handleYieldTotalChange =
        (operationField: FullOperationField) => (formYieldTotal: number) => {
            const fieldUuid = operationField.field.uuid;
            const yieldTotal = defaultToZero(formYieldTotal);
            const workArea = defaultToZero(formState.values.fields[fieldUuid].workArea);
            const yieldRate = calculateYieldRate(workArea, yieldTotal);

            const { totalYield, totalYieldRate } = calculateYieldTotals(
                set(
                    set({ ...formState.values }, `fields.${fieldUuid}.yieldRate`, yieldRate),
                    `fields.${fieldUuid}.yieldTotal`,
                    yieldTotal
                )
            );

            formApi.setValue(`fields.${operationField.field.uuid}.yieldRate`, yieldRate);
            formApi.setValue('totalYield', totalYield);
            formApi.setValue('totalYieldRate', totalYieldRate);
        };

    const handleWorkAreaChange = (operationField: FullOperationField) => (workArea: number) => {
        const fieldUuid = operationField.field.uuid;
        const yieldRate = defaultToZero(formState.values.fields[fieldUuid].yieldRate);
        const yieldTotal = calculateYieldTotal(workArea, yieldRate);
        const { totalYield, totalYieldRate } = calculateYieldTotals(
            set({ ...formState.values }, `fields.${fieldUuid}`, {
                workArea,
                yieldRate,
                yieldTotal,
            })
        );
        formApi.setValue(`fields.${operationField.field.uuid}.yieldTotal`, yieldTotal);
        formApi.setValue('totalYield', totalYield);
        formApi.setValue('totalYieldRate', totalYieldRate);
    };

    const handleTotalYieldRateChange = (formTotalYieldRate: number) => {
        const yieldRate = defaultToZero(formTotalYieldRate);
        const nextValues = update({ ...formState.values }, 'fields', (fields) =>
            Object.keys(fields).reduce((map, fieldUuid) => {
                map[fieldUuid] = set(
                    set({ ...fields[fieldUuid] }, 'yieldRate', yieldRate),
                    'yieldTotal',
                    calculateYieldTotal(defaultToZero(fields[fieldUuid].workArea), yieldRate)
                );
                return map;
            }, {})
        );
        const { totalYield } = calculateYieldTotals(nextValues);
        Object.keys(formState.values.fields).forEach((fieldUuid) => {
            formApi.setValue(
                `fields.${fieldUuid}.yieldRate`,
                nextValues.fields[fieldUuid].yieldRate
            );
            formApi.setValue(
                `fields.${fieldUuid}.yieldTotal`,
                nextValues.fields[fieldUuid].yieldTotal
            );
        });
        formApi.setValue('totalYield', totalYield);
    };

    const handleTotalYieldChange = (formTotalYield: number) => {
        const totalYield = defaultToZero(formTotalYield);
        const totalYieldRate = calculateYieldRate(
            formatArea(totalArea, areaMeasurementUnit),
            totalYield
        );
        Object.keys(formState.values.fields).forEach((fieldUuid) => {
            formApi.setValue(`fields.${fieldUuid}.yieldRate`, totalYieldRate);
            formApi.setValue(
                `fields.${fieldUuid}.yieldTotal`,
                calculateYieldTotal(
                    defaultToZero(formState.values.fields[fieldUuid].workArea),
                    totalYieldRate
                )
            );
        });
        formApi.setValue('totalYieldRate', totalYieldRate);
    };

    return (
        <>
            {showingTotalWarningModal && <YieldTotalWarningModal onClose={hideTotalWarningModal} />}
            <table className="OperationTable OperationYieldTable editing">
                <thead>
                    <tr className="spaced">
                        <th className="field-name" />
                        <th className="field-area" />
                        <th className="work-area">
                            {t('output_rate_area')} (<ShortAreaUnit />)
                        </th>
                        <th className="symbol" />
                        <th className="yield-rate">
                            {t('yield')}/
                            <ShortAreaUnit />
                        </th>
                        <th className="symbol" />
                        <th className="yield-total">{t('total')}</th>
                        <th className="remove" />
                    </tr>
                </thead>
                <tbody>
                    {operationFields.size === 0 ? (
                        <tr className="spaced">
                            <td>&mdash;</td>
                            <td />
                            <td className="text-center">&mdash;</td>
                            <td />
                            <td className="text-center">&mdash;</td>
                            <td />
                            <td className="text-center">&mdash;</td>
                            <td />
                        </tr>
                    ) : (
                        operationFields.map((operationField) => (
                            <tr key={operationField.field.uuid}>
                                <td className="field-name">
                                    <div className="flex items-center h-12">
                                        <OperationFieldUsageMarker
                                            fieldUsage={operationField.fieldUsage}
                                        />
                                        <div className="w-3/4 py-2 flex flex-wrap">
                                            <div className="w-5/6">
                                                <strong className="block truncate">
                                                    {operationField.field.name}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="field-area">
                                    <FormattedArea areaSqm={getFieldArea(operationField.field)} />
                                </td>
                                <td className="work-area">
                                    <WorkAreaField
                                        field={`fields.${operationField.field.uuid}.workArea`}
                                        initialArea={formatArea(
                                            operationField.areaSqm,
                                            areaMeasurementUnit
                                        )}
                                        onChange={handleWorkAreaChange(operationField)}
                                        className="w-full"
                                    />
                                </td>
                                <td className="symbol">x</td>
                                <td className="yield-rate">
                                    <PulsingNumberInput
                                        field={`fields.${operationField.field.uuid}.yieldRate`}
                                        onChange={handleYieldRateChange(operationField)}
                                    />
                                </td>
                                <td className="symbol">=</td>
                                <td className="yield-total">
                                    <PulsingNumberInput
                                        field={`fields.${operationField.field.uuid}.yieldTotal`}
                                        onChange={handleYieldTotalChange(operationField)}
                                    />
                                </td>
                                <td className="remove">
                                    <button
                                        className="bare-btn bg-white w-4"
                                        type="button"
                                        onClick={() => onRemoveField(operationField.field.uuid)}
                                    >
                                        {icon('edit-remove', 'red')}
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                    {operationFields.size > 0 && (
                        <tr className="spaced-y">
                            <td colSpan={2}>
                                <strong className="ml-5">{t('total')}</strong>
                            </td>
                            <td className="work-area">
                                <strong>
                                    <FormattedArea areaSqm={totalArea} />
                                </strong>
                            </td>
                            <td />
                            <td className="yield-rate">
                                <PulsingNumberInput
                                    field="totalYieldRate"
                                    onChange={handleTotalYieldRateChange}
                                    onClick={showTotalWarningModal}
                                    onFocus={showTooltip}
                                    onBlur={hideTooltip}
                                />
                            </td>
                            <td />
                            <td className="yield-total relative">
                                <PulsingNumberInput
                                    field="totalYield"
                                    onChange={handleTotalYieldChange}
                                    onClick={showTotalWarningModal}
                                    onFocus={showTooltip}
                                    onBlur={hideTooltip}
                                />
                                {showingTooltip && <YieldTotalTooltip />}
                            </td>
                            <td />
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
};

export default NewOperationYieldTable;

const YieldTotalTooltip = () => {
    const { t } = useTranslation();
    return <div className="YieldTotalTooltip">{t('overwrite_warning')}</div>;
};
