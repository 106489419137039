import type { CSSProperties, ReactNode } from 'react';
import React from 'react';
import c from 'classnames';

const colours = {
    white: '#ffffff',
    'dark-blue': '#2c3e50',
    grey: '#969696',
    red: '#ce1d18',
    orange: '#ff7b1d',
    green: '#4da201',
    blue: '#4da4da',
    'dark-grey': '#888888',
    current: 'inherit',
};

export const createIconGenerator = <T extends { [key: string]: any }>(
    src: T,
    className: string,
    defaultIconName = null as string | null
): ((
    iconName: keyof typeof src,
    colour?: string,
    iconClassName?: string,
    style?: CSSProperties
) => ReactNode) => {
    const cache = {};
    return (iconName, colour = 'dark-blue', iconClassName, style) => {
        if (!src[iconName]) {
            //@ts-ignore  backwards compatiblity requires a default, though it would imply bad typing
            iconName = defaultIconName;
        }
        const key = `${String(iconName)}-${colour}`;
        if (!cache[key]) {
            let content = src[iconName];
            colour = colours[colour] || colour;
            content = content
                .replace(/fill: ?#[0-9a-f]+;/i, `fill: ${colour};`)
                .replace(/COLOUR/g, colour);
            cache[key] = 'data:image/svg+xml;base64,' + window.btoa(content);
        }
        return React.createElement('img', {
            className: c(className, iconClassName, iconName as string),
            style,
            src: cache[key],
        });
    };
};
