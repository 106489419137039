import { connect } from 'react-redux';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { setReturnTo } from 'system/ui-state';

interface ReturningLinkProps extends LinkProps {
    // From redux
    returnTo?: string;
    setReturnTo: typeof setReturnTo;
}

/**
 * This component can be used to return the user to a previously remembered URL.
 * It should be used in conjunction with <RememberingLink>.
 */
const ReturningLink = ({ children, returnTo, setReturnTo, to, ...props }: ReturningLinkProps) => {
    const handleClick = () => {
        setReturnTo(undefined);
    };

    return (
        <Link onClick={handleClick} to={returnTo !== undefined ? returnTo : to} {...props}>
            {children}
        </Link>
    );
};

export default connect(
    (state: AppState) => ({
        returnTo: state.uiState.returnTo,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setReturnTo,
            },
            dispatch
        )
)(ReturningLink);
