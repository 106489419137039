import { useEffect } from 'react';
import type { geojson } from '@fieldmargin/webapp-ol-map';
import { selectGeoJsonDrawingControllerProps } from 'farm-editing/farm-editing-state';
import type { FieldToolTipControllerProps } from 'farm-map/controllers/FieldTooltipController';
import type { Feature } from 'geojson';
import { omit, values } from 'ramda';
import { useAppSelector } from 'system/store';

import type { useCreateFarmMap } from './useCreateFarmMap';

export interface UpdateFarmMapProps {
    basemap: string;
    tiledLayers: geojson.TiledLayerSpec[];
    mainFeatures: Feature[];
    noteMarkers: Feature[];
    fieldFeatures: geojson.FeaturesContollerData;
    pointFeatures: Feature[];
    editingFeatures: Feature[];
    editingAttachments: Feature[];
    herdFeatures: Feature[];
    fieldTooltipProps: FieldToolTipControllerProps;
    initials: Feature[];
    sensors: Feature[];
    subFields: Feature[];
    viewportAction?: geojson.ViewportAction;
}

export const useUpdateFarmMap = (
    mapRef: ReturnType<typeof useCreateFarmMap>['mapRef'],
    props: UpdateFarmMapProps
) => {
    const drawingProps = useAppSelector(selectGeoJsonDrawingControllerProps);
    useEffect(
        () => {
            mapRef.current?.update({
                basemap: props.basemap,
                tiledLayers: props.tiledLayers,
                mainFeatures: {
                    features: props.mainFeatures,
                    options: {},
                },
                fields: props.fieldFeatures,
                drawing: drawingProps,
                editing: props.editingFeatures,
                editingAttachments: {
                    features: props.editingAttachments,
                    options: {},
                },
                fieldTooltip: props.fieldTooltipProps,
                noteMarkers: props.noteMarkers,
                pointFeatures: {
                    features: props.pointFeatures,
                    options: {},
                },
                initials: {
                    features: props.initials,
                    options: {},
                },
                sensors: {
                    features: props.sensors,
                    options: {},
                },
                herdMarkers: props.herdFeatures,
                subFields: props.subFields,
            });
        },
        values(omit(['viewportAction'], props))
    );

    useEffect(() => {
        if (props.viewportAction !== undefined) {
            mapRef.current?.updateViewport(props.viewportAction);
        }
    }, [props.viewportAction]);
};
