import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { List } from 'immutable';

import type { OutputDTO } from './Output';
import type Output from './Output';
import { deserializeOutput, serializeOutput } from './Output';

export const getOutputsApi = (farmUuid: string) =>
    authorizedRequest<OutputDTO[]>({
        url: `/notes-api/v2/farms/${farmUuid}/operations/outputs/`,
    }).then((outputs) => List(outputs.map(deserializeOutput)));

export const saveOutputApi = (farmUuid: string, output: Output) =>
    authorizedRequest<OutputDTO>({
        method: 'post',
        url: `/notes-api/v2/farms/${farmUuid}/operations/outputs/`,
        data: serializeOutput(output.set('farmUuid', farmUuid)),
    }).then(deserializeOutput);

export interface OutputCostDTO {
    operationOutputUUID: string;
    unitCost: number;
    year: number;
}

export const saveOutputCosts = (farmUuid: string, year: number, outputCosts: OutputCostDTO[]) =>
    authorizedRequest<void>({
        method: 'post',
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/outputs/`,
        data: outputCosts,
    });

export const getOutputCosts = (farmUuid: string, year: number) =>
    authorizedRequest<OutputCostDTO[]>({
        url: `/notes-api/v2/farms/${farmUuid}/years/${year}/operations/outputs/`,
    });
