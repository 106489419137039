import type { PropsWithChildren } from 'react';
import { Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectFarms } from 'farms/farms-state';
import { usePageParams } from 'hooks/usePageParamas';
import LocalStorageHelper from 'lib/storage/LocalStorageHelper';
import queryString from 'query-string';
import { useAppSelector } from 'system/store';
import { isQueryParamString } from 'system/url-util';

import { VITE_APP_NAME } from '../../../env';

interface FarmInitialiseProps extends PropsWithChildren {
    nextRoute: 'notes' | 'fields';
}

function FarmInitialise({ children, nextRoute }: FarmInitialiseProps) {
    const farms = useAppSelector(selectFarms);
    const navigate = useNavigate();
    const isAgreenaApp = VITE_APP_NAME === 'agreena';
    const { farmUuid: farmUuidParam } = usePageParams();
    const { pathname, search } = useLocation();
    const params = queryString.parse(search);
    const toNextRoute = isQueryParamString(params, 'redirect')
        ? (params.redirect as string)
        : nextRoute;

    const isFarmRoute = pathname.startsWith('/farms/');
    const isCreateFarmRoute = pathname === '/create-farm';

    useEffect(() => {
        if (pathname === '/createFarm') {
            navigate('/create-farm', { replace: true });
            return;
        }

        // Skip redirect if already on a farm route
        if (isFarmRoute || isCreateFarmRoute) {
            return;
        }

        const farmUuid = isAgreenaApp ? farmUuidParam : LocalStorageHelper.getItem('lastFarmUuid');

        const redirectToFarm = (uuid: string) => {
            navigate(`/farms/${uuid}/${toNextRoute}`);
        };

        if (farmUuid && farms.some((farm) => farm.uuid === farmUuid)) {
            // push to last viewed farm if they still have access to it
            redirectToFarm(farmUuid);
        } else if (!farms.isEmpty() && !isAgreenaApp) {
            // no item stored - push to first farm in the list
            redirectToFarm(farms.getIn([0, 'uuid']));
        } else {
            // no farms - ask them to create one
            navigate('/create-farm', { replace: true });
        }
    }, [
        farms,
        navigate,
        toNextRoute,
        isFarmRoute,
        isCreateFarmRoute,
        pathname,
        isAgreenaApp,
        farmUuidParam,
    ]);

    return <Fragment>{children}</Fragment>;
}

export { FarmInitialise };
