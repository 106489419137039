import { useTranslation } from 'react-i18next';
import { useDateFormat } from 'hooks/useDateFormat';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';

import type { PesticideCheckExpiryDate } from './PesticideCheck';
import { PesticideCheckStatus } from './PesticideCheck';
import PesticideCheckResultIcon from './PesticideCheckResultIcon';

interface ExpiryDateResultProps {
    check: PesticideCheckExpiryDate;
}

const ExpiryDateResult = ({ check }: ExpiryDateResultProps) => {
    const { t } = useTranslation();
    const { format } = useDateFormat();
    const date =
        check.values !== null ? format(new Date(check.values.expiryDate), 'dd MMM yyyy') : null;

    return (
        <tr>
            <PesticideCheckResultIcon status={check.status} />
            <td>
                {check.status === PesticideCheckStatus.FAIL ? (
                    <MarkdownNoPara>
                        {t('pesticide_check_expiry_date_fail', { sprintf: [date] })}
                    </MarkdownNoPara>
                ) : check.status === PesticideCheckStatus.UNKNOWN ? (
                    t('pesticide_check_expiry_date_no_data')
                ) : (
                    t('pesticide_check_expiry_date_valid', { sprintf: [date] })
                )}
            </td>
        </tr>
    );
};

export default ExpiryDateResult;
