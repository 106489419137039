import { useFormContext } from 'react-hook-form';
import { clsx } from 'clsx';
import type { FullOperationField } from 'operations/OperationField';
import type { SingleParamVoidFunction } from 'system/types';
import NumberInputField from 'view/form/hook/NumberInputField';

import type { EditOperationFieldsFormValues } from './EditOperationFields';

interface YieldCellsProps {
    operationField: FullOperationField;
    onYieldRateChange: SingleParamVoidFunction<number>;
    onYieldTotalChange: SingleParamVoidFunction<number>;
    className?: string;
}

const YieldCells = ({
    operationField,
    onYieldRateChange,
    onYieldTotalChange,
    className,
}: YieldCellsProps) => {
    const {
        formState: { errors },
    } = useFormContext<EditOperationFieldsFormValues>();

    const handleRateChange = (value: number) => {
        onYieldRateChange(value);
    };

    const handleTotalChange = (value: number) => {
        onYieldTotalChange(value);
    };

    const fieldErrors = errors[`${operationField.field.uuid}`];

    return (
        <>
            <td className={className}>
                <NumberInputField
                    field={`${operationField.field.uuid}.yieldRate`}
                    onChange={handleRateChange}
                    inputClassName={clsx('w-16', {
                        'has-error': fieldErrors?.yieldRate,
                    })}
                />
            </td>
            <td className={className}>
                <NumberInputField
                    field={`${operationField.field.uuid}.yieldTotal`}
                    onChange={handleTotalChange}
                    inputClassName={clsx('w-16', {
                        'has-error': fieldErrors?.yieldTotal,
                    })}
                />
            </td>
        </>
    );
};

export default YieldCells;
