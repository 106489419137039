interface AreaIconProps {
    className?: string;
    fill?: string;
}

const AreaIcon = ({ className, fill = '#4da4da' }: AreaIconProps) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <g>
                <path
                    style={{ fill }}
                    d="M48.7,32.6l-9.1,14.6c1.4,0.2,2.7,1.1,3.3,2.4L52,35C50.6,34.8,49.4,34,48.7,32.6z"
                />
                <path
                    style={{ fill }}
                    d="M7,32.1c1.3,0,2.5,0.5,3.3,1.5l2.3-10.8c-0.2,0-0.4,0.1-0.7,0.1c-1.3,0-2.4-0.5-3.2-1.4L6.5,32.1
		C6.7,32.1,6.8,32.1,7,32.1z"
                />
                <g>
                    <path
                        style={{ fill }}
                        d="M49.7,11.5c-0.8,1.1-2.1,1.8-3.6,1.8c-0.1,0-0.1,0-0.2,0l3.8,14c0.8-0.7,1.7-1.1,2.9-1.1
			c0.3,0,0.8,0.1,1.1,0.1L49.7,11.5z"
                    />
                    <path
                        style={{ fill }}
                        d="M43.3,12.2c-1-0.8-1.5-2-1.5-3.4c0-0.1,0-0.3,0-0.4l-27.3,6.4c1.1,0.8,1.8,2.1,1.8,3.6v0.1L43.3,12.2z"
                    />
                </g>
                <path
                    style={{ fill }}
                    d="M34.5,51.6c0-1.3,0.5-2.5,1.4-3.3L11.3,36.2c0,0.1,0,0.1,0,0.2c0,1.4-0.7,2.6-1.7,3.5l24.9,12.2
		C34.5,51.9,34.5,51.8,34.5,51.6z"
                />
                <path
                    style={{ fill }}
                    d="M7.1,30.5c-3.4,0-6.1,2.7-6.1,6.1s2.7,6.1,6.1,6.1c3.4,0,6.1-2.7,6.1-6.1S10.4,30.5,7.1,30.5z M7.1,39.7
		c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1S8.8,39.7,7.1,39.7z"
                />
                <path
                    style={{ fill }}
                    d="M38.5,45c-3.4,0-6.1,2.7-6.1,6.1c0,3.4,2.7,6.1,6.1,6.1c3.4,0,6.1-2.7,6.1-6.1C44.5,47.8,41.8,45,38.5,45z
		 M38.5,54.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1C41.5,52.8,40.2,54.2,38.5,54.2z"
                />
                <path
                    style={{ fill }}
                    d="M52.9,24.4c-3.4,0-6.1,2.7-6.1,6.1s2.7,6.1,6.1,6.1c3.4,0,6.1-2.7,6.1-6.1S56.3,24.4,52.9,24.4z M52.9,33.6
		c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1S54.6,33.6,52.9,33.6z"
                />
                <path
                    style={{ fill }}
                    d="M45.9,2.8c-3.4,0-6.1,2.7-6.1,6.1s2.7,6.1,6.1,6.1c3.4,0,6.1-2.7,6.1-6.1S49.2,2.8,45.9,2.8z M45.9,12
		c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1S47.6,12,45.9,12z"
                />
                <path
                    style={{ fill }}
                    d="M11.7,12.5c-3.4,0-6.1,2.7-6.1,6.1s2.7,6.1,6.1,6.1c3.4,0,6.1-2.7,6.1-6.1S15.1,12.5,11.7,12.5z M11.7,21.7
		c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1S13.4,21.7,11.7,21.7z"
                />
            </g>
        </svg>
    );
};

export default AreaIcon;
