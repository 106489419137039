import type { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import { selectDrawingTool } from 'farm-editing/farm-editing-state';
import { startDrawingNew, SubFieldTool } from 'fields/sub-fields-state';
import { useAppDispatch, useAppSelector } from 'system/store';
import AreaIcon from 'view/svg/AreaIcon';

const SubFieldsDrawNew = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const drawingNew = useAppSelector(
        (state) => state.subFieldsState.selectedTool === SubFieldTool.NEW
    );

    const handleClick = (e?: MouseEvent) => {
        e && e.preventDefault();
        dispatch(selectDrawingTool('area'));
        dispatch(startDrawingNew());
    };

    return (
        <Button
            small
            variant="outline"
            onClick={handleClick}
            className="flex items-center mr-3"
            disabled={drawingNew}
        >
            <AreaIcon className="h-6 mr-3" />
            {t('draw_new')}
        </Button>
    );
};

export default SubFieldsDrawNew;
