import { useTranslation } from 'react-i18next';
import c from 'classnames';
import NativeSelectFormField from 'view/form/native/NativeSelectFormField';

interface ReportStatusFilterProps {
    filter: string;
    setFilter: (filter: string) => void;
    disabled?: boolean;
    className?: string;
}

const ReportStatusFilter = ({
    filter,
    setFilter,
    className,
    disabled = false,
}: ReportStatusFilterProps) => {
    const { t } = useTranslation();
    return (
        <div className={c('mr-5', className)}>
            <NativeSelectFormField
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                disabled={disabled}
            >
                <option value="all">{t('field_details_filter_all')}</option>
                <option value="todo">{t('field_details_filter_incomplete')}</option>
                <option value="done">{t('field_details_filter_complete')}</option>
            </NativeSelectFormField>
        </div>
    );
};

export default ReportStatusFilter;
