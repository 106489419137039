import { useTranslation } from 'react-i18next';
import InformedCheckbox from '@fieldmargin/webapp-styling/components/informed/InformedCheckbox';
import c from 'classnames';
import { useFieldState } from 'informed';
import SidebarModule from 'sidebar/modules/SidebarModule';

interface MarkAsTodoFieldProps {
    field: string;
}

const MarkAsTodoField = ({ field }: MarkAsTodoFieldProps) => {
    const { t } = useTranslation();
    const { value } = useFieldState(field);
    return (
        <SidebarModule className={c('MarkAsTodoField', { hidden: !!value })} editing={true}>
            <InformedCheckbox id={field} field={field} label={t('create_note_mark_todo')} />
        </SidebarModule>
    );
};

export default MarkAsTodoField;
