import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { OperationField } from 'components/operation-field';
import { SidebarCard } from 'components/sidebar-card';
import { AttachmentType } from 'farm-editing/attachments';
import { startSelectingMultipleAttachments } from 'farm-editing/farm-editing-state';
import { startEditingOperation } from 'farm-editing/start-editing-reducer';
import { selectCurrentFarm } from 'farms/farms-state';
import { useOperationDetailsActions } from 'hooks/actions';
import type { List } from 'immutable';
import type FullOperation from 'operations/FullOperation';
import type { FullOperationField } from 'operations/OperationField';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import FormattedArea from 'view/units/FormattedArea';

import OperationFieldListItem from './OperationFieldListItem';

import './OperationFieldsView.css';

interface ReduxProps {
    // From redux
    farm: Farm;
    startSelectingMultipleAttachments: typeof startSelectingMultipleAttachments;
    startEditingOperation: typeof startEditingOperation;
}

interface ParentProps {
    // From parent
    fullOperation: FullOperation;
    operationFields: List<FullOperationField>;
    totalFieldArea: number;
}

type OperationFieldsViewProps = ParentProps & ReduxProps;

const OperationFieldsView = ({
    farm,
    fullOperation,
    operationFields,
    totalFieldArea,
    startSelectingMultipleAttachments,
    startEditingOperation,
}: OperationFieldsViewProps) => {
    const { t } = useTranslation();
    const { handleToggleEditingFields: toggleEditingFields, handleToggleAddFields } =
        useOperationDetailsActions();

    const handleAddFields = () => {
        startEditingOperation(fullOperation);
        startSelectingMultipleAttachments(AttachmentType.FIELD);
        handleToggleAddFields();
    };

    return (
        <SidebarCard.Root>
            <SidebarCard.Header>
                <SidebarCard.Heading as="h4">{t('fields')}</SidebarCard.Heading>
                <SidebarCard.Buttons>
                    <SidebarCard.AddButton onPress={handleAddFields}>
                        {t('operation_details_add_fields')}
                    </SidebarCard.AddButton>
                    <span className="vertical-separator" />
                    <Button
                        variant="subtle"
                        small
                        onClick={toggleEditingFields}
                        className="centered text-fm-sky"
                    >
                        {icon('new-edit', 'blue', 'OperationFieldsViewEditIcon')}
                    </Button>
                </SidebarCard.Buttons>
            </SidebarCard.Header>
            {operationFields.size === 0 && (
                <p className="mt-2">
                    <em className="text-gray-550 ">{t('activity_log_no_fields')}</em>
                </p>
            )}
            {operationFields.size > 0 && (
                <Fragment>
                    <OperationField.List>
                        {operationFields.map((operationField) => (
                            <OperationFieldListItem
                                key={operationField.field.uuid}
                                farm={farm}
                                fullOperation={fullOperation}
                                operationField={operationField}
                                onEditFields={toggleEditingFields}
                            />
                        ))}
                    </OperationField.List>

                    <p className="OperationFieldTotalArea">
                        <strong>{t('field_work_area_total')}</strong>
                        <strong>
                            <FormattedArea areaSqm={totalFieldArea} withUnit />
                        </strong>
                    </p>
                </Fragment>
            )}
        </SidebarCard.Root>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        year: state.yearsState.currentYear,
        fieldUsages: state.fieldUsageState.fieldUsages,
    }),
    (dispatch) =>
        bindActionCreators({ startSelectingMultipleAttachments, startEditingOperation }, dispatch)
)(OperationFieldsView);
