import type { FarmUser } from '@fieldmargin/webapp-farms';
import { FarmUserRole } from '@fieldmargin/webapp-farms';
import type { Item } from 'system/types';

const ownerRoles = [FarmUserRole.OWNER, FarmUserRole.ADMIN];

export const isFarmOwner = <FU extends Pick<FarmUser, 'role'>>(farmUser: FU) =>
    ownerRoles.includes(farmUser.role);

export const isCreator = (item: Item, currentUserId: number) => {
    return item.createdByUserId === currentUserId;
};

export const isMediaItemOwner = (mediaUserId: number, currentUserId: number) => {
    return mediaUserId === currentUserId;
};
