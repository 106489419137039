import { useSelector } from 'react-redux';
import { useCurrentYearSelector } from 'hooks/selectors';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import configService from 'lib/config';
import { selectUserAreaMeasurementUnit } from 'users/user-state';

type FileFormat = 'CSV' | 'XLS';

export const useDownloadLink = () => {
    const { currentFarm } = useCurrentFarm();
    const { currentYear } = useCurrentYearSelector();
    const areaMeasurementUnit = useSelector(selectUserAreaMeasurementUnit);

    return (link: string, format: FileFormat = 'CSV') => {
        const url = new URL(
            `/notes-api/farms/${currentFarm.uuid}/years/${currentYear}/${link}`,
            configService.get('apiRoot')
        );
        url.searchParams.set('measurementSystem', areaMeasurementUnit.toUpperCase());
        url.searchParams.set('fileFormat', format);
        return url.toString();
    };
};

export const useOperationsDownloadLink = () => {
    const { currentFarm } = useCurrentFarm();
    const { currentYear } = useCurrentYearSelector();
    const areaMeasurementUnit = useSelector(selectUserAreaMeasurementUnit);

    return (format: FileFormat = 'CSV') => {
        const url = new URL(
            `/notes-api/v2/farms/${currentFarm.uuid}/years/${currentYear}/operations/summary`,
            configService.get('apiRoot')
        );
        url.searchParams.set('measurementSystem', areaMeasurementUnit.toUpperCase());
        url.searchParams.set('fileFormat', format);
        return url.toString();
    };
};

export const useFeatureListDownloadLink = () => {
    const { currentFarm } = useCurrentFarm();
    const areaMeasurementUnit = useSelector(selectUserAreaMeasurementUnit);

    return (format: FileFormat = 'CSV') => {
        const url = new URL(
            `/notes-api/farms/${currentFarm.uuid}/features/report`,
            configService.get('apiRoot')
        );
        url.searchParams.set('measurementSystem', areaMeasurementUnit.toUpperCase());
        url.searchParams.set('fileFormat', format);
        return url.toString();
    };
};
