import { selectMaybeVisibleFilteredActivities } from 'notes/notes-filter';
import { useAppSelector } from 'system/store';

const useFilteredActivities = () => {
    const filteredActivities = useAppSelector((state) =>
        selectMaybeVisibleFilteredActivities(state)
    );

    const totalFilteredActivities = filteredActivities.size;

    return { filteredActivities, totalFilteredActivities };
};

export { useFilteredActivities };
