import { useEffect, useState } from 'react';
import { authorizedRequest } from '@fieldmargin/webapp-auth';
import { createLogger } from '@fieldmargin/webapp-reporting';
import type { ChatImage } from 'chat/chat-models';
import c from 'classnames';

import './DiscussionImage.scss';

const logger = createLogger('chat.chatlog.DiscussionImage');

interface DiscussionImageProps {
    image: ChatImage;
}

const DiscussionImage = ({ image }: DiscussionImageProps) => {
    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(() => {
        if (image.isFarmChat) {
            // We request the thumb URL via the backend to ensure the auth token is sent.
            // The response from the backend will be the location of the image in the media bucket.
            authorizedRequest({ url: image.thumbUrl }).then(setImageUrl);
        } else {
            const downloadingImage = new Image();
            downloadingImage.onload = () => {
                setImageUrl(downloadingImage.src);
            };
            downloadingImage.onerror = (e) => {
                logger.error(`Unable to load discussion image at ${image.thumbUrl}`, e);
            };
            if (image.thumbUrl) {
                downloadingImage.src = image.thumbUrl;
            }
        }
    }, [image]);

    return (
        <div className={c('discussion-image', { clickable: image.onClick })}>
            <img
                alt="Loading spinner"
                src={imageUrl ? imageUrl : '/images/imageSpinner.gif'}
                onClick={() => image.onClick && image.onClick()}
            />
        </div>
    );
};

export default DiscussionImage;
