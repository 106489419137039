interface LineIconProps {
    className?: string;
    fill?: string;
}

const LineIcon = ({ className, fill = '#4da4da' }: LineIconProps) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <g>
                <path
                    style={{ fill }}
                    d="M53.4,11c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6S56.5,11,53.4,11z M53.4,19.4
		c-1.6,0-2.8-1.3-2.8-2.8s1.3-2.8,2.8-2.8c1.6,0,2.8,1.3,2.8,2.8S55,19.4,53.4,19.4z"
                />
                <path
                    style={{ fill }}
                    d="M19.2,19.8c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6S22.3,19.8,19.2,19.8z M19.2,28.2
		c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8c1.6,0,2.8,1.3,2.8,2.8C22,26.9,20.7,28.2,19.2,28.2z"
                />
                <path
                    style={{ fill }}
                    d="M6.6,37.8c-3.1,0-5.6,2.5-5.6,5.6S3.5,49,6.6,49c3.1,0,5.6-2.5,5.6-5.6S9.7,37.8,6.6,37.8z M6.6,46.3
		c-1.6,0-2.8-1.3-2.8-2.8s1.3-2.8,2.8-2.8c1.6,0,2.8,1.3,2.8,2.8S8.2,46.3,6.6,46.3z"
                />
                <path
                    style={{ fill }}
                    d="M18.1,28.9c-0.9,0-1.9-0.4-2.5-1.2c0-0.1,0-0.1-0.1-0.1L7.7,38.9c0.7,0.1,1.3,0.5,1.8,1.1
		c0.1,0.2,0.3,0.5,0.4,0.6L18.1,28.9z"
                />
                <path
                    style={{ fill }}
                    d="M50.5,18.7c-0.7-0.5-1-1.3-1-2.3c0-0.1,0-0.2,0-0.3L22,22.7c0.7,0.5,1.3,1.4,1.3,2.4v0.1L50.5,18.7z"
                />
            </g>
        </svg>
    );
};

export default LineIcon;
