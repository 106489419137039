interface PlanningIconProps {
    className?: string;
    fill?: string;
}

const PlanningIcon = ({ className, fill = '#4da4da' }: PlanningIconProps) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
            className={className}
        >
            <g>
                <g transform="translate(0,-952.36218)">
                    <path
                        style={{ fill }}
                        d="M21.1,953.5c-1.8,0-3,1.3-3,3v3H8.2H8c-1.5,0.3-2.8,1.5-2.8,3v45.8c0,1.8,1.3,3,3,3h43.6c1.8,0,3-1.3,3-3
			v-45.8c0-1.8-1.3-3-3-3h-9.9v-3c0-1.8-1.3-3-3-3H21.1z M36.8,958.5v8.6H23.3v-8.6H36.8z M9.5,963.9H18v4.1c0,2,1.4,3.3,3.3,3.3
			h17.5c2,0,3.3-1.4,3.3-3.3v-4.1h8.5v43.5H9.5V963.9z"
                    />
                </g>
                <path
                    style={{ fill }}
                    d="M41.7,27.8c-1-1.3-3-1.3-4.3,0l-9.1,9.4l-4.1-4.1c-1-1.3-3-1.3-4.3,0c-1.3,1-1.3,3,0,4.3l6.3,6.1l0,0
		c0.3,0.3,0.5,0.5,1,0.8c0.8,0.3,1.5,0.3,2.3,0c0.3-0.3,0.8-0.5,1-0.8l0,0l11.4-11.4C42.9,30.8,42.9,29,41.7,27.8z"
                />
            </g>
        </svg>
    );
};

export default PlanningIcon;
