import { useTranslation } from 'react-i18next';
import type { GeoFeature, GeoPolygon } from '@fieldmargin/webapp-geo';
import { calcPerimeter } from '@fieldmargin/webapp-geo';
import type { SingleParamVoidFunction } from 'system/types';
import FormattedLine from 'view/units/FormattedLine';

import ShapeBubble from './ShapeBubble';

interface PerimeterShapeBubbleProps {
    index?: number;
    geoFeature: GeoFeature;
    onHoverGeoFeature?: SingleParamVoidFunction<string | number | null>;
    onRemove?: SingleParamVoidFunction<string | number>;
}

const PerimeterShapeBubble = ({
    index,
    geoFeature,
    onHoverGeoFeature,
    onRemove,
}: PerimeterShapeBubbleProps) => {
    const { t } = useTranslation();
    const perimeter = t('perimeter');
    const label = index ? `${perimeter} ${index}` : perimeter;
    return (
        <ShapeBubble
            geoFeature={geoFeature}
            label={
                <>
                    {label}:{' '}
                    <FormattedLine
                        lengthMetres={calcPerimeter(geoFeature.geometry as GeoPolygon)}
                        withUnit
                    />
                </>
            }
            onHoverGeoFeature={onHoverGeoFeature}
            onRemove={onRemove}
        />
    );
};

export default PerimeterShapeBubble;
