interface DownloadIconProps {
    fill?: string;
    className?: string;
}

const DownloadIcon = ({ fill, className }: DownloadIconProps) => {
    return (
        <svg
            width="700pt"
            height="700pt"
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill }}
            className={className}
        >
            <g>
                <path d="m572.32 381.08v98.422c0 1.9609-0.28125 3.7812-0.69922 5.4609-1.9609 9.6602-10.5 16.941-20.578 16.941l-402.22-0.003906c-10.781 0-19.738-8.2617-20.859-18.762-0.28125-1.2617-0.28125-2.3789-0.28125-3.7812v-98.422c0-11.621 9.5195-21 21-21 5.7383 0 11.059 2.3789 14.84 6.1602s6.1602 9.1016 6.1602 14.84v78.82h360.78v-78.82c0-11.621 9.5195-21 21-21 5.7383 0 11.059 2.3789 14.84 6.1602 3.6406 3.9258 6.0195 9.2461 6.0195 14.984z" />
                <path d="m471.1 282.52-103.18 103.18c-0.14062 0.28125-0.42188 0.42188-0.55859 0.55859-3.7812 3.7812-8.6797 6.1602-13.578 6.8594-0.42188 0-0.83984 0.14062-1.2617 0.14062-0.83984 0.14063-1.6797 0.14063-2.5195 0.14063l-2.3789-0.14063c-0.42188 0-0.83984-0.14062-1.2617-0.14062-5.0391-0.69922-9.8008-3.0781-13.578-6.8594-0.14062-0.14062-0.42188-0.42187-0.55859-0.55859l-103.18-103.18c-4.7617-4.7617-7.1406-11.059-7.1406-17.359 0-6.3008 2.3789-12.602 7.1406-17.359 9.5195-9.5195 25.059-9.5195 34.719 0l62.02 62.02-0.003906-227.22c0-13.441 11.059-24.5 24.5-24.5 6.7188 0 12.879 2.8008 17.359 7.1406 4.4805 4.4805 7.1406 10.5 7.1406 17.359v227.22l62.02-62.02c9.5195-9.5195 25.059-9.5195 34.719 0 9.1016 9.6602 9.1016 25.199-0.41797 34.719z" />
                <path d="m4.6875-0.28125c-0.24219 0.125-0.49219 0.21875-0.75 0.28125-0.26172 0.0625-0.53906 0.09375-0.82812 0.09375-0.84375 0-1.5156-0.23438-2.0156-0.70312-0.5-0.47656-0.75-1.125-0.75-1.9375s0.25-1.457 0.75-1.9375c0.5-0.47656 1.1719-0.71875 2.0156-0.71875 0.28906 0 0.56641 0.039063 0.82812 0.10938 0.25781 0.0625 0.50781 0.15625 0.75 0.28125v1.0469c-0.24219-0.16406-0.48047-0.28516-0.71875-0.35938-0.24219-0.070312-0.49219-0.10938-0.75-0.10938-0.46875 0-0.83984 0.15234-1.1094 0.45312-0.27344 0.29297-0.40625 0.70312-0.40625 1.2344s0.13281 0.94922 0.40625 1.25c0.26953 0.29297 0.64062 0.4375 1.1094 0.4375 0.25781 0 0.50781-0.035156 0.75-0.10938 0.23828-0.082031 0.47656-0.20703 0.71875-0.375z" />
                <path d="m3.4375-2.7812c-0.11719-0.050781-0.22656-0.085938-0.32812-0.10938-0.10547-0.03125-0.21094-0.046875-0.3125-0.046875-0.3125 0-0.55859 0.10547-0.73438 0.3125-0.16797 0.19922-0.25 0.48438-0.25 0.85938v1.7656h-1.2188v-3.8281h1.2188v0.625c0.15625-0.25 0.33203-0.42969 0.53125-0.54688 0.20703-0.11328 0.45703-0.17188 0.75-0.17188 0.03125 0 0.070312 0.007813 0.125 0.015625 0.050781 0 0.11719 0.007812 0.20312 0.015625z" />
                <path d="m4.4062-1.9219v0.34375h-2.8594c0.03125 0.29297 0.13281 0.51172 0.3125 0.65625 0.17578 0.13672 0.42578 0.20312 0.75 0.20312 0.25 0 0.50781-0.035156 0.78125-0.10938 0.26953-0.070313 0.55078-0.1875 0.84375-0.34375v0.9375c-0.29297 0.11719-0.58594 0.19922-0.875 0.25-0.29297 0.050781-0.58594 0.078125-0.875 0.078125-0.6875 0-1.2266-0.17188-1.6094-0.51562-0.38672-0.35156-0.57812-0.84766-0.57812-1.4844 0-0.625 0.1875-1.1133 0.5625-1.4688 0.38281-0.36328 0.90625-0.54688 1.5625-0.54688 0.60156 0 1.082 0.18359 1.4375 0.54688 0.36328 0.36719 0.54688 0.85156 0.54688 1.4531zm-1.25-0.40625c0-0.23828-0.070312-0.42578-0.20312-0.5625-0.13672-0.14453-0.3125-0.21875-0.53125-0.21875-0.24219 0-0.4375 0.070313-0.59375 0.20312-0.14844 0.13672-0.23438 0.32812-0.26562 0.57812z" />
                <path d="m2.2969-1.7188c-0.25 0-0.44531 0.042969-0.57812 0.125-0.125 0.085938-0.1875 0.21484-0.1875 0.39062 0 0.14844 0.050781 0.26562 0.15625 0.35938 0.10156 0.085938 0.25 0.125 0.4375 0.125 0.22656 0 0.42188-0.078125 0.57812-0.23438 0.15625-0.16406 0.23438-0.375 0.23438-0.625v-0.14062zm1.875-0.46875v2.1875h-1.2344v-0.5625c-0.16797 0.23047-0.35547 0.39844-0.5625 0.5-0.19922 0.10156-0.44531 0.15625-0.73438 0.15625-0.39844 0-0.71875-0.11328-0.96875-0.34375-0.25-0.22656-0.375-0.53125-0.375-0.90625 0-0.44531 0.15625-0.77344 0.46875-0.98438 0.3125-0.20703 0.79688-0.3125 1.4531-0.3125h0.71875v-0.09375c0-0.19531-0.078125-0.33594-0.23438-0.42188-0.14844-0.09375-0.38281-0.14062-0.70312-0.14062-0.26172 0-0.50781 0.027344-0.73438 0.078125-0.23047 0.054688-0.4375 0.13281-0.625 0.23438v-0.92188c0.25781-0.070312 0.51953-0.125 0.78125-0.15625 0.26953-0.03125 0.53516-0.046875 0.79688-0.046875 0.6875 0 1.1797 0.13672 1.4844 0.40625 0.3125 0.27344 0.46875 0.71484 0.46875 1.3281z" />
                <path d="m1.9219-4.9219v1.0938h1.2656v0.875h-1.2656v1.625c0 0.17969 0.035156 0.29688 0.10938 0.35938 0.070312 0.0625 0.21094 0.09375 0.42188 0.09375h0.625v0.875h-1.0469c-0.48047 0-0.82422-0.097656-1.0312-0.29688-0.19922-0.20703-0.29688-0.55078-0.29688-1.0312v-1.625h-0.60938v-0.875h0.60938v-1.0938z" />
                <path d="m3.1875-3.2656v-2.0469h1.2344v5.3125h-1.2344v-0.54688c-0.16797 0.21875-0.35547 0.38281-0.5625 0.48438-0.19922 0.10156-0.42969 0.15625-0.6875 0.15625-0.48047 0-0.87109-0.1875-1.1719-0.5625-0.30469-0.375-0.45312-0.85156-0.45312-1.4375 0-0.59375 0.14844-1.0781 0.45312-1.4531 0.30078-0.375 0.69141-0.5625 1.1719-0.5625 0.25781 0 0.48828 0.054687 0.6875 0.15625 0.20703 0.10547 0.39453 0.27344 0.5625 0.5zm-0.79688 2.4688c0.25781 0 0.45703-0.09375 0.59375-0.28125 0.13281-0.1875 0.20312-0.46094 0.20312-0.82812 0-0.36328-0.070312-0.64062-0.20312-0.82812-0.13672-0.19531-0.33594-0.29688-0.59375-0.29688-0.26172 0-0.46484 0.10156-0.60938 0.29688-0.13672 0.1875-0.20312 0.46484-0.20312 0.82812 0 0.36719 0.066406 0.64062 0.20312 0.82812 0.14453 0.1875 0.34766 0.28125 0.60938 0.28125z" />
                <path d="m2.625-0.79688c0.25781 0 0.45703-0.09375 0.59375-0.28125 0.14453-0.1875 0.21875-0.46094 0.21875-0.82812 0-0.36328-0.074219-0.64062-0.21875-0.82812-0.13672-0.19531-0.33594-0.29688-0.59375-0.29688-0.26172 0-0.46484 0.10156-0.60938 0.29688-0.13672 0.1875-0.20312 0.46484-0.20312 0.82812 0 0.35547 0.066406 0.63281 0.20312 0.82812 0.14453 0.1875 0.34766 0.28125 0.60938 0.28125zm-0.8125-2.4688c0.16406-0.22656 0.35156-0.39453 0.5625-0.5 0.20703-0.10156 0.44141-0.15625 0.70312-0.15625 0.46875 0 0.85156 0.1875 1.1562 0.5625 0.3125 0.375 0.46875 0.85938 0.46875 1.4531 0 0.58594-0.15625 1.0625-0.46875 1.4375-0.30469 0.375-0.6875 0.5625-1.1562 0.5625-0.26172 0-0.49609-0.054688-0.70312-0.15625-0.21094-0.10156-0.39844-0.26562-0.5625-0.48438v0.54688h-1.2188v-5.3125h1.2188z" />
                <path d="m0.078125-3.8281h1.2344l1.0312 2.5938 0.875-2.5938h1.2188l-1.6094 4.1875c-0.16797 0.42578-0.35938 0.72266-0.57812 0.89062-0.21094 0.17578-0.49219 0.26562-0.84375 0.26562h-0.70312v-0.8125h0.375c0.20703 0 0.35938-0.03125 0.45312-0.09375s0.16406-0.18359 0.21875-0.35938l0.046875-0.10938z" />
                <path d="m0.64062-5.1094h1.6719l1.1719 2.7344 1.1719-2.7344h1.6562v5.1094h-1.2344v-3.7344l-1.1719 2.75h-0.84375l-1.1719-2.75v3.7344h-1.25z" />
                <path d="m4.4375-2.3281v2.3281h-1.2344v-1.7812c0-0.33203-0.011719-0.5625-0.03125-0.6875-0.011719-0.125-0.03125-0.21875-0.0625-0.28125-0.054687-0.070312-0.12109-0.12891-0.20312-0.17188-0.074219-0.050781-0.16797-0.078125-0.28125-0.078125-0.25 0-0.44922 0.10156-0.59375 0.29688-0.14844 0.19922-0.21875 0.46875-0.21875 0.8125v1.8906h-1.2188v-5.3125h1.2188v2.0469c0.1875-0.22656 0.37891-0.39453 0.57812-0.5 0.20703-0.10156 0.44141-0.15625 0.70312-0.15625 0.4375 0 0.76953 0.13672 1 0.40625 0.22656 0.27344 0.34375 0.66797 0.34375 1.1875z" />
                <path d="m3.5781-3.7031v0.92188c-0.26172-0.10156-0.51562-0.17969-0.76562-0.23438-0.24219-0.0625-0.46875-0.09375-0.6875-0.09375-0.23047 0-0.40234 0.03125-0.51562 0.09375-0.11719 0.054687-0.17188 0.14062-0.17188 0.26562 0 0.09375 0.039062 0.16797 0.125 0.21875 0.09375 0.054688 0.25 0.09375 0.46875 0.125l0.21875 0.03125c0.625 0.074219 1.0391 0.20312 1.25 0.39062 0.21875 0.17969 0.32812 0.46484 0.32812 0.85938 0 0.40625-0.15234 0.71484-0.45312 0.92188-0.29297 0.19922-0.74219 0.29688-1.3438 0.29688-0.25 0-0.51172-0.023438-0.78125-0.0625-0.27344-0.03125-0.54688-0.085938-0.82812-0.17188v-0.92188c0.23828 0.11719 0.48828 0.20312 0.75 0.26562 0.25781 0.054687 0.51953 0.078125 0.78125 0.078125 0.23828 0 0.41406-0.03125 0.53125-0.09375 0.125-0.0625 0.1875-0.16016 0.1875-0.29688 0-0.10156-0.042969-0.17969-0.125-0.23438-0.085937-0.0625-0.25-0.10938-0.5-0.14062l-0.21875-0.015625c-0.54297-0.070312-0.92188-0.19531-1.1406-0.375-0.21875-0.1875-0.32812-0.46875-0.32812-0.84375 0-0.40625 0.14062-0.70703 0.42188-0.90625 0.28125-0.19531 0.70703-0.29688 1.2812-0.29688 0.21875 0 0.45312 0.023437 0.70312 0.0625 0.25 0.03125 0.51953 0.085937 0.8125 0.15625z" />
                <path d="m0.64062-5.1094h1.3125v1.875l1.9062-1.875h1.5312l-2.4688 2.4219 2.7188 2.6875h-1.6562l-2.0312-2.0156v2.0156h-1.3125z" />
                <path d="m0.10938-3.8281h1.2188l0.95312 2.6406 0.95312-2.6406h1.2188l-1.5 3.8281h-1.3438z" />
                <path d="m0.59375-5.3125h1.2188v5.3125h-1.2188z" />
                <path d="m3.1094-5.3125v0.79688h-0.67188c-0.17969 0-0.30469 0.03125-0.375 0.09375-0.0625 0.0625-0.09375 0.17188-0.09375 0.32812v0.26562h1.0469v0.875h-1.0469v2.9531h-1.2344v-2.9531h-0.59375v-0.875h0.59375v-0.26562c0-0.41406 0.11328-0.72266 0.34375-0.92188 0.23828-0.19531 0.60156-0.29688 1.0938-0.29688z" />
                <path d="m2.4062-3.0469c-0.27344 0-0.48047 0.10156-0.625 0.29688-0.13672 0.1875-0.20312 0.46875-0.20312 0.84375 0 0.36719 0.066406 0.64844 0.20312 0.84375 0.14453 0.1875 0.35156 0.28125 0.625 0.28125 0.26953 0 0.47266-0.09375 0.60938-0.28125 0.14453-0.19531 0.21875-0.47656 0.21875-0.84375 0-0.375-0.074219-0.65625-0.21875-0.84375-0.13672-0.19531-0.33984-0.29688-0.60938-0.29688zm0-0.875c0.65625 0 1.1719 0.17969 1.5469 0.53125 0.375 0.35547 0.5625 0.85156 0.5625 1.4844 0 0.625-0.1875 1.1172-0.5625 1.4688-0.375 0.35547-0.89062 0.53125-1.5469 0.53125s-1.1719-0.17578-1.5469-0.53125c-0.375-0.35156-0.5625-0.84375-0.5625-1.4688 0-0.63281 0.1875-1.1289 0.5625-1.4844 0.375-0.35156 0.89062-0.53125 1.5469-0.53125z" />
                <path d="m4.1406-3.1875c0.15625-0.23828 0.33594-0.42188 0.54688-0.54688 0.20703-0.125 0.44141-0.1875 0.70312-0.1875 0.4375 0 0.76953 0.13672 1 0.40625 0.22656 0.27344 0.34375 0.66797 0.34375 1.1875v2.3281h-1.2188v-2-0.09375-0.125c0-0.26953-0.042969-0.46875-0.125-0.59375-0.074219-0.125-0.19922-0.1875-0.375-0.1875-0.24219 0-0.42188 0.10156-0.54688 0.29688-0.125 0.1875-0.1875 0.46484-0.1875 0.82812v1.875h-1.2344v-2c0-0.41406-0.039063-0.6875-0.10938-0.8125-0.074219-0.125-0.20312-0.1875-0.39062-0.1875-0.24219 0-0.42188 0.10156-0.54688 0.29688-0.125 0.1875-0.1875 0.46094-0.1875 0.8125v1.8906h-1.2344v-3.8281h1.2344v0.5625c0.14453-0.21875 0.31641-0.37891 0.51562-0.48438 0.19531-0.11328 0.41016-0.17188 0.64062-0.17188 0.26953 0 0.50391 0.070313 0.70312 0.20312 0.20703 0.125 0.36328 0.30469 0.46875 0.53125z" />
                <path d="m0.64062-5.1094h1.4688l1.8594 3.5v-3.5h1.25v5.1094h-1.4688l-1.8594-3.5v3.5h-1.25z" />
                <path d="m0.54688-1.4844v-2.3438h1.2344v0.375c0 0.21094-0.007812 0.47656-0.015625 0.79688v0.625c0 0.30469 0.003906 0.52344 0.015625 0.65625 0.019531 0.13672 0.050781 0.23438 0.09375 0.29688 0.050781 0.085937 0.11328 0.14844 0.1875 0.1875 0.082031 0.042969 0.17969 0.0625 0.29688 0.0625 0.25 0 0.44531-0.097656 0.59375-0.29688 0.14453-0.19531 0.21875-0.46875 0.21875-0.8125v-1.8906h1.2188v3.8281h-1.2188v-0.54688c-0.1875 0.21875-0.38672 0.38281-0.59375 0.48438-0.19922 0.10156-0.42188 0.15625-0.67188 0.15625-0.44922 0-0.78906-0.13281-1.0156-0.40625-0.23047-0.26953-0.34375-0.66016-0.34375-1.1719z" />
                <path d="m4.4375-2.3281v2.3281h-1.2344v-1.7812c0-0.33203-0.011719-0.5625-0.03125-0.6875-0.011719-0.125-0.03125-0.21875-0.0625-0.28125-0.054687-0.070312-0.12109-0.12891-0.20312-0.17188-0.074219-0.050781-0.16797-0.078125-0.28125-0.078125-0.25 0-0.44922 0.10156-0.59375 0.29688-0.14844 0.19922-0.21875 0.46875-0.21875 0.8125v1.8906h-1.2188v-3.8281h1.2188v0.5625c0.1875-0.22656 0.37891-0.39453 0.57812-0.5 0.20703-0.10156 0.44141-0.15625 0.70312-0.15625 0.4375 0 0.76953 0.13672 1 0.40625 0.22656 0.27344 0.34375 0.66797 0.34375 1.1875z" />
                <path d="m0.64062-5.1094h2.1875c0.64453 0 1.1406 0.14844 1.4844 0.4375 0.35156 0.29297 0.53125 0.70312 0.53125 1.2344s-0.17969 0.94531-0.53125 1.2344c-0.34375 0.29297-0.83984 0.4375-1.4844 0.4375h-0.875v1.7656h-1.3125zm1.3125 0.95312v1.4375h0.73438c0.25 0 0.44141-0.0625 0.57812-0.1875 0.14453-0.125 0.21875-0.30078 0.21875-0.53125 0-0.22656-0.074219-0.40625-0.21875-0.53125-0.13672-0.125-0.32812-0.1875-0.57812-0.1875z" />
                <path d="m0.59375-3.8281h1.2188v3.7656c0 0.50781-0.125 0.89844-0.375 1.1719-0.24219 0.26953-0.59375 0.40625-1.0625 0.40625h-0.60938v-0.8125h0.21875c0.22656 0 0.38281-0.054687 0.46875-0.15625 0.09375-0.10547 0.14062-0.30859 0.14062-0.60938zm0-1.4844h1.2188v0.98438h-1.2188z" />
                <path d="m3.6875-3.7031v1c-0.16797-0.11328-0.33984-0.19531-0.51562-0.25-0.16797-0.0625-0.33984-0.09375-0.51562-0.09375-0.34375 0-0.60938 0.10156-0.79688 0.29688-0.1875 0.19922-0.28125 0.48047-0.28125 0.84375 0 0.35547 0.09375 0.63281 0.28125 0.82812 0.1875 0.19922 0.45312 0.29688 0.79688 0.29688 0.19531 0 0.37891-0.023438 0.54688-0.078125 0.17578-0.0625 0.33594-0.14453 0.48438-0.25v1c-0.19922 0.074219-0.39844 0.125-0.59375 0.15625-0.19922 0.03125-0.39844 0.046875-0.59375 0.046875-0.6875 0-1.2305-0.17578-1.625-0.53125-0.38672-0.35156-0.57812-0.84375-0.57812-1.4688 0-0.63281 0.19141-1.1289 0.57812-1.4844 0.39453-0.35156 0.9375-0.53125 1.625-0.53125 0.20703 0 0.40625 0.023437 0.59375 0.0625 0.19531 0.03125 0.39453 0.085937 0.59375 0.15625z" />
            </g>
        </svg>
    );
};

export default DownloadIcon;
