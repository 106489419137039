import type { MouseEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import c from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';
import type { InputReportSummary } from 'inputs/InputReportSummary';
import { capitaliseWord } from 'lib/util/text';
import { sortBy as sortArrayBy } from 'ramda';
import Num from 'view/Num';
import FormattedRate from 'view/units/FormattedRate';
import ShortUnit from 'view/units/ShortUnit';

import sortAscImg from '../sort-asc.svg';
import sortDescImg from '../sort-desc.svg';
import sortInactiveImg from '../sort-inactive.svg';

import EditInputCostTotalField from './edit-costs/EditInputCostTotalField';
import EditInputCostUnitField from './edit-costs/EditInputCostUnitField';

interface InputSummaryTableProps {
    farm: Farm;
    inputReportSummaries: InputReportSummary[];
    editingCosts?: boolean;
}

const InputSummaryTable = ({
    farm,
    inputReportSummaries,
    editingCosts = false,
}: InputSummaryTableProps) => {
    const { t } = useTranslation();
    const [sortBy, setSortBy] = useState<'name' | 'inputType'>('name');
    const [sortDir, setSortDir] = useState<'asc' | 'desc'>('asc');

    let sortedInputReportSummaries = sortArrayBy((i) => i[sortBy] || i.name, inputReportSummaries);
    if (sortDir === 'desc') {
        sortedInputReportSummaries = sortedInputReportSummaries.reverse();
    }

    const handleSort = (nextSortBy: 'name' | 'inputType') => (e?: MouseEvent) => {
        e && e.preventDefault();
        if (sortBy !== nextSortBy) {
            setSortBy(nextSortBy);
            setSortDir('asc');
        } else {
            setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
        }
    };

    return (
        <table className="ReportingTable">
            <thead>
                <tr>
                    <th className="sortable">
                        <button className="bare-btn sort" onClick={handleSort('name')}>
                            {t('field_details_input')}{' '}
                            <SortArrow active={sortBy === 'name'} direction={sortDir} />
                        </button>
                    </th>
                    <th>{t('input_reg_number')}</th>
                    <th className="sortable">
                        <button className="bare-btn sort" onClick={handleSort('inputType')}>
                            {t('type')}{' '}
                            <SortArrow active={sortBy === 'inputType'} direction={sortDir} />
                        </button>
                    </th>
                    <th>{t('input_summary_total_input_applied')}</th>
                    <th>
                        {t('reporting_unit_cost')} (
                        {getSymbolFromCurrency(farm.costingsCurrency || 'GBP')})
                    </th>
                    <th>
                        {t('reporting_total_cost')} (
                        {getSymbolFromCurrency(farm.costingsCurrency || 'GBP')})
                    </th>
                </tr>
            </thead>
            <tbody>
                {sortedInputReportSummaries.map((inputReportSummary) => (
                    <tr key={inputReportSummary.uuid}>
                        <td>{inputReportSummary.name}</td>
                        <td>{inputReportSummary.pesticideData?.registrationNumber || '-'}</td>
                        <td>{capitaliseWord(inputReportSummary.inputType)}</td>
                        <td>
                            <FormattedRate rate={inputReportSummary.sumTotals} noConversion />{' '}
                            <ShortUnit unit={inputReportSummary.inputUnit} />
                        </td>
                        <td>
                            {editingCosts ? (
                                <EditInputCostUnitField inputReportSummary={inputReportSummary} />
                            ) : (
                                <Num value={inputReportSummary.unitCost} />
                            )}
                        </td>
                        <td>
                            {editingCosts ? (
                                <EditInputCostTotalField inputReportSummary={inputReportSummary} />
                            ) : (
                                <Num value={inputReportSummary.totalCost} />
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default InputSummaryTable;

const SortArrow = ({ active, direction }: { active: boolean; direction: 'asc' | 'desc' }) => {
    let src = sortAscImg;
    let alt = 'Arrow pointing down to show items are sorted in ascending order';
    if (!active) {
        src = sortInactiveImg;
        alt =
            'Two arrows, one pointing up and one pointing down to show sort is not active on this column';
    } else if (direction === 'desc') {
        src = sortDescImg;
        alt = 'Arrow pointing down to show items are sorted in descending order';
    }
    return <img alt={alt} className={c('w-6', { 'text-gray-350': !active })} src={src} />;
};
