import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';
import {
    selectDisplayableFarmUsersAndPendingInvitesSortedByOwnerAndFirstName,
    selectShouldShowUserLimitMessage,
} from 'team/farm-users-state';
import type FarmUserLocation from 'team/FarmUserLocation';
import TeamUserCountMessage from 'team/view/TeamUserCountMessage';

import PendingTeamMember from '../view/PendingTeamMember';

import ExistingTeamMember from './ExistingTeamMember';

import './TeamList.css';

interface TeamListProps {
    // This list includes pending invites.
    farmUsers: FarmUser[];
    locations: Record<string, FarmUserLocation> | null;
    showUserLimitMessage: boolean;
}

const TeamList = ({ farmUsers, locations, showUserLimitMessage }: TeamListProps) => {
    const { t } = useTranslation();
    return (
        <>
            <SidebarModule>
                <h3 className="mb-0">{t('team_list_title')}</h3>
            </SidebarModule>
            {showUserLimitMessage && <TeamUserCountMessage />}
            <ul className="TeamList">
                {farmUsers.map((farmUser, i) => (
                    <li key={i}>
                        <SidebarModule className="flex items-center">
                            {farmUser.pending ? (
                                <PendingTeamMember farmUser={farmUser} />
                            ) : (
                                <ExistingTeamMember
                                    farmUser={farmUser}
                                    location={
                                        locations && locations[farmUser.id.toString()]
                                            ? (locations[
                                                  farmUser.id.toString()
                                              ] as FarmUserLocation)
                                            : null
                                    }
                                />
                            )}
                        </SidebarModule>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default connect((state: AppState) => ({
    farmUsers: selectDisplayableFarmUsersAndPendingInvitesSortedByOwnerAndFirstName(state),
    locations: state.farmUsersState.locations,
    showUserLimitMessage: selectShouldShowUserLimitMessage(state),
}))(TeamList);
