import { addHours, intervalToDuration } from 'date-fns';
import type { HerdLocation } from 'herd/Herd';
import type Herd from 'herd/Herd';
import type { List } from 'immutable';

export const selectFieldRestDuration = (herds: Herd[], herdLocations: List<HerdLocation>) => {
    if (herdLocations.size === 0) {
        return undefined;
    }

    const herdUuids = herds.map((h) => h.uuid);
    const relevantHerdLocations = herdLocations.filter((hl) => herdUuids.includes(hl.herdUuid));

    // No undeleted herd locations.
    if (relevantHerdLocations.size === 0) {
        return undefined;
    }

    const latestLocation = relevantHerdLocations.find((hl) => hl.latest);
    // If there's a latest location then a herd is still in the field.
    if (latestLocation !== undefined) {
        return undefined;
    }

    const lastMove = relevantHerdLocations
        .sortBy((hl) => hl.moveDate)
        .reverse()
        .first<HerdLocation>();

    if (lastMove === undefined) {
        return undefined;
    }

    return intervalToDuration({
        start: addHours(lastMove.moveDate, lastMove.durationHours || 0),
        end: new Date(),
    });
};
