import { useCurrentOperation } from 'hooks/useCurrentOperation';
import { selectFullOperationFields } from 'operations/operations-selectors';
import { useAppSelector } from 'system/store';

const useOperationFieldsSelector = () => {
    const { currentOperation } = useCurrentOperation();

    const operationFields = useAppSelector((state) =>
        selectFullOperationFields(state, currentOperation)
    );

    return {
        operationFields,
    };
};

export { useOperationFieldsSelector };
