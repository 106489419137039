import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type { List } from 'immutable';
import type Input from 'inputs/Input';
import { selectActiveInputs } from 'inputs/inputs-state';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import EditInputCostsButton from 'reporting/EditInputCostsButton';
import ExportReportButton from 'reporting/ExportReportButton';
import { getInputReportCsvDownloadLink, getYearInputReport } from 'reporting/reporting-api';
import ReportStatusFilter from 'reporting/ReportStatusFilter';
import type { AppState } from 'system/store';
import { selectUserAreaMeasurementUnit } from 'users/user-state';
import ContactSupportLink from 'view/ContactSupportLink';
import ErrorMessage from 'view/ErrorMessage';
import MarkdownNoPara from 'view/markdown/MarkdownNoPara';

import InputFilter from './InputFilter';
import type { InputReport } from './InputReport';
import InputReportTable from './InputReportTable';

interface InputReportContainerProps {
    farm: Farm;
    year: number;
    measurementUnit: MeasurementUnit;
    inputs: List<Input>;
}

const InputReportContainer = ({
    farm,
    year,
    measurementUnit,
    inputs,
}: InputReportContainerProps) => {
    const { t } = useTranslation();
    const [selectedInputUuids, setSelectedInputUuids] = useState<string[]>([]);
    const [statusFilter, setStatusFilter] = useState('all');
    const [inputReports, setInputReports] = useState<List<InputReport>>();
    const { pending, error, setPromise } = usePromise<List<InputReport>>((inputReports) =>
        setInputReports(
            inputReports.map((inputReport) => {
                const input = inputs.find((input) => input.name === inputReport.input);
                return input !== undefined ? inputReport.set('unit', input.unit) : inputReport;
            })
        )
    );

    useEffect(() => {
        setPromise(
            getYearInputReport(
                farm.uuid,
                year,
                measurementUnit,
                selectedInputUuids,
                statusFilter !== 'all' ? statusFilter : undefined
            )
        );
    }, [farm, year, selectedInputUuids, measurementUnit, statusFilter]);

    const reportHasNoData = !pending && inputReports?.size === 0;

    let children: ReactNode = null;

    if (pending) {
        children = (
            <p>
                <em>Loading report...</em>
            </p>
        );
    } else if (error) {
        children = (
            <ErrorMessage>
                <MarkdownNoPara
                    components={{
                        a: ({ children }) => (
                            <ContactSupportLink className="text-error-text">
                                {children}
                            </ContactSupportLink>
                        ),
                    }}
                >
                    {t('something_went_wrong_or_contact')}
                </MarkdownNoPara>
            </ErrorMessage>
        );
    } else if (reportHasNoData) {
        children = <InputReportEmpty filter={statusFilter} />;
    } else {
        children = inputReports?.map((inputReport, i) => (
            <InputReportTable key={i} farm={farm} inputReport={inputReport} />
        ));
    }

    return (
        <div className="relative">
            <Button
                as={Link}
                to={`/farms/${farm.uuid}/reporting`}
                className="absolute top-0 left-0 flex items-center gap-2"
                variant="subtle"
            >
                {icon('back')} {t('back')}
            </Button>
            <h1 className="uppercase text-center text-2xl">{t('input_report_title')}</h1>
            <div className="flex items-end justify-between mb-4">
                <InputFilter
                    selectedInputUuids={selectedInputUuids}
                    onApply={setSelectedInputUuids}
                />
                <EditInputCostsButton farm={farm} className="mb-1 ml-4" />
                <ReportStatusFilter
                    filter={statusFilter}
                    setFilter={setStatusFilter}
                    className="ml-auto"
                />
                <ExportReportButton
                    filename="input-report"
                    downloadLink={getInputReportCsvDownloadLink(
                        farm.uuid,
                        year,
                        measurementUnit,
                        selectedInputUuids,
                        statusFilter
                    )}
                />
            </div>
            {children}
        </div>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
    year: state.yearsState.currentYear,
    measurementUnit: selectUserAreaMeasurementUnit(state),
    inputs: selectActiveInputs(state),
}))(InputReportContainer);

const InputReportEmpty = ({ filter }: { filter: string }) => {
    const { t } = useTranslation();

    if (filter !== 'all') {
        return (
            <p>
                {t(
                    filter === 'todo'
                        ? 'input_report_empty_complete'
                        : 'input_report_empty_complete'
                )}
            </p>
        );
    }
    return <p>{t('input_report_empty_all')}</p>;
};
