import { useTranslation } from 'react-i18next';
import c from 'classnames';

interface VegetationMapLegendProps {
    top?: string;
    bottom?: string;
    left?: string;
}

export default ({
    top = 'top-20',
    bottom = 'bottom-auto',
    left = 'left-10',
}: VegetationMapLegendProps) => {
    const { t } = useTranslation();
    return (
        <div
            className={c(
                'absolute z-10 bg-fm-blue text-white p-2 pb-1 no-print',
                top,
                bottom,
                left
            )}
        >
            <div className="scale">
                <div className="flex">
                    <div className="w-10 h-2" style={{ backgroundColor: '#d73027' }} />
                    <div className="w-10 h-2" style={{ backgroundColor: '#fdae61' }} />
                    <div className="w-10 h-2" style={{ backgroundColor: '#ffffbf' }} />
                    <div className="w-10 h-2" style={{ backgroundColor: '#a6d96a' }} />
                    <div className="w-10 h-2" style={{ backgroundColor: '#1a9850' }} />
                </div>
                <div className="text-xxs flex justify-between">
                    <span>{t('field_health_map_key_low')}</span>
                    <span>{t('field_health_map_key_high')}</span>
                </div>
            </div>
        </div>
    );
};
