import { createAction, handleActions } from '@fieldmargin/webapp-state';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess, loadingFarm } from 'farms/farm-loading-state';
import { List, Record } from 'immutable';
import { replaceOrAdd } from 'lib/immutil';
import { compareName } from 'lib/util/text';
import { selectCurrentYearOperationsList } from 'operations/operations-selectors';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

import type Output from './Output';

export const OutputState = Record({
    outputs: List<Output>(),
});
export interface OutputState extends ReturnType<typeof OutputState> {}

export const setOutput = createAction<OutputState, Output>('Set output', (state, newOutput) =>
    state.update('outputs', (outputs: List<Output>) =>
        replaceOrAdd(outputs, newOutput, (output) => output.uuid === newOutput.uuid)
    )
);

export const addOutputs = createAction<OutputState, Output[]>('Add outputs', (state, newOutputs) =>
    state.update('outputs', (outputs) => outputs.concat(List(newOutputs)))
);

export const outputsReducer = handleActions<OutputState>(OutputState(), [setOutput, addOutputs], {
    [loadingFarm.toString()]: () => OutputState(),
    [loadedFarmSuccess.toString()]: (state, payload: LoadedFarmSuccessPayload) =>
        state.set('outputs', payload.outputs),
});

export const selectOutputsSortedByStateAndName = createSelector(
    (state: AppState) => state.outputsState.outputs,
    (outputs) => {
        const sorted = outputs.reduce(
            (map, output) => {
                if (output.archived) {
                    map.archived = map.archived.push(output);
                } else {
                    map.active = map.active.push(output);
                }
                return map;
            },
            { active: List<Output>(), archived: List<Output>() }
        );
        sorted.active = sorted.active.sortBy((o) => o.name.toLowerCase());
        sorted.archived = sorted.archived.sortBy((o) => o.name.toLowerCase());
        return sorted;
    }
);

export const selectOutputsSortedByName = createSelector(
    (state: AppState) => state.outputsState.outputs,
    (outputs) => outputs.sortBy((o) => o.name.toLowerCase())
);

export const selectActiveOutputsSortedByName = createSelector(
    (state: AppState) => state.outputsState.outputs,
    (outputs) => outputs.filter((o) => !o.archived).sort(compareName)
);

export const selectedOutputsWithOperationsSortedByName = createSelector(
    selectCurrentYearOperationsList,
    selectOutputsSortedByName,
    (operations, outputs) => {
        const outputUuids = operations
            .map((operation) => operation.summary.outputUuid)
            .filter((x) => !!x);
        return outputs.filter((output) => outputUuids.contains(output.uuid));
    }
);
