import { selectBaseMap } from 'farm-editing/farm-editing-state';
import { useAction } from 'lib/hooks';
import { useAppSelector } from 'system/store';
import BaseMapControl from 'view/openlayers/BaseMapControl';

export const GlobalBaseMapControl = () => {
    const baseMap = useAppSelector((state) => state.farmEditingState.baseMap);
    const select = useAction(selectBaseMap);
    return <BaseMapControl onSelectBasemap={select} selectedBasemap={baseMap} />;
};
