import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { always } from 'ramda';
import ErrorMessage from 'view/ErrorMessage';

interface ShapeAndFieldsFieldProps {
    shapes?: GeoFeatureCollection;
    fields?: string[];
    required?: boolean;
}

/**
 * This provides a form field to react-hook-form, that allows shapes to be validated as part of a
 * larger form. It does not provide a way for the user to alter shapes, it just validates
 * those provided to it.
 *
 * Validation fails if no shapes or fields are passed to it unless the required prop is false.
 *
 * It will show an error message, nothing else.
 */
const ShapeAndFieldsField = ({ shapes, fields, required }: ShapeAndFieldsFieldProps) => {
    const { t } = useTranslation();
    const hasShapes = shapes && shapes.features.size > 0;
    const hasFields = fields && fields.length > 0;

    const { control, getValues, setValue } = useFormContext<{
        shapes: { shapes?: GeoFeatureCollection; fields?: string[] };
    }>();

    useEffect(() => {
        const value = getValues('shapes');
        if (value?.shapes !== shapes || value?.fields !== fields) {
            setValue('shapes', { shapes, fields }, { shouldDirty: true, shouldValidate: true });
        }
    }, [shapes, fields]);

    return (
        <Controller
            name="shapes"
            control={control}
            defaultValue={{ shapes, fields }}
            rules={{
                validate: required
                    ? () => (!hasShapes && !hasFields ? t('register_required') : undefined)
                    : always(undefined),
            }}
            render={({ fieldState }) => (
                <>
                    {fieldState.error && (
                        <ErrorMessage className="px-5 pb-4">
                            {fieldState.error.message}
                        </ErrorMessage>
                    )}
                </>
            )}
        />
    );
};

export default ShapeAndFieldsField;
