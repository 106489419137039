import { isValid, parseISO } from 'date-fns';
import type { useDateFormat } from 'hooks/useDateFormat';

const DATE_FORMAT = 'do MMM yyyy';

export const parseStringToDate = (
    date: string | undefined,
    format: ReturnType<typeof useDateFormat>['format']
) => {
    if (!date) return undefined;

    const parsedDate = parseISO(date);

    if (!isValid(parsedDate)) {
        return undefined;
    }

    return format(parsedDate, DATE_FORMAT);
};
