import { createIconGenerator } from 'lib/util/icon-generator';

import cutIcon from './icons/cut.svg?raw';
import marginIcon from './icons/margin.svg?raw';
import mergeIcon from './icons/merge.svg?raw';
import sliceIcon from './icons/slice.svg?raw';
import undoIcon from './icons/undo.svg?raw';

const subFieldIconSrc = {
    undo: undoIcon,
    cut: cutIcon,
    margin: marginIcon,
    merge: mergeIcon,
    slice: sliceIcon,
};

export const subFieldIcon = createIconGenerator(subFieldIconSrc, 'icon');
