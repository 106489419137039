import type { List } from 'immutable';
import type UserPreferences from 'lib/model/UserPreferences';
import { converter } from 'utils/conversion';

import type Metric from './Metric';

const graphable = ['number', 'integer', 'float', 'percentage', 'temperature', 'humidity'];
export const canGraph = (sensorType: string) => {
    return graphable.indexOf(sensorType) >= 0;
};

export const getGraphableSensorReadings = (metrics: List<Metric>) =>
    metrics.filter((metric) => canGraph(metric.type));

export const convertMetricValueByPreferences = (
    metricType: string,
    value: string | number | null,
    userPreferences: UserPreferences
) => {
    if (
        metricType === 'temperature' &&
        userPreferences.temperatureMeasurementUnit === 'FAHRENHEIT'
    ) {
        return converter.convertCelsiusToFahrenheit(value as number);
    }
    return value;
};

export const deserializeMetricValue = (type: string, value: string | number | null) => {
    switch (type) {
        case 'integer':
            return value !== null ? Math.round(Number(value)) : null;
        case 'number':
        case 'float':
        case 'humidity':
        case 'percentage':
        case 'temperature':
            return value !== null ? Number(value) : null;
        default:
            return value;
    }
};

export const convertMetricValueForStorage = (
    type: string,
    value: string | number,
    userPreferences: UserPreferences
) => {
    const storageValue = deserializeMetricValue(type, value);

    if (
        type === 'temperature' &&
        userPreferences.temperatureMeasurementUnit === 'FAHRENHEIT' &&
        storageValue !== null
    ) {
        return converter.convertFahrenheitToCelsius(storageValue as number);
    }
    return storageValue;
};
