import { useTranslation } from 'react-i18next';
import c from 'classnames';
import PrimaryPlusButton from 'sidebar/modules/PrimaryPlusButton';
import { TutorialTypes } from 'tutorials/TutorialTypes';

import signalGreenSvg from './signal-green.svg';
import signalGreySvg from './signal-grey.svg';
import spinnerGif from './spinner.gif';

interface SensorsListGroupHeaderProps {
    name: string;
    logoUri?: string;
    active: boolean;
    updating?: boolean;
    onAdd?: VoidFunction;

    activeTutorial?: string | null;
}

const SensorsListGroupHeader = ({
    name,
    logoUri,
    active,
    updating = false,
    onAdd,
    activeTutorial = null,
}: SensorsListGroupHeaderProps) => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center bg-gray-100 p-5 separator-bottom">
            <img
                alt="Three radiating wave signals"
                className="w-9 mr-5"
                src={active ? signalGreenSvg : signalGreySvg}
            />
            {logoUri && <img className="w-12 h-12 mr-5" src={logoUri} alt={name + ' logo'} />}
            <h4 className="m-0 font-bold text-lg text-black flex-grow">{name}</h4>
            <div className="w-4 mx-2">
                {updating && <img alt="Loading indicator" src={spinnerGif} />}
            </div>
            {onAdd && (
                <PrimaryPlusButton
                    className={c('flex items-center whitespace-normal max-h-none', {
                        forefront: activeTutorial === TutorialTypes.DATA,
                    })}
                    onClick={onAdd}
                    small={false}
                >
                    {t('sensors_add_manual_sensor')}
                </PrimaryPlusButton>
            )}
        </div>
    );
};

export default SensorsListGroupHeader;
