import { List, Record } from 'immutable';
import { defaultToZero } from 'lib/fp-helpers';
import { parseNumeric } from 'lib/util/number';
import { compareString } from 'lib/util/text';
import { isString } from 'lodash';

import type { OutputUnit } from './Output';

interface OutputReportFieldSummaryDTO {
    fieldUUID: string;
    fieldName: string;
    yieldRate: number | null;
    yieldRateHa: number | null;
    yieldTotal: number;
    income: number;
    totalIncome: number;
}

export interface OutputReportSummaryDTO {
    uuid: string;
    name: string;
    unit: OutputUnit;
    yieldRate: number | string;
    yieldRateHa: number | string;
    yieldTotal: number;
    taskStatus: string;
    fields: OutputReportFieldSummaryDTO[];
    income: number;
    totalIncome: number;
}

export const OutputReportFieldSummary = Record({
    fieldUuid: '',
    fieldName: '',
    yieldRate: 0,
    yieldRateHa: 0,
    yieldTotal: 0,
    income: 0,
    totalIncome: 0,
});
export interface OutputReportFieldSummary extends ReturnType<typeof OutputReportFieldSummary> {}

const OutputReportSummary = Record({
    outputUuid: '',
    outputName: '',
    outputUnit: '',
    yieldRate: 0,
    yieldRateHa: 0,
    yieldTotal: 0,
    fields: List<OutputReportFieldSummary>(),
    income: 0,
    totalIncome: 0,
});
interface OutputReportSummary extends ReturnType<typeof OutputReportSummary> {}

export default OutputReportSummary;

const deserializeOutputFieldSummary = (dto: OutputReportFieldSummaryDTO) =>
    OutputReportFieldSummary({
        fieldUuid: dto.fieldUUID,
        fieldName: dto.fieldName,
        yieldRate: defaultToZero(dto.yieldRate),
        yieldRateHa: defaultToZero(dto.yieldRateHa),
        yieldTotal: dto.yieldTotal,
        income: dto.income,
        totalIncome: dto.totalIncome,
    });

export const deserializeOutputReportSummary = (dto: OutputReportSummaryDTO) =>
    OutputReportSummary({
        outputUuid: dto.uuid,
        outputName: dto.name,
        outputUnit: dto.unit,
        yieldRate: isString(dto.yieldRate) ? parseNumeric(dto.yieldRate) : dto.yieldRate,
        yieldRateHa: isString(dto.yieldRateHa) ? parseNumeric(dto.yieldRateHa) : dto.yieldRateHa,
        yieldTotal: dto.yieldTotal,
        fields: List(dto.fields.map(deserializeOutputFieldSummary)).sort((a, b) =>
            compareString(a.fieldName, b.fieldName)
        ),
        income: dto.income,
        totalIncome: dto.totalIncome,
    });

export const deserializeOutputReportSummaries = (dto: OutputReportSummaryDTO[]) =>
    List(dto.map(deserializeOutputReportSummary)).sort((a, b) =>
        compareString(a.outputName, b.outputName)
    );
