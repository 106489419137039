import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import icon from 'components/icons/icon';
import { selectCurrentFarm } from 'farms/farms-state';
import type Note from 'notes/Note';
import withPageNote from 'notes/withPageNote';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { AppState } from 'system/store';

import styles from './NoteLoader.module.css';

interface NoteLoaderProps {
    farm: Farm;
    note?: Note;
    error?: boolean;
}

/**
 * This page handles showing a loading message if the note that is being viewed has not yet
 * been loaded. This is most likely to happen when the note is viewed on a note page.
 */
const NoteLoader = ({ farm, note, error = false }: NoteLoaderProps) => {
    if (note !== undefined) {
        return (
            <div className={styles.fullNoteLoader}>
                <Outlet />
            </div>
        );
    }

    return (
        <div className={styles.fullNoteLoader}>
            <NoteLoading farm={farm} error={error} />
        </div>
    );
};

const NoteLoading = ({ farm, error }: { farm: Farm; error: boolean }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.fullNoteLoader}>
            <SidebarHeader>
                <SidebarHeaderLink to={`/farms/${farm.uuid}/notes`}>
                    {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                </SidebarHeaderLink>
            </SidebarHeader>
            {error ? (
                <SidebarModule editing>{t('note_load_fail')}</SidebarModule>
            ) : (
                <SidebarModule editing>
                    <em>{t('loading')}</em>
                </SidebarModule>
            )}
        </div>
    );
};

export default connect((state: AppState) => ({
    farm: selectCurrentFarm(state),
}))(withPageNote(NoteLoader));
