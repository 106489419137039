import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { clone } from '@fieldmargin/webapp-geo';
import { useFreshEditingGeoFeatureCollection } from 'fields/sub-field-hooks';
import { selectSelectedSubFields } from 'fields/sub-fields-selectors';
import { updateSubFieldBoundary } from 'fields/sub-fields-state';
import { useClearEditingGeoFeatureCollection } from 'hooks/useClearEditingGeoFeatureCollection';
import { useAppDispatch, useAppSelector } from 'system/store';

const SubFieldBoundaryEdit = () => {
    const dispatch = useAppDispatch();
    const { clearEditingGeoFeatureCollection } = useClearEditingGeoFeatureCollection();
    const subField = useAppSelector(selectSelectedSubFields).get(0);

    useFreshEditingGeoFeatureCollection<string>((editingGeoFeatureCollection, subFieldId) => {
        if (
            subFieldId &&
            editingGeoFeatureCollection !== null &&
            editingGeoFeatureCollection.features.size > 0
        ) {
            dispatch(
                updateSubFieldBoundary({
                    subFieldId,
                    geoFeature: clone(editingGeoFeatureCollection.features.get(0) as GeoFeature),
                })
            );
            clearEditingGeoFeatureCollection();
        }
    }, subField?.id);

    return null;
};

export default SubFieldBoundaryEdit;
