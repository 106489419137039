import { startEditingField } from 'farm-editing/start-editing-reducer';
import type Field from 'fields/Field';
import { useAppDispatch } from 'system/store';

const useStartEditingField = () => {
    const dispatch = useAppDispatch();

    return {
        startEditingField: (field: Field) => dispatch(startEditingField(field)),
    };
};

export { useStartEditingField };
