import { editingLineStyles, geojson } from '@fieldmargin/webapp-ol-map';
import type Feature from 'ol/Feature';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

class PassiveEditController extends geojson.FeaturesController {
    constructor(zIndex: number) {
        super(zIndex, {});
    }

    protected styleFeature(feature: Feature) {
        return [
            new Style({
                fill: new Fill({
                    color: [255, 255, 255, 0.5],
                }),
                text: new Text({
                    font: `bold 12px sans-serif`,
                    fill: new Fill({ color: '#333' }),
                    stroke: new Stroke({ color: '#fff', width: 3 }),
                    text: feature.get('label'),
                    overflow: true,
                }),
            }),
            ...editingLineStyles,
        ];
    }
}

export default PassiveEditController;
