import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import { usePromise } from '@fieldmargin/webapp-state';
import Button from '@fieldmargin/webapp-styling/components/button';
import SubmitButton from '@fieldmargin/webapp-styling/components/button/SubmitButton';
import { selectCurrentFarm } from 'farms/farms-state';
import { setFieldUsages } from 'fields/field-usage-state';
import { updateFieldUsage } from 'fields/field-usages-api';
import type FieldUsage from 'fields/FieldUsage';
import { asBadge } from 'fields/FieldUsage';
import CropTypeFormField from 'fields/usage/CropTypeFormField';
import type { List } from 'immutable';
import { Form } from 'informed';
import { preventDefaultAnd } from 'lib/dom';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import ErrorMessage from 'view/ErrorMessage';
import { required } from 'view/form/validations';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';

const validateCropType = required('Please choose a crop type from the list');

interface PesticideCheckEditUsageProps {
    onBack: VoidFunction;
    onDone: VoidFunction;
    invalidUsages: List<FieldUsage>;

    // From redux
    farm: Farm;
    setFieldUsages: typeof setFieldUsages;
}

interface FormValues {
    usages: {
        [key: string]: {
            cropCode: string;
            cropName: string;
        };
    };
}

const PesticideCheckEditUsage = ({
    onBack,
    onDone,
    invalidUsages,
    farm,
    setFieldUsages,
}: PesticideCheckEditUsageProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise(onDone);
    const handleSubmit = (values: FormValues) => {
        const promises = Promise.all(
            invalidUsages
                .filter((fieldUsage) => values.usages[fieldUsage.uuid] !== undefined)
                .map((fieldUsage) =>
                    updateFieldUsage(farm.uuid, fieldUsage.uuid, {
                        cropCode: values.usages[fieldUsage.uuid].cropCode,
                        name: values.usages[fieldUsage.uuid].cropName,
                    }).then((data) => ({ ...fieldUsage, data }))
                )
        ).then(setFieldUsages);
        setPromise(promises);
    };

    return (
        <Form<FormValues>
            className="PesticideCheckEditUsageModal stacked-form"
            onSubmit={handleSubmit}
        >
            <table className="PesticideCheckUsageTable">
                <thead>
                    <tr>
                        <th>{t('usage')}</th>
                        <th className="PesticideCheckUsageTableCropTypeCell">
                            {t('field_usages_crop_type')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {invalidUsages.map((fieldUsage) => (
                        <tr key={fieldUsage.uuid}>
                            <td>
                                <BadgeItem item={asBadge(fieldUsage)} />
                            </td>
                            <td className="PesticideCheckUsageTableCropTypeCell">
                                <CropTypeFormField
                                    farm={farm}
                                    field={`usages.${fieldUsage.uuid}.crop`}
                                    validate={validateCropType}
                                    label=""
                                    allowOther={false}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {error && <ErrorMessage>{t('something_went_wrong')}</ErrorMessage>}
            <div className="flex justify-center gap-4">
                <Button variant="outline" disabled={pending} onClick={preventDefaultAnd(onBack)}>
                    {t('back')}
                </Button>
                <SubmitButton disabled={pending}>{t('next')}</SubmitButton>
            </div>
        </Form>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
    }),
    (dispatch) => bindActionCreators({ setFieldUsages }, dispatch)
)(PesticideCheckEditUsage);
