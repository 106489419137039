import { useTranslation } from 'react-i18next';
import type { Farm } from '@fieldmargin/webapp-farms';
import type Output from 'outputs/Output';
import SidebarModule from 'sidebar/modules/SidebarModule';
import type { SingleParamVoidFunction } from 'system/types';

import OutputSelector from '../outputs/OutputSelector';
import YieldUpgrade from '../YieldUpgrade';

interface NewOperationYieldProps {
    farm: Farm;
    attachedOutputUuid?: string;
    onSelectOutput: SingleParamVoidFunction<Output>;
    onAddOutput: VoidFunction;
}

const NewOperationYield = ({
    farm,
    attachedOutputUuid,
    onSelectOutput,
    onAddOutput,
}: NewOperationYieldProps) => {
    const { t } = useTranslation();
    if (!farm.plan.yieldRecording) {
        return (
            <>
                <SidebarModule className="pb-0 text-base" noBorder>
                    <h4 className="mb-1">{t('output')}</h4>
                    <p className="text-gray-500">
                        <em>{t('operation_details_add_output_hint')}</em>
                    </p>
                </SidebarModule>
                <YieldUpgrade />
            </>
        );
    }
    return (
        <>
            <SidebarModule className="text-base">
                <h4 className="mb-1">{t('output')}</h4>
                <p className="text-gray-500">
                    <em>{t('operation_details_add_output_hint')}</em>
                </p>
                <OutputSelector
                    selectedOutputUuid={attachedOutputUuid}
                    onSelectOutput={onSelectOutput}
                    onAddOutput={onAddOutput}
                />
            </SidebarModule>
        </>
    );
};

export default NewOperationYield;
