import type { ChangeEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import icon from 'components/icons/icon';
import type { Nullable, SingleParamVoidFunction } from 'system/types';

import styles from './SearchBar.module.css';

const ICON_COLOUR = 'blue';

interface SearchBarProps {
    value: Nullable<string>;
    searchFunction: SingleParamVoidFunction<string>;
    className?: string;
    placeholderI18nKey?: string;
}

const SearchBar = ({
    value,
    searchFunction,
    className,
    placeholderI18nKey = 'search',
}: SearchBarProps) => {
    const { t } = useTranslation();

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        searchFunction(e.target.value);
    };

    const handleClearSearch = (e: MouseEvent) => {
        e.preventDefault();
        searchFunction('');
    };

    const searchIcon =
        value === null || value === '' ? (
            icon('search', ICON_COLOUR)
        ) : (
            <button className="bare-btn focus" onClick={handleClearSearch}>
                {icon('close', ICON_COLOUR)}
            </button>
        );

    return (
        <div className={clsx(styles.searchBar, className)}>
            <input
                type="text"
                className={`simple-text-input ${styles.input}`}
                placeholder={t(placeholderI18nKey)}
                onChange={handleSearch}
                value={value ?? ''}
            />
            <div className={`${styles.icon} centered`}>{searchIcon}</div>
        </div>
    );
};

export { SearchBar };
