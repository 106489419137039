import { useToggle } from '@fieldmargin/webapp-state';
import { useEditingGeoFeatureCollection } from 'hooks/useEditingGeoFeatureCollection';
import { useEditingNewFieldEffect } from 'hooks/useEditingNewFieldEffect';
import { useShowHintOnMount } from 'lib/firebase/firebase-hints-api';
import SidebarModule from 'sidebar/modules/SidebarModule';

import FieldBoundaryContainer from '../boundary/FieldBoundaryContainer';
import FieldBoundaryHelpModal from '../boundary/FieldBoundaryHelpModal';

function FieldNewBoundary() {
    const [showHelpModal, toggleHelpModal] = useToggle(false);
    const { editingGeoFeatureCollection } = useEditingGeoFeatureCollection();

    useShowHintOnMount('field-creation', toggleHelpModal);
    useEditingNewFieldEffect();

    return (
        <SidebarModule editing className="p-0">
            {showHelpModal && <FieldBoundaryHelpModal onClose={toggleHelpModal} />}
            <FieldBoundaryContainer
                editingGeoFeatureCollection={editingGeoFeatureCollection}
                onInfoClick={toggleHelpModal}
                includeAutoBoundary
            />
        </SidebarModule>
    );
}

export default FieldNewBoundary;
