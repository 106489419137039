import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadList } from 'components/download-list';
import { useDownloadFieldBoundry } from 'hooks/useDownloadFieldBoundry';

function FieldBoundryDownloadItems() {
    const { t } = useTranslation();
    const { handleGeoJsonDownload, handleKmlDownload, handleShpDownload } =
        useDownloadFieldBoundry();

    const fieldBoundryItems = [
        {
            fileName: 'KML',
            handler: handleKmlDownload,
        },

        {
            fileName: 'Shp',
            handler: handleShpDownload,
        },

        {
            fileName: 'GeoJSON',
            handler: handleGeoJsonDownload,
        },
    ];

    return (
        <Fragment>
            {fieldBoundryItems.map((item) => (
                <DownloadList.Button key={item.fileName} onPress={item.handler}>
                    {t('download_field_boundry_file', { sprintf: [item.fileName] })}
                </DownloadList.Button>
            ))}
        </Fragment>
    );
}

export { FieldBoundryDownloadItems };
