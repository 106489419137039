import type FarmEditingState from 'farm-editing/farm-editing-state';
import type { List, Set } from 'immutable';
import { Record } from 'immutable';
import type MapGroup from 'maps/farm/MapGroup';
import { VisibilityOptions } from 'system/types';

export const MapVisibilityKey = Record({
    mapUuid: '',
    farmIntegrationUuid: '',
    visibility: VisibilityOptions.ON,
});
export interface MapVisibilityKey extends ReturnType<typeof MapVisibilityKey> {}

export const setMapGroupVisibilityReducer = (
    state: FarmEditingState,
    { mapGroup, value }: { mapGroup: MapGroup; value: VisibilityOptions }
) => {
    if (state.layerVisibility.hiddenMapIntegrations.has(mapGroup.farmIntegrationUuid)) {
        // Integration is hidden, we want to enable it with only the single map displayed
        // So turn off all other maps in this integration
        state = state.updateIn(
            ['layerVisibility', 'visibleMapGroups'],
            (mapGroups: List<MapVisibilityKey>) => {
                return mapGroups.filter(
                    (key) => key.farmIntegrationUuid !== mapGroup.farmIntegrationUuid
                );
            }
        );
    }

    return state
        .updateIn(['layerVisibility', 'visibleMapGroups'], (mapGroups: List<MapVisibilityKey>) => {
            // Move the map to the end of the list so it renders on top
            mapGroups = mapGroups.filter((key) => key.mapUuid !== mapGroup.uuid);
            if (value !== 'off') {
                return mapGroups.push(
                    MapVisibilityKey({
                        mapUuid: mapGroup.uuid,
                        farmIntegrationUuid: mapGroup.farmIntegrationUuid,
                        visibility: value,
                    })
                );
            }
            return mapGroups;
        })
        .updateIn(
            ['layerVisibility', 'hiddenMapIntegrations'],
            (hiddenMapIntegrations: Set<string>) =>
                hiddenMapIntegrations.delete(mapGroup.farmIntegrationUuid)
        );
};

export const setMapIntegrationVisibilityReducer = (
    state: FarmEditingState,
    { farmIntegrationUuid, visible }: { farmIntegrationUuid: string; visible: boolean }
) => {
    return state.updateIn(
        ['layerVisibility', 'hiddenMapIntegrations'],
        (hiddenMapIntegrations: Set<string>) =>
            visible
                ? hiddenMapIntegrations.delete(farmIntegrationUuid)
                : hiddenMapIntegrations.add(farmIntegrationUuid)
    );
};

export const getMapVisibility = (
    visibleMapGroups: List<MapVisibilityKey>,
    hiddenMapIntegrations: Set<string>,
    mapGroup: MapGroup
): VisibilityOptions => {
    const integrationHidden = !!hiddenMapIntegrations.has(mapGroup.farmIntegrationUuid);
    const mapVisibility = visibleMapGroups.find((key) => key.mapUuid === mapGroup.uuid);

    if (integrationHidden || !mapVisibility) {
        return VisibilityOptions.OFF;
    }

    return mapVisibility.visibility;
};
