import { createIconGenerator } from 'lib/util/icon-generator';

import clearDayIcon from './clear-day.svg?raw';
import clearNightIcon from './clear-night.svg?raw';
import cloudyIcon from './cloudy.svg?raw';
import fogIcon from './fog.svg?raw';
import partlyCloudyDayIcon from './partly-cloudy-day.svg?raw';
import partlyCloudyNightIcon from './partly-cloudy-night.svg?raw';
import rainIcon from './rain.svg?raw';
import sleetIcon from './sleet.svg?raw';
import snowIcon from './snow.svg?raw';
import windIcon from './wind.svg?raw';

const src = {
    'clear-day': clearDayIcon,
    'clear-night': clearNightIcon,
    cloudy: cloudyIcon,
    fog: fogIcon,
    'partly-cloudy-day': partlyCloudyDayIcon,
    'partly-cloudy-night': partlyCloudyNightIcon,
    rain: rainIcon,
    sleet: sleetIcon,
    snow: snowIcon,
    wind: windIcon,
};

export default createIconGenerator(src, 'weather-icon', 'cloudy');
