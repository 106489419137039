import type { ComponentProps } from 'react';

function UnlockIcon({ fill = 'currentColor', ...props }: ComponentProps<'svg'>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 19" {...props}>
            <path
                fill={fill}
                d="M4.203 6.333h6.305V4.749c0-.66-.204-1.22-.613-1.682-.409-.462-.905-.693-1.489-.693-.583 0-1.08.231-1.488.693-.409.462-.613 1.023-.613 1.682H4.904c0-1.095.341-2.028 1.024-2.8C6.611 1.177 7.438.79 8.406.79c.97 0 1.795.386 2.478 1.158.684.772 1.025 1.705 1.025 2.8v1.584h.7c.386 0 .716.155.99.465.274.31.412.683.412 1.118v7.917c0 .435-.138.808-.412 1.118-.274.31-.604.465-.99.465H4.204c-.385 0-.715-.155-.99-.465a1.63 1.63 0 0 1-.41-1.118V7.916c0-.435.136-.808.41-1.118.275-.31.605-.465.99-.465Zm0 9.5h8.407V7.916H4.203v7.917Zm4.203-2.375c.386 0 .716-.155.99-.465a1.63 1.63 0 0 0 .411-1.119 1.63 1.63 0 0 0-.411-1.118c-.274-.31-.604-.465-.99-.465-.385 0-.715.155-.989.465a1.63 1.63 0 0 0-.412 1.118c0 .436.138.808.412 1.119.274.31.604.465.99.465Z"
            />
        </svg>
    );
}

export { UnlockIcon };
