import { useTranslation } from 'react-i18next';
import type FieldUsage from 'fields/FieldUsage';
import { asBadge } from 'fields/FieldUsage';
import SidebarModule from 'sidebar/modules/SidebarModule';
import BadgeItem from 'view/molecules/badge-item/BadgeItem';

interface FieldUsageChosenProps {
    onEditUsage: VoidFunction;
    selectedFieldUsage: FieldUsage;
}

const FieldUsageChosen = ({ onEditUsage, selectedFieldUsage }: FieldUsageChosenProps) => {
    const { t } = useTranslation();
    return (
        <SidebarModule>
            <button
                className="bare-btn float-right text-fm-sky uppercase focus rounded"
                onClick={onEditUsage}
            >
                {t('edit')}
            </button>
            <h4>{t('field_usage_title')}</h4>
            <BadgeItem item={asBadge(selectedFieldUsage)} />
        </SidebarModule>
    );
};

export default FieldUsageChosen;
