import { useTranslation } from 'react-i18next';

import introImg from './images/intro.png';

const SubFieldsHelpIntro = () => {
    const { t } = useTranslation();
    return (
        <div style={{ width: 600 }}>
            <h2 className="text-center text-sm">{t('what_are_subfields')}</h2>
            <div className="flex">
                <div className="mr-4">
                    <p>{t('sub_fields_help_intro1')}</p>
                    <p>{t('sub_fields_help_intro2')}</p>
                    <p>
                        <strong>{t('sub_fields_help_intro3')}</strong>
                    </p>
                </div>
                <img src={introImg} alt="Image showing a field split into sub fields" />
            </div>
        </div>
    );
};

export default SubFieldsHelpIntro;
