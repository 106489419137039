import { createAction, handleActions } from '@fieldmargin/webapp-state';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess } from 'farms/farm-loading-state';
import { List, Record } from 'immutable';
import { getUuid, uuidMatches } from 'lib/fp-helpers';
import { listToMap, replaceOrAdd } from 'lib/immutil';

import type FieldUsage from './FieldUsage';

class FieldUsageState extends Record({
    fieldUsages: null as List<FieldUsage> | null,
}) {}
export default FieldUsageState;

export const setFieldUsage = createAction<FieldUsageState, FieldUsage>(
    'Field usage: Set field usage',
    (state, payload) =>
        state.set(
            'fieldUsages',
            replaceOrAdd(
                state.fieldUsages ?? List<FieldUsage>(),
                payload,
                uuidMatches(payload.uuid)
            )
        )
);

export const setFieldUsages = createAction<FieldUsageState, FieldUsage[]>(
    'Field usage: Set field usages',
    (state, payload) =>
        state.update('fieldUsages', (fieldUsages) => {
            const newUsageMap = listToMap(List(payload), getUuid);
            return fieldUsages === null
                ? List(payload)
                : fieldUsages.map((fieldUsage) =>
                      newUsageMap.has(fieldUsage.uuid)
                          ? newUsageMap.get(fieldUsage.uuid)! // We know this is safe because we just checked for it
                          : fieldUsage
                  );
        })
);

export const fieldUsageReducer = handleActions<FieldUsageState>(
    new FieldUsageState(),
    [setFieldUsage, setFieldUsages],
    {
        [loadedFarmSuccess.toString()]: (state, action: LoadedFarmSuccessPayload) =>
            state.set('fieldUsages', action.fieldUsages),
    }
);
