import { pluralise } from 'lib/util/text';
import Num from 'view/Num';

interface FormattedPointsProps {
    points: number;
    withUnit?: boolean;
}

const FormattedPoints = ({ points, withUnit = false }: FormattedPointsProps) => {
    return (
        <>
            <Num value={points} />
            {withUnit && <> {pluralise(points, 'point', 'points')}</>}
        </>
    );
};

export default FormattedPoints;
