import type { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { useState } from 'react';
import c from 'classnames';
import type { StopPropagation } from 'lib/dom';
import { stopPropagation } from 'lib/dom';
import type { SingleParamVoidFunction } from 'system/types';

import OptionalLabel from '../OptionalLabel';

/**
 * This component should be used when you want a text input that is not managed by informed.
 */
interface NativeTextFormFieldProps {
    id: string;
    value: string;
    onChange: SingleParamVoidFunction<string>;
    optional?: boolean;
    label?: ReactNode;
    validate?: (value: string) => string | undefined;
    validateOnChange?: boolean;
    validateOnBlur?: boolean;
    toggleError?: SingleParamVoidFunction<string>;
    className?: string;
    inputClassName?: string;
    disabled?: boolean;
}

const NativeTextFormField = ({
    id,
    value,
    onChange,
    optional = false,
    label,
    validate,
    validateOnChange = false,
    validateOnBlur = false,
    toggleError,
    className,
    inputClassName,
    disabled = false,
}: NativeTextFormFieldProps) => {
    const [error, setError] = useState<string>();

    const handleValidation = (errorStr?: string) => {
        if (errorStr !== undefined && error === undefined) {
            setError(errorStr);
            toggleError && toggleError(id);
        } else if (errorStr === undefined && error !== undefined) {
            setError(undefined);
            toggleError && toggleError(id);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
        if (validateOnChange && validate) {
            handleValidation(validate(e.target.value));
        }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        if (validateOnBlur && validate) {
            handleValidation(validate(e.target.value));
        }
    };

    const handleKeyDown: StopPropagation<KeyboardEvent<HTMLInputElement>> = stopPropagation;
    const handleClick: StopPropagation<MouseEvent> = stopPropagation;

    return (
        <div className={c('form-field', className)}>
            {label && (
                <label htmlFor={id}>
                    {label}
                    {optional && (
                        <>
                            {' '}
                            <OptionalLabel />
                        </>
                    )}
                </label>
            )}
            <input
                id={id}
                type="text"
                className={c('simple-text-input', inputClassName, {
                    'has-error': error,
                })}
                autoComplete="off"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onClick={handleClick}
                disabled={disabled}
            />
            {error && <div className="field-error">{error}</div>}
        </div>
    );
};

export default NativeTextFormField;
