import { Input, NumberField } from 'react-aria-components';
import { Controller, useFormContext } from 'react-hook-form';
import { clsx } from 'clsx';
import type { SingleParamVoidFunction } from 'system/types';

interface NumberInputFieldProps {
    field: string;
    validate?: (value: any) => string | undefined;
    className?: string;
    inputClassName?: string;
    onChange?: SingleParamVoidFunction<number>;
    minValue?: number;
    initialValue?: number;
    disabled?: boolean;
    hideErrorMessage?: boolean;
}

const NumberInputField = ({
    field,
    validate,
    className,
    inputClassName,
    onChange,
    minValue,
    initialValue,
    disabled = false,
    hideErrorMessage = false,
}: NumberInputFieldProps) => {
    const { control } = useFormContext();
    return (
        <Controller
            control={control}
            name={field}
            defaultValue={initialValue}
            rules={{ validate }}
            render={({ field, fieldState }) => (
                <>
                    <NumberField
                        value={field.value}
                        onChange={(value) => {
                            field.onChange(value);
                            if (onChange) onChange(value);
                        }}
                        className={className}
                        minValue={minValue}
                        isDisabled={disabled}
                        formatOptions={{
                            maximumSignificantDigits: 21,
                        }}
                    >
                        <Input
                            className={clsx('simple-text-input', inputClassName)}
                            autoComplete="off"
                        />
                    </NumberField>
                    {!hideErrorMessage && fieldState.error && (
                        <div className="field-error">{fieldState.error.message}</div>
                    )}
                </>
            )}
        />
    );
};

export default NumberInputField;
