import { useTranslation } from 'react-i18next';
import { herdIcon } from 'herd/herd-icons';
import ItemHistoryItem from 'sidebar/modules/item-history/ItemHistoryItem';
import { useFarmUserDisplayName } from 'team/farm-users-hooks';

import type { FieldHistoryHerdItem, FieldHistoryItem } from './field-history-generator';

interface FieldHistoryHerdMoveItemProps {
    // From parent
    historyItem: FieldHistoryItem;
}

const FieldHistoryHerdMoveItem = ({ historyItem }: FieldHistoryHerdMoveItemProps) => {
    const { t } = useTranslation();
    const getDisplayName = useFarmUserDisplayName();
    const item = historyItem.item as FieldHistoryHerdItem;

    return (
        <ItemHistoryItem>
            {herdIcon(item.herdType, 'blue')}
            <div className="flex flex-col">
                <strong>
                    {item.herdLocation.size} {item.herdName} {t('activity_log_added')}
                </strong>
                <span className="ItemHistorySubText">
                    {t('created_by')} {getDisplayName(item.herdLocation.createdByUserId)}
                </span>
            </div>
        </ItemHistoryItem>
    );
};

export default FieldHistoryHerdMoveItem;
