import type { ReactNode } from 'react';
import { FilterSelect } from 'components/filters/FilterSelect';

import styles from './JohnDeereSelect.module.css';

interface DataItem {
    name: string;
    id: string;
}

interface JohnDeereSelectProps {
    data: DataItem[] | undefined;
    label: ReactNode;
    onSelect: ({ item, index }) => void;
}

function JohnDeereSelect({ data, label, onSelect }: JohnDeereSelectProps) {
    return (
        <FilterSelect.Root>
            <FilterSelect.ButtonChevron>{label}</FilterSelect.ButtonChevron>

            {data && (
                <FilterSelect.Popover>
                    <div className={styles.itemsGroup}>
                        {data.map((item, index) => (
                            <div
                                className={styles.selectItem}
                                key={item.id}
                                onClick={() => onSelect({ item, index })}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                </FilterSelect.Popover>
            )}
        </FilterSelect.Root>
    );
}

export { JohnDeereSelect };
