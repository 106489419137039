import { authorizedRequest } from '@fieldmargin/webapp-auth';
import type { PendingFarmInvite } from '@fieldmargin/webapp-farms/dist/PendingFarmInvite';
import type { FarmInviteDTO } from 'farms/invites/FarmInvite';
import type FarmInvite from 'farms/invites/FarmInvite';
import { deserializeFarmInvite } from 'farms/invites/FarmInvite';
import type UserPreferences from 'lib/model/UserPreferences';
import { deserializeUserPreferences } from 'lib/model/UserPreferences';

export const getFarmInvitesApi = (farmInviteUuid?: string) => {
    let url = '/farms-api/v2/farms/pendingInvitesForUser/';
    if (farmInviteUuid) {
        url += `?farmInviteUUID=${farmInviteUuid}`;
    }
    return authorizedRequest<FarmInviteDTO[]>({
        method: 'get',
        url,
    }).then((invites) => invites.map(deserializeFarmInvite));
};

export const getPendingFarmInvitesApi = (farmUuid: string) =>
    authorizedRequest<PendingFarmInvite[]>({
        url: `/farms-api/v2/farms/${farmUuid}/users/pendingInvitesForFarm/`,
    });

export const acceptInviteApi = (farmInvite: FarmInvite) => {
    const { farm, uuid } = farmInvite;
    return authorizedRequest({
        method: 'post',
        url: `/farms-api/v2/farms/${farm.uuid}/users/acceptInvite/?uuid=${uuid}`,
    }).then(() => farmInvite);
};

export const declineInviteApi = (farmInvite: FarmInvite) => {
    const { farm, uuid } = farmInvite;
    return authorizedRequest({
        method: 'post',
        url: `/farms-api/v2/farms/${farm.uuid}/users/declineInvite/?uuid=${uuid}`,
    }).then(() => farmInvite);
};

export const getUserPreferencesApi = () => {
    return authorizedRequest<UserPreferences>({
        url: '/notes-api/users/preference/',
        method: 'get',
    }).then(deserializeUserPreferences);
};

export const updateUserPreferencesApi = (userPreferences: UserPreferences) => {
    return authorizedRequest<UserPreferences>({
        url: '/notes-api/users/preference/',
        method: 'post',
        data: userPreferences,
    }).then(deserializeUserPreferences);
};

export const acceptTermsApi = () => {
    return authorizedRequest({
        url: '/auth-api/account/legal/acceptTerms',
        method: 'post',
    });
};

export const resendVerificationEmail = () => {
    return authorizedRequest({
        method: 'post',
        url: '/auth-api/account/activate/init',
    }).then(() => null);
};
