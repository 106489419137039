import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import c from 'classnames';

interface TopNavProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {}

const TopNav = ({ children, className, ...props }: TopNavProps) => {
    return (
        <header className={c('h-15 relative z-40 flex no-print', className)} {...props}>
            {children}
        </header>
    );
};

export default TopNav;
