import c from 'classnames';

import SidebarModule from '../SidebarModule';

import TitleText from './TitleText';

import './Title.css';

interface NewTitleProps {
    field: string;
    label: string;
    className?: string;
    onChange?: (value: string | undefined) => void;
    placeholder?: string;
}

const NewTitle = ({ field, label, className, onChange, placeholder }: NewTitleProps) => {
    return (
        <SidebarModule className={c('Title', className)} editing={true}>
            <h4>{label}</h4>
            <TitleText field={field} onChange={onChange} placeholder={placeholder} />
        </SidebarModule>
    );
};

export default NewTitle;
