import type { ComponentProps } from 'react';
import { clsx } from 'clsx';
import { ButtonBase } from 'components/button-base';
import { SidebarCard } from 'components/sidebar-card';

import { ButtonBack } from '../Buttons/ButtonBack/ButtonBack';

import styles from './Sidebar.module.css';

type SidebarWrapperProps = ComponentProps<'div'>;
type SidebarBodyProps = ComponentProps<'div'>;
type SidebarHeaderProps = ComponentProps<typeof SidebarCard.Root>;
type SidebarHeadingProps = ComponentProps<'div'>;
type SidebarDescriptionProps = ComponentProps<'span'>;
type SidebarFooterProps = ComponentProps<'div'>;
type SidebarFooterButton = ComponentProps<typeof ButtonBase.Root>;
type SidebarBoxProps = ComponentProps<typeof SidebarCard.Root>;

type SidebarNavigationProps = ComponentProps<typeof ButtonBack>;

export function SidebarWrapper({ ...props }: SidebarWrapperProps) {
    return <div className={styles.sidebarWrapper} {...props} />;
}

export function SidebarNavigation({ ...props }: SidebarNavigationProps) {
    return (
        <div>
            <ButtonBack {...props} />
        </div>
    );
}

export function SidebarBody({ ...props }: SidebarBodyProps) {
    return <div className={styles.sidebarContainer} {...props} />;
}

export function SidebarBox({ className, ...props }: SidebarBoxProps) {
    return <SidebarCard.Root className={clsx(styles.sidebarBox, className)} {...props} />;
}

export function SidebarHeader({ ...props }: SidebarHeaderProps) {
    return <SidebarCard.Root className={styles.sidebarHeader} {...props} />;
}

export function SidebarHeading({ ...props }: SidebarHeadingProps) {
    return <div className={styles.sidebarHeading} {...props} />;
}

export function SidebarTitle({ className, ...props }: SidebarDescriptionProps) {
    return <span className={clsx(styles.sidebarTitle, className)} {...props} />;
}

export function SidebarDescription({ className, ...props }: SidebarDescriptionProps) {
    return <span className={clsx(styles.sidebarDescription, className)} {...props} />;
}

export function SidebarFooter({ ...props }: SidebarFooterProps) {
    return <div className={styles.sidebarFooter} {...props} />;
}

export function SidebarFooterButton({
    children,

    ...props
}: ComponentProps<typeof ButtonBase.Root>) {
    return (
        <ButtonBase.Root className={styles.footerButton} variant="primary" {...props}>
            {children}
        </ButtonBase.Root>
    );
}
