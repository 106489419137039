import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { usePromise } from '@fieldmargin/webapp-state';
import c from 'classnames';
import { stopEditing } from 'farm-editing/farm-editing-state';
import { startNewFeature } from 'farm-editing/start-editing-reducer';
import { selectCurrentFarm } from 'farms/farms-state';
import { useCreateFeature } from 'features/feature-hooks';
import { selectNextFeatureNameCountForTypeMap } from 'features/features-selectors';
import { selectDisplayableFeatureTypes } from 'features/features-state';
import type FeatureType from 'features/FeatureType';
import { asTypeItem } from 'features/FeatureType';
import AddFeatureType from 'features/type/AddFeatureType';
import { useAddingTypeState } from 'features/type/adding-type-state';
import type { List, Map } from 'immutable';
import { bindActionCreators } from 'redux';
import CreateHeader from 'sidebar/header/CreateHeader';
import NewBadge from 'sidebar/modules/badge/NewBadge';
import NewShapes from 'sidebar/modules/shapes/NewShapes';
import SidebarModule from 'sidebar/modules/SidebarModule';
import SidebarError from 'sidebar/SidebarError';
import type { AppState } from 'system/store';
import TextInputField from 'view/form/hook/TextInputField';
import { required } from 'view/form/validations';
import type { BadgeItemWithIdLike } from 'view/molecules/badge-item/BadgeItem';

interface FeatureNewProps {
    farm: Farm;
    editingGeoFeatureCollection: GeoFeatureCollection;
    featureTypes: List<FeatureType>;
    featureTypeNameCount: Map<string, number>;
    startNewFeature: typeof startNewFeature;
    stopEditing: typeof stopEditing;
}

interface FormValues {
    name: string;
    featureTypeUuid: string;
}

const FeatureNew = ({
    farm,
    featureTypes,
    featureTypeNameCount,
    editingGeoFeatureCollection,
    startNewFeature,
    stopEditing,
}: FeatureNewProps) => {
    const { t } = useTranslation();
    const { pending, error, setPromise } = usePromise();
    const [nameIsPlaceholder, setNameIsPlaceholder] = useState(true);
    const methods = useForm<FormValues>({
        mode: 'onChange',
    });

    const [{ addingType, createdFeatureTypeUuid }, toggleAddingType, setCreatedFeatureTypeUuid] =
        useAddingTypeState();

    useEffect(() => {
        if (createdFeatureTypeUuid !== undefined) {
            methods.setValue('featureTypeUuid', createdFeatureTypeUuid);
        }
    }, [createdFeatureTypeUuid]);

    const createFeature = useCreateFeature();

    const handleSave = (values: FormValues) => {
        setPromise(createFeature(values.name, editingGeoFeatureCollection, values.featureTypeUuid));
    };

    const handleTypeChange = (item: BadgeItemWithIdLike) => {
        if (nameIsPlaceholder) {
            const count = featureTypeNameCount.get(item.id, 0);
            const countStr = count === 0 ? '' : ` ${count}`;
            methods.setValue('name', `${item.name}${countStr}`, { shouldValidate: true });
        }
    };

    return (
        <>
            {addingType && (
                <AddFeatureType
                    onBack={toggleAddingType}
                    onSave={(featureType) => {
                        setCreatedFeatureTypeUuid(featureType.uuid);
                        toggleAddingType();
                    }}
                />
            )}
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSave)}
                    className={c('FeatureNew', 'scrollable', { hidden: addingType })}
                >
                    <div className="non-scrolling">
                        <CreateHeader
                            isSaving={pending}
                            backPath={`/farms/${farm.uuid}/features/`}
                        />
                        {error && (
                            <SidebarError
                                title={t('failed_to_save')}
                                message={t('something_went_wrong')}
                            />
                        )}
                    </div>
                    <div className="scrolling">
                        <NewBadge
                            field="featureTypeUuid"
                            label={t('feature_type_title')}
                            options={featureTypes.map(asTypeItem)}
                            onCreate={toggleAddingType}
                            onChange={handleTypeChange}
                        />
                        <SidebarModule className="Title" editing>
                            <h4>{t('label_feature_name')}</h4>
                            <TextInputField
                                field="name"
                                onChange={() => setNameIsPlaceholder(false)}
                                validate={required(t('register_required'))}
                            />
                        </SidebarModule>
                        <NewShapes
                            startEditing={startNewFeature}
                            stopEditing={stopEditing}
                            required
                        />
                    </div>
                </form>
            </FormProvider>
        </>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        featureTypes: selectDisplayableFeatureTypes(state),
        editingGeoFeatureCollection: state.farmEditingState.editingGeoFeatureCollection,
        featureTypeNameCount: selectNextFeatureNameCountForTypeMap(state),
    }),
    (dispatch) =>
        bindActionCreators(
            {
                startNewFeature,
                stopEditing,
            },
            dispatch
        )
)(FeatureNew);
