import { useTranslation } from 'react-i18next';
import { ToggleActivity } from 'components/filters/ToggleActivity';

import { FilterByJobs } from '../FilterByJobs';
import { FilterByScreen } from '../FilterByScreen/FilterByScreen';
import { FilterByUsers } from '../FilterByUsers';
import { FiltersGroup } from '../FiltersGroup';
import { FilterTimeRange } from '../FilterTimeRange';
import { FilterWrapper } from '../FilterWrapper';

function Filters() {
    const { t } = useTranslation();

    return (
        <FiltersGroup>
            <FilterWrapper.Root>
                <FilterWrapper.Label>{t('time_range')}</FilterWrapper.Label>
                <FilterWrapper.Item>
                    <FilterTimeRange />
                </FilterWrapper.Item>
            </FilterWrapper.Root>

            <FilterWrapper.Item>
                <FilterByUsers />
            </FilterWrapper.Item>

            <FilterWrapper.Item>
                <FilterByJobs />
            </FilterWrapper.Item>

            <FilterWrapper.Item>
                <ToggleActivity />
            </FilterWrapper.Item>

            <FilterWrapper.Item>
                <FilterByScreen />
            </FilterWrapper.Item>
        </FiltersGroup>
    );
}

export { Filters };
