const getStorage = () => {
    return window.localStorage;
};

export default {
    getItem(key: string) {
        const storage = getStorage();
        if (storage) {
            try {
                return storage.getItem(key);
            } catch (_e) {
                //
            }
        }
        return null;
    },

    getItemAsJson<T>(key: string): T | null {
        const storage = getStorage();
        if (storage) {
            try {
                const i = storage.getItem(key);
                if (i) {
                    return JSON.parse(i) as T;
                }
            } catch (_e) {
                //
            }
        }
        return null;
    },

    setItem(key: string, value: string) {
        const storage = getStorage();
        if (storage) {
            try {
                storage.setItem(key, value);
            } catch (_e) {
                //
            }
        }
    },

    setItemAsJson(key: string, value: any) {
        const storage = getStorage();
        if (storage) {
            try {
                storage.setItem(key, JSON.stringify(value));
            } catch (_e) {
                //
            }
        }
    },

    removeItem(key: string) {
        const storage = getStorage();
        if (storage) {
            try {
                storage.removeItem(key);
            } catch (_e) {
                //
            }
        }
    },
};
