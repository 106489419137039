import { apiSlice } from 'api/base.api';
import { chatSliceReducer } from 'chat/chat-state';
import { discussionsReducer } from 'discussions/discussions-state';
import { farmEditingReducer } from 'farm-editing/farm-editing-state';
import { farmsReducer } from 'farms/farms-state';
import { farmInvitesReducer } from 'farms/invites/farm-invites-state';
import { featuresReducer } from 'features/features-state';
import { fieldUsageReducer } from 'fields/field-usage-state';
import { fieldsReducer } from 'fields/fields-state';
import { subFieldsReducer } from 'fields/sub-fields-state';
import { herdsReducer } from 'herd/herd-state';
import { inputsReducer } from 'inputs/inputs-state';
import { integrationsReducer } from 'integrations/integrations-state';
import { mapsReducer } from 'maps/farm/maps-state';
import { vegetationMapsReducer } from 'maps/vegetation/vegetation-maps-state';
import { mediaReducer } from 'media/media-state';
import { membershipReducer } from 'membership/membership-state';
import { notesReducer as _notesReducer } from 'notes/notes-state';
import { operationsReducer } from 'operations/operations-state';
import { organisationReducer } from 'organisation/organisation-state';
import { outputsReducer } from 'outputs/outputs-state';
import { combineReducers } from 'redux';
import { manualSensorsReducer } from 'sensors/manual/manual-sensors-state';
import { sensorsReducer } from 'sensors/sensors-state';
import { drawingReducer } from 'store/draw.slice';
import { agreenaReducer } from 'store/slices/agreenaToken.slice';
import { notesReducer } from 'store/slices/notes.slice';
import { uiReducer } from 'system/ui-state';
import { farmUsersReducer } from 'team/farm-users-state';
import { tutorialsReducer } from 'tutorials/tutorials-state';
import { userReducer } from 'users/user-state';
import { vehicleTrackingReducer } from 'vehicle-tracking/vehicle-tracking-state';
import { yearsReducer } from 'years/years-state';

// Reducers that operate on single segments of state
const combinedReducer = combineReducers({
    chatState: chatSliceReducer,
    farmsState: farmsReducer,
    farmEditingState: farmEditingReducer,
    farmInvites: farmInvitesReducer,
    farmUsersState: farmUsersReducer,
    featuresState: featuresReducer,
    fieldsState: fieldsReducer,
    subFieldsState: subFieldsReducer,
    fieldUsageState: fieldUsageReducer,
    integrationsState: integrationsReducer,
    mapsState: mapsReducer,
    vegetationMapsState: vegetationMapsReducer,
    //@deprecated
    notesState: _notesReducer,
    //
    operationsState: operationsReducer,
    inputsState: inputsReducer,
    outputsState: outputsReducer,
    sensorsState: sensorsReducer,
    manualSensorsState: manualSensorsReducer,
    membershipState: membershipReducer,
    organisationState: organisationReducer,
    tutorialsState: tutorialsReducer,
    uiState: uiReducer,
    user: userReducer,
    vehicleTrackingState: vehicleTrackingReducer,
    yearsState: yearsReducer,
    herdsState: herdsReducer,
    mediaState: mediaReducer,
    discussionsState: discussionsReducer,
    notes: notesReducer,
    drawing: drawingReducer,
    agreena: agreenaReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
});

export default combinedReducer;
