import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeatureCollection } from '@fieldmargin/webapp-geo';
import { serialize } from '@fieldmargin/webapp-geo';
import { usePromise, useToggle } from '@fieldmargin/webapp-state';
import c from 'classnames';
import { stopEditing } from 'farm-editing/farm-editing-state';
import { startEditingFeature } from 'farm-editing/start-editing-reducer';
import { selectCurrentFarm } from 'farms/farms-state';
import Feature from 'features/Feature';
import { useUpdateFeature } from 'features/feature-hooks';
import { fetchFeatureNotes } from 'features/features-api';
import { selectDisplayableFeatureTypes } from 'features/features-state';
import type FeatureType from 'features/FeatureType';
import { asTypeItem } from 'features/FeatureType';
import AddFeatureType from 'features/type/AddFeatureType';
import { useAddingTypeState } from 'features/type/adding-type-state';
import withPageFeature from 'features/withPageFeature';
import type { List } from 'immutable';
import type Note from 'notes/Note';
import { addNotes } from 'notes/notes-state';
import { bindActionCreators } from 'redux';
import EditHeader from 'sidebar/header/EditHeader';
import EditableBadge from 'sidebar/modules/badge/EditableBadge';
import EditableShapes from 'sidebar/modules/shapes/EditableShapes';
import EditableTitle from 'sidebar/modules/title/EditableTitle';
import type { AppState } from 'system/store';
import Fog from 'view/Fog';
import ItemNotFound from 'view/ItemNotFound';
import { selectCurrentYear } from 'years/years-state';

import FeatureTabs from './FeatureTabs';

interface EditableFeatureDetailsProps {
    farm: Farm;
    year: number;
    feature?: Feature;
    featureTypes: List<FeatureType>;
    startEditingFeature: typeof startEditingFeature;
    stopEditing: typeof stopEditing;
    addNotes: typeof addNotes;
}

const EditableFeatureDetails = ({
    farm,
    year,
    feature,
    featureTypes,
    startEditingFeature,
    stopEditing,
    addNotes,
}: EditableFeatureDetailsProps) => {
    const { t } = useTranslation();
    const [headerMenuOpen, toggleHeaderMenuOpen] = useToggle(false);

    const [{ addingType, createdFeatureTypeUuid }, toggleAddingType, setCreatedFeatureTypeUuid] =
        useAddingTypeState();

    const { pending: historyPending, setPromise: setHistoryPromise } =
        usePromise<Map<string, Note>>(addNotes);

    useEffect(() => {
        if (feature !== undefined) {
            setHistoryPromise(fetchFeatureNotes(farm.uuid, year, feature.uuid));
        }
    }, [farm.uuid, year, feature]);

    const fog = headerMenuOpen ? (
        <Fog blue className="top-14" onClick={toggleHeaderMenuOpen} />
    ) : null;

    // We default to a new feature here because it's safe to do so as updateFeature will never
    // be called if feature is undefined.
    // We have to do this though to prevent React potentially rendering a different amount
    // of hooks depending on whether feature is undefined or not.
    const updateFeature = useUpdateFeature(feature ?? new Feature());

    if (feature === undefined) {
        return (
            <ItemNotFound backPath={`/farms/${farm.uuid}/features`}>
                {t('feature_not_found')}
            </ItemNotFound>
        );
    }

    const handleNameSave = (name: string) => updateFeature({ name });

    const handleFeatureTypeSave = (featureTypeUuid: string) => updateFeature({ featureTypeUuid });

    const handleShapesSave = (newShapes: GeoFeatureCollection) =>
        updateFeature({ geoJson: serialize(newShapes) });

    return (
        <>
            {addingType && (
                <AddFeatureType
                    onBack={toggleAddingType}
                    onSave={(featureType) => {
                        setCreatedFeatureTypeUuid(featureType.uuid);
                        toggleAddingType();
                    }}
                />
            )}
            <div className={c('EditableFeatureDetails', 'scrollable', { hidden: addingType })}>
                {fog}
                <div className="non-scrolling">
                    <EditHeader
                        item={feature}
                        backPath={`/farms/${farm.uuid}/features`}
                        deletePath={`/farms/${farm.uuid}/features/${feature.uuid}/delete`}
                        menuOpen={headerMenuOpen}
                        onToggleHeaderMenu={toggleHeaderMenuOpen}
                    />
                </div>
                <div className="scrolling">
                    <EditableTitle
                        label={t('label_feature_name')}
                        value={feature.name}
                        onSave={handleNameSave}
                    />
                    <EditableBadge
                        label={t('feature_type_title')}
                        value={feature.featureTypeUuid}
                        options={featureTypes.map(asTypeItem)}
                        allowNull={false}
                        onSave={handleFeatureTypeSave}
                        forceSelectedId={createdFeatureTypeUuid}
                        onCreateClick={toggleAddingType}
                    />
                    <EditableShapes
                        value={feature.geoJson}
                        startEditing={() => {
                            startEditingFeature(feature);
                        }}
                        stopEditing={() => {
                            stopEditing();
                        }}
                        onSave={handleShapesSave}
                    />
                    <FeatureTabs feature={feature} pending={historyPending} />
                </div>
            </div>
        </>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
        year: selectCurrentYear(state),
        featureTypes: selectDisplayableFeatureTypes(state),
    }),
    (dispatch) => bindActionCreators({ startEditingFeature, stopEditing, addNotes }, dispatch)
)(withPageFeature(EditableFeatureDetails));
