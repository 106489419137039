import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { Text, useFieldState } from 'informed';
import { required } from 'view/form/validations';

interface TitleTextProps {
    field: string;
    className?: string;
    onChange?: (value: string | undefined) => void;
    placeholder?: string;
}

const TitleText = ({ field, className, onChange, placeholder }: TitleTextProps) => {
    const { t } = useTranslation();
    const { error } = useFieldState(field);
    const requiredValidation = required(t('register_required'));

    return (
        <>
            <Text
                field={field}
                type="text"
                validate={requiredValidation}
                className={c('simple-text-input', className, {
                    'has-error': error,
                })}
                validateOnBlur
                validateOnChange
                autoComplete="off"
                keepState={true}
                onChange={(e) => onChange && onChange(e.target.value)}
                placeholder={placeholder}
            />
            {error && <div className="field-error">{error}</div>}
        </>
    );
};

export default TitleText;
