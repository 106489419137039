import type { Farm } from '@fieldmargin/webapp-farms';
import type Field from 'fields/Field';
import {
    copySubFieldsToNotes,
    copySubFieldsToOperation,
    createSubFields,
} from 'fields/sub-field-utils';
import type SubField from 'fields/sub-fields/SubField';
import type { List, Set } from 'immutable';
import { Map as ImmutableMap } from 'immutable';
import type Note from 'notes/Note';
import type FullOperation from 'operations/FullOperation';
import type { OperationField } from 'operations/OperationField';
import { saveOperationsFields } from 'operations/operations-save-helper';
import { trackEvent } from 'utils/trackEvent';

export enum SubFieldsDataCopyStep {
    USAGE,
    OPERATIONS,
    NOTES,
}

export const getStartingStep = (
    fieldUsage: string | undefined,
    operations: ImmutableMap<string, FullOperation>
) => {
    if (fieldUsage !== undefined) {
        return SubFieldsDataCopyStep.USAGE;
    }
    if (operations.size > 0) {
        return SubFieldsDataCopyStep.OPERATIONS;
    }
    return SubFieldsDataCopyStep.NOTES;
};

export const getNextStep = (
    currentStep: SubFieldsDataCopyStep,
    operations: ImmutableMap<string, FullOperation>,
    notes: Map<string, Note>
) => {
    const hasOperations = operations.size > 0;
    const hasNotes = notes.size > 0;
    if (currentStep === SubFieldsDataCopyStep.USAGE) {
        return hasOperations
            ? SubFieldsDataCopyStep.OPERATIONS
            : hasNotes
              ? SubFieldsDataCopyStep.NOTES
              : undefined;
    }
    if (currentStep === SubFieldsDataCopyStep.OPERATIONS) {
        return hasNotes ? SubFieldsDataCopyStep.NOTES : undefined;
    }
    return;
};

export const getPreviousStep = (
    currentStep: SubFieldsDataCopyStep,
    yearFieldUsage: string | undefined,
    operations: ImmutableMap<string, FullOperation>
) => {
    const hasYearFieldUsage = yearFieldUsage !== undefined;
    const hasOperations = operations.size > 0;
    if (currentStep === SubFieldsDataCopyStep.OPERATIONS) {
        return hasYearFieldUsage ? SubFieldsDataCopyStep.USAGE : undefined;
    }
    if (currentStep === SubFieldsDataCopyStep.NOTES) {
        return hasOperations
            ? SubFieldsDataCopyStep.OPERATIONS
            : hasYearFieldUsage
              ? SubFieldsDataCopyStep.USAGE
              : undefined;
    }
    return;
};

export const saveSubFieldsCopyData = async (
    farm: Farm,
    year: number,
    parentField: Field,
    yearFieldUsage: string | undefined,
    subFields: List<SubField>,
    fieldOperations: ImmutableMap<string, FullOperation>,
    fieldNotes: Map<string, Note>,
    copyUsagesTo: Set<string>,
    copyOperationsTo: Set<string>,
    copyNotesTo: Set<string>
) => {
    // First, set up the sub fields with the correct usage
    const subFieldsWithUsages = subFields.map((subField) =>
        copyUsagesTo.contains(subField.id) && yearFieldUsage !== undefined
            ? subField.setIn(
                  ['field', 'yearFieldUsages'],
                  new Map([[year.toString(), yearFieldUsage]])
              )
            : subField
    );

    // Secondly, save these sub fields to the server so that they have UUIDs
    const savedSubFields = await createSubFields(farm.uuid, subFieldsWithUsages);

    // Thirdly, update the operation fields with the new sub field uuids and save them
    const subFieldsToSaveToOperations = savedSubFields.filter((sf) =>
        copyOperationsTo.contains(sf.id)
    );
    const savedOperations =
        copyOperationsTo.size > 0
            ? await saveOperationsFields(
                  farm.uuid,
                  fieldOperations.toList().map((fullOperation) => ({
                      fullOperation,
                      operationFields: copySubFieldsToOperation(
                          parentField,
                          subFieldsToSaveToOperations,
                          fullOperation
                      ).fields as Set<OperationField>,
                  }))
              )
            : ImmutableMap<string, FullOperation>();

    // Lastly, update the note fields with the new sub field uuids and save them
    const savedNotes =
        copyNotesTo.size > 0
            ? await copySubFieldsToNotes(
                  farm.uuid,
                  year,
                  parentField,
                  savedSubFields.filter((sf) => copyNotesTo.contains(sf.id)),
                  fieldNotes
              )
            : new Map<string, Note>();

    trackEvent(`Subfield created`, {
        farmUuid: farm.uuid,
        year,
    });

    return { subFields: savedSubFields, operations: savedOperations, notes: savedNotes };
};
