import type { MouseEvent } from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import c from 'classnames';
import { SearchBar } from 'components/search-bar';
import { selectFarms, selectUserCanAddMoreFreeFarms } from 'farms/farms-state';
import FreeFarmLimitWarningModal from 'farms/FreeFarmLimitWarningModal';
import { useSegmentTracking } from 'hooks/useSegmentTracking';
import { getRemoteConfigNumberValue, RemoteConfigKeys } from 'lib/firebase/firebase-connection';
import { stringContains } from 'lib/util/text';
import { useAppSelector } from 'system/store';
import { UIContext } from 'system/ui-state';
import type { ActiveSection } from 'system/url-util';
import { activeSectionToPath } from 'system/url-util';
import InfoMsg from 'view/InfoMsg';
import Arrow from 'view/svg/Arrow';

import ListDropdown from './ListDropdown';
import ListDropdownItem from './ListDropdownItem';
import ListDropdownLink from './ListDropdownLink';

import './FarmChooser.css';

interface FarmChooserProps {
    currentFarm?: Farm;
    activeSection: ActiveSection;
}

const FarmChooser = ({ currentFarm, activeSection }: FarmChooserProps) => {
    const { t } = useTranslation();
    const { createFarm } = useSegmentTracking();
    const farms = useAppSelector(selectFarms);
    const canAddFreeFarm = useAppSelector((state) =>
        selectUserCanAddMoreFreeFarms(
            state,
            getRemoteConfigNumberValue(RemoteConfigKeys.MAX_FREE_FARMS)
        )
    );
    // Seven is a magic, product number here.
    const canSearch = farms.size > 7;
    const { infoOpen } = useContext(UIContext);

    const [open, toggleOpen] = useToggle(false);
    const [modal, toggleModal] = useToggle(false);
    const [filter, setFilter] = useState('');

    const handleAddClick = (e: MouseEvent) => {
        if (!canAddFreeFarm) {
            e.preventDefault();
            toggleOpen();
            toggleModal();
            createFarm();
        }
    };

    const filteredFarms = farms.filter((farm) => stringContains(farm.name, filter));

    return (
        <div className={c('relative flex items-center self-stretch', { forefront: infoOpen })}>
            {infoOpen && (
                <InfoMsg className="top-16 left-4 flex-col">
                    <Arrow className="w-12 flip-x mb-3" />
                    {t('info_farm_list')}
                </InfoMsg>
            )}
            {modal && currentFarm !== undefined && (
                <FreeFarmLimitWarningModal farm={currentFarm} onClose={toggleModal} />
            )}
            <button
                className="bare-btn focus CurrentItem"
                onClick={toggleOpen}
                data-dropdown="farm-chooser"
            >
                <span className="block mr-2 max-w-36 truncate">
                    {currentFarm?.name || 'Select farm'}
                </span>
                <i className={open ? 'ion-arrow-up-b' : 'ion-arrow-down-b'} />
            </button>
            {open && (
                <ListDropdown onClose={toggleOpen} className="FarmChooser" name="farm-chooser">
                    <Link
                        to="/create-farm"
                        className="CreateFarmLink focus"
                        onClick={handleAddClick}
                    >
                        <i className="ion-plus mr-2" />
                        {t('label_add_new_farm')}
                    </Link>
                    {canSearch && (
                        <div className="FarmSearch">
                            <SearchBar
                                value={filter}
                                searchFunction={setFilter}
                                className="w-full"
                            />
                            {filteredFarms.size === 0 && <p>{t('farm_search_empty')}</p>}
                            {filteredFarms.size > 0 && filteredFarms.size < farms.size && (
                                <p>
                                    {t('farm_search_count', {
                                        sprintf: [filteredFarms.size, farms.size],
                                    })}
                                </p>
                            )}
                        </div>
                    )}
                    {filteredFarms.map((farm) => (
                        <ListDropdownItem key={farm.uuid}>
                            <ListDropdownLink
                                to={`/farms/${farm.uuid}/${activeSectionToPath(activeSection)}`}
                                onClick={toggleOpen}
                                selected={farm.uuid === currentFarm?.uuid}
                                locked={farm.plan.blocked || farm.blocked}
                            >
                                {farm.name}
                                {!farm.plan.blocked && ` - ${farm.plan.displayName}`}
                            </ListDropdownLink>
                        </ListDropdownItem>
                    ))}
                </ListDropdown>
            )}
        </div>
    );
};

export default FarmChooser;
