import type { ChangeEvent, FocusEvent, ReactNode } from 'react';
import { createContext, useEffect, useRef, useState } from 'react';
import c from 'classnames';
import { useDocumentEventListener } from 'lib/hooks';
import type { SingleParamVoidFunction } from 'system/types';

export const ComboboxContext = createContext<{ deactivate: VoidFunction }>({
    deactivate: () => {},
});

interface ComboboxProps {
    id: string;
    children?: ReactNode;
    search: string;
    onSearch: SingleParamVoidFunction<string>;
    error?: boolean;
    placeholder?: string;
    autofocus?: boolean;
    className?: string;
    onFocus?: SingleParamVoidFunction<FocusEvent>;
}

const Combobox = ({
    id,
    children,
    search,
    onSearch,
    error = false,
    placeholder = '',
    autofocus = false,
    className,
    onFocus,
}: ComboboxProps) => {
    const [active, setActive] = useState(false);
    const searchRef = useRef<HTMLInputElement | null>(null);

    const handleDocClick = (e: MouseEvent) => {
        const isStillActive = searchRef.current === document.activeElement;
        const isComboboxEl =
            e.target !== null && (e.target as HTMLElement).dataset.combobox === 'true';

        if (!isStillActive && !isComboboxEl) {
            setActive(false);
        }
    };

    useEffect(() => {
        if (autofocus && searchRef.current !== null) {
            searchRef.current.focus();
        }
    }, [autofocus]);

    const handleEnterPress = (e: KeyboardEvent) => {
        active && e.keyCode === 13 && e.preventDefault();
    };

    useDocumentEventListener('keydown', handleEnterPress, [active]);
    useDocumentEventListener('click', handleDocClick);

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        setActive(true);
        onFocus && onFocus(e);
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        onSearch(e.target.value);
    };

    return (
        <div className="relative">
            <input
                type="text"
                id={id}
                className={c('simple-text-input placeholder-gray-350', className, {
                    'has-error': error,
                })}
                onChange={handleSearch}
                value={search}
                onFocus={handleFocus}
                data-combobox
                autoComplete="off"
                placeholder={placeholder}
                ref={searchRef}
            />
            <ComboboxContext.Provider value={{ deactivate: () => setActive(false) }}>
                {active && children !== undefined && (
                    <ol className="bg-white bordered rounded shadow absolute z-20 top-full w-full max-h-96 overflow-auto list-none p-0">
                        {children}
                    </ol>
                )}
            </ComboboxContext.Provider>
        </div>
    );
};

export default Combobox;
