import { useTranslation } from 'react-i18next';
import Button from '@fieldmargin/webapp-styling/components/button';
import { selectDrawingTool } from 'farm-editing/farm-editing-state';
import type Field from 'fields/Field';
import { useFreshEditingGeoFeatureCollection } from 'fields/sub-field-hooks';
import { finishDrawingNew, stopDrawingNew } from 'fields/sub-fields-state';
import { useClearEditingGeoFeatureCollection } from 'hooks/useClearEditingGeoFeatureCollection';
import SidebarModule from 'sidebar/modules/SidebarModule';
import { useAppDispatch } from 'system/store';

import { createEmptySubField } from '../SubField';

interface SubFieldsDrawNewPanelProps {
    year: number;
    parentField: Field;
}

const SubFieldsDrawNewPanel = ({ year, parentField }: SubFieldsDrawNewPanelProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { clearEditingGeoFeatureCollection } = useClearEditingGeoFeatureCollection();

    const handleCancel = () => {
        dispatch(stopDrawingNew());
        dispatch(selectDrawingTool(null));
    };

    useFreshEditingGeoFeatureCollection((editingGeoFeatureCollection) => {
        if (editingGeoFeatureCollection !== null) {
            const subField = createEmptySubField(
                parentField.uuid,
                year,
                editingGeoFeatureCollection
            );
            dispatch(finishDrawingNew(subField));
            clearEditingGeoFeatureCollection();
        }
    });

    return (
        <SidebarModule editing className="text-gray-450">
            <p>{t('sub_fields_draw_new_desc')}</p>
            <p>
                <Button small variant="danger-outline" onClick={handleCancel}>
                    {t('cancel')}
                </Button>
            </p>
        </SidebarModule>
    );
};

export default SubFieldsDrawNewPanel;
