import type { Extent, GeoPosition } from '@fieldmargin/webapp-geo';
import { Record } from 'immutable';

const MapPosition = Record({
    centre: null as GeoPosition | null,
    zoom: null as number | null,
    extent: null as Extent | null,
});
interface MapPosition extends ReturnType<typeof MapPosition> {}

export default MapPosition;
