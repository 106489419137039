import { List, Record } from 'immutable';
import { InputUnit } from 'inputs/Input';
import type { PesticideData } from 'inputs/Pesticide';
import { compareString } from 'lib/util/text';

export interface InputReportDTO {
    input: string;
    usages: InputReportUsageDTO[];
    sumOfTotal: number;
    sumOfCost: number;
    pesticideData: PesticideData | null;
}

interface InputReportUsageDTO {
    usageName: string;
    total: number;
    cost: number;
    fields: InputReportFieldDTO[];
}

interface InputReportFieldDTO {
    fieldName: string;
    application: number;
    totalRate: number;
    total: number;
    cost: number;
}

export class InputReport extends Record({
    input: '',
    unit: InputUnit.COUNT,
    usages: List<InputReportUsage>(),
    total: 0,
    cost: 0,
    pesticideData: undefined as undefined | PesticideData,
}) {}

class InputReportUsage extends Record({
    usageName: '',
    total: 0,
    cost: 0,
    fields: List<InputReportField>(),
}) {}

class InputReportField extends Record({
    fieldName: '',
    application: 0,
    totalRate: 0,
    total: 0,
    cost: 0,
}) {}

export const deserializeInputReports = (dtos: InputReportDTO[]) =>
    List(dtos.map(deserializeInputReport)).sort((a, b) => compareString(a.input, b.input));

export const deserializeInputReport = (dto: InputReportDTO) =>
    new InputReport({
        input: dto.input,
        usages: List(dto.usages.map(deserializeInputReportUsage)).sort((a, b) =>
            compareString(a.usageName, b.usageName)
        ),
        total: dto.sumOfTotal,
        cost: dto.sumOfCost,
        pesticideData: dto.pesticideData !== null ? dto.pesticideData : undefined,
    });

const deserializeInputReportUsage = (dto: InputReportUsageDTO) =>
    new InputReportUsage({
        usageName: dto.usageName,
        total: dto.total,
        cost: dto.cost,
        fields: List(dto.fields.map(deserializeInputReportField)).sort((a, b) =>
            compareString(a.fieldName, b.fieldName)
        ),
    });

const deserializeInputReportField = (dto: InputReportFieldDTO) =>
    new InputReportField({
        ...dto,
    });
