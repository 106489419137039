import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import c from 'classnames';
import ReturningLink from 'view/ReturningLink';

interface SidebarHeaderLinkProps extends LinkProps {
    returning?: boolean;
}

const SidebarHeaderLink = ({
    returning = false,
    children,
    className,
    ...props
}: SidebarHeaderLinkProps) => {
    if (returning) {
        return (
            <ReturningLink {...props} className={c('SidebarHeaderLink', className)}>
                {children}
            </ReturningLink>
        );
    }
    return (
        <Link className={c('SidebarHeaderLink', className)} {...props}>
            {children}
        </Link>
    );
};

export default SidebarHeaderLink;
