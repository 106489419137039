import { useEffect, useState } from 'react';
import { usePromise, useToggle } from '@fieldmargin/webapp-state';
import c from 'classnames';
import { useCurrentFarm } from 'hooks/useCurrentFarm';
import Temperature from 'view/units/Temperature';
import TemperatureUnit from 'view/units/TemperatureUnit';

import weatherIcon from './icons/weatherIcon';
import WeatherModal from './modal/WeatherModal';
import type { Weather } from './Weather';
import { getWeather } from './weather-api';

interface CurrentWeatherProps {
    className?: string;
}

const CurrentWeather = ({ className }: CurrentWeatherProps) => {
    const { currentFarm } = useCurrentFarm();
    const [weather, setWeather] = useState<Weather>();
    const { pending, setPromise } = usePromise<Weather>(setWeather);

    const [showWeatherModal, toggleWeatherModal] = useToggle(false);

    useEffect(() => {
        const coords = currentFarm.geoJsonPoint.coordinates;
        setPromise(getWeather(coords[0], coords[1]));
        const interval = window.setInterval(
            () => {
                setPromise(getWeather(coords[0], coords[1]));
            },
            60 * 60 * 1000
        ); // every hour

        return () => {
            clearInterval(interval);
        };
    }, [currentFarm]);

    if (pending) {
        return (
            <div className="rounded bg-fm-blue text-white text-sm font-bold leading-10 px-5">
                Loading...
            </div>
        );
    }

    if (weather === undefined || weather.currentWeather === undefined) {
        return null;
    }

    return (
        <>
            {showWeatherModal && (
                <WeatherModal farm={currentFarm} weather={weather} onClose={toggleWeatherModal} />
            )}
            <button
                className={c(
                    'bare-btn rounded bg-fm-blue text-white text-sm font-bold pl-5 pr-3 flex items-center gap-2 leading-10 focus',
                    className
                )}
                onClick={toggleWeatherModal}
            >
                <span>
                    <Temperature celsius={weather.currentWeather.temperature} round />{' '}
                    <TemperatureUnit />
                </span>
                {weatherIcon(weather.currentWeather.icon, 'white', 'h-10 w-10 ml-2')}
            </button>
        </>
    );
};

export default CurrentWeather;
