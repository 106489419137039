import { createAction, handleActions } from '@fieldmargin/webapp-state';
import type { LoadedFarmSuccessPayload } from 'farms/farm-loading-state';
import { loadedFarmSuccess, loadingFarm } from 'farms/farm-loading-state';
import { selectCurrentFarm } from 'farms/farms-state';
import { List, Map, Record } from 'immutable';
import { getUuid, uuidMatches } from 'lib/fp-helpers';
import { replaceOrAdd } from 'lib/immutil';
import { compareName } from 'lib/util/text';
import { groupBy } from 'ramda';
import { createSelector } from 'reselect';
import type { AppState } from 'system/store';

import type Input from './Input';
import { inputIsPesticide } from './Input';

export const setInput = createAction<InputsState, Input>('Set input', (state, newInput) =>
    state.update('inputs', (inputs: List<Input>) =>
        replaceOrAdd(inputs, newInput, uuidMatches(newInput.uuid))
    )
);

export const setInputs = createAction<InputsState, List<Input>>('Set inputs', (state, nextInputs) =>
    nextInputs.reduce(
        (nextState, input) =>
            nextState.update('inputs', (inputs) =>
                replaceOrAdd(inputs, input, uuidMatches(input.uuid))
            ),
        state
    )
);

export const InputsState = Record({
    inputs: List<Input>(),
});
export interface InputsState extends ReturnType<typeof InputsState> {}

export const inputsReducer = handleActions<InputsState>(InputsState(), [setInput, setInputs], {
    [loadingFarm.toString()]: () => InputsState(),
    [loadedFarmSuccess.toString()]: (state, payload: LoadedFarmSuccessPayload) =>
        state.set('inputs', payload.inputs),
});

export const selectInputsSortedByArchived = createSelector(
    (state: AppState) => state.inputsState.inputs,
    (inputs) => {
        const sorted = groupBy(
            (input) => (input.archived ? 'archived' : 'active'),
            inputs.toArray()
        );
        sorted.active = sorted.active?.sort(compareName) ?? [];
        sorted.archived = sorted.archived?.sort(compareName) ?? [];
        return { active: List(sorted.active), archived: List(sorted.archived) };
    }
);

export const selectActiveInputs = createSelector(
    selectInputsSortedByArchived,
    ({ active }) => active
);

export const selectLockedInputs = createSelector(
    selectCurrentFarm,
    (state: AppState) => state.inputsState.inputs,
    (farm, inputs) => {
        // There are no locked inputs if the user has no limit or they are below the limit
        if (!farm || !farm.plan.inputLimit || inputs.size <= farm.plan.inputLimit) {
            return List();
        }

        return inputs
            .sortBy((input) => input.createdDate)
            .slice(0, inputs.size - farm.plan.inputLimit)
            .map((input) => input.uuid);
    }
);

export const selectInputMap = createSelector(
    (state: AppState) => state.inputsState.inputs,
    (inputs) => inputs.reduce((map, input) => map.set(input.uuid, input), Map<string, Input>())
);

export const selectPesticideInputUuids = createSelector(selectActiveInputs, (inputs) =>
    inputs.filter(inputIsPesticide).map(getUuid)
);
