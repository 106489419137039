import { Controller, useFormContext } from 'react-hook-form';
import type { Colour } from 'lib/colours';

import ColourPicker from '../ColourPicker';

export interface ColourPickerControllerProps {
    field: string;
    validate: (value: any) => string | undefined;
    colours?: Colour[];
}

const ColourPickerController = ({ field, validate, colours }: ColourPickerControllerProps) => {
    const { control } = useFormContext();
    return (
        <Controller
            control={control}
            name={field}
            rules={{ validate }}
            render={({ field, fieldState }) => (
                <>
                    <ColourPicker
                        selectedColour={field.value}
                        onChange={(value) => field.onChange(value)}
                        colours={colours}
                        className="mt-3"
                    />
                    {fieldState.error && (
                        <div className="field-error">{fieldState.error.message}</div>
                    )}
                </>
            )}
        />
    );
};

export default ColourPickerController;
