import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@fieldmargin/webapp-styling/components/button';
import type Herd from 'herd/Herd';
import { selectActiveHerdsForType, selectUnlockedHerds } from 'herd/herd-selectors';
import { List } from 'immutable';
import { notUuidMatches } from 'lib/fp-helpers';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarModule from 'sidebar/modules/SidebarModule';
import { useAppSelector } from 'system/store';
import ReturningLinkButton from 'view/ReturningLinkButton';

import { selectHerdField } from '../herd-ui-utils';
import HerdPreviewDetails from '../list/HerdPreviewDetails';
import HerdSelectorHerdTypeHerds from '../selector/HerdSelectorHerdTypeHerds';

import { useHerdMergeContext } from './useHerdMergeContext';

const HerdMergeSelectHerds = () => {
    const { t } = useTranslation();
    const { herd, farm, selectedHerds, setSelectedHerds } = useHerdMergeContext();
    const { herdField, herds, unlockedHerds } = useHerdMergeData(herd);

    return (
        <div className="scrollable">
            <div className="non-scrolling">
                <SidebarHeader className="px-5">
                    <ReturningLinkButton
                        to={`/farms/${farm.uuid}/livestock/${herd.uuid}`}
                        variant="danger-outline"
                        small
                    >
                        {t('cancel')}
                    </ReturningLinkButton>
                    <Button
                        as={Link}
                        to={`/farms/${farm.uuid}/livestock/${herd.uuid}/merge/details`}
                        small
                    >
                        {t('next')}
                    </Button>
                </SidebarHeader>
            </div>
            <div className="scrolling">
                <SidebarModule editing>
                    <h2 className="mb-1">{t('merge_herds')}</h2>
                    <p>
                        <em>{t('livestock_merge_instructions').replace(/\\n/g, ' ')}</em>
                    </p>
                </SidebarModule>
                <SidebarModule editing className="p-0 pr-5 flex justify-between">
                    <HerdPreviewDetails herd={herd} herdField={herdField} />
                    <div className="flex items-center">
                        <span>{herd.size}</span>
                    </div>
                </SidebarModule>
                <HerdSelectorHerdTypeHerds
                    type={herd.type}
                    herds={herds}
                    unlockedHerds={unlockedHerds}
                    selectedHerds={selectedHerds}
                    setSelectedHerds={setSelectedHerds}
                />
            </div>
        </div>
    );
};

const useHerdMergeData = (herd: Herd) => {
    const herds = useAppSelector((state) => selectActiveHerdsForType(state, herd.type)).filter(
        notUuidMatches(herd.uuid)
    );
    const unlockedHerds = useAppSelector(selectUnlockedHerds) ?? [];
    const fields = useAppSelector((state) => state.fieldsState.fields) ?? List();
    const herdField = selectHerdField(fields, herd);

    return { herdField, herds, unlockedHerds };
};

export default HerdMergeSelectHerds;
