import { defaultToEmptyString, defaultToZero } from 'lib/fp-helpers';
import { pick } from 'ramda';

export enum HerdType {
    CATTLE = 'CATTLE',
    SHEEP = 'SHEEP',
    PIGS = 'PIGS',
    GOATS = 'GOATS',
    HORSES = 'HORSES',
    POULTRY = 'POULTRY',
    OTHER = 'OTHER',
}

interface Herd {
    uuid: string;
    farmUuid: string;
    name: string;
    type: HerdType;
    colour: string;
    size: number;

    herdLocation: HerdLocation | null;

    createdByUserId: number;
    createdDate: Date;
    lastModifiedByUserId: number;
    version: number;

    archived: boolean;
}

export interface ReadHerdDTO {
    uuid: string;
    farmUUID: string;
    name: string;
    liveStockType: HerdType;
    colour: string;
    size: number;
    herdLocation: HerdLocationDTO | null;
    createdByUserId: number;
    createdDate: number;
    lastModifiedByUserId: number;
    version: number;
    archived: boolean;
}
export interface WriteHerdDTO
    extends Pick<
        ReadHerdDTO,
        | 'farmUUID'
        | 'name'
        | 'liveStockType'
        | 'colour'
        | 'size'
        | 'herdLocation'
        | 'archived'
        | 'version'
    > {
    uuid?: string;
}

export const createNewHerd = (
    props: Pick<Herd, 'farmUuid' | 'name' | 'type' | 'colour' | 'size'>
): Herd =>
    Object.freeze({
        ...props,
        uuid: '',
        herdLocation: null,
        createdByUserId: 0,
        createdDate: new Date(),
        lastModifiedByUserId: 0,
        version: -1,
        archived: false,
    });

export const deserializeHerd = (dto: ReadHerdDTO): Herd =>
    Object.freeze({
        ...pick(
            [
                'uuid',
                'name',
                'colour',
                'size',
                'createdByUserId',
                'lastModifiedByUserId',
                'version',
                'archived',
            ],
            dto
        ),
        farmUuid: dto.farmUUID,
        type: dto.liveStockType,
        herdLocation: dto.herdLocation ? deserializeHerdLocation(dto.herdLocation) : null,
        createdDate: dto.createdDate ? new Date(dto.createdDate) : new Date(),
    });

export const serializeHerd = (herd: Herd): WriteHerdDTO => ({
    uuid: herd.uuid === '' ? undefined : herd.uuid,
    farmUUID: herd.farmUuid,
    name: herd.name,
    liveStockType: herd.type,
    colour: herd.colour,
    size: herd.size,
    herdLocation: herd.herdLocation ? serializeHerdLocation(herd.herdLocation) : null,
    version: herd.version,
    archived: herd.archived,
});

export interface HerdLocation {
    uuid: string;
    fieldUuid?: string;
    herdUuid: string;
    latest: boolean;
    moveDate: Date;
    size: number;
    durationHours?: number;
    createdByUserId: number;
}

export interface HerdLocationDTO {
    uuid?: string;
    fieldUUID?: string;
    herdUUID: string;
    latest: boolean;
    moveDate: number;
    size?: number;
    durationHours?: number;
    createdByUserId?: number;
}

export const deserializeHerdLocation = ({
    uuid,
    fieldUUID,
    herdUUID,
    latest,
    moveDate,
    size,
    durationHours,
    createdByUserId,
}: HerdLocationDTO): HerdLocation => ({
    uuid: defaultToEmptyString(uuid),
    fieldUuid: fieldUUID,
    herdUuid: herdUUID,
    latest,
    moveDate: new Date(moveDate),
    size: defaultToZero(size),
    durationHours,
    createdByUserId: defaultToZero(createdByUserId),
});

export type WriteHerdLocation = Pick<
    HerdLocation,
    'fieldUuid' | 'herdUuid' | 'latest' | 'moveDate' | 'size'
>;
export const serializeHerdLocation = (location: WriteHerdLocation): HerdLocationDTO => ({
    fieldUUID: location.fieldUuid,
    herdUUID: location.herdUuid,
    latest: location.latest,
    moveDate: location.moveDate.getTime(),
    size: location.size,
});

export const herdLocationAsRemove = (firstMove: HerdLocation, secondMove: HerdLocation) => ({
    ...firstMove,
    moveDate: secondMove.moveDate,
    size: secondMove.size,
});

export enum HerdEventAction {
    NEW = 'new',
    NEW_FROM_SPLIT = 'new_from_split',
    UPDATE = 'update',
    ARCHIVE = 'archived',
    UNARCHIVE = 'un_archived',
    MERGE = 'merge',
}

export enum HerdEventCategory {
    HERD = 'HERD',
    HERD_NOTES = 'HERD_NOTES',
}

export type HerdEventDTO =
    | {
          action: HerdEventAction.NEW;
          category: HerdEventCategory.HERD;
          herdUUID: string;
          payload: ReadHerdDTO;
          timestamp: number;
      }
    | {
          action: HerdEventAction.NEW_FROM_SPLIT;
          category: HerdEventCategory.HERD;
          herdUUID: string;
          payload: { parentHerd: { name: string; uuid: string } };
          timestamp: number;
      }
    | {
          action: HerdEventAction.UPDATE;
          category: HerdEventCategory.HERD;
          herdUUID: string;
          payload: ReadHerdDTO;
          timestamp: number;
      }
    | {
          action: HerdEventAction.ARCHIVE;
          category: HerdEventCategory.HERD;
          herdUUID: string;
          payload: { auditing: { lastModifiedByUserId: number } };
          timestamp: number;
      }
    | {
          action: HerdEventAction.UNARCHIVE;
          category: HerdEventCategory.HERD;
          herdUUID: string;
          payload: { auditing: { lastModifiedByUserId: number } };
          timestamp: number;
      }
    | {
          action: HerdEventAction.MERGE;
          category: HerdEventCategory.HERD;
          herdUUID: string;
          payload: { sourceHerds: { name: string; uuid: string }[] };
          timestamp: number;
      }
    | {
          action: HerdEventAction.NEW;
          category: HerdEventCategory.HERD_NOTES;
          herdUUID: string;
          payload: { noteUUID: string };
          timestamp: number;
      };

export type HerdNewEvent = {
    action: HerdEventAction.NEW;
    category: HerdEventCategory.HERD;
    herdUuid: string;
    data: Herd;
    date: Date;
};

export type HerdNewFromSplitEvent = {
    action: HerdEventAction.NEW_FROM_SPLIT;
    category: HerdEventCategory.HERD;
    herdUuid: string;
    data: { name: string; uuid: string };
    date: Date;
};

export type HerdUpdateEvent = {
    action: HerdEventAction.UPDATE;
    category: HerdEventCategory.HERD;
    herdUuid: string;
    data: Herd;
    date: Date;
};

export type HerdArchiveEvent = {
    action: HerdEventAction.ARCHIVE;
    category: HerdEventCategory.HERD;
    herdUuid: string;
    data: { userId: number };
    date: Date;
};

export type HerdUnarchiveEvent = {
    action: HerdEventAction.UNARCHIVE;
    category: HerdEventCategory.HERD;
    herdUuid: string;
    data: { userId: number };
    date: Date;
};

export type HerdMergeEvent = {
    action: HerdEventAction.MERGE;
    category: HerdEventCategory.HERD;
    herdUuid: string;
    data: { name: string; uuid: string }[];
    date: Date;
};

export type HerdNewNoteEvent = {
    action: HerdEventAction.NEW;
    category: HerdEventCategory.HERD_NOTES;
    herdUuid: string;
    data: { noteUuid: string };
    date: Date;
};

export type HerdEvent =
    | HerdNewEvent
    | HerdNewFromSplitEvent
    | HerdUpdateEvent
    | HerdArchiveEvent
    | HerdUnarchiveEvent
    | HerdMergeEvent
    | HerdNewNoteEvent;

export const deserializeHerdEvent = (event: HerdEventDTO): HerdEvent => {
    if (
        (event.action === HerdEventAction.NEW && event.category === HerdEventCategory.HERD) ||
        event.action === HerdEventAction.UPDATE
    ) {
        return {
            action: event.action,
            category: event.category,
            herdUuid: event.herdUUID,
            data: deserializeHerd(event.payload),
            date: new Date(event.timestamp),
        };
    }
    if (event.action === HerdEventAction.NEW_FROM_SPLIT) {
        return {
            action: event.action,
            category: event.category,
            herdUuid: event.herdUUID,
            data: event.payload.parentHerd,
            date: new Date(event.timestamp),
        };
    }
    if (event.action === HerdEventAction.ARCHIVE || event.action === HerdEventAction.UNARCHIVE) {
        return {
            action: event.action,
            category: event.category,
            herdUuid: event.herdUUID,
            data: { userId: event.payload.auditing.lastModifiedByUserId },
            date: new Date(event.timestamp),
        };
    }
    if (event.action === HerdEventAction.MERGE) {
        return {
            action: event.action,
            category: event.category,
            herdUuid: event.herdUUID,
            data: event.payload.sourceHerds,
            date: new Date(event.timestamp),
        };
    }
    return {
        action: event.action,
        category: event.category,
        herdUuid: event.herdUUID,
        data: { noteUuid: event.payload.noteUUID },
        date: new Date(event.timestamp),
    };
};

export const getHerdTypeI18NKey = (type: string | HerdType) =>
    type === HerdType.OTHER ? 'other' : `livestock_type_${type.toLowerCase()}`;

export default Herd;
