import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { GeoFeature, GeoPosition } from '@fieldmargin/webapp-geo';
import clsx from 'clsx';
import { selectCurrentFarm } from 'farms/farms-state';
import type { Point } from 'geojson';
import type { Map } from 'immutable';
import type { MeasurementUnit } from 'lib/MeasurementUnit';
import { uniqueId } from 'lodash';
import { bindActionCreators } from 'redux';
import type { AppState } from 'system/store';
import { changeModal } from 'system/ui-state';

import type MapPosition from '../model/MapPosition';
import type MapView from '../model/MapView';

import type { MapData } from './LegacyMapAdapter';
import LegacyMapAdapter from './LegacyMapAdapter';

import './OpenLayersMapWrapper.scss';
import './ol.scss';
import styles from './OpenLayersMapWrapper.module.css';

interface OpenLayersMapWrapperProps {
    farm: Farm;
    changeModal: typeof changeModal;
    mapData: MapData;
    defaultPoint: Point;
    defaultZoom?: number;
    maxZoom?: number;
    baseMap?: string;
    mapView?: MapView;
    geoFeatureMap: Map<string, GeoFeature>;
    onClusterClick: (features: any[]) => boolean;
    onGeoFeatureClick: (id: string, type: string) => void;
    onGeoFeatureHover: (id: string | number | undefined) => void;
    onGeoFeatureAdd: (added: GeoFeature) => void;
    onGeoFeatureUpdate: (updated: GeoFeature, type?: string) => void;
    onMapClick: (position: GeoPosition) => void;
    onMapMove: (position: MapPosition) => void;
    onTiledLayersLoading?: (loadingLayers: string[]) => void;
    onTiledLayersError?: (errorLayers: string[]) => void;
    lengthUnits: MeasurementUnit;
    areaUnits: MeasurementUnit;
    declutterFieldNames: boolean;
}

const OpenLayersMapWrapper = (props: OpenLayersMapWrapperProps) => {
    const hostElementId = useRef(uniqueId('map-host-'));
    const mapInstance = useRef<any>(null);

    useEffect(() => {
        const map = new LegacyMapAdapter(hostElementId.current, props);
        mapInstance.current = map;
        map.update(props);
    }, []);

    useEffect(() => {
        if (mapInstance.current) {
            mapInstance.current.update(props);
        }
    }, [props]);
    return (
        <div className="open-layers-map-wrapper notranslate">
            <div
                id={hostElementId.current}
                className={clsx('open-layers-map', styles.olMapWrapper, {
                    retina: window.devicePixelRatio === 2,
                })}
            />
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        farm: selectCurrentFarm(state),
    }),
    (dispatch) => bindActionCreators({ changeModal }, dispatch)
)(OpenLayersMapWrapper);
