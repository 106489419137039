import { InputType } from 'inputs/InputType';
import { getRecordingInfo } from 'operations/sidebar/details/recording/operation-recording-utils';

import { useInputsSelector } from './useInputsSelector';
import { useOperationRecordingsSelector } from './useOperationRecordingsSelector';

const usePasedInputsRecordings = () => {
    const { recordings } = useOperationRecordingsSelector();
    const { inputs } = useInputsSelector();

    const items = recordings ? getRecordingInfo(recordings, inputs) : null;
    const parsedInputs = items?.filter((item) => item.input.get('type') !== InputType.FUEL);

    return {
        inputs: parsedInputs,
    };
};

export { usePasedInputsRecordings };
