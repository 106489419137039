import type { ReactNode } from 'react';
import c from 'classnames';

interface ErrorMessageBoxProps {
    className?: string;
    children: ReactNode;
}

const ErrorMessageBox = ({ className, children }: ErrorMessageBoxProps) => {
    return (
        <div className={c('bg-error-bg text-white rounded px-3 py-2', className)}>{children}</div>
    );
};

export default ErrorMessageBox;
