import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { List, Set } from 'immutable';
import { defaultToZero } from 'lib/fp-helpers';
import type { FullOperationField } from 'operations/OperationField';
import type Output from 'outputs/Output';
import type { SingleParamVoidFunction } from 'system/types';
import ShortAreaUnit from 'view/units/ShortAreaUnit';
import ShortUnit from 'view/units/ShortUnit';
import TemperatureUnit from 'view/units/TemperatureUnit';

import SpeedUnit from '../../../view/units/SpeedUnit';
import { calculateYieldRate, calculateYieldTotal } from '../details/yields/yield-utils';
import ShortYieldRate from '../ShortYieldRate';

import EditOperationFieldRow from './EditOperationFieldRow';
import type {
    EditOperationFieldsFieldValues,
    EditOperationFieldsFormValues,
} from './EditOperationFields';

interface EditOperationFieldsTableProps {
    userId: number;
    operationFields: List<FullOperationField>;
    selectedFields: Set<string>;
    toggleFieldSelected: SingleParamVoidFunction<string>;
    output?: Output;
    includeYield?: boolean;
    onRemoveField: SingleParamVoidFunction<string>;
}

const EditOperationFieldsTable = ({
    userId,
    operationFields,
    selectedFields,
    toggleFieldSelected,
    output,
    includeYield = false,
    onRemoveField,
}: EditOperationFieldsTableProps) => {
    const { t } = useTranslation();
    const { setValue, getValues } = useFormContext<EditOperationFieldsFormValues>();
    const copyChanges = (
        fieldUuid: string,
        prop: keyof EditOperationFieldsFieldValues,
        value: any
    ) => {
        if (selectedFields.size > 0 && selectedFields.contains(fieldUuid)) {
            selectedFields.forEach((fieldUuid) => {
                setValue(`${fieldUuid}.${prop}`, value);
            });
        }
    };

    const handleCompletedDateChange = (fieldUuid: string, dateTime: Date) => {
        const values = getValues(fieldUuid);
        if (values.completedByUserId === undefined) {
            setValue(`${fieldUuid}.completedByUserId`, userId.toString());
            copyChanges(fieldUuid, 'completedByUserId', userId.toString());
        }
        setValue(`${fieldUuid}.completedDate`, dateTime);
        copyChanges(fieldUuid, 'completedDate', dateTime);
    };

    const handleFieldCompleteChange = (
        fieldUuid: string,
        complete: boolean,
        completedByUserIdStr?: string
    ) => {
        const existingCompletedDate = getValues(`${fieldUuid}.completedDate`);
        const completedDate = !complete
            ? ''
            : existingCompletedDate instanceof Date
              ? existingCompletedDate
              : new Date();
        const completedByUserId = !complete
            ? ''
            : completedByUserIdStr
              ? completedByUserIdStr
              : userId.toString();

        setValue(`${fieldUuid}.completedDate`, completedDate);
        setValue(`${fieldUuid}.completedByUserId`, completedByUserId);
        copyChanges(fieldUuid, 'completedDate', completedDate);
        copyChanges(fieldUuid, 'completedByUserId', completedByUserId);
    };

    const handleWorkAreaChange = (fieldUuid: string, workArea: number) => {
        Object.entries(getValues())
            .filter(
                ([key]) =>
                    key === fieldUuid ||
                    (selectedFields.contains(fieldUuid) && selectedFields.contains(key))
            )
            .forEach(([key, values]) => {
                const yieldRate = defaultToZero(values.yieldRate);
                const yieldTotal = calculateYieldTotal(workArea, yieldRate);
                setValue(key, { ...values, workArea, yieldTotal });
            });
    };

    const handleYieldRateChange = (fieldUuid: string, yieldRate: number) => {
        Object.entries(getValues())
            .filter(
                ([key]) =>
                    key === fieldUuid ||
                    (selectedFields.contains(fieldUuid) && selectedFields.contains(key))
            )
            .forEach(([key, values]) => {
                const workArea = defaultToZero(values.workArea);
                const yieldTotal = calculateYieldTotal(workArea, yieldRate);
                setValue(key, { ...values, yieldRate, yieldTotal });
            });
    };

    const handleYieldTotalChange = (fieldUuid: string, yieldTotal: number) => {
        Object.entries(getValues())
            .filter(
                ([key]) =>
                    key === fieldUuid ||
                    (selectedFields.contains(fieldUuid) && selectedFields.contains(key))
            )
            .forEach(([key, values]) => {
                const workArea = defaultToZero(values.workArea);
                const yieldRate = calculateYieldRate(workArea, yieldTotal);
                setValue(key, { ...values, yieldRate, yieldTotal });
            });
    };

    return (
        <table className="EditOperationFieldsTable">
            <thead>
                <tr>
                    <th className="w-16">{t('edit_multiple')}</th>
                    <th>{t('hint_create_field_name')}</th>
                    <th className="w-16">{t('field_area')}</th>
                    <th className="w-16">
                        {t('output_rate_area')} (<ShortAreaUnit />)
                    </th>
                    <th className="">{t('start_date')}</th>
                    <th className="">{t('activity_log_completed_date_title')}</th>
                    {includeYield && (
                        <>
                            <th className="w-16">
                                {t('yield')} {output && <ShortYieldRate output={output} />}
                            </th>
                            <th className="w-16">
                                {t('operation_total_yield_label')}{' '}
                                {output && (
                                    <>
                                        (<ShortUnit unit={output.unit} />)
                                    </>
                                )}
                            </th>
                        </>
                    )}
                    <th className="w-16">
                        {t('wind_speed')} (<SpeedUnit />)
                    </th>
                    <th className="w-16">{t('weather_direction')}</th>
                    <th className="w-16">
                        {t('temp_placeholder')} (<TemperatureUnit />)
                    </th>
                    <th>{t('weather')}</th>
                    <th>{t('completed')}</th>
                    <th className="remove" />
                </tr>
            </thead>
            <tbody>
                {operationFields.map((operationField) => (
                    <EditOperationFieldRow
                        key={operationField.field.uuid}
                        operationField={operationField}
                        selected={selectedFields.contains(operationField.field.uuid)}
                        toggleSelected={toggleFieldSelected}
                        includeYield={includeYield}
                        onFormFieldChange={copyChanges}
                        onWorkAreaChange={handleWorkAreaChange}
                        onDateChange={handleCompletedDateChange}
                        onCompleteChange={handleFieldCompleteChange}
                        onYieldRateChange={handleYieldRateChange}
                        onYieldTotalChange={handleYieldTotalChange}
                        onRemoveField={onRemoveField}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default EditOperationFieldsTable;
