import { useTranslation } from 'react-i18next';
import icon from 'components/icons/icon';
import type FeatureType from 'features/FeatureType';
import SidebarHeader from 'sidebar/modules/header/SidebarHeader';
import SidebarHeaderLink from 'sidebar/modules/header/SidebarHeaderLink';
import SidebarModule from 'sidebar/modules/SidebarModule';

import { useCreateFeatureType } from './feature-type-hooks';
import FeatureTypeForm from './FeatureTypeForm';

interface AddFeatureTypeProps {
    onBack: VoidFunction;
    onSave: (featureType: FeatureType) => void;
}

const AddFeatureType = ({ onBack, onSave }: AddFeatureTypeProps) => {
    const { t } = useTranslation();
    const createFeatureType = useCreateFeatureType(onSave);
    return (
        <div className="AddFeatureType scrollable">
            <div className="non-scrolling">
                <SidebarHeader>
                    <SidebarHeaderLink
                        to="#"
                        onClick={(e) => {
                            e.preventDefault();
                            onBack();
                        }}
                    >
                        {icon('back', 'dark-blue', 'mr-3')} {t('back')}
                    </SidebarHeaderLink>
                </SidebarHeader>
            </div>
            <div className="scrolling">
                <SidebarModule editing={true}>
                    <FeatureTypeForm onCancel={onBack} onSave={createFeatureType} />
                </SidebarModule>
            </div>
        </div>
    );
};

export default AddFeatureType;
