import { useOutletContext } from 'react-router-dom';
import type { Farm } from '@fieldmargin/webapp-farms';
import type { Activity } from 'activity/activity-helper';
import type Field from 'fields/Field';
import type { HerdLocation } from 'herd/Herd';
import type { List } from 'immutable';
import type FullOperation from 'operations/FullOperation';

export interface FieldTabsSchema {
    todoActivities: List<Activity>;
    farm: Farm;
    field: Field;
    herdLocations: List<HerdLocation> | undefined;
    subFields: List<Field>;
    operations: List<FullOperation>;
}

export const useFieldTabsContext = () => {
    return useOutletContext<FieldTabsSchema>();
};
