import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import c from 'classnames';
import { useDocumentEventListener } from 'lib/hooks';
import { uniqueId } from 'lodash';

import './ListDropdown.css';

interface Props {
    children: ReactNode;
    className?: string;
    onClose: VoidFunction;
    small?: boolean;
    noFog?: boolean;
    name?: string;
}

const ListDropdown = ({
    children,
    className,
    onClose,
    small = false,
    noFog = false,
    name = 'dropdown',
}: Props) => {
    const [el, setEl] = useState<HTMLDivElement>();

    useEffect(() => {
        const div = document.createElement('div');
        div.id = uniqueId('dropdown-fog-');
        setEl(div);
    }, []);

    useEffect(() => {
        if (el !== undefined) document.body.appendChild(el);

        return () => {
            if (el !== undefined) document.body.removeChild(el);
        };
    }, [el]);

    useDocumentEventListener(
        'click',
        (e) => {
            const isInside =
                e.composedPath().filter((el: HTMLElement) => el.dataset?.dropdown === name).length >
                0;

            if (!isInside) onClose();
        },
        [onClose]
    );

    useDocumentEventListener(
        'keyup',
        (e: KeyboardEvent) => {
            // 27 is the key code for the escape key
            if (e.keyCode === 27) onClose();
        },
        [onClose]
    );

    let fog: ReactNode = null;
    if (el !== undefined && !noFog) {
        fog = createPortal(
            <div className="absolute top-14 left-0 w-screen h-content-full bg-fm-blue bg-opacity-75 z-10" />,
            el
        );
    }

    return (
        <div className="ListDropdown" data-dropdown={name}>
            {fog}
            <ol className={c('ListDropdownList', className, { ListDropdownListSmall: small })}>
                {children}
            </ol>
        </div>
    );
};

export default ListDropdown;
