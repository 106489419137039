import type { GeoFeature } from '@fieldmargin/webapp-geo';
import { deserializeLngLat } from '@fieldmargin/webapp-geo';
import { addEditingGeoFeature, selectDrawingTool } from 'farm-editing/farm-editing-state';
import type { Feature } from 'geojson';
import { useAction } from 'lib/hooks';

export const useFarmMapFeatureAdd = () => {
    const setDrawingTool = useAction(selectDrawingTool);
    const addFeature = useAction(addEditingGeoFeature);

    return (feature: Feature) => {
        const geoFeature = deserializeLngLat(feature) as GeoFeature;
        addFeature(geoFeature);
        setDrawingTool(null);
    };
};
