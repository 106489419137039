import { List, Record, Set } from 'immutable';
import { VisibilityOptions } from 'system/types';

import type { MapVisibilityKey } from './farm-editing-maps';

const LayerVisibility = Record({
    farmUuid: '',
    displayNotes: true,
    featuresVisibility: VisibilityOptions.ON,
    fieldsVisibility: VisibilityOptions.ON,
    herdsVisibility: VisibilityOptions.ON,
    hiddenFeatureTypes: Set<string>(), // Set of feature type uuid
    hiddenFieldUsages: Set<string | null>(), // Set of field usage uuids
    // Order affects how they are drawn on map, last in list on top
    visibleMapGroups: List<MapVisibilityKey>(),
    hiddenMapIntegrations: Set<string>(), // Set of farmIntegrationUuids
});

interface LayerVisibility extends ReturnType<typeof LayerVisibility> {}

export default LayerVisibility;
