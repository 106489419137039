import { authorizedRequest } from '@fieldmargin/webapp-auth';

import type { WeatherDTO } from './Weather';
import { deserializeWeather } from './Weather';

export const getWeather = (longitude: number, latitude: number, language = 'en') =>
    authorizedRequest<WeatherDTO>({
        url: '/weather/weatherByLocation',
        params: {
            latitude,
            longitude,
            language,
        },
    }).then(deserializeWeather);
