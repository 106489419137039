import { useEffect } from 'react';
import c from 'classnames';
import icon from 'components/icons/icon';
import { removeSubField, setSelectedTool, SubFieldTool } from 'fields/sub-fields-state';
import type { Set } from 'immutable';
import type { TurfFns } from 'lib/turf/turf-utils';
import { useTurf } from 'lib/turf/turf-utils';
import { useAppDispatch, useAppSelector } from 'system/store';
import ErrorMessage from 'view/ErrorMessage';

import { subFieldIcon } from '../sub-field-icons';

import SubFieldBoundaryEdit from './SubFieldBoundaryEdit';
import SubFieldMapMenuButton from './SubFieldMapMenuButton';
import SubFieldMargin from './SubFieldMargin';
import SubFieldMerge from './SubFieldMerge';
import SubFieldsCut from './SubFieldsCut';
import SubFieldSlice from './SubFieldSlice';

import './SubFieldMapMenu.css';

const options = [
    { tool: SubFieldTool.SLICE, display: 'Slice' },
    { tool: SubFieldTool.CUT, display: 'Cut out' },
    { tool: SubFieldTool.MARGIN, display: 'Add margin' },
    { tool: SubFieldTool.MERGE, display: 'Merge' },
] as {
    tool: SubFieldTool;
    display: string;
}[];

const optionIsDisabled = (selectedSubFields: Set<string>) => selectedSubFields.size < 1;

const SubFieldMapMenu = () => {
    const [pending, error, turf] = useTurf();
    const dispatch = useAppDispatch();
    const selectedSubFields = useAppSelector((state) => state.subFieldsState.selectedSubFields);
    const subFields = useAppSelector((state) => state.subFieldsState.subFields);
    const selectedTool = useAppSelector((state) => state.subFieldsState.selectedTool);

    useEffect(() => {
        if (selectedSubFields.size === 0 || (selectedTool && optionIsDisabled(selectedSubFields))) {
            dispatch(setSelectedTool(undefined));
        }
    }, [selectedSubFields]);

    if (selectedTool === SubFieldTool.NEW) {
        return null;
    }

    const handleDelete = () => {
        dispatch(removeSubField(selectedSubFields.first<string>()));
    };

    const deleteEnabled = subFields.size > 1 && selectedSubFields.size === 1;

    return (
        <div className="SubFieldMapMenu" style={{ width: 430 }}>
            <div className={c('flex justify-center', { 'mb-3': selectedTool !== undefined })}>
                {options.map(({ tool, display }) => (
                    <SubFieldMapMenuButton
                        key={tool.toString()}
                        onClick={() => dispatch(setSelectedTool(tool))}
                        className={selectedTool === tool ? 'opacity-50' : ''}
                        disabled={pending || optionIsDisabled(selectedSubFields)}
                    >
                        {subFieldIcon(
                            tool.toString() as any,
                            pending || optionIsDisabled(selectedSubFields) ? 'grey' : 'blue'
                        )}
                        {display}
                    </SubFieldMapMenuButton>
                ))}
                <SubFieldMapMenuButton
                    className={deleteEnabled ? 'SubFieldMapMenuDeleteButton' : ''}
                    disabled={!deleteEnabled}
                    onClick={handleDelete}
                >
                    {icon('remove', deleteEnabled ? 'red' : 'grey')}
                    Delete
                </SubFieldMapMenuButton>
            </div>
            {error && (
                <ErrorMessage className="mt-2">
                    Something went wrong, please refresh the page and try again.
                </ErrorMessage>
            )}
            {turf && (
                <SubFieldMapPanel
                    selectedTool={selectedTool}
                    setSelectedTool={(tool) => dispatch(setSelectedTool(tool))}
                    turf={turf}
                />
            )}
        </div>
    );
};

const subFieldMapPanelComponents = {
    margin: SubFieldMargin,
    cut: SubFieldsCut,
    slice: SubFieldSlice,
    merge: SubFieldMerge,
};

const SubFieldMapPanel = ({
    selectedTool,
    setSelectedTool,
    turf,
}: {
    selectedTool?: SubFieldTool;
    setSelectedTool: (selectedTool?: SubFieldTool) => void;
    turf: TurfFns;
}) => {
    if (!selectedTool || subFieldMapPanelComponents[selectedTool.toString()] === undefined) {
        return <SubFieldBoundaryEdit />;
    }

    const handleDone = () => setSelectedTool();
    const Component = subFieldMapPanelComponents[selectedTool.toString()];
    return <Component onDone={handleDone} turf={turf} />;
};

export default SubFieldMapMenu;
