import type { MouseEvent } from 'react';
import { connect } from 'react-redux';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { ButtonVariant } from '@fieldmargin/webapp-styling/components/button';
import Button from '@fieldmargin/webapp-styling/components/button';
import { bindActionCreators } from 'redux';
import { rememberCurrentLocation } from 'system/ui-state';

interface RememberingLinkButtonProps extends LinkProps {
    variant?: ButtonVariant;
    wide?: boolean;
    small?: boolean;
    disabled?: boolean;
    rememberCurrentLocation: typeof rememberCurrentLocation;
}

const RememberingLinkButton = ({
    variant = 'primary',
    wide = false,
    small = false,
    disabled = false,
    rememberCurrentLocation,
    children,
    ...props
}: RememberingLinkButtonProps) => {
    const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
        rememberCurrentLocation();
        props.onClick && props.onClick(e);
    };
    return (
        <Button as={Link} {...{ ...props, variant, wide, small, disabled }} onClick={handleClick}>
            {children}
        </Button>
    );
};

export default connect(
    () => ({}),
    (dispatch) => bindActionCreators({ rememberCurrentLocation }, dispatch)
)(RememberingLinkButton);
