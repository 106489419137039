import type { MouseEvent } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FarmUserRole } from '@fieldmargin/webapp-farms';
import { useToggle } from '@fieldmargin/webapp-state';
import classNames from 'classnames';
import {
    useDocumentEventListener,
    useInsideComponentKeyUpListener,
    useOutsideComponentClickListener,
} from 'lib/hooks';
import { uniqueId } from 'lodash';
import type { SingleParamVoidFunction } from 'system/types';

import './FarmUserRoleSelect.css';

interface FarmUserRoleSelectProps {
    value: FarmUserRole;
    onChange: SingleParamVoidFunction<FarmUserRole>;
    openDirection?: 'left' | 'right';
}

const FarmUserRoleSelect = ({
    value,
    onChange,
    openDirection = 'left',
}: FarmUserRoleSelectProps) => {
    const { t } = useTranslation();
    const id = uniqueId('farm-user-role-select-');
    const [open, toggleOpen] = useToggle(false);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const adminRef = useRef<HTMLButtonElement>(null);
    const memberRef = useRef<HTMLButtonElement>(null);

    const handleChange = (role: FarmUserRole, e: MouseEvent) => {
        e.preventDefault();
        onChange(role);
        toggleOpen();
        buttonRef.current?.focus();
    };

    const handleOpen = (e: MouseEvent) => {
        e.preventDefault();
        toggleOpen();
    };

    // Clicking outside this component should close it - this matches expected
    // select element behaviour.
    useOutsideComponentClickListener(id, () => {
        if (open) {
            toggleOpen();
        }
    }, [open]);

    // Clicking escape inside this component should close it - this matches expected
    // select element behaviour.
    useInsideComponentKeyUpListener(
        id,
        (e) => {
            if (e.key === 'Escape' && open) {
                // This prevents any parent element handling escape - this helps prevent
                // a modal from closing when this component is inside it.
                e.stopPropagation();
                toggleOpen();
            }
        },
        [open]
    );

    // Using the up and down arrows should navigate the elements in the list - this matches expected
    // select element behaviour.
    useDocumentEventListener(
        'keyup',
        (e: KeyboardEvent) => {
            if (e.key === 'ArrowDown' && document.activeElement === buttonRef.current && !open) {
                toggleOpen();
                return;
            }
            if (e.key === 'ArrowDown' && document.activeElement === buttonRef.current && open) {
                adminRef.current?.focus();
                return;
            }
            if (e.key === 'ArrowDown' && document.activeElement === adminRef.current) {
                memberRef.current?.focus();
                return;
            }
            if (e.key === 'ArrowUp' && document.activeElement === adminRef.current) {
                buttonRef.current?.focus();
                return;
            }
            if (e.key === 'ArrowUp' && document.activeElement === memberRef.current) {
                adminRef.current?.focus();
                return;
            }
        },
        [open]
    );

    return (
        <div className="FarmUserRoleSelect" data-elementid={id}>
            {/* This element is rendered only for screen readers so that visually impaired users can
			use and change the farm user role */}
            <div className="sr-only">
                <select
                    name={id}
                    id={id}
                    value={value}
                    onChange={(e) => onChange(FarmUserRole[e.target.value])}
                >
                    <option value={FarmUserRole.ADMIN}>
                        {t('admin')} - {t('role_description_admin')}
                    </option>
                    <option value={FarmUserRole.MEMBER}>
                        {t('member')} - {t('role_description_member')}
                    </option>
                </select>
            </div>
            <div aria-hidden="true">
                <button
                    className="bare-btn focus FarmUserRoleSelectButton"
                    onClick={handleOpen}
                    ref={buttonRef}
                >
                    {t(value.toLowerCase())} <i className="ion-chevron-down" />
                </button>
                {open && (
                    <ul
                        className={classNames('FarmUserRoleSelectItems', {
                            'FarmUserRoleSelectItems-right': openDirection === 'right',
                        })}
                    >
                        <li>
                            <button
                                className="bare-btn focus FarmUserRoleSelectItemButton"
                                onClick={handleChange.bind(null, FarmUserRole.ADMIN)}
                                ref={adminRef}
                            >
                                <h4>{t('admin')}</h4>
                                <p>{t('role_description_admin')}</p>
                                {(value === FarmUserRole.ADMIN || value === FarmUserRole.OWNER) && (
                                    <i className="ion-checkmark" />
                                )}
                            </button>
                        </li>
                        <li>
                            <button
                                className="bare-btn focus FarmUserRoleSelectItemButton"
                                onClick={handleChange.bind(null, FarmUserRole.MEMBER)}
                                ref={memberRef}
                            >
                                <h4>{t('member')}</h4>
                                <p>{t('role_description_member')}</p>
                                {value === FarmUserRole.MEMBER && <i className="ion-checkmark" />}
                            </button>
                        </li>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default FarmUserRoleSelect;
